unit MBI_u07;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Imaging.pngimage, WEBLib.Buttons,
  WEBLib.ExtCtrls, Vcl.Controls, WEBLib.StdCtrls, Vcl.StdCtrls;

type
  TfmLetoPodatkov = class(TForm)
    izLetoPodatkov_Naslov: TLabel;
    vpLetoPodatkov_Izbor: TSpinEdit;
    ploscicaLetoPodatkov: TPanel;
    gPotrdi: TSpeedButton;
    gPreklici: TSpeedButton;
    procedure gPrekliciClick(Sender: TObject);
    procedure gPotrdiClick(Sender: TObject);
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  fmLetoPodatkov: TfmLetoPodatkov;

implementation

{$R *.dfm}

procedure TfmLetoPodatkov.gPotrdiClick(Sender: TObject);
begin
  ModalResult := 1;
  Close;
  FreeAndNil(fmLetoPodatkov);
end;

procedure TfmLetoPodatkov.gPrekliciClick(Sender: TObject);
begin
  ModalResult := 0;
  Close;
  FreeAndNil(fmLetoPodatkov);
end;

procedure TfmLetoPodatkov.LoadDFMValues;
begin
  inherited LoadDFMValues;

  izLetoPodatkov_Naslov := TLabel.Create(Self);
  vpLetoPodatkov_Izbor := TSpinEdit.Create(Self);
  ploscicaLetoPodatkov := TPanel.Create(Self);
  gPotrdi := TSpeedButton.Create(Self);
  gPreklici := TSpeedButton.Create(Self);

  izLetoPodatkov_Naslov.BeforeLoadDFMValues;
  vpLetoPodatkov_Izbor.BeforeLoadDFMValues;
  ploscicaLetoPodatkov.BeforeLoadDFMValues;
  gPotrdi.BeforeLoadDFMValues;
  gPreklici.BeforeLoadDFMValues;
  try
    Name := 'fmLetoPodatkov';
    Width := 209;
    Height := 104;
    izLetoPodatkov_Naslov.SetParentComponent(Self);
    izLetoPodatkov_Naslov.Name := 'izLetoPodatkov_Naslov';
    izLetoPodatkov_Naslov.AlignWithMargins := True;
    izLetoPodatkov_Naslov.Left := 3;
    izLetoPodatkov_Naslov.Top := 3;
    izLetoPodatkov_Naslov.Width := 203;
    izLetoPodatkov_Naslov.Height := 13;
    izLetoPodatkov_Naslov.Align := alTop;
    izLetoPodatkov_Naslov.Alignment := taCenter;
    izLetoPodatkov_Naslov.Caption := 'LETO OBRA'#268'UNA / PODATKOV';
    izLetoPodatkov_Naslov.Font.Charset := DEFAULT_CHARSET;
    izLetoPodatkov_Naslov.Font.Color := clWindowText;
    izLetoPodatkov_Naslov.Font.Height := -11;
    izLetoPodatkov_Naslov.Font.Name := 'Tahoma';
    izLetoPodatkov_Naslov.Font.Style := [fsBold];
    izLetoPodatkov_Naslov.HeightPercent := 100.000000000000000000;
    izLetoPodatkov_Naslov.ParentFont := False;
    izLetoPodatkov_Naslov.WidthPercent := 100.000000000000000000;
    vpLetoPodatkov_Izbor.SetParentComponent(Self);
    vpLetoPodatkov_Izbor.Name := 'vpLetoPodatkov_Izbor';
    vpLetoPodatkov_Izbor.AlignWithMargins := True;
    vpLetoPodatkov_Izbor.Left := 5;
    vpLetoPodatkov_Izbor.Top := 39;
    vpLetoPodatkov_Izbor.Width := 199;
    vpLetoPodatkov_Izbor.Height := 22;
    vpLetoPodatkov_Izbor.Margins.Left := 5;
    vpLetoPodatkov_Izbor.Margins.Top := 20;
    vpLetoPodatkov_Izbor.Margins.Right := 5;
    vpLetoPodatkov_Izbor.Margins.Bottom := 20;
    vpLetoPodatkov_Izbor.HeightPercent := 100.000000000000000000;
    vpLetoPodatkov_Izbor.WidthPercent := 100.000000000000000000;
    vpLetoPodatkov_Izbor.Align := alTop;
    vpLetoPodatkov_Izbor.AutoSize := False;
    vpLetoPodatkov_Izbor.BorderStyle := bsSingle;
    vpLetoPodatkov_Izbor.ChildOrder := 1;
    vpLetoPodatkov_Izbor.Color := clWhite;
    vpLetoPodatkov_Izbor.Increment := 1;
    vpLetoPodatkov_Izbor.MaxValue := 100;
    vpLetoPodatkov_Izbor.MinValue := 0;
    vpLetoPodatkov_Izbor.Role := '';
    vpLetoPodatkov_Izbor.Value := 0;
    ploscicaLetoPodatkov.SetParentComponent(Self);
    ploscicaLetoPodatkov.Name := 'ploscicaLetoPodatkov';
    ploscicaLetoPodatkov.Left := 0;
    ploscicaLetoPodatkov.Top := 76;
    ploscicaLetoPodatkov.Width := 209;
    ploscicaLetoPodatkov.Height := 28;
    ploscicaLetoPodatkov.HeightPercent := 100.000000000000000000;
    ploscicaLetoPodatkov.WidthPercent := 100.000000000000000000;
    ploscicaLetoPodatkov.Align := alBottom;
    ploscicaLetoPodatkov.BorderColor := clSilver;
    ploscicaLetoPodatkov.BorderStyle := bsNone;
    ploscicaLetoPodatkov.ChildOrder := 2;
    ploscicaLetoPodatkov.Color := clSilver;
    gPotrdi.SetParentComponent(ploscicaLetoPodatkov);
    gPotrdi.Name := 'gPotrdi';
    gPotrdi.AlignWithMargins := True;
    gPotrdi.Left := 3;
    gPotrdi.Top := 3;
    gPotrdi.Width := 100;
    gPotrdi.Height := 22;
    gPotrdi.Align := alLeft;
    gPotrdi.Caption := 'Potrdi';
    gPotrdi.Color := clSilver;
    gPotrdi.Flat := True;
    gPotrdi.Glyph.LoadFromFile('MBI_u07.ploscicaLetoPodatkov.gPotrdi.Glyph.png');
    gPotrdi.HeightPercent := 100.000000000000000000;
    gPotrdi.WidthPercent := 100.000000000000000000;
    SetEvent(gPotrdi, Self, 'OnClick', 'gPotrdiClick');
    gPreklici.SetParentComponent(ploscicaLetoPodatkov);
    gPreklici.Name := 'gPreklici';
    gPreklici.AlignWithMargins := True;
    gPreklici.Left := 106;
    gPreklici.Top := 3;
    gPreklici.Width := 100;
    gPreklici.Height := 22;
    gPreklici.Align := alRight;
    gPreklici.Caption := 'Prekli'#269'i';
    gPreklici.Color := clSilver;
    gPreklici.Flat := True;
    gPreklici.Glyph.LoadFromFile('MBI_u07.ploscicaLetoPodatkov.gPreklici.Glyph.png');
    gPreklici.HeightPercent := 100.000000000000000000;
    gPreklici.WidthPercent := 100.000000000000000000;
    SetEvent(gPreklici, Self, 'OnClick', 'gPrekliciClick');
  finally
    izLetoPodatkov_Naslov.AfterLoadDFMValues;
    vpLetoPodatkov_Izbor.AfterLoadDFMValues;
    ploscicaLetoPodatkov.AfterLoadDFMValues;
    gPotrdi.AfterLoadDFMValues;
    gPreklici.AfterLoadDFMValues;
  end;
end;

end.