unit MBI_u02;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.ExtCtrls, Vcl.Imaging.pngimage,
  Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls, WEBLib.Buttons;

type
  TfmVizitka = class(TForm)
    izNaslov: TLabel;
    slikaLogoModelBI: TImageControl;
    izCopyRight: TLabel;
    izFirma: TLabel;
    izSpletnaStran: TLinkLabel;
    ploscicaVizitka: TPanel;
    izSubvencija: TLabel;
    izElektronskaPosta: TLinkLabel;
    ploscicaGlava: TPanel;
    gZapriVizitko: TSpeedButton;
    izVerzija: TLabel;
    slikaLogoSubvencija1: TImageControl;
    slikaLogoSubvencija2: TImageControl;
    procedure gZapriVizitkoClick(Sender: TObject);
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  fmVizitka: TfmVizitka;

implementation

{$R *.dfm}

procedure TfmVizitka.gZapriVizitkoClick(Sender: TObject);
begin
  Close;
end;

procedure TfmVizitka.LoadDFMValues;
begin
  inherited LoadDFMValues;

  izSubvencija := TLabel.Create(Self);
  slikaLogoSubvencija1 := TImageControl.Create(Self);
  slikaLogoSubvencija2 := TImageControl.Create(Self);
  ploscicaVizitka := TPanel.Create(Self);
  slikaLogoModelBI := TImageControl.Create(Self);
  izCopyRight := TLabel.Create(Self);
  izFirma := TLabel.Create(Self);
  izSpletnaStran := TLinkLabel.Create(Self);
  izElektronskaPosta := TLinkLabel.Create(Self);
  ploscicaGlava := TPanel.Create(Self);
  izNaslov := TLabel.Create(Self);
  izVerzija := TLabel.Create(Self);
  gZapriVizitko := TSpeedButton.Create(Self);

  izSubvencija.BeforeLoadDFMValues;
  slikaLogoSubvencija1.BeforeLoadDFMValues;
  slikaLogoSubvencija2.BeforeLoadDFMValues;
  ploscicaVizitka.BeforeLoadDFMValues;
  slikaLogoModelBI.BeforeLoadDFMValues;
  izCopyRight.BeforeLoadDFMValues;
  izFirma.BeforeLoadDFMValues;
  izSpletnaStran.BeforeLoadDFMValues;
  izElektronskaPosta.BeforeLoadDFMValues;
  ploscicaGlava.BeforeLoadDFMValues;
  izNaslov.BeforeLoadDFMValues;
  izVerzija.BeforeLoadDFMValues;
  gZapriVizitko.BeforeLoadDFMValues;
  try
    Name := 'fmVizitka';
    Width := 589;
    Height := 312;
    Color := clSilver;
    izSubvencija.SetParentComponent(Self);
    izSubvencija.Name := 'izSubvencija';
    izSubvencija.Left := 8;
    izSubvencija.Top := 216;
    izSubvencija.Width := 576;
    izSubvencija.Height := 13;
    izSubvencija.Alignment := taCenter;
    izSubvencija.AutoSize := False;
    izSubvencija.Caption := 'Projekt / operacijo sta sofinancirali Republika Slovenija in Evropska unija iz Evropskega sklada za regionalni razvoj. ';
    izSubvencija.Color := clSilver;
    izSubvencija.HeightPercent := 100.000000000000000000;
    izSubvencija.WidthPercent := 100.000000000000000000;
    slikaLogoSubvencija1.SetParentComponent(Self);
    slikaLogoSubvencija1.Name := 'slikaLogoSubvencija1';
    slikaLogoSubvencija1.Left := 80;
    slikaLogoSubvencija1.Top := 227;
    slikaLogoSubvencija1.Width := 150;
    slikaLogoSubvencija1.Height := 77;
    slikaLogoSubvencija1.HeightPercent := 100.000000000000000000;
    slikaLogoSubvencija1.WidthPercent := 100.000000000000000000;
    slikaLogoSubvencija1.AutoSize := True;
    slikaLogoSubvencija1.ChildOrder := 3;
    slikaLogoSubvencija1.Picture.LoadFromFile('MBI_u02.slikaLogoSubvencija1.Picture.png');
    slikaLogoSubvencija2.SetParentComponent(Self);
    slikaLogoSubvencija2.Name := 'slikaLogoSubvencija2';
    slikaLogoSubvencija2.Left := 355;
    slikaLogoSubvencija2.Top := 246;
    slikaLogoSubvencija2.Width := 175;
    slikaLogoSubvencija2.Height := 50;
    slikaLogoSubvencija2.HeightPercent := 100.000000000000000000;
    slikaLogoSubvencija2.WidthPercent := 100.000000000000000000;
    slikaLogoSubvencija2.AutoSize := True;
    slikaLogoSubvencija2.ChildOrder := 3;
    slikaLogoSubvencija2.Picture.LoadFromFile('MBI_u02.slikaLogoSubvencija2.Picture.png');
    ploscicaVizitka.SetParentComponent(Self);
    ploscicaVizitka.Name := 'ploscicaVizitka';
    ploscicaVizitka.Left := 16;
    ploscicaVizitka.Top := 48;
    ploscicaVizitka.Width := 553;
    ploscicaVizitka.Height := 121;
    ploscicaVizitka.HeightPercent := 100.000000000000000000;
    ploscicaVizitka.WidthPercent := 100.000000000000000000;
    ploscicaVizitka.BorderColor := clSilver;
    ploscicaVizitka.BorderStyle := bsSingle;
    ploscicaVizitka.ChildOrder := 6;
    ploscicaVizitka.Color := clWhite;
    slikaLogoModelBI.SetParentComponent(ploscicaVizitka);
    slikaLogoModelBI.Name := 'slikaLogoModelBI';
    slikaLogoModelBI.Left := 6;
    slikaLogoModelBI.Top := 10;
    slikaLogoModelBI.Width := 181;
    slikaLogoModelBI.Height := 100;
    slikaLogoModelBI.HeightPercent := 100.000000000000000000;
    slikaLogoModelBI.WidthPercent := 100.000000000000000000;
    slikaLogoModelBI.AutoSize := True;
    slikaLogoModelBI.Picture.LoadFromFile('MBI_u02.ploscicaVizitka.slikaLogoModelBI.Picture.png');
    izCopyRight.SetParentComponent(ploscicaVizitka);
    izCopyRight.Name := 'izCopyRight';
    izCopyRight.Left := 216;
    izCopyRight.Top := 16;
    izCopyRight.Width := 179;
    izCopyRight.Height := 16;
    izCopyRight.Caption := #169' 2020 - 2022 by RislkClinic';
    izCopyRight.Font.Charset := DEFAULT_CHARSET;
    izCopyRight.Font.Color := clWindowText;
    izCopyRight.Font.Height := -13;
    izCopyRight.Font.Name := 'Tahoma';
    izCopyRight.Font.Style := [fsBold];
    izCopyRight.HeightPercent := 100.000000000000000000;
    izCopyRight.ParentFont := False;
    izCopyRight.WidthPercent := 100.000000000000000000;
    izFirma.SetParentComponent(ploscicaVizitka);
    izFirma.Name := 'izFirma';
    izFirma.Left := 216;
    izFirma.Top := 46;
    izFirma.Width := 315;
    izFirma.Height := 16;
    izFirma.Caption := 'RiskClinic, poslovno in RM svetovanje, Tadej Avsec s.p.';
    izFirma.Font.Charset := DEFAULT_CHARSET;
    izFirma.Font.Color := clWindowText;
    izFirma.Font.Height := -13;
    izFirma.Font.Name := 'Tahoma';
    izFirma.Font.Style := [];
    izFirma.HeightPercent := 100.000000000000000000;
    izFirma.ParentFont := False;
    izFirma.WidthPercent := 100.000000000000000000;
    izSpletnaStran.SetParentComponent(ploscicaVizitka);
    izSpletnaStran.Name := 'izSpletnaStran';
    izSpletnaStran.Left := 216;
    izSpletnaStran.Top := 90;
    izSpletnaStran.Width := 318;
    izSpletnaStran.Height := 16;
    izSpletnaStran.AutoSize := False;
    izSpletnaStran.Font.Charset := DEFAULT_CHARSET;
    izSpletnaStran.Font.Color := clWindowText;
    izSpletnaStran.Font.Height := -13;
    izSpletnaStran.Font.Name := 'Tahoma';
    izSpletnaStran.Font.Style := [];
    izSpletnaStran.ParentFont := False;
    izSpletnaStran.HeightPercent := 100.000000000000000000;
    izSpletnaStran.WidthPercent := 100.000000000000000000;
    izSpletnaStran.Caption := 'spletna stran <a href="https://www.RiskClinic.si" target="_blank">RiskClinic</a';
    izElektronskaPosta.SetParentComponent(ploscicaVizitka);
    izElektronskaPosta.Name := 'izElektronskaPosta';
    izElektronskaPosta.Left := 216;
    izElektronskaPosta.Top := 68;
    izElektronskaPosta.Width := 268;
    izElektronskaPosta.Height := 16;
    izElektronskaPosta.Font.Charset := DEFAULT_CHARSET;
    izElektronskaPosta.Font.Color := clWindowText;
    izElektronskaPosta.Font.Height := -13;
    izElektronskaPosta.Font.Name := 'Tahoma';
    izElektronskaPosta.Font.Style := [];
    izElektronskaPosta.ParentFont := False;
    izElektronskaPosta.HeightPercent := 100.000000000000000000;
    izElektronskaPosta.WidthPercent := 100.000000000000000000;
    izElektronskaPosta.Caption := 'elektronska po'#353'ta <a href="mailto:name@email.com">tadej.avsec@RiskClinic.si</a';
    ploscicaGlava.SetParentComponent(Self);
    ploscicaGlava.Name := 'ploscicaGlava';
    ploscicaGlava.Left := 0;
    ploscicaGlava.Top := 0;
    ploscicaGlava.Width := 589;
    ploscicaGlava.Height := 28;
    ploscicaGlava.HeightPercent := 100.000000000000000000;
    ploscicaGlava.WidthPercent := 100.000000000000000000;
    ploscicaGlava.Align := alTop;
    ploscicaGlava.BorderColor := clNone;
    ploscicaGlava.BorderStyle := bsNone;
    ploscicaGlava.ChildOrder := 3;
    ploscicaGlava.Color := clGray;
    izNaslov.SetParentComponent(ploscicaGlava);
    izNaslov.Name := 'izNaslov';
    izNaslov.Left := 3;
    izNaslov.Top := 6;
    izNaslov.Width := 78;
    izNaslov.Height := 18;
    izNaslov.Caption := 'O ModelBI';
    izNaslov.Color := clGray;
    izNaslov.Font.Charset := DEFAULT_CHARSET;
    izNaslov.Font.Color := clWhite;
    izNaslov.Font.Height := -15;
    izNaslov.Font.Name := 'Tahoma';
    izNaslov.Font.Style := [fsBold];
    izNaslov.HeightPercent := 100.000000000000000000;
    izNaslov.ParentFont := False;
    izNaslov.Transparent := False;
    izNaslov.WidthPercent := 100.000000000000000000;
    izVerzija.SetParentComponent(ploscicaGlava);
    izVerzija.Name := 'izVerzija';
    izVerzija.Left := 336;
    izVerzija.Top := 6;
    izVerzija.Width := 214;
    izVerzija.Height := 16;
    izVerzija.Alignment := taRightJustify;
    izVerzija.AutoSize := False;
    izVerzija.Caption := 'ModelBI ver. 1.0.0 (06.03.2022)';
    izVerzija.Color := clGray;
    izVerzija.Font.Charset := DEFAULT_CHARSET;
    izVerzija.Font.Color := clWhite;
    izVerzija.Font.Height := -13;
    izVerzija.Font.Name := 'Tahoma';
    izVerzija.Font.Style := [];
    izVerzija.HeightPercent := 100.000000000000000000;
    izVerzija.ParentFont := False;
    izVerzija.Transparent := False;
    izVerzija.WidthPercent := 100.000000000000000000;
    gZapriVizitko.SetParentComponent(ploscicaGlava);
    gZapriVizitko.Name := 'gZapriVizitko';
    gZapriVizitko.Left := 565;
    gZapriVizitko.Top := 0;
    gZapriVizitko.Width := 24;
    gZapriVizitko.Height := 28;
    gZapriVizitko.Align := alRight;
    gZapriVizitko.Caption := 'gZapriVizitko';
    gZapriVizitko.Color := clGray;
    gZapriVizitko.Flat := True;
    gZapriVizitko.Glyph.LoadFromFile('MBI_u02.ploscicaGlava.gZapriVizitko.Glyph.png');
    gZapriVizitko.HeightPercent := 100.000000000000000000;
    gZapriVizitko.WidthPercent := 100.000000000000000000;
    SetEvent(gZapriVizitko, Self, 'OnClick', 'gZapriVizitkoClick');
  finally
    izSubvencija.AfterLoadDFMValues;
    slikaLogoSubvencija1.AfterLoadDFMValues;
    slikaLogoSubvencija2.AfterLoadDFMValues;
    ploscicaVizitka.AfterLoadDFMValues;
    slikaLogoModelBI.AfterLoadDFMValues;
    izCopyRight.AfterLoadDFMValues;
    izFirma.AfterLoadDFMValues;
    izSpletnaStran.AfterLoadDFMValues;
    izElektronskaPosta.AfterLoadDFMValues;
    ploscicaGlava.AfterLoadDFMValues;
    izNaslov.AfterLoadDFMValues;
    izVerzija.AfterLoadDFMValues;
    gZapriVizitko.AfterLoadDFMValues;
  end;
end;

end.
