Unit MBI_u04;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.ExtCtrls, Vcl.StdCtrls, WEBLib.StdCtrls,
  Vcl.Controls, WEBLib.ComCtrls, WEBLib.Buttons, Vcl.Imaging.pngimage, MBI_u05, DateUtils,
  XData.Web.Client, XData.Web.Connection;

type
  TfmSimulacijaSkode = class(TForm)
    ploscicaIkoneZgoraj: TPanel;
    gIzhod: TSpeedButton;
    plosicaG5: TPanel;
    g5: TSpeedButton;
    ploscicaG4: TPanel;
    g4: TSpeedButton;
    ploscicaG3: TPanel;
    g3: TSpeedButton;
    ploscicaG2: TPanel;
    g2: TSpeedButton;
    ploscicaIkoneSpodaj: TPanel;
    ploscicaGSimulacije: TPanel;
    gSimulacije: TSpeedButton;
    SimulacijaSkode_Listi: TPageControl;
    SimulacijaSkode_List1: TTabSheet;
    izNaslovList1: TLabel;
    ploscicaIkoneList1: TPanel;
    ploscicaGNaprej1: TPanel;
    gNaprej1: TSpeedButton;
    ploscicaTabelaNepokritiStroski: TGridPanel;
    ploscicaTabelaNepokritiStroski_Naslov1: TLabel;
    ploscicaTabelaNepokritiStroski_Povzetek: TGridPanel;
    IzpadPrometaTekst: TLabel;
    izIzpadPrometa: TEdit;
    IzpadPrometaPrazno: TPanel;
    ZavarovaniIzpadPrometaTekst: TLabel;
    izZavarovaniIzpadPrometa: TEdit;
    ZavarovaniIzpadPrometaPrazno: TPanel;
    slikaIzpadPrometa: TImageControl;
    NepokritiZavarovaniIzpadPrometaTekst: TLabel;
    izNepokritiZavarovaniIzpadPrometa: TEdit;
    NepokritiZavarovaniIzpadPrometaPrazno: TPanel;
    SimulacijaSkode_List2: TTabSheet;
    izNaslovList2: TLabel;
    ploscicaIkoneList2: TPanel;
    ploscicaGNaprej2: TPanel;
    gNaprej2: TSpeedButton;
    ploscicaGNazaj2: TPanel;
    gNazaj2: TSpeedButton;
    ploscicaPovisaniPoslovniStroski: TGridPanel;
    PovisaniPoslovniStrskiTekst: TLabel;
    izPovisaniPoslovniStroski: TEdit;
    ResenPrometTekst: TLabel;
    izResenPromet: TEdit;
    EkonomskoUpraviceniPovisaniStroskiTekst: TLabel;
    izEkonomskoUpraviceniStroski: TEdit;
    SimulacijaSkode_List3: TTabSheet;
    izNaslovList3: TLabel;
    ploscicaIkoneList3: TPanel;
    ploscicaGNaprej3: TPanel;
    gNaprej3: TSpeedButton;
    ploscicaGNazaj3: TPanel;
    gNazaj3: TSpeedButton;
    ploscicaPregledPrihrankov: TGridPanel;
    ploscicaTabelaNepokritiStroski_Naslov7: TLabel;
    ploscicaTabelaNepokritiStroski_Naslov8: TLabel;
    ploscicaTabelaNepokritiStroski_Naslov9: TLabel;
    ploscicaTabelaNepokritiStroski_Naslov10: TLabel;
    ploscicaPregledPrihrankov_Povzetek: TGridPanel;
    PrijavljeniPrihrankiTekst: TLabel;
    izPrijavljeniPrihranki: TEdit;
    DejanskiPrihrankiTekst: TLabel;
    izDejanskiPrihranki: TEdit;
    SimulacijaSkode_List4: TTabSheet;
    izNaslovList4: TLabel;
    ploscicaIkoneList4: TPanel;
    ploscicaGNaprej4: TPanel;
    gNaprej4: TSpeedButton;
    ploscicaGNazaj4: TPanel;
    gNazaj4: TSpeedButton;
    ploscicaTabelaIzhodiscaLetniPrometMeseci: TGridPanel;
    MesecPredZastojem_1: TEdit;
    PrometPredZastojem_1: TEdit;
    MesecPredZastojem_2: TEdit;
    PrometPredZastojem_2: TEdit;
    MesecPredZastojem_3: TEdit;
    PrometPredZastojem_3: TEdit;
    MesecPredZastojem_4: TEdit;
    PrometPredZastojem_4: TEdit;
    MesecPredZastojem_5: TEdit;
    PrometPredZastojem_5: TEdit;
    MesecPredZastojem_6: TEdit;
    PrometPredZastojem_6: TEdit;
    MesecPredZastojem_7: TEdit;
    PrometPredZastojem_7: TEdit;
    MesecPredZastojem_8: TEdit;
    PrometPredZastojem_8: TEdit;
    MesecPredZastojem_9: TEdit;
    PrometPredZastojem_9: TEdit;
    MesecPredZastojem_10: TEdit;
    PrometPredZastojem_10: TEdit;
    MesecPredZastojem_11: TEdit;
    PrometPredZastojem_11: TEdit;
    MesecPredZastojem_12: TEdit;
    PrometPredZastojem_12: TEdit;
    ploscicaTabelaIzhodiscaLetniPrometSkupaj: TGridPanel;
    izIzhodiscaLetniPrometTekst: TLabel;
    izIzhodiscaLetniPromet: TEdit;
    ploscicaPodzavarovanje: TGridPanel;
    izLetniPrometOsnovaTekst: TLabel;
    izLetniPrometOsnova: TEdit;
    PodzavarovanjePrazno1: TPanel;
    izLetniPrometRastTekst: TLabel;
    izLetniPrometRast: TEdit;
    PodzavarovanjePrazno2: TPanel;
    izLetniPrometTekst: TLabel;
    izLetniPromet: TEdit;
    PodzavarovanjePrazno3: TPanel;
    izPotrebnaZVTekst: TLabel;
    izPotrebnaZV: TEdit;
    PodzavarovanjePrazno4: TPanel;
    izDejanskaZVTekst: TLabel;
    izDejanskaZV: TEdit;
    PodzavarovanjePrazno5: TPanel;
    slikaZV: TImageControl;
    izPodzavarovanjeTekst: TLabel;
    izPodzavarovanje: TEdit;
    PodzavarovanjePrazno6: TPanel;
    SimulacijaSkode_List5: TTabSheet;
    izNaslovList5: TLabel;
    ploscicaIkoneList5: TPanel;
    ploscicaGNazaj5: TPanel;
    gNazaj5: TSpeedButton;
    ploscicaTabelaIzracunZavarovalnine: TGridPanel;
    izRezultatIzpadPrometaTekst: TLabel;
    izRezultatIzpadPrometa: TEdit;
    ploscicaZavarovalninaPrazna_1: TPanel;
    slikaZavarovalnina_1: TImageControl;
    izRezultatPovisaniStroskiTekst: TLabel;
    izRezultatPovisaniStroski: TEdit;
    ploscicaZavarovalninaPrazna_2: TPanel;
    ploscicaZavarovalninaPrazna_3a: TPanel;
    izRezultatIzpadInStroski: TEdit;
    ploscicaZavarovalninaPrazna_3b: TPanel;
    izRezultatPrihrankiTekst: TLabel;
    izRezultatPrihranki: TEdit;
    ploscicaZavarovalninaPrazna_4: TPanel;
    ploscicaZavarovalninaPrazna_5a: TPanel;
    izRezultatZavarovanaSkoda: TEdit;
    ploscicaZavarovalninaPrazna_5b: TPanel;
    izRezultatPodzavarovanjeTekst: TLabel;
    izRezultatPodzavarovanje: TEdit;
    ploscicaZavarovalninaPrazna_6: TPanel;
    slikaZavarovalnina_2: TImageControl;
    izRezultatZavarovalninaTekst: TLabel;
    izRezultatZavarovalnina: TEdit;
    ploscicaZavarovalninaPrazna_7: TPanel;
    slikaZavarovalnina_3: TImageControl;
    izRezultatDejanskoOcenjenaSkodaTekst: TLabel;
    izRezultatDejanskoOcenjenaSkoda: TEdit;
    ploscicaZavarovalninaPrazna_8: TPanel;
    ploscicaG1: TPanel;
    g1: TSpeedButton;
    izKoraki: TLabel;
    ploscicaNaslov: TPanel;
    izNaslov: TLabel;
    izSimulacijaNogaTekstMDJ: TLabel;
    izSimulacijaNogaMDJ: TLabel;
    WebGridPanel1: TGridPanel;
    izSimulacijaNogaTekstPrihranki: TLabel;
    izSimulacijaNogaPrihranki: TLabel;
    izSimulacijaNogaTekstRast: TLabel;
    izSimulacijaNogaRast: TLabel;
    izSimulacijaNogaTekstZV: TLabel;
    izSimulacijaNogaZV: TLabel;
    izSimulacijaNogaTekstStroski: TLabel;
    izSimulacijaNogaStroski: TLabel;
    PovezavaWSIzracunSkode: TXDataWebConnection;
    WebClientIzracunSkode: TXDataWebClient;
    ploscicaTabelaNepokritiStroski_Naslov2: TMemo;
    ploscicaTabelaNepokritiStroski_Naslov3: TMemo;
    ploscicaTabelaNepokritiStroski_Naslov4: TMemo;
    ploscicaTabelaNepokritiStroski_Naslov5: TMemo;
    ploscicaTabelaNepokritiStroski_Naslov6: TMemo;
    ploscicaTabelaNepokritiStroski_Naslov11: TMemo;
    ploscicaTabelaNepokritiStroski_Naslov12: TMemo;
    izSimulacijaNogaTekstPremija: TLabel;
    izSimulacijaNogaPremija: TLabel;
    procedure gIzhodClick(Sender: TObject);
    procedure gNaprejNazajClick(Sender: TObject);
    procedure gumbKorakClick(Sender: TObject);
    procedure UpravicenostPrihranka(Sender: TObject);
    procedure gSimulacijeClick(Sender: TObject);
    procedure gPonastaviClick(Sender: TObject);
    procedure PovezavaWSIzracunSkodeError(Error: TXDataWebConnectionError);
    procedure PovezavaWSIzracunSkodeConnect(Sender: TObject);
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  fmSimulacijaSkode: TfmSimulacijaSkode;
  // za potrebe simulacije zaavrovalne premije
  nesimuliranaPremija, nesimuliranaZV, simuliranaZV : Real;
  nesimuliranMDJ, simuliranMDJ, simuliranaVrednost : Integer;

implementation

uses MBI_u01;

{$R *.dfm}


function PreracunPrihrankov (j : Integer; visinaPrihranka, delezPrihranka : Array of Real) : Real;
var skupniPrihranek : Real;
    i : Integer;
begin
  skupniPrihranek := 0;
  // seštevanje priznanih prihrankov (z upoštevanjem deleža)
  for i := 1 to j do begin
    skupniPrihranek := skupniPrihranek + ( visinaPrihranka[i] * delezPrihranka[i] / 100 );
  end;
  // prepis v rezultat funkcije
  Result := skupniPrihranek;
end;


procedure TfmSimulacijaSkode.gumbKorakClick(Sender: TObject);
begin
  SimulacijaSkode_Listi.TabIndex := StrToInt(RightStr((Sender As TSpeedButton).Name, 1)) - 1;
end;

procedure TfmSimulacijaSkode.gIzhodClick(Sender: TObject);
begin
  Close;
  FreeAndNil(fmSimulacijaSkode);
end;

procedure TfmSimulacijaSkode.gNaprejNazajClick(Sender: TObject);
var gumb : TSpeedButton;
begin

  if LeftStr((Sender As TSpeedButton).Name, Length((Sender As TSpeedButton).Name)-1) = 'gNaprej' then begin
    SimulacijaSkode_Listi.TabIndex := SimulacijaSkode_Listi.TabIndex + 1;
    gumb := FindComponent('g' + IntToStr(SimulacijaSkode_Listi.TabIndex + 1)) As TSpeedButton;
    gumb.Glyph.LoadFromFile('img_korak'+IntToStr(SimulacijaSkode_Listi.TabIndex + 1)+'.png');
    gumb.Enabled := true;
  end
  else if LeftStr((Sender As TSpeedButton).Name,Length((Sender As TSpeedButton).Name)-1) = 'gNazaj' then begin
    SimulacijaSkode_Listi.TabIndex := SimulacijaSkode_Listi.TabIndex - 1;
  end;
end;

procedure TfmSimulacijaSkode.gPonastaviClick(Sender: TObject);
begin
   // vračanje začetnih vrednosti (tj. nuliranje vseh simulacij) za spremenljivko arhivIzborSimulacij in arhivVrednostiZadnjeSimulacije (vrednosti za slednjo so določene na osnovi arhivVrednostizacetneSimulacije iz osnovne proceduri)
   arhivIzborovSimulacije.simulacijaZV := false;
   arhivIzborovSimulacije.simulacijaMDJ := false;
   arhivIzborovSimulacije.simulacijaPrihranki := false;
   arhivIzborovSimulacije.simulacijaPovisaniStroski := false;
   arhivIzborovSimulacije.simulacijaRast := false;
   arhivVrednostiZadnjeSimulacije.simulacijaZV := arhivVrednostiZacetneSimulacije.simulacijaZV;
   arhivVrednostiZadnjeSimulacije.simulacijaMDJ := arhivVrednostiZacetneSimulacije.simulacijaMDJ;
   arhivVrednostiZadnjeSimulacije.simulacijaPovisaniStroski := arhivVrednostiZacetneSimulacije.simulacijaPovisaniStroski;
   arhivVrednostiZadnjeSimulacije.simulacijaPrihranki := arhivVrednostiZacetneSimulacije.simulacijaPrihranki;
   arhivVrednostiZadnjeSimulacije.simulacijaRast := arhivVrednostiZacetneSimulacije.simulacijaRast;

   // Manjka procedura za preračun
end;

procedure TfmSimulacijaSkode.gSimulacijeClick(Sender: TObject);
var novoOkno : TfmSimulacije;

   procedure ObOdprtjuShowModal(AForm: TObject);  // Procedura, ki se zažene ob zagonu okna / forme fmSimulacije
   var x, y : Integer;
   begin
     x := Window.InnerWidth;
     y := Window.InnerHeight;
     novoOkno.Left := Round((x / 2) + (Width / 2) - novoOkno.Width - 3);
     novoOkno.top :=  Round((Y / 2) + (Height / 2) - novoOkno.Height - 33);

     with novoOkno Do begin
         izSimulacijeNaslov.Caption := LjezikSimulacija_Naslov;
         izSpremembaZV.Caption := LjezikSimulacija_ZV;
         izSpremembaMDJ.Caption:= LjezikSimulacija_MDJ;
         izSpremembaMDJtekst.Caption := LjezikSteviloMesecev;
         izSpremembaObdobjaResenegaPrometa.Caption := LjezikSimulacija_ObdobjeResenegaPrometa;
         izSpremembaObdobjaPrihrankov.Caption := LjezikSimulacija_ObdobjePrihrankov;
         izSpremembaPricakovaneRasti.Caption := LjezikSimulacija_PricakovanaRast;
         gSpremeni.Caption := LjezikSimulacija_gSpremeni;
         gPonastavi.Caption := LjezikSimulacija_gPonastavi;
         gpreklici.caption := LjezikSimulacija_gPreklici;
         vpSpremembaObdobjaResenegaPrometa.Items[0] := fmOsnovniKontroler.vpPovisaniPoslovniStroskiObdobje.Items[0];
         vpSpremembaObdobjaResenegaPrometa.Items[1] := fmOsnovniKontroler.vpPovisaniPoslovniStroskiObdobje.Items[1];
         vpSpremembaObdobjaPrihrankov.Items[0] := fmOsnovniKontroler.vpPrihrankiObdobje.Items[0];
         vpSpremembaObdobjaPrihrankov.Items[1] := fmOsnovniKontroler.vpPrihrankiObdobje.Items[1];
         izSpremembaMDJtekst.Font.Color := clGray;
     end;
     with novoOkno do begin
       cbSpremembaZV.Checked := arhivIzborovSimulacije.simulacijaZV;
       if cbSpremembaZV.Checked = true then
         vpSpremembaZV.Enabled := true
       else
         vpSpremembaZV.Enabled := false;
       cbSpremembaMDJ.Checked:= arhivIzborovSimulacije.simulacijaMDJ;
       if cbSpremembaMDJ.Checked = true then
         vpSpremembaMDJ.Enabled := true
       else
         vpSpremembaMDJ.Enabled := false;
       cbSpremembaObdobjaResenegaPrometa.Checked := arhivIzborovSimulacije.simulacijaPovisaniStroski;
       if cbSpremembaObdobjaresenegaPrometa.Checked = true then
         vpSpremembaObdobjaresenegaPrometa.Enabled := true
       else
         vpSpremembaObdobjaResenegaPrometa.Enabled := false;
       cbSpremembaObdobjaPrihrankov.Checked := arhivIzborovSimulacije.simulacijaPrihranki;
       if cbSpremembaObdobjaPrihrankov.Checked = true then
         vpSpremembaObdobjaPrihrankov.Enabled := true
       else
         vpSpremembaObdobjaPrihrankov.Enabled := false;
       cbSpremembaPricakovaneRasti.Checked := arhivIzborovSimulacije.simulacijaRast;
       if cbSpremembaPricakovaneRasti.Checked = true then
         vpSpremembaPricakovaneRasti.Enabled := true
       else
         vpSpremembaPricakovaneRasti.Enabled := false;
       vpSpremembaZV.Text := arhivVrednostiZadnjeSimulacije.simulacijaZV;
       vpSpremembaMDJ.ItemIndex := arhivVrednostiZadnjeSimulacije.simulacijaMDJ;
       vpSpremembaObdobjaResenegaPrometa.ItemIndex := arhivVrednostiZadnjeSimulacije.simulacijaPovisaniStroski;
       vpSpremembaObdobjaPrihrankov.ItemIndex := arhivVrednostiZadnjeSimulacije.simulacijaPrihranki;
       vpSpremembaPricakovaneRasti.Position := arhivVrednostiZadnjeSimulacije.simulacijaRast;
       izSpremembaPricakovaneRasti_Tekst.Caption := FormatFloat('###,###,##0.00', vpSpremembaPricakovaneRasti.Position / 10) + ' %';
     end;
   end;

   procedure PoZaprtjuShowModal(AValue: TModalResult);  // procedura, ki se zažene ob zapiranju okna / forme fmSimulacije
   var i, j, vrstica, stolpec, stolpecZacetni : Integer;
       steviloPrihrankov : Integer;
       vnosnoPolje, vnosnoPolje2 : TEdit;
       zapis : TLabel;
       slika : TImageControl;
       polje1, polje2 : TComboBox;
       polje3 : TEdit;
       dan, mesec, leto : Word;
       mesecSkode, letoSkode, letoPrej : Integer;
       tekst, tekst2 : String;
       prometPlanVZastojuM : array of Real;
       prometRealizacijaVZastojuM : array of Real;
       planiranPrometVZastojuM : array of Real;
       prometPredSkodoM : array of Real;

   begin

    if (AValue = 1) or (AValue = 2) then begin
     fmOsnovniKontroler.Cursor := crHourGlass;
     try

       // Definira velikost vseh tabel na 12 (mesecev), kar je najvišja vrednost in jih napolni z ničlami, da se ne ugodi, da je katera brez vrednosti
       SetLength(prometPlanVZastojuM, 12);
       SetLength(prometRealizacijaVZastojuM, 12);
       SetLength(planiranPrometvZastojuM, 12);
       SetLength(prometPredSkodoM, 12);
       for i := 0 to 11 do begin
         prometPlanVZastojuM[i] := 0;
         prometRealizacijaVZastojuM[i] := 0;
         planiranPrometvZastojuM[i] := 0;
         prometPredSkodoM[i] := 0;
       end;

       // izračun koliko mesecev je trajal zastoj (prešteje celice, ki so obarvane)
       mesecevSkode := 0;
       for i := 2 to 4 do begin
          for j := 1 to 12 do begin
            with fmOsnovniKontroler do begin
              vnosnoPolje := FindComponent('ploscicaPromet_Promet_'+IntToStr(i)+'_'+IntToStr(j)) as TEdit;
              if vnosnoPolje.Color = clWebCornSilk then
                 mesecevSkode := mesecevSkode + 1;
            end;
          end;
       end;

       // preveri dan in mesec začetka zavarovanja (tj. zavarovalnega leta), akr je potrebno, da se da ugotoviti, kateri mesec je izbran iz spustnega seznama
       with fmOsnovniKontroler do begin
         dan := DayOf(vpZavarovanjeOd.Date);
         mesec := MonthOf(vpZavarovanjeOd.Date);
         leto := YearOf(vpZavarovanjeOd.Date);
         // Če dan začetka zavarovanja ni 1. dan v mesecu, se lahko škoda simulira šele naslednji mesec (1.)
         if dan > 1 then
           mesec := mesec + 1;
         // določi, kateri mesec je iz spustnega seznama izbran za začetek škode
         mesecSkode := vpDatumFizicneSkode.ItemIndex + mesec;
         if mesecSkode > 12 then begin
           mesecSkode := mesecSkode - 12;
           stolpec := 3;
           // letoSkode := leto + 1;
         end
         else begin
           mesecSkode := mesecSkode;
           stolpec := 2;
           // letoSkode := leto;
         end;
       end;

       // TABELA NEPOKRITIH STROŠKOV - zavihek 1
       // Poljnjenje polj z vrednostmi

       vrstica := mesecSkode;

       with novoOkno do begin
         if cbSpremembaMDJ.Checked = true then begin
             dogovorjenaMDJ := StrToInt(StringReplace(vpSpremembaMDJ.Text, ' mesece(v)' , '' , [rfreplaceAll, rfIgnorecase]));
             izSimulacijaNogaMDJ.Caption := vpSpremembaMDJ.Text + ' ' + LjezikSteviloMesecev +' [' + LjezikSimulacija_nogaS + ']'
         end
         else begin
            dogovorjenaMDJ := StrToInt(StringReplace(fmOsnovniKontroler.izMaksimalnaDobaJamcenja.Text, ' mesece(v)' , '' , [rfreplaceAll, rfIgnorecase]));
            izSimulacijaNogaMDJ.Caption := fmOsnovniKontroler.izMaksimalnaDobaJamcenja.Text + ' ' + LjezikSteviloMesecev + ' [' + LjezikSimulacija_nogaD + ']'
         end;
       end;

       for i := 1 to mesecevSkode do Begin
         if vrstica > 12 then begin
           stolpec := stolpec + 1;
           vrstica := vrstica - 12;
         end;

         // prepis prometa iz leta pred škodo
         with fmOsnovniKontroler do begin
           vnosnoPolje := FindComponent('ploscicaPromet_Promet_'+IntToStr(stolpec-1)+'_'+IntToStr(vrstica)) as TEdit;
           tekst := vnosnoPolje.Text;
         end;
         with SimulacijaSkode_List1 do begin
           vnosnoPolje2 := FindComponent('VnosnoPolje_'+IntToStr(i)+'_'+IntToStr(1)) as TEdit;
           vnosnoPolje2.Text := tekst;
         end;
         // prepis dejanskega prometa v času zastoja
         with fmOsnovniKontroler do begin
          vnosnoPolje := FindComponent('ploscicaPromet_Promet_'+IntToStr(stolpec)+'_'+IntToStr(vrstica)) as TEdit;
          tekst := vnosnoPolje.Text;
         end;
         with SimulacijaSkode_List1 do begin
           vnosnoPolje2 := FindComponent('VnosnoPolje_'+IntToStr(i)+'_'+IntToStr(3)) as TEdit;
           vnosnoPolje2.Text := tekst;
         end;
         // prepis planiranega prometa za čas zastoja (iz tabele na listu Analiza prodaje
         with fmOsnovniKontroler do begin
           zapis := FindComponent('ploscicaAnalizeProdaje_izPromet_'+IntToStr(stolpec)+'_'+IntToStr(vrstica)) as Tlabel;
           tekst := zapis.Caption;
         end;
         with SimulacijaSkode_List1 do begin
           vnosnoPolje2 := FindComponent('VnosnoPolje_'+IntToStr(i)+'_'+IntToStr(2)) as TEdit;
           vnosnoPolje2.Text := tekst;
         end;

         // Izračun izpada prometa v času zastoja
         with SimulacijaSkode_List1 do begin
           vnosnoPolje := FindComponent('VnosnoPolje_'+IntToStr(i)+'_'+IntToStr(2)) as TEdit;
           tekst := vnosnoPolje.Text;
           tekst := StringReplace(tekst, '.', '', [rfreplaceAll, rfIgnorecase]);
           vnosnoPolje2 := FindComponent('VnosnoPolje_'+IntToStr(i)+'_'+IntToStr(3)) as TEdit;
           tekst2 := vnosnoPolje2.Text;
           tekst2 := StringReplace(tekst2, '.', '', [rfreplaceAll, rfIgnorecase]);
           vnosnoPolje := FindComponent('VnosnoPolje_'+IntToStr(i)+'_'+IntToStr(4)) as TEdit;
           vnosnoPolje.Text := FormatFloat('###,###,##0.00', StrToFloat(tekst2) - StrToFloat(tekst));

           prometPlanVZastojuM[i-1] := StrToFloat(tekst);
           prometRealizacijaVZastojuM[i-1] := StrToFloat(tekst2);

           if i <= dogovorjenaMDJ then begin      // če je izpad krajši ali enak dogovorjeni MDJ
              slika := FindComponent('SlikaKritje_'+IntToStr(i)) as TImageControl;
              slika.Picture.LoadFromFile('img_OK.png');
           end
           else begin                             // če je izpad daljši od dogovorjene MDJ (tj. zavarovanje ne pokrije celotne dobe)
             slika := FindComponent('SlikaKritje_'+IntToStr(i)) as TImageControl;
             slika.Picture.LoadFromFile('img_close_2.png');
           end;
         end;
         vrstica := vrstica + 1;
       end;

       prometPlanVZastojuM1 := prometPlanVZastojuM[0] * 100;
       prometPlanVZastojuM2 := prometPlanVZastojuM[1] * 100;
       prometPlanVZastojuM3 := prometPlanVZastojuM[2] * 100;
       prometPlanVZastojuM4 := prometPlanVZastojuM[3] * 100;
       prometPlanVZastojuM5 := prometPlanVZastojuM[4] * 100;
       prometPlanVZastojuM6 := prometPlanVZastojuM[5] * 100;
       prometPlanVZastojuM7 := prometPlanVZastojuM[6] * 100;
       prometPlanVZastojuM8 := prometPlanVZastojuM[7] * 100;
       prometPlanVZastojuM9 := prometPlanVZastojuM[8] * 100;
       prometPlanVZastojuM10 := prometPlanVZastojuM[9] * 100;
       prometPlanVZastojuM11 := prometPlanVZastojuM[10] * 100;
       prometPlanVZastojuM12 := prometPlanVZastojuM[11] * 100;

       prometRealizacijaVZastojuM1 := prometRealizacijaVZastojuM[0] * 100;
       prometRealizacijaVZastojuM2 := prometRealizacijaVZastojuM[1] * 100;
       prometRealizacijaVZastojuM3 := prometRealizacijaVZastojuM[2] * 100;
       prometRealizacijaVZastojuM4 := prometRealizacijaVZastojuM[3] * 100;
       prometRealizacijaVZastojuM5 := prometRealizacijaVZastojuM[4] * 100;
       prometRealizacijaVZastojuM6 := prometRealizacijaVZastojuM[5] * 100;
       prometRealizacijaVZastojuM7 := prometRealizacijaVZastojuM[6] * 100;
       prometRealizacijaVZastojuM8 := prometRealizacijaVZastojuM[7] * 100;
       prometRealizacijaVZastojuM9 := prometRealizacijaVZastojuM[8] * 100;
       prometRealizacijaVZastojuM10 := prometRealizacijaVZastojuM[9] * 100;
       prometRealizacijaVZastojuM11 := prometRealizacijaVZastojuM[10] * 100;
       prometRealizacijaVZastojuM12 := prometRealizacijaVZastojuM[11] * 100;


       // POVIŠANI POSLOVNI STROŠKI - zavihek 2
       izPovisaniPoslovniStroski.Text := fmOsnovniKontroler.vpPovisaniPoslovniStroskiZnesek.Text;
       izResenPromet.Text := fmOsnovniKontroler.vpResenPrometZnesek.Text;
       EkonomskoUpraviceniPovisaniStroskiTekst.Caption := 'Ekonomsko upravičeni povišani stroški (ob stopnji kosmatega dobička ' + fmOsnovniKontroler.vpVnosFRPStopnjaKosmategaDobicka.Caption + ' ):';
       // preračun
       povisaniStroski :=  StrToFloat(StringReplace(izPovisaniPoslovniStroski.Text, '.', '', [rfreplaceAll, rfIgnorecase])) * 100;
       resenPromet :=  StrToFloat(StringReplace(izResenPromet.Text, '.', '', [rfreplaceAll, rfIgnorecase])) * 100;
       delezKosmategaDobicka := StrToFloat(StringReplace(fmOsnovniKontroler.vpVnosFRPStopnjaKosmategaDobicka.Caption, ' %', '', [rfreplaceAll, rfIgnorecase])) * 100;

       // definiran ali se uporabljajo simulirane ali dejanske vrednosti
       with novoOkno do begin
         if cbSpremembaObdobjaResenegaPrometa.Checked = true then begin    // simulirane vrednosti
             obdobjePovisanihStroskov := vpSpremembaObdobjaResenegaPrometa.ItemIndex;
             izSimulacijaNogaStroski.Caption := vpSpremembaObdobjaResenegaPrometa.Text + ' [' + LjezikSimulacija_nogaS + ']';
         end
         else begin
            obdobjePovisanihStroskov := fmOsnovniKontroler.vpPovisaniPoslovniStroskiObdobje.ItemIndex;  // dejanske (vnešene) vrednosati
            izSimulacijaNogaStroski.Caption := fmOsnovniKontroler.vpPovisaniPoslovniStroskiObdobje.Text + ' [' + LjezikSimulacija_nogaD +']';
         end;
       end;


       // TABELA prihranki - zavihek 3
       // prešteje število vrstic s prihranki (gleda le tiste, kjer je vnesen znesek (ne šteje opisov brez zneskov)
       steviloPrihrankov := 0;
       with fmOsnovniKontroler do begin
          for i := 1 to 10 do begin
             vnosnoPolje := FindComponent('ploscicaPrihranki_Prihranek_'+IntToStr(2)+'_'+IntToStr(i)) as TEdit;
             if vnosnoPolje.Text <> '' then
                steviloPrihrankov := steviloPrihrankov + 1;
          end;
       end;

       prijavljeniPrihranki := 0;
       with SimulacijaSkode_List3 do begin
         for i := 1 to steviloPrihrankov do begin
           polje1 := FindComponent('vpUpravicenostPrihranka_'+IntToStr(i)) as TComboBox;
           if polje1.ItemIndex =0 then begin

             polje2 := FindComponent('vpVisinaUpravicenostiPrihranka_'+IntToStr(i)) as TComboBox;
             polje3 := FindComponent('izVisinaPrihranka_'+IntToStr(i)) as TEdit;

             prijavljeniPrihranki := prijavljeniPrihranki + ( StrToFloat(StringReplace(polje3.Text, '.', '', [rfreplaceAll, rfIgnorecase])) * StrToFloat(StringReplace(polje2.Text, ' %', '', [rfreplaceAll, rfIgnorecase])) / 100);

           end;
         end;
       end;


       // definiran ali se uporabljajo simulirane ali dejanske vrednosti
       with novoOkno do begin
         if cbSpremembaObdobjaPrihrankov.Checked = true then begin    // simulirane vrednosti
           obdobjePrihrankov := vpSpremembaObdobjaPrihrankov.ItemIndex;
           izSimulacijaNogaPrihranki.Caption := vpSpremembaObdobjaPrihrankov.Text + ' [' + LjezikSimulacija_nogaS +']';
         end
         else begin
           obdobjePrihrankov := fmOsnovniKontroler.vpPrihrankiObdobje.ItemIndex;  // dejanske (vnešene) vrednosati
           izSimulacijaNogaPrihranki.Caption := fmOsnovniKontroler.vpPrihrankiObdobje.Text + ' [' + LjezikSimulacija_nogaD + ']';
         end;
       end;
       prijavljeniPrihranki := prijavljeniPrihranki * 100;
       ploscicaPregledPrihrankov_Povzetek.Top := ploscicaPregledPrihrankov.top + ploscicaPregledPrihrankov.Height + 15;


       // TABELA podzavarovanje - zavihek 4
       // preveri dan in mesec začetka zavarovanja (tj. zavarovalnega leta), akr je potrebno, da se da ugotoviti, kateri mesec je izbran iz spustnega seznama
       with fmOsnovniKontroler do begin
         dan := DayOf(vpZavarovanjeOd.Date);
         mesec := MonthOf(vpZavarovanjeOd.Date);
         leto := YearOf(vpZavarovanjeOd.Date);
         // Če dan začetka zavarovanja ni 1. dan v mesecu, se lahko škoda simulira šele naslednji mesec (1.)
         if dan > 1 then
           mesec := mesec + 1;
         // določi, kateri mesec je iz spustnega seznama izbran za začetek škode
         mesecSkode := vpDatumFizicneSkode.ItemIndex + mesec;
         if mesecSkode > 12 then begin
           mesecSkode := mesecSkode - 12;
           stolpec := 3;
           stolpecZacetni := 3;
           leto := leto + 1;
         end
         else begin
           mesecSkode := mesecSkode;
           stolpec := 2;
           stolpecZacetni := 2;
           letoSkode := leto;
         end;
       end;

      // Izračuna planiran promet v obdobju 12 mesecev od začetka zastoja
       vrstica := mesecSkode;
       for i := 1 to 12 do Begin
         if vrstica > 12 then begin
           stolpec := stolpec + 1;
           vrstica := vrstica - 12;
         end;
         // seštevanje planiranega prometa za čas 12 mesecev od začetka zastoja (iz tabele na listu Analiza prodaje
         with fmOsnovniKontroler do begin
           zapis := FindComponent('ploscicaAnalizeProdaje_izPromet_'+IntToStr(stolpec)+'_'+IntToStr(vrstica)) as TLabel;
           tekst := zapis.Caption;
           planiranPrometvZastojuM[i-1] := StrTOFloat(StringReplace(tekst, '.', '', [rfreplaceAll, rfIgnorecase]));
           vrstica := vrstica + 1;
         end;
       end;

       // Napolni tabelo s prometom v zadnjih 12 mesecih nepsoredno pred fizično škodo
       stolpec := stolpecZacetni;
       for i := 1 to 12 do begin
        vrstica := mesecSkode - i;
        letoPrej := 0;
         if vrstica < 1 then begin  // če je manjše od 1, mora program pobrati še podatke iz predhodnega leta
           vrstica := vrstica + 12;
           stolpec := stolpecZacetni - 1;
           letoPrej := -1 ;
         end;
         with fmOsnovniKontroler do begin
           zapis := FindComponent('ploscicaPromet_Mesec'+IntToStr(vrstica)) as TLabel;
           tekst := zapis.Caption;
         end;
         vnosnoPolje := FindComponent('MesecPredZastojem_'+IntToStr(i)) as TEdit;
         vnosnoPolje.Text := tekst + ', ' + IntToStr(letoSkode + letoPrej);
         with fmOsnovniKontroler do begin
           vnosnoPolje2 := FindComponent('ploscicaPromet_Promet_'+IntToStr(stolpec)+'_'+IntToStr(vrstica)) as TEdit;
           tekst := vnosnoPolje2.Text;
         end;
         vnosnoPolje := FindComponent('PrometPredZastojem_'+IntToStr(i)) as TEdit;
         vnosnoPolje.Text := tekst;
         prometPredSkodoM[i-1] := StrToFloat(StringReplace(tekst, '.', '', [rfreplaceAll, rfIgnorecase]));
       end;

       planiranPrometvZastojuM1 := planiranPrometvZastojuM[0] * 100;
       planiranPrometvZastojuM2 := planiranPrometvZastojuM[1] * 100;
       planiranPrometvZastojuM3 := planiranPrometvZastojuM[2] * 100;
       planiranPrometvZastojuM4 := planiranPrometvZastojuM[3] * 100;
       planiranPrometvZastojuM5 := planiranPrometvZastojuM[4] * 100;
       planiranPrometvZastojuM6 := planiranPrometvZastojuM[5] * 100;
       planiranPrometvZastojuM7 := planiranPrometvZastojuM[6] * 100;
       planiranPrometvZastojuM8 := planiranPrometvZastojuM[7] * 100;
       planiranPrometvZastojuM9 := planiranPrometvZastojuM[8] * 100;
       planiranPrometvZastojuM10 := planiranPrometvZastojuM[9] * 100;
       planiranPrometvZastojuM11 := planiranPrometvZastojuM[10] * 100;
       planiranPrometvZastojuM12 := planiranPrometvZastojuM[11] * 100;

       prometPredSkodoM1 := prometPredSkodoM[0] * 100;
       prometPredSkodoM2 := prometPredSkodoM[1] * 100;
       prometPredSkodoM3 := prometPredSkodoM[2] * 100;
       prometPredSkodoM4 := prometPredSkodoM[3] * 100;
       prometPredSkodoM5 := prometPredSkodoM[4] * 100;
       prometPredSkodoM6 := prometPredSkodoM[5] * 100;
       prometPredSkodoM7 := prometPredSkodoM[6] * 100;
       prometPredSkodoM8 := prometPredSkodoM[7] * 100;
       prometPredSkodoM9 := prometPredSkodoM[8] * 100;
       prometPredSkodoM10 := prometPredSkodoM[9] * 100;
       prometPredSkodoM11 := prometPredSkodoM[10] * 100;
       prometPredSkodoM12 := prometPredSkodoM[11] * 100;

       // definiran ali se uporabljajo simulirane ali dejanske vrednosti rasti
       if novoOkno.cbSpremembaPricakovaneRasti.Checked = true then begin  // simulirane vrednosti
          rastNacin := 1;
          rastVisina := novoOkno.vpSpremembaPricakovaneRasti.Position * 10;  // (namesto doslednih: Position / 10 * 100)
          izSimulacijaNogaRast.Caption := FormatFloat('###,###,##0.00 %', novoOkno.vpSpremembaPricakovaneRasti.Position * 10/100) + ' [' + LjezikSimulacija_nogaS +']';
       end
       else begin  // dejanske vnešene vrednosti
          rastNacin := 0;
          rastVisina := 0;
       end;

       // definiran ali se uporabljajo simulirane ali dejanske vrednosti ZV
       if novoOkno.cbSpremembaZV.Checked = true then begin  // simulirane vrednosti
          dejanskaZV := StrToFloat(StringReplace(novoOkno.vpSpremembaZV.Text, '.', '', [rfreplaceAll, rfIgnorecase])) * 100;
          izSimulacijaNogaZV.Caption := novoOkno.vpSpremembaZV.Text + ' [' + LjezikSimulacija_nogaS +']';
       end
       else begin
          dejanskaZV := StrToFloat(StringReplace(fmOsnovniKontroler.izZavarovalnaVsota.Text, '.', '', [rfreplaceAll, rfIgnorecase])) * 100;  // dejanske (vnešene) vrednosti
          izSimulacijaNogaZV.Caption := fmOsnovniKontroler.izZavarovalnaVsota.Text + ' [' + LjezikSimulacija_nogaD +']';
       end;

       // Simulacija premije ...

       nesimuliranaPremija := StrToFloat(StringReplace(fmOsnovniKontroler.izPremija.Text, '.', '', [rfreplaceAll, rfIgnorecase])) * 100;
       nesimuliranaZV := StrToFloat(StringReplace(fmOsnovniKontroler.izZavarovalnaVsota.Text, '.', '', [rfreplaceAll, rfIgnorecase])) * 100;
       if novoOkno.cbSpremembaZV.Checked = true then   // simulirane vrednosti
         simuliranaZV := StrToFloat(StringReplace(novoOkno.vpSpremembaZV.Text, '.', '', [rfreplaceAll, rfIgnorecase])) * 100
       else
         simuliranaZV := StrToFloat(StringReplace(fmOsnovniKontroler.izZavarovalnaVsota.Text, '.', '', [rfreplaceAll, rfIgnorecase])) * 100;
       nesimuliranMDJ := fmOsnovniKontroler.izMaksimalnaDobaJamcenja.ItemIndex + 3;
       if novoOkno.cbSpremembaMDJ.Checked = true then   // simulirane vrednosti
         simuliranMDJ := novoOkno.vpspremembaMDJ.ItemIndex + 3
       else
         simuliranMDJ := fmOsnovniKontroler.izMaksimalnaDobaJamcenja.ItemIndex + 3;
       if (novoOkno.cbSpremembaZV.Checked = true) Or (novoOkno.cbSpremembaMDJ.Checked = true) then
          simuliranaVrednost := 1
       else
          simuliranaVrednost := 0;


       posiljateljIzracunSkode := 2;

       // poljnjenje vrednosti v spremenljivki arhivIzborovSimulacij in arhivVrednostiZadnjeSimulacije (povzetek zadnjih nastavitev)

       with novoOkno do begin
         arhivIzborovSimulacije.SimulacijaZV := cbSpremembaZV.Checked;
         arhivIzborovSimulacije.SimulacijaMDJ := cbSpremembaMDJ.Checked;
         arhivIzborovSimulacije.SimulacijaPovisaniStroski := cbSpremembaObdobjaResenegaPrometa.Checked;
         arhivIzborovSimulacije.SimulacijaPrihranki := cbSpremembaObdobjaPrihrankov.Checked;
         arhivIzborovSimulacije.SimulacijaRast := cbSpremembaPricakovaneRasti.Checked;
         if cbSpremembaZV.Checked = true then
           arhivVrednostiZadnjeSimulacije.SimulacijaZV := vpSpremembaZV.Text
         else
           arhivVrednostiZadnjeSimulacije.SimulacijaZV := arhivVrednostiZacetneSimulacije.SimulacijaZV;
         if cbSpremembaMDJ.Checked = true then
           arhivVrednostiZadnjeSimulacije.SimulacijaMDJ := vpSpremembaMDJ.ItemIndex
         else
           arhivVrednostiZadnjeSimulacije.SimulacijaMDJ := arhivVrednostiZacetneSimulacije.SimulacijaMDJ;

         if cbSpremembaObdobjaResenegaPrometa.Checked = true then
           arhivVrednostiZadnjeSimulacije.SimulacijaPovisaniStroski := vpSpremembaObdobjaresenegaPrometa.ItemIndex
         else
           arhivVrednostiZadnjeSimulacije.SimulacijaPovisaniStroski := arhivVrednostiZacetneSimulacije.SimulacijaPovisaniStroski;

         if cbSpremembaObdobjaPrihrankov.Checked = true then
           arhivVrednostiZadnjeSimulacije.SimulacijaPrihranki := vpSpremembaObdobjaPrihrankov.ItemIndex
         else
           arhivVrednostiZadnjeSimulacije.SimulacijaPrihranki := arhivVrednostiZacetneSimulacije.SimulacijaPrihranki;

         if cbSpremembaPricakovaneRasti.Checked = true then
           arhivVrednostiZadnjeSimulacije.SimulacijaRast :=  vpSpremembaPricakovaneRasti.Position
         else
           arhivVrednostiZadnjeSimulacije.SimulacijaRast := arhivVrednostiZacetneSimulacije.SimulacijaRast;
       end;

       if PovezavaWSIzracunSkode.Connected = true then
           PovezavaWSIzracunSkode.Connected := false;
       PovezavaWSIzracunSkode.Connected := true;

    finally
       PovezavaWSIzracunSkode.Connected := false;
    end;
   end;
  end;

begin
  novoOkno := TfmSimulacije.CreateNew(@ObOdprtjuShowModal);
  novoOkno.Popup := true;
  novoOkno.ShowModal(@PoZaprtjuShowModal);
end;

procedure TfmSimulacijaSkode.UpravicenostPrihranka(Sender: TObject);
var i, j, steviloZapisov : Integer;
    polje1, polje2 : TComboBox;
    polje3 : TEdit;
    visinaPrihranka : Array of Real;
    delezPrihranka : Array of Real;
begin

  // 1. del - sproži se le, če je izbrano polje Upravičenost prihranka
  if LeftStr((Sender as TComboBox).Name, 24) = 'vpUpravicenostPrihranka_' then begin
     i := StrToInt(StringReplace((Sender as TComboBox).Name, 'vpUpravicenostPrihranka_' , '' , [rfreplaceAll, rfIgnorecase]));
     with SimulacijaSkode_List3 do begin
        polje1 := FindComponent('vpVisinaUpravicenostiPrihranka_'+IntToStr(i)) as TComboBox;
     end;
     if (Sender as TComboBox).ItemIndex = 0 then begin
        polje1.ItemIndex := 19;
        polje1.Enabled := true;
     end
     else if (Sender as TComboBox).ItemIndex = 1 then begin
       polje1.ItemIndex := -1;
       polje1.Enabled := false;
     end;
  end;

  // 2. del - sproži se le, če je izbrano polje Upravičenost prihranka ali Delež prihranka (pripravi paramtre za funkcijo PreracunPrihrankov)
    // preverjanje priznanih prihrankov
  steviloZapisov := ploscicaPregledPrihrankov.RowCollection.Count;
  j := 0;
  with SimulacijaSkode_List3 do begin
    for i := 1 to steviloZapisov-1 do begin
      polje1 := FindComponent('vpUpravicenostPrihranka_'+IntToStr(i)) as TComboBox;
      if polje1.ItemIndex = 0 then begin
         j := j+1;
         polje3 := FindComponent('izVisinaPrihranka_'+IntToStr(i)) as TEdit;
         visinaPrihranka[j] := StrToFloat(StringReplace(polje3.Text, '.', '', [rfreplaceAll, rfIgnorecase]));
         polje2 := FindComponent('vpVisinaUpravicenostiPrihranka_'+IntToStr(i)) as TComboBox;
         delezPrihranka[j] := StrToFloat(StringReplace(polje2.Text, ' %', '', [rfreplaceAll, rfIgnorecase]));
      end;
    end;
  end;


  posiljateljIzracunSkode := 5;
  prijavljeniPrihranki := PreracunPrihrankov(j, visinaPrihranka, delezPrihranka) * 100;


//  izDejanskiPrihranki.Text := FormatFloat('###,###,##0.00', PreracunPrihrankov (j, dogovorjenaMDJ, zastoj, obdobjePrihrankov, visinaPrihranka, delezPrihranka));


  try
    if PovezavaWSIzracunSkode.Connected = true then
         PovezavaWSIzracunSkode.Connected := false;
         PovezavaWSIzracunSkode.Connected := true;
    finally
       PovezavaWSIzracunSkode.Connected := false;
    end;

end;


procedure TfmSimulacijaSkode.PovezavaWSIzracunSkodeConnect(Sender: TObject);

     procedure OnResultIzracunSkode( Response: TXDataClientResponse );
     var
        LTabela : TJSArray;
        TabelaRezultatov : Array of Real;
        i : Integer;
        slika : TImageControl;
     begin
       try
          LTabela := TJSArray(TJSObject(Response.Result)['value']);
          SetLength(TabelaRezultatov, 24);
          for i := 0 to 23 do
             TabelaRezultatov[i] := Real(LTabela[i]);

          // LIST 1
          izIzpadPrometa.Text := FormatFloat('###,###,##0.00', TabelaRezultatov[2]); // * (-1));
          izZavarovaniIzpadPrometa.Text := FormatFloat('###,###,##0.00', TabelaRezultatov[3]); // * (-1));
          NepokritiZavarovaniIzpadPrometaTekst.Caption := LjezikSkodaK1_NepokritiStroski1 + ' ' + fmOsnovniKontroler.vpVnosFRPStopnjaKosmategaDobicka.Caption + ' ' + LjezikSkodaK1_NepokritiStroski2;
          izNepokritiZavarovaniIzpadPrometa.Text := FormatFloat('###,###,##0.00', TabelaRezultatov[5]);
          // grafični prikaz ustreznosti dogovorjene MDJ glede na dejanski izpad (primerjava izpada prometa in zavarovanega izpada prometa)
          slika := FindComponent('SlikaIzpadPrometa') as TImageControl;
          if TabelaRezultatov[6] = 1 then
              slika.Picture.LoadFromFile('luc_Z.png')
          else if TabelaRezultatov[6] = 2 then
               slika.Picture.LoadFromFile('luc_Ru.png')
          else
              slika.Picture.LoadFromFile('luc_Rd.png');

          // LIST 2
          izEkonomskoUpraviceniStroski.Text := FormatFloat('###,###,##0.00', TabelaRezultatov[7]);

          // LIST 3
          izDejanskiPrihranki.Text := FormatFloat('###,###,##0.00', TabelaRezultatov[8]);

          // LIST 4
          izIzhodiscaLetniPromet.Text := FormatFloat('###,###,##0.00', TabelaRezultatov[9]);
          izLetniPrometOsnova.Text := FormatFloat('###,###,##0.00', TabelaRezultatov[9]);
          izLetniPrometRast.Text := FormatFloat('###,###,##0.00 %', TabelaRezultatov[10]);
          if rastNacin = 0 then
              izSimulacijaNogaRast.Caption := FormatFloat('###,###,##0.00 %', TabelaRezultatov[10]) + ' [' + LjezikSimulacija_nogaD + ']';
  //      arhivVrednostiZacetneSimulacije.simulacijaRast := RealToInt(TabelaRezultatov[12]);
          izLetniPromet.Text := FormatFloat('###,###,##0.00', TabelaRezultatov[11]);
          izPotrebnaZV.Text := FormatFloat('###,###,##0.00', TabelaRezultatov[13]);
          izDejanskaZV.Text := FormatFloat('###,###,##0.00', TabelaRezultatov[22]);
          arhivVrednostiZacetneSimulacije.SimulacijaZV := fmOsnovniKontroler.izZavarovalnaVsota.Text;
              // Izračun podzavarovanja
          slika := FindComponent('SlikaZV') as TImageControl;
          if TabelaRezultatov[14] = 1 then begin
             izPodzavarovanje.Text := LjezikNe;
             slika.Picture.LoadFromFile('luc_Z.png')
          end
          else if TabelaRezultatov[14] = 2 then begin
             izPodzavarovanje.Text := LjezikSkodaK4_PodzavarovanjeIzpis + ' ' + FormatFloat('###,###,##0.00', TabelaRezultatov[15]) + ' %';
             slika.Picture.LoadFromFile('luc_Ru.png')
          end
          else begin
             izPodzavarovanje.Text := LjezikSkodaK4_PodzavarovanjeIzpis + ' ' + FormatFloat('###,###,##0.00', TabelaRezultatov[15]) + ' %';
             slika.Picture.LoadFromFile('luc_Rd.png')
          end;

          // LIST 5
          izRezultatIzpadPrometa.Text := izNepokritiZavarovaniIzpadPrometa.Text;
          izRezultatPovisaniStroski.Text := izEkonomskoUpraviceniStroski.Text;
          izRezultatIzpadInStroski.Text := FormatFloat('###,###,##0.00', TabelaRezultatov[16]);
          izRezultatPrihranki.Text := FormatFloat('###,###,##0.00', TabelaRezultatov[8] * (-1));
          izRezultatZavarovanaSkoda.Text := FormatFloat('###,###,##0.00', TabelaRezultatov[17]);
          izRezultatPodzavarovanje.Text := FormatFloat('###,###,##0.00', TabelaRezultatov[18] * (-1));
          izRezultatZavarovalnina.Text := FormatFloat('###,###,##0.00', TabelaRezultatov[19]);
          izRezultatDejanskoOcenjenaSkoda.Text := FormatFloat('###,###,##0.00', TabelaRezultatov[20]);

          slikaZavarovalnina_1.Picture.Assign(slikaIzpadPrometa.Picture);
          slikaZavarovalnina_2.Picture.Assign(slikaZV.Picture);
          if TabelaRezultatov[21] = 1 then
             slikaZavarovalnina_3.Picture.LoadFromFile('luc_Z.png')
          else if TabelaRezultatov[21] = 2 then
             slikaZavarovalnina_3.Picture.LoadFromFile('luc_Ru.png')
          else
             slikaZavarovalnina_3.Picture.LoadFromFile('luc_Rd.png');

          // polnjenje začetnih vrednosti za spremenljivko arhivIzborSimulacij in arhivVrednostiZadnjeSimulacije (vrednosti za slednjo so določene na osnovi arhivVrednostizacetneSimulacije iz same proceduri)
          if TabelaRezultatov[23] = 1 then begin
            arhivIzborovSimulacije.simulacijaZV := false;
            arhivIzborovSimulacije.simulacijaMDJ := false;
            arhivIzborovSimulacije.simulacijaPrihranki := false;
            arhivIzborovSimulacije.simulacijaPovisaniStroski := false;
            arhivIzborovSimulacije.simulacijaRast := false;
            arhivVrednostiZadnjeSimulacije.simulacijaZV := arhivVrednostiZacetneSimulacije.simulacijaZV;
            arhivVrednostiZadnjeSimulacije.simulacijaMDJ := arhivVrednostiZacetneSimulacije.simulacijaMDJ;
            arhivVrednostiZadnjeSimulacije.simulacijaPovisaniStroski := arhivVrednostiZacetneSimulacije.simulacijaPovisaniStroski;
            arhivVrednostiZadnjeSimulacije.simulacijaPrihranki := arhivVrednostiZacetneSimulacije.simulacijaPrihranki;
            arhivVrednostiZadnjeSimulacije.simulacijaRast := arhivVrednostiZacetneSimulacije.simulacijaRast;
            // Nastavitve gumbov,...
            SimulacijaSkode_Listi.TabIndex := 0;
            g1.Enabled := true;
            g2.Enabled := false;
            g3.Enabled := false;
            g4.Enabled := false;
            g5.Enabled := false;
          end;
       finally
          PovezavaWSIzracunSkode.Connected := false;
          fmOsnovniKontroler.Cursor := crDefault;
       end;
     end;


     procedure OnResultIzracunPremije( Response: TXDataClientResponse );
     var
        LTabela : TJSArray;
        TabelaRezultatov : Array of Real;
        i : Integer;
     begin
       try
          LTabela := TJSArray(TJSObject(Response.Result)['value']);
          SetLength(TabelaRezultatov, 2);
          for i := 0 to 1 do
             TabelaRezultatov[i] := Real(LTabela[i]);

          if TabelaRezultatov[0] = 0 then
              izSimulacijaNogaPremija.Caption := FormatFloat('###,###,##0.00', TabelaRezultatov[1] / 100) + ' [' + LjezikSimulacija_nogaD +']'
          else
              izSimulacijaNogaPremija.Caption := FormatFloat('###,###,##0.00', TabelaRezultatov[1] / 100) + ' [' + LjezikSimulacija_nogaS + ']';
       finally
          PovezavaWSIzracunSkode.Connected := false;
          fmOsnovniKontroler.Cursor := crDefault;
       end;
     end;



begin
  try
    WebClientIzracunSkode.RawInvoke('IIzracuniService.IzracunSkode',
        [ prometPlanVZastojuM1, prometPlanVZastojuM2, prometPlanVZastojuM3, prometPlanVZastojuM4, prometPlanVZastojuM5, prometPlanVZastojuM6, prometPlanVZastojuM7, prometPlanVZastojuM8, prometPlanVZastojuM9, prometPlanVZastojuM10, prometPlanVZastojuM11, prometPlanVZastojuM12,
          prometRealizacijaVZastojuM1, prometRealizacijaVZastojuM2, prometRealizacijaVZastojuM3, prometRealizacijaVZastojuM4, prometRealizacijaVZastojuM5, prometRealizacijaVZastojuM6, prometRealizacijaVZastojuM7, prometRealizacijaVZastojuM8, prometRealizacijaVZastojuM9, prometRealizacijaVZastojuM10, prometRealizacijaVZastojuM11, prometRealizacijaVZastojuM12,
          resenPromet, povisaniStroski, delezKosmategaDobicka, prijavljeniPrihranki, dejanskaZV, rastVisina,
          planiranPrometvZastojuM1, planiranPrometvZastojuM2, planiranPrometvZastojuM3, planiranPrometvZastojuM4, planiranPrometvZastojuM5, planiranPrometvZastojuM6, planiranPrometvZastojuM7, planiranPrometvZastojuM8, planiranPrometvZastojuM9, planiranPrometvZastojuM10, planiranPrometvZastojuM11, planiranPrometvZastojuM12,
          prometPredSkodoM1, prometPredSkodoM2, prometPredSkodoM3, prometPredSkodoM4, prometPredSkodoM5, prometPredSkodoM6, prometPredSkodoM7, prometPredSkodoM8, prometPredSkodoM9, prometPredSkodoM10, prometPredSkodoM11, prometPredSkodoM12,
          mesecevSkode, dogovorjenaMDJ, obdobjePovisanihStroskov, obdobjePrihrankov, rastNacin, posiljateljIzracunSkode ],
          @OnResultIzracunSkode);
  except
    ShowMessage(LjezikObvestilo_WebServis_Skoda);
  end;
  try
    if posiljateljIzracunSkode = 2 then
       WebClientIzracunSkode.RawInvoke('IIzracuniService.IzracunPremije', [nesimuliranaPremija, nesimuliranaZV, simuliranaZV, nesimuliranMDJ, simuliranMDJ, simuliranaVrednost], @OnResultIzracunPremije);
  except
    ShowMessage(LjezikObvestilo_WebServis_Premija);
  end;


end;

procedure TfmSimulacijaSkode.PovezavaWSIzracunSkodeError(
  Error: TXDataWebConnectionError);
begin
  showMessage(LjezikObvestilo_WebServisi);
end;

procedure TfmSimulacijaSkode.LoadDFMValues;
begin
  inherited LoadDFMValues;

  ploscicaIkoneZgoraj := TPanel.Create(Self);
  izKoraki := TLabel.Create(Self);
  gIzhod := TSpeedButton.Create(Self);
  plosicaG5 := TPanel.Create(Self);
  g5 := TSpeedButton.Create(Self);
  ploscicaG4 := TPanel.Create(Self);
  g4 := TSpeedButton.Create(Self);
  ploscicaG3 := TPanel.Create(Self);
  g3 := TSpeedButton.Create(Self);
  ploscicaG2 := TPanel.Create(Self);
  g2 := TSpeedButton.Create(Self);
  ploscicaG1 := TPanel.Create(Self);
  g1 := TSpeedButton.Create(Self);
  ploscicaIkoneSpodaj := TPanel.Create(Self);
  ploscicaGSimulacije := TPanel.Create(Self);
  gSimulacije := TSpeedButton.Create(Self);
  WebGridPanel1 := TGridPanel.Create(Self);
  izSimulacijaNogaTekstMDJ := TLabel.Create(Self);
  izSimulacijaNogaTekstZV := TLabel.Create(Self);
  izSimulacijaNogaTekstPrihranki := TLabel.Create(Self);
  izSimulacijaNogaTekstStroski := TLabel.Create(Self);
  izSimulacijaNogaTekstRast := TLabel.Create(Self);
  izSimulacijaNogaTekstPremija := TLabel.Create(Self);
  izSimulacijaNogaMDJ := TLabel.Create(Self);
  izSimulacijaNogaZV := TLabel.Create(Self);
  izSimulacijaNogaPrihranki := TLabel.Create(Self);
  izSimulacijaNogaStroski := TLabel.Create(Self);
  izSimulacijaNogaRast := TLabel.Create(Self);
  izSimulacijaNogaPremija := TLabel.Create(Self);
  SimulacijaSkode_Listi := TPageControl.Create(Self);
  SimulacijaSkode_List1 := TTabSheet.Create(Self);
  izNaslovList1 := TLabel.Create(Self);
  ploscicaIkoneList1 := TPanel.Create(Self);
  ploscicaGNaprej1 := TPanel.Create(Self);
  gNaprej1 := TSpeedButton.Create(Self);
  ploscicaTabelaNepokritiStroski := TGridPanel.Create(Self);
  ploscicaTabelaNepokritiStroski_Naslov1 := TLabel.Create(Self);
  ploscicaTabelaNepokritiStroski_Naslov2 := TMemo.Create(Self);
  ploscicaTabelaNepokritiStroski_Naslov3 := TMemo.Create(Self);
  ploscicaTabelaNepokritiStroski_Naslov4 := TMemo.Create(Self);
  ploscicaTabelaNepokritiStroski_Naslov5 := TMemo.Create(Self);
  ploscicaTabelaNepokritiStroski_Naslov6 := TMemo.Create(Self);
  ploscicaTabelaNepokritiStroski_Povzetek := TGridPanel.Create(Self);
  IzpadPrometaTekst := TLabel.Create(Self);
  ZavarovaniIzpadPrometaTekst := TLabel.Create(Self);
  NepokritiZavarovaniIzpadPrometaTekst := TLabel.Create(Self);
  izIzpadPrometa := TEdit.Create(Self);
  IzpadPrometaPrazno := TPanel.Create(Self);
  izZavarovaniIzpadPrometa := TEdit.Create(Self);
  ZavarovaniIzpadPrometaPrazno := TPanel.Create(Self);
  slikaIzpadPrometa := TImageControl.Create(Self);
  izNepokritiZavarovaniIzpadPrometa := TEdit.Create(Self);
  NepokritiZavarovaniIzpadPrometaPrazno := TPanel.Create(Self);
  SimulacijaSkode_List2 := TTabSheet.Create(Self);
  izNaslovList2 := TLabel.Create(Self);
  ploscicaIkoneList2 := TPanel.Create(Self);
  ploscicaGNaprej2 := TPanel.Create(Self);
  gNaprej2 := TSpeedButton.Create(Self);
  ploscicaGNazaj2 := TPanel.Create(Self);
  gNazaj2 := TSpeedButton.Create(Self);
  ploscicaPovisaniPoslovniStroski := TGridPanel.Create(Self);
  PovisaniPoslovniStrskiTekst := TLabel.Create(Self);
  ResenPrometTekst := TLabel.Create(Self);
  EkonomskoUpraviceniPovisaniStroskiTekst := TLabel.Create(Self);
  izPovisaniPoslovniStroski := TEdit.Create(Self);
  izResenPromet := TEdit.Create(Self);
  izEkonomskoUpraviceniStroski := TEdit.Create(Self);
  SimulacijaSkode_List3 := TTabSheet.Create(Self);
  izNaslovList3 := TLabel.Create(Self);
  ploscicaIkoneList3 := TPanel.Create(Self);
  ploscicaGNaprej3 := TPanel.Create(Self);
  gNaprej3 := TSpeedButton.Create(Self);
  ploscicaGNazaj3 := TPanel.Create(Self);
  gNazaj3 := TSpeedButton.Create(Self);
  ploscicaPregledPrihrankov := TGridPanel.Create(Self);
  ploscicaTabelaNepokritiStroski_Naslov7 := TLabel.Create(Self);
  ploscicaTabelaNepokritiStroski_Naslov8 := TLabel.Create(Self);
  ploscicaTabelaNepokritiStroski_Naslov9 := TLabel.Create(Self);
  ploscicaTabelaNepokritiStroski_Naslov10 := TLabel.Create(Self);
  ploscicaPregledPrihrankov_Povzetek := TGridPanel.Create(Self);
  PrijavljeniPrihrankiTekst := TLabel.Create(Self);
  DejanskiPrihrankiTekst := TLabel.Create(Self);
  izPrijavljeniPrihranki := TEdit.Create(Self);
  izDejanskiPrihranki := TEdit.Create(Self);
  SimulacijaSkode_List4 := TTabSheet.Create(Self);
  izNaslovList4 := TLabel.Create(Self);
  ploscicaIkoneList4 := TPanel.Create(Self);
  ploscicaGNaprej4 := TPanel.Create(Self);
  gNaprej4 := TSpeedButton.Create(Self);
  ploscicaGNazaj4 := TPanel.Create(Self);
  gNazaj4 := TSpeedButton.Create(Self);
  ploscicaTabelaIzhodiscaLetniPrometMeseci := TGridPanel.Create(Self);
  ploscicaTabelaNepokritiStroski_Naslov11 := TMemo.Create(Self);
  ploscicaTabelaNepokritiStroski_Naslov12 := TMemo.Create(Self);
  MesecPredZastojem_1 := TEdit.Create(Self);
  PrometPredZastojem_1 := TEdit.Create(Self);
  MesecPredZastojem_2 := TEdit.Create(Self);
  PrometPredZastojem_2 := TEdit.Create(Self);
  MesecPredZastojem_3 := TEdit.Create(Self);
  PrometPredZastojem_3 := TEdit.Create(Self);
  MesecPredZastojem_4 := TEdit.Create(Self);
  PrometPredZastojem_4 := TEdit.Create(Self);
  MesecPredZastojem_5 := TEdit.Create(Self);
  PrometPredZastojem_5 := TEdit.Create(Self);
  MesecPredZastojem_6 := TEdit.Create(Self);
  PrometPredZastojem_6 := TEdit.Create(Self);
  MesecPredZastojem_7 := TEdit.Create(Self);
  PrometPredZastojem_7 := TEdit.Create(Self);
  MesecPredZastojem_8 := TEdit.Create(Self);
  PrometPredZastojem_8 := TEdit.Create(Self);
  MesecPredZastojem_9 := TEdit.Create(Self);
  PrometPredZastojem_9 := TEdit.Create(Self);
  MesecPredZastojem_10 := TEdit.Create(Self);
  PrometPredZastojem_10 := TEdit.Create(Self);
  MesecPredZastojem_11 := TEdit.Create(Self);
  PrometPredZastojem_11 := TEdit.Create(Self);
  MesecPredZastojem_12 := TEdit.Create(Self);
  PrometPredZastojem_12 := TEdit.Create(Self);
  ploscicaTabelaIzhodiscaLetniPrometSkupaj := TGridPanel.Create(Self);
  izIzhodiscaLetniPrometTekst := TLabel.Create(Self);
  izIzhodiscaLetniPromet := TEdit.Create(Self);
  ploscicaPodzavarovanje := TGridPanel.Create(Self);
  izLetniPrometOsnovaTekst := TLabel.Create(Self);
  izLetniPrometRastTekst := TLabel.Create(Self);
  izLetniPrometTekst := TLabel.Create(Self);
  izPotrebnaZVTekst := TLabel.Create(Self);
  izDejanskaZVTekst := TLabel.Create(Self);
  izPodzavarovanjeTekst := TLabel.Create(Self);
  izLetniPrometOsnova := TEdit.Create(Self);
  PodzavarovanjePrazno1 := TPanel.Create(Self);
  izLetniPrometRast := TEdit.Create(Self);
  PodzavarovanjePrazno2 := TPanel.Create(Self);
  izLetniPromet := TEdit.Create(Self);
  PodzavarovanjePrazno3 := TPanel.Create(Self);
  izPotrebnaZV := TEdit.Create(Self);
  PodzavarovanjePrazno4 := TPanel.Create(Self);
  izDejanskaZV := TEdit.Create(Self);
  PodzavarovanjePrazno5 := TPanel.Create(Self);
  slikaZV := TImageControl.Create(Self);
  izPodzavarovanje := TEdit.Create(Self);
  PodzavarovanjePrazno6 := TPanel.Create(Self);
  SimulacijaSkode_List5 := TTabSheet.Create(Self);
  izNaslovList5 := TLabel.Create(Self);
  ploscicaIkoneList5 := TPanel.Create(Self);
  ploscicaGNazaj5 := TPanel.Create(Self);
  gNazaj5 := TSpeedButton.Create(Self);
  ploscicaTabelaIzracunZavarovalnine := TGridPanel.Create(Self);
  izRezultatIzpadPrometaTekst := TLabel.Create(Self);
  izRezultatPovisaniStroskiTekst := TLabel.Create(Self);
  izRezultatPrihrankiTekst := TLabel.Create(Self);
  izRezultatPodzavarovanjeTekst := TLabel.Create(Self);
  izRezultatZavarovalninaTekst := TLabel.Create(Self);
  izRezultatDejanskoOcenjenaSkodaTekst := TLabel.Create(Self);
  izRezultatIzpadPrometa := TEdit.Create(Self);
  ploscicaZavarovalninaPrazna_1 := TPanel.Create(Self);
  slikaZavarovalnina_1 := TImageControl.Create(Self);
  izRezultatPovisaniStroski := TEdit.Create(Self);
  ploscicaZavarovalninaPrazna_2 := TPanel.Create(Self);
  ploscicaZavarovalninaPrazna_3a := TPanel.Create(Self);
  izRezultatIzpadInStroski := TEdit.Create(Self);
  ploscicaZavarovalninaPrazna_3b := TPanel.Create(Self);
  izRezultatPrihranki := TEdit.Create(Self);
  ploscicaZavarovalninaPrazna_4 := TPanel.Create(Self);
  ploscicaZavarovalninaPrazna_5a := TPanel.Create(Self);
  izRezultatZavarovanaSkoda := TEdit.Create(Self);
  ploscicaZavarovalninaPrazna_5b := TPanel.Create(Self);
  izRezultatPodzavarovanje := TEdit.Create(Self);
  ploscicaZavarovalninaPrazna_6 := TPanel.Create(Self);
  slikaZavarovalnina_2 := TImageControl.Create(Self);
  izRezultatZavarovalnina := TEdit.Create(Self);
  ploscicaZavarovalninaPrazna_7 := TPanel.Create(Self);
  slikaZavarovalnina_3 := TImageControl.Create(Self);
  izRezultatDejanskoOcenjenaSkoda := TEdit.Create(Self);
  ploscicaZavarovalninaPrazna_8 := TPanel.Create(Self);
  ploscicaNaslov := TPanel.Create(Self);
  izNaslov := TLabel.Create(Self);
  PovezavaWSIzracunSkode := TXDataWebConnection.Create(Self);
  WebClientIzracunSkode := TXDataWebClient.Create(Self);

  ploscicaIkoneZgoraj.BeforeLoadDFMValues;
  izKoraki.BeforeLoadDFMValues;
  gIzhod.BeforeLoadDFMValues;
  plosicaG5.BeforeLoadDFMValues;
  g5.BeforeLoadDFMValues;
  ploscicaG4.BeforeLoadDFMValues;
  g4.BeforeLoadDFMValues;
  ploscicaG3.BeforeLoadDFMValues;
  g3.BeforeLoadDFMValues;
  ploscicaG2.BeforeLoadDFMValues;
  g2.BeforeLoadDFMValues;
  ploscicaG1.BeforeLoadDFMValues;
  g1.BeforeLoadDFMValues;
  ploscicaIkoneSpodaj.BeforeLoadDFMValues;
  ploscicaGSimulacije.BeforeLoadDFMValues;
  gSimulacije.BeforeLoadDFMValues;
  WebGridPanel1.BeforeLoadDFMValues;
  izSimulacijaNogaTekstMDJ.BeforeLoadDFMValues;
  izSimulacijaNogaTekstZV.BeforeLoadDFMValues;
  izSimulacijaNogaTekstPrihranki.BeforeLoadDFMValues;
  izSimulacijaNogaTekstStroski.BeforeLoadDFMValues;
  izSimulacijaNogaTekstRast.BeforeLoadDFMValues;
  izSimulacijaNogaTekstPremija.BeforeLoadDFMValues;
  izSimulacijaNogaMDJ.BeforeLoadDFMValues;
  izSimulacijaNogaZV.BeforeLoadDFMValues;
  izSimulacijaNogaPrihranki.BeforeLoadDFMValues;
  izSimulacijaNogaStroski.BeforeLoadDFMValues;
  izSimulacijaNogaRast.BeforeLoadDFMValues;
  izSimulacijaNogaPremija.BeforeLoadDFMValues;
  SimulacijaSkode_Listi.BeforeLoadDFMValues;
  SimulacijaSkode_List1.BeforeLoadDFMValues;
  izNaslovList1.BeforeLoadDFMValues;
  ploscicaIkoneList1.BeforeLoadDFMValues;
  ploscicaGNaprej1.BeforeLoadDFMValues;
  gNaprej1.BeforeLoadDFMValues;
  ploscicaTabelaNepokritiStroski.BeforeLoadDFMValues;
  ploscicaTabelaNepokritiStroski_Naslov1.BeforeLoadDFMValues;
  ploscicaTabelaNepokritiStroski_Naslov2.BeforeLoadDFMValues;
  ploscicaTabelaNepokritiStroski_Naslov3.BeforeLoadDFMValues;
  ploscicaTabelaNepokritiStroski_Naslov4.BeforeLoadDFMValues;
  ploscicaTabelaNepokritiStroski_Naslov5.BeforeLoadDFMValues;
  ploscicaTabelaNepokritiStroski_Naslov6.BeforeLoadDFMValues;
  ploscicaTabelaNepokritiStroski_Povzetek.BeforeLoadDFMValues;
  IzpadPrometaTekst.BeforeLoadDFMValues;
  ZavarovaniIzpadPrometaTekst.BeforeLoadDFMValues;
  NepokritiZavarovaniIzpadPrometaTekst.BeforeLoadDFMValues;
  izIzpadPrometa.BeforeLoadDFMValues;
  IzpadPrometaPrazno.BeforeLoadDFMValues;
  izZavarovaniIzpadPrometa.BeforeLoadDFMValues;
  ZavarovaniIzpadPrometaPrazno.BeforeLoadDFMValues;
  slikaIzpadPrometa.BeforeLoadDFMValues;
  izNepokritiZavarovaniIzpadPrometa.BeforeLoadDFMValues;
  NepokritiZavarovaniIzpadPrometaPrazno.BeforeLoadDFMValues;
  SimulacijaSkode_List2.BeforeLoadDFMValues;
  izNaslovList2.BeforeLoadDFMValues;
  ploscicaIkoneList2.BeforeLoadDFMValues;
  ploscicaGNaprej2.BeforeLoadDFMValues;
  gNaprej2.BeforeLoadDFMValues;
  ploscicaGNazaj2.BeforeLoadDFMValues;
  gNazaj2.BeforeLoadDFMValues;
  ploscicaPovisaniPoslovniStroski.BeforeLoadDFMValues;
  PovisaniPoslovniStrskiTekst.BeforeLoadDFMValues;
  ResenPrometTekst.BeforeLoadDFMValues;
  EkonomskoUpraviceniPovisaniStroskiTekst.BeforeLoadDFMValues;
  izPovisaniPoslovniStroski.BeforeLoadDFMValues;
  izResenPromet.BeforeLoadDFMValues;
  izEkonomskoUpraviceniStroski.BeforeLoadDFMValues;
  SimulacijaSkode_List3.BeforeLoadDFMValues;
  izNaslovList3.BeforeLoadDFMValues;
  ploscicaIkoneList3.BeforeLoadDFMValues;
  ploscicaGNaprej3.BeforeLoadDFMValues;
  gNaprej3.BeforeLoadDFMValues;
  ploscicaGNazaj3.BeforeLoadDFMValues;
  gNazaj3.BeforeLoadDFMValues;
  ploscicaPregledPrihrankov.BeforeLoadDFMValues;
  ploscicaTabelaNepokritiStroski_Naslov7.BeforeLoadDFMValues;
  ploscicaTabelaNepokritiStroski_Naslov8.BeforeLoadDFMValues;
  ploscicaTabelaNepokritiStroski_Naslov9.BeforeLoadDFMValues;
  ploscicaTabelaNepokritiStroski_Naslov10.BeforeLoadDFMValues;
  ploscicaPregledPrihrankov_Povzetek.BeforeLoadDFMValues;
  PrijavljeniPrihrankiTekst.BeforeLoadDFMValues;
  DejanskiPrihrankiTekst.BeforeLoadDFMValues;
  izPrijavljeniPrihranki.BeforeLoadDFMValues;
  izDejanskiPrihranki.BeforeLoadDFMValues;
  SimulacijaSkode_List4.BeforeLoadDFMValues;
  izNaslovList4.BeforeLoadDFMValues;
  ploscicaIkoneList4.BeforeLoadDFMValues;
  ploscicaGNaprej4.BeforeLoadDFMValues;
  gNaprej4.BeforeLoadDFMValues;
  ploscicaGNazaj4.BeforeLoadDFMValues;
  gNazaj4.BeforeLoadDFMValues;
  ploscicaTabelaIzhodiscaLetniPrometMeseci.BeforeLoadDFMValues;
  ploscicaTabelaNepokritiStroski_Naslov11.BeforeLoadDFMValues;
  ploscicaTabelaNepokritiStroski_Naslov12.BeforeLoadDFMValues;
  MesecPredZastojem_1.BeforeLoadDFMValues;
  PrometPredZastojem_1.BeforeLoadDFMValues;
  MesecPredZastojem_2.BeforeLoadDFMValues;
  PrometPredZastojem_2.BeforeLoadDFMValues;
  MesecPredZastojem_3.BeforeLoadDFMValues;
  PrometPredZastojem_3.BeforeLoadDFMValues;
  MesecPredZastojem_4.BeforeLoadDFMValues;
  PrometPredZastojem_4.BeforeLoadDFMValues;
  MesecPredZastojem_5.BeforeLoadDFMValues;
  PrometPredZastojem_5.BeforeLoadDFMValues;
  MesecPredZastojem_6.BeforeLoadDFMValues;
  PrometPredZastojem_6.BeforeLoadDFMValues;
  MesecPredZastojem_7.BeforeLoadDFMValues;
  PrometPredZastojem_7.BeforeLoadDFMValues;
  MesecPredZastojem_8.BeforeLoadDFMValues;
  PrometPredZastojem_8.BeforeLoadDFMValues;
  MesecPredZastojem_9.BeforeLoadDFMValues;
  PrometPredZastojem_9.BeforeLoadDFMValues;
  MesecPredZastojem_10.BeforeLoadDFMValues;
  PrometPredZastojem_10.BeforeLoadDFMValues;
  MesecPredZastojem_11.BeforeLoadDFMValues;
  PrometPredZastojem_11.BeforeLoadDFMValues;
  MesecPredZastojem_12.BeforeLoadDFMValues;
  PrometPredZastojem_12.BeforeLoadDFMValues;
  ploscicaTabelaIzhodiscaLetniPrometSkupaj.BeforeLoadDFMValues;
  izIzhodiscaLetniPrometTekst.BeforeLoadDFMValues;
  izIzhodiscaLetniPromet.BeforeLoadDFMValues;
  ploscicaPodzavarovanje.BeforeLoadDFMValues;
  izLetniPrometOsnovaTekst.BeforeLoadDFMValues;
  izLetniPrometRastTekst.BeforeLoadDFMValues;
  izLetniPrometTekst.BeforeLoadDFMValues;
  izPotrebnaZVTekst.BeforeLoadDFMValues;
  izDejanskaZVTekst.BeforeLoadDFMValues;
  izPodzavarovanjeTekst.BeforeLoadDFMValues;
  izLetniPrometOsnova.BeforeLoadDFMValues;
  PodzavarovanjePrazno1.BeforeLoadDFMValues;
  izLetniPrometRast.BeforeLoadDFMValues;
  PodzavarovanjePrazno2.BeforeLoadDFMValues;
  izLetniPromet.BeforeLoadDFMValues;
  PodzavarovanjePrazno3.BeforeLoadDFMValues;
  izPotrebnaZV.BeforeLoadDFMValues;
  PodzavarovanjePrazno4.BeforeLoadDFMValues;
  izDejanskaZV.BeforeLoadDFMValues;
  PodzavarovanjePrazno5.BeforeLoadDFMValues;
  slikaZV.BeforeLoadDFMValues;
  izPodzavarovanje.BeforeLoadDFMValues;
  PodzavarovanjePrazno6.BeforeLoadDFMValues;
  SimulacijaSkode_List5.BeforeLoadDFMValues;
  izNaslovList5.BeforeLoadDFMValues;
  ploscicaIkoneList5.BeforeLoadDFMValues;
  ploscicaGNazaj5.BeforeLoadDFMValues;
  gNazaj5.BeforeLoadDFMValues;
  ploscicaTabelaIzracunZavarovalnine.BeforeLoadDFMValues;
  izRezultatIzpadPrometaTekst.BeforeLoadDFMValues;
  izRezultatPovisaniStroskiTekst.BeforeLoadDFMValues;
  izRezultatPrihrankiTekst.BeforeLoadDFMValues;
  izRezultatPodzavarovanjeTekst.BeforeLoadDFMValues;
  izRezultatZavarovalninaTekst.BeforeLoadDFMValues;
  izRezultatDejanskoOcenjenaSkodaTekst.BeforeLoadDFMValues;
  izRezultatIzpadPrometa.BeforeLoadDFMValues;
  ploscicaZavarovalninaPrazna_1.BeforeLoadDFMValues;
  slikaZavarovalnina_1.BeforeLoadDFMValues;
  izRezultatPovisaniStroski.BeforeLoadDFMValues;
  ploscicaZavarovalninaPrazna_2.BeforeLoadDFMValues;
  ploscicaZavarovalninaPrazna_3a.BeforeLoadDFMValues;
  izRezultatIzpadInStroski.BeforeLoadDFMValues;
  ploscicaZavarovalninaPrazna_3b.BeforeLoadDFMValues;
  izRezultatPrihranki.BeforeLoadDFMValues;
  ploscicaZavarovalninaPrazna_4.BeforeLoadDFMValues;
  ploscicaZavarovalninaPrazna_5a.BeforeLoadDFMValues;
  izRezultatZavarovanaSkoda.BeforeLoadDFMValues;
  ploscicaZavarovalninaPrazna_5b.BeforeLoadDFMValues;
  izRezultatPodzavarovanje.BeforeLoadDFMValues;
  ploscicaZavarovalninaPrazna_6.BeforeLoadDFMValues;
  slikaZavarovalnina_2.BeforeLoadDFMValues;
  izRezultatZavarovalnina.BeforeLoadDFMValues;
  ploscicaZavarovalninaPrazna_7.BeforeLoadDFMValues;
  slikaZavarovalnina_3.BeforeLoadDFMValues;
  izRezultatDejanskoOcenjenaSkoda.BeforeLoadDFMValues;
  ploscicaZavarovalninaPrazna_8.BeforeLoadDFMValues;
  ploscicaNaslov.BeforeLoadDFMValues;
  izNaslov.BeforeLoadDFMValues;
  PovezavaWSIzracunSkode.BeforeLoadDFMValues;
  WebClientIzracunSkode.BeforeLoadDFMValues;
  try
    Name := 'fmSimulacijaSkode';
    Width := 850;
    Height := 700;
    ploscicaIkoneZgoraj.SetParentComponent(Self);
    ploscicaIkoneZgoraj.Name := 'ploscicaIkoneZgoraj';
    ploscicaIkoneZgoraj.Left := 0;
    ploscicaIkoneZgoraj.Top := 30;
    ploscicaIkoneZgoraj.Width := 850;
    ploscicaIkoneZgoraj.Height := 30;
    ploscicaIkoneZgoraj.HeightPercent := 100.000000000000000000;
    ploscicaIkoneZgoraj.WidthPercent := 100.000000000000000000;
    ploscicaIkoneZgoraj.Align := alTop;
    ploscicaIkoneZgoraj.BorderColor := clNone;
    ploscicaIkoneZgoraj.BorderStyle := bsNone;
    ploscicaIkoneZgoraj.Color := clSilver;
    izKoraki.SetParentComponent(ploscicaIkoneZgoraj);
    izKoraki.Name := 'izKoraki';
    izKoraki.AlignWithMargins := True;
    izKoraki.Left := 15;
    izKoraki.Top := 8;
    izKoraki.Width := 36;
    izKoraki.Height := 14;
    izKoraki.Margins.Left := 15;
    izKoraki.Margins.Top := 8;
    izKoraki.Margins.Bottom := 8;
    izKoraki.Align := alLeft;
    izKoraki.Caption := 'Koraki:';
    izKoraki.HeightPercent := 100.000000000000000000;
    izKoraki.WidthPercent := 100.000000000000000000;
    gIzhod.SetParentComponent(ploscicaIkoneZgoraj);
    gIzhod.Name := 'gIzhod';
    gIzhod.Left := 772;
    gIzhod.Top := 3;
    gIzhod.Width := 75;
    gIzhod.Height := 24;
    gIzhod.Caption := 'Izhod';
    gIzhod.Color := clNone;
    gIzhod.Flat := True;
    gIzhod.Glyph.LoadFromFile('MBI_u04.ploscicaIkoneZgoraj.gIzhod.Glyph.png');
    gIzhod.HeightPercent := 100.000000000000000000;
    gIzhod.WidthPercent := 100.000000000000000000;
    SetEvent(gIzhod, Self, 'OnClick', 'gIzhodClick');
    plosicaG5.SetParentComponent(ploscicaIkoneZgoraj);
    plosicaG5.Name := 'plosicaG5';
    plosicaG5.Left := 254;
    plosicaG5.Top := 0;
    plosicaG5.Width := 50;
    plosicaG5.Height := 30;
    plosicaG5.HeightPercent := 100.000000000000000000;
    plosicaG5.WidthPercent := 100.000000000000000000;
    plosicaG5.Align := alLeft;
    plosicaG5.BorderColor := clNone;
    plosicaG5.BorderStyle := bsNone;
    plosicaG5.ChildOrder := 1;
    plosicaG5.Color := clWindow;
    g5.SetParentComponent(plosicaG5);
    g5.Name := 'g5';
    g5.Left := 26;
    g5.Top := 0;
    g5.Width := 24;
    g5.Height := 30;
    g5.Align := alRight;
    g5.Color := clNone;
    g5.Flat := True;
    g5.Glyph.LoadFromFile('MBI_u04.plosicaG5.g5.Glyph.png');
    g5.HeightPercent := 100.000000000000000000;
    g5.Layout := blGlyphBottom;
    g5.WidthPercent := 100.000000000000000000;
    SetEvent(g5, Self, 'OnClick', 'gumbKorakClick');
    ploscicaG4.SetParentComponent(ploscicaIkoneZgoraj);
    ploscicaG4.Name := 'ploscicaG4';
    ploscicaG4.Left := 204;
    ploscicaG4.Top := 0;
    ploscicaG4.Width := 50;
    ploscicaG4.Height := 30;
    ploscicaG4.HeightPercent := 100.000000000000000000;
    ploscicaG4.WidthPercent := 100.000000000000000000;
    ploscicaG4.Align := alLeft;
    ploscicaG4.BorderColor := clNone;
    ploscicaG4.BorderStyle := bsNone;
    ploscicaG4.ChildOrder := 2;
    ploscicaG4.Color := clWindow;
    g4.SetParentComponent(ploscicaG4);
    g4.Name := 'g4';
    g4.Left := 26;
    g4.Top := 0;
    g4.Width := 24;
    g4.Height := 30;
    g4.Align := alRight;
    g4.Color := clNone;
    g4.Flat := True;
    g4.Glyph.LoadFromFile('MBI_u04.ploscicaG4.g4.Glyph.png');
    g4.HeightPercent := 100.000000000000000000;
    g4.Layout := blGlyphBottom;
    g4.WidthPercent := 100.000000000000000000;
    SetEvent(g4, Self, 'OnClick', 'gumbKorakClick');
    ploscicaG3.SetParentComponent(ploscicaIkoneZgoraj);
    ploscicaG3.Name := 'ploscicaG3';
    ploscicaG3.Left := 154;
    ploscicaG3.Top := 0;
    ploscicaG3.Width := 50;
    ploscicaG3.Height := 30;
    ploscicaG3.HeightPercent := 100.000000000000000000;
    ploscicaG3.WidthPercent := 100.000000000000000000;
    ploscicaG3.Align := alLeft;
    ploscicaG3.BorderColor := clNone;
    ploscicaG3.BorderStyle := bsNone;
    ploscicaG3.ChildOrder := 3;
    ploscicaG3.Color := clWindow;
    g3.SetParentComponent(ploscicaG3);
    g3.Name := 'g3';
    g3.Left := 26;
    g3.Top := 0;
    g3.Width := 24;
    g3.Height := 30;
    g3.Align := alRight;
    g3.Color := clNone;
    g3.Flat := True;
    g3.Glyph.LoadFromFile('MBI_u04.ploscicaG3.g3.Glyph.png');
    g3.HeightPercent := 100.000000000000000000;
    g3.Layout := blGlyphBottom;
    g3.WidthPercent := 100.000000000000000000;
    SetEvent(g3, Self, 'OnClick', 'gumbKorakClick');
    ploscicaG2.SetParentComponent(ploscicaIkoneZgoraj);
    ploscicaG2.Name := 'ploscicaG2';
    ploscicaG2.Left := 104;
    ploscicaG2.Top := 0;
    ploscicaG2.Width := 50;
    ploscicaG2.Height := 30;
    ploscicaG2.HeightPercent := 100.000000000000000000;
    ploscicaG2.WidthPercent := 100.000000000000000000;
    ploscicaG2.Align := alLeft;
    ploscicaG2.BorderColor := clNone;
    ploscicaG2.BorderStyle := bsNone;
    ploscicaG2.ChildOrder := 4;
    ploscicaG2.Color := clWindow;
    g2.SetParentComponent(ploscicaG2);
    g2.Name := 'g2';
    g2.Left := 26;
    g2.Top := 0;
    g2.Width := 24;
    g2.Height := 30;
    g2.Align := alRight;
    g2.Color := clNone;
    g2.Flat := True;
    g2.Glyph.LoadFromFile('MBI_u04.ploscicaG2.g2.Glyph.png');
    g2.HeightPercent := 100.000000000000000000;
    g2.Layout := blGlyphBottom;
    g2.WidthPercent := 100.000000000000000000;
    SetEvent(g2, Self, 'OnClick', 'gumbKorakClick');
    ploscicaG1.SetParentComponent(ploscicaIkoneZgoraj);
    ploscicaG1.Name := 'ploscicaG1';
    ploscicaG1.Left := 54;
    ploscicaG1.Top := 0;
    ploscicaG1.Width := 50;
    ploscicaG1.Height := 30;
    ploscicaG1.HeightPercent := 100.000000000000000000;
    ploscicaG1.WidthPercent := 100.000000000000000000;
    ploscicaG1.Align := alLeft;
    ploscicaG1.BorderColor := clNone;
    ploscicaG1.BorderStyle := bsNone;
    ploscicaG1.ChildOrder := 5;
    ploscicaG1.Color := clWindow;
    g1.SetParentComponent(ploscicaG1);
    g1.Name := 'g1';
    g1.Left := 26;
    g1.Top := 0;
    g1.Width := 24;
    g1.Height := 30;
    g1.Align := alRight;
    g1.Color := clNone;
    g1.Flat := True;
    g1.Glyph.LoadFromFile('MBI_u04.ploscicaG1.g1.Glyph.png');
    g1.HeightPercent := 100.000000000000000000;
    g1.Layout := blGlyphBottom;
    g1.WidthPercent := 100.000000000000000000;
    SetEvent(g1, Self, 'OnClick', 'gumbKorakClick');
    ploscicaIkoneSpodaj.SetParentComponent(Self);
    ploscicaIkoneSpodaj.Name := 'ploscicaIkoneSpodaj';
    ploscicaIkoneSpodaj.Left := 0;
    ploscicaIkoneSpodaj.Top := 670;
    ploscicaIkoneSpodaj.Width := 850;
    ploscicaIkoneSpodaj.Height := 30;
    ploscicaIkoneSpodaj.HeightPercent := 100.000000000000000000;
    ploscicaIkoneSpodaj.WidthPercent := 100.000000000000000000;
    ploscicaIkoneSpodaj.Align := alBottom;
    ploscicaIkoneSpodaj.BorderColor := clSilver;
    ploscicaIkoneSpodaj.BorderStyle := bsNone;
    ploscicaIkoneSpodaj.ChildOrder := 1;
    ploscicaIkoneSpodaj.Color := clSilver;
    ploscicaGSimulacije.SetParentComponent(ploscicaIkoneSpodaj);
    ploscicaGSimulacije.Name := 'ploscicaGSimulacije';
    ploscicaGSimulacije.Left := 740;
    ploscicaGSimulacije.Top := 0;
    ploscicaGSimulacije.Width := 110;
    ploscicaGSimulacije.Height := 30;
    ploscicaGSimulacije.HeightPercent := 100.000000000000000000;
    ploscicaGSimulacije.WidthPercent := 100.000000000000000000;
    ploscicaGSimulacije.Align := alRight;
    ploscicaGSimulacije.BorderColor := clNone;
    ploscicaGSimulacije.BorderStyle := bsNone;
    ploscicaGSimulacije.ChildOrder := 1;
    ploscicaGSimulacije.Color := clWindow;
    gSimulacije.SetParentComponent(ploscicaGSimulacije);
    gSimulacije.Name := 'gSimulacije';
    gSimulacije.Left := 7;
    gSimulacije.Top := 1;
    gSimulacije.Width := 100;
    gSimulacije.Height := 24;
    gSimulacije.Caption := 'Simulacije';
    gSimulacije.Color := clNone;
    gSimulacije.Flat := True;
    gSimulacije.Glyph.LoadFromFile('MBI_u04.ploscicaGSimulacije.gSimulacije.Glyph.png');
    gSimulacije.HeightPercent := 100.000000000000000000;
    gSimulacije.WidthPercent := 100.000000000000000000;
    SetEvent(gSimulacije, Self, 'OnClick', 'gSimulacijeClick');
    WebGridPanel1.SetParentComponent(ploscicaIkoneSpodaj);
    WebGridPanel1.Name := 'WebGridPanel1';
    WebGridPanel1.AlignWithMargins := True;
    WebGridPanel1.Left := 10;
    WebGridPanel1.Top := 0;
    WebGridPanel1.Width := 735;
    WebGridPanel1.Height := 30;
    WebGridPanel1.Margins.Left := 10;
    WebGridPanel1.Margins.Top := 0;
    WebGridPanel1.Margins.Bottom := 0;
    WebGridPanel1.HeightPercent := 100.000000000000000000;
    WebGridPanel1.WidthPercent := 100.000000000000000000;
    WebGridPanel1.Align := alLeft;
    WebGridPanel1.ChildOrder := 3;
    WebGridPanel1.ColumnCollection.Clear;
    with WebGridPanel1.ColumnCollection.Add do
    begin
      Value := 15;
    end;
    with WebGridPanel1.ColumnCollection.Add do
    begin
      Value := 14;
    end;
    with WebGridPanel1.ColumnCollection.Add do
    begin
      Value := 25;
    end;
    with WebGridPanel1.ColumnCollection.Add do
    begin
      Value := 25;
    end;
    with WebGridPanel1.ColumnCollection.Add do
    begin
      Value := 12;
    end;
    with WebGridPanel1.ColumnCollection.Add do
    begin
      Value := 9;
    end;
    WebGridPanel1.ControlCollection.Clear;
    with WebGridPanel1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izSimulacijaNogaTekstMDJ;
    end;
    with WebGridPanel1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izSimulacijaNogaTekstZV;
    end;
    with WebGridPanel1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izSimulacijaNogaTekstPrihranki;
    end;
    with WebGridPanel1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izSimulacijaNogaTekstStroski;
    end;
    with WebGridPanel1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izSimulacijaNogaTekstRast;
    end;
    with WebGridPanel1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izSimulacijaNogaTekstPremija;
    end;
    with WebGridPanel1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izSimulacijaNogaMDJ;
    end;
    with WebGridPanel1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izSimulacijaNogaZV;
    end;
    with WebGridPanel1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izSimulacijaNogaPrihranki;
    end;
    with WebGridPanel1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izSimulacijaNogaStroski;
    end;
    with WebGridPanel1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izSimulacijaNogaRast;
    end;
    with WebGridPanel1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izSimulacijaNogaPremija;
    end;
    WebGridPanel1.Color := clNone;
    WebGridPanel1.GridLineColor := clBlack;
    WebGridPanel1.RowCollection.Clear;
    with WebGridPanel1.RowCollection.Add do
    begin
      Value := 50;
    end;
    with WebGridPanel1.RowCollection.Add do
    begin
      Value := 50;
    end;
    izSimulacijaNogaTekstMDJ.SetParentComponent(WebGridPanel1);
    izSimulacijaNogaTekstMDJ.Name := 'izSimulacijaNogaTekstMDJ';
    izSimulacijaNogaTekstMDJ.Left := 2;
    izSimulacijaNogaTekstMDJ.Top := 2;
    izSimulacijaNogaTekstMDJ.Width := 108;
    izSimulacijaNogaTekstMDJ.Height := 11;
    izSimulacijaNogaTekstMDJ.Anchors := [];
    izSimulacijaNogaTekstMDJ.AutoSize := False;
    izSimulacijaNogaTekstMDJ.Caption := 'Max. indemnity period:';
    izSimulacijaNogaTekstMDJ.Color := clNone;
    izSimulacijaNogaTekstMDJ.Font.Charset := DEFAULT_CHARSET;
    izSimulacijaNogaTekstMDJ.Font.Color := clWindowText;
    izSimulacijaNogaTekstMDJ.Font.Height := -9;
    izSimulacijaNogaTekstMDJ.Font.Name := 'Tahoma';
    izSimulacijaNogaTekstMDJ.Font.Style := [fsItalic,fsUnderline];
    izSimulacijaNogaTekstMDJ.HeightPercent := 100.000000000000000000;
    izSimulacijaNogaTekstMDJ.ParentFont := False;
    izSimulacijaNogaTekstMDJ.WidthPercent := 100.000000000000000000;
    izSimulacijaNogaTekstZV.SetParentComponent(WebGridPanel1);
    izSimulacijaNogaTekstZV.Name := 'izSimulacijaNogaTekstZV';
    izSimulacijaNogaTekstZV.Left := 112;
    izSimulacijaNogaTekstZV.Top := 2;
    izSimulacijaNogaTekstZV.Width := 90;
    izSimulacijaNogaTekstZV.Height := 11;
    izSimulacijaNogaTekstZV.Anchors := [];
    izSimulacijaNogaTekstZV.AutoSize := False;
    izSimulacijaNogaTekstZV.Caption := 'Zavarovalna vsota:';
    izSimulacijaNogaTekstZV.Color := clNone;
    izSimulacijaNogaTekstZV.Font.Charset := DEFAULT_CHARSET;
    izSimulacijaNogaTekstZV.Font.Color := clWindowText;
    izSimulacijaNogaTekstZV.Font.Height := -9;
    izSimulacijaNogaTekstZV.Font.Name := 'Tahoma';
    izSimulacijaNogaTekstZV.Font.Style := [fsItalic,fsUnderline];
    izSimulacijaNogaTekstZV.HeightPercent := 100.000000000000000000;
    izSimulacijaNogaTekstZV.ParentFont := False;
    izSimulacijaNogaTekstZV.WidthPercent := 100.000000000000000000;
    izSimulacijaNogaTekstPrihranki.SetParentComponent(WebGridPanel1);
    izSimulacijaNogaTekstPrihranki.Name := 'izSimulacijaNogaTekstPrihranki';
    izSimulacijaNogaTekstPrihranki.Left := 215;
    izSimulacijaNogaTekstPrihranki.Top := 2;
    izSimulacijaNogaTekstPrihranki.Width := 180;
    izSimulacijaNogaTekstPrihranki.Height := 11;
    izSimulacijaNogaTekstPrihranki.Anchors := [];
    izSimulacijaNogaTekstPrihranki.AutoSize := False;
    izSimulacijaNogaTekstPrihranki.Caption := 'Obdobje prihrankov:';
    izSimulacijaNogaTekstPrihranki.Color := clNone;
    izSimulacijaNogaTekstPrihranki.Font.Charset := DEFAULT_CHARSET;
    izSimulacijaNogaTekstPrihranki.Font.Color := clWindowText;
    izSimulacijaNogaTekstPrihranki.Font.Height := -9;
    izSimulacijaNogaTekstPrihranki.Font.Name := 'Tahoma';
    izSimulacijaNogaTekstPrihranki.Font.Style := [fsItalic,fsUnderline];
    izSimulacijaNogaTekstPrihranki.HeightPercent := 100.000000000000000000;
    izSimulacijaNogaTekstPrihranki.ParentFont := False;
    izSimulacijaNogaTekstPrihranki.WidthPercent := 100.000000000000000000;
    izSimulacijaNogaTekstStroski.SetParentComponent(WebGridPanel1);
    izSimulacijaNogaTekstStroski.Name := 'izSimulacijaNogaTekstStroski';
    izSimulacijaNogaTekstStroski.Left := 399;
    izSimulacijaNogaTekstStroski.Top := 2;
    izSimulacijaNogaTekstStroski.Width := 180;
    izSimulacijaNogaTekstStroski.Height := 11;
    izSimulacijaNogaTekstStroski.Anchors := [];
    izSimulacijaNogaTekstStroski.AutoSize := False;
    izSimulacijaNogaTekstStroski.Caption := 'Obdobje povi'#353'anmih stro'#353'kov:';
    izSimulacijaNogaTekstStroski.Color := clNone;
    izSimulacijaNogaTekstStroski.Font.Charset := DEFAULT_CHARSET;
    izSimulacijaNogaTekstStroski.Font.Color := clWindowText;
    izSimulacijaNogaTekstStroski.Font.Height := -9;
    izSimulacijaNogaTekstStroski.Font.Name := 'Tahoma';
    izSimulacijaNogaTekstStroski.Font.Style := [fsItalic,fsUnderline];
    izSimulacijaNogaTekstStroski.HeightPercent := 100.000000000000000000;
    izSimulacijaNogaTekstStroski.ParentFont := False;
    izSimulacijaNogaTekstStroski.WidthPercent := 100.000000000000000000;
    izSimulacijaNogaTekstRast.SetParentComponent(WebGridPanel1);
    izSimulacijaNogaTekstRast.Name := 'izSimulacijaNogaTekstRast';
    izSimulacijaNogaTekstRast.Left := 583;
    izSimulacijaNogaTekstRast.Top := 2;
    izSimulacijaNogaTekstRast.Width := 85;
    izSimulacijaNogaTekstRast.Height := 11;
    izSimulacijaNogaTekstRast.Anchors := [];
    izSimulacijaNogaTekstRast.AutoSize := False;
    izSimulacijaNogaTekstRast.Caption := 'Pri'#269'akovana rast:';
    izSimulacijaNogaTekstRast.Color := clNone;
    izSimulacijaNogaTekstRast.Font.Charset := DEFAULT_CHARSET;
    izSimulacijaNogaTekstRast.Font.Color := clWindowText;
    izSimulacijaNogaTekstRast.Font.Height := -9;
    izSimulacijaNogaTekstRast.Font.Name := 'Tahoma';
    izSimulacijaNogaTekstRast.Font.Style := [fsItalic,fsUnderline];
    izSimulacijaNogaTekstRast.HeightPercent := 100.000000000000000000;
    izSimulacijaNogaTekstRast.ParentFont := False;
    izSimulacijaNogaTekstRast.WidthPercent := 100.000000000000000000;
    izSimulacijaNogaTekstPremija.SetParentComponent(WebGridPanel1);
    izSimulacijaNogaTekstPremija.Name := 'izSimulacijaNogaTekstPremija';
    izSimulacijaNogaTekstPremija.Left := 671;
    izSimulacijaNogaTekstPremija.Top := 2;
    izSimulacijaNogaTekstPremija.Width := 66;
    izSimulacijaNogaTekstPremija.Height := 11;
    izSimulacijaNogaTekstPremija.Anchors := [];
    izSimulacijaNogaTekstPremija.AutoSize := False;
    izSimulacijaNogaTekstPremija.Caption := 'Premija:';
    izSimulacijaNogaTekstPremija.Color := clNone;
    izSimulacijaNogaTekstPremija.Font.Charset := DEFAULT_CHARSET;
    izSimulacijaNogaTekstPremija.Font.Color := clWindowText;
    izSimulacijaNogaTekstPremija.Font.Height := -9;
    izSimulacijaNogaTekstPremija.Font.Name := 'Tahoma';
    izSimulacijaNogaTekstPremija.Font.Style := [fsItalic,fsUnderline];
    izSimulacijaNogaTekstPremija.HeightPercent := 100.000000000000000000;
    izSimulacijaNogaTekstPremija.ParentFont := False;
    izSimulacijaNogaTekstPremija.WidthPercent := 100.000000000000000000;
    izSimulacijaNogaMDJ.SetParentComponent(WebGridPanel1);
    izSimulacijaNogaMDJ.Name := 'izSimulacijaNogaMDJ';
    izSimulacijaNogaMDJ.Left := 2;
    izSimulacijaNogaMDJ.Top := 17;
    izSimulacijaNogaMDJ.Width := 108;
    izSimulacijaNogaMDJ.Height := 11;
    izSimulacijaNogaMDJ.Anchors := [];
    izSimulacijaNogaMDJ.AutoSize := False;
    izSimulacijaNogaMDJ.Caption := '12 mesece(v) [D]';
    izSimulacijaNogaMDJ.Color := clNone;
    izSimulacijaNogaMDJ.Font.Charset := DEFAULT_CHARSET;
    izSimulacijaNogaMDJ.Font.Color := clBlack;
    izSimulacijaNogaMDJ.Font.Height := -9;
    izSimulacijaNogaMDJ.Font.Name := 'Tahoma';
    izSimulacijaNogaMDJ.Font.Style := [];
    izSimulacijaNogaMDJ.HeightPercent := 100.000000000000000000;
    izSimulacijaNogaMDJ.ParentFont := False;
    izSimulacijaNogaMDJ.WidthPercent := 100.000000000000000000;
    izSimulacijaNogaZV.SetParentComponent(WebGridPanel1);
    izSimulacijaNogaZV.Name := 'izSimulacijaNogaZV';
    izSimulacijaNogaZV.Left := 112;
    izSimulacijaNogaZV.Top := 17;
    izSimulacijaNogaZV.Width := 90;
    izSimulacijaNogaZV.Height := 11;
    izSimulacijaNogaZV.Anchors := [];
    izSimulacijaNogaZV.AutoSize := False;
    izSimulacijaNogaZV.Caption := '10.000.000,00 [S]';
    izSimulacijaNogaZV.Color := clNone;
    izSimulacijaNogaZV.Font.Charset := DEFAULT_CHARSET;
    izSimulacijaNogaZV.Font.Color := clBlack;
    izSimulacijaNogaZV.Font.Height := -9;
    izSimulacijaNogaZV.Font.Name := 'Tahoma';
    izSimulacijaNogaZV.Font.Style := [];
    izSimulacijaNogaZV.HeightPercent := 100.000000000000000000;
    izSimulacijaNogaZV.ParentFont := False;
    izSimulacijaNogaZV.WidthPercent := 100.000000000000000000;
    izSimulacijaNogaPrihranki.SetParentComponent(WebGridPanel1);
    izSimulacijaNogaPrihranki.Name := 'izSimulacijaNogaPrihranki';
    izSimulacijaNogaPrihranki.Left := 215;
    izSimulacijaNogaPrihranki.Top := 17;
    izSimulacijaNogaPrihranki.Width := 180;
    izSimulacijaNogaPrihranki.Height := 11;
    izSimulacijaNogaPrihranki.Anchors := [];
    izSimulacijaNogaPrihranki.AutoSize := False;
    izSimulacijaNogaPrihranki.Caption := 'obdobje ugotovljenega zastoja  [D]';
    izSimulacijaNogaPrihranki.Color := clNone;
    izSimulacijaNogaPrihranki.Font.Charset := DEFAULT_CHARSET;
    izSimulacijaNogaPrihranki.Font.Color := clBlack;
    izSimulacijaNogaPrihranki.Font.Height := -9;
    izSimulacijaNogaPrihranki.Font.Name := 'Tahoma';
    izSimulacijaNogaPrihranki.Font.Style := [];
    izSimulacijaNogaPrihranki.HeightPercent := 100.000000000000000000;
    izSimulacijaNogaPrihranki.ParentFont := False;
    izSimulacijaNogaPrihranki.WidthPercent := 100.000000000000000000;
    izSimulacijaNogaStroski.SetParentComponent(WebGridPanel1);
    izSimulacijaNogaStroski.Name := 'izSimulacijaNogaStroski';
    izSimulacijaNogaStroski.Left := 399;
    izSimulacijaNogaStroski.Top := 17;
    izSimulacijaNogaStroski.Width := 180;
    izSimulacijaNogaStroski.Height := 11;
    izSimulacijaNogaStroski.Anchors := [];
    izSimulacijaNogaStroski.AutoSize := False;
    izSimulacijaNogaStroski.Caption := 'obdobje dogovorjene MDJ [D]';
    izSimulacijaNogaStroski.Color := clNone;
    izSimulacijaNogaStroski.Font.Charset := DEFAULT_CHARSET;
    izSimulacijaNogaStroski.Font.Color := clBlack;
    izSimulacijaNogaStroski.Font.Height := -9;
    izSimulacijaNogaStroski.Font.Name := 'Tahoma';
    izSimulacijaNogaStroski.Font.Style := [];
    izSimulacijaNogaStroski.HeightPercent := 100.000000000000000000;
    izSimulacijaNogaStroski.ParentFont := False;
    izSimulacijaNogaStroski.WidthPercent := 100.000000000000000000;
    izSimulacijaNogaRast.SetParentComponent(WebGridPanel1);
    izSimulacijaNogaRast.Name := 'izSimulacijaNogaRast';
    izSimulacijaNogaRast.Left := 583;
    izSimulacijaNogaRast.Top := 17;
    izSimulacijaNogaRast.Width := 85;
    izSimulacijaNogaRast.Height := 11;
    izSimulacijaNogaRast.Anchors := [];
    izSimulacijaNogaRast.AutoSize := False;
    izSimulacijaNogaRast.Caption := '10,0 % [S]';
    izSimulacijaNogaRast.Color := clNone;
    izSimulacijaNogaRast.Font.Charset := DEFAULT_CHARSET;
    izSimulacijaNogaRast.Font.Color := clBlack;
    izSimulacijaNogaRast.Font.Height := -9;
    izSimulacijaNogaRast.Font.Name := 'Tahoma';
    izSimulacijaNogaRast.Font.Style := [];
    izSimulacijaNogaRast.HeightPercent := 100.000000000000000000;
    izSimulacijaNogaRast.ParentFont := False;
    izSimulacijaNogaRast.WidthPercent := 100.000000000000000000;
    izSimulacijaNogaPremija.SetParentComponent(WebGridPanel1);
    izSimulacijaNogaPremija.Name := 'izSimulacijaNogaPremija';
    izSimulacijaNogaPremija.Left := 671;
    izSimulacijaNogaPremija.Top := 17;
    izSimulacijaNogaPremija.Width := 105;
    izSimulacijaNogaPremija.Height := 11;
    izSimulacijaNogaPremija.Anchors := [];
    izSimulacijaNogaPremija.AutoSize := False;
    izSimulacijaNogaPremija.Caption := '5.000,00 [S]';
    izSimulacijaNogaPremija.Color := clNone;
    izSimulacijaNogaPremija.Font.Charset := DEFAULT_CHARSET;
    izSimulacijaNogaPremija.Font.Color := clBlack;
    izSimulacijaNogaPremija.Font.Height := -9;
    izSimulacijaNogaPremija.Font.Name := 'Tahoma';
    izSimulacijaNogaPremija.Font.Style := [];
    izSimulacijaNogaPremija.HeightPercent := 100.000000000000000000;
    izSimulacijaNogaPremija.ParentFont := False;
    izSimulacijaNogaPremija.WidthPercent := 100.000000000000000000;
    SimulacijaSkode_Listi.SetParentComponent(Self);
    SimulacijaSkode_Listi.Name := 'SimulacijaSkode_Listi';
    SimulacijaSkode_Listi.AlignWithMargins := True;
    SimulacijaSkode_Listi.Left := 5;
    SimulacijaSkode_Listi.Top := 65;
    SimulacijaSkode_Listi.Width := 840;
    SimulacijaSkode_Listi.Height := 600;
    SimulacijaSkode_Listi.Margins.Left := 5;
    SimulacijaSkode_Listi.Margins.Top := 5;
    SimulacijaSkode_Listi.Margins.Right := 5;
    SimulacijaSkode_Listi.Margins.Bottom := 5;
    SimulacijaSkode_Listi.HeightPercent := 100.000000000000000000;
    SimulacijaSkode_Listi.WidthPercent := 100.000000000000000000;
    SimulacijaSkode_Listi.Align := alClient;
    SimulacijaSkode_Listi.ChildOrder := 2;
    SimulacijaSkode_Listi.Font.Charset := DEFAULT_CHARSET;
    SimulacijaSkode_Listi.Font.Color := clWindowText;
    SimulacijaSkode_Listi.Font.Height := -11;
    SimulacijaSkode_Listi.Font.Name := 'Arial';
    SimulacijaSkode_Listi.Font.Style := [];
    SimulacijaSkode_Listi.TabIndex := 3;
    SimulacijaSkode_Listi.ParentFont := False;
    SimulacijaSkode_Listi.SelectedColor := clScrollBar;
    SimulacijaSkode_Listi.TabOrder := 2;
    SimulacijaSkode_List1.SetParentComponent(SimulacijaSkode_Listi);
    SimulacijaSkode_List1.Name := 'SimulacijaSkode_List1';
    SimulacijaSkode_List1.Left := 0;
    SimulacijaSkode_List1.Top := 20;
    SimulacijaSkode_List1.Width := 840;
    SimulacijaSkode_List1.Height := 580;
    SimulacijaSkode_List1.HeightPercent := 100.000000000000000000;
    SimulacijaSkode_List1.WidthPercent := 100.000000000000000000;
    SimulacijaSkode_List1.Caption := 'WebPageControl1Sheet1';
    SimulacijaSkode_List1.ChildOrder := 3;
    SimulacijaSkode_List1.ShowCaption := False;
    SimulacijaSkode_List1.TabVisible := False;
    izNaslovList1.SetParentComponent(SimulacijaSkode_List1);
    izNaslovList1.Name := 'izNaslovList1';
    izNaslovList1.Left := 30;
    izNaslovList1.Top := 25;
    izNaslovList1.Width := 403;
    izNaslovList1.Height := 14;
    izNaslovList1.Caption := '1. korak: Izra'#269'un nepokritih zavarovanih stro'#353'kov in izgubljenega dobi'#269'ka';
    izNaslovList1.Color := clNone;
    izNaslovList1.Font.Charset := DEFAULT_CHARSET;
    izNaslovList1.Font.Color := clWindowText;
    izNaslovList1.Font.Height := -11;
    izNaslovList1.Font.Name := 'Arial';
    izNaslovList1.Font.Style := [fsBold];
    izNaslovList1.HeightPercent := 100.000000000000000000;
    izNaslovList1.ParentFont := False;
    izNaslovList1.WidthPercent := 100.000000000000000000;
    ploscicaIkoneList1.SetParentComponent(SimulacijaSkode_List1);
    ploscicaIkoneList1.Name := 'ploscicaIkoneList1';
    ploscicaIkoneList1.Left := 0;
    ploscicaIkoneList1.Top := 540;
    ploscicaIkoneList1.Width := 840;
    ploscicaIkoneList1.Height := 40;
    ploscicaIkoneList1.HeightPercent := 100.000000000000000000;
    ploscicaIkoneList1.WidthPercent := 100.000000000000000000;
    ploscicaIkoneList1.Align := alBottom;
    ploscicaIkoneList1.BorderColor := clNone;
    ploscicaIkoneList1.BorderStyle := bsNone;
    ploscicaIkoneList1.ChildOrder := 1;
    ploscicaIkoneList1.Color := clWindow;
    ploscicaGNaprej1.SetParentComponent(ploscicaIkoneList1);
    ploscicaGNaprej1.Name := 'ploscicaGNaprej1';
    ploscicaGNaprej1.Left := 790;
    ploscicaGNaprej1.Top := 0;
    ploscicaGNaprej1.Width := 50;
    ploscicaGNaprej1.Height := 40;
    ploscicaGNaprej1.HeightPercent := 100.000000000000000000;
    ploscicaGNaprej1.WidthPercent := 100.000000000000000000;
    ploscicaGNaprej1.Align := alRight;
    ploscicaGNaprej1.BorderColor := clNone;
    ploscicaGNaprej1.BorderStyle := bsNone;
    ploscicaGNaprej1.Color := clWindow;
    gNaprej1.SetParentComponent(ploscicaGNaprej1);
    gNaprej1.Name := 'gNaprej1';
    gNaprej1.Left := 0;
    gNaprej1.Top := 0;
    gNaprej1.Width := 35;
    gNaprej1.Height := 40;
    gNaprej1.Color := clNone;
    gNaprej1.Flat := True;
    gNaprej1.Glyph.LoadFromFile('MBI_u04.ploscicaGNaprej1.gNaprej1.Glyph.png');
    gNaprej1.HeightPercent := 100.000000000000000000;
    gNaprej1.WidthPercent := 100.000000000000000000;
    SetEvent(gNaprej1, Self, 'OnClick', 'gNaprejNazajClick');
    ploscicaTabelaNepokritiStroski.SetParentComponent(SimulacijaSkode_List1);
    ploscicaTabelaNepokritiStroski.Name := 'ploscicaTabelaNepokritiStroski';
    ploscicaTabelaNepokritiStroski.Left := 30;
    ploscicaTabelaNepokritiStroski.Top := 97;
    ploscicaTabelaNepokritiStroski.Width := 650;
    ploscicaTabelaNepokritiStroski.Height := 50;
    ploscicaTabelaNepokritiStroski.HeightPercent := 100.000000000000000000;
    ploscicaTabelaNepokritiStroski.WidthPercent := 100.000000000000000000;
    ploscicaTabelaNepokritiStroski.ChildOrder := 2;
    ploscicaTabelaNepokritiStroski.ColumnCollection.Clear;
    with ploscicaTabelaNepokritiStroski.ColumnCollection.Add do
    begin
      MarginLeft := 3;
      Value := 16;
    end;
    with ploscicaTabelaNepokritiStroski.ColumnCollection.Add do
    begin
      Alignment := taCenter;
      Value := 19;
    end;
    with ploscicaTabelaNepokritiStroski.ColumnCollection.Add do
    begin
      Alignment := taCenter;
      Value := 19;
    end;
    with ploscicaTabelaNepokritiStroski.ColumnCollection.Add do
    begin
      Alignment := taCenter;
      Value := 19;
    end;
    with ploscicaTabelaNepokritiStroski.ColumnCollection.Add do
    begin
      Alignment := taCenter;
      Value := 19;
    end;
    with ploscicaTabelaNepokritiStroski.ColumnCollection.Add do
    begin
      Alignment := taCenter;
      Value := 7;
    end;
    ploscicaTabelaNepokritiStroski.ControlCollection.Clear;
    with ploscicaTabelaNepokritiStroski.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaTabelaNepokritiStroski_Naslov1;
    end;
    with ploscicaTabelaNepokritiStroski.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaTabelaNepokritiStroski_Naslov2;
    end;
    with ploscicaTabelaNepokritiStroski.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaTabelaNepokritiStroski_Naslov3;
    end;
    with ploscicaTabelaNepokritiStroski.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaTabelaNepokritiStroski_Naslov4;
    end;
    with ploscicaTabelaNepokritiStroski.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaTabelaNepokritiStroski_Naslov5;
    end;
    with ploscicaTabelaNepokritiStroski.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaTabelaNepokritiStroski_Naslov6;
    end;
    ploscicaTabelaNepokritiStroski.Color := 15461355;
    ploscicaTabelaNepokritiStroski.GridLineWidth := 1;
    ploscicaTabelaNepokritiStroski.GridLineColor := clGray;
    ploscicaTabelaNepokritiStroski.RowCollection.Clear;
    with ploscicaTabelaNepokritiStroski.RowCollection.Add do
    begin
      Alignment := vaCenter;
      SizeStyle := ssAbsolute;
      Value := 40;
    end;
    ploscicaTabelaNepokritiStroski_Naslov1.SetParentComponent(ploscicaTabelaNepokritiStroski);
    ploscicaTabelaNepokritiStroski_Naslov1.Name := 'ploscicaTabelaNepokritiStroski_Naslov1';
    ploscicaTabelaNepokritiStroski_Naslov1.Left := 5;
    ploscicaTabelaNepokritiStroski_Naslov1.Top := 13;
    ploscicaTabelaNepokritiStroski_Naslov1.Width := 63;
    ploscicaTabelaNepokritiStroski_Naslov1.Height := 14;
    ploscicaTabelaNepokritiStroski_Naslov1.Anchors := [];
    ploscicaTabelaNepokritiStroski_Naslov1.Caption := 'mesec in leto';
    ploscicaTabelaNepokritiStroski_Naslov1.Color := clNone;
    ploscicaTabelaNepokritiStroski_Naslov1.HeightPercent := 100.000000000000000000;
    ploscicaTabelaNepokritiStroski_Naslov1.WidthPercent := 100.000000000000000000;
    ploscicaTabelaNepokritiStroski_Naslov2.SetParentComponent(ploscicaTabelaNepokritiStroski);
    ploscicaTabelaNepokritiStroski_Naslov2.Name := 'ploscicaTabelaNepokritiStroski_Naslov2';
    ploscicaTabelaNepokritiStroski_Naslov2.Left := 124;
    ploscicaTabelaNepokritiStroski_Naslov2.Top := 11;
    ploscicaTabelaNepokritiStroski_Naslov2.Width := 120;
    ploscicaTabelaNepokritiStroski_Naslov2.Height := 28;
    ploscicaTabelaNepokritiStroski_Naslov2.Align := alCustom;
    ploscicaTabelaNepokritiStroski_Naslov2.AutoSize := False;
    ploscicaTabelaNepokritiStroski_Naslov2.BorderStyle := bsNone;
    ploscicaTabelaNepokritiStroski_Naslov2.HeightPercent := 100.000000000000000000;
    ploscicaTabelaNepokritiStroski_Naslov2.Lines.BeginUpdate;
    try
      ploscicaTabelaNepokritiStroski_Naslov2.Lines.Clear;
      ploscicaTabelaNepokritiStroski_Naslov2.Lines.Add('Realizacija x');
      ploscicaTabelaNepokritiStroski_Naslov2.Lines.Add('(x = 2020 / 2021)');
    finally
      ploscicaTabelaNepokritiStroski_Naslov2.Lines.EndUpdate;
    end;
    ploscicaTabelaNepokritiStroski_Naslov2.ParentColor := True;
    ploscicaTabelaNepokritiStroski_Naslov2.ReadOnly := True;
    ploscicaTabelaNepokritiStroski_Naslov2.SelLength := 0;
    ploscicaTabelaNepokritiStroski_Naslov2.SelStart := 34;
    ploscicaTabelaNepokritiStroski_Naslov2.ShowFocus := False;
    ploscicaTabelaNepokritiStroski_Naslov2.SpellCheck := False;
    ploscicaTabelaNepokritiStroski_Naslov2.WidthPercent := 100.000000000000000000;
    ploscicaTabelaNepokritiStroski_Naslov3.SetParentComponent(ploscicaTabelaNepokritiStroski);
    ploscicaTabelaNepokritiStroski_Naslov3.Name := 'ploscicaTabelaNepokritiStroski_Naslov3';
    ploscicaTabelaNepokritiStroski_Naslov3.Left := 248;
    ploscicaTabelaNepokritiStroski_Naslov3.Top := 14;
    ploscicaTabelaNepokritiStroski_Naslov3.Width := 120;
    ploscicaTabelaNepokritiStroski_Naslov3.Height := 16;
    ploscicaTabelaNepokritiStroski_Naslov3.Align := alCustom;
    ploscicaTabelaNepokritiStroski_Naslov3.AutoSize := False;
    ploscicaTabelaNepokritiStroski_Naslov3.BorderStyle := bsNone;
    ploscicaTabelaNepokritiStroski_Naslov3.HeightPercent := 100.000000000000000000;
    ploscicaTabelaNepokritiStroski_Naslov3.Lines.BeginUpdate;
    try
      ploscicaTabelaNepokritiStroski_Naslov3.Lines.Clear;
      ploscicaTabelaNepokritiStroski_Naslov3.Lines.Add('Plan x + 1');
    finally
      ploscicaTabelaNepokritiStroski_Naslov3.Lines.EndUpdate;
    end;
    ploscicaTabelaNepokritiStroski_Naslov3.ParentColor := True;
    ploscicaTabelaNepokritiStroski_Naslov3.ReadOnly := True;
    ploscicaTabelaNepokritiStroski_Naslov3.SelLength := 0;
    ploscicaTabelaNepokritiStroski_Naslov3.SelStart := 12;
    ploscicaTabelaNepokritiStroski_Naslov3.ShowFocus := False;
    ploscicaTabelaNepokritiStroski_Naslov3.SpellCheck := False;
    ploscicaTabelaNepokritiStroski_Naslov3.WidthPercent := 100.000000000000000000;
    ploscicaTabelaNepokritiStroski_Naslov3.WordWrap := False;
    ploscicaTabelaNepokritiStroski_Naslov4.SetParentComponent(ploscicaTabelaNepokritiStroski);
    ploscicaTabelaNepokritiStroski_Naslov4.Name := 'ploscicaTabelaNepokritiStroski_Naslov4';
    ploscicaTabelaNepokritiStroski_Naslov4.Left := 374;
    ploscicaTabelaNepokritiStroski_Naslov4.Top := 14;
    ploscicaTabelaNepokritiStroski_Naslov4.Width := 120;
    ploscicaTabelaNepokritiStroski_Naslov4.Height := 28;
    ploscicaTabelaNepokritiStroski_Naslov4.Align := alCustom;
    ploscicaTabelaNepokritiStroski_Naslov4.AutoSize := False;
    ploscicaTabelaNepokritiStroski_Naslov4.BorderStyle := bsNone;
    ploscicaTabelaNepokritiStroski_Naslov4.HeightPercent := 100.000000000000000000;
    ploscicaTabelaNepokritiStroski_Naslov4.Lines.BeginUpdate;
    try
      ploscicaTabelaNepokritiStroski_Naslov4.Lines.Clear;
      ploscicaTabelaNepokritiStroski_Naslov4.Lines.Add('Dejanska realizacija x + 1');
    finally
      ploscicaTabelaNepokritiStroski_Naslov4.Lines.EndUpdate;
    end;
    ploscicaTabelaNepokritiStroski_Naslov4.ParentColor := True;
    ploscicaTabelaNepokritiStroski_Naslov4.ReadOnly := True;
    ploscicaTabelaNepokritiStroski_Naslov4.SelLength := 0;
    ploscicaTabelaNepokritiStroski_Naslov4.SelStart := 28;
    ploscicaTabelaNepokritiStroski_Naslov4.ShowFocus := False;
    ploscicaTabelaNepokritiStroski_Naslov4.SpellCheck := False;
    ploscicaTabelaNepokritiStroski_Naslov4.WidthPercent := 100.000000000000000000;
    ploscicaTabelaNepokritiStroski_Naslov4.WordWrap := False;
    ploscicaTabelaNepokritiStroski_Naslov5.SetParentComponent(ploscicaTabelaNepokritiStroski);
    ploscicaTabelaNepokritiStroski_Naslov5.Name := 'ploscicaTabelaNepokritiStroski_Naslov5';
    ploscicaTabelaNepokritiStroski_Naslov5.Left := 499;
    ploscicaTabelaNepokritiStroski_Naslov5.Top := 11;
    ploscicaTabelaNepokritiStroski_Naslov5.Width := 120;
    ploscicaTabelaNepokritiStroski_Naslov5.Height := 28;
    ploscicaTabelaNepokritiStroski_Naslov5.Align := alCustom;
    ploscicaTabelaNepokritiStroski_Naslov5.AutoSize := False;
    ploscicaTabelaNepokritiStroski_Naslov5.BorderStyle := bsNone;
    ploscicaTabelaNepokritiStroski_Naslov5.HeightPercent := 100.000000000000000000;
    ploscicaTabelaNepokritiStroski_Naslov5.Lines.BeginUpdate;
    try
      ploscicaTabelaNepokritiStroski_Naslov5.Lines.Clear;
      ploscicaTabelaNepokritiStroski_Naslov5.Lines.Add('Razlika med planom in realizacijo');
    finally
      ploscicaTabelaNepokritiStroski_Naslov5.Lines.EndUpdate;
    end;
    ploscicaTabelaNepokritiStroski_Naslov5.ParentColor := True;
    ploscicaTabelaNepokritiStroski_Naslov5.ReadOnly := True;
    ploscicaTabelaNepokritiStroski_Naslov5.SelLength := 0;
    ploscicaTabelaNepokritiStroski_Naslov5.SelStart := 35;
    ploscicaTabelaNepokritiStroski_Naslov5.ShowFocus := False;
    ploscicaTabelaNepokritiStroski_Naslov5.SpellCheck := False;
    ploscicaTabelaNepokritiStroski_Naslov5.WidthPercent := 100.000000000000000000;
    ploscicaTabelaNepokritiStroski_Naslov5.WordWrap := False;
    ploscicaTabelaNepokritiStroski_Naslov6.SetParentComponent(ploscicaTabelaNepokritiStroski);
    ploscicaTabelaNepokritiStroski_Naslov6.Name := 'ploscicaTabelaNepokritiStroski_Naslov6';
    ploscicaTabelaNepokritiStroski_Naslov6.Left := 602;
    ploscicaTabelaNepokritiStroski_Naslov6.Top := 14;
    ploscicaTabelaNepokritiStroski_Naslov6.Width := 40;
    ploscicaTabelaNepokritiStroski_Naslov6.Height := 16;
    ploscicaTabelaNepokritiStroski_Naslov6.Align := alCustom;
    ploscicaTabelaNepokritiStroski_Naslov6.AutoSize := False;
    ploscicaTabelaNepokritiStroski_Naslov6.BorderStyle := bsNone;
    ploscicaTabelaNepokritiStroski_Naslov6.HeightPercent := 100.000000000000000000;
    ploscicaTabelaNepokritiStroski_Naslov6.Lines.BeginUpdate;
    try
      ploscicaTabelaNepokritiStroski_Naslov6.Lines.Clear;
      ploscicaTabelaNepokritiStroski_Naslov6.Lines.Add('Kritje');
    finally
      ploscicaTabelaNepokritiStroski_Naslov6.Lines.EndUpdate;
    end;
    ploscicaTabelaNepokritiStroski_Naslov6.ParentColor := True;
    ploscicaTabelaNepokritiStroski_Naslov6.ReadOnly := True;
    ploscicaTabelaNepokritiStroski_Naslov6.SelLength := 0;
    ploscicaTabelaNepokritiStroski_Naslov6.SelStart := 8;
    ploscicaTabelaNepokritiStroski_Naslov6.ShowFocus := False;
    ploscicaTabelaNepokritiStroski_Naslov6.SpellCheck := False;
    ploscicaTabelaNepokritiStroski_Naslov6.WidthPercent := 100.000000000000000000;
    ploscicaTabelaNepokritiStroski_Povzetek.SetParentComponent(SimulacijaSkode_List1);
    ploscicaTabelaNepokritiStroski_Povzetek.Name := 'ploscicaTabelaNepokritiStroski_Povzetek';
    ploscicaTabelaNepokritiStroski_Povzetek.Left := 30;
    ploscicaTabelaNepokritiStroski_Povzetek.Top := 501;
    ploscicaTabelaNepokritiStroski_Povzetek.Width := 650;
    ploscicaTabelaNepokritiStroski_Povzetek.Height := 95;
    ploscicaTabelaNepokritiStroski_Povzetek.HeightPercent := 100.000000000000000000;
    ploscicaTabelaNepokritiStroski_Povzetek.WidthPercent := 100.000000000000000000;
    ploscicaTabelaNepokritiStroski_Povzetek.ChildOrder := 3;
    ploscicaTabelaNepokritiStroski_Povzetek.ColumnCollection.Clear;
    with ploscicaTabelaNepokritiStroski_Povzetek.ColumnCollection.Add do
    begin
      Alignment := taRightJustify;
      MarginLeft := 3;
      Value := 72;
    end;
    with ploscicaTabelaNepokritiStroski_Povzetek.ColumnCollection.Add do
    begin
      Alignment := taCenter;
      Value := 23;
    end;
    with ploscicaTabelaNepokritiStroski_Povzetek.ColumnCollection.Add do
    begin
      Alignment := taCenter;
      Value := 5;
    end;
    ploscicaTabelaNepokritiStroski_Povzetek.ControlCollection.Clear;
    with ploscicaTabelaNepokritiStroski_Povzetek.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := IzpadPrometaTekst;
    end;
    with ploscicaTabelaNepokritiStroski_Povzetek.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izIzpadPrometa;
    end;
    with ploscicaTabelaNepokritiStroski_Povzetek.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := IzpadPrometaPrazno;
    end;
    with ploscicaTabelaNepokritiStroski_Povzetek.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ZavarovaniIzpadPrometaTekst;
    end;
    with ploscicaTabelaNepokritiStroski_Povzetek.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izZavarovaniIzpadPrometa;
    end;
    with ploscicaTabelaNepokritiStroski_Povzetek.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ZavarovaniIzpadPrometaPrazno;
    end;
    with ploscicaTabelaNepokritiStroski_Povzetek.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := NepokritiZavarovaniIzpadPrometaTekst;
    end;
    with ploscicaTabelaNepokritiStroski_Povzetek.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izNepokritiZavarovaniIzpadPrometa;
    end;
    with ploscicaTabelaNepokritiStroski_Povzetek.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := NepokritiZavarovaniIzpadPrometaPrazno;
    end;
    ploscicaTabelaNepokritiStroski_Povzetek.Color := clNone;
    ploscicaTabelaNepokritiStroski_Povzetek.GridLineColor := clGray;
    ploscicaTabelaNepokritiStroski_Povzetek.RowCollection.Clear;
    with ploscicaTabelaNepokritiStroski_Povzetek.RowCollection.Add do
    begin
      Alignment := vaCenter;
      SizeStyle := ssAbsolute;
      Value := 30;
    end;
    with ploscicaTabelaNepokritiStroski_Povzetek.RowCollection.Add do
    begin
      Value := 30;
    end;
    with ploscicaTabelaNepokritiStroski_Povzetek.RowCollection.Add do
    begin
      Value := 30;
    end;
    IzpadPrometaTekst.SetParentComponent(ploscicaTabelaNepokritiStroski_Povzetek);
    IzpadPrometaTekst.Name := 'IzpadPrometaTekst';
    IzpadPrometaTekst.Left := 392;
    IzpadPrometaTekst.Top := 8;
    IzpadPrometaTekst.Width := 74;
    IzpadPrometaTekst.Height := 14;
    IzpadPrometaTekst.Alignment := taRightJustify;
    IzpadPrometaTekst.Anchors := [];
    IzpadPrometaTekst.Caption := 'Izpad prometa: ';
    IzpadPrometaTekst.Color := clNone;
    IzpadPrometaTekst.HeightPercent := 100.000000000000000000;
    IzpadPrometaTekst.WidthPercent := 100.000000000000000000;
    ZavarovaniIzpadPrometaTekst.SetParentComponent(ploscicaTabelaNepokritiStroski_Povzetek);
    ZavarovaniIzpadPrometaTekst.Name := 'ZavarovaniIzpadPrometaTekst';
    ZavarovaniIzpadPrometaTekst.Left := 334;
    ZavarovaniIzpadPrometaTekst.Top := 32;
    ZavarovaniIzpadPrometaTekst.Width := 132;
    ZavarovaniIzpadPrometaTekst.Height := 14;
    ZavarovaniIzpadPrometaTekst.Alignment := taRightJustify;
    ZavarovaniIzpadPrometaTekst.Anchors := [];
    ZavarovaniIzpadPrometaTekst.Caption := 'Zavarovani izpad prometa: ';
    ZavarovaniIzpadPrometaTekst.Color := clNone;
    ZavarovaniIzpadPrometaTekst.HeightPercent := 100.000000000000000000;
    ZavarovaniIzpadPrometaTekst.WidthPercent := 100.000000000000000000;
    NepokritiZavarovaniIzpadPrometaTekst.SetParentComponent(ploscicaTabelaNepokritiStroski_Povzetek);
    NepokritiZavarovaniIzpadPrometaTekst.Name := 'NepokritiZavarovaniIzpadPrometaTekst';
    NepokritiZavarovaniIzpadPrometaTekst.Left := 78;
    NepokritiZavarovaniIzpadPrometaTekst.Top := 52;
    NepokritiZavarovaniIzpadPrometaTekst.Width := 388;
    NepokritiZavarovaniIzpadPrometaTekst.Height := 14;
    NepokritiZavarovaniIzpadPrometaTekst.Alignment := taRightJustify;
    NepokritiZavarovaniIzpadPrometaTekst.Anchors := [];
    NepokritiZavarovaniIzpadPrometaTekst.Caption := 'Nepokriti zavarovani stro'#353'ki: xx,xx% od zavarovanega izpada prometa:  ';
    NepokritiZavarovaniIzpadPrometaTekst.Color := clNone;
    NepokritiZavarovaniIzpadPrometaTekst.Font.Charset := DEFAULT_CHARSET;
    NepokritiZavarovaniIzpadPrometaTekst.Font.Color := clWindowText;
    NepokritiZavarovaniIzpadPrometaTekst.Font.Height := -11;
    NepokritiZavarovaniIzpadPrometaTekst.Font.Name := 'Arial';
    NepokritiZavarovaniIzpadPrometaTekst.Font.Style := [fsBold];
    NepokritiZavarovaniIzpadPrometaTekst.HeightPercent := 100.000000000000000000;
    NepokritiZavarovaniIzpadPrometaTekst.ParentFont := False;
    NepokritiZavarovaniIzpadPrometaTekst.WidthPercent := 100.000000000000000000;
    izIzpadPrometa.SetParentComponent(ploscicaTabelaNepokritiStroski_Povzetek);
    izIzpadPrometa.Name := 'izIzpadPrometa';
    izIzpadPrometa.Left := 478;
    izIzpadPrometa.Top := 4;
    izIzpadPrometa.Width := 130;
    izIzpadPrometa.Height := 22;
    izIzpadPrometa.TabStop := False;
    izIzpadPrometa.Alignment := taRightJustify;
    izIzpadPrometa.Anchors := [];
    izIzpadPrometa.AutoSelect := False;
    izIzpadPrometa.ChildOrder := 1;
    izIzpadPrometa.HeightPercent := 100.000000000000000000;
    izIzpadPrometa.ReadOnly := True;
    izIzpadPrometa.ShowFocus := False;
    izIzpadPrometa.Text := '0.00';
    izIzpadPrometa.WidthPercent := 100.000000000000000000;
    IzpadPrometaPrazno.SetParentComponent(ploscicaTabelaNepokritiStroski_Povzetek);
    IzpadPrometaPrazno.Name := 'IzpadPrometaPrazno';
    IzpadPrometaPrazno.Left := 621;
    IzpadPrometaPrazno.Top := 2;
    IzpadPrometaPrazno.Width := 26;
    IzpadPrometaPrazno.Height := 26;
    IzpadPrometaPrazno.HeightPercent := 100.000000000000000000;
    IzpadPrometaPrazno.WidthPercent := 100.000000000000000000;
    IzpadPrometaPrazno.Anchors := [];
    IzpadPrometaPrazno.BorderColor := clSilver;
    IzpadPrometaPrazno.BorderStyle := bsNone;
    IzpadPrometaPrazno.ChildOrder := 2;
    IzpadPrometaPrazno.Color := clWindow;
    izZavarovaniIzpadPrometa.SetParentComponent(ploscicaTabelaNepokritiStroski_Povzetek);
    izZavarovaniIzpadPrometa.Name := 'izZavarovaniIzpadPrometa';
    izZavarovaniIzpadPrometa.Left := 478;
    izZavarovaniIzpadPrometa.Top := 32;
    izZavarovaniIzpadPrometa.Width := 130;
    izZavarovaniIzpadPrometa.Height := 22;
    izZavarovaniIzpadPrometa.TabStop := False;
    izZavarovaniIzpadPrometa.Alignment := taRightJustify;
    izZavarovaniIzpadPrometa.Anchors := [];
    izZavarovaniIzpadPrometa.AutoSelect := False;
    izZavarovaniIzpadPrometa.ChildOrder := 4;
    izZavarovaniIzpadPrometa.HeightPercent := 100.000000000000000000;
    izZavarovaniIzpadPrometa.ReadOnly := True;
    izZavarovaniIzpadPrometa.ShowFocus := False;
    izZavarovaniIzpadPrometa.Text := '0.00';
    izZavarovaniIzpadPrometa.WidthPercent := 100.000000000000000000;
    ZavarovaniIzpadPrometaPrazno.SetParentComponent(ploscicaTabelaNepokritiStroski_Povzetek);
    ZavarovaniIzpadPrometaPrazno.Name := 'ZavarovaniIzpadPrometaPrazno';
    ZavarovaniIzpadPrometaPrazno.Left := 621;
    ZavarovaniIzpadPrometaPrazno.Top := 32;
    ZavarovaniIzpadPrometaPrazno.Width := 26;
    ZavarovaniIzpadPrometaPrazno.Height := 26;
    ZavarovaniIzpadPrometaPrazno.HeightPercent := 100.000000000000000000;
    ZavarovaniIzpadPrometaPrazno.WidthPercent := 100.000000000000000000;
    ZavarovaniIzpadPrometaPrazno.Anchors := [];
    ZavarovaniIzpadPrometaPrazno.BorderColor := clSilver;
    ZavarovaniIzpadPrometaPrazno.BorderStyle := bsNone;
    ZavarovaniIzpadPrometaPrazno.ChildOrder := 5;
    ZavarovaniIzpadPrometaPrazno.Color := clWindow;
    slikaIzpadPrometa.SetParentComponent(ZavarovaniIzpadPrometaPrazno);
    slikaIzpadPrometa.Name := 'slikaIzpadPrometa';
    slikaIzpadPrometa.Left := 5;
    slikaIzpadPrometa.Top := 5;
    slikaIzpadPrometa.Width := 16;
    slikaIzpadPrometa.Height := 16;
    slikaIzpadPrometa.HeightPercent := 100.000000000000000000;
    slikaIzpadPrometa.WidthPercent := 100.000000000000000000;
    izNepokritiZavarovaniIzpadPrometa.SetParentComponent(ploscicaTabelaNepokritiStroski_Povzetek);
    izNepokritiZavarovaniIzpadPrometa.Name := 'izNepokritiZavarovaniIzpadPrometa';
    izNepokritiZavarovaniIzpadPrometa.Left := 478;
    izNepokritiZavarovaniIzpadPrometa.Top := 52;
    izNepokritiZavarovaniIzpadPrometa.Width := 130;
    izNepokritiZavarovaniIzpadPrometa.Height := 22;
    izNepokritiZavarovaniIzpadPrometa.TabStop := False;
    izNepokritiZavarovaniIzpadPrometa.Alignment := taRightJustify;
    izNepokritiZavarovaniIzpadPrometa.Anchors := [];
    izNepokritiZavarovaniIzpadPrometa.AutoSelect := False;
    izNepokritiZavarovaniIzpadPrometa.ChildOrder := 7;
    izNepokritiZavarovaniIzpadPrometa.Font.Charset := DEFAULT_CHARSET;
    izNepokritiZavarovaniIzpadPrometa.Font.Color := clWindowText;
    izNepokritiZavarovaniIzpadPrometa.Font.Height := -11;
    izNepokritiZavarovaniIzpadPrometa.Font.Name := 'Arial';
    izNepokritiZavarovaniIzpadPrometa.Font.Style := [fsBold];
    izNepokritiZavarovaniIzpadPrometa.HeightPercent := 100.000000000000000000;
    izNepokritiZavarovaniIzpadPrometa.ParentFont := False;
    izNepokritiZavarovaniIzpadPrometa.ReadOnly := True;
    izNepokritiZavarovaniIzpadPrometa.ShowFocus := False;
    izNepokritiZavarovaniIzpadPrometa.Text := '0.00';
    izNepokritiZavarovaniIzpadPrometa.WidthPercent := 100.000000000000000000;
    NepokritiZavarovaniIzpadPrometaPrazno.SetParentComponent(ploscicaTabelaNepokritiStroski_Povzetek);
    NepokritiZavarovaniIzpadPrometaPrazno.Name := 'NepokritiZavarovaniIzpadPrometaPrazno';
    NepokritiZavarovaniIzpadPrometaPrazno.Left := 621;
    NepokritiZavarovaniIzpadPrometaPrazno.Top := 52;
    NepokritiZavarovaniIzpadPrometaPrazno.Width := 26;
    NepokritiZavarovaniIzpadPrometaPrazno.Height := 26;
    NepokritiZavarovaniIzpadPrometaPrazno.HeightPercent := 100.000000000000000000;
    NepokritiZavarovaniIzpadPrometaPrazno.WidthPercent := 100.000000000000000000;
    NepokritiZavarovaniIzpadPrometaPrazno.Anchors := [];
    NepokritiZavarovaniIzpadPrometaPrazno.BorderColor := clSilver;
    NepokritiZavarovaniIzpadPrometaPrazno.BorderStyle := bsNone;
    NepokritiZavarovaniIzpadPrometaPrazno.ChildOrder := 8;
    NepokritiZavarovaniIzpadPrometaPrazno.Color := clWindow;
    SimulacijaSkode_List2.SetParentComponent(SimulacijaSkode_Listi);
    SimulacijaSkode_List2.Name := 'SimulacijaSkode_List2';
    SimulacijaSkode_List2.Left := 0;
    SimulacijaSkode_List2.Top := 20;
    SimulacijaSkode_List2.Width := 840;
    SimulacijaSkode_List2.Height := 580;
    SimulacijaSkode_List2.HeightPercent := 100.000000000000000000;
    SimulacijaSkode_List2.WidthPercent := 100.000000000000000000;
    SimulacijaSkode_List2.Caption := 'WebPageControl1Sheet2';
    SimulacijaSkode_List2.ChildOrder := 4;
    SimulacijaSkode_List2.ShowCaption := False;
    SimulacijaSkode_List2.TabVisible := False;
    izNaslovList2.SetParentComponent(SimulacijaSkode_List2);
    izNaslovList2.Name := 'izNaslovList2';
    izNaslovList2.Left := 30;
    izNaslovList2.Top := 25;
    izNaslovList2.Width := 393;
    izNaslovList2.Height := 14;
    izNaslovList2.Caption := '2. korak: Izra'#269'un ekonomsko upravi'#269'enih povi'#353'anih poslovnih stro'+#353'kov';
    izNaslovList2.Color := clNone;
    izNaslovList2.Font.Charset := DEFAULT_CHARSET;
    izNaslovList2.Font.Color := clWindowText;
    izNaslovList2.Font.Height := -11;
    izNaslovList2.Font.Name := 'Arial';
    izNaslovList2.Font.Style := [fsBold];
    izNaslovList2.HeightPercent := 100.000000000000000000;
    izNaslovList2.ParentFont := False;
    izNaslovList2.WidthPercent := 100.000000000000000000;
    ploscicaIkoneList2.SetParentComponent(SimulacijaSkode_List2);
    ploscicaIkoneList2.Name := 'ploscicaIkoneList2';
    ploscicaIkoneList2.Left := 0;
    ploscicaIkoneList2.Top := 540;
    ploscicaIkoneList2.Width := 840;
    ploscicaIkoneList2.Height := 40;
    ploscicaIkoneList2.HeightPercent := 100.000000000000000000;
    ploscicaIkoneList2.WidthPercent := 100.000000000000000000;
    ploscicaIkoneList2.Align := alBottom;
    ploscicaIkoneList2.BorderColor := clNone;
    ploscicaIkoneList2.BorderStyle := bsNone;
    ploscicaIkoneList2.ChildOrder := 1;
    ploscicaIkoneList2.Color := clWindow;
    ploscicaGNaprej2.SetParentComponent(ploscicaIkoneList2);
    ploscicaGNaprej2.Name := 'ploscicaGNaprej2';
    ploscicaGNaprej2.Left := 790;
    ploscicaGNaprej2.Top := 0;
    ploscicaGNaprej2.Width := 50;
    ploscicaGNaprej2.Height := 40;
    ploscicaGNaprej2.HeightPercent := 100.000000000000000000;
    ploscicaGNaprej2.WidthPercent := 100.000000000000000000;
    ploscicaGNaprej2.Align := alRight;
    ploscicaGNaprej2.BorderColor := clNone;
    ploscicaGNaprej2.BorderStyle := bsNone;
    ploscicaGNaprej2.Color := clWindow;
    gNaprej2.SetParentComponent(ploscicaGNaprej2);
    gNaprej2.Name := 'gNaprej2';
    gNaprej2.Left := 0;
    gNaprej2.Top := 0;
    gNaprej2.Width := 35;
    gNaprej2.Height := 40;
    gNaprej2.Color := clNone;
    gNaprej2.Flat := True;
    gNaprej2.Glyph.LoadFromFile('MBI_u04.ploscicaGNaprej2.gNaprej2.Glyph.png');
    gNaprej2.HeightPercent := 100.000000000000000000;
    gNaprej2.WidthPercent := 100.000000000000000000;
    SetEvent(gNaprej2, Self, 'OnClick', 'gNaprejNazajClick');
    ploscicaGNazaj2.SetParentComponent(ploscicaIkoneList2);
    ploscicaGNazaj2.Name := 'ploscicaGNazaj2';
    ploscicaGNazaj2.Left := 0;
    ploscicaGNazaj2.Top := 0;
    ploscicaGNazaj2.Width := 50;
    ploscicaGNazaj2.Height := 40;
    ploscicaGNazaj2.HeightPercent := 100.000000000000000000;
    ploscicaGNazaj2.WidthPercent := 100.000000000000000000;
    ploscicaGNazaj2.Align := alLeft;
    ploscicaGNazaj2.BorderColor := clNone;
    ploscicaGNazaj2.BorderStyle := bsNone;
    ploscicaGNazaj2.ChildOrder := 1;
    ploscicaGNazaj2.Color := clWindow;
    gNazaj2.SetParentComponent(ploscicaGNazaj2);
    gNazaj2.Name := 'gNazaj2';
    gNazaj2.Left := 15;
    gNazaj2.Top := 0;
    gNazaj2.Width := 35;
    gNazaj2.Height := 40;
    gNazaj2.Color := clNone;
    gNazaj2.Flat := True;
    gNazaj2.Glyph.LoadFromFile('MBI_u04.ploscicaGNazaj2.gNazaj2.Glyph.png');
    gNazaj2.HeightPercent := 100.000000000000000000;
    gNazaj2.WidthPercent := 100.000000000000000000;
    SetEvent(gNazaj2, Self, 'OnClick', 'gNaprejNazajClick');
    ploscicaPovisaniPoslovniStroski.SetParentComponent(SimulacijaSkode_List2);
    ploscicaPovisaniPoslovniStroski.Name := 'ploscicaPovisaniPoslovniStroski';
    ploscicaPovisaniPoslovniStroski.Left := 30;
    ploscicaPovisaniPoslovniStroski.Top := 100;
    ploscicaPovisaniPoslovniStroski.Width := 650;
    ploscicaPovisaniPoslovniStroski.Height := 95;
    ploscicaPovisaniPoslovniStroski.HeightPercent := 100.000000000000000000;
    ploscicaPovisaniPoslovniStroski.WidthPercent := 100.000000000000000000;
    ploscicaPovisaniPoslovniStroski.ChildOrder := 2;
    ploscicaPovisaniPoslovniStroski.ColumnCollection.Clear;
    with ploscicaPovisaniPoslovniStroski.ColumnCollection.Add do
    begin
      MarginLeft := 3;
      Value := 75;
    end;
    with ploscicaPovisaniPoslovniStroski.ColumnCollection.Add do
    begin
      Alignment := taCenter;
      Value := 25;
    end;
    ploscicaPovisaniPoslovniStroski.ControlCollection.Clear;
    with ploscicaPovisaniPoslovniStroski.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := PovisaniPoslovniStrskiTekst;
    end;
    with ploscicaPovisaniPoslovniStroski.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izPovisaniPoslovniStroski;
    end;
    with ploscicaPovisaniPoslovniStroski.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ResenPrometTekst;
    end;
    with ploscicaPovisaniPoslovniStroski.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izResenPromet;
    end;
    with ploscicaPovisaniPoslovniStroski.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := EkonomskoUpraviceniPovisaniStroskiTekst;
    end;
    with ploscicaPovisaniPoslovniStroski.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izEkonomskoUpraviceniStroski;
    end;
    ploscicaPovisaniPoslovniStroski.Color := clNone;
    ploscicaPovisaniPoslovniStroski.GridLineColor := clGray;
    ploscicaPovisaniPoslovniStroski.RowCollection.Clear;
    with ploscicaPovisaniPoslovniStroski.RowCollection.Add do
    begin
      Alignment := vaCenter;
      SizeStyle := ssAbsolute;
      Value := 30;
    end;
    with ploscicaPovisaniPoslovniStroski.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 30;
    end;
    with ploscicaPovisaniPoslovniStroski.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 30;
    end;
    PovisaniPoslovniStrskiTekst.SetParentComponent(ploscicaPovisaniPoslovniStroski);
    PovisaniPoslovniStrskiTekst.Name := 'PovisaniPoslovniStrskiTekst';
    PovisaniPoslovniStrskiTekst.Left := 5;
    PovisaniPoslovniStrskiTekst.Top := 8;
    PovisaniPoslovniStrskiTekst.Width := 402;
    PovisaniPoslovniStrskiTekst.Height := 14;
    PovisaniPoslovniStrskiTekst.Alignment := taRightJustify;
    PovisaniPoslovniStrskiTekst.Anchors := [];
    PovisaniPoslovniStrskiTekst.Caption := 'Prijavljeni povi'#353'ani poslovni stro'#353'ki, povezani z re'#353'evanjem prometa v '#269'asu zastoja:';
    PovisaniPoslovniStrskiTekst.Color := clNone;
    PovisaniPoslovniStrskiTekst.HeightPercent := 100.000000000000000000;
    PovisaniPoslovniStrskiTekst.WidthPercent := 100.000000000000000000;
    ResenPrometTekst.SetParentComponent(ploscicaPovisaniPoslovniStroski);
    ResenPrometTekst.Name := 'ResenPrometTekst';
    ResenPrometTekst.Left := 5;
    ResenPrometTekst.Top := 33;
    ResenPrometTekst.Width := 319;
    ResenPrometTekst.Height := 14;
    ResenPrometTekst.Alignment := taRightJustify;
    ResenPrometTekst.Anchors := [];
    ResenPrometTekst.Caption := 'Prijavljen re'#353'en promet s prijavljenimi povi'#353'animi poslovnimi stro'#353'ki:';
    ResenPrometTekst.Color := clNone;
    ResenPrometTekst.HeightPercent := 100.000000000000000000;
    ResenPrometTekst.WidthPercent := 100.000000000000000000;
    EkonomskoUpraviceniPovisaniStroskiTekst.SetParentComponent(ploscicaPovisaniPoslovniStroski);
    EkonomskoUpraviceniPovisaniStroskiTekst.Name := 'EkonomskoUpraviceniPovisaniStroskiTekst';
    EkonomskoUpraviceniPovisaniStroskiTekst.Left := 5;
    EkonomskoUpraviceniPovisaniStroskiTekst.Top := 53;
    EkonomskoUpraviceniPovisaniStroskiTekst.Width := 437;
    EkonomskoUpraviceniPovisaniStroskiTekst.Height := 14;
    EkonomskoUpraviceniPovisaniStroskiTekst.Alignment := taRightJustify;
    EkonomskoUpraviceniPovisaniStroskiTekst.Anchors := [];
    EkonomskoUpraviceniPovisaniStroskiTekst.Caption := 'Ekonomsko upravi'#269'eni povi'#353'ani stro'#353'ki (ob stopnji kosmatega dobi'+#269'ka xx,xx%):';
    EkonomskoUpraviceniPovisaniStroskiTekst.Color := clNone;
    EkonomskoUpraviceniPovisaniStroskiTekst.Font.Charset := DEFAULT_CHARSET;
    EkonomskoUpraviceniPovisaniStroskiTekst.Font.Color := clWindowText;
    EkonomskoUpraviceniPovisaniStroskiTekst.Font.Height := -11;
    EkonomskoUpraviceniPovisaniStroskiTekst.Font.Name := 'Arial';
    EkonomskoUpraviceniPovisaniStroskiTekst.Font.Style := [fsBold];
    EkonomskoUpraviceniPovisaniStroskiTekst.HeightPercent := 100.000000000000000000;
    EkonomskoUpraviceniPovisaniStroskiTekst.ParentFont := False;
    EkonomskoUpraviceniPovisaniStroskiTekst.WidthPercent := 100.000000000000000000;
    izPovisaniPoslovniStroski.SetParentComponent(ploscicaPovisaniPoslovniStroski);
    izPovisaniPoslovniStroski.Name := 'izPovisaniPoslovniStroski';
    izPovisaniPoslovniStroski.Left := 504;
    izPovisaniPoslovniStroski.Top := 4;
    izPovisaniPoslovniStroski.Width := 130;
    izPovisaniPoslovniStroski.Height := 22;
    izPovisaniPoslovniStroski.TabStop := False;
    izPovisaniPoslovniStroski.Alignment := taRightJustify;
    izPovisaniPoslovniStroski.Anchors := [];
    izPovisaniPoslovniStroski.AutoSelect := False;
    izPovisaniPoslovniStroski.ChildOrder := 1;
    izPovisaniPoslovniStroski.HeightPercent := 100.000000000000000000;
    izPovisaniPoslovniStroski.ReadOnly := True;
    izPovisaniPoslovniStroski.ShowFocus := False;
    izPovisaniPoslovniStroski.Text := '0.00';
    izPovisaniPoslovniStroski.WidthPercent := 100.000000000000000000;
    izResenPromet.SetParentComponent(ploscicaPovisaniPoslovniStroski);
    izResenPromet.Name := 'izResenPromet';
    izResenPromet.Left := 504;
    izResenPromet.Top := 29;
    izResenPromet.Width := 130;
    izResenPromet.Height := 22;
    izResenPromet.TabStop := False;
    izResenPromet.Alignment := taRightJustify;
    izResenPromet.Anchors := [];
    izResenPromet.AutoSelect := False;
    izResenPromet.ChildOrder := 3;
    izResenPromet.HeightPercent := 100.000000000000000000;
    izResenPromet.ReadOnly := True;
    izResenPromet.ShowFocus := False;
    izResenPromet.Text := '0.00';
    izResenPromet.WidthPercent := 100.000000000000000000;
    izEkonomskoUpraviceniStroski.SetParentComponent(ploscicaPovisaniPoslovniStroski);
    izEkonomskoUpraviceniStroski.Name := 'izEkonomskoUpraviceniStroski';
    izEkonomskoUpraviceniStroski.Left := 504;
    izEkonomskoUpraviceniStroski.Top := 49;
    izEkonomskoUpraviceniStroski.Width := 130;
    izEkonomskoUpraviceniStroski.Height := 22;
    izEkonomskoUpraviceniStroski.TabStop := False;
    izEkonomskoUpraviceniStroski.Alignment := taRightJustify;
    izEkonomskoUpraviceniStroski.Anchors := [];
    izEkonomskoUpraviceniStroski.AutoSelect := False;
    izEkonomskoUpraviceniStroski.ChildOrder := 5;
    izEkonomskoUpraviceniStroski.Font.Charset := DEFAULT_CHARSET;
    izEkonomskoUpraviceniStroski.Font.Color := clWindowText;
    izEkonomskoUpraviceniStroski.Font.Height := -11;
    izEkonomskoUpraviceniStroski.Font.Name := 'Arial';
    izEkonomskoUpraviceniStroski.Font.Style := [fsBold];
    izEkonomskoUpraviceniStroski.HeightPercent := 100.000000000000000000;
    izEkonomskoUpraviceniStroski.ParentFont := False;
    izEkonomskoUpraviceniStroski.ReadOnly := True;
    izEkonomskoUpraviceniStroski.ShowFocus := False;
    izEkonomskoUpraviceniStroski.Text := '0.00';
    izEkonomskoUpraviceniStroski.WidthPercent := 100.000000000000000000;
    SimulacijaSkode_List3.SetParentComponent(SimulacijaSkode_Listi);
    SimulacijaSkode_List3.Name := 'SimulacijaSkode_List3';
    SimulacijaSkode_List3.Left := 0;
    SimulacijaSkode_List3.Top := 20;
    SimulacijaSkode_List3.Width := 840;
    SimulacijaSkode_List3.Height := 580;
    SimulacijaSkode_List3.HeightPercent := 100.000000000000000000;
    SimulacijaSkode_List3.WidthPercent := 100.000000000000000000;
    SimulacijaSkode_List3.Caption := 'WebPageControl1Sheet3';
    SimulacijaSkode_List3.ChildOrder := 5;
    SimulacijaSkode_List3.ShowCaption := False;
    SimulacijaSkode_List3.TabVisible := False;
    izNaslovList3.SetParentComponent(SimulacijaSkode_List3);
    izNaslovList3.Name := 'izNaslovList3';
    izNaslovList3.Left := 30;
    izNaslovList3.Top := 25;
    izNaslovList3.Width := 319;
    izNaslovList3.Height := 14;
    izNaslovList3.Caption := '3. korak: Izra'#269'un prihrankov (zmanj'#353'anja stalnih stro'#353'kov)';
    izNaslovList3.Color := clNone;
    izNaslovList3.Font.Charset := DEFAULT_CHARSET;
    izNaslovList3.Font.Color := clWindowText;
    izNaslovList3.Font.Height := -11;
    izNaslovList3.Font.Name := 'Arial';
    izNaslovList3.Font.Style := [fsBold];
    izNaslovList3.HeightPercent := 100.000000000000000000;
    izNaslovList3.ParentFont := False;
    izNaslovList3.WidthPercent := 100.000000000000000000;
    ploscicaIkoneList3.SetParentComponent(SimulacijaSkode_List3);
    ploscicaIkoneList3.Name := 'ploscicaIkoneList3';
    ploscicaIkoneList3.Left := 0;
    ploscicaIkoneList3.Top := 540;
    ploscicaIkoneList3.Width := 840;
    ploscicaIkoneList3.Height := 40;
    ploscicaIkoneList3.HeightPercent := 100.000000000000000000;
    ploscicaIkoneList3.WidthPercent := 100.000000000000000000;
    ploscicaIkoneList3.Align := alBottom;
    ploscicaIkoneList3.BorderColor := clNone;
    ploscicaIkoneList3.BorderStyle := bsNone;
    ploscicaIkoneList3.ChildOrder := 1;
    ploscicaIkoneList3.Color := clWindow;
    ploscicaGNaprej3.SetParentComponent(ploscicaIkoneList3);
    ploscicaGNaprej3.Name := 'ploscicaGNaprej3';
    ploscicaGNaprej3.Left := 790;
    ploscicaGNaprej3.Top := 0;
    ploscicaGNaprej3.Width := 50;
    ploscicaGNaprej3.Height := 40;
    ploscicaGNaprej3.HeightPercent := 100.000000000000000000;
    ploscicaGNaprej3.WidthPercent := 100.000000000000000000;
    ploscicaGNaprej3.Align := alRight;
    ploscicaGNaprej3.BorderColor := clNone;
    ploscicaGNaprej3.BorderStyle := bsNone;
    ploscicaGNaprej3.Color := clWindow;
    gNaprej3.SetParentComponent(ploscicaGNaprej3);
    gNaprej3.Name := 'gNaprej3';
    gNaprej3.Left := 0;
    gNaprej3.Top := 0;
    gNaprej3.Width := 35;
    gNaprej3.Height := 40;
    gNaprej3.Color := clNone;
    gNaprej3.Flat := True;
    gNaprej3.Glyph.LoadFromFile('MBI_u04.ploscicaGNaprej3.gNaprej3.Glyph.png');
    gNaprej3.HeightPercent := 100.000000000000000000;
    gNaprej3.WidthPercent := 100.000000000000000000;
    SetEvent(gNaprej3, Self, 'OnClick', 'gNaprejNazajClick');
    ploscicaGNazaj3.SetParentComponent(ploscicaIkoneList3);
    ploscicaGNazaj3.Name := 'ploscicaGNazaj3';
    ploscicaGNazaj3.Left := 0;
    ploscicaGNazaj3.Top := 0;
    ploscicaGNazaj3.Width := 50;
    ploscicaGNazaj3.Height := 40;
    ploscicaGNazaj3.HeightPercent := 100.000000000000000000;
    ploscicaGNazaj3.WidthPercent := 100.000000000000000000;
    ploscicaGNazaj3.Align := alLeft;
    ploscicaGNazaj3.BorderColor := clNone;
    ploscicaGNazaj3.BorderStyle := bsNone;
    ploscicaGNazaj3.ChildOrder := 1;
    ploscicaGNazaj3.Color := clWindow;
    gNazaj3.SetParentComponent(ploscicaGNazaj3);
    gNazaj3.Name := 'gNazaj3';
    gNazaj3.Left := 15;
    gNazaj3.Top := 0;
    gNazaj3.Width := 35;
    gNazaj3.Height := 40;
    gNazaj3.Color := clNone;
    gNazaj3.Flat := True;
    gNazaj3.Glyph.LoadFromFile('MBI_u04.ploscicaGNazaj3.gNazaj3.Glyph.png');
    gNazaj3.HeightPercent := 100.000000000000000000;
    gNazaj3.WidthPercent := 100.000000000000000000;
    SetEvent(gNazaj3, Self, 'OnClick', 'gNaprejNazajClick');
    ploscicaPregledPrihrankov.SetParentComponent(SimulacijaSkode_List3);
    ploscicaPregledPrihrankov.Name := 'ploscicaPregledPrihrankov';
    ploscicaPregledPrihrankov.Left := 30;
    ploscicaPregledPrihrankov.Top := 97;
    ploscicaPregledPrihrankov.Width := 648;
    ploscicaPregledPrihrankov.Height := 30;
    ploscicaPregledPrihrankov.HeightPercent := 100.000000000000000000;
    ploscicaPregledPrihrankov.WidthPercent := 100.000000000000000000;
    ploscicaPregledPrihrankov.ChildOrder := 2;
    ploscicaPregledPrihrankov.ColumnCollection.Clear;
    with ploscicaPregledPrihrankov.ColumnCollection.Add do
    begin
      MarginLeft := 3;
      Value := 38;
    end;
    with ploscicaPregledPrihrankov.ColumnCollection.Add do
    begin
      Alignment := taCenter;
      Value := 22;
    end;
    with ploscicaPregledPrihrankov.ColumnCollection.Add do
    begin
      Alignment := taCenter;
      Value := 20;
    end;
    with ploscicaPregledPrihrankov.ColumnCollection.Add do
    begin
      Alignment := taCenter;
      Value := 20;
    end;
    ploscicaPregledPrihrankov.ControlCollection.Clear;
    with ploscicaPregledPrihrankov.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaTabelaNepokritiStroski_Naslov7;
    end;
    with ploscicaPregledPrihrankov.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaTabelaNepokritiStroski_Naslov8;
    end;
    with ploscicaPregledPrihrankov.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaTabelaNepokritiStroski_Naslov9;
    end;
    with ploscicaPregledPrihrankov.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaTabelaNepokritiStroski_Naslov10;
    end;
    ploscicaPregledPrihrankov.Color := 15461355;
    ploscicaPregledPrihrankov.GridLineWidth := 1;
    ploscicaPregledPrihrankov.GridLineColor := clGray;
    ploscicaPregledPrihrankov.RowCollection.Clear;
    with ploscicaPregledPrihrankov.RowCollection.Add do
    begin
      Alignment := vaCenter;
      SizeStyle := ssAbsolute;
      Value := 30;
    end;
    ploscicaTabelaNepokritiStroski_Naslov7.SetParentComponent(ploscicaPregledPrihrankov);
    ploscicaTabelaNepokritiStroski_Naslov7.Name := 'ploscicaTabelaNepokritiStroski_Naslov7';
    ploscicaTabelaNepokritiStroski_Naslov7.Left := 5;
    ploscicaTabelaNepokritiStroski_Naslov7.Top := 8;
    ploscicaTabelaNepokritiStroski_Naslov7.Width := 138;
    ploscicaTabelaNepokritiStroski_Naslov7.Height := 14;
    ploscicaTabelaNepokritiStroski_Naslov7.Anchors := [];
    ploscicaTabelaNepokritiStroski_Naslov7.Caption := 'Vrsta prijavljenega prihranka';
    ploscicaTabelaNepokritiStroski_Naslov7.Color := clNone;
    ploscicaTabelaNepokritiStroski_Naslov7.HeightPercent := 100.000000000000000000;
    ploscicaTabelaNepokritiStroski_Naslov7.WidthPercent := 100.000000000000000000;
    ploscicaTabelaNepokritiStroski_Naslov8.SetParentComponent(ploscicaPregledPrihrankov);
    ploscicaTabelaNepokritiStroski_Naslov8.Name := 'ploscicaTabelaNepokritiStroski_Naslov8';
    ploscicaTabelaNepokritiStroski_Naslov8.Left := 247;
    ploscicaTabelaNepokritiStroski_Naslov8.Top := 8;
    ploscicaTabelaNepokritiStroski_Naslov8.Width := 141;
    ploscicaTabelaNepokritiStroski_Naslov8.Height := 14;
    ploscicaTabelaNepokritiStroski_Naslov8.Anchors := [];
    ploscicaTabelaNepokritiStroski_Naslov8.Caption := 'Vi'#353'ina prijavljenega prihranka';
    ploscicaTabelaNepokritiStroski_Naslov8.Color := clNone;
    ploscicaTabelaNepokritiStroski_Naslov8.HeightPercent := 100.000000000000000000;
    ploscicaTabelaNepokritiStroski_Naslov8.WidthPercent := 100.000000000000000000;
    ploscicaTabelaNepokritiStroski_Naslov9.SetParentComponent(ploscicaPregledPrihrankov);
    ploscicaTabelaNepokritiStroski_Naslov9.Name := 'ploscicaTabelaNepokritiStroski_Naslov9';
    ploscicaTabelaNepokritiStroski_Naslov9.Left := 398;
    ploscicaTabelaNepokritiStroski_Naslov9.Top := 8;
    ploscicaTabelaNepokritiStroski_Naslov9.Width := 112;
    ploscicaTabelaNepokritiStroski_Naslov9.Height := 14;
    ploscicaTabelaNepokritiStroski_Naslov9.Anchors := [];
    ploscicaTabelaNepokritiStroski_Naslov9.Caption := 'Upravi'#269'enost prihranka';
    ploscicaTabelaNepokritiStroski_Naslov9.Color := clNone;
    ploscicaTabelaNepokritiStroski_Naslov9.HeightPercent := 100.000000000000000000;
    ploscicaTabelaNepokritiStroski_Naslov9.WidthPercent := 100.000000000000000000;
    ploscicaTabelaNepokritiStroski_Naslov10.SetParentComponent(ploscicaPregledPrihrankov);
    ploscicaTabelaNepokritiStroski_Naslov10.Name := 'ploscicaTabelaNepokritiStroski_Naslov10';
    ploscicaTabelaNepokritiStroski_Naslov10.Left := 528;
    ploscicaTabelaNepokritiStroski_Naslov10.Top := 8;
    ploscicaTabelaNepokritiStroski_Naslov10.Width := 111;
    ploscicaTabelaNepokritiStroski_Naslov10.Height := 14;
    ploscicaTabelaNepokritiStroski_Naslov10.Anchors := [];
    ploscicaTabelaNepokritiStroski_Naslov10.Caption := 'Odstotek upravi'#269'enosti';
    ploscicaTabelaNepokritiStroski_Naslov10.Color := clNone;
    ploscicaTabelaNepokritiStroski_Naslov10.HeightPercent := 100.000000000000000000;
    ploscicaTabelaNepokritiStroski_Naslov10.WidthPercent := 100.000000000000000000;
    ploscicaPregledPrihrankov_Povzetek.SetParentComponent(SimulacijaSkode_List3);
    ploscicaPregledPrihrankov_Povzetek.Name := 'ploscicaPregledPrihrankov_Povzetek';
    ploscicaPregledPrihrankov_Povzetek.Left := 30;
    ploscicaPregledPrihrankov_Povzetek.Top := 500;
    ploscicaPregledPrihrankov_Povzetek.Width := 650;
    ploscicaPregledPrihrankov_Povzetek.Height := 60;
    ploscicaPregledPrihrankov_Povzetek.HeightPercent := 100.000000000000000000;
    ploscicaPregledPrihrankov_Povzetek.WidthPercent := 100.000000000000000000;
    ploscicaPregledPrihrankov_Povzetek.ChildOrder := 3;
    ploscicaPregledPrihrankov_Povzetek.ColumnCollection.Clear;
    with ploscicaPregledPrihrankov_Povzetek.ColumnCollection.Add do
    begin
      Alignment := taRightJustify;
      MarginLeft := 3;
      Value := 80;
    end;
    with ploscicaPregledPrihrankov_Povzetek.ColumnCollection.Add do
    begin
      Alignment := taCenter;
      Value := 20;
    end;
    ploscicaPregledPrihrankov_Povzetek.ControlCollection.Clear;
    with ploscicaPregledPrihrankov_Povzetek.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := PrijavljeniPrihrankiTekst;
    end;
    with ploscicaPregledPrihrankov_Povzetek.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izPrijavljeniPrihranki;
    end;
    with ploscicaPregledPrihrankov_Povzetek.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := DejanskiPrihrankiTekst;
    end;
    with ploscicaPregledPrihrankov_Povzetek.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izDejanskiPrihranki;
    end;
    ploscicaPregledPrihrankov_Povzetek.Color := clNone;
    ploscicaPregledPrihrankov_Povzetek.GridLineColor := clGray;
    ploscicaPregledPrihrankov_Povzetek.RowCollection.Clear;
    with ploscicaPregledPrihrankov_Povzetek.RowCollection.Add do
    begin
      Alignment := vaCenter;
      SizeStyle := ssAbsolute;
      Value := 30;
    end;
    with ploscicaPregledPrihrankov_Povzetek.RowCollection.Add do
    begin
      Value := 30;
    end;
    PrijavljeniPrihrankiTekst.SetParentComponent(ploscicaPregledPrihrankov_Povzetek);
    PrijavljeniPrihrankiTekst.Name := 'PrijavljeniPrihrankiTekst';
    PrijavljeniPrihrankiTekst.Left := 393;
    PrijavljeniPrihrankiTekst.Top := 8;
    PrijavljeniPrihrankiTekst.Width := 125;
    PrijavljeniPrihrankiTekst.Height := 14;
    PrijavljeniPrihrankiTekst.Alignment := taRightJustify;
    PrijavljeniPrihrankiTekst.Anchors := [];
    PrijavljeniPrihrankiTekst.Caption := 'Prijavljeni prihranki skupaj:';
    PrijavljeniPrihrankiTekst.Color := clNone;
    PrijavljeniPrihrankiTekst.HeightPercent := 100.000000000000000000;
    PrijavljeniPrihrankiTekst.WidthPercent := 100.000000000000000000;
    DejanskiPrihrankiTekst.SetParentComponent(ploscicaPregledPrihrankov_Povzetek);
    DejanskiPrihrankiTekst.Name := 'DejanskiPrihrankiTekst';
    DejanskiPrihrankiTekst.Left := 427;
    DejanskiPrihrankiTekst.Top := 32;
    DejanskiPrihrankiTekst.Width := 91;
    DejanskiPrihrankiTekst.Height := 14;
    DejanskiPrihrankiTekst.Alignment := taRightJustify;
    DejanskiPrihrankiTekst.Anchors := [];
    DejanskiPrihrankiTekst.Caption := 'Dejansku prihranki:';
    DejanskiPrihrankiTekst.Color := clNone;
    DejanskiPrihrankiTekst.HeightPercent := 100.000000000000000000;
    DejanskiPrihrankiTekst.WidthPercent := 100.000000000000000000;
    izPrijavljeniPrihranki.SetParentComponent(ploscicaPregledPrihrankov_Povzetek);
    izPrijavljeniPrihranki.Name := 'izPrijavljeniPrihranki';
    izPrijavljeniPrihranki.Left := 522;
    izPrijavljeniPrihranki.Top := 4;
    izPrijavljeniPrihranki.Width := 127;
    izPrijavljeniPrihranki.Height := 22;
    izPrijavljeniPrihranki.TabStop := False;
    izPrijavljeniPrihranki.Alignment := taRightJustify;
    izPrijavljeniPrihranki.Anchors := [];
    izPrijavljeniPrihranki.AutoSelect := False;
    izPrijavljeniPrihranki.ChildOrder := 1;
    izPrijavljeniPrihranki.HeightPercent := 100.000000000000000000;
    izPrijavljeniPrihranki.ReadOnly := True;
    izPrijavljeniPrihranki.ShowFocus := False;
    izPrijavljeniPrihranki.Text := '0.00';
    izPrijavljeniPrihranki.WidthPercent := 100.000000000000000000;
    izDejanskiPrihranki.SetParentComponent(ploscicaPregledPrihrankov_Povzetek);
    izDejanskiPrihranki.Name := 'izDejanskiPrihranki';
    izDejanskiPrihranki.Left := 522;
    izDejanskiPrihranki.Top := 32;
    izDejanskiPrihranki.Width := 127;
    izDejanskiPrihranki.Height := 22;
    izDejanskiPrihranki.TabStop := False;
    izDejanskiPrihranki.Alignment := taRightJustify;
    izDejanskiPrihranki.Anchors := [];
    izDejanskiPrihranki.AutoSelect := False;
    izDejanskiPrihranki.ChildOrder := 3;
    izDejanskiPrihranki.Font.Charset := DEFAULT_CHARSET;
    izDejanskiPrihranki.Font.Color := clWindowText;
    izDejanskiPrihranki.Font.Height := -11;
    izDejanskiPrihranki.Font.Name := 'Arial';
    izDejanskiPrihranki.Font.Style := [fsBold];
    izDejanskiPrihranki.HeightPercent := 100.000000000000000000;
    izDejanskiPrihranki.ParentFont := False;
    izDejanskiPrihranki.ReadOnly := True;
    izDejanskiPrihranki.ShowFocus := False;
    izDejanskiPrihranki.Text := '0.00';
    izDejanskiPrihranki.WidthPercent := 100.000000000000000000;
    SimulacijaSkode_List4.SetParentComponent(SimulacijaSkode_Listi);
    SimulacijaSkode_List4.Name := 'SimulacijaSkode_List4';
    SimulacijaSkode_List4.Left := 0;
    SimulacijaSkode_List4.Top := 20;
    SimulacijaSkode_List4.Width := 840;
    SimulacijaSkode_List4.Height := 580;
    SimulacijaSkode_List4.HeightPercent := 100.000000000000000000;
    SimulacijaSkode_List4.WidthPercent := 100.000000000000000000;
    SimulacijaSkode_List4.Caption := 'WebPageControl1Sheet1';
    SimulacijaSkode_List4.ChildOrder := 6;
    SimulacijaSkode_List4.ShowCaption := False;
    SimulacijaSkode_List4.TabVisible := False;
    izNaslovList4.SetParentComponent(SimulacijaSkode_List4);
    izNaslovList4.Name := 'izNaslovList4';
    izNaslovList4.Left := 30;
    izNaslovList4.Top := 25;
    izNaslovList4.Width := 251;
    izNaslovList4.Height := 14;
    izNaslovList4.Caption := '4. korak: Izra'#269'un morebitne podzavarovanosti';
    izNaslovList4.Color := clNone;
    izNaslovList4.Font.Charset := DEFAULT_CHARSET;
    izNaslovList4.Font.Color := clWindowText;
    izNaslovList4.Font.Height := -11;
    izNaslovList4.Font.Name := 'Arial';
    izNaslovList4.Font.Style := [fsBold];
    izNaslovList4.HeightPercent := 100.000000000000000000;
    izNaslovList4.ParentFont := False;
    izNaslovList4.WidthPercent := 100.000000000000000000;
    ploscicaIkoneList4.SetParentComponent(SimulacijaSkode_List4);
    ploscicaIkoneList4.Name := 'ploscicaIkoneList4';
    ploscicaIkoneList4.Left := 0;
    ploscicaIkoneList4.Top := 540;
    ploscicaIkoneList4.Width := 840;
    ploscicaIkoneList4.Height := 40;
    ploscicaIkoneList4.HeightPercent := 100.000000000000000000;
    ploscicaIkoneList4.WidthPercent := 100.000000000000000000;
    ploscicaIkoneList4.Align := alBottom;
    ploscicaIkoneList4.BorderColor := clNone;
    ploscicaIkoneList4.BorderStyle := bsNone;
    ploscicaIkoneList4.ChildOrder := 1;
    ploscicaIkoneList4.Color := clWindow;
    ploscicaGNaprej4.SetParentComponent(ploscicaIkoneList4);
    ploscicaGNaprej4.Name := 'ploscicaGNaprej4';
    ploscicaGNaprej4.Left := 790;
    ploscicaGNaprej4.Top := 0;
    ploscicaGNaprej4.Width := 50;
    ploscicaGNaprej4.Height := 40;
    ploscicaGNaprej4.HeightPercent := 100.000000000000000000;
    ploscicaGNaprej4.WidthPercent := 100.000000000000000000;
    ploscicaGNaprej4.Align := alRight;
    ploscicaGNaprej4.BorderColor := clNone;
    ploscicaGNaprej4.BorderStyle := bsNone;
    ploscicaGNaprej4.Color := clWindow;
    gNaprej4.SetParentComponent(ploscicaGNaprej4);
    gNaprej4.Name := 'gNaprej4';
    gNaprej4.Left := 0;
    gNaprej4.Top := 0;
    gNaprej4.Width := 35;
    gNaprej4.Height := 40;
    gNaprej4.Color := clNone;
    gNaprej4.Flat := True;
    gNaprej4.Glyph.LoadFromFile('MBI_u04.ploscicaGNaprej4.gNaprej4.Glyph.png');
    gNaprej4.HeightPercent := 100.000000000000000000;
    gNaprej4.WidthPercent := 100.000000000000000000;
    SetEvent(gNaprej4, Self, 'OnClick', 'gNaprejNazajClick');
    ploscicaGNazaj4.SetParentComponent(ploscicaIkoneList4);
    ploscicaGNazaj4.Name := 'ploscicaGNazaj4';
    ploscicaGNazaj4.Left := 0;
    ploscicaGNazaj4.Top := 0;
    ploscicaGNazaj4.Width := 50;
    ploscicaGNazaj4.Height := 40;
    ploscicaGNazaj4.HeightPercent := 100.000000000000000000;
    ploscicaGNazaj4.WidthPercent := 100.000000000000000000;
    ploscicaGNazaj4.Align := alLeft;
    ploscicaGNazaj4.BorderColor := clNone;
    ploscicaGNazaj4.BorderStyle := bsNone;
    ploscicaGNazaj4.ChildOrder := 1;
    ploscicaGNazaj4.Color := clWindow;
    gNazaj4.SetParentComponent(ploscicaGNazaj4);
    gNazaj4.Name := 'gNazaj4';
    gNazaj4.Left := 15;
    gNazaj4.Top := 0;
    gNazaj4.Width := 35;
    gNazaj4.Height := 40;
    gNazaj4.Color := clNone;
    gNazaj4.Flat := True;
    gNazaj4.Glyph.LoadFromFile('MBI_u04.ploscicaGNazaj4.gNazaj4.Glyph.png');
    gNazaj4.HeightPercent := 100.000000000000000000;
    gNazaj4.WidthPercent := 100.000000000000000000;
    SetEvent(gNazaj4, Self, 'OnClick', 'gNaprejNazajClick');
    ploscicaTabelaIzhodiscaLetniPrometMeseci.SetParentComponent(SimulacijaSkode_List4);
    ploscicaTabelaIzhodiscaLetniPrometMeseci.Name := 'ploscicaTabelaIzhodiscaLetniPrometMeseci';
    ploscicaTabelaIzhodiscaLetniPrometMeseci.Left := 30;
    ploscicaTabelaIzhodiscaLetniPrometMeseci.Top := 100;
    ploscicaTabelaIzhodiscaLetniPrometMeseci.Width := 281;
    ploscicaTabelaIzhodiscaLetniPrometMeseci.Height := 343;
    ploscicaTabelaIzhodiscaLetniPrometMeseci.HeightPercent := 100.000000000000000000;
    ploscicaTabelaIzhodiscaLetniPrometMeseci.WidthPercent := 100.000000000000000000;
    ploscicaTabelaIzhodiscaLetniPrometMeseci.ChildOrder := 2;
    ploscicaTabelaIzhodiscaLetniPrometMeseci.ColumnCollection.Clear;
    with ploscicaTabelaIzhodiscaLetniPrometMeseci.ColumnCollection.Add do
    begin
      Alignment := taCenter;
      Value := 50;
    end;
    with ploscicaTabelaIzhodiscaLetniPrometMeseci.ColumnCollection.Add do
    begin
      Alignment := taCenter;
      Value := 50;
    end;
    ploscicaTabelaIzhodiscaLetniPrometMeseci.ControlCollection.Clear;
    with ploscicaTabelaIzhodiscaLetniPrometMeseci.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaTabelaNepokritiStroski_Naslov11;
    end;
    with ploscicaTabelaIzhodiscaLetniPrometMeseci.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaTabelaNepokritiStroski_Naslov12;
    end;
    with ploscicaTabelaIzhodiscaLetniPrometMeseci.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := MesecPredZastojem_1;
    end;
    with ploscicaTabelaIzhodiscaLetniPrometMeseci.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := PrometPredZastojem_1;
    end;
    with ploscicaTabelaIzhodiscaLetniPrometMeseci.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := MesecPredZastojem_2;
    end;
    with ploscicaTabelaIzhodiscaLetniPrometMeseci.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := PrometPredZastojem_2;
    end;
    with ploscicaTabelaIzhodiscaLetniPrometMeseci.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := MesecPredZastojem_3;
    end;
    with ploscicaTabelaIzhodiscaLetniPrometMeseci.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := PrometPredZastojem_3;
    end;
    with ploscicaTabelaIzhodiscaLetniPrometMeseci.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := MesecPredZastojem_4;
    end;
    with ploscicaTabelaIzhodiscaLetniPrometMeseci.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := PrometPredZastojem_4;
    end;
    with ploscicaTabelaIzhodiscaLetniPrometMeseci.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := MesecPredZastojem_5;
    end;
    with ploscicaTabelaIzhodiscaLetniPrometMeseci.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := PrometPredZastojem_5;
    end;
    with ploscicaTabelaIzhodiscaLetniPrometMeseci.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := MesecPredZastojem_6;
    end;
    with ploscicaTabelaIzhodiscaLetniPrometMeseci.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := PrometPredZastojem_6;
    end;
    with ploscicaTabelaIzhodiscaLetniPrometMeseci.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := MesecPredZastojem_7;
    end;
    with ploscicaTabelaIzhodiscaLetniPrometMeseci.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := PrometPredZastojem_7;
    end;
    with ploscicaTabelaIzhodiscaLetniPrometMeseci.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := MesecPredZastojem_8;
    end;
    with ploscicaTabelaIzhodiscaLetniPrometMeseci.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := PrometPredZastojem_8;
    end;
    with ploscicaTabelaIzhodiscaLetniPrometMeseci.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := MesecPredZastojem_9;
    end;
    with ploscicaTabelaIzhodiscaLetniPrometMeseci.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := PrometPredZastojem_9;
    end;
    with ploscicaTabelaIzhodiscaLetniPrometMeseci.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := MesecPredZastojem_10;
    end;
    with ploscicaTabelaIzhodiscaLetniPrometMeseci.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := PrometPredZastojem_10;
    end;
    with ploscicaTabelaIzhodiscaLetniPrometMeseci.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := MesecPredZastojem_11;
    end;
    with ploscicaTabelaIzhodiscaLetniPrometMeseci.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := PrometPredZastojem_11;
    end;
    with ploscicaTabelaIzhodiscaLetniPrometMeseci.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := MesecPredZastojem_12;
    end;
    with ploscicaTabelaIzhodiscaLetniPrometMeseci.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := PrometPredZastojem_12;
    end;
    ploscicaTabelaIzhodiscaLetniPrometMeseci.Color := 15461355;
    ploscicaTabelaIzhodiscaLetniPrometMeseci.GridLineWidth := 1;
    ploscicaTabelaIzhodiscaLetniPrometMeseci.GridLineColor := clGray;
    ploscicaTabelaIzhodiscaLetniPrometMeseci.RowCollection.Clear;
    with ploscicaTabelaIzhodiscaLetniPrometMeseci.RowCollection.Add do
    begin
      Alignment := vaCenter;
      SizeStyle := ssAbsolute;
      Value := 40;
    end;
    with ploscicaTabelaIzhodiscaLetniPrometMeseci.RowCollection.Add do
    begin
      SizeStyle := ssAbsolute;
      Value := 24;
    end;
    with ploscicaTabelaIzhodiscaLetniPrometMeseci.RowCollection.Add do
    begin
      SizeStyle := ssAbsolute;
      Value := 24;
    end;
    with ploscicaTabelaIzhodiscaLetniPrometMeseci.RowCollection.Add do
    begin
      SizeStyle := ssAbsolute;
      Value := 24;
    end;
    with ploscicaTabelaIzhodiscaLetniPrometMeseci.RowCollection.Add do
    begin
      SizeStyle := ssAbsolute;
      Value := 24;
    end;
    with ploscicaTabelaIzhodiscaLetniPrometMeseci.RowCollection.Add do
    begin
      SizeStyle := ssAbsolute;
      Value := 24;
    end;
    with ploscicaTabelaIzhodiscaLetniPrometMeseci.RowCollection.Add do
    begin
      SizeStyle := ssAbsolute;
      Value := 24;
    end;
    with ploscicaTabelaIzhodiscaLetniPrometMeseci.RowCollection.Add do
    begin
      SizeStyle := ssAbsolute;
      Value := 24;
    end;
    with ploscicaTabelaIzhodiscaLetniPrometMeseci.RowCollection.Add do
    begin
      SizeStyle := ssAbsolute;
      Value := 24;
    end;
    with ploscicaTabelaIzhodiscaLetniPrometMeseci.RowCollection.Add do
    begin
      SizeStyle := ssAbsolute;
      Value := 24;
    end;
    with ploscicaTabelaIzhodiscaLetniPrometMeseci.RowCollection.Add do
    begin
      SizeStyle := ssAbsolute;
      Value := 24;
    end;
    with ploscicaTabelaIzhodiscaLetniPrometMeseci.RowCollection.Add do
    begin
      SizeStyle := ssAbsolute;
      Value := 24;
    end;
    with ploscicaTabelaIzhodiscaLetniPrometMeseci.RowCollection.Add do
    begin
      SizeStyle := ssAbsolute;
      Value := 24;
    end;
    ploscicaTabelaNepokritiStroski_Naslov11.SetParentComponent(ploscicaTabelaIzhodiscaLetniPrometMeseci);
    ploscicaTabelaNepokritiStroski_Naslov11.Name := 'ploscicaTabelaNepokritiStroski_Naslov11';
    ploscicaTabelaNepokritiStroski_Naslov11.Left := 2;
    ploscicaTabelaNepokritiStroski_Naslov11.Top := 3;
    ploscicaTabelaNepokritiStroski_Naslov11.Width := 135;
    ploscicaTabelaNepokritiStroski_Naslov11.Height := 28;
    ploscicaTabelaNepokritiStroski_Naslov11.Align := alCustom;
    ploscicaTabelaNepokritiStroski_Naslov11.Anchors := [];
    ploscicaTabelaNepokritiStroski_Naslov11.AutoSize := False;
    ploscicaTabelaNepokritiStroski_Naslov11.BorderStyle := bsNone;
    ploscicaTabelaNepokritiStroski_Naslov11.HeightPercent := 100.000000000000000000;
    ploscicaTabelaNepokritiStroski_Naslov11.Lines.BeginUpdate;
    try
      ploscicaTabelaNepokritiStroski_Naslov11.Lines.Clear;
      ploscicaTabelaNepokritiStroski_Naslov11.Lines.Add('12 mes. pred '#353'kodo');
    finally
      ploscicaTabelaNepokritiStroski_Naslov11.Lines.EndUpdate;
    end;
    ploscicaTabelaNepokritiStroski_Naslov11.ParentColor := True;
    ploscicaTabelaNepokritiStroski_Naslov11.ReadOnly := True;
    ploscicaTabelaNepokritiStroski_Naslov11.SelLength := 0;
    ploscicaTabelaNepokritiStroski_Naslov11.SelStart := 20;
    ploscicaTabelaNepokritiStroski_Naslov11.ShowFocus := False;
    ploscicaTabelaNepokritiStroski_Naslov11.SpellCheck := False;
    ploscicaTabelaNepokritiStroski_Naslov11.WidthPercent := 100.000000000000000000;
    ploscicaTabelaNepokritiStroski_Naslov12.SetParentComponent(ploscicaTabelaIzhodiscaLetniPrometMeseci);
    ploscicaTabelaNepokritiStroski_Naslov12.Name := 'ploscicaTabelaNepokritiStroski_Naslov12';
    ploscicaTabelaNepokritiStroski_Naslov12.Left := 143;
    ploscicaTabelaNepokritiStroski_Naslov12.Top := 3;
    ploscicaTabelaNepokritiStroski_Naslov12.Width := 135;
    ploscicaTabelaNepokritiStroski_Naslov12.Height := 28;
    ploscicaTabelaNepokritiStroski_Naslov12.Align := alCustom;
    ploscicaTabelaNepokritiStroski_Naslov12.Anchors := [];
    ploscicaTabelaNepokritiStroski_Naslov12.AutoSize := False;
    ploscicaTabelaNepokritiStroski_Naslov12.BorderStyle := bsNone;
    ploscicaTabelaNepokritiStroski_Naslov12.HeightPercent := 100.000000000000000000;
    ploscicaTabelaNepokritiStroski_Naslov12.Lines.BeginUpdate;
    try
      ploscicaTabelaNepokritiStroski_Naslov12.Lines.Clear;
      ploscicaTabelaNepokritiStroski_Naslov12.Lines.Add('dose'#382'en promet');
    finally
      ploscicaTabelaNepokritiStroski_Naslov12.Lines.EndUpdate;
    end;
    ploscicaTabelaNepokritiStroski_Naslov12.ParentColor := True;
    ploscicaTabelaNepokritiStroski_Naslov12.ReadOnly := True;
    ploscicaTabelaNepokritiStroski_Naslov12.SelLength := 0;
    ploscicaTabelaNepokritiStroski_Naslov12.SelStart := 16;
    ploscicaTabelaNepokritiStroski_Naslov12.ShowFocus := False;
    ploscicaTabelaNepokritiStroski_Naslov12.SpellCheck := False;
    ploscicaTabelaNepokritiStroski_Naslov12.WidthPercent := 100.000000000000000000;
    MesecPredZastojem_1.SetParentComponent(ploscicaTabelaIzhodiscaLetniPrometMeseci);
    MesecPredZastojem_1.Name := 'MesecPredZastojem_1';
    MesecPredZastojem_1.Left := 2;
    MesecPredZastojem_1.Top := 42;
    MesecPredZastojem_1.Width := 137;
    MesecPredZastojem_1.Height := 22;
    MesecPredZastojem_1.Anchors := [];
    MesecPredZastojem_1.BorderStyle := bsNone;
    MesecPredZastojem_1.ChildOrder := 2;
    MesecPredZastojem_1.HeightPercent := 100.000000000000000000;
    MesecPredZastojem_1.Text := '0.00';
    MesecPredZastojem_1.WidthPercent := 100.000000000000000000;
    PrometPredZastojem_1.SetParentComponent(ploscicaTabelaIzhodiscaLetniPrometMeseci);
    PrometPredZastojem_1.Name := 'PrometPredZastojem_1';
    PrometPredZastojem_1.Left := 142;
    PrometPredZastojem_1.Top := 42;
    PrometPredZastojem_1.Width := 137;
    PrometPredZastojem_1.Height := 22;
    PrometPredZastojem_1.Alignment := taRightJustify;
    PrometPredZastojem_1.Anchors := [];
    PrometPredZastojem_1.BorderStyle := bsNone;
    PrometPredZastojem_1.ChildOrder := 3;
    PrometPredZastojem_1.HeightPercent := 100.000000000000000000;
    PrometPredZastojem_1.Text := '0.00';
    PrometPredZastojem_1.WidthPercent := 100.000000000000000000;
    MesecPredZastojem_2.SetParentComponent(ploscicaTabelaIzhodiscaLetniPrometMeseci);
    MesecPredZastojem_2.Name := 'MesecPredZastojem_2';
    MesecPredZastojem_2.Left := 2;
    MesecPredZastojem_2.Top := 66;
    MesecPredZastojem_2.Width := 137;
    MesecPredZastojem_2.Height := 22;
    MesecPredZastojem_2.Anchors := [];
    MesecPredZastojem_2.BorderStyle := bsNone;
    MesecPredZastojem_2.ChildOrder := 4;
    MesecPredZastojem_2.HeightPercent := 100.000000000000000000;
    MesecPredZastojem_2.Text := '0.00';
    MesecPredZastojem_2.WidthPercent := 100.000000000000000000;
    PrometPredZastojem_2.SetParentComponent(ploscicaTabelaIzhodiscaLetniPrometMeseci);
    PrometPredZastojem_2.Name := 'PrometPredZastojem_2';
    PrometPredZastojem_2.Left := 142;
    PrometPredZastojem_2.Top := 66;
    PrometPredZastojem_2.Width := 137;
    PrometPredZastojem_2.Height := 22;
    PrometPredZastojem_2.Alignment := taRightJustify;
    PrometPredZastojem_2.Anchors := [];
    PrometPredZastojem_2.BorderStyle := bsNone;
    PrometPredZastojem_2.ChildOrder := 5;
    PrometPredZastojem_2.HeightPercent := 100.000000000000000000;
    PrometPredZastojem_2.Text := '0.00';
    PrometPredZastojem_2.WidthPercent := 100.000000000000000000;
    MesecPredZastojem_3.SetParentComponent(ploscicaTabelaIzhodiscaLetniPrometMeseci);
    MesecPredZastojem_3.Name := 'MesecPredZastojem_3';
    MesecPredZastojem_3.Left := 2;
    MesecPredZastojem_3.Top := 90;
    MesecPredZastojem_3.Width := 137;
    MesecPredZastojem_3.Height := 22;
    MesecPredZastojem_3.Anchors := [];
    MesecPredZastojem_3.BorderStyle := bsNone;
    MesecPredZastojem_3.ChildOrder := 6;
    MesecPredZastojem_3.HeightPercent := 100.000000000000000000;
    MesecPredZastojem_3.Text := '0.00';
    MesecPredZastojem_3.WidthPercent := 100.000000000000000000;
    PrometPredZastojem_3.SetParentComponent(ploscicaTabelaIzhodiscaLetniPrometMeseci);
    PrometPredZastojem_3.Name := 'PrometPredZastojem_3';
    PrometPredZastojem_3.Left := 142;
    PrometPredZastojem_3.Top := 90;
    PrometPredZastojem_3.Width := 137;
    PrometPredZastojem_3.Height := 22;
    PrometPredZastojem_3.Alignment := taRightJustify;
    PrometPredZastojem_3.Anchors := [];
    PrometPredZastojem_3.BorderStyle := bsNone;
    PrometPredZastojem_3.ChildOrder := 7;
    PrometPredZastojem_3.HeightPercent := 100.000000000000000000;
    PrometPredZastojem_3.Text := '0.00';
    PrometPredZastojem_3.WidthPercent := 100.000000000000000000;
    MesecPredZastojem_4.SetParentComponent(ploscicaTabelaIzhodiscaLetniPrometMeseci);
    MesecPredZastojem_4.Name := 'MesecPredZastojem_4';
    MesecPredZastojem_4.Left := 2;
    MesecPredZastojem_4.Top := 114;
    MesecPredZastojem_4.Width := 137;
    MesecPredZastojem_4.Height := 22;
    MesecPredZastojem_4.Anchors := [];
    MesecPredZastojem_4.BorderStyle := bsNone;
    MesecPredZastojem_4.ChildOrder := 8;
    MesecPredZastojem_4.HeightPercent := 100.000000000000000000;
    MesecPredZastojem_4.Text := '0.00';
    MesecPredZastojem_4.WidthPercent := 100.000000000000000000;
    PrometPredZastojem_4.SetParentComponent(ploscicaTabelaIzhodiscaLetniPrometMeseci);
    PrometPredZastojem_4.Name := 'PrometPredZastojem_4';
    PrometPredZastojem_4.Left := 142;
    PrometPredZastojem_4.Top := 114;
    PrometPredZastojem_4.Width := 137;
    PrometPredZastojem_4.Height := 22;
    PrometPredZastojem_4.Alignment := taRightJustify;
    PrometPredZastojem_4.Anchors := [];
    PrometPredZastojem_4.BorderStyle := bsNone;
    PrometPredZastojem_4.ChildOrder := 9;
    PrometPredZastojem_4.HeightPercent := 100.000000000000000000;
    PrometPredZastojem_4.Text := '0.00';
    PrometPredZastojem_4.WidthPercent := 100.000000000000000000;
    MesecPredZastojem_5.SetParentComponent(ploscicaTabelaIzhodiscaLetniPrometMeseci);
    MesecPredZastojem_5.Name := 'MesecPredZastojem_5';
    MesecPredZastojem_5.Left := 2;
    MesecPredZastojem_5.Top := 138;
    MesecPredZastojem_5.Width := 137;
    MesecPredZastojem_5.Height := 22;
    MesecPredZastojem_5.Anchors := [];
    MesecPredZastojem_5.BorderStyle := bsNone;
    MesecPredZastojem_5.ChildOrder := 10;
    MesecPredZastojem_5.HeightPercent := 100.000000000000000000;
    MesecPredZastojem_5.Text := '0.00';
    MesecPredZastojem_5.WidthPercent := 100.000000000000000000;
    PrometPredZastojem_5.SetParentComponent(ploscicaTabelaIzhodiscaLetniPrometMeseci);
    PrometPredZastojem_5.Name := 'PrometPredZastojem_5';
    PrometPredZastojem_5.Left := 142;
    PrometPredZastojem_5.Top := 138;
    PrometPredZastojem_5.Width := 137;
    PrometPredZastojem_5.Height := 22;
    PrometPredZastojem_5.Alignment := taRightJustify;
    PrometPredZastojem_5.Anchors := [];
    PrometPredZastojem_5.BorderStyle := bsNone;
    PrometPredZastojem_5.ChildOrder := 11;
    PrometPredZastojem_5.HeightPercent := 100.000000000000000000;
    PrometPredZastojem_5.Text := '0.00';
    PrometPredZastojem_5.WidthPercent := 100.000000000000000000;
    MesecPredZastojem_6.SetParentComponent(ploscicaTabelaIzhodiscaLetniPrometMeseci);
    MesecPredZastojem_6.Name := 'MesecPredZastojem_6';
    MesecPredZastojem_6.Left := 2;
    MesecPredZastojem_6.Top := 162;
    MesecPredZastojem_6.Width := 137;
    MesecPredZastojem_6.Height := 22;
    MesecPredZastojem_6.Anchors := [];
    MesecPredZastojem_6.BorderStyle := bsNone;
    MesecPredZastojem_6.ChildOrder := 12;
    MesecPredZastojem_6.HeightPercent := 100.000000000000000000;
    MesecPredZastojem_6.Text := '0.00';
    MesecPredZastojem_6.WidthPercent := 100.000000000000000000;
    PrometPredZastojem_6.SetParentComponent(ploscicaTabelaIzhodiscaLetniPrometMeseci);
    PrometPredZastojem_6.Name := 'PrometPredZastojem_6';
    PrometPredZastojem_6.Left := 142;
    PrometPredZastojem_6.Top := 162;
    PrometPredZastojem_6.Width := 137;
    PrometPredZastojem_6.Height := 22;
    PrometPredZastojem_6.Alignment := taRightJustify;
    PrometPredZastojem_6.Anchors := [];
    PrometPredZastojem_6.BorderStyle := bsNone;
    PrometPredZastojem_6.ChildOrder := 13;
    PrometPredZastojem_6.HeightPercent := 100.000000000000000000;
    PrometPredZastojem_6.Text := '0.00';
    PrometPredZastojem_6.WidthPercent := 100.000000000000000000;
    MesecPredZastojem_7.SetParentComponent(ploscicaTabelaIzhodiscaLetniPrometMeseci);
    MesecPredZastojem_7.Name := 'MesecPredZastojem_7';
    MesecPredZastojem_7.Left := 2;
    MesecPredZastojem_7.Top := 186;
    MesecPredZastojem_7.Width := 137;
    MesecPredZastojem_7.Height := 22;
    MesecPredZastojem_7.Anchors := [];
    MesecPredZastojem_7.BorderStyle := bsNone;
    MesecPredZastojem_7.ChildOrder := 14;
    MesecPredZastojem_7.HeightPercent := 100.000000000000000000;
    MesecPredZastojem_7.Text := '0.00';
    MesecPredZastojem_7.WidthPercent := 100.000000000000000000;
    PrometPredZastojem_7.SetParentComponent(ploscicaTabelaIzhodiscaLetniPrometMeseci);
    PrometPredZastojem_7.Name := 'PrometPredZastojem_7';
    PrometPredZastojem_7.Left := 142;
    PrometPredZastojem_7.Top := 186;
    PrometPredZastojem_7.Width := 137;
    PrometPredZastojem_7.Height := 22;
    PrometPredZastojem_7.Alignment := taRightJustify;
    PrometPredZastojem_7.Anchors := [];
    PrometPredZastojem_7.BorderStyle := bsNone;
    PrometPredZastojem_7.ChildOrder := 15;
    PrometPredZastojem_7.HeightPercent := 100.000000000000000000;
    PrometPredZastojem_7.Text := '0.00';
    PrometPredZastojem_7.WidthPercent := 100.000000000000000000;
    MesecPredZastojem_8.SetParentComponent(ploscicaTabelaIzhodiscaLetniPrometMeseci);
    MesecPredZastojem_8.Name := 'MesecPredZastojem_8';
    MesecPredZastojem_8.Left := 2;
    MesecPredZastojem_8.Top := 210;
    MesecPredZastojem_8.Width := 137;
    MesecPredZastojem_8.Height := 22;
    MesecPredZastojem_8.Anchors := [];
    MesecPredZastojem_8.BorderStyle := bsNone;
    MesecPredZastojem_8.ChildOrder := 16;
    MesecPredZastojem_8.HeightPercent := 100.000000000000000000;
    MesecPredZastojem_8.Text := '0.00';
    MesecPredZastojem_8.WidthPercent := 100.000000000000000000;
    PrometPredZastojem_8.SetParentComponent(ploscicaTabelaIzhodiscaLetniPrometMeseci);
    PrometPredZastojem_8.Name := 'PrometPredZastojem_8';
    PrometPredZastojem_8.Left := 142;
    PrometPredZastojem_8.Top := 210;
    PrometPredZastojem_8.Width := 137;
    PrometPredZastojem_8.Height := 22;
    PrometPredZastojem_8.Alignment := taRightJustify;
    PrometPredZastojem_8.Anchors := [];
    PrometPredZastojem_8.BorderStyle := bsNone;
    PrometPredZastojem_8.ChildOrder := 17;
    PrometPredZastojem_8.HeightPercent := 100.000000000000000000;
    PrometPredZastojem_8.Text := '0.00';
    PrometPredZastojem_8.WidthPercent := 100.000000000000000000;
    MesecPredZastojem_9.SetParentComponent(ploscicaTabelaIzhodiscaLetniPrometMeseci);
    MesecPredZastojem_9.Name := 'MesecPredZastojem_9';
    MesecPredZastojem_9.Left := 2;
    MesecPredZastojem_9.Top := 234;
    MesecPredZastojem_9.Width := 137;
    MesecPredZastojem_9.Height := 22;
    MesecPredZastojem_9.Anchors := [];
    MesecPredZastojem_9.BorderStyle := bsNone;
    MesecPredZastojem_9.ChildOrder := 18;
    MesecPredZastojem_9.HeightPercent := 100.000000000000000000;
    MesecPredZastojem_9.Text := '0.00';
    MesecPredZastojem_9.WidthPercent := 100.000000000000000000;
    PrometPredZastojem_9.SetParentComponent(ploscicaTabelaIzhodiscaLetniPrometMeseci);
    PrometPredZastojem_9.Name := 'PrometPredZastojem_9';
    PrometPredZastojem_9.Left := 142;
    PrometPredZastojem_9.Top := 234;
    PrometPredZastojem_9.Width := 137;
    PrometPredZastojem_9.Height := 22;
    PrometPredZastojem_9.Alignment := taRightJustify;
    PrometPredZastojem_9.Anchors := [];
    PrometPredZastojem_9.BorderStyle := bsNone;
    PrometPredZastojem_9.ChildOrder := 19;
    PrometPredZastojem_9.HeightPercent := 100.000000000000000000;
    PrometPredZastojem_9.Text := '0.00';
    PrometPredZastojem_9.WidthPercent := 100.000000000000000000;
    MesecPredZastojem_10.SetParentComponent(ploscicaTabelaIzhodiscaLetniPrometMeseci);
    MesecPredZastojem_10.Name := 'MesecPredZastojem_10';
    MesecPredZastojem_10.Left := 2;
    MesecPredZastojem_10.Top := 258;
    MesecPredZastojem_10.Width := 137;
    MesecPredZastojem_10.Height := 22;
    MesecPredZastojem_10.Anchors := [];
    MesecPredZastojem_10.BorderStyle := bsNone;
    MesecPredZastojem_10.ChildOrder := 20;
    MesecPredZastojem_10.HeightPercent := 100.000000000000000000;
    MesecPredZastojem_10.Text := '0.00';
    MesecPredZastojem_10.WidthPercent := 100.000000000000000000;
    PrometPredZastojem_10.SetParentComponent(ploscicaTabelaIzhodiscaLetniPrometMeseci);
    PrometPredZastojem_10.Name := 'PrometPredZastojem_10';
    PrometPredZastojem_10.Left := 142;
    PrometPredZastojem_10.Top := 258;
    PrometPredZastojem_10.Width := 137;
    PrometPredZastojem_10.Height := 22;
    PrometPredZastojem_10.Alignment := taRightJustify;
    PrometPredZastojem_10.Anchors := [];
    PrometPredZastojem_10.BorderStyle := bsNone;
    PrometPredZastojem_10.ChildOrder := 21;
    PrometPredZastojem_10.HeightPercent := 100.000000000000000000;
    PrometPredZastojem_10.Text := '0.00';
    PrometPredZastojem_10.WidthPercent := 100.000000000000000000;
    MesecPredZastojem_11.SetParentComponent(ploscicaTabelaIzhodiscaLetniPrometMeseci);
    MesecPredZastojem_11.Name := 'MesecPredZastojem_11';
    MesecPredZastojem_11.Left := 2;
    MesecPredZastojem_11.Top := 282;
    MesecPredZastojem_11.Width := 137;
    MesecPredZastojem_11.Height := 22;
    MesecPredZastojem_11.Anchors := [];
    MesecPredZastojem_11.BorderStyle := bsNone;
    MesecPredZastojem_11.ChildOrder := 22;
    MesecPredZastojem_11.HeightPercent := 100.000000000000000000;
    MesecPredZastojem_11.Text := '0.00';
    MesecPredZastojem_11.WidthPercent := 100.000000000000000000;
    PrometPredZastojem_11.SetParentComponent(ploscicaTabelaIzhodiscaLetniPrometMeseci);
    PrometPredZastojem_11.Name := 'PrometPredZastojem_11';
    PrometPredZastojem_11.Left := 142;
    PrometPredZastojem_11.Top := 282;
    PrometPredZastojem_11.Width := 137;
    PrometPredZastojem_11.Height := 22;
    PrometPredZastojem_11.Alignment := taRightJustify;
    PrometPredZastojem_11.Anchors := [];
    PrometPredZastojem_11.BorderStyle := bsNone;
    PrometPredZastojem_11.ChildOrder := 23;
    PrometPredZastojem_11.HeightPercent := 100.000000000000000000;
    PrometPredZastojem_11.Text := '0.00';
    PrometPredZastojem_11.WidthPercent := 100.000000000000000000;
    MesecPredZastojem_12.SetParentComponent(ploscicaTabelaIzhodiscaLetniPrometMeseci);
    MesecPredZastojem_12.Name := 'MesecPredZastojem_12';
    MesecPredZastojem_12.Left := 2;
    MesecPredZastojem_12.Top := 306;
    MesecPredZastojem_12.Width := 137;
    MesecPredZastojem_12.Height := 22;
    MesecPredZastojem_12.Anchors := [];
    MesecPredZastojem_12.BorderStyle := bsNone;
    MesecPredZastojem_12.ChildOrder := 24;
    MesecPredZastojem_12.HeightPercent := 100.000000000000000000;
    MesecPredZastojem_12.Text := '0.00';
    MesecPredZastojem_12.WidthPercent := 100.000000000000000000;
    PrometPredZastojem_12.SetParentComponent(ploscicaTabelaIzhodiscaLetniPrometMeseci);
    PrometPredZastojem_12.Name := 'PrometPredZastojem_12';
    PrometPredZastojem_12.Left := 142;
    PrometPredZastojem_12.Top := 306;
    PrometPredZastojem_12.Width := 137;
    PrometPredZastojem_12.Height := 22;
    PrometPredZastojem_12.Alignment := taRightJustify;
    PrometPredZastojem_12.Anchors := [];
    PrometPredZastojem_12.BorderStyle := bsNone;
    PrometPredZastojem_12.ChildOrder := 25;
    PrometPredZastojem_12.HeightPercent := 100.000000000000000000;
    PrometPredZastojem_12.Text := '0.00';
    PrometPredZastojem_12.WidthPercent := 100.000000000000000000;
    ploscicaTabelaIzhodiscaLetniPrometSkupaj.SetParentComponent(SimulacijaSkode_List4);
    ploscicaTabelaIzhodiscaLetniPrometSkupaj.Name := 'ploscicaTabelaIzhodiscaLetniPrometSkupaj';
    ploscicaTabelaIzhodiscaLetniPrometSkupaj.Left := 30;
    ploscicaTabelaIzhodiscaLetniPrometSkupaj.Top := 450;
    ploscicaTabelaIzhodiscaLetniPrometSkupaj.Width := 281;
    ploscicaTabelaIzhodiscaLetniPrometSkupaj.Height := 60;
    ploscicaTabelaIzhodiscaLetniPrometSkupaj.HeightPercent := 100.000000000000000000;
    ploscicaTabelaIzhodiscaLetniPrometSkupaj.WidthPercent := 100.000000000000000000;
    ploscicaTabelaIzhodiscaLetniPrometSkupaj.ChildOrder := 3;
    ploscicaTabelaIzhodiscaLetniPrometSkupaj.ColumnCollection.Clear;
    with ploscicaTabelaIzhodiscaLetniPrometSkupaj.ColumnCollection.Add do
    begin
      Alignment := taRightJustify;
      MarginLeft := 3;
      Value := 50;
    end;
    with ploscicaTabelaIzhodiscaLetniPrometSkupaj.ColumnCollection.Add do
    begin
      Alignment := taCenter;
      Value := 50;
    end;
    ploscicaTabelaIzhodiscaLetniPrometSkupaj.ControlCollection.Clear;
    with ploscicaTabelaIzhodiscaLetniPrometSkupaj.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izIzhodiscaLetniPrometTekst;
    end;
    with ploscicaTabelaIzhodiscaLetniPrometSkupaj.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izIzhodiscaLetniPromet;
    end;
    ploscicaTabelaIzhodiscaLetniPrometSkupaj.Color := clNone;
    ploscicaTabelaIzhodiscaLetniPrometSkupaj.GridLineColor := clGray;
    ploscicaTabelaIzhodiscaLetniPrometSkupaj.RowCollection.Clear;
    with ploscicaTabelaIzhodiscaLetniPrometSkupaj.RowCollection.Add do
    begin
      Alignment := vaCenter;
      SizeStyle := ssAbsolute;
      Value := 30;
    end;
    izIzhodiscaLetniPrometTekst.SetParentComponent(ploscicaTabelaIzhodiscaLetniPrometSkupaj);
    izIzhodiscaLetniPrometTekst.Name := 'izIzhodiscaLetniPrometTekst';
    izIzhodiscaLetniPrometTekst.Left := 68;
    izIzhodiscaLetniPrometTekst.Top := 8;
    izIzhodiscaLetniPrometTekst.Width := 70;
    izIzhodiscaLetniPrometTekst.Height := 14;
    izIzhodiscaLetniPrometTekst.Alignment := taRightJustify;
    izIzhodiscaLetniPrometTekst.Anchors := [];
    izIzhodiscaLetniPrometTekst.Caption := 'Promet skupaj:';
    izIzhodiscaLetniPrometTekst.Color := clNone;
    izIzhodiscaLetniPrometTekst.HeightPercent := 100.000000000000000000;
    izIzhodiscaLetniPrometTekst.WidthPercent := 100.000000000000000000;
    izIzhodiscaLetniPromet.SetParentComponent(ploscicaTabelaIzhodiscaLetniPrometSkupaj);
    izIzhodiscaLetniPromet.Name := 'izIzhodiscaLetniPromet';
    izIzhodiscaLetniPromet.Left := 142;
    izIzhodiscaLetniPromet.Top := 4;
    izIzhodiscaLetniPromet.Width := 137;
    izIzhodiscaLetniPromet.Height := 22;
    izIzhodiscaLetniPromet.TabStop := False;
    izIzhodiscaLetniPromet.Alignment := taRightJustify;
    izIzhodiscaLetniPromet.Anchors := [];
    izIzhodiscaLetniPromet.AutoSelect := False;
    izIzhodiscaLetniPromet.ChildOrder := 1;
    izIzhodiscaLetniPromet.HeightPercent := 100.000000000000000000;
    izIzhodiscaLetniPromet.ReadOnly := True;
    izIzhodiscaLetniPromet.ShowFocus := False;
    izIzhodiscaLetniPromet.Text := '0.00';
    izIzhodiscaLetniPromet.WidthPercent := 100.000000000000000000;
    ploscicaPodzavarovanje.SetParentComponent(SimulacijaSkode_List4);
    ploscicaPodzavarovanje.Name := 'ploscicaPodzavarovanje';
    ploscicaPodzavarovanje.Left := 370;
    ploscicaPodzavarovanje.Top := 100;
    ploscicaPodzavarovanje.Width := 380;
    ploscicaPodzavarovanje.Height := 260;
    ploscicaPodzavarovanje.HeightPercent := 100.000000000000000000;
    ploscicaPodzavarovanje.WidthPercent := 100.000000000000000000;
    ploscicaPodzavarovanje.ChildOrder := 4;
    ploscicaPodzavarovanje.ColumnCollection.Clear;
    with ploscicaPodzavarovanje.ColumnCollection.Add do
    begin
      SizeStyle := ssAbsolute;
      Value := 220;
    end;
    with ploscicaPodzavarovanje.ColumnCollection.Add do
    begin
      Alignment := taRightJustify;
      SizeStyle := ssAbsolute;
      Value := 120;
    end;
    with ploscicaPodzavarovanje.ColumnCollection.Add do
    begin
      Alignment := taCenter;
      SizeStyle := ssAbsolute;
      Value := 40;
    end;
    ploscicaPodzavarovanje.ControlCollection.Clear;
    with ploscicaPodzavarovanje.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izLetniPrometOsnovaTekst;
    end;
    with ploscicaPodzavarovanje.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izLetniPrometOsnova;
    end;
    with ploscicaPodzavarovanje.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := PodzavarovanjePrazno1;
    end;
    with ploscicaPodzavarovanje.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izLetniPrometRastTekst;
    end;
    with ploscicaPodzavarovanje.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izLetniPrometRast;
    end;
    with ploscicaPodzavarovanje.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := PodzavarovanjePrazno2;
    end;
    with ploscicaPodzavarovanje.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izLetniPrometTekst;
    end;
    with ploscicaPodzavarovanje.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izLetniPromet;
    end;
    with ploscicaPodzavarovanje.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := PodzavarovanjePrazno3;
    end;
    with ploscicaPodzavarovanje.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izPotrebnaZVTekst;
    end;
    with ploscicaPodzavarovanje.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izPotrebnaZV;
    end;
    with ploscicaPodzavarovanje.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := PodzavarovanjePrazno4;
    end;
    with ploscicaPodzavarovanje.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izDejanskaZVTekst;
    end;
    with ploscicaPodzavarovanje.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izDejanskaZV;
    end;
    with ploscicaPodzavarovanje.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := PodzavarovanjePrazno5;
    end;
    with ploscicaPodzavarovanje.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izPodzavarovanjeTekst;
    end;
    with ploscicaPodzavarovanje.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izPodzavarovanje;
    end;
    with ploscicaPodzavarovanje.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := PodzavarovanjePrazno6;
    end;
    ploscicaPodzavarovanje.Color := clWhite;
    ploscicaPodzavarovanje.GridLineColor := clBlack;
    ploscicaPodzavarovanje.RowCollection.Clear;
    with ploscicaPodzavarovanje.RowCollection.Add do
    begin
      Alignment := vaCenter;
      SizeStyle := ssAbsolute;
      Value := 40;
    end;
    with ploscicaPodzavarovanje.RowCollection.Add do
    begin
      Alignment := vaCenter;
      SizeStyle := ssAbsolute;
      Value := 40;
    end;
    with ploscicaPodzavarovanje.RowCollection.Add do
    begin
      Alignment := vaCenter;
      SizeStyle := ssAbsolute;
      Value := 40;
    end;
    with ploscicaPodzavarovanje.RowCollection.Add do
    begin
      Alignment := vaCenter;
      SizeStyle := ssAbsolute;
      Value := 40;
    end;
    with ploscicaPodzavarovanje.RowCollection.Add do
    begin
      Alignment := vaCenter;
      SizeStyle := ssAbsolute;
      Value := 40;
    end;
    with ploscicaPodzavarovanje.RowCollection.Add do
    begin
      Alignment := vaCenter;
      SizeStyle := ssAbsolute;
      Value := 40;
    end;
    izLetniPrometOsnovaTekst.SetParentComponent(ploscicaPodzavarovanje);
    izLetniPrometOsnovaTekst.Name := 'izLetniPrometOsnovaTekst';
    izLetniPrometOsnovaTekst.Left := 2;
    izLetniPrometOsnovaTekst.Top := 13;
    izLetniPrometOsnovaTekst.Width := 114;
    izLetniPrometOsnovaTekst.Height := 14;
    izLetniPrometOsnovaTekst.Alignment := taRightJustify;
    izLetniPrometOsnovaTekst.Anchors := [];
    izLetniPrometOsnovaTekst.Caption := 'Osnova za letni promet:';
    izLetniPrometOsnovaTekst.Color := clNone;
    izLetniPrometOsnovaTekst.HeightPercent := 100.000000000000000000;
    izLetniPrometOsnovaTekst.WidthPercent := 100.000000000000000000;
    izLetniPrometRastTekst.SetParentComponent(ploscicaPodzavarovanje);
    izLetniPrometRastTekst.Name := 'izLetniPrometRastTekst';
    izLetniPrometRastTekst.Left := 2;
    izLetniPrometRastTekst.Top := 53;
    izLetniPrometRastTekst.Width := 79;
    izLetniPrometRastTekst.Height := 14;
    izLetniPrometRastTekst.Alignment := taRightJustify;
    izLetniPrometRastTekst.Anchors := [];
    izLetniPrometRastTekst.Caption := 'Predvidena rast:';
    izLetniPrometRastTekst.Color := clNone;
    izLetniPrometRastTekst.HeightPercent := 100.000000000000000000;
    izLetniPrometRastTekst.WidthPercent := 100.000000000000000000;
    izLetniPrometTekst.SetParentComponent(ploscicaPodzavarovanje);
    izLetniPrometTekst.Name := 'izLetniPrometTekst';
    izLetniPrometTekst.Left := 2;
    izLetniPrometTekst.Top := 93;
    izLetniPrometTekst.Width := 62;
    izLetniPrometTekst.Height := 14;
    izLetniPrometTekst.Alignment := taRightJustify;
    izLetniPrometTekst.Anchors := [];
    izLetniPrometTekst.Caption := 'Letni promet:';
    izLetniPrometTekst.Color := clNone;
    izLetniPrometTekst.HeightPercent := 100.000000000000000000;
    izLetniPrometTekst.WidthPercent := 100.000000000000000000;
    izPotrebnaZVTekst.SetParentComponent(ploscicaPodzavarovanje);
    izPotrebnaZVTekst.Name := 'izPotrebnaZVTekst';
    izPotrebnaZVTekst.Left := 2;
    izPotrebnaZVTekst.Top := 133;
    izPotrebnaZVTekst.Width := 139;
    izPotrebnaZVTekst.Height := 14;
    izPotrebnaZVTekst.Alignment := taRightJustify;
    izPotrebnaZVTekst.Anchors := [];
    izPotrebnaZVTekst.Caption := 'Potrebna zavarovalna vsota:';
    izPotrebnaZVTekst.Color := clNone;
    izPotrebnaZVTekst.HeightPercent := 100.000000000000000000;
    izPotrebnaZVTekst.WidthPercent := 100.000000000000000000;
    izDejanskaZVTekst.SetParentComponent(ploscicaPodzavarovanje);
    izDejanskaZVTekst.Name := 'izDejanskaZVTekst';
    izDejanskaZVTekst.Left := 2;
    izDejanskaZVTekst.Top := 173;
    izDejanskaZVTekst.Width := 140;
    izDejanskaZVTekst.Height := 14;
    izDejanskaZVTekst.Alignment := taRightJustify;
    izDejanskaZVTekst.Anchors := [];
    izDejanskaZVTekst.Caption := 'Dejanska zavarovalna vsota:';
    izDejanskaZVTekst.Color := clNone;
    izDejanskaZVTekst.HeightPercent := 100.000000000000000000;
    izDejanskaZVTekst.WidthPercent := 100.000000000000000000;
    izPodzavarovanjeTekst.SetParentComponent(ploscicaPodzavarovanje);
    izPodzavarovanjeTekst.Name := 'izPodzavarovanjeTekst';
    izPodzavarovanjeTekst.Left := 2;
    izPodzavarovanjeTekst.Top := 213;
    izPodzavarovanjeTekst.Width := 89;
    izPodzavarovanjeTekst.Height := 14;
    izPodzavarovanjeTekst.Alignment := taRightJustify;
    izPodzavarovanjeTekst.Anchors := [];
    izPodzavarovanjeTekst.Caption := 'Podzavarovanje:';
    izPodzavarovanjeTekst.Color := clNone;
    izPodzavarovanjeTekst.Font.Charset := DEFAULT_CHARSET;
    izPodzavarovanjeTekst.Font.Color := clWindowText;
    izPodzavarovanjeTekst.Font.Height := -11;
    izPodzavarovanjeTekst.Font.Name := 'Arial';
    izPodzavarovanjeTekst.Font.Style := [fsBold];
    izPodzavarovanjeTekst.HeightPercent := 100.000000000000000000;
    izPodzavarovanjeTekst.ParentFont := False;
    izPodzavarovanjeTekst.WidthPercent := 100.000000000000000000;
    izLetniPrometOsnova.SetParentComponent(ploscicaPodzavarovanje);
    izLetniPrometOsnova.Name := 'izLetniPrometOsnova';
    izLetniPrometOsnova.Left := 163;
    izLetniPrometOsnova.Top := 9;
    izLetniPrometOsnova.Width := 175;
    izLetniPrometOsnova.Height := 22;
    izLetniPrometOsnova.TabStop := False;
    izLetniPrometOsnova.Alignment := taRightJustify;
    izLetniPrometOsnova.Anchors := [];
    izLetniPrometOsnova.AutoSelect := False;
    izLetniPrometOsnova.ChildOrder := 1;
    izLetniPrometOsnova.HeightPercent := 100.000000000000000000;
    izLetniPrometOsnova.ReadOnly := True;
    izLetniPrometOsnova.ShowFocus := False;
    izLetniPrometOsnova.Text := '0.00';
    izLetniPrometOsnova.WidthPercent := 100.000000000000000000;
    PodzavarovanjePrazno1.SetParentComponent(ploscicaPodzavarovanje);
    PodzavarovanjePrazno1.Name := 'PodzavarovanjePrazno1';
    PodzavarovanjePrazno1.Left := 340;
    PodzavarovanjePrazno1.Top := 0;
    PodzavarovanjePrazno1.Width := 40;
    PodzavarovanjePrazno1.Height := 40;
    PodzavarovanjePrazno1.HeightPercent := 100.000000000000000000;
    PodzavarovanjePrazno1.WidthPercent := 100.000000000000000000;
    PodzavarovanjePrazno1.Anchors := [];
    PodzavarovanjePrazno1.BorderColor := clSilver;
    PodzavarovanjePrazno1.BorderStyle := bsNone;
    PodzavarovanjePrazno1.ChildOrder := 2;
    PodzavarovanjePrazno1.Color := clWindow;
    izLetniPrometRast.SetParentComponent(ploscicaPodzavarovanje);
    izLetniPrometRast.Name := 'izLetniPrometRast';
    izLetniPrometRast.Left := 163;
    izLetniPrometRast.Top := 49;
    izLetniPrometRast.Width := 175;
    izLetniPrometRast.Height := 22;
    izLetniPrometRast.TabStop := False;
    izLetniPrometRast.Alignment := taRightJustify;
    izLetniPrometRast.Anchors := [];
    izLetniPrometRast.AutoSelect := False;
    izLetniPrometRast.ChildOrder := 4;
    izLetniPrometRast.HeightPercent := 100.000000000000000000;
    izLetniPrometRast.ReadOnly := True;
    izLetniPrometRast.ShowFocus := False;
    izLetniPrometRast.Text := '0.00';
    izLetniPrometRast.WidthPercent := 100.000000000000000000;
    PodzavarovanjePrazno2.SetParentComponent(ploscicaPodzavarovanje);
    PodzavarovanjePrazno2.Name := 'PodzavarovanjePrazno2';
    PodzavarovanjePrazno2.Left := 340;
    PodzavarovanjePrazno2.Top := 40;
    PodzavarovanjePrazno2.Width := 40;
    PodzavarovanjePrazno2.Height := 40;
    PodzavarovanjePrazno2.HeightPercent := 100.000000000000000000;
    PodzavarovanjePrazno2.WidthPercent := 100.000000000000000000;
    PodzavarovanjePrazno2.Anchors := [];
    PodzavarovanjePrazno2.BorderColor := clSilver;
    PodzavarovanjePrazno2.BorderStyle := bsNone;
    PodzavarovanjePrazno2.ChildOrder := 5;
    PodzavarovanjePrazno2.Color := clWindow;
    izLetniPromet.SetParentComponent(ploscicaPodzavarovanje);
    izLetniPromet.Name := 'izLetniPromet';
    izLetniPromet.Left := 163;
    izLetniPromet.Top := 89;
    izLetniPromet.Width := 175;
    izLetniPromet.Height := 22;
    izLetniPromet.TabStop := False;
    izLetniPromet.Alignment := taRightJustify;
    izLetniPromet.Anchors := [];
    izLetniPromet.AutoSelect := False;
    izLetniPromet.ChildOrder := 7;
    izLetniPromet.HeightPercent := 100.000000000000000000;
    izLetniPromet.ReadOnly := True;
    izLetniPromet.ShowFocus := False;
    izLetniPromet.Text := '0.00';
    izLetniPromet.WidthPercent := 100.000000000000000000;
    PodzavarovanjePrazno3.SetParentComponent(ploscicaPodzavarovanje);
    PodzavarovanjePrazno3.Name := 'PodzavarovanjePrazno3';
    PodzavarovanjePrazno3.Left := 340;
    PodzavarovanjePrazno3.Top := 80;
    PodzavarovanjePrazno3.Width := 40;
    PodzavarovanjePrazno3.Height := 40;
    PodzavarovanjePrazno3.HeightPercent := 100.000000000000000000;
    PodzavarovanjePrazno3.WidthPercent := 100.000000000000000000;
    PodzavarovanjePrazno3.Anchors := [];
    PodzavarovanjePrazno3.BorderColor := clSilver;
    PodzavarovanjePrazno3.BorderStyle := bsNone;
    PodzavarovanjePrazno3.ChildOrder := 8;
    PodzavarovanjePrazno3.Color := clWindow;
    izPotrebnaZV.SetParentComponent(ploscicaPodzavarovanje);
    izPotrebnaZV.Name := 'izPotrebnaZV';
    izPotrebnaZV.Left := 163;
    izPotrebnaZV.Top := 129;
    izPotrebnaZV.Width := 175;
    izPotrebnaZV.Height := 22;
    izPotrebnaZV.TabStop := False;
    izPotrebnaZV.Alignment := taRightJustify;
    izPotrebnaZV.Anchors := [];
    izPotrebnaZV.AutoSelect := False;
    izPotrebnaZV.ChildOrder := 10;
    izPotrebnaZV.HeightPercent := 100.000000000000000000;
    izPotrebnaZV.ReadOnly := True;
    izPotrebnaZV.ShowFocus := False;
    izPotrebnaZV.Text := '0.00';
    izPotrebnaZV.WidthPercent := 100.000000000000000000;
    PodzavarovanjePrazno4.SetParentComponent(ploscicaPodzavarovanje);
    PodzavarovanjePrazno4.Name := 'PodzavarovanjePrazno4';
    PodzavarovanjePrazno4.Left := 340;
    PodzavarovanjePrazno4.Top := 120;
    PodzavarovanjePrazno4.Width := 40;
    PodzavarovanjePrazno4.Height := 40;
    PodzavarovanjePrazno4.HeightPercent := 100.000000000000000000;
    PodzavarovanjePrazno4.WidthPercent := 100.000000000000000000;
    PodzavarovanjePrazno4.Anchors := [];
    PodzavarovanjePrazno4.BorderColor := clSilver;
    PodzavarovanjePrazno4.BorderStyle := bsNone;
    PodzavarovanjePrazno4.ChildOrder := 11;
    PodzavarovanjePrazno4.Color := clWindow;
    izDejanskaZV.SetParentComponent(ploscicaPodzavarovanje);
    izDejanskaZV.Name := 'izDejanskaZV';
    izDejanskaZV.Left := 163;
    izDejanskaZV.Top := 169;
    izDejanskaZV.Width := 175;
    izDejanskaZV.Height := 22;
    izDejanskaZV.TabStop := False;
    izDejanskaZV.Alignment := taRightJustify;
    izDejanskaZV.Anchors := [];
    izDejanskaZV.AutoSelect := False;
    izDejanskaZV.ChildOrder := 13;
    izDejanskaZV.HeightPercent := 100.000000000000000000;
    izDejanskaZV.ReadOnly := True;
    izDejanskaZV.ShowFocus := False;
    izDejanskaZV.Text := '0.00';
    izDejanskaZV.WidthPercent := 100.000000000000000000;
    PodzavarovanjePrazno5.SetParentComponent(ploscicaPodzavarovanje);
    PodzavarovanjePrazno5.Name := 'PodzavarovanjePrazno5';
    PodzavarovanjePrazno5.Left := 340;
    PodzavarovanjePrazno5.Top := 160;
    PodzavarovanjePrazno5.Width := 40;
    PodzavarovanjePrazno5.Height := 40;
    PodzavarovanjePrazno5.HeightPercent := 100.000000000000000000;
    PodzavarovanjePrazno5.WidthPercent := 100.000000000000000000;
    PodzavarovanjePrazno5.Anchors := [];
    PodzavarovanjePrazno5.BorderColor := clSilver;
    PodzavarovanjePrazno5.BorderStyle := bsNone;
    PodzavarovanjePrazno5.ChildOrder := 14;
    PodzavarovanjePrazno5.Color := clWindow;
    slikaZV.SetParentComponent(PodzavarovanjePrazno5);
    slikaZV.Name := 'slikaZV';
    slikaZV.Left := 12;
    slikaZV.Top := 12;
    slikaZV.Width := 16;
    slikaZV.Height := 16;
    slikaZV.HeightPercent := 100.000000000000000000;
    slikaZV.WidthPercent := 100.000000000000000000;
    izPodzavarovanje.SetParentComponent(ploscicaPodzavarovanje);
    izPodzavarovanje.Name := 'izPodzavarovanje';
    izPodzavarovanje.Left := 163;
    izPodzavarovanje.Top := 209;
    izPodzavarovanje.Width := 175;
    izPodzavarovanje.Height := 22;
    izPodzavarovanje.TabStop := False;
    izPodzavarovanje.Alignment := taRightJustify;
    izPodzavarovanje.Anchors := [];
    izPodzavarovanje.AutoSelect := False;
    izPodzavarovanje.ChildOrder := 16;
    izPodzavarovanje.Font.Charset := DEFAULT_CHARSET;
    izPodzavarovanje.Font.Color := clWindowText;
    izPodzavarovanje.Font.Height := -11;
    izPodzavarovanje.Font.Name := 'Arial';
    izPodzavarovanje.Font.Style := [fsBold];
    izPodzavarovanje.HeightPercent := 100.000000000000000000;
    izPodzavarovanje.ParentFont := False;
    izPodzavarovanje.ReadOnly := True;
    izPodzavarovanje.ShowFocus := False;
    izPodzavarovanje.Text := 'DA, v vi'#353'ini xx,xx %';
    izPodzavarovanje.WidthPercent := 100.000000000000000000;
    PodzavarovanjePrazno6.SetParentComponent(ploscicaPodzavarovanje);
    PodzavarovanjePrazno6.Name := 'PodzavarovanjePrazno6';
    PodzavarovanjePrazno6.Left := 340;
    PodzavarovanjePrazno6.Top := 200;
    PodzavarovanjePrazno6.Width := 40;
    PodzavarovanjePrazno6.Height := 40;
    PodzavarovanjePrazno6.HeightPercent := 100.000000000000000000;
    PodzavarovanjePrazno6.WidthPercent := 100.000000000000000000;
    PodzavarovanjePrazno6.Anchors := [];
    PodzavarovanjePrazno6.BorderColor := clSilver;
    PodzavarovanjePrazno6.BorderStyle := bsNone;
    PodzavarovanjePrazno6.ChildOrder := 17;
    PodzavarovanjePrazno6.Color := clWindow;
    SimulacijaSkode_List5.SetParentComponent(SimulacijaSkode_Listi);
    SimulacijaSkode_List5.Name := 'SimulacijaSkode_List5';
    SimulacijaSkode_List5.Left := 0;
    SimulacijaSkode_List5.Top := 20;
    SimulacijaSkode_List5.Width := 840;
    SimulacijaSkode_List5.Height := 580;
    SimulacijaSkode_List5.HeightPercent := 100.000000000000000000;
    SimulacijaSkode_List5.WidthPercent := 100.000000000000000000;
    SimulacijaSkode_List5.Caption := 'WebPageControl1Sheet2';
    SimulacijaSkode_List5.ChildOrder := 7;
    SimulacijaSkode_List5.ShowCaption := False;
    SimulacijaSkode_List5.TabVisible := False;
    izNaslovList5.SetParentComponent(SimulacijaSkode_List5);
    izNaslovList5.Name := 'izNaslovList5';
    izNaslovList5.Left := 30;
    izNaslovList5.Top := 25;
    izNaslovList5.Width := 342;
    izNaslovList5.Height := 14;
    izNaslovList5.Caption := '5. korak: Izra'#269'un zavarovalnine (brez upo'#353'tevanja soudele'#382'be)';
    izNaslovList5.Color := clNone;
    izNaslovList5.Font.Charset := DEFAULT_CHARSET;
    izNaslovList5.Font.Color := clWindowText;
    izNaslovList5.Font.Height := -11;
    izNaslovList5.Font.Name := 'Arial';
    izNaslovList5.Font.Style := [fsBold];
    izNaslovList5.HeightPercent := 100.000000000000000000;
    izNaslovList5.ParentFont := False;
    izNaslovList5.WidthPercent := 100.000000000000000000;
    ploscicaIkoneList5.SetParentComponent(SimulacijaSkode_List5);
    ploscicaIkoneList5.Name := 'ploscicaIkoneList5';
    ploscicaIkoneList5.Left := 0;
    ploscicaIkoneList5.Top := 540;
    ploscicaIkoneList5.Width := 840;
    ploscicaIkoneList5.Height := 40;
    ploscicaIkoneList5.HeightPercent := 100.000000000000000000;
    ploscicaIkoneList5.WidthPercent := 100.000000000000000000;
    ploscicaIkoneList5.Align := alBottom;
    ploscicaIkoneList5.BorderColor := clNone;
    ploscicaIkoneList5.BorderStyle := bsNone;
    ploscicaIkoneList5.ChildOrder := 1;
    ploscicaIkoneList5.Color := clWindow;
    ploscicaGNazaj5.SetParentComponent(ploscicaIkoneList5);
    ploscicaGNazaj5.Name := 'ploscicaGNazaj5';
    ploscicaGNazaj5.Left := 0;
    ploscicaGNazaj5.Top := 0;
    ploscicaGNazaj5.Width := 50;
    ploscicaGNazaj5.Height := 40;
    ploscicaGNazaj5.HeightPercent := 100.000000000000000000;
    ploscicaGNazaj5.WidthPercent := 100.000000000000000000;
    ploscicaGNazaj5.Align := alLeft;
    ploscicaGNazaj5.BorderColor := clNone;
    ploscicaGNazaj5.BorderStyle := bsNone;
    ploscicaGNazaj5.Color := clWindow;
    gNazaj5.SetParentComponent(ploscicaGNazaj5);
    gNazaj5.Name := 'gNazaj5';
    gNazaj5.Left := 15;
    gNazaj5.Top := 0;
    gNazaj5.Width := 35;
    gNazaj5.Height := 40;
    gNazaj5.Color := clNone;
    gNazaj5.Flat := True;
    gNazaj5.Glyph.LoadFromFile('MBI_u04.ploscicaGNazaj5.gNazaj5.Glyph.png');
    gNazaj5.HeightPercent := 100.000000000000000000;
    gNazaj5.WidthPercent := 100.000000000000000000;
    SetEvent(gNazaj5, Self, 'OnClick', 'gNaprejNazajClick');
    ploscicaTabelaIzracunZavarovalnine.SetParentComponent(SimulacijaSkode_List5);
    ploscicaTabelaIzracunZavarovalnine.Name := 'ploscicaTabelaIzracunZavarovalnine';
    ploscicaTabelaIzracunZavarovalnine.Left := 30;
    ploscicaTabelaIzracunZavarovalnine.Top := 100;
    ploscicaTabelaIzracunZavarovalnine.Width := 450;
    ploscicaTabelaIzracunZavarovalnine.Height := 360;
    ploscicaTabelaIzracunZavarovalnine.HeightPercent := 100.000000000000000000;
    ploscicaTabelaIzracunZavarovalnine.WidthPercent := 100.000000000000000000;
    ploscicaTabelaIzracunZavarovalnine.ChildOrder := 2;
    ploscicaTabelaIzracunZavarovalnine.ColumnCollection.Clear;
    with ploscicaTabelaIzracunZavarovalnine.ColumnCollection.Add do
    begin
      MarginLeft := 5;
      SizeStyle := ssAbsolute;
      Value := 290;
    end;
    with ploscicaTabelaIzracunZavarovalnine.ColumnCollection.Add do
    begin
      Alignment := taRightJustify;
      SizeStyle := ssAbsolute;
      Value := 120;
    end;
    with ploscicaTabelaIzracunZavarovalnine.ColumnCollection.Add do
    begin
      Alignment := taRightJustify;
      SizeStyle := ssAbsolute;
      Value := 40;
    end;
    ploscicaTabelaIzracunZavarovalnine.ControlCollection.Clear;
    with ploscicaTabelaIzracunZavarovalnine.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izRezultatIzpadPrometaTekst;
    end;
    with ploscicaTabelaIzracunZavarovalnine.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izRezultatIzpadPrometa;
    end;
    with ploscicaTabelaIzracunZavarovalnine.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaZavarovalninaPrazna_1;
    end;
    with ploscicaTabelaIzracunZavarovalnine.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izRezultatPovisaniStroskiTekst;
    end;
    with ploscicaTabelaIzracunZavarovalnine.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izRezultatPovisaniStroski;
    end;
    with ploscicaTabelaIzracunZavarovalnine.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaZavarovalninaPrazna_2;
    end;
    with ploscicaTabelaIzracunZavarovalnine.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaZavarovalninaPrazna_3a;
    end;
    with ploscicaTabelaIzracunZavarovalnine.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izRezultatIzpadInStroski;
    end;
    with ploscicaTabelaIzracunZavarovalnine.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaZavarovalninaPrazna_3b;
    end;
    with ploscicaTabelaIzracunZavarovalnine.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izRezultatPrihrankiTekst;
    end;
    with ploscicaTabelaIzracunZavarovalnine.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izRezultatPrihranki;
    end;
    with ploscicaTabelaIzracunZavarovalnine.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaZavarovalninaPrazna_4;
    end;
    with ploscicaTabelaIzracunZavarovalnine.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaZavarovalninaPrazna_5a;
    end;
    with ploscicaTabelaIzracunZavarovalnine.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izRezultatZavarovanaSkoda;
    end;
    with ploscicaTabelaIzracunZavarovalnine.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaZavarovalninaPrazna_5b;
    end;
    with ploscicaTabelaIzracunZavarovalnine.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izRezultatPodzavarovanjeTekst;
    end;
    with ploscicaTabelaIzracunZavarovalnine.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izRezultatPodzavarovanje;
    end;
    with ploscicaTabelaIzracunZavarovalnine.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaZavarovalninaPrazna_6;
    end;
    with ploscicaTabelaIzracunZavarovalnine.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izRezultatZavarovalninaTekst;
    end;
    with ploscicaTabelaIzracunZavarovalnine.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izRezultatZavarovalnina;
    end;
    with ploscicaTabelaIzracunZavarovalnine.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaZavarovalninaPrazna_7;
    end;
    with ploscicaTabelaIzracunZavarovalnine.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izRezultatDejanskoOcenjenaSkodaTekst;
    end;
    with ploscicaTabelaIzracunZavarovalnine.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izRezultatDejanskoOcenjenaSkoda;
    end;
    with ploscicaTabelaIzracunZavarovalnine.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaZavarovalninaPrazna_8;
    end;
    ploscicaTabelaIzracunZavarovalnine.Color := 15724527;
    ploscicaTabelaIzracunZavarovalnine.GridLineColor := clBlack;
    ploscicaTabelaIzracunZavarovalnine.RowCollection.Clear;
    with ploscicaTabelaIzracunZavarovalnine.RowCollection.Add do
    begin
      Alignment := vaCenter;
      SizeStyle := ssAbsolute;
      Value := 40;
    end;
    with ploscicaTabelaIzracunZavarovalnine.RowCollection.Add do
    begin
      Alignment := vaCenter;
      SizeStyle := ssAbsolute;
      Value := 40;
    end;
    with ploscicaTabelaIzracunZavarovalnine.RowCollection.Add do
    begin
      Alignment := vaCenter;
      SizeStyle := ssAbsolute;
      Value := 40;
    end;
    with ploscicaTabelaIzracunZavarovalnine.RowCollection.Add do
    begin
      Alignment := vaCenter;
      SizeStyle := ssAbsolute;
      Value := 40;
    end;
    with ploscicaTabelaIzracunZavarovalnine.RowCollection.Add do
    begin
      Alignment := vaCenter;
      SizeStyle := ssAbsolute;
      Value := 40;
    end;
    with ploscicaTabelaIzracunZavarovalnine.RowCollection.Add do
    begin
      Alignment := vaCenter;
      SizeStyle := ssAbsolute;
      Value := 40;
    end;
    with ploscicaTabelaIzracunZavarovalnine.RowCollection.Add do
    begin
      Alignment := vaCenter;
      SizeStyle := ssAbsolute;
      Value := 40;
    end;
    with ploscicaTabelaIzracunZavarovalnine.RowCollection.Add do
    begin
      Alignment := vaCenter;
      SizeStyle := ssAbsolute;
      Value := 40;
    end;
    izRezultatIzpadPrometaTekst.SetParentComponent(ploscicaTabelaIzracunZavarovalnine);
    izRezultatIzpadPrometaTekst.Name := 'izRezultatIzpadPrometaTekst';
    izRezultatIzpadPrometaTekst.Left := 7;
    izRezultatIzpadPrometaTekst.Top := 13;
    izRezultatIzpadPrometaTekst.Width := 233;
    izRezultatIzpadPrometaTekst.Height := 14;
    izRezultatIzpadPrometaTekst.Alignment := taRightJustify;
    izRezultatIzpadPrometaTekst.Anchors := [];
    izRezultatIzpadPrometaTekst.Caption := '(1) Zavarovani nepokriti stro'#353'ki in izpad dobi'#269'ka:';
    izRezultatIzpadPrometaTekst.Color := clNone;
    izRezultatIzpadPrometaTekst.HeightPercent := 100.000000000000000000;
    izRezultatIzpadPrometaTekst.WidthPercent := 100.000000000000000000;
    izRezultatPovisaniStroskiTekst.SetParentComponent(ploscicaTabelaIzracunZavarovalnine);
    izRezultatPovisaniStroskiTekst.Name := 'izRezultatPovisaniStroskiTekst';
    izRezultatPovisaniStroskiTekst.Left := 7;
    izRezultatPovisaniStroskiTekst.Top := 53;
    izRezultatPovisaniStroskiTekst.Width := 263;
    izRezultatPovisaniStroskiTekst.Height := 14;
    izRezultatPovisaniStroskiTekst.Alignment := taRightJustify;
    izRezultatPovisaniStroskiTekst.Anchors := [];
    izRezultatPovisaniStroskiTekst.Caption := '(2) Zavarovani ekonomsko upravi'#269'eni poslovni stro'#353'ki:';
    izRezultatPovisaniStroskiTekst.Color := clNone;
    izRezultatPovisaniStroskiTekst.HeightPercent := 100.000000000000000000;
    izRezultatPovisaniStroskiTekst.WidthPercent := 100.000000000000000000;
    izRezultatPrihrankiTekst.SetParentComponent(ploscicaTabelaIzracunZavarovalnine);
    izRezultatPrihrankiTekst.Name := 'izRezultatPrihrankiTekst';
    izRezultatPrihrankiTekst.Left := 7;
    izRezultatPrihrankiTekst.Top := 133;
    izRezultatPrihrankiTekst.Width := 167;
    izRezultatPrihrankiTekst.Height := 14;
    izRezultatPrihrankiTekst.Alignment := taRightJustify;
    izRezultatPrihrankiTekst.Anchors := [];
    izRezultatPrihrankiTekst.Caption := '(3) Prihranki (privar'#269'evani stro'#353'ki):';
    izRezultatPrihrankiTekst.Color := clNone;
    izRezultatPrihrankiTekst.HeightPercent := 100.000000000000000000;
    izRezultatPrihrankiTekst.WidthPercent := 100.000000000000000000;
    izRezultatPodzavarovanjeTekst.SetParentComponent(ploscicaTabelaIzracunZavarovalnine);
    izRezultatPodzavarovanjeTekst.Name := 'izRezultatPodzavarovanjeTekst';
    izRezultatPodzavarovanjeTekst.Left := 7;
    izRezultatPodzavarovanjeTekst.Top := 213;
    izRezultatPodzavarovanjeTekst.Width := 98;
    izRezultatPodzavarovanjeTekst.Height := 14;
    izRezultatPodzavarovanjeTekst.Alignment := taRightJustify;
    izRezultatPodzavarovanjeTekst.Anchors := [];
    izRezultatPodzavarovanjeTekst.Caption := '(4) Podzavarovanje:';
    izRezultatPodzavarovanjeTekst.Color := clNone;
    izRezultatPodzavarovanjeTekst.HeightPercent := 100.000000000000000000;
    izRezultatPodzavarovanjeTekst.WidthPercent := 100.000000000000000000;
    izRezultatZavarovalninaTekst.SetParentComponent(ploscicaTabelaIzracunZavarovalnine);
    izRezultatZavarovalninaTekst.Name := 'izRezultatZavarovalninaTekst';
    izRezultatZavarovalninaTekst.Left := 7;
    izRezultatZavarovalninaTekst.Top := 253;
    izRezultatZavarovalninaTekst.Width := 111;
    izRezultatZavarovalninaTekst.Height := 14;
    izRezultatZavarovalninaTekst.Alignment := taRightJustify;
    izRezultatZavarovalninaTekst.Anchors := [];
    izRezultatZavarovalninaTekst.Caption := '(5) ZAVAROVALNINA:';
    izRezultatZavarovalninaTekst.Color := clNone;
    izRezultatZavarovalninaTekst.Font.Charset := DEFAULT_CHARSET;
    izRezultatZavarovalninaTekst.Font.Color := clWindowText;
    izRezultatZavarovalninaTekst.Font.Height := -11;
    izRezultatZavarovalninaTekst.Font.Name := 'Arial';
    izRezultatZavarovalninaTekst.Font.Style := [fsBold];
    izRezultatZavarovalninaTekst.HeightPercent := 100.000000000000000000;
    izRezultatZavarovalninaTekst.ParentFont := False;
    izRezultatZavarovalninaTekst.WidthPercent := 100.000000000000000000;
    izRezultatDejanskoOcenjenaSkodaTekst.SetParentComponent(ploscicaTabelaIzracunZavarovalnine);
    izRezultatDejanskoOcenjenaSkodaTekst.Name := 'izRezultatDejanskoOcenjenaSkodaTekst';
    izRezultatDejanskoOcenjenaSkodaTekst.Left := 7;
    izRezultatDejanskoOcenjenaSkodaTekst.Top := 293;
    izRezultatDejanskoOcenjenaSkodaTekst.Width := 126;
    izRezultatDejanskoOcenjenaSkodaTekst.Height := 14;
    izRezultatDejanskoOcenjenaSkodaTekst.Alignment := taRightJustify;
    izRezultatDejanskoOcenjenaSkodaTekst.Anchors := [];
    izRezultatDejanskoOcenjenaSkodaTekst.Caption := 'Dejanska ocenjena '#353'koda:';
    izRezultatDejanskoOcenjenaSkodaTekst.Color := clNone;
    izRezultatDejanskoOcenjenaSkodaTekst.HeightPercent := 100.000000000000000000;
    izRezultatDejanskoOcenjenaSkodaTekst.WidthPercent := 100.000000000000000000;
    izRezultatIzpadPrometa.SetParentComponent(ploscicaTabelaIzracunZavarovalnine);
    izRezultatIzpadPrometa.Name := 'izRezultatIzpadPrometa';
    izRezultatIzpadPrometa.Left := 288;
    izRezultatIzpadPrometa.Top := 9;
    izRezultatIzpadPrometa.Width := 120;
    izRezultatIzpadPrometa.Height := 22;
    izRezultatIzpadPrometa.TabStop := False;
    izRezultatIzpadPrometa.Alignment := taRightJustify;
    izRezultatIzpadPrometa.Anchors := [];
    izRezultatIzpadPrometa.AutoSelect := False;
    izRezultatIzpadPrometa.BorderStyle := bsNone;
    izRezultatIzpadPrometa.ChildOrder := 1;
    izRezultatIzpadPrometa.HeightPercent := 100.000000000000000000;
    izRezultatIzpadPrometa.ReadOnly := True;
    izRezultatIzpadPrometa.ShowFocus := False;
    izRezultatIzpadPrometa.Text := '0.00';
    izRezultatIzpadPrometa.WidthPercent := 100.000000000000000000;
    ploscicaZavarovalninaPrazna_1.SetParentComponent(ploscicaTabelaIzracunZavarovalnine);
    ploscicaZavarovalninaPrazna_1.Name := 'ploscicaZavarovalninaPrazna_1';
    ploscicaZavarovalninaPrazna_1.Left := 408;
    ploscicaZavarovalninaPrazna_1.Top := 0;
    ploscicaZavarovalninaPrazna_1.Width := 40;
    ploscicaZavarovalninaPrazna_1.Height := 40;
    ploscicaZavarovalninaPrazna_1.HeightPercent := 100.000000000000000000;
    ploscicaZavarovalninaPrazna_1.WidthPercent := 100.000000000000000000;
    ploscicaZavarovalninaPrazna_1.Anchors := [];
    ploscicaZavarovalninaPrazna_1.BorderColor := clSilver;
    ploscicaZavarovalninaPrazna_1.BorderStyle := bsNone;
    ploscicaZavarovalninaPrazna_1.ChildOrder := 2;
    ploscicaZavarovalninaPrazna_1.Color := clWindow;
    slikaZavarovalnina_1.SetParentComponent(ploscicaZavarovalninaPrazna_1);
    slikaZavarovalnina_1.Name := 'slikaZavarovalnina_1';
    slikaZavarovalnina_1.Left := 12;
    slikaZavarovalnina_1.Top := 12;
    slikaZavarovalnina_1.Width := 16;
    slikaZavarovalnina_1.Height := 16;
    slikaZavarovalnina_1.HeightPercent := 100.000000000000000000;
    slikaZavarovalnina_1.WidthPercent := 100.000000000000000000;
    izRezultatPovisaniStroski.SetParentComponent(ploscicaTabelaIzracunZavarovalnine);
    izRezultatPovisaniStroski.Name := 'izRezultatPovisaniStroski';
    izRezultatPovisaniStroski.Left := 288;
    izRezultatPovisaniStroski.Top := 49;
    izRezultatPovisaniStroski.Width := 120;
    izRezultatPovisaniStroski.Height := 22;
    izRezultatPovisaniStroski.TabStop := False;
    izRezultatPovisaniStroski.Alignment := taRightJustify;
    izRezultatPovisaniStroski.Anchors := [];
    izRezultatPovisaniStroski.AutoSelect := False;
    izRezultatPovisaniStroski.BorderStyle := bsNone;
    izRezultatPovisaniStroski.ChildOrder := 4;
    izRezultatPovisaniStroski.HeightPercent := 100.000000000000000000;
    izRezultatPovisaniStroski.ReadOnly := True;
    izRezultatPovisaniStroski.ShowFocus := False;
    izRezultatPovisaniStroski.Text := '0.00';
    izRezultatPovisaniStroski.WidthPercent := 100.000000000000000000;
    ploscicaZavarovalninaPrazna_2.SetParentComponent(ploscicaTabelaIzracunZavarovalnine);
    ploscicaZavarovalninaPrazna_2.Name := 'ploscicaZavarovalninaPrazna_2';
    ploscicaZavarovalninaPrazna_2.Left := 408;
    ploscicaZavarovalninaPrazna_2.Top := 40;
    ploscicaZavarovalninaPrazna_2.Width := 40;
    ploscicaZavarovalninaPrazna_2.Height := 40;
    ploscicaZavarovalninaPrazna_2.HeightPercent := 100.000000000000000000;
    ploscicaZavarovalninaPrazna_2.WidthPercent := 100.000000000000000000;
    ploscicaZavarovalninaPrazna_2.Anchors := [];
    ploscicaZavarovalninaPrazna_2.BorderColor := clSilver;
    ploscicaZavarovalninaPrazna_2.BorderStyle := bsNone;
    ploscicaZavarovalninaPrazna_2.ChildOrder := 5;
    ploscicaZavarovalninaPrazna_2.Color := clWindow;
    ploscicaZavarovalninaPrazna_3a.SetParentComponent(ploscicaTabelaIzracunZavarovalnine);
    ploscicaZavarovalninaPrazna_3a.Name := 'ploscicaZavarovalninaPrazna_3a';
    ploscicaZavarovalninaPrazna_3a.Left := 7;
    ploscicaZavarovalninaPrazna_3a.Top := 80;
    ploscicaZavarovalninaPrazna_3a.Width := 40;
    ploscicaZavarovalninaPrazna_3a.Height := 40;
    ploscicaZavarovalninaPrazna_3a.HeightPercent := 100.000000000000000000;
    ploscicaZavarovalninaPrazna_3a.WidthPercent := 100.000000000000000000;
    ploscicaZavarovalninaPrazna_3a.Anchors := [];
    ploscicaZavarovalninaPrazna_3a.BorderColor := clSilver;
    ploscicaZavarovalninaPrazna_3a.BorderStyle := bsNone;
    ploscicaZavarovalninaPrazna_3a.ChildOrder := 6;
    ploscicaZavarovalninaPrazna_3a.Color := clWindow;
    izRezultatIzpadInStroski.SetParentComponent(ploscicaTabelaIzracunZavarovalnine);
    izRezultatIzpadInStroski.Name := 'izRezultatIzpadInStroski';
    izRezultatIzpadInStroski.Left := 288;
    izRezultatIzpadInStroski.Top := 89;
    izRezultatIzpadInStroski.Width := 120;
    izRezultatIzpadInStroski.Height := 22;
    izRezultatIzpadInStroski.TabStop := False;
    izRezultatIzpadInStroski.Alignment := taRightJustify;
    izRezultatIzpadInStroski.Anchors := [];
    izRezultatIzpadInStroski.AutoSelect := False;
    izRezultatIzpadInStroski.ChildOrder := 7;
    izRezultatIzpadInStroski.Color := clNone;
    izRezultatIzpadInStroski.HeightPercent := 100.000000000000000000;
    izRezultatIzpadInStroski.ReadOnly := True;
    izRezultatIzpadInStroski.ShowFocus := False;
    izRezultatIzpadInStroski.Text := '0.00';
    izRezultatIzpadInStroski.WidthPercent := 100.000000000000000000;
    ploscicaZavarovalninaPrazna_3b.SetParentComponent(ploscicaTabelaIzracunZavarovalnine);
    ploscicaZavarovalninaPrazna_3b.Name := 'ploscicaZavarovalninaPrazna_3b';
    ploscicaZavarovalninaPrazna_3b.Left := 408;
    ploscicaZavarovalninaPrazna_3b.Top := 80;
    ploscicaZavarovalninaPrazna_3b.Width := 40;
    ploscicaZavarovalninaPrazna_3b.Height := 40;
    ploscicaZavarovalninaPrazna_3b.HeightPercent := 100.000000000000000000;
    ploscicaZavarovalninaPrazna_3b.WidthPercent := 100.000000000000000000;
    ploscicaZavarovalninaPrazna_3b.Anchors := [];
    ploscicaZavarovalninaPrazna_3b.BorderColor := clSilver;
    ploscicaZavarovalninaPrazna_3b.BorderStyle := bsNone;
    ploscicaZavarovalninaPrazna_3b.ChildOrder := 8;
    ploscicaZavarovalninaPrazna_3b.Color := clWindow;
    izRezultatPrihranki.SetParentComponent(ploscicaTabelaIzracunZavarovalnine);
    izRezultatPrihranki.Name := 'izRezultatPrihranki';
    izRezultatPrihranki.Left := 288;
    izRezultatPrihranki.Top := 129;
    izRezultatPrihranki.Width := 120;
    izRezultatPrihranki.Height := 22;
    izRezultatPrihranki.TabStop := False;
    izRezultatPrihranki.Alignment := taRightJustify;
    izRezultatPrihranki.Anchors := [];
    izRezultatPrihranki.AutoSelect := False;
    izRezultatPrihranki.BorderStyle := bsNone;
    izRezultatPrihranki.ChildOrder := 10;
    izRezultatPrihranki.HeightPercent := 100.000000000000000000;
    izRezultatPrihranki.ReadOnly := True;
    izRezultatPrihranki.ShowFocus := False;
    izRezultatPrihranki.Text := '0.00';
    izRezultatPrihranki.WidthPercent := 100.000000000000000000;
    ploscicaZavarovalninaPrazna_4.SetParentComponent(ploscicaTabelaIzracunZavarovalnine);
    ploscicaZavarovalninaPrazna_4.Name := 'ploscicaZavarovalninaPrazna_4';
    ploscicaZavarovalninaPrazna_4.Left := 408;
    ploscicaZavarovalninaPrazna_4.Top := 120;
    ploscicaZavarovalninaPrazna_4.Width := 40;
    ploscicaZavarovalninaPrazna_4.Height := 40;
    ploscicaZavarovalninaPrazna_4.HeightPercent := 100.000000000000000000;
    ploscicaZavarovalninaPrazna_4.WidthPercent := 100.000000000000000000;
    ploscicaZavarovalninaPrazna_4.Anchors := [];
    ploscicaZavarovalninaPrazna_4.BorderColor := clSilver;
    ploscicaZavarovalninaPrazna_4.BorderStyle := bsNone;
    ploscicaZavarovalninaPrazna_4.ChildOrder := 11;
    ploscicaZavarovalninaPrazna_4.Color := clWindow;
    ploscicaZavarovalninaPrazna_5a.SetParentComponent(ploscicaTabelaIzracunZavarovalnine);
    ploscicaZavarovalninaPrazna_5a.Name := 'ploscicaZavarovalninaPrazna_5a';
    ploscicaZavarovalninaPrazna_5a.Left := 7;
    ploscicaZavarovalninaPrazna_5a.Top := 160;
    ploscicaZavarovalninaPrazna_5a.Width := 40;
    ploscicaZavarovalninaPrazna_5a.Height := 40;
    ploscicaZavarovalninaPrazna_5a.HeightPercent := 100.000000000000000000;
    ploscicaZavarovalninaPrazna_5a.WidthPercent := 100.000000000000000000;
    ploscicaZavarovalninaPrazna_5a.Anchors := [];
    ploscicaZavarovalninaPrazna_5a.BorderColor := clSilver;
    ploscicaZavarovalninaPrazna_5a.BorderStyle := bsNone;
    ploscicaZavarovalninaPrazna_5a.ChildOrder := 12;
    ploscicaZavarovalninaPrazna_5a.Color := clWindow;
    izRezultatZavarovanaSkoda.SetParentComponent(ploscicaTabelaIzracunZavarovalnine);
    izRezultatZavarovanaSkoda.Name := 'izRezultatZavarovanaSkoda';
    izRezultatZavarovanaSkoda.Left := 288;
    izRezultatZavarovanaSkoda.Top := 169;
    izRezultatZavarovanaSkoda.Width := 120;
    izRezultatZavarovanaSkoda.Height := 22;
    izRezultatZavarovanaSkoda.TabStop := False;
    izRezultatZavarovanaSkoda.Alignment := taRightJustify;
    izRezultatZavarovanaSkoda.Anchors := [];
    izRezultatZavarovanaSkoda.AutoSelect := False;
    izRezultatZavarovanaSkoda.ChildOrder := 13;
    izRezultatZavarovanaSkoda.HeightPercent := 100.000000000000000000;
    izRezultatZavarovanaSkoda.ReadOnly := True;
    izRezultatZavarovanaSkoda.ShowFocus := False;
    izRezultatZavarovanaSkoda.Text := '0.00';
    izRezultatZavarovanaSkoda.WidthPercent := 100.000000000000000000;
    ploscicaZavarovalninaPrazna_5b.SetParentComponent(ploscicaTabelaIzracunZavarovalnine);
    ploscicaZavarovalninaPrazna_5b.Name := 'ploscicaZavarovalninaPrazna_5b';
    ploscicaZavarovalninaPrazna_5b.Left := 408;
    ploscicaZavarovalninaPrazna_5b.Top := 160;
    ploscicaZavarovalninaPrazna_5b.Width := 40;
    ploscicaZavarovalninaPrazna_5b.Height := 40;
    ploscicaZavarovalninaPrazna_5b.HeightPercent := 100.000000000000000000;
    ploscicaZavarovalninaPrazna_5b.WidthPercent := 100.000000000000000000;
    ploscicaZavarovalninaPrazna_5b.Anchors := [];
    ploscicaZavarovalninaPrazna_5b.BorderColor := clSilver;
    ploscicaZavarovalninaPrazna_5b.BorderStyle := bsNone;
    ploscicaZavarovalninaPrazna_5b.ChildOrder := 14;
    ploscicaZavarovalninaPrazna_5b.Color := clWindow;
    izRezultatPodzavarovanje.SetParentComponent(ploscicaTabelaIzracunZavarovalnine);
    izRezultatPodzavarovanje.Name := 'izRezultatPodzavarovanje';
    izRezultatPodzavarovanje.Left := 288;
    izRezultatPodzavarovanje.Top := 209;
    izRezultatPodzavarovanje.Width := 120;
    izRezultatPodzavarovanje.Height := 22;
    izRezultatPodzavarovanje.TabStop := False;
    izRezultatPodzavarovanje.Alignment := taRightJustify;
    izRezultatPodzavarovanje.Anchors := [];
    izRezultatPodzavarovanje.AutoSelect := False;
    izRezultatPodzavarovanje.ChildOrder := 16;
    izRezultatPodzavarovanje.HeightPercent := 100.000000000000000000;
    izRezultatPodzavarovanje.ReadOnly := True;
    izRezultatPodzavarovanje.ShowFocus := False;
    izRezultatPodzavarovanje.Text := '0.00';
    izRezultatPodzavarovanje.WidthPercent := 100.000000000000000000;
    ploscicaZavarovalninaPrazna_6.SetParentComponent(ploscicaTabelaIzracunZavarovalnine);
    ploscicaZavarovalninaPrazna_6.Name := 'ploscicaZavarovalninaPrazna_6';
    ploscicaZavarovalninaPrazna_6.Left := 408;
    ploscicaZavarovalninaPrazna_6.Top := 200;
    ploscicaZavarovalninaPrazna_6.Width := 40;
    ploscicaZavarovalninaPrazna_6.Height := 40;
    ploscicaZavarovalninaPrazna_6.HeightPercent := 100.000000000000000000;
    ploscicaZavarovalninaPrazna_6.WidthPercent := 100.000000000000000000;
    ploscicaZavarovalninaPrazna_6.Anchors := [];
    ploscicaZavarovalninaPrazna_6.BorderColor := clSilver;
    ploscicaZavarovalninaPrazna_6.BorderStyle := bsNone;
    ploscicaZavarovalninaPrazna_6.ChildOrder := 17;
    ploscicaZavarovalninaPrazna_6.Color := clWindow;
    slikaZavarovalnina_2.SetParentComponent(ploscicaZavarovalninaPrazna_6);
    slikaZavarovalnina_2.Name := 'slikaZavarovalnina_2';
    slikaZavarovalnina_2.Left := 12;
    slikaZavarovalnina_2.Top := 12;
    slikaZavarovalnina_2.Width := 16;
    slikaZavarovalnina_2.Height := 16;
    slikaZavarovalnina_2.HeightPercent := 100.000000000000000000;
    slikaZavarovalnina_2.WidthPercent := 100.000000000000000000;
    izRezultatZavarovalnina.SetParentComponent(ploscicaTabelaIzracunZavarovalnine);
    izRezultatZavarovalnina.Name := 'izRezultatZavarovalnina';
    izRezultatZavarovalnina.Left := 288;
    izRezultatZavarovalnina.Top := 249;
    izRezultatZavarovalnina.Width := 120;
    izRezultatZavarovalnina.Height := 22;
    izRezultatZavarovalnina.TabStop := False;
    izRezultatZavarovalnina.Alignment := taRightJustify;
    izRezultatZavarovalnina.Anchors := [];
    izRezultatZavarovalnina.AutoSelect := False;
    izRezultatZavarovalnina.ChildOrder := 19;
    izRezultatZavarovalnina.Font.Charset := DEFAULT_CHARSET;
    izRezultatZavarovalnina.Font.Color := clWindowText;
    izRezultatZavarovalnina.Font.Height := -11;
    izRezultatZavarovalnina.Font.Name := 'Arial';
    izRezultatZavarovalnina.Font.Style := [fsBold];
    izRezultatZavarovalnina.HeightPercent := 100.000000000000000000;
    izRezultatZavarovalnina.ParentFont := False;
    izRezultatZavarovalnina.ReadOnly := True;
    izRezultatZavarovalnina.ShowFocus := False;
    izRezultatZavarovalnina.Text := '0.00';
    izRezultatZavarovalnina.WidthPercent := 100.000000000000000000;
    ploscicaZavarovalninaPrazna_7.SetParentComponent(ploscicaTabelaIzracunZavarovalnine);
    ploscicaZavarovalninaPrazna_7.Name := 'ploscicaZavarovalninaPrazna_7';
    ploscicaZavarovalninaPrazna_7.Left := 408;
    ploscicaZavarovalninaPrazna_7.Top := 240;
    ploscicaZavarovalninaPrazna_7.Width := 40;
    ploscicaZavarovalninaPrazna_7.Height := 40;
    ploscicaZavarovalninaPrazna_7.HeightPercent := 100.000000000000000000;
    ploscicaZavarovalninaPrazna_7.WidthPercent := 100.000000000000000000;
    ploscicaZavarovalninaPrazna_7.Anchors := [];
    ploscicaZavarovalninaPrazna_7.BorderColor := clSilver;
    ploscicaZavarovalninaPrazna_7.BorderStyle := bsNone;
    ploscicaZavarovalninaPrazna_7.ChildOrder := 20;
    ploscicaZavarovalninaPrazna_7.Color := clWindow;
    slikaZavarovalnina_3.SetParentComponent(ploscicaZavarovalninaPrazna_7);
    slikaZavarovalnina_3.Name := 'slikaZavarovalnina_3';
    slikaZavarovalnina_3.Left := 12;
    slikaZavarovalnina_3.Top := 12;
    slikaZavarovalnina_3.Width := 16;
    slikaZavarovalnina_3.Height := 16;
    slikaZavarovalnina_3.HeightPercent := 100.000000000000000000;
    slikaZavarovalnina_3.WidthPercent := 100.000000000000000000;
    izRezultatDejanskoOcenjenaSkoda.SetParentComponent(ploscicaTabelaIzracunZavarovalnine);
    izRezultatDejanskoOcenjenaSkoda.Name := 'izRezultatDejanskoOcenjenaSkoda';
    izRezultatDejanskoOcenjenaSkoda.Left := 288;
    izRezultatDejanskoOcenjenaSkoda.Top := 289;
    izRezultatDejanskoOcenjenaSkoda.Width := 120;
    izRezultatDejanskoOcenjenaSkoda.Height := 22;
    izRezultatDejanskoOcenjenaSkoda.TabStop := False;
    izRezultatDejanskoOcenjenaSkoda.Alignment := taRightJustify;
    izRezultatDejanskoOcenjenaSkoda.Anchors := [];
    izRezultatDejanskoOcenjenaSkoda.AutoSelect := False;
    izRezultatDejanskoOcenjenaSkoda.ChildOrder := 22;
    izRezultatDejanskoOcenjenaSkoda.HeightPercent := 100.000000000000000000;
    izRezultatDejanskoOcenjenaSkoda.ReadOnly := True;
    izRezultatDejanskoOcenjenaSkoda.ShowFocus := False;
    izRezultatDejanskoOcenjenaSkoda.Text := '0.00';
    izRezultatDejanskoOcenjenaSkoda.WidthPercent := 100.000000000000000000;
    ploscicaZavarovalninaPrazna_8.SetParentComponent(ploscicaTabelaIzracunZavarovalnine);
    ploscicaZavarovalninaPrazna_8.Name := 'ploscicaZavarovalninaPrazna_8';
    ploscicaZavarovalninaPrazna_8.Left := 408;
    ploscicaZavarovalninaPrazna_8.Top := 280;
    ploscicaZavarovalninaPrazna_8.Width := 40;
    ploscicaZavarovalninaPrazna_8.Height := 40;
    ploscicaZavarovalninaPrazna_8.HeightPercent := 100.000000000000000000;
    ploscicaZavarovalninaPrazna_8.WidthPercent := 100.000000000000000000;
    ploscicaZavarovalninaPrazna_8.Anchors := [];
    ploscicaZavarovalninaPrazna_8.BorderColor := clSilver;
    ploscicaZavarovalninaPrazna_8.BorderStyle := bsNone;
    ploscicaZavarovalninaPrazna_8.ChildOrder := 23;
    ploscicaZavarovalninaPrazna_8.Color := clWindow;
    ploscicaNaslov.SetParentComponent(Self);
    ploscicaNaslov.Name := 'ploscicaNaslov';
    ploscicaNaslov.Left := 0;
    ploscicaNaslov.Top := 0;
    ploscicaNaslov.Width := 850;
    ploscicaNaslov.Height := 30;
    ploscicaNaslov.HeightPercent := 100.000000000000000000;
    ploscicaNaslov.WidthPercent := 100.000000000000000000;
    ploscicaNaslov.Align := alTop;
    ploscicaNaslov.Alignment := taLeftJustify;
    ploscicaNaslov.BorderColor := clSilver;
    ploscicaNaslov.BorderStyle := bsSingle;
    ploscicaNaslov.ChildOrder := 3;
    ploscicaNaslov.Font.Charset := DEFAULT_CHARSET;
    ploscicaNaslov.Font.Color := clWindowText;
    ploscicaNaslov.Font.Height := -11;
    ploscicaNaslov.Font.Name := 'Tahoma';
    ploscicaNaslov.Font.Style := [fsBold];
    ploscicaNaslov.ParentFont := False;
    izNaslov.SetParentComponent(ploscicaNaslov);
    izNaslov.Name := 'izNaslov';
    izNaslov.AlignWithMargins := True;
    izNaslov.Left := 15;
    izNaslov.Top := 8;
    izNaslov.Width := 217;
    izNaslov.Height := 15;
    izNaslov.Margins.Left := 15;
    izNaslov.Margins.Top := 8;
    izNaslov.Margins.Bottom := 7;
    izNaslov.Align := alLeft;
    izNaslov.Caption := 'IZRA'#268'UN ZAVAROVALNINE PO KORAKIH';
    izNaslov.HeightPercent := 100.000000000000000000;
    izNaslov.WidthPercent := 100.000000000000000000;
    PovezavaWSIzracunSkode.SetParentComponent(Self);
    PovezavaWSIzracunSkode.Name := 'PovezavaWSIzracunSkode';
    PovezavaWSIzracunSkode.URL := 'http://ec2-35-158-89-121.eu-central-1.compute.amazonaws.com/tms/xdata';
    SetEvent(PovezavaWSIzracunSkode, Self, 'OnConnect', 'PovezavaWSIzracunSkodeConnect');
    SetEvent(PovezavaWSIzracunSkode, Self, 'OnError', 'PovezavaWSIzracunSkodeError');
    PovezavaWSIzracunSkode.Left := 640;
    PovezavaWSIzracunSkode.Top := 488;
    WebClientIzracunSkode.SetParentComponent(Self);
    WebClientIzracunSkode.Name := 'WebClientIzracunSkode';
    WebClientIzracunSkode.Connection := PovezavaWSIzracunSkode;
    WebClientIzracunSkode.Left := 541;
    WebClientIzracunSkode.Top := 485;
  finally
    ploscicaIkoneZgoraj.AfterLoadDFMValues;
    izKoraki.AfterLoadDFMValues;
    gIzhod.AfterLoadDFMValues;
    plosicaG5.AfterLoadDFMValues;
    g5.AfterLoadDFMValues;
    ploscicaG4.AfterLoadDFMValues;
    g4.AfterLoadDFMValues;
    ploscicaG3.AfterLoadDFMValues;
    g3.AfterLoadDFMValues;
    ploscicaG2.AfterLoadDFMValues;
    g2.AfterLoadDFMValues;
    ploscicaG1.AfterLoadDFMValues;
    g1.AfterLoadDFMValues;
    ploscicaIkoneSpodaj.AfterLoadDFMValues;
    ploscicaGSimulacije.AfterLoadDFMValues;
    gSimulacije.AfterLoadDFMValues;
    WebGridPanel1.AfterLoadDFMValues;
    izSimulacijaNogaTekstMDJ.AfterLoadDFMValues;
    izSimulacijaNogaTekstZV.AfterLoadDFMValues;
    izSimulacijaNogaTekstPrihranki.AfterLoadDFMValues;
    izSimulacijaNogaTekstStroski.AfterLoadDFMValues;
    izSimulacijaNogaTekstRast.AfterLoadDFMValues;
    izSimulacijaNogaTekstPremija.AfterLoadDFMValues;
    izSimulacijaNogaMDJ.AfterLoadDFMValues;
    izSimulacijaNogaZV.AfterLoadDFMValues;
    izSimulacijaNogaPrihranki.AfterLoadDFMValues;
    izSimulacijaNogaStroski.AfterLoadDFMValues;
    izSimulacijaNogaRast.AfterLoadDFMValues;
    izSimulacijaNogaPremija.AfterLoadDFMValues;
    SimulacijaSkode_Listi.AfterLoadDFMValues;
    SimulacijaSkode_List1.AfterLoadDFMValues;
    izNaslovList1.AfterLoadDFMValues;
    ploscicaIkoneList1.AfterLoadDFMValues;
    ploscicaGNaprej1.AfterLoadDFMValues;
    gNaprej1.AfterLoadDFMValues;
    ploscicaTabelaNepokritiStroski.AfterLoadDFMValues;
    ploscicaTabelaNepokritiStroski_Naslov1.AfterLoadDFMValues;
    ploscicaTabelaNepokritiStroski_Naslov2.AfterLoadDFMValues;
    ploscicaTabelaNepokritiStroski_Naslov3.AfterLoadDFMValues;
    ploscicaTabelaNepokritiStroski_Naslov4.AfterLoadDFMValues;
    ploscicaTabelaNepokritiStroski_Naslov5.AfterLoadDFMValues;
    ploscicaTabelaNepokritiStroski_Naslov6.AfterLoadDFMValues;
    ploscicaTabelaNepokritiStroski_Povzetek.AfterLoadDFMValues;
    IzpadPrometaTekst.AfterLoadDFMValues;
    ZavarovaniIzpadPrometaTekst.AfterLoadDFMValues;
    NepokritiZavarovaniIzpadPrometaTekst.AfterLoadDFMValues;
    izIzpadPrometa.AfterLoadDFMValues;
    IzpadPrometaPrazno.AfterLoadDFMValues;
    izZavarovaniIzpadPrometa.AfterLoadDFMValues;
    ZavarovaniIzpadPrometaPrazno.AfterLoadDFMValues;
    slikaIzpadPrometa.AfterLoadDFMValues;
    izNepokritiZavarovaniIzpadPrometa.AfterLoadDFMValues;
    NepokritiZavarovaniIzpadPrometaPrazno.AfterLoadDFMValues;
    SimulacijaSkode_List2.AfterLoadDFMValues;
    izNaslovList2.AfterLoadDFMValues;
    ploscicaIkoneList2.AfterLoadDFMValues;
    ploscicaGNaprej2.AfterLoadDFMValues;
    gNaprej2.AfterLoadDFMValues;
    ploscicaGNazaj2.AfterLoadDFMValues;
    gNazaj2.AfterLoadDFMValues;
    ploscicaPovisaniPoslovniStroski.AfterLoadDFMValues;
    PovisaniPoslovniStrskiTekst.AfterLoadDFMValues;
    ResenPrometTekst.AfterLoadDFMValues;
    EkonomskoUpraviceniPovisaniStroskiTekst.AfterLoadDFMValues;
    izPovisaniPoslovniStroski.AfterLoadDFMValues;
    izResenPromet.AfterLoadDFMValues;
    izEkonomskoUpraviceniStroski.AfterLoadDFMValues;
    SimulacijaSkode_List3.AfterLoadDFMValues;
    izNaslovList3.AfterLoadDFMValues;
    ploscicaIkoneList3.AfterLoadDFMValues;
    ploscicaGNaprej3.AfterLoadDFMValues;
    gNaprej3.AfterLoadDFMValues;
    ploscicaGNazaj3.AfterLoadDFMValues;
    gNazaj3.AfterLoadDFMValues;
    ploscicaPregledPrihrankov.AfterLoadDFMValues;
    ploscicaTabelaNepokritiStroski_Naslov7.AfterLoadDFMValues;
    ploscicaTabelaNepokritiStroski_Naslov8.AfterLoadDFMValues;
    ploscicaTabelaNepokritiStroski_Naslov9.AfterLoadDFMValues;
    ploscicaTabelaNepokritiStroski_Naslov10.AfterLoadDFMValues;
    ploscicaPregledPrihrankov_Povzetek.AfterLoadDFMValues;
    PrijavljeniPrihrankiTekst.AfterLoadDFMValues;
    DejanskiPrihrankiTekst.AfterLoadDFMValues;
    izPrijavljeniPrihranki.AfterLoadDFMValues;
    izDejanskiPrihranki.AfterLoadDFMValues;
    SimulacijaSkode_List4.AfterLoadDFMValues;
    izNaslovList4.AfterLoadDFMValues;
    ploscicaIkoneList4.AfterLoadDFMValues;
    ploscicaGNaprej4.AfterLoadDFMValues;
    gNaprej4.AfterLoadDFMValues;
    ploscicaGNazaj4.AfterLoadDFMValues;
    gNazaj4.AfterLoadDFMValues;
    ploscicaTabelaIzhodiscaLetniPrometMeseci.AfterLoadDFMValues;
    ploscicaTabelaNepokritiStroski_Naslov11.AfterLoadDFMValues;
    ploscicaTabelaNepokritiStroski_Naslov12.AfterLoadDFMValues;
    MesecPredZastojem_1.AfterLoadDFMValues;
    PrometPredZastojem_1.AfterLoadDFMValues;
    MesecPredZastojem_2.AfterLoadDFMValues;
    PrometPredZastojem_2.AfterLoadDFMValues;
    MesecPredZastojem_3.AfterLoadDFMValues;
    PrometPredZastojem_3.AfterLoadDFMValues;
    MesecPredZastojem_4.AfterLoadDFMValues;
    PrometPredZastojem_4.AfterLoadDFMValues;
    MesecPredZastojem_5.AfterLoadDFMValues;
    PrometPredZastojem_5.AfterLoadDFMValues;
    MesecPredZastojem_6.AfterLoadDFMValues;
    PrometPredZastojem_6.AfterLoadDFMValues;
    MesecPredZastojem_7.AfterLoadDFMValues;
    PrometPredZastojem_7.AfterLoadDFMValues;
    MesecPredZastojem_8.AfterLoadDFMValues;
    PrometPredZastojem_8.AfterLoadDFMValues;
    MesecPredZastojem_9.AfterLoadDFMValues;
    PrometPredZastojem_9.AfterLoadDFMValues;
    MesecPredZastojem_10.AfterLoadDFMValues;
    PrometPredZastojem_10.AfterLoadDFMValues;
    MesecPredZastojem_11.AfterLoadDFMValues;
    PrometPredZastojem_11.AfterLoadDFMValues;
    MesecPredZastojem_12.AfterLoadDFMValues;
    PrometPredZastojem_12.AfterLoadDFMValues;
    ploscicaTabelaIzhodiscaLetniPrometSkupaj.AfterLoadDFMValues;
    izIzhodiscaLetniPrometTekst.AfterLoadDFMValues;
    izIzhodiscaLetniPromet.AfterLoadDFMValues;
    ploscicaPodzavarovanje.AfterLoadDFMValues;
    izLetniPrometOsnovaTekst.AfterLoadDFMValues;
    izLetniPrometRastTekst.AfterLoadDFMValues;
    izLetniPrometTekst.AfterLoadDFMValues;
    izPotrebnaZVTekst.AfterLoadDFMValues;
    izDejanskaZVTekst.AfterLoadDFMValues;
    izPodzavarovanjeTekst.AfterLoadDFMValues;
    izLetniPrometOsnova.AfterLoadDFMValues;
    PodzavarovanjePrazno1.AfterLoadDFMValues;
    izLetniPrometRast.AfterLoadDFMValues;
    PodzavarovanjePrazno2.AfterLoadDFMValues;
    izLetniPromet.AfterLoadDFMValues;
    PodzavarovanjePrazno3.AfterLoadDFMValues;
    izPotrebnaZV.AfterLoadDFMValues;
    PodzavarovanjePrazno4.AfterLoadDFMValues;
    izDejanskaZV.AfterLoadDFMValues;
    PodzavarovanjePrazno5.AfterLoadDFMValues;
    slikaZV.AfterLoadDFMValues;
    izPodzavarovanje.AfterLoadDFMValues;
    PodzavarovanjePrazno6.AfterLoadDFMValues;
    SimulacijaSkode_List5.AfterLoadDFMValues;
    izNaslovList5.AfterLoadDFMValues;
    ploscicaIkoneList5.AfterLoadDFMValues;
    ploscicaGNazaj5.AfterLoadDFMValues;
    gNazaj5.AfterLoadDFMValues;
    ploscicaTabelaIzracunZavarovalnine.AfterLoadDFMValues;
    izRezultatIzpadPrometaTekst.AfterLoadDFMValues;
    izRezultatPovisaniStroskiTekst.AfterLoadDFMValues;
    izRezultatPrihrankiTekst.AfterLoadDFMValues;
    izRezultatPodzavarovanjeTekst.AfterLoadDFMValues;
    izRezultatZavarovalninaTekst.AfterLoadDFMValues;
    izRezultatDejanskoOcenjenaSkodaTekst.AfterLoadDFMValues;
    izRezultatIzpadPrometa.AfterLoadDFMValues;
    ploscicaZavarovalninaPrazna_1.AfterLoadDFMValues;
    slikaZavarovalnina_1.AfterLoadDFMValues;
    izRezultatPovisaniStroski.AfterLoadDFMValues;
    ploscicaZavarovalninaPrazna_2.AfterLoadDFMValues;
    ploscicaZavarovalninaPrazna_3a.AfterLoadDFMValues;
    izRezultatIzpadInStroski.AfterLoadDFMValues;
    ploscicaZavarovalninaPrazna_3b.AfterLoadDFMValues;
    izRezultatPrihranki.AfterLoadDFMValues;
    ploscicaZavarovalninaPrazna_4.AfterLoadDFMValues;
    ploscicaZavarovalninaPrazna_5a.AfterLoadDFMValues;
    izRezultatZavarovanaSkoda.AfterLoadDFMValues;
    ploscicaZavarovalninaPrazna_5b.AfterLoadDFMValues;
    izRezultatPodzavarovanje.AfterLoadDFMValues;
    ploscicaZavarovalninaPrazna_6.AfterLoadDFMValues;
    slikaZavarovalnina_2.AfterLoadDFMValues;
    izRezultatZavarovalnina.AfterLoadDFMValues;
    ploscicaZavarovalninaPrazna_7.AfterLoadDFMValues;
    slikaZavarovalnina_3.AfterLoadDFMValues;
    izRezultatDejanskoOcenjenaSkoda.AfterLoadDFMValues;
    ploscicaZavarovalninaPrazna_8.AfterLoadDFMValues;
    ploscicaNaslov.AfterLoadDFMValues;
    izNaslov.AfterLoadDFMValues;
    PovezavaWSIzracunSkode.AfterLoadDFMValues;
    WebClientIzracunSkode.AfterLoadDFMValues;
  end;
end;

end.
