unit MBI_u05;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls,
  WEBLib.ExtCtrls, WEBLib.Buttons, Vcl.Imaging.pngimage, DateUtils;

type
  TfmSimulacije = class(TForm)
    ploscicaSimulacijeGlava: TPanel;
    izSimulacijeNaslov: TLabel;
    izSpremembaZV: TLabel;
    vpSpremembaZV: TEdit;
    izSpremembaMDJ: TLabel;
    vpSpremembaMDJ: TComboBox;
    izSpremembaObdobjaResenegaPrometa: TLabel;
    vpSpremembaObdobjaResenegaPrometa: TComboBox;
    izSpremembaPricakovaneRasti: TLabel;
    vpSpremembaObdobjaPrihrankov: TComboBox;
    izSpremembaObdobjaPrihrankov: TLabel;
    ploscicSpremembaPricakovaneRasti: TPanel;
    izSpremembaPricakovaneRasti_Tekst: TLabel;
    vpSpremembaPricakovaneRasti: TTrackBar;
    gSpremeni: TSpeedButton;
    gPreklici: TSpeedButton;
    cbSpremembaZV: TCheckBox;
    ploscicaSpremembaZV: TPanel;
    ploscicaSpremembaMDJ: TPanel;
    cbSpremembaMDJ: TCheckBox;
    ploscicaSpremembaObdobjaResenegaPrometa: TPanel;
    cbSpremembaObdobjaResenegaPrometa: TCheckBox;
    ploscicaSpremembaObdobjaPrihrankov: TPanel;
    cbSpremembaObdobjaPrihrankov: TCheckBox;
    cbSpremembaPricakovaneRasti: TCheckBox;
    ploscicaGumbi: TPanel;
    gPonastavi: TSpeedButton;
    izSpremembaMDJtekst: TLabel;
    procedure gPrekliciClick(Sender: TObject);
    procedure gSpremeniClick(Sender: TObject);
    procedure vpSpremembaZVKeyPress(Sender: TObject; var Key: Char);
    procedure vpSpremembaZVExit(Sender: TObject);
    procedure cbSpremembaZVClick(Sender: TObject);
    procedure cbSpremembaComboBoxClick(Sender: TObject);
    procedure cbSpremembaPricakovaneRastiClick(Sender: TObject);
    procedure vpSpremembaPricakovaneRastiChange(Sender: TObject);
    procedure gPonastaviClick(Sender: TObject);
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  fmSimulacije: TfmSimulacije;

implementation

uses MBI_u01, MBI_u04;

{$R *.dfm}

procedure TfmSimulacije.gSpremeniClick(Sender: TObject);
begin
  ModalResult := 1;
  Close;
  FreeAndNil(fmSimulacije);
end;

procedure TfmSimulacije.vpSpremembaPricakovaneRastiChange(Sender: TObject);
begin
  izSpremembaPricakovaneRasti_Tekst.Caption := FormatFloat('###,###,##0.00 %', (Sender as TTrackBar).Position / 10);
end;

procedure TfmSimulacije.vpSpremembaZVExit(Sender: TObject);
begin
   if (Sender as TEdit).Text <> '' then
     (Sender as TEdit).Text := FormatFloat('###,###,##0.00', StrToFloat(StringReplace((Sender as TEdit).Text, '.', '', [rfReplaceAll, rfIgnoreCase])))
    else
      (Sender as TEdit).Text := FormatFloat('###,###,##0.00', StrToFloat('0'));
end;

procedure TfmSimulacije.vpSpremembaZVKeyPress(Sender: TObject; var Key: Char);
begin
  // Omogoči zgolj vnos številk in decimalne vejice
  if not (Key in ['0'..'9', ',', #8, #9, #13]) then Key := #0
    else if (Key = ',') and (Pos(Key, (Sender as TEdit).Text) > 0) then begin   // Dovoli vnos decimalne vejice samo enkrat
       Key := #0
    end
    else if (Key = #13) then  // po pritisku na <Enter> izvede proceduro OnExit, ki spremeni zapis v pravi zapis številke
      vpSpremembaZVExit(Sender);
end;


procedure TfmSimulacije.cbSpremembaComboBoxClick(Sender: TObject);
var imeIzbranegaPolja : String;
    vnosnoPolje : TComboBox;
begin
  imeIzbranegaPolja := RightStr((Sender as TCheckBox).Name, Length((Sender as TCheckBox).Name) - 2);
  vnosnoPolje := FindComponent('vp' + imeIzbranegaPolja) as TComboBox;
  if (Sender as TCheckBox).Checked = true then begin
    vnosnoPolje.Enabled := true;
    if (Sender as TCheckBox).Name = 'cbSpremembaMDJ' then
       izSpremembaMDJtekst.Font.Color := clBlack;
  end
  else begin
    vnosnoPolje.Enabled := false;
    if (Sender as TCheckBox).Name = 'cbSpremembaMDJ' then
       izSpremembaMDJtekst.Font.Color := clGray;
  end;
end;

procedure TfmSimulacije.cbSpremembaPricakovaneRastiClick(Sender: TObject);
var imeIzbranegaPolja : String;
    vnosnoPolje : TTrackBar;
begin
  imeIzbranegaPolja := RightStr((Sender as TCheckBox).Name, Length((Sender as TCheckBox).Name) - 2);
  vnosnoPolje := FindComponent('vp' + imeIzbranegaPolja) as TTrackBar;
  if (Sender as TCheckBox).Checked = true then
    vnosnoPolje.Enabled := true
  else
    vnosnoPolje.Enabled := false;
end;

procedure TfmSimulacije.cbSpremembaZVClick(Sender: TObject);
var imeIzbranegaPolja : String;
    vnosnoPolje : TEdit;
begin
  imeIzbranegaPolja := RightStr((Sender as TCheckBox).Name, Length((Sender as TCheckBox).Name) - 2);
  vnosnoPolje := FindComponent('vp' + imeIzbranegaPolja) as TEdit;
  if (Sender as TCheckBox).Checked = true then
    vnosnoPolje.Enabled := true
  else
    vnosnoPolje.Enabled := false;
end;

procedure TfmSimulacije.gPonastaviClick(Sender: TObject);
begin
  // Izbrana je ponastavitev vrednosti (tj.postavitev na začetne nastavitve - brez simulacij...)
  // Ker so vsa polja odkljukana, se bo izračun izvedel z prevzetimi (začetnimi) vrednostmi
  // Check polja se postavijo na neizbrano, vnosna polja pa se bodo nastavila na začetne vrednosti samodejno pri izvajanju procedure @PoZaprtjuShowModal)
  cbSpremembaZV.Checked := false;
  cbSpremembaMDJ.Checked := false;
  cbSpremembaObdobjaResenegaPrometa.Checked := false;
  cbSpremembaObdobjaPrihrankov.Checked := false;
  cbSpremembaPricakovaneRasti.Checked := false;
  // Potrebno je še ponastaviti vrednosti v tabeli prihrankov
  ModalResult := 2;
  Close;
  FreeAndNil(fmSimulacije);
end;

procedure TfmSimulacije.gPrekliciClick(Sender: TObject);
begin
  ModalResult := 0;
  Close;
  FreeAndNil(fmSimulacije);
end;

procedure TfmSimulacije.LoadDFMValues;
begin
  inherited LoadDFMValues;

  izSpremembaZV := TLabel.Create(Self);
  izSpremembaMDJ := TLabel.Create(Self);
  izSpremembaObdobjaResenegaPrometa := TLabel.Create(Self);
  izSpremembaPricakovaneRasti := TLabel.Create(Self);
  izSpremembaObdobjaPrihrankov := TLabel.Create(Self);
  ploscicaSimulacijeGlava := TPanel.Create(Self);
  izSimulacijeNaslov := TLabel.Create(Self);
  ploscicSpremembaPricakovaneRasti := TPanel.Create(Self);
  izSpremembaPricakovaneRasti_Tekst := TLabel.Create(Self);
  vpSpremembaPricakovaneRasti := TTrackBar.Create(Self);
  cbSpremembaPricakovaneRasti := TCheckBox.Create(Self);
  ploscicaSpremembaZV := TPanel.Create(Self);
  cbSpremembaZV := TCheckBox.Create(Self);
  vpSpremembaZV := TEdit.Create(Self);
  ploscicaSpremembaMDJ := TPanel.Create(Self);
  izSpremembaMDJtekst := TLabel.Create(Self);
  cbSpremembaMDJ := TCheckBox.Create(Self);
  vpSpremembaMDJ := TComboBox.Create(Self);
  ploscicaSpremembaObdobjaResenegaPrometa := TPanel.Create(Self);
  cbSpremembaObdobjaResenegaPrometa := TCheckBox.Create(Self);
  vpSpremembaObdobjaResenegaPrometa := TComboBox.Create(Self);
  ploscicaSpremembaObdobjaPrihrankov := TPanel.Create(Self);
  cbSpremembaObdobjaPrihrankov := TCheckBox.Create(Self);
  vpSpremembaObdobjaPrihrankov := TComboBox.Create(Self);
  ploscicaGumbi := TPanel.Create(Self);
  gSpremeni := TSpeedButton.Create(Self);
  gPreklici := TSpeedButton.Create(Self);
  gPonastavi := TSpeedButton.Create(Self);

  izSpremembaZV.BeforeLoadDFMValues;
  izSpremembaMDJ.BeforeLoadDFMValues;
  izSpremembaObdobjaResenegaPrometa.BeforeLoadDFMValues;
  izSpremembaPricakovaneRasti.BeforeLoadDFMValues;
  izSpremembaObdobjaPrihrankov.BeforeLoadDFMValues;
  ploscicaSimulacijeGlava.BeforeLoadDFMValues;
  izSimulacijeNaslov.BeforeLoadDFMValues;
  ploscicSpremembaPricakovaneRasti.BeforeLoadDFMValues;
  izSpremembaPricakovaneRasti_Tekst.BeforeLoadDFMValues;
  vpSpremembaPricakovaneRasti.BeforeLoadDFMValues;
  cbSpremembaPricakovaneRasti.BeforeLoadDFMValues;
  ploscicaSpremembaZV.BeforeLoadDFMValues;
  cbSpremembaZV.BeforeLoadDFMValues;
  vpSpremembaZV.BeforeLoadDFMValues;
  ploscicaSpremembaMDJ.BeforeLoadDFMValues;
  izSpremembaMDJtekst.BeforeLoadDFMValues;
  cbSpremembaMDJ.BeforeLoadDFMValues;
  vpSpremembaMDJ.BeforeLoadDFMValues;
  ploscicaSpremembaObdobjaResenegaPrometa.BeforeLoadDFMValues;
  cbSpremembaObdobjaResenegaPrometa.BeforeLoadDFMValues;
  vpSpremembaObdobjaResenegaPrometa.BeforeLoadDFMValues;
  ploscicaSpremembaObdobjaPrihrankov.BeforeLoadDFMValues;
  cbSpremembaObdobjaPrihrankov.BeforeLoadDFMValues;
  vpSpremembaObdobjaPrihrankov.BeforeLoadDFMValues;
  ploscicaGumbi.BeforeLoadDFMValues;
  gSpremeni.BeforeLoadDFMValues;
  gPreklici.BeforeLoadDFMValues;
  gPonastavi.BeforeLoadDFMValues;
  try
    Name := 'fmSimulacije';
    Width := 286;
    Height := 335;
    Color := clSilver;
    izSpremembaZV.SetParentComponent(Self);
    izSpremembaZV.Name := 'izSpremembaZV';
    izSpremembaZV.AlignWithMargins := True;
    izSpremembaZV.Left := 7;
    izSpremembaZV.Top := 34;
    izSpremembaZV.Width := 272;
    izSpremembaZV.Height := 13;
    izSpremembaZV.Margins.Left := 7;
    izSpremembaZV.Margins.Top := 10;
    izSpremembaZV.Margins.Right := 7;
    izSpremembaZV.Align := alTop;
    izSpremembaZV.Caption := 'Spremeni dogovorjeno zavarovalno vsoto:';
    izSpremembaZV.Color := clSilver;
    izSpremembaZV.HeightPercent := 100.000000000000000000;
    izSpremembaZV.WidthPercent := 100.000000000000000000;
    izSpremembaMDJ.SetParentComponent(Self);
    izSpremembaMDJ.Name := 'izSpremembaMDJ';
    izSpremembaMDJ.AlignWithMargins := True;
    izSpremembaMDJ.Left := 7;
    izSpremembaMDJ.Top := 87;
    izSpremembaMDJ.Width := 272;
    izSpremembaMDJ.Height := 13;
    izSpremembaMDJ.Margins.Left := 7;
    izSpremembaMDJ.Margins.Top := 10;
    izSpremembaMDJ.Margins.Right := 7;
    izSpremembaMDJ.Align := alTop;
    izSpremembaMDJ.Caption := 'Spremeni dogovorjeno maksimalno dobo ja'#269'enja:';
    izSpremembaMDJ.Color := clSilver;
    izSpremembaMDJ.HeightPercent := 100.000000000000000000;
    izSpremembaMDJ.WidthPercent := 100.000000000000000000;
    izSpremembaObdobjaResenegaPrometa.SetParentComponent(Self);
    izSpremembaObdobjaResenegaPrometa.Name := 'izSpremembaObdobjaResenegaPrometa';
    izSpremembaObdobjaResenegaPrometa.AlignWithMargins := True;
    izSpremembaObdobjaResenegaPrometa.Left := 7;
    izSpremembaObdobjaResenegaPrometa.Top := 140;
    izSpremembaObdobjaResenegaPrometa.Width := 272;
    izSpremembaObdobjaResenegaPrometa.Height := 13;
    izSpremembaObdobjaResenegaPrometa.Margins.Left := 7;
    izSpremembaObdobjaResenegaPrometa.Margins.Top := 10;
    izSpremembaObdobjaResenegaPrometa.Margins.Right := 7;
    izSpremembaObdobjaResenegaPrometa.Align := alTop;
    izSpremembaObdobjaResenegaPrometa.Caption := 'Spremeni obdobje re'#353'enega prometa:';
    izSpremembaObdobjaResenegaPrometa.Color := clSilver;
    izSpremembaObdobjaResenegaPrometa.HeightPercent := 100.000000000000000000;
    izSpremembaObdobjaResenegaPrometa.WidthPercent := 100.000000000000000000;
    izSpremembaPricakovaneRasti.SetParentComponent(Self);
    izSpremembaPricakovaneRasti.Name := 'izSpremembaPricakovaneRasti';
    izSpremembaPricakovaneRasti.AlignWithMargins := True;
    izSpremembaPricakovaneRasti.Left := 7;
    izSpremembaPricakovaneRasti.Top := 246;
    izSpremembaPricakovaneRasti.Width := 272;
    izSpremembaPricakovaneRasti.Height := 13;
    izSpremembaPricakovaneRasti.Margins.Left := 7;
    izSpremembaPricakovaneRasti.Margins.Top := 10;
    izSpremembaPricakovaneRasti.Margins.Right := 7;
    izSpremembaPricakovaneRasti.Align := alTop;
    izSpremembaPricakovaneRasti.Caption := 'Spremeni pri'#269'akovano rast prometa za obdobje zastoja:';
    izSpremembaPricakovaneRasti.Color := clSilver;
    izSpremembaPricakovaneRasti.HeightPercent := 100.000000000000000000;
    izSpremembaPricakovaneRasti.WidthPercent := 100.000000000000000000;
    izSpremembaObdobjaPrihrankov.SetParentComponent(Self);
    izSpremembaObdobjaPrihrankov.Name := 'izSpremembaObdobjaPrihrankov';
    izSpremembaObdobjaPrihrankov.AlignWithMargins := True;
    izSpremembaObdobjaPrihrankov.Left := 7;
    izSpremembaObdobjaPrihrankov.Top := 193;
    izSpremembaObdobjaPrihrankov.Width := 272;
    izSpremembaObdobjaPrihrankov.Height := 13;
    izSpremembaObdobjaPrihrankov.Margins.Left := 7;
    izSpremembaObdobjaPrihrankov.Margins.Top := 10;
    izSpremembaObdobjaPrihrankov.Margins.Right := 7;
    izSpremembaObdobjaPrihrankov.Align := alTop;
    izSpremembaObdobjaPrihrankov.Caption := 'Spremeni obdobje prihrankov:';
    izSpremembaObdobjaPrihrankov.Color := clSilver;
    izSpremembaObdobjaPrihrankov.HeightPercent := 100.000000000000000000;
    izSpremembaObdobjaPrihrankov.WidthPercent := 100.000000000000000000;
    ploscicaSimulacijeGlava.SetParentComponent(Self);
    ploscicaSimulacijeGlava.Name := 'ploscicaSimulacijeGlava';
    ploscicaSimulacijeGlava.Left := 0;
    ploscicaSimulacijeGlava.Top := 0;
    ploscicaSimulacijeGlava.Width := 286;
    ploscicaSimulacijeGlava.Height := 24;
    ploscicaSimulacijeGlava.HeightPercent := 100.000000000000000000;
    ploscicaSimulacijeGlava.WidthPercent := 100.000000000000000000;
    ploscicaSimulacijeGlava.Align := alTop;
    ploscicaSimulacijeGlava.BorderColor := clSilver;
    ploscicaSimulacijeGlava.BorderStyle := bsNone;
    ploscicaSimulacijeGlava.Color := clGray;
    izSimulacijeNaslov.SetParentComponent(ploscicaSimulacijeGlava);
    izSimulacijeNaslov.Name := 'izSimulacijeNaslov';
    izSimulacijeNaslov.AlignWithMargins := True;
    izSimulacijeNaslov.Left := 3;
    izSimulacijeNaslov.Top := 5;
    izSimulacijeNaslov.Width := 280;
    izSimulacijeNaslov.Height := 13;
    izSimulacijeNaslov.Margins.Top := 5;
    izSimulacijeNaslov.Margins.Bottom := 5;
    izSimulacijeNaslov.Align := alTop;
    izSimulacijeNaslov.Alignment := taCenter;
    izSimulacijeNaslov.AutoSize := False;
    izSimulacijeNaslov.Caption := '"KAJ '#268'E..." SIMULACIJE';
    izSimulacijeNaslov.Font.Charset := DEFAULT_CHARSET;
    izSimulacijeNaslov.Font.Color := clWindowText;
    izSimulacijeNaslov.Font.Height := -11;
    izSimulacijeNaslov.Font.Name := 'Tahoma';
    izSimulacijeNaslov.Font.Style := [fsBold];
    izSimulacijeNaslov.HeightPercent := 100.000000000000000000;
    izSimulacijeNaslov.ParentFont := False;
    izSimulacijeNaslov.WidthPercent := 100.000000000000000000;
    ploscicSpremembaPricakovaneRasti.SetParentComponent(Self);
    ploscicSpremembaPricakovaneRasti.Name := 'ploscicSpremembaPricakovaneRasti';
    ploscicSpremembaPricakovaneRasti.Left := 0;
    ploscicSpremembaPricakovaneRasti.Top := 262;
    ploscicSpremembaPricakovaneRasti.Width := 286;
    ploscicSpremembaPricakovaneRasti.Height := 30;
    ploscicSpremembaPricakovaneRasti.Margins.Left := 7;
    ploscicSpremembaPricakovaneRasti.Margins.Right := 7;
    ploscicSpremembaPricakovaneRasti.HeightPercent := 100.000000000000000000;
    ploscicSpremembaPricakovaneRasti.WidthPercent := 100.000000000000000000;
    ploscicSpremembaPricakovaneRasti.Align := alTop;
    ploscicSpremembaPricakovaneRasti.BorderColor := clSilver;
    ploscicSpremembaPricakovaneRasti.BorderStyle := bsNone;
    ploscicSpremembaPricakovaneRasti.ChildOrder := 12;
    ploscicSpremembaPricakovaneRasti.Color := clSilver;
    izSpremembaPricakovaneRasti_Tekst.SetParentComponent(ploscicSpremembaPricakovaneRasti);
    izSpremembaPricakovaneRasti_Tekst.Name := 'izSpremembaPricakovaneRasti_Tekst';
    izSpremembaPricakovaneRasti_Tekst.AlignWithMargins := True;
    izSpremembaPricakovaneRasti_Tekst.Left := 236;
    izSpremembaPricakovaneRasti_Tekst.Top := 6;
    izSpremembaPricakovaneRasti_Tekst.Width := 40;
    izSpremembaPricakovaneRasti_Tekst.Height := 21;
    izSpremembaPricakovaneRasti_Tekst.Margins.Top := 6;
    izSpremembaPricakovaneRasti_Tekst.Margins.Right := 10;
    izSpremembaPricakovaneRasti_Tekst.Align := alRight;
    izSpremembaPricakovaneRasti_Tekst.Alignment := taRightJustify;
    izSpremembaPricakovaneRasti_Tekst.AutoSize := False;
    izSpremembaPricakovaneRasti_Tekst.Caption := '9.5 %';
    izSpremembaPricakovaneRasti_Tekst.Color := clNone;
    izSpremembaPricakovaneRasti_Tekst.HeightPercent := 100.000000000000000000;
    izSpremembaPricakovaneRasti_Tekst.WidthPercent := 100.000000000000000000;
    vpSpremembaPricakovaneRasti.SetParentComponent(ploscicSpremembaPricakovaneRasti);
    vpSpremembaPricakovaneRasti.Name := 'vpSpremembaPricakovaneRasti';
    vpSpremembaPricakovaneRasti.Left := 34;
    vpSpremembaPricakovaneRasti.Top := 2;
    vpSpremembaPricakovaneRasti.Width := 200;
    vpSpremembaPricakovaneRasti.Height := 24;
    vpSpremembaPricakovaneRasti.Margins.Top := 0;
    vpSpremembaPricakovaneRasti.Margins.Bottom := 0;
    vpSpremembaPricakovaneRasti.HeightPercent := 100.000000000000000000;
    vpSpremembaPricakovaneRasti.WidthPercent := 100.000000000000000000;
    vpSpremembaPricakovaneRasti.ChildOrder := 7;
    vpSpremembaPricakovaneRasti.Max := 150;
    vpSpremembaPricakovaneRasti.Min := -50;
    vpSpremembaPricakovaneRasti.Position := 0;
    vpSpremembaPricakovaneRasti.Role := '';
    vpSpremembaPricakovaneRasti.TabOrder := 25;
    SetEvent(vpSpremembaPricakovaneRasti, Self, 'OnChange', 'vpSpremembaPricakovaneRastiChange');
    cbSpremembaPricakovaneRasti.SetParentComponent(ploscicSpremembaPricakovaneRasti);
    cbSpremembaPricakovaneRasti.Name := 'cbSpremembaPricakovaneRasti';
    cbSpremembaPricakovaneRasti.AlignWithMargins := True;
    cbSpremembaPricakovaneRasti.Left := 7;
    cbSpremembaPricakovaneRasti.Top := 2;
    cbSpremembaPricakovaneRasti.Width := 21;
    cbSpremembaPricakovaneRasti.Height := 21;
    cbSpremembaPricakovaneRasti.Margins.Left := 7;
    cbSpremembaPricakovaneRasti.Margins.Top := 0;
    cbSpremembaPricakovaneRasti.ChildOrder := 2;
    cbSpremembaPricakovaneRasti.Color := clNone;
    cbSpremembaPricakovaneRasti.HeightPercent := 100.000000000000000000;
    cbSpremembaPricakovaneRasti.State := cbUnchecked;
    cbSpremembaPricakovaneRasti.WidthPercent := 100.000000000000000000;
    SetEvent(cbSpremembaPricakovaneRasti, Self, 'OnClick', 'cbSpremembaPricakovaneRastiClick');
    ploscicaSpremembaZV.SetParentComponent(Self);
    ploscicaSpremembaZV.Name := 'ploscicaSpremembaZV';
    ploscicaSpremembaZV.Left := 0;
    ploscicaSpremembaZV.Top := 50;
    ploscicaSpremembaZV.Width := 286;
    ploscicaSpremembaZV.Height := 27;
    ploscicaSpremembaZV.Margins.Left := 7;
    ploscicaSpremembaZV.Margins.Right := 7;
    ploscicaSpremembaZV.HeightPercent := 100.000000000000000000;
    ploscicaSpremembaZV.WidthPercent := 100.000000000000000000;
    ploscicaSpremembaZV.Align := alTop;
    ploscicaSpremembaZV.BorderColor := clSilver;
    ploscicaSpremembaZV.BorderStyle := bsNone;
    ploscicaSpremembaZV.ChildOrder := 14;
    ploscicaSpremembaZV.Color := clSilver;
    cbSpremembaZV.SetParentComponent(ploscicaSpremembaZV);
    cbSpremembaZV.Name := 'cbSpremembaZV';
    cbSpremembaZV.AlignWithMargins := True;
    cbSpremembaZV.Left := 7;
    cbSpremembaZV.Top := 3;
    cbSpremembaZV.Width := 21;
    cbSpremembaZV.Height := 21;
    cbSpremembaZV.Margins.Left := 7;
    cbSpremembaZV.Align := alLeft;
    cbSpremembaZV.Color := clNone;
    cbSpremembaZV.HeightPercent := 100.000000000000000000;
    cbSpremembaZV.State := cbUnchecked;
    cbSpremembaZV.WidthPercent := 100.000000000000000000;
    SetEvent(cbSpremembaZV, Self, 'OnClick', 'cbSpremembaZVClick');
    vpSpremembaZV.SetParentComponent(ploscicaSpremembaZV);
    vpSpremembaZV.Name := 'vpSpremembaZV';
    vpSpremembaZV.AlignWithMargins := True;
    vpSpremembaZV.Left := 34;
    vpSpremembaZV.Top := 3;
    vpSpremembaZV.Width := 245;
    vpSpremembaZV.Height := 21;
    vpSpremembaZV.Margins.Left := 7;
    vpSpremembaZV.Margins.Right := 7;
    vpSpremembaZV.Align := alRight;
    vpSpremembaZV.ChildOrder := 2;
    vpSpremembaZV.HeightPercent := 100.000000000000000000;
    vpSpremembaZV.WidthPercent := 100.000000000000000000;
    SetEvent(vpSpremembaZV, Self, 'OnExit', 'vpSpremembaZVExit');
    SetEvent(vpSpremembaZV, Self, 'OnKeyPress', 'vpSpremembaZVKeyPress');
    ploscicaSpremembaMDJ.SetParentComponent(Self);
    ploscicaSpremembaMDJ.Name := 'ploscicaSpremembaMDJ';
    ploscicaSpremembaMDJ.Left := 0;
    ploscicaSpremembaMDJ.Top := 103;
    ploscicaSpremembaMDJ.Width := 286;
    ploscicaSpremembaMDJ.Height := 27;
    ploscicaSpremembaMDJ.Margins.Left := 7;
    ploscicaSpremembaMDJ.Margins.Right := 7;
    ploscicaSpremembaMDJ.HeightPercent := 100.000000000000000000;
    ploscicaSpremembaMDJ.WidthPercent := 100.000000000000000000;
    ploscicaSpremembaMDJ.Align := alTop;
    ploscicaSpremembaMDJ.BorderColor := clSilver;
    ploscicaSpremembaMDJ.BorderStyle := bsNone;
    ploscicaSpremembaMDJ.ChildOrder := 14;
    ploscicaSpremembaMDJ.Color := clSilver;
    izSpremembaMDJtekst.SetParentComponent(ploscicaSpremembaMDJ);
    izSpremembaMDJtekst.Name := 'izSpremembaMDJtekst';
    izSpremembaMDJtekst.AlignWithMargins := True;
    izSpremembaMDJtekst.Left := 86;
    izSpremembaMDJtekst.Top := 6;
    izSpremembaMDJtekst.Width := 63;
    izSpremembaMDJtekst.Height := 14;
    izSpremembaMDJtekst.Margins.Left := 7;
    izSpremembaMDJtekst.Margins.Top := 10;
    izSpremembaMDJtekst.Margins.Right := 7;
    izSpremembaMDJtekst.AutoSize := False;
    izSpremembaMDJtekst.Caption := 'mesec(e/ev)';
    izSpremembaMDJtekst.Color := clSilver;
    izSpremembaMDJtekst.HeightPercent := 100.000000000000000000;
    izSpremembaMDJtekst.WidthPercent := 100.000000000000000000;
    cbSpremembaMDJ.SetParentComponent(ploscicaSpremembaMDJ);
    cbSpremembaMDJ.Name := 'cbSpremembaMDJ';
    cbSpremembaMDJ.AlignWithMargins := True;
    cbSpremembaMDJ.Left := 7;
    cbSpremembaMDJ.Top := 3;
    cbSpremembaMDJ.Width := 21;
    cbSpremembaMDJ.Height := 21;
    cbSpremembaMDJ.Margins.Left := 7;
    cbSpremembaMDJ.Align := alLeft;
    cbSpremembaMDJ.Color := clNone;
    cbSpremembaMDJ.HeightPercent := 100.000000000000000000;
    cbSpremembaMDJ.State := cbUnchecked;
    cbSpremembaMDJ.WidthPercent := 100.000000000000000000;
    SetEvent(cbSpremembaMDJ, Self, 'OnClick', 'cbSpremembaComboBoxClick');
    vpSpremembaMDJ.SetParentComponent(ploscicaSpremembaMDJ);
    vpSpremembaMDJ.Name := 'vpSpremembaMDJ';
    vpSpremembaMDJ.AlignWithMargins := True;
    vpSpremembaMDJ.Left := 38;
    vpSpremembaMDJ.Top := 3;
    vpSpremembaMDJ.Width := 43;
    vpSpremembaMDJ.Height := 21;
    vpSpremembaMDJ.Margins.Left := 7;
    vpSpremembaMDJ.Margins.Right := 7;
    vpSpremembaMDJ.Align := alLeft;
    vpSpremembaMDJ.HeightPercent := 100.000000000000000000;
    vpSpremembaMDJ.Text := '3';
    vpSpremembaMDJ.WidthPercent := 100.000000000000000000;
    vpSpremembaMDJ.ItemIndex := -1;
    vpSpremembaMDJ.Items.BeginUpdate;
    try
      vpSpremembaMDJ.Items.Clear;
      vpSpremembaMDJ.Items.Add('3');
      vpSpremembaMDJ.Items.Add('4');
      vpSpremembaMDJ.Items.Add('5');
      vpSpremembaMDJ.Items.Add('6');
      vpSpremembaMDJ.Items.Add('7');
      vpSpremembaMDJ.Items.Add('8');
      vpSpremembaMDJ.Items.Add('9');
      vpSpremembaMDJ.Items.Add('10');
      vpSpremembaMDJ.Items.Add('11');
      vpSpremembaMDJ.Items.Add('12');
    finally
      vpSpremembaMDJ.Items.EndUpdate;
    end;
    ploscicaSpremembaObdobjaResenegaPrometa.SetParentComponent(Self);
    ploscicaSpremembaObdobjaResenegaPrometa.Name := 'ploscicaSpremembaObdobjaResenegaPrometa';
    ploscicaSpremembaObdobjaResenegaPrometa.Left := 0;
    ploscicaSpremembaObdobjaResenegaPrometa.Top := 156;
    ploscicaSpremembaObdobjaResenegaPrometa.Width := 286;
    ploscicaSpremembaObdobjaResenegaPrometa.Height := 27;
    ploscicaSpremembaObdobjaResenegaPrometa.Margins.Left := 7;
    ploscicaSpremembaObdobjaResenegaPrometa.Margins.Right := 7;
    ploscicaSpremembaObdobjaResenegaPrometa.HeightPercent := 100.000000000000000000;
    ploscicaSpremembaObdobjaResenegaPrometa.WidthPercent := 100.000000000000000000;
    ploscicaSpremembaObdobjaResenegaPrometa.Align := alTop;
    ploscicaSpremembaObdobjaResenegaPrometa.BorderColor := clSilver;
    ploscicaSpremembaObdobjaResenegaPrometa.BorderStyle := bsNone;
    ploscicaSpremembaObdobjaResenegaPrometa.ChildOrder := 14;
    ploscicaSpremembaObdobjaResenegaPrometa.Color := clSilver;
    cbSpremembaObdobjaResenegaPrometa.SetParentComponent(ploscicaSpremembaObdobjaResenegaPrometa);
    cbSpremembaObdobjaResenegaPrometa.Name := 'cbSpremembaObdobjaResenegaPrometa';
    cbSpremembaObdobjaResenegaPrometa.AlignWithMargins := True;
    cbSpremembaObdobjaResenegaPrometa.Left := 7;
    cbSpremembaObdobjaResenegaPrometa.Top := 3;
    cbSpremembaObdobjaResenegaPrometa.Width := 21;
    cbSpremembaObdobjaResenegaPrometa.Height := 21;
    cbSpremembaObdobjaResenegaPrometa.Margins.Left := 7;
    cbSpremembaObdobjaResenegaPrometa.Align := alLeft;
    cbSpremembaObdobjaResenegaPrometa.Color := clNone;
    cbSpremembaObdobjaResenegaPrometa.HeightPercent := 100.000000000000000000;
    cbSpremembaObdobjaResenegaPrometa.State := cbUnchecked;
    cbSpremembaObdobjaResenegaPrometa.WidthPercent := 100.000000000000000000;
    SetEvent(cbSpremembaObdobjaResenegaPrometa, Self, 'OnClick', 'cbSpremembaComboBoxClick');
    vpSpremembaObdobjaResenegaPrometa.SetParentComponent(ploscicaSpremembaObdobjaResenegaPrometa);
    vpSpremembaObdobjaResenegaPrometa.Name := 'vpSpremembaObdobjaResenegaPrometa';
    vpSpremembaObdobjaResenegaPrometa.AlignWithMargins := True;
    vpSpremembaObdobjaResenegaPrometa.Left := 34;
    vpSpremembaObdobjaResenegaPrometa.Top := 3;
    vpSpremembaObdobjaResenegaPrometa.Width := 245;
    vpSpremembaObdobjaResenegaPrometa.Height := 21;
    vpSpremembaObdobjaResenegaPrometa.Margins.Left := 7;
    vpSpremembaObdobjaResenegaPrometa.Margins.Right := 7;
    vpSpremembaObdobjaResenegaPrometa.Align := alRight;
    vpSpremembaObdobjaResenegaPrometa.HeightPercent := 100.000000000000000000;
    vpSpremembaObdobjaResenegaPrometa.Text := 'obdobje ugotovljenega zastoja';
    vpSpremembaObdobjaResenegaPrometa.WidthPercent := 100.000000000000000000;
    vpSpremembaObdobjaResenegaPrometa.ItemIndex := 0;
    vpSpremembaObdobjaResenegaPrometa.Items.BeginUpdate;
    try
      vpSpremembaObdobjaResenegaPrometa.Items.Clear;
      vpSpremembaObdobjaResenegaPrometa.Items.Add('obdobje ugotovljenega zastoja');
      vpSpremembaObdobjaResenegaPrometa.Items.Add('obdobje maksimalne dobe jam'#269'enja');
    finally
      vpSpremembaObdobjaResenegaPrometa.Items.EndUpdate;
    end;
    ploscicaSpremembaObdobjaPrihrankov.SetParentComponent(Self);
    ploscicaSpremembaObdobjaPrihrankov.Name := 'ploscicaSpremembaObdobjaPrihrankov';
    ploscicaSpremembaObdobjaPrihrankov.Left := 0;
    ploscicaSpremembaObdobjaPrihrankov.Top := 209;
    ploscicaSpremembaObdobjaPrihrankov.Width := 286;
    ploscicaSpremembaObdobjaPrihrankov.Height := 27;
    ploscicaSpremembaObdobjaPrihrankov.Margins.Left := 7;
    ploscicaSpremembaObdobjaPrihrankov.Margins.Right := 7;
    ploscicaSpremembaObdobjaPrihrankov.HeightPercent := 100.000000000000000000;
    ploscicaSpremembaObdobjaPrihrankov.WidthPercent := 100.000000000000000000;
    ploscicaSpremembaObdobjaPrihrankov.Align := alTop;
    ploscicaSpremembaObdobjaPrihrankov.BorderColor := clSilver;
    ploscicaSpremembaObdobjaPrihrankov.BorderStyle := bsNone;
    ploscicaSpremembaObdobjaPrihrankov.ChildOrder := 14;
    ploscicaSpremembaObdobjaPrihrankov.Color := clSilver;
    cbSpremembaObdobjaPrihrankov.SetParentComponent(ploscicaSpremembaObdobjaPrihrankov);
    cbSpremembaObdobjaPrihrankov.Name := 'cbSpremembaObdobjaPrihrankov';
    cbSpremembaObdobjaPrihrankov.AlignWithMargins := True;
    cbSpremembaObdobjaPrihrankov.Left := 7;
    cbSpremembaObdobjaPrihrankov.Top := 3;
    cbSpremembaObdobjaPrihrankov.Width := 21;
    cbSpremembaObdobjaPrihrankov.Height := 21;
    cbSpremembaObdobjaPrihrankov.Margins.Left := 7;
    cbSpremembaObdobjaPrihrankov.Align := alLeft;
    cbSpremembaObdobjaPrihrankov.Color := clNone;
    cbSpremembaObdobjaPrihrankov.HeightPercent := 100.000000000000000000;
    cbSpremembaObdobjaPrihrankov.State := cbUnchecked;
    cbSpremembaObdobjaPrihrankov.WidthPercent := 100.000000000000000000;
    SetEvent(cbSpremembaObdobjaPrihrankov, Self, 'OnClick', 'cbSpremembaComboBoxClick');
    vpSpremembaObdobjaPrihrankov.SetParentComponent(ploscicaSpremembaObdobjaPrihrankov);
    vpSpremembaObdobjaPrihrankov.Name := 'vpSpremembaObdobjaPrihrankov';
    vpSpremembaObdobjaPrihrankov.AlignWithMargins := True;
    vpSpremembaObdobjaPrihrankov.Left := 34;
    vpSpremembaObdobjaPrihrankov.Top := 3;
    vpSpremembaObdobjaPrihrankov.Width := 245;
    vpSpremembaObdobjaPrihrankov.Height := 21;
    vpSpremembaObdobjaPrihrankov.Margins.Left := 7;
    vpSpremembaObdobjaPrihrankov.Margins.Right := 7;
    vpSpremembaObdobjaPrihrankov.Align := alRight;
    vpSpremembaObdobjaPrihrankov.HeightPercent := 100.000000000000000000;
    vpSpremembaObdobjaPrihrankov.Text := 'cbSpremembaPricakovaneRasti';
    vpSpremembaObdobjaPrihrankov.WidthPercent := 100.000000000000000000;
    vpSpremembaObdobjaPrihrankov.ItemIndex := -1;
    vpSpremembaObdobjaPrihrankov.Items.BeginUpdate;
    try
      vpSpremembaObdobjaPrihrankov.Items.Clear;
      vpSpremembaObdobjaPrihrankov.Items.Add('obdobje ugotovljenega zastoja');
      vpSpremembaObdobjaPrihrankov.Items.Add('obdobje maksimalne dobe jam'#269'enja');
    finally
      vpSpremembaObdobjaPrihrankov.Items.EndUpdate;
    end;
    ploscicaGumbi.SetParentComponent(Self);
    ploscicaGumbi.Name := 'ploscicaGumbi';
    ploscicaGumbi.Left := 0;
    ploscicaGumbi.Top := 311;
    ploscicaGumbi.Width := 286;
    ploscicaGumbi.Height := 24;
    ploscicaGumbi.Margins.Left := 0;
    ploscicaGumbi.Margins.Top := 0;
    ploscicaGumbi.Margins.Right := 0;
    ploscicaGumbi.Margins.Bottom := 0;
    ploscicaGumbi.HeightPercent := 100.000000000000000000;
    ploscicaGumbi.WidthPercent := 100.000000000000000000;
    ploscicaGumbi.Align := alBottom;
    ploscicaGumbi.BorderColor := clSilver;
    ploscicaGumbi.BorderStyle := bsNone;
    ploscicaGumbi.ChildOrder := 13;
    ploscicaGumbi.Color := clGray;
    gSpremeni.SetParentComponent(ploscicaGumbi);
    gSpremeni.Name := 'gSpremeni';
    gSpremeni.AlignWithMargins := True;
    gSpremeni.Left := 2;
    gSpremeni.Top := 2;
    gSpremeni.Width := 94;
    gSpremeni.Height := 20;
    gSpremeni.Margins.Left := 2;
    gSpremeni.Margins.Top := 2;
    gSpremeni.Margins.Right := 1;
    gSpremeni.Margins.Bottom := 2;
    gSpremeni.Align := alLeft;
    gSpremeni.Caption := 'Spremeni';
    gSpremeni.Color := clGray;
    gSpremeni.Flat := True;
    gSpremeni.Glyph.LoadFromFile('MBI_u05.ploscicaGumbi.gSpremeni.Glyph.png');
    gSpremeni.HeightPercent := 100.000000000000000000;
    gSpremeni.WidthPercent := 100.000000000000000000;
    SetEvent(gSpremeni, Self, 'OnClick', 'gSpremeniClick');
    gPreklici.SetParentComponent(ploscicaGumbi);
    gPreklici.Name := 'gPreklici';
    gPreklici.AlignWithMargins := True;
    gPreklici.Left := 190;
    gPreklici.Top := 2;
    gPreklici.Width := 94;
    gPreklici.Height := 20;
    gPreklici.Margins.Left := 1;
    gPreklici.Margins.Top := 2;
    gPreklici.Margins.Right := 2;
    gPreklici.Margins.Bottom := 2;
    gPreklici.Align := alRight;
    gPreklici.Caption := 'Prekli'#269'i';
    gPreklici.Color := clGray;
    gPreklici.Flat := True;
    gPreklici.Glyph.LoadFromFile('MBI_u05.ploscicaGumbi.gPreklici.Glyph.png');
    gPreklici.HeightPercent := 100.000000000000000000;
    gPreklici.WidthPercent := 100.000000000000000000;
    SetEvent(gPreklici, Self, 'OnClick', 'gPrekliciClick');
    gPonastavi.SetParentComponent(ploscicaGumbi);
    gPonastavi.Name := 'gPonastavi';
    gPonastavi.AlignWithMargins := True;
    gPonastavi.Left := 98;
    gPonastavi.Top := 2;
    gPonastavi.Width := 90;
    gPonastavi.Height := 20;
    gPonastavi.Margins.Left := 1;
    gPonastavi.Margins.Top := 2;
    gPonastavi.Margins.Right := 1;
    gPonastavi.Margins.Bottom := 2;
    gPonastavi.Align := alClient;
    gPonastavi.Caption := 'Ponastavi';
    gPonastavi.Color := clGray;
    gPonastavi.Flat := True;
    gPonastavi.Glyph.LoadFromFile('MBI_u05.ploscicaGumbi.gPonastavi.Glyph.png');
    gPonastavi.HeightPercent := 100.000000000000000000;
    gPonastavi.WidthPercent := 100.000000000000000000;
    SetEvent(gPonastavi, Self, 'OnClick', 'gPonastaviClick');
  finally
    izSpremembaZV.AfterLoadDFMValues;
    izSpremembaMDJ.AfterLoadDFMValues;
    izSpremembaObdobjaResenegaPrometa.AfterLoadDFMValues;
    izSpremembaPricakovaneRasti.AfterLoadDFMValues;
    izSpremembaObdobjaPrihrankov.AfterLoadDFMValues;
    ploscicaSimulacijeGlava.AfterLoadDFMValues;
    izSimulacijeNaslov.AfterLoadDFMValues;
    ploscicSpremembaPricakovaneRasti.AfterLoadDFMValues;
    izSpremembaPricakovaneRasti_Tekst.AfterLoadDFMValues;
    vpSpremembaPricakovaneRasti.AfterLoadDFMValues;
    cbSpremembaPricakovaneRasti.AfterLoadDFMValues;
    ploscicaSpremembaZV.AfterLoadDFMValues;
    cbSpremembaZV.AfterLoadDFMValues;
    vpSpremembaZV.AfterLoadDFMValues;
    ploscicaSpremembaMDJ.AfterLoadDFMValues;
    izSpremembaMDJtekst.AfterLoadDFMValues;
    cbSpremembaMDJ.AfterLoadDFMValues;
    vpSpremembaMDJ.AfterLoadDFMValues;
    ploscicaSpremembaObdobjaResenegaPrometa.AfterLoadDFMValues;
    cbSpremembaObdobjaResenegaPrometa.AfterLoadDFMValues;
    vpSpremembaObdobjaResenegaPrometa.AfterLoadDFMValues;
    ploscicaSpremembaObdobjaPrihrankov.AfterLoadDFMValues;
    cbSpremembaObdobjaPrihrankov.AfterLoadDFMValues;
    vpSpremembaObdobjaPrihrankov.AfterLoadDFMValues;
    ploscicaGumbi.AfterLoadDFMValues;
    gSpremeni.AfterLoadDFMValues;
    gPreklici.AfterLoadDFMValues;
    gPonastavi.AfterLoadDFMValues;
  end;
end;

end.