unit MBI_u01;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, WEBLib.ExtCtrls,
  Vcl.Imaging.pngimage, WEBLib.Buttons, WEBLib.ComCtrls, Vcl.StdCtrls,
  WEBLib.StdCtrls, WEBLib.Login, DateUtils, MBI_u02, MBI_u03, MBI_u04, MBI_u06,
  MBI_u07, WEBLib.GoogleReCaptcha, XData.Web.Client, XData.Web.Connection,
  Vcl.Menus, WEBLib.Menus, WEBLib.GoogleChart, Vcl.Imaging.GIFImg;

type
  TArhivVrednostiZadnjeSimulacije  = record
    simulacijaZV : String;
    simulacijaMDJ : Integer;
    simulacijaPrihranki : Integer;
    simulacijaPovisaniStroski : Integer;
    simulacijaRast : Integer;
end;
type
  TArhivIzborovSimulacije  = record
    simulacijaZV : Boolean;
    simulacijaMDJ : Boolean;
    simulacijaPrihranki : Boolean;
    simulacijaPovisaniStroski : Boolean;
    simulacijaRast : Boolean;
end;
type
  TArhivVrednostiZacetneSimulacije  = record
    simulacijaZV : String;
    simulacijaMDJ : Integer;
    simulacijaPrihranki : Integer;
    simulacijaPovisaniStroski : Integer;
    simulacijaRast : Integer;
end;
type
  TfmOsnovniKontroler = class(TForm)
    ploscicaGlava: TPanel;
    logoRC1: TImageControl;
    ploscicaIzborPogledov: TPanel;
    izIzborVhodniPodatki: TLabel;
    izborVhodniPodatki: TListBox;
    izborAnalize: TListBox;
    izIzborAnalize: TLabel;
    izborSimulacijeSkod: TListBox;
    izIzborSimulacijeSkod: TLabel;
    DelovniList0A: TTabSheet;
    ploscicaDelovniListi: TPageControl;
    DelovniList1: TTabSheet;
    ploscicaVnosPodatkovOPodjetju: TGridPanel;
    izImePodjetja: TLabel;
    vpImePodjetja: TEdit;
    izDavcnaStevilkaPodjetja: TLabel;
    izDejavnostPodjetja: TLabel;
    vpDavcnaStevilkaPodjetja: TEdit;
    vpNaslovPodjetja: TEdit;
    izNaslovPodjetja: TLabel;
    vpDejavnostPodjetja: TComboBox;
    izNaslovDL1: TLabel;
    DelovniList2: TTabSheet;
    izNaslovDL2: TLabel;
    ploscicaVnosFinancnihPodatkovPodjetja: TGridPanel;
    izVnosFRPLeto: TLabel;
    izVnosFRPPromet: TLabel;
    vpVnosFRPPromet: TEdit;
    izVnosFRPZalogeZacetek: TLabel;
    vpVnosFRPZalogeZacetek: TEdit;
    izVnosFRPZalogeKonec: TLabel;
    vpVnosFRPZalogeKonec: TEdit;
    izVnosFRPInternoProizvedeniDodatki: TLabel;
    vpVnosFRPInternoProizvedeniDodatki: TEdit;
    izVnosFRPStroskiSurovine: TLabel;
    vpVnosFRPStroskiSurovine: TEdit;
    izVnosFRPStroskiMaterial: TLabel;
    vpVnosFRPStroskiMaterial: TEdit;
    izVnosFRPStroskiEnergija: TLabel;
    vpVnosFRPStroskiEnergija: TEdit;
    izVnosFRPStroskiTrgovskoBlago: TLabel;
    vpVnosFRPStroskiTrgovskoBlago: TEdit;
    izVnosFRPStroskiStoritve: TLabel;
    vpVnosFRPStroskiStoritve: TEdit;
    izVnosFRPDrugiStroski: TLabel;
    vpVnosFRPDrugiStroski: TEdit;
    izVnosFRPPrejeteZavarovalnine: TLabel;
    vpVnosFRPPrejeteZavarovalnine: TEdit;
    izVnosFRPKosmatiDobicek: TLabel;
    vpVnosFRPKosmatiDobicek: TLabel;
    izVnosFRPStopnjaKosmategaDobicka: TLabel;
    vpVnosFRPStopnjaKosmategaDobicka: TLabel;
    DelovniList3: TTabSheet;
    izNaslovDL3: TLabel;
    ploscicaTabelaPrometaXminus1: TGridPanel;
    tabelaPromet_Mesec: TLabel;
    vpTabelaPromet_LetoXminus1: TLabel;
    tabelaPromet_Mesec1: TLabel;
    vpTabelaPromet_ProdajaXminus1_1: TEdit;
    tabelaPromet_Mesec2: TLabel;
    vpTabelaPromet_ProdajaXminus1_2: TEdit;
    tabelaPromet_Mesec3: TLabel;
    vpTabelaPromet_ProdajaXminus1_3: TEdit;
    tabelaPromet_Mesec4: TLabel;
    vpTabelaPromet_ProdajaXminus1_4: TEdit;
    tabelaPromet_Mesec5: TLabel;
    vpTabelaPromet_ProdajaXminus1_5: TEdit;
    tabelaPromet_Mesec6: TLabel;
    vpTabelaPromet_ProdajaXminus1_6: TEdit;
    tabelaPromet_Mesec7: TLabel;
    vpTabelaPromet_ProdajaXminus1_7: TEdit;
    tabelaPromet_Mesec8: TLabel;
    vpTabelaPromet_ProdajaXminus1_8: TEdit;
    tabelaPromet_Mesec9: TLabel;
    vpTabelaPromet_ProdajaXminus1_9: TEdit;
    tabelaPromet_Mesec10: TLabel;
    vpTabelaPromet_ProdajaXminus1_10: TEdit;
    tabelaPromet_Mesec11: TLabel;
    vpTabelaPromet_ProdajaXminus1_11: TEdit;
    tabelaPromet_Mesec12: TLabel;
    vpTabelaPromet_ProdajaXminus1_12: TEdit;
    tabelaPromet_Skupaj: TLabel;
    vpTabelaPromet_LetoXminus1Skupaj: TLabel;
    izPlanProdaje_Naslov: TLabel;
    izPlanProdaje_LetoXplus1: TLabel;
    izPlanProdaje_LetoXplus2: TLabel;
    izPlanProdaje_LetoXplus3: TLabel;
    izPlanProdaje_LetoXplus4: TLabel;
    vpPlanProdaje_RastXplus1: TTrackBar;
    izPlanProdaje_RastXplus1: TLabel;
    vpPlanProdaje_RastXplus2: TTrackBar;
    izPlanProdaje_RastXplus2: TLabel;
    vpPlanProdaje_RastXplus3: TTrackBar;
    izPlanProdaje_RastXplus3: TLabel;
    vpPlanProdaje_RastXplus4: TTrackBar;
    izPlanProdaje_RastXplus4: TLabel;
    ploscicaTabelaPrometaX: TGridPanel;
    vpTabelaPromet_LetoX: TLabel;
    vpTabelaPromet_ProdajaX_1: TEdit;
    vpTabelaPromet_ProdajaX_2: TEdit;
    vpTabelaPromet_ProdajaX_3: TEdit;
    vpTabelaPromet_ProdajaX_4: TEdit;
    vpTabelaPromet_ProdajaX_5: TEdit;
    vpTabelaPromet_ProdajaX_6: TEdit;
    vpTabelaPromet_ProdajaX_7: TEdit;
    vpTabelaPromet_ProdajaX_8: TEdit;
    vpTabelaPromet_ProdajaX_9: TEdit;
    vpTabelaPromet_ProdajaX_10: TEdit;
    vpTabelaPromet_ProdajaX_11: TEdit;
    vpTabelaPromet_ProdajaX_12: TEdit;
    vpTabelaPromet_LetoXSkupaj: TLabel;
    DelovniList4: TTabSheet;
    izNaslovDL4: TLabel;
    ploscicaTabelaOrganizacijskihVprasanj: TGridPanel;
    DelovniList5: TTabSheet;
    izNaslovDL5: TLabel;
    vpZavarovanjeDa: TCheckBox;
    ploscicaPodatkiOZavarovanju: TPanel;
    vpZavarovanjeDAKosmatiDobicek: TCheckBox;
    izTekstZVKosmatiDobicek: TLabel;
    vpZVKosmatiDobicek: TEdit;
    vpZavarovanjeDANastetiStroski: TCheckBox;
    izTekstStroski1: TLabel;
    ploscicaNastetiStroski: TGridPanel;
    izZavarovaniStroski1_1: TLabel;
    izZavarovaniStroski2_1: TLabel;
    izZavarovaniStroski1_2: TLabel;
    vpZavarovaniStroski2_2: TEdit;
    izZavarovaniStroski1_3: TLabel;
    vpZavarovaniStroski2_3: TEdit;
    izZavarovaniStroski1_4: TLabel;
    vpZavarovaniStroski2_4: TEdit;
    izZavarovaniStroski1_5: TLabel;
    vpZavarovaniStroski2_5: TEdit;
    izZavarovaniStroski1_6: TLabel;
    vpZavarovaniStroski2_6: TEdit;
    izTekstStroski2: TLabel;
    vpZVNastetiStroski: TEdit;
    izTekstMDJ: TLabel;
    izTekstZacetekZavarovanja: TLabel;
    vpMDJ: TComboBox;
    vpZacetekZavarovanja: TDateTimePicker;
    DelovniList6: TTabSheet;
    izNaslovDL6: TLabel;
    ploscicaMDJRezultat: TPanel;
    izTekstPriporocenaMDJ: TLabel;
    izPriporocenaMDJ: TLabel;
    ploscicaParametriMDJ: TGridPanel;
    izParametriMDJ_Naslov_1: TLabel;
    izParametriMDJ_Naslov_2: TLabel;
    izParametriMDJ_Naslov_3: TLabel;
    izParametriMDJ_Parameter1: TLabel;
    ploscicaParameter1_1: TPanel;
    vpParameter1_1: TTrackBar;
    izParameter1_1: TLabel;
    ploscicaParameter1_2: TPanel;
    vpParameter1_2: TTrackBar;
    izParameter1_2: TLabel;
    izParametriMDJ_Parameter2: TLabel;
    ploscicaParameter2_1: TPanel;
    vpParameter2_1: TTrackBar;
    izParameter2_1: TLabel;
    ploscicaParameter2_2: TPanel;
    vpParameter2_2: TTrackBar;
    izParameter2_2: TLabel;
    izParametriMDJ_Parameter3: TLabel;
    ploscicaParameter3_1: TPanel;
    vpParameter3_1: TTrackBar;
    izParameter3_1: TLabel;
    ploscicaParameter3_2: TPanel;
    vpParameter3_2: TTrackBar;
    izParameter3_2: TLabel;
    izParametriMDJ_Parameter4: TLabel;
    ploscicaParameter4_1: TPanel;
    vpParameter4_1: TTrackBar;
    izParameter4_1: TLabel;
    ploscicaParameter4_2: TPanel;
    vpParameter4_2: TTrackBar;
    izParameter4_2: TLabel;
    izParametriMDJ_Parameter5: TLabel;
    ploscicaParameter5_1: TPanel;
    vpParameter5_1: TTrackBar;
    izParameter5_1: TLabel;
    ploscicaParameter5_2: TPanel;
    vpParameter5_2: TTrackBar;
    izParameter5_2: TLabel;
    izParametriMDJ_Parameter6: TLabel;
    ploscicaParameter6_1: TPanel;
    vpParameter6_1: TTrackBar;
    izParameter6_1: TLabel;
    izParametriMDJ_PrazenParameter6_2: TLabel;
    izParametriMDJ_Parameter7: TLabel;
    ploscicaParameter7_1: TPanel;
    vpParameter7_1: TTrackBar;
    izParameter7_1: TLabel;
    izParametriMDJ_PrazenParameter7_2: TLabel;
    izNastavljanjeOsnovnihParametrov: TLabel;
    vpKorekcijskiFaktorji: TCheckBox;
    ploscicaMaksimalniPribitek: TPanel;
    vpMaksimalniPribitek: TTrackBar;
    izMaksimalniPribitek: TLabel;
    ploscicaKorekcijskiFaktorji: TGridPanel;
    izPloscicaKorekcijskiFaktorji_Naslov1: TLabel;
    izPloscicaKorekcijskiFaktorji_Naslov2: TLabel;
    izPloscicaKorekcijskiFaktorji_Naslov3: TLabel;
    izPloscicaKorekcijskiFaktorji_Naslov4: TLabel;
    izPloscicaKorekcijskiFaktorji_KF1_1: TLabel;
    izPloscicaKorekcijskiFaktorji_KF1_2: TLabel;
    izPloscicaKorekcijskiFaktorji_KF1_3: TLabel;
    ploscicaPloscicaKorekcijskiFaktor_1: TPanel;
    vpPloscicaKorekcijskiFaktorji_KFDrsnik1_4: TTrackBar;
    vpPloscicaKorekcijskiFaktorji_KFIzpis1_4: TLabel;
    izPloscicaKorekcijskiFaktorji_KF2_1: TLabel;
    izPloscicaKorekcijskiFaktorji_KF2_2: TLabel;
    izPloscicaKorekcijskiFaktorji_KF2_3: TLabel;
    ploscicaPloscicaKorekcijskiFaktor_2: TPanel;
    vpPloscicaKorekcijskiFaktorji_KFDrsnik2_4: TTrackBar;
    vpPloscicaKorekcijskiFaktorji_KFIzpis2_4: TLabel;
    izPloscicaKorekcijskiFaktorji_KF3_1: TLabel;
    izPloscicaKorekcijskiFaktorji_KF3_2: TLabel;
    izPloscicaKorekcijskiFaktorji_KF3_3: TLabel;
    ploscicaPloscicaKorekcijskiFaktor_3: TPanel;
    vpPloscicaKorekcijskiFaktorji_KFDrsnik3_4: TTrackBar;
    vpPloscicaKorekcijskiFaktorji_KFIzpis3_4: TLabel;
    izPloscicaKorekcijskiFaktorji_KF4_1: TLabel;
    izPloscicaKorekcijskiFaktorji_KF4_2: TLabel;
    izPloscicaKorekcijskiFaktorji_KF4_3: TLabel;
    ploscicaPloscicaKorekcijskiFaktor_4: TPanel;
    vpPloscicaKorekcijskiFaktorji_KFDrsnik4_4: TTrackBar;
    vpPloscicaKorekcijskiFaktorji_KFIzpis4_4: TLabel;
    izPloscicaKorekcijskiFaktorji_KF5_1: TLabel;
    izPloscicaKorekcijskiFaktorji_KF5_2: TLabel;
    izPloscicaKorekcijskiFaktorji_KF5_3: TLabel;
    ploscicaPloscicaKorekcijskiFaktor_5: TPanel;
    vpPloscicaKorekcijskiFaktorji_KFDrsnik5_4: TTrackBar;
    vpPloscicaKorekcijskiFaktorji_KFIzpis5_4: TLabel;
    DelovniList7: TTabSheet;
    izNaslovDL7: TLabel;
    ploscicaZVRezultat: TPanel;
    izTekstPriporoceniKD: TLabel;
    izTekstPriporocenaSKD: TLabel;
    izPriporoceniKD: TLabel;
    izPriporocenaSKD: TLabel;
    ploscicaPriporoceneZV: TPanel;
    izTekstLetoPriporoceneZV: TLabel;
    ploscicaLetoPriporoceneZV: TPanel;
    vpLetoPriporoceneZV: TTrackBar;
    izLetoPriporoceneZVmax: TLabel;
    izLetoPriporoceneZVmin: TLabel;
    ploscicaTabelaPriporoceneZV: TGridPanel;
    izPriporoceneZV_Naslov1: TLabel;
    izPriporoceneZV_Naslov2: TLabel;
    izPriporoceneZV_tekstZV12: TLabel;
    izPriporoceneZV_ZV12: TLabel;
    izPriporoceneZV_tekstZV18: TLabel;
    izPriporoceneZV_ZV18: TLabel;
    izPriporoceneZV_tekstZV24: TLabel;
    izPriporoceneZV_ZV24: TLabel;
    DelovniList8: TTabSheet;
    izNaslovDL8: TLabel;
    ploscicaAnalizeProdaje_Meseci: TGridPanel;
    ploscicaAnalizeProdaje_izMesec_Naslov: TLabel;
    ploscicaAnalizeProdaje_izMesec_1: TLabel;
    ploscicaAnalizeProdaje_izMesec_2: TLabel;
    ploscicaAnalizeProdaje_izMesec_3: TLabel;
    ploscicaAnalizeProdaje_izMesec_4: TLabel;
    ploscicaAnalizeProdaje_izMesec_5: TLabel;
    ploscicaAnalizeProdaje_izMesec_6: TLabel;
    ploscicaAnalizeProdaje_izMesec_7: TLabel;
    ploscicaAnalizeProdaje_izMesec_8: TLabel;
    ploscicaAnalizeProdaje_izMesec_9: TLabel;
    ploscicaAnalizeProdaje_izMesec_10: TLabel;
    ploscicaAnalizeProdaje_izMesec_11: TLabel;
    ploscicaAnalizeProdaje_izMesec_12: TLabel;
    ploscicaAnalizeProdaje_PrometXminus1: TGridPanel;
    ploscicaAnalizeProdaje_izPromet_NaslovXminus1: TLabel;
    ploscicaAnalizeProdaje_izPromet_1_1: TLabel;
    ploscicaAnalizeProdaje_izPromet_1_2: TLabel;
    ploscicaAnalizeProdaje_izPromet_1_3: TLabel;
    ploscicaAnalizeProdaje_izPromet_1_4: TLabel;
    ploscicaAnalizeProdaje_izPromet_1_5: TLabel;
    ploscicaAnalizeProdaje_izPromet_1_6: TLabel;
    ploscicaAnalizeProdaje_izPromet_1_7: TLabel;
    ploscicaAnalizeProdaje_izPromet_1_8: TLabel;
    ploscicaAnalizeProdaje_izPromet_1_9: TLabel;
    ploscicaAnalizeProdaje_izPromet_1_10: TLabel;
    ploscicaAnalizeProdaje_izPromet_1_11: TLabel;
    ploscicaAnalizeProdaje_izPromet_1_12: TLabel;
    ploscicaAnalizeProdaje_PrometX: TGridPanel;
    ploscicaAnalizeProdaje_izPromet_NaslovX: TLabel;
    ploscicaAnalizeProdaje_izPromet_2_1: TLabel;
    ploscicaAnalizeProdaje_izPromet_2_2: TLabel;
    ploscicaAnalizeProdaje_izPromet_2_3: TLabel;
    ploscicaAnalizeProdaje_izPromet_2_4: TLabel;
    ploscicaAnalizeProdaje_izPromet_2_5: TLabel;
    ploscicaAnalizeProdaje_izPromet_2_6: TLabel;
    ploscicaAnalizeProdaje_izPromet_2_7: TLabel;
    ploscicaAnalizeProdaje_izPromet_2_8: TLabel;
    ploscicaAnalizeProdaje_izPromet_2_9: TLabel;
    ploscicaAnalizeProdaje_izPromet_2_10: TLabel;
    ploscicaAnalizeProdaje_izPromet_2_11: TLabel;
    ploscicaAnalizeProdaje_izPromet_2_12: TLabel;
    ploscicaAnalizeProdaje_PrometXplus1: TGridPanel;
    ploscicaAnalizeProdaje_izPromet_NaslovXplus1: TLabel;
    ploscicaAnalizeProdaje_izPromet_3_1: TLabel;
    ploscicaAnalizeProdaje_izPromet_3_2: TLabel;
    ploscicaAnalizeProdaje_izPromet_3_3: TLabel;
    ploscicaAnalizeProdaje_izPromet_3_4: TLabel;
    ploscicaAnalizeProdaje_izPromet_3_5: TLabel;
    ploscicaAnalizeProdaje_izPromet_3_6: TLabel;
    ploscicaAnalizeProdaje_izPromet_3_7: TLabel;
    ploscicaAnalizeProdaje_izPromet_3_8: TLabel;
    ploscicaAnalizeProdaje_izPromet_3_9: TLabel;
    ploscicaAnalizeProdaje_izPromet_3_10: TLabel;
    ploscicaAnalizeProdaje_izPromet_3_11: TLabel;
    ploscicaAnalizeProdaje_izPromet_3_12: TLabel;
    ploscicaAnalizeProdaje_PrometXplus2: TGridPanel;
    ploscicaAnalizeProdaje_izPromet_NaslovXplus2: TLabel;
    ploscicaAnalizeProdaje_izPromet_4_1: TLabel;
    ploscicaAnalizeProdaje_izPromet_4_2: TLabel;
    ploscicaAnalizeProdaje_izPromet_4_3: TLabel;
    ploscicaAnalizeProdaje_izPromet_4_4: TLabel;
    ploscicaAnalizeProdaje_izPromet_4_5: TLabel;
    ploscicaAnalizeProdaje_izPromet_4_6: TLabel;
    ploscicaAnalizeProdaje_izPromet_4_7: TLabel;
    ploscicaAnalizeProdaje_izPromet_4_8: TLabel;
    ploscicaAnalizeProdaje_izPromet_4_9: TLabel;
    ploscicaAnalizeProdaje_izPromet_4_10: TLabel;
    ploscicaAnalizeProdaje_izPromet_4_11: TLabel;
    ploscicaAnalizeProdaje_izPromet_4_12: TLabel;
    ploscicaAnalizeProdaje_PrometXplus3: TGridPanel;
    ploscicaAnalizeProdaje_izPromet_NaslovXplus3: TLabel;
    ploscicaAnalizeProdaje_izPromet_5_1: TLabel;
    ploscicaAnalizeProdaje_izPromet_5_2: TLabel;
    ploscicaAnalizeProdaje_izPromet_5_3: TLabel;
    ploscicaAnalizeProdaje_izPromet_5_4: TLabel;
    ploscicaAnalizeProdaje_izPromet_5_5: TLabel;
    ploscicaAnalizeProdaje_izPromet_5_6: TLabel;
    ploscicaAnalizeProdaje_izPromet_5_7: TLabel;
    ploscicaAnalizeProdaje_izPromet_5_8: TLabel;
    ploscicaAnalizeProdaje_izPromet_5_9: TLabel;
    ploscicaAnalizeProdaje_izPromet_5_10: TLabel;
    ploscicaAnalizeProdaje_izPromet_5_11: TLabel;
    ploscicaAnalizeProdaje_izPromet_5_12: TLabel;
    ploscicaAnalizeProdaje_PrometXplus4: TGridPanel;
    ploscicaAnalizeProdaje_izPromet_NaslovXplus4: TLabel;
    ploscicaAnalizeProdaje_izPromet_6_1: TLabel;
    ploscicaAnalizeProdaje_izPromet_6_2: TLabel;
    ploscicaAnalizeProdaje_izPromet_6_3: TLabel;
    ploscicaAnalizeProdaje_izPromet_6_4: TLabel;
    ploscicaAnalizeProdaje_izPromet_6_5: TLabel;
    ploscicaAnalizeProdaje_izPromet_6_6: TLabel;
    ploscicaAnalizeProdaje_izPromet_6_7: TLabel;
    ploscicaAnalizeProdaje_izPromet_6_8: TLabel;
    ploscicaAnalizeProdaje_izPromet_6_9: TLabel;
    ploscicaAnalizeProdaje_izPromet_6_10: TLabel;
    ploscicaAnalizeProdaje_izPromet_6_11: TLabel;
    ploscicaAnalizeProdaje_izPromet_6_12: TLabel;
    DelovniList9: TTabSheet;
    izNaslovDL9: TLabel;
    ploscicaObstojeceZavarovanje: TGridPanel;
    izPloscicaObstojeceZavarovanje_Vprasanje1: TLabel;
    izPloscicaObstojeceZavarovanje_Odgovor1: TLabel;
    izPloscicaObstojeceZavarovanje_Semafor1: TImageControl;
    izPloscicaObstojeceZavarovanje_prazna1: TLabel;
    izPloscicaObstojeceZavarovanje_Vprasanje2: TLabel;
    izPloscicaObstojeceZavarovanje_Odgovor2: TLabel;
    izPloscicaObstojeceZavarovanje_Semafor2: TImageControl;
    izPloscicaObstojeceZavarovanje_prazna2: TLabel;
    izPloscicaObstojeceZavarovanje_Vprasanje3: TLabel;
    izPloscicaObstojeceZavarovanje_Odgovor3: TLabel;
    izPloscicaObstojeceZavarovanje_Semafor3: TImageControl;
    izPloscicaObstojeceZavarovanje_Semafor3b: TImageControl;
    izPloscicaObstojeceZavarovanje_Vprasanje4: TLabel;
    izPloscicaObstojeceZavarovanje_Odgovor4: TLabel;
    izPloscicaObstojeceZavarovanje_Semafor4: TImageControl;
    izPloscicaObstojeceZavarovanje_prazna4: TLabel;
    DelovniList10: TTabSheet;
    izNaslovDL10: TLabel;
    ploscicaSimulacijaSkode_PodatkiOZavarovanju: TPanel;
    izPodatkiOZavarovanju_Naslov: TLabel;
    izPodatkioZavarovanju_Od: TLabel;
    vpZavarovanjeOd: TDateTimePicker;
    izPodatkioZavarovanju_Do: TLabel;
    vpZavarovanjeDo: TDateTimePicker;
    izPodatkioZavarovanju_ZavarovalnaVsota: TLabel;
    izPodatkioZavarovanju_MaksimalnaDobaJamcenja: TLabel;
    izDatumFizicneSkode: TLabel;
    vpDatumFizicneSkode: TComboBox;
    ploscicaPodatkiZaSimulacijoSkode: TPanel;
    gPreracunaj: TSpeedButton;
    ploscicaNaslovDL0A: TPanel;
    izNaslov2_1_DL0A: TLabel;
    izNaslov2_2_DL0A: TLabel;
    izNaslov2_3_DL0A: TLabel;
    DelovniListiPodatkiZaSimulacijoSkod: TPageControl;
    WebTabSheet1: TTabSheet;
    ploscicaTabelaPodatkovOPrometu: TGridPanel;
    ploscicaPromet_Naslov1: TLabel;
    ploscicaPromet_Naslov2: TLabel;
    ploscicaPromet_Naslov3: TLabel;
    ploscicaPromet_Naslov4: TLabel;
    ploscicaPromet_Naslov5: TLabel;
    ploscicaPromet_Naslov6: TLabel;
    ploscicaPromet_Naslov7: TLabel;
    ploscicaPromet_Naslov8: TLabel;
    ploscicaPromet_Mesec1: TLabel;
    ploscicaPromet_Promet_1_1: TEdit;
    ploscicaPromet_Promet_2_1: TEdit;
    ploscicaPromet_Promet_3_1: TEdit;
    ploscicaPromet_Promet_4_1: TEdit;
    ploscicaPromet_Indeks_1_1: TEdit;
    ploscicaPromet_Indeks_2_1: TEdit;
    ploscicaPromet_Indeks_3_1: TEdit;
    ploscicaPromet_Mesec2: TLabel;
    ploscicaPromet_Promet_1_2: TEdit;
    ploscicaPromet_Promet_2_2: TEdit;
    ploscicaPromet_Promet_3_2: TEdit;
    ploscicaPromet_Promet_4_2: TEdit;
    ploscicaPromet_Indeks_1_2: TEdit;
    ploscicaPromet_Indeks_2_2: TEdit;
    ploscicaPromet_Indeks_3_2: TEdit;
    ploscicaPromet_Mesec3: TLabel;
    ploscicaPromet_Promet_1_3: TEdit;
    ploscicaPromet_Promet_2_3: TEdit;
    ploscicaPromet_Promet_3_3: TEdit;
    ploscicaPromet_Promet_4_3: TEdit;
    ploscicaPromet_Indeks_1_3: TEdit;
    ploscicaPromet_Indeks_2_3: TEdit;
    ploscicaPromet_Indeks_3_3: TEdit;
    ploscicaPromet_Mesec4: TLabel;
    ploscicaPromet_Promet_1_4: TEdit;
    ploscicaPromet_Promet_2_4: TEdit;
    ploscicaPromet_Promet_3_4: TEdit;
    ploscicaPromet_Promet_4_4: TEdit;
    ploscicaPromet_Indeks_1_4: TEdit;
    ploscicaPromet_Indeks_2_4: TEdit;
    ploscicaPromet_Indeks_3_4: TEdit;
    ploscicaPromet_Mesec5: TLabel;
    ploscicaPromet_Promet_1_5: TEdit;
    ploscicaPromet_Promet_2_5: TEdit;
    ploscicaPromet_Promet_3_5: TEdit;
    ploscicaPromet_Promet_4_5: TEdit;
    ploscicaPromet_Indeks_1_5: TEdit;
    ploscicaPromet_Indeks_2_5: TEdit;
    ploscicaPromet_Indeks_3_5: TEdit;
    ploscicaPromet_Mesec6: TLabel;
    ploscicaPromet_Promet_1_6: TEdit;
    ploscicaPromet_Promet_2_6: TEdit;
    ploscicaPromet_Promet_3_6: TEdit;
    ploscicaPromet_Promet_4_6: TEdit;
    ploscicaPromet_Indeks_1_6: TEdit;
    ploscicaPromet_Indeks_2_6: TEdit;
    ploscicaPromet_Indeks_3_6: TEdit;
    ploscicaPromet_Mesec7: TLabel;
    ploscicaPromet_Promet_1_7: TEdit;
    ploscicaPromet_Promet_2_7: TEdit;
    ploscicaPromet_Promet_3_7: TEdit;
    ploscicaPromet_Promet_4_7: TEdit;
    ploscicaPromet_Indeks_1_7: TEdit;
    ploscicaPromet_Indeks_2_7: TEdit;
    ploscicaPromet_Indeks_3_7: TEdit;
    ploscicaPromet_Mesec8: TLabel;
    ploscicaPromet_Promet_1_8: TEdit;
    ploscicaPromet_Promet_2_8: TEdit;
    ploscicaPromet_Promet_3_8: TEdit;
    ploscicaPromet_Promet_4_8: TEdit;
    ploscicaPromet_Indeks_1_8: TEdit;
    ploscicaPromet_Indeks_2_8: TEdit;
    ploscicaPromet_Indeks_3_8: TEdit;
    ploscicaPromet_Mesec9: TLabel;
    ploscicaPromet_Promet_1_9: TEdit;
    ploscicaPromet_Promet_2_9: TEdit;
    ploscicaPromet_Promet_3_9: TEdit;
    ploscicaPromet_Promet_4_9: TEdit;
    ploscicaPromet_Indeks_1_9: TEdit;
    ploscicaPromet_Indeks_2_9: TEdit;
    ploscicaPromet_Indeks_3_9: TEdit;
    ploscicaPromet_Mesec10: TLabel;
    ploscicaPromet_Promet_1_10: TEdit;
    ploscicaPromet_Promet_2_10: TEdit;
    ploscicaPromet_Promet_3_10: TEdit;
    ploscicaPromet_Promet_4_10: TEdit;
    ploscicaPromet_Indeks_1_10: TEdit;
    ploscicaPromet_Indeks_2_10: TEdit;
    ploscicaPromet_Indeks_3_10: TEdit;
    ploscicaPromet_Mesec11: TLabel;
    ploscicaPromet_Promet_1_11: TEdit;
    ploscicaPromet_Promet_2_11: TEdit;
    ploscicaPromet_Promet_3_11: TEdit;
    ploscicaPromet_Promet_4_11: TEdit;
    ploscicaPromet_Indeks_1_11: TEdit;
    ploscicaPromet_Indeks_2_11: TEdit;
    ploscicaPromet_Indeks_3_11: TEdit;
    ploscicaPromet_Mesec12: TLabel;
    ploscicaPromet_Promet_1_12: TEdit;
    ploscicaPromet_Promet_2_12: TEdit;
    ploscicaPromet_Promet_3_12: TEdit;
    ploscicaPromet_Promet_4_12: TEdit;
    ploscicaPromet_Indeks_1_12: TEdit;
    ploscicaPromet_Indeks_2_12: TEdit;
    ploscicaPromet_Indeks_3_12: TEdit;
    gVnosPopolnegaZastoja: TSpeedButton;
    gAvtomaticnoPolnjenje: TSpeedButton;
    WebTabSheet2: TTabSheet;
    ploscicaPovisaniStroski: TGridPanel;
    izPovisaniPoslovniStroskiOpis: TLabel;
    vpPovisaniPoslovniStroskiZnesek: TEdit;
    izResenPrometOpis: TLabel;
    vpResenPrometZnesek: TEdit;
    izPovisaniPoslovniStroskiObdobjeOpis: TLabel;
    vpPovisaniPoslovniStroskiObdobje: TComboBox;
    WebTabSheet3: TTabSheet;
    ploscicaPrihranki: TGridPanel;
    ploscicaPrihranki_Naslov1: TLabel;
    ploscicaPrihranki_Naslov2: TLabel;
    ploscicaPrihranki_Prihranek_1_1: TEdit;
    ploscicaPrihranki_Prihranek_2_1: TEdit;
    ploscicaPrihranki_Prihranek_1_2: TEdit;
    ploscicaPrihranki_Prihranek_2_2: TEdit;
    ploscicaPrihranki_Prihranek_1_3: TEdit;
    ploscicaPrihranki_Prihranek_2_3: TEdit;
    ploscicaPrihranki_Prihranek_1_4: TEdit;
    ploscicaPrihranki_Prihranek_2_4: TEdit;
    ploscicaPrihranki_Prihranek_1_5: TEdit;
    ploscicaPrihranki_Prihranek_2_5: TEdit;
    ploscicaPrihranki_Prihranek_1_6: TEdit;
    ploscicaPrihranki_Prihranek_2_6: TEdit;
    ploscicaPrihranki_Prihranek_1_7: TEdit;
    ploscicaPrihranki_Prihranek_2_7: TEdit;
    ploscicaPrihranki_Prihranek_1_8: TEdit;
    ploscicaPrihranki_Prihranek_2_8: TEdit;
    ploscicaPrihranki_Prihranek_1_9: TEdit;
    ploscicaPrihranki_Prihranek_2_9: TEdit;
    ploscicaPrihranki_Prihranek_1_10: TEdit;
    ploscicaPrihranki_Prihranek_2_10: TEdit;
    ploscicaPrihranki_PrihranekSkupajOpis: TEdit;
    ploscicaPrihranki_PrihranekSkupajZnesek: TEdit;
    izPrihrankiObdobjeOpis: TLabel;
    vpPrihrankiObdobje: TComboBox;
    ploscicaPrijava: TPanel;
    vpUporabniskoIme_Tekst: TLabel;
    vpUporabniskoIme: TEdit;
    vpGeslo_Tekst: TLabel;
    vpGeslo: TEdit;
    izPrijavaNaslov: TLabel;
    gPrijava: TSpeedButton;
    PovezavaServerGeslo: TXDataWebConnection;
    WebClientGeslo: TXDataWebClient;
    izUporabniskoImeNapaka: TLabel;
    izGesloNapaka: TLabel;
    ploscicaUporabnik: TPanel;
    izUporabnikTekst: TLabel;
    izPodjetjeTekst: TLabel;
    izUporabnik: TLabel;
    PovezavaServerPodatki: TXDataWebConnection;
    WebClientPodatki: TXDataWebClient;
    PovezavaWSPopolniPreracun: TXDataWebConnection;
    WebClientWSPopolniIzracun: TXDataWebClient;
    PovezavaWSDelniIzracuni: TXDataWebConnection;
    WebClientWSDelniIzracuni: TXDataWebClient;
    vpOpazovanoLeto: TEdit;
    DelovniList0B: TTabSheet;
    gZacniNovProjekt: TSpeedButton;
    gNaloziProjekt: TSpeedButton;
    gPrekiniDelo: TSpeedButton;
    ploscicaNaslovDL0B: TPanel;
    izNaslov2_1_DL0B: TLabel;
    izNaslov2_2_DL0B: TLabel;
    izNaslov2_3_DL0B: TLabel;
    obvestilo: TMessageDlg;
    GoogleReCaptcha: TGoogleReCaptcha;
    gVnesiKrivuljoZastoja: TSpeedButton;
    menuKrivuljaZastoja: TPopupMenu;
    mnKrivulja1: TMenuItem;
    mnKrivulja2: TMenuItem;
    mnKrivulja3: TMenuItem;
    menuPopolniZastoj: TPopupMenu;
    mn3: TMenuItem;
    mn4: TMenuItem;
    mn5: TMenuItem;
    mn6: TMenuItem;
    mn7: TMenuItem;
    mn8: TMenuItem;
    mn9: TMenuItem;
    mn10: TMenuItem;
    mn11: TMenuItem;
    mn12: TMenuItem;
    WebScrollBoxDL0A: TScrollBox;
    WebScrollBoxDL0B: TScrollBox;
    WebScrollBoxDL1: TScrollBox;
    WebScrollBoxDL2: TScrollBox;
    WebScrollBoxDL3: TScrollBox;
    WebScrollBoxDL4: TScrollBox;
    WebScrollBox2: TScrollBox;
    WebScrollBoxDL6: TScrollBox;
    WebScrollBoxDL7: TScrollBox;
    WebScrollBoxDL8: TScrollBox;
    WebScrollBoxDL9: TScrollBox;
    WebScrollBoxDL10: TScrollBox;
    slikaJezikSLO: TImageControl;
    slikaJezikANG: TImageControl;
    PovezavaServerShranjevanje: TXDataWebConnection;
    WebClientShranjevanje: TXDataWebClient;
    izVerzija: TLabel;
    izOrganizacija_mera11_5: TLabel;
    izOrganizacija_mera11_6: TLabel;
    izOrganizacija_mera12: TLabel;
    izOrganizacija_mera13: TLabel;
    izOrganizacija_MeraPrazen11_4: TLabel;
    izOrganizacija_St12: TLabel;
    izOrganizacija_St13: TLabel;
    izOrganizacija_StPrazen11_5: TLabel;
    izOrganizacija_StPrazen11_6: TLabel;
    ploscicaOrganizacija_Odgovor11_4: TPanel;
    vpOrganizacija_OdgovorDa11_4: TRadioButton;
    vpOrganizacija_OdgovorNe11_4: TRadioButton;
    ploscicaOrganizacija_Odgovor4: TPanel;
    vpOrganizacija_OdgovorDa4: TRadioButton;
    vpOrganizacija_OdgovorNe4: TRadioButton;
    ploscicaOrganizacija_Odgovor5: TPanel;
    vpOrganizacija_OdgovorDa5: TRadioButton;
    vpOrganizacija_OdgovorNe5: TRadioButton;
    ploscicaOrganizacija_Odgovor6: TPanel;
    vpOrganizacija_OdgovorDa6: TRadioButton;
    vpOrganizacija_OdgovorNe6: TRadioButton;
    ploscicaOrganizacija_Odgovor7: TPanel;
    vpOrganizacija_OdgovorDa7: TRadioButton;
    vpOrganizacija_OdgovorNe7: TRadioButton;
    ploscicaOrganizacija_Odgovor8: TPanel;
    vpOrganizacija_OdgovorDa8: TRadioButton;
    vpOrganizacija_OdgovorNe8: TRadioButton;
    vpOrganizacija_Odgovor1: TTrackBar;
    vpOrganizacija_Odgovor2: TTrackBar;
    izOrganizacija_St1: TLabel;
    izOrganizacija_Vprasanje1: TMemo;
    izOrganizacija_mera1: TLabel;
    izOrganizacija_St2: TLabel;
    izOrganizacija_Vprasanje2: TMemo;
    izOrganizacija_mera2: TLabel;
    izOrganizacija_St3: TLabel;
    izOrganizacija_Vprasanje3: TMemo;
    ploscicaOrganizacija_Odgovor3: TPanel;
    vpOrganizacija_OdgovorDa3: TRadioButton;
    vpOrganizacija_OdgovorNe3: TRadioButton;
    izOrganizacija_mera3: TLabel;
    izOrganizacija_St4: TLabel;
    izOrganizacija_Vprasanje4: TMemo;
    izOrganizacija_mera4: TLabel;
    izOrganizacija_St5: TLabel;
    izOrganizacija_Vprasanje5: TMemo;
    izOrganizacija_mera5: TLabel;
    izOrganizacija_St6: TLabel;
    izOrganizacija_Vprasanje6: TMemo;
    izOrganizacija_mera6: TLabel;
    izOrganizacija_St7: TLabel;
    izOrganizacija_Vprasanje7: TMemo;
    izOrganizacija_mera7: TLabel;
    izOrganizacija_St8: TLabel;
    izOrganizacija_Vprasanje8: TMemo;
    izOrganizacija_mera8: TLabel;
    izOrganizacija_St9: TLabel;
    izOrganizacija_Vprasanje9: TMemo;
    vpOrganizacija_Odgovor9: TTrackBar;
    izOrganizacija_mera9: TLabel;
    izOrganizacija_St10: TLabel;
    izOrganizacija_Vprasanje10: TMemo;
    vpOrganizacija_Odgovor10: TTrackBar;
    izOrganizacija_mera10: TLabel;
    izOrganizacija_St11: TLabel;
    izOrganizacija_Vprasanje11: TMemo;
    ploscicaOrganizacija_Odgovor11_1: TPanel;
    vpOrganizacija_OdgovorDa11_1: TRadioButton;
    vpOrganizacija_OdgovorNe11_1: TRadioButton;
    izOrganizacija_OdgovorPrazen11: TLabel;
    izOrganizacija_MeraPrazen11: TLabel;
    izOrganizacija_StPrazen11_1: TLabel;
    izOrganizacija_Vprasanje11_1: TMemo;
    izOrganizacija_MeraPrazen11_1: TLabel;
    izOrganizacija_StPrazen11_2: TLabel;
    izOrganizacija_Vprasanje11_2: TMemo;
    vpOrganizacija_Odgovor11_2: TTrackBar;
    izOrganizacija_mera11_2: TLabel;
    izOrganizacija_StPrazen11_3: TLabel;
    izOrganizacija_Vprasanje11_3: TMemo;
    vpOrganizacija_Odgovor11_3: TTrackBar;
    izOrganizacija_mera11_3: TLabel;
    izOrganizacija_StPrazen11_4: TLabel;
    izOrganizacija_Vprasanje11_4: TMemo;
    izOrganizacija_Vprasanje11_5: TMemo;
    vpOrganizacija_Odgovor11_5: TTrackBar;
    izOrganizacija_Vprasanje11_6: TMemo;
    vpOrganizacija_Odgovor11_6: TTrackBar;
    izOrganizacija_Vprasanje12: TMemo;
    vpOrganizacija_Odgovor12: TTrackBar;
    izOrganizacija_Vprasanje13: TMemo;
    vpOrganizacija_Odgovor13: TComboBox;
    izZavarovalnaVsota: TEdit;
    izMaksimalnaDobaJamcenja: TComboBox;
    logo_ModelBI_DL0A: TImageControl;
    logo_ModelBI_DL0B: TImageControl;
    izTekstPremija: TLabel;
    vpPremija: TEdit;
    izPotekLicenceTekst: TLabel;
    izPodjetje: TLabel;
    izPotekLicence: TDateTimePicker;
    izPodatkioZavarovanju_Premija: TLabel;
    izPremija: TEdit;
    PovezavaServerJezik: TXDataWebConnection;
    WebClientJezik: TXDataWebClient;
    izMDJ: TLabel;
    izMaksimalnaDobaJamcenjaTekst: TLabel;
    ploscicaGrafMDJ: TPanel;
    GrafMDJ: TGoogleChart;
    ploscicaGrafikaMDJ: TPanel;
    izGrafikaMDJ_DA: TLabel;
    izGrafikaMDJ_NE: TLabel;
    vpGrafikaMDJ: TTrackBar;
    izGraficniPrikazMDJ: TLabel;
    ploscicaGrafikaMDJ_osnovni: TPanel;
    GrafMesecniTrendi: TGoogleChart;
    ListiProdajnihTrendov: TPageControl;
    LPT1_Tabela: TTabSheet;
    LPT2_MesecniGraf: TTabSheet;
    LPT3_LetniGraf: TTabSheet;
    GrafLetniTrendi: TGoogleChart;
    GrafSimulacijaSkode: TGoogleChart;
    ploscicaGrafikaSkode: TPanel;
    izGrafikaSkode_DA: TLabel;
    izGrafikaSkode_NE: TLabel;
    vpGrafikaSkode: TTrackBar;
    izGraficniPrikazSimulacijaSkode: TLabel;
    ploscicaGrafSimulacijaSkode: TPanel;
    ploscicaGlavniMeni: TPanel;
    mnGlavniMeni: TMainMenu;
    mnNovProjekt: TMenuItem;
    mnOdpriProjekt: TMenuItem;
    mnShraniProjekt: TMenuItem;
    mnOsveziAnalize: TMenuItem;
    mnOstalo: TMenuItem;
    mnZakljuciDelo: TMenuItem;
    mnNastavitve: TMenuItem;
    N4: TMenuItem;
    mnOOrodju: TMenuItem;
    N5: TMenuItem;
    mnPomoc: TMenuItem;
    mnOsveziPrivzeteVrednosti: TMenuItem;
    N6: TMenuItem;
    mnOsveziDejanskeVrednosti: TMenuItem;
    ploscicaPrvaStran: TPanel;
    slikaBrowser1: TImageControl;
    slikaBrowser2: TImageControl;
    slikaBrowser3: TImageControl;
    slikaBrowser4: TImageControl;
    izBrskalniki: TLabel;
    izSpletnaStran: TLinkLabel;
    gShraniSkodo: TSpeedButton;
    gNaloziSkodo: TSpeedButton;
    PovezavaServerSkode: TXDataWebConnection;
    WebClientSkode: TXDataWebClient;
    izVerzijaPrijava: TLabel;
    procedure gZakljuciClick(Sender: TObject);
    procedure izborVhodniPodatkiChange(Sender: TObject);
    procedure izborAnalizeChange(Sender: TObject);
    procedure ploscicaWebLoginClick(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    procedure vpVnosFRPKeyPress(Sender: TObject; var Key: Char);
    procedure vpVnosFRPExit(Sender: TObject);
    procedure vpTabelaPromet_ProdajaExit(Sender: TObject);
    procedure vpOpazovanoLeto_staraChange(Sender: TObject);
    procedure vpOrganizacija_OdgovorMontazaChange(Sender: TObject);
    procedure vpOrganizacija_Odgovor3Click(Sender: TObject);
    procedure vpOrganizacija_Odgovor9Change(Sender: TObject);
    procedure vpOrganizacija_Odgovor10Change(Sender: TObject);
    procedure vpOrganizacija_Odgovor11IzborClick(Sender: TObject);
    procedure vpOrganizacija_Odgovor11DelezZalogChange(Sender: TObject);
    procedure vpOrganizacija_Odgovor11KolicinaZalogChange(Sender: TObject);
    procedure vpOrganizacija_Odgovor12Change(Sender: TObject);
    procedure vpZavarovanjeDaClick(Sender: TObject);
    procedure vpZavarovanjeDAKosmatiDobicekClick(Sender: TObject);
    procedure vpZavarovanjeDANastetiStroskiClick(Sender: TObject);
    procedure vpZVKosmatiDobicekExit(Sender: TObject);
    procedure vpZavarovaniStroskiSestevekExit(Sender: TObject);
    procedure vpPlanProdaje_RastChange(Sender: TObject);
    procedure vpLetoPriporoceneZVChange(Sender: TObject);
    procedure vpKorekcijskiFaktorjiClick(Sender: TObject);
    procedure vpMaksimalniPribitekChange(Sender: TObject);
    procedure vpParameter1_1Change(Sender: TObject);
    procedure vpPloscicaKorekcijskiFaktorji_KFDrsnik(Sender: TObject);
    procedure izborSimulacijeSkodChange(Sender: TObject);
    procedure ploscicaPrihranki_PrihranekExit(Sender: TObject);
    procedure OblikovanjeStevilkeExit(Sender: TObject);
    procedure vpDatumFizicneSkodeChange(Sender: TObject);
    procedure gVnosPopolnegaZastojaClick(Sender: TObject);
    procedure gAvtomaticnoPolnjenjeClick(Sender: TObject);
    procedure gPreracunajClick(Sender: TObject);
    procedure ploscicaPromet_Promet_OznacevanjeIzpadaDblClick(Sender: TObject);
    procedure gPrijavaClick(Sender: TObject);
    procedure PovezavaServerGesloConnect(Sender: TObject);
    procedure PovezavaServerGesloError(Error: TXDataWebConnectionError);
    procedure PovezavaServerPodatkiError(Error: TXDataWebConnectionError);
    procedure PovezavaServerPodatkiConnect(Sender: TObject);
    procedure PovezavaWSPopolniPreracunError(Error: TXDataWebConnectionError);
    procedure PovezavaWSPopolniPreracunConnect(Sender: TObject);
    procedure PovezavaWSDelniIzracuniError(Error: TXDataWebConnectionError);
    procedure PovezavaWSDelniIzracuniConnect(Sender: TObject);
    procedure obvestiloButtonClick(Sender: TObject);
    procedure gNaloziProjektClick(Sender: TObject);
    procedure gZacniNovProjektClick(Sender: TObject);
    procedure vpZacetekZavarovanjaChange(Sender: TObject);
    procedure gVnesiKrivuljoZastojaClick(Sender: TObject);
    procedure mnKrivulja1Click(Sender: TObject);
    procedure mn3Click(Sender: TObject);
    procedure mnPrivzeteVrednostiClick(Sender: TObject);
    procedure PovezavaServerShranjevanjeError(Error: TXDataWebConnectionError);
    procedure PovezavaServerShranjevanjeConnect(Sender: TObject);
    procedure vpZavarovanjeOdChange(Sender: TObject);
    procedure OorodjuClick(Sender: TObject);
    procedure NastavitveClick(Sender: TObject);
    procedure PovezavaServerJezikError(Error: TXDataWebConnectionError);
    procedure PovezavaServerJezikConnect(Sender: TObject);
    procedure slikaJezikSLOClick(Sender: TObject);
    procedure slikaJezikANGClick(Sender: TObject);
    procedure vpGrafikaMDJChange(Sender: TObject);
    procedure ListiProdajnihTrendovChange(Sender: TObject);
    procedure vpGrafikaSkodeChange(Sender: TObject);
    procedure DelovniListiPodatkiZaSimulacijoSkodChange(Sender: TObject);
    procedure mnZakljuciDeloClick(Sender: TObject);
    procedure mnNastavitveClick(Sender: TObject);
    procedure mnOOrodjuClick(Sender: TObject);
    procedure mnOsveziPrivzeteVrednostiClick(Sender: TObject);
    procedure mnOdpriProjektClick(Sender: TObject);
    procedure mnShraniProjektClick(Sender: TObject);
    procedure mnNovProjektClick(Sender: TObject);
    procedure WebFormResize(Sender: TObject);
    procedure PovezavaServerSkodeError(Error: TXDataWebConnectionError);
    procedure PovezavaServerSkodeConnect(Sender: TObject);
    procedure gShraniSkodoClick(Sender: TObject);
    procedure gNaloziSkodoClick(Sender: TObject);
    procedure mnPomocClick(Sender: TObject);
  public
    { Public declarations }
  private
    { Private declarations }
  protected procedure LoadDFMValues; override; end;

var
  fmOsnovniKontroler: TfmOsnovniKontroler;
  arhivVrednostiZadnjeSimulacije : TArhivVrednostiZadnjeSimulacije;
  arhivIzborovSimulacije : TArhivIzborovSimulacije;
  arhivVrednostiZacetneSimulacije : TArhivVrednostiZacetneSimulacije;
  izborKalkulacije : Integer;    // uporablja se tudi za preverjanje, katera kalkulacija je trenutno aktivna (pri shranjevanju)
  kosmatiDobicek : Real;
  rast1, rast2, rast3, rast4 : Real;
  parameter1_1, parameter1_2 : Real;
  parameter2_1, parameter2_2 : Real;
  parameter3_1, parameter3_2 : Real;
  parameter4_1, parameter4_2 : Real;
  parameter5_1, parameter5_2 : Real;
  parameter6_1 : Real;
  parameter7_1 : Real;
  korFaktor1, korFaktor2, korFaktor3, korFaktor4, korfaktor5 : Real;
  maxPribitek : Real;
  korigiranMDJ : Integer;
  letoZV : Integer;
  zvObstojeca : Real;
  mdjObstojeca : Integer;
  mesecniPrometXminus1_1, mesecniPrometXminus1_2, mesecniPrometXminus1_3, mesecniPrometXminus1_4 : Real;
  mesecniPrometXminus1_5, mesecniPrometXminus1_6, mesecniPrometXminus1_7, mesecniPrometXminus1_8 : Real;
  mesecniPrometXminus1_9, mesecniPrometXminus1_10, mesecniPrometXminus1_11, mesecniPrometXminus1_12 : Real;
  mesecniPrometX_1, mesecniPrometX_2, mesecniPrometX_3, mesecniPrometX_4 : Real;
  mesecniPrometX_5, mesecniPrometX_6, mesecniPrometX_7, mesecniPrometX_8 : Real;
  mesecniPrometX_9, mesecniPrometX_10, mesecniPrometX_11, mesecniPrometX_12 : Real;
  promet : Real;
  zalogeZacetek, zalogeKonec : Real;
  internoProizvedeniDodatki : Real;
  stroskiSurovin, stroskimateriala, stroskiEnergije, stroskitrgovskegaBlaga, stroskiStoritev, drugiStroski : Real;
  prejeteZavarovalnine : Real;

  posiljatelj : Integer;    // spremenljivka, ki zagonu web servisa pove, kdo (kateri izračun) jo je zahteval  ( 1 =  izračuna ZV , 2 = preračun MDJ , 3 = preračun kosmatega dobička , 4 = preračun proemta X in Xminus1 , 5 = sprememba prihrankov, 0 = prazno)

  prometPlanVZastojuM1, prometPlanVZastojuM2, prometPlanVZastojuM3, prometPlanVZastojuM4, prometPlanVZastojuM5, prometPlanVZastojuM6, prometPlanVZastojuM7, prometPlanVZastojuM8, prometPlanVZastojuM9, prometPlanVZastojuM10, prometPlanVZastojuM11, prometPlanVZastojuM12 : Real;
  prometRealizacijaVZastojuM1, prometRealizacijaVZastojuM2, prometRealizacijaVZastojuM3, prometRealizacijaVZastojuM4, prometRealizacijaVZastojuM5, prometRealizacijaVZastojuM6, prometRealizacijaVZastojuM7, prometRealizacijaVZastojuM8, prometRealizacijaVZastojuM9, prometRealizacijaVZastojuM10, prometRealizacijaVZastojuM11, prometRealizacijaVZastojuM12 : Real;
  planiranPrometvZastojuM1, planiranPrometvZastojuM2, planiranPrometvZastojuM3, planiranPrometvZastojuM4, planiranPrometvZastojuM5, planiranPrometvZastojuM6, planiranPrometvZastojuM7, planiranPrometvZastojuM8, planiranPrometvZastojuM9, planiranPrometvZastojuM10, planiranPrometvZastojuM11, planiranPrometvZastojuM12 : Real;
  prometPredSkodoM1, prometPredSkodoM2, prometPredSkodoM3, prometPredSkodoM4, prometPredSkodoM5, prometPredSkodoM6, prometPredSkodoM7, prometPredSkodoM8, prometPredSkodoM9, prometPredSkodoM10, prometPredSkodoM11, prometPredSkodoM12 : Real ;
  resenPromet, povisaniStroski, delezKosmategaDobicka, prijavljeniPrihranki, dejanskaZV, rastVisina : Real;
  mesecevSkode, dogovorjenaMDJ, obdobjePovisanihStroskov,	obdobjePrihrankov, rastNacin, posiljateljIzracunSkode : Integer;

  posiljateljObvestilo : Integer; // spremeljivka, ki zagonu obvestila pove, kdo jo je zahteval (1 - gumb NovProjekt, 2 = gumb Odpriprojekt)
  jezik : String;  // spremnljivka, ki pove web servisu, kateri jezik je izbran

  // Jezikovne spremenljivke
  LfmLetoPodatkov_izLetoPodatkov_Naslov, LfmLetoPodatkov_gPotrdi, LfmLetoPodatkov_gPreklici : String;
  LfmOdpriKalkulacijo_ploscicaNaslov, LfmOdpriKalkulacijo_gIzberi, LfmOdpriKalkulacijo_gIzbrisi, LfmOdpriKalkulacijo_gPreklici : string;
  LfmVizitka_izNaslov, LfmVizitka_izFirma, LfmVizitka_izElektronskaPosta, LfmVizitka_izSpletnaStran, LfmVizitka_izSubvencija : string;

  LjezikDL7_izTekstPriporoceniKD, LjezikDL7_izTekstPriporocenaSKD, LjezikDL7_izTekstLetoPriporoceneZV : string;

  LjezikNacinZavarovanja_KD, LjezikNacinZavarovanja_NS, LjezikNacinZavarovanja_NP : String;

  LjezikNaslovDL10, LjezikNaslovDL10simulirano : String;

  LjezikSkoda_Naslov, LjezikSkoda_gumbIzhod, LjezikSkoda_napisKoraki, LjezikSkoda_gumbSimulacije : String;

  LjezikSkodaK1_Naslov, LjezikSkodaK1_TabelaRealizacijaX, LjezikSkodaK1_TabelaPlan, LjezikSkodaK1_TabelaRealizacijaXplus1, LjezikSkodaK1_TabelaRazlika : String;
  LjezikSkodaK1_TabelaKritje, LjezikSkodaK1_IzpadPrometa, LjezikSkodaK1_ZavarovaniIzpad, LjezikSkodaK1_NepokritiStroski1, LjezikSkodaK1_NepokritiStroski2 : String;

  LjezikSkodaK2_Naslov, LjezikSkodaK2_PovisaniStroski, LjezikSkodaK2_ResenPromet, LjezikSkodaK2_UpraviceniStroski : String;

  LjezikSkodaK3_Naslov, LjezikSkodaK3_TabelaVrstaPrihranka, LjezikSkodaK3_TabelaVisinaPrihranka, LjezikSkodaK3_TabelaUpravicenost, LjezikSkodaK3_TabelaOdstotek : String;
  LjezikSkodaK3_PrijavljeniPrihranki, LjezikSkodaK3_DejanskiPrihranki : String;

  LjezikSkodaK4_Naslov, LjezikSkodaK4_TabelaMeseci, LjezikSkodaK4_TabelaPrometPoMesecih, LjezikSkodaK4_TabelaPrometSkupaj : String;
  LjezikSkodaK4_OsnovaZaLetniPromet, LjezikSkodaK4_Rast, LjezikSkodaK4_LetniPromet, LjezikSkodaK4_PotrebnaZV, LjezikSkodaK4_DejanskaZV, LjezikSkodaK4_Podzavarovanje, LjezikSkodaK4_PodzavarovanjeIzpis : String;

  LjezikSkodaK5_Naslov, LjezikSkodaK5_Izpad, LjezikSkodaK5_PovisaniStroski, LjezikSkodaK5_Prihranki, LjezikSkodaK5_Podzavarovanje, LjezikSkodaK5_Zavarovalnina, LjezikSkodaK5_DejanskaSkoda : String;

  LjezikSimulacija_Naslov, LjezikSimulacija_ZV, LjezikSimulacija_MDJ, LjezikSimulacija_ObdobjeResenegaPrometa, LjezikSimulacija_ObdobjePrihrankov, LjezikSimulacija_PricakovanaRast, LjezikSimulacija_gSpremeni, LjezikSimulacija_gPonastavi, LjezikSimulacija_gPreklici : String;

  LjezikUporabnik_Naslov, LjezikUporabnik_Uporabnik, LjezikUporabnik_Podjetje, LjezikUporabnik_Licenca, LjezikUporabnik_SpremembaGeslaNaslov, LjezikUporabnik_StaroGeslo, LjezikUporabnik_NovoGeslo1, LjezikUporabnik_NovoGeslo2, LjezikUporabnik_gSpremeni : String;
  LjezikUporabnik_Opozorilo1, LjezikUporabnik_Opozorilo2, LjezikUporabnik_Opozorilo3, LjezikUporabnik_Opozorilo4 : String;

  LjezikSimulacija_nogaMDJ, LjezikSimulacija_nogaZV, LjezikSimulacija_nogaPrihranki, LjezikSimulacija_nogaPovisaniStroski, LjezikSimulacija_nogaRast, LjezikSimulacija_nogaPremija : String;
  LjezikSimulacija_nogaD, LjezikSimulacija_nogaS : String;

  LjezikDa, LjezikNe : String;

  LjezikLeto, LjezikMesec : String;
  LjezikImeMeseca : array[1..12] of String;
  LjezikMera_Mesec, LjezikMera_Izmena, LjezikSteviloMesecev : String;

  LjezikObvestilo_ServerShranjevanje_01, LjezikObvestilo_ServerShranjevanje_02, LjezikObvestilo_ServerShranjevanje_03, LjezikObvestilo_ServerShranjevanje_04 : String;
  LjezikObvestilo_ServerShranjevanje_05, LjezikObvestilo_ServerShranjevanje_06, LjezikObvestilo_ServerShranjevanje_07, LjezikObvestilo_ServerShranjevanje_08, LjezikObvestilo_ServerShranjevanje_09 : String;

  LjezikObvestilo_ServerNiPovezave_01, LjezikObvestilo_ServerNiPovezave_02, LjezikObvestilo_WebServis_Skoda, LjezikObvestilo_WebServis_Premija : String;

  LjezikObvestilo_gumbSkode, LjezikObvestilo_gumbSkode2, LjezikObvestilo_ReCaptcha_01, LjezikObvestilo_ReCaptcha_02, LjezikObvestilo_gumbShraniProjekt, LjezikObvestilo_IzborSimulacijaSkode : String;
  LjezikObvestilo_Licenca_01, LjezikObvestilo_Licenca_02, LjezikObvestilo_Licenca_03, LjezikObvestilo_ServerPoizvedba, LjezikObvestilo_WebServisi, LjezikObvestilo_NalaganjePodatkov : String;

  LjezikObvestilo_ServerBrisanje_01, LjezikObvestilo_ServerBrisanje_02 : String;

  LjezikObvestiloBrisanje, LjezikObvestilo_Tip, LjezikGrafObvestilo : String;

  LjezikObvestilo_ServerSkode_01, LjezikObvestilo_ServerSkode_02, LjezikObvestilo_ServerSkode_03, LjezikObvestilo_ServerSkode_04, LjezikObvestilo_ServerSkode_05, LjezikObvestilo_ServerSkode_06, LjezikObvestilo_ServerSkode_07, LjezikObvestilo_ServerSkode_08, LjezikObvestilo_ServerSkode_09 : String;

  LjezikObvestilo_mnOsveziAnalize_01, LjezikObvestilo_mnOsveziAnalize_02, LjezikObvestilo_mnOsveziAnalize_03 : String;

  Ljezik_Hint_Semafor_IzpadPrometa, Ljezik_Hint_Semafor_ZV, Ljezik_Hint_Semafor_Zavarovalnina1, Ljezik_Hint_Semafor_Zavarovalnina2, Ljezik_Hint_Semafor_Zavarovalnina3 : String;

  LjezikGrafMDJ_Skoda, LjezikGrafMDJ_Ciscenje, LjezikGrafMDJ_Dokumentacija, LjezikGrafMDJ_Gradnja, LjezikGrafMDJ_DobavaStrojev, LjezikGrafMDJ_MontazaStrojev, LjezikGrafMDJ_PolneZmogljivosti, LjezikGrafMDJ_VarnostniDodatek : String;

  izpad : array[1..12] of Integer;
  izpadZnesek : array[1..12] of Real;
  prihranki : array[1..10] of Integer;
  prihrankiOpis : array[1..10] of String;
  prihrankiZnesek : array[1..10] of Real;


implementation

{$R *.dfm}


procedure CelotnoOsvezevanjeIzracunov;  // Procedura Osveževanje z dejanskimi podatki
var
    izracunanaVrednost : Real;
    i, j, k : Integer;
    zapis2 : TLabel;
    zapis1 : TEdit;
    rast : TTrackBar;
begin

  try
    with fmOsnovniKontroler do begin

     // POLJNJENJE SPREMENLJIVK ZA WEB SERVIS - bere iz vnosov na ekranu
     // Polnjenje spremenljivk za preračun MDJ (ki se nato pošljejo v preračun web servisu) - množeno s 100, da ni decimalk pri prenosu - kjer so decimalke je pomnoženo s 100
     parameter1_1 := vpParameter1_1.Position / 4 * 100;
     parameter1_2 := vpParameter1_2.Position / 4 * 100;
     parameter2_1 := vpParameter2_1.Position / 4 * 100;
     parameter2_2 := vpParameter2_2.Position / 4 * 100;
     parameter3_1 := vpParameter3_1.Position / 4 * 100;
     parameter3_2 := vpParameter3_2.Position / 4 * 100;
     parameter4_1 := vpParameter4_1.Position / 4 * 100;
     parameter4_2 := vpParameter4_2.Position / 4 * 100;
     parameter5_1 := vpParameter5_1.Position / 4 * 100;
     parameter5_2 := vpParameter5_2.Position / 4 * 100;
     parameter6_1 := vpParameter6_1.Position / 4 * 100;
     parameter7_1 := vpParameter7_1.Position / 4 * 100;
     korFaktor1 := vpPloscicaKorekcijskiFaktorji_KFDrsnik1_4.Position / 4 * 100;
     korFaktor2 := vpPloscicaKorekcijskiFaktorji_KFDrsnik2_4.Position / 4 * 100;
     korFaktor3 := vpPloscicaKorekcijskiFaktorji_KFDrsnik3_4.Position / 4 * 100;
     korFaktor4 := vpPloscicaKorekcijskiFaktorji_KFDrsnik4_4.Position / 4 * 100;
     korFaktor5 := vpPloscicaKorekcijskiFaktorji_KFDrsnik5_4.Position / 4 * 100;
     maxPribitek := vpMaksimalniPribitek.Position;
      if vpKorekcijskiFaktorji.Checked = true then    // polnjenje spremnljivke z vrednostjo ali je izbran osnovni ali korigiran izračun MDJ
       korigiranMDJ := 1
     else
      korigiranMDJ := 0;
     rast1 := vpPlanProdaje_RastXplus1.Position / 10 * 100;
     rast2 := vpPlanProdaje_RastXplus2.Position / 10 * 100;
     rast3 := vpPlanProdaje_RastXplus3.Position / 10 * 100;
     rast4 := vpPlanProdaje_RastXplus4.Position / 10 * 100;
     if vpLetoPriporoceneZV.Position = vpLetoPriporoceneZV.Max then
        letoZV := 2       // Če je izračun za leto MIN
      else
        letoZV := 1;      // Če je izračun za leto MAX

     promet := StrToFloat(StringReplace(vpVnosFRPPromet.Text, '.', '', [rfReplaceAll, rfIgnoreCase])) * 100;
     zalogeZacetek := StrToFloat(StringReplace(vpVnosFRPZalogeZacetek.Text, '.', '', [rfReplaceAll, rfIgnoreCase])) * 100;
     zalogeKonec := StrToFloat(StringReplace(vpVnosFRPZalogeKonec.Text, '.', '', [rfReplaceAll, rfIgnoreCase])) * 100;
     internoProizvedeniDodatki := StrToFloat(StringReplace(vpVnosFRPInternoProizvedeniDodatki.Text, '.', '', [rfReplaceAll, rfIgnoreCase])) * 100;
     stroskiSurovin := StrToFloat(StringReplace(vpVnosFRPStroskiSurovine.Text, '.', '', [rfReplaceAll, rfIgnoreCase])) * 100;
     stroskiMateriala := StrToFloat(StringReplace(vpVnosFRPStroskiMaterial.Text, '.', '', [rfReplaceAll, rfIgnoreCase])) * 100;
     stroskiEnergije := StrToFloat(StringReplace(vpVnosFRPStroskiEnergija.Text, '.', '', [rfReplaceAll, rfIgnoreCase])) * 100;
     stroskiTrgovskegaBlaga :=StrToFloat(StringReplace(vpVnosFRPStroskiTrgovskoBlago.Text, '.', '', [rfReplaceAll, rfIgnoreCase])) * 100;
     stroskiStoritev := StrToFloat(StringReplace(vpVnosFRPStroskiStoritve.Text, '.', '', [rfReplaceAll, rfIgnoreCase])) * 100;
     drugiStroski := StrToFloat(StringReplace(vpVnosFRPDrugiStroski.Text, '.', '', [rfReplaceAll, rfIgnoreCase])) * 100;
     prejeteZavarovalnine := StrToFloat(StringReplace(vpVnosFRPPrejeteZavarovalnine.Text, '.', '', [rfReplaceAll, rfIgnoreCase])) * 100;

     mesecniPrometXminus1_1 := StrToFloat(StringReplace(vpTabelaPromet_ProdajaXminus1_1.Text, '.', '', [rfReplaceAll, rfIgnoreCase])) * 100;
     mesecniPrometXminus1_2 := StrToFloat(StringReplace(vpTabelaPromet_ProdajaXminus1_2.Text, '.', '', [rfReplaceAll, rfIgnoreCase])) * 100;
     mesecniPrometXminus1_3 := StrToFloat(StringReplace(vpTabelaPromet_ProdajaXminus1_3.Text, '.', '', [rfReplaceAll, rfIgnoreCase])) * 100;
     mesecniPrometXminus1_4 := StrToFloat(StringReplace(vpTabelaPromet_ProdajaXminus1_4.Text, '.', '', [rfReplaceAll, rfIgnoreCase])) * 100;
     mesecniPrometXminus1_5 := StrToFloat(StringReplace(vpTabelaPromet_ProdajaXminus1_5.Text, '.', '', [rfReplaceAll, rfIgnoreCase])) * 100;
     mesecniPrometXminus1_6 := StrToFloat(StringReplace(vpTabelaPromet_ProdajaXminus1_6.Text, '.', '', [rfReplaceAll, rfIgnoreCase])) * 100;
     mesecniPrometXminus1_7 := StrToFloat(StringReplace(vpTabelaPromet_ProdajaXminus1_7.Text, '.', '', [rfReplaceAll, rfIgnoreCase])) * 100;
     mesecniPrometXminus1_8 := StrToFloat(StringReplace(vpTabelaPromet_ProdajaXminus1_8.Text, '.', '', [rfReplaceAll, rfIgnoreCase])) * 100;
     mesecniPrometXminus1_9 := StrToFloat(StringReplace(vpTabelaPromet_ProdajaXminus1_9.Text, '.', '', [rfReplaceAll, rfIgnoreCase])) * 100;
     mesecniPrometXminus1_10 := StrToFloat(StringReplace(vpTabelaPromet_ProdajaXminus1_10.Text, '.', '', [rfReplaceAll, rfIgnoreCase])) * 100;
     mesecniPrometXminus1_11 := StrToFloat(StringReplace(vpTabelaPromet_ProdajaXminus1_11.Text, '.', '', [rfReplaceAll, rfIgnoreCase])) * 100;
     mesecniPrometXminus1_12 := StrToFloat(StringReplace(vpTabelaPromet_ProdajaXminus1_12.Text, '.', '', [rfReplaceAll, rfIgnoreCase])) * 100;

     mesecniPrometX_1 := StrToFloat(StringReplace(vpTabelaPromet_ProdajaX_1.Text, '.', '', [rfReplaceAll, rfIgnoreCase])) * 100;
     mesecniPrometX_2 := StrToFloat(StringReplace(vpTabelaPromet_ProdajaX_2.Text, '.', '', [rfReplaceAll, rfIgnoreCase])) * 100;
     mesecniPrometX_3 := StrToFloat(StringReplace(vpTabelaPromet_ProdajaX_3.Text, '.', '', [rfReplaceAll, rfIgnoreCase])) * 100;
     mesecniPrometX_4 := StrToFloat(StringReplace(vpTabelaPromet_ProdajaX_4.Text, '.', '', [rfReplaceAll, rfIgnoreCase])) * 100;
     mesecniPrometX_5 := StrToFloat(StringReplace(vpTabelaPromet_ProdajaX_5.Text, '.', '', [rfReplaceAll, rfIgnoreCase])) * 100;
     mesecniPrometX_6 := StrToFloat(StringReplace(vpTabelaPromet_ProdajaX_6.Text, '.', '', [rfReplaceAll, rfIgnoreCase])) * 100;
     mesecniPrometX_7 := StrToFloat(StringReplace(vpTabelaPromet_ProdajaX_7.Text, '.', '', [rfReplaceAll, rfIgnoreCase])) * 100;
     mesecniPrometX_8 := StrToFloat(StringReplace(vpTabelaPromet_ProdajaX_8.Text, '.', '', [rfReplaceAll, rfIgnoreCase])) * 100;
     mesecniPrometX_9 := StrToFloat(StringReplace(vpTabelaPromet_ProdajaX_9.Text, '.', '', [rfReplaceAll, rfIgnoreCase])) * 100;
     mesecniPrometX_10 := StrToFloat(StringReplace(vpTabelaPromet_ProdajaX_10.Text, '.', '', [rfReplaceAll, rfIgnoreCase])) * 100;
     mesecniPrometX_11 := StrToFloat(StringReplace(vpTabelaPromet_ProdajaX_11.Text, '.', '', [rfReplaceAll, rfIgnoreCase])) * 100;
     mesecniPrometX_12 := StrToFloat(StringReplace(vpTabelaPromet_ProdajaX_12.Text, '.', '', [rfReplaceAll, rfIgnoreCase])) * 100;

     if vpZavarovanjeDa.Checked = true then begin
       if vpZavarovanjeDAKosmatiDobicek.Checked = true then
             zvObstojeca := StrToFloat(StringReplace(vpZVKosmatiDobicek.Text, '.', '', [rfReplaceAll, rfIgnoreCase])) * 100
       else
             zvObstojeca := StrToFloat(StringReplace(vpZVNastetiStroski.Text, '.', '', [rfReplaceAll, rfIgnoreCase])) * 100;
     end
     else
       zvObstojeca := 0;

     case vpMDJ.ItemIndex of
        0..9 : mdjObstojeca := vpMDJ.ItemIndex + 3;
        10   : mdjObstojeca := 18;
        11   : mdjObstojeca := 24;
     end;

     // KONEC POLNMJENJA SPREMENLJIVK

      if vpZavarovanjeDa.Checked = true then begin
        izPloscicaObstojeceZavarovanje_Odgovor1.Caption := LjezikDa;
        izPloscicaObstojeceZavarovanje_Semafor1.Picture.LoadFromFile('luc_Z.png');
        izPloscicaObstojeceZavarovanje_Semafor1.Visible := true;
        izPloscicaObstojeceZavarovanje_Odgovor3.Caption := vpMDJ.Text + ' ' + izMDJ.Caption;
      //  mdjObstojeca := StrToInt(vpMDJ.Text);
        if vpZavarovanjeDAKosmatiDobicek.Checked = true then begin
          izPloscicaObstojeceZavarovanje_Odgovor2.Caption := LjezikNacinZavarovanja_KD;
          izPloscicaObstojeceZavarovanje_Semafor2.Picture.LoadFromFile('luc_Z.png');
          izPloscicaObstojeceZavarovanje_Semafor2.Visible := true;
          izPloscicaObstojeceZavarovanje_Semafor3b.Picture.LoadFromFile('');
          izPloscicaObstojeceZavarovanje_Semafor3b.Visible := false;
          izPloscicaObstojeceZavarovanje_Odgovor4.Caption := vpZVKosmatiDobicek.Text;
          zvObstojeca := StrToFloat(StringReplace(izPloscicaObstojeceZavarovanje_Odgovor4.Caption, '.', '', [rfReplaceAll, rfIgnoreCase]));
        end
        else if vpZavarovanjeDANastetiStroski.Checked = true then begin
          izPloscicaObstojeceZavarovanje_Odgovor2.Caption := LjezikNacinZavarovanja_NS;
          izPloscicaObstojeceZavarovanje_Semafor2.Picture.LoadFromFile('luc_Ru.png');
          izPloscicaObstojeceZavarovanje_Semafor2.Visible := true;
          izPloscicaObstojeceZavarovanje_Odgovor4.Caption := vpZVNastetiStroski.Text;
          zvObstojeca := StrToFloat(StringReplace(izPloscicaObstojeceZavarovanje_Odgovor4.Caption, '.', '', [rfReplaceAll, rfIgnoreCase]));
        end
        else begin
          izPloscicaObstojeceZavarovanje_Odgovor2.Caption := LjezikNacinZavarovanja_NP;
          izPloscicaObstojeceZavarovanje_Semafor2.Picture.LoadFromFile('luc_Rd.png');
          izPloscicaObstojeceZavarovanje_Semafor2.Visible := true;
          izPloscicaObstojeceZavarovanje_Odgovor3.Caption := '/';
          izPloscicaObstojeceZavarovanje_Semafor3.Picture.LoadFromFile('');
          izPloscicaObstojeceZavarovanje_Semafor3.Visible := false;
          izPloscicaObstojeceZavarovanje_Semafor3b.Picture.LoadFromFile('');
          izPloscicaObstojeceZavarovanje_Semafor3b.Visible := false;
          izPloscicaObstojeceZavarovanje_Odgovor4.Caption := '/';
          izPloscicaObstojeceZavarovanje_Semafor4.Picture.LoadFromFile('');
          izPloscicaObstojeceZavarovanje_Semafor4.Visible := false;
        end;
      end
      else begin
         izPloscicaObstojeceZavarovanje_Odgovor1.Caption := LjezikNe;
         izPloscicaObstojeceZavarovanje_Semafor1.Picture.LoadFromFile('luc_Rd.png');
         izPloscicaObstojeceZavarovanje_Semafor1.Visible := true;
         izPloscicaObstojeceZavarovanje_Odgovor2.Caption := '/';
         izPloscicaObstojeceZavarovanje_Semafor2.Picture.LoadFromFile('');
         izPloscicaObstojeceZavarovanje_Semafor2.Visible := false;
         izPloscicaObstojeceZavarovanje_Odgovor3.Caption := '/';
         izPloscicaObstojeceZavarovanje_Semafor3.Picture.LoadFromFile('');
         izPloscicaObstojeceZavarovanje_Semafor3.Visible := false;
         izPloscicaObstojeceZavarovanje_Semafor3b.Picture.LoadFromFile('');
         izPloscicaObstojeceZavarovanje_Semafor3b.Visible := false;
         izPloscicaObstojeceZavarovanje_Odgovor4.Caption := '/';
         izPloscicaObstojeceZavarovanje_Semafor4.Picture.LoadFromFile('');
         izPloscicaObstojeceZavarovanje_Semafor4.Visible := false;
      end;

      // Postavitev vrednosti na strani ANALIZE - Prodajni trendi
      ploscicaAnalizeProdaje_izPromet_NaslovXminus1.Caption := LjezikLeto +' '+ IntToStr(StrToInt(vpOpazovanoLeto.Text) - 1);
      ploscicaAnalizeProdaje_izPromet_NaslovX.Caption := LjezikLeto + ' '+ vpOpazovanoLeto.Text;
      for i := 1 to 4 do begin
          zapis2 := FindComponent('ploscicaAnalizeProdaje_izPromet_NaslovXplus'+IntToStr(i)) as TLabel;
          zapis2.Caption := LjezikLeto + ' ' + IntToStr(StrToInt(vpOpazovanoLeto.Text) + i);
      end;
      for j := 1 to 12 do begin
          zapis1 := FindComponent('vpTabelaPromet_ProdajaXminus1_'+IntToStr(j)) as TEdit;
          zapis2 := FindComponent('ploscicaAnalizeProdaje_izPromet_1_'+IntToStr(j)) as TLabel;
          zapis2.Caption := zapis1.Text;
      end;
      for j := 1 to 12 do begin
          zapis1 := FindComponent('vpTabelaPromet_ProdajaX_'+IntToStr(j)) as TEdit;
          zapis2 := FindComponent('ploscicaAnalizeProdaje_izPromet_2_'+IntToStr(j)) as TLabel;
          zapis2.Caption := zapis1.Text;
      end;
      for i := 1 to 4 do begin
        for j := 1 to 12 do begin
          zapis1 := FindComponent('vpTabelaPromet_ProdajaX_'+IntToStr(j)) as TEdit;
          zapis2 := FindComponent('ploscicaAnalizeProdaje_izPromet_'+IntToStr(i+2)+'_'+IntToStr(j)) as TLabel;
          izracunanaVrednost := StrToFloat(StringReplace(zapis1.Text, '.', '', [rfReplaceAll, rfIgnoreCase]));
          for k := 1 to i do begin
            rast := FindComponent('vpPlanProdaje_RastXplus'+IntToStr(k)) as TTrackBar;
            izracunanaVrednost := izracunanaVrednost * (1 + rast.Position / 1000);
          end;
          zapis2.Caption := FormatFloat('###,###,##0.00', izracunanaVrednost);
        end;
      end;

      // Zagon preračuna (webservisa)

      if PovezavaWSPopolniPreracun.Connected = true then
           PovezavaWSPopolniPreracun.Connected := false;
      PovezavaWSPopolniPreracun.Connected := true;
    end;
  finally
    fmOsnovniKontroler.PovezavaWSPopolniPreracun.Connected := false;
  end;
end;


procedure ZagonPrograma;
var i, j : Integer;
    zapis : TLabel;
    vnosnoPolje : TEdit;
    drsnik : TTrackBar;
    izbor : TRadioButton;

begin

  with fmOsnovniKontroler do begin
    // Praznjenje vrednosti iz registracije
     vpUporabniskoIme.Text :='';
    izUporabniskoImeNapaka.Visible := false;
    vpGeslo.Text := '';
    izGesloNapaka.Visible := false;

    // Postavljanje menijev na začetne vrednosti ter pogleda na 1. delovni list
    izborVhodniPodatki.ItemIndex := 0;
    izborAnalize.ItemIndex := -1;
    izborAnalize.Visible := false;
    izIzborAnalize.Visible := false;
    izborSimulacijeSkod.ItemIndex := -1;
    izborSimulacijeSkod.Visible := false;
    izIzborSimulacijeSkod.Visible := false;

    // Določitev osnovnih parametrov na delovnem listu "Osnovni podatki"
    vpImePodjetja.Text := '';
    vpNaslovPodjetja.text := '';
    vpDavcnaStevilkaPodjetja.Text := '';
    vpDejavnostPodjetja.ItemIndex := -1;

    // Določitev osnovnih parametrov na delovnem listu "Finančno računovodski podatki"
    vpVnosFRPPromet.Text := '0,00';
    vpVnosFRPZalogeZacetek.Text := '0,00';
    vpVnosFRPZalogeKonec.Text := '0,00';
    vpVnosFRPInternoProizvedeniDodatki.Text := '0,00';
    vpVnosFRPStroskiSurovine.Text := '0,00';
    vpVnosFRPStroskiMaterial.Text := '0,00';
    vpVnosFRPStroskiEnergija.Text := '0,00';
    vpVnosFRPStroskiTrgovskoBlago.Text := '0,00';
    vpVnosFRPStroskiStoritve.Text := '0,00';
    vpVnosFRPDrugiStroski.Text := '0,00';
    vpVnosFRPPrejeteZavarovalnine.Text := '0,00';
    vpVnosFRPKosmatiDobicek.Caption := '0,00';
    vpVnosFRPStopnjaKosmategaDobicka.Caption := '0,00';
    // Opazovano leto je skupaj z njim povezanimi polji obdelan posebej

    // Dolocitev osnovnih parametrov na delovnem listu "Podatki o prodaji"
    for i:= 1 to 12 do begin
      vnosnoPolje := FindComponent('vpTabelaPromet_ProdajaXminus1_'+IntToStr(i)) as TEdit;
      vnosnoPolje.Text := '0,00';
      vnosnoPolje := FindComponent('vpTabelaPromet_ProdajaX_'+IntToStr(i)) as TEdit;
       vnosnoPolje.Text := '0,00';
    end;
    vpTabelaPromet_LetoXminus1Skupaj.Caption := '0,00';
    vpTabelaPromet_LetoXSkupaj.Caption := '0,00';
    for i := 1 to 4 do begin
      drsnik := FindComponent('vpPlanProdaje_RastXplus'+IntToStr(i)) as TTrackBar;
      drsnik.Position:=0;
      zapis := FindComponent('izPlanProdaje_RastXplus'+IntToStr(i)) as TLabel;
      zapis.Caption := '0,00 %';
    end;
    // Podatki vezani na leta obravnave so obdelani skupaj (zaradi celovitosti s poljem OpazovanoLeto

    // Določitev osnovnih parametrov na delovnem listu "Organizacija dela"
    for i := 1 to 2 do begin
      drsnik := FindComponent('vpOrganizacija_Odgovor'+IntToStr(i)) as TTrackBar;
       drsnik.Position:=2;
      zapis := FindComponent('izOrganizacija_mera'+IntToStr(i)) as TLabel;
      zapis.Caption := '1 mes';
    end;
    for i := 3 to 8 do begin
      izbor := FindComponent('vpOrganizacija_OdgovorDa'+IntToStr(i)) as TRadioButton;
      izbor.Checked := false;
      izbor := FindComponent('vpOrganizacija_OdgovorNe'+IntToStr(i)) as TRadioButton;
      izbor.Checked := false;
    end;
    vpOrganizacija_Odgovor9.Position := 1;
    izOrganizacija_mera9.Caption := '1 ' + LjezikMera_Izmena;
    vpOrganizacija_Odgovor10.Position := 100;
    izOrganizacija_mera10.Caption := '100 %';
    vpOrganizacija_OdgovorDa11_1.Checked := false;
    vpOrganizacija_OdgovorNe11_1.Checked := false;
    vpOrganizacija_Odgovor11_2.Position := 100;
    izOrganizacija_mera11_2.Caption := '100 %';
    vpOrganizacija_Odgovor11_3.Position := 4;
    izOrganizacija_mera11_3.Caption := '2 ' + LjezikMera_Mesec;
    vpOrganizacija_OdgovorDa11_4.Checked := false;
    vpOrganizacija_OdgovorNe11_4.Checked := false;
    vpOrganizacija_Odgovor11_5.Position := 100;
    izOrganizacija_mera11_5.Caption := '100 %';
    vpOrganizacija_Odgovor11_6.Position := 4;
    izOrganizacija_mera11_6.Caption := '2 ' + LjezikMera_Mesec;
    vpOrganizacija_Odgovor12.Position := 2;
    izOrganizacija_mera12.Caption := '1 ' + LjezikMera_Mesec;
    vpOrganizacija_Odgovor13.ItemIndex := -1;

    // Določi osnovne parametre na delovnem listu "Podatki o obstoječem zavarovanju"
    vpZavarovanjeDa.Checked := false;
    vpZavarovanjeDAKosmatiDobicek.Checked := false;
    vpZVKosmatiDobicek.Text := '0,00';
    vpZVKosmatiDobicek.Enabled := false;
    vpZavarovanjeDANastetiStroski.Checked := false;
    for i := 2 to 6 do begin
      vnosnoPolje := FindComponent('vpZavarovaniStroski2_'+IntToStr(i)) as TEdit;
      vnosnoPolje.Text := '0,00';
      vnosnoPolje.Enabled := false;
    end;
    ploscicaPodatkiOZavarovanju.Visible := false;
    vpMDJ.ItemIndex := 9 ;
    vpZacetekZavarovanja.Date := Now;
    vpPremija.Text := '0,00';

    // Določi razpon vrednosti v vnosnem polju OpazovanoLeto, torej letu, za katerega se v osnovi delajo izračuni
    // oziroma za katerega imamo ustrezne finančno računovodske podatke - in polj, nepsoredno povezanih z njim

    vpTabelaPromet_LetoXminus1.Caption := IntToStr(StrToInt(vpOpazovanoLeto.Text) - 1);
    vpTabelaPromet_LetoX.Caption := vpOpazovanoLeto.Text;
    for i := 1 to 4 do begin
      zapis := FindComponent('izPlanProdaje_letoXplus'+IntToStr(i)) as TLabel;
      zapis.Caption := LjezikLeto + ' ' + IntToStr(StrToInt(vpOpazovanoLeto.Text) + i) +':';
    end;


    //Delovni list 6 (Potrebna maksimalna doba jamčenja)
    vpParameter1_1.Position := 6;
    izParameter1_1.Caption := '1,50';
    vpParameter1_2.Position := 2;
    izParameter1_2.Caption := '0,50';
    vpParameter2_1.Position := 10;
    izParameter2_1.Caption := '2,50';
    vpParameter2_2.Position := 2;
    izParameter2_2.Caption := '0,50';
    vpParameter3_1.Position := 16;
    izParameter3_1.Caption := '4,00';
    vpParameter3_2.Position := 0;
    izParameter3_2.Caption := '0,00';
    vpParameter4_1.Position := 10;
    izParameter4_1.Caption := '2,50';
    vpParameter4_2.Position := 0;
    izParameter4_2.Caption := '0,00';
    vpParameter5_1.Position := 8;
    izParameter5_1.Caption := '2,00';
    vpParameter5_2.Position := 0;
    izParameter5_2.Caption := '0,00';
    vpParameter6_1.Position := 8;
    izParameter6_1.Caption := '2,00';
    vpParameter7_1.Position := 4;
    izParameter7_1.Caption := '1,00';
    izPloscicaKorekcijskiFaktorji_KF1_3.Caption := 'NE';
    vpPloscicaKorekcijskiFaktorji_KFDrsnik1_4.Position := 0;
    vpPloscicaKorekcijskiFaktorji_KFDrsnik1_4.Enabled := false;
    vpPloscicaKorekcijskiFaktorji_KFIzpis1_4.Caption := '0,00';
    izPloscicaKorekcijskiFaktorji_KF2_3.Caption := 'NE';
    vpPloscicaKorekcijskiFaktorji_KFDrsnik2_4.Position := 0;
    vpPloscicaKorekcijskiFaktorji_KFDrsnik2_4.Enabled := false;
    vpPloscicaKorekcijskiFaktorji_KFIzpis2_4.Caption := '0,00';
    izPloscicaKorekcijskiFaktorji_KF3_3.Caption := 'NE';
    vpPloscicaKorekcijskiFaktorji_KFDrsnik3_4.Position := 0;
    vpPloscicaKorekcijskiFaktorji_KFDrsnik3_4.Enabled := false;
    vpPloscicaKorekcijskiFaktorji_KFIzpis3_4.Caption := '0,00';
    izPloscicaKorekcijskiFaktorji_KF4_3.Caption := 'NE';
    vpPloscicaKorekcijskiFaktorji_KFDrsnik4_4.Position := 0;
    vpPloscicaKorekcijskiFaktorji_KFDrsnik4_4.Enabled := false;
    vpPloscicaKorekcijskiFaktorji_KFIzpis4_4.Caption := '0,00';
    izPloscicaKorekcijskiFaktorji_KF5_3.Caption := 'NE';
    vpPloscicaKorekcijskiFaktorji_KFDrsnik5_4.Position := 0;
    vpPloscicaKorekcijskiFaktorji_KFDrsnik5_4.Enabled := false;
    vpPloscicaKorekcijskiFaktorji_KFIzpis5_4.Caption := '0,00';
    vpKorekcijskiFaktorji.Checked := false;
    vpMaksimalniPribitek.Position := 10;
    izMaksimalniPribitek.Caption := '10 %';
    ploscicaMaksimalniPribitek.Visible := false;
    ploscicaKorekcijskiFaktorji.Visible := false;


    vpGrafikaMDJ.Position := 0;
    ploscicaGrafMDJ.Visible := false;

    ploscicaMaksimalniPribitek.Top := 990;
    ploscicaKorekcijskiFaktorji.Top := 1025;
    vpKorekcijskiFaktorji.Top := 993;
    ploscicaMaksimalniPribitek.Top := ploscicaMaksimalniPribitek.Top - 400;
    ploscicaKorekcijskiFaktorji.Top := ploscicaKorekcijskiFaktorji.Top - 400;
    vpKorekcijskiFaktorji.Top := vpKorekcijskiFaktorji.Top - 400;
    GrafMDJ.BeginUpdate;
    GrafMDJ.Series.Clear;
    GrafMDJ.Options.Clear;
    GrafMDJ.EndUpdate;

    // Delovni list 7 (Potrebna zavarovalna vsota)

    izTekstPriporoceniKD.Caption := LjezikDL7_izTekstPriporoceniKD + ' ' + vpOpazovanoLeto.Text + ':';
    izTekstPriporocenaSKD.Caption := LjezikDL7_izTekstPriporocenaSKD + ' ' + vpOpazovanoLeto.Text + ':';
    izTekstLetoPriporoceneZV.Caption := LjezikDL7_izTekstLetoPriporoceneZV + ':';

    izLetoPriporoceneZVmin.Caption := IntToStr(StrToInt(vpOpazovanoLeto.Text) + 1);
    izLetoPriporoceneZVmax.Caption := IntToStr(StrToInt(vpOpazovanoLeto.Text) + 2);
    vpLetoPriporoceneZV.Min := StrToInt(vpOpazovanoLeto.Text) +1;
    vpLetoPriporoceneZV.Max := StrToInt(vpOpazovanoLeto.Text) +2;
    vpLetoPriporoceneZV.Position := vpLetoPriporoceneZV.Min;
    izLetoPriporoceneZVmin.Font.Style := [fsBold];
    izLetoPriporoceneZVmax.Font.Style := [];

    // Delovni list 8 (Prodajni trendi)
    ploscicaAnalizeProdaje_izPromet_NaslovXminus1.Caption := LjezikLeto + ' '+ IntToStr(StrToInt(vpOpazovanoLeto.Text) - 1);
    ploscicaAnalizeProdaje_izPromet_NaslovX.Caption := LjezikLeto +' '+ vpOpazovanoLeto.Text;
    for i := 1 to 4 do begin
      zapis := FindComponent('ploscicaAnalizeProdaje_izPromet_NaslovXplus'+IntToStr(i)) as TLabel;
      zapis.Caption := LjezikLeto + ' ' + IntToStr(StrToInt(vpOpazovanoLeto.Text) + i);
    end;
    for i := 1 to 6 do begin
      for j := 1 to 12 do begin
        zapis := FindComponent('ploscicaAnalizeProdaje_izPromet_'+IntToStr(i)+'_'+IntToStr(j)) as TLabel;
        zapis.Caption := '0,00';
      end;
    end;
    ListiProdajnihTrendov.TabIndex := 0;


    // Delovni list 10 (Simulacija skode)
    ploscicaGrafSimulacijaSkode.Visible := false;
    vpGrafikaSkode.Position := 0;

    izborVhodniPodatki.ItemIndex := 0;
    ploscicaDelovniListi.TabIndex := 11;

  end;

end;


procedure IzrisGrafaSimulacijeSkod;
var it : TGoogleChartSeriesItem;
    AnnotationType: TGoogleChartAnnotationType;
    i, j : Integer;
    vrsticaZacetek, stolpecZacetek, vrsticaFSkoda, stolpecFSkoda, polozajVrstica, polozajStolpec : Integer;
  	leto, mesec : Word;
	  mesecevSkode, dolzinaGrafa : Integer;
    poljeBranje1 : TLabel;
    poljeBranje2 : TEdit;
    zapis : String;
    datum : String;

begin
  with fmOsnovniKontroler do begin
    GrafSimulacijaSkode.BeginUpdate;
    GrafSimulacijaSkode.Series.Clear;
    GrafSimulacijaSkode.Options.Clear;
    GrafSimulacijaSkode.Appearance.Legend.Position := gcpRight;
    AnnotationType := gcatData;
    it := GrafSimulacijaSkode.Series.Add;
    it.ChartType := gctArea;

    it.Line.LineWidth := 2;
    it.Line.PointSize := 0;

    // podatki o začetku zavarovanja
    mesec := MonthOf(vpZavarovanjeOd.Date);
    leto := YearOf(vpZavarovanjeOd.Date);

    // izračun polja, kjer se v tabeli začne zastoj
    vrsticaFSkoda := vpDatumFizicneSkode.ItemIndex + mesec; // ugotovi, kateri mesec je nastala škoda (tj. bila izbrana iz spustnega seznama)
    stolpecFSkoda := StrToInt(vpOpazovanoLeto.Text) - leto + 2 ;

    // izračun polja, kjer se začne grafični prikaz (3 mesece pred fizično škodo)
    stolpecZacetek := stolpecFSkoda;
    vrsticaZacetek := vrsticaFSkoda - 3;
    if vrsticaZacetek < 1 then begin
         stolpecZacetek := stolpecZacetek - 1;
         vrsticaZacetek := 12 + (vrsticaFSkoda - vrsticaZacetek);
    end;

    // izračun koliko mesecev je trajal zastoj (prešteje celice, ki so obarvane)
    mesecevSkode := 0;
    for i := 2 to 4 do begin
        for j := 1 to 12 do begin
            poljeBranje2 := FindComponent('ploscicaPromet_Promet_'+IntToStr(i)+'_'+IntToStr(j)) as TEdit;
            if poljeBranje2.Color = clWebCornSilk then
                 mesecevSkode := mesecevSkode + 1;
        end;
    end;

    // Izračun števila podatkov (trajanje zastopja + 3 mesece pred in po njem)
    dolzinaGrafa := mesecevSkode + 3 + 3;

    // Prva serija pdoatkov

    for i := 0 to dolzinaGrafa - 1 do begin

        polozajVrstica := vrsticaZacetek + i;
        if polozajVrstica > 24 then begin
             polozajVrstica := polozajVrstica - 24;
             polozajStolpec := stolpecZacetek + 2;
        end
        else if polozajVrstica > 12 then begin
             polozajVrstica := polozajVrstica - 12;
             polozajStolpec := stolpecZacetek + 1;
        end
        else
            polozajStolpec :=	stolpecZacetek;


        poljeBranje1 := FindComponent('ploscicaAnalizeProdaje_izMesec_'+IntToStr(polozajVrstica)) as TLabel;
        datum := poljeBranje1.Caption + ', ';

        if (polozajStolpec - stolpecFSkoda = -1) then begin
               poljeBranje1 := FindComponent('ploscicaAnalizeProdaje_izPromet_1_'+IntToStr(polozajVrstica)) as TLabel;
               zapis := StringReplace(poljeBranje1.Caption, '.', '', [rfreplaceAll, rfIgnorecase]);
               poljeBranje1 := FindComponent('ploscicaAnalizeProdaje_izPromet_NaslovXminus1') as TLabel;
        end
        else if (polozajStolpec - stolpecFSkoda = 0) then begin
               poljeBranje1 := FindComponent('ploscicaAnalizeProdaje_izPromet_2_'+IntToStr(polozajVrstica)) as TLabel;
               zapis := StringReplace(poljeBranje1.Caption, '.', '', [rfreplaceAll, rfIgnorecase]);
               poljeBranje1 := FindComponent('ploscicaAnalizeProdaje_izPromet_NaslovX') as TLabel;
        end
        else if (polozajStolpec - stolpecFSkoda = 1) then begin
               poljeBranje1 := FindComponent('ploscicaAnalizeProdaje_izPromet_3_'+IntToStr(polozajVrstica)) as TLabel;
               zapis := StringReplace(poljeBranje1.Caption, '.', '', [rfreplaceAll, rfIgnorecase]);
               poljeBranje1 := FindComponent('ploscicaAnalizeProdaje_izPromet_NaslovXplus1') as TLabel;
        end
        else if (polozajStolpec - stolpecFSkoda = 2) then begin
               poljeBranje1 := FindComponent('ploscicaAnalizeProdaje_izPromet_4_'+IntToStr(polozajVrstica)) as TLabel;
               zapis := StringReplace(poljeBranje1.Caption, '.', '', [rfreplaceAll, rfIgnorecase]);
               poljeBranje1 := FindComponent('ploscicaAnalizeProdaje_izPromet_NaslovXplus2') as TLabel;
        end;

        datum := datum + StringReplace(poljeBranje1.Caption, LjezikLeto + ' ', '', [rfreplaceAll, rfIgnorecase]);

        it.Values.AddSinglePoint(StrToFloat(zapis), datum);
    end;

    // Druga serija podatkov

    it := GrafSimulacijaSkode.Series.Add;
    //  it.Title := 'promet z zastojem';
    it.ChartType := gctArea;

    it.Line.LineWidth := 2;
    it.Line.PointSize := 0;

    for i := 0 to dolzinaGrafa - 1 do begin

        polozajVrstica := vrsticaZacetek + i;
        if polozajVrstica > 24 then begin
            polozajVrstica := polozajVrstica - 24;
            polozajStolpec := stolpecZacetek + 2;
        end
        else if polozajVrstica > 12 then begin
            polozajVrstica := polozajVrstica - 12;
            polozajStolpec := stolpecZacetek + 1;
        end
        else
            polozajStolpec :=	stolpecZacetek;

        poljeBranje2 := FindComponent('ploscicaPromet_Promet_'+IntToStr(polozajStolpec)+'_'+IntToStr(polozajVrstica)) as TEdit;
        zapis := StringReplace(poljeBranje2.Text, '.', '', [rfreplaceAll, rfIgnorecase]);

        poljeBranje1 := FindComponent('ploscicaAnalizeProdaje_izMesec_'+IntToStr(polozajVrstica)) as TLabel;
        datum := poljeBranje1.Caption + ', ';

        if (polozajStolpec - stolpecFSkoda = -1) then
               poljeBranje1 := FindComponent('ploscicaAnalizeProdaje_izPromet_NaslovXminus1') as TLabel
        else if (polozajStolpec - stolpecFSkoda = 0) then
               poljeBranje1 := FindComponent('ploscicaAnalizeProdaje_izPromet_NaslovX') as TLabel
        else if (polozajStolpec - stolpecFSkoda = 1) then
               poljeBranje1 := FindComponent('ploscicaAnalizeProdaje_izPromet_NaslovXplus1') as TLabel
        else if (polozajStolpec - stolpecFSkoda = 2) then
               poljeBranje1 := FindComponent('ploscicaAnalizeProdaje_izPromet_NaslovXplus2') as TLabel;

        datum := datum + StringReplace(poljeBranje1.Caption, LjezikLeto+' ', '', [rfreplaceAll, rfIgnorecase]);

        it.Values.AddSinglePoint(StrToFloat(zapis), datum);
    end;

    GrafSimulacijaSkode.Enabled := true;
    GrafSimulacijaSkode.EndUpdate;
  end;
end;

procedure TfmOsnovniKontroler.PovezavaServerShranjevanjeConnect(Sender: TObject);
var stevilkaZapisa, OpazovanoObdobje : Integer;
  	dobavniRokStrojev, montazaStrojev : Real;
    rabljeniStroji, drugaLokacijaLastna, drugaLokacijaNajeta, posebniDelovniPogoji, uporabaNevarnihSnovi, vgradnjaNevarnihSnovi, izmene : Integer;
    zasedenostProizvodnje : Real;
    locenoSkladisce_nad10 : Integer;
    locenoSkladisce_nad10_Delez, locenoSkladisce_nad10_Obdobje : Real;
    locenoSkladisce_pod10 : Integer;
    locenoSkladisce_pod10_Delez, locenoSkladisce_pod10_Obdobje : Real;
    zamikPlacil : Real;
    tipProizvodnje, letoZV: Integer;
    zavarovanje, zavarovanjeKD : Integer;
    zavarovanjeKD_ZV : Real;
    zavarovanjeNS : Integer;
    zavarovanjeNS_StrStoritev, zavarovanjeNS_Amortizacija, zavarovanjeNS_StrDela, zavarovanjeNS_StrDrugi, zavarovanjeNS_Dobicek : Real;
    zavarovanjeMDJ : Integer;
    zavarovanjeZacetek : String;
    zavarovanjePremija : Real;


  procedure OnResultOsnovnaPoizvedba( Response: TXDataClientResponse );
  var
    LArray : TJSArray;
    LItem : TJSObject;

      procedure OnResultPoizvedbaShranjevanjePodatkov( Response: TXDataClientResponse );

          procedure OnResultShranjevanjePodatkov( Response: TXDataClientResponse );
          var
            LArray : TJSArray;
            LItem : TJSObject;
            podatkiObj: TJSObject;

          begin
             stevilkaZapisa := 0;

             try

               LArray := TJSArray( TJSObject( Response.Result )['value']);
               LItem := TJSObject( LArray[0]);

               if LItem <> null then begin
                  stevilkaZapisa := Integer(LItem['Stevilka']);
                  opazovanoObdobje := Integer(LItem['LetoPodatkov']);

                  promet := StrToFloat(vpVnosFRPPromet.Text);
                  zalogeZacetek := StrToFloat(vpVnosFRPZalogeZacetek.Text);
                  zalogeKonec := StrToFloat(vpVnosFRPZalogeKonec.Text);
                  internoProizvedeniDodatki := StrToFloat(vpVnosFRPInternoProizvedeniDodatki.Text);
                  stroskiSurovin := StrToFloat(vpVnosFRPStroskiSurovine.Text);
                  stroskiMateriala := StrToFloat(vpVnosFRPStroskiMaterial.Text);
                  stroskiEnergije := StrToFloat(vpVnosFRPStroskiEnergija.Text);
                  stroskiTrgovskegaBlaga :=StrToFloat(vpVnosFRPStroskiTrgovskoBlago.Text);
                  stroskiStoritev := StrToFloat(vpVnosFRPStroskiStoritve.Text);
                  drugiStroski := StrToFloat(vpVnosFRPDrugiStroski.Text);
                  prejeteZavarovalnine := StrToFloat(vpVnosFRPPrejeteZavarovalnine.Text);
                  mesecniPrometXminus1_1 := StrToFloat(vpTabelaPromet_ProdajaXminus1_1.Text);
                  mesecniPrometXminus1_2 := StrToFloat(vpTabelaPromet_ProdajaXminus1_2.Text);
                  mesecniPrometXminus1_3 := StrToFloat(vpTabelaPromet_ProdajaXminus1_3.Text);
                  mesecniPrometXminus1_4 := StrToFloat(vpTabelaPromet_ProdajaXminus1_4.Text);
                  mesecniPrometXminus1_5 := StrToFloat(vpTabelaPromet_ProdajaXminus1_5.Text);
                  mesecniPrometXminus1_6 := StrToFloat(vpTabelaPromet_ProdajaXminus1_6.Text);
                  mesecniPrometXminus1_7 := StrToFloat(vpTabelaPromet_ProdajaXminus1_7.Text);
                  mesecniPrometXminus1_8 := StrToFloat(vpTabelaPromet_ProdajaXminus1_8.Text);
                  mesecniPrometXminus1_9 := StrToFloat(vpTabelaPromet_ProdajaXminus1_9.Text);
                  mesecniPrometXminus1_10 := StrToFloat(vpTabelaPromet_ProdajaXminus1_10.Text);
                  mesecniPrometXminus1_11 := StrToFloat(vpTabelaPromet_ProdajaXminus1_11.Text);
                  mesecniPrometXminus1_12 := StrToFloat(vpTabelaPromet_ProdajaXminus1_12.Text);
                  mesecniPrometX_1 := StrToFloat(vpTabelaPromet_ProdajaX_1.Text);
                  mesecniPrometX_2 := StrToFloat(vpTabelaPromet_ProdajaX_2.Text);
                  mesecniPrometX_3 := StrToFloat(vpTabelaPromet_ProdajaX_3.Text);
                  mesecniPrometX_4 := StrToFloat(vpTabelaPromet_ProdajaX_4.Text);
                  mesecniPrometX_5 := StrToFloat(vpTabelaPromet_ProdajaX_5.Text);
                  mesecniPrometX_6 := StrToFloat(vpTabelaPromet_ProdajaX_6.Text);
                  mesecniPrometX_7 := StrToFloat(vpTabelaPromet_ProdajaX_7.Text);
                  mesecniPrometX_8 := StrToFloat(vpTabelaPromet_ProdajaX_8.Text);
                  mesecniPrometX_9 := StrToFloat(vpTabelaPromet_ProdajaX_9.Text);
                  mesecniPrometX_10 := StrToFloat(vpTabelaPromet_ProdajaX_10.Text);
                  mesecniPrometX_11 := StrToFloat(vpTabelaPromet_ProdajaX_11.Text);
                  mesecniPrometX_12 := StrToFloat(vpTabelaPromet_ProdajaX_12.Text);
                  rast1 := vpPlanProdaje_RastXplus1.Position;
                  rast2 := vpPlanProdaje_RastXplus2.Position;
                  rast3 := vpPlanProdaje_RastXplus3.Position;
                  rast4 := vpPlanProdaje_RastXplus4.Position;

                  dobavniRokStrojev := vpOrganizacija_Odgovor1.Position;
                  montazaStrojev := vpOrganizacija_Odgovor2.Position;

                  if vpOrganizacija_OdgovorDa3.Checked = true then
                     rabljeniStroji := 1
                  else
                     rabljeniStroji := 0;

                  if vpOrganizacija_OdgovorDa4.Checked = true then
                   drugaLokacijaLastna := 1
                  else
                 drugaLokacijaLastna := 0;

                  if vpOrganizacija_OdgovorDa5.Checked = true then
                    drugaLokacijaNajeta := 1
                  else
                     drugaLokacijaNajeta := 0;

                  if vpOrganizacija_OdgovorDa6.Checked = true then
                    PosebniDelovniPogoji := 1
                  else
                     PosebniDelovniPogoji := 0;

                  if vpOrganizacija_OdgovorDa7.Checked = true then
                    uporabaNevarnihSnovi := 1
                  else
                     uporabaNevarnihSnovi := 0;

                  if vpOrganizacija_OdgovorDa8.Checked = true then
                    vgradnjaNevarnihSnovi := 1
                  else
                     vgradnjaNevarnihSnovi := 0;

                  izmene := vpOrganizacija_Odgovor9.Position;

                  zasedenostProizvodnje := vpOrganizacija_Odgovor10.Position;;

                  if vpOrganizacija_OdgovorDa11_1.Checked = true then
                    locenoSkladisce_nad10 := 1
                  else
                    locenoSkladisce_nad10 := 0;

                  locenoSkladisce_nad10_Delez := vpOrganizacija_Odgovor11_2.Position;;

                  locenoSkladisce_nad10_Obdobje := vpOrganizacija_Odgovor11_3.Position;;

                  if vpOrganizacija_OdgovorDa11_4.Checked = true then
                    locenoSkladisce_pod10 := 1
                  else
                    locenoSkladisce_pod10 := 0;

                  locenoSkladisce_pod10_Delez := vpOrganizacija_Odgovor11_5.Position;;

                  locenoSkladisce_pod10_Obdobje := vpOrganizacija_Odgovor11_6.Position;;

                  zamikPlacil := vpOrganizacija_Odgovor12.Position;

                  tipProizvodnje := vpOrganizacija_Odgovor13.ItemIndex;

                 if vpLetoPriporoceneZV.Position = vpLetoPriporoceneZV.Max then
                    letoZV := 2       // Če je izračun za leto MIN
                  else
                    letoZV := 1;      // Če je izračun za leto MAX

                 parameter1_1 := vpParameter1_1.Position;
                 parameter1_2 := vpParameter1_2.Position;
                 parameter2_1 := vpParameter2_1.Position;
                 parameter2_2 := vpParameter2_2.Position;
                 parameter3_1 := vpParameter3_1.Position;
                 parameter3_2 := vpParameter3_2.Position;
                 parameter4_1 := vpParameter4_1.Position;
                 parameter4_2 := vpParameter4_2.Position;
                 parameter5_1 := vpParameter5_1.Position;
                 parameter5_2 := vpParameter5_2.Position;
                 parameter6_1 := vpParameter6_1.Position;
                 parameter7_1 := vpParameter7_1.Position;

                 if vpKorekcijskiFaktorji.Checked = true then    // polnjenje spremnljivke z vrednostjo ali je izbran osnovni ali korigiran izračun MDJ
                    korigiranMDJ := 1
                 else
                  korigiranMDJ := 0;

                 maxPribitek := vpMaksimalniPribitek.Position;

                 korFaktor1 := vpPloscicaKorekcijskiFaktorji_KFDrsnik1_4.Position;
                 korFaktor2 := vpPloscicaKorekcijskiFaktorji_KFDrsnik2_4.Position;
                 korFaktor3 := vpPloscicaKorekcijskiFaktorji_KFDrsnik3_4.Position;
                 korFaktor4 := vpPloscicaKorekcijskiFaktorji_KFDrsnik4_4.Position;
                 korFaktor5 := vpPloscicaKorekcijskiFaktorji_KFDrsnik5_4.Position;

                 if vpZavarovanjeDa.Checked = true then
                    zavarovanje :=  1
                 else
                    zavarovanje := 0;

                 if vpZavarovanjeDaKosmatiDobicek.Checked = true then
                    zavarovanjeKD :=  1
                 else
                   zavarovanjeKD := 0;

                 zavarovanjeKD_ZV := StrToFloat(vpZVKosmatiDobicek.Text);

                 if vpZavarovanjeDANastetiStroski.Checked = true then
                   zavarovanjeNS :=  1
                 else
                   zavarovanjeNS := 0;

                 zavarovanjeNS_StrStoritev := StrToFloat(vpZavarovaniStroski2_2.Text);
                 zavarovanjeNS_Amortizacija := StrToFloat(vpZavarovaniStroski2_3.Text);
                 zavarovanjeNS_StrDela := StrToFloat(vpZavarovaniStroski2_4.Text);
                 zavarovanjeNS_StrDrugi := StrToFloat(vpZavarovaniStroski2_5.Text);
                 zavarovanjeNS_Dobicek := StrToFloat(vpZavarovaniStroski2_6.Text);

                 zavarovanjeMDJ := vpMDJ.ItemIndex;

                 zavarovanjeZacetek := DateTimeToStr(vpZacetekZavarovanja.Date);

                 zavarovanjePremija := StrToFloat(vpPremija.Text);

                  try
                    podatkiObj := TJSObject.New;
                    podatkiObj['Stevilka'] := stevilkaZapisa;
                    podatkiObj['OpazovanoObdobje'] := opazovanoObdobje;
                    podatkiObj['Promet'] := promet;
                    podatkiObj['ZalogeZacetek'] := zalogeZacetek;
                    podatkiObj['ZalogeKonec'] := zalogeKonec;
                    podatkiObj['InternoProizvedeniDodatki'] := internoProizvedeniDodatki;
                    podatkiObj['StroskiSurovin'] := stroskiSurovin;
                    podatkiObj['Stroskimateriala'] := stroskiMateriala;
                    podatkiObj['StroskiEnergije'] := stroskiEnergije;
                    podatkiObj['StroskiTrgovskegaBlaga'] := stroskiTrgovskegaBlaga;
                    podatkiObj['StroskiZunanjihIzvajalcev'] := stroskiStoritev;
                    podatkiObj['DrugiStroski'] := drugiStroski;
                    podatkiObj['PrejeteZavarovalnine'] := prejeteZavarovalnine;
                    podatkiObj['PrometXminus1_1'] := mesecniPrometXminus1_1;
                    podatkiObj['PrometXminus1_2'] := mesecniPrometXminus1_2;
                    podatkiObj['PrometXminus1_3'] := mesecniPrometXminus1_3;
                    podatkiObj['PrometXminus1_4'] := mesecniPrometXminus1_4;
                    podatkiObj['PrometXminus1_5'] := mesecniPrometXminus1_5;
                    podatkiObj['PrometXminus1_6'] := mesecniPrometXminus1_6;
                    podatkiObj['PrometXminus1_7'] := mesecniPrometXminus1_7;
                    podatkiObj['PrometXminus1_8'] := mesecniPrometXminus1_8;
                    podatkiObj['PrometXminus1_9'] := mesecniPrometXminus1_9;
                    podatkiObj['PrometXminus1_10'] := mesecniPrometXminus1_10;
                    podatkiObj['PrometXminus1_11'] := mesecniPrometXminus1_11;
                    podatkiObj['PrometXminus1_12'] := mesecniPrometXminus1_12;
                    podatkiObj['PrometX_1'] := mesecniPrometX_1;
                    podatkiObj['PrometX_2'] := mesecniPrometX_2;
                    podatkiObj['PrometX_3'] := mesecniPrometX_3;
                    podatkiObj['PrometX_4'] := mesecniPrometX_4;
                    podatkiObj['PrometX_5'] := mesecniPrometX_5;
                    podatkiObj['PrometX_6'] := mesecniPrometX_6;
                    podatkiObj['PrometX_7'] := mesecniPrometX_7;
                    podatkiObj['PrometX_8'] := mesecniPrometX_8;
                    podatkiObj['PrometX_9'] := mesecniPrometX_9;
                    podatkiObj['PrometX_10'] := mesecniPrometX_10;
                    podatkiObj['PrometX_11'] := mesecniPrometX_11;
                    podatkiObj['PrometX_12'] := mesecniPrometX_12;
                    podatkiObj['PlanXplus1'] := rast1;
                    podatkiObj['PlanXplus2'] := rast2;
                    podatkiObj['PlanXplus3'] := rast3;
                    podatkiObj['PlanXplus4'] := rast4;
                    podatkiObj['DobavniRokStrojev'] := dobavniRokStrojev;
                    podatkiObj['MontazaStrojev'] := montazaStrojev;
                    podatkiObj['RabljeniStroji'] := rabljeniStroji;
                    podatkiObj['DrugaLokacijaLastna'] := drugaLokacijaLastna;
                    podatkiObj['DrugaLokacijaNajeta'] := drugaLokacijaNajeta;
                    podatkiObj['PosebniDelovniPogoji'] := posebniDelovniPogoji;
                    podatkiObj['UporabaNevarnihSnovi'] := uporabaNevarnihSnovi;
                    podatkiObj['VgradnjaNevarnihSnovi'] := vgradnjaNevarnihSnovi;
                    podatkiObj['Izmene'] := izmene;
                    podatkiObj['ZasedenostProizvodnje'] := zasedenostProizvodnje;
                    podatkiObj['LocenoSkladisce_nad10'] := locenoSkladisce_nad10;
                    podatkiObj['LocenoSkladisce_nad10_Delez'] := locenoSkladisce_nad10_Delez;
                    podatkiObj['LocenoSkladisce_nad10_Obdobje'] := locenoSkladisce_nad10_Obdobje;
                    podatkiObj['LocenoSkladisce_pod10'] := locenoSkladisce_pod10;
                    podatkiObj['LocenoSkladisce_pod10_Delez'] := locenoSkladisce_pod10_Delez;
                    podatkiObj['LocenoSkladisce_pod10_Obdobje'] := locenoSkladisce_pod10_Obdobje;
                    podatkiObj['ZamikPlacil'] := zamikPlacil;
                    podatkiObj['TipProizvodnje'] := tipProizvodnje;
                    podatkiObj['IzracunZV_Leto'] := letoZV;
                    podatkiObj['Ciscenje_Trajanje'] := parameter1_1;
                    podatkiObj['Ciscenje_Zacetek'] := parameter1_2;
                    podatkiObj['Dokumentacija_Trajanje'] := parameter2_1;
                    podatkiObj['Dokumentacija_Zacetek'] := parameter2_2;
                    podatkiObj['Gradnja_Trajanje'] := parameter3_1;
                    podatkiObj['Gradnja_Zacetek'] := parameter3_2;
                    podatkiObj['DobavaStrojev_Trajanje'] := parameter4_1;
                    podatkiObj['DobavaStrojev_Zacetek'] := parameter4_2;
                    podatkiObj['MontazaStrojev_Trajanje'] := parameter5_1;
                    podatkiObj['MontazaStrojev_Zacetek'] := parameter5_2;
                    podatkiObj['PolneZmogljivosti_Trajanje'] := parameter6_1;
                    podatkiObj['VarnostniDodatek'] := parameter7_1;
                    podatkiObj['KorekcijskiDodatek'] := korigiranMDJ;
                    podatkiObj['KorekcijskiDodatek_Max'] := maxPribitek;
                    podatkiObj['Faktor_VplivNaOkolje_NS'] := korFaktor1;
                    podatkiObj['Faktor_VplivNaOkolje_NV'] := korFaktor2;
                    podatkiObj['Faktor_PosebniDelovniPogoji'] := korFaktor3;
                    podatkiObj['Faktor_RabljeniStroji'] := korFaktor4;
                    podatkiObj['Faktor_Izmene'] := korFaktor5;
                    podatkiObj['Zavarovanje'] := zavarovanje;
                    podatkiObj['ZavarovanjeKD'] := zavarovanjeKD;
                    podatkiObj['ZavarovanjeKD_ZV'] := zavarovanjeKD_ZV;
                    podatkiObj['ZavarovanjeNS'] := zavarovanjeNS;
                    podatkiObj['ZavarovanjeNS_StrStoritev'] := zavarovanjeNS_StrStoritev;
                    podatkiObj['ZavarovanjeNS_Amortizacija'] := zavarovanjeNS_Amortizacija;
                    podatkiObj['ZavarovanjeNS_StrDela'] := zavarovanjeNS_StrDela;
                    podatkiObj['ZavarovanjeNS_StrDrugi'] := zavarovanjeNS_StrDrugi;
                    podatkiObj['ZavarovanjeNS_Dobicek'] := zavarovanjeNS_Dobicek;
                    podatkiObj['ZavarovanjeMDJ'] := zavarovanjeMDJ;
                    podatkiObj['ZavarovanjeZacetek'] := zavarovanjeZacetek;
                    podatkiObj['Premija'] := zavarovanjePremija;

                    WebClientShranjevanje.Post('Podatki', podatkiObj);

                  except
                     ShowMessage(LjezikObvestilo_ServerShranjevanje_01);
                  end;
               end
               else
                 ShowMessage(LjezikObvestilo_ServerShranjevanje_02);

             finally
           //    PovezavaServerShranjevanje.Connected := false;
               fmOsnovniKontroler.Cursor := crDefault;
             end;
          end;


      begin
         try
           WebClientShranjevanje.RawInvoke('IKalkulacijeService.PoizvedbaKalkulacije', [vpDavcnaStevilkaPodjetja.Text, vpOpazovanoLeto.Text, izUporabnik.Caption], @OnResultShranjevanjePodatkov );
         except
           ShowMessage(LjezikObvestilo_ServerShranjevanje_03);
         end;
      end;

      procedure OnResultUpdatePodatkov( Response: TXDataClientResponse );
      var podatkiObj : TJSObject;
      begin
        try
           promet := StrToFloat(vpVnosFRPPromet.Text);
           zalogeZacetek := StrToFloat(vpVnosFRPZalogeZacetek.Text);
           zalogeKonec := StrToFloat(vpVnosFRPZalogeKonec.Text);
           internoProizvedeniDodatki := StrToFloat(vpVnosFRPInternoProizvedeniDodatki.Text);
           stroskiSurovin := StrToFloat(vpVnosFRPStroskiSurovine.Text);
           stroskiMateriala := StrToFloat(vpVnosFRPStroskiMaterial.Text);
           stroskiEnergije := StrToFloat(vpVnosFRPStroskiEnergija.Text);
           stroskiTrgovskegaBlaga :=StrToFloat(vpVnosFRPStroskiTrgovskoBlago.Text);
           stroskiStoritev := StrToFloat(vpVnosFRPStroskiStoritve.Text);
           drugiStroski := StrToFloat(vpVnosFRPDrugiStroski.Text);
           prejeteZavarovalnine := StrToFloat(vpVnosFRPPrejeteZavarovalnine.Text);
           mesecniPrometXminus1_1 := StrToFloat(vpTabelaPromet_ProdajaXminus1_1.Text);
           mesecniPrometXminus1_2 := StrToFloat(vpTabelaPromet_ProdajaXminus1_2.Text);
           mesecniPrometXminus1_3 := StrToFloat(vpTabelaPromet_ProdajaXminus1_3.Text);
           mesecniPrometXminus1_4 := StrToFloat(vpTabelaPromet_ProdajaXminus1_4.Text);
           mesecniPrometXminus1_5 := StrToFloat(vpTabelaPromet_ProdajaXminus1_5.Text);
           mesecniPrometXminus1_6 := StrToFloat(vpTabelaPromet_ProdajaXminus1_6.Text);
           mesecniPrometXminus1_7 := StrToFloat(vpTabelaPromet_ProdajaXminus1_7.Text);
           mesecniPrometXminus1_8 := StrToFloat(vpTabelaPromet_ProdajaXminus1_8.Text);
           mesecniPrometXminus1_9 := StrToFloat(vpTabelaPromet_ProdajaXminus1_9.Text);
           mesecniPrometXminus1_10 := StrToFloat(vpTabelaPromet_ProdajaXminus1_10.Text);
           mesecniPrometXminus1_11 := StrToFloat(vpTabelaPromet_ProdajaXminus1_11.Text);
           mesecniPrometXminus1_12 := StrToFloat(vpTabelaPromet_ProdajaXminus1_12.Text);
           mesecniPrometX_1 := StrToFloat(vpTabelaPromet_ProdajaX_1.Text);
           mesecniPrometX_2 := StrToFloat(vpTabelaPromet_ProdajaX_2.Text);
           mesecniPrometX_3 := StrToFloat(vpTabelaPromet_ProdajaX_3.Text);
           mesecniPrometX_4 := StrToFloat(vpTabelaPromet_ProdajaX_4.Text);
           mesecniPrometX_5 := StrToFloat(vpTabelaPromet_ProdajaX_5.Text);
           mesecniPrometX_6 := StrToFloat(vpTabelaPromet_ProdajaX_6.Text);
           mesecniPrometX_7 := StrToFloat(vpTabelaPromet_ProdajaX_7.Text);
           mesecniPrometX_8 := StrToFloat(vpTabelaPromet_ProdajaX_8.Text);
           mesecniPrometX_9 := StrToFloat(vpTabelaPromet_ProdajaX_9.Text);
           mesecniPrometX_10 := StrToFloat(vpTabelaPromet_ProdajaX_10.Text);
           mesecniPrometX_11 := StrToFloat(vpTabelaPromet_ProdajaX_11.Text);
           mesecniPrometX_12 := StrToFloat(vpTabelaPromet_ProdajaX_12.Text);
           rast1 := vpPlanProdaje_RastXplus1.Position;
           rast2 := vpPlanProdaje_RastXplus2.Position;
           rast3 := vpPlanProdaje_RastXplus3.Position;
           rast4 := vpPlanProdaje_RastXplus4.Position;

           dobavniRokStrojev := vpOrganizacija_Odgovor1.Position;
           montazaStrojev := vpOrganizacija_Odgovor2.Position;

           if vpOrganizacija_OdgovorDa3.Checked = true then
                rabljeniStroji := 1
           else
               rabljeniStroji := 0;

           if vpOrganizacija_OdgovorDa4.Checked = true then
               drugaLokacijaLastna := 1
           else
              drugaLokacijaLastna := 0;

           if vpOrganizacija_OdgovorDa5.Checked = true then
               drugaLokacijaNajeta := 1
           else
               drugaLokacijaNajeta := 0;

           if vpOrganizacija_OdgovorDa6.Checked = true then
               PosebniDelovniPogoji := 1
           else
               PosebniDelovniPogoji := 0;

           if vpOrganizacija_OdgovorDa7.Checked = true then
                uporabaNevarnihSnovi := 1
           else
                uporabaNevarnihSnovi := 0;

          if vpOrganizacija_OdgovorDa8.Checked = true then
               vgradnjaNevarnihSnovi := 1
          else
               vgradnjaNevarnihSnovi := 0;

          izmene := vpOrganizacija_Odgovor9.Position;

          zasedenostProizvodnje := vpOrganizacija_Odgovor10.Position;;

          if vpOrganizacija_OdgovorDa11_1.Checked = true then
               locenoSkladisce_nad10 := 1
          else
               locenoSkladisce_nad10 := 0;

          locenoSkladisce_nad10_Delez := vpOrganizacija_Odgovor11_2.Position;;

          locenoSkladisce_nad10_Obdobje := vpOrganizacija_Odgovor11_3.Position;;

          if vpOrganizacija_OdgovorDa11_4.Checked = true then
               locenoSkladisce_pod10 := 1
          else
               locenoSkladisce_pod10 := 0;

          locenoSkladisce_pod10_Delez := vpOrganizacija_Odgovor11_5.Position;;

          locenoSkladisce_pod10_Obdobje := vpOrganizacija_Odgovor11_6.Position;;

          zamikPlacil := vpOrganizacija_Odgovor12.Position;

          tipProizvodnje := vpOrganizacija_Odgovor13.ItemIndex;

          if vpLetoPriporoceneZV.Position = vpLetoPriporoceneZV.Max then
               letoZV := 2       // Če je izračun za leto MIN
          else
               letoZV := 1;      // Če je izračun za leto MAX

          parameter1_1 := vpParameter1_1.Position;
          parameter1_2 := vpParameter1_2.Position;
          parameter2_1 := vpParameter2_1.Position;
          parameter2_2 := vpParameter2_2.Position;
          parameter3_1 := vpParameter3_1.Position;
          parameter3_2 := vpParameter3_2.Position;
          parameter4_1 := vpParameter4_1.Position;
          parameter4_2 := vpParameter4_2.Position;
          parameter5_1 := vpParameter5_1.Position;
          parameter5_2 := vpParameter5_2.Position;
          parameter6_1 := vpParameter6_1.Position;
          parameter7_1 := vpParameter7_1.Position;

          if vpKorekcijskiFaktorji.Checked = true then    // polnjenje spremnljivke z vrednostjo ali je izbran osnovni ali korigiran izračun MDJ
               korigiranMDJ := 1
          else
               korigiranMDJ := 0;

          maxPribitek := vpMaksimalniPribitek.Position;

          korFaktor1 := vpPloscicaKorekcijskiFaktorji_KFDrsnik1_4.Position;
          korFaktor2 := vpPloscicaKorekcijskiFaktorji_KFDrsnik2_4.Position;
          korFaktor3 := vpPloscicaKorekcijskiFaktorji_KFDrsnik3_4.Position;
          korFaktor4 := vpPloscicaKorekcijskiFaktorji_KFDrsnik4_4.Position;
          korFaktor5 := vpPloscicaKorekcijskiFaktorji_KFDrsnik5_4.Position;

          if vpZavarovanjeDa.Checked = true then
               zavarovanje :=  1
          else
               zavarovanje := 0;

          if vpZavarovanjeDaKosmatiDobicek.Checked = true then
                zavarovanjeKD :=  1
          else
                zavarovanjeKD := 0;

          zavarovanjeKD_ZV := StrToFloat(vpZVKosmatiDobicek.Text);

          if vpZavarovanjeDANastetiStroski.Checked = true then
                zavarovanjeNS :=  1
          else
                zavarovanjeNS := 0;

          zavarovanjeNS_StrStoritev := StrToFloat(vpZavarovaniStroski2_2.Text);
          zavarovanjeNS_Amortizacija := StrToFloat(vpZavarovaniStroski2_3.Text);
          zavarovanjeNS_StrDela := StrToFloat(vpZavarovaniStroski2_4.Text);
          zavarovanjeNS_StrDrugi := StrToFloat(vpZavarovaniStroski2_5.Text);
          zavarovanjeNS_Dobicek := StrToFloat(vpZavarovaniStroski2_6.Text);

          zavarovanjeMDJ := vpMDJ.ItemIndex;

          zavarovanjeZacetek := DateTimeToStr(vpZacetekZavarovanja.Date);

          zavarovanjePremija := StrToFloat(vpPremija.Text);

          try
            podatkiObj := TJSObject.New;
            podatkiObj['Stevilka'] := stevilkaZapisa;
            podatkiObj['OpazovanoObdobje'] := StrToInt(vpOpazovanoLeto.Text);
            podatkiObj['Promet'] := promet;
            podatkiObj['ZalogeZacetek'] := zalogeZacetek;
            podatkiObj['ZalogeKonec'] := zalogeKonec;
            podatkiObj['InternoProizvedeniDodatki'] := internoProizvedeniDodatki;
            podatkiObj['StroskiSurovin'             ] := stroskiSurovin;
            podatkiObj['Stroskimateriala'] := stroskiMateriala;
            podatkiObj['StroskiEnergije'] := stroskiEnergije;
            podatkiObj['StroskiTrgovskegaBlaga'] := stroskiTrgovskegaBlaga;
            podatkiObj['StroskiZunanjihIzvajalcev'] := stroskiStoritev;
            podatkiObj['DrugiStroski'] := drugiStroski;
            podatkiObj['PrejeteZavarovalnine'] := prejeteZavarovalnine;
            podatkiObj['PrometXminus1_1'] := mesecniPrometXminus1_1;
            podatkiObj['PrometXminus1_2'] := mesecniPrometXminus1_2;
            podatkiObj['PrometXminus1_3'] := mesecniPrometXminus1_3;
            podatkiObj['PrometXminus1_4'] := mesecniPrometXminus1_4;
            podatkiObj['PrometXminus1_5'] := mesecniPrometXminus1_5;
            podatkiObj['PrometXminus1_6'] := mesecniPrometXminus1_6;
            podatkiObj['PrometXminus1_7'] := mesecniPrometXminus1_7;
            podatkiObj['PrometXminus1_8'] := mesecniPrometXminus1_8;
            podatkiObj['PrometXminus1_9'] := mesecniPrometXminus1_9;
            podatkiObj['PrometXminus1_10'] := mesecniPrometXminus1_10;
            podatkiObj['PrometXminus1_11'] := mesecniPrometXminus1_11;
            podatkiObj['PrometXminus1_12'] := mesecniPrometXminus1_12;
            podatkiObj['PrometX_1'] := mesecniPrometX_1;
            podatkiObj['PrometX_2'] := mesecniPrometX_2;
            podatkiObj['PrometX_3'] := mesecniPrometX_3;
            podatkiObj['PrometX_4'] := mesecniPrometX_4;
            podatkiObj['PrometX_5'] := mesecniPrometX_5;
            podatkiObj['PrometX_6'] := mesecniPrometX_6;
            podatkiObj['PrometX_7'] := mesecniPrometX_7;
            podatkiObj['PrometX_8'] := mesecniPrometX_8;
            podatkiObj['PrometX_9'] := mesecniPrometX_9;
            podatkiObj['PrometX_10'] := mesecniPrometX_10;
            podatkiObj['PrometX_11'] := mesecniPrometX_11;
            podatkiObj['PrometX_12'] := mesecniPrometX_12;
            podatkiObj['PlanXplus1'] := rast1;
            podatkiObj['PlanXplus2'] := rast2;
            podatkiObj['PlanXplus3'] := rast3;
            podatkiObj['PlanXplus4'] := rast4;
            podatkiObj['DobavniRokStrojev'] := dobavniRokStrojev;
            podatkiObj['MontazaStrojev'] := montazaStrojev;
            podatkiObj['RabljeniStroji'] := rabljeniStroji;
            podatkiObj['DrugaLokacijaLastna'] := drugaLokacijaLastna;
            podatkiObj['DrugaLokacijaNajeta'] := drugaLokacijaNajeta;
            podatkiObj['PosebniDelovniPogoji'] := posebniDelovniPogoji;
            podatkiObj['UporabaNevarnihSnovi'] := uporabaNevarnihSnovi;
            podatkiObj['VgradnjaNevarnihSnovi'] := vgradnjaNevarnihSnovi;
            podatkiObj['Izmene'] := izmene;
            podatkiObj['ZasedenostProizvodnje'] := zasedenostProizvodnje;
            podatkiObj['LocenoSkladisce_nad10'] := locenoSkladisce_nad10;
            podatkiObj['LocenoSkladisce_nad10_Delez'] := locenoSkladisce_nad10_Delez;
            podatkiObj['LocenoSkladisce_nad10_Obdobje'] := locenoSkladisce_nad10_Obdobje;
            podatkiObj['LocenoSkladisce_pod10'] := locenoSkladisce_pod10;
            podatkiObj['LocenoSkladisce_pod10_Delez'] := locenoSkladisce_pod10_Delez;
            podatkiObj['LocenoSkladisce_pod10_Obdobje'] := locenoSkladisce_pod10_Obdobje;
            podatkiObj['ZamikPlacil'] := zamikPlacil;
            podatkiObj['TipProizvodnje'] := tipProizvodnje;
            podatkiObj['IzracunZV_Leto'] := letoZV;
            podatkiObj['Ciscenje_Trajanje'] := parameter1_1;
            podatkiObj['Ciscenje_Zacetek'] := parameter1_2;
            podatkiObj['Dokumentacija_Trajanje'] := parameter2_1;
            podatkiObj['Dokumentacija_Zacetek'] := parameter2_2;
            podatkiObj['Gradnja_Trajanje'] := parameter3_1;
            podatkiObj['Gradnja_Zacetek'] := parameter3_2;
            podatkiObj['DobavaStrojev_Trajanje'] := parameter4_1;
            podatkiObj['DobavaStrojev_Zacetek'] := parameter4_2;
            podatkiObj['MontazaStrojev_Trajanje'] := parameter5_1;
            podatkiObj['MontazaStrojev_Zacetek'] := parameter5_2;
            podatkiObj['PolneZmogljivosti_Trajanje'] := parameter6_1;
            podatkiObj['VarnostniDodatek'] := parameter7_1;
            podatkiObj['KorekcijskiDodatek'] := korigiranMDJ;
            podatkiObj['KorekcijskiDodatek_Max'] := maxPribitek;
            podatkiObj['Faktor_VplivNaOkolje_NS'] := korFaktor1;
            podatkiObj['Faktor_VplivNaOkolje_NV'] := korFaktor2;
            podatkiObj['Faktor_PosebniDelovniPogoji'] := korFaktor3;
            podatkiObj['Faktor_RabljeniStroji'] := korFaktor4;
            podatkiObj['Faktor_Izmene'] := korFaktor5;
            podatkiObj['Zavarovanje'] := zavarovanje;
            podatkiObj['ZavarovanjeKD'] := zavarovanjeKD;
            podatkiObj['ZavarovanjeKD_ZV'] := zavarovanjeKD_ZV;
            podatkiObj['ZavarovanjeNS'] := zavarovanjeNS;
            podatkiObj['ZavarovanjeNS_StrStoritev'] := zavarovanjeNS_StrStoritev;
            podatkiObj['ZavarovanjeNS_Amortizacija'] := zavarovanjeNS_Amortizacija;
            podatkiObj['ZavarovanjeNS_StrDela'] := zavarovanjeNS_StrDela;
            podatkiObj['ZavarovanjeNS_StrDrugi'] := zavarovanjeNS_StrDrugi;
            podatkiObj['ZavarovanjeNS_Dobicek'] := zavarovanjeNS_Dobicek;
            podatkiObj['ZavarovanjeMDJ'] := zavarovanjeMDJ;
            podatkiObj['ZavarovanjeZacetek'] := zavarovanjeZacetek;
            podatkiObj['premija'] := zavarovanjePremija;

            WebClientShranjevanje.Put('Podatki', podatkiObj);

          except
               ShowMessage(LjezikObvestilo_ServerShranjevanje_04);
            end;
        finally
          PovezavaServerShranjevanje.Connected := false;
          fmOsnovniKontroler.Cursor := crDefault;
        end;
      end;

  begin
    stevilkaZapisa := 0;
    try
      LArray := TJSArray( TJSObject( Response.Result )['value']);
      LItem := TJSObject( LArray[0]);

      if LItem <> null then begin
          StevilkaZapisa := Integer(LItem['Stevilka']);
          ShowMessage(LjezikObvestilo_ServerShranjevanje_05);
          try
            WebClientShranjevanje.RawInvoke('IKalkulacijeService.UpdateKalkulacije', [StevilkaZapisa, vpDavcnaStevilkaPodjetja.Text, vpOpazovanoLeto.Text, FormatDateTime('dd.mm.yyyy', Now),  izUporabnik.Caption, vpImePodjetja.Text, vpNaslovPodjetja.Text, vpDejavnostPodjetja.Text], @OnResultUpdatePodatkov );
          except
            ShowMessage(LjezikObvestilo_ServerShranjevanje_06);
          end;
      end
      else begin
          ShowMessage(LjezikObvestilo_ServerShranjevanje_09);
          try
            WebClientShranjevanje.RawInvoke('IKalkulacijeService.VnosKalkulacije', [vpDavcnaStevilkaPodjetja.Text, vpOpazovanoLeto.Text, FormatDateTime('dd.mm.yyyy', Now),  izUporabnik.Caption, vpImePodjetja.Text, vpNaslovPodjetja.Text, vpDejavnostPodjetja.Text], @OnResultPoizvedbaShranjevanjePodatkov );
          except
            ShowMessage(LjezikObvestilo_ServerShranjevanje_07);
          end;
      end;
    except
      ShowMessage(LjezikObvestilo_ServerShranjevanje_08);
      PovezavaServerShranjevanje.Connected := false;
      fmOsnovniKontroler.Cursor := crDefault;
    end;
  end;



begin
  try
     WebClientShranjevanje.RawInvoke('IKalkulacijeService.PoizvedbaKalkulacije', [vpDavcnaStevilkaPodjetja.Text, vpOpazovanoLeto.Text, izUporabnik.Caption], @OnResultOsnovnaPoizvedba );
  except
    ShowMessage(LjezikObvestilo_ServerShranjevanje_03);
  end;
end;

procedure TfmOsnovniKontroler.PovezavaServerShranjevanjeError(
  Error: TXDataWebConnectionError);
begin
  showMessage(LjezikObvestilo_ServerNiPovezave_01);
end;


procedure TfmOsnovniKontroler.PovezavaServerSkodeConnect(Sender: TObject);

   procedure OnResultOsnovnaPoizvedbaShranjevanje( Response: TXDataClientResponse );
   var
    vrstaShranjevanja : Integer;
    stevilkaZapisa : Integer;
    LArray : TJSArray;
    LItem : TJSObject;


    procedure OnResultIskanjeSkodeShranjevanje( Response: TXDataClientResponse );

     procedure ShraniSkode;
     var skodeObj : TJSObject;
     begin
        try
          try
            skodeObj := TJSObject.New;
            skodeObj['Stevilka'] := stevilkaZapisa;
            skodeObj['OpazovanoObdobje'] := StrToInt(vpOpazovanoLeto.Text);
            skodeObj['Izpad_1'] := izpad[1];
            skodeObj['IzpadZnesek_1'] := izpadZnesek[1];
            skodeObj['Izpad_2'] := izpad[2];
            skodeObj['IzpadZnesek_2'] := izpadZnesek[2];
            skodeObj['Izpad_3'] := izpad[3];
            skodeObj['IzpadZnesek_3'] := izpadZnesek[3];
            skodeObj['Izpad_4'] := izpad[4];
            skodeObj['IzpadZnesek_4'] := izpadZnesek[4];
            skodeObj['Izpad_5'] := izpad[5];
            skodeObj['IzpadZnesek_5'] := izpadZnesek[5];
            skodeObj['Izpad_6'] := izpad[6];
            skodeObj['IzpadZnesek_6'] := izpadZnesek[6];
            skodeObj['Izpad_7'] := izpad[7];
            skodeObj['IzpadZnesek_7'] := izpadZnesek[7];
            skodeObj['Izpad_8'] := izpad[8];
            skodeObj['IzpadZnesek_8'] := izpadZnesek[8];
            skodeObj['Izpad_9'] := izpad[9];
            skodeObj['IzpadZnesek_9'] := izpadZnesek[9];
            skodeObj['Izpad_10'] := izpad[10];
            skodeObj['IzpadZnesek_10'] := izpadZnesek[10];
            skodeObj['Izpad_11'] := izpad[11];
            skodeObj['IzpadZnesek_11'] := izpadZnesek[11];
            skodeObj['Izpad_12'] := izpad[12];
            skodeObj['IzpadZnesek_12'] := izpadZnesek[12];
            if vpPovisaniPoslovniStroskiZnesek.Text <> '' then
              skodeObj['PovisaniPoslovniStroski'] := StrToFloat(vpPovisaniPoslovniStroskiZnesek.Text)
            else
              skodeObj['PovisaniPoslovniStroski'] := 0;
            if vpResenPrometZnesek.Text <> '' then
              skodeObj['ResenPromet_Znesek'] := StrToFloat(vpResenPrometZnesek.Text)
            else
              skodeObj['ResenPromet_Znesek'] := 0;
            skodeObj['ResenPromet_Obdobje'] := vpPovisaniPoslovniStroskiObdobje.ItemIndex;
            skodeObj['Prihranki_1'] := prihranki[1];
            skodeObj['PrihrankiOpis_1'] := prihrankiOpis[1];
            skodeObj['PrihrankiZnesek_1'] := prihrankiZnesek[1];
            skodeObj['Prihranki_2'] := prihranki[2];
            skodeObj['PrihrankiOpis_2'] := prihrankiOpis[2];
            skodeObj['PrihrankiZnesek_2'] := prihrankiZnesek[2];
            skodeObj['Prihranki_3'] := prihranki[3];
            skodeObj['PrihrankiOpis_3'] := prihrankiOpis[3];
            skodeObj['PrihrankiZnesek_3'] := prihrankiZnesek[3];
            skodeObj['Prihranki_4'] := prihranki[4];
            skodeObj['PrihrankiOpis_4'] := prihrankiOpis[4];
            skodeObj['PrihrankiZnesek_4'] := prihrankiZnesek[4];
            skodeObj['Prihranki_5'] := prihranki[5];
            skodeObj['PrihrankiOpis_5'] := prihrankiOpis[5];
            skodeObj['PrihrankiZnesek_5'] := prihrankiZnesek[5];
            skodeObj['Prihranki_6'] := prihranki[6];
            skodeObj['PrihrankiOpis_6'] := prihrankiOpis[6];
            skodeObj['PrihrankiZnesek_6'] := prihrankiZnesek[6];
            skodeObj['Prihranki_7'] := prihranki[7];
            skodeObj['PrihrankiOpis_7'] := prihrankiOpis[7];
            skodeObj['PrihrankiZnesek_7'] := prihrankiZnesek[7];
            skodeObj['Prihranki_8'] := prihranki[8];
            skodeObj['PrihrankiOpis_8'] := prihrankiOpis[8];
            skodeObj['PrihrankiZnesek_8'] := prihrankiZnesek[8];
            skodeObj['Prihranki_9'] := prihranki[9];
            skodeObj['PrihrankiOpis_9'] := prihrankiOpis[9];
            skodeObj['PrihrankiZnesek_9'] := prihrankiZnesek[9];
            skodeObj['Prihranki_10'] := prihranki[10];
            skodeObj['PrihrankiOpis_10'] := prihrankiOpis[10];
            skodeObj['PrihrankiZnesek_10'] := prihrankiZnesek[10];
            if ploscicaPrihranki_PrihranekSkupajZnesek.Text <> '' then
              skodeObj['Prihranki_Skupaj'] := StrToFloat(ploscicaPrihranki_PrihranekSkupajZnesek.Text)
            else
              skodeObj['Prihranki_Skupaj'] := 0;
            skodeObj['Prihranki_Obdobje'] := vpPrihrankiObdobje.ItemIndex;

            if vrstaShranjevanja = 1 then
              WebClientSkode.Put('Skode', skodeObj)
            else if vrstaShranjevanja = 2 then
              WebClientSkode.Post('Skode', skodeObj);

            ShowMessage(LjezikObvestilo_ServerSkode_09);
          except
            ShowMessage(LjezikObvestilo_ServerSkode_08);   // ***
          end;
        finally
          PovezavaServerShranjevanje.Connected := false;
          fmOsnovniKontroler.Cursor := crDefault;
        end;
      end;


      begin
        vrstaShranjevanja := 0;
        try
          LArray := TJSArray( TJSObject( Response.Result )['value']);
          LItem := TJSObject( LArray[0]);

          if LItem <> null then begin
            try
               vrstaShranjevanja := 1; //dejansko naredi update
               ShraniSkode;
            except
              ShowMessage(LjezikObvestilo_ServerSkode_08); //***
            end;
          end
          else begin
            try
              vrstaShranjevanja := 2;  // shrani nov zapis
              ShraniSkode;
            except
              ShowMessage(LjezikObvestilo_ServerSkode_08);
            end;
          end;
        except
          ShowMessage(LjezikObvestilo_ServerSkode_03);
          PovezavaServerSkode.Connected := false;
          fmOsnovniKontroler.Cursor := crDefault;
        end;
      end;


  begin
    stevilkaZapisa := 0;
    try
      LArray := TJSArray( TJSObject( Response.Result )['value']);
      LItem := TJSObject( LArray[0]);

      if LItem <> null then begin
          stevilkaZapisa := Integer(LItem['Stevilka']);
          try
            WebClientSkode.RawInvoke('ISkodeService.GetSkode', [stevilkaZapisa], @OnResultIskanjeSkodeShranjevanje);
          except
            ShowMessage(LjezikObvestilo_ServerSkode_04);
          end;
      end
      else begin
         ShowMessage(LjezikObvestilo_ServerSkode_07);
         PovezavaServerSkode.Connected := false;
         fmOsnovniKontroler.Cursor := crDefault;
      end;
    except
      ShowMessage(LjezikObvestilo_ServerSkode_03);
      PovezavaServerSkode.Connected := false;
      fmOsnovniKontroler.Cursor := crDefault;
    end;
  end;



   procedure OnResultOsnovnaPoizvedbaNalaganje( Response: TXDataClientResponse );
   var
    stevilkaZapisa : Integer;
    LArray : TJSArray;
    LItem : TJSObject;
    i, j : Integer;

      procedure OnResultIskanjeSkodeNalaganje( Response: TXDataClientResponse );
      var vnosnoPolje1, vnosnoPolje2, poljeBranje1, poljeBranje2 : TEdit;
          poljeBranje : TLabel;
          vrstica, stolpec : Integer;
          leto, mesec, dan : Word;
          tekst, s1, s2 : String;

      begin
        try
          LArray := TJSArray( TJSObject( Response.Result )['value']);

          if LArray <> nil then begin
             LItem := TJSObject( LArray[0]);

            // 1. Nalaganje podatkov o izpadu prometa

             // podatki o začetku zavarovanja
             dan := DayOf(vpZavarovanjeOd.Date);
             mesec := MonthOf(vpZavarovanjeOd.Date);
             leto := YearOf(vpZavarovanjeOd.Date);
             // škoda se lahko simulira samo na 1. v mesecu, kar pomen, če začetek zaavrovanja ni 1. v mesecu, je lahko škoda šele naslednji mesec (1.)
             if dan > 1 then
                mesec := mesec + 1;
             // izračun polja, kjer se v tabeli začne zastoj
             vrstica := vpDatumFizicneSkode.ItemIndex + mesec; // ugotovi, kateri mesec je nastala škoda (tj. bila izbrana iz spustnega seznama)
             stolpec := StrToInt(vpOpazovanoLeto.Text) - leto + 2 ;
             // počisti polne celice)
             for i := 1 to 4 do begin
                for j := 1 to 12 do begin
                  vnosnoPolje1 := FindComponent('ploscicaPromet_Promet_'+IntToStr(i)+'_'+IntToStr(j)) as TEdit;
                  vnosnoPolje1.Text := '';
                  vnosnoPolje1.Color := clWhite;
                end;
             end;
             for i := 1 to 3 do begin
                for j := 1 to 12 do begin
                  vnosnoPolje1 := FindComponent('ploscicaPromet_Indeks_'+IntToStr(i)+'_'+IntToStr(j)) as TEdit;
                  vnosnoPolje1.Text := '';
                  vnosnoPolje1.Color := clWhite;
                end;
             end;

             // vnos prometov v višini 0 (popolni zastoj) za število mesecev, kot je bila izbrana dolžina popolnega zastoja
             for i := 1 to 12 do begin
               if vrstica > 12 then begin
                  stolpec := stolpec + 1;
                  vrstica := vrstica - 12;
               end;
               vnosnoPolje1 := FindComponent('ploscicaPromet_Promet_'+IntToStr(stolpec)+'_'+IntToStr(vrstica)) as TEdit;
               poljeBranje := FindComponent('ploscicaAnalizeProdaje_izPromet_'+IntToStr(stolpec)+'_'+IntToStr(vrstica)) as TLabel;

               if Integer(LItem['Izpad_' + IntToStr(i)]) = 1 then begin
                  if LItem['IzpadZnesek_' + IntToStr(i)] <> nil then
                       vnosnoPolje1.Text := FormatFloat('###,###,##0.00', Real(LItem['IzpadZnesek_' + IntToStr(i)]))
                     else
                       vnosnoPolje1.Text := FormatFloat('###,###,##0.00', 0);
                  vnosnoPolje1.Color := clWebCornSilk;
               end;

               vrstica := vrstica + 1;
             end;

             // preveri ali je celica prazna in če je, vanjo prepiše vrednost tabele z analizo planiranih prometov
             for i := 1 to 4 do begin
               for j := 1 to 12 do begin
                  vnosnoPolje1 := FindComponent('ploscicaPromet_Promet_'+IntToStr(i)+'_'+IntToStr(j)) as TEdit;
                  if (vnosnoPolje1.Text = '') then begin
                     poljeBranje := FindComponent('ploscicaAnalizeProdaje_izPromet_'+IntToStr(i)+'_'+IntToStr(j)) as TLabel;
                     tekst := poljeBranje.Caption;
                     vnosnoPolje1.Text := tekst;
                  end;
               end;
             end;
             // Preračuna indekse (vse)
             for i := 1 to 3 do begin
               for j := 1 to 12 do begin
                  vnosnoPolje1 := FindComponent('ploscicaPromet_Indeks_'+IntToStr(i)+'_'+IntToStr(j)) as TEdit;
                  poljeBranje2 := FindComponent('ploscicaPromet_Promet_'+IntToStr(i+1)+'_'+IntToStr(j)) as TEdit;
                  poljeBranje1 := FindComponent('ploscicaPromet_Promet_'+IntToStr(i)+'_'+IntToStr(j)) as TEdit;
                  s1 := Stringreplace(poljeBranje1.Text, '.', '', [rfreplaceAll, rfIgnorecase]);
                  s2 := Stringreplace(poljeBranje2.Text, '.', '', [rfreplaceAll, rfIgnorecase]);
                  vnosnoPolje1.Text := FormatFloat('###,###,##0.00', StrToFloat(s2) / StrToFloat(s1) * 100);
               end;
             end;

             if vpGrafikaSkode.Position = 1 then
                IzrisGrafaSimulacijeSkod;

             // 2. Nalaganje pdoatkov o povišanih poslovnih stroških
             vpPovisaniPoslovniStroskiZnesek.Text := FormatFloat('###,###,##0.00', Real(LItem['PovisaniPoslovniStroski']));
             vpResenPrometZnesek.Text := FormatFloat('###,###,##0.00', Real(LItem['ResenPromet_Znesek']));
             vpPovisaniPoslovniStroskiObdobje.ItemIndex := Integer(LItem['ResenPromet_Obdobje']);

             // 3. Nalaganje pdoatkov o prihrankih
             for i := 1 to 10 do begin
                if Integer(LItem['Prihranki_' + IntToStr(i)]) = 1 then begin
                   vnosnoPolje1 := FindComponent('ploscicaPrihranki_Prihranek_1_'+IntToStr(i)) as TEdit;
                   vnosnoPolje1.Text := String(LItem['PrihrankiOpis_' + IntToStr(i)]);
                   vnosnoPolje2 := FindComponent('ploscicaPrihranki_Prihranek_2_'+IntToStr(i)) as TEdit;
                   if LItem['PrihrankiZnesek_' + IntToStr(i)] <> nil then
                     vnosnoPolje2.Text := FormatFloat('###,###,##0.00', Real(LItem['PrihrankiZnesek_' + IntToStr(i)]))
                   else
                     vnosnoPolje2.Text := FormatFloat('###,###,##0.00', 0);
                end;
             end;
             ploscicaPrihranki_PrihranekSkupajZnesek.Text := FormatFloat('###,###,##0.00', Real(LItem['Prihranki_Skupaj']));
             vpPrihrankiObdobje.ItemIndex := Integer(LItem['Prihranki_Obdobje']);

             ShowMessage(LjezikObvestilo_ServerSkode_06);

          end
          else begin
             ShowMessage(LjezikObvestilo_ServerSkode_05);
          end;
       finally
         PovezavaServerSkode.Connected := false;
         fmOsnovniKontroler.Cursor := crDefault;
       end;

     end;



   begin
     stevilkaZapisa := 0;
     try
       LArray := TJSArray( TJSObject( Response.Result )['value']);
       LItem := TJSObject( LArray[0]);

       if LItem <> null then begin
          stevilkaZapisa := Integer(LItem['Stevilka']);
          try
            WebClientSkode.RawInvoke('ISkodeService.GetSkode', [stevilkaZapisa], @OnResultIskanjeSkodeNalaganje);
          except
            ShowMessage(LjezikObvestilo_ServerSkode_04);
          end;
       end
       else begin
         ShowMessage(LjezikObvestilo_ServerSkode_02);
         PovezavaServerSkode.Connected := false;
         fmOsnovniKontroler.Cursor := crDefault;
       end;
     except
       ShowMessage(LjezikObvestilo_ServerShranjevanje_03);
       PovezavaServerSkode.Connected := false;
       fmOsnovniKontroler.Cursor := crDefault;
     end;
   end;




begin

  if posiljatelj = 6 then begin
    try
      try
         WebClientSkode.RawInvoke('IKalkulacijeService.PoizvedbaKalkulacije', [vpDavcnaStevilkaPodjetja.Text, vpOpazovanoLeto.Text, izUporabnik.Caption], @OnResultOsnovnaPoizvedbaShranjevanje );
      except
        ShowMessage(LjezikObvestilo_ServerSkode_01);
      end;
    finally
      posiljatelj := 0;
    end;
  end
  else if posiljatelj = 7 then begin
    try
      try
        WebClientSkode.RawInvoke('IKalkulacijeService.PoizvedbaKalkulacije', [vpDavcnaStevilkaPodjetja.Text, vpOpazovanoLeto.Text, izUporabnik.Caption], @OnResultOsnovnaPoizvedbaNalaganje );
      except
        ShowMessage(LjezikObvestilo_ServerSkode_01);
      end;
    finally
      posiljatelj := 0;
    end;
  end;
end;

procedure TfmOsnovniKontroler.PovezavaServerSkodeError(
  Error: TXDataWebConnectionError);
begin
  showMessage(LjezikObvestilo_ServerNiPovezave_01);
end;

procedure TfmOsnovniKontroler.DelovniListiPodatkiZaSimulacijoSkodChange(
  Sender: TObject);
begin
if ((DelovniListiPodatkiZaSimulacijoSkod.TabIndex = 1) or (DelovniListiPodatkiZaSimulacijoSkod.TabIndex = 2)) and (vpGrafikaSkode.Position = 1) then
   ploscicaGrafSimulacijaSkode.Visible := false
else if (DelovniListiPodatkiZaSimulacijoSkod.TabIndex = 0) and (vpGrafikaSkode.Position = 1) then
   ploscicaGrafSimulacijaSkode.Visible := true;

end;

procedure TfmOsnovniKontroler.gAvtomaticnoPolnjenjeClick(Sender: TObject);
var poljeBranje : TLabel;
    poljeBranje1, poljeBranje2 : TEdit;
    poljePisanje : TEdit;
    tekst, s1, s2 : String;
    stolpec, vrstica : Integer;
begin

  // preveri ali je celica prazna in če je, vanjo prepiše vrednost tabele z analizo planiranih prometov
  for stolpec := 1 to 4 do begin
    for vrstica := 1 to 12 do begin
      poljePisanje := FindComponent('ploscicaPromet_Promet_'+IntToStr(stolpec)+'_'+IntToStr(vrstica)) as TEdit;
      if (poljePisanje.Text = '') then begin
         poljeBranje := FindComponent('ploscicaAnalizeProdaje_izPromet_'+IntToStr(stolpec)+'_'+IntToStr(vrstica)) as TLabel;
         tekst := poljeBranje.Caption;
         poljePisanje.Text := tekst;
      end;
    end;
  end;
  // Preračuna indekse (vse)
  for stolpec := 1 to 3 do begin
    for vrstica := 1 to 12 do begin
      poljePisanje := FindComponent('ploscicaPromet_Indeks_'+IntToStr(stolpec)+'_'+IntToStr(vrstica)) as TEdit;
      poljeBranje2 := FindComponent('ploscicaPromet_Promet_'+IntToStr(stolpec+1)+'_'+IntToStr(vrstica)) as TEdit;
      poljeBranje1 := FindComponent('ploscicaPromet_Promet_'+IntToStr(stolpec)+'_'+IntToStr(vrstica)) as TEdit;
      s1 := Stringreplace(poljeBranje1.Text, '.', '', [rfreplaceAll, rfIgnorecase]);
      s2 := Stringreplace(poljeBranje2.Text, '.', '', [rfreplaceAll, rfIgnorecase]);
      poljePisanje.Text := FormatFloat('###,###,##0.00', StrToFloat(s2) / StrToFloat(s1) * 100);
    end;
  end;
end;

procedure TfmOsnovniKontroler.gNaloziProjektClick(Sender: TObject);
var novoOkno : TfmOdpriKalkulacijo;

    procedure ObOdprtjuShowModal(AForm: TObject);
    begin
       with novoOkno do begin
         obvestiloBrisanje.Message := LjezikObvestiloBrisanje;
         obvestiloBrisanje.DialogText[7] := LjezikDa;
         obvestiloBrisanje.DialogText[8] := LjezikNe;
         obvestiloBrisanje.DialogText[0] :=  LjezikObvestilo_Tip;
         ploscicaNaslov.Caption := LfmOdpriKalkulacijo_ploscicaNaslov;
         gIzberi.Caption := LfmOdpriKalkulacijo_gIzberi;
         gIzbrisi.Caption := LfmOdpriKalkulacijo_gIzbrisi;
         gPreklici.Caption := LfmOdpriKalkulacijo_gPreklici;
       end;

       novoOkno.DataSetKalkulacije.QueryString := '$filter=uporabnik eq ''' + izUporabnik.Caption + '''&$orderby=davcnaID';
       novoOkno.PovezavaServerKalkulacije.Connected := true;
       novoOkno.TabelaKalkulacije.SetFocus;
    end;

    procedure PoZaprtjuShowModal(AValue: TModalResult);
    begin
      try
        fmOsnovniKontroler.Cursor := crHourGlass;
        if AValue = 1 then begin
          try
            izborKalkulacije := novoOkno.DataSourceKalkulacije.DataSet.FieldByName('Stevilka').AsInteger;   // Preberi vrednost Stevilka iz tabele KALKULACIJE, ki nato služi za ibor podatkov iz tabele PODATKI
            vpImePodjetja.Text := novoOkno.DataSourceKalkulacije.DataSet.FieldByName('ImePodjetja').AsString;
            vpNaslovPodjetja.Text := novoOkno.DataSourceKalkulacije.DataSet.FieldByName('NaslovPodjetja').AsString;
            vpDavcnaStevilkaPodjetja.Text := novoOkno.DataSourceKalkulacije.DataSet.FieldByName('DavcnaID').AsString;
            if (novoOkno.DataSourceKalkulacije.DataSet.FieldByName('DavcnaID').AsString = '') then begin
              vpDejavnostPodjetja.ItemIndex := -1;
            end;

            ploscicaGlava.Show;
            ploscicaGlavniMeni.Show;
            ploscicaGlavniMeni.ElementHandle.style.setProperty('overflow', 'visible');
            ploscicaIzborPogledov.Show;
            ploscicaDelovniListi.Margins.Top := 15;
            ploscicaGrafMDJ.Visible := false;
            ploscicaMaksimalniPribitek.Top := 572;
            ploscicaKorekcijskiFaktorji.Top := 625;
            vpKorekcijskiFaktorji.Top := 575;
            vpGrafikaMDJ.Position := 0;


            PovezavaServerPodatki.Connected := true;   // Zažene povezavo s serjeverjem. Ko je ta vzpostavljena se zažene procedura OnConnect gradnika PovezavaServer
          except
            showMessage(LjezikObvestilo_ServerNiPovezave_02);
          end;

      end;
      finally
        novoOkno.PovezavaServerKalkulacije.Connected := false;
        PovezavaServerPodatki.Connected := false;
      end;
    end;


begin
  novoOkno := TfmOdpriKalkulacijo.CreateNew(@ObOdprtjuShowModal);
  novoOkno.Popup := true;
  novoOkno.ShowModal(@PoZaprtjuShowModal); // Odpre novo okno (formo) in po njenem zaprtju (odvisno od gumba, ki formo zapre) izvede proceduro PoZaprtjuShowModal
end;


procedure TfmOsnovniKontroler.gNaloziSkodoClick(Sender: TObject);
begin
   WebClientSkode.Connection := PovezavaServerSkode;
   fmOsnovniKontroler.Cursor := crHourGlass;
   if PovezavaServerSkode.Connected = true then
       PovezavaServerSkode.Connected := false;
   try
      posiljatelj := 7;
      PovezavaServerSkode.Connected := true;  // Zažene povezavo s serjeverjem. Ko je ta vzpostavljena se zažene procedura OnConnect gradnika PovezavaServer
   except
      posiljatelj := 0;
      showMessage(LjezikObvestilo_ServerNiPovezave_02);
    end;
end;

procedure TfmOsnovniKontroler.gPreracunajClick(Sender: TObject);
var novoOkno : TfmSimulacijaSkode;
    i, j, zastojDa : Integer;
    poljePisanje, poljeBranje1, poljeBranje2 : TEdit;
    poljeBranje : TLabel;
    s1, s2 : String;

   procedure ObOdprtjuShowModal(AForm: TObject);  // Procedura, ki se zažene ob zagonu okna / forme fmPogovornoOkno_OsveževanjeAnaliz
   var  i, j, vrstica, stolpec, stolpecZacetni, komponenta : Integer;
        steviloPrihrankov : Integer;
        vnosnoPolje, vnosnoPolje2 : TEdit;
        zapis : TLabel;
        ploscica : TPanel;
        slika : TImageControl;
        izbor : TComboBox;
        dan, mesec, leto : Word;
        mesecSkode, letoSkode, letoPrej : Integer;
        tekst, tekst2 : String;

        prometPlanVZastojuM : array of Real;
        prometRealizacijaVZastojuM : array of Real;
        planiranPrometVZastojuM : array of Real;
        prometPredSkodoM : array of Real;


    begin

     fmOsnovniKontroler.Cursor := crHourGlass;

     With novoOkno do begin
       izNaslov.Caption := LjezikSkoda_Naslov;
       gIzhod.Caption := LjezikSkoda_gumbIzhod;
       gSimulacije.caption := LjezikSkoda_gumbSimulacije;
       izKoraki.Caption := LjezikSkoda_napisKoraki;
       izNaslovList1.Caption := LjezikSkodaK1_Naslov;
       IzpadPrometaTekst.Caption := LjezikSkodaK1_IzpadPrometa;
       ZavarovaniIzpadPrometaTekst.Caption := LjezikSkodaK1_ZavarovaniIzpad;
       ploscicaTabelaNepokritiStroski_Naslov1.Caption := LjezikMesec + ' / ' + LjezikLeto;
       ploscicaTabelaNepokritiStroski_Naslov2.Lines[0] := LjezikSkodaK1_TabelaRealizacijaX;
       ploscicaTabelaNepokritiStroski_Naslov3.Lines[0] := LjezikSkodaK1_TabelaPlan;
       ploscicaTabelaNepokritiStroski_Naslov4.Lines[0] := LjezikSkodaK1_TabelaRealizacijaXplus1;
       ploscicaTabelaNepokritiStroski_Naslov5.Lines[0] := LjezikSkodaK1_TabelaRazlika;
       ploscicaTabelaNepokritiStroski_Naslov6.Lines[0] := LjezikSkodaK1_TabelaKritje;
       izNaslovList2.Caption := LjezikSkodaK2_Naslov;
       PovisaniPoslovniStrskiTekst.Caption := LjezikSkodaK2_PovisaniStroski;
       ResenPrometTekst.Caption := LjezikSkodaK2_ResenPromet;
       izNaslovList3.Caption := LjezikSkodaK3_Naslov;
       ploscicaTabelaNepokritiStroski_Naslov7.Caption := LjezikSkodaK3_TabelaVrstaPrihranka;
       ploscicaTabelaNepokritiStroski_Naslov8.Caption := LjezikSkodaK3_TabelaVisinaPrihranka;
       ploscicaTabelaNepokritiStroski_Naslov9.Caption := LjezikSkodaK3_TabelaUpravicenost;
       ploscicaTabelaNepokritiStroski_Naslov10.Caption := LjezikSkodaK3_TabelaOdstotek;
       PrijavljeniPrihrankiTekst.Caption := LjezikSkodaK3_PrijavljeniPrihranki;
       DejanskiPrihrankiTekst.Caption := LjezikSkodaK3_DejanskiPrihranki;
       izNaslovList4.Caption := LjezikSkodaK4_Naslov;
       ploscicaTabelaNepokritiStroski_Naslov11.Lines[0] := LjezikSkodaK4_TabelaMeseci;
       ploscicaTabelaNepokritiStroski_Naslov12.Lines[0] := LjezikSkodaK4_TabelaPrometPoMesecih;
       izIzhodiscaLetniPrometTekst.Caption := LjezikSkodaK4_TabelaPrometSkupaj;
       izLetniPrometOsnovaTekst.Caption := LjezikSkodaK4_OsnovaZaLetniPromet;
       izLetniPrometRastTekst.Caption := LjezikSkodaK4_Rast;
       izLetniPrometTekst.Caption := LjezikSkodaK4_LetniPromet;
       izPotrebnaZVTekst.Caption := LjezikSkodaK4_PotrebnaZV;
       izDejanskaZVTekst.Caption := LjezikSkodaK4_DejanskaZV;
       izPodzavarovanjeTekst.Caption := LjezikSkodaK4_Podzavarovanje;
       izNaslovList5.Caption := LjezikSkodaK5_Naslov;
       izRezultatIzpadPrometaTekst.Caption := LjezikSkodaK5_Izpad;
       izRezultatPovisaniStroskiTekst.Caption := LjezikSkodaK5_PovisaniStroski;
       izRezultatPrihrankiTekst.Caption := LjezikSkodaK5_Prihranki;
       izRezultatPodzavarovanjeTekst.Caption := LjezikSkodaK5_Podzavarovanje;
       izRezultatZavarovalninaTekst.Caption := LjezikSkodaK5_Zavarovalnina;
       izRezultatDejanskoOcenjenaSkodaTekst.Caption := LjezikSkodaK5_DejanskaSkoda;
       izSimulacijaNogaTekstMDJ.Caption := LjezikSimulacija_nogaMDJ;
       izSimulacijaNogaTekstZV.Caption := LjezikSimulacija_nogaZV;
       izSimulacijaNogaTekstPrihranki.Caption := LjezikSimulacija_nogaPrihranki;
       izSimulacijaNogaTekstStroski.Caption := LjezikSimulacija_nogaPovisaniStroski;
       izSimulacijaNogaTekstRast.Caption := LjezikSimulacija_nogaRast;
       izSimulacijaNogaTekstPremija.Caption := LjezikSimulacija_nogaPremija;

     end;


     try

       // Definira velikost vseh tabel na 12 (mesecev), kar je najvišja vrednost in jih napolni z ničlami, da se ne ugodi, da je katera brez vrednosti
       SetLength(prometPlanVZastojuM, 12);
       SetLength(prometRealizacijaVZastojuM, 12);
       SetLength(planiranPrometvZastojuM, 12);
       SetLength(prometPredSkodoM, 12);
       for i := 0 to 11 do begin
         prometPlanVZastojuM[i] := 0;
         prometRealizacijaVZastojuM[i] := 0;
         planiranPrometvZastojuM[i] := 0;
         prometPredSkodoM[i] := 0;
       end;

       // izračun koliko mesecev je trajal zastoj (prešteje celice, ki so obarvane)
       mesecevSkode := 0;
       for i := 2 to 4 do begin
         for j := 1 to 12 do begin
            vnosnoPolje := FindComponent('ploscicaPromet_Promet_'+IntToStr(i)+'_'+IntToStr(j)) as TEdit;
            if vnosnoPolje.Color = clWebCornSilk then
               mesecevSkode := mesecevSkode + 1;
         end;
       end;

       // preveri dan in mesec začetka zavarovanja (tj. zavarovalnega leta), akr je potrebno, da se da ugotoviti, kateri mesec je izbran iz spustnega seznama
       dan := DayOf(vpZavarovanjeOd.Date);
       mesec := MonthOf(vpZavarovanjeOd.Date);
       leto := YearOf(vpZavarovanjeOd.Date);
       // Če dan začetka zavarovanja ni 1. dan v mesecu, se lahko škoda simulira šele naslednji mesec (1.)
       if dan > 1 then
          mesec := mesec + 1;
       // določi, kateri mesec je iz spustnega seznama izbran za začetek škode
       mesecSkode := vpDatumFizicneSkode.ItemIndex + mesec;
       if mesecSkode > 12 then begin
          mesecSkode := mesecSkode - 12;
          stolpec := 3;
          // letoSkode := leto + 1;
       end
       else begin
          mesecSkode := mesecSkode;
          stolpec := 2;
          // letoSkode := leto;
       end;

       // TABELA NEPOKRITIH STROŠKOV - zavihek 1

       // Izdelava polj, ki se vstavijo v tabelo - njihovo število je odvisno od števila mesecev, za katere se ugotovi zastoj
       with  novoOkno.SimulacijaSkode_List1 do begin
         for vrstica := 1 to mesecevSkode do begin
           zapis := TLabel.Create(novoOkno.SimulacijaSkode_List1);
           with zapis do begin
             Parent := novoOkno.SimulacijaSkode_List1;
             Align := alNone;
             Alignment := taLeftJustify;
             AutoSize := false;
             if (vrstica + mesecSkode - 1) > 12 then
               Caption := LjezikImeMeseca[vrstica + mesecSkode -1 - 12]
              else
               Caption := LjezikImeMeseca[vrstica + mesecSkode - 1];
             ChildOrder := 0;
             Color := clNone;
             Cursor := crDefault;
             Dragmode := dmManual;
             ElementFont :=efProperty;
             ElementPosition := epAbsolute;
             Enabled := true;
             Height := 18;
             HeightPercent := 100;
             HeightStyle := ssAbsolute;
             Left := 5;
             ParentFont := true;
             ShowAccelChar := true;
             ShowHint := false;
             Tag := 0;
             TextDirection := tdDefault;
             Top := 36;
             Transparent := true;
             Visible := true;
             Width := 105;
             WidthPercent := 100;
             WidthStyle := ssAbsolute;
             WordWrap := false;
              Name := 'mesec_' + IntToStr(vrstica);
           end;
           for komponenta := 1 to 4 do begin
              vnosnoPolje := TEdit.Create(novoOkno.SimulacijaSkode_List1);
              with vnosnoPolje do begin
                Parent := novoOkno.SimulacijaSkode_List1;
                Align := alNone;
                Alignment := taRightJustify;
                AutoCompletion:= acOff;
                AutoFocus := false;
                AutoSelect := false;
                AutoSize := false;
                BiDiMode := bdLeftToRight;
                BorderStyle := bsNone;
                CharCase := wecNormal;
                ChildOrder := 0;
                Color := clNone;
                Cursor := crDefault;
                Dragmode := dmManual;
                EditType := weString;
                ElementFont :=efProperty;
                ElementPosition := epAbsolute;
                Enabled := true;
                Height := 25;
                HeightPercent := 100;
                HeightStyle := ssAbsolute;
                HideSelection := true;
                Left := 15;
                MaxLength := 0;
                ParentFont := true;
                ReadOnly := true;
                Required := false;
                ShowFocus := false;
                ShowHint := false;
                TabStop := false;
                Tag := 0;
                Text := '0,00';
                TextDirection := tdDefault;
                Top := 350;
                Visible := true;
                Width := 120;
                WidthPercent := 100;
                WidthStyle := ssAbsolute;
                Name := 'VnosnoPolje_'+IntToStr(vrstica)+'_'+IntToStr(komponenta);
              end;
           end;
           ploscica := TPanel.Create(novoOkno.SimulacijaSkode_List1);
           with ploscica do begin
             Parent := novoOkno.SimulacijaSkode_List1;
             Align := alNone;
             AutoSize := false;
             BorderColor := clNone;
             BorderStyle := bsNone;
             Caption := '';
             ChildOrder := 0;
             Color := clWhite;
             Cursor := crDefault;
             DragMode := dmManual;
             ElementFont := efProperty;
             ElementPosition := epAbsolute;
             Enabled := true;
             Height := 25;
             HeightPercent := 100;
             HeightStyle := ssAbsolute;
             Left := 0;
             ParentFont := true;
             ShowCaption := false;
             ShowHint := false;
             Tag := 0;
             Top := 100;
             Visible := true;
             Width := 42;
             WidthPercent := 100;
             WidthStyle := ssAbsolute;
             Name := 'PloscicaKritje_'+IntToStr(vrstica);
           end;
           ploscica := FindComponent('PloscicaKritje_'+IntToStr(vrstica)) as TPanel;
           slika := TImageControl.Create(novoOkno.SimulacijaSkode_List1);
           with slika do begin
             Parent := novoOkno.SimulacijaSkode_List1;
             Align := alNone;
             AutoSize := false;
             ChildOrder := 0;
             Cursor := crDefault;
             DragMode := dmManual;
             ElementFont := efProperty;
             ElementPosition := epAbsolute;
             Height := 16;
             HeightPercent := 100;
             HeightStyle := ssAbsolute;
             Left := 13;
             ShowHint := false;
             Tag := 0;
             Top := 5;
             Visible := true;
             Width := 16;
             WidthPercent := 100;
             WidthStyle := ssAbsolute;
             Name := 'SlikaKritje_'+IntToStr(vrstica);
             Picture.LoadFromFile('img_OK.png');
             Parent := ploscica;
           end;
         end;
       end;

       // Izdelava tabele - daljšanje
       with novoOkno do begin
         ploscicaTabelaNepokritiStroski.RowCollection.BeginUpdate;
         ploscicaTabelaNepokritiStroski.Height := 40 + (mesecevSkode * 28) + 3;
         for vrstica := 1 to mesecevSkode do begin
           ploscicaTabelaNepokritiStroski.RowCollection.Add;
         end;
         for vrstica := 1 to mesecevSkode do begin
           ploscicaTabelaNepokritiStroski.RowCollection.Items[vrstica].SizeStyle := ssAbsolute;
           ploscicaTabelaNepokritiStroski.RowCollection.Items[vrstica].Value := 26;
           ploscicaTabelaNepokritiStroski.RowCollection.Items[vrstica].Alignment := vaCenter;
         end;

         ploscicaTabelaNepokritiStroski.ControlCollection.BeginUpdate;
         for vrstica := 1 to mesecevSkode do begin
            with SimulacijaSkode_List1 do begin
              zapis := FindComponent('Mesec_'+IntToStr(vrstica)) as TLabel;
            end;
            ploscicaTabelaNepokritiStroski.ControlCollection.Add;
            ploscicaTabelaNepokritiStroski.ControlCollection.Items[6 * vrstica].Control := zapis;
            for komponenta := 1 to 4 do begin
              with SimulacijaSkode_List1 do begin
                vnosnoPolje := FindComponent('VnosnoPolje_'+IntToStr(vrstica)+'_'+IntToStr(komponenta)) as TEdit;
              end;
              ploscicaTabelaNepokritiStroski.ControlCollection.Add;
              ploscicaTabelaNepokritiStroski.ControlCollection.Items[6 * vrstica + komponenta].Control := vnosnoPolje;
            end;
            with SimulacijaSkode_List1 do begin
              ploscica := FindComponent('PloscicaKritje_'+IntToStr(vrstica)) as TPanel;
            end;
            ploscicaTabelaNepokritiStroski.ControlCollection.Add;
            ploscicaTabelaNepokritiStroski.ControlCollection.Items[6 * vrstica + 5].Control := ploscica;
         end;
         ploscicaTabelaNepokritiStroski.ControlCollection.EndUpdate;
         ploscicaTabelaNepokritiStroski.RowCollection.EndUpdate;
       end;

       with novoOkno do begin
         ploscicaTabelaNepokritiStroski_Povzetek.Top := ploscicaTabelaNepokritiStroski.top + ploscicaTabelaNepokritiStroski.Height + 15;
       end;

      // Poljnjenje polj z vrednostmi

       vrstica := mesecSkode;
       dogovorjenaMDJ := StrToInt(izMaksimalnaDobaJamcenja.Text);
       arhivVrednostiZacetneSimulacije.SimulacijaMDJ := vpMDj.ItemIndex;

       for i := 1 to mesecevSkode do Begin
          if vrstica > 12 then begin
            stolpec := stolpec + 1;
            vrstica := vrstica - 12;
          end;

          // prepis prometa iz leta pred škodo
          vnosnoPolje := FindComponent('ploscicaPromet_Promet_'+IntToStr(stolpec-1)+'_'+IntToStr(vrstica)) as TEdit;
          tekst := vnosnoPolje.Text;
          with novoOkno.SimulacijaSkode_List1 do begin
            vnosnoPolje2 := FindComponent('VnosnoPolje_'+IntToStr(i)+'_'+IntToStr(1)) as TEdit;
            vnosnoPolje2.Text := tekst;
          end;
          // prepis dejanskega prometa v času zastoja
          vnosnoPolje := FindComponent('ploscicaPromet_Promet_'+IntToStr(stolpec)+'_'+IntToStr(vrstica)) as TEdit;
          tekst := vnosnoPolje.Text;
          with novoOkno.SimulacijaSkode_List1 do begin
            vnosnoPolje2 := FindComponent('VnosnoPolje_'+IntToStr(i)+'_'+IntToStr(3)) as TEdit;
            vnosnoPolje2.Text := tekst;
          end;
          // prepis planiranega prometa za čas zastoja (iz tabele na listu Analiza prodaje
          zapis := FindComponent('ploscicaAnalizeProdaje_izPromet_'+IntToStr(stolpec)+'_'+IntToStr(vrstica)) as Tlabel;
          tekst := zapis.Caption;
          with novoOkno.SimulacijaSkode_List1 do begin
            vnosnoPolje2 := FindComponent('VnosnoPolje_'+IntToStr(i)+'_'+IntToStr(2)) as TEdit;
            vnosnoPolje2.Text := tekst;
          end;
          // Izračun izpada prometa v času zastoja
          with novoOkno.SimulacijaSkode_List1 do begin
            vnosnoPolje := FindComponent('VnosnoPolje_'+IntToStr(i)+'_'+IntToStr(2)) as TEdit;
            tekst := vnosnoPolje.Text;
            tekst := StringReplace(tekst, '.', '', [rfreplaceAll, rfIgnorecase]);
            vnosnoPolje2 := FindComponent('VnosnoPolje_'+IntToStr(i)+'_'+IntToStr(3)) as TEdit;
            tekst2 := vnosnoPolje2.Text;
            tekst2 := StringReplace(tekst2, '.', '', [rfreplaceAll, rfIgnorecase]);
            vnosnoPolje := FindComponent('VnosnoPolje_'+IntToStr(i)+'_'+IntToStr(4)) as TEdit;
            vnosnoPolje.Text := FormatFloat('###,###,##0.00', StrToFloat(tekst2) - StrToFloat(tekst));

            prometPlanVZastojuM[i-1] := StrToFloat(tekst);
            prometRealizacijaVZastojuM[i-1] := StrToFloat(tekst2);

            if i <= dogovorjenaMDJ then begin      // če je izpad krajši ali enak dogovorjeni MDJ
              slika := FindComponent('SlikaKritje_'+IntToStr(i)) as TImageControl;
              slika.Picture.LoadFromFile('img_OK.png');
            end
            else begin                             // če je izpad daljši od dogovorjene MDJ (tj. zavarovanje ne pokrije celotne dobe)
              slika := FindComponent('SlikaKritje_'+IntToStr(i)) as TImageControl;
              slika.Picture.LoadFromFile('img_close_2.png');
            end;
          end;
          vrstica := vrstica + 1;
       end;

       prometPlanVZastojuM1 := prometPlanVZastojuM[0] * 100;
       prometPlanVZastojuM2 := prometPlanVZastojuM[1] * 100;
       prometPlanVZastojuM3 := prometPlanVZastojuM[2] * 100;
       prometPlanVZastojuM4 := prometPlanVZastojuM[3] * 100;
       prometPlanVZastojuM5 := prometPlanVZastojuM[4] * 100;
       prometPlanVZastojuM6 := prometPlanVZastojuM[5] * 100;
       prometPlanVZastojuM7 := prometPlanVZastojuM[6] * 100;
       prometPlanVZastojuM8 := prometPlanVZastojuM[7] * 100;
       prometPlanVZastojuM9 := prometPlanVZastojuM[8] * 100;
       prometPlanVZastojuM10 := prometPlanVZastojuM[9] * 100;
       prometPlanVZastojuM11 := prometPlanVZastojuM[10] * 100;
       prometPlanVZastojuM12 := prometPlanVZastojuM[11] * 100;

       prometRealizacijaVZastojuM1 := prometRealizacijaVZastojuM[0] * 100;
       prometRealizacijaVZastojuM2 := prometRealizacijaVZastojuM[1] * 100;
       prometRealizacijaVZastojuM3 := prometRealizacijaVZastojuM[2] * 100;
       prometRealizacijaVZastojuM4 := prometRealizacijaVZastojuM[3] * 100;
       prometRealizacijaVZastojuM5 := prometRealizacijaVZastojuM[4] * 100;
       prometRealizacijaVZastojuM6 := prometRealizacijaVZastojuM[5] * 100;
       prometRealizacijaVZastojuM7 := prometRealizacijaVZastojuM[6] * 100;
       prometRealizacijaVZastojuM8 := prometRealizacijaVZastojuM[7] * 100;
       prometRealizacijaVZastojuM9 := prometRealizacijaVZastojuM[8] * 100;
       prometRealizacijaVZastojuM10 := prometRealizacijaVZastojuM[9] * 100;
       prometRealizacijaVZastojuM11 := prometRealizacijaVZastojuM[10] * 100;
       prometRealizacijaVZastojuM12 := prometRealizacijaVZastojuM[11] * 100;

       // POVIŠANI POSLOVNI STROŠKI - zavihek 2
//     osnovaUpravicenihPovisanihStroskov := 0;

       with novoOkno do begin
         izPovisaniPoslovniStroski.Text := vpPovisaniPoslovniStroskiZnesek.Text;
         izResenPromet.Text := vpResenPrometZnesek.Text;
         EkonomskoUpraviceniPovisaniStroskiTekst.Caption := LjezikSkodaK2_UpraviceniStroski + ' ' + vpVnosFRPStopnjaKosmategaDobicka.Caption + ' ):';
         // preračun
         povisaniStroski :=  StrToFloat(StringReplace(izPovisaniPoslovniStroski.Text, '.', '', [rfreplaceAll, rfIgnorecase])) * 100;
         resenPromet :=  StrToFloat(StringReplace(izResenPromet.Text, '.', '', [rfreplaceAll, rfIgnorecase])) * 100;
         delezKosmategaDobicka := StrToFloat(StringReplace(vpVnosFRPStopnjaKosmategaDobicka.Caption, ' %', '', [rfreplaceAll, rfIgnorecase])) * 100;
         arhivVrednostiZacetneSimulacije.simulacijaPovisaniStroski := vpPovisaniPoslovniStroskiObdobje.ItemIndex;

         obdobjePovisanihStroskov := vpPovisaniPoslovniStroskiObdobje.ItemIndex;
       end;

       // TABELA prihranki - zavihek 3

       // prešteje število vrstic s prihranki (gleda le tiste, kjer je vnesen znesek (ne šteje opisov brez zneskov)
       steviloPrihrankov := 0;
       for i := 1 to 10 do begin
           vnosnoPolje := FindComponent('ploscicaPrihranki_Prihranek_'+IntToStr(2)+'_'+IntToStr(i)) as TEdit;
           if vnosnoPolje.Text <> '' then
               steviloPrihrankov := steviloPrihrankov + 1;
       end;
         // Izdelava polj
       with  novoOkno.SimulacijaSkode_List3 do begin
         for vrstica := 1 to steviloPrihrankov do begin
            zapis := TLabel.Create(novoOkno.SimulacijaSkode_List3);
            with zapis do begin
             Parent := novoOkno.SimulacijaSkode_List3;
             Align := alNone;
             Alignment := taLeftJustify;
             AutoSize := false;
             Caption := '';
             ChildOrder := 0;
             Color := clNone;
             Cursor := crDefault;
             Dragmode := dmManual;
             ElementFont :=efProperty;
             ElementPosition := epAbsolute;
             Enabled := true;
             Height := 18;
             HeightPercent := 100;
             HeightStyle := ssAbsolute;
             Left := 5;
             ParentFont := true;
             ShowAccelChar := true;
             ShowHint := false;
             Tag := 0;
             TextDirection := tdDefault;
             Top := 36;
             Transparent := true;
             Visible := true;
             Width := 235;
             WidthPercent := 100;
             WidthStyle := ssAbsolute;
             WordWrap := false;
             Name := 'PrihranekTekst_'+IntToStr(vrstica);
            end;
            vnosnoPolje := TEdit.Create(novoOkno.SimulacijaSkode_List3);
            with vnosnoPolje do begin
             Parent := novoOkno.SimulacijaSkode_List3;
             Align := alNone;
             Alignment := taRightJustify;
             AutoCompletion:= acOff;
             AutoFocus := false;
             AutoSelect := false;
             AutoSize := false;
             BiDiMode := bdLeftToRight;
             BorderStyle := bsNone;
             CharCase := wecNormal;
             ChildOrder := 0;
             Color := clNone;
             Cursor := crDefault;
             Dragmode := dmManual;
             EditType := weString;
             ElementFont :=efProperty;
             ElementPosition := epAbsolute;
             Enabled := true;
             Height := 26;
             HeightPercent := 100;
             HeightStyle := ssAbsolute;
             HideSelection := true;
             Left := 15;
             MaxLength := 0;
             ParentFont := true;
             ReadOnly := true;
             Required := false;
             ShowFocus := false;
             ShowHint := false;
             TabStop := false;
             Tag := 0;
             Text := '0,00';
             TextDirection := tdDefault;
             Top := 350;
             Visible := true;
             Width := 139;
             WidthPercent := 100;
             WidthStyle := ssAbsolute;
             Name := 'izVisinaPrihranka_'+IntToStr(vrstica);
            end;
            izbor := TComboBox.Create(novoOkno.SimulacijaSkode_List3);
            with izbor do begin
             Parent := novoOkno.SimulacijaSkode_List3;
             Align := alNone;
             BiDiMode := bdLeftToRight;
             ChildOrder := 0;
             Color := clNone;
             Cursor := crDefault;
             Dragmode := dmManual;
             ElementFont :=efProperty;
             ElementPosition := epAbsolute;
             Enabled := true;
             Height := 26;
             HeightPercent := 100;
             HeightStyle := ssAbsolute;
             Left := 15;
             ParentFont := true;
             ShowFocus := false;
             ShowHint := false;
             Style := csDropDownList;
             TabOrder := 0;
             TabStop := true;
             Tag := 0;
             Text := '';
             TextDirection := tdDefault;
             Top := 350;
             Visible := true;
             Width := 127;
             WidthPercent := 100;
             WidthStyle := ssAbsolute;
             Items.Add(LjezikDa);
             Items.Add(LjezikNe);
             ItemIndex := 0;
             Name := 'vpUpravicenostPrihranka_'+IntToStr(vrstica);
             OnChange := novoOkno.UpravicenostPrihranka;
            end;
            izbor := TComboBox.Create(novoOkno.SimulacijaSkode_List3);
            with izbor do begin
             Parent := novoOkno.SimulacijaSkode_List3;
             Align := alNone;
             BiDiMode := bdLeftToRight;
             ChildOrder := 0;
             Color := clNone;
             Cursor := crDefault;
             Dragmode := dmManual;
             ElementFont :=efProperty;
             ElementPosition := epAbsolute;
             Enabled := true;
             Height := 26;
             HeightPercent := 100;
             HeightStyle := ssAbsolute;
             Left := 15;
             ParentFont := true;
             ShowFocus := false;
             ShowHint := false;
             Style := csDropDownList;
             TabOrder := 0;
             TabStop := true;
             Tag := 0;
             Text := '';
             TextDirection := tdDefault;
             Top := 350;
             Visible := true;
             Width := 127;
             WidthPercent := 100;
             WidthStyle := ssAbsolute;
             for i := 1 to 20 do begin
               Items.Add(IntToStr(i * 5)+' %');
             end;
             ItemIndex := 19;
             Name := 'vpVisinaUpravicenostiPrihranka_'+IntToStr(vrstica);
             OnChange := novoOkno.UpravicenostPrihranka;
            end;
          end;
       end;

       // Izdelava tabele - daljšanje in polnjenje
       with novoOkno do begin
         ploscicaPregledPrihrankov.RowCollection.BeginUpdate;
         ploscicaPregledPrihrankov.Height := 30 + steviloPrihrankov * 29 + 3;
         for vrstica := 1 to steviloPrihrankov do begin
           ploscicaPregledPrihrankov.RowCollection.Add;
         end;
         for vrstica := 1 to steviloPrihrankov do begin
           ploscicaPregledPrihrankov.RowCollection.Items[vrstica].SizeStyle := ssAbsolute;
           ploscicaPregledPrihrankov.RowCollection.Items[vrstica].Value := 26;
           ploscicaPregledPrihrankov.RowCollection.Items[vrstica].Alignment := vaCenter;
         end;

         ploscicaPregledPrihrankov.ControlCollection.BeginUpdate;
         for vrstica := 1 to steviloPrihrankov do begin
            with SimulacijaSkode_List3 do begin
              zapis := FindComponent('PrihranekTekst_'+IntToStr(vrstica)) as TLabel;
            end;
            ploscicaPregledPrihrankov.ControlCollection.Add;
            ploscicaPregledPrihrankov.ControlCollection.Items[4 * vrstica].Control := zapis;
            with SimulacijaSkode_List3 do begin
                vnosnoPolje := FindComponent('izVisinaPrihranka_'+IntToStr(vrstica)) as TEdit;
            end;
            ploscicaPregledPrihrankov.ControlCollection.Add;
            ploscicaPregledPrihrankov.ControlCollection.Items[4 * vrstica + 1].Control := vnosnoPolje;
            with SimulacijaSkode_List3 do begin
              izbor := FindComponent('vpUpravicenostPrihranka_'+IntToStr(vrstica)) as TComboBox;
            end;
            ploscicaPregledPrihrankov.ControlCollection.Add;
            ploscicaPregledPrihrankov.ControlCollection.Items[4 * vrstica + 2].Control := izbor;
            with SimulacijaSkode_List3 do begin
              izbor := FindComponent('vpVisinaUpravicenostiPrihranka_'+IntToStr(vrstica)) as TComboBox;
            end;
            ploscicaPregledPrihrankov.ControlCollection.Add;
            ploscicaPregledPrihrankov.ControlCollection.Items[4 * vrstica + 3].Control := izbor;

         end;
        ploscicaPregledPrihrankov.ControlCollection.EndUpdate;
        ploscicaPregledPrihrankov.RowCollection.EndUpdate;
       end;

       prijavljeniPrihranki := 0;
       if steviloPrihrankov = 0 then begin
          with novoOkno do begin
            izPrijavljeniPrihranki.Text := '0,00';
            izDejanskiPrihranki.Text := '0,00';
          end;
       end
       else begin
         for i := 1 to steviloPrihrankov do begin
            vnosnoPolje := FindComponent('ploscicaPrihranki_Prihranek_'+IntToStr(1)+'_'+IntToStr(i)) as TEdit;
            tekst := vnosnoPolje.Text;
            with novoOkno.SimulacijaSkode_List3 do begin
                zapis := FindComponent('PrihranekTekst_'+IntToStr(i)) as TLabel;
                zapis.Caption := tekst;
            end;
            vnosnoPolje := FindComponent('ploscicaPrihranki_Prihranek_'+IntToStr(2)+'_'+IntToStr(i)) as TEdit;
            tekst := vnosnoPolje.Text;
            with novoOkno.SimulacijaSkode_List3 do begin
              vnosnoPolje2 := FindComponent('izVisinaPrihranka_'+IntToStr(i)) as TEdit;
              vnosnoPolje2.Text := tekst;
              prijavljeniPrihranki := prijavljeniPrihranki + StrToFloat(StringReplace(vnosnoPolje2.Text, '.', '', [rfreplaceAll, rfIgnorecase]));
            end;
         end;
         with novoOkno do begin
           izPrijavljeniPrihranki.Text := FormatFloat('###,###,##0.00', prijavljeniPrihranki);
           arhivVrednostiZacetneSimulacije.SimulacijaPrihranki := vpPrihrankiObdobje.ItemIndex;
           obdobjePrihrankov := vpPrihrankiObdobje.ItemIndex;

           prijavljeniPrihranki := prijavljeniPrihranki * 100;

           ploscicaPregledPrihrankov_Povzetek.Top := ploscicaPregledPrihrankov.top + ploscicaPregledPrihrankov.Height + 15;
         end;
       end;

       // TABELA podzavarovanje - zavihek 4
       // preveri dan in mesec začetka zavarovanja (tj. zavarovalnega leta), kar je potrebno, da se da ugotoviti, kateri mesec je izbran iz spustnega seznama
       dan := DayOf(vpZavarovanjeOd.Date);
       mesec := MonthOf(vpZavarovanjeOd.Date);
       leto := YearOf(vpZavarovanjeOd.Date);
       // Če dan začetka zavarovanja ni 1. dan v mesecu, se lahko škoda simulira šele naslednji mesec (1.)
       if dan > 1 then
          mesec := mesec + 1;
       // določi, kateri mesec je iz spustnega seznama izbran za začetek škode
          mesecSkode := vpDatumFizicneSkode.ItemIndex + mesec;
       if mesecSkode > 12 then begin
         mesecSkode := mesecSkode - 12;
         stolpec := 3;
         stolpecZacetni := 3;
         letoSkode := leto + 1;
       end
       else begin
        mesecSkode := mesecSkode;
        stolpec := 2;
        stolpecZacetni := 2;
        letoSkode := leto;
       end;
       // Izračuna planiran promet v obdobju 12 mesecev od začetka zastoja
       vrstica := mesecSkode;
       for i := 1 to 12 do Begin
         if vrstica > 12 then begin
           stolpec := stolpec + 1;
           vrstica := vrstica - 12;
         end;
         // seštevanje planiranega prometa za čas 12 mesecev od začetka zastoja (iz tabele na listu Analiza prodaje
         zapis := FindComponent('ploscicaAnalizeProdaje_izPromet_'+IntToStr(stolpec)+'_'+IntToStr(vrstica)) as TLabel;
         tekst := zapis.Caption;
         planiranPrometvZastojuM[i-1] := StrTOFloat(StringReplace(tekst, '.', '', [rfreplaceAll, rfIgnorecase]));
         vrstica := vrstica + 1;
       end;
       // Napolni tabelo s prometom v zadnjih 12 mesecih nepsoredno pred fizično škodo
       stolpec := stolpecZacetni;
       for i := 1 to 12 do begin
         vrstica := mesecSkode - i;
         letoPrej := 0;
         if vrstica < 1 then begin  // če je manjše od 1, mora program pobrati še podatke iz predhodnega leta
           vrstica := vrstica + 12;
           stolpec := stolpecZacetni - 1;
           letoPrej := -1 ;
         end;
         zapis := FindComponent('ploscicaPromet_Mesec'+IntToStr(vrstica)) as TLabel;
         tekst := zapis.Caption;
         with novoOkno do begin
           vnosnoPolje := FindComponent('MesecPredZastojem_'+IntToStr(i)) as TEdit;
           vnosnoPolje.Text := tekst + ', ' + IntToStr(letoSkode + letoPrej);
         end;
         vnosnoPolje2 := FindComponent('ploscicaPromet_Promet_'+IntToStr(stolpec)+'_'+IntToStr(vrstica)) as TEdit;
         tekst := vnosnoPolje2.Text;
         with novoOkno do begin
           vnosnoPolje := FindComponent('PrometPredZastojem_'+IntToStr(i)) as TEdit;
           vnosnoPolje.Text := tekst;
           prometPredSkodoM[i-1] := StrToFloat(StringReplace(tekst, '.', '', [rfreplaceAll, rfIgnorecase]));
         end;
       end;

       planiranPrometvZastojuM1 := planiranPrometvZastojuM[0] * 100;
       planiranPrometvZastojuM2 := planiranPrometvZastojuM[1] * 100;
       planiranPrometvZastojuM3 := planiranPrometvZastojuM[2] * 100;
       planiranPrometvZastojuM4 := planiranPrometvZastojuM[3] * 100;
       planiranPrometvZastojuM5 := planiranPrometvZastojuM[4] * 100;
       planiranPrometvZastojuM6 := planiranPrometvZastojuM[5] * 100;
       planiranPrometvZastojuM7 := planiranPrometvZastojuM[6] * 100;
       planiranPrometvZastojuM8 := planiranPrometvZastojuM[7] * 100;
       planiranPrometvZastojuM9 := planiranPrometvZastojuM[8] * 100;
       planiranPrometvZastojuM10 := planiranPrometvZastojuM[9] * 100;
       planiranPrometvZastojuM11 := planiranPrometvZastojuM[10] * 100;
       planiranPrometvZastojuM12 := planiranPrometvZastojuM[11] * 100;

       prometPredSkodoM1 := prometPredSkodoM[0] * 100;
       prometPredSkodoM2 := prometPredSkodoM[1] * 100;
       prometPredSkodoM3 := prometPredSkodoM[2] * 100;
       prometPredSkodoM4 := prometPredSkodoM[3] * 100;
       prometPredSkodoM5 := prometPredSkodoM[4] * 100;
       prometPredSkodoM6 := prometPredSkodoM[5] * 100;
       prometPredSkodoM7 := prometPredSkodoM[6] * 100;
       prometPredSkodoM8 := prometPredSkodoM[7] * 100;
       prometPredSkodoM9 := prometPredSkodoM[8] * 100;
       prometPredSkodoM10 := prometPredSkodoM[9] * 100;
       prometPredSkodoM11 := prometPredSkodoM[10] * 100;
       prometPredSkodoM12 := prometPredSkodoM[11] * 100;

       dejanskaZV := StrToFloat(StringReplace(izZavarovalnaVsota.Text, '.', '', [rfreplaceAll, rfIgnorecase])) * 100;
       arhivVrednostiZacetneSimulacije.SimulacijaZV := izZavarovalnaVsota.Text;

       rastNacin := 0;
       rastVisina := 0;

       posiljateljIzracunSkode := 1;

       novoOkno.izSimulacijaNogaMDJ.Caption := izMaksimalnaDobaJamcenja.Text + ' '+ LjezikSteviloMesecev +' [' + LjezikSimulacija_nogaD + ']';
       novoOkno.izSimulacijaNogaZV.Caption := izZavarovalnaVsota.Text + ' [' + LjezikSimulacija_nogaD + ']';
       novoOkno.izSimulacijaNogaPrihranki.Caption := vpPrihrankiObdobje.Text + ' [' + LjezikSimulacija_nogaD + ']';
       novoOkno.izSimulacijaNogaStroski.Caption := vpPovisaniPoslovniStroskiObdobje.Text + ' [' + LjezikSimulacija_nogaD + ']';
       novoOkno.izSimulacijaNogaPremija.Caption := izPremija.Text + ' [' + LjezikSimulacija_nogaD + ']';

       // Definiranje Hintov ...
       with novoOkno do begin
         slikaIzpadPrometa.Hint := Ljezik_Hint_Semafor_IzpadPrometa;
         slikaIzpadPrometa.ShowHint := true;
         slikaZV.Hint := Ljezik_Hint_Semafor_ZV;
         slikaZV.ShowHint := true;
         slikaZavarovalnina_1.Hint := Ljezik_Hint_Semafor_Zavarovalnina1;
         slikaZavarovalnina_1.ShowHint := true;
         slikaZavarovalnina_2.Hint := Ljezik_Hint_Semafor_Zavarovalnina2;
         slikaZavarovalnina_2.ShowHint := true;
         slikaZavarovalnina_3.Hint := Ljezik_Hint_Semafor_Zavarovalnina3;
         slikaZavarovalnina_3.ShowHint := true;
       end;

       with novoOkno do begin
          if PovezavaWSIzracunSkode.Connected = true then
             PovezavaWSIzracunSkode.Connected := false;
          PovezavaWSIzracunSkode.Connected := true;
       end;

   finally
      novoOkno.PovezavaWSIzracunSkode.Connected := false;
   end;

  end;


begin

   // Preveri, če je vsaj ena celiza izbrana za zastoj (tj. če je bil zastoj) - gre čez tabelo in šteje označene (pobarvane) celice
   zastojDa := 0;
   for i := 1 to 4 do begin
      for j := 1 to 12 do begin
         poljePisanje:= FindComponent('ploscicaPromet_Promet_'+IntToStr(i)+'_'+IntToStr(j)) as TEdit;
         if (poljePisanje.Color = clWebCornSilk) then begin
             zastojDa := zastojDa + 1;
         end;
      end;
   end;
   if ZastojDa > 0 then begin

     if (StrToFloat(izZavarovalnaVsota.Text) > 0) and (StrToFloat(izPremija.Text) > 0) then begin

       fmOsnovniKontroler.Cursor := crHourGlass;
       // Če celica, ki je označena (tj. je v zastoju), nima vrednosti, zapiše 0
       for i := 1 to 4 do begin
         for j := 1 to 12 do begin
           poljePisanje:= FindComponent('ploscicaPromet_Promet_'+IntToStr(i)+'_'+IntToStr(j)) as TEdit;
           if (poljePisanje.Color = clWebCornSilk) and (poljePisanje.Text = '') then begin
               poljePisanje.Text := '0,00';
           end;
         end;
       end;
       // Napolni celice, ki so prazne z vrednostmi iz tabel s prodajnimi plani
       for i := 1 to 4 do begin
         for j := 1 to 12 do begin
           poljePisanje := FindComponent('ploscicaPromet_Promet_'+IntToStr(i)+'_'+IntToStr(j)) as TEdit;
           if (poljePisanje.Text = '') then begin
              poljeBranje := FindComponent('ploscicaAnalizeProdaje_izPromet_'+IntToStr(i)+'_'+IntToStr(j)) as TLabel;
              poljePisanje.Text := poljeBranje.Caption ;
           end;
         end;
       end;
       // Preračuna indekse (vse)
       for i := 1 to 3 do begin
         for j := 1 to 12 do begin
           poljePisanje := FindComponent('ploscicaPromet_Indeks_'+IntToStr(i)+'_'+IntToStr(j)) as TEdit;
           poljeBranje2 := FindComponent('ploscicaPromet_Promet_'+IntToStr(i+1)+'_'+IntToStr(j)) as TEdit;
           poljeBranje1 := FindComponent('ploscicaPromet_Promet_'+IntToStr(i)+'_'+IntToStr(j)) as TEdit;
           s1 := Stringreplace(poljeBranje1.Text, '.', '', [rfreplaceAll, rfIgnorecase]);
           s2 := Stringreplace(poljeBranje2.Text, '.', '', [rfreplaceAll, rfIgnorecase]);
           poljePisanje.Text := FormatFloat('###,###,##0.00', StrToFloat(s2) / StrToFloat(s1) * 100);
         end;
       end;

       if vpPovisaniPoslovniStroskiZnesek.Text = '' then
         vpPovisaniPoslovniStroskiZnesek.Text := '0,00';
       if vpResenPrometZnesek.Text = '' then
         vpResenPrometZnesek.Text := '0,00';
       DelovniListiPodatkiZaSimulacijoSkod.TabIndex := 0;
       if vpGrafikaSkode.Position = 1 then
         ploscicaGrafSimulacijaSkode.Visible := true;
       novoOkno := TfmSimulacijaSkode.CreateNew(@ObOdprtjuShowModal);
       novoOkno.Popup := true;
       novoOkno.ShowModal;
     end
     else begin
       ShowMessage(LjezikObvestilo_gumbSkode2);
     end;
   end
   else begin
     for i := 1 to 4 do begin
       for j := 1 to 12 do begin
         poljePisanje := FindComponent('ploscicaPromet_Promet_'+IntToStr(i)+'_'+IntToStr(j)) as TEdit;
         poljePisanje.Color := clWhite;
       end;
     end;
     ShowMessage(LjezikObvestilo_gumbSkode);
   end;
end;

procedure TfmOsnovniKontroler.gPrijavaClick(Sender: TObject);
begin
  fmOsnovniKontroler.Cursor := crHourGlass;

  try
    fmOsnovniKontroler.GoogleReCaptcha.Verify('TEST');
    try
      PovezavaServerGeslo.Connected := true;
      // Zažene povezavo s serjeverjem. Ko je ta vzpostavljena se zažene procedura OnConnect gradnika PovezavaServer
    except
      showMessage(LjezikObvestilo_ServerNiPovezave_02);
     end;
  except
     ShowMessage(LjezikObvestilo_ReCaptcha_01 + sLineBreak + LjezikObvestilo_ReCaptcha_02);
     fmOsnovniKontroler.Cursor := crDefault;
  end;

end;


procedure TfmOsnovniKontroler.gVnesiKrivuljoZastojaClick(Sender: TObject);
var  x, y : Integer;
begin
  x := gVnesiKrivuljoZastoja.Left + gVnesiKrivuljoZastoja.Width + 200 + 30;
  y := gVnesiKrivuljoZastoja.Top + 371 - menuKrivuljaZastoja.Height;
  menuKrivuljaZastoja.Popup(x, y);
end;

procedure TfmOsnovniKontroler.gVnosPopolnegaZastojaClick(Sender: TObject);
var  x, y : Integer;
begin
  x := gVnosPopolnegaZastoja.Left + gVnosPopolnegaZastoja.Width + 200 + 30;
  y := gVnosPopolnegaZastoja.Top + 175 - menuPopolniZastoj.Height;
  menuPopolniZastoj.Popup(x, y);
end;

procedure TfmOsnovniKontroler.gZacniNovProjektClick(Sender: TObject);
var novoOkno : TfmLetoPodatkov;

  procedure ObOdprtjuShowModal(AForm: TObject);
  var sedaj : TDateTime;
  begin
    novoOkno.izLetoPodatkov_Naslov.Caption := LfmLetoPodatkov_izLetoPodatkov_Naslov;
    novoOkno.gPotrdi.Caption := LfmLetoPodatkov_gPotrdi;
    novoOkno.gPreklici.Caption := LfmLetoPodatkov_gPreklici;
    with novoOkno.vpLetoPodatkov_Izbor do begin
       sedaj := Now;
       Value := YearOf(sedaj);
       MinValue := Value - 3;
       MaxValue := Value;
    end;
  end;


  procedure PoZaprtjuShowModal(AValue: TModalResult);
  var i : Integer;
      zapis : Tlabel;
  begin
    if AValue = 1 then begin

      izborKalkulacije := 0;

      vpOpazovanoLeto.Text := IntToStr(novoOkno.vpLetoPodatkov_Izbor.Value);
      ZagonPrograma;

      vpTabelaPromet_LetoX.Caption := vpOpazovanoLeto.Text;
      vpTabelaPromet_LetoXminus1.Caption := IntToStr(StrToInt(vpOpazovanoLeto.Text) - 1);

      for i := 1 to 4 do begin
        zapis := FindComponent('izPlanProdaje_letoXplus'+IntToStr(i)) as TLabel;
        zapis.Caption := LjezikLeto +' ' + IntToStr(StrToInt(vpOpazovanoLeto.Text) + i) +':';
      end;
      vpZacetekZavarovanja.Date := EncodeDate(StrToInt(vpOpazovanoLeto.Text), 01, 01);

      ploscicaGlava.Show;
      ploscicaGlavniMeni.Show;
      ploscicaGlavniMeni.ElementHandle.style.setProperty('overflow', 'visible');
      ploscicaIzborPogledov.Show;
      ploscicaDelovniListi.Margins.Top := 15;
      ploscicaDelovniListi.TabIndex := 1;

    end;
  end;


begin
   novoOkno := TfmLetoPodatkov.CreateNew(@ObOdprtjuShowModal);
   novoOkno.Popup := true;
   novoOkno.ShowModal(@PoZaprtjuShowModal); // Odpre novo okno (formo) in po njenem zaprtju (odvisno od gumba, ki formo zapre) izvede proceduro PoZaprtjuShowModal
end;

procedure TfmOsnovniKontroler.gZakljuciClick(Sender: TObject);
begin
  ploscicaGlava.Hide;
  ploscicaGlavniMeni.Hide;
  ploscicaIzborPogledov.Hide;
  ploscicaDelovniListi.Margins.Top := 3;
  ploscicaDelovniListi.TabIndex := 0;
end;

procedure TfmOsnovniKontroler.izborAnalizeChange(Sender: TObject);
begin
  // Postavi vrednosti v ostalih dveh seznamih na prazno vrednost, da izgleda kot da gre za en dolg menu
  if izborAnalize.ItemIndex <> -1 then begin
    izborVhodniPodatki.ItemIndex := -1;
    izborSimulacijeSkod.ItemIndex := -1;
    izborVhodniPodatki.ClearSelection;
    izborSimulacijeSkod.ClearSelection;
    ListiProdajnihTrendov.TabIndex := 0;
  end;
//  Osvezevanje;   // Preračuna podatke predno se aplikacija prestavi na izbrano stran
  ploscicaDelovniListi.TabIndex := izborAnalize.ItemIndex+6;     // Postavi se na ustreno stran (list) na ploščiti ploscicaDelovniListi
end;

procedure TfmOsnovniKontroler.izborSimulacijeSkodChange(Sender: TObject);
var i, j, mesecSkode, letoPodatkov : Integer;
    dan, mesec, leto, mesecDanes : Word;
    tekst : String;
    zapis  : TEdit;
    zapis2 : TLabel;
    vrstica, stolpec : Integer;
begin
  // Postavi vrednosti v ostalih dveh seznamih na prazno vrednost, da izgleda kot da gre za en dolg menu
  if izborSimulacijeSkod.ItemIndex <> -1 then begin
    izborVhodniPodatki.ItemIndex := -1;
    izborAnalize.ItemIndex := -1;
    izborVhodniPodatki.ClearSelection;
    izborAnalize.ClearSelection;
    ploscicaGrafSimulacijaSkode.Visible := false;
    vpGrafikaSkode.Position := 0;
  end;

  if izborSimulacijeSkod.ItemIndex = 0 then begin
    if vpZavarovanjeDa.Checked = true then begin

      // Določi datum začetka in datum konca zavarovanja, kot je na zavihku "Obstoječa zavarovanja"
      vpZavarovanjeOd.ReadOnly := true;
      vpZavarovanjeOd.BorderStyle := bsNone;
      vpZavarovanjeOd.Date := vpZacetekZavarovanja.Date;
      vpZavarovanjeDo.Date := vpZavarovanjeOd.Date + 365;
      // Prepis podatkov o ZV in MDJ iz zavihka "Obstoječa zavarovanja"

      izZavarovalnaVsota.ReadOnly := true;
      izZavarovalnaVsota.BorderStyle := bsNone;
      izZavarovalnaVsota.Alignment := taLeftJustify;
      izZavarovalnaVsota.AutoSelect := false;

      izPremija.ReadOnly := true;
      izPremija.BorderStyle := bsNone;
      izPremija.Alignment := taLeftJustify;
      izPremija.AutoSelect := false;

      if vpZavarovanjeDAKosmatiDobicek.Checked = true then
        izZavarovalnaVsota.Text := vpZVKosmatiDobicek.Text
      else
      if vpZavarovanjeDANastetiStroski.Checked = true then
        izZavarovalnaVsota.Text := vpZVNastetiStroski.Text
      else
        izZavarovalnaVsota.Text := '0,00';

      izMaksimalnaDObaJamcenja.Enabled := false;
      izMaksimalnaDObaJamcenja.Text := vpMDJ.Text;
      izPremija.Text := vpPremija.Text;

      letoPodatkov := StrToInt(vpOpazovanoLeto.Text);
      // preveri dan in mesec začetka zavarovanja (tj. zavarovalnega leta)
      dan := DayOf(vpZavarovanjeOd.Date);
      mesec := MonthOf(vpZavarovanjeOd.Date);
      leto := YearOf(vpZavarovanjeOd.Date);
      // Če dan začetka zavarovanja ni 1. dan v mesecu, se lahko škoda simulira šele naslednji mesec (1.)
      if dan > 1 then
        mesec := mesec + 1;
      // polnjenje seznama datumov začetka škode
      vpDatumFizicneSkode.Items.Clear;
      for i := mesec to (mesec+11) do begin
        if i > 12 then   // prehod v novo leto
         vpDatumFizicneSkode.Items.Add('1. ' + LjezikImeMeseca[i-12] + ' ' + IntToStr(leto + 1)) // naslednje leto od leta, v katerem je začetek zavarovanja
        else
         vpDatumFizicneSkode.Items.Add('1. ' + LjezikImeMeseca[i] + ' ' + IntToStr(leto));  // leto, v katerem je začetek zavarovanja
      end;
      // postavitev privzete vrednosti v izboru datuma škode na tekoči mesec
      mesecDanes := MonthOf(Today);
      if ((mesecDanes - mesec) = -1) then    // izjema, ki se zgodi, ko je zavarovanje sklenjeno v mesecu (a ne 1. v tem mesecu), ki je enak sistemskemu mesecu
          vpDatumFizicneSkode.ItemIndex := 0
      else if (mesecDanes >= mesec) then
         vpDatumFizicneSkode.ItemIndex := mesecDanes - mesec
      else
         vpDatumFizicneSkode.ItemIndex := 12 - mesec + mesecDanes;  // upošteva, da gre za naslednje leto
      mesecSkode := vpDatumFizicneSkode.ItemIndex + mesec;
      // Vnos letnic v naslovno vrstico tabele
      ploscicaPromet_Naslov2.Caption := IntToStr(letoPodatkov - 1);
      ploscicaPromet_Naslov3.Caption := IntToStr(letoPodatkov );
      ploscicaPromet_Naslov4.Caption := IntToStr(letoPodatkov + 1);
      ploscicaPromet_Naslov5.Caption := IntToStr(letoPodatkov + 2);
      ploscicaPromet_Naslov6.Caption := 'I ' + IntToStr(letoPodatkov) + ' / ' + IntToStr(letoPodatkov - 1);
      ploscicaPromet_Naslov7.Caption := 'I ' + IntToStr(letoPodatkov + 1) + ' / ' + IntToStr(letoPodatkov);
      ploscicaPromet_Naslov8.Caption := 'I ' + IntToStr(letoPodatkov + 2) + ' / ' + IntToStr(letoPodatkov + 1);
      // praznjenje vrednosti v vnosnih poljih
      for i := 1 to 4 do begin
          for j := 1 to 12 do begin
              zapis := FindComponent('ploscicaPromet_Promet_'+IntToStr(i)+'_'+IntToStr(j)) as TEdit;
              zapis.Color := clWhite;
              zapis.Text := '';
          end;
      end;
      for i := 1 to 3 do begin
          for j := 1 to 12 do begin
              zapis := FindComponent('ploscicaPromet_Indeks_'+IntToStr(i)+'_'+IntToStr(j)) as TEdit;
              zapis.Text := '';
          end;
      end;
      // prepis podatkov o prometu za leto X-1
      for j := 1 to 12 do begin
          zapis2 := FindComponent('ploscicaAnalizeProdaje_izPromet_1_'+IntToStr(j)) as TLabel;
          tekst := zapis2.Caption;
          zapis := FindComponent('ploscicaPromet_Promet_1_'+IntToStr(j)) as TEdit;
          zapis.Text := tekst;
      end;
      // prepis podatkov iz leta X (in iz X+1, če je škoda v novem letu) - do meseca škode
      for j := 1 to mesecSkode-1 do begin
          if j > 12 then begin   // če je j več kot 12, pomeni, da se gledajo podatki v novem letu
             vrstica := j - 12;
             stolpec := 3;
          end
          else begin
             vrstica := j;
             stolpec := 2;
          end;
         zapis2 := FindComponent('ploscicaAnalizeProdaje_izPromet_'+IntToStr(stolpec)+'_'+IntToStr(vrstica)) as TLabel;
         tekst := zapis2.Caption;
         zapis := FindComponent('ploscicaPromet_Promet_'+IntToStr(stolpec)+'_'+IntToStr(vrstica)) as TEdit;
         zapis.Text := tekst;
      end;

      // zavihek rešen promet - praznjenje vrednosti
      vpPovisaniPoslovniStroskiZnesek.Text := '';
      vpResenPrometZnesek.Text := '';
      vpPovisaniPoslovniStroskiObdobje.ItemIndex := 0;
      // zavihek prihranki - praznjenje vrednosti
      for i := 1 to 2 do begin
          for j := 1 to 10 do begin
              zapis := FindComponent('ploscicaPrihranki_Prihranek_'+IntToStr(i)+'_'+IntToStr(j)) as TEdit;
              zapis.Text := '';
          end;
      end;
      vpPrihrankiObdobje.ItemIndex := 0;
      // postavitev na prvi zavihek
      DelovniListiPodatkiZaSimulacijoSkod.TabIndex := 0;
      // Postavi se na ustreno stran (list) na ploščici ploscicaDelovniListi
      izNaslovDL10.Caption := LjezikNaslovDL10;
      ploscicaDelovniListi.TabIndex := izborSimulacijeSkod.ItemIndex+10;
    end
    else
      ShowMessage(LjezikObvestilo_IzborSimulacijaSkode);
  end
  else begin

   // SIMULACIJA ŠKODE !!!


       // Določi datum začetka in datum konca zavarovanja, kot je na zavihku "Obstoječa zavarovanja"

      vpZavarovanjeOd.ReadOnly := false;
      vpZavarovanjeOd.BorderStyle := bsSingle;
      vpZavarovanjeOd.Date := Encodedate(StrToInt(vpOpazovanoLeto.Text), 01, 01);
      vpZavarovanjeDo.Date := vpZavarovanjeOd.Date + 365;

      // Prepis podatkov o ZV in MDJ iz zavihka "Obstoječa zavarovanja"
      izZavarovalnaVsota.ReadOnly := false;
      izZavarovalnaVsota.BorderStyle := bsSingle;
      izZavarovalnaVsota.Alignment := taRightJustify;
      izZavarovalnaVsota.AutoSelect := true;
      izZavarovalnaVsota.Text := '0,00';

      izPremija.ReadOnly := false;
      izPremija.BorderStyle := bsSingle;
      izPremija.Alignment := taRightJustify;
      izPremija.AutoSelect := true;
      izPremija.text := '0,00';

      izMaksimalnaDObaJamcenja.Enabled := true;
      izMaksimalnaDobaJamcenja.ItemIndex := 0;

      letoPodatkov := StrToInt(vpOpazovanoLeto.Text);
      // preveri dan in mesec začetka zavarovanja (tj. zavarovalnega leta)
      dan := DayOf(vpZavarovanjeOd.Date);
      mesec := MonthOf(vpZavarovanjeOd.Date);
      leto := YearOf(vpZavarovanjeOd.Date);
      // Če dan začetka zavarovanja ni 1. dan v mesecu, se lahko škoda simulira šele naslednji mesec (1.)
      if dan > 1 then
        mesec := mesec + 1;
      // polnjenje seznama datumov začetka škode
      vpDatumFizicneSkode.Items.Clear;
      for i := mesec to (mesec+11) do begin
        if i > 12 then   // prehod v novo leto
         vpDatumFizicneSkode.Items.Add('1. ' + LjezikImeMeseca[i-12] + ' ' + IntToStr(leto + 1)) // naslednje leto od leta, v katerem je začetek zavarovanja
        else
         vpDatumFizicneSkode.Items.Add('1. ' + LjezikImeMeseca[i] + ' ' + IntToStr(leto));  // leto, v katerem je začetek zavarovanja
      end;
      // postavitev privzete vrednosti v izboru datuma škode na tekoči mesec
      mesecDanes := MonthOf(Today);
      if ((mesecDanes - mesec) = -1) then    // izjema, ki se zgodi, ko je zavarovanje sklenjeno v mesecu (a ne 1. v tem mesecu), ki je enak sistemskemu mesecu
          vpDatumFizicneSkode.ItemIndex := 0
      else if (mesecDanes >= mesec) then
         vpDatumFizicneSkode.ItemIndex := mesecDanes - mesec
      else
         vpDatumFizicneSkode.ItemIndex := 12 - mesec + mesecDanes;  // upošteva, da gre za naslednje leto
      mesecSkode := vpDatumFizicneSkode.ItemIndex + mesec;
      // Vnos letnic v naslovno vrstico tabele
      ploscicaPromet_Naslov2.Caption := IntToStr(letoPodatkov - 1);
      ploscicaPromet_Naslov3.Caption := IntToStr(letoPodatkov );
      ploscicaPromet_Naslov4.Caption := IntToStr(letoPodatkov + 1);
      ploscicaPromet_Naslov5.Caption := IntToStr(letoPodatkov + 2);
      ploscicaPromet_Naslov6.Caption := 'I ' + IntToStr(letoPodatkov) + ' / ' + IntToStr(letoPodatkov - 1);
      ploscicaPromet_Naslov7.Caption := 'I ' + IntToStr(letoPodatkov + 1) + ' / ' + IntToStr(letoPodatkov);
      ploscicaPromet_Naslov8.Caption := 'I ' + IntToStr(letoPodatkov + 2) + ' / ' + IntToStr(letoPodatkov + 1);
      // praznjenje vrednosti v vnosnih poljih
      for i := 1 to 4 do begin
          for j := 1 to 12 do begin
              zapis := FindComponent('ploscicaPromet_Promet_'+IntToStr(i)+'_'+IntToStr(j)) as TEdit;
              zapis.Color := clWhite;
              zapis.Text := '';
          end;
      end;
      for i := 1 to 3 do begin
          for j := 1 to 12 do begin
              zapis := FindComponent('ploscicaPromet_Indeks_'+IntToStr(i)+'_'+IntToStr(j)) as TEdit;
              zapis.Text := '';
          end;
      end;
      // prepis podatkov o prometu za leto X-1
      for j := 1 to 12 do begin
          zapis2 := FindComponent('ploscicaAnalizeProdaje_izPromet_1_'+IntToStr(j)) as TLabel;
          tekst := zapis2.Caption;
          zapis := FindComponent('ploscicaPromet_Promet_1_'+IntToStr(j)) as TEdit;
          zapis.Text := tekst;
      end;
      // prepis podatkov iz leta X (in iz X+1, če je škoda v novem letu) - do meseca škode
      for j := 1 to mesecSkode-1 do begin
          if j > 12 then begin   // če je j več kot 12, pomeni, da se gledajo podatki v novem letu
             vrstica := j - 12;
             stolpec := 3;
          end
          else begin
             vrstica := j;
             stolpec := 2;
          end;
         zapis2 := FindComponent('ploscicaAnalizeProdaje_izPromet_'+IntToStr(stolpec)+'_'+IntToStr(vrstica)) as TLabel;
         tekst := zapis2.Caption;
         zapis := FindComponent('ploscicaPromet_Promet_'+IntToStr(stolpec)+'_'+IntToStr(vrstica)) as TEdit;
         zapis.Text := tekst;
      end;

      // zavihek rešen promet - praznjenje vrednosti
      vpPovisaniPoslovniStroskiZnesek.Text := '';
      vpResenPrometZnesek.Text := '';
      vpPovisaniPoslovniStroskiObdobje.ItemIndex := 0;
      // zavihek prihranki - praznjenje vrednosti
      for i := 1 to 2 do begin
          for j := 1 to 10 do begin
              zapis := FindComponent('ploscicaPrihranki_Prihranek_'+IntToStr(i)+'_'+IntToStr(j)) as TEdit;
              zapis.Text := '';
          end;
      end;
      vpPrihrankiObdobje.ItemIndex := 0;
      // postavitev na prvi zavihek
      DelovniListiPodatkiZaSimulacijoSkod.TabIndex := 0;
      // Postavi se na ustreno stran (list) na ploščiti ploscicaDelovniListi
      izNaslovDL10.Caption := LjezikNaslovDL10simulirano;
      ploscicaDelovniListi.TabIndex := izborSimulacijeSkod.ItemIndex+9;

  end;
end;

procedure TfmOsnovniKontroler.izborVhodniPodatkiChange(Sender: TObject);
begin
  // Postavi vrednosti v ostalih dveh seznamih na prazno vrednost, da izgleda kot da gre za en dolg menu
  if izborVhodniPodatki.ItemIndex <> -1 then begin
    izborAnalize.ItemIndex := -1;
    izborSimulacijeSkod.ItemIndex := -1;
    izborAnalize.ClearSelection;
    izborSimulacijeSkod.ClearSelection;
  end;
  // Postavi se na ustreno stran (list) na ploščiti ploscicaDelovniListi
  ploscicaDelovniListi.TabIndex := izborVhodniPodatki.ItemIndex + 1;
end;

procedure TfmOsnovniKontroler.ListiProdajnihTrendovChange(Sender: TObject);
var it : TGoogleChartSeriesItem;
    AnnotationType: TGoogleChartAnnotationType;
    i, j : Integer;
    poljeBranje : TLabel;
    zapis : real;
    datum : String;
begin

  If ListiProdajnihTrendov.TabIndex = 1 then begin
     GrafMesecniTrendi.BeginUpdate;
     GrafMesecniTrendi.Series.Clear;
     GrafMesecniTrendi.Options.Clear;
     GrafMesecniTrendi.Appearance.Legend.Position := gcpNone;
     AnnotationType := gcatData;
     it := GrafMesecniTrendi.Series.Add;
     it.ChartType := gctArea;

     it.Line.LineWidth := 2;
     it.Line.PointSize := 0;

     for i := 1 to 12 do begin

         poljeBranje := FindComponent('ploscicaAnalizeProdaje_izPromet_1_'+IntToStr(i)) as TLabel;
         zapis := StrToFloat(StringReplace(poljeBranje.Caption, '.', '', [rfreplaceAll, rfIgnorecase]));

         poljeBranje := FindComponent('ploscicaAnalizeProdaje_izMesec_'+IntToStr(i)) as TLabel;
         datum := poljeBranje.Caption + ', ';
         poljeBranje := FindComponent('ploscicaAnalizeProdaje_izPromet_NaslovXminus1') as TLabel;
         datum := datum + StringReplace(poljeBranje.Caption, LjezikLeto+' ', '', [rfreplaceAll, rfIgnorecase]);

         it.Values.AddSinglePoint(zapis, datum);

     end;

     for i := 1 to 12 do begin

         poljeBranje := FindComponent('ploscicaAnalizeProdaje_izPromet_2_'+IntToStr(i)) as TLabel;
         zapis := StrToFloat(StringReplace(poljeBranje.Caption, '.', '', [rfreplaceAll, rfIgnorecase]));

         poljeBranje := FindComponent('ploscicaAnalizeProdaje_izMesec_'+IntToStr(i)) as TLabel;
         datum := poljeBranje.Caption + ', ';
         poljeBranje := FindComponent('ploscicaAnalizeProdaje_izPromet_NaslovX') as TLabel;
         datum := datum + StringReplace(poljeBranje.Caption, LjezikLeto+' ', '', [rfreplaceAll, rfIgnorecase]);

         it.Values.AddSinglePoint(zapis, datum);

     end;

     for j := 3 to 6 do begin
         for i := 1 to 12 do begin

             poljeBranje := FindComponent('ploscicaAnalizeProdaje_izPromet_'+IntToStr(j)+'_'+IntToStr(i)) as TLabel;
             zapis := StrToFloat(StringReplace(poljeBranje.Caption, '.', '', [rfreplaceAll, rfIgnorecase]));

             poljeBranje := FindComponent('ploscicaAnalizeProdaje_izMesec_'+IntToStr(i)) as TLabel;
             datum := poljeBranje.Caption + ', ';
             poljeBranje := FindComponent('ploscicaAnalizeProdaje_izPromet_NaslovXplus'+IntToStr(j-2)) as TLabel;
             datum := datum + StringReplace(poljeBranje.Caption, LjezikLeto+' ', '', [rfreplaceAll, rfIgnorecase]);

             it.Values.AddSinglePoint(zapis, datum);

         end;
     end;

     GrafMesecniTrendi.Enabled := true;
     GrafMesecniTrendi.EndUpdate;

  end
  else if ListiProdajnihTrendov.TabIndex = 2 then begin

     GrafLetniTrendi.BeginUpdate;
     GrafLetniTrendi.Series.Clear;
     GrafLetniTrendi.Options.Clear;
     GrafLetniTrendi.Appearance.Legend.Position := gcpNone;
     AnnotationType := gcatData;
     it := GrafLetniTrendi.Series.Add;
     it.ChartType := gctArea;

     it.Line.LineWidth := 2;
     it.Line.PointSize := 0;

     zapis := 0;
     for i := 1 to 12 do begin
         poljeBranje := FindComponent('ploscicaAnalizeProdaje_izPromet_1_'+IntToStr(i)) as TLabel;
         zapis := zapis + StrToFloat(StringReplace(poljeBranje.Caption, '.', '', [rfreplaceAll, rfIgnorecase]));
     end;
     poljeBranje := FindComponent('ploscicaAnalizeProdaje_izPromet_NaslovXminus1') as TLabel;
     datum := poljeBranje.Caption;
     it.Values.AddSinglePoint(zapis, datum);

     zapis := 0;
     for i := 1 to 12 do begin
         poljeBranje := FindComponent('ploscicaAnalizeProdaje_izPromet_2_'+IntToStr(i)) as TLabel;
         zapis := zapis + StrToFloat(StringReplace(poljeBranje.Caption, '.', '', [rfreplaceAll, rfIgnorecase]));
     end;
     poljeBranje := FindComponent('ploscicaAnalizeProdaje_izPromet_NaslovX') as TLabel;
     datum := poljeBranje.Caption;
     it.Values.AddSinglePoint(zapis, datum);

     for j := 3 to 6 do begin
         zapis := 0;
         for i := 1 to 12 do begin
             poljeBranje := FindComponent('ploscicaAnalizeProdaje_izPromet_'+IntToStr(j)+'_'+IntToStr(i)) as TLabel;
             zapis := zapis + StrToFloat(StringReplace(poljeBranje.Caption, '.', '', [rfreplaceAll, rfIgnorecase]));
         end;
         poljeBranje := FindComponent('ploscicaAnalizeProdaje_izPromet_NaslovXplus'+IntToStr(j-2)) as TLabel;
         datum := poljeBranje.Caption;
         it.Values.AddSinglePoint(zapis, datum);
     end;

     GrafLetniTrendi.Enabled := true;
     GrafLetniTrendi.EndUpdate;

  end;
end;

procedure TfmOsnovniKontroler.mn3Click(Sender: TObject);
  var maxZastoj : Integer;
      vrstica, stolpec : Integer;
      i, j : Integer;
      leto, mesec, dan : Word;
      poljePisanje, PoljeBranje1, poljeBranje2 : TEdit;
      poljeBranje : TLabel;
      tekst, s1, s2 : String;
begin
     case (Sender as TMenuItem).Name of
       'mn3' : maxZastoj := 3;
       'mn4' : maxZastoj := 4;
       'mn5' : maxZastoj := 5;
       'mn6' : maxZastoj := 6;
       'mn7' : maxZastoj := 7;
       'mn8' : maxZastoj := 8;
       'mn9' : maxZastoj := 9;
       'mn10' : maxZastoj := 10;
       'mn11' : maxZastoj := 11;
       'mn12' : maxZastoj := 12;
     end;

     // podatki o začetku zavarovanja
     dan := DayOf(vpZavarovanjeOd.Date);
     mesec := MonthOf(vpZavarovanjeOd.Date);
     leto := YearOf(vpZavarovanjeOd.Date);
     // škoda se lahko simulira samo na 1. v mesecu, kar pomen, če začetek zaavrovanja ni 1. v mesecu, je lahko škoda šele naslednji mesec (1.)
     if dan > 1 then
       mesec := mesec + 1;
     // izračun polja, kjer se v tabeli začne zastoj
     vrstica := vpDatumFizicneSkode.ItemIndex + mesec; // ugotovi, kateri mesec je nastala škoda (tj. bila izbrana iz spustnega seznama)
     stolpec := StrToInt(vpOpazovanoLeto.Text) - leto + 2 ;
     // počisti polne celice)
     for i := 1 to 4 do begin
       for j := 1 to 12 do begin
         poljePisanje := FindComponent('ploscicaPromet_Promet_'+IntToStr(i)+'_'+IntToStr(j)) as TEdit;
         poljePisanje.Text := '';
         poljePisanje.Color := clWhite;
       end;
     end;
     for i := 1 to 3 do begin
       for j := 1 to 12 do begin
         poljePisanje := FindComponent('ploscicaPromet_Indeks_'+IntToStr(i)+'_'+IntToStr(j)) as TEdit;
         poljePisanje.Text := '';
         poljePisanje.Color := clWhite;
       end;
     end;
     // vnos prometov v višini 0 (popolni zastoj) za število mesecev, kot je bila izbrana dolžina popolnega zastoja
     for i := 1 to maxZastoj do Begin
       if vrstica > 12 then begin
         stolpec := stolpec + 1;
         vrstica := vrstica - 12;
       end;
       poljePisanje := FindComponent('ploscicaPromet_Promet_'+IntToStr(stolpec)+'_'+IntToStr(vrstica)) as TEdit;
       poljePisanje.Text := '0,00';
       poljePisanje.Color := clWebCornSilk;
       vrstica := vrstica + 1;
     end;
     // preveri ali je celica prazna in če je, vanjo prepiše vrednost tabele z analizo planiranih prometov
     for i := 1 to 4 do begin
       for j := 1 to 12 do begin
         poljePisanje := FindComponent('ploscicaPromet_Promet_'+IntToStr(i)+'_'+IntToStr(j)) as TEdit;
         if (poljePisanje.Text = '') then begin
            poljeBranje := FindComponent('ploscicaAnalizeProdaje_izPromet_'+IntToStr(i)+'_'+IntToStr(j)) as TLabel;
            tekst := poljeBranje.Caption;
            poljePisanje.Text := tekst;
         end;
       end;
     end;
     // Preračuna indekse (vse)
     for i := 1 to 3 do begin
       for j := 1 to 12 do begin
         poljePisanje := FindComponent('ploscicaPromet_Indeks_'+IntToStr(i)+'_'+IntToStr(j)) as TEdit;
         poljeBranje2 := FindComponent('ploscicaPromet_Promet_'+IntToStr(i+1)+'_'+IntToStr(j)) as TEdit;
         poljeBranje1 := FindComponent('ploscicaPromet_Promet_'+IntToStr(i)+'_'+IntToStr(j)) as TEdit;
         s1 := Stringreplace(poljeBranje1.Text, '.', '', [rfreplaceAll, rfIgnorecase]);
         s2 := Stringreplace(poljeBranje2.Text, '.', '', [rfreplaceAll, rfIgnorecase]);
         poljePisanje.Text := FormatFloat('###,###,##0.00', StrToFloat(s2) / StrToFloat(s1) * 100);
       end;
     end;

   if vpGrafikaSkode.Position = 1 then
      IzrisGrafaSimulacijeSkod;
end;

procedure TfmOsnovniKontroler.mnKrivulja1Click(Sender: TObject);
  var maxZastoj : Integer;
      vrstica, stolpec : Integer;
      i, j : Integer;
      leto, mesec, dan : Word;
      poljePisanje, PoljeBranje1, poljeBranje2 : TEdit;
      poljeBranje : TLabel;
      tekst, s1, s2 : String;

  const krivulja1 : array[1..12] of Integer = (95, 75, 50, 30, 10, 5, 0, 0, 0, 10, 25, 50);
  const krivulja2 : array[1..12] of Integer = (95, 90, 70, 25, 5, 5, 5, 5, 5, 5, 20, 50);
  const krivulja3 : array[1..12] of Integer = (90, 10, 0, 0, 0, 0, 0, 10, 25, 50, 85, 95);

begin
   maxZastoj := 12;

   // podatki o začetku zavarovanja
   dan := DayOf(vpZavarovanjeOd.Date);
   mesec := MonthOf(vpZavarovanjeOd.Date);
   leto := YearOf(vpZavarovanjeOd.Date);
   // škoda se lahko simulira samo na 1. v mesecu, kar pomen, če začetek zaavrovanja ni 1. v mesecu, je lahko škoda šele naslednji mesec (1.)
   if dan > 1 then
      mesec := mesec + 1;
   // izračun polja, kjer se v tabeli začne zastoj
   vrstica := vpDatumFizicneSkode.ItemIndex + mesec; // ugotovi, kateri mesec je nastala škoda (tj. bila izbrana iz spustnega seznama)
   stolpec := StrToInt(vpOpazovanoLeto.Text) - leto + 2 ;
   // počisti polne celice)
   for i := 1 to 4 do begin
      for j := 1 to 12 do begin
        poljePisanje := FindComponent('ploscicaPromet_Promet_'+IntToStr(i)+'_'+IntToStr(j)) as TEdit;
        poljePisanje.Text := '';
        poljePisanje.Color := clWhite;
      end;
   end;
   for i := 1 to 3 do begin
      for j := 1 to 12 do begin
        poljePisanje := FindComponent('ploscicaPromet_Indeks_'+IntToStr(i)+'_'+IntToStr(j)) as TEdit;
        poljePisanje.Text := '';
        poljePisanje.Color := clWhite;
      end;
   end;

   // vnos prometov v višini 0 (popolni zastoj) za število mesecev, kot je bila izbrana dolžina popolnega zastoja
   for i := 1 to maxZastoj do begin
     if vrstica > 12 then begin
        stolpec := stolpec + 1;
        vrstica := vrstica - 12;
     end;
     poljePisanje := FindComponent('ploscicaPromet_Promet_'+IntToStr(stolpec)+'_'+IntToStr(vrstica)) as TEdit;
     poljeBranje := FindComponent('ploscicaAnalizeProdaje_izPromet_'+IntToStr(stolpec)+'_'+IntToStr(vrstica)) as TLabel;

     case (Sender as TMenuItem).Name of
       'mnKrivulja1' : poljePisanje.Text := FormatFloat('###,###,##0.00', StrToFloat(StringReplace(poljeBranje.Caption, '.', '', [rfreplaceAll, rfIgnorecase])) * krivulja1[i] / 100);
       'mnKrivulja2' : poljePisanje.Text := FormatFloat('###,###,##0.00', StrToFloat(StringReplace(poljeBranje.Caption, '.', '', [rfreplaceAll, rfIgnorecase])) * krivulja2[i] / 100);
       'mnKrivulja3' : poljePisanje.Text := FormatFloat('###,###,##0.00', StrToFloat(StringReplace(poljeBranje.Caption, '.', '', [rfreplaceAll, rfIgnorecase])) * krivulja3[i] / 100);
     end;

     poljePisanje.Color := clWebCornSilk;
     vrstica := vrstica + 1;
   end;

   // preveri ali je celica prazna in če je, vanjo prepiše vrednost tabele z analizo planiranih prometov
   for i := 1 to 4 do begin
     for j := 1 to 12 do begin
        poljePisanje := FindComponent('ploscicaPromet_Promet_'+IntToStr(i)+'_'+IntToStr(j)) as TEdit;
        if (poljePisanje.Text = '') then begin
           poljeBranje := FindComponent('ploscicaAnalizeProdaje_izPromet_'+IntToStr(i)+'_'+IntToStr(j)) as TLabel;
           tekst := poljeBranje.Caption;
           poljePisanje.Text := tekst;
        end;
     end;
   end;
   // Preračuna indekse (vse)
   for i := 1 to 3 do begin
     for j := 1 to 12 do begin
        poljePisanje := FindComponent('ploscicaPromet_Indeks_'+IntToStr(i)+'_'+IntToStr(j)) as TEdit;
        poljeBranje2 := FindComponent('ploscicaPromet_Promet_'+IntToStr(i+1)+'_'+IntToStr(j)) as TEdit;
        poljeBranje1 := FindComponent('ploscicaPromet_Promet_'+IntToStr(i)+'_'+IntToStr(j)) as TEdit;
        s1 := Stringreplace(poljeBranje1.Text, '.', '', [rfreplaceAll, rfIgnorecase]);
        s2 := Stringreplace(poljeBranje2.Text, '.', '', [rfreplaceAll, rfIgnorecase]);
        poljePisanje.Text := FormatFloat('###,###,##0.00', StrToFloat(s2) / StrToFloat(s1) * 100);
     end;
   end;

   if vpGrafikaSkode.Position = 1 then
      IzrisGrafaSimulacijeSkod;
end;

procedure TfmOsnovniKontroler.mnNovProjektClick(Sender: TObject);
begin
  posiljateljObvestilo := 1;
  obvestilo.ShowDialog();
end;

procedure TfmOsnovniKontroler.mnOdpriProjektClick(Sender: TObject);
begin
  posiljateljObvestilo := 2;
  obvestilo.ShowDialog();
end;

procedure TfmOsnovniKontroler.mnOsveziPrivzeteVrednostiClick(Sender: TObject);
var  izbor : Integer;

begin

  if (StrToFloat(vpVnosFRPPromet.Text) = 0) then begin
     ShowMessage(LjezikObvestilo_mnOsveziAnalize_01 + sLineBreak + sLineBreak + LjezikObvestilo_mnOsveziAnalize_02 + sLineBreak + sLineBreak + LjezikObvestilo_mnOsveziAnalize_03);
  end
  else begin

    case (Sender As TMenuItem).Name of
      'mnOsveziPrivzeteVrednosti' : izbor := 1;
      'mnOsveziDejanskeVrednosti' : izbor := 2;
    end;

    if izbor = 1  then begin

      fmOsnovniKontroler.Cursor := crHourGlass;

       // Postavitev vrednosti na strani ANALIZE - Določanje maksimalne dobe jamčenja
       // Postavitev osnovnih vrednosti glede na odgovore na stran VHODNI PODATKI - Organizacija
      vpParameter1_1.Position := 6;
      izParameter1_1.Caption := '1,50';
      vpParameter1_2.Position := 2;
      izParameter1_2.Caption := '0,50';
      vpParameter2_1.Position := 10;
      izParameter2_1.Caption := '2,50';
      vpParameter2_2.Position := 2;
      izParameter2_2.Caption := '0,50';
      vpParameter3_1.Position := 16;
      izParameter3_1.Caption := '4,00';
      vpParameter3_2.Position := 0;
      izParameter3_2.Caption := '0,00';
      vpParameter4_1.Position := 10;
      izParameter4_1.Caption := '2,50';
      vpParameter4_2.Position := 0;
      izParameter4_2.Caption := '0,00';
      vpParameter5_1.Position := 8;
      izParameter5_1.Caption := '2,00';
      vpParameter5_2.Position := 0;
      izParameter5_2.Caption := '0,00';
      vpParameter6_1.Position := 8;
      izParameter6_1.Caption := '2,00';
      vpParameter7_1.Position := 4;
      izParameter7_1.Caption := '1,00';

       if vpOrganizacija_OdgovorDA3.Checked = true then begin  //Če je možno nabaviti rabljene stroje
          izPloscicaKorekcijskiFaktorji_KF4_3.Caption := 'DA';
          vpPloscicaKorekcijskiFaktorji_KFDrsnik4_4.Position := 0;
          vpPloscicaKorekcijskiFaktorji_KFIzpis4_4.Caption := '0,00';
          vpPloscicaKorekcijskiFaktorji_KFDrsnik4_4.Enabled := true;
          vpPloscicaKorekcijskiFaktorji_KFIzpis4_4.Enabled := true;
       end
       else begin
          izPloscicaKorekcijskiFaktorji_KF4_3.Caption := 'NE';
          vpPloscicaKorekcijskiFaktorji_KFDrsnik4_4.Position := 0;
          vpPloscicaKorekcijskiFaktorji_KFIzpis4_4.Caption := '0,00';
          vpPloscicaKorekcijskiFaktorji_KFDrsnik4_4.Enabled := false;
          vpPloscicaKorekcijskiFaktorji_KFIzpis4_4.Enabled := false
       end;
       if vpOrganizacija_OdgovorDA6.Checked = true then begin  //Če so potrebni posebni delovni pogoji
          izPloscicaKorekcijskiFaktorji_KF3_3.Caption := 'DA';
          vpPloscicaKorekcijskiFaktorji_KFDrsnik3_4.Position := 4;
          vpPloscicaKorekcijskiFaktorji_KFIzpis3_4.Caption := '1,00';
          vpPloscicaKorekcijskiFaktorji_KFDrsnik3_4.Enabled := true;
          vpPloscicaKorekcijskiFaktorji_KFIzpis3_4.Enabled := true;
       end
       else begin
          izPloscicaKorekcijskiFaktorji_KF3_3.Caption := 'NE';
          vpPloscicaKorekcijskiFaktorji_KFDrsnik3_4.Position := 0;
          vpPloscicaKorekcijskiFaktorji_KFIzpis3_4.Caption := '0,00';
          vpPloscicaKorekcijskiFaktorji_KFDrsnik3_4.Enabled := false;
          vpPloscicaKorekcijskiFaktorji_KFIzpis3_4.Enabled := false
       end;
       if vpOrganizacija_OdgovorDA7.Checked = true then begin  //Če se v proizvodnji uporabljajo nevarne snovi
          izPloscicaKorekcijskiFaktorji_KF1_3.Caption := 'DA';
          vpPloscicaKorekcijskiFaktorji_KFDrsnik1_4.Position := 4;
          vpPloscicaKorekcijskiFaktorji_KFIzpis1_4.Caption := '1,00';
          vpPloscicaKorekcijskiFaktorji_KFDrsnik1_4.Enabled := true;
          vpPloscicaKorekcijskiFaktorji_KFIzpis1_4.Enabled := true;
       end
       else begin
          izPloscicaKorekcijskiFaktorji_KF1_3.Caption := 'NE';
          vpPloscicaKorekcijskiFaktorji_KFDrsnik1_4.Position := 0;
          vpPloscicaKorekcijskiFaktorji_KFIzpis1_4.Caption := '0,00';
          vpPloscicaKorekcijskiFaktorji_KFDrsnik1_4.Enabled := false;
          vpPloscicaKorekcijskiFaktorji_KFIzpis1_4.Enabled := false;
       end;
       if vpOrganizacija_OdgovorDA8.Checked = true then begin  //Če se vgrajene nevarne snovi
          izPloscicaKorekcijskiFaktorji_KF2_3.Caption := 'DA';
          vpPloscicaKorekcijskiFaktorji_KFDrsnik2_4.Position := 2;
          vpPloscicaKorekcijskiFaktorji_KFIzpis2_4.Caption := '0,50';
          vpPloscicaKorekcijskiFaktorji_KFDrsnik2_4.Enabled := true;
          vpPloscicaKorekcijskiFaktorji_KFIzpis2_4.Enabled := true;
       end
       else begin
          izPloscicaKorekcijskiFaktorji_KF2_3.Caption := 'NE';
          vpPloscicaKorekcijskiFaktorji_KFDrsnik2_4.Position := 0;
          vpPloscicaKorekcijskiFaktorji_KFIzpis2_4.Caption := '0,00';
          vpPloscicaKorekcijskiFaktorji_KFDrsnik2_4.Enabled := false;
          vpPloscicaKorekcijskiFaktorji_KFIzpis2_4.Enabled := false;
       end;
       if (vpOrganizacija_Odgovor9.Position < 3) or (vpOrganizacija_Odgovor10.Position < 75) then begin  //Če se vgrajene nevarne snovi
          izPloscicaKorekcijskiFaktorji_KF5_3.Caption := 'DA';
          vpPloscicaKorekcijskiFaktorji_KFDrsnik5_4.Position := 4;
          vpPloscicaKorekcijskiFaktorji_KFIzpis5_4.Caption := '1,00';
          vpPloscicaKorekcijskiFaktorji_KFDrsnik5_4.Enabled := true;
          vpPloscicaKorekcijskiFaktorji_KFIzpis5_4.Enabled := true;
       end
       else begin
         izPloscicaKorekcijskiFaktorji_KF5_3.Caption := 'NE';
         vpPloscicaKorekcijskiFaktorji_KFDrsnik5_4.Position := 0;
         vpPloscicaKorekcijskiFaktorji_KFIzpis5_4.Caption := '0,00';
         vpPloscicaKorekcijskiFaktorji_KFDrsnik5_4.Enabled := false;
         vpPloscicaKorekcijskiFaktorji_KFIzpis5_4.Enabled := false;
       end;
       vpKorekcijskiFaktorji.Checked := false;
       vpMaksimalniPribitek.Position := 10;
       izMaksimalniPribitek.Caption := '10 %';
       ploscicaMaksimalniPribitek.Visible := false;
       ploscicaKorekcijskiFaktorji.Visible := false;

       // Postavitev vrednosti na strani ANALIZE - Določanje zavarovalne vsote

       izTekstPriporoceniKD.Caption :=  LjezikDL7_izTekstPriporoceniKD + ' ' + vpOpazovanoLeto.Text + ':';
       izTekstPriporocenaSKD.Caption := LjezikDL7_izTekstPriporocenaSKD + ' ' + vpOpazovanoLeto.Text + ':';
       izTekstLetoPriporoceneZV.Caption :=  LjezikDL7_izTekstLetoPriporoceneZV + ':';

       izPriporoceniKD.Caption := vpVnosFRPKosmatiDobicek.Caption;
       izPriporocenaSKD.Caption := vpVnosFRPStopnjaKosmategaDobicka.Caption;
       izLetoPriporoceneZVmin.Caption := IntToStr(StrToInt(vpOpazovanoLeto.Text) + 1);
       izLetoPriporoceneZVmax.Caption := IntToStr(StrToInt(vpOpazovanoLeto.Text) + 2);
       vpLetoPriporoceneZV.Min := StrToInt(vpOpazovanoLeto.Text) + 1;
       vpLetoPriporoceneZV.Max := StrToInt(vpOpazovanoLeto.Text) + 2;
       vpLetoPriporoceneZV.Position := vpLetoPriporoceneZV.Min;
       izLetoPriporoceneZVmin.Font.Style := [fsBold];
       izLetoPriporoceneZVmax.Font.Style := [];

       CelotnoOsvezevanjeIzracunov;

    end
    else if izbor = 2 then begin   // OBDRŽIJO SE VNESENI PODATKI

      fmOsnovniKontroler.Cursor := crHourGlass;

      // Polnjenje vrednosti za izračun MDJ
      if vpOrganizacija_OdgovorDA3.Checked = true then begin  //Če je možno nabaviti rabljene stroje
         if izPloscicaKorekcijskiFaktorji_KF4_3.Caption = 'NE' then begin  // Če je bilo pred osveževanjem polje neaktivno (torej ni bilo na organizaciji izbrana opcija, polje postavi na aktivno (velja enako za vse naprej)
            izPloscicaKorekcijskiFaktorji_KF4_3.Caption := 'DA';
            vpPloscicaKorekcijskiFaktorji_KFDrsnik4_4.Position := 0;
            vpPloscicaKorekcijskiFaktorji_KFIzpis4_4.Caption := '0,00';
            vpPloscicaKorekcijskiFaktorji_KFDrsnik4_4.Enabled := true;
            vpPloscicaKorekcijskiFaktorji_KFIzpis4_4.Enabled := true;
         end;
      end
      else begin
          izPloscicaKorekcijskiFaktorji_KF4_3.Caption := 'NE';
          vpPloscicaKorekcijskiFaktorji_KFDrsnik4_4.Position := 0;
          vpPloscicaKorekcijskiFaktorji_KFIzpis4_4.Caption := '0,00';
          vpPloscicaKorekcijskiFaktorji_KFDrsnik4_4.Enabled := false;
          vpPloscicaKorekcijskiFaktorji_KFIzpis4_4.Enabled := false
      end;
      if vpOrganizacija_OdgovorDA6.Checked = true then begin  //Če so potrebni posebni delovni pogoji
         if izPloscicaKorekcijskiFaktorji_KF3_3.Caption = 'NE' then begin
            izPloscicaKorekcijskiFaktorji_KF3_3.Caption := 'DA';
            vpPloscicaKorekcijskiFaktorji_KFDrsnik3_4.Position := 4;
            vpPloscicaKorekcijskiFaktorji_KFIzpis3_4.Caption := '1,00';
            vpPloscicaKorekcijskiFaktorji_KFDrsnik3_4.Enabled := true;
            vpPloscicaKorekcijskiFaktorji_KFIzpis3_4.Enabled := true;
         end;
      end
      else begin
          izPloscicaKorekcijskiFaktorji_KF3_3.Caption := 'NE';
          vpPloscicaKorekcijskiFaktorji_KFDrsnik3_4.Position := 0;
          vpPloscicaKorekcijskiFaktorji_KFIzpis3_4.Caption := '0,00';
          vpPloscicaKorekcijskiFaktorji_KFDrsnik3_4.Enabled := false;
          vpPloscicaKorekcijskiFaktorji_KFIzpis3_4.Enabled := false
      end;
      if vpOrganizacija_OdgovorDA7.Checked = true then begin  //Če se v proizvodnji uporabljajo nevarne snovi
         if izPloscicaKorekcijskiFaktorji_KF1_3.Caption = 'NE' then begin
           izPloscicaKorekcijskiFaktorji_KF1_3.Caption := 'DA';
           vpPloscicaKorekcijskiFaktorji_KFDrsnik1_4.Position := 4;
           vpPloscicaKorekcijskiFaktorji_KFIzpis1_4.Caption := '1,00';
           vpPloscicaKorekcijskiFaktorji_KFDrsnik1_4.Enabled := true;
           vpPloscicaKorekcijskiFaktorji_KFIzpis1_4.Enabled := true;
         end;
      end
      else begin
          izPloscicaKorekcijskiFaktorji_KF1_3.Caption := 'NE';
          vpPloscicaKorekcijskiFaktorji_KFDrsnik1_4.Position := 0;
          vpPloscicaKorekcijskiFaktorji_KFIzpis1_4.Caption := '0,00';
          vpPloscicaKorekcijskiFaktorji_KFDrsnik1_4.Enabled := false;
          vpPloscicaKorekcijskiFaktorji_KFIzpis1_4.Enabled := false;
      end;
      if vpOrganizacija_OdgovorDA8.Checked = true then begin  //Če se vgrajene nevarne snovi
         if izPloscicaKorekcijskiFaktorji_KF2_3.Caption = 'NE' then begin
           izPloscicaKorekcijskiFaktorji_KF2_3.Caption := 'DA';
           vpPloscicaKorekcijskiFaktorji_KFDrsnik2_4.Position := 2;
           vpPloscicaKorekcijskiFaktorji_KFIzpis2_4.Caption := '0,50';
           vpPloscicaKorekcijskiFaktorji_KFDrsnik2_4.Enabled := true;
           vpPloscicaKorekcijskiFaktorji_KFIzpis2_4.Enabled := true;
         end;
      end
      else begin
          izPloscicaKorekcijskiFaktorji_KF2_3.Caption := 'NE';
          vpPloscicaKorekcijskiFaktorji_KFDrsnik2_4.Position := 0;
          vpPloscicaKorekcijskiFaktorji_KFIzpis2_4.Caption := '0,00';
          vpPloscicaKorekcijskiFaktorji_KFDrsnik2_4.Enabled := false;
          vpPloscicaKorekcijskiFaktorji_KFIzpis2_4.Enabled := false;
      end;
      if (vpOrganizacija_Odgovor9.Position < 3) or (vpOrganizacija_Odgovor10.Position < 75) then begin  //Če se vgrajene nevarne snovi
         if izPloscicaKorekcijskiFaktorji_KF5_3.Caption = 'NE' then begin
           izPloscicaKorekcijskiFaktorji_KF5_3.Caption := 'DA';
           vpPloscicaKorekcijskiFaktorji_KFDrsnik5_4.Position := 4;
           vpPloscicaKorekcijskiFaktorji_KFIzpis5_4.Caption := '1,00';
           vpPloscicaKorekcijskiFaktorji_KFDrsnik5_4.Enabled := true;
           vpPloscicaKorekcijskiFaktorji_KFIzpis5_4.Enabled := true;
         end;
      end
      else begin
         izPloscicaKorekcijskiFaktorji_KF5_3.Caption := 'NE';
         vpPloscicaKorekcijskiFaktorji_KFDrsnik5_4.Position := 0;
         vpPloscicaKorekcijskiFaktorji_KFIzpis5_4.Caption := '0,00';
         vpPloscicaKorekcijskiFaktorji_KFDrsnik5_4.Enabled := false;
         vpPloscicaKorekcijskiFaktorji_KFIzpis5_4.Enabled := false;
      end;
      // Polnjenje podatkov na zavihku za izračun priporočene zavarovalne vsote

      izTekstPriporoceniKD.Caption :=  LjezikDL7_izTekstPriporoceniKD + ' ' + vpOpazovanoLeto.Text + ':';
      izTekstPriporocenaSKD.Caption := LjezikDL7_izTekstPriporocenaSKD + ' ' + vpOpazovanoLeto.Text + ':';
      izTekstLetoPriporoceneZV.Caption :=  LjezikDL7_izTekstLetoPriporoceneZV + ':';

      izPriporoceniKD.Caption := vpVnosFRPKosmatiDobicek.Caption;
      izPriporocenaSKD.Caption := vpVnosFRPStopnjaKosmategaDobicka.Caption;
      izLetoPriporoceneZVmin.Caption := IntToStr(StrToInt(vpOpazovanoLeto.Text) + 1);
      izLetoPriporoceneZVmax.Caption := IntToStr(StrToInt(vpOpazovanoLeto.text) + 2);
      vpLetoPriporoceneZV.Min := StrToInt(vpOpazovanoLeto.Text) + 1;
      vpLetoPriporoceneZV.Max := StrToInt(vpOpazovanoLeto.Text) + 2;

      CelotnoOsvezevanjeIzracunov;
    end;
  end;
end;

procedure TfmOsnovniKontroler.mnPomocClick(Sender: TObject);
const
  URL = 'ModelBI_Navodila.pdf';
begin
  Application.Navigate(URL, ntBlank);
end;

procedure TfmOsnovniKontroler.mnPrivzeteVrednostiClick(Sender: TObject);
var izbor : Integer;

begin
  case (Sender As TMenuItem).Name of
    'mnPrivzeteVrednosti' : izbor := 1;
    'mnDejanskeVrednosti' : izbor := 2;
  end;

  if izbor = 1  then begin

    fmOsnovniKontroler.Cursor := crHourGlass;

     // Postavitev vrednosti na strani ANALIZE - Določanje maksimalne dobe jamčenja
     // Postavitev osnovnih vrednosti glede na odgovore na stran VHODNI PODATKI - Organizacija
    vpParameter1_1.Position := 6;
    izParameter1_1.Caption := '1,50';
    vpParameter1_2.Position := 2;
    izParameter1_2.Caption := '0,50';
    vpParameter2_1.Position := 10;
    izParameter2_1.Caption := '2,50';
    vpParameter2_2.Position := 2;
    izParameter2_2.Caption := '0,50';
    vpParameter3_1.Position := 16;
    izParameter3_1.Caption := '4,00';
    vpParameter3_2.Position := 0;
    izParameter3_2.Caption := '0,00';
    vpParameter4_1.Position := 10;
    izParameter4_1.Caption := '2,50';
    vpParameter4_2.Position := 0;
    izParameter4_2.Caption := '0,00';
    vpParameter5_1.Position := 8;
    izParameter5_1.Caption := '2,00';
    vpParameter5_2.Position := 0;
    izParameter5_2.Caption := '0,00';
    vpParameter6_1.Position := 8;
    izParameter6_1.Caption := '2,00';
    vpParameter7_1.Position := 4;
    izParameter7_1.Caption := '1,00';

     if vpOrganizacija_OdgovorDA3.Checked = true then begin  //Če je možno nabaviti rabljene stroje
        izPloscicaKorekcijskiFaktorji_KF4_3.Caption := 'DA';
        vpPloscicaKorekcijskiFaktorji_KFDrsnik4_4.Position := 0;
        vpPloscicaKorekcijskiFaktorji_KFIzpis4_4.Caption := '0,00';
        vpPloscicaKorekcijskiFaktorji_KFDrsnik4_4.Enabled := true;
        vpPloscicaKorekcijskiFaktorji_KFIzpis4_4.Enabled := true;
     end
     else begin
        izPloscicaKorekcijskiFaktorji_KF4_3.Caption := 'NE';
        vpPloscicaKorekcijskiFaktorji_KFDrsnik4_4.Position := 0;
        vpPloscicaKorekcijskiFaktorji_KFIzpis4_4.Caption := '0,00';
        vpPloscicaKorekcijskiFaktorji_KFDrsnik4_4.Enabled := false;
        vpPloscicaKorekcijskiFaktorji_KFIzpis4_4.Enabled := false
     end;
     if vpOrganizacija_OdgovorDA6.Checked = true then begin  //Če so potrebni posebni delovni pogoji
        izPloscicaKorekcijskiFaktorji_KF3_3.Caption := 'DA';
        vpPloscicaKorekcijskiFaktorji_KFDrsnik3_4.Position := 4;
        vpPloscicaKorekcijskiFaktorji_KFIzpis3_4.Caption := '1,00';
        vpPloscicaKorekcijskiFaktorji_KFDrsnik3_4.Enabled := true;
        vpPloscicaKorekcijskiFaktorji_KFIzpis3_4.Enabled := true;
     end
     else begin
        izPloscicaKorekcijskiFaktorji_KF3_3.Caption := 'NE';
        vpPloscicaKorekcijskiFaktorji_KFDrsnik3_4.Position := 0;
        vpPloscicaKorekcijskiFaktorji_KFIzpis3_4.Caption := '0,00';
        vpPloscicaKorekcijskiFaktorji_KFDrsnik3_4.Enabled := false;
        vpPloscicaKorekcijskiFaktorji_KFIzpis3_4.Enabled := false
     end;
     if vpOrganizacija_OdgovorDA7.Checked = true then begin  //Če se v proizvodnji uporabljajo nevarne snovi
        izPloscicaKorekcijskiFaktorji_KF1_3.Caption := 'DA';
        vpPloscicaKorekcijskiFaktorji_KFDrsnik1_4.Position := 4;
        vpPloscicaKorekcijskiFaktorji_KFIzpis1_4.Caption := '1,00';
        vpPloscicaKorekcijskiFaktorji_KFDrsnik1_4.Enabled := true;
        vpPloscicaKorekcijskiFaktorji_KFIzpis1_4.Enabled := true;
     end
     else begin
        izPloscicaKorekcijskiFaktorji_KF1_3.Caption := 'NE';
        vpPloscicaKorekcijskiFaktorji_KFDrsnik1_4.Position := 0;
        vpPloscicaKorekcijskiFaktorji_KFIzpis1_4.Caption := '0,00';
        vpPloscicaKorekcijskiFaktorji_KFDrsnik1_4.Enabled := false;
        vpPloscicaKorekcijskiFaktorji_KFIzpis1_4.Enabled := false;
     end;
     if vpOrganizacija_OdgovorDA8.Checked = true then begin  //Če se vgrajene nevarne snovi
        izPloscicaKorekcijskiFaktorji_KF2_3.Caption := 'DA';
        vpPloscicaKorekcijskiFaktorji_KFDrsnik2_4.Position := 2;
        vpPloscicaKorekcijskiFaktorji_KFIzpis2_4.Caption := '0,50';
        vpPloscicaKorekcijskiFaktorji_KFDrsnik2_4.Enabled := true;
        vpPloscicaKorekcijskiFaktorji_KFIzpis2_4.Enabled := true;
     end
     else begin
        izPloscicaKorekcijskiFaktorji_KF2_3.Caption := 'NE';
        vpPloscicaKorekcijskiFaktorji_KFDrsnik2_4.Position := 0;
        vpPloscicaKorekcijskiFaktorji_KFIzpis2_4.Caption := '0,00';
        vpPloscicaKorekcijskiFaktorji_KFDrsnik2_4.Enabled := false;
        vpPloscicaKorekcijskiFaktorji_KFIzpis2_4.Enabled := false;
     end;
     if (vpOrganizacija_Odgovor9.Position < 3) or (vpOrganizacija_Odgovor10.Position < 75) then begin  //Če se vgrajene nevarne snovi
        izPloscicaKorekcijskiFaktorji_KF5_3.Caption := 'DA';
        vpPloscicaKorekcijskiFaktorji_KFDrsnik5_4.Position := 4;
        vpPloscicaKorekcijskiFaktorji_KFIzpis5_4.Caption := '1,00';
        vpPloscicaKorekcijskiFaktorji_KFDrsnik5_4.Enabled := true;
        vpPloscicaKorekcijskiFaktorji_KFIzpis5_4.Enabled := true;
     end
     else begin
       izPloscicaKorekcijskiFaktorji_KF5_3.Caption := 'NE';
       vpPloscicaKorekcijskiFaktorji_KFDrsnik5_4.Position := 0;
       vpPloscicaKorekcijskiFaktorji_KFIzpis5_4.Caption := '0,00';
       vpPloscicaKorekcijskiFaktorji_KFDrsnik5_4.Enabled := false;
       vpPloscicaKorekcijskiFaktorji_KFIzpis5_4.Enabled := false;
     end;
     vpKorekcijskiFaktorji.Checked := false;
     vpMaksimalniPribitek.Position := 10;
     izMaksimalniPribitek.Caption := '10 %';
     ploscicaMaksimalniPribitek.Visible := false;
     ploscicaKorekcijskiFaktorji.Visible := false;

     // Postavitev vrednosti na strani ANALIZE - Določanje zavarovalne vsote
     izTekstPriporoceniKD.Caption :=  LjezikDL7_izTekstPriporoceniKD + ' ' + vpOpazovanoLeto.Text + ':';
     izTekstPriporocenaSKD.Caption := LjezikDL7_izTekstPriporocenaSKD + ' ' + vpOpazovanoLeto.Text + ':';
     izTekstLetoPriporoceneZV.Caption :=  LjezikDL7_izTekstLetoPriporoceneZV + ':';

     izPriporoceniKD.Caption := vpVnosFRPKosmatiDobicek.Caption;
     izPriporocenaSKD.Caption := vpVnosFRPStopnjaKosmategaDobicka.Caption;
     izLetoPriporoceneZVmin.Caption := IntToStr(StrToInt(vpOpazovanoLeto.Text) + 1);
     izLetoPriporoceneZVmax.Caption := IntToStr(StrToInt(vpOpazovanoLeto.Text) + 2);
     vpLetoPriporoceneZV.Min := StrToInt(vpOpazovanoLeto.Text) + 1;
     vpLetoPriporoceneZV.Max := StrToInt(vpOpazovanoLeto.Text) + 2;
     vpLetoPriporoceneZV.Position := vpLetoPriporoceneZV.Min;
     izLetoPriporoceneZVmin.Font.Style := [fsBold];
     izLetoPriporoceneZVmax.Font.Style := [];

     CelotnoOsvezevanjeIzracunov;

  end
  else if izbor = 2 then begin   // OBDRŽIJO SE VNESENI PODATKI

    fmOsnovniKontroler.Cursor := crHourGlass;

    // Polnjenje vrednosti za izračun MDJ
    if vpOrganizacija_OdgovorDA3.Checked = true then begin  //Če je možno nabaviti rabljene stroje
       if izPloscicaKorekcijskiFaktorji_KF4_3.Caption = 'NE' then begin  // Če je bilo pred osveževanjem polje neaktivno (torej ni bilo na organizaciji izbrana opcija, polje postavi na aktivno (velja enako za vse naprej)
          izPloscicaKorekcijskiFaktorji_KF4_3.Caption := 'DA';
          vpPloscicaKorekcijskiFaktorji_KFDrsnik4_4.Position := 0;
          vpPloscicaKorekcijskiFaktorji_KFIzpis4_4.Caption := '0,00';
          vpPloscicaKorekcijskiFaktorji_KFDrsnik4_4.Enabled := true;
          vpPloscicaKorekcijskiFaktorji_KFIzpis4_4.Enabled := true;
       end;
    end
    else begin
        izPloscicaKorekcijskiFaktorji_KF4_3.Caption := 'NE';
        vpPloscicaKorekcijskiFaktorji_KFDrsnik4_4.Position := 0;
        vpPloscicaKorekcijskiFaktorji_KFIzpis4_4.Caption := '0,00';
        vpPloscicaKorekcijskiFaktorji_KFDrsnik4_4.Enabled := false;
        vpPloscicaKorekcijskiFaktorji_KFIzpis4_4.Enabled := false
    end;
    if vpOrganizacija_OdgovorDA6.Checked = true then begin  //Če so potrebni posebni delovni pogoji
       if izPloscicaKorekcijskiFaktorji_KF3_3.Caption = 'NE' then begin
          izPloscicaKorekcijskiFaktorji_KF3_3.Caption := 'DA';
          vpPloscicaKorekcijskiFaktorji_KFDrsnik3_4.Position := 4;
          vpPloscicaKorekcijskiFaktorji_KFIzpis3_4.Caption := '1,00';
          vpPloscicaKorekcijskiFaktorji_KFDrsnik3_4.Enabled := true;
          vpPloscicaKorekcijskiFaktorji_KFIzpis3_4.Enabled := true;
       end;
    end
    else begin
        izPloscicaKorekcijskiFaktorji_KF3_3.Caption := 'NE';
        vpPloscicaKorekcijskiFaktorji_KFDrsnik3_4.Position := 0;
        vpPloscicaKorekcijskiFaktorji_KFIzpis3_4.Caption := '0,00';
        vpPloscicaKorekcijskiFaktorji_KFDrsnik3_4.Enabled := false;
        vpPloscicaKorekcijskiFaktorji_KFIzpis3_4.Enabled := false
    end;
    if vpOrganizacija_OdgovorDA7.Checked = true then begin  //Če se v proizvodnji uporabljajo nevarne snovi
       if izPloscicaKorekcijskiFaktorji_KF1_3.Caption = 'NE' then begin
         izPloscicaKorekcijskiFaktorji_KF1_3.Caption := 'DA';
         vpPloscicaKorekcijskiFaktorji_KFDrsnik1_4.Position := 4;
         vpPloscicaKorekcijskiFaktorji_KFIzpis1_4.Caption := '1,00';
         vpPloscicaKorekcijskiFaktorji_KFDrsnik1_4.Enabled := true;
         vpPloscicaKorekcijskiFaktorji_KFIzpis1_4.Enabled := true;
       end;
    end
    else begin
        izPloscicaKorekcijskiFaktorji_KF1_3.Caption := 'NE';
        vpPloscicaKorekcijskiFaktorji_KFDrsnik1_4.Position := 0;
        vpPloscicaKorekcijskiFaktorji_KFIzpis1_4.Caption := '0,00';
        vpPloscicaKorekcijskiFaktorji_KFDrsnik1_4.Enabled := false;
        vpPloscicaKorekcijskiFaktorji_KFIzpis1_4.Enabled := false;
    end;
    if vpOrganizacija_OdgovorDA8.Checked = true then begin  //Če se vgrajene nevarne snovi
       if izPloscicaKorekcijskiFaktorji_KF2_3.Caption = 'NE' then begin
         izPloscicaKorekcijskiFaktorji_KF2_3.Caption := 'DA';
         vpPloscicaKorekcijskiFaktorji_KFDrsnik2_4.Position := 2;
         vpPloscicaKorekcijskiFaktorji_KFIzpis2_4.Caption := '0,50';
         vpPloscicaKorekcijskiFaktorji_KFDrsnik2_4.Enabled := true;
         vpPloscicaKorekcijskiFaktorji_KFIzpis2_4.Enabled := true;
       end;
    end
    else begin
        izPloscicaKorekcijskiFaktorji_KF2_3.Caption := 'NE';
        vpPloscicaKorekcijskiFaktorji_KFDrsnik2_4.Position := 0;
        vpPloscicaKorekcijskiFaktorji_KFIzpis2_4.Caption := '0,00';
        vpPloscicaKorekcijskiFaktorji_KFDrsnik2_4.Enabled := false;
        vpPloscicaKorekcijskiFaktorji_KFIzpis2_4.Enabled := false;
    end;
    if (vpOrganizacija_Odgovor9.Position < 3) or (vpOrganizacija_Odgovor10.Position < 75) then begin  //Če se vgrajene nevarne snovi
       if izPloscicaKorekcijskiFaktorji_KF5_3.Caption = 'NE' then begin
         izPloscicaKorekcijskiFaktorji_KF5_3.Caption := 'DA';
         vpPloscicaKorekcijskiFaktorji_KFDrsnik5_4.Position := 4;
         vpPloscicaKorekcijskiFaktorji_KFIzpis5_4.Caption := '1,00';
         vpPloscicaKorekcijskiFaktorji_KFDrsnik5_4.Enabled := true;
         vpPloscicaKorekcijskiFaktorji_KFIzpis5_4.Enabled := true;
       end;
    end
    else begin
       izPloscicaKorekcijskiFaktorji_KF5_3.Caption := 'NE';
       vpPloscicaKorekcijskiFaktorji_KFDrsnik5_4.Position := 0;
       vpPloscicaKorekcijskiFaktorji_KFIzpis5_4.Caption := '0,00';
       vpPloscicaKorekcijskiFaktorji_KFDrsnik5_4.Enabled := false;
       vpPloscicaKorekcijskiFaktorji_KFIzpis5_4.Enabled := false;
    end;
    // Polnjenje pdoatkov na zavihku za izračun priporočene zavarovalne vsote

    izTekstPriporoceniKD.Caption :=  LjezikDL7_izTekstPriporoceniKD + ' ' + vpOpazovanoLeto.Text + ':';
    izTekstPriporocenaSKD.Caption := LjezikDL7_izTekstPriporocenaSKD + ' ' + vpOpazovanoLeto.Text + ':';
    izTekstLetoPriporoceneZV.Caption :=  LjezikDL7_izTekstLetoPriporoceneZV + ':';

    izPriporoceniKD.Caption := vpVnosFRPKosmatiDobicek.Caption;
    izPriporocenaSKD.Caption := vpVnosFRPStopnjaKosmategaDobicka.Caption;
    izLetoPriporoceneZVmin.Caption := IntToStr(StrToInt(vpOpazovanoLeto.Text) + 1);
    izLetoPriporoceneZVmax.Caption := IntToStr(StrToInt(vpOpazovanoLeto.text) + 2);
    vpLetoPriporoceneZV.Min := StrToInt(vpOpazovanoLeto.Text) + 1;
    vpLetoPriporoceneZV.Max := StrToInt(vpOpazovanoLeto.Text) + 2;

    CelotnoOsvezevanjeIzracunov;
  end;
end;

procedure TfmOsnovniKontroler.mnShraniProjektClick(Sender: TObject);
begin
  if (vpDavcnaStevilkaPodjetja.Text ='') Or (vpImePodjetja.Text = '') Or (vpOpazovanoLeto.Text = '')  or (StrToFloat(vpVnosFRPPromet.Text) <= 0) then
     ShowMessage(LjezikObvestilo_gumbShraniProjekt)
  else begin
    WebClientShranjevanje.Connection := PovezavaServerShranjevanje;
    fmOsnovniKontroler.Cursor := crHourGlass;
    if PovezavaServerShranjevanje.Connected = true then
       PovezavaServerShranjevanje.Connected := false;
    try
      PovezavaServerShranjevanje.Connected := true;  // Zažene povezavo s serjeverjem. Ko je ta vzpostavljena se zažene procedura OnConnect gradnika PovezavaServer
    except
      showMessage(LjezikObvestilo_ServerNiPovezave_02);
    end;
  end;
end;

procedure TfmOsnovniKontroler.mnNastavitveClick(Sender: TObject);
var novoOkno : TfmNastavitveUporabnika;

   procedure ObOdprtjuShowModal(AForm: TObject);  // Procedura, ki se zažene ob zagonu okna / forme fmNastavitveUporabnika
   begin

     with novoOkno do begin
       ploscicaGlava.caption := LjezikUporabnik_Naslov;
       izUporabnikTekst.Caption := LjezikUporabnik_Uporabnik;
       izPodjetjeTekst.Caption := LjezikUporabnik_Podjetje;
       izLicencaTekst.Caption := LjezikUporabnik_Licenca;
       izSpremeniGeslo.Caption := LjezikUporabnik_SpremembaGeslaNaslov;
       izStaroGeslo.Caption := LjezikUporabnik_StaroGeslo;
       izNovoGeslo1Tekst.Caption := LjezikUporabnik_NovoGeslo1;
       izNovoGeslo2Tekst.Caption := LjezikUporabnik_NovoGeslo2;
       gSpremeniGeslo.Caption := LjezikUporabnik_gSpremeni;
     end;
     novoOkno.izUporabnik.Caption := izUporabnik.Caption;
     novoOkno.izPodjetje.Caption := izPodjetje.Caption;
     novoOkno.izVeljavnostLicence.Date := izPotekLicence.Date;
     novoOkno.vpStaroGeslo.Text := '';
     novoOkno.vpNovoGeslo1.Text := '';
     novoOkno.vpNovoGeslo2.Text := '';
     novoOkno.izSpremembaGeslaOpozorilo.Caption := '';
   end;

begin
     novoOkno := TfmNastavitveUporabnika.CreateNew(@ObOdprtjuShowModal);
     novoOkno.Popup := true;
     novoOkno.Show;
end;

procedure TfmOsnovniKontroler.NastavitveClick(Sender: TObject);
var novoOkno : TfmNastavitveUporabnika;

   procedure ObOdprtjuShowModal(AForm: TObject);  // Procedura, ki se zažene ob zagonu okna / forme fmNastavitveUporabnika
   begin

     with novoOkno do begin
       ploscicaGlava.caption := LjezikUporabnik_Naslov;
       izUporabnikTekst.Caption := LjezikUporabnik_Uporabnik;
       izPodjetjeTekst.Caption := LjezikUporabnik_Podjetje;
       izLicencaTekst.Caption := LjezikUporabnik_Licenca;
       izSpremeniGeslo.Caption := LjezikUporabnik_SpremembaGeslaNaslov;
       izStaroGeslo.Caption := LjezikUporabnik_StaroGeslo;
       izNovoGeslo1Tekst.Caption := LjezikUporabnik_NovoGeslo1;
       izNovoGeslo2Tekst.Caption := LjezikUporabnik_NovoGeslo2;
       gSpremeniGeslo.Caption := LjezikUporabnik_gSpremeni;
     end;
     novoOkno.izUporabnik.Caption := izUporabnik.Caption;
     novoOkno.izPodjetje.Caption := izPodjetje.Caption;
     novoOkno.izVeljavnostLicence.Date := izPotekLicence.Date;
     novoOkno.vpStaroGeslo.Text := '';
     novoOkno.vpNovoGeslo1.Text := '';
     novoOkno.vpNovoGeslo2.Text := '';
     novoOkno.izSpremembaGeslaOpozorilo.Caption := '';
   end;

begin
     novoOkno := TfmNastavitveUporabnika.CreateNew(@ObOdprtjuShowModal);
     novoOkno.Popup := true;
     novoOkno.Show;
end;

procedure TfmOsnovniKontroler.ploscicaPrihranki_PrihranekExit(
  Sender: TObject);
var s : String;
    sestevek : Real;
    i : Integer;
    vnosnoPolje : TEdit;
begin
  sestevek := 0;
  for i:= 1 to 10 do begin
    vnosnoPolje := FindComponent('ploscicaPrihranki_Prihranek_2_'+IntToStr(i)) as TEdit;
    if vnosnoPolje.Text <> '' then begin
      s := StringReplace(vnosnoPolje.Text, '.', '', [rfReplaceAll, rfIgnoreCase]);
      vnosnoPolje.Text := FormatFloat('###,###,##0.00', StrToFloat(s));
      sestevek := sestevek + StrToFloat(s);
    end
    else begin
     sestevek := sestevek;
    end;
  end;
  ploscicaPrihranki_PrihranekSkupajZnesek.Text := FormatFloat('###,###,##0.00', sestevek);
end;

procedure TfmOsnovniKontroler.ploscicaPromet_Promet_OznacevanjeIzpadaDblClick(
  Sender: TObject);
var stolpec, vrstica, stolpecTekoc, vrsticatekoca : Integer;
    i : Integer;
    dan, mesec : Word;
    mesecSkode, letoSkode : Integer;
    polje, izbranoPolje : TEdit;
begin
  izbranoPolje := (Sender as TEdit);  // izbranemu polju se dodeli posebna spremenljivka, saj se drugače v nadaljevanju vrednosti spremenijo, ker polje spremeni barvo
  // ugotovi, katero polje v tabeli je izbrano in prepiše vrednosti koordinat (stolpec / vrstica)
  stolpec := StrToInt(copy((Sender as TEdit).Name, 23, 1));
  if Length((Sender as TEdit).Name) = 25 then
    vrstica := StrToInt(copy((Sender as TEdit).Name, 25, 1))
  else if Length((Sender as TEdit).Name) = 26 then
    vrstica := StrToInt(copy((Sender as TEdit).Name, 25, 2));
   // preveri dan in mesec začetka zavarovanja (tj. zavarovalnega leta), kar je potrebno, da se da ugotoviti, kateri mesec je izbran iz spustnega seznama
  dan := DayOf(vpZavarovanjeOd.Date);
  mesec := MonthOf(vpZavarovanjeOd.Date);
  // Če dan začetka zavarovanja ni 1. dan v mesecu, se lahko škoda simulira šele naslednji mesec (1.)
  if dan > 1 then
    mesec := mesec + 1;
  // določi, kateri mesec je iz spustnega seznama izbran za začetek škode
  mesecSkode := vpDatumFizicneSkode.ItemIndex + mesec;
  if mesecSkode > 12 then begin
     mesecSkode := mesecSkode - 12;
     letoSkode := 3;
  end
  else begin
    mesecSkode := mesecSkode;
    letoSkode := 2;
  end;
  if not (((vrstica < mesecSkode) and (stolpec = letoSkode)) or ((vrstica >= mesecSkode) and (stolpec > letoSkode))) then begin
    // obarva (označi) polja oziroma jih odznači
    for i := mesecSkode to (mesecSkode+11) do begin  // začne se pregled v mesecu fizične škode in traja 12 mesecev, kar je najdaljša možna MDJ
       if i > 12 then begin
          vrsticaTekoca := i - 12;
          stolpecTekoc := letoSkode + 1;
       end
       else begin
          vrsticaTekoca := i;
          stolpecTekoc := letoSkode;
       end;
       polje := FindComponent('ploscicaPromet_Promet_'+IntToStr(stolpecTekoc)+'_'+IntToStr(vrsticaTekoca)) as TEdit;  // definira posamezno celico znotraj 12-mesečnega obdobja od začetka škode (to ni kliknjena celica)
       if izbranoPolje.Color = clWebCornSilk then begin   // če je kliknjena celica že sedaj rdeča (izbrana)
          if (vrsticaTekoca < vrstica) and (stolpecTekoc = stolpec) then       // vse celice pred kliknjeno celico ostajajo rdeče (ker so bile rdeče že prej, saj ne more biti drugače)
             polje.Color := clWebCornSilk
          else if (vrsticaTekoca = vrstica) and (stolpecTekoc = stolpec) then    // sprememba kliknjene celice
             polje.Color := clWhite
          else if (vrsticaTekoca > vrstica) and (stolpecTekoc = stolpec) then    // pokliknjeni celici so vse celice bele - neoznačene
             polje.Color := clWhite
          else if (stolpecTekoc > stolpec) then    // vse celice po kliknjeni celici (sicer v stolpcu za kliknjeno celico) so bele, saj za kliknjeno celico ne morejo biti drugačne
             polje.Color := clWhite
          else if (stolpecTekoc < stolpec) then   // vse celice pred kliknjeno celico (sicer v solpcu pred kliknjeno celico) so rdeče, saj ne more biti drugače
             polje.Color := clWebCornSilk;
       end
       else if izbranoPolje.Color = clWhite then begin    // če kliknjena celica ni izbrana (je bela)
          if (vrsticaTekoca < vrstica) and (stolpecTekoc = stolpec) then
             polje.Color := clWebCornSilk
          else if (vrsticaTekoca = vrstica) and (stolpecTekoc = stolpec) then
             polje.Color := clWebCornSilk
          else if (vrsticaTekoca > vrstica) and (stolpecTekoc = stolpec) then
             polje.Color := clWhite
          else if (stolpecTekoc > stolpec) then
             polje.Color := clWhite
          else if (stolpecTekoc < stolpec) then
             polje.Color := clWebCornSilk;
       end;
    end;
  end;
end;


procedure TfmOsnovniKontroler.ploscicaWebLoginClick(Sender: TObject);
var i, j : Integer;
    zapis : TLabel;
    vnosnoPolje : TEdit;
    drsnik : TTrackBar;
    izbor : TRadioButton;
// const imeMeseca : array[1..12] of String = ('januar', 'februar', 'marec', 'april', 'maj', 'junij', 'julij', 'avgust', 'september', 'oktober', 'november', 'december');
begin

  // Postavljanje menijev na začetne vrednosti ter pogleda na 1. delovni list
  izborVhodniPodatki.ItemIndex := 0;
  izborAnalize.ItemIndex := -1;
  izborAnalize.Visible := false;
  izIzborAnalize.Visible := false;
  izborSimulacijeSkod.ItemIndex := -1;
  izborSimulacijeSkod.Visible := false;
  izIzborSimulacijeSkod.Visible := false;

  // Določitev osnovnih parametrov na delovnem listu "Osnovni podatki"
  vpImePodjetja.Text := '';
  vpNaslovPodjetja.text := '';
  vpDavcnaStevilkaPodjetja.Text := '';
  vpDejavnostPodjetja.ItemIndex := -1;

  // Določitev osnovnih parametrov na delovnem listu "Finančno računovodski podatki"
  vpVnosFRPPromet.Text := '0,00';
  vpVnosFRPZalogeZacetek.Text := '0,00';
  vpVnosFRPZalogeKonec.Text := '0,00';
  vpVnosFRPInternoProizvedeniDodatki.Text := '0,00';
  vpVnosFRPStroskiSurovine.Text := '0,00';
  vpVnosFRPStroskiMaterial.Text := '0,00';
  vpVnosFRPStroskiEnergija.Text := '0,00';
  vpVnosFRPStroskiTrgovskoBlago.Text := '0,00';
  vpVnosFRPStroskiStoritve.Text := '0,00';
  vpVnosFRPDrugiStroski.Text := '0,00';
  vpVnosFRPPrejeteZavarovalnine.Text := '0,00';
  vpVnosFRPKosmatiDobicek.Caption := '0,00';
  vpVnosFRPStopnjaKosmategaDobicka.Caption := '0,00';
  // Opazovano leto je skupaj z njim povezanimi polji obdelan posebej

  // Dolocitev osnovnih parametrov na delovnem listu "Podatki o prodaji"
  for i:= 1 to 12 do begin
    vnosnoPolje := FindComponent('vpTabelaPromet_ProdajaXminus1_'+IntToStr(i)) as TEdit;
    vnosnoPolje.Text := '0,00';
    vnosnoPolje := FindComponent('vpTabelaPromet_ProdajaX_'+IntToStr(i)) as TEdit;
    vnosnoPolje.Text := '0,00';
  end;
  vpTabelaPromet_LetoXminus1Skupaj.Caption := '0,00';
  vpTabelaPromet_LetoXSkupaj.Caption := '0,00';
  for i := 1 to 4 do begin
    drsnik := FindComponent('vpPlanProdaje_RastXplus'+IntToStr(i)) as TTrackBar;
    drsnik.Position:=0;
    zapis := FindComponent('izPlanProdaje_RastXplus'+IntToStr(i)) as TLabel;
    zapis.Caption := '0,00 %';
  end;
  // Podatki vezani na leta obravnave so obdelani skupaj (zaradi celovitosti s poljem OpazovanoLeto

  // Določitev osnovnih parametrov na delovnem listu "Organizacija dela"
  for i := 1 to 2 do begin
    drsnik := FindComponent('vpOrganizacija_Odgovor'+IntToStr(i)) as TTrackBar;
    drsnik.Position:=2;
    zapis := FindComponent('izOrganizacija_mera'+IntToStr(i)) as TLabel;
    zapis.Caption := '1 mes';
  end;
  for i := 3 to 8 do begin
    izbor := FindComponent('vpOrganizacija_OdgovorDa'+IntToStr(i)) as TRadioButton;
    izbor.Checked := false;
    izbor := FindComponent('vpOrganizacija_OdgovorNe'+IntToStr(i)) as TRadioButton;
    izbor.Checked := false;
  end;
  vpOrganizacija_Odgovor9.Position := 1;
  izOrganizacija_mera9.Caption := '1 ' + LjezikMera_Izmena;
  vpOrganizacija_Odgovor10.Position := 100;
  izOrganizacija_mera10.Caption := '100 %';
  vpOrganizacija_OdgovorDa11_1.Checked := false;
  vpOrganizacija_OdgovorNe11_1.Checked := false;
  vpOrganizacija_Odgovor11_2.Position := 100;
  izOrganizacija_mera11_2.Caption := '100 %';
  vpOrganizacija_Odgovor11_3.Position := 4;
  izOrganizacija_mera11_3.Caption := '2 ' + LjezikMera_Mesec;
  vpOrganizacija_OdgovorDa11_4.Checked := false;
  vpOrganizacija_OdgovorNe11_4.Checked := false;
  vpOrganizacija_Odgovor11_5.Position := 100;
  izOrganizacija_mera11_5.Caption := '100 %';
  vpOrganizacija_Odgovor11_6.Position := 4;
  izOrganizacija_mera11_6.Caption := '2 ' + LjezikMera_Mesec;
  vpOrganizacija_Odgovor12.Position := 2;
  izOrganizacija_mera12.Caption := '1 ' + LjezikMera_Mesec;
  vpOrganizacija_Odgovor13.ItemIndex := -1;

  // Določi osnovne parametre na delovnem listu "Podatki o obstoječem zavarovanju"
  vpZavarovanjeDa.Checked := false;
  vpZavarovanjeDAKosmatiDobicek.Checked := false;
  vpZVKosmatiDobicek.Text := '0,00';
  vpZVKosmatiDobicek.Enabled := false;
  vpZavarovanjeDANastetiStroski.Checked := false;
  for i := 2 to 6 do begin
     vnosnoPolje := FindComponent('vpZavarovaniStroski2_'+IntToStr(i)) as TEdit;
     vnosnoPolje.Text := '0,00';
     vnosnoPolje.Enabled := false;
  end;
  ploscicaPodatkiOZavarovanju.Visible := false;
  vpMDJ.ItemIndex := 9 ;
  vpZacetekZavarovanja.Date := Now;
  vpPremija.Text := '0,00';

  //Delovni list 6 (Potrebna maksimalna doba jamčenja)
  vpParameter1_1.Position := 6;
  izParameter1_1.Caption := '1,50';
  vpParameter1_2.Position := 2;
  izParameter1_2.Caption := '0,50';
  vpParameter2_1.Position := 10;
  izParameter2_1.Caption := '2,50';
  vpParameter2_2.Position := 2;
  izParameter2_2.Caption := '0,50';
  vpParameter3_1.Position := 16;
  izParameter3_1.Caption := '4,00';
  vpParameter3_2.Position := 0;
  izParameter3_2.Caption := '0,00';
  vpParameter4_1.Position := 10;
  izParameter4_1.Caption := '2,50';
  vpParameter4_2.Position := 0;
  izParameter4_2.Caption := '0,00';
  vpParameter5_1.Position := 8;
  izParameter5_1.Caption := '2,00';
  vpParameter5_2.Position := 0;
  izParameter5_2.Caption := '0,00';
  vpParameter6_1.Position := 8;
  izParameter6_1.Caption := '2,00';
  vpParameter7_1.Position := 4;
  izParameter7_1.Caption := '1,00';

  izPloscicaKorekcijskiFaktorji_KF1_3.Caption := 'NE';
  vpPloscicaKorekcijskiFaktorji_KFDrsnik1_4.Position := 0;
  vpPloscicaKorekcijskiFaktorji_KFDrsnik1_4.Enabled := false;
  vpPloscicaKorekcijskiFaktorji_KFIzpis1_4.Caption := '0,00';
  izPloscicaKorekcijskiFaktorji_KF2_3.Caption := 'NE';
  vpPloscicaKorekcijskiFaktorji_KFDrsnik2_4.Position := 0;
  vpPloscicaKorekcijskiFaktorji_KFDrsnik2_4.Enabled := false;
  vpPloscicaKorekcijskiFaktorji_KFIzpis2_4.Caption := '0,00';
  izPloscicaKorekcijskiFaktorji_KF3_3.Caption := 'NE';
  vpPloscicaKorekcijskiFaktorji_KFDrsnik3_4.Position := 0;
  vpPloscicaKorekcijskiFaktorji_KFDrsnik3_4.Enabled := false;
  vpPloscicaKorekcijskiFaktorji_KFIzpis3_4.Caption := '0,00';
  izPloscicaKorekcijskiFaktorji_KF4_3.Caption := 'NE';
  vpPloscicaKorekcijskiFaktorji_KFDrsnik4_4.Position := 0;
  vpPloscicaKorekcijskiFaktorji_KFDrsnik4_4.Enabled := false;
  vpPloscicaKorekcijskiFaktorji_KFIzpis4_4.Caption := '0,00';
  izPloscicaKorekcijskiFaktorji_KF5_3.Caption := 'NE';
  vpPloscicaKorekcijskiFaktorji_KFDrsnik5_4.Position := 0;
  vpPloscicaKorekcijskiFaktorji_KFDrsnik5_4.Enabled := false;
  vpPloscicaKorekcijskiFaktorji_KFIzpis5_4.Caption := '0,00';
  vpKorekcijskiFaktorji.Checked := false;
  vpMaksimalniPribitek.Position := 10;
  izMaksimalniPribitek.Caption := '10 %';
  ploscicaMaksimalniPribitek.Visible := false;
  ploscicaKorekcijskiFaktorji.Visible := false;

  // Delovni list 7 (Potrebna zavarovalna vsota)

  izTekstPriporoceniKD.Caption :=  LjezikDL7_izTekstPriporoceniKD + ' ' + vpOpazovanoLeto.Text + ':';
  izTekstPriporocenaSKD.Caption := LjezikDL7_izTekstPriporocenaSKD + ' ' + vpOpazovanoLeto.Text + ':';
  izTekstLetoPriporoceneZV.Caption :=  LjezikDL7_izTekstLetoPriporoceneZV + ':';

  izLetoPriporoceneZVmin.Caption := IntToStr(StrToInt(vpOpazovanoLeto.Text) + 1);
  izLetoPriporoceneZVmax.Caption := IntToStr(StrToInt(vpOpazovanoLeto.Text) + 2);
  vpLetoPriporoceneZV.Min := StrToInt(vpOpazovanoLeto.Text) + 1;
  vpLetoPriporoceneZV.Max := StrToInt(vpOpazovanoLeto.Text) + 2;
  vpLetoPriporoceneZV.Position := vpLetoPriporoceneZV.Min;
  izLetoPriporoceneZVmin.Font.Style := [fsBold];
  izLetoPriporoceneZVmax.Font.Style := [];

  // Delovni list 8 (Prodajni trendi)
  ploscicaAnalizeProdaje_izPromet_NaslovXminus1.Caption := LjezikLeto + ' '+ IntToStr(StrToInt(vpOpazovanoLeto.Text) - 1);
  ploscicaAnalizeProdaje_izPromet_NaslovX.Caption := LjezikLeto + ' '+ vpOpazovanoLeto.Text;
  for i := 1 to 4 do begin
    zapis := FindComponent('ploscicaAnalizeProdaje_izPromet_NaslovXplus'+IntToStr(i)) as TLabel;
    zapis.Caption := LjezikLeto + ' ' + IntToStr(StrToInt(vpOpazovanoLeto.Text) + i);
  end;
  for i := 1 to 6 do begin
    for j := 1 to 12 do begin
      zapis := FindComponent('ploscicaAnalizeProdaje_izPromet_'+IntToStr(i)+'_'+IntToStr(j)) as TLabel;
      zapis.Caption := '0,00';
    end;
  end;

  ploscicaGlava.Show;
  ploscicaGlavniMeni.Show;
  ploscicaGlavniMeni.ElementHandle.style.setProperty('overflow', 'visible');
  ploscicaIzborPogledov.Show;
  ploscicaDelovniListi.Margins.Top := 15;
  izborVhodniPodatki.ItemIndex := 0;
  ploscicaDelovniListi.TabIndex := 1;
end;

procedure TfmOsnovniKontroler.PovezavaServerGesloConnect(Sender: TObject);

 procedure OnResult( Response: TXDataClientResponse );
  var
    LArray : TJSArray;
    LItem : TJSObject;
    // spremenljivke v katere se prepišejo vrednosti/rezultati iz poizvedbe / web servisa
    LUporabnik : String;
    LGeslo : String;
    LPodjetje : String;
    LPotekLicence : TDateTime;

  begin

    try
      LArray := TJSArray( TJSObject( Response.Result )['value']);
      LItem := TJSObject( LArray[0]);

      if LItem <> null then begin
          LUporabnik := string(LItem['Uporabnik']);
          LPodjetje := string(LItem['Podjetje']);
          LGeslo := string(LItem['Geslo']);
          LPotekLicence := StrToDateTime(String(LItem['PotekLicence']));

          if LUporabnik = vpUporabniskoIme.Text then begin

             if LGeslo = vpGeslo.Text then begin

                 if LPotekLicence < Now then begin
                   izUporabniskoImeNapaka.Visible:=false;
                   izGesloNapaka.Visible:=false;
                   vpUporabniskoIme.Text := '';
                   vpGeslo.Text := '';
                   vpUporabniskoIme.SetFocus;
                   showMessage(LjezikObvestilo_Licenca_01 + sLineBreak + sLineBreak + LjezikObvestilo_Licenca_02 + sLineBreak + sLineBreak + LjezikObvestilo_Licenca_03);
                 end
                 else begin
                   izUporabnik.Caption := LUporabnik;
                   izPodjetje.Caption := LPodjetje;
                   izPotekLicence.Date := LPotekLicence;
                   izGesloNapaka.Visible := false;
                   izUporabniskoImeNapaka.Visible := false;

                   vpOpazovanoLeto.Text := IntToStr(YearOf(Now));
                   ZagonPrograma;   // Zažene se procedura, ki postavi začetne vrednosti aplikacije...

                 end;
             end
             else begin
                izUporabniskoImeNapaka.Visible:=false;
                izGesloNapaka.Visible:=true;
                vpUporabniskoIme.Text := '';
                vpGeslo.Text := '';
                vpUporabniskoIme.SetFocus;
             end;
          end
          else begin
              izUporabniskoImeNapaka.Visible:=true;
              izGesloNapaka.Visible:=false;
              vpUporabniskoIme.Text := '';
              vpGeslo.Text := '';
              vpUporabniskoIme.SetFocus;
          end;
      end
      else begin
          izUporabniskoImeNapaka.Visible:=true;
          vpUporabniskoIme.Text := '';
          vpGeslo.Text := '';
          vpUporabniskoIme.SetFocus;
      end;
    finally
       PovezavaServerGeslo.Connected := false;
       fmOsnovniKontroler.Cursor := crDefault;
    end;
  end;


begin
  try
    WebClientGeslo.RawInvoke('IUporabnikiService.GetUporabnik', [vpUporabniskoIme.Text], @OnResult );
  except
    ShowMessage(LjezikObvestilo_ServerPoizvedba);
  end;

end;

procedure TfmOsnovniKontroler.PovezavaServerGesloError(
  Error: TXDataWebConnectionError);
begin
  showMessage(LjezikObvestilo_ServerNiPovezave_01);
end;


procedure TfmOsnovniKontroler.PovezavaServerJezikConnect(Sender: TObject);

 procedure OnResult( Response: TXDataClientResponse );
  var
    LArray : TJSArray;
    LItem : TJSObject;

  begin

    try
      LArray := TJSArray( TJSObject( Response.Result )['value']);
      LItem := TJSObject( LArray[0]);

      if Litem <> null then begin

         izNaslov2_1_DL0A.Caption := string(LItem['DL0A_napisPodnaslov1']);
         izNaslov2_2_DL0A.Caption := string(LItem['DL0A_napisPodnaslov2']);
         izNaslov2_3_DL0A.Caption := string(LItem['DL0A_napisPodnaslov3']);
         izPrijavaNaslov.Caption := string(LItem['DL0A_napisNaslovPrijava']);
         vpUporabniskoIme_Tekst.Caption := string(LItem['DL0A_napisUporabniskoIme']);
         vpGeslo_Tekst.Caption := string(LItem['DL0A_napisGeslo']);
         izUporabniskoImeNapaka.Caption := string(LItem['DL0A_napakaUporabniskoIme']);
         izGesloNapaka.Caption := string(LItem['DL0A_napakaGeslo']);
         gPrijava.Caption := string(LItem['DL0A_gumbPrijava']);
         izBrskalniki.Caption := string(LItem['DL0A_izBrskalniki']);
         izSpletnaStran.Caption := string(LItem['DL0A_izSpletnaStran']);

         izNaslov2_1_DL0B.Caption := string(LItem['DL0A_napisPodnaslov1']);
         izNaslov2_2_DL0B.Caption := string(LItem['DL0A_napisPodnaslov2']);
         izNaslov2_3_DL0B.Caption := string(LItem['DL0A_napisPodnaslov3']);
         gZacniNovprojekt.Caption := string(LItem['DL0B_gumbNovProjekt']);
         gNaloziprojekt.Caption := string(LItem['DL0B_gumbNaloziProjekt']);
         gPrekiniDelo.Caption := string(LItem['DL0B_gumbPrekiniDelo']);

         LfmLetoPodatkov_izLetoPodatkov_Naslov := string(LItem['NovProjekt_napisNaslov']);
         LfmLetoPodatkov_gPotrdi := string(LItem['NovProjekt_gumbPotrdi']);
         LfmLetoPodatkov_gPreklici := string(LItem['NovProjekt_gumbPreklici']);

         LfmOdpriKalkulacijo_ploscicaNaslov := string(LItem['NaloziProjekt_napisNaslov']);
         LfmOdpriKalkulacijo_gIzberi := string(LItem['NaloziProjekt_gumbIzberi']);
         LfmOdpriKalkulacijo_gIzbrisi := string(LItem['NaloziProjekt_gumbIzbrisi']);
         LfmOdpriKalkulacijo_gPreklici := string(LItem['NaloziProjekt_gumbPreklici']);

         mnNovProjekt.Caption := string(LItem['Gumb_NovProjekt']);
         mnOdpriProjekt.Caption := string(LItem['Gumb_OdpriProjekt']);
         mnShraniProjekt.Caption := string(LItem['Gumb_ShraniProjekt']);
         mnOsveziAnalize.Caption := string(LItem['Gumb_OsveziAnalize']);
         mnZakljuciDelo.Caption := string(LItem['Gumb_Zakljuci']);
         mnOstalo.Caption := string(LItem['Gumb_Ostalo']);

         mnNastavitve.Caption := string(LItem['Gumb_Nastavitve']);
         mnOOrodju.Caption := string(LItem['Gumb_OOrodju']);
         mnPomoc.Caption := string(LItem['Gumb_Pomoc']);

         mnOsveziPrivzeteVrednosti.Caption := string(LItem['Podmenu1_izborPrivzeteVrednosti']);
         mnOsveziDejanskevrednosti.Caption := string(LItem['Podmenu1_izborDejanskeVrednosti']);

         izIzborVhodniPodatki.Caption := string(LItem['Menu1_napisNaslov']);
         izborVhodniPodatki.Items[0] := String(LItem['Menu1_izborOPodjetju']);
         izborVhodniPodatki.Items[1] := String(LItem['Menu1_izborFinancniPodatki']);
         izborVhodniPodatki.Items[2] := String(LItem['Menu1_izborProdaja']);
         izborVhodniPodatki.Items[3] := String(LItem['Menu1_izborOrganizacija']);
         izborVhodniPodatki.Items[4] := String(LItem['Menu1_izborZavarovanje']);

         izIzborAnalize.Caption := string(LItem['Menu2_napisNaslov']);
         izborAnalize.Items[0] := String(LItem['Menu2_izborMDJ']);
         izborAnalize.Items[1] := String(LItem['Menu2_izborZV']);
         izborAnalize.Items[2] := String(LItem['Menu2_izborTrendi']);
         izborAnalize.Items[3] := String(LItem['Menu2_izborZavarovanje']);

         izIzborSimulacijeSkod.Caption := string(LItem['Menu3_napisNaslov']);
         izborSimulacijeSkod.Items[0] := String(LItem['Menu3_izborObstojeceZavarovanje']);
         izborSimulacijeSkod.Items[1] := String(LItem['Menu3_izborSimuliranoZavarovanje']);

         izUporabnikTekst.Caption := string(LItem['licenca_napisUporabnik']);
         izPodjetjeTekst.Caption := string(LItem['licenca_napisPodjetje']);
         izPotekLicenceTekst.Caption := string(LItem['licenca_napisPotekLicence']);

         LfmVizitka_izNaslov := string(LItem['OOrodju_napisNaslov']);
         LfmVizitka_izFirma := string(LItem['OOrodju_napisFirma']);
         LfmVizitka_izElektronskaPosta := string(LItem['OOrodju_napisEmail']);
         LfmVizitka_izSpletnaStran := string(LItem['OOrodju_napisWeb']);
         LfmVizitka_izSubvencija := string(LItem['OOrodju_napisSofinanciranje']);

         izNaslovDL1.Caption := string(LItem['DL1_Naslov']);
         izImePodjetja.Caption := string(LItem['DL1_ImePodjetja']);
         izNaslovPodjetja.Caption := string(LItem['DL1_NaslovPodjetja']);
         izDavcnaStevilkaPodjetja.Caption := string(LItem['DL1_DavcnaStevilkaPodjetja']);
         izDejavnostPodjetja.Caption := string(LItem['DL1_DejavnostPodjetja']);

         izNaslovDL2.Caption := string(LItem['DL2_Naslov']);
         izVnosFRPLeto.Caption := string(LItem['DL2_Leto']);
         izVnosFRPpromet.Caption := string(LItem['DL2_Promet']);
         izVnosFRPZalogeKonec.Caption := string(LItem['DL2_ZalogeKonec']);
         izVnosFRPZalogeZacetek.Caption := string(LItem['DL2_ZalogeZacetek']);
         izVnosFRPInternoProizvedeniDodatki.Caption := string(LItem['DL2_InternoProizvedeniDodatki']);
         izVnosFRPStroskiSurovine.Caption := string(LItem['DL2_StroskiSurovin']);
         izVnosFRPStroskimaterial.Caption := string(LItem['DL2_StroskiMateriala']);
         izVnosFRPStroskiEnergija.Caption := string(LItem['DL2_StroskiEnergije']);
         izVnosFRPStroskiTrgovskoBlago.Caption := string(LItem['DL2_StroskiTrgovskegaBlaga']);
         izVnosFRPStroskiStoritve.Caption := string(LItem['DL2_StroskiStoritev']);
         izVnosFRPDrugiStroski.Caption := string(LItem['DL2_DrugiStroski']);
         izVnosFRPPrejeteZavarovalnine.Caption := string(LItem['DL2_PrejeteZavarovalnine']);
         izVnosFRPKosmatiDobicek.Caption := string(LItem['DL2_KosmatiDobicek']);
         izVnosFRPStopnjaKosmategaDobicka.Caption := string(LItem['DL2_StopnjaKosmategaDobicka']);

         izNaslovDL3.Caption := string(LItem['DL3_Naslov']);
         izPlanProdaje_Naslov.Caption := string(LItem['DL3_NaslovPlaniranaRast']);
         tabelaPromet_Mesec.Caption := string(LItem['Mesec']);
         tabelaPromet_Mesec1.Caption := string(LItem['Mesec_01']);
         tabelaPromet_Mesec2.Caption := string(LItem['Mesec_02']);
         tabelaPromet_Mesec3.Caption := string(LItem['Mesec_03']);
         tabelaPromet_Mesec4.Caption := string(LItem['Mesec_04']);
         tabelaPromet_Mesec5.Caption := string(LItem['Mesec_05']);
         tabelaPromet_Mesec6.Caption := string(LItem['Mesec_06']);
         tabelaPromet_Mesec7.Caption := string(LItem['Mesec_07']);
         tabelaPromet_Mesec8.Caption := string(LItem['Mesec_08']);
         tabelaPromet_Mesec9.Caption := string(LItem['Mesec_09']);
         tabelaPromet_Mesec10.Caption := string(LItem['Mesec_10']);
         tabelaPromet_Mesec11.Caption := string(LItem['Mesec_11']);
         tabelaPromet_Mesec12.Caption := string(LItem['Mesec_12']);
         tabelaPromet_Skupaj.Caption := string(LItem['DL3_Skupaj']);

         izNaslovDL4.Caption := string(LItem['DL4_Naslov']);
         izOrganizacija_Vprasanje1.Lines[0] := string(LItem['DL4_OrganizacijaVprasanje01_1']);
         izOrganizacija_Vprasanje1.Lines[1] := string(LItem['DL4_OrganizacijaVprasanje01_2']);
         izOrganizacija_Vprasanje2.Lines[0] := string(LItem['DL4_OrganizacijaVprasanje02_1']);
         izOrganizacija_Vprasanje2.Lines[1] := string(LItem['DL4_OrganizacijaVprasanje02_2']);
         izOrganizacija_Vprasanje3.Lines[0] := string(LItem['DL4_OrganizacijaVprasanje03_1']);
         izOrganizacija_Vprasanje3.Lines[1] := string(LItem['DL4_OrganizacijaVprasanje03_2']);
         izOrganizacija_Vprasanje4.Lines[0] := string(LItem['DL4_OrganizacijaVprasanje04_1']);
         izOrganizacija_Vprasanje4.Lines[1] := string(LItem['DL4_OrganizacijaVprasanje04_2']);
         izOrganizacija_Vprasanje5.Lines[0] := string(LItem['DL4_OrganizacijaVprasanje05_1']);
         izOrganizacija_Vprasanje5.Lines[1] := string(LItem['DL4_OrganizacijaVprasanje05_2']);
         izOrganizacija_Vprasanje6.Lines[0] := string(LItem['DL4_OrganizacijaVprasanje06_1']);
         izOrganizacija_Vprasanje6.Lines[1] := string(LItem['DL4_OrganizacijaVprasanje06_2']);
         izOrganizacija_Vprasanje7.Lines[0] := string(LItem['DL4_OrganizacijaVprasanje07_1']);
         izOrganizacija_Vprasanje7.Lines[1] := string(LItem['DL4_OrganizacijaVprasanje07_2']);
         izOrganizacija_Vprasanje8.Lines[0] := string(LItem['DL4_OrganizacijaVprasanje08_1']);
         izOrganizacija_Vprasanje8.Lines[1] := string(LItem['DL4_OrganizacijaVprasanje08_2']);
         izOrganizacija_Vprasanje9.Lines[0] := string(LItem['DL4_OrganizacijaVprasanje09_1']);
         izOrganizacija_Vprasanje9.Lines[1] := string(LItem['DL4_OrganizacijaVprasanje09_2']);
         izOrganizacija_Vprasanje10.Lines[0] := string(LItem['DL4_OrganizacijaVprasanje10_1']);
         izOrganizacija_Vprasanje10.Lines[1] := string(LItem['DL4_OrganizacijaVprasanje10_2']);
         izOrganizacija_Vprasanje11.Lines[0] := string(LItem['DL4_OrganizacijaVprasanje11_1']);
         izOrganizacija_Vprasanje11.Lines[1] := string(LItem['DL4_OrganizacijaVprasanje11_2']);
         izOrganizacija_Vprasanje11_1.Lines[0] := string(LItem['DL4_OrganizacijaVprasanje11_pv1']);
         izOrganizacija_Vprasanje11_2.Lines[0] := string(LItem['DL4_OrganizacijaVprasanje11_pv2']);
         izOrganizacija_Vprasanje11_3.Lines[0] := string(LItem['DL4_OrganizacijaVprasanje11_pv3']);
         izOrganizacija_Vprasanje11_4.Lines[0] := string(LItem['DL4_OrganizacijaVprasanje11_pv4']);
         izOrganizacija_Vprasanje11_5.Lines[0] := string(LItem['DL4_OrganizacijaVprasanje11_pv5']);
         izOrganizacija_Vprasanje11_6.Lines[0] := string(LItem['DL4_OrganizacijaVprasanje11_pv6']);
         izOrganizacija_Vprasanje12.Lines[0] := string(LItem['DL4_OrganizacijaVprasanje12_1']);
         izOrganizacija_Vprasanje12.Lines[1] := string(LItem['DL4_OrganizacijaVprasanje12_2']);
         izOrganizacija_Vprasanje13.Lines[0] := string(LItem['DL4_OrganizacijaVprasanje13_1']);
         izOrganizacija_Vprasanje13.Lines[1] := string(LItem['DL4_OrganizacijaVprasanje13_2']);
         izOrganizacija_mera1.Caption := string(LItem['DL4_OrganizacijaMera_Mesec']);
         izOrganizacija_mera2.Caption := string(LItem['DL4_OrganizacijaMera_Mesec']);
         izOrganizacija_mera11_3.Caption := string(LItem['DL4_OrganizacijaMera_Mesec']);
         izOrganizacija_mera11_6.Caption := string(LItem['DL4_OrganizacijaMera_Mesec']);
         izOrganizacija_mera12.Caption := string(LItem['DL4_OrganizacijaMera_Mesec']);
         izOrganizacija_mera9.Caption := string(LItem['DL4_OrganizacijaMeraIzmena']);
         vpOrganizacija_OdgovorDa3.Caption := string(LItem['Da']);
         vpOrganizacija_OdgovorNe3.Caption := string(LItem['Ne']);
         vpOrganizacija_OdgovorDa4.Caption := string(LItem['Da']);
         vpOrganizacija_OdgovorNe4.Caption := string(LItem['Ne']);
         vpOrganizacija_OdgovorDa5.Caption := string(LItem['Da']);
         vpOrganizacija_OdgovorNe5.Caption := string(LItem['Ne']);
         vpOrganizacija_OdgovorDa6.Caption := string(LItem['Da']);
         vpOrganizacija_OdgovorNe6.Caption := string(LItem['Ne']);
         vpOrganizacija_OdgovorDa7.Caption := string(LItem['Da']);
         vpOrganizacija_OdgovorNe7.Caption := string(LItem['Ne']);
         vpOrganizacija_OdgovorDa8.Caption := string(LItem['Da']);
         vpOrganizacija_OdgovorNe8.Caption := string(LItem['Ne']);
         vpOrganizacija_OdgovorDa11_1.Caption := string(LItem['Da']);
         vpOrganizacija_OdgovorNe11_1.Caption := string(LItem['Ne']);
         vpOrganizacija_OdgovorDa11_4.Caption := string(LItem['Da']);
         vpOrganizacija_OdgovorNe11_4.Caption := string(LItem['Ne']);
         vpOrganizacija_Odgovor13.Items[0] := String(LItem['DL4_OrganizacijaOdgovor_13_1']);
         vpOrganizacija_Odgovor13.Items[1] := String(LItem['DL4_OrganizacijaOdgovor_13_2']);
         vpOrganizacija_Odgovor13.Items[2] := String(LItem['DL4_OrganizacijaOdgovor_13_3']);

         izNaslovDL5.Caption := string(LItem['DL5_Naslov']);
         vpZavarovanjeDa.Caption := string(LItem['DL5_ZavarovanjeDa']);
         vpZavarovanjeDAKosmatiDobicek.Caption := string(LItem['DL5_ZavarovanjeKD']);
         izTekstZVKosmatiDobicek .Caption := string(LItem['DL5_ZavarovanjeKD_ZV']);
         vpZavarovanjeDANastetiStroski .Caption := string(LItem['DL5_ZavarovanjeNS']);
         izTekstStroski1.Caption := string(LItem['DL5_ZavarovanjeNS_stroski']);
         izZavarovaniStroski1_1.Caption := string(LItem['DL5_Zavarovanje_TabelaStroski']);
         izZavarovaniStroski2_1.Caption := string(LItem['DL5_Zavarovanje_TabelaVisina']);
         izZavarovaniStroski1_2.Caption := string(LItem['DL5_Zavarovanje_StrStoritev']);
         izZavarovaniStroski1_3.Caption := string(LItem['DL5_Zavarovanje_StrAmortizacije']);
         izZavarovaniStroski1_4.Caption := string(LItem['DL5_Zavarovanje_StrDela']);
         izZavarovaniStroski1_5.Caption := string(LItem['DL5_Zavarovanje_StrDrugi']);
         izZavarovaniStroski1_6.Caption := string(LItem['DL5_Zavarovanje_Dobicek']);
         izTekstStroski2.Caption := string(LItem['DL5_ZavarovanjeNS_ZV']);
         izMDJ.Caption := string(LItem['MDJ_SteviloMesecev']);
         izTekstMDJ.Caption := string(LItem['DL5_ZavarovanjeMDJ']);
         izTekstZacetekZavarovanja.Caption := string(LItem['DL5_ZavarovanjeZacetek']);
         izTekstPremija.Caption := string(LItem['DL5_ZavarovanjePremija']);

         izNaslovDL6.Caption := string(LItem['DL6_Naslov']);
         izTekstPriporocenaMDJ.Caption := string(LItem['DL6_napisRezultat']);
         izNastavljanjeOsnovnihParametrov.Caption := string(LItem['DL6_TabelaNaslov']);
         izParametriMDJ_Naslov_2.Caption := string(LItem['DL6_napisTrajanje']);
         izParametriMDJ_Naslov_3.Caption := string(LItem['DL6_napisZacetek']);
         izParametriMDJ_Parameter1.Caption := string(LItem['DL6_Ciscenje']);
         izParametriMDJ_Parameter2.Caption := string(LItem['DL6_Dokumentacija']);
         izParametriMDJ_Parameter3.Caption := string(LItem['DL6_Gradnja']);
         izParametriMDJ_Parameter4.Caption := string(LItem['DL6_DobavaStrojev']);
         izParametriMDJ_Parameter5.Caption := string(LItem['DL6_MontazaStrojev']);
         izParametriMDJ_Parameter6.Caption := string(LItem['DL6_PolnaProdaja']);
         izParametriMDJ_Parameter7.Caption := string(LItem['DL6_VarnostniDodatek']);
         vpKorekcijskiFaktorji.Caption := string(LItem['DL6_KorekcijaDa']);
         izPloscicaKorekcijskiFaktorji_Naslov4.Caption := string(LItem['DL6_napisKorekcija']);
         izPloscicaKorekcijskiFaktorji_KF1_1.Caption := string(LItem['DL6_NevarneSnovi']);
         izPloscicaKorekcijskiFaktorji_KF2_1.Caption := string(LItem['DL6_VgrajeneNevarneSnovi']);
         izPloscicaKorekcijskiFaktorji_KF3_1.Caption := string(LItem['DL6_PosebniPogoji']);
         izPloscicaKorekcijskiFaktorji_KF4_1.Caption := string(LItem['DL6_RabljeniStroji']);
         izPloscicaKorekcijskiFaktorji_KF5_1.Caption := string(LItem['DL6_Izmene']);

         izNaslovDL7.Caption := string(LItem['DL7_Naslov']);
         LjezikDL7_izTekstPriporoceniKD := string(LItem['DL7_napisRezultatKD']);
         LjezikDL7_izTekstPriporocenaSKD := string(LItem['DL7_napisRezultatSKD']);
         LjezikDL7_izTekstLetoPriporoceneZV := string(LItem['DL7_napisLeto']);
         izPriporoceneZV_Naslov1.Caption := string(LItem['DL7_napisMDJ']);
         izPriporoceneZV_Naslov2.Caption := string(LItem['DL7_napisZV']);
         izPriporoceneZV_tekstZV12.Caption := string(LItem['DL7_MDJ12']);
         izPriporoceneZV_tekstZV18.Caption := string(LItem['DL7_MDJ18']);
         izPriporoceneZV_tekstZV24.Caption := string(LItem['DL7_MDJ24']);
         izGraficniPrikazMDJ.Caption := string(LItem['DL7_GraficniPrikazMDJ']);
         izGrafikaMDJ_NE.Caption := string(LItem['Ne']);
         izGrafikaMDJ_DA.Caption := string(LItem['Da']);
         LjezikGrafMDJ_Skoda := string(LItem['DL7_Graf_Skoda']);
         LjezikGrafMDJ_Ciscenje := string(LItem['DL7_Graf_Ciscenje']);
         LjezikGrafMDJ_Dokumentacija := string(LItem['DL7_Graf_Dokumentacija']);
         LjezikGrafMDJ_Gradnja := string(LItem['DL7_Graf_Gradnja']);
         LjezikGrafMDJ_DobavaStrojev := string(LItem['DL7_Graf_DobavaStrojev']);
         LjezikGrafMDJ_MontazaStrojev := string(LItem['DL7_Graf_MontazaStrojev']);
         LjezikGrafMDJ_PolneZmogljivosti := string(LItem['DL7_Graf_PolneZmogljivosti']);
         LjezikGrafMDJ_VarnostniDodatek := string(LItem['DL7_Graf_VarnostniDodatek']);

         izNaslovDL8.Caption := string(LItem['DL8_Naslov']);
         ploscicaAnalizeProdaje_izMesec_Naslov.Caption := string(LItem['Mesec']) + ' / ' + string(LItem['Leto']);;
         ploscicaAnalizeProdaje_izMesec_1.Caption := string(LItem['Mesec_01']);
         ploscicaAnalizeProdaje_izMesec_2.Caption := string(LItem['Mesec_02']);
         ploscicaAnalizeProdaje_izMesec_3.Caption := string(LItem['Mesec_03']);
         ploscicaAnalizeProdaje_izMesec_4.Caption := string(LItem['Mesec_04']);
         ploscicaAnalizeProdaje_izMesec_5.Caption := string(LItem['Mesec_05']);
         ploscicaAnalizeProdaje_izMesec_6.Caption := string(LItem['Mesec_06']);
         ploscicaAnalizeProdaje_izMesec_7.Caption := string(LItem['Mesec_07']);
         ploscicaAnalizeProdaje_izMesec_8.Caption := string(LItem['Mesec_08']);
         ploscicaAnalizeProdaje_izMesec_9.Caption := string(LItem['Mesec_09']);
         ploscicaAnalizeProdaje_izMesec_10.Caption := string(LItem['Mesec_10']);
         ploscicaAnalizeProdaje_izMesec_11.Caption := string(LItem['Mesec_11']);
         ploscicaAnalizeProdaje_izMesec_12.Caption := string(LItem['Mesec_12']);
         LPT1_Tabela.Caption := string(LItem['DL8_Tabela']);
         LPT2_MesecniGraf.Caption := string(LItem['DL8_MesecniPrikaz']);
         LPT3_LetniGraf.Caption := string(LItem['DL8_LetniPrikaz']);

         izNaslovDL9.Caption := string(LItem['DL9_Naslov']);
         izPloscicaObstojeceZavarovanje_Vprasanje1.Caption := string(LItem['DL9_napisZavarovanje']);
         izPloscicaObstojeceZavarovanje_Vprasanje2.Caption := string(LItem['DL9_napisNacinZavarovanja']);
         izPloscicaObstojeceZavarovanje_Vprasanje3.Caption := string(LItem['DL9_napisMDJ']);
         izPloscicaObstojeceZavarovanje_Vprasanje4.Caption := string(LItem['DL9_napisZV']);
         LjezikNacinZavarovanja_KD := string(LItem['DL9_NacinZavarovanja_KD']);
         LjezikNacinZavarovanja_NS := string(LItem['DL9_NacinZavarovanja_NS']);
         LjezikNacinZavarovanja_NP := string(LItem['DL9_NacinZavarovanja_NP']);

         LjezikNaslovDL10 := string(LItem['DL10_Naslov']);
         LjezikNaslovDL10simulirano := string(LItem['DL10_NaslovSimulirano']);
         izPodatkiOZavarovanju_Naslov.Caption := string(LItem['DL10_Podatki']);
         izPodatkioZavarovanju_Od.Caption := string(LItem['DL10_ZavarovanjeOd']);
         izPodatkioZavarovanju_Do.Caption := string(LItem['DL10_ZavarovanjeDo']);
         izPodatkioZavarovanju_ZavarovalnaVsota.Caption := string(LItem['DL10_ZavarovalnaVsota']);
         izPodatkioZavarovanju_MaksimalnaDobaJamcenja.Caption := string(LItem['DL10_MDJ']);
         izMaksimalnaDobaJamcenjaTekst.Caption := string(LItem['MDJ_SteviloMesecev']);
         izPodatkioZavarovanju_Premija.Caption := string(LItem['DL10_Premija']);
         izDatumFizicneSkode.Caption := string(LItem['DL10_DatumSkode']);
         WebTabSheet1.Caption := string(LItem['DL10_TabelaPromet']);
         WebTabSheet2.Caption := string(LItem['DL10_TabelaPovisaniStroski']);
         WebTabSheet3.Caption := string(LItem['DL10_TabelaPrihranki']);
         izPovisaniPoslovniStroskiOpis.Caption := string(LItem['DL10_napisPovisaniStroski']);
         izResenPrometOpis.Caption := string(LItem['DL10_napisResenPromet']);
         izPovisaniPoslovniStroskiObdobjeOpis.Caption := string(LItem['DL10_napisObdobjeStroskov']);
         ploscicaPrihranki_Naslov1.Caption := string(LItem['DL10_napisPrihranki']);
         ploscicaPrihranki_Naslov2.Caption := string(LItem['DL10_napisZnesekPrihrankov']);
         ploscicaPrihranki_PrihranekSkupajOpis.Text := string(LItem['DL10_napisSkupaj']);
         izPrihrankiObdobjeOpis.Caption := string(LItem['DL10_napisObdobjePrihrankov']);
         gPreracunaj.Caption := string(LItem['DL10_gumbPreracunaj']);
         gVnosPopolnegaZastoja.Caption := string(LItem['DL10_gumbPopolniZastoj']);
         gVnesiKrivuljoZastoja.Caption := string(LItem['DL10_gumbKrivulje']);
         gAvtomaticnoPolnjenje.Caption := string(LItem['DL10_gumbAvtomaticnoPolnjenje']);
         ploscicaPromet_Naslov1.Caption := string(LItem['Mesec']) + ' / ' + string(LItem['Leto']);;
         ploscicaPromet_Mesec1.Caption := string(LItem['Mesec_01']);
         ploscicaPromet_Mesec2.Caption := string(LItem['Mesec_02']);
         ploscicaPromet_Mesec3.Caption := string(LItem['Mesec_03']);
         ploscicaPromet_Mesec4.Caption := string(LItem['Mesec_04']);
         ploscicaPromet_Mesec5.Caption := string(LItem['Mesec_05']);
         ploscicaPromet_Mesec6.Caption := string(LItem['Mesec_06']);
         ploscicaPromet_Mesec7.Caption := string(LItem['Mesec_07']);
         ploscicaPromet_Mesec8.Caption := string(LItem['Mesec_08']);
         ploscicaPromet_Mesec9.Caption := string(LItem['Mesec_09']);
         ploscicaPromet_Mesec10.Caption := string(LItem['Mesec_10']);
         ploscicaPromet_Mesec11.Caption := string(LItem['Mesec_11']);
         ploscicaPromet_Mesec12.Caption := string(LItem['Mesec_12']);
         vpPrihrankiObdobje.Items[0] := String(LItem['DL10_izborObdobjeZastoj']);
         vpPrihrankiObdobje.Items[1] := String(LItem['DL10_izborObdobjeMDJ']);
         vpPovisaniPoslovniStroskiObdobje.Items[0] := String(LItem['DL10_izborObdobjeZastoj']);
         vpPovisaniPoslovniStroskiObdobje.Items[1] := String(LItem['DL10_izborObdobjeMDJ']);
         mn3.Caption := string(LItem['DL10_podmeniBI3']);
         mn4.Caption := string(LItem['DL10_podmeniBI4']);
         mn5.Caption := string(LItem['DL10_podmeniBI5']);
         mn6.Caption := string(LItem['DL10_podmeniBI6']);
         mn7.Caption := string(LItem['DL10_podmeniBI7']);
         mn8.Caption := string(LItem['DL10_podmeniBI8']);
         mn9.Caption := string(LItem['DL10_podmeniBI9']);
         mn10.Caption := string(LItem['DL10_podmeniBI10']);
         mn11.Caption := string(LItem['DL10_podmeniBI11']);
         mn12.Caption := string(LItem['DL10_podmeniBI12']);
         mnKrivulja1.Caption := string(LItem['DL10_podmeniBIkrivulja1']);
         mnKrivulja2.Caption := string(LItem['DL10_podmeniBIkrivulja2']);
         mnKrivulja3.Caption := string(LItem['DL10_podmeniBIkrivulja3']);
         izGraficniPrikazSimulacijaSkode.Caption := string(LItem['DL7_GraficniPrikazMDJ']);
         izGrafikaSkode_NE.Caption := string(LItem['Ne']);
         izGrafikaSkode_DA.Caption := string(LItem['Da']);
         LjezikGrafObvestilo := string(LItem['DL10_GrafObvestilo']);
         gNaloziSkodo.Caption := string(LItem['DL10_gNaloziSkodo']);
         gShraniSkodo.Caption := string(LItem['DL10_gShraniSkodo']);

         LjezikSkoda_Naslov := string(LItem['Skoda_Naslov']);
         LjezikSkoda_gumbIzhod := string(LItem['Skoda_gumbIzhod']);
         LjezikSkoda_napisKoraki := string(LItem['Skoda_napisKoraki']);
         LjezikSkoda_gumbSimulacije := string(LItem['Skoda_gumbSimulacije']);

         LjezikSkodaK1_Naslov := string(LItem['SkodaK1_Naslov']);
         LjezikSkodaK1_TabelaRealizacijaX := string(LItem['SkodaK1_TabelaRealizacijaX']);
         LjezikSkodaK1_TabelaPlan := string(LItem['SkodaK1_TabelaPlan']);
         LjezikSkodaK1_TabelaRealizacijaXplus1 := string(LItem['SkodaK1_TabelaRealizacijaXplus1']);
         LjezikSkodaK1_TabelaRazlika := string(LItem['SkodaK1_TabelaRazlika']);
         LjezikSkodaK1_TabelaKritje := string(LItem['SkodaK1_TabelaKritje']);
         LjezikSkodaK1_IzpadPrometa := string(LItem['SkodaK1_IzpadPrometa']);
         LjezikSkodaK1_ZavarovaniIzpad := string(LItem['SkodaK1_ZavarovaniIzpad']);
         LjezikSkodaK1_NepokritiStroski1 := string(LItem['SkodaK1_NepokritiStroski1']);
         LjezikSkodaK1_NepokritiStroski2 := string(LItem['SkodaK1_NepokritiStroski2']);

         LjezikSkodaK2_Naslov := string(LItem['SkodaK2_Naslov']);
         LjezikSkodaK2_PovisaniStroski := string(LItem['SkodaK2_PovisaniStroski']);
         LjezikSkodaK2_ResenPromet := string(LItem['SkodaK2_ResenPromet']);
         LjezikSkodaK2_UpraviceniStroski := string(LItem['SkodaK2_UpraviceniStroski']);

         LjezikSkodaK3_Naslov := string(LItem['SkodaK3_Naslov']);
         LjezikSkodaK3_TabelaVrstaPrihranka := string(LItem['SkodaK3_TabelaVrstaPrihranka']);
         LjezikSkodaK3_TabelaVisinaPrihranka := string(LItem['SkodaK3_TabelaVisinaPrihranka']);
         LjezikSkodaK3_TabelaUpravicenost := string(LItem['SkodaK3_TabelaUpravicenost']);
         LjezikSkodaK3_TabelaOdstotek := string(LItem['SkodaK3_TabelaOdstotek']);
         LjezikSkodaK3_PrijavljeniPrihranki := string(LItem['SkodaK3_PrijavljeniPrihranki']);
         LjezikSkodaK3_DejanskiPrihranki := string(LItem['SkodaK3_DejanskiPrihranki']);

         LjezikSkodaK4_Naslov := string(LItem['SkodaK4_Naslov']);
         LjezikSkodaK4_TabelaMeseci := string(LItem['SkodaK4_TabelaMeseci']);
         LjezikSkodaK4_TabelaPrometPoMesecih := string(LItem['SkodaK4_TabelaPrometPoMesecih']);
         LjezikSkodaK4_TabelaPrometSkupaj := string(LItem['SkodaK4_TabelaPrometSkupaj']);
         LjezikSkodaK4_OsnovaZaLetniPromet := string(LItem['SkodaK4_OsnovaZaLetniPromet']);
         LjezikSkodaK4_Rast := string(LItem['SkodaK4_Rast']);
         LjezikSkodaK4_LetniPromet := string(LItem['SkodaK4_LetniPromet']);
         LjezikSkodaK4_PotrebnaZV := string(LItem['SkodaK4_PotrebnaZV']);
         LjezikSkodaK4_DejanskaZV := string(LItem['SkodaK4_DejanskaZV']);
         LjezikSkodaK4_Podzavarovanje := string(LItem['SkodaK4_Podzavarovanje']);
         LjezikSkodaK4_PodzavarovanjeIzpis := string(LItem['SkodaK4_PodzavarovanjeIzpis']);

         LjezikSkodaK5_Naslov := string(LItem['SkodaK5_Naslov']);
         LjezikSkodaK5_Izpad := string(LItem['SkodaK5_Izpad']);
         LjezikSkodaK5_PovisaniStroski := string(LItem['SkodaK5_PovisaniStroski']);
         LjezikSkodaK5_Prihranki := string(LItem['SkodaK5_Prihranki']);
         LjezikSkodaK5_Podzavarovanje := string(LItem['SkodaK5_Podzavarovanje']);
         LjezikSkodaK5_Zavarovalnina := string(LItem['SkodaK5_Zavarovalnina']);
         LjezikSkodaK5_DejanskaSkoda := string(LItem['SkodaK5_DejanskaSkoda']);

         LjezikSimulacija_Naslov := string(LItem['Simulacija_Naslov']);
         LjezikSimulacija_ZV := string(LItem['Simulacija_ZV']);
         LjezikSimulacija_MDJ := string(LItem['Simulacija_MDJ']);
         LjezikSimulacija_ObdobjeResenegaPrometa := string(LItem['Simulacija_ObdobjeResenegaPrometa']);
         LjezikSimulacija_ObdobjePrihrankov := string(LItem['Simulacija_ObdobjePrihrankov']);
         LjezikSimulacija_PricakovanaRast := string(LItem['Simulacija_PricakovanaRast']);
         LjezikSimulacija_gSpremeni := string(LItem['Simulacija_gSpremeni']);
         LjezikSimulacija_gPonastavi := string(LItem['Simulacija_gPonastavi']);
         LjezikSimulacija_gPreklici := string(LItem['Simulacija_gPreklici']);

         LjezikSimulacija_nogaMDJ := string(LItem['Simulacija_nogaMDJ']);
         LjezikSimulacija_nogaZV := string(LItem['Simulacija_nogaZV']);
         LjezikSimulacija_nogaPrihranki := string(LItem['Simulacija_nogaPrihranki']);
         LjezikSimulacija_nogaPovisaniStroski := string(LItem['Simulacija_nogaPovisaniStroski']);
         LjezikSimulacija_nogaRast := string(LItem['Simulacija_nogaRast']);
         LjezikSimulacija_nogaPremija := string(LItem['Simulacija_nogaPremija']);
         LjezikSimulacija_nogaD := string(LItem['Simulacija_nogaD']);
         LjezikSimulacija_nogaS := string(LItem['Simulacija_nogaS']);

         LjezikUporabnik_Naslov := string(LItem['Uporabnik_Naslov']);
         LjezikUporabnik_Uporabnik := string(LItem['Uporabnik_Uporabnik']);
         LjezikUporabnik_Podjetje := string(LItem['Uporabnik_Podjetje']);
         LjezikUporabnik_Licenca := string(LItem['Uporabnik_Licenca']);
         LjezikUporabnik_SpremembaGeslaNaslov := string(LItem['Uporabnik_SpremembaGeslaNaslov']);
         LjezikUporabnik_StaroGeslo := string(LItem['Uporabnik_StaroGeslo']);
         LjezikUporabnik_NovoGeslo1 := string(LItem['Uporabnik_NovoGeslo1']);
         LjezikUporabnik_NovoGeslo2 := string(LItem['Uporabnik_NovoGeslo2']);
         LjezikUporabnik_gSpremeni := string(LItem['Uporabnik_gSpremeni']);
         LjezikUporabnik_Opozorilo1 := string(LItem['Uporabnik_Opozorilo1']);
         LjezikUporabnik_Opozorilo2 := string(LItem['Uporabnik_Opozorilo2']);
         LjezikUporabnik_Opozorilo3 := string(LItem['Uporabnik_Opozorilo3']);
         LjezikUporabnik_Opozorilo4 := string(LItem['Uporabnik_Opozorilo4']);

         LjezikLeto := string(LItem['Leto']);
         LjezikMesec := string(LItem['Mesec']);
         LjezikImeMeseca[1] := string(LItem['Mesec_01']);
         LjezikImeMeseca[2] := string(LItem['Mesec_02']);
         LjezikImeMeseca[3] := string(LItem['Mesec_03']);
         LjezikImeMeseca[4] := string(LItem['Mesec_04']);
         LjezikImeMeseca[5] := string(LItem['Mesec_05']);
         LjezikImeMeseca[6] := string(LItem['Mesec_06']);
         LjezikImeMeseca[7] := string(LItem['Mesec_07']);
         LjezikImeMeseca[8] := string(LItem['Mesec_08']);
         LjezikImeMeseca[9] := string(LItem['Mesec_09']);
         LjezikImeMeseca[10] := string(LItem['Mesec_10']);
         LjezikImeMeseca[11] := string(LItem['Mesec_11']);
         LjezikImeMeseca[12] := string(LItem['Mesec_12']);

         LjezikMera_Mesec := string(LItem['DL4_OrganizacijaMera_Mesec']);
         LjezikMera_Izmena := string(LItem['DL4_OrganizacijaMeraIzmena']);
         LjezikSteviloMesecev := string(LItem['MDJ_SteviloMesecev']);

         LjezikDa := string(LItem['Da']);
         LjezikNe := string(LItem['Ne']);

        izPloscicaObstojeceZavarovanje_Semafor1.Hint := string(LItem['Hint_Semafor1']);
        izPloscicaObstojeceZavarovanje_Semafor1.ShowHint := true;
        izPloscicaObstojeceZavarovanje_Semafor2.Hint := string(LItem['Hint_Semafor2']);
        izPloscicaObstojeceZavarovanje_Semafor2.ShowHint := true;
        izPloscicaObstojeceZavarovanje_Semafor3.Hint := string(LItem['Hint_Semafor3']);
        izPloscicaObstojeceZavarovanje_Semafor3.ShowHint := true;
        izPloscicaObstojeceZavarovanje_Semafor3b.Hint := string(LItem['Hint_Semafor3b']);
        izPloscicaObstojeceZavarovanje_Semafor3b.ShowHint := true;
        izPloscicaObstojeceZavarovanje_Semafor4.Hint := string(LItem['Hint_Semafor4']);
        izPloscicaObstojeceZavarovanje_Semafor4.ShowHint := true;

        LjezikObvestilo_ServerShranjevanje_01 := string(LItem['Obvestilo_ServerShranjevanje_01']);
        LjezikObvestilo_ServerShranjevanje_02 := string(LItem['Obvestilo_ServerShranjevanje_02']);
        LjezikObvestilo_ServerShranjevanje_03 := string(LItem['Obvestilo_ServerShranjevanje_03']);
        LjezikObvestilo_ServerShranjevanje_04 := string(LItem['Obvestilo_ServerShranjevanje_04']);
        LjezikObvestilo_ServerShranjevanje_05 := string(LItem['Obvestilo_ServerShranjevanje_05']);
        LjezikObvestilo_ServerShranjevanje_06 := string(LItem['Obvestilo_ServerShranjevanje_06']);
        LjezikObvestilo_ServerShranjevanje_07 := string(LItem['Obvestilo_ServerShranjevanje_07']);
        LjezikObvestilo_ServerShranjevanje_08 := string(LItem['Obvestilo_ServerShranjevanje_08']);
        LjezikObvestilo_ServerShranjevanje_09 := string(LItem['Obvestilo_ServerShranjevanje_09']);

        LjezikObvestilo_ServerSkode_01 := String(LItem['Obvestilo_ServerSkode_01']);
        LjezikObvestilo_ServerSkode_02 := String(LItem['Obvestilo_ServerSkode_02']);
        LjezikObvestilo_ServerSkode_03 := String(LItem['Obvestilo_ServerSkode_03']);
        LjezikObvestilo_ServerSkode_04 := String(LItem['Obvestilo_ServerSkode_04']);
        LjezikObvestilo_ServerSkode_05 := String(LItem['Obvestilo_ServerSkode_05']);
        LjezikObvestilo_ServerSkode_06 := String(LItem['Obvestilo_ServerSkode_06']);
        LjezikObvestilo_ServerSkode_07 := String(LItem['Obvestilo_ServerSkode_07']);
        LjezikObvestilo_ServerSkode_08 := String(LItem['Obvestilo_ServerSkode_08']);
        LjezikObvestilo_ServerSkode_09 := String(LItem['Obvestilo_ServerSkode_09']);

        LjezikObvestilo_ServerNiPovezave_01 := string(LItem['Obvestilo_ServerNiPovezave_01']);
        LjezikObvestilo_ServerNiPovezave_02 := string(LItem['Obvestilo_ServerNiPovezave_02']);

        LjezikObvestilo_gumbSkode := string(LItem['Obvestilo_gumbSkode']);
        LjezikObvestilo_gumbSkode2 := string(LItem['Obvestilo_gumbSkode2']);
        LjezikObvestilo_ReCaptcha_01 := string(LItem['Obvestilo_SReCaptcha_01']);
        LjezikObvestilo_ReCaptcha_02 := string(LItem['Obvestilo_ReCaptcha_02']);
        LjezikObvestilo_gumbShraniProjekt := string(LItem['Obvestilo_gumbShraniProjekt']);
        LjezikObvestilo_IzborSimulacijaSkode := string(LItem['Obvestilo_IzborSimulacijaSkode']);
        LjezikObvestilo_Licenca_01 := string(LItem['Obvestilo_Licenca_01']);
        LjezikObvestilo_Licenca_02 := string(LItem['Obvestilo_Licenca_02']);
        LjezikObvestilo_Licenca_03 := string(LItem['Obvestilo_Licenca_03']);
        LjezikObvestilo_ServerPoizvedba := string(LItem['Obvestilo_ServerPoizvedba']);
        LjezikObvestilo_WebServisi := string(LItem['Obvestilo_WebServisi']);
        LjezikObvestilo_NalaganjePodatkov := string(LItem['Obvestilo_NalaganjePodatkov']);
        LjezikObvestilo_WebServis_Skoda := string(LItem['Obvestilo_WebServis_Skoda']);
        LjezikObvestilo_WebServis_Premija := string(LItem['Obvestilo_WebServis_Premija']);
        LjezikObvestilo_ServerBrisanje_01 := string(LItem['Obvestilo_ServerBrisanje_01']);
        LjezikObvestilo_ServerBrisanje_02 := string(LItem['Obvestilo_ServerBrisanje_02']);

        LjezikObvestilo_mnOsveziAnalize_01 := string(LItem['Obvestilo_mnOsveziAnalize_01']);
        LjezikObvestilo_mnOsveziAnalize_02 := string(LItem['Obvestilo_mnOsveziAnalize_02']);
        LjezikObvestilo_mnOsveziAnalize_03 := string(LItem['Obvestilo_mnOsveziAnalize_03']);

        obvestilo.Message := string(LItem['Opozorilo_01']);
        obvestilo.DialogText[7] := string(LItem['Da']);
        obvestilo.DialogText[8] := string(LItem['Ne']);
        obvestilo.DialogText[0] := string(LItem['Opozorilo_Tip']);
        LjezikObvestiloBrisanje := string(LItem['Opozorilo_02']);
        LjezikObvestilo_Tip := string(LItem['Opozorilo_Tip']);

        Ljezik_Hint_Semafor_IzpadPrometa := string(LItem['Hint_Semafor_IzpadPrometa']);
        Ljezik_Hint_Semafor_ZV := string(LItem['Hint_Semafor_ZV']);
        Ljezik_Hint_Semafor_Zavarovalnina1 := string(LItem['Hint_Semafor_Zavarovalnina1']);
        Ljezik_Hint_Semafor_Zavarovalnina2 := string(LItem['Hint_Semafor_Zavarovalnina2']);
        Ljezik_Hint_Semafor_Zavarovalnina3 := string(LItem['Hint_Semafor_Zavarovalnina3']);

      end;
    finally
       PovezavaServerjezik.Connected := false;
       fmOsnovniKontroler.Cursor := crDefault;
    end;
  end;


  begin
  try
    WebClientjezik.RawInvoke('IJezikService.GetJezik', [jezik], @OnResult );
  except
    ShowMessage(LjezikObvestilo_ServerPoizvedba);
  end;
end;

procedure TfmOsnovniKontroler.PovezavaServerJezikError(
  Error: TXDataWebConnectionError);
begin
  showMessage(LjezikObvestilo_ServerNiPovezave_01);
end;

procedure TfmOsnovniKontroler.PovezavaServerPodatkiConnect(Sender: TObject);

 procedure OnResult( Response: TXDataClientResponse );
  var
    LArray : TJSArray;
    LItem : TJSObject;

    i : Integer;

    izpisVrednosti : TLabel;
    prikazVrednosti: TTrackBar;
    poljeVrednosti : TEdit;


  begin
    try
     LArray := TJSArray( TJSObject( Response.Result )['value']);

       if LArray <> nil then begin
          LItem := TJSObject( LArray[0]);

          vpOpazovanoLeto.Text := String(LItem['OpazovanoObdobje']);
          // Na osnovi vpOpazovanoLeto vnese letnice na ostalih listih...
          for i := 1 to 4 do begin
            izpisVrednosti := FindComponent('izPlanProdaje_letoXplus'+IntToStr(i)) as TLabel;
            izpisVrednosti.Caption := LjezikLeto + ' ' + IntToStr(StrToInt(vpOpazovanoLeto.Text) + i) +':';
          end;
          vpTabelaPromet_LetoXminus1.Caption := LjezikLeto + ' ' + IntToStr(StrToInt(vpOpazovanoLeto.Text) - 1) +':';
          vpTabelaPromet_LetoX.Caption := LjezikLeto + ' ' + vpOpazovanoLeto.Text +':';
          // polnjenje polj podatkov za izračun kosmatega dobička
          vpVnosFRPPromet.Text :=FormatFloat('###,###,##0.00', Real(LItem['Promet']));
          vpVnosFRPZalogeZacetek.Text := FormatFloat('###,###,##0.00', Real(LItem['ZalogeZacetek']));
          vpVnosFRPZalogeKonec.Text := FormatFloat('###,###,##0.00', Real(LItem['ZalogeKonec']));
          vpVnosFRPInternoProizvedeniDodatki.Text := FormatFloat('###,###,##0.00', Real(LItem['InternoProizvedeniDodatki']));
          vpVnosFRPStroskiSurovine.Text := FormatFloat('###,###,##0.00', Real(LItem['StroskiSurovin']));
          vpVnosFRPStroskiMaterial.Text := FormatFloat('###,###,##0.00', Real(LItem['Stroskimateriala']));
          vpVnosFRPStroskiEnergija.Text := FormatFloat('###,###,##0.00', Real(LItem['StroskiEnergije']));
          vpVnosFRPStroskiTrgovskoBlago.Text := FormatFloat('###,###,##0.00', Real(LItem['StroskiTrgovskegaBlaga']));
          vpVnosFRPStroskiStoritve.Text := FormatFloat('###,###,##0.00', Real(LItem['StroskiZunanjihIzvajalcev']));
          vpVnosFRPDrugiStroski.Text := FormatFloat('###,###,##0.00', Real(LItem['DrugiStroski']));
          vpVnosFRPPrejeteZavarovalnine.Text := FormatFloat('###,###,##0.00', Real(LItem['PrejeteZavarovalnine']));
          // polnjenje spremeljivk z vrednostmi, ki bodo nato posredovane Web servisu za izračun kosmatega dobička in stopnje kosmatega dobička
          promet := Real(LItem['Promet']) * 100;
          zalogeZacetek := Real(LItem['ZalogeZacetek']) * 100;
          zalogeKonec := Real(LItem['ZalogeKonec']) * 100;
          internoProizvedeniDodatki := Real(LItem['InternoProizvedeniDodatki']) * 100;
          stroskiSurovin := Real(LItem['StroskiSurovin']) * 100;
          stroskiMateriala := Real(LItem['Stroskimateriala']) * 100;
          stroskiEnergije := Real(LItem['StroskiEnergije']) * 100;
          stroskiTrgovskegaBlaga := Real(LItem['StroskiTrgovskegaBlaga']) * 100;
          stroskiStoritev := Real(LItem['StroskiZunanjihIzvajalcev']) * 100;
          drugiStroski := Real(LItem['DrugiStroski']) * 100;
          prejeteZavarovalnine := Real(LItem['PrejeteZavarovalnine']) * 100;
          // polje pdoatkov o mesečnih prometih za leto Xminus1 in X
          for i := 1 to 12 do begin
             poljeVrednosti := FindComponent('vpTabelaPromet_ProdajaXminus1_'+IntToStr(i)) as TEdit;
             poljeVrednosti.Text := FormatFloat('###,###,##0.00', Real(LItem['PrometXminus1_'+IntToStr(i)]));
          end;
          // polnjenje spremeljivk z vrednostmi, ki bodo nato posredovane Web servisu za izračun letnega prometa leta Xminus1
          mesecniPrometXminus1_1 := Real(LItem['PrometXminus1_1']) * 100;
          mesecniPrometXminus1_2 := Real(LItem['PrometXminus1_2']) * 100;
          mesecniPrometXminus1_3 := Real(LItem['PrometXminus1_3']) * 100;
          mesecniPrometXminus1_4 := Real(LItem['PrometXminus1_4']) * 100;
          mesecniPrometXminus1_5 := Real(LItem['PrometXminus1_5']) * 100;
          mesecniPrometXminus1_6 := Real(LItem['PrometXminus1_6']) * 100;
          mesecniPrometXminus1_7 := Real(LItem['PrometXminus1_7']) * 100;
          mesecniPrometXminus1_8 := Real(LItem['PrometXminus1_8']) * 100;
          mesecniPrometXminus1_9 := Real(LItem['PrometXminus1_9']) * 100;
          mesecniPrometXminus1_10 := Real(LItem['PrometXminus1_10']) * 100;
          mesecniPrometXminus1_11 := Real(LItem['PrometXminus1_11']) * 100;
          mesecniPrometXminus1_12 := Real(LItem['PrometXminus1_12']) * 100;

          for i := 1 to 12 do begin
             poljeVrednosti := FindComponent('vpTabelaPromet_ProdajaX_'+IntToStr(i)) as TEdit;
             poljeVrednosti.Text := FormatFloat('###,###,##0.00', Real(LItem['PrometX_'+IntToStr(i)]));
          end;
          // polnjenje spremeljivk z vrednostmi, ki bodo nato posredovane Web servisu za izračun letnega prometa leta X
          mesecniPrometX_1 := Real(LItem['PrometX_1']) * 100;
          mesecniPrometX_2 := Real(LItem['PrometX_2']) * 100;
          mesecniPrometX_3 := Real(LItem['PrometX_3']) * 100;
          mesecniPrometX_4 := Real(LItem['PrometX_4']) * 100;
          mesecniPrometX_5 := Real(LItem['PrometX_5']) * 100;
          mesecniPrometX_6 := Real(LItem['PrometX_6']) * 100;
          mesecniPrometX_7 := Real(LItem['PrometX_7']) * 100;
          mesecniPrometX_8 := Real(LItem['PrometX_8']) * 100;
          mesecniPrometX_9 := Real(LItem['PrometX_9']) * 100;
          mesecniPrometX_10 := Real(LItem['PrometX_10']) * 100;
          mesecniPrometX_11 := Real(LItem['PrometX_11']) * 100;
          mesecniPrometX_12 := Real(LItem['PrometX_12']) * 100;

          for i := 1 to 4 do begin
             prikazVrednosti := FindComponent('vpPlanprodaje_RastXplus'+IntToStr(i)) as TTrackBar;
             izpisVrednosti := FindComponent('izPlanProdaje_RastXplus'+IntToStr(i)) as TLabel;
             prikazVrednosti.Position := Integer(LItem['PlanXplus'+IntToStr(i)]);
             izpisVrednosti.Caption := FormatFloat('###,###,##0.00 %', prikazVrednosti.Position / 10);
          end;
          // polnjenje spremeljivk z vrednostmi, ki bodo nato posredovane Web servisu (pričakovane rasti v letih Xplus 1 do Xplus4) - podatek je v Position
          rast1 := Integer(LItem['PlanXplus1']) / 10 * 100;
          rast2 := Integer(LItem['PlanXplus2']) / 10 * 100;
          rast3 := Integer(LItem['PlanXplus3']) / 10 * 100;
          rast4 := Integer(LItem['PlanXplus4']) / 10 * 100;

          vpOrganizacija_Odgovor1.Position := Integer(LItem['DobavniRokStrojev']);
          izOrganizacija_mera1.Caption := FormatFloat('###,###,##0.0', vpOrganizacija_Odgovor1.Position / 2)+' ' + LjezikMera_Mesec;
          vpOrganizacija_Odgovor2.Position := Integer(LItem['MontazaStrojev']);
          izOrganizacija_mera2.Caption := FormatFloat('###,###,##0.0', vpOrganizacija_Odgovor2.Position / 2)+' ' + LjezikMera_Mesec;

          if Integer(LItem['RabljeniStroji']) = 1 then begin
             vpOrganizacija_OdgovorDa3.Checked := true;
             vpOrganizacija_OdgovorNe3.Checked := false;
          end
          else begin
             vpOrganizacija_OdgovorDa3.Checked := false;
             vpOrganizacija_OdgovorNe3.Checked := true;
          end;

          if Integer(LItem['DrugaLokacijaLastna']) = 1 then begin
             vpOrganizacija_OdgovorDa4.Checked := true;
             vpOrganizacija_OdgovorNe4.Checked := false;
          end
          else begin
             vpOrganizacija_OdgovorDa4.Checked := false;
             vpOrganizacija_OdgovorNe4.Checked := true;
          end;

          if Integer(LItem['DrugaLokacijaNajeta']) = 1 then begin
             vpOrganizacija_OdgovorDa5.Checked := true;
             vpOrganizacija_OdgovorNe5.Checked := false;
          end
          else begin
             vpOrganizacija_OdgovorDa5.Checked := false;
             vpOrganizacija_OdgovorNe5.Checked := true;
          end;

          if Integer(LItem['PosebniDelovniPogoji']) = 1 then begin
             vpOrganizacija_OdgovorDa6.Checked := true;
             vpOrganizacija_OdgovorNe6.Checked := false;
          end
          else begin
             vpOrganizacija_OdgovorDa6.Checked := false;
             vpOrganizacija_OdgovorNe6.Checked := true;
          end;

          if Integer(LItem['UporabaNevarnihSnovi']) = 1 then begin
             vpOrganizacija_OdgovorDa7.Checked := true;
             vpOrganizacija_OdgovorNe7.Checked := false;
          end
          else begin
             vpOrganizacija_OdgovorDa7.Checked := false;
             vpOrganizacija_OdgovorNe7.Checked := true;
          end;

          if Integer(LItem['VgradnjaNevarnihSnovi']) = 1 then begin
             vpOrganizacija_OdgovorDa8.Checked := true;
             vpOrganizacija_OdgovorNe8.Checked := false;
          end
          else begin
             vpOrganizacija_OdgovorDa8.Checked := false;
             vpOrganizacija_OdgovorNe8.Checked := true;
          end;

          vpOrganizacija_Odgovor9.Position := Integer(LItem['Izmene']);
          izOrganizacija_mera9.Caption := FormatFloat('###,###,##0.0', vpOrganizacija_Odgovor9.Position )+' ' + LjezikMera_Izmena;

          vpOrganizacija_Odgovor10.Position := Integer(LItem['ZasedenostProizvodnje']);
          izOrganizacija_mera10.Caption := FormatFloat('###,###,##0.0', vpOrganizacija_Odgovor10.Position )+' %';

          if Integer(LItem['LocenoSkladisce_nad10']) = 1 then begin
             vpOrganizacija_OdgovorDa11_1.Checked := true;
             vpOrganizacija_OdgovorNe11_1.Checked := false;
          end
          else begin
             vpOrganizacija_OdgovorDa11_1.Checked := false;
             vpOrganizacija_OdgovorNe11_1.Checked := true;
          end;

          vpOrganizacija_Odgovor11_2.Position := Integer(LItem['LocenoSkladisce_nad10_Delez']);
          izOrganizacija_mera11_2.Caption := FormatFloat('###,###,##0.0', vpOrganizacija_Odgovor11_2.Position )+' %';

          vpOrganizacija_Odgovor11_3.Position := Integer(LItem['LocenoSkladisce_nad10_Obdobje']);
          izOrganizacija_mera11_3.Caption := FormatFloat('###,###,##0.0', vpOrganizacija_Odgovor11_3.Position / 2 )+' ' + LjezikMera_Mesec;

          if Integer(LItem['LocenoSkladisce_pod10']) = 1 then begin
             vpOrganizacija_OdgovorDa11_4.Checked := true;
             vpOrganizacija_OdgovorNe11_4.Checked := false;
          end
          else begin
             vpOrganizacija_OdgovorDa11_4.Checked := false;
             vpOrganizacija_OdgovorNe11_4.Checked := true;
          end;

          vpOrganizacija_Odgovor11_5.Position := Integer(LItem['LocenoSkladisce_pod10_Delez']);
          izOrganizacija_mera11_5.Caption := FormatFloat('###,###,##0.0', vpOrganizacija_Odgovor11_5.Position )+' %';

          vpOrganizacija_Odgovor11_6.Position := Integer(LItem['LocenoSkladisce_pod10_Obdobje']);
          izOrganizacija_mera11_6.Caption := FormatFloat('###,###,##0.0', vpOrganizacija_Odgovor11_6.Position / 2 )+' ' + LjezikMera_Mesec;

          vpOrganizacija_Odgovor12.Position := Integer(LItem['ZamikPlacil']);
          izOrganizacija_mera12.Caption := FormatFloat('###,###,##0.0', vpOrganizacija_Odgovor12.Position / 2 )+' ' + LjezikMera_Mesec;

          vpOrganizacija_Odgovor13.ItemIndex := Integer(LItem['TipProizvodnje']);

          if Integer(LItem['Zavarovanje']) = 1 then begin
            vpZavarovanjeDa.Checked := true;
            ploscicaPodatkiOZavarovanju.Visible := true;
          end
          else begin
            vpZavarovanjeDa.Checked := false;
            ploscicaPodatkiOZavarovanju.Visible := false;
            zvObstojeca := 0;
          end;

          if Integer(LItem['ZavarovanjeKD']) = 1 then begin
            vpZavarovanjeDAKosmatiDobicek.Checked := true;
            vpZVKosmatiDobicek.Enabled := true;
            zvObstojeca := Real(LItem['ZavarovanjeKD_ZV']) * 100;
          end
          else begin
            vpZavarovanjeDAKosmatiDobicek.Checked := false;
            vpZVKosmatiDobicek.Enabled := false;
          end;

          vpZVKosmatiDobicek.Text := FormatFloat('###,###,##0.00', Real(LItem['ZavarovanjeKD_ZV']));

          if Integer(LItem['ZavarovanjeNS']) = 1 then begin
             vpZavarovanjeDANastetiStroski.Checked := true;
             for i := 2 to 6 do begin
                 poljeVrednosti := FindComponent('vpZavarovaniStroski2_'+IntToStr(i)) as TEdit;
                 poljeVrednosti.Enabled := true;
             end;
             zvObstojeca := (Real(LItem['ZavarovanjeNS_StrStoritev']) + Real(LItem['ZavarovanjeNS_Amortizacija']) + Real(LItem['ZavarovanjeNS_StrDela']) + Real(LItem['ZavarovanjeNS_StrDrugi']) + Real(LItem['ZavarovanjeNS_Dobicek'])) * 100;
             vpZVNastetiStroski.Enabled := true;
          end
          else begin
             vpZavarovanjeDANastetiStroski.Checked := false;
             for i := 2 to 6 do begin
                 poljeVrednosti := FindComponent('vpZavarovaniStroski2_'+IntToStr(i)) as TEdit;
                 poljeVrednosti.Enabled := false;
             end;
             vpZVNastetiStroski.Enabled := false;
          end;

         vpZavarovaniStroski2_2.Text := FormatFloat('###,###,##0.00', Real(LItem['ZavarovanjeNS_StrStoritev']));
         vpZavarovaniStroski2_3.Text := FormatFloat('###,###,##0.00', Real(LItem['ZavarovanjeNS_Amortizacija']));
         vpZavarovaniStroski2_4.Text := FormatFloat('###,###,##0.00', Real(LItem['ZavarovanjeNS_StrDela']));
         vpZavarovaniStroski2_5.Text := FormatFloat('###,###,##0.00', Real(LItem['ZavarovanjeNS_StrDrugi']));
         vpZavarovaniStroski2_6.Text := FormatFloat('###,###,##0.00', Real(LItem['ZavarovanjeNS_Dobicek']));
         vpZVNastetiStroski.Text := FormatFloat('###,###,##0.00', (Real(LItem['ZavarovanjeNS_StrStoritev']) + Real(LItem['ZavarovanjeNS_Amortizacija']) + Real(LItem['ZavarovanjeNS_StrDela']) + Real(LItem['ZavarovanjeNS_StrDrugi']) + Real(LItem['ZavarovanjeNS_Dobicek'])));

         vpMDJ.ItemIndex := Integer(LItem['ZavarovanjeMDJ']);
         case Integer(LItem['ZavarovanjeMDJ']) of
           0..9 : mdjObstojeca := Integer(LItem['ZavarovanjeMDJ']) + 3;
           10   : mdjObstojeca := 18;
           11   : mdjObstojeca := 24;
         end;

         vpZacetekZavarovanja.Date:= StrToDateTime(String(LItem['ZavarovanjeZacetek']));

         vpPremija.Text := FormatFloat('###,###,##0.00', Real(LItem['Premija']));

         vpParameter1_1.Position := Integer(LItem['Ciscenje_Trajanje']);
         vpParameter1_2.Position := Integer(LItem['Ciscenje_Zacetek']);
         vpParameter2_1.Position := Integer(LItem['Dokumentacija_Trajanje']);
         vpParameter2_2.Position := Integer(LItem['Dokumentacija_Zacetek']);
         vpParameter3_1.Position := Integer(LItem['Gradnja_Trajanje']);
         vpParameter3_2.Position := Integer(LItem['Gradnja_Zacetek']);
         vpParameter4_1.Position := Integer(LItem['DobavaStrojev_Trajanje']);
         vpParameter4_2.Position := Integer(LItem['DobavaStrojev_Zacetek']);
         vpParameter5_1.Position := Integer(LItem['MontazaStrojev_Trajanje']);
         vpParameter5_2.Position := Integer(LItem['MontazaStrojev_Zacetek']);
         vpParameter6_1.Position := Integer(LItem['PolneZmogljivosti_Trajanje']);
         vpParameter7_1.Position := Integer(LItem['VarnostniDodatek']);
         // polnjenje spremeljivk z vrednostmi, ki bodo nato posredovane Web servisu za izračun MDJ
         parameter1_1 := Integer(LItem['Ciscenje_Trajanje']) / 4 * 100;
         parameter1_2 := Integer(LItem['Ciscenje_Zacetek']) / 4 * 100;
         parameter2_1 := Integer(LItem['Dokumentacija_Trajanje']) / 4 * 100;
         parameter2_2 := Integer(LItem['Dokumentacija_Zacetek']) / 4 * 100;
         parameter3_1 := Integer(LItem['Gradnja_Trajanje']) / 4 * 100;
         parameter3_2 := Integer(LItem['Gradnja_Zacetek']) / 4 * 100;
         parameter4_1 := Integer(LItem['DobavaStrojev_Trajanje']) / 4 * 100;
         parameter4_2 := Integer(LItem['DobavaStrojev_Zacetek']) / 4 * 100;
         parameter5_1 := Integer(LItem['MontazaStrojev_Trajanje']) / 4 * 100;
         parameter5_2 := Integer(LItem['MontazaStrojev_Zacetek']) / 4 * 100;
         parameter6_1 := Integer(LItem['PolneZmogljivosti_Trajanje']) / 4 *100;
         parameter7_1 := Integer(LItem['VarnostniDodatek']) / 4 * 100;

         // Izpis v tekstovni obliki
         for i := 1 to 7 do begin
             prikazVrednosti := FindComponent('vpParameter'+IntToStr(i)+'_1') as TTrackBar;
             izpisVrednosti := FindComponent('izParameter'+IntToStr(i)+'_1') as TLabel;
             izpisVrednosti.Caption := FormatFloat('###,###,##0.00', prikazVrednosti.Position / 4);
         end;
         for i := 1 to 5 do begin
             prikazVrednosti := FindComponent('vpParameter'+IntToStr(i)+'_2') as TTrackBar;
             izpisVrednosti := FindComponent('izParameter'+IntToStr(i)+'_2') as TLabel;
             izpisVrednosti.Caption := FormatFloat('###,###,##0.00', prikazVrednosti.Position / 4);
         end;

         if Integer(LItem['KorekcijskiDodatek']) = 1 then begin
           vpKorekcijskiFaktorji.Checked := true;
           ploscicaKorekcijskiFaktorji.Visible := true;
           ploscicaMaksimalniPribitek.Visible := true;
         end
         else begin
           vpKorekcijskiFaktorji.Checked := false;
           ploscicaKorekcijskiFaktorji.Visible := false;
           ploscicaMaksimalniPribitek.Visible := false;
         end;
         vpMaksimalniPribitek.Position := Integer(LItem['KorekcijskiDodatek_Max']);
         izMaksimalniPribitek.Caption := FormatFloat('###,###,##0 %', vpMaksimalniPribitek.Position);
         // polnjenje spremeljivk z vrednostmi, ki bodo nato posredovane Web servisu za izračun MDJ
         korigiranMDJ := Integer(LItem['KorekcijskiDodatek']);
         maxPribitek := Integer(LItem['KorekcijskiDodatek_Max']);

         vpPloscicaKorekcijskiFaktorji_KFDrsnik1_4.Position := Integer(LItem['Faktor_VplivNaOkolje_NS']);
         if vpPloscicaKorekcijskiFaktorji_KFDrsnik1_4.Position > 0 then begin
            izPloscicaKorekcijskiFaktorji_KF1_3.Enabled := true;
            izPloscicaKorekcijskiFaktorji_KF1_3.Caption := 'DA';
            vpPloscicaKorekcijskiFaktorji_KFDrsnik1_4.Enabled := true;
         end
         else begin
            izPloscicaKorekcijskiFaktorji_KF1_3.Enabled := false;
            izPloscicaKorekcijskiFaktorji_KF1_3.Caption := 'NE';
            vpPloscicaKorekcijskiFaktorji_KFDrsnik1_4.Enabled := false;
         end;
         vpPloscicaKorekcijskiFaktorji_KFDrsnik2_4.Position := Integer(LItem['Faktor_VplivNaOkolje_NV']);
         if vpPloscicaKorekcijskiFaktorji_KFDrsnik2_4.Position > 0 then begin
            izPloscicaKorekcijskiFaktorji_KF2_3.Enabled := true;
            izPloscicaKorekcijskiFaktorji_KF2_3.Caption := 'DA';
            vpPloscicaKorekcijskiFaktorji_KFDrsnik2_4.Enabled := true;
         end
         else begin
            izPloscicaKorekcijskiFaktorji_KF2_3.Enabled := false;
            izPloscicaKorekcijskiFaktorji_KF2_3.Caption := 'NE';
            vpPloscicaKorekcijskiFaktorji_KFDrsnik2_4.Enabled := false;
         end;
         vpPloscicaKorekcijskiFaktorji_KFDrsnik3_4.Position := Integer(LItem['Faktor_PosebniDelovniPogoji']);
         if vpPloscicaKorekcijskiFaktorji_KFDrsnik3_4.Position > 0 then begin
            izPloscicaKorekcijskiFaktorji_KF3_3.Enabled := true;
            izPloscicaKorekcijskiFaktorji_KF3_3.Caption := 'DA';
            vpPloscicaKorekcijskiFaktorji_KFDrsnik3_4.Enabled := true;
         end
         else begin
            izPloscicaKorekcijskiFaktorji_KF3_3.Enabled := false;
            izPloscicaKorekcijskiFaktorji_KF3_3.Caption := 'NE';
            vpPloscicaKorekcijskiFaktorji_KFDrsnik3_4.Enabled := false;
         end;
         vpPloscicaKorekcijskiFaktorji_KFDrsnik4_4.Position := Integer(LItem['Faktor_RabljeniStroji']);
         if vpPloscicaKorekcijskiFaktorji_KFDrsnik4_4.Position > 0 then begin
            izPloscicaKorekcijskiFaktorji_KF4_3.Enabled := true;
            izPloscicaKorekcijskiFaktorji_KF4_3.Caption := 'DA';
            vpPloscicaKorekcijskiFaktorji_KFDrsnik4_4.Enabled := true;
         end
         else begin
            izPloscicaKorekcijskiFaktorji_KF4_3.Enabled := false;
            izPloscicaKorekcijskiFaktorji_KF4_3.Caption := 'NE';
            vpPloscicaKorekcijskiFaktorji_KFDrsnik4_4.Enabled := false;
         end;
         vpPloscicaKorekcijskiFaktorji_KFDrsnik5_4.Position := Integer(LItem['Faktor_Izmene']);
         if vpPloscicaKorekcijskiFaktorji_KFDrsnik5_4.Position > 0 then begin
            izPloscicaKorekcijskiFaktorji_KF5_3.Enabled := true;
            izPloscicaKorekcijskiFaktorji_KF5_3.Caption := 'DA';
            vpPloscicaKorekcijskiFaktorji_KFDrsnik5_4.Enabled := true;
         end
         else begin
            izPloscicaKorekcijskiFaktorji_KF5_3.Enabled := false;
            izPloscicaKorekcijskiFaktorji_KF5_3.Caption := 'NE';
            vpPloscicaKorekcijskiFaktorji_KFDrsnik5_4.Enabled := false;
         end;
         // polnjenje spremeljivk z vrednostmi, ki bodo nato posredovane Web servisu za izračun MDJ
         korFaktor1 := Integer(LItem['Faktor_VplivNaOkolje_NS']) / 4 *100;
         korFaktor2 := Integer(LItem['Faktor_VplivNaOkolje_NV']) / 4 *100;
         korFaktor3 := Integer(LItem['Faktor_PosebniDelovniPogoji']) / 4 *100;
         korFaktor4 := Integer(LItem['Faktor_RabljeniStroji']) / 4 *100;
         korFaktor5 := Integer(LItem['Faktor_Izmene']) / 4 *100;

         // Izpis v tekstovni obliki
         for i := 1 to 5 do begin
             prikazVrednosti := FindComponent('vpPloscicaKorekcijskiFaktorji_KFDrsnik'+IntToStr(i)+'_4') as TTrackBar;
             izpisVrednosti := FindComponent('vpPloscicaKorekcijskiFaktorji_KFIzpis'+IntToStr(i)+'_4') as TLabel;
             izpisVrednosti.Caption := FormatFloat('###,###,##0.00', prikazVrednosti.Position / 4);
         end;

         if Integer(LItem['IzracunZV_Leto']) = 1 then
           vpLetoPriporoceneZV.Position := vpLetoPriporoceneZV.Min
         else
           vpLetoPriporoceneZV.Position := vpLetoPriporoceneZV.Max;
         izLetoPriporoceneZVmin.Caption := IntToStr(StrToInt(vpOpazovanoLeto.Text) + 1);
         izLetoPriporoceneZVmax.Caption := IntToStr(StrToInt(vpOpazovanoLeto.Text) + 2);

         // polnjenje spremeljivk z vrednostmi, ki bodo nato posredovane Web servisu za izračun MDJ
         letoZV := Integer(LItem['IzracunZV_Leto']);

        izTekstPriporoceniKD.Caption :=  LjezikDL7_izTekstPriporoceniKD + ' ' + vpOpazovanoLeto.Text + ':';
        izTekstPriporocenaSKD.Caption := LjezikDL7_izTekstPriporocenaSKD + ' ' + vpOpazovanoLeto.Text + ':';
        izTekstLetoPriporoceneZV.Caption :=  LjezikDL7_izTekstLetoPriporoceneZV + ':';


         // PRERAČUN

          CelotnoOsvezevanjeIzracunov;

       end
       else begin
          ShowMessage(LjezikObvestilo_NalaganjePodatkov);
       end;
     finally
      PovezavaServerPodatki.Connected := false;
     end;
  end;


begin
  try
    WebClientPodatki.RawInvoke('IPodatkiService.GetPodatki', [izborKalkulacije], @OnResult );
  except
    ShowMessage(LjezikObvestilo_ServerPoizvedba);
  end;

end;

procedure TfmOsnovniKontroler.PovezavaServerPodatkiError(
  Error: TXDataWebConnectionError);
begin
  showMessage(LjezikObvestilo_ServerNiPovezave_01);
end;

procedure TfmOsnovniKontroler.PovezavaWSDelniIzracuniConnect(Sender: TObject);

     procedure OnResultIzracunZV( Response: TXDataClientResponse );
     var
       LTabela : TJSArray;
       TabelaRezultatov : Array of Real;
       i : Integer;
     begin
       try
         LTabela := TJSArray(TJSObject(Response.Result)['value']);
         SetLength(TabelaRezultatov, 3);
         for i := 0 to 2 do
             TabelaRezultatov[i] := Real(LTabela[i]);
         izPriporoceneZV_ZV12.Caption := FormatFloat('###,###,##0.00', TabelaRezultatov[0]);
         izPriporoceneZV_ZV18.Caption := FormatFloat('###,###,##0.00', TabelaRezultatov[1]);
         izPriporoceneZV_ZV24.Caption := FormatFloat('###,###,##0.00', TabelaRezultatov[2]);
       finally
         PovezavaWSDelniIzracuni.Connected := false;
         fmOsnovniKontroler.Cursor := crDefault;
       end;
     end;

     procedure OnResultIzracunMDJ( Response: TXDataClientResponse );
     var
       LTabela : TJSArray;
       TabelaRezultatov : Array of Real;
       i : Integer;
       it : TGoogleChartSeriesItem;
       AnnotationType: TGoogleChartAnnotationType;
     begin
       try
         LTabela := TJSArray(TJSObject(Response.Result)['value']);
         SetLength(TabelaRezultatov, 15);
         for i := 0 to 14 do
             TabelaRezultatov[i] := Real(LTabela[i]);
         izPriporocenaMDJ.Caption := FormatFloat('###,###,##0.00', TabelaRezultatov[0])+ ' ' + LjezikSteviloMesecev;

         GrafMDJ.BeginUpdate;
         GrafMDJ.Series.Clear;
         GrafMDJ.Options.Clear;
         AnnotationType := gcatData;
         it := GrafMDJ.Series.Add;
         it.ChartType := gctTimeLine;

         it.Values.AddTimelinePoint(Now, Now, LjezikGrafMDJ_Skoda);
         it.Values.AddTimelinePoint(TabelaRezultatov[1], TabelaRezultatov[2], LjezikGrafMDJ_Ciscenje);
         it.Values.AddTimelinePoint(TabelaRezultatov[3], TabelaRezultatov[4], LjezikGrafMDJ_Dokumentacija);
         it.Values.AddTimelinePoint(TabelaRezultatov[5], TabelaRezultatov[6], LjezikGrafMDJ_Gradnja);
         it.Values.AddTimelinePoint(TabelaRezultatov[7], TabelaRezultatov[8], LjezikGrafMDJ_DobavaStrojev);
         it.Values.AddTimelinePoint(TabelaRezultatov[9], TabelaRezultatov[10], LjezikGrafMDJ_MontazaStrojev);
         it.Values.AddTimelinePoint(TabelaRezultatov[11], TabelaRezultatov[12], LjezikGrafMDJ_PolneZmogljivosti);
         it.Values.AddTimelinePoint(TabelaRezultatov[13], TabelaRezultatov[14], LjezikGrafMDJ_VarnostniDodatek);

         GrafMDJ.Enabled := true;
         GrafMDJ.EndUpdate;

       finally
         PovezavaWSDelniIzracuni.Connected := false;
         fmOsnovniKontroler.Cursor := crDefault;
       end;
     end;


     procedure OnResultKosmatiDobicek( Response: TXDataClientResponse );
     var
       LTabela : TJSArray;
       TabelaRezultatov : Array of Real;
       i : Integer;
     begin
       try
         LTabela := TJSArray(TJSObject(Response.Result)['value']);
         SetLength(TabelaRezultatov, 2);
         for i := 0 to 1 do
           TabelaRezultatov[i] := Real(LTabela[i]);
         vpVnosFRPKosmatiDobicek.Caption := FormatFloat('###,###,##0.00', TabelaRezultatov[0]);
         izPriporoceniKD.Caption := FormatFloat('###,###,##0.00', TabelaRezultatov[0]);
         vpVnosFRPStopnjaKosmategaDobicka.Caption := FormatFloat('###,###,##0.00', TabelaRezultatov[1]) + ' %';
         izPriporocenaSKD.Caption := FormatFloat('###,###,##0.00', TabelaRezultatov[1]) + ' %';
       finally
         PovezavaWSDelniIzracuni.Connected := false;
         fmOsnovniKontroler.Cursor := crDefault;
       end;
     end;

     procedure OnResultLetniPrometi( Response: TXDataClientResponse );
     var
       LTabela : TJSArray;
       TabelaRezultatov : Array of Real;
       i : Integer;
     begin
       try
         LTabela := TJSArray(TJSObject(Response.Result)['value']);
         SetLength(TabelaRezultatov, 2);
         for i := 0 to 1 do
           TabelaRezultatov[i] := Real(LTabela[i]);
         vpTabelaPromet_LetoXminus1Skupaj.Caption := FormatFloat('###,###,##0.00', TabelaRezultatov[0]);
         vpTabelaPromet_LetoXSkupaj.Caption := FormatFloat('###,###,##0.00', TabelaRezultatov[1]);
       finally
         PovezavaWSDelniIzracuni.Connected := false;
         fmOsnovniKontroler.Cursor := crDefault;
       end;
     end;


begin

  if posiljatelj = 1 then begin
     try
       try
         WebClientWSDelniIzracuni.RawInvoke('IIzracuniService.IzracunZV', [kosmatiDobicek, rast1, rast2, rast3, rast4, letoZV], @OnResultIzracunZV );
       finally
         posiljatelj := 0;
       end;
     except
       ShowMessage(LjezikObvestilo_ServerPoizvedba);
     end;
  end
  else if posiljatelj = 2 then begin
     try
       try
         WebClientWSDelniIzracuni.RawInvoke('IIzracuniService.IzracunMDJ', [parameter1_1, parameter1_2, parameter2_1, parameter2_2, parameter3_1, parameter3_2, parameter4_1, parameter4_2, parameter5_1, parameter5_2, parameter6_1, parameter7_1, korFaktor1, korFaktor2, korFaktor3, korFaktor4, korFaktor5, maxPribitek, korigiranMDJ], @OnResultIzracunMDJ );
        finally
          posiljatelj := 0;
        end;
     except
       ShowMessage(LjezikObvestilo_ServerPoizvedba);
     end;
  end
  else if posiljatelj = 3 then begin
     try
       try
         WebClientWSDelniIzracuni.RawInvoke('IIzracuniService.IzracunKosmatiDobicek', [promet, zalogeZacetek, ZalogeKonec, internoProizvedeniDodatki, stroskiSurovin, stroskiMateriala, stroskiEnergije, stroskiTrgovskegaBlaga, stroskiStoritev, drugiStroski, prejeteZavarovalnine], @OnResultKosmatiDobicek);
        finally
          posiljatelj := 0;
        end;
     except
       ShowMessage(LjezikObvestilo_ServerPoizvedba);
     end;
  end
  else if posiljatelj = 4 then begin
     try
       try
         WebClientWSDelniIzracuni.RawInvoke('IIzracuniService.IzracunLetnihPrometov', [mesecniPrometXminus1_1, mesecniPrometXminus1_2, mesecniPrometXminus1_3, mesecniPrometXminus1_4, mesecniPrometXminus1_5, mesecniPrometXminus1_6, mesecniPrometXminus1_7, mesecniPrometXminus1_8, mesecniPrometXminus1_9, mesecniPrometXminus1_10, mesecniPrometXminus1_11, mesecniPrometXminus1_12, mesecniPrometX_1, mesecniPrometX_2, mesecniPrometX_3, mesecniPrometX_4, mesecniPrometX_5, mesecniPrometX_6, mesecniPrometX_7, mesecniPrometX_8, mesecniPrometX_9, mesecniPrometX_10, mesecniPrometX_11, mesecniPrometX_12], @OnResultLetniPrometi);
        finally
          posiljatelj := 0;
        end;
     except
       ShowMessage(LjezikObvestilo_ServerPoizvedba);
     end;
  end;
end;

procedure TfmOsnovniKontroler.PovezavaWSDelniIzracuniError(
  Error: TXDataWebConnectionError);
begin
  showMessage(LjezikObvestilo_WebServisi);
end;

procedure TfmOsnovniKontroler.PovezavaWSPopolniPreracunConnect(Sender: TObject);

   procedure OnResultKosmatiDobicek( Response: TXDataClientResponse );

         procedure OnResultZV( Response: TXDataClientResponse );
         var
           LTabela : TJSArray;
           TabelaRezultatov : Array of Real;
           i : Integer;
         begin
           try
            LTabela := TJSArray(TJSObject(Response.Result)['value']);
            SetLength(TabelaRezultatov, 19);
            for i := 0 to 18 do
               TabelaRezultatov[i] := Real(LTabela[i]);

           // Zapiše izračunano / priporočeno MDJ (glede na to ali je izbrana opcija z ali brez korekcijskega faktorja)
           if vpKorekcijskiFaktorji.Checked = true then begin
             izPriporocenaMDJ.Caption := FormatFloat('###,###,##0.00', TabelaRezultatov[10])+ ' ' + LjezikSteviloMesecev;
           end
           else begin
             izPriporocenaMDJ.Caption := FormatFloat('###,###,##0.00', TabelaRezultatov[9])+' ' + LjezikSteviloMesecev;
           end;
           // Zapiše izračunane / priporočene ZV (glede na to ali je izbrana vrednost za leto X+1 (tj. 1) ali X+2 (tj. 2)
           if TabelaRezultatov[14] = 1 then begin
              vpLetoPriporoceneZV.Position := vpLetoPriporoceneZV.Min;
              izPriporoceneZV_ZV12.Caption := FormatFloat('###,###,##0.00', TabelaRezultatov[0]);
              izPriporoceneZV_ZV18.Caption := FormatFloat('###,###,##0.00', TabelaRezultatov[1]);
              izPriporoceneZV_ZV24.Caption := FormatFloat('###,###,##0.00', TabelaRezultatov[2]);
           end
           else begin
              vpLetoPriporoceneZV.Position := vpLetoPriporoceneZV.Max;
              izPriporoceneZV_ZV12.Caption := FormatFloat('###,###,##0.00', TabelaRezultatov[3]);
              izPriporoceneZV_ZV18.Caption := FormatFloat('###,###,##0.00', TabelaRezultatov[4]);
              izPriporoceneZV_ZV24.Caption := FormatFloat('###,###,##0.00', TabelaRezultatov[5]);
           end;
           // Izbere in izriše ustrezno luč na semaforju za MDJ (osnovno)
           if ((vpZavarovanjeDa.Checked = true) and (vpZavarovanjeDAKosmatiDobicek.Checked = true)) Or ((vpZavarovanjeDa.Checked = true) and (vpZavarovanjeDANastetiStroski.Checked = true)) then begin
             if TabelaRezultatov[15] = 1 then begin
               izPloscicaObstojeceZavarovanje_Semafor3.Picture.LoadFromFile('luc_Z.png');
               izPloscicaObstojeceZavarovanje_Semafor3.Visible := true;
             end
             else if TabelaRezultatov[15] = 2 then begin
               izPloscicaObstojeceZavarovanje_Semafor3.Picture.LoadFromFile('luc_Ru.png');
               izPloscicaObstojeceZavarovanje_Semafor3.Visible := true;
             end
             else begin
               izPloscicaObstojeceZavarovanje_Semafor3.Picture.LoadFromFile('luc_Rd.png');
               izPloscicaObstojeceZavarovanje_Semafor3.Visible := true;
             end;
             // Izbere in izriše ustrezno luč na semaforju za ZV (osnovno)
             if TabelaRezultatov[17] = 1 then begin
               izPloscicaObstojeceZavarovanje_Semafor4.Picture.LoadFromFile('luc_Z.png');
               izPloscicaObstojeceZavarovanje_Semafor4.Visible := true;
             end
             else if TabelaRezultatov[17] = 2 then begin
               izPloscicaObstojeceZavarovanje_Semafor4.Picture.LoadFromFile('luc_Ru.png');
               izPloscicaObstojeceZavarovanje_Semafor4.Visible := true;
             end
             else begin
               izPloscicaObstojeceZavarovanje_Semafor4.Picture.LoadFromFile('luc_Rd.png');
               izPloscicaObstojeceZavarovanje_Semafor4.Visible := true; // SPREMENJENO !!!
             end;
             // Izbere in izriše ustrezno luč na semaforju za MDJ (posebna za kosmati dobiček - brez prodajnega efekta)
           end;
           if ((vpZavarovanjeDa.Checked = true) and (vpZavarovanjeDANastetiStroski.Checked = true)) then begin
              if TabelaRezultatov[16] = 1 then begin
                izPloscicaObstojeceZavarovanje_Semafor3b.Picture.LoadFromFile('luc_Z.png');
                izPloscicaObstojeceZavarovanje_Semafor3b.Visible := true;
              end
              else if TabelaRezultatov[16] = 2 then begin
               izPloscicaObstojeceZavarovanje_Semafor3b.Picture.LoadFromFile('luc_Ru.png');
               izPloscicaObstojeceZavarovanje_Semafor3b.Visible := true;
             end
             else begin
               izPloscicaObstojeceZavarovanje_Semafor3b.Picture.LoadFromFile('luc_Rd.png');
               izPloscicaObstojeceZavarovanje_Semafor3b.Visible := true;
             end;
           end;

           izborAnalize.Visible := true;
           izIzborAnalize.Visible := true;
           izborSimulacijeSkod.Visible := true;
           izIzborSimulacijeSkod.Visible := true;
           izborVhodniPodatki.ItemIndex := -1;
           izborAnalize.ClearSelection;
           izborAnalize.ItemIndex := 0;
           izborSimulacijeSkod.ItemIndex := -1;
           izborVhodniPodatki.ClearSelection;
           izborSimulacijeSkod.ClearSelection;
           ploscicaDelovniListi.TabIndex := izborAnalize.ItemIndex+6;
           ListiProdajnihTrendov.TabIndex := 0;
           fmOsnovniKontroler.Cursor := crDefault;

           finally
             PovezavaWSPopolniPreracun.Connected := false;
             fmOsnovniKontroler.Cursor := crDefault
           end;
         end;

   var
     LTabela : TJSArray;
     TabelaRezultatov : Array of Real;
     i : Integer;
   begin
      LTabela := TJSArray(TJSObject(Response.Result)['value']);
      SetLength(TabelaRezultatov, 2);
      for i := 0 to 1 do
         TabelaRezultatov[i] := Real(LTabela[i]);

      kosmatiDobicek := TabelaRezultatov[0] * 100;

      vpVnosFRPKosmatiDobicek.Caption := FormatFloat('###,###,##0.00', TabelaRezultatov[0]);
      izPriporoceniKD.Caption := FormatFloat('###,###,##0.00', TabelaRezultatov[0]);
      vpVnosFRPStopnjaKosmategaDobicka.Caption := FormatFloat('###,###,##0.00', TabelaRezultatov[1]) + ' %';
      izPriporocenaSKD.Caption := FormatFloat('###,###,##0.00', TabelaRezultatov[1]) + ' %';

      WebClientWSPopolniIzracun.RawInvoke('IIzracuniService.IzracunOsvezitev2', [kosmatiDobicek, rast1, rast2, rast3, rast4, parameter1_1, parameter1_2, parameter2_1, parameter2_2, parameter3_1, parameter3_2, parameter4_1, parameter4_2, parameter5_1, parameter5_2, parameter6_1, parameter7_1, korFaktor1, korFaktor2, korFaktor3, korFaktor4, korFaktor5, maxPribitek, zvObstojeca, korigiranMDJ, letoZV, mdjObstojeca], @OnResultZV);

   end;


   procedure OnResultLetniPrometi( Response: TXDataClientResponse );
   var
     LTabela : TJSArray;
     TabelaRezultatov : Array of Real;
     i : Integer;
   begin
      LTabela := TJSArray(TJSObject(Response.Result)['value']);
      SetLength(TabelaRezultatov, 2);
      for i := 0 to 1 do
         TabelaRezultatov[i] := Real(LTabela[i]);

      vpTabelaPromet_LetoXminus1Skupaj.Caption := FormatFloat('###,###,##0.00', TabelaRezultatov[0]);
      vpTabelaPromet_LetoXSkupaj.Caption := FormatFloat('###,###,##0.00', TabelaRezultatov[1]);
   end;

begin
   WebClientWSPopolniIzracun.RawInvoke('IIzracuniService.IzracunKosmatiDobicek', [promet, zalogeZacetek, ZalogeKonec, internoProizvedeniDodatki, stroskiSurovin, stroskiMateriala, stroskiEnergije, stroskiTrgovskegaBlaga, stroskiStoritev, drugiStroski, prejeteZavarovalnine], @OnResultKosmatiDobicek);
   WebClientWSPopolniIzracun.RawInvoke('IIzracuniService.IzracunLetnihPrometov', [mesecniPrometXminus1_1, mesecniPrometXminus1_2, mesecniPrometXminus1_3, mesecniPrometXminus1_4, mesecniPrometXminus1_5, mesecniPrometXminus1_6, mesecniPrometXminus1_7, mesecniPrometXminus1_8, mesecniPrometXminus1_9, mesecniPrometXminus1_10, mesecniPrometXminus1_11, mesecniPrometXminus1_12, mesecniPrometX_1, mesecniPrometX_2, mesecniPrometX_3, mesecniPrometX_4, mesecniPrometX_5, mesecniPrometX_6, mesecniPrometX_7, mesecniPrometX_8, mesecniPrometX_9, mesecniPrometX_10, mesecniPrometX_11, mesecniPrometX_12], @OnResultLetniPrometi);
end;

procedure TfmOsnovniKontroler.PovezavaWSPopolniPreracunError(
  Error: TXDataWebConnectionError);
begin
  showMessage(LjezikObvestilo_WebServisi);
end;


procedure TfmOsnovniKontroler.slikaJezikANGClick(Sender: TObject);
var jezikZacasni : String;
begin
   jezikZacasni := jezik;
   try
     jezik := 'ENG';
     slikaJezikSLO.Picture.LoadFromFile('ikona_SLO_ne.png');
     slikaJezikAng.Picture.LoadFromFile('ikona_ENG_da.png');

      WebClientJezik.Connection := PovezavaServerJezik;
      fmOsnovniKontroler.Cursor := crHourGlass;
      if PovezavaServerJezik.Connected = true then
        PovezavaServerJezik.Connected := false;
      try
        PovezavaServerJezik.Connected := true;  // Zažene povezavo s serjeverjem. Ko je ta vzpostavljena se zažene procedura OnConnect gradnika PovezavaServer
      except
        showMessage(LjezikObvestilo_ServerNiPovezave_02);
      end;

   except
      jezik := jezikZacasni;
   end;
end;

procedure TfmOsnovniKontroler.slikaJezikSLOClick(Sender: TObject);
var jezikZacasni : String;
begin
   jezikZacasni := jezik;
   try
     jezik := 'SLO';
     slikaJezikSLO.Picture.LoadFromFile('ikona_SLO_da.png');
     slikaJezikAng.Picture.LoadFromFile('ikona_ENG_ne.png');

      WebClientJezik.Connection := PovezavaServerJezik;
      fmOsnovniKontroler.Cursor := crHourGlass;
      if PovezavaServerJezik.Connected = true then
        PovezavaServerJezik.Connected := false;
      try
        PovezavaServerJezik.Connected := true;  // Zažene povezavo s serjeverjem. Ko je ta vzpostavljena se zažene procedura OnConnect gradnika PovezavaServer
      except
        showMessage(LjezikObvestilo_ServerNiPovezave_02);
      end;

   except
     jezik := jezikZacasni;
   end;
end;

procedure TfmOsnovniKontroler.vpTabelaPromet_ProdajaExit(
  Sender: TObject);
var s : String;
    i : Integer;
    vnosnoPolje : TEdit;
begin
  for i:= 1 to 12 do begin
    vnosnoPolje := FindComponent('vpTabelaPromet_ProdajaXminus1_'+IntToStr(i)) as TEdit;
    s := StringReplace(vnosnoPolje.Text, '.', '', [rfReplaceAll, rfIgnoreCase]);
    if vnosnoPolje.Text <> '' then vnosnoPolje.Text := FormatFloat('###,###,##0.00', StrToFloat(s))
      else vnosnoPolje.Text := FormatFloat('###,###,##0.00', StrToFloat('0'));
  end;
  for i:= 1 to 12 do begin
    vnosnoPolje := FindComponent('vpTabelaPromet_ProdajaX_'+IntToStr(i)) as TEdit;
    s := StringReplace(vnosnoPolje.Text, '.', '', [rfReplaceAll, rfIgnoreCase]);
    if vnosnoPolje.Text <> '' then vnosnoPolje.Text := FormatFloat('###,###,##0.00', StrToFloat(s))
      else vnosnoPolje.Text := FormatFloat('###,###,##0.00', StrToFloat('0'));
  end;

  try
    mesecniPrometXminus1_1 := StrToFloat(StringReplace(vpTabelaPromet_ProdajaXminus1_1.Text, '.', '', [rfReplaceAll, rfIgnoreCase])) * 100;
    mesecniPrometXminus1_2 := StrToFloat(StringReplace(vpTabelaPromet_ProdajaXminus1_2.Text, '.', '', [rfReplaceAll, rfIgnoreCase])) * 100;
    mesecniPrometXminus1_3 := StrToFloat(StringReplace(vpTabelaPromet_ProdajaXminus1_3.Text, '.', '', [rfReplaceAll, rfIgnoreCase])) * 100;
    mesecniPrometXminus1_4 := StrToFloat(StringReplace(vpTabelaPromet_ProdajaXminus1_4.Text, '.', '', [rfReplaceAll, rfIgnoreCase])) * 100;
    mesecniPrometXminus1_5 := StrToFloat(StringReplace(vpTabelaPromet_ProdajaXminus1_5.Text, '.', '', [rfReplaceAll, rfIgnoreCase])) * 100;
    mesecniPrometXminus1_6 := StrToFloat(StringReplace(vpTabelaPromet_ProdajaXminus1_6.Text, '.', '', [rfReplaceAll, rfIgnoreCase])) * 100;
    mesecniPrometXminus1_7 := StrToFloat(StringReplace(vpTabelaPromet_ProdajaXminus1_7.Text, '.', '', [rfReplaceAll, rfIgnoreCase])) * 100;
    mesecniPrometXminus1_8 := StrToFloat(StringReplace(vpTabelaPromet_ProdajaXminus1_8.Text, '.', '', [rfReplaceAll, rfIgnoreCase])) * 100;
    mesecniPrometXminus1_9 := StrToFloat(StringReplace(vpTabelaPromet_ProdajaXminus1_9.Text, '.', '', [rfReplaceAll, rfIgnoreCase])) * 100;
    mesecniPrometXminus1_10 := StrToFloat(StringReplace(vpTabelaPromet_ProdajaXminus1_10.Text, '.', '', [rfReplaceAll, rfIgnoreCase])) * 100;
    mesecniPrometXminus1_11 := StrToFloat(StringReplace(vpTabelaPromet_ProdajaXminus1_11.Text, '.', '', [rfReplaceAll, rfIgnoreCase])) * 100;
    mesecniPrometXminus1_12 := StrToFloat(StringReplace(vpTabelaPromet_ProdajaXminus1_12.Text, '.', '', [rfReplaceAll, rfIgnoreCase])) * 100;

    mesecniPrometX_1 := StrToFloat(StringReplace(vpTabelaPromet_ProdajaX_1.Text, '.', '', [rfReplaceAll, rfIgnoreCase])) * 100;
    mesecniPrometX_2 := StrToFloat(StringReplace(vpTabelaPromet_ProdajaX_2.Text, '.', '', [rfReplaceAll, rfIgnoreCase])) * 100;
    mesecniPrometX_3 := StrToFloat(StringReplace(vpTabelaPromet_ProdajaX_3.Text, '.', '', [rfReplaceAll, rfIgnoreCase])) * 100;
    mesecniPrometX_4 := StrToFloat(StringReplace(vpTabelaPromet_ProdajaX_4.Text, '.', '', [rfReplaceAll, rfIgnoreCase])) * 100;
    mesecniPrometX_5 := StrToFloat(StringReplace(vpTabelaPromet_ProdajaX_5.Text, '.', '', [rfReplaceAll, rfIgnoreCase])) * 100;
    mesecniPrometX_6 := StrToFloat(StringReplace(vpTabelaPromet_ProdajaX_6.Text, '.', '', [rfReplaceAll, rfIgnoreCase])) * 100;
    mesecniPrometX_7 := StrToFloat(StringReplace(vpTabelaPromet_ProdajaX_7.Text, '.', '', [rfReplaceAll, rfIgnoreCase])) * 100;
    mesecniPrometX_8 := StrToFloat(StringReplace(vpTabelaPromet_ProdajaX_8.Text, '.', '', [rfReplaceAll, rfIgnoreCase])) * 100;
    mesecniPrometX_9 := StrToFloat(StringReplace(vpTabelaPromet_ProdajaX_9.Text, '.', '', [rfReplaceAll, rfIgnoreCase])) * 100;
    mesecniPrometX_10 := StrToFloat(StringReplace(vpTabelaPromet_ProdajaX_10.Text, '.', '', [rfReplaceAll, rfIgnoreCase])) * 100;
    mesecniPrometX_11 := StrToFloat(StringReplace(vpTabelaPromet_ProdajaX_11.Text, '.', '', [rfReplaceAll, rfIgnoreCase])) * 100;
    mesecniPrometX_12 := StrToFloat(StringReplace(vpTabelaPromet_ProdajaX_12.Text, '.', '', [rfReplaceAll, rfIgnoreCase])) * 100;

    posiljatelj := 4;

    if PovezavaWSDelniIzracuni.Connected = true then
        PovezavaWSDelniIzracuni.Connected := false;
     PovezavaWSDelniIzracuni.Connected := true;
  finally
    fmOsnovniKontroler.PovezavaWSDelniIzracuni.Connected := false;
  end;
end;

procedure TfmOsnovniKontroler.vpDatumFizicneSkodeChange(Sender: TObject);
var zapis  : TEdit;
    zapis2 : TLabel;
    poljeBranje1, poljeBranje2, poljePisanje : TEdit;
    vrstica, stolpec, i, j : Integer;
    mesecSkode, letoPodatkov : Integer;
    mesec, dan : Word;
    tekst, s1, s2 : String;
begin
  letoPodatkov := StrToInt(vpOpazovanoLeto.Text);
  // preveri dan in mesec začetka zavarovanja (tj. zavarovalnega leta)
  dan := DayOf(vpZavarovanjeOd.Date);
  mesec := MonthOf(vpZavarovanjeOd.Date);
  // Če dan začetka zavarovanja ni 1. dan v mesecu, se lahko škoda simulira šele naslednji mesec (1.)
  if dan > 1 then
    mesec := mesec + 1;
  mesecSkode := vpDatumFizicneSkode.ItemIndex + mesec;
  // Vnos letnic v naslovno vrstico tabele
  ploscicaPromet_Naslov2.Caption := IntToStr(letoPodatkov - 1);
  ploscicaPromet_Naslov3.Caption := IntToStr(letoPodatkov );
  ploscicaPromet_Naslov4.Caption := IntToStr(letoPodatkov + 1);
  ploscicaPromet_Naslov5.Caption := IntToStr(letoPodatkov + 2);
  ploscicaPromet_Naslov6.Caption := 'I ' + IntToStr(letoPodatkov) + ' / ' + IntToStr(letoPodatkov - 1);
  ploscicaPromet_Naslov7.Caption := 'I ' + IntToStr(letoPodatkov + 1) + ' / ' + IntToStr(letoPodatkov);
  ploscicaPromet_Naslov8.Caption := 'I ' + IntToStr(letoPodatkov + 2) + ' / ' + IntToStr(letoPodatkov + 1);
  // praznjenje vrednosti v vnosnih poljih
  for i := 1 to 4 do begin
      for j := 1 to 12 do begin
         zapis := FindComponent('ploscicaPromet_Promet_'+IntToStr(i)+'_'+IntToStr(j)) as TEdit;
         zapis.Color := clWhite;
         zapis.Text := '';
      end;
  end;
  for i := 1 to 3 do begin
      for j := 1 to 12 do begin
          zapis := FindComponent('ploscicaPromet_Indeks_'+IntToStr(i)+'_'+IntToStr(j)) as TEdit;
          zapis.Text := '';
      end;
  end;
  // prepis podatkov o prometu za leto X-1 (tj. leto prej)
  for j := 1 to 12 do begin
     zapis2 := FindComponent('ploscicaAnalizeProdaje_izPromet_1_'+IntToStr(j)) as TLabel;
     tekst := zapis2.Caption;
     zapis := FindComponent('ploscicaPromet_Promet_1_'+IntToStr(j)) as TEdit;
     zapis.Text := tekst;
  end;

  // prepis podatkov iz leta X (in iz X+1, če je škoda v novem letu) - do meseca škode
  for j := 1 to mesecSkode-1 do begin
     if j > 12 then begin   // če je j več kot 12, pomeni, da se gledajo podatki v novem letu
        vrstica := j - 12;
        stolpec := 3;
     end
     else begin
        vrstica := j;
        stolpec := 2;
     end;
     zapis2 := FindComponent('ploscicaAnalizeProdaje_izPromet_'+IntToStr(stolpec)+'_'+IntToStr(vrstica)) as TLabel;
     tekst := zapis2.Caption;
     zapis := FindComponent('ploscicaPromet_Promet_'+IntToStr(stolpec)+'_'+IntToStr(vrstica)) as TEdit;
     zapis.Text := tekst;

     // preračuna še indekse za vnešene podatke
     poljePisanje := FindComponent('ploscicaPromet_Indeks_'+IntToStr(stolpec-1)+'_'+IntToStr(vrstica)) as TEdit;
     poljeBranje2 := FindComponent('ploscicaPromet_Promet_'+IntToStr(stolpec)+'_'+IntToStr(vrstica)) as TEdit;
     poljeBranje1 := FindComponent('ploscicaPromet_Promet_'+IntToStr(stolpec-1)+'_'+IntToStr(vrstica)) as TEdit;
     s1 := Stringreplace(poljeBranje1.Text, '.', '', [rfreplaceAll, rfIgnorecase]);
     s2 := Stringreplace(poljeBranje2.Text, '.', '', [rfreplaceAll, rfIgnorecase]);
     poljePisanje.Text := FormatFloat('###,###,##0.00', StrToFloat(s2) / StrToFloat(s1) * 100);
  end;
  DelovniListiPodatkiZaSimulacijoSkod.TabIndex := 0;
  vpGrafikaSkode.Position := 0;
  ploscicaGrafSimulacijaSkode.Visible := false;

end;

procedure TfmOsnovniKontroler.vpKorekcijskiFaktorjiClick(Sender: TObject);
begin
  if vpKorekcijskiFaktorji.Checked then begin
    ploscicaMaksimalniPribitek.visible := true;
    ploscicaKorekcijskiFaktorji.visible := true;
  end
  else begin
    ploscicaMaksimalniPribitek.visible := false;
    ploscicaKorekcijskiFaktorji.visible := false;
  end;
  try
    parameter1_1 := vpParameter1_1.Position / 4 * 100;
    parameter1_2 := vpParameter1_2.Position / 4 * 100;
    parameter2_1 := vpParameter2_1.Position / 4 * 100;
    parameter2_2 := vpParameter2_2.Position / 4 * 100;
    parameter3_1 := vpParameter3_1.Position / 4 * 100;
    parameter3_2 := vpParameter3_2.Position / 4 * 100;
    parameter4_1 := vpParameter4_1.Position / 4 * 100;
    parameter4_2 := vpParameter4_2.Position / 4 * 100;
    parameter5_1 := vpParameter5_1.Position / 4 * 100;
    parameter5_2 := vpParameter5_2.Position / 4 * 100;
    parameter6_1 := vpParameter6_1.Position / 4 * 100;
    parameter7_1 := vpParameter7_1.Position / 4 * 100;
    korFaktor1 := vpPloscicaKorekcijskiFaktorji_KFDrsnik1_4.Position / 4 * 100;
    korFaktor2 := vpPloscicaKorekcijskiFaktorji_KFDrsnik2_4.Position / 4 * 100;
    korFaktor3 := vpPloscicaKorekcijskiFaktorji_KFDrsnik3_4.Position / 4 * 100;
    korFaktor4 := vpPloscicaKorekcijskiFaktorji_KFDrsnik4_4.Position / 4 * 100;
    korFaktor5 := vpPloscicaKorekcijskiFaktorji_KFDrsnik5_4.Position / 4 * 100;
    maxPribitek := vpMaksimalniPribitek.Position;

    if vpKorekcijskiFaktorji.Checked = true then
      korigiranMDJ := 1
    else
      korigiranMDJ := 0;

    posiljatelj := 2;

    if PovezavaWSDelniIzracuni.Connected = true then
        PovezavaWSDelniIzracuni.Connected := false;
     PovezavaWSDelniIzracuni.Connected := true;
  finally
    fmOsnovniKontroler.PovezavaWSDelniIzracuni.Connected := false;
  end;

end;

procedure TfmOsnovniKontroler.vpLetoPriporoceneZVChange(Sender: TObject);
begin
  try
    fmOsnovniKontroler.Cursor := crHourGlass;
    kosmatiDobicek := StrToFloat(StringReplace(izPriporoceniKD.Caption, '.', '', [rfReplaceAll, rfIgnoreCase])) * 100;
    rast1 := vpPlanProdaje_RastXplus1.Position /10 * 100;
    rast2 := vpPlanProdaje_RastXplus2.Position /10 * 100;
    rast3 := vpPlanProdaje_RastXplus3.Position /10 * 100;
    rast4 := vpPlanProdaje_RastXplus4.Position /10 * 100;

    if vpLetoPriporoceneZV.Position = vpLetoPriporoceneZV.Min then begin
      izLetoPriporoceneZVmin.Font.Style := [fsBold];
      izLetoPriporoceneZVmax.Font.Style := [];
      letoZV := 1;
    end
    else if vpLetoPriporoceneZV.Position = vpLetoPriporoceneZV.Max then begin
      izLetoPriporoceneZVmin.Font.Style := [];
      izLetoPriporoceneZVmax.Font.Style := [fsBold];
      letoZV := 2;
    end;

    posiljatelj := 1;

    if PovezavaWSDelniIzracuni.Connected = true then
          PovezavaWSDelniIzracuni.Connected := false;
    PovezavaWSDelniIzracuni.Connected := true;
  finally
    fmOsnovniKontroler.PovezavaWSDelniIzracuni.Connected := false;
  end;
end;

procedure TfmOsnovniKontroler.vpMaksimalniPribitekChange(Sender: TObject);
begin
  izMaksimalniPribitek.Caption := FormatFloat('###,###,##0', (Sender as TTrackBar).Position) + ' %';

  try
    parameter1_1 := vpParameter1_1.Position / 4 * 100;
    parameter1_2 := vpParameter1_2.Position / 4 * 100;
    parameter2_1 := vpParameter2_1.Position / 4 * 100;
    parameter2_2 := vpParameter2_2.Position / 4 * 100;
    parameter3_1 := vpParameter3_1.Position / 4 * 100;
    parameter3_2 := vpParameter3_2.Position / 4 * 100;
    parameter4_1 := vpParameter4_1.Position / 4 * 100;
    parameter4_2 := vpParameter4_2.Position / 4 * 100;
    parameter5_1 := vpParameter5_1.Position / 4 * 100;
    parameter5_2 := vpParameter5_2.Position / 4 * 100;
    parameter6_1 := vpParameter6_1.Position / 4 * 100;
    parameter7_1 := vpParameter7_1.Position / 4 * 100;
    korFaktor1 := vpPloscicaKorekcijskiFaktorji_KFDrsnik1_4.Position / 4 * 100;
    korFaktor2 := vpPloscicaKorekcijskiFaktorji_KFDrsnik2_4.Position / 4 * 100;
    korFaktor3 := vpPloscicaKorekcijskiFaktorji_KFDrsnik3_4.Position / 4 * 100;
    korFaktor4 := vpPloscicaKorekcijskiFaktorji_KFDrsnik4_4.Position / 4 * 100;
    korFaktor5 := vpPloscicaKorekcijskiFaktorji_KFDrsnik5_4.Position / 4 * 100;
    maxPribitek := vpMaksimalniPribitek.Position;

    if vpKorekcijskiFaktorji.Checked = true then
      korigiranMDJ := 1
    else
      korigiranMDJ := 0;

    posiljatelj := 2;

    if PovezavaWSDelniIzracuni.Connected = true then
        PovezavaWSDelniIzracuni.Connected := false;
     PovezavaWSDelniIzracuni.Connected := true;
  finally
    fmOsnovniKontroler.PovezavaWSDelniIzracuni.Connected := false;
  end;

end;

procedure TfmOsnovniKontroler.vpOpazovanoLeto_staraChange(Sender: TObject);
var zapis : TLabel;
    i : Integer;
begin
  vpTabelaPromet_LetoXminus1.Caption := IntToStr(StrToINt(vpOpazovanoLeto.Text) - 1);
  vpTabelaPromet_LetoX.Caption := vpOpazovanoLeto.Text;
  for i := 1 to 4 do begin
    zapis := FindComponent('izPlanProdaje_letoXplus'+IntToStr(i)) as TLabel;
    zapis.Caption := LjezikLeto + ' ' + IntToStr(StrToInt(vpOpazovanoLeto.Text) + i) +':';
  end;
end;

procedure TfmOsnovniKontroler.vpOrganizacija_Odgovor10Change(Sender: TObject);
begin
  izOrganizacija_mera10.Caption := FormatFloat('###,###,##0', (Sender as TTrackBar).Position)+' %';
end;

procedure TfmOsnovniKontroler.vpOrganizacija_Odgovor11DelezZalogChange(Sender: TObject);
var stevec : String;
    izpisVrednosti : TLabel;
begin
  stevec := RightStr((Sender as TTrackBar).Name,4);
  izpisVrednosti := FindComponent('izOrganizacija_mera'+stevec) as TLabel;
  izpisVrednosti.Caption := FormatFloat('###,###,##0', (Sender as TTrackBar).Position)+' %';
end;

procedure TfmOsnovniKontroler.vpOrganizacija_Odgovor11KolicinaZalogChange(Sender: TObject);
var stevec : String;
    izpisVrednosti : TLabel;
begin
  stevec := RightStr((Sender as TTrackBar).Name,4);
  izpisVrednosti := FindComponent('izOrganizacija_mera'+stevec) as TLabel;
  izpisVrednosti.Caption := FormatFloat('###,###,##0', (Sender as TTrackBar).Position / 2)+' ' + LjezikMera_Mesec;
end;

procedure TfmOsnovniKontroler.vpOrganizacija_Odgovor12Change(Sender: TObject);
begin
  izOrganizacija_Mera12.Caption := FormatFloat('###,###,##0', (Sender as TTrackBar).Position / 2)+' ' + LjezikMera_Mesec;
end;

procedure TfmOsnovniKontroler.vpOrganizacija_Odgovor3Click(Sender: TObject);
var i : Integer;
    odgovor, kontraOdgovor : String;
    odgovorPolje : TRadioButton;
begin
  i := StrToInt(RightStr((Sender as TRadioButton).Name,1));
  odgovor := LeftStr(RightStr((Sender as TRadioButton).Name,3),2);

  if odgovor = 'Da' then
    kontraOdgovor := 'Ne'
  else
    kontraOdgovor := 'Da';

  odgovorPolje := FindComponent('vpOrganizacija_Odgovor'+kontraOdgovor+IntToStr(i)) as TRadioButton;

  if (Sender as TRadioButton).Checked = true then
    odgovorPolje.Checked := false
  else
    odgovorPolje.Checked := true;
end;

procedure TfmOsnovniKontroler.vpOrganizacija_Odgovor9Change(Sender: TObject);
begin
  izOrganizacija_mera9.Caption := FormatFloat('###,###,##0', (Sender as TTrackBar).Position)+' ' + LjezikMera_Izmena;
end;

procedure TfmOsnovniKontroler.vpOrganizacija_Odgovor11IzborClick(
  Sender: TObject);
var stevec : String;  // Spremenljivka za preverjanje, katera vrstica / vprašanje je opazovana(o)
    odgovor, kontraOdgovor : String;   // Dobi vrednosti "Da" in "Ne"
    odgovorPolje : TRadioButton;
begin
  stevec := RightStr((Sender as TRadioButton).Name,4);    //Prebere zadnja 4 mesta imena (11_x)
  odgovor := LeftStr(RightStr((Sender as TRadioButton).Name,6),2);  // Najprej prebere zadnjih 6 mest imena, nato pa prvi dve mesti - dejansko prebere 5 in 6 mesto od zadaj (kjer je zapis "Da" ali "Ne")

  if odgovor = 'Da' then    // Odgovor in kontraOdgovor morata imeti nasprotne vrednosti (če je sender z "Da", potem mora biti kontra pokje z "Ne"
    kontraOdgovor := 'Ne'
  else
    kontraOdgovor := 'Da';

  odgovorPolje := FindComponent('vpOrganizacija_Odgovor'+kontraOdgovor+stevec) as TRadioButton;

  if (Sender as TRadioButton).Checked = true then
    odgovorPolje.Checked := false
  else
    odgovorPOlje.Checked := true;
end;

procedure TfmOsnovniKontroler.vpOrganizacija_OdgovorMontazaChange(Sender: TObject);
var i : Integer;   // Spremenljivka za preverjanje, katera vrstica / vprašanje je opazovana(o)
    izpisVrednosti : TLabel;
begin
  i := StrToInt(RightStr((Sender as TTrackBar).Name,1));  // Prebere zadnji znak imena
  izpisVrednosti := FindComponent('izOrganizacija_mera'+IntToStr(i)) as TLabel;
  izpisVrednosti.Caption := FormatFloat('###,###,##0.0', (Sender as TTrackBar).Position / 2)+' ' + LjezikMera_Mesec;
end;

procedure TfmOsnovniKontroler.vpParameter1_1Change(Sender: TObject);
var stevec : String;   // Spremenljivka za preverjanje, katera vrstica / vprašanje je opazovana(o)
    izpisVrednosti : TLabel;

begin
  stevec := RightStr((Sender as TTrackBar).Name,3);  // Prebere zadnje 3 znake imena
  izpisVrednosti := FindComponent('izParameter'+stevec) as TLabel;
  izpisVrednosti.Caption := FormatFloat('###,###,##0.00', (Sender as TTrackBar).Position / 4);

  try
    parameter1_1 := vpParameter1_1.Position / 4 * 100;
    parameter1_2 := vpParameter1_2.Position / 4 * 100;
    parameter2_1 := vpParameter2_1.Position / 4 * 100;
    parameter2_2 := vpParameter2_2.Position / 4 * 100;
    parameter3_1 := vpParameter3_1.Position / 4 * 100;
    parameter3_2 := vpParameter3_2.Position / 4 * 100;
    parameter4_1 := vpParameter4_1.Position / 4 * 100;
    parameter4_2 := vpParameter4_2.Position / 4 * 100;
    parameter5_1 := vpParameter5_1.Position / 4 * 100;
    parameter5_2 := vpParameter5_2.Position / 4 * 100;
    parameter6_1 := vpParameter6_1.Position / 4 * 100;
    parameter7_1 := vpParameter7_1.Position / 4 * 100;
    korFaktor1 := vpPloscicaKorekcijskiFaktorji_KFDrsnik1_4.Position / 4 * 100;
    korFaktor2 := vpPloscicaKorekcijskiFaktorji_KFDrsnik2_4.Position / 4 * 100;
    korFaktor3 := vpPloscicaKorekcijskiFaktorji_KFDrsnik3_4.Position / 4 * 100;
    korFaktor4 := vpPloscicaKorekcijskiFaktorji_KFDrsnik4_4.Position / 4 * 100;
    korFaktor5 := vpPloscicaKorekcijskiFaktorji_KFDrsnik5_4.Position / 4 * 100;
    maxPribitek := vpMaksimalniPribitek.Position;

    if vpKorekcijskiFaktorji.Checked = true then
      korigiranMDJ := 1
    else
      korigiranMDJ := 0;

    posiljatelj := 2;

    if PovezavaWSDelniIzracuni.Connected = true then
          PovezavaWSDelniIzracuni.Connected := false;
    PovezavaWSDelniIzracuni.Connected := true;
  finally
    fmOsnovniKontroler.PovezavaWSDelniIzracuni.Connected := false;
  end;

end;

procedure TfmOsnovniKontroler.vpPlanProdaje_RastChange(Sender: TObject);
var i : Integer;  // Spremenljivka za preverjanje, katera vrstica / vprašanje je opazovana(o)
    izpisVrednosti : TLabel;
begin
  i := StrToInt(RightStr((Sender as TTrackBar).Name,1));   // Prebere zadnji znak imena
  izpisVrednosti := FindComponent('izPlanProdaje_RastXplus'+IntToStr(i)) as TLabel;
  izpisVrednosti.Caption := FormatFloat('###,###,##0.00 %', (Sender as TTrackBar).Position / 10);
end;

procedure TfmOsnovniKontroler.vpPloscicaKorekcijskiFaktorji_KFDrsnik(
  Sender: TObject);
var stevec : String;   // Spremenljivka za preverjanje, katera vrstica / vprašanje je opazovana(o)
    izpisVrednosti : TLabel;
begin
  stevec := RightStr((Sender as TTrackBar).Name,3);  // Prebere zadnje 3 znake imena
  izpisVrednosti := FindComponent('vpPloscicaKorekcijskiFaktorji_KFIzpis'+stevec) as TLabel;
  izpisVrednosti.Caption := FormatFloat('###,###,##0.00', (Sender as TTrackBar).Position / 4);

  try
    parameter1_1 := vpParameter1_1.Position / 4 * 100;
    parameter1_2 := vpParameter1_2.Position / 4 * 100;
    parameter2_1 := vpParameter2_1.Position / 4 * 100;
    parameter2_2 := vpParameter2_2.Position / 4 * 100;
    parameter3_1 := vpParameter3_1.Position / 4 * 100;
    parameter3_2 := vpParameter3_2.Position / 4 * 100;
    parameter4_1 := vpParameter4_1.Position / 4 * 100;
    parameter4_2 := vpParameter4_2.Position / 4 * 100;
    parameter5_1 := vpParameter5_1.Position / 4 * 100;
    parameter5_2 := vpParameter5_2.Position / 4 * 100;
    parameter6_1 := vpParameter6_1.Position / 4 * 100;
    parameter7_1 := vpParameter7_1.Position / 4 * 100;
    korFaktor1 := vpPloscicaKorekcijskiFaktorji_KFDrsnik1_4.Position / 4 * 100;
    korFaktor2 := vpPloscicaKorekcijskiFaktorji_KFDrsnik2_4.Position / 4 * 100;
    korFaktor3 := vpPloscicaKorekcijskiFaktorji_KFDrsnik3_4.Position / 4 * 100;
    korFaktor4 := vpPloscicaKorekcijskiFaktorji_KFDrsnik4_4.Position / 4 * 100;
    korFaktor5 := vpPloscicaKorekcijskiFaktorji_KFDrsnik5_4.Position / 4 * 100;
    maxPribitek := vpMaksimalniPribitek.Position;

    if vpKorekcijskiFaktorji.Checked = true then
      korigiranMDJ := 1
    else
      korigiranMDJ := 0;

    posiljatelj := 2;

    if PovezavaWSDelniIzracuni.Connected = true then
          PovezavaWSDelniIzracuni.Connected := false;
    PovezavaWSDelniIzracuni.Connected := true;
  finally
    fmOsnovniKontroler.PovezavaWSDelniIzracuni.Connected := false;
  end;

end;

procedure TfmOsnovniKontroler.OblikovanjeStevilkeExit(
  Sender: TObject);
var s : String;
begin
  s := StringReplace((Sender as TEdit).Text, '.', '', [rfReplaceAll, rfIgnoreCase]);
  if (Sender as TEdit).Text <> '' then (Sender as TEdit).Text := FormatFloat('###,###,##0.00', StrToFloat(s));
end;

procedure TfmOsnovniKontroler.obvestiloButtonClick(Sender: TObject);
begin
  if obvestilo.DialogResult = 6 then begin
    case posiljateljObvestilo of
      1 : gZacniNovProjektClick(sender);
      2 : gNaloziProjektClick(Sender);
    end;
  end;
end;

procedure TfmOsnovniKontroler.mnOOrodjuClick(Sender: TObject);
var novoOkno : TfmVizitka;


  procedure ObOdprtjuShowModal(AForm: TObject);  // Procedura, ki se zažene ob zagonu okna / forme fmPogovornoOkno_OsveževanjeAnaliz
  begin
    With novoOkno do begin
      izNaslov.Caption := LfmVizitka_izNaslov;
      izFirma.Caption := LfmVizitka_izFirma;
      izElektronskaPosta.Caption := LfmVizitka_izElektronskaPosta;
      izSpletnaStran.Caption := LfmVizitka_izSpletnaStran;
      izSubvencija.Caption := LfmVizitka_izSubvencija;
    end;
  end;


begin

     novoOkno := TfmVizitka.CreateNew(@ObOdprtjuShowModal);
     novoOkno.Popup := true;
     novoOkno.Show;
end;

procedure TfmOsnovniKontroler.OorodjuClick(Sender: TObject);
var novoOkno : TfmVizitka;


  procedure ObOdprtjuShowModal(AForm: TObject);  // Procedura, ki se zažene ob zagonu okna / forme fmPogovornoOkno_OsveževanjeAnaliz
  begin
    With novoOkno do begin
      izNaslov.Caption := LfmVizitka_izNaslov;
      izFirma.Caption := LfmVizitka_izFirma;
      izElektronskaPosta.Caption := LfmVizitka_izElektronskaPosta;
      izSpletnaStran.Caption := LfmVizitka_izSpletnaStran;
      izSubvencija.Caption := LfmVizitka_izSubvencija;
    end;
  end;


begin

     novoOkno := TfmVizitka.CreateNew(@ObOdprtjuShowModal);
     novoOkno.Popup := true;
     novoOkno.Show;

end;

procedure TfmOsnovniKontroler.vpVnosFRPExit(Sender: TObject);
var s : String;
begin
  s := StringReplace((Sender as TEdit).Text, '.', '', [rfReplaceAll, rfIgnoreCase]);
  if (Sender as TEdit).Text <> '' then (Sender as TEdit).Text := FormatFloat('###,###,##0.00', StrToFloat(s))
    else (Sender as TEdit).Text := FormatFloat('###,###,##0.00', StrToFloat('0'));

  try
     promet := StrToFloat(StringReplace(vpVnosFRPPromet.Text, '.', '', [rfReplaceAll, rfIgnoreCase])) * 100;
     zalogeZacetek := StrToFloat(StringReplace(vpVnosFRPZalogeZacetek.Text, '.', '', [rfReplaceAll, rfIgnoreCase])) * 100;
     zalogeKonec := StrToFloat(StringReplace(vpVnosFRPZalogeKonec.Text, '.', '', [rfReplaceAll, rfIgnoreCase])) * 100;
     internoProizvedeniDodatki := StrToFloat(StringReplace(vpVnosFRPInternoProizvedeniDodatki.Text, '.', '', [rfReplaceAll, rfIgnoreCase])) * 100;
     stroskiSurovin := StrToFloat(StringReplace(vpVnosFRPStroskiSurovine.Text, '.', '', [rfReplaceAll, rfIgnoreCase])) * 100;
     stroskiMateriala := StrToFloat(StringReplace(vpVnosFRPStroskiMaterial.Text, '.', '', [rfReplaceAll, rfIgnoreCase])) * 100;
     stroskiEnergije := StrToFloat(StringReplace(vpVnosFRPStroskiEnergija.Text, '.', '', [rfReplaceAll, rfIgnoreCase])) * 100;
     stroskiTrgovskegaBlaga :=StrToFloat(StringReplace(vpVnosFRPStroskiTrgovskoBlago.Text, '.', '', [rfReplaceAll, rfIgnoreCase])) * 100;
     stroskiStoritev := StrToFloat(StringReplace(vpVnosFRPStroskiStoritve.Text, '.', '', [rfReplaceAll, rfIgnoreCase])) * 100;
     drugiStroski := StrToFloat(StringReplace(vpVnosFRPDrugiStroski.Text, '.', '', [rfReplaceAll, rfIgnoreCase])) * 100;
     prejeteZavarovalnine := StrToFloat(StringReplace(vpVnosFRPPrejeteZavarovalnine.Text, '.', '', [rfReplaceAll, rfIgnoreCase])) * 100;

     posiljatelj := 3;

    if PovezavaWSDelniIzracuni.Connected = true then
          PovezavaWSDelniIzracuni.Connected := false;
    PovezavaWSDelniIzracuni.Connected := true;
  finally
    fmOsnovniKontroler.PovezavaWSDelniIzracuni.Connected := false;
  end;

end;

procedure TfmOsnovniKontroler.vpVnosFRPKeyPress(Sender: TObject;
  var Key: Char);
begin
  // Omogoči zgolj vnos številk in decimalne vejice
  if not (Key in ['0'..'9', ',', #8, #9, #13]) then Key := #0
    else if (Key = ',') and (Pos(Key, (Sender as TEdit).Text) > 0) then begin   // Dovoli vnos decimalne vejice samo enkrat
       Key := #0
    end
    else if Key = #13 then begin         // Če je v polju pritisnjena tipka <Enter> požene OnExit proceduro (za izračun)
      try
        vpTabelaPromet_ProdajaExit(Sender);    // požene proceduro za list 3 - stolpec ProdajaX in ProdajaXminus1
      except
      end;
      try
        vpVnosFRPExit(Sender);    // požene proceduro za List 2
      except
      end;
      try
        vpZVKosmatiDobicekExit(Sender);  // požene prceduro za List 5 - ZV za metodo kosmatega dobička
      except
      end;
      try
        vpZavarovaniStroskiSestevekExit(Sender);  // požene proceduro za List 5 - ZV za metodo naštevanja
      except
      end;
      try
        OblikovanjeStevilkeExit(Sender);   // požene proceduro na Listu 10 - povišani stroški, tabela prometov
      except
      end;
      try
        ploscicaPrihranki_PrihranekExit(Sender);   // požene proceduro na Listu 10 - prihranki
      except
      end;
    end;
end;

procedure TfmOsnovniKontroler.vpZacetekZavarovanjaChange(Sender: TObject);
var datumMin, datumMax : TDateTime;
begin

 datumMin := encodeDate(StrToInt(vpOpazovanoLeto.Text), 01, 01);
 datumMax := encodedate(StrToInt(vpOpazovanoLeto.Text), 12, 31);

 if vpZacetekZavarovanja.Date < datumMin then
    vpZacetekZavarovanja.date := datumMin
 else if vpZacetekZavarovanja.Date > datumMax then
    vpZacetekZavarovanja.date := datumMax;
end;

procedure TfmOsnovniKontroler.vpZavarovaniStroskiSestevekExit(Sender: TObject);
var i : Integer;
    s : String;
    zavarovalnaVsota : Real;
    vnosnoPolje : TEdit;
begin
    zavarovalnaVsota := 0;
    for i:= 2 to 6 do begin
      vnosnoPolje := FindComponent('vpZavarovaniStroski2_'+IntToStr(i)) as TEdit;
      s := StringReplace(vnosnoPolje.Text, '.', '', [rfReplaceAll, rfIgnoreCase]);
      if vnosnoPolje.Text <> '' then vnosnoPolje.Text := FormatFloat('###,###,##0.00', StrToFloat(s))
         else vnosnoPolje.Text := FormatFloat('###,###,##0.00', StrToFloat('0'));
    s := StringReplace(vnosnoPolje.Text, '.', '', [rfReplaceAll, rfIgnoreCase]);
    zavarovalnaVsota := zavarovalnaVsota + StrToFloat(s);
    end;
    vpZVNastetiStroski.Text := FormatFloat('###,###,##0.00', zavarovalnaVsota);
end;

procedure TfmOsnovniKontroler.vpZavarovanjeDaClick(Sender: TObject);
begin
  if vpZavarovanjeDa.Checked = true Then
    ploscicaPodatkiOZavarovanju.Visible := true
  else
    ploscicaPodatkiOZavarovanju.Visible := false;
end;

procedure TfmOsnovniKontroler.vpZavarovanjeDAKosmatiDobicekClick(
  Sender: TObject);
var i : Integer;
    vnosnoPolje : TEdit;
begin
  if vpZavarovanjeDAKosmatiDobicek.Checked = true then begin
     vpZVKosmatiDobicek.Text := '0,00';
     vpZVKosmatiDobicek.Enabled := true;
     vpZavarovanjeDANastetiStroski.Checked := false;
     for i := 2 to 6 do begin
        vnosnoPolje := FindComponent('vpZavarovaniStroski2_'+IntToStr(i)) as TEdit;
        vnosnoPolje.Text := '0,00';
        vnosnoPolje.Enabled := false;
     end;
  end
  else begin
    vpZVKosmatiDobicek.Enabled := false;
    vpZVKosmatiDobicek.Text := '0,00';
  end;
end;

procedure TfmOsnovniKontroler.vpZavarovanjeDANastetiStroskiClick(
  Sender: TObject);
var i : Integer;
    vnosnoPolje : TEdit;
begin
  if vpZavarovanjeDANastetiStroski.Checked = true then begin
     vpZVKosmatiDobicek.Enabled := false;
     vpZVKosmatiDobicek.Text := '0,00';
     vpZavarovanjeDAKosmatiDobicek.Checked := false;
     for i := 2 to 6 do begin
        vnosnoPolje := FindComponent('vpZavarovaniStroski2_'+IntToStr(i)) as TEdit;
        vnosnoPolje.Text := '0,00';
        vnosnoPolje.Enabled := true;
     end;
  end
  else begin
    for i := 2 to 6 do begin
       vnosnoPolje := FindComponent('vpZavarovaniStroski2_'+IntToStr(i)) as TEdit;
       vnosnoPolje.text := '0,00';
       vnosnoPolje.Enabled := false;
    end;
  end;
end;

procedure TfmOsnovniKontroler.vpZavarovanjeOdChange(Sender: TObject);
var i, j, mesecSkode, letoPodatkov : Integer;
    dan, mesec, leto, mesecDanes : Word;
    tekst : String;
    zapis  : TEdit;
    zapis2 : TLabel;
    vrstica, stolpec : Integer;
begin

  if izborSimulacijeSkod.ItemIndex = 1 then begin

     if vpZavarovanjeOd.Date < EncodeDate(StrToInt(vpOpazovanoLeto.Text), 1, 1) then
       vpZavarovanjeOd.Date := EncodeDate(StrToInt(vpOpazovanoLeto.Text), 1, 1)
     else if vpZavarovanjeOd.Date > EncodeDate(StrToInt(vpOpazovanoLeto.Text), 12, 31) then
        vpZavarovanjeOd.Date := EncodeDate(StrToInt(vpOpazovanoLeto.Text), 12, 31);

     vpZavarovanjeDo.Date := vpzavarovanjeOd.date + 365;

     letoPodatkov := StrToInt(vpOpazovanoLeto.Text);
      // preveri dan in mesec začetka zavarovanja (tj. zavarovalnega leta)
      dan := DayOf(vpZavarovanjeOd.Date);
      mesec := MonthOf(vpZavarovanjeOd.Date);
      leto := YearOf(vpZavarovanjeOd.Date);
      // Če dan začetka zavarovanja ni 1. dan v mesecu, se lahko škoda simulira šele naslednji mesec (1.)
      if dan > 1 then
        mesec := mesec + 1;
      // polnjenje seznama datumov začetka škode
      vpDatumFizicneSkode.Items.Clear;
      for i := mesec to (mesec+11) do begin
        if i > 12 then   // prehod v novo leto
         vpDatumFizicneSkode.Items.Add('1. ' + LjezikImeMeseca[i-12] + ' ' + IntToStr(leto + 1)) // naslednje leto od leta, v katerem je začetek zavarovanja
        else
         vpDatumFizicneSkode.Items.Add('1. ' + LjezikImeMeseca[i] + ' ' + IntToStr(leto));  // leto, v katerem je začetek zavarovanja
      end;
      // postavitev privzete vrednosti v izboru datuma škode na tekoči mesec
      mesecDanes := MonthOf(Today);
      if ((mesecDanes - mesec) = -1) then    // izjema, ki se zgodi, ko je zavarovanje sklenjeno v mesecu (a ne 1. v tem mesecu), ki je enak sistemskemu mesecu
          vpDatumFizicneSkode.ItemIndex := 0
      else if (mesecDanes >= mesec) then
         vpDatumFizicneSkode.ItemIndex := mesecDanes - mesec
      else
         vpDatumFizicneSkode.ItemIndex := 12 - mesec + mesecDanes;  // upošteva, da gre za naslednje leto
      mesecSkode := vpDatumFizicneSkode.ItemIndex + mesec;
      // Vnos letnic v naslovno vrstico tabele
      ploscicaPromet_Naslov2.Caption := IntToStr(letoPodatkov - 1);
      ploscicaPromet_Naslov3.Caption := IntToStr(letoPodatkov );
      ploscicaPromet_Naslov4.Caption := IntToStr(letoPodatkov + 1);
      ploscicaPromet_Naslov5.Caption := IntToStr(letoPodatkov + 2);
      ploscicaPromet_Naslov6.Caption := 'I ' + IntToStr(letoPodatkov) + ' / ' + IntToStr(letoPodatkov - 1);
      ploscicaPromet_Naslov7.Caption := 'I ' + IntToStr(letoPodatkov + 1) + ' / ' + IntToStr(letoPodatkov);
      ploscicaPromet_Naslov8.Caption := 'I ' + IntToStr(letoPodatkov + 2) + ' / ' + IntToStr(letoPodatkov + 1);
      // praznjenje vrednosti v vnosnih poljih
      for i := 1 to 4 do begin
          for j := 1 to 12 do begin
              zapis := FindComponent('ploscicaPromet_Promet_'+IntToStr(i)+'_'+IntToStr(j)) as TEdit;
              zapis.Text := '';
          end;
      end;
      for i := 1 to 3 do begin
          for j := 1 to 12 do begin
              zapis := FindComponent('ploscicaPromet_Indeks_'+IntToStr(i)+'_'+IntToStr(j)) as TEdit;
              zapis.Text := '';
          end;
      end;
      // prepis podatkov o prometu za leto X-1
      for j := 1 to 12 do begin
          zapis2 := FindComponent('ploscicaAnalizeProdaje_izPromet_1_'+IntToStr(j)) as TLabel;
          tekst := zapis2.Caption;
          zapis := FindComponent('ploscicaPromet_Promet_1_'+IntToStr(j)) as TEdit;
          zapis.Text := tekst;
      end;
      // prepis podatkov iz leta X (in iz X+1, če je škoda v novem letu) - do meseca škode
      for j := 1 to mesecSkode-1 do begin
          if j > 12 then begin   // če je j več kot 12, pomeni, da se gledajo podatki v novem letu
             vrstica := j - 12;
             stolpec := 3;
          end
          else begin
             vrstica := j;
             stolpec := 2;
          end;
         zapis2 := FindComponent('ploscicaAnalizeProdaje_izPromet_'+IntToStr(stolpec)+'_'+IntToStr(vrstica)) as TLabel;
         tekst := zapis2.Caption;
         zapis := FindComponent('ploscicaPromet_Promet_'+IntToStr(stolpec)+'_'+IntToStr(vrstica)) as TEdit;
         zapis.Text := tekst;
      end;
  end;

end;

procedure TfmOsnovniKontroler.vpZVKosmatiDobicekExit(Sender: TObject);
var s : String;
begin
  s := StringReplace(vpZVKosmatiDobicek.Text, '.', '', [rfReplaceAll, rfIgnoreCase]);
  if vpZVKosmatiDobicek.Text <> '' then vpZVKosmatiDobicek.Text := FormatFloat('###,###,##0.00', StrToFloat(s))
      else vpZVKosmatiDobicek.Text := FormatFloat('###,###,##0.00', StrToFloat('0'));
end;

procedure TfmOsnovniKontroler.WebFormCreate(Sender: TObject);
var
  jsr: TJSDOMRect;
begin
  FormatSettings.DecimalSeparator :=',';
  FormatSettings.ThousandSeparator := '.';
  ploscicaGlava.Hide;
  ploscicaGlavniMeni.Hide;
  ploscicaIzborPogledov.Hide;
  ploscicaDelovniListi.Margins.Top := 3;

  jsr := document.body.getBoundingClientRect;
  ploscicaPrvaStran.Left := Round((jsr.right - jsr.left - ploscicaPrvaStran.Width) / 2);
  ploscicaPrvaStran.Top := Round((window.innerHeight  - ploscicaPrvaStran.Height) / 2);
  jezik := 'SLO';
  slikaJezikSLO.Picture.LoadFromFile('ikona_SLO_da.png');
  slikaJezikAng.Picture.LoadFromFile('ikona_ENG_ne.png');

  WebClientJezik.Connection := PovezavaServerJezik;
  fmOsnovniKontroler.Cursor := crHourGlass;
  if PovezavaServerJezik.Connected = true then
      PovezavaServerJezik.Connected := false;
  try
      PovezavaServerJezik.Connected := true;  // Zažene povezavo s serjeverjem. Ko je ta vzpostavljena se zažene procedura OnConnect gradnika PovezavaServer
  except
      showMessage(LjezikObvestilo_ServerNiPovezave_02);
  end;

end;

procedure TfmOsnovniKontroler.WebFormResize(Sender: TObject);
var
  jsr: TJSDOMRect;
begin
  jsr := document.body.getBoundingClientRect;
  ploscicaPrvaStran.Left := Round((jsr.right - jsr.left - ploscicaPrvaStran.Width) / 2);
  ploscicaPrvaStran.Top := Round((window.innerHeight  - ploscicaPrvaStran.Height) / 2);
end;

procedure TfmOsnovniKontroler.gShraniSkodoClick(Sender: TObject);
var
  i, j, x : Integer;
  vnosnoPolje, vnosnoPolje2 : TEdit;
begin

   // vrdnosti postavi na izhodišča ...

   for x := 1 to 12 do begin
       izpad[x] := 0;
       izpadZnesek[x] := 0;
   end;

   for x := 1 to 10 do begin
       prihranki[x] := 0;
       prihrankiOpis[x] := '';
       prihrankiZnesek[x] := 0;
   end;


   // v tabeli prometov poišče, kateri meseci so označeni kot BI škoda (najde celice, ki so obarvane)
   x := 0;
   for i := 2 to 4 do begin
      for j := 1 to 12 do begin
          vnosnoPolje := FindComponent('ploscicaPromet_Promet_'+IntToStr(i)+'_'+IntToStr(j)) as TEdit;
          if vnosnoPolje.Color = clWebCornSilk then begin
             x := x + 1;
             izpad[x] := 1;
             if vnosnoPolje.Text <> '' then
                izpadZnesek[x] := StrToFloat(vnosnoPolje.Text)
             else
                izpadZnesek[x] := 0;
          end;
      end;
   end;

   // v tabeli prihrankov poišče katera polja so polna
   for i := 1 to 10 do begin
       vnosnoPolje := FindComponent('ploscicaPrihranki_Prihranek_1_'+IntToStr(i)) as TEdit;
       vnosnoPolje2 := FindComponent('ploscicaPrihranki_Prihranek_2_'+IntToStr(i)) as TEdit;

       if ((vnosnoPolje.Text <> '') or (vnosnoPolje2.Text <> '')) then begin
            prihranki[i] := 1;
            prihrankiOpis[i] := vnosnoPolje.Text;
            if vnosnoPolje2.Text <> '' then
               prihrankiZnesek[i] := StrToFloat(vnosnoPolje2.Text)
            else
            prihrankiZnesek[i] := 0;
       end;
   end;

   WebClientSkode.Connection := PovezavaServerSkode;
   fmOsnovniKontroler.Cursor := crHourGlass;
   if PovezavaServerSkode.Connected = true then
       PovezavaServerSkode.Connected := false;
   try
      posiljatelj := 6;
      PovezavaServerSkode.Connected := true;  // Zažene povezavo s serjeverjem. Ko je ta vzpostavljena se zažene procedura OnConnect gradnika PovezavaServer
   except
      posiljatelj := 0;
      showMessage(LjezikObvestilo_ServerNiPovezave_02);
    end;

end;

procedure TfmOsnovniKontroler.mnZakljuciDeloClick(Sender: TObject);
begin
  ploscicaGlava.Hide;
  ploscicaGlavniMeni.Hide;
  ploscicaIzborPogledov.Hide;
  ploscicaDelovniListi.Margins.Top := 3;
  ploscicaDelovniListi.TabIndex := 0;
end;

procedure TfmOsnovniKontroler.vpGrafikaMDJChange(Sender: TObject);
begin

   if vpGrafikaMDJ.Position = 0 then begin
      ploscicaGrafMDJ.Visible := false;
      ploscicaMaksimalniPribitek.Top := ploscicaMaksimalniPribitek.Top - 400;
      ploscicaKorekcijskiFaktorji.Top := ploscicaKorekcijskiFaktorji.Top - 400;
      vpKorekcijskiFaktorji.Top := vpKorekcijskiFaktorji.Top - 400;
   end
   else begin
      ploscicaMaksimalniPribitek.Top := ploscicaMaksimalniPribitek.Top + 400;
      ploscicaKorekcijskiFaktorji.Top := ploscicaKorekcijskiFaktorji.Top + 400;
      vpKorekcijskiFaktorji.Top := vpKorekcijskiFaktorji.Top + 400;

      ploscicaGrafMDJ.Visible := true;

      try
        parameter1_1 := vpParameter1_1.Position / 4 * 100;
        parameter1_2 := vpParameter1_2.Position / 4 * 100;
        parameter2_1 := vpParameter2_1.Position / 4 * 100;
        parameter2_2 := vpParameter2_2.Position / 4 * 100;
        parameter3_1 := vpParameter3_1.Position / 4 * 100;
        parameter3_2 := vpParameter3_2.Position / 4 * 100;
        parameter4_1 := vpParameter4_1.Position / 4 * 100;
        parameter4_2 := vpParameter4_2.Position / 4 * 100;
        parameter5_1 := vpParameter5_1.Position / 4 * 100;
        parameter5_2 := vpParameter5_2.Position / 4 * 100;
        parameter6_1 := vpParameter6_1.Position / 4 * 100;
        parameter7_1 := vpParameter7_1.Position / 4 * 100;
        korFaktor1 := vpPloscicaKorekcijskiFaktorji_KFDrsnik1_4.Position / 4 * 100;
        korFaktor2 := vpPloscicaKorekcijskiFaktorji_KFDrsnik2_4.Position / 4 * 100;
        korFaktor3 := vpPloscicaKorekcijskiFaktorji_KFDrsnik3_4.Position / 4 * 100;
        korFaktor4 := vpPloscicaKorekcijskiFaktorji_KFDrsnik4_4.Position / 4 * 100;
        korFaktor5 := vpPloscicaKorekcijskiFaktorji_KFDrsnik5_4.Position / 4 * 100;
        maxPribitek := vpMaksimalniPribitek.Position;

        if vpKorekcijskiFaktorji.Checked = true then
          korigiranMDJ := 1
        else
          korigiranMDJ := 0;

        posiljatelj := 2;

        if PovezavaWSDelniIzracuni.Connected = true then
              PovezavaWSDelniIzracuni.Connected := false;
        PovezavaWSDelniIzracuni.Connected := true;
      finally
        fmOsnovniKontroler.PovezavaWSDelniIzracuni.Connected := false;
      end;
   end;
end;

procedure TfmOsnovniKontroler.vpGrafikaSkodeChange(Sender: TObject);
var i, j : Integer;
    praznaPolja : Integer;
    poljeBranje : TEdit;

begin
   if vpGrafikaSkode.Position = 0 then begin

     ploscicaGrafSimulacijaSkode.Visible := false;
   end
   else begin

       praznaPolja := 0;
       for j := 1 to 4 do begin
          for i := 1 to 12 do begin
            poljeBranje := FindComponent('ploscicaPromet_Promet_'+IntToStr(j)+'_'+IntToStr(i)) as TEdit;
            if poljeBranje.Text = '' then
                praznaPolja := praznaPolja + 1;
          end;
       end;

       if praznaPolja = 0 then begin

         IzrisGrafaSimulacijeSkod;

         ploscicaGrafSimulacijaSkode.Visible := true;
       end
       else begin
         ShowMessage(LjezikGrafObvestilo);
         vpGrafikaSkode.Position := 0;
       end;
   end;
end;

procedure TfmOsnovniKontroler.LoadDFMValues;
begin
  inherited LoadDFMValues;

  obvestilo := TMessageDlg.Create(Self);
  ploscicaDelovniListi := TPageControl.Create(Self);
  DelovniList0A := TTabSheet.Create(Self);
  WebScrollBoxDL0A := TScrollBox.Create(Self);
  GoogleReCaptcha := TGoogleReCaptcha.Create(Self);
  ploscicaPrvaStran := TPanel.Create(Self);
  slikaJezikANG := TImageControl.Create(Self);
  slikaJezikSLO := TImageControl.Create(Self);
  logo_ModelBI_DL0A := TImageControl.Create(Self);
  slikaBrowser1 := TImageControl.Create(Self);
  slikaBrowser2 := TImageControl.Create(Self);
  slikaBrowser3 := TImageControl.Create(Self);
  slikaBrowser4 := TImageControl.Create(Self);
  izBrskalniki := TLabel.Create(Self);
  ploscicaPrijava := TPanel.Create(Self);
  vpUporabniskoIme_Tekst := TLabel.Create(Self);
  vpGeslo_Tekst := TLabel.Create(Self);
  izPrijavaNaslov := TLabel.Create(Self);
  izUporabniskoImeNapaka := TLabel.Create(Self);
  izGesloNapaka := TLabel.Create(Self);
  izVerzijaPrijava := TLabel.Create(Self);
  vpUporabniskoIme := TEdit.Create(Self);
  vpGeslo := TEdit.Create(Self);
  gPrijava := TSpeedButton.Create(Self);
  ploscicaNaslovDL0A := TPanel.Create(Self);
  izNaslov2_1_DL0A := TLabel.Create(Self);
  izNaslov2_2_DL0A := TLabel.Create(Self);
  izNaslov2_3_DL0A := TLabel.Create(Self);
  izSpletnaStran := TLinkLabel.Create(Self);
  DelovniList1 := TTabSheet.Create(Self);
  WebScrollBoxDL1 := TScrollBox.Create(Self);
  izNaslovDL1 := TLabel.Create(Self);
  ploscicaVnosPodatkovOPodjetju := TGridPanel.Create(Self);
  izImePodjetja := TLabel.Create(Self);
  izDavcnaStevilkaPodjetja := TLabel.Create(Self);
  izDejavnostPodjetja := TLabel.Create(Self);
  izNaslovPodjetja := TLabel.Create(Self);
  vpImePodjetja := TEdit.Create(Self);
  vpDavcnaStevilkaPodjetja := TEdit.Create(Self);
  vpNaslovPodjetja := TEdit.Create(Self);
  vpDejavnostPodjetja := TComboBox.Create(Self);
  DelovniList2 := TTabSheet.Create(Self);
  WebScrollBoxDL2 := TScrollBox.Create(Self);
  izNaslovDL2 := TLabel.Create(Self);
  ploscicaVnosFinancnihPodatkovPodjetja := TGridPanel.Create(Self);
  izVnosFRPLeto := TLabel.Create(Self);
  izVnosFRPPromet := TLabel.Create(Self);
  izVnosFRPZalogeZacetek := TLabel.Create(Self);
  izVnosFRPZalogeKonec := TLabel.Create(Self);
  izVnosFRPInternoProizvedeniDodatki := TLabel.Create(Self);
  izVnosFRPStroskiSurovine := TLabel.Create(Self);
  izVnosFRPStroskiMaterial := TLabel.Create(Self);
  izVnosFRPStroskiEnergija := TLabel.Create(Self);
  izVnosFRPStroskiTrgovskoBlago := TLabel.Create(Self);
  izVnosFRPStroskiStoritve := TLabel.Create(Self);
  izVnosFRPDrugiStroski := TLabel.Create(Self);
  izVnosFRPPrejeteZavarovalnine := TLabel.Create(Self);
  izVnosFRPKosmatiDobicek := TLabel.Create(Self);
  vpVnosFRPKosmatiDobicek := TLabel.Create(Self);
  izVnosFRPStopnjaKosmategaDobicka := TLabel.Create(Self);
  vpVnosFRPStopnjaKosmategaDobicka := TLabel.Create(Self);
  vpOpazovanoLeto := TEdit.Create(Self);
  vpVnosFRPPromet := TEdit.Create(Self);
  vpVnosFRPZalogeZacetek := TEdit.Create(Self);
  vpVnosFRPZalogeKonec := TEdit.Create(Self);
  vpVnosFRPInternoProizvedeniDodatki := TEdit.Create(Self);
  vpVnosFRPStroskiSurovine := TEdit.Create(Self);
  vpVnosFRPStroskiMaterial := TEdit.Create(Self);
  vpVnosFRPStroskiEnergija := TEdit.Create(Self);
  vpVnosFRPStroskiTrgovskoBlago := TEdit.Create(Self);
  vpVnosFRPStroskiStoritve := TEdit.Create(Self);
  vpVnosFRPDrugiStroski := TEdit.Create(Self);
  vpVnosFRPPrejeteZavarovalnine := TEdit.Create(Self);
  DelovniList3 := TTabSheet.Create(Self);
  WebScrollBoxDL3 := TScrollBox.Create(Self);
  izNaslovDL3 := TLabel.Create(Self);
  izPlanProdaje_LetoXplus1 := TLabel.Create(Self);
  izPlanProdaje_LetoXplus2 := TLabel.Create(Self);
  izPlanProdaje_LetoXplus3 := TLabel.Create(Self);
  izPlanProdaje_LetoXplus4 := TLabel.Create(Self);
  izPlanProdaje_Naslov := TLabel.Create(Self);
  izPlanProdaje_RastXplus1 := TLabel.Create(Self);
  izPlanProdaje_RastXplus2 := TLabel.Create(Self);
  izPlanProdaje_RastXplus3 := TLabel.Create(Self);
  izPlanProdaje_RastXplus4 := TLabel.Create(Self);
  ploscicaTabelaPrometaX := TGridPanel.Create(Self);
  vpTabelaPromet_LetoX := TLabel.Create(Self);
  vpTabelaPromet_LetoXSkupaj := TLabel.Create(Self);
  vpTabelaPromet_ProdajaX_1 := TEdit.Create(Self);
  vpTabelaPromet_ProdajaX_2 := TEdit.Create(Self);
  vpTabelaPromet_ProdajaX_3 := TEdit.Create(Self);
  vpTabelaPromet_ProdajaX_4 := TEdit.Create(Self);
  vpTabelaPromet_ProdajaX_5 := TEdit.Create(Self);
  vpTabelaPromet_ProdajaX_6 := TEdit.Create(Self);
  vpTabelaPromet_ProdajaX_7 := TEdit.Create(Self);
  vpTabelaPromet_ProdajaX_8 := TEdit.Create(Self);
  vpTabelaPromet_ProdajaX_9 := TEdit.Create(Self);
  vpTabelaPromet_ProdajaX_10 := TEdit.Create(Self);
  vpTabelaPromet_ProdajaX_11 := TEdit.Create(Self);
  vpTabelaPromet_ProdajaX_12 := TEdit.Create(Self);
  ploscicaTabelaPrometaXminus1 := TGridPanel.Create(Self);
  tabelaPromet_Mesec := TLabel.Create(Self);
  vpTabelaPromet_LetoXminus1 := TLabel.Create(Self);
  tabelaPromet_Mesec1 := TLabel.Create(Self);
  tabelaPromet_Mesec2 := TLabel.Create(Self);
  tabelaPromet_Mesec3 := TLabel.Create(Self);
  tabelaPromet_Mesec4 := TLabel.Create(Self);
  tabelaPromet_Mesec5 := TLabel.Create(Self);
  tabelaPromet_Mesec6 := TLabel.Create(Self);
  tabelaPromet_Mesec7 := TLabel.Create(Self);
  tabelaPromet_Mesec8 := TLabel.Create(Self);
  tabelaPromet_Mesec9 := TLabel.Create(Self);
  tabelaPromet_Mesec10 := TLabel.Create(Self);
  tabelaPromet_Mesec11 := TLabel.Create(Self);
  tabelaPromet_Mesec12 := TLabel.Create(Self);
  tabelaPromet_Skupaj := TLabel.Create(Self);
  vpTabelaPromet_LetoXminus1Skupaj := TLabel.Create(Self);
  vpTabelaPromet_ProdajaXminus1_1 := TEdit.Create(Self);
  vpTabelaPromet_ProdajaXminus1_2 := TEdit.Create(Self);
  vpTabelaPromet_ProdajaXminus1_3 := TEdit.Create(Self);
  vpTabelaPromet_ProdajaXminus1_4 := TEdit.Create(Self);
  vpTabelaPromet_ProdajaXminus1_5 := TEdit.Create(Self);
  vpTabelaPromet_ProdajaXminus1_6 := TEdit.Create(Self);
  vpTabelaPromet_ProdajaXminus1_7 := TEdit.Create(Self);
  vpTabelaPromet_ProdajaXminus1_8 := TEdit.Create(Self);
  vpTabelaPromet_ProdajaXminus1_9 := TEdit.Create(Self);
  vpTabelaPromet_ProdajaXminus1_10 := TEdit.Create(Self);
  vpTabelaPromet_ProdajaXminus1_11 := TEdit.Create(Self);
  vpTabelaPromet_ProdajaXminus1_12 := TEdit.Create(Self);
  vpPlanProdaje_RastXplus1 := TTrackBar.Create(Self);
  vpPlanProdaje_RastXplus2 := TTrackBar.Create(Self);
  vpPlanProdaje_RastXplus3 := TTrackBar.Create(Self);
  vpPlanProdaje_RastXplus4 := TTrackBar.Create(Self);
  DelovniList4 := TTabSheet.Create(Self);
  WebScrollBoxDL4 := TScrollBox.Create(Self);
  izNaslovDL4 := TLabel.Create(Self);
  ploscicaTabelaOrganizacijskihVprasanj := TGridPanel.Create(Self);
  izOrganizacija_St1 := TLabel.Create(Self);
  izOrganizacija_mera1 := TLabel.Create(Self);
  izOrganizacija_St2 := TLabel.Create(Self);
  izOrganizacija_mera2 := TLabel.Create(Self);
  izOrganizacija_St3 := TLabel.Create(Self);
  izOrganizacija_mera3 := TLabel.Create(Self);
  izOrganizacija_St4 := TLabel.Create(Self);
  izOrganizacija_mera4 := TLabel.Create(Self);
  izOrganizacija_St5 := TLabel.Create(Self);
  izOrganizacija_mera5 := TLabel.Create(Self);
  izOrganizacija_St6 := TLabel.Create(Self);
  izOrganizacija_mera6 := TLabel.Create(Self);
  izOrganizacija_St7 := TLabel.Create(Self);
  izOrganizacija_mera7 := TLabel.Create(Self);
  izOrganizacija_St8 := TLabel.Create(Self);
  izOrganizacija_mera8 := TLabel.Create(Self);
  izOrganizacija_St9 := TLabel.Create(Self);
  izOrganizacija_mera9 := TLabel.Create(Self);
  izOrganizacija_St10 := TLabel.Create(Self);
  izOrganizacija_mera10 := TLabel.Create(Self);
  izOrganizacija_St11 := TLabel.Create(Self);
  izOrganizacija_OdgovorPrazen11 := TLabel.Create(Self);
  izOrganizacija_MeraPrazen11 := TLabel.Create(Self);
  izOrganizacija_StPrazen11_1 := TLabel.Create(Self);
  izOrganizacija_MeraPrazen11_1 := TLabel.Create(Self);
  izOrganizacija_StPrazen11_2 := TLabel.Create(Self);
  izOrganizacija_mera11_2 := TLabel.Create(Self);
  izOrganizacija_StPrazen11_3 := TLabel.Create(Self);
  izOrganizacija_mera11_3 := TLabel.Create(Self);
  izOrganizacija_StPrazen11_4 := TLabel.Create(Self);
  izOrganizacija_MeraPrazen11_4 := TLabel.Create(Self);
  izOrganizacija_StPrazen11_5 := TLabel.Create(Self);
  izOrganizacija_mera11_5 := TLabel.Create(Self);
  izOrganizacija_StPrazen11_6 := TLabel.Create(Self);
  izOrganizacija_mera11_6 := TLabel.Create(Self);
  izOrganizacija_St12 := TLabel.Create(Self);
  izOrganizacija_mera12 := TLabel.Create(Self);
  izOrganizacija_St13 := TLabel.Create(Self);
  izOrganizacija_mera13 := TLabel.Create(Self);
  izOrganizacija_Vprasanje1 := TMemo.Create(Self);
  vpOrganizacija_Odgovor1 := TTrackBar.Create(Self);
  izOrganizacija_Vprasanje2 := TMemo.Create(Self);
  vpOrganizacija_Odgovor2 := TTrackBar.Create(Self);
  izOrganizacija_Vprasanje3 := TMemo.Create(Self);
  ploscicaOrganizacija_Odgovor3 := TPanel.Create(Self);
  vpOrganizacija_OdgovorDa3 := TRadioButton.Create(Self);
  vpOrganizacija_OdgovorNe3 := TRadioButton.Create(Self);
  izOrganizacija_Vprasanje4 := TMemo.Create(Self);
  ploscicaOrganizacija_Odgovor4 := TPanel.Create(Self);
  vpOrganizacija_OdgovorDa4 := TRadioButton.Create(Self);
  vpOrganizacija_OdgovorNe4 := TRadioButton.Create(Self);
  izOrganizacija_Vprasanje5 := TMemo.Create(Self);
  ploscicaOrganizacija_Odgovor5 := TPanel.Create(Self);
  vpOrganizacija_OdgovorDa5 := TRadioButton.Create(Self);
  vpOrganizacija_OdgovorNe5 := TRadioButton.Create(Self);
  izOrganizacija_Vprasanje6 := TMemo.Create(Self);
  ploscicaOrganizacija_Odgovor6 := TPanel.Create(Self);
  vpOrganizacija_OdgovorDa6 := TRadioButton.Create(Self);
  vpOrganizacija_OdgovorNe6 := TRadioButton.Create(Self);
  izOrganizacija_Vprasanje7 := TMemo.Create(Self);
  ploscicaOrganizacija_Odgovor7 := TPanel.Create(Self);
  vpOrganizacija_OdgovorDa7 := TRadioButton.Create(Self);
  vpOrganizacija_OdgovorNe7 := TRadioButton.Create(Self);
  izOrganizacija_Vprasanje8 := TMemo.Create(Self);
  ploscicaOrganizacija_Odgovor8 := TPanel.Create(Self);
  vpOrganizacija_OdgovorDa8 := TRadioButton.Create(Self);
  vpOrganizacija_OdgovorNe8 := TRadioButton.Create(Self);
  izOrganizacija_Vprasanje9 := TMemo.Create(Self);
  vpOrganizacija_Odgovor9 := TTrackBar.Create(Self);
  izOrganizacija_Vprasanje10 := TMemo.Create(Self);
  vpOrganizacija_Odgovor10 := TTrackBar.Create(Self);
  izOrganizacija_Vprasanje11 := TMemo.Create(Self);
  izOrganizacija_Vprasanje11_1 := TMemo.Create(Self);
  ploscicaOrganizacija_Odgovor11_1 := TPanel.Create(Self);
  vpOrganizacija_OdgovorDa11_1 := TRadioButton.Create(Self);
  vpOrganizacija_OdgovorNe11_1 := TRadioButton.Create(Self);
  izOrganizacija_Vprasanje11_2 := TMemo.Create(Self);
  vpOrganizacija_Odgovor11_2 := TTrackBar.Create(Self);
  izOrganizacija_Vprasanje11_3 := TMemo.Create(Self);
  vpOrganizacija_Odgovor11_3 := TTrackBar.Create(Self);
  izOrganizacija_Vprasanje11_4 := TMemo.Create(Self);
  ploscicaOrganizacija_Odgovor11_4 := TPanel.Create(Self);
  vpOrganizacija_OdgovorDa11_4 := TRadioButton.Create(Self);
  vpOrganizacija_OdgovorNe11_4 := TRadioButton.Create(Self);
  izOrganizacija_Vprasanje11_5 := TMemo.Create(Self);
  vpOrganizacija_Odgovor11_5 := TTrackBar.Create(Self);
  izOrganizacija_Vprasanje11_6 := TMemo.Create(Self);
  vpOrganizacija_Odgovor11_6 := TTrackBar.Create(Self);
  izOrganizacija_Vprasanje12 := TMemo.Create(Self);
  vpOrganizacija_Odgovor12 := TTrackBar.Create(Self);
  izOrganizacija_Vprasanje13 := TMemo.Create(Self);
  vpOrganizacija_Odgovor13 := TComboBox.Create(Self);
  DelovniList5 := TTabSheet.Create(Self);
  WebScrollBox2 := TScrollBox.Create(Self);
  izNaslovDL5 := TLabel.Create(Self);
  ploscicaPodatkiOZavarovanju := TPanel.Create(Self);
  izTekstZVKosmatiDobicek := TLabel.Create(Self);
  izTekstStroski1 := TLabel.Create(Self);
  izTekstStroski2 := TLabel.Create(Self);
  izTekstMDJ := TLabel.Create(Self);
  izTekstZacetekZavarovanja := TLabel.Create(Self);
  izTekstPremija := TLabel.Create(Self);
  izMDJ := TLabel.Create(Self);
  vpZavarovanjeDAKosmatiDobicek := TCheckBox.Create(Self);
  vpZVKosmatiDobicek := TEdit.Create(Self);
  vpZavarovanjeDANastetiStroski := TCheckBox.Create(Self);
  ploscicaNastetiStroski := TGridPanel.Create(Self);
  izZavarovaniStroski1_1 := TLabel.Create(Self);
  izZavarovaniStroski2_1 := TLabel.Create(Self);
  izZavarovaniStroski1_2 := TLabel.Create(Self);
  izZavarovaniStroski1_3 := TLabel.Create(Self);
  izZavarovaniStroski1_4 := TLabel.Create(Self);
  izZavarovaniStroski1_5 := TLabel.Create(Self);
  izZavarovaniStroski1_6 := TLabel.Create(Self);
  vpZavarovaniStroski2_2 := TEdit.Create(Self);
  vpZavarovaniStroski2_3 := TEdit.Create(Self);
  vpZavarovaniStroski2_4 := TEdit.Create(Self);
  vpZavarovaniStroski2_5 := TEdit.Create(Self);
  vpZavarovaniStroski2_6 := TEdit.Create(Self);
  vpZVNastetiStroski := TEdit.Create(Self);
  vpMDJ := TComboBox.Create(Self);
  vpZacetekZavarovanja := TDateTimePicker.Create(Self);
  vpPremija := TEdit.Create(Self);
  vpZavarovanjeDa := TCheckBox.Create(Self);
  DelovniList6 := TTabSheet.Create(Self);
  WebScrollBoxDL6 := TScrollBox.Create(Self);
  izNaslovDL6 := TLabel.Create(Self);
  izNastavljanjeOsnovnihParametrov := TLabel.Create(Self);
  ploscicaKorekcijskiFaktorji := TGridPanel.Create(Self);
  izPloscicaKorekcijskiFaktorji_Naslov1 := TLabel.Create(Self);
  izPloscicaKorekcijskiFaktorji_Naslov2 := TLabel.Create(Self);
  izPloscicaKorekcijskiFaktorji_Naslov3 := TLabel.Create(Self);
  izPloscicaKorekcijskiFaktorji_Naslov4 := TLabel.Create(Self);
  izPloscicaKorekcijskiFaktorji_KF1_1 := TLabel.Create(Self);
  izPloscicaKorekcijskiFaktorji_KF1_2 := TLabel.Create(Self);
  izPloscicaKorekcijskiFaktorji_KF1_3 := TLabel.Create(Self);
  izPloscicaKorekcijskiFaktorji_KF2_1 := TLabel.Create(Self);
  izPloscicaKorekcijskiFaktorji_KF2_2 := TLabel.Create(Self);
  izPloscicaKorekcijskiFaktorji_KF2_3 := TLabel.Create(Self);
  izPloscicaKorekcijskiFaktorji_KF3_1 := TLabel.Create(Self);
  izPloscicaKorekcijskiFaktorji_KF3_2 := TLabel.Create(Self);
  izPloscicaKorekcijskiFaktorji_KF3_3 := TLabel.Create(Self);
  izPloscicaKorekcijskiFaktorji_KF4_1 := TLabel.Create(Self);
  izPloscicaKorekcijskiFaktorji_KF4_2 := TLabel.Create(Self);
  izPloscicaKorekcijskiFaktorji_KF4_3 := TLabel.Create(Self);
  izPloscicaKorekcijskiFaktorji_KF5_1 := TLabel.Create(Self);
  izPloscicaKorekcijskiFaktorji_KF5_2 := TLabel.Create(Self);
  izPloscicaKorekcijskiFaktorji_KF5_3 := TLabel.Create(Self);
  ploscicaPloscicaKorekcijskiFaktor_1 := TPanel.Create(Self);
  vpPloscicaKorekcijskiFaktorji_KFIzpis1_4 := TLabel.Create(Self);
  vpPloscicaKorekcijskiFaktorji_KFDrsnik1_4 := TTrackBar.Create(Self);
  ploscicaPloscicaKorekcijskiFaktor_2 := TPanel.Create(Self);
  vpPloscicaKorekcijskiFaktorji_KFIzpis2_4 := TLabel.Create(Self);
  vpPloscicaKorekcijskiFaktorji_KFDrsnik2_4 := TTrackBar.Create(Self);
  ploscicaPloscicaKorekcijskiFaktor_3 := TPanel.Create(Self);
  vpPloscicaKorekcijskiFaktorji_KFIzpis3_4 := TLabel.Create(Self);
  vpPloscicaKorekcijskiFaktorji_KFDrsnik3_4 := TTrackBar.Create(Self);
  ploscicaPloscicaKorekcijskiFaktor_4 := TPanel.Create(Self);
  vpPloscicaKorekcijskiFaktorji_KFIzpis4_4 := TLabel.Create(Self);
  vpPloscicaKorekcijskiFaktorji_KFDrsnik4_4 := TTrackBar.Create(Self);
  ploscicaPloscicaKorekcijskiFaktor_5 := TPanel.Create(Self);
  vpPloscicaKorekcijskiFaktorji_KFIzpis5_4 := TLabel.Create(Self);
  vpPloscicaKorekcijskiFaktorji_KFDrsnik5_4 := TTrackBar.Create(Self);
  ploscicaMaksimalniPribitek := TPanel.Create(Self);
  izMaksimalniPribitek := TLabel.Create(Self);
  vpMaksimalniPribitek := TTrackBar.Create(Self);
  ploscicaMDJRezultat := TPanel.Create(Self);
  izTekstPriporocenaMDJ := TLabel.Create(Self);
  izPriporocenaMDJ := TLabel.Create(Self);
  ploscicaParametriMDJ := TGridPanel.Create(Self);
  izParametriMDJ_Naslov_1 := TLabel.Create(Self);
  izParametriMDJ_Naslov_2 := TLabel.Create(Self);
  izParametriMDJ_Naslov_3 := TLabel.Create(Self);
  izParametriMDJ_Parameter1 := TLabel.Create(Self);
  izParametriMDJ_Parameter2 := TLabel.Create(Self);
  izParametriMDJ_Parameter3 := TLabel.Create(Self);
  izParametriMDJ_Parameter4 := TLabel.Create(Self);
  izParametriMDJ_Parameter5 := TLabel.Create(Self);
  izParametriMDJ_Parameter6 := TLabel.Create(Self);
  izParametriMDJ_PrazenParameter6_2 := TLabel.Create(Self);
  izParametriMDJ_Parameter7 := TLabel.Create(Self);
  izParametriMDJ_PrazenParameter7_2 := TLabel.Create(Self);
  ploscicaParameter1_1 := TPanel.Create(Self);
  izParameter1_1 := TLabel.Create(Self);
  vpParameter1_1 := TTrackBar.Create(Self);
  ploscicaParameter1_2 := TPanel.Create(Self);
  izParameter1_2 := TLabel.Create(Self);
  vpParameter1_2 := TTrackBar.Create(Self);
  ploscicaParameter2_1 := TPanel.Create(Self);
  izParameter2_1 := TLabel.Create(Self);
  vpParameter2_1 := TTrackBar.Create(Self);
  ploscicaParameter2_2 := TPanel.Create(Self);
  izParameter2_2 := TLabel.Create(Self);
  vpParameter2_2 := TTrackBar.Create(Self);
  ploscicaParameter3_1 := TPanel.Create(Self);
  izParameter3_1 := TLabel.Create(Self);
  vpParameter3_1 := TTrackBar.Create(Self);
  ploscicaParameter3_2 := TPanel.Create(Self);
  izParameter3_2 := TLabel.Create(Self);
  vpParameter3_2 := TTrackBar.Create(Self);
  ploscicaParameter4_1 := TPanel.Create(Self);
  izParameter4_1 := TLabel.Create(Self);
  vpParameter4_1 := TTrackBar.Create(Self);
  ploscicaParameter4_2 := TPanel.Create(Self);
  izParameter4_2 := TLabel.Create(Self);
  vpParameter4_2 := TTrackBar.Create(Self);
  ploscicaParameter5_1 := TPanel.Create(Self);
  izParameter5_1 := TLabel.Create(Self);
  vpParameter5_1 := TTrackBar.Create(Self);
  ploscicaParameter5_2 := TPanel.Create(Self);
  izParameter5_2 := TLabel.Create(Self);
  vpParameter5_2 := TTrackBar.Create(Self);
  ploscicaParameter6_1 := TPanel.Create(Self);
  izParameter6_1 := TLabel.Create(Self);
  vpParameter6_1 := TTrackBar.Create(Self);
  ploscicaParameter7_1 := TPanel.Create(Self);
  izParameter7_1 := TLabel.Create(Self);
  vpParameter7_1 := TTrackBar.Create(Self);
  vpKorekcijskiFaktorji := TCheckBox.Create(Self);
  ploscicaGrafMDJ := TPanel.Create(Self);
  GrafMDJ := TGoogleChart.Create(Self);
  ploscicaGrafikaMDJ_osnovni := TPanel.Create(Self);
  izGraficniPrikazMDJ := TLabel.Create(Self);
  ploscicaGrafikaMDJ := TPanel.Create(Self);
  izGrafikaMDJ_DA := TLabel.Create(Self);
  izGrafikaMDJ_NE := TLabel.Create(Self);
  vpGrafikaMDJ := TTrackBar.Create(Self);
  DelovniList7 := TTabSheet.Create(Self);
  WebScrollBoxDL7 := TScrollBox.Create(Self);
  izNaslovDL7 := TLabel.Create(Self);
  ploscicaPriporoceneZV := TPanel.Create(Self);
  izTekstLetoPriporoceneZV := TLabel.Create(Self);
  ploscicaLetoPriporoceneZV := TPanel.Create(Self);
  izLetoPriporoceneZVmax := TLabel.Create(Self);
  izLetoPriporoceneZVmin := TLabel.Create(Self);
  vpLetoPriporoceneZV := TTrackBar.Create(Self);
  ploscicaTabelaPriporoceneZV := TGridPanel.Create(Self);
  izPriporoceneZV_Naslov1 := TLabel.Create(Self);
  izPriporoceneZV_Naslov2 := TLabel.Create(Self);
  izPriporoceneZV_tekstZV12 := TLabel.Create(Self);
  izPriporoceneZV_ZV12 := TLabel.Create(Self);
  izPriporoceneZV_tekstZV18 := TLabel.Create(Self);
  izPriporoceneZV_ZV18 := TLabel.Create(Self);
  izPriporoceneZV_tekstZV24 := TLabel.Create(Self);
  izPriporoceneZV_ZV24 := TLabel.Create(Self);
  ploscicaZVRezultat := TPanel.Create(Self);
  izTekstPriporoceniKD := TLabel.Create(Self);
  izTekstPriporocenaSKD := TLabel.Create(Self);
  izPriporoceniKD := TLabel.Create(Self);
  izPriporocenaSKD := TLabel.Create(Self);
  DelovniList8 := TTabSheet.Create(Self);
  WebScrollBoxDL8 := TScrollBox.Create(Self);
  izNaslovDL8 := TLabel.Create(Self);
  ListiProdajnihTrendov := TPageControl.Create(Self);
  LPT1_Tabela := TTabSheet.Create(Self);
  ploscicaAnalizeProdaje_Meseci := TGridPanel.Create(Self);
  ploscicaAnalizeProdaje_izMesec_Naslov := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izMesec_1 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izMesec_2 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izMesec_3 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izMesec_4 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izMesec_5 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izMesec_6 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izMesec_7 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izMesec_8 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izMesec_9 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izMesec_10 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izMesec_11 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izMesec_12 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_PrometX := TGridPanel.Create(Self);
  ploscicaAnalizeProdaje_izPromet_NaslovX := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izPromet_2_1 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izPromet_2_2 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izPromet_2_3 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izPromet_2_4 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izPromet_2_5 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izPromet_2_6 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izPromet_2_7 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izPromet_2_8 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izPromet_2_9 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izPromet_2_10 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izPromet_2_11 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izPromet_2_12 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_PrometXminus1 := TGridPanel.Create(Self);
  ploscicaAnalizeProdaje_izPromet_NaslovXminus1 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izPromet_1_1 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izPromet_1_2 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izPromet_1_3 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izPromet_1_4 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izPromet_1_5 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izPromet_1_6 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izPromet_1_7 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izPromet_1_8 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izPromet_1_9 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izPromet_1_10 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izPromet_1_11 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izPromet_1_12 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_PrometXplus1 := TGridPanel.Create(Self);
  ploscicaAnalizeProdaje_izPromet_NaslovXplus1 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izPromet_3_1 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izPromet_3_2 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izPromet_3_3 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izPromet_3_4 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izPromet_3_5 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izPromet_3_6 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izPromet_3_7 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izPromet_3_8 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izPromet_3_9 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izPromet_3_10 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izPromet_3_11 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izPromet_3_12 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_PrometXplus2 := TGridPanel.Create(Self);
  ploscicaAnalizeProdaje_izPromet_NaslovXplus2 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izPromet_4_1 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izPromet_4_2 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izPromet_4_3 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izPromet_4_4 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izPromet_4_5 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izPromet_4_6 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izPromet_4_7 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izPromet_4_8 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izPromet_4_9 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izPromet_4_10 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izPromet_4_11 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izPromet_4_12 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_PrometXplus3 := TGridPanel.Create(Self);
  ploscicaAnalizeProdaje_izPromet_NaslovXplus3 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izPromet_5_1 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izPromet_5_2 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izPromet_5_3 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izPromet_5_4 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izPromet_5_5 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izPromet_5_6 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izPromet_5_7 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izPromet_5_8 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izPromet_5_9 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izPromet_5_10 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izPromet_5_11 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izPromet_5_12 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_PrometXplus4 := TGridPanel.Create(Self);
  ploscicaAnalizeProdaje_izPromet_NaslovXplus4 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izPromet_6_1 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izPromet_6_2 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izPromet_6_3 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izPromet_6_4 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izPromet_6_5 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izPromet_6_6 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izPromet_6_7 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izPromet_6_8 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izPromet_6_9 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izPromet_6_10 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izPromet_6_11 := TLabel.Create(Self);
  ploscicaAnalizeProdaje_izPromet_6_12 := TLabel.Create(Self);
  LPT2_MesecniGraf := TTabSheet.Create(Self);
  GrafMesecniTrendi := TGoogleChart.Create(Self);
  LPT3_LetniGraf := TTabSheet.Create(Self);
  GrafLetniTrendi := TGoogleChart.Create(Self);
  DelovniList9 := TTabSheet.Create(Self);
  WebScrollBoxDL9 := TScrollBox.Create(Self);
  izNaslovDL9 := TLabel.Create(Self);
  ploscicaObstojeceZavarovanje := TGridPanel.Create(Self);
  izPloscicaObstojeceZavarovanje_Vprasanje1 := TLabel.Create(Self);
  izPloscicaObstojeceZavarovanje_Odgovor1 := TLabel.Create(Self);
  izPloscicaObstojeceZavarovanje_Semafor1 := TImageControl.Create(Self);
  izPloscicaObstojeceZavarovanje_prazna1 := TLabel.Create(Self);
  izPloscicaObstojeceZavarovanje_Vprasanje2 := TLabel.Create(Self);
  izPloscicaObstojeceZavarovanje_Odgovor2 := TLabel.Create(Self);
  izPloscicaObstojeceZavarovanje_Semafor2 := TImageControl.Create(Self);
  izPloscicaObstojeceZavarovanje_prazna2 := TLabel.Create(Self);
  izPloscicaObstojeceZavarovanje_Vprasanje3 := TLabel.Create(Self);
  izPloscicaObstojeceZavarovanje_Odgovor3 := TLabel.Create(Self);
  izPloscicaObstojeceZavarovanje_Semafor3 := TImageControl.Create(Self);
  izPloscicaObstojeceZavarovanje_Semafor3b := TImageControl.Create(Self);
  izPloscicaObstojeceZavarovanje_Vprasanje4 := TLabel.Create(Self);
  izPloscicaObstojeceZavarovanje_Odgovor4 := TLabel.Create(Self);
  izPloscicaObstojeceZavarovanje_Semafor4 := TImageControl.Create(Self);
  izPloscicaObstojeceZavarovanje_prazna4 := TLabel.Create(Self);
  DelovniList10 := TTabSheet.Create(Self);
  WebScrollBoxDL10 := TScrollBox.Create(Self);
  izDatumFizicneSkode := TLabel.Create(Self);
  izNaslovDL10 := TLabel.Create(Self);
  gPreracunaj := TSpeedButton.Create(Self);
  ploscicaPodatkiZaSimulacijoSkode := TPanel.Create(Self);
  DelovniListiPodatkiZaSimulacijoSkod := TPageControl.Create(Self);
  WebTabSheet1 := TTabSheet.Create(Self);
  izGraficniPrikazSimulacijaSkode := TLabel.Create(Self);
  ploscicaTabelaPodatkovOPrometu := TGridPanel.Create(Self);
  ploscicaPromet_Naslov1 := TLabel.Create(Self);
  ploscicaPromet_Naslov2 := TLabel.Create(Self);
  ploscicaPromet_Naslov3 := TLabel.Create(Self);
  ploscicaPromet_Naslov4 := TLabel.Create(Self);
  ploscicaPromet_Naslov5 := TLabel.Create(Self);
  ploscicaPromet_Naslov6 := TLabel.Create(Self);
  ploscicaPromet_Naslov7 := TLabel.Create(Self);
  ploscicaPromet_Naslov8 := TLabel.Create(Self);
  ploscicaPromet_Mesec1 := TLabel.Create(Self);
  ploscicaPromet_Mesec2 := TLabel.Create(Self);
  ploscicaPromet_Mesec3 := TLabel.Create(Self);
  ploscicaPromet_Mesec4 := TLabel.Create(Self);
  ploscicaPromet_Mesec5 := TLabel.Create(Self);
  ploscicaPromet_Mesec6 := TLabel.Create(Self);
  ploscicaPromet_Mesec7 := TLabel.Create(Self);
  ploscicaPromet_Mesec8 := TLabel.Create(Self);
  ploscicaPromet_Mesec9 := TLabel.Create(Self);
  ploscicaPromet_Mesec10 := TLabel.Create(Self);
  ploscicaPromet_Mesec11 := TLabel.Create(Self);
  ploscicaPromet_Mesec12 := TLabel.Create(Self);
  ploscicaPromet_Promet_1_1 := TEdit.Create(Self);
  ploscicaPromet_Promet_2_1 := TEdit.Create(Self);
  ploscicaPromet_Promet_3_1 := TEdit.Create(Self);
  ploscicaPromet_Promet_4_1 := TEdit.Create(Self);
  ploscicaPromet_Indeks_1_1 := TEdit.Create(Self);
  ploscicaPromet_Indeks_2_1 := TEdit.Create(Self);
  ploscicaPromet_Indeks_3_1 := TEdit.Create(Self);
  ploscicaPromet_Promet_1_2 := TEdit.Create(Self);
  ploscicaPromet_Promet_2_2 := TEdit.Create(Self);
  ploscicaPromet_Promet_3_2 := TEdit.Create(Self);
  ploscicaPromet_Promet_4_2 := TEdit.Create(Self);
  ploscicaPromet_Indeks_1_2 := TEdit.Create(Self);
  ploscicaPromet_Indeks_2_2 := TEdit.Create(Self);
  ploscicaPromet_Indeks_3_2 := TEdit.Create(Self);
  ploscicaPromet_Promet_1_3 := TEdit.Create(Self);
  ploscicaPromet_Promet_2_3 := TEdit.Create(Self);
  ploscicaPromet_Promet_3_3 := TEdit.Create(Self);
  ploscicaPromet_Promet_4_3 := TEdit.Create(Self);
  ploscicaPromet_Indeks_1_3 := TEdit.Create(Self);
  ploscicaPromet_Indeks_2_3 := TEdit.Create(Self);
  ploscicaPromet_Indeks_3_3 := TEdit.Create(Self);
  ploscicaPromet_Promet_1_4 := TEdit.Create(Self);
  ploscicaPromet_Promet_2_4 := TEdit.Create(Self);
  ploscicaPromet_Promet_3_4 := TEdit.Create(Self);
  ploscicaPromet_Promet_4_4 := TEdit.Create(Self);
  ploscicaPromet_Indeks_1_4 := TEdit.Create(Self);
  ploscicaPromet_Indeks_2_4 := TEdit.Create(Self);
  ploscicaPromet_Indeks_3_4 := TEdit.Create(Self);
  ploscicaPromet_Promet_1_5 := TEdit.Create(Self);
  ploscicaPromet_Promet_2_5 := TEdit.Create(Self);
  ploscicaPromet_Promet_3_5 := TEdit.Create(Self);
  ploscicaPromet_Promet_4_5 := TEdit.Create(Self);
  ploscicaPromet_Indeks_1_5 := TEdit.Create(Self);
  ploscicaPromet_Indeks_2_5 := TEdit.Create(Self);
  ploscicaPromet_Indeks_3_5 := TEdit.Create(Self);
  ploscicaPromet_Promet_1_6 := TEdit.Create(Self);
  ploscicaPromet_Promet_2_6 := TEdit.Create(Self);
  ploscicaPromet_Promet_3_6 := TEdit.Create(Self);
  ploscicaPromet_Promet_4_6 := TEdit.Create(Self);
  ploscicaPromet_Indeks_1_6 := TEdit.Create(Self);
  ploscicaPromet_Indeks_2_6 := TEdit.Create(Self);
  ploscicaPromet_Indeks_3_6 := TEdit.Create(Self);
  ploscicaPromet_Promet_1_7 := TEdit.Create(Self);
  ploscicaPromet_Promet_2_7 := TEdit.Create(Self);
  ploscicaPromet_Promet_3_7 := TEdit.Create(Self);
  ploscicaPromet_Promet_4_7 := TEdit.Create(Self);
  ploscicaPromet_Indeks_1_7 := TEdit.Create(Self);
  ploscicaPromet_Indeks_2_7 := TEdit.Create(Self);
  ploscicaPromet_Indeks_3_7 := TEdit.Create(Self);
  ploscicaPromet_Promet_1_8 := TEdit.Create(Self);
  ploscicaPromet_Promet_2_8 := TEdit.Create(Self);
  ploscicaPromet_Promet_3_8 := TEdit.Create(Self);
  ploscicaPromet_Promet_4_8 := TEdit.Create(Self);
  ploscicaPromet_Indeks_1_8 := TEdit.Create(Self);
  ploscicaPromet_Indeks_2_8 := TEdit.Create(Self);
  ploscicaPromet_Indeks_3_8 := TEdit.Create(Self);
  ploscicaPromet_Promet_1_9 := TEdit.Create(Self);
  ploscicaPromet_Promet_2_9 := TEdit.Create(Self);
  ploscicaPromet_Promet_3_9 := TEdit.Create(Self);
  ploscicaPromet_Promet_4_9 := TEdit.Create(Self);
  ploscicaPromet_Indeks_1_9 := TEdit.Create(Self);
  ploscicaPromet_Indeks_2_9 := TEdit.Create(Self);
  ploscicaPromet_Indeks_3_9 := TEdit.Create(Self);
  ploscicaPromet_Promet_1_10 := TEdit.Create(Self);
  ploscicaPromet_Promet_2_10 := TEdit.Create(Self);
  ploscicaPromet_Promet_3_10 := TEdit.Create(Self);
  ploscicaPromet_Promet_4_10 := TEdit.Create(Self);
  ploscicaPromet_Indeks_1_10 := TEdit.Create(Self);
  ploscicaPromet_Indeks_2_10 := TEdit.Create(Self);
  ploscicaPromet_Indeks_3_10 := TEdit.Create(Self);
  ploscicaPromet_Promet_1_11 := TEdit.Create(Self);
  ploscicaPromet_Promet_2_11 := TEdit.Create(Self);
  ploscicaPromet_Promet_3_11 := TEdit.Create(Self);
  ploscicaPromet_Promet_4_11 := TEdit.Create(Self);
  ploscicaPromet_Indeks_1_11 := TEdit.Create(Self);
  ploscicaPromet_Indeks_2_11 := TEdit.Create(Self);
  ploscicaPromet_Indeks_3_11 := TEdit.Create(Self);
  ploscicaPromet_Promet_1_12 := TEdit.Create(Self);
  ploscicaPromet_Promet_2_12 := TEdit.Create(Self);
  ploscicaPromet_Promet_3_12 := TEdit.Create(Self);
  ploscicaPromet_Promet_4_12 := TEdit.Create(Self);
  ploscicaPromet_Indeks_1_12 := TEdit.Create(Self);
  ploscicaPromet_Indeks_2_12 := TEdit.Create(Self);
  ploscicaPromet_Indeks_3_12 := TEdit.Create(Self);
  gVnosPopolnegaZastoja := TSpeedButton.Create(Self);
  gAvtomaticnoPolnjenje := TSpeedButton.Create(Self);
  gVnesiKrivuljoZastoja := TSpeedButton.Create(Self);
  ploscicaGrafikaSkode := TPanel.Create(Self);
  izGrafikaSkode_DA := TLabel.Create(Self);
  izGrafikaSkode_NE := TLabel.Create(Self);
  vpGrafikaSkode := TTrackBar.Create(Self);
  WebTabSheet2 := TTabSheet.Create(Self);
  ploscicaPovisaniStroski := TGridPanel.Create(Self);
  izPovisaniPoslovniStroskiOpis := TLabel.Create(Self);
  izResenPrometOpis := TLabel.Create(Self);
  izPovisaniPoslovniStroskiObdobjeOpis := TLabel.Create(Self);
  vpPovisaniPoslovniStroskiZnesek := TEdit.Create(Self);
  vpResenPrometZnesek := TEdit.Create(Self);
  vpPovisaniPoslovniStroskiObdobje := TComboBox.Create(Self);
  WebTabSheet3 := TTabSheet.Create(Self);
  izPrihrankiObdobjeOpis := TLabel.Create(Self);
  ploscicaPrihranki := TGridPanel.Create(Self);
  ploscicaPrihranki_Naslov1 := TLabel.Create(Self);
  ploscicaPrihranki_Naslov2 := TLabel.Create(Self);
  ploscicaPrihranki_Prihranek_1_1 := TEdit.Create(Self);
  ploscicaPrihranki_Prihranek_2_1 := TEdit.Create(Self);
  ploscicaPrihranki_Prihranek_1_2 := TEdit.Create(Self);
  ploscicaPrihranki_Prihranek_2_2 := TEdit.Create(Self);
  ploscicaPrihranki_Prihranek_1_3 := TEdit.Create(Self);
  ploscicaPrihranki_Prihranek_2_3 := TEdit.Create(Self);
  ploscicaPrihranki_Prihranek_1_4 := TEdit.Create(Self);
  ploscicaPrihranki_Prihranek_2_4 := TEdit.Create(Self);
  ploscicaPrihranki_Prihranek_1_5 := TEdit.Create(Self);
  ploscicaPrihranki_Prihranek_2_5 := TEdit.Create(Self);
  ploscicaPrihranki_Prihranek_1_6 := TEdit.Create(Self);
  ploscicaPrihranki_Prihranek_2_6 := TEdit.Create(Self);
  ploscicaPrihranki_Prihranek_1_7 := TEdit.Create(Self);
  ploscicaPrihranki_Prihranek_2_7 := TEdit.Create(Self);
  ploscicaPrihranki_Prihranek_1_8 := TEdit.Create(Self);
  ploscicaPrihranki_Prihranek_2_8 := TEdit.Create(Self);
  ploscicaPrihranki_Prihranek_1_9 := TEdit.Create(Self);
  ploscicaPrihranki_Prihranek_2_9 := TEdit.Create(Self);
  ploscicaPrihranki_Prihranek_1_10 := TEdit.Create(Self);
  ploscicaPrihranki_Prihranek_2_10 := TEdit.Create(Self);
  ploscicaPrihranki_PrihranekSkupajOpis := TEdit.Create(Self);
  ploscicaPrihranki_PrihranekSkupajZnesek := TEdit.Create(Self);
  vpPrihrankiObdobje := TComboBox.Create(Self);
  gShraniSkodo := TSpeedButton.Create(Self);
  gNaloziSkodo := TSpeedButton.Create(Self);
  ploscicaSimulacijaSkode_PodatkiOZavarovanju := TPanel.Create(Self);
  izPodatkiOZavarovanju_Naslov := TLabel.Create(Self);
  izPodatkioZavarovanju_Od := TLabel.Create(Self);
  izPodatkioZavarovanju_Do := TLabel.Create(Self);
  izPodatkioZavarovanju_ZavarovalnaVsota := TLabel.Create(Self);
  izPodatkioZavarovanju_MaksimalnaDobaJamcenja := TLabel.Create(Self);
  izPodatkioZavarovanju_Premija := TLabel.Create(Self);
  izMaksimalnaDobaJamcenjaTekst := TLabel.Create(Self);
  vpZavarovanjeOd := TDateTimePicker.Create(Self);
  vpZavarovanjeDo := TDateTimePicker.Create(Self);
  izZavarovalnaVsota := TEdit.Create(Self);
  izMaksimalnaDobaJamcenja := TComboBox.Create(Self);
  izPremija := TEdit.Create(Self);
  vpDatumFizicneSkode := TComboBox.Create(Self);
  ploscicaGrafSimulacijaSkode := TPanel.Create(Self);
  GrafSimulacijaSkode := TGoogleChart.Create(Self);
  DelovniList0B := TTabSheet.Create(Self);
  WebScrollBoxDL0B := TScrollBox.Create(Self);
  gNaloziProjekt := TSpeedButton.Create(Self);
  gPrekiniDelo := TSpeedButton.Create(Self);
  gZacniNovProjekt := TSpeedButton.Create(Self);
  ploscicaNaslovDL0B := TPanel.Create(Self);
  izNaslov2_1_DL0B := TLabel.Create(Self);
  izNaslov2_2_DL0B := TLabel.Create(Self);
  izNaslov2_3_DL0B := TLabel.Create(Self);
  logo_ModelBI_DL0B := TImageControl.Create(Self);
  ploscicaGlava := TPanel.Create(Self);
  logoRC1 := TImageControl.Create(Self);
  izVerzija := TLabel.Create(Self);
  ploscicaIzborPogledov := TPanel.Create(Self);
  izIzborVhodniPodatki := TLabel.Create(Self);
  izIzborAnalize := TLabel.Create(Self);
  izIzborSimulacijeSkod := TLabel.Create(Self);
  izborVhodniPodatki := TListBox.Create(Self);
  izborAnalize := TListBox.Create(Self);
  izborSimulacijeSkod := TListBox.Create(Self);
  ploscicaUporabnik := TPanel.Create(Self);
  izUporabnikTekst := TLabel.Create(Self);
  izPodjetjeTekst := TLabel.Create(Self);
  izUporabnik := TLabel.Create(Self);
  izPotekLicenceTekst := TLabel.Create(Self);
  izPodjetje := TLabel.Create(Self);
  izPotekLicence := TDateTimePicker.Create(Self);
  ploscicaGlavniMeni := TPanel.Create(Self);
  PovezavaServerGeslo := TXDataWebConnection.Create(Self);
  WebClientGeslo := TXDataWebClient.Create(Self);
  PovezavaServerPodatki := TXDataWebConnection.Create(Self);
  WebClientPodatki := TXDataWebClient.Create(Self);
  PovezavaWSPopolniPreracun := TXDataWebConnection.Create(Self);
  WebClientWSPopolniIzracun := TXDataWebClient.Create(Self);
  PovezavaWSDelniIzracuni := TXDataWebConnection.Create(Self);
  WebClientWSDelniIzracuni := TXDataWebClient.Create(Self);
  menuKrivuljaZastoja := TPopupMenu.Create(Self);
  mnKrivulja1 := TMenuItem.Create(Self);
  mnKrivulja2 := TMenuItem.Create(Self);
  mnKrivulja3 := TMenuItem.Create(Self);
  menuPopolniZastoj := TPopupMenu.Create(Self);
  mn3 := TMenuItem.Create(Self);
  mn4 := TMenuItem.Create(Self);
  mn5 := TMenuItem.Create(Self);
  mn6 := TMenuItem.Create(Self);
  mn7 := TMenuItem.Create(Self);
  mn8 := TMenuItem.Create(Self);
  mn9 := TMenuItem.Create(Self);
  mn10 := TMenuItem.Create(Self);
  mn11 := TMenuItem.Create(Self);
  mn12 := TMenuItem.Create(Self);
  PovezavaServerShranjevanje := TXDataWebConnection.Create(Self);
  WebClientShranjevanje := TXDataWebClient.Create(Self);
  PovezavaServerJezik := TXDataWebConnection.Create(Self);
  WebClientJezik := TXDataWebClient.Create(Self);
  mnGlavniMeni := TMainMenu.Create(Self);
  mnNovProjekt := TMenuItem.Create(Self);
  mnOdpriProjekt := TMenuItem.Create(Self);
  mnShraniProjekt := TMenuItem.Create(Self);
  mnOsveziAnalize := TMenuItem.Create(Self);
  mnOsveziPrivzeteVrednosti := TMenuItem.Create(Self);
  N6 := TMenuItem.Create(Self);
  mnOsveziDejanskeVrednosti := TMenuItem.Create(Self);
  mnOstalo := TMenuItem.Create(Self);
  mnNastavitve := TMenuItem.Create(Self);
  N4 := TMenuItem.Create(Self);
  mnOOrodju := TMenuItem.Create(Self);
  N5 := TMenuItem.Create(Self);
  mnPomoc := TMenuItem.Create(Self);
  mnZakljuciDelo := TMenuItem.Create(Self);
  PovezavaServerSkode := TXDataWebConnection.Create(Self);
  WebClientSkode := TXDataWebClient.Create(Self);

  obvestilo.BeforeLoadDFMValues;
  ploscicaDelovniListi.BeforeLoadDFMValues;
  DelovniList0A.BeforeLoadDFMValues;
  WebScrollBoxDL0A.BeforeLoadDFMValues;
  GoogleReCaptcha.BeforeLoadDFMValues;
  ploscicaPrvaStran.BeforeLoadDFMValues;
  slikaJezikANG.BeforeLoadDFMValues;
  slikaJezikSLO.BeforeLoadDFMValues;
  logo_ModelBI_DL0A.BeforeLoadDFMValues;
  slikaBrowser1.BeforeLoadDFMValues;
  slikaBrowser2.BeforeLoadDFMValues;
  slikaBrowser3.BeforeLoadDFMValues;
  slikaBrowser4.BeforeLoadDFMValues;
  izBrskalniki.BeforeLoadDFMValues;
  ploscicaPrijava.BeforeLoadDFMValues;
  vpUporabniskoIme_Tekst.BeforeLoadDFMValues;
  vpGeslo_Tekst.BeforeLoadDFMValues;
  izPrijavaNaslov.BeforeLoadDFMValues;
  izUporabniskoImeNapaka.BeforeLoadDFMValues;
  izGesloNapaka.BeforeLoadDFMValues;
  izVerzijaPrijava.BeforeLoadDFMValues;
  vpUporabniskoIme.BeforeLoadDFMValues;
  vpGeslo.BeforeLoadDFMValues;
  gPrijava.BeforeLoadDFMValues;
  ploscicaNaslovDL0A.BeforeLoadDFMValues;
  izNaslov2_1_DL0A.BeforeLoadDFMValues;
  izNaslov2_2_DL0A.BeforeLoadDFMValues;
  izNaslov2_3_DL0A.BeforeLoadDFMValues;
  izSpletnaStran.BeforeLoadDFMValues;
  DelovniList1.BeforeLoadDFMValues;
  WebScrollBoxDL1.BeforeLoadDFMValues;
  izNaslovDL1.BeforeLoadDFMValues;
  ploscicaVnosPodatkovOPodjetju.BeforeLoadDFMValues;
  izImePodjetja.BeforeLoadDFMValues;
  izDavcnaStevilkaPodjetja.BeforeLoadDFMValues;
  izDejavnostPodjetja.BeforeLoadDFMValues;
  izNaslovPodjetja.BeforeLoadDFMValues;
  vpImePodjetja.BeforeLoadDFMValues;
  vpDavcnaStevilkaPodjetja.BeforeLoadDFMValues;
  vpNaslovPodjetja.BeforeLoadDFMValues;
  vpDejavnostPodjetja.BeforeLoadDFMValues;
  DelovniList2.BeforeLoadDFMValues;
  WebScrollBoxDL2.BeforeLoadDFMValues;
  izNaslovDL2.BeforeLoadDFMValues;
  ploscicaVnosFinancnihPodatkovPodjetja.BeforeLoadDFMValues;
  izVnosFRPLeto.BeforeLoadDFMValues;
  izVnosFRPPromet.BeforeLoadDFMValues;
  izVnosFRPZalogeZacetek.BeforeLoadDFMValues;
  izVnosFRPZalogeKonec.BeforeLoadDFMValues;
  izVnosFRPInternoProizvedeniDodatki.BeforeLoadDFMValues;
  izVnosFRPStroskiSurovine.BeforeLoadDFMValues;
  izVnosFRPStroskiMaterial.BeforeLoadDFMValues;
  izVnosFRPStroskiEnergija.BeforeLoadDFMValues;
  izVnosFRPStroskiTrgovskoBlago.BeforeLoadDFMValues;
  izVnosFRPStroskiStoritve.BeforeLoadDFMValues;
  izVnosFRPDrugiStroski.BeforeLoadDFMValues;
  izVnosFRPPrejeteZavarovalnine.BeforeLoadDFMValues;
  izVnosFRPKosmatiDobicek.BeforeLoadDFMValues;
  vpVnosFRPKosmatiDobicek.BeforeLoadDFMValues;
  izVnosFRPStopnjaKosmategaDobicka.BeforeLoadDFMValues;
  vpVnosFRPStopnjaKosmategaDobicka.BeforeLoadDFMValues;
  vpOpazovanoLeto.BeforeLoadDFMValues;
  vpVnosFRPPromet.BeforeLoadDFMValues;
  vpVnosFRPZalogeZacetek.BeforeLoadDFMValues;
  vpVnosFRPZalogeKonec.BeforeLoadDFMValues;
  vpVnosFRPInternoProizvedeniDodatki.BeforeLoadDFMValues;
  vpVnosFRPStroskiSurovine.BeforeLoadDFMValues;
  vpVnosFRPStroskiMaterial.BeforeLoadDFMValues;
  vpVnosFRPStroskiEnergija.BeforeLoadDFMValues;
  vpVnosFRPStroskiTrgovskoBlago.BeforeLoadDFMValues;
  vpVnosFRPStroskiStoritve.BeforeLoadDFMValues;
  vpVnosFRPDrugiStroski.BeforeLoadDFMValues;
  vpVnosFRPPrejeteZavarovalnine.BeforeLoadDFMValues;
  DelovniList3.BeforeLoadDFMValues;
  WebScrollBoxDL3.BeforeLoadDFMValues;
  izNaslovDL3.BeforeLoadDFMValues;
  izPlanProdaje_LetoXplus1.BeforeLoadDFMValues;
  izPlanProdaje_LetoXplus2.BeforeLoadDFMValues;
  izPlanProdaje_LetoXplus3.BeforeLoadDFMValues;
  izPlanProdaje_LetoXplus4.BeforeLoadDFMValues;
  izPlanProdaje_Naslov.BeforeLoadDFMValues;
  izPlanProdaje_RastXplus1.BeforeLoadDFMValues;
  izPlanProdaje_RastXplus2.BeforeLoadDFMValues;
  izPlanProdaje_RastXplus3.BeforeLoadDFMValues;
  izPlanProdaje_RastXplus4.BeforeLoadDFMValues;
  ploscicaTabelaPrometaX.BeforeLoadDFMValues;
  vpTabelaPromet_LetoX.BeforeLoadDFMValues;
  vpTabelaPromet_LetoXSkupaj.BeforeLoadDFMValues;
  vpTabelaPromet_ProdajaX_1.BeforeLoadDFMValues;
  vpTabelaPromet_ProdajaX_2.BeforeLoadDFMValues;
  vpTabelaPromet_ProdajaX_3.BeforeLoadDFMValues;
  vpTabelaPromet_ProdajaX_4.BeforeLoadDFMValues;
  vpTabelaPromet_ProdajaX_5.BeforeLoadDFMValues;
  vpTabelaPromet_ProdajaX_6.BeforeLoadDFMValues;
  vpTabelaPromet_ProdajaX_7.BeforeLoadDFMValues;
  vpTabelaPromet_ProdajaX_8.BeforeLoadDFMValues;
  vpTabelaPromet_ProdajaX_9.BeforeLoadDFMValues;
  vpTabelaPromet_ProdajaX_10.BeforeLoadDFMValues;
  vpTabelaPromet_ProdajaX_11.BeforeLoadDFMValues;
  vpTabelaPromet_ProdajaX_12.BeforeLoadDFMValues;
  ploscicaTabelaPrometaXminus1.BeforeLoadDFMValues;
  tabelaPromet_Mesec.BeforeLoadDFMValues;
  vpTabelaPromet_LetoXminus1.BeforeLoadDFMValues;
  tabelaPromet_Mesec1.BeforeLoadDFMValues;
  tabelaPromet_Mesec2.BeforeLoadDFMValues;
  tabelaPromet_Mesec3.BeforeLoadDFMValues;
  tabelaPromet_Mesec4.BeforeLoadDFMValues;
  tabelaPromet_Mesec5.BeforeLoadDFMValues;
  tabelaPromet_Mesec6.BeforeLoadDFMValues;
  tabelaPromet_Mesec7.BeforeLoadDFMValues;
  tabelaPromet_Mesec8.BeforeLoadDFMValues;
  tabelaPromet_Mesec9.BeforeLoadDFMValues;
  tabelaPromet_Mesec10.BeforeLoadDFMValues;
  tabelaPromet_Mesec11.BeforeLoadDFMValues;
  tabelaPromet_Mesec12.BeforeLoadDFMValues;
  tabelaPromet_Skupaj.BeforeLoadDFMValues;
  vpTabelaPromet_LetoXminus1Skupaj.BeforeLoadDFMValues;
  vpTabelaPromet_ProdajaXminus1_1.BeforeLoadDFMValues;
  vpTabelaPromet_ProdajaXminus1_2.BeforeLoadDFMValues;
  vpTabelaPromet_ProdajaXminus1_3.BeforeLoadDFMValues;
  vpTabelaPromet_ProdajaXminus1_4.BeforeLoadDFMValues;
  vpTabelaPromet_ProdajaXminus1_5.BeforeLoadDFMValues;
  vpTabelaPromet_ProdajaXminus1_6.BeforeLoadDFMValues;
  vpTabelaPromet_ProdajaXminus1_7.BeforeLoadDFMValues;
  vpTabelaPromet_ProdajaXminus1_8.BeforeLoadDFMValues;
  vpTabelaPromet_ProdajaXminus1_9.BeforeLoadDFMValues;
  vpTabelaPromet_ProdajaXminus1_10.BeforeLoadDFMValues;
  vpTabelaPromet_ProdajaXminus1_11.BeforeLoadDFMValues;
  vpTabelaPromet_ProdajaXminus1_12.BeforeLoadDFMValues;
  vpPlanProdaje_RastXplus1.BeforeLoadDFMValues;
  vpPlanProdaje_RastXplus2.BeforeLoadDFMValues;
  vpPlanProdaje_RastXplus3.BeforeLoadDFMValues;
  vpPlanProdaje_RastXplus4.BeforeLoadDFMValues;
  DelovniList4.BeforeLoadDFMValues;
  WebScrollBoxDL4.BeforeLoadDFMValues;
  izNaslovDL4.BeforeLoadDFMValues;
  ploscicaTabelaOrganizacijskihVprasanj.BeforeLoadDFMValues;
  izOrganizacija_St1.BeforeLoadDFMValues;
  izOrganizacija_mera1.BeforeLoadDFMValues;
  izOrganizacija_St2.BeforeLoadDFMValues;
  izOrganizacija_mera2.BeforeLoadDFMValues;
  izOrganizacija_St3.BeforeLoadDFMValues;
  izOrganizacija_mera3.BeforeLoadDFMValues;
  izOrganizacija_St4.BeforeLoadDFMValues;
  izOrganizacija_mera4.BeforeLoadDFMValues;
  izOrganizacija_St5.BeforeLoadDFMValues;
  izOrganizacija_mera5.BeforeLoadDFMValues;
  izOrganizacija_St6.BeforeLoadDFMValues;
  izOrganizacija_mera6.BeforeLoadDFMValues;
  izOrganizacija_St7.BeforeLoadDFMValues;
  izOrganizacija_mera7.BeforeLoadDFMValues;
  izOrganizacija_St8.BeforeLoadDFMValues;
  izOrganizacija_mera8.BeforeLoadDFMValues;
  izOrganizacija_St9.BeforeLoadDFMValues;
  izOrganizacija_mera9.BeforeLoadDFMValues;
  izOrganizacija_St10.BeforeLoadDFMValues;
  izOrganizacija_mera10.BeforeLoadDFMValues;
  izOrganizacija_St11.BeforeLoadDFMValues;
  izOrganizacija_OdgovorPrazen11.BeforeLoadDFMValues;
  izOrganizacija_MeraPrazen11.BeforeLoadDFMValues;
  izOrganizacija_StPrazen11_1.BeforeLoadDFMValues;
  izOrganizacija_MeraPrazen11_1.BeforeLoadDFMValues;
  izOrganizacija_StPrazen11_2.BeforeLoadDFMValues;
  izOrganizacija_mera11_2.BeforeLoadDFMValues;
  izOrganizacija_StPrazen11_3.BeforeLoadDFMValues;
  izOrganizacija_mera11_3.BeforeLoadDFMValues;
  izOrganizacija_StPrazen11_4.BeforeLoadDFMValues;
  izOrganizacija_MeraPrazen11_4.BeforeLoadDFMValues;
  izOrganizacija_StPrazen11_5.BeforeLoadDFMValues;
  izOrganizacija_mera11_5.BeforeLoadDFMValues;
  izOrganizacija_StPrazen11_6.BeforeLoadDFMValues;
  izOrganizacija_mera11_6.BeforeLoadDFMValues;
  izOrganizacija_St12.BeforeLoadDFMValues;
  izOrganizacija_mera12.BeforeLoadDFMValues;
  izOrganizacija_St13.BeforeLoadDFMValues;
  izOrganizacija_mera13.BeforeLoadDFMValues;
  izOrganizacija_Vprasanje1.BeforeLoadDFMValues;
  vpOrganizacija_Odgovor1.BeforeLoadDFMValues;
  izOrganizacija_Vprasanje2.BeforeLoadDFMValues;
  vpOrganizacija_Odgovor2.BeforeLoadDFMValues;
  izOrganizacija_Vprasanje3.BeforeLoadDFMValues;
  ploscicaOrganizacija_Odgovor3.BeforeLoadDFMValues;
  vpOrganizacija_OdgovorDa3.BeforeLoadDFMValues;
  vpOrganizacija_OdgovorNe3.BeforeLoadDFMValues;
  izOrganizacija_Vprasanje4.BeforeLoadDFMValues;
  ploscicaOrganizacija_Odgovor4.BeforeLoadDFMValues;
  vpOrganizacija_OdgovorDa4.BeforeLoadDFMValues;
  vpOrganizacija_OdgovorNe4.BeforeLoadDFMValues;
  izOrganizacija_Vprasanje5.BeforeLoadDFMValues;
  ploscicaOrganizacija_Odgovor5.BeforeLoadDFMValues;
  vpOrganizacija_OdgovorDa5.BeforeLoadDFMValues;
  vpOrganizacija_OdgovorNe5.BeforeLoadDFMValues;
  izOrganizacija_Vprasanje6.BeforeLoadDFMValues;
  ploscicaOrganizacija_Odgovor6.BeforeLoadDFMValues;
  vpOrganizacija_OdgovorDa6.BeforeLoadDFMValues;
  vpOrganizacija_OdgovorNe6.BeforeLoadDFMValues;
  izOrganizacija_Vprasanje7.BeforeLoadDFMValues;
  ploscicaOrganizacija_Odgovor7.BeforeLoadDFMValues;
  vpOrganizacija_OdgovorDa7.BeforeLoadDFMValues;
  vpOrganizacija_OdgovorNe7.BeforeLoadDFMValues;
  izOrganizacija_Vprasanje8.BeforeLoadDFMValues;
  ploscicaOrganizacija_Odgovor8.BeforeLoadDFMValues;
  vpOrganizacija_OdgovorDa8.BeforeLoadDFMValues;
  vpOrganizacija_OdgovorNe8.BeforeLoadDFMValues;
  izOrganizacija_Vprasanje9.BeforeLoadDFMValues;
  vpOrganizacija_Odgovor9.BeforeLoadDFMValues;
  izOrganizacija_Vprasanje10.BeforeLoadDFMValues;
  vpOrganizacija_Odgovor10.BeforeLoadDFMValues;
  izOrganizacija_Vprasanje11.BeforeLoadDFMValues;
  izOrganizacija_Vprasanje11_1.BeforeLoadDFMValues;
  ploscicaOrganizacija_Odgovor11_1.BeforeLoadDFMValues;
  vpOrganizacija_OdgovorDa11_1.BeforeLoadDFMValues;
  vpOrganizacija_OdgovorNe11_1.BeforeLoadDFMValues;
  izOrganizacija_Vprasanje11_2.BeforeLoadDFMValues;
  vpOrganizacija_Odgovor11_2.BeforeLoadDFMValues;
  izOrganizacija_Vprasanje11_3.BeforeLoadDFMValues;
  vpOrganizacija_Odgovor11_3.BeforeLoadDFMValues;
  izOrganizacija_Vprasanje11_4.BeforeLoadDFMValues;
  ploscicaOrganizacija_Odgovor11_4.BeforeLoadDFMValues;
  vpOrganizacija_OdgovorDa11_4.BeforeLoadDFMValues;
  vpOrganizacija_OdgovorNe11_4.BeforeLoadDFMValues;
  izOrganizacija_Vprasanje11_5.BeforeLoadDFMValues;
  vpOrganizacija_Odgovor11_5.BeforeLoadDFMValues;
  izOrganizacija_Vprasanje11_6.BeforeLoadDFMValues;
  vpOrganizacija_Odgovor11_6.BeforeLoadDFMValues;
  izOrganizacija_Vprasanje12.BeforeLoadDFMValues;
  vpOrganizacija_Odgovor12.BeforeLoadDFMValues;
  izOrganizacija_Vprasanje13.BeforeLoadDFMValues;
  vpOrganizacija_Odgovor13.BeforeLoadDFMValues;
  DelovniList5.BeforeLoadDFMValues;
  WebScrollBox2.BeforeLoadDFMValues;
  izNaslovDL5.BeforeLoadDFMValues;
  ploscicaPodatkiOZavarovanju.BeforeLoadDFMValues;
  izTekstZVKosmatiDobicek.BeforeLoadDFMValues;
  izTekstStroski1.BeforeLoadDFMValues;
  izTekstStroski2.BeforeLoadDFMValues;
  izTekstMDJ.BeforeLoadDFMValues;
  izTekstZacetekZavarovanja.BeforeLoadDFMValues;
  izTekstPremija.BeforeLoadDFMValues;
  izMDJ.BeforeLoadDFMValues;
  vpZavarovanjeDAKosmatiDobicek.BeforeLoadDFMValues;
  vpZVKosmatiDobicek.BeforeLoadDFMValues;
  vpZavarovanjeDANastetiStroski.BeforeLoadDFMValues;
  ploscicaNastetiStroski.BeforeLoadDFMValues;
  izZavarovaniStroski1_1.BeforeLoadDFMValues;
  izZavarovaniStroski2_1.BeforeLoadDFMValues;
  izZavarovaniStroski1_2.BeforeLoadDFMValues;
  izZavarovaniStroski1_3.BeforeLoadDFMValues;
  izZavarovaniStroski1_4.BeforeLoadDFMValues;
  izZavarovaniStroski1_5.BeforeLoadDFMValues;
  izZavarovaniStroski1_6.BeforeLoadDFMValues;
  vpZavarovaniStroski2_2.BeforeLoadDFMValues;
  vpZavarovaniStroski2_3.BeforeLoadDFMValues;
  vpZavarovaniStroski2_4.BeforeLoadDFMValues;
  vpZavarovaniStroski2_5.BeforeLoadDFMValues;
  vpZavarovaniStroski2_6.BeforeLoadDFMValues;
  vpZVNastetiStroski.BeforeLoadDFMValues;
  vpMDJ.BeforeLoadDFMValues;
  vpZacetekZavarovanja.BeforeLoadDFMValues;
  vpPremija.BeforeLoadDFMValues;
  vpZavarovanjeDa.BeforeLoadDFMValues;
  DelovniList6.BeforeLoadDFMValues;
  WebScrollBoxDL6.BeforeLoadDFMValues;
  izNaslovDL6.BeforeLoadDFMValues;
  izNastavljanjeOsnovnihParametrov.BeforeLoadDFMValues;
  ploscicaKorekcijskiFaktorji.BeforeLoadDFMValues;
  izPloscicaKorekcijskiFaktorji_Naslov1.BeforeLoadDFMValues;
  izPloscicaKorekcijskiFaktorji_Naslov2.BeforeLoadDFMValues;
  izPloscicaKorekcijskiFaktorji_Naslov3.BeforeLoadDFMValues;
  izPloscicaKorekcijskiFaktorji_Naslov4.BeforeLoadDFMValues;
  izPloscicaKorekcijskiFaktorji_KF1_1.BeforeLoadDFMValues;
  izPloscicaKorekcijskiFaktorji_KF1_2.BeforeLoadDFMValues;
  izPloscicaKorekcijskiFaktorji_KF1_3.BeforeLoadDFMValues;
  izPloscicaKorekcijskiFaktorji_KF2_1.BeforeLoadDFMValues;
  izPloscicaKorekcijskiFaktorji_KF2_2.BeforeLoadDFMValues;
  izPloscicaKorekcijskiFaktorji_KF2_3.BeforeLoadDFMValues;
  izPloscicaKorekcijskiFaktorji_KF3_1.BeforeLoadDFMValues;
  izPloscicaKorekcijskiFaktorji_KF3_2.BeforeLoadDFMValues;
  izPloscicaKorekcijskiFaktorji_KF3_3.BeforeLoadDFMValues;
  izPloscicaKorekcijskiFaktorji_KF4_1.BeforeLoadDFMValues;
  izPloscicaKorekcijskiFaktorji_KF4_2.BeforeLoadDFMValues;
  izPloscicaKorekcijskiFaktorji_KF4_3.BeforeLoadDFMValues;
  izPloscicaKorekcijskiFaktorji_KF5_1.BeforeLoadDFMValues;
  izPloscicaKorekcijskiFaktorji_KF5_2.BeforeLoadDFMValues;
  izPloscicaKorekcijskiFaktorji_KF5_3.BeforeLoadDFMValues;
  ploscicaPloscicaKorekcijskiFaktor_1.BeforeLoadDFMValues;
  vpPloscicaKorekcijskiFaktorji_KFIzpis1_4.BeforeLoadDFMValues;
  vpPloscicaKorekcijskiFaktorji_KFDrsnik1_4.BeforeLoadDFMValues;
  ploscicaPloscicaKorekcijskiFaktor_2.BeforeLoadDFMValues;
  vpPloscicaKorekcijskiFaktorji_KFIzpis2_4.BeforeLoadDFMValues;
  vpPloscicaKorekcijskiFaktorji_KFDrsnik2_4.BeforeLoadDFMValues;
  ploscicaPloscicaKorekcijskiFaktor_3.BeforeLoadDFMValues;
  vpPloscicaKorekcijskiFaktorji_KFIzpis3_4.BeforeLoadDFMValues;
  vpPloscicaKorekcijskiFaktorji_KFDrsnik3_4.BeforeLoadDFMValues;
  ploscicaPloscicaKorekcijskiFaktor_4.BeforeLoadDFMValues;
  vpPloscicaKorekcijskiFaktorji_KFIzpis4_4.BeforeLoadDFMValues;
  vpPloscicaKorekcijskiFaktorji_KFDrsnik4_4.BeforeLoadDFMValues;
  ploscicaPloscicaKorekcijskiFaktor_5.BeforeLoadDFMValues;
  vpPloscicaKorekcijskiFaktorji_KFIzpis5_4.BeforeLoadDFMValues;
  vpPloscicaKorekcijskiFaktorji_KFDrsnik5_4.BeforeLoadDFMValues;
  ploscicaMaksimalniPribitek.BeforeLoadDFMValues;
  izMaksimalniPribitek.BeforeLoadDFMValues;
  vpMaksimalniPribitek.BeforeLoadDFMValues;
  ploscicaMDJRezultat.BeforeLoadDFMValues;
  izTekstPriporocenaMDJ.BeforeLoadDFMValues;
  izPriporocenaMDJ.BeforeLoadDFMValues;
  ploscicaParametriMDJ.BeforeLoadDFMValues;
  izParametriMDJ_Naslov_1.BeforeLoadDFMValues;
  izParametriMDJ_Naslov_2.BeforeLoadDFMValues;
  izParametriMDJ_Naslov_3.BeforeLoadDFMValues;
  izParametriMDJ_Parameter1.BeforeLoadDFMValues;
  izParametriMDJ_Parameter2.BeforeLoadDFMValues;
  izParametriMDJ_Parameter3.BeforeLoadDFMValues;
  izParametriMDJ_Parameter4.BeforeLoadDFMValues;
  izParametriMDJ_Parameter5.BeforeLoadDFMValues;
  izParametriMDJ_Parameter6.BeforeLoadDFMValues;
  izParametriMDJ_PrazenParameter6_2.BeforeLoadDFMValues;
  izParametriMDJ_Parameter7.BeforeLoadDFMValues;
  izParametriMDJ_PrazenParameter7_2.BeforeLoadDFMValues;
  ploscicaParameter1_1.BeforeLoadDFMValues;
  izParameter1_1.BeforeLoadDFMValues;
  vpParameter1_1.BeforeLoadDFMValues;
  ploscicaParameter1_2.BeforeLoadDFMValues;
  izParameter1_2.BeforeLoadDFMValues;
  vpParameter1_2.BeforeLoadDFMValues;
  ploscicaParameter2_1.BeforeLoadDFMValues;
  izParameter2_1.BeforeLoadDFMValues;
  vpParameter2_1.BeforeLoadDFMValues;
  ploscicaParameter2_2.BeforeLoadDFMValues;
  izParameter2_2.BeforeLoadDFMValues;
  vpParameter2_2.BeforeLoadDFMValues;
  ploscicaParameter3_1.BeforeLoadDFMValues;
  izParameter3_1.BeforeLoadDFMValues;
  vpParameter3_1.BeforeLoadDFMValues;
  ploscicaParameter3_2.BeforeLoadDFMValues;
  izParameter3_2.BeforeLoadDFMValues;
  vpParameter3_2.BeforeLoadDFMValues;
  ploscicaParameter4_1.BeforeLoadDFMValues;
  izParameter4_1.BeforeLoadDFMValues;
  vpParameter4_1.BeforeLoadDFMValues;
  ploscicaParameter4_2.BeforeLoadDFMValues;
  izParameter4_2.BeforeLoadDFMValues;
  vpParameter4_2.BeforeLoadDFMValues;
  ploscicaParameter5_1.BeforeLoadDFMValues;
  izParameter5_1.BeforeLoadDFMValues;
  vpParameter5_1.BeforeLoadDFMValues;
  ploscicaParameter5_2.BeforeLoadDFMValues;
  izParameter5_2.BeforeLoadDFMValues;
  vpParameter5_2.BeforeLoadDFMValues;
  ploscicaParameter6_1.BeforeLoadDFMValues;
  izParameter6_1.BeforeLoadDFMValues;
  vpParameter6_1.BeforeLoadDFMValues;
  ploscicaParameter7_1.BeforeLoadDFMValues;
  izParameter7_1.BeforeLoadDFMValues;
  vpParameter7_1.BeforeLoadDFMValues;
  vpKorekcijskiFaktorji.BeforeLoadDFMValues;
  ploscicaGrafMDJ.BeforeLoadDFMValues;
  GrafMDJ.BeforeLoadDFMValues;
  ploscicaGrafikaMDJ_osnovni.BeforeLoadDFMValues;
  izGraficniPrikazMDJ.BeforeLoadDFMValues;
  ploscicaGrafikaMDJ.BeforeLoadDFMValues;
  izGrafikaMDJ_DA.BeforeLoadDFMValues;
  izGrafikaMDJ_NE.BeforeLoadDFMValues;
  vpGrafikaMDJ.BeforeLoadDFMValues;
  DelovniList7.BeforeLoadDFMValues;
  WebScrollBoxDL7.BeforeLoadDFMValues;
  izNaslovDL7.BeforeLoadDFMValues;
  ploscicaPriporoceneZV.BeforeLoadDFMValues;
  izTekstLetoPriporoceneZV.BeforeLoadDFMValues;
  ploscicaLetoPriporoceneZV.BeforeLoadDFMValues;
  izLetoPriporoceneZVmax.BeforeLoadDFMValues;
  izLetoPriporoceneZVmin.BeforeLoadDFMValues;
  vpLetoPriporoceneZV.BeforeLoadDFMValues;
  ploscicaTabelaPriporoceneZV.BeforeLoadDFMValues;
  izPriporoceneZV_Naslov1.BeforeLoadDFMValues;
  izPriporoceneZV_Naslov2.BeforeLoadDFMValues;
  izPriporoceneZV_tekstZV12.BeforeLoadDFMValues;
  izPriporoceneZV_ZV12.BeforeLoadDFMValues;
  izPriporoceneZV_tekstZV18.BeforeLoadDFMValues;
  izPriporoceneZV_ZV18.BeforeLoadDFMValues;
  izPriporoceneZV_tekstZV24.BeforeLoadDFMValues;
  izPriporoceneZV_ZV24.BeforeLoadDFMValues;
  ploscicaZVRezultat.BeforeLoadDFMValues;
  izTekstPriporoceniKD.BeforeLoadDFMValues;
  izTekstPriporocenaSKD.BeforeLoadDFMValues;
  izPriporoceniKD.BeforeLoadDFMValues;
  izPriporocenaSKD.BeforeLoadDFMValues;
  DelovniList8.BeforeLoadDFMValues;
  WebScrollBoxDL8.BeforeLoadDFMValues;
  izNaslovDL8.BeforeLoadDFMValues;
  ListiProdajnihTrendov.BeforeLoadDFMValues;
  LPT1_Tabela.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_Meseci.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izMesec_Naslov.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izMesec_1.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izMesec_2.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izMesec_3.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izMesec_4.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izMesec_5.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izMesec_6.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izMesec_7.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izMesec_8.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izMesec_9.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izMesec_10.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izMesec_11.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izMesec_12.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_PrometX.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izPromet_NaslovX.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izPromet_2_1.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izPromet_2_2.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izPromet_2_3.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izPromet_2_4.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izPromet_2_5.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izPromet_2_6.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izPromet_2_7.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izPromet_2_8.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izPromet_2_9.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izPromet_2_10.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izPromet_2_11.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izPromet_2_12.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_PrometXminus1.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izPromet_NaslovXminus1.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izPromet_1_1.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izPromet_1_2.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izPromet_1_3.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izPromet_1_4.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izPromet_1_5.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izPromet_1_6.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izPromet_1_7.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izPromet_1_8.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izPromet_1_9.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izPromet_1_10.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izPromet_1_11.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izPromet_1_12.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_PrometXplus1.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izPromet_NaslovXplus1.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izPromet_3_1.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izPromet_3_2.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izPromet_3_3.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izPromet_3_4.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izPromet_3_5.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izPromet_3_6.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izPromet_3_7.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izPromet_3_8.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izPromet_3_9.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izPromet_3_10.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izPromet_3_11.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izPromet_3_12.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_PrometXplus2.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izPromet_NaslovXplus2.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izPromet_4_1.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izPromet_4_2.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izPromet_4_3.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izPromet_4_4.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izPromet_4_5.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izPromet_4_6.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izPromet_4_7.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izPromet_4_8.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izPromet_4_9.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izPromet_4_10.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izPromet_4_11.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izPromet_4_12.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_PrometXplus3.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izPromet_NaslovXplus3.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izPromet_5_1.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izPromet_5_2.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izPromet_5_3.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izPromet_5_4.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izPromet_5_5.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izPromet_5_6.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izPromet_5_7.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izPromet_5_8.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izPromet_5_9.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izPromet_5_10.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izPromet_5_11.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izPromet_5_12.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_PrometXplus4.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izPromet_NaslovXplus4.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izPromet_6_1.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izPromet_6_2.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izPromet_6_3.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izPromet_6_4.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izPromet_6_5.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izPromet_6_6.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izPromet_6_7.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izPromet_6_8.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izPromet_6_9.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izPromet_6_10.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izPromet_6_11.BeforeLoadDFMValues;
  ploscicaAnalizeProdaje_izPromet_6_12.BeforeLoadDFMValues;
  LPT2_MesecniGraf.BeforeLoadDFMValues;
  GrafMesecniTrendi.BeforeLoadDFMValues;
  LPT3_LetniGraf.BeforeLoadDFMValues;
  GrafLetniTrendi.BeforeLoadDFMValues;
  DelovniList9.BeforeLoadDFMValues;
  WebScrollBoxDL9.BeforeLoadDFMValues;
  izNaslovDL9.BeforeLoadDFMValues;
  ploscicaObstojeceZavarovanje.BeforeLoadDFMValues;
  izPloscicaObstojeceZavarovanje_Vprasanje1.BeforeLoadDFMValues;
  izPloscicaObstojeceZavarovanje_Odgovor1.BeforeLoadDFMValues;
  izPloscicaObstojeceZavarovanje_Semafor1.BeforeLoadDFMValues;
  izPloscicaObstojeceZavarovanje_prazna1.BeforeLoadDFMValues;
  izPloscicaObstojeceZavarovanje_Vprasanje2.BeforeLoadDFMValues;
  izPloscicaObstojeceZavarovanje_Odgovor2.BeforeLoadDFMValues;
  izPloscicaObstojeceZavarovanje_Semafor2.BeforeLoadDFMValues;
  izPloscicaObstojeceZavarovanje_prazna2.BeforeLoadDFMValues;
  izPloscicaObstojeceZavarovanje_Vprasanje3.BeforeLoadDFMValues;
  izPloscicaObstojeceZavarovanje_Odgovor3.BeforeLoadDFMValues;
  izPloscicaObstojeceZavarovanje_Semafor3.BeforeLoadDFMValues;
  izPloscicaObstojeceZavarovanje_Semafor3b.BeforeLoadDFMValues;
  izPloscicaObstojeceZavarovanje_Vprasanje4.BeforeLoadDFMValues;
  izPloscicaObstojeceZavarovanje_Odgovor4.BeforeLoadDFMValues;
  izPloscicaObstojeceZavarovanje_Semafor4.BeforeLoadDFMValues;
  izPloscicaObstojeceZavarovanje_prazna4.BeforeLoadDFMValues;
  DelovniList10.BeforeLoadDFMValues;
  WebScrollBoxDL10.BeforeLoadDFMValues;
  izDatumFizicneSkode.BeforeLoadDFMValues;
  izNaslovDL10.BeforeLoadDFMValues;
  gPreracunaj.BeforeLoadDFMValues;
  ploscicaPodatkiZaSimulacijoSkode.BeforeLoadDFMValues;
  DelovniListiPodatkiZaSimulacijoSkod.BeforeLoadDFMValues;
  WebTabSheet1.BeforeLoadDFMValues;
  izGraficniPrikazSimulacijaSkode.BeforeLoadDFMValues;
  ploscicaTabelaPodatkovOPrometu.BeforeLoadDFMValues;
  ploscicaPromet_Naslov1.BeforeLoadDFMValues;
  ploscicaPromet_Naslov2.BeforeLoadDFMValues;
  ploscicaPromet_Naslov3.BeforeLoadDFMValues;
  ploscicaPromet_Naslov4.BeforeLoadDFMValues;
  ploscicaPromet_Naslov5.BeforeLoadDFMValues;
  ploscicaPromet_Naslov6.BeforeLoadDFMValues;
  ploscicaPromet_Naslov7.BeforeLoadDFMValues;
  ploscicaPromet_Naslov8.BeforeLoadDFMValues;
  ploscicaPromet_Mesec1.BeforeLoadDFMValues;
  ploscicaPromet_Mesec2.BeforeLoadDFMValues;
  ploscicaPromet_Mesec3.BeforeLoadDFMValues;
  ploscicaPromet_Mesec4.BeforeLoadDFMValues;
  ploscicaPromet_Mesec5.BeforeLoadDFMValues;
  ploscicaPromet_Mesec6.BeforeLoadDFMValues;
  ploscicaPromet_Mesec7.BeforeLoadDFMValues;
  ploscicaPromet_Mesec8.BeforeLoadDFMValues;
  ploscicaPromet_Mesec9.BeforeLoadDFMValues;
  ploscicaPromet_Mesec10.BeforeLoadDFMValues;
  ploscicaPromet_Mesec11.BeforeLoadDFMValues;
  ploscicaPromet_Mesec12.BeforeLoadDFMValues;
  ploscicaPromet_Promet_1_1.BeforeLoadDFMValues;
  ploscicaPromet_Promet_2_1.BeforeLoadDFMValues;
  ploscicaPromet_Promet_3_1.BeforeLoadDFMValues;
  ploscicaPromet_Promet_4_1.BeforeLoadDFMValues;
  ploscicaPromet_Indeks_1_1.BeforeLoadDFMValues;
  ploscicaPromet_Indeks_2_1.BeforeLoadDFMValues;
  ploscicaPromet_Indeks_3_1.BeforeLoadDFMValues;
  ploscicaPromet_Promet_1_2.BeforeLoadDFMValues;
  ploscicaPromet_Promet_2_2.BeforeLoadDFMValues;
  ploscicaPromet_Promet_3_2.BeforeLoadDFMValues;
  ploscicaPromet_Promet_4_2.BeforeLoadDFMValues;
  ploscicaPromet_Indeks_1_2.BeforeLoadDFMValues;
  ploscicaPromet_Indeks_2_2.BeforeLoadDFMValues;
  ploscicaPromet_Indeks_3_2.BeforeLoadDFMValues;
  ploscicaPromet_Promet_1_3.BeforeLoadDFMValues;
  ploscicaPromet_Promet_2_3.BeforeLoadDFMValues;
  ploscicaPromet_Promet_3_3.BeforeLoadDFMValues;
  ploscicaPromet_Promet_4_3.BeforeLoadDFMValues;
  ploscicaPromet_Indeks_1_3.BeforeLoadDFMValues;
  ploscicaPromet_Indeks_2_3.BeforeLoadDFMValues;
  ploscicaPromet_Indeks_3_3.BeforeLoadDFMValues;
  ploscicaPromet_Promet_1_4.BeforeLoadDFMValues;
  ploscicaPromet_Promet_2_4.BeforeLoadDFMValues;
  ploscicaPromet_Promet_3_4.BeforeLoadDFMValues;
  ploscicaPromet_Promet_4_4.BeforeLoadDFMValues;
  ploscicaPromet_Indeks_1_4.BeforeLoadDFMValues;
  ploscicaPromet_Indeks_2_4.BeforeLoadDFMValues;
  ploscicaPromet_Indeks_3_4.BeforeLoadDFMValues;
  ploscicaPromet_Promet_1_5.BeforeLoadDFMValues;
  ploscicaPromet_Promet_2_5.BeforeLoadDFMValues;
  ploscicaPromet_Promet_3_5.BeforeLoadDFMValues;
  ploscicaPromet_Promet_4_5.BeforeLoadDFMValues;
  ploscicaPromet_Indeks_1_5.BeforeLoadDFMValues;
  ploscicaPromet_Indeks_2_5.BeforeLoadDFMValues;
  ploscicaPromet_Indeks_3_5.BeforeLoadDFMValues;
  ploscicaPromet_Promet_1_6.BeforeLoadDFMValues;
  ploscicaPromet_Promet_2_6.BeforeLoadDFMValues;
  ploscicaPromet_Promet_3_6.BeforeLoadDFMValues;
  ploscicaPromet_Promet_4_6.BeforeLoadDFMValues;
  ploscicaPromet_Indeks_1_6.BeforeLoadDFMValues;
  ploscicaPromet_Indeks_2_6.BeforeLoadDFMValues;
  ploscicaPromet_Indeks_3_6.BeforeLoadDFMValues;
  ploscicaPromet_Promet_1_7.BeforeLoadDFMValues;
  ploscicaPromet_Promet_2_7.BeforeLoadDFMValues;
  ploscicaPromet_Promet_3_7.BeforeLoadDFMValues;
  ploscicaPromet_Promet_4_7.BeforeLoadDFMValues;
  ploscicaPromet_Indeks_1_7.BeforeLoadDFMValues;
  ploscicaPromet_Indeks_2_7.BeforeLoadDFMValues;
  ploscicaPromet_Indeks_3_7.BeforeLoadDFMValues;
  ploscicaPromet_Promet_1_8.BeforeLoadDFMValues;
  ploscicaPromet_Promet_2_8.BeforeLoadDFMValues;
  ploscicaPromet_Promet_3_8.BeforeLoadDFMValues;
  ploscicaPromet_Promet_4_8.BeforeLoadDFMValues;
  ploscicaPromet_Indeks_1_8.BeforeLoadDFMValues;
  ploscicaPromet_Indeks_2_8.BeforeLoadDFMValues;
  ploscicaPromet_Indeks_3_8.BeforeLoadDFMValues;
  ploscicaPromet_Promet_1_9.BeforeLoadDFMValues;
  ploscicaPromet_Promet_2_9.BeforeLoadDFMValues;
  ploscicaPromet_Promet_3_9.BeforeLoadDFMValues;
  ploscicaPromet_Promet_4_9.BeforeLoadDFMValues;
  ploscicaPromet_Indeks_1_9.BeforeLoadDFMValues;
  ploscicaPromet_Indeks_2_9.BeforeLoadDFMValues;
  ploscicaPromet_Indeks_3_9.BeforeLoadDFMValues;
  ploscicaPromet_Promet_1_10.BeforeLoadDFMValues;
  ploscicaPromet_Promet_2_10.BeforeLoadDFMValues;
  ploscicaPromet_Promet_3_10.BeforeLoadDFMValues;
  ploscicaPromet_Promet_4_10.BeforeLoadDFMValues;
  ploscicaPromet_Indeks_1_10.BeforeLoadDFMValues;
  ploscicaPromet_Indeks_2_10.BeforeLoadDFMValues;
  ploscicaPromet_Indeks_3_10.BeforeLoadDFMValues;
  ploscicaPromet_Promet_1_11.BeforeLoadDFMValues;
  ploscicaPromet_Promet_2_11.BeforeLoadDFMValues;
  ploscicaPromet_Promet_3_11.BeforeLoadDFMValues;
  ploscicaPromet_Promet_4_11.BeforeLoadDFMValues;
  ploscicaPromet_Indeks_1_11.BeforeLoadDFMValues;
  ploscicaPromet_Indeks_2_11.BeforeLoadDFMValues;
  ploscicaPromet_Indeks_3_11.BeforeLoadDFMValues;
  ploscicaPromet_Promet_1_12.BeforeLoadDFMValues;
  ploscicaPromet_Promet_2_12.BeforeLoadDFMValues;
  ploscicaPromet_Promet_3_12.BeforeLoadDFMValues;
  ploscicaPromet_Promet_4_12.BeforeLoadDFMValues;
  ploscicaPromet_Indeks_1_12.BeforeLoadDFMValues;
  ploscicaPromet_Indeks_2_12.BeforeLoadDFMValues;
  ploscicaPromet_Indeks_3_12.BeforeLoadDFMValues;
  gVnosPopolnegaZastoja.BeforeLoadDFMValues;
  gAvtomaticnoPolnjenje.BeforeLoadDFMValues;
  gVnesiKrivuljoZastoja.BeforeLoadDFMValues;
  ploscicaGrafikaSkode.BeforeLoadDFMValues;
  izGrafikaSkode_DA.BeforeLoadDFMValues;
  izGrafikaSkode_NE.BeforeLoadDFMValues;
  vpGrafikaSkode.BeforeLoadDFMValues;
  WebTabSheet2.BeforeLoadDFMValues;
  ploscicaPovisaniStroski.BeforeLoadDFMValues;
  izPovisaniPoslovniStroskiOpis.BeforeLoadDFMValues;
  izResenPrometOpis.BeforeLoadDFMValues;
  izPovisaniPoslovniStroskiObdobjeOpis.BeforeLoadDFMValues;
  vpPovisaniPoslovniStroskiZnesek.BeforeLoadDFMValues;
  vpResenPrometZnesek.BeforeLoadDFMValues;
  vpPovisaniPoslovniStroskiObdobje.BeforeLoadDFMValues;
  WebTabSheet3.BeforeLoadDFMValues;
  izPrihrankiObdobjeOpis.BeforeLoadDFMValues;
  ploscicaPrihranki.BeforeLoadDFMValues;
  ploscicaPrihranki_Naslov1.BeforeLoadDFMValues;
  ploscicaPrihranki_Naslov2.BeforeLoadDFMValues;
  ploscicaPrihranki_Prihranek_1_1.BeforeLoadDFMValues;
  ploscicaPrihranki_Prihranek_2_1.BeforeLoadDFMValues;
  ploscicaPrihranki_Prihranek_1_2.BeforeLoadDFMValues;
  ploscicaPrihranki_Prihranek_2_2.BeforeLoadDFMValues;
  ploscicaPrihranki_Prihranek_1_3.BeforeLoadDFMValues;
  ploscicaPrihranki_Prihranek_2_3.BeforeLoadDFMValues;
  ploscicaPrihranki_Prihranek_1_4.BeforeLoadDFMValues;
  ploscicaPrihranki_Prihranek_2_4.BeforeLoadDFMValues;
  ploscicaPrihranki_Prihranek_1_5.BeforeLoadDFMValues;
  ploscicaPrihranki_Prihranek_2_5.BeforeLoadDFMValues;
  ploscicaPrihranki_Prihranek_1_6.BeforeLoadDFMValues;
  ploscicaPrihranki_Prihranek_2_6.BeforeLoadDFMValues;
  ploscicaPrihranki_Prihranek_1_7.BeforeLoadDFMValues;
  ploscicaPrihranki_Prihranek_2_7.BeforeLoadDFMValues;
  ploscicaPrihranki_Prihranek_1_8.BeforeLoadDFMValues;
  ploscicaPrihranki_Prihranek_2_8.BeforeLoadDFMValues;
  ploscicaPrihranki_Prihranek_1_9.BeforeLoadDFMValues;
  ploscicaPrihranki_Prihranek_2_9.BeforeLoadDFMValues;
  ploscicaPrihranki_Prihranek_1_10.BeforeLoadDFMValues;
  ploscicaPrihranki_Prihranek_2_10.BeforeLoadDFMValues;
  ploscicaPrihranki_PrihranekSkupajOpis.BeforeLoadDFMValues;
  ploscicaPrihranki_PrihranekSkupajZnesek.BeforeLoadDFMValues;
  vpPrihrankiObdobje.BeforeLoadDFMValues;
  gShraniSkodo.BeforeLoadDFMValues;
  gNaloziSkodo.BeforeLoadDFMValues;
  ploscicaSimulacijaSkode_PodatkiOZavarovanju.BeforeLoadDFMValues;
  izPodatkiOZavarovanju_Naslov.BeforeLoadDFMValues;
  izPodatkioZavarovanju_Od.BeforeLoadDFMValues;
  izPodatkioZavarovanju_Do.BeforeLoadDFMValues;
  izPodatkioZavarovanju_ZavarovalnaVsota.BeforeLoadDFMValues;
  izPodatkioZavarovanju_MaksimalnaDobaJamcenja.BeforeLoadDFMValues;
  izPodatkioZavarovanju_Premija.BeforeLoadDFMValues;
  izMaksimalnaDobaJamcenjaTekst.BeforeLoadDFMValues;
  vpZavarovanjeOd.BeforeLoadDFMValues;
  vpZavarovanjeDo.BeforeLoadDFMValues;
  izZavarovalnaVsota.BeforeLoadDFMValues;
  izMaksimalnaDobaJamcenja.BeforeLoadDFMValues;
  izPremija.BeforeLoadDFMValues;
  vpDatumFizicneSkode.BeforeLoadDFMValues;
  ploscicaGrafSimulacijaSkode.BeforeLoadDFMValues;
  GrafSimulacijaSkode.BeforeLoadDFMValues;
  DelovniList0B.BeforeLoadDFMValues;
  WebScrollBoxDL0B.BeforeLoadDFMValues;
  gNaloziProjekt.BeforeLoadDFMValues;
  gPrekiniDelo.BeforeLoadDFMValues;
  gZacniNovProjekt.BeforeLoadDFMValues;
  ploscicaNaslovDL0B.BeforeLoadDFMValues;
  izNaslov2_1_DL0B.BeforeLoadDFMValues;
  izNaslov2_2_DL0B.BeforeLoadDFMValues;
  izNaslov2_3_DL0B.BeforeLoadDFMValues;
  logo_ModelBI_DL0B.BeforeLoadDFMValues;
  ploscicaGlava.BeforeLoadDFMValues;
  logoRC1.BeforeLoadDFMValues;
  izVerzija.BeforeLoadDFMValues;
  ploscicaIzborPogledov.BeforeLoadDFMValues;
  izIzborVhodniPodatki.BeforeLoadDFMValues;
  izIzborAnalize.BeforeLoadDFMValues;
  izIzborSimulacijeSkod.BeforeLoadDFMValues;
  izborVhodniPodatki.BeforeLoadDFMValues;
  izborAnalize.BeforeLoadDFMValues;
  izborSimulacijeSkod.BeforeLoadDFMValues;
  ploscicaUporabnik.BeforeLoadDFMValues;
  izUporabnikTekst.BeforeLoadDFMValues;
  izPodjetjeTekst.BeforeLoadDFMValues;
  izUporabnik.BeforeLoadDFMValues;
  izPotekLicenceTekst.BeforeLoadDFMValues;
  izPodjetje.BeforeLoadDFMValues;
  izPotekLicence.BeforeLoadDFMValues;
  ploscicaGlavniMeni.BeforeLoadDFMValues;
  PovezavaServerGeslo.BeforeLoadDFMValues;
  WebClientGeslo.BeforeLoadDFMValues;
  PovezavaServerPodatki.BeforeLoadDFMValues;
  WebClientPodatki.BeforeLoadDFMValues;
  PovezavaWSPopolniPreracun.BeforeLoadDFMValues;
  WebClientWSPopolniIzracun.BeforeLoadDFMValues;
  PovezavaWSDelniIzracuni.BeforeLoadDFMValues;
  WebClientWSDelniIzracuni.BeforeLoadDFMValues;
  menuKrivuljaZastoja.BeforeLoadDFMValues;
  mnKrivulja1.BeforeLoadDFMValues;
  mnKrivulja2.BeforeLoadDFMValues;
  mnKrivulja3.BeforeLoadDFMValues;
  menuPopolniZastoj.BeforeLoadDFMValues;
  mn3.BeforeLoadDFMValues;
  mn4.BeforeLoadDFMValues;
  mn5.BeforeLoadDFMValues;
  mn6.BeforeLoadDFMValues;
  mn7.BeforeLoadDFMValues;
  mn8.BeforeLoadDFMValues;
  mn9.BeforeLoadDFMValues;
  mn10.BeforeLoadDFMValues;
  mn11.BeforeLoadDFMValues;
  mn12.BeforeLoadDFMValues;
  PovezavaServerShranjevanje.BeforeLoadDFMValues;
  WebClientShranjevanje.BeforeLoadDFMValues;
  PovezavaServerJezik.BeforeLoadDFMValues;
  WebClientJezik.BeforeLoadDFMValues;
  mnGlavniMeni.BeforeLoadDFMValues;
  mnNovProjekt.BeforeLoadDFMValues;
  mnOdpriProjekt.BeforeLoadDFMValues;
  mnShraniProjekt.BeforeLoadDFMValues;
  mnOsveziAnalize.BeforeLoadDFMValues;
  mnOsveziPrivzeteVrednosti.BeforeLoadDFMValues;
  N6.BeforeLoadDFMValues;
  mnOsveziDejanskeVrednosti.BeforeLoadDFMValues;
  mnOstalo.BeforeLoadDFMValues;
  mnNastavitve.BeforeLoadDFMValues;
  N4.BeforeLoadDFMValues;
  mnOOrodju.BeforeLoadDFMValues;
  N5.BeforeLoadDFMValues;
  mnPomoc.BeforeLoadDFMValues;
  mnZakljuciDelo.BeforeLoadDFMValues;
  PovezavaServerSkode.BeforeLoadDFMValues;
  WebClientSkode.BeforeLoadDFMValues;
  try
    Name := 'fmOsnovniKontroler';
    Top := 206;
    Width := 1384;
    Height := 1208;
    Caption := 'ModelBI (RiskClinic solution)';
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnResize', 'WebFormResize');
    obvestilo.SetParentComponent(Self);
    obvestilo.Name := 'obvestilo';
    obvestilo.Left := 59;
    obvestilo.Top := 774;
    obvestilo.Width := 24;
    obvestilo.Height := 24;
    obvestilo.HeightPercent := 100.000000000000000000;
    obvestilo.WidthPercent := 100.000000000000000000;
    obvestilo.Buttons := [mbYes,mbNo];
    obvestilo.DialogText.BeginUpdate;
    try
      obvestilo.DialogText.Clear;
      obvestilo.DialogText.Add('Warning');
      obvestilo.DialogText.Add('Error');
      obvestilo.DialogText.Add('Information');
      obvestilo.DialogText.Add('Confirm');
      obvestilo.DialogText.Add('Custom');
      obvestilo.DialogText.Add('OK');
      obvestilo.DialogText.Add('Cancel');
      obvestilo.DialogText.Add('DA');
      obvestilo.DialogText.Add('NE');
      obvestilo.DialogText.Add('Abort');
      obvestilo.DialogText.Add('Retry');
      obvestilo.DialogText.Add('Ignore');
      obvestilo.DialogText.Add('All');
      obvestilo.DialogText.Add('Yes to All');
      obvestilo.DialogText.Add('No to All');
      obvestilo.DialogText.Add('Help');
      obvestilo.DialogText.Add('Close');
    finally
      obvestilo.DialogText.EndUpdate;
    end;
    obvestilo.DialogType := mtWarning;
    obvestilo.Message := #268'e nadaljujete, boste izgubili neshranjene podatke, ki jih niste shranili. <br> <br> Ali '#382'elite nadaljevati?';
    obvestilo.Opacity := 0.600000000000000000;
    obvestilo.Title := 'OPOZORILO';
    SetEvent(obvestilo, Self, 'OnButtonClick', 'obvestiloButtonClick');
    ploscicaDelovniListi.SetParentComponent(Self);
    ploscicaDelovniListi.Name := 'ploscicaDelovniListi';
    ploscicaDelovniListi.AlignWithMargins := True;
    ploscicaDelovniListi.Left := 224;
    ploscicaDelovniListi.Top := 95;
    ploscicaDelovniListi.Width := 1157;
    ploscicaDelovniListi.Height := 1110;
    ploscicaDelovniListi.Margins.Top := 15;
    ploscicaDelovniListi.HeightPercent := 100.000000000000000000;
    ploscicaDelovniListi.WidthPercent := 100.000000000000000000;
    ploscicaDelovniListi.Align := alClient;
    ploscicaDelovniListi.ChildOrder := 3;
    ploscicaDelovniListi.TabIndex := 0;
    ploscicaDelovniListi.ShowTabs := False;
    ploscicaDelovniListi.TabOrder := 1;
    DelovniList0A.SetParentComponent(ploscicaDelovniListi);
    DelovniList0A.Name := 'DelovniList0A';
    DelovniList0A.Left := 0;
    DelovniList0A.Top := 20;
    DelovniList0A.Width := 1157;
    DelovniList0A.Height := 1090;
    DelovniList0A.HeightPercent := 100.000000000000000000;
    DelovniList0A.WidthPercent := 100.000000000000000000;
    DelovniList0A.Caption := 'DelovniList0A';
    DelovniList0A.TabVisible := False;
    WebScrollBoxDL0A.SetParentComponent(DelovniList0A);
    WebScrollBoxDL0A.Name := 'WebScrollBoxDL0A';
    WebScrollBoxDL0A.Left := 0;
    WebScrollBoxDL0A.Top := 0;
    WebScrollBoxDL0A.Width := 1157;
    WebScrollBoxDL0A.Height := 1090;
    WebScrollBoxDL0A.HeightPercent := 100.000000000000000000;
    WebScrollBoxDL0A.WidthPercent := 100.000000000000000000;
    WebScrollBoxDL0A.Align := alClient;
    WebScrollBoxDL0A.AutoScroll := True;
    WebScrollBoxDL0A.BorderStyle := bsNone;
    WebScrollBoxDL0A.ChildOrder := 3;
    WebScrollBoxDL0A.Color := clWhite;
    GoogleReCaptcha.SetParentComponent(WebScrollBoxDL0A);
    GoogleReCaptcha.Name := 'GoogleReCaptcha';
    GoogleReCaptcha.Left := 66;
    GoogleReCaptcha.Top := 562;
    GoogleReCaptcha.Width := 177;
    GoogleReCaptcha.Height := 40;
    GoogleReCaptcha.HeightPercent := 100.000000000000000000;
    GoogleReCaptcha.WidthPercent := 100.000000000000000000;
    GoogleReCaptcha.APIKey := '6LehnLsaAAAAADSa9GDGJPTPj0raKgmg1oD048eL';
    GoogleReCaptcha.APIUrl := ' https://www.google.com/recaptcha/api/siteverify';
    ploscicaPrvaStran.SetParentComponent(WebScrollBoxDL0A);
    ploscicaPrvaStran.Name := 'ploscicaPrvaStran';
    ploscicaPrvaStran.Left := 322;
    ploscicaPrvaStran.Top := 236;
    ploscicaPrvaStran.Width := 575;
    ploscicaPrvaStran.Height := 576;
    ploscicaPrvaStran.HeightPercent := 100.000000000000000000;
    ploscicaPrvaStran.WidthPercent := 100.000000000000000000;
    ploscicaPrvaStran.BorderColor := clSilver;
    ploscicaPrvaStran.BorderStyle := bsNone;
    ploscicaPrvaStran.ChildOrder := 5;
    ploscicaPrvaStran.Color := clWindow;
    slikaJezikANG.SetParentComponent(ploscicaPrvaStran);
    slikaJezikANG.Name := 'slikaJezikANG';
    slikaJezikANG.Left := 371;
    slikaJezikANG.Top := 187;
    slikaJezikANG.Width := 32;
    slikaJezikANG.Height := 32;
    slikaJezikANG.HeightPercent := 100.000000000000000000;
    slikaJezikANG.WidthPercent := 100.000000000000000000;
    slikaJezikANG.AutoSize := True;
    slikaJezikANG.ChildOrder := 3;
    SetEvent(slikaJezikANG, Self, 'OnClick', 'slikaJezikANGClick');
    slikaJezikANG.Picture.LoadFromFile('MBI_u01.ploscicaPrvaStran.slikaJezikANG.Picture.png');
    slikaJezikSLO.SetParentComponent(ploscicaPrvaStran);
    slikaJezikSLO.Name := 'slikaJezikSLO';
    slikaJezikSLO.Left := 371;
    slikaJezikSLO.Top := 147;
    slikaJezikSLO.Width := 32;
    slikaJezikSLO.Height := 32;
    slikaJezikSLO.HeightPercent := 100.000000000000000000;
    slikaJezikSLO.WidthPercent := 100.000000000000000000;
    slikaJezikSLO.AutoSize := True;
    slikaJezikSLO.ChildOrder := 3;
    SetEvent(slikaJezikSLO, Self, 'OnClick', 'slikaJezikSLOClick');
    slikaJezikSLO.Picture.LoadFromFile('MBI_u01.ploscicaPrvaStran.slikaJezikSLO.Picture.png');
    logo_ModelBI_DL0A.SetParentComponent(ploscicaPrvaStran);
    logo_ModelBI_DL0A.Name := 'logo_ModelBI_DL0A';
    logo_ModelBI_DL0A.Left := 167;
    logo_ModelBI_DL0A.Top := 34;
    logo_ModelBI_DL0A.Width := 181;
    logo_ModelBI_DL0A.Height := 100;
    logo_ModelBI_DL0A.HeightPercent := 100.000000000000000000;
    logo_ModelBI_DL0A.WidthPercent := 100.000000000000000000;
    logo_ModelBI_DL0A.AutoSize := True;
    logo_ModelBI_DL0A.ChildOrder := 4;
    logo_ModelBI_DL0A.Picture.LoadFromFile('MBI_u01.ploscicaPrvaStran.logo_ModelBI_DL0A.Picture.png');
    slikaBrowser1.SetParentComponent(ploscicaPrvaStran);
    slikaBrowser1.Name := 'slikaBrowser1';
    slikaBrowser1.Left := 225;
    slikaBrowser1.Top := 546;
    slikaBrowser1.Width := 16;
    slikaBrowser1.Height := 16;
    slikaBrowser1.Hint := 'Google Crome';
    slikaBrowser1.HeightPercent := 100.000000000000000000;
    slikaBrowser1.WidthPercent := 100.000000000000000000;
    slikaBrowser1.ChildOrder := 5;
    slikaBrowser1.ShowHint := True;
    slikaBrowser1.Picture.LoadFromFile('MBI_u01.ploscicaPrvaStran.slikaBrowser1.Picture.png');
    slikaBrowser2.SetParentComponent(ploscicaPrvaStran);
    slikaBrowser2.Name := 'slikaBrowser2';
    slikaBrowser2.Left := 247;
    slikaBrowser2.Top := 546;
    slikaBrowser2.Width := 16;
    slikaBrowser2.Height := 16;
    slikaBrowser2.Hint := 'Mozilla Firefox';
    slikaBrowser2.HeightPercent := 100.000000000000000000;
    slikaBrowser2.WidthPercent := 100.000000000000000000;
    slikaBrowser2.ChildOrder := 5;
    slikaBrowser2.ShowHint := True;
    slikaBrowser2.Picture.LoadFromFile('MBI_u01.ploscicaPrvaStran.slikaBrowser2.Picture.png');
    slikaBrowser3.SetParentComponent(ploscicaPrvaStran);
    slikaBrowser3.Name := 'slikaBrowser3';
    slikaBrowser3.Left := 269;
    slikaBrowser3.Top := 546;
    slikaBrowser3.Width := 16;
    slikaBrowser3.Height := 16;
    slikaBrowser3.Hint := 'Apple Safari';
    slikaBrowser3.HeightPercent := 100.000000000000000000;
    slikaBrowser3.WidthPercent := 100.000000000000000000;
    slikaBrowser3.ChildOrder := 5;
    slikaBrowser3.ShowHint := True;
    slikaBrowser3.Picture.LoadFromFile('MBI_u01.ploscicaPrvaStran.slikaBrowser3.Picture.png');
    slikaBrowser4.SetParentComponent(ploscicaPrvaStran);
    slikaBrowser4.Name := 'slikaBrowser4';
    slikaBrowser4.Left := 291;
    slikaBrowser4.Top := 546;
    slikaBrowser4.Width := 16;
    slikaBrowser4.Height := 16;
    slikaBrowser4.Hint := 'Microsoft Edge';
    slikaBrowser4.HeightPercent := 100.000000000000000000;
    slikaBrowser4.WidthPercent := 100.000000000000000000;
    slikaBrowser4.ChildOrder := 5;
    slikaBrowser4.ShowHint := True;
    slikaBrowser4.Picture.LoadFromFile('MBI_u01.ploscicaPrvaStran.slikaBrowser4.Picture.png');
    izBrskalniki.SetParentComponent(ploscicaPrvaStran);
    izBrskalniki.Name := 'izBrskalniki';
    izBrskalniki.Left := 157;
    izBrskalniki.Top := 525;
    izBrskalniki.Width := 219;
    izBrskalniki.Height := 15;
    izBrskalniki.Alignment := taCenter;
    izBrskalniki.AutoSize := False;
    izBrskalniki.Caption := 'Deluje s/z:';
    izBrskalniki.Font.Charset := DEFAULT_CHARSET;
    izBrskalniki.Font.Color := clWindowText;
    izBrskalniki.Font.Height := -12;
    izBrskalniki.Font.Name := 'Arial';
    izBrskalniki.Font.Style := [];
    izBrskalniki.HeightPercent := 100.000000000000000000;
    izBrskalniki.ParentFont := False;
    izBrskalniki.WidthPercent := 100.000000000000000000;
    ploscicaPrijava.SetParentComponent(ploscicaPrvaStran);
    ploscicaPrijava.Name := 'ploscicaPrijava';
    ploscicaPrijava.Left := 146;
    ploscicaPrijava.Top := 145;
    ploscicaPrijava.Width := 219;
    ploscicaPrijava.Height := 265;
    ploscicaPrijava.HeightPercent := 100.000000000000000000;
    ploscicaPrijava.WidthPercent := 100.000000000000000000;
    ploscicaPrijava.BorderColor := clSilver;
    ploscicaPrijava.BorderStyle := bsSingle;
    ploscicaPrijava.ChildOrder := 1;
    vpUporabniskoIme_Tekst.SetParentComponent(ploscicaPrijava);
    vpUporabniskoIme_Tekst.Name := 'vpUporabniskoIme_Tekst';
    vpUporabniskoIme_Tekst.Left := 23;
    vpUporabniskoIme_Tekst.Top := 64;
    vpUporabniskoIme_Tekst.Width := 133;
    vpUporabniskoIme_Tekst.Height := 15;
    vpUporabniskoIme_Tekst.Caption := 'Vnesite uporabni'#353'ko ime:';
    vpUporabniskoIme_Tekst.HeightPercent := 100.000000000000000000;
    vpUporabniskoIme_Tekst.WidthPercent := 100.000000000000000000;
    vpGeslo_Tekst.SetParentComponent(ploscicaPrijava);
    vpGeslo_Tekst.Name := 'vpGeslo_Tekst';
    vpGeslo_Tekst.Left := 23;
    vpGeslo_Tekst.Top := 130;
    vpGeslo_Tekst.Width := 72;
    vpGeslo_Tekst.Height := 15;
    vpGeslo_Tekst.Caption := 'Vnesite geslo:';
    vpGeslo_Tekst.HeightPercent := 100.000000000000000000;
    vpGeslo_Tekst.WidthPercent := 100.000000000000000000;
    izPrijavaNaslov.SetParentComponent(ploscicaPrijava);
    izPrijavaNaslov.Name := 'izPrijavaNaslov';
    izPrijavaNaslov.Left := 5;
    izPrijavaNaslov.Top := 17;
    izPrijavaNaslov.Width := 211;
    izPrijavaNaslov.Height := 13;
    izPrijavaNaslov.Alignment := taCenter;
    izPrijavaNaslov.AutoSize := False;
    izPrijavaNaslov.Caption := 'PRIJAVA V SISTEM';
    izPrijavaNaslov.Font.Charset := DEFAULT_CHARSET;
    izPrijavaNaslov.Font.Color := clWindowText;
    izPrijavaNaslov.Font.Height := -11;
    izPrijavaNaslov.Font.Name := 'Tahoma';
    izPrijavaNaslov.Font.Style := [fsBold,fsUnderline];
    izPrijavaNaslov.HeightPercent := 100.000000000000000000;
    izPrijavaNaslov.ParentFont := False;
    izPrijavaNaslov.WidthPercent := 100.000000000000000000;
    izUporabniskoImeNapaka.SetParentComponent(ploscicaPrijava);
    izUporabniskoImeNapaka.Name := 'izUporabniskoImeNapaka';
    izUporabniskoImeNapaka.Left := 21;
    izUporabniskoImeNapaka.Top := 108;
    izUporabniskoImeNapaka.Width := 173;
    izUporabniskoImeNapaka.Height := 13;
    izUporabniskoImeNapaka.Alignment := taCenter;
    izUporabniskoImeNapaka.AutoSize := False;
    izUporabniskoImeNapaka.Caption := 'Uporabnik ne obstaja...';
    izUporabniskoImeNapaka.Font.Charset := DEFAULT_CHARSET;
    izUporabniskoImeNapaka.Font.Color := clMaroon;
    izUporabniskoImeNapaka.Font.Height := -9;
    izUporabniskoImeNapaka.Font.Name := 'Tahoma';
    izUporabniskoImeNapaka.Font.Style := [];
    izUporabniskoImeNapaka.HeightPercent := 100.000000000000000000;
    izUporabniskoImeNapaka.ParentFont := False;
    izUporabniskoImeNapaka.Visible := False;
    izUporabniskoImeNapaka.WidthPercent := 100.000000000000000000;
    izGesloNapaka.SetParentComponent(ploscicaPrijava);
    izGesloNapaka.Name := 'izGesloNapaka';
    izGesloNapaka.Left := 26;
    izGesloNapaka.Top := 177;
    izGesloNapaka.Width := 173;
    izGesloNapaka.Height := 13;
    izGesloNapaka.Alignment := taCenter;
    izGesloNapaka.AutoSize := False;
    izGesloNapaka.Caption := 'Geslo ni pravilno...';
    izGesloNapaka.Font.Charset := DEFAULT_CHARSET;
    izGesloNapaka.Font.Color := clMaroon;
    izGesloNapaka.Font.Height := -9;
    izGesloNapaka.Font.Name := 'Tahoma';
    izGesloNapaka.Font.Style := [];
    izGesloNapaka.HeightPercent := 100.000000000000000000;
    izGesloNapaka.ParentFont := False;
    izGesloNapaka.Visible := False;
    izGesloNapaka.WidthPercent := 100.000000000000000000;
    izVerzijaPrijava.SetParentComponent(ploscicaPrijava);
    izVerzijaPrijava.Name := 'izVerzijaPrijava';
    izVerzijaPrijava.Left := 3;
    izVerzijaPrijava.Top := 251;
    izVerzijaPrijava.Width := 213;
    izVerzijaPrijava.Height := 11;
    izVerzijaPrijava.Alignment := taCenter;
    izVerzijaPrijava.AutoSize := False;
    izVerzijaPrijava.Caption := 'ModelBI ver. 1.0.0 (06.03.2022)';
    izVerzijaPrijava.Font.Charset := DEFAULT_CHARSET;
    izVerzijaPrijava.Font.Color := clWindowText;
    izVerzijaPrijava.Font.Height := -8;
    izVerzijaPrijava.Font.Name := 'Segoe UI';
    izVerzijaPrijava.Font.Style := [];
    izVerzijaPrijava.HeightPercent := 100.000000000000000000;
    izVerzijaPrijava.ParentFont := False;
    izVerzijaPrijava.WidthPercent := 100.000000000000000000;
    vpUporabniskoIme.SetParentComponent(ploscicaPrijava);
    vpUporabniskoIme.Name := 'vpUporabniskoIme';
    vpUporabniskoIme.Left := 23;
    vpUporabniskoIme.Top := 83;
    vpUporabniskoIme.Width := 174;
    vpUporabniskoIme.Height := 22;
    vpUporabniskoIme.AutoCompletion := acUserName;
    vpUporabniskoIme.ChildOrder := 1;
    vpUporabniskoIme.HeightPercent := 100.000000000000000000;
    vpUporabniskoIme.WidthPercent := 100.000000000000000000;
    vpGeslo.SetParentComponent(ploscicaPrijava);
    vpGeslo.Name := 'vpGeslo';
    vpGeslo.Left := 23;
    vpGeslo.Top := 149;
    vpGeslo.Width := 176;
    vpGeslo.Height := 22;
    vpGeslo.AutoCompletion := acCurrentPassword;
    vpGeslo.ChildOrder := 1;
    vpGeslo.HeightPercent := 100.000000000000000000;
    vpGeslo.PasswordChar := '*';
    vpGeslo.WidthPercent := 100.000000000000000000;
    gPrijava.SetParentComponent(ploscicaPrijava);
    gPrijava.Name := 'gPrijava';
    gPrijava.Left := 26;
    gPrijava.Top := 207;
    gPrijava.Width := 173;
    gPrijava.Height := 28;
    gPrijava.Caption := 'Prijava';
    gPrijava.Color := clNone;
    gPrijava.Flat := True;
    gPrijava.HeightPercent := 100.000000000000000000;
    gPrijava.MaterialGlyph := 'lock';
    gPrijava.WidthPercent := 100.000000000000000000;
    SetEvent(gPrijava, Self, 'OnClick', 'gPrijavaClick');
    ploscicaNaslovDL0A.SetParentComponent(ploscicaPrvaStran);
    ploscicaNaslovDL0A.Name := 'ploscicaNaslovDL0A';
    ploscicaNaslovDL0A.Left := 25;
    ploscicaNaslovDL0A.Top := 430;
    ploscicaNaslovDL0A.Width := 540;
    ploscicaNaslovDL0A.Height := 82;
    ploscicaNaslovDL0A.HeightPercent := 100.000000000000000000;
    ploscicaNaslovDL0A.WidthPercent := 100.000000000000000000;
    ploscicaNaslovDL0A.BorderColor := clNone;
    ploscicaNaslovDL0A.BorderStyle := bsNone;
    ploscicaNaslovDL0A.Color := clWhite;
    izNaslov2_1_DL0A.SetParentComponent(ploscicaNaslovDL0A);
    izNaslov2_1_DL0A.Name := 'izNaslov2_1_DL0A';
    izNaslov2_1_DL0A.Left := 3;
    izNaslov2_1_DL0A.Top := 2;
    izNaslov2_1_DL0A.Width := 540;
    izNaslov2_1_DL0A.Height := 18;
    izNaslov2_1_DL0A.AutoSize := False;
    izNaslov2_1_DL0A.Caption := 'Model za ocenjevanje tveganj, povezanih z obratovalnimi zastoji v';
    izNaslov2_1_DL0A.Color := clNone;
    izNaslov2_1_DL0A.Font.Charset := DEFAULT_CHARSET;
    izNaslov2_1_DL0A.Font.Color := clWindowText;
    izNaslov2_1_DL0A.Font.Height := -15;
    izNaslov2_1_DL0A.Font.Name := 'Arial';
    izNaslov2_1_DL0A.Font.Style := [fsBold];
    izNaslov2_1_DL0A.HeightPercent := 100.000000000000000000;
    izNaslov2_1_DL0A.ParentFont := False;
    izNaslov2_1_DL0A.WidthPercent := 100.000000000000000000;
    izNaslov2_2_DL0A.SetParentComponent(ploscicaNaslovDL0A);
    izNaslov2_2_DL0A.Name := 'izNaslov2_2_DL0A';
    izNaslov2_2_DL0A.Left := 3;
    izNaslov2_2_DL0A.Top := 22;
    izNaslov2_2_DL0A.Width := 540;
    izNaslov2_2_DL0A.Height := 18;
    izNaslov2_2_DL0A.AutoSize := False;
    izNaslov2_2_DL0A.Caption := 'malih in srednje velikih podjetjih ter vrednotenje preventivnih';
    izNaslov2_2_DL0A.Color := clNone;
    izNaslov2_2_DL0A.Font.Charset := DEFAULT_CHARSET;
    izNaslov2_2_DL0A.Font.Color := clWindowText;
    izNaslov2_2_DL0A.Font.Height := -15;
    izNaslov2_2_DL0A.Font.Name := 'Arial';
    izNaslov2_2_DL0A.Font.Style := [fsBold];
    izNaslov2_2_DL0A.HeightPercent := 100.000000000000000000;
    izNaslov2_2_DL0A.ParentFont := False;
    izNaslov2_2_DL0A.WidthPercent := 100.000000000000000000;
    izNaslov2_3_DL0A.SetParentComponent(ploscicaNaslovDL0A);
    izNaslov2_3_DL0A.Name := 'izNaslov2_3_DL0A';
    izNaslov2_3_DL0A.Left := 3;
    izNaslov2_3_DL0A.Top := 42;
    izNaslov2_3_DL0A.Width := 540;
    izNaslov2_3_DL0A.Height := 18;
    izNaslov2_3_DL0A.AutoSize := False;
    izNaslov2_3_DL0A.Caption := 'ukrepov za zmanj'#353'anje tveganj oziroma odpravljanje tovrstnih tveganj';
    izNaslov2_3_DL0A.Color := clNone;
    izNaslov2_3_DL0A.Font.Charset := DEFAULT_CHARSET;
    izNaslov2_3_DL0A.Font.Color := clWindowText;
    izNaslov2_3_DL0A.Font.Height := -15;
    izNaslov2_3_DL0A.Font.Name := 'Arial';
    izNaslov2_3_DL0A.Font.Style := [fsBold];
    izNaslov2_3_DL0A.HeightPercent := 100.000000000000000000;
    izNaslov2_3_DL0A.ParentFont := False;
    izNaslov2_3_DL0A.WidthPercent := 100.000000000000000000;
    izSpletnaStran.SetParentComponent(ploscicaNaslovDL0A);
    izSpletnaStran.Name := 'izSpletnaStran';
    izSpletnaStran.Left := 3;
    izSpletnaStran.Top := 63;
    izSpletnaStran.Width := 520;
    izSpletnaStran.Height := 16;
    izSpletnaStran.AutoSize := False;
    izSpletnaStran.Font.Charset := DEFAULT_CHARSET;
    izSpletnaStran.Font.Color := clWindowText;
    izSpletnaStran.Font.Height := -15;
    izSpletnaStran.Font.Name := 'Arial';
    izSpletnaStran.Font.Style := [];
    izSpletnaStran.ParentFont := False;
    izSpletnaStran.HeightPercent := 100.000000000000000000;
    izSpletnaStran.WidthPercent := 100.000000000000000000;
    izSpletnaStran.Caption := '(ve'#269' informacij na <a href="https://www.RiskClinic.si/modelbi" target="_blank">RiskClinic)</a';
    DelovniList1.SetParentComponent(ploscicaDelovniListi);
    DelovniList1.Name := 'DelovniList1';
    DelovniList1.Left := 0;
    DelovniList1.Top := 20;
    DelovniList1.Width := 1157;
    DelovniList1.Height := 1090;
    DelovniList1.HeightPercent := 100.000000000000000000;
    DelovniList1.WidthPercent := 100.000000000000000000;
    DelovniList1.Caption := 'DelovniList1';
    DelovniList1.ChildOrder := 3;
    DelovniList1.TabVisible := False;
    WebScrollBoxDL1.SetParentComponent(DelovniList1);
    WebScrollBoxDL1.Name := 'WebScrollBoxDL1';
    WebScrollBoxDL1.Left := 0;
    WebScrollBoxDL1.Top := 0;
    WebScrollBoxDL1.Width := 1157;
    WebScrollBoxDL1.Height := 1090;
    WebScrollBoxDL1.HeightPercent := 100.000000000000000000;
    WebScrollBoxDL1.WidthPercent := 100.000000000000000000;
    WebScrollBoxDL1.Align := alClient;
    WebScrollBoxDL1.AutoScroll := True;
    WebScrollBoxDL1.BorderStyle := bsNone;
    WebScrollBoxDL1.ChildOrder := 2;
    WebScrollBoxDL1.Color := clWindow;
    izNaslovDL1.SetParentComponent(WebScrollBoxDL1);
    izNaslovDL1.Name := 'izNaslovDL1';
    izNaslovDL1.Left := 12;
    izNaslovDL1.Top := 20;
    izNaslovDL1.Width := 211;
    izNaslovDL1.Height := 19;
    izNaslovDL1.Anchors := [];
    izNaslovDL1.Caption := 'Osnovni podatki o podjetju';
    izNaslovDL1.Color := clNone;
    izNaslovDL1.Font.Charset := DEFAULT_CHARSET;
    izNaslovDL1.Font.Color := clWindowText;
    izNaslovDL1.Font.Height := -17;
    izNaslovDL1.Font.Name := 'Arial';
    izNaslovDL1.Font.Style := [fsBold];
    izNaslovDL1.HeightPercent := 100.000000000000000000;
    izNaslovDL1.ParentFont := False;
    izNaslovDL1.WidthPercent := 100.000000000000000000;
    ploscicaVnosPodatkovOPodjetju.SetParentComponent(WebScrollBoxDL1);
    ploscicaVnosPodatkovOPodjetju.Name := 'ploscicaVnosPodatkovOPodjetju';
    ploscicaVnosPodatkovOPodjetju.Left := 15;
    ploscicaVnosPodatkovOPodjetju.Top := 80;
    ploscicaVnosPodatkovOPodjetju.Width := 400;
    ploscicaVnosPodatkovOPodjetju.Height := 154;
    ploscicaVnosPodatkovOPodjetju.HeightPercent := 100.000000000000000000;
    ploscicaVnosPodatkovOPodjetju.WidthPercent := 100.000000000000000000;
    ploscicaVnosPodatkovOPodjetju.ColumnCollection.Clear;
    with ploscicaVnosPodatkovOPodjetju.ColumnCollection.Add do
    begin
      Value := 75;
    end;
    with ploscicaVnosPodatkovOPodjetju.ColumnCollection.Add do
    begin
      Alignment := taCenter;
      Value := 25;
    end;
    ploscicaVnosPodatkovOPodjetju.ControlCollection.Clear;
    with ploscicaVnosPodatkovOPodjetju.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izImePodjetja;
    end;
    with ploscicaVnosPodatkovOPodjetju.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := vpImePodjetja;
    end;
    with ploscicaVnosPodatkovOPodjetju.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izNaslovPodjetja;
    end;
    with ploscicaVnosPodatkovOPodjetju.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := vpNaslovPodjetja;
    end;
    with ploscicaVnosPodatkovOPodjetju.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izDavcnaStevilkaPodjetja;
    end;
    with ploscicaVnosPodatkovOPodjetju.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := vpDavcnaStevilkaPodjetja;
    end;
    with ploscicaVnosPodatkovOPodjetju.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izDejavnostPodjetja;
    end;
    with ploscicaVnosPodatkovOPodjetju.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := vpDejavnostPodjetja;
    end;
    ploscicaVnosPodatkovOPodjetju.Color := clNone;
    ploscicaVnosPodatkovOPodjetju.GridLineColor := clBlack;
    ploscicaVnosPodatkovOPodjetju.RowCollection.Clear;
    with ploscicaVnosPodatkovOPodjetju.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 25;
    end;
    with ploscicaVnosPodatkovOPodjetju.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 25;
    end;
    with ploscicaVnosPodatkovOPodjetju.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 25;
    end;
    with ploscicaVnosPodatkovOPodjetju.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 25;
    end;
    izImePodjetja.SetParentComponent(ploscicaVnosPodatkovOPodjetju);
    izImePodjetja.Name := 'izImePodjetja';
    izImePodjetja.Left := 2;
    izImePodjetja.Top := 12;
    izImePodjetja.Width := 59;
    izImePodjetja.Height := 14;
    izImePodjetja.Anchors := [];
    izImePodjetja.Caption := 'Ime podjetja:';
    izImePodjetja.Color := clNone;
    izImePodjetja.Font.Charset := DEFAULT_CHARSET;
    izImePodjetja.Font.Color := clWindowText;
    izImePodjetja.Font.Height := -11;
    izImePodjetja.Font.Name := 'Arial';
    izImePodjetja.Font.Style := [];
    izImePodjetja.HeightPercent := 100.000000000000000000;
    izImePodjetja.Layout := tlCenter;
    izImePodjetja.ParentFont := False;
    izImePodjetja.WidthPercent := 100.000000000000000000;
    izDavcnaStevilkaPodjetja.SetParentComponent(ploscicaVnosPodatkovOPodjetju);
    izDavcnaStevilkaPodjetja.Name := 'izDavcnaStevilkaPodjetja';
    izDavcnaStevilkaPodjetja.Left := 2;
    izDavcnaStevilkaPodjetja.Top := 87;
    izDavcnaStevilkaPodjetja.Width := 130;
    izDavcnaStevilkaPodjetja.Height := 15;
    izDavcnaStevilkaPodjetja.Anchors := [];
    izDavcnaStevilkaPodjetja.Caption := 'Dav'#269'na '#353'tevilka podjetja:';
    izDavcnaStevilkaPodjetja.Color := clNone;
    izDavcnaStevilkaPodjetja.HeightPercent := 100.000000000000000000;
    izDavcnaStevilkaPodjetja.Layout := tlCenter;
    izDavcnaStevilkaPodjetja.WidthPercent := 100.000000000000000000;
    izDejavnostPodjetja.SetParentComponent(ploscicaVnosPodatkovOPodjetju);
    izDejavnostPodjetja.Name := 'izDejavnostPodjetja';
    izDejavnostPodjetja.Left := 2;
    izDejavnostPodjetja.Top := 125;
    izDejavnostPodjetja.Width := 101;
    izDejavnostPodjetja.Height := 15;
    izDejavnostPodjetja.Anchors := [];
    izDejavnostPodjetja.Caption := 'Dejavnost podjetja:';
    izDejavnostPodjetja.Color := clNone;
    izDejavnostPodjetja.HeightPercent := 100.000000000000000000;
    izDejavnostPodjetja.Layout := tlCenter;
    izDejavnostPodjetja.WidthPercent := 100.000000000000000000;
    izNaslovPodjetja.SetParentComponent(ploscicaVnosPodatkovOPodjetju);
    izNaslovPodjetja.Name := 'izNaslovPodjetja';
    izNaslovPodjetja.Left := 2;
    izNaslovPodjetja.Top := 49;
    izNaslovPodjetja.Width := 85;
    izNaslovPodjetja.Height := 15;
    izNaslovPodjetja.Anchors := [];
    izNaslovPodjetja.Caption := 'Naslov podjetja:';
    izNaslovPodjetja.Color := clNone;
    izNaslovPodjetja.HeightPercent := 100.000000000000000000;
    izNaslovPodjetja.Layout := tlCenter;
    izNaslovPodjetja.WidthPercent := 100.000000000000000000;
    vpImePodjetja.SetParentComponent(ploscicaVnosPodatkovOPodjetju);
    vpImePodjetja.Name := 'vpImePodjetja';
    vpImePodjetja.Left := 213;
    vpImePodjetja.Top := 8;
    vpImePodjetja.Width := 275;
    vpImePodjetja.Height := 22;
    vpImePodjetja.Anchors := [];
    vpImePodjetja.ChildOrder := 1;
    vpImePodjetja.HeightPercent := 100.000000000000000000;
    vpImePodjetja.WidthPercent := 100.000000000000000000;
    vpDavcnaStevilkaPodjetja.SetParentComponent(ploscicaVnosPodatkovOPodjetju);
    vpDavcnaStevilkaPodjetja.Name := 'vpDavcnaStevilkaPodjetja';
    vpDavcnaStevilkaPodjetja.Left := 213;
    vpDavcnaStevilkaPodjetja.Top := 84;
    vpDavcnaStevilkaPodjetja.Width := 275;
    vpDavcnaStevilkaPodjetja.Height := 22;
    vpDavcnaStevilkaPodjetja.Anchors := [];
    vpDavcnaStevilkaPodjetja.ChildOrder := 4;
    vpDavcnaStevilkaPodjetja.HeightPercent := 100.000000000000000000;
    vpDavcnaStevilkaPodjetja.WidthPercent := 100.000000000000000000;
    vpNaslovPodjetja.SetParentComponent(ploscicaVnosPodatkovOPodjetju);
    vpNaslovPodjetja.Name := 'vpNaslovPodjetja';
    vpNaslovPodjetja.Left := 213;
    vpNaslovPodjetja.Top := 46;
    vpNaslovPodjetja.Width := 275;
    vpNaslovPodjetja.Height := 22;
    vpNaslovPodjetja.Anchors := [];
    vpNaslovPodjetja.ChildOrder := 5;
    vpNaslovPodjetja.HeightPercent := 100.000000000000000000;
    vpNaslovPodjetja.WidthPercent := 100.000000000000000000;
    vpDejavnostPodjetja.SetParentComponent(ploscicaVnosPodatkovOPodjetju);
    vpDejavnostPodjetja.Name := 'vpDejavnostPodjetja';
    vpDejavnostPodjetja.Left := 213;
    vpDejavnostPodjetja.Top := 121;
    vpDejavnostPodjetja.Width := 275;
    vpDejavnostPodjetja.Height := 23;
    vpDejavnostPodjetja.Anchors := [];
    vpDejavnostPodjetja.HeightPercent := 100.000000000000000000;
    vpDejavnostPodjetja.WidthPercent := 100.000000000000000000;
    vpDejavnostPodjetja.ItemIndex := -1;
    DelovniList2.SetParentComponent(ploscicaDelovniListi);
    DelovniList2.Name := 'DelovniList2';
    DelovniList2.Left := 0;
    DelovniList2.Top := 20;
    DelovniList2.Width := 1157;
    DelovniList2.Height := 1090;
    DelovniList2.HeightPercent := 100.000000000000000000;
    DelovniList2.WidthPercent := 100.000000000000000000;
    DelovniList2.Caption := 'DelovniList2';
    DelovniList2.ChildOrder := 4;
    DelovniList2.TabVisible := False;
    WebScrollBoxDL2.SetParentComponent(DelovniList2);
    WebScrollBoxDL2.Name := 'WebScrollBoxDL2';
    WebScrollBoxDL2.Left := 0;
    WebScrollBoxDL2.Top := 0;
    WebScrollBoxDL2.Width := 1157;
    WebScrollBoxDL2.Height := 1090;
    WebScrollBoxDL2.HeightPercent := 100.000000000000000000;
    WebScrollBoxDL2.WidthPercent := 100.000000000000000000;
    WebScrollBoxDL2.Align := alClient;
    WebScrollBoxDL2.AutoScroll := True;
    WebScrollBoxDL2.BorderStyle := bsNone;
    WebScrollBoxDL2.ChildOrder := 2;
    WebScrollBoxDL2.Color := clWindow;
    izNaslovDL2.SetParentComponent(WebScrollBoxDL2);
    izNaslovDL2.Name := 'izNaslovDL2';
    izNaslovDL2.Left := 12;
    izNaslovDL2.Top := 20;
    izNaslovDL2.Width := 313;
    izNaslovDL2.Height := 19;
    izNaslovDL2.Anchors := [];
    izNaslovDL2.Caption := 'Finan'#269'no ra'#269'unovodski podatki podjetja';
    izNaslovDL2.Color := clNone;
    izNaslovDL2.Font.Charset := DEFAULT_CHARSET;
    izNaslovDL2.Font.Color := clWindowText;
    izNaslovDL2.Font.Height := -17;
    izNaslovDL2.Font.Name := 'Arial';
    izNaslovDL2.Font.Style := [fsBold];
    izNaslovDL2.HeightPercent := 100.000000000000000000;
    izNaslovDL2.ParentFont := False;
    izNaslovDL2.WidthPercent := 100.000000000000000000;
    ploscicaVnosFinancnihPodatkovPodjetja.SetParentComponent(WebScrollBoxDL2);
    ploscicaVnosFinancnihPodatkovPodjetja.Name := 'ploscicaVnosFinancnihPodatkovPodjetja';
    ploscicaVnosFinancnihPodatkovPodjetja.Left := 12;
    ploscicaVnosFinancnihPodatkovPodjetja.Top := 78;
    ploscicaVnosFinancnihPodatkovPodjetja.Width := 830;
    ploscicaVnosFinancnihPodatkovPodjetja.Height := 536;
    ploscicaVnosFinancnihPodatkovPodjetja.HeightPercent := 100.000000000000000000;
    ploscicaVnosFinancnihPodatkovPodjetja.WidthPercent := 100.000000000000000000;
    ploscicaVnosFinancnihPodatkovPodjetja.ChildOrder := 1;
    ploscicaVnosFinancnihPodatkovPodjetja.ColumnCollection.Clear;
    with ploscicaVnosFinancnihPodatkovPodjetja.ColumnCollection.Add do
    begin
      Value := 84;
    end;
    with ploscicaVnosFinancnihPodatkovPodjetja.ColumnCollection.Add do
    begin
      Alignment := taRightJustify;
      Value := 16;
    end;
    ploscicaVnosFinancnihPodatkovPodjetja.ControlCollection.Clear;
    with ploscicaVnosFinancnihPodatkovPodjetja.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izVnosFRPLeto;
    end;
    with ploscicaVnosFinancnihPodatkovPodjetja.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := vpOpazovanoLeto;
    end;
    with ploscicaVnosFinancnihPodatkovPodjetja.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izVnosFRPPromet;
    end;
    with ploscicaVnosFinancnihPodatkovPodjetja.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := vpVnosFRPPromet;
    end;
    with ploscicaVnosFinancnihPodatkovPodjetja.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izVnosFRPZalogeZacetek;
    end;
    with ploscicaVnosFinancnihPodatkovPodjetja.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := vpVnosFRPZalogeZacetek;
    end;
    with ploscicaVnosFinancnihPodatkovPodjetja.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izVnosFRPZalogeKonec;
    end;
    with ploscicaVnosFinancnihPodatkovPodjetja.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := vpVnosFRPZalogeKonec;
    end;
    with ploscicaVnosFinancnihPodatkovPodjetja.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izVnosFRPInternoProizvedeniDodatki;
    end;
    with ploscicaVnosFinancnihPodatkovPodjetja.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := vpVnosFRPInternoProizvedeniDodatki;
    end;
    with ploscicaVnosFinancnihPodatkovPodjetja.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izVnosFRPStroskiSurovine;
    end;
    with ploscicaVnosFinancnihPodatkovPodjetja.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := vpVnosFRPStroskiSurovine;
    end;
    with ploscicaVnosFinancnihPodatkovPodjetja.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izVnosFRPStroskiMaterial;
    end;
    with ploscicaVnosFinancnihPodatkovPodjetja.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := vpVnosFRPStroskiMaterial;
    end;
    with ploscicaVnosFinancnihPodatkovPodjetja.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izVnosFRPStroskiEnergija;
    end;
    with ploscicaVnosFinancnihPodatkovPodjetja.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := vpVnosFRPStroskiEnergija;
    end;
    with ploscicaVnosFinancnihPodatkovPodjetja.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izVnosFRPStroskiTrgovskoBlago;
    end;
    with ploscicaVnosFinancnihPodatkovPodjetja.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := vpVnosFRPStroskiTrgovskoBlago;
    end;
    with ploscicaVnosFinancnihPodatkovPodjetja.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izVnosFRPStroskiStoritve;
    end;
    with ploscicaVnosFinancnihPodatkovPodjetja.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := vpVnosFRPStroskiStoritve;
    end;
    with ploscicaVnosFinancnihPodatkovPodjetja.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izVnosFRPDrugiStroski;
    end;
    with ploscicaVnosFinancnihPodatkovPodjetja.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := vpVnosFRPDrugiStroski;
    end;
    with ploscicaVnosFinancnihPodatkovPodjetja.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izVnosFRPPrejeteZavarovalnine;
    end;
    with ploscicaVnosFinancnihPodatkovPodjetja.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := vpVnosFRPPrejeteZavarovalnine;
    end;
    with ploscicaVnosFinancnihPodatkovPodjetja.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izVnosFRPKosmatiDobicek;
    end;
    with ploscicaVnosFinancnihPodatkovPodjetja.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := vpVnosFRPKosmatiDobicek;
    end;
    with ploscicaVnosFinancnihPodatkovPodjetja.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izVnosFRPStopnjaKosmategaDobicka;
    end;
    with ploscicaVnosFinancnihPodatkovPodjetja.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := vpVnosFRPStopnjaKosmategaDobicka;
    end;
    ploscicaVnosFinancnihPodatkovPodjetja.Color := clWhite;
    ploscicaVnosFinancnihPodatkovPodjetja.GridLineColor := clBlack;
    ploscicaVnosFinancnihPodatkovPodjetja.RowCollection.Clear;
    with ploscicaVnosFinancnihPodatkovPodjetja.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 7;
    end;
    with ploscicaVnosFinancnihPodatkovPodjetja.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 7;
    end;
    with ploscicaVnosFinancnihPodatkovPodjetja.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 7;
    end;
    with ploscicaVnosFinancnihPodatkovPodjetja.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 7;
    end;
    with ploscicaVnosFinancnihPodatkovPodjetja.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 7;
    end;
    with ploscicaVnosFinancnihPodatkovPodjetja.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 7;
    end;
    with ploscicaVnosFinancnihPodatkovPodjetja.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 7;
    end;
    with ploscicaVnosFinancnihPodatkovPodjetja.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 7;
    end;
    with ploscicaVnosFinancnihPodatkovPodjetja.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 7;
    end;
    with ploscicaVnosFinancnihPodatkovPodjetja.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 7;
    end;
    with ploscicaVnosFinancnihPodatkovPodjetja.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 7;
    end;
    with ploscicaVnosFinancnihPodatkovPodjetja.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 7;
    end;
    with ploscicaVnosFinancnihPodatkovPodjetja.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 7;
    end;
    with ploscicaVnosFinancnihPodatkovPodjetja.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 7;
    end;
    with ploscicaVnosFinancnihPodatkovPodjetja.RowCollection.Add do
    begin
      Value := 7;
    end;
    izVnosFRPLeto.SetParentComponent(ploscicaVnosFinancnihPodatkovPodjetja);
    izVnosFRPLeto.Name := 'izVnosFRPLeto';
    izVnosFRPLeto.Left := 2;
    izVnosFRPLeto.Top := 12;
    izVnosFRPLeto.Width := 128;
    izVnosFRPLeto.Height := 14;
    izVnosFRPLeto.Anchors := [];
    izVnosFRPLeto.Caption := 'Opazovano obdobje (leto):';
    izVnosFRPLeto.Color := clNone;
    izVnosFRPLeto.Font.Charset := DEFAULT_CHARSET;
    izVnosFRPLeto.Font.Color := clWindowText;
    izVnosFRPLeto.Font.Height := -11;
    izVnosFRPLeto.Font.Name := 'Arial';
    izVnosFRPLeto.Font.Style := [];
    izVnosFRPLeto.HeightPercent := 100.000000000000000000;
    izVnosFRPLeto.Layout := tlCenter;
    izVnosFRPLeto.ParentFont := False;
    izVnosFRPLeto.WidthPercent := 100.000000000000000000;
    izVnosFRPPromet.SetParentComponent(ploscicaVnosFinancnihPodatkovPodjetja);
    izVnosFRPPromet.Name := 'izVnosFRPPromet';
    izVnosFRPPromet.Left := 2;
    izVnosFRPPromet.Top := 50;
    izVnosFRPPromet.Width := 163;
    izVnosFRPPromet.Height := 14;
    izVnosFRPPromet.Anchors := [];
    izVnosFRPPromet.Caption := '1. Promet v opazovanem obdobju:';
    izVnosFRPPromet.Color := clNone;
    izVnosFRPPromet.Font.Charset := DEFAULT_CHARSET;
    izVnosFRPPromet.Font.Color := clWindowText;
    izVnosFRPPromet.Font.Height := -11;
    izVnosFRPPromet.Font.Name := 'Arial';
    izVnosFRPPromet.Font.Style := [];
    izVnosFRPPromet.HeightPercent := 100.000000000000000000;
    izVnosFRPPromet.Layout := tlCenter;
    izVnosFRPPromet.ParentFont := False;
    izVnosFRPPromet.WidthPercent := 100.000000000000000000;
    izVnosFRPZalogeZacetek.SetParentComponent(ploscicaVnosFinancnihPodatkovPodjetja);
    izVnosFRPZalogeZacetek.Name := 'izVnosFRPZalogeZacetek';
    izVnosFRPZalogeZacetek.Left := 2;
    izVnosFRPZalogeZacetek.Top := 88;
    izVnosFRPZalogeZacetek.Width := 240;
    izVnosFRPZalogeZacetek.Height := 14;
    izVnosFRPZalogeZacetek.Anchors := [];
    izVnosFRPZalogeZacetek.Caption := '2. Stanje zalog na za'#269'etku opazovanega obdobja:';
    izVnosFRPZalogeZacetek.Color := clNone;
    izVnosFRPZalogeZacetek.Font.Charset := DEFAULT_CHARSET;
    izVnosFRPZalogeZacetek.Font.Color := clWindowText;
    izVnosFRPZalogeZacetek.Font.Height := -11;
    izVnosFRPZalogeZacetek.Font.Name := 'Arial';
    izVnosFRPZalogeZacetek.Font.Style := [];
    izVnosFRPZalogeZacetek.HeightPercent := 100.000000000000000000;
    izVnosFRPZalogeZacetek.Layout := tlCenter;
    izVnosFRPZalogeZacetek.ParentFont := False;
    izVnosFRPZalogeZacetek.WidthPercent := 100.000000000000000000;
    izVnosFRPZalogeKonec.SetParentComponent(ploscicaVnosFinancnihPodatkovPodjetja);
    izVnosFRPZalogeKonec.Name := 'izVnosFRPZalogeKonec';
    izVnosFRPZalogeKonec.Left := 2;
    izVnosFRPZalogeKonec.Top := 126;
    izVnosFRPZalogeKonec.Width := 231;
    izVnosFRPZalogeKonec.Height := 14;
    izVnosFRPZalogeKonec.Anchors := [];
    izVnosFRPZalogeKonec.Caption := '3. Stanje zalog na koncu opazovanega obdobja:';
    izVnosFRPZalogeKonec.Color := clNone;
    izVnosFRPZalogeKonec.Font.Charset := DEFAULT_CHARSET;
    izVnosFRPZalogeKonec.Font.Color := clWindowText;
    izVnosFRPZalogeKonec.Font.Height := -11;
    izVnosFRPZalogeKonec.Font.Name := 'Arial';
    izVnosFRPZalogeKonec.Font.Style := [];
    izVnosFRPZalogeKonec.HeightPercent := 100.000000000000000000;
    izVnosFRPZalogeKonec.Layout := tlCenter;
    izVnosFRPZalogeKonec.ParentFont := False;
    izVnosFRPZalogeKonec.WidthPercent := 100.000000000000000000;
    izVnosFRPInternoProizvedeniDodatki.SetParentComponent(ploscicaVnosFinancnihPodatkovPodjetja);
    izVnosFRPInternoProizvedeniDodatki.Name := 'izVnosFRPInternoProizvedeniDodatki';
    izVnosFRPInternoProizvedeniDodatki.Left := 2;
    izVnosFRPInternoProizvedeniDodatki.Top := 164;
    izVnosFRPInternoProizvedeniDodatki.Width := 511;
    izVnosFRPInternoProizvedeniDodatki.Height := 14;
    izVnosFRPInternoProizvedeniDodatki.Anchors := [];
    izVnosFRPInternoProizvedeniDodatki.Caption := '4. Interno proizvedeni dodatki za lastne objekte in opremo (s kapitaliziranimi u'#269'inki) v opazovanem obdobju:';
    izVnosFRPInternoProizvedeniDodatki.Color := clNone;
    izVnosFRPInternoProizvedeniDodatki.Font.Charset := DEFAULT_CHARSET;
    izVnosFRPInternoProizvedeniDodatki.Font.Color := clWindowText;
    izVnosFRPInternoProizvedeniDodatki.Font.Height := -11;
    izVnosFRPInternoProizvedeniDodatki.Font.Name := 'Arial';
    izVnosFRPInternoProizvedeniDodatki.Font.Style := [];
    izVnosFRPInternoProizvedeniDodatki.HeightPercent := 100.000000000000000000;
    izVnosFRPInternoProizvedeniDodatki.Layout := tlCenter;
    izVnosFRPInternoProizvedeniDodatki.ParentFont := False;
    izVnosFRPInternoProizvedeniDodatki.WidthPercent := 100.000000000000000000;
    izVnosFRPStroskiSurovine.SetParentComponent(ploscicaVnosFinancnihPodatkovPodjetja);
    izVnosFRPStroskiSurovine.Name := 'izVnosFRPStroskiSurovine';
    izVnosFRPStroskiSurovine.Left := 2;
    izVnosFRPStroskiSurovine.Top := 202;
    izVnosFRPStroskiSurovine.Width := 490;
    izVnosFRPStroskiSurovine.Height := 14;
    izVnosFRPStroskiSurovine.Anchors := [];
    izVnosFRPStroskiSurovine.Caption := '5. Stro'#353'ki oziroma izdatki v opazovanem obdobju za nabavo surovin, namenjenih za izdelavo izdelkov:';
    izVnosFRPStroskiSurovine.Color := clNone;
    izVnosFRPStroskiSurovine.Font.Charset := DEFAULT_CHARSET;
    izVnosFRPStroskiSurovine.Font.Color := clWindowText;
    izVnosFRPStroskiSurovine.Font.Height := -11;
    izVnosFRPStroskiSurovine.Font.Name := 'Arial';
    izVnosFRPStroskiSurovine.Font.Style := [];
    izVnosFRPStroskiSurovine.HeightPercent := 100.000000000000000000;
    izVnosFRPStroskiSurovine.Layout := tlCenter;
    izVnosFRPStroskiSurovine.ParentFont := False;
    izVnosFRPStroskiSurovine.WidthPercent := 100.000000000000000000;
    izVnosFRPStroskiMaterial.SetParentComponent(ploscicaVnosFinancnihPodatkovPodjetja);
    izVnosFRPStroskiMaterial.Name := 'izVnosFRPStroskiMaterial';
    izVnosFRPStroskiMaterial.Left := 2;
    izVnosFRPStroskiMaterial.Top := 240;
    izVnosFRPStroskiMaterial.Width := 628;
    izVnosFRPStroskiMaterial.Height := 14;
    izVnosFRPStroskiMaterial.Anchors := [];
    izVnosFRPStroskiMaterial.Caption := '6. Stro'#353'ki oziroma izdatki v opazovanem obdobju za nabavo materiala, ki je bil porabljen za proizvodnjo izdelkov ali dobavo storitev:';
    izVnosFRPStroskiMaterial.Color := clNone;
    izVnosFRPStroskiMaterial.Font.Charset := DEFAULT_CHARSET;
    izVnosFRPStroskiMaterial.Font.Color := clWindowText;
    izVnosFRPStroskiMaterial.Font.Height := -11;
    izVnosFRPStroskiMaterial.Font.Name := 'Arial';
    izVnosFRPStroskiMaterial.Font.Style := [];
    izVnosFRPStroskiMaterial.HeightPercent := 100.000000000000000000;
    izVnosFRPStroskiMaterial.Layout := tlCenter;
    izVnosFRPStroskiMaterial.ParentFont := False;
    izVnosFRPStroskiMaterial.WidthPercent := 100.000000000000000000;
    izVnosFRPStroskiEnergija.SetParentComponent(ploscicaVnosFinancnihPodatkovPodjetja);
    izVnosFRPStroskiEnergija.Name := 'izVnosFRPStroskiEnergija';
    izVnosFRPStroskiEnergija.Left := 2;
    izVnosFRPStroskiEnergija.Top := 278;
    izVnosFRPStroskiEnergija.Width := 346;
    izVnosFRPStroskiEnergija.Height := 14;
    izVnosFRPStroskiEnergija.Anchors := [];
    izVnosFRPStroskiEnergija.Caption := '7. Stro'#353'ki energije v opazovanem obdobju, ki ni namenjena vzdr'#382'evanju:';
    izVnosFRPStroskiEnergija.Color := clNone;
    izVnosFRPStroskiEnergija.Font.Charset := DEFAULT_CHARSET;
    izVnosFRPStroskiEnergija.Font.Color := clWindowText;
    izVnosFRPStroskiEnergija.Font.Height := -11;
    izVnosFRPStroskiEnergija.Font.Name := 'Arial';
    izVnosFRPStroskiEnergija.Font.Style := [];
    izVnosFRPStroskiEnergija.HeightPercent := 100.000000000000000000;
    izVnosFRPStroskiEnergija.Layout := tlCenter;
    izVnosFRPStroskiEnergija.ParentFont := False;
    izVnosFRPStroskiEnergija.WidthPercent := 100.000000000000000000;
    izVnosFRPStroskiTrgovskoBlago.SetParentComponent(ploscicaVnosFinancnihPodatkovPodjetja);
    izVnosFRPStroskiTrgovskoBlago.Name := 'izVnosFRPStroskiTrgovskoBlago';
    izVnosFRPStroskiTrgovskoBlago.Left := 2;
    izVnosFRPStroskiTrgovskoBlago.Top := 316;
    izVnosFRPStroskiTrgovskoBlago.Width := 234;
    izVnosFRPStroskiTrgovskoBlago.Height := 14;
    izVnosFRPStroskiTrgovskoBlago.Anchors := [];
    izVnosFRPStroskiTrgovskoBlago.Caption := '8. Stro'#353'ki ali izdatki za nabavo trgovskega blaga:';
    izVnosFRPStroskiTrgovskoBlago.Color := clNone;
    izVnosFRPStroskiTrgovskoBlago.Font.Charset := DEFAULT_CHARSET;
    izVnosFRPStroskiTrgovskoBlago.Font.Color := clWindowText;
    izVnosFRPStroskiTrgovskoBlago.Font.Height := -11;
    izVnosFRPStroskiTrgovskoBlago.Font.Name := 'Arial';
    izVnosFRPStroskiTrgovskoBlago.Font.Style := [];
    izVnosFRPStroskiTrgovskoBlago.HeightPercent := 100.000000000000000000;
    izVnosFRPStroskiTrgovskoBlago.Layout := tlCenter;
    izVnosFRPStroskiTrgovskoBlago.ParentFont := False;
    izVnosFRPStroskiTrgovskoBlago.WidthPercent := 100.000000000000000000;
    izVnosFRPStroskiStoritve.SetParentComponent(ploscicaVnosFinancnihPodatkovPodjetja);
    izVnosFRPStroskiStoritve.Name := 'izVnosFRPStroskiStoritve';
    izVnosFRPStroskiStoritve.Left := 2;
    izVnosFRPStroskiStoritve.Top := 354;
    izVnosFRPStroskiStoritve.Width := 626;
    izVnosFRPStroskiStoritve.Height := 14;
    izVnosFRPStroskiStoritve.Anchors := [];
    izVnosFRPStroskiStoritve.Caption := '9. Stro'#353'ki ali izdatki za druge storitve zunanjih izvajalcev v opazovanem obdobju, namenjene preprodaji, ki niso urejene s pogodbo:';
    izVnosFRPStroskiStoritve.Color := clNone;
    izVnosFRPStroskiStoritve.Font.Charset := DEFAULT_CHARSET;
    izVnosFRPStroskiStoritve.Font.Color := clWindowText;
    izVnosFRPStroskiStoritve.Font.Height := -11;
    izVnosFRPStroskiStoritve.Font.Name := 'Arial';
    izVnosFRPStroskiStoritve.Font.Style := [];
    izVnosFRPStroskiStoritve.HeightPercent := 100.000000000000000000;
    izVnosFRPStroskiStoritve.Layout := tlCenter;
    izVnosFRPStroskiStoritve.ParentFont := False;
    izVnosFRPStroskiStoritve.WidthPercent := 100.000000000000000000;
    izVnosFRPDrugiStroski.SetParentComponent(ploscicaVnosFinancnihPodatkovPodjetja);
    izVnosFRPDrugiStroski.Name := 'izVnosFRPDrugiStroski';
    izVnosFRPDrugiStroski.Left := 2;
    izVnosFRPDrugiStroski.Top := 392;
    izVnosFRPDrugiStroski.Width := 339;
    izVnosFRPDrugiStroski.Height := 14;
    izVnosFRPDrugiStroski.Anchors := [];
    izVnosFRPDrugiStroski.Caption := '10. Drugi stro'#353'ki ali izdatki v opazovanem obdobju, odvisni od prometa:';
    izVnosFRPDrugiStroski.Color := clNone;
    izVnosFRPDrugiStroski.Font.Charset := DEFAULT_CHARSET;
    izVnosFRPDrugiStroski.Font.Color := clWindowText;
    izVnosFRPDrugiStroski.Font.Height := -11;
    izVnosFRPDrugiStroski.Font.Name := 'Arial';
    izVnosFRPDrugiStroski.Font.Style := [];
    izVnosFRPDrugiStroski.HeightPercent := 100.000000000000000000;
    izVnosFRPDrugiStroski.Layout := tlCenter;
    izVnosFRPDrugiStroski.ParentFont := False;
    izVnosFRPDrugiStroski.WidthPercent := 100.000000000000000000;
    izVnosFRPPrejeteZavarovalnine.SetParentComponent(ploscicaVnosFinancnihPodatkovPodjetja);
    izVnosFRPPrejeteZavarovalnine.Name := 'izVnosFRPPrejeteZavarovalnine';
    izVnosFRPPrejeteZavarovalnine.Left := 2;
    izVnosFRPPrejeteZavarovalnine.Top := 430;
    izVnosFRPPrejeteZavarovalnine.Width := 529;
    izVnosFRPPrejeteZavarovalnine.Height := 14;
    izVnosFRPPrejeteZavarovalnine.Anchors := [];
    izVnosFRPPrejeteZavarovalnine.Caption := '11. Prejete zavarovalnine iz zavarovanja zalog in prejete zavarovalnine iz zavarovanja obratovalnega zastoja:';
    izVnosFRPPrejeteZavarovalnine.Color := clNone;
    izVnosFRPPrejeteZavarovalnine.Font.Charset := DEFAULT_CHARSET;
    izVnosFRPPrejeteZavarovalnine.Font.Color := clWindowText;
    izVnosFRPPrejeteZavarovalnine.Font.Height := -11;
    izVnosFRPPrejeteZavarovalnine.Font.Name := 'Arial';
    izVnosFRPPrejeteZavarovalnine.Font.Style := [];
    izVnosFRPPrejeteZavarovalnine.HeightPercent := 100.000000000000000000;
    izVnosFRPPrejeteZavarovalnine.Layout := tlCenter;
    izVnosFRPPrejeteZavarovalnine.ParentFont := False;
    izVnosFRPPrejeteZavarovalnine.WidthPercent := 100.000000000000000000;
    izVnosFRPKosmatiDobicek.SetParentComponent(ploscicaVnosFinancnihPodatkovPodjetja);
    izVnosFRPKosmatiDobicek.Name := 'izVnosFRPKosmatiDobicek';
    izVnosFRPKosmatiDobicek.Left := 2;
    izVnosFRPKosmatiDobicek.Top := 465;
    izVnosFRPKosmatiDobicek.Width := 620;
    izVnosFRPKosmatiDobicek.Height := 19;
    izVnosFRPKosmatiDobicek.Alignment := taRightJustify;
    izVnosFRPKosmatiDobicek.Anchors := [];
    izVnosFRPKosmatiDobicek.AutoSize := False;
    izVnosFRPKosmatiDobicek.Caption := 'Kosmati dobi'#269'ek:';
    izVnosFRPKosmatiDobicek.Color := clNone;
    izVnosFRPKosmatiDobicek.Font.Charset := DEFAULT_CHARSET;
    izVnosFRPKosmatiDobicek.Font.Color := clWindowText;
    izVnosFRPKosmatiDobicek.Font.Height := -11;
    izVnosFRPKosmatiDobicek.Font.Name := 'Arial';
    izVnosFRPKosmatiDobicek.Font.Style := [fsBold];
    izVnosFRPKosmatiDobicek.HeightPercent := 100.000000000000000000;
    izVnosFRPKosmatiDobicek.Layout := tlCenter;
    izVnosFRPKosmatiDobicek.ParentFont := False;
    izVnosFRPKosmatiDobicek.WidthPercent := 100.000000000000000000;
    vpVnosFRPKosmatiDobicek.SetParentComponent(ploscicaVnosFinancnihPodatkovPodjetja);
    vpVnosFRPKosmatiDobicek.Name := 'vpVnosFRPKosmatiDobicek';
    vpVnosFRPKosmatiDobicek.Left := 703;
    vpVnosFRPKosmatiDobicek.Top := 465;
    vpVnosFRPKosmatiDobicek.Width := 125;
    vpVnosFRPKosmatiDobicek.Height := 19;
    vpVnosFRPKosmatiDobicek.Alignment := taRightJustify;
    vpVnosFRPKosmatiDobicek.Anchors := [];
    vpVnosFRPKosmatiDobicek.AutoSize := False;
    vpVnosFRPKosmatiDobicek.Caption := '0,00';
    vpVnosFRPKosmatiDobicek.Color := clNone;
    vpVnosFRPKosmatiDobicek.Font.Charset := DEFAULT_CHARSET;
    vpVnosFRPKosmatiDobicek.Font.Color := clWindowText;
    vpVnosFRPKosmatiDobicek.Font.Height := -11;
    vpVnosFRPKosmatiDobicek.Font.Name := 'Arial';
    vpVnosFRPKosmatiDobicek.Font.Style := [fsBold];
    vpVnosFRPKosmatiDobicek.HeightPercent := 100.000000000000000000;
    vpVnosFRPKosmatiDobicek.Layout := tlCenter;
    vpVnosFRPKosmatiDobicek.ParentFont := False;
    vpVnosFRPKosmatiDobicek.WidthPercent := 100.000000000000000000;
    izVnosFRPStopnjaKosmategaDobicka.SetParentComponent(ploscicaVnosFinancnihPodatkovPodjetja);
    izVnosFRPStopnjaKosmategaDobicka.Name := 'izVnosFRPStopnjaKosmategaDobicka';
    izVnosFRPStopnjaKosmategaDobicka.Left := 2;
    izVnosFRPStopnjaKosmategaDobicka.Top := 503;
    izVnosFRPStopnjaKosmategaDobicka.Width := 620;
    izVnosFRPStopnjaKosmategaDobicka.Height := 19;
    izVnosFRPStopnjaKosmategaDobicka.Alignment := taRightJustify;
    izVnosFRPStopnjaKosmategaDobicka.Anchors := [];
    izVnosFRPStopnjaKosmategaDobicka.AutoSize := False;
    izVnosFRPStopnjaKosmategaDobicka.Caption := 'Stopnja kosmatega dobi'#269'ka:';
    izVnosFRPStopnjaKosmategaDobicka.Color := clNone;
    izVnosFRPStopnjaKosmategaDobicka.Font.Charset := DEFAULT_CHARSET;
    izVnosFRPStopnjaKosmategaDobicka.Font.Color := clWindowText;
    izVnosFRPStopnjaKosmategaDobicka.Font.Height := -11;
    izVnosFRPStopnjaKosmategaDobicka.Font.Name := 'Arial';
    izVnosFRPStopnjaKosmategaDobicka.Font.Style := [fsBold];
    izVnosFRPStopnjaKosmategaDobicka.HeightPercent := 100.000000000000000000;
    izVnosFRPStopnjaKosmategaDobicka.Layout := tlCenter;
    izVnosFRPStopnjaKosmategaDobicka.ParentFont := False;
    izVnosFRPStopnjaKosmategaDobicka.WidthPercent := 100.000000000000000000;
    vpVnosFRPStopnjaKosmategaDobicka.SetParentComponent(ploscicaVnosFinancnihPodatkovPodjetja);
    vpVnosFRPStopnjaKosmategaDobicka.Name := 'vpVnosFRPStopnjaKosmategaDobicka';
    vpVnosFRPStopnjaKosmategaDobicka.Left := 703;
    vpVnosFRPStopnjaKosmategaDobicka.Top := 503;
    vpVnosFRPStopnjaKosmategaDobicka.Width := 125;
    vpVnosFRPStopnjaKosmategaDobicka.Height := 19;
    vpVnosFRPStopnjaKosmategaDobicka.Alignment := taRightJustify;
    vpVnosFRPStopnjaKosmategaDobicka.Anchors := [];
    vpVnosFRPStopnjaKosmategaDobicka.AutoSize := False;
    vpVnosFRPStopnjaKosmategaDobicka.Caption := '0,00';
    vpVnosFRPStopnjaKosmategaDobicka.Color := clNone;
    vpVnosFRPStopnjaKosmategaDobicka.Font.Charset := DEFAULT_CHARSET;
    vpVnosFRPStopnjaKosmategaDobicka.Font.Color := clWindowText;
    vpVnosFRPStopnjaKosmategaDobicka.Font.Height := -11;
    vpVnosFRPStopnjaKosmategaDobicka.Font.Name := 'Arial';
    vpVnosFRPStopnjaKosmategaDobicka.Font.Style := [fsBold];
    vpVnosFRPStopnjaKosmategaDobicka.HeightPercent := 100.000000000000000000;
    vpVnosFRPStopnjaKosmategaDobicka.Layout := tlCenter;
    vpVnosFRPStopnjaKosmategaDobicka.ParentFont := False;
    vpVnosFRPStopnjaKosmategaDobicka.WidthPercent := 100.000000000000000000;
    vpOpazovanoLeto.SetParentComponent(ploscicaVnosFinancnihPodatkovPodjetja);
    vpOpazovanoLeto.Name := 'vpOpazovanoLeto';
    vpOpazovanoLeto.Left := 703;
    vpOpazovanoLeto.Top := 8;
    vpOpazovanoLeto.Width := 125;
    vpOpazovanoLeto.Height := 22;
    vpOpazovanoLeto.Alignment := taRightJustify;
    vpOpazovanoLeto.Anchors := [];
    vpOpazovanoLeto.ChildOrder := 1;
    vpOpazovanoLeto.HeightPercent := 100.000000000000000000;
    vpOpazovanoLeto.ReadOnly := True;
    vpOpazovanoLeto.WidthPercent := 100.000000000000000000;
    vpVnosFRPPromet.SetParentComponent(ploscicaVnosFinancnihPodatkovPodjetja);
    vpVnosFRPPromet.Name := 'vpVnosFRPPromet';
    vpVnosFRPPromet.Left := 703;
    vpVnosFRPPromet.Top := 46;
    vpVnosFRPPromet.Width := 125;
    vpVnosFRPPromet.Height := 22;
    vpVnosFRPPromet.Alignment := taRightJustify;
    vpVnosFRPPromet.Anchors := [];
    vpVnosFRPPromet.ChildOrder := 3;
    vpVnosFRPPromet.HeightPercent := 100.000000000000000000;
    vpVnosFRPPromet.Text := '0,00';
    vpVnosFRPPromet.WidthPercent := 100.000000000000000000;
    SetEvent(vpVnosFRPPromet, Self, 'OnExit', 'vpVnosFRPExit');
    SetEvent(vpVnosFRPPromet, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    vpVnosFRPZalogeZacetek.SetParentComponent(ploscicaVnosFinancnihPodatkovPodjetja);
    vpVnosFRPZalogeZacetek.Name := 'vpVnosFRPZalogeZacetek';
    vpVnosFRPZalogeZacetek.Left := 703;
    vpVnosFRPZalogeZacetek.Top := 84;
    vpVnosFRPZalogeZacetek.Width := 125;
    vpVnosFRPZalogeZacetek.Height := 22;
    vpVnosFRPZalogeZacetek.Alignment := taRightJustify;
    vpVnosFRPZalogeZacetek.Anchors := [];
    vpVnosFRPZalogeZacetek.ChildOrder := 5;
    vpVnosFRPZalogeZacetek.HeightPercent := 100.000000000000000000;
    vpVnosFRPZalogeZacetek.Text := '0,00';
    vpVnosFRPZalogeZacetek.WidthPercent := 100.000000000000000000;
    SetEvent(vpVnosFRPZalogeZacetek, Self, 'OnExit', 'vpVnosFRPExit');
    SetEvent(vpVnosFRPZalogeZacetek, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    vpVnosFRPZalogeKonec.SetParentComponent(ploscicaVnosFinancnihPodatkovPodjetja);
    vpVnosFRPZalogeKonec.Name := 'vpVnosFRPZalogeKonec';
    vpVnosFRPZalogeKonec.Left := 703;
    vpVnosFRPZalogeKonec.Top := 122;
    vpVnosFRPZalogeKonec.Width := 125;
    vpVnosFRPZalogeKonec.Height := 22;
    vpVnosFRPZalogeKonec.Alignment := taRightJustify;
    vpVnosFRPZalogeKonec.Anchors := [];
    vpVnosFRPZalogeKonec.ChildOrder := 7;
    vpVnosFRPZalogeKonec.HeightPercent := 100.000000000000000000;
    vpVnosFRPZalogeKonec.Text := '0,00';
    vpVnosFRPZalogeKonec.WidthPercent := 100.000000000000000000;
    SetEvent(vpVnosFRPZalogeKonec, Self, 'OnExit', 'vpVnosFRPExit');
    SetEvent(vpVnosFRPZalogeKonec, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    vpVnosFRPInternoProizvedeniDodatki.SetParentComponent(ploscicaVnosFinancnihPodatkovPodjetja);
    vpVnosFRPInternoProizvedeniDodatki.Name := 'vpVnosFRPInternoProizvedeniDodatki';
    vpVnosFRPInternoProizvedeniDodatki.Left := 703;
    vpVnosFRPInternoProizvedeniDodatki.Top := 160;
    vpVnosFRPInternoProizvedeniDodatki.Width := 125;
    vpVnosFRPInternoProizvedeniDodatki.Height := 22;
    vpVnosFRPInternoProizvedeniDodatki.Alignment := taRightJustify;
    vpVnosFRPInternoProizvedeniDodatki.Anchors := [];
    vpVnosFRPInternoProizvedeniDodatki.ChildOrder := 9;
    vpVnosFRPInternoProizvedeniDodatki.HeightPercent := 100.000000000000000000;
    vpVnosFRPInternoProizvedeniDodatki.Text := '0,00';
    vpVnosFRPInternoProizvedeniDodatki.WidthPercent := 100.000000000000000000;
    SetEvent(vpVnosFRPInternoProizvedeniDodatki, Self, 'OnExit', 'vpVnosFRPExit');
    SetEvent(vpVnosFRPInternoProizvedeniDodatki, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    vpVnosFRPStroskiSurovine.SetParentComponent(ploscicaVnosFinancnihPodatkovPodjetja);
    vpVnosFRPStroskiSurovine.Name := 'vpVnosFRPStroskiSurovine';
    vpVnosFRPStroskiSurovine.Left := 703;
    vpVnosFRPStroskiSurovine.Top := 198;
    vpVnosFRPStroskiSurovine.Width := 125;
    vpVnosFRPStroskiSurovine.Height := 22;
    vpVnosFRPStroskiSurovine.Alignment := taRightJustify;
    vpVnosFRPStroskiSurovine.Anchors := [];
    vpVnosFRPStroskiSurovine.ChildOrder := 11;
    vpVnosFRPStroskiSurovine.HeightPercent := 100.000000000000000000;
    vpVnosFRPStroskiSurovine.Text := '0,00';
    vpVnosFRPStroskiSurovine.WidthPercent := 100.000000000000000000;
    SetEvent(vpVnosFRPStroskiSurovine, Self, 'OnExit', 'vpVnosFRPExit');
    SetEvent(vpVnosFRPStroskiSurovine, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    vpVnosFRPStroskiMaterial.SetParentComponent(ploscicaVnosFinancnihPodatkovPodjetja);
    vpVnosFRPStroskiMaterial.Name := 'vpVnosFRPStroskiMaterial';
    vpVnosFRPStroskiMaterial.Left := 703;
    vpVnosFRPStroskiMaterial.Top := 236;
    vpVnosFRPStroskiMaterial.Width := 125;
    vpVnosFRPStroskiMaterial.Height := 22;
    vpVnosFRPStroskiMaterial.Alignment := taRightJustify;
    vpVnosFRPStroskiMaterial.Anchors := [];
    vpVnosFRPStroskiMaterial.ChildOrder := 13;
    vpVnosFRPStroskiMaterial.HeightPercent := 100.000000000000000000;
    vpVnosFRPStroskiMaterial.Text := '0,00';
    vpVnosFRPStroskiMaterial.WidthPercent := 100.000000000000000000;
    SetEvent(vpVnosFRPStroskiMaterial, Self, 'OnExit', 'vpVnosFRPExit');
    SetEvent(vpVnosFRPStroskiMaterial, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    vpVnosFRPStroskiEnergija.SetParentComponent(ploscicaVnosFinancnihPodatkovPodjetja);
    vpVnosFRPStroskiEnergija.Name := 'vpVnosFRPStroskiEnergija';
    vpVnosFRPStroskiEnergija.Left := 703;
    vpVnosFRPStroskiEnergija.Top := 274;
    vpVnosFRPStroskiEnergija.Width := 125;
    vpVnosFRPStroskiEnergija.Height := 22;
    vpVnosFRPStroskiEnergija.Alignment := taRightJustify;
    vpVnosFRPStroskiEnergija.Anchors := [];
    vpVnosFRPStroskiEnergija.ChildOrder := 15;
    vpVnosFRPStroskiEnergija.HeightPercent := 100.000000000000000000;
    vpVnosFRPStroskiEnergija.Text := '0,00';
    vpVnosFRPStroskiEnergija.WidthPercent := 100.000000000000000000;
    SetEvent(vpVnosFRPStroskiEnergija, Self, 'OnExit', 'vpVnosFRPExit');
    SetEvent(vpVnosFRPStroskiEnergija, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    vpVnosFRPStroskiTrgovskoBlago.SetParentComponent(ploscicaVnosFinancnihPodatkovPodjetja);
    vpVnosFRPStroskiTrgovskoBlago.Name := 'vpVnosFRPStroskiTrgovskoBlago';
    vpVnosFRPStroskiTrgovskoBlago.Left := 703;
    vpVnosFRPStroskiTrgovskoBlago.Top := 312;
    vpVnosFRPStroskiTrgovskoBlago.Width := 125;
    vpVnosFRPStroskiTrgovskoBlago.Height := 22;
    vpVnosFRPStroskiTrgovskoBlago.Alignment := taRightJustify;
    vpVnosFRPStroskiTrgovskoBlago.Anchors := [];
    vpVnosFRPStroskiTrgovskoBlago.ChildOrder := 17;
    vpVnosFRPStroskiTrgovskoBlago.HeightPercent := 100.000000000000000000;
    vpVnosFRPStroskiTrgovskoBlago.Text := '0,00';
    vpVnosFRPStroskiTrgovskoBlago.WidthPercent := 100.000000000000000000;
    SetEvent(vpVnosFRPStroskiTrgovskoBlago, Self, 'OnExit', 'vpVnosFRPExit');
    SetEvent(vpVnosFRPStroskiTrgovskoBlago, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    vpVnosFRPStroskiStoritve.SetParentComponent(ploscicaVnosFinancnihPodatkovPodjetja);
    vpVnosFRPStroskiStoritve.Name := 'vpVnosFRPStroskiStoritve';
    vpVnosFRPStroskiStoritve.Left := 703;
    vpVnosFRPStroskiStoritve.Top := 350;
    vpVnosFRPStroskiStoritve.Width := 125;
    vpVnosFRPStroskiStoritve.Height := 22;
    vpVnosFRPStroskiStoritve.Alignment := taRightJustify;
    vpVnosFRPStroskiStoritve.Anchors := [];
    vpVnosFRPStroskiStoritve.ChildOrder := 19;
    vpVnosFRPStroskiStoritve.HeightPercent := 100.000000000000000000;
    vpVnosFRPStroskiStoritve.Text := '0,00';
    vpVnosFRPStroskiStoritve.WidthPercent := 100.000000000000000000;
    SetEvent(vpVnosFRPStroskiStoritve, Self, 'OnExit', 'vpVnosFRPExit');
    SetEvent(vpVnosFRPStroskiStoritve, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    vpVnosFRPDrugiStroski.SetParentComponent(ploscicaVnosFinancnihPodatkovPodjetja);
    vpVnosFRPDrugiStroski.Name := 'vpVnosFRPDrugiStroski';
    vpVnosFRPDrugiStroski.Left := 703;
    vpVnosFRPDrugiStroski.Top := 388;
    vpVnosFRPDrugiStroski.Width := 125;
    vpVnosFRPDrugiStroski.Height := 22;
    vpVnosFRPDrugiStroski.Alignment := taRightJustify;
    vpVnosFRPDrugiStroski.Anchors := [];
    vpVnosFRPDrugiStroski.ChildOrder := 21;
    vpVnosFRPDrugiStroski.HeightPercent := 100.000000000000000000;
    vpVnosFRPDrugiStroski.Text := '0,00';
    vpVnosFRPDrugiStroski.WidthPercent := 100.000000000000000000;
    SetEvent(vpVnosFRPDrugiStroski, Self, 'OnExit', 'vpVnosFRPExit');
    SetEvent(vpVnosFRPDrugiStroski, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    vpVnosFRPPrejeteZavarovalnine.SetParentComponent(ploscicaVnosFinancnihPodatkovPodjetja);
    vpVnosFRPPrejeteZavarovalnine.Name := 'vpVnosFRPPrejeteZavarovalnine';
    vpVnosFRPPrejeteZavarovalnine.Left := 703;
    vpVnosFRPPrejeteZavarovalnine.Top := 426;
    vpVnosFRPPrejeteZavarovalnine.Width := 125;
    vpVnosFRPPrejeteZavarovalnine.Height := 22;
    vpVnosFRPPrejeteZavarovalnine.Alignment := taRightJustify;
    vpVnosFRPPrejeteZavarovalnine.Anchors := [];
    vpVnosFRPPrejeteZavarovalnine.ChildOrder := 23;
    vpVnosFRPPrejeteZavarovalnine.HeightPercent := 100.000000000000000000;
    vpVnosFRPPrejeteZavarovalnine.Text := '0,00';
    vpVnosFRPPrejeteZavarovalnine.WidthPercent := 100.000000000000000000;
    SetEvent(vpVnosFRPPrejeteZavarovalnine, Self, 'OnExit', 'vpVnosFRPExit');
    SetEvent(vpVnosFRPPrejeteZavarovalnine, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    DelovniList3.SetParentComponent(ploscicaDelovniListi);
    DelovniList3.Name := 'DelovniList3';
    DelovniList3.Left := 0;
    DelovniList3.Top := 20;
    DelovniList3.Width := 1157;
    DelovniList3.Height := 1090;
    DelovniList3.HeightPercent := 100.000000000000000000;
    DelovniList3.WidthPercent := 100.000000000000000000;
    DelovniList3.Caption := 'DelovniList3';
    DelovniList3.ChildOrder := 5;
    DelovniList3.TabVisible := False;
    WebScrollBoxDL3.SetParentComponent(DelovniList3);
    WebScrollBoxDL3.Name := 'WebScrollBoxDL3';
    WebScrollBoxDL3.Left := 0;
    WebScrollBoxDL3.Top := 0;
    WebScrollBoxDL3.Width := 1157;
    WebScrollBoxDL3.Height := 1090;
    WebScrollBoxDL3.HeightPercent := 100.000000000000000000;
    WebScrollBoxDL3.WidthPercent := 100.000000000000000000;
    WebScrollBoxDL3.Align := alClient;
    WebScrollBoxDL3.AutoScroll := True;
    WebScrollBoxDL3.BorderStyle := bsNone;
    WebScrollBoxDL3.ChildOrder := 16;
    WebScrollBoxDL3.Color := clWindow;
    izNaslovDL3.SetParentComponent(WebScrollBoxDL3);
    izNaslovDL3.Name := 'izNaslovDL3';
    izNaslovDL3.Left := 12;
    izNaslovDL3.Top := 20;
    izNaslovDL3.Width := 200;
    izNaslovDL3.Height := 19;
    izNaslovDL3.Anchors := [];
    izNaslovDL3.Caption := 'Podatki o prodaji podjetja';
    izNaslovDL3.Color := clNone;
    izNaslovDL3.Font.Charset := DEFAULT_CHARSET;
    izNaslovDL3.Font.Color := clWindowText;
    izNaslovDL3.Font.Height := -17;
    izNaslovDL3.Font.Name := 'Arial';
    izNaslovDL3.Font.Style := [fsBold];
    izNaslovDL3.HeightPercent := 100.000000000000000000;
    izNaslovDL3.ParentFont := False;
    izNaslovDL3.WidthPercent := 100.000000000000000000;
    izPlanProdaje_LetoXplus1.SetParentComponent(WebScrollBoxDL3);
    izPlanProdaje_LetoXplus1.Name := 'izPlanProdaje_LetoXplus1';
    izPlanProdaje_LetoXplus1.Left := 15;
    izPlanProdaje_LetoXplus1.Top := 525;
    izPlanProdaje_LetoXplus1.Width := 46;
    izPlanProdaje_LetoXplus1.Height := 15;
    izPlanProdaje_LetoXplus1.Caption := 'leto: x+1';
    izPlanProdaje_LetoXplus1.Color := clNone;
    izPlanProdaje_LetoXplus1.HeightPercent := 100.000000000000000000;
    izPlanProdaje_LetoXplus1.WidthPercent := 100.000000000000000000;
    izPlanProdaje_LetoXplus2.SetParentComponent(WebScrollBoxDL3);
    izPlanProdaje_LetoXplus2.Name := 'izPlanProdaje_LetoXplus2';
    izPlanProdaje_LetoXplus2.Left := 15;
    izPlanProdaje_LetoXplus2.Top := 575;
    izPlanProdaje_LetoXplus2.Width := 46;
    izPlanProdaje_LetoXplus2.Height := 15;
    izPlanProdaje_LetoXplus2.Caption := 'leto: x+2';
    izPlanProdaje_LetoXplus2.Color := clNone;
    izPlanProdaje_LetoXplus2.HeightPercent := 100.000000000000000000;
    izPlanProdaje_LetoXplus2.WidthPercent := 100.000000000000000000;
    izPlanProdaje_LetoXplus3.SetParentComponent(WebScrollBoxDL3);
    izPlanProdaje_LetoXplus3.Name := 'izPlanProdaje_LetoXplus3';
    izPlanProdaje_LetoXplus3.Left := 15;
    izPlanProdaje_LetoXplus3.Top := 625;
    izPlanProdaje_LetoXplus3.Width := 46;
    izPlanProdaje_LetoXplus3.Height := 15;
    izPlanProdaje_LetoXplus3.Caption := 'leto: x+3';
    izPlanProdaje_LetoXplus3.Color := clNone;
    izPlanProdaje_LetoXplus3.HeightPercent := 100.000000000000000000;
    izPlanProdaje_LetoXplus3.WidthPercent := 100.000000000000000000;
    izPlanProdaje_LetoXplus4.SetParentComponent(WebScrollBoxDL3);
    izPlanProdaje_LetoXplus4.Name := 'izPlanProdaje_LetoXplus4';
    izPlanProdaje_LetoXplus4.Left := 15;
    izPlanProdaje_LetoXplus4.Top := 675;
    izPlanProdaje_LetoXplus4.Width := 46;
    izPlanProdaje_LetoXplus4.Height := 15;
    izPlanProdaje_LetoXplus4.Caption := 'leto: x+4';
    izPlanProdaje_LetoXplus4.Color := clNone;
    izPlanProdaje_LetoXplus4.HeightPercent := 100.000000000000000000;
    izPlanProdaje_LetoXplus4.WidthPercent := 100.000000000000000000;
    izPlanProdaje_Naslov.SetParentComponent(WebScrollBoxDL3);
    izPlanProdaje_Naslov.Name := 'izPlanProdaje_Naslov';
    izPlanProdaje_Naslov.Left := 15;
    izPlanProdaje_Naslov.Top := 475;
    izPlanProdaje_Naslov.Width := 219;
    izPlanProdaje_Naslov.Height := 14;
    izPlanProdaje_Naslov.Caption := 'Planirana rast prodaje v naslednjih letih:';
    izPlanProdaje_Naslov.Color := clNone;
    izPlanProdaje_Naslov.Font.Charset := DEFAULT_CHARSET;
    izPlanProdaje_Naslov.Font.Color := clWindowText;
    izPlanProdaje_Naslov.Font.Height := -11;
    izPlanProdaje_Naslov.Font.Name := 'Arial';
    izPlanProdaje_Naslov.Font.Style := [fsBold];
    izPlanProdaje_Naslov.HeightPercent := 100.000000000000000000;
    izPlanProdaje_Naslov.ParentFont := False;
    izPlanProdaje_Naslov.WidthPercent := 100.000000000000000000;
    izPlanProdaje_RastXplus1.SetParentComponent(WebScrollBoxDL3);
    izPlanProdaje_RastXplus1.Name := 'izPlanProdaje_RastXplus1';
    izPlanProdaje_RastXplus1.Left := 300;
    izPlanProdaje_RastXplus1.Top := 525;
    izPlanProdaje_RastXplus1.Width := 19;
    izPlanProdaje_RastXplus1.Height := 15;
    izPlanProdaje_RastXplus1.Caption := '0 %';
    izPlanProdaje_RastXplus1.Color := clNone;
    izPlanProdaje_RastXplus1.HeightPercent := 100.000000000000000000;
    izPlanProdaje_RastXplus1.WidthPercent := 100.000000000000000000;
    izPlanProdaje_RastXplus2.SetParentComponent(WebScrollBoxDL3);
    izPlanProdaje_RastXplus2.Name := 'izPlanProdaje_RastXplus2';
    izPlanProdaje_RastXplus2.Left := 300;
    izPlanProdaje_RastXplus2.Top := 575;
    izPlanProdaje_RastXplus2.Width := 19;
    izPlanProdaje_RastXplus2.Height := 15;
    izPlanProdaje_RastXplus2.Caption := '0 %';
    izPlanProdaje_RastXplus2.Color := clNone;
    izPlanProdaje_RastXplus2.HeightPercent := 100.000000000000000000;
    izPlanProdaje_RastXplus2.WidthPercent := 100.000000000000000000;
    izPlanProdaje_RastXplus3.SetParentComponent(WebScrollBoxDL3);
    izPlanProdaje_RastXplus3.Name := 'izPlanProdaje_RastXplus3';
    izPlanProdaje_RastXplus3.Left := 300;
    izPlanProdaje_RastXplus3.Top := 625;
    izPlanProdaje_RastXplus3.Width := 19;
    izPlanProdaje_RastXplus3.Height := 15;
    izPlanProdaje_RastXplus3.Caption := '0 %';
    izPlanProdaje_RastXplus3.Color := clNone;
    izPlanProdaje_RastXplus3.HeightPercent := 100.000000000000000000;
    izPlanProdaje_RastXplus3.WidthPercent := 100.000000000000000000;
    izPlanProdaje_RastXplus4.SetParentComponent(WebScrollBoxDL3);
    izPlanProdaje_RastXplus4.Name := 'izPlanProdaje_RastXplus4';
    izPlanProdaje_RastXplus4.Left := 300;
    izPlanProdaje_RastXplus4.Top := 675;
    izPlanProdaje_RastXplus4.Width := 19;
    izPlanProdaje_RastXplus4.Height := 15;
    izPlanProdaje_RastXplus4.Caption := '0 %';
    izPlanProdaje_RastXplus4.Color := clNone;
    izPlanProdaje_RastXplus4.HeightPercent := 100.000000000000000000;
    izPlanProdaje_RastXplus4.WidthPercent := 100.000000000000000000;
    ploscicaTabelaPrometaX.SetParentComponent(WebScrollBoxDL3);
    ploscicaTabelaPrometaX.Name := 'ploscicaTabelaPrometaX';
    ploscicaTabelaPrometaX.Left := 275;
    ploscicaTabelaPrometaX.Top := 80;
    ploscicaTabelaPrometaX.Width := 130;
    ploscicaTabelaPrometaX.Height := 344;
    ploscicaTabelaPrometaX.HeightPercent := 100.000000000000000000;
    ploscicaTabelaPrometaX.WidthPercent := 100.000000000000000000;
    ploscicaTabelaPrometaX.ChildOrder := 15;
    ploscicaTabelaPrometaX.ColumnCollection.Clear;
    with ploscicaTabelaPrometaX.ColumnCollection.Add do
    begin
      Alignment := taCenter;
      Value := 20;
    end;
    ploscicaTabelaPrometaX.ControlCollection.Clear;
    with ploscicaTabelaPrometaX.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := vpTabelaPromet_LetoX;
    end;
    with ploscicaTabelaPrometaX.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := vpTabelaPromet_ProdajaX_1;
    end;
    with ploscicaTabelaPrometaX.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := vpTabelaPromet_ProdajaX_2;
    end;
    with ploscicaTabelaPrometaX.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := vpTabelaPromet_ProdajaX_3;
    end;
    with ploscicaTabelaPrometaX.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := vpTabelaPromet_ProdajaX_4;
    end;
    with ploscicaTabelaPrometaX.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := vpTabelaPromet_ProdajaX_5;
    end;
    with ploscicaTabelaPrometaX.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := vpTabelaPromet_ProdajaX_6;
    end;
    with ploscicaTabelaPrometaX.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := vpTabelaPromet_ProdajaX_7;
    end;
    with ploscicaTabelaPrometaX.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := vpTabelaPromet_ProdajaX_8;
    end;
    with ploscicaTabelaPrometaX.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := vpTabelaPromet_ProdajaX_9;
    end;
    with ploscicaTabelaPrometaX.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := vpTabelaPromet_ProdajaX_10;
    end;
    with ploscicaTabelaPrometaX.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := vpTabelaPromet_ProdajaX_11;
    end;
    with ploscicaTabelaPrometaX.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := vpTabelaPromet_ProdajaX_12;
    end;
    with ploscicaTabelaPrometaX.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := vpTabelaPromet_LetoXSkupaj;
    end;
    ploscicaTabelaPrometaX.Color := clWhite;
    ploscicaTabelaPrometaX.GridLineColor := clBlack;
    ploscicaTabelaPrometaX.RowCollection.Clear;
    with ploscicaTabelaPrometaX.RowCollection.Add do
    begin
      Value := 7;
    end;
    with ploscicaTabelaPrometaX.RowCollection.Add do
    begin
      Value := 7;
    end;
    with ploscicaTabelaPrometaX.RowCollection.Add do
    begin
      Value := 7;
    end;
    with ploscicaTabelaPrometaX.RowCollection.Add do
    begin
      Value := 7;
    end;
    with ploscicaTabelaPrometaX.RowCollection.Add do
    begin
      Value := 7;
    end;
    with ploscicaTabelaPrometaX.RowCollection.Add do
    begin
      Value := 7;
    end;
    with ploscicaTabelaPrometaX.RowCollection.Add do
    begin
      Value := 7;
    end;
    with ploscicaTabelaPrometaX.RowCollection.Add do
    begin
      Value := 7;
    end;
    with ploscicaTabelaPrometaX.RowCollection.Add do
    begin
      Value := 7;
    end;
    with ploscicaTabelaPrometaX.RowCollection.Add do
    begin
      Value := 7;
    end;
    with ploscicaTabelaPrometaX.RowCollection.Add do
    begin
      Value := 7;
    end;
    with ploscicaTabelaPrometaX.RowCollection.Add do
    begin
      Value := 7;
    end;
    with ploscicaTabelaPrometaX.RowCollection.Add do
    begin
      Value := 7;
    end;
    with ploscicaTabelaPrometaX.RowCollection.Add do
    begin
      Value := 7;
    end;
    vpTabelaPromet_LetoX.SetParentComponent(ploscicaTabelaPrometaX);
    vpTabelaPromet_LetoX.Name := 'vpTabelaPromet_LetoX';
    vpTabelaPromet_LetoX.Left := -11;
    vpTabelaPromet_LetoX.Top := 2;
    vpTabelaPromet_LetoX.Width := 48;
    vpTabelaPromet_LetoX.Height := 14;
    vpTabelaPromet_LetoX.Anchors := [];
    vpTabelaPromet_LetoX.Caption := 'leto xxxx';
    vpTabelaPromet_LetoX.Color := clNone;
    vpTabelaPromet_LetoX.Font.Charset := DEFAULT_CHARSET;
    vpTabelaPromet_LetoX.Font.Color := clWindowText;
    vpTabelaPromet_LetoX.Font.Height := -11;
    vpTabelaPromet_LetoX.Font.Name := 'Arial';
    vpTabelaPromet_LetoX.Font.Style := [fsBold];
    vpTabelaPromet_LetoX.HeightPercent := 100.000000000000000000;
    vpTabelaPromet_LetoX.Layout := tlCenter;
    vpTabelaPromet_LetoX.ParentFont := False;
    vpTabelaPromet_LetoX.WidthPercent := 100.000000000000000000;
    vpTabelaPromet_LetoXSkupaj.SetParentComponent(ploscicaTabelaPrometaX);
    vpTabelaPromet_LetoXSkupaj.Name := 'vpTabelaPromet_LetoXSkupaj';
    vpTabelaPromet_LetoXSkupaj.Left := -47;
    vpTabelaPromet_LetoXSkupaj.Top := 314;
    vpTabelaPromet_LetoXSkupaj.Width := 120;
    vpTabelaPromet_LetoXSkupaj.Height := 19;
    vpTabelaPromet_LetoXSkupaj.Alignment := taRightJustify;
    vpTabelaPromet_LetoXSkupaj.Anchors := [];
    vpTabelaPromet_LetoXSkupaj.AutoSize := False;
    vpTabelaPromet_LetoXSkupaj.Caption := '0,00';
    vpTabelaPromet_LetoXSkupaj.Color := clNone;
    vpTabelaPromet_LetoXSkupaj.Font.Charset := DEFAULT_CHARSET;
    vpTabelaPromet_LetoXSkupaj.Font.Color := clWindowText;
    vpTabelaPromet_LetoXSkupaj.Font.Height := -11;
    vpTabelaPromet_LetoXSkupaj.Font.Name := 'Arial';
    vpTabelaPromet_LetoXSkupaj.Font.Style := [fsBold];
    vpTabelaPromet_LetoXSkupaj.HeightPercent := 100.000000000000000000;
    vpTabelaPromet_LetoXSkupaj.Layout := tlCenter;
    vpTabelaPromet_LetoXSkupaj.ParentFont := False;
    vpTabelaPromet_LetoXSkupaj.WidthPercent := 100.000000000000000000;
    vpTabelaPromet_ProdajaX_1.SetParentComponent(ploscicaTabelaPrometaX);
    vpTabelaPromet_ProdajaX_1.Name := 'vpTabelaPromet_ProdajaX_1';
    vpTabelaPromet_ProdajaX_1.Left := -49;
    vpTabelaPromet_ProdajaX_1.Top := 26;
    vpTabelaPromet_ProdajaX_1.Width := 125;
    vpTabelaPromet_ProdajaX_1.Height := 22;
    vpTabelaPromet_ProdajaX_1.Alignment := taRightJustify;
    vpTabelaPromet_ProdajaX_1.Anchors := [];
    vpTabelaPromet_ProdajaX_1.ChildOrder := 13;
    vpTabelaPromet_ProdajaX_1.HeightPercent := 100.000000000000000000;
    vpTabelaPromet_ProdajaX_1.TabOrder := 13;
    vpTabelaPromet_ProdajaX_1.Text := '0,00';
    vpTabelaPromet_ProdajaX_1.WidthPercent := 100.000000000000000000;
    SetEvent(vpTabelaPromet_ProdajaX_1, Self, 'OnExit', 'vpTabelaPromet_ProdajaExit');
    SetEvent(vpTabelaPromet_ProdajaX_1, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    vpTabelaPromet_ProdajaX_2.SetParentComponent(ploscicaTabelaPrometaX);
    vpTabelaPromet_ProdajaX_2.Name := 'vpTabelaPromet_ProdajaX_2';
    vpTabelaPromet_ProdajaX_2.Left := -49;
    vpTabelaPromet_ProdajaX_2.Top := 50;
    vpTabelaPromet_ProdajaX_2.Width := 125;
    vpTabelaPromet_ProdajaX_2.Height := 22;
    vpTabelaPromet_ProdajaX_2.Alignment := taRightJustify;
    vpTabelaPromet_ProdajaX_2.Anchors := [];
    vpTabelaPromet_ProdajaX_2.ChildOrder := 14;
    vpTabelaPromet_ProdajaX_2.HeightPercent := 100.000000000000000000;
    vpTabelaPromet_ProdajaX_2.TabOrder := 14;
    vpTabelaPromet_ProdajaX_2.Text := '0,00';
    vpTabelaPromet_ProdajaX_2.WidthPercent := 100.000000000000000000;
    SetEvent(vpTabelaPromet_ProdajaX_2, Self, 'OnExit', 'vpTabelaPromet_ProdajaExit');
    SetEvent(vpTabelaPromet_ProdajaX_2, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    vpTabelaPromet_ProdajaX_3.SetParentComponent(ploscicaTabelaPrometaX);
    vpTabelaPromet_ProdajaX_3.Name := 'vpTabelaPromet_ProdajaX_3';
    vpTabelaPromet_ProdajaX_3.Left := -49;
    vpTabelaPromet_ProdajaX_3.Top := 74;
    vpTabelaPromet_ProdajaX_3.Width := 125;
    vpTabelaPromet_ProdajaX_3.Height := 22;
    vpTabelaPromet_ProdajaX_3.Alignment := taRightJustify;
    vpTabelaPromet_ProdajaX_3.Anchors := [];
    vpTabelaPromet_ProdajaX_3.ChildOrder := 3;
    vpTabelaPromet_ProdajaX_3.HeightPercent := 100.000000000000000000;
    vpTabelaPromet_ProdajaX_3.TabOrder := 15;
    vpTabelaPromet_ProdajaX_3.Text := '0,00';
    vpTabelaPromet_ProdajaX_3.WidthPercent := 100.000000000000000000;
    SetEvent(vpTabelaPromet_ProdajaX_3, Self, 'OnExit', 'vpTabelaPromet_ProdajaExit');
    SetEvent(vpTabelaPromet_ProdajaX_3, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    vpTabelaPromet_ProdajaX_4.SetParentComponent(ploscicaTabelaPrometaX);
    vpTabelaPromet_ProdajaX_4.Name := 'vpTabelaPromet_ProdajaX_4';
    vpTabelaPromet_ProdajaX_4.Left := -49;
    vpTabelaPromet_ProdajaX_4.Top := 98;
    vpTabelaPromet_ProdajaX_4.Width := 125;
    vpTabelaPromet_ProdajaX_4.Height := 22;
    vpTabelaPromet_ProdajaX_4.Alignment := taRightJustify;
    vpTabelaPromet_ProdajaX_4.Anchors := [];
    vpTabelaPromet_ProdajaX_4.ChildOrder := 4;
    vpTabelaPromet_ProdajaX_4.HeightPercent := 100.000000000000000000;
    vpTabelaPromet_ProdajaX_4.TabOrder := 16;
    vpTabelaPromet_ProdajaX_4.Text := '0,00';
    vpTabelaPromet_ProdajaX_4.WidthPercent := 100.000000000000000000;
    SetEvent(vpTabelaPromet_ProdajaX_4, Self, 'OnExit', 'vpTabelaPromet_ProdajaExit');
    SetEvent(vpTabelaPromet_ProdajaX_4, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    vpTabelaPromet_ProdajaX_5.SetParentComponent(ploscicaTabelaPrometaX);
    vpTabelaPromet_ProdajaX_5.Name := 'vpTabelaPromet_ProdajaX_5';
    vpTabelaPromet_ProdajaX_5.Left := -49;
    vpTabelaPromet_ProdajaX_5.Top := 122;
    vpTabelaPromet_ProdajaX_5.Width := 125;
    vpTabelaPromet_ProdajaX_5.Height := 22;
    vpTabelaPromet_ProdajaX_5.Alignment := taRightJustify;
    vpTabelaPromet_ProdajaX_5.Anchors := [];
    vpTabelaPromet_ProdajaX_5.ChildOrder := 5;
    vpTabelaPromet_ProdajaX_5.HeightPercent := 100.000000000000000000;
    vpTabelaPromet_ProdajaX_5.TabOrder := 17;
    vpTabelaPromet_ProdajaX_5.Text := '0,00';
    vpTabelaPromet_ProdajaX_5.WidthPercent := 100.000000000000000000;
    SetEvent(vpTabelaPromet_ProdajaX_5, Self, 'OnExit', 'vpTabelaPromet_ProdajaExit');
    SetEvent(vpTabelaPromet_ProdajaX_5, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    vpTabelaPromet_ProdajaX_6.SetParentComponent(ploscicaTabelaPrometaX);
    vpTabelaPromet_ProdajaX_6.Name := 'vpTabelaPromet_ProdajaX_6';
    vpTabelaPromet_ProdajaX_6.Left := -49;
    vpTabelaPromet_ProdajaX_6.Top := 146;
    vpTabelaPromet_ProdajaX_6.Width := 125;
    vpTabelaPromet_ProdajaX_6.Height := 22;
    vpTabelaPromet_ProdajaX_6.Alignment := taRightJustify;
    vpTabelaPromet_ProdajaX_6.Anchors := [];
    vpTabelaPromet_ProdajaX_6.ChildOrder := 6;
    vpTabelaPromet_ProdajaX_6.HeightPercent := 100.000000000000000000;
    vpTabelaPromet_ProdajaX_6.TabOrder := 18;
    vpTabelaPromet_ProdajaX_6.Text := '0,00';
    vpTabelaPromet_ProdajaX_6.WidthPercent := 100.000000000000000000;
    SetEvent(vpTabelaPromet_ProdajaX_6, Self, 'OnExit', 'vpTabelaPromet_ProdajaExit');
    SetEvent(vpTabelaPromet_ProdajaX_6, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    vpTabelaPromet_ProdajaX_7.SetParentComponent(ploscicaTabelaPrometaX);
    vpTabelaPromet_ProdajaX_7.Name := 'vpTabelaPromet_ProdajaX_7';
    vpTabelaPromet_ProdajaX_7.Left := -49;
    vpTabelaPromet_ProdajaX_7.Top := 170;
    vpTabelaPromet_ProdajaX_7.Width := 125;
    vpTabelaPromet_ProdajaX_7.Height := 22;
    vpTabelaPromet_ProdajaX_7.Alignment := taRightJustify;
    vpTabelaPromet_ProdajaX_7.Anchors := [];
    vpTabelaPromet_ProdajaX_7.ChildOrder := 7;
    vpTabelaPromet_ProdajaX_7.HeightPercent := 100.000000000000000000;
    vpTabelaPromet_ProdajaX_7.TabOrder := 19;
    vpTabelaPromet_ProdajaX_7.Text := '0,00';
    vpTabelaPromet_ProdajaX_7.WidthPercent := 100.000000000000000000;
    SetEvent(vpTabelaPromet_ProdajaX_7, Self, 'OnExit', 'vpTabelaPromet_ProdajaExit');
    SetEvent(vpTabelaPromet_ProdajaX_7, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    vpTabelaPromet_ProdajaX_8.SetParentComponent(ploscicaTabelaPrometaX);
    vpTabelaPromet_ProdajaX_8.Name := 'vpTabelaPromet_ProdajaX_8';
    vpTabelaPromet_ProdajaX_8.Left := -49;
    vpTabelaPromet_ProdajaX_8.Top := 194;
    vpTabelaPromet_ProdajaX_8.Width := 125;
    vpTabelaPromet_ProdajaX_8.Height := 22;
    vpTabelaPromet_ProdajaX_8.Alignment := taRightJustify;
    vpTabelaPromet_ProdajaX_8.Anchors := [];
    vpTabelaPromet_ProdajaX_8.ChildOrder := 8;
    vpTabelaPromet_ProdajaX_8.HeightPercent := 100.000000000000000000;
    vpTabelaPromet_ProdajaX_8.TabOrder := 20;
    vpTabelaPromet_ProdajaX_8.Text := '0,00';
    vpTabelaPromet_ProdajaX_8.WidthPercent := 100.000000000000000000;
    SetEvent(vpTabelaPromet_ProdajaX_8, Self, 'OnExit', 'vpTabelaPromet_ProdajaExit');
    SetEvent(vpTabelaPromet_ProdajaX_8, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    vpTabelaPromet_ProdajaX_9.SetParentComponent(ploscicaTabelaPrometaX);
    vpTabelaPromet_ProdajaX_9.Name := 'vpTabelaPromet_ProdajaX_9';
    vpTabelaPromet_ProdajaX_9.Left := -49;
    vpTabelaPromet_ProdajaX_9.Top := 218;
    vpTabelaPromet_ProdajaX_9.Width := 125;
    vpTabelaPromet_ProdajaX_9.Height := 22;
    vpTabelaPromet_ProdajaX_9.Alignment := taRightJustify;
    vpTabelaPromet_ProdajaX_9.Anchors := [];
    vpTabelaPromet_ProdajaX_9.ChildOrder := 9;
    vpTabelaPromet_ProdajaX_9.HeightPercent := 100.000000000000000000;
    vpTabelaPromet_ProdajaX_9.TabOrder := 21;
    vpTabelaPromet_ProdajaX_9.Text := '0,00';
    vpTabelaPromet_ProdajaX_9.WidthPercent := 100.000000000000000000;
    SetEvent(vpTabelaPromet_ProdajaX_9, Self, 'OnExit', 'vpTabelaPromet_ProdajaExit');
    SetEvent(vpTabelaPromet_ProdajaX_9, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    vpTabelaPromet_ProdajaX_10.SetParentComponent(ploscicaTabelaPrometaX);
    vpTabelaPromet_ProdajaX_10.Name := 'vpTabelaPromet_ProdajaX_10';
    vpTabelaPromet_ProdajaX_10.Left := -49;
    vpTabelaPromet_ProdajaX_10.Top := 242;
    vpTabelaPromet_ProdajaX_10.Width := 125;
    vpTabelaPromet_ProdajaX_10.Height := 22;
    vpTabelaPromet_ProdajaX_10.Alignment := taRightJustify;
    vpTabelaPromet_ProdajaX_10.Anchors := [];
    vpTabelaPromet_ProdajaX_10.ChildOrder := 10;
    vpTabelaPromet_ProdajaX_10.HeightPercent := 100.000000000000000000;
    vpTabelaPromet_ProdajaX_10.TabOrder := 22;
    vpTabelaPromet_ProdajaX_10.Text := '0,00';
    vpTabelaPromet_ProdajaX_10.WidthPercent := 100.000000000000000000;
    SetEvent(vpTabelaPromet_ProdajaX_10, Self, 'OnExit', 'vpTabelaPromet_ProdajaExit');
    SetEvent(vpTabelaPromet_ProdajaX_10, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    vpTabelaPromet_ProdajaX_11.SetParentComponent(ploscicaTabelaPrometaX);
    vpTabelaPromet_ProdajaX_11.Name := 'vpTabelaPromet_ProdajaX_11';
    vpTabelaPromet_ProdajaX_11.Left := -49;
    vpTabelaPromet_ProdajaX_11.Top := 266;
    vpTabelaPromet_ProdajaX_11.Width := 125;
    vpTabelaPromet_ProdajaX_11.Height := 22;
    vpTabelaPromet_ProdajaX_11.Alignment := taRightJustify;
    vpTabelaPromet_ProdajaX_11.Anchors := [];
    vpTabelaPromet_ProdajaX_11.ChildOrder := 11;
    vpTabelaPromet_ProdajaX_11.HeightPercent := 100.000000000000000000;
    vpTabelaPromet_ProdajaX_11.TabOrder := 23;
    vpTabelaPromet_ProdajaX_11.Text := '0,00';
    vpTabelaPromet_ProdajaX_11.WidthPercent := 100.000000000000000000;
    SetEvent(vpTabelaPromet_ProdajaX_11, Self, 'OnExit', 'vpTabelaPromet_ProdajaExit');
    SetEvent(vpTabelaPromet_ProdajaX_11, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    vpTabelaPromet_ProdajaX_12.SetParentComponent(ploscicaTabelaPrometaX);
    vpTabelaPromet_ProdajaX_12.Name := 'vpTabelaPromet_ProdajaX_12';
    vpTabelaPromet_ProdajaX_12.Left := -49;
    vpTabelaPromet_ProdajaX_12.Top := 290;
    vpTabelaPromet_ProdajaX_12.Width := 125;
    vpTabelaPromet_ProdajaX_12.Height := 22;
    vpTabelaPromet_ProdajaX_12.Alignment := taRightJustify;
    vpTabelaPromet_ProdajaX_12.Anchors := [];
    vpTabelaPromet_ProdajaX_12.ChildOrder := 12;
    vpTabelaPromet_ProdajaX_12.HeightPercent := 100.000000000000000000;
    vpTabelaPromet_ProdajaX_12.TabOrder := 24;
    vpTabelaPromet_ProdajaX_12.Text := '0,00';
    vpTabelaPromet_ProdajaX_12.WidthPercent := 100.000000000000000000;
    SetEvent(vpTabelaPromet_ProdajaX_12, Self, 'OnExit', 'vpTabelaPromet_ProdajaExit');
    SetEvent(vpTabelaPromet_ProdajaX_12, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaTabelaPrometaXminus1.SetParentComponent(WebScrollBoxDL3);
    ploscicaTabelaPrometaXminus1.Name := 'ploscicaTabelaPrometaXminus1';
    ploscicaTabelaPrometaXminus1.Left := 15;
    ploscicaTabelaPrometaXminus1.Top := 80;
    ploscicaTabelaPrometaXminus1.Width := 245;
    ploscicaTabelaPrometaXminus1.Height := 344;
    ploscicaTabelaPrometaXminus1.HeightPercent := 100.000000000000000000;
    ploscicaTabelaPrometaXminus1.WidthPercent := 100.000000000000000000;
    ploscicaTabelaPrometaXminus1.ChildOrder := 1;
    ploscicaTabelaPrometaXminus1.ColumnCollection.Clear;
    with ploscicaTabelaPrometaXminus1.ColumnCollection.Add do
    begin
      Value := 20;
    end;
    with ploscicaTabelaPrometaXminus1.ColumnCollection.Add do
    begin
      Alignment := taCenter;
      Value := 33;
    end;
    ploscicaTabelaPrometaXminus1.ControlCollection.Clear;
    with ploscicaTabelaPrometaXminus1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := tabelaPromet_Mesec;
    end;
    with ploscicaTabelaPrometaXminus1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := vpTabelaPromet_LetoXminus1;
    end;
    with ploscicaTabelaPrometaXminus1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := tabelaPromet_Mesec1;
    end;
    with ploscicaTabelaPrometaXminus1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := vpTabelaPromet_ProdajaXminus1_1;
    end;
    with ploscicaTabelaPrometaXminus1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := tabelaPromet_Mesec2;
    end;
    with ploscicaTabelaPrometaXminus1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := vpTabelaPromet_ProdajaXminus1_2;
    end;
    with ploscicaTabelaPrometaXminus1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := tabelaPromet_Mesec3;
    end;
    with ploscicaTabelaPrometaXminus1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := vpTabelaPromet_ProdajaXminus1_3;
    end;
    with ploscicaTabelaPrometaXminus1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := tabelaPromet_Mesec4;
    end;
    with ploscicaTabelaPrometaXminus1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := vpTabelaPromet_ProdajaXminus1_4;
    end;
    with ploscicaTabelaPrometaXminus1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := tabelaPromet_Mesec5;
    end;
    with ploscicaTabelaPrometaXminus1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := vpTabelaPromet_ProdajaXminus1_5;
    end;
    with ploscicaTabelaPrometaXminus1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := tabelaPromet_Mesec6;
    end;
    with ploscicaTabelaPrometaXminus1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := vpTabelaPromet_ProdajaXminus1_6;
    end;
    with ploscicaTabelaPrometaXminus1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := tabelaPromet_Mesec7;
    end;
    with ploscicaTabelaPrometaXminus1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := vpTabelaPromet_ProdajaXminus1_7;
    end;
    with ploscicaTabelaPrometaXminus1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := tabelaPromet_Mesec8;
    end;
    with ploscicaTabelaPrometaXminus1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := vpTabelaPromet_ProdajaXminus1_8;
    end;
    with ploscicaTabelaPrometaXminus1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := tabelaPromet_Mesec9;
    end;
    with ploscicaTabelaPrometaXminus1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := vpTabelaPromet_ProdajaXminus1_9;
    end;
    with ploscicaTabelaPrometaXminus1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := tabelaPromet_Mesec10;
    end;
    with ploscicaTabelaPrometaXminus1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := vpTabelaPromet_ProdajaXminus1_10;
    end;
    with ploscicaTabelaPrometaXminus1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := tabelaPromet_Mesec11;
    end;
    with ploscicaTabelaPrometaXminus1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := vpTabelaPromet_ProdajaXminus1_11;
    end;
    with ploscicaTabelaPrometaXminus1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := tabelaPromet_Mesec12;
    end;
    with ploscicaTabelaPrometaXminus1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := vpTabelaPromet_ProdajaXminus1_12;
    end;
    with ploscicaTabelaPrometaXminus1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := tabelaPromet_Skupaj;
    end;
    with ploscicaTabelaPrometaXminus1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := vpTabelaPromet_LetoXminus1Skupaj;
    end;
    ploscicaTabelaPrometaXminus1.Color := clWhite;
    ploscicaTabelaPrometaXminus1.GridLineColor := clBlack;
    ploscicaTabelaPrometaXminus1.RowCollection.Clear;
    with ploscicaTabelaPrometaXminus1.RowCollection.Add do
    begin
      Value := 7;
    end;
    with ploscicaTabelaPrometaXminus1.RowCollection.Add do
    begin
      Value := 7;
    end;
    with ploscicaTabelaPrometaXminus1.RowCollection.Add do
    begin
      Value := 7;
    end;
    with ploscicaTabelaPrometaXminus1.RowCollection.Add do
    begin
      Value := 7;
    end;
    with ploscicaTabelaPrometaXminus1.RowCollection.Add do
    begin
      Value := 7;
    end;
    with ploscicaTabelaPrometaXminus1.RowCollection.Add do
    begin
      Value := 7;
    end;
    with ploscicaTabelaPrometaXminus1.RowCollection.Add do
    begin
      Value := 7;
    end;
    with ploscicaTabelaPrometaXminus1.RowCollection.Add do
    begin
      Value := 7;
    end;
    with ploscicaTabelaPrometaXminus1.RowCollection.Add do
    begin
      Value := 7;
    end;
    with ploscicaTabelaPrometaXminus1.RowCollection.Add do
    begin
      Value := 7;
    end;
    with ploscicaTabelaPrometaXminus1.RowCollection.Add do
    begin
      Value := 7;
    end;
    with ploscicaTabelaPrometaXminus1.RowCollection.Add do
    begin
      Value := 7;
    end;
    with ploscicaTabelaPrometaXminus1.RowCollection.Add do
    begin
      Value := 7;
    end;
    with ploscicaTabelaPrometaXminus1.RowCollection.Add do
    begin
      Value := 7;
    end;
    tabelaPromet_Mesec.SetParentComponent(ploscicaTabelaPrometaXminus1);
    tabelaPromet_Mesec.Name := 'tabelaPromet_Mesec';
    tabelaPromet_Mesec.Left := 2;
    tabelaPromet_Mesec.Top := 2;
    tabelaPromet_Mesec.Width := 41;
    tabelaPromet_Mesec.Height := 14;
    tabelaPromet_Mesec.Anchors := [];
    tabelaPromet_Mesec.Caption := 'mesec:';
    tabelaPromet_Mesec.Color := clNone;
    tabelaPromet_Mesec.Font.Charset := DEFAULT_CHARSET;
    tabelaPromet_Mesec.Font.Color := clWindowText;
    tabelaPromet_Mesec.Font.Height := -11;
    tabelaPromet_Mesec.Font.Name := 'Arial';
    tabelaPromet_Mesec.Font.Style := [fsBold];
    tabelaPromet_Mesec.HeightPercent := 100.000000000000000000;
    tabelaPromet_Mesec.Layout := tlCenter;
    tabelaPromet_Mesec.ParentFont := False;
    tabelaPromet_Mesec.WidthPercent := 100.000000000000000000;
    vpTabelaPromet_LetoXminus1.SetParentComponent(ploscicaTabelaPrometaXminus1);
    vpTabelaPromet_LetoXminus1.Name := 'vpTabelaPromet_LetoXminus1';
    vpTabelaPromet_LetoXminus1.Left := 65;
    vpTabelaPromet_LetoXminus1.Top := 2;
    vpTabelaPromet_LetoXminus1.Width := 48;
    vpTabelaPromet_LetoXminus1.Height := 14;
    vpTabelaPromet_LetoXminus1.Anchors := [];
    vpTabelaPromet_LetoXminus1.Caption := 'leto xxxx';
    vpTabelaPromet_LetoXminus1.Color := clNone;
    vpTabelaPromet_LetoXminus1.Font.Charset := DEFAULT_CHARSET;
    vpTabelaPromet_LetoXminus1.Font.Color := clWindowText;
    vpTabelaPromet_LetoXminus1.Font.Height := -11;
    vpTabelaPromet_LetoXminus1.Font.Name := 'Arial';
    vpTabelaPromet_LetoXminus1.Font.Style := [fsBold];
    vpTabelaPromet_LetoXminus1.HeightPercent := 100.000000000000000000;
    vpTabelaPromet_LetoXminus1.Layout := tlCenter;
    vpTabelaPromet_LetoXminus1.ParentFont := False;
    vpTabelaPromet_LetoXminus1.WidthPercent := 100.000000000000000000;
    tabelaPromet_Mesec1.SetParentComponent(ploscicaTabelaPrometaXminus1);
    tabelaPromet_Mesec1.Name := 'tabelaPromet_Mesec1';
    tabelaPromet_Mesec1.Left := 2;
    tabelaPromet_Mesec1.Top := 26;
    tabelaPromet_Mesec1.Width := 30;
    tabelaPromet_Mesec1.Height := 14;
    tabelaPromet_Mesec1.Anchors := [];
    tabelaPromet_Mesec1.Caption := 'januar';
    tabelaPromet_Mesec1.Color := clNone;
    tabelaPromet_Mesec1.Font.Charset := DEFAULT_CHARSET;
    tabelaPromet_Mesec1.Font.Color := clWindowText;
    tabelaPromet_Mesec1.Font.Height := -11;
    tabelaPromet_Mesec1.Font.Name := 'Arial';
    tabelaPromet_Mesec1.Font.Style := [];
    tabelaPromet_Mesec1.HeightPercent := 100.000000000000000000;
    tabelaPromet_Mesec1.Layout := tlCenter;
    tabelaPromet_Mesec1.ParentFont := False;
    tabelaPromet_Mesec1.WidthPercent := 100.000000000000000000;
    tabelaPromet_Mesec2.SetParentComponent(ploscicaTabelaPrometaXminus1);
    tabelaPromet_Mesec2.Name := 'tabelaPromet_Mesec2';
    tabelaPromet_Mesec2.Left := 2;
    tabelaPromet_Mesec2.Top := 50;
    tabelaPromet_Mesec2.Width := 36;
    tabelaPromet_Mesec2.Height := 14;
    tabelaPromet_Mesec2.Anchors := [];
    tabelaPromet_Mesec2.Caption := 'februar';
    tabelaPromet_Mesec2.Color := clNone;
    tabelaPromet_Mesec2.Font.Charset := DEFAULT_CHARSET;
    tabelaPromet_Mesec2.Font.Color := clWindowText;
    tabelaPromet_Mesec2.Font.Height := -11;
    tabelaPromet_Mesec2.Font.Name := 'Arial';
    tabelaPromet_Mesec2.Font.Style := [];
    tabelaPromet_Mesec2.HeightPercent := 100.000000000000000000;
    tabelaPromet_Mesec2.Layout := tlCenter;
    tabelaPromet_Mesec2.ParentFont := False;
    tabelaPromet_Mesec2.WidthPercent := 100.000000000000000000;
    tabelaPromet_Mesec3.SetParentComponent(ploscicaTabelaPrometaXminus1);
    tabelaPromet_Mesec3.Name := 'tabelaPromet_Mesec3';
    tabelaPromet_Mesec3.Left := 2;
    tabelaPromet_Mesec3.Top := 74;
    tabelaPromet_Mesec3.Width := 30;
    tabelaPromet_Mesec3.Height := 14;
    tabelaPromet_Mesec3.Anchors := [];
    tabelaPromet_Mesec3.Caption := 'marec';
    tabelaPromet_Mesec3.Color := clNone;
    tabelaPromet_Mesec3.Font.Charset := DEFAULT_CHARSET;
    tabelaPromet_Mesec3.Font.Color := clWindowText;
    tabelaPromet_Mesec3.Font.Height := -11;
    tabelaPromet_Mesec3.Font.Name := 'Arial';
    tabelaPromet_Mesec3.Font.Style := [];
    tabelaPromet_Mesec3.HeightPercent := 100.000000000000000000;
    tabelaPromet_Mesec3.Layout := tlCenter;
    tabelaPromet_Mesec3.ParentFont := False;
    tabelaPromet_Mesec3.WidthPercent := 100.000000000000000000;
    tabelaPromet_Mesec4.SetParentComponent(ploscicaTabelaPrometaXminus1);
    tabelaPromet_Mesec4.Name := 'tabelaPromet_Mesec4';
    tabelaPromet_Mesec4.Left := 2;
    tabelaPromet_Mesec4.Top := 98;
    tabelaPromet_Mesec4.Width := 20;
    tabelaPromet_Mesec4.Height := 14;
    tabelaPromet_Mesec4.Anchors := [];
    tabelaPromet_Mesec4.Caption := 'april';
    tabelaPromet_Mesec4.Color := clNone;
    tabelaPromet_Mesec4.Font.Charset := DEFAULT_CHARSET;
    tabelaPromet_Mesec4.Font.Color := clWindowText;
    tabelaPromet_Mesec4.Font.Height := -11;
    tabelaPromet_Mesec4.Font.Name := 'Arial';
    tabelaPromet_Mesec4.Font.Style := [];
    tabelaPromet_Mesec4.HeightPercent := 100.000000000000000000;
    tabelaPromet_Mesec4.Layout := tlCenter;
    tabelaPromet_Mesec4.ParentFont := False;
    tabelaPromet_Mesec4.WidthPercent := 100.000000000000000000;
    tabelaPromet_Mesec5.SetParentComponent(ploscicaTabelaPrometaXminus1);
    tabelaPromet_Mesec5.Name := 'tabelaPromet_Mesec5';
    tabelaPromet_Mesec5.Left := 2;
    tabelaPromet_Mesec5.Top := 122;
    tabelaPromet_Mesec5.Width := 16;
    tabelaPromet_Mesec5.Height := 14;
    tabelaPromet_Mesec5.Anchors := [];
    tabelaPromet_Mesec5.Caption := 'maj';
    tabelaPromet_Mesec5.Color := clNone;
    tabelaPromet_Mesec5.Font.Charset := DEFAULT_CHARSET;
    tabelaPromet_Mesec5.Font.Color := clWindowText;
    tabelaPromet_Mesec5.Font.Height := -11;
    tabelaPromet_Mesec5.Font.Name := 'Arial';
    tabelaPromet_Mesec5.Font.Style := [];
    tabelaPromet_Mesec5.HeightPercent := 100.000000000000000000;
    tabelaPromet_Mesec5.Layout := tlCenter;
    tabelaPromet_Mesec5.ParentFont := False;
    tabelaPromet_Mesec5.WidthPercent := 100.000000000000000000;
    tabelaPromet_Mesec6.SetParentComponent(ploscicaTabelaPrometaXminus1);
    tabelaPromet_Mesec6.Name := 'tabelaPromet_Mesec6';
    tabelaPromet_Mesec6.Left := 2;
    tabelaPromet_Mesec6.Top := 146;
    tabelaPromet_Mesec6.Width := 18;
    tabelaPromet_Mesec6.Height := 14;
    tabelaPromet_Mesec6.Anchors := [];
    tabelaPromet_Mesec6.Caption := 'junij';
    tabelaPromet_Mesec6.Color := clNone;
    tabelaPromet_Mesec6.Font.Charset := DEFAULT_CHARSET;
    tabelaPromet_Mesec6.Font.Color := clWindowText;
    tabelaPromet_Mesec6.Font.Height := -11;
    tabelaPromet_Mesec6.Font.Name := 'Arial';
    tabelaPromet_Mesec6.Font.Style := [];
    tabelaPromet_Mesec6.HeightPercent := 100.000000000000000000;
    tabelaPromet_Mesec6.Layout := tlCenter;
    tabelaPromet_Mesec6.ParentFont := False;
    tabelaPromet_Mesec6.WidthPercent := 100.000000000000000000;
    tabelaPromet_Mesec7.SetParentComponent(ploscicaTabelaPrometaXminus1);
    tabelaPromet_Mesec7.Name := 'tabelaPromet_Mesec7';
    tabelaPromet_Mesec7.Left := 2;
    tabelaPromet_Mesec7.Top := 170;
    tabelaPromet_Mesec7.Width := 14;
    tabelaPromet_Mesec7.Height := 14;
    tabelaPromet_Mesec7.Anchors := [];
    tabelaPromet_Mesec7.Caption := 'julij';
    tabelaPromet_Mesec7.Color := clNone;
    tabelaPromet_Mesec7.Font.Charset := DEFAULT_CHARSET;
    tabelaPromet_Mesec7.Font.Color := clWindowText;
    tabelaPromet_Mesec7.Font.Height := -11;
    tabelaPromet_Mesec7.Font.Name := 'Arial';
    tabelaPromet_Mesec7.Font.Style := [];
    tabelaPromet_Mesec7.HeightPercent := 100.000000000000000000;
    tabelaPromet_Mesec7.Layout := tlCenter;
    tabelaPromet_Mesec7.ParentFont := False;
    tabelaPromet_Mesec7.WidthPercent := 100.000000000000000000;
    tabelaPromet_Mesec8.SetParentComponent(ploscicaTabelaPrometaXminus1);
    tabelaPromet_Mesec8.Name := 'tabelaPromet_Mesec8';
    tabelaPromet_Mesec8.Left := 2;
    tabelaPromet_Mesec8.Top := 194;
    tabelaPromet_Mesec8.Width := 33;
    tabelaPromet_Mesec8.Height := 14;
    tabelaPromet_Mesec8.Anchors := [];
    tabelaPromet_Mesec8.Caption := 'avgust';
    tabelaPromet_Mesec8.Color := clNone;
    tabelaPromet_Mesec8.Font.Charset := DEFAULT_CHARSET;
    tabelaPromet_Mesec8.Font.Color := clWindowText;
    tabelaPromet_Mesec8.Font.Height := -11;
    tabelaPromet_Mesec8.Font.Name := 'Arial';
    tabelaPromet_Mesec8.Font.Style := [];
    tabelaPromet_Mesec8.HeightPercent := 100.000000000000000000;
    tabelaPromet_Mesec8.Layout := tlCenter;
    tabelaPromet_Mesec8.ParentFont := False;
    tabelaPromet_Mesec8.WidthPercent := 100.000000000000000000;
    tabelaPromet_Mesec9.SetParentComponent(ploscicaTabelaPrometaXminus1);
    tabelaPromet_Mesec9.Name := 'tabelaPromet_Mesec9';
    tabelaPromet_Mesec9.Left := 2;
    tabelaPromet_Mesec9.Top := 218;
    tabelaPromet_Mesec9.Width := 51;
    tabelaPromet_Mesec9.Height := 14;
    tabelaPromet_Mesec9.Anchors := [];
    tabelaPromet_Mesec9.Caption := 'september';
    tabelaPromet_Mesec9.Color := clNone;
    tabelaPromet_Mesec9.Font.Charset := DEFAULT_CHARSET;
    tabelaPromet_Mesec9.Font.Color := clWindowText;
    tabelaPromet_Mesec9.Font.Height := -11;
    tabelaPromet_Mesec9.Font.Name := 'Arial';
    tabelaPromet_Mesec9.Font.Style := [];
    tabelaPromet_Mesec9.HeightPercent := 100.000000000000000000;
    tabelaPromet_Mesec9.Layout := tlCenter;
    tabelaPromet_Mesec9.ParentFont := False;
    tabelaPromet_Mesec9.WidthPercent := 100.000000000000000000;
    tabelaPromet_Mesec10.SetParentComponent(ploscicaTabelaPrometaXminus1);
    tabelaPromet_Mesec10.Name := 'tabelaPromet_Mesec10';
    tabelaPromet_Mesec10.Left := 2;
    tabelaPromet_Mesec10.Top := 242;
    tabelaPromet_Mesec10.Width := 36;
    tabelaPromet_Mesec10.Height := 14;
    tabelaPromet_Mesec10.Anchors := [];
    tabelaPromet_Mesec10.Caption := 'oktober';
    tabelaPromet_Mesec10.Color := clNone;
    tabelaPromet_Mesec10.Font.Charset := DEFAULT_CHARSET;
    tabelaPromet_Mesec10.Font.Color := clWindowText;
    tabelaPromet_Mesec10.Font.Height := -11;
    tabelaPromet_Mesec10.Font.Name := 'Arial';
    tabelaPromet_Mesec10.Font.Style := [];
    tabelaPromet_Mesec10.HeightPercent := 100.000000000000000000;
    tabelaPromet_Mesec10.Layout := tlCenter;
    tabelaPromet_Mesec10.ParentFont := False;
    tabelaPromet_Mesec10.WidthPercent := 100.000000000000000000;
    tabelaPromet_Mesec11.SetParentComponent(ploscicaTabelaPrometaXminus1);
    tabelaPromet_Mesec11.Name := 'tabelaPromet_Mesec11';
    tabelaPromet_Mesec11.Left := 2;
    tabelaPromet_Mesec11.Top := 266;
    tabelaPromet_Mesec11.Width := 48;
    tabelaPromet_Mesec11.Height := 14;
    tabelaPromet_Mesec11.Anchors := [];
    tabelaPromet_Mesec11.Caption := 'november';
    tabelaPromet_Mesec11.Color := clNone;
    tabelaPromet_Mesec11.Font.Charset := DEFAULT_CHARSET;
    tabelaPromet_Mesec11.Font.Color := clWindowText;
    tabelaPromet_Mesec11.Font.Height := -11;
    tabelaPromet_Mesec11.Font.Name := 'Arial';
    tabelaPromet_Mesec11.Font.Style := [];
    tabelaPromet_Mesec11.HeightPercent := 100.000000000000000000;
    tabelaPromet_Mesec11.Layout := tlCenter;
    tabelaPromet_Mesec11.ParentFont := False;
    tabelaPromet_Mesec11.WidthPercent := 100.000000000000000000;
    tabelaPromet_Mesec12.SetParentComponent(ploscicaTabelaPrometaXminus1);
    tabelaPromet_Mesec12.Name := 'tabelaPromet_Mesec12';
    tabelaPromet_Mesec12.Left := 2;
    tabelaPromet_Mesec12.Top := 290;
    tabelaPromet_Mesec12.Width := 48;
    tabelaPromet_Mesec12.Height := 14;
    tabelaPromet_Mesec12.Anchors := [];
    tabelaPromet_Mesec12.Caption := 'december';
    tabelaPromet_Mesec12.Color := clNone;
    tabelaPromet_Mesec12.Font.Charset := DEFAULT_CHARSET;
    tabelaPromet_Mesec12.Font.Color := clWindowText;
    tabelaPromet_Mesec12.Font.Height := -11;
    tabelaPromet_Mesec12.Font.Name := 'Arial';
    tabelaPromet_Mesec12.Font.Style := [];
    tabelaPromet_Mesec12.HeightPercent := 100.000000000000000000;
    tabelaPromet_Mesec12.Layout := tlCenter;
    tabelaPromet_Mesec12.ParentFont := False;
    tabelaPromet_Mesec12.WidthPercent := 100.000000000000000000;
    tabelaPromet_Skupaj.SetParentComponent(ploscicaTabelaPrometaXminus1);
    tabelaPromet_Skupaj.Name := 'tabelaPromet_Skupaj';
    tabelaPromet_Skupaj.Left := 2;
    tabelaPromet_Skupaj.Top := 314;
    tabelaPromet_Skupaj.Width := 42;
    tabelaPromet_Skupaj.Height := 14;
    tabelaPromet_Skupaj.Anchors := [];
    tabelaPromet_Skupaj.Caption := 'SKUPAJ:';
    tabelaPromet_Skupaj.Color := clNone;
    tabelaPromet_Skupaj.Font.Charset := DEFAULT_CHARSET;
    tabelaPromet_Skupaj.Font.Color := clWindowText;
    tabelaPromet_Skupaj.Font.Height := -11;
    tabelaPromet_Skupaj.Font.Name := 'Arial';
    tabelaPromet_Skupaj.Font.Style := [];
    tabelaPromet_Skupaj.HeightPercent := 100.000000000000000000;
    tabelaPromet_Skupaj.Layout := tlCenter;
    tabelaPromet_Skupaj.ParentFont := False;
    tabelaPromet_Skupaj.WidthPercent := 100.000000000000000000;
    vpTabelaPromet_LetoXminus1Skupaj.SetParentComponent(ploscicaTabelaPrometaXminus1);
    vpTabelaPromet_LetoXminus1Skupaj.Name := 'vpTabelaPromet_LetoXminus1Skupaj';
    vpTabelaPromet_LetoXminus1Skupaj.Left := 30;
    vpTabelaPromet_LetoXminus1Skupaj.Top := 314;
    vpTabelaPromet_LetoXminus1Skupaj.Width := 120;
    vpTabelaPromet_LetoXminus1Skupaj.Height := 19;
    vpTabelaPromet_LetoXminus1Skupaj.Alignment := taRightJustify;
    vpTabelaPromet_LetoXminus1Skupaj.Anchors := [];
    vpTabelaPromet_LetoXminus1Skupaj.AutoSize := False;
    vpTabelaPromet_LetoXminus1Skupaj.Caption := '0.00';
    vpTabelaPromet_LetoXminus1Skupaj.Color := clNone;
    vpTabelaPromet_LetoXminus1Skupaj.Font.Charset := DEFAULT_CHARSET;
    vpTabelaPromet_LetoXminus1Skupaj.Font.Color := clWindowText;
    vpTabelaPromet_LetoXminus1Skupaj.Font.Height := -11;
    vpTabelaPromet_LetoXminus1Skupaj.Font.Name := 'Arial';
    vpTabelaPromet_LetoXminus1Skupaj.Font.Style := [fsBold];
    vpTabelaPromet_LetoXminus1Skupaj.HeightPercent := 100.000000000000000000;
    vpTabelaPromet_LetoXminus1Skupaj.Layout := tlCenter;
    vpTabelaPromet_LetoXminus1Skupaj.ParentFont := False;
    vpTabelaPromet_LetoXminus1Skupaj.WidthPercent := 100.000000000000000000;
    vpTabelaPromet_ProdajaXminus1_1.SetParentComponent(ploscicaTabelaPrometaXminus1);
    vpTabelaPromet_ProdajaXminus1_1.Name := 'vpTabelaPromet_ProdajaXminus1_1';
    vpTabelaPromet_ProdajaXminus1_1.Left := 27;
    vpTabelaPromet_ProdajaXminus1_1.Top := 26;
    vpTabelaPromet_ProdajaXminus1_1.Width := 125;
    vpTabelaPromet_ProdajaXminus1_1.Height := 22;
    vpTabelaPromet_ProdajaXminus1_1.Alignment := taRightJustify;
    vpTabelaPromet_ProdajaXminus1_1.Anchors := [];
    vpTabelaPromet_ProdajaXminus1_1.ChildOrder := 1;
    vpTabelaPromet_ProdajaXminus1_1.HeightPercent := 100.000000000000000000;
    vpTabelaPromet_ProdajaXminus1_1.TabOrder := 1;
    vpTabelaPromet_ProdajaXminus1_1.Text := '0,00';
    vpTabelaPromet_ProdajaXminus1_1.WidthPercent := 100.000000000000000000;
    SetEvent(vpTabelaPromet_ProdajaXminus1_1, Self, 'OnExit', 'vpTabelaPromet_ProdajaExit');
    SetEvent(vpTabelaPromet_ProdajaXminus1_1, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    vpTabelaPromet_ProdajaXminus1_2.SetParentComponent(ploscicaTabelaPrometaXminus1);
    vpTabelaPromet_ProdajaXminus1_2.Name := 'vpTabelaPromet_ProdajaXminus1_2';
    vpTabelaPromet_ProdajaXminus1_2.Left := 27;
    vpTabelaPromet_ProdajaXminus1_2.Top := 50;
    vpTabelaPromet_ProdajaXminus1_2.Width := 125;
    vpTabelaPromet_ProdajaXminus1_2.Height := 22;
    vpTabelaPromet_ProdajaXminus1_2.Alignment := taRightJustify;
    vpTabelaPromet_ProdajaXminus1_2.Anchors := [];
    vpTabelaPromet_ProdajaXminus1_2.ChildOrder := 2;
    vpTabelaPromet_ProdajaXminus1_2.HeightPercent := 100.000000000000000000;
    vpTabelaPromet_ProdajaXminus1_2.TabOrder := 2;
    vpTabelaPromet_ProdajaXminus1_2.Text := '0,00';
    vpTabelaPromet_ProdajaXminus1_2.WidthPercent := 100.000000000000000000;
    SetEvent(vpTabelaPromet_ProdajaXminus1_2, Self, 'OnExit', 'vpTabelaPromet_ProdajaExit');
    SetEvent(vpTabelaPromet_ProdajaXminus1_2, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    vpTabelaPromet_ProdajaXminus1_3.SetParentComponent(ploscicaTabelaPrometaXminus1);
    vpTabelaPromet_ProdajaXminus1_3.Name := 'vpTabelaPromet_ProdajaXminus1_3';
    vpTabelaPromet_ProdajaXminus1_3.Left := 27;
    vpTabelaPromet_ProdajaXminus1_3.Top := 74;
    vpTabelaPromet_ProdajaXminus1_3.Width := 125;
    vpTabelaPromet_ProdajaXminus1_3.Height := 22;
    vpTabelaPromet_ProdajaXminus1_3.Alignment := taRightJustify;
    vpTabelaPromet_ProdajaXminus1_3.Anchors := [];
    vpTabelaPromet_ProdajaXminus1_3.ChildOrder := 3;
    vpTabelaPromet_ProdajaXminus1_3.HeightPercent := 100.000000000000000000;
    vpTabelaPromet_ProdajaXminus1_3.TabOrder := 3;
    vpTabelaPromet_ProdajaXminus1_3.Text := '0,00';
    vpTabelaPromet_ProdajaXminus1_3.WidthPercent := 100.000000000000000000;
    SetEvent(vpTabelaPromet_ProdajaXminus1_3, Self, 'OnExit', 'vpTabelaPromet_ProdajaExit');
    SetEvent(vpTabelaPromet_ProdajaXminus1_3, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    vpTabelaPromet_ProdajaXminus1_4.SetParentComponent(ploscicaTabelaPrometaXminus1);
    vpTabelaPromet_ProdajaXminus1_4.Name := 'vpTabelaPromet_ProdajaXminus1_4';
    vpTabelaPromet_ProdajaXminus1_4.Left := 27;
    vpTabelaPromet_ProdajaXminus1_4.Top := 98;
    vpTabelaPromet_ProdajaXminus1_4.Width := 125;
    vpTabelaPromet_ProdajaXminus1_4.Height := 22;
    vpTabelaPromet_ProdajaXminus1_4.Alignment := taRightJustify;
    vpTabelaPromet_ProdajaXminus1_4.Anchors := [];
    vpTabelaPromet_ProdajaXminus1_4.ChildOrder := 4;
    vpTabelaPromet_ProdajaXminus1_4.HeightPercent := 100.000000000000000000;
    vpTabelaPromet_ProdajaXminus1_4.TabOrder := 4;
    vpTabelaPromet_ProdajaXminus1_4.Text := '0,00';
    vpTabelaPromet_ProdajaXminus1_4.WidthPercent := 100.000000000000000000;
    SetEvent(vpTabelaPromet_ProdajaXminus1_4, Self, 'OnExit', 'vpTabelaPromet_ProdajaExit');
    SetEvent(vpTabelaPromet_ProdajaXminus1_4, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    vpTabelaPromet_ProdajaXminus1_5.SetParentComponent(ploscicaTabelaPrometaXminus1);
    vpTabelaPromet_ProdajaXminus1_5.Name := 'vpTabelaPromet_ProdajaXminus1_5';
    vpTabelaPromet_ProdajaXminus1_5.Left := 27;
    vpTabelaPromet_ProdajaXminus1_5.Top := 122;
    vpTabelaPromet_ProdajaXminus1_5.Width := 125;
    vpTabelaPromet_ProdajaXminus1_5.Height := 22;
    vpTabelaPromet_ProdajaXminus1_5.Alignment := taRightJustify;
    vpTabelaPromet_ProdajaXminus1_5.Anchors := [];
    vpTabelaPromet_ProdajaXminus1_5.ChildOrder := 5;
    vpTabelaPromet_ProdajaXminus1_5.HeightPercent := 100.000000000000000000;
    vpTabelaPromet_ProdajaXminus1_5.TabOrder := 5;
    vpTabelaPromet_ProdajaXminus1_5.Text := '0,00';
    vpTabelaPromet_ProdajaXminus1_5.WidthPercent := 100.000000000000000000;
    SetEvent(vpTabelaPromet_ProdajaXminus1_5, Self, 'OnExit', 'vpTabelaPromet_ProdajaExit');
    SetEvent(vpTabelaPromet_ProdajaXminus1_5, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    vpTabelaPromet_ProdajaXminus1_6.SetParentComponent(ploscicaTabelaPrometaXminus1);
    vpTabelaPromet_ProdajaXminus1_6.Name := 'vpTabelaPromet_ProdajaXminus1_6';
    vpTabelaPromet_ProdajaXminus1_6.Left := 27;
    vpTabelaPromet_ProdajaXminus1_6.Top := 146;
    vpTabelaPromet_ProdajaXminus1_6.Width := 125;
    vpTabelaPromet_ProdajaXminus1_6.Height := 22;
    vpTabelaPromet_ProdajaXminus1_6.Alignment := taRightJustify;
    vpTabelaPromet_ProdajaXminus1_6.Anchors := [];
    vpTabelaPromet_ProdajaXminus1_6.ChildOrder := 6;
    vpTabelaPromet_ProdajaXminus1_6.HeightPercent := 100.000000000000000000;
    vpTabelaPromet_ProdajaXminus1_6.TabOrder := 6;
    vpTabelaPromet_ProdajaXminus1_6.Text := '0,00';
    vpTabelaPromet_ProdajaXminus1_6.WidthPercent := 100.000000000000000000;
    SetEvent(vpTabelaPromet_ProdajaXminus1_6, Self, 'OnExit', 'vpTabelaPromet_ProdajaExit');
    SetEvent(vpTabelaPromet_ProdajaXminus1_6, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    vpTabelaPromet_ProdajaXminus1_7.SetParentComponent(ploscicaTabelaPrometaXminus1);
    vpTabelaPromet_ProdajaXminus1_7.Name := 'vpTabelaPromet_ProdajaXminus1_7';
    vpTabelaPromet_ProdajaXminus1_7.Left := 27;
    vpTabelaPromet_ProdajaXminus1_7.Top := 170;
    vpTabelaPromet_ProdajaXminus1_7.Width := 125;
    vpTabelaPromet_ProdajaXminus1_7.Height := 22;
    vpTabelaPromet_ProdajaXminus1_7.Alignment := taRightJustify;
    vpTabelaPromet_ProdajaXminus1_7.Anchors := [];
    vpTabelaPromet_ProdajaXminus1_7.ChildOrder := 7;
    vpTabelaPromet_ProdajaXminus1_7.HeightPercent := 100.000000000000000000;
    vpTabelaPromet_ProdajaXminus1_7.TabOrder := 7;
    vpTabelaPromet_ProdajaXminus1_7.Text := '0,00';
    vpTabelaPromet_ProdajaXminus1_7.WidthPercent := 100.000000000000000000;
    SetEvent(vpTabelaPromet_ProdajaXminus1_7, Self, 'OnExit', 'vpTabelaPromet_ProdajaExit');
    SetEvent(vpTabelaPromet_ProdajaXminus1_7, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    vpTabelaPromet_ProdajaXminus1_8.SetParentComponent(ploscicaTabelaPrometaXminus1);
    vpTabelaPromet_ProdajaXminus1_8.Name := 'vpTabelaPromet_ProdajaXminus1_8';
    vpTabelaPromet_ProdajaXminus1_8.Left := 27;
    vpTabelaPromet_ProdajaXminus1_8.Top := 194;
    vpTabelaPromet_ProdajaXminus1_8.Width := 125;
    vpTabelaPromet_ProdajaXminus1_8.Height := 22;
    vpTabelaPromet_ProdajaXminus1_8.Alignment := taRightJustify;
    vpTabelaPromet_ProdajaXminus1_8.Anchors := [];
    vpTabelaPromet_ProdajaXminus1_8.ChildOrder := 8;
    vpTabelaPromet_ProdajaXminus1_8.HeightPercent := 100.000000000000000000;
    vpTabelaPromet_ProdajaXminus1_8.TabOrder := 8;
    vpTabelaPromet_ProdajaXminus1_8.Text := '0,00';
    vpTabelaPromet_ProdajaXminus1_8.WidthPercent := 100.000000000000000000;
    SetEvent(vpTabelaPromet_ProdajaXminus1_8, Self, 'OnExit', 'vpTabelaPromet_ProdajaExit');
    SetEvent(vpTabelaPromet_ProdajaXminus1_8, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    vpTabelaPromet_ProdajaXminus1_9.SetParentComponent(ploscicaTabelaPrometaXminus1);
    vpTabelaPromet_ProdajaXminus1_9.Name := 'vpTabelaPromet_ProdajaXminus1_9';
    vpTabelaPromet_ProdajaXminus1_9.Left := 27;
    vpTabelaPromet_ProdajaXminus1_9.Top := 218;
    vpTabelaPromet_ProdajaXminus1_9.Width := 125;
    vpTabelaPromet_ProdajaXminus1_9.Height := 22;
    vpTabelaPromet_ProdajaXminus1_9.Alignment := taRightJustify;
    vpTabelaPromet_ProdajaXminus1_9.Anchors := [];
    vpTabelaPromet_ProdajaXminus1_9.ChildOrder := 9;
    vpTabelaPromet_ProdajaXminus1_9.HeightPercent := 100.000000000000000000;
    vpTabelaPromet_ProdajaXminus1_9.TabOrder := 9;
    vpTabelaPromet_ProdajaXminus1_9.Text := '0,00';
    vpTabelaPromet_ProdajaXminus1_9.WidthPercent := 100.000000000000000000;
    SetEvent(vpTabelaPromet_ProdajaXminus1_9, Self, 'OnExit', 'vpTabelaPromet_ProdajaExit');
    SetEvent(vpTabelaPromet_ProdajaXminus1_9, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    vpTabelaPromet_ProdajaXminus1_10.SetParentComponent(ploscicaTabelaPrometaXminus1);
    vpTabelaPromet_ProdajaXminus1_10.Name := 'vpTabelaPromet_ProdajaXminus1_10';
    vpTabelaPromet_ProdajaXminus1_10.Left := 27;
    vpTabelaPromet_ProdajaXminus1_10.Top := 242;
    vpTabelaPromet_ProdajaXminus1_10.Width := 125;
    vpTabelaPromet_ProdajaXminus1_10.Height := 22;
    vpTabelaPromet_ProdajaXminus1_10.Alignment := taRightJustify;
    vpTabelaPromet_ProdajaXminus1_10.Anchors := [];
    vpTabelaPromet_ProdajaXminus1_10.ChildOrder := 10;
    vpTabelaPromet_ProdajaXminus1_10.HeightPercent := 100.000000000000000000;
    vpTabelaPromet_ProdajaXminus1_10.TabOrder := 10;
    vpTabelaPromet_ProdajaXminus1_10.Text := '0,00';
    vpTabelaPromet_ProdajaXminus1_10.WidthPercent := 100.000000000000000000;
    SetEvent(vpTabelaPromet_ProdajaXminus1_10, Self, 'OnExit', 'vpTabelaPromet_ProdajaExit');
    SetEvent(vpTabelaPromet_ProdajaXminus1_10, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    vpTabelaPromet_ProdajaXminus1_11.SetParentComponent(ploscicaTabelaPrometaXminus1);
    vpTabelaPromet_ProdajaXminus1_11.Name := 'vpTabelaPromet_ProdajaXminus1_11';
    vpTabelaPromet_ProdajaXminus1_11.Left := 27;
    vpTabelaPromet_ProdajaXminus1_11.Top := 266;
    vpTabelaPromet_ProdajaXminus1_11.Width := 125;
    vpTabelaPromet_ProdajaXminus1_11.Height := 22;
    vpTabelaPromet_ProdajaXminus1_11.Alignment := taRightJustify;
    vpTabelaPromet_ProdajaXminus1_11.Anchors := [];
    vpTabelaPromet_ProdajaXminus1_11.ChildOrder := 11;
    vpTabelaPromet_ProdajaXminus1_11.HeightPercent := 100.000000000000000000;
    vpTabelaPromet_ProdajaXminus1_11.TabOrder := 11;
    vpTabelaPromet_ProdajaXminus1_11.Text := '0,00';
    vpTabelaPromet_ProdajaXminus1_11.WidthPercent := 100.000000000000000000;
    SetEvent(vpTabelaPromet_ProdajaXminus1_11, Self, 'OnExit', 'vpTabelaPromet_ProdajaExit');
    SetEvent(vpTabelaPromet_ProdajaXminus1_11, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    vpTabelaPromet_ProdajaXminus1_12.SetParentComponent(ploscicaTabelaPrometaXminus1);
    vpTabelaPromet_ProdajaXminus1_12.Name := 'vpTabelaPromet_ProdajaXminus1_12';
    vpTabelaPromet_ProdajaXminus1_12.Left := 27;
    vpTabelaPromet_ProdajaXminus1_12.Top := 290;
    vpTabelaPromet_ProdajaXminus1_12.Width := 125;
    vpTabelaPromet_ProdajaXminus1_12.Height := 22;
    vpTabelaPromet_ProdajaXminus1_12.Alignment := taRightJustify;
    vpTabelaPromet_ProdajaXminus1_12.Anchors := [];
    vpTabelaPromet_ProdajaXminus1_12.ChildOrder := 12;
    vpTabelaPromet_ProdajaXminus1_12.HeightPercent := 100.000000000000000000;
    vpTabelaPromet_ProdajaXminus1_12.TabOrder := 12;
    vpTabelaPromet_ProdajaXminus1_12.Text := '0,00';
    vpTabelaPromet_ProdajaXminus1_12.WidthPercent := 100.000000000000000000;
    SetEvent(vpTabelaPromet_ProdajaXminus1_12, Self, 'OnExit', 'vpTabelaPromet_ProdajaExit');
    SetEvent(vpTabelaPromet_ProdajaXminus1_12, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    vpPlanProdaje_RastXplus1.SetParentComponent(WebScrollBoxDL3);
    vpPlanProdaje_RastXplus1.Name := 'vpPlanProdaje_RastXplus1';
    vpPlanProdaje_RastXplus1.Left := 94;
    vpPlanProdaje_RastXplus1.Top := 519;
    vpPlanProdaje_RastXplus1.Width := 200;
    vpPlanProdaje_RastXplus1.Height := 20;
    vpPlanProdaje_RastXplus1.HeightPercent := 100.000000000000000000;
    vpPlanProdaje_RastXplus1.WidthPercent := 100.000000000000000000;
    vpPlanProdaje_RastXplus1.ChildOrder := 7;
    vpPlanProdaje_RastXplus1.Max := 150;
    vpPlanProdaje_RastXplus1.Min := -50;
    vpPlanProdaje_RastXplus1.Position := 0;
    vpPlanProdaje_RastXplus1.Role := '';
    vpPlanProdaje_RastXplus1.TabOrder := 25;
    SetEvent(vpPlanProdaje_RastXplus1, Self, 'OnChange', 'vpPlanProdaje_RastChange');
    vpPlanProdaje_RastXplus2.SetParentComponent(WebScrollBoxDL3);
    vpPlanProdaje_RastXplus2.Name := 'vpPlanProdaje_RastXplus2';
    vpPlanProdaje_RastXplus2.Left := 94;
    vpPlanProdaje_RastXplus2.Top := 570;
    vpPlanProdaje_RastXplus2.Width := 200;
    vpPlanProdaje_RastXplus2.Height := 20;
    vpPlanProdaje_RastXplus2.HeightPercent := 100.000000000000000000;
    vpPlanProdaje_RastXplus2.WidthPercent := 100.000000000000000000;
    vpPlanProdaje_RastXplus2.ChildOrder := 9;
    vpPlanProdaje_RastXplus2.Max := 150;
    vpPlanProdaje_RastXplus2.Min := -50;
    vpPlanProdaje_RastXplus2.Position := 0;
    vpPlanProdaje_RastXplus2.Role := '';
    vpPlanProdaje_RastXplus2.TabOrder := 26;
    SetEvent(vpPlanProdaje_RastXplus2, Self, 'OnChange', 'vpPlanProdaje_RastChange');
    vpPlanProdaje_RastXplus3.SetParentComponent(WebScrollBoxDL3);
    vpPlanProdaje_RastXplus3.Name := 'vpPlanProdaje_RastXplus3';
    vpPlanProdaje_RastXplus3.Left := 90;
    vpPlanProdaje_RastXplus3.Top := 620;
    vpPlanProdaje_RastXplus3.Width := 200;
    vpPlanProdaje_RastXplus3.Height := 20;
    vpPlanProdaje_RastXplus3.HeightPercent := 100.000000000000000000;
    vpPlanProdaje_RastXplus3.WidthPercent := 100.000000000000000000;
    vpPlanProdaje_RastXplus3.ChildOrder := 11;
    vpPlanProdaje_RastXplus3.Max := 150;
    vpPlanProdaje_RastXplus3.Min := -50;
    vpPlanProdaje_RastXplus3.Position := 0;
    vpPlanProdaje_RastXplus3.Role := '';
    vpPlanProdaje_RastXplus3.TabOrder := 27;
    SetEvent(vpPlanProdaje_RastXplus3, Self, 'OnChange', 'vpPlanProdaje_RastChange');
    vpPlanProdaje_RastXplus4.SetParentComponent(WebScrollBoxDL3);
    vpPlanProdaje_RastXplus4.Name := 'vpPlanProdaje_RastXplus4';
    vpPlanProdaje_RastXplus4.Left := 90;
    vpPlanProdaje_RastXplus4.Top := 670;
    vpPlanProdaje_RastXplus4.Width := 200;
    vpPlanProdaje_RastXplus4.Height := 20;
    vpPlanProdaje_RastXplus4.HeightPercent := 100.000000000000000000;
    vpPlanProdaje_RastXplus4.WidthPercent := 100.000000000000000000;
    vpPlanProdaje_RastXplus4.ChildOrder := 13;
    vpPlanProdaje_RastXplus4.Max := 150;
    vpPlanProdaje_RastXplus4.Min := -50;
    vpPlanProdaje_RastXplus4.Position := 0;
    vpPlanProdaje_RastXplus4.Role := '';
    vpPlanProdaje_RastXplus4.TabOrder := 28;
    SetEvent(vpPlanProdaje_RastXplus4, Self, 'OnChange', 'vpPlanProdaje_RastChange');
    DelovniList4.SetParentComponent(ploscicaDelovniListi);
    DelovniList4.Name := 'DelovniList4';
    DelovniList4.Left := 0;
    DelovniList4.Top := 20;
    DelovniList4.Width := 1157;
    DelovniList4.Height := 1090;
    DelovniList4.HeightPercent := 100.000000000000000000;
    DelovniList4.WidthPercent := 100.000000000000000000;
    DelovniList4.Caption := 'DelovniList4';
    DelovniList4.ChildOrder := 6;
    DelovniList4.TabVisible := False;
    WebScrollBoxDL4.SetParentComponent(DelovniList4);
    WebScrollBoxDL4.Name := 'WebScrollBoxDL4';
    WebScrollBoxDL4.Left := 0;
    WebScrollBoxDL4.Top := 0;
    WebScrollBoxDL4.Width := 1157;
    WebScrollBoxDL4.Height := 1090;
    WebScrollBoxDL4.HeightPercent := 100.000000000000000000;
    WebScrollBoxDL4.WidthPercent := 100.000000000000000000;
    WebScrollBoxDL4.Align := alClient;
    WebScrollBoxDL4.AutoScroll := True;
    WebScrollBoxDL4.BorderStyle := bsNone;
    WebScrollBoxDL4.ChildOrder := 2;
    WebScrollBoxDL4.Color := clWindow;
    izNaslovDL4.SetParentComponent(WebScrollBoxDL4);
    izNaslovDL4.Name := 'izNaslovDL4';
    izNaslovDL4.Left := 12;
    izNaslovDL4.Top := 19;
    izNaslovDL4.Width := 287;
    izNaslovDL4.Height := 19;
    izNaslovDL4.Anchors := [];
    izNaslovDL4.Caption := 'Podatki o organizaciji dela v podjetju';
    izNaslovDL4.Color := clNone;
    izNaslovDL4.Font.Charset := DEFAULT_CHARSET;
    izNaslovDL4.Font.Color := clWindowText;
    izNaslovDL4.Font.Height := -17;
    izNaslovDL4.Font.Name := 'Arial';
    izNaslovDL4.Font.Style := [fsBold];
    izNaslovDL4.HeightPercent := 100.000000000000000000;
    izNaslovDL4.ParentFont := False;
    izNaslovDL4.WidthPercent := 100.000000000000000000;
    ploscicaTabelaOrganizacijskihVprasanj.SetParentComponent(WebScrollBoxDL4);
    ploscicaTabelaOrganizacijskihVprasanj.Name := 'ploscicaTabelaOrganizacijskihVprasanj';
    ploscicaTabelaOrganizacijskihVprasanj.Left := 15;
    ploscicaTabelaOrganizacijskihVprasanj.Top := 80;
    ploscicaTabelaOrganizacijskihVprasanj.Width := 730;
    ploscicaTabelaOrganizacijskihVprasanj.Height := 850;
    ploscicaTabelaOrganizacijskihVprasanj.HeightPercent := 100.000000000000000000;
    ploscicaTabelaOrganizacijskihVprasanj.WidthPercent := 100.000000000000000000;
    ploscicaTabelaOrganizacijskihVprasanj.ChildOrder := 1;
    ploscicaTabelaOrganizacijskihVprasanj.ColumnCollection.Clear;
    with ploscicaTabelaOrganizacijskihVprasanj.ColumnCollection.Add do
    begin
      Value := 3;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.ColumnCollection.Add do
    begin
      Value := 62;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.ColumnCollection.Add do
    begin
      Alignment := taCenter;
      Value := 25;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.ColumnCollection.Add do
    begin
      Value := 10;
    end;
    ploscicaTabelaOrganizacijskihVprasanj.ControlCollection.Clear;
    with ploscicaTabelaOrganizacijskihVprasanj.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izOrganizacija_St1;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izOrganizacija_Vprasanje1;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := vpOrganizacija_Odgovor1;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izOrganizacija_mera1;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izOrganizacija_St2;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izOrganizacija_Vprasanje2;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := vpOrganizacija_Odgovor2;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izOrganizacija_mera2;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izOrganizacija_St3;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izOrganizacija_Vprasanje3;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaOrganizacija_Odgovor3;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izOrganizacija_mera3;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izOrganizacija_St4;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izOrganizacija_Vprasanje4;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaOrganizacija_Odgovor4;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izOrganizacija_mera4;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izOrganizacija_St5;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izOrganizacija_Vprasanje5;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaOrganizacija_Odgovor5;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izOrganizacija_mera5;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izOrganizacija_St6;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izOrganizacija_Vprasanje6;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaOrganizacija_Odgovor6;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izOrganizacija_mera6;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izOrganizacija_St7;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izOrganizacija_Vprasanje7;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaOrganizacija_Odgovor7;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izOrganizacija_mera7;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izOrganizacija_St8;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izOrganizacija_Vprasanje8;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaOrganizacija_Odgovor8;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izOrganizacija_mera8;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izOrganizacija_St9;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izOrganizacija_Vprasanje9;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := vpOrganizacija_Odgovor9;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izOrganizacija_mera9;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izOrganizacija_St10;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izOrganizacija_Vprasanje10;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := vpOrganizacija_Odgovor10;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izOrganizacija_mera10;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izOrganizacija_St11;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izOrganizacija_Vprasanje11;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izOrganizacija_OdgovorPrazen11;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izOrganizacija_MeraPrazen11;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izOrganizacija_StPrazen11_1;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izOrganizacija_Vprasanje11_1;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaOrganizacija_Odgovor11_1;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izOrganizacija_MeraPrazen11_1;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izOrganizacija_StPrazen11_2;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izOrganizacija_Vprasanje11_2;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := vpOrganizacija_Odgovor11_2;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izOrganizacija_mera11_2;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izOrganizacija_StPrazen11_3;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izOrganizacija_Vprasanje11_3;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := vpOrganizacija_Odgovor11_3;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izOrganizacija_mera11_3;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izOrganizacija_StPrazen11_4;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izOrganizacija_Vprasanje11_4;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaOrganizacija_Odgovor11_4;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izOrganizacija_MeraPrazen11_4;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izOrganizacija_StPrazen11_5;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izOrganizacija_Vprasanje11_5;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := vpOrganizacija_Odgovor11_5;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izOrganizacija_mera11_5;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izOrganizacija_StPrazen11_6;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izOrganizacija_Vprasanje11_6;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := vpOrganizacija_Odgovor11_6;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izOrganizacija_mera11_6;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izOrganizacija_St12;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izOrganizacija_Vprasanje12;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := vpOrganizacija_Odgovor12;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izOrganizacija_mera12;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izOrganizacija_St13;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izOrganizacija_Vprasanje13;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := vpOrganizacija_Odgovor13;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izOrganizacija_mera13;
    end;
    ploscicaTabelaOrganizacijskihVprasanj.Color := clWhite;
    ploscicaTabelaOrganizacijskihVprasanj.GridLineColor := clBlack;
    ploscicaTabelaOrganizacijskihVprasanj.RowCollection.Clear;
    with ploscicaTabelaOrganizacijskihVprasanj.RowCollection.Add do
    begin
      Value := 5;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.RowCollection.Add do
    begin
      Value := 5;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.RowCollection.Add do
    begin
      Value := 5;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.RowCollection.Add do
    begin
      Value := 5;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.RowCollection.Add do
    begin
      Value := 5;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.RowCollection.Add do
    begin
      Value := 5;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.RowCollection.Add do
    begin
      Value := 5;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.RowCollection.Add do
    begin
      Value := 5;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.RowCollection.Add do
    begin
      Value := 5;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.RowCollection.Add do
    begin
      Value := 5;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.RowCollection.Add do
    begin
      Value := 5;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.RowCollection.Add do
    begin
      Value := 5;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.RowCollection.Add do
    begin
      Value := 5;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.RowCollection.Add do
    begin
      Value := 5;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.RowCollection.Add do
    begin
      Value := 5;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.RowCollection.Add do
    begin
      Value := 5;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.RowCollection.Add do
    begin
      Value := 5;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.RowCollection.Add do
    begin
      Value := 5;
    end;
    with ploscicaTabelaOrganizacijskihVprasanj.RowCollection.Add do
    begin
      Value := 5;
    end;
    izOrganizacija_St1.SetParentComponent(ploscicaTabelaOrganizacijskihVprasanj);
    izOrganizacija_St1.Name := 'izOrganizacija_St1';
    izOrganizacija_St1.Left := 2;
    izOrganizacija_St1.Top := 2;
    izOrganizacija_St1.Width := 10;
    izOrganizacija_St1.Height := 16;
    izOrganizacija_St1.Anchors := [];
    izOrganizacija_St1.AutoSize := False;
    izOrganizacija_St1.Caption := '1.';
    izOrganizacija_St1.Color := clNone;
    izOrganizacija_St1.HeightPercent := 100.000000000000000000;
    izOrganizacija_St1.WidthPercent := 100.000000000000000000;
    izOrganizacija_mera1.SetParentComponent(ploscicaTabelaOrganizacijskihVprasanj);
    izOrganizacija_mera1.Name := 'izOrganizacija_mera1';
    izOrganizacija_mera1.Left := 659;
    izOrganizacija_mera1.Top := 2;
    izOrganizacija_mera1.Width := 60;
    izOrganizacija_mera1.Height := 18;
    izOrganizacija_mera1.Anchors := [];
    izOrganizacija_mera1.AutoSize := False;
    izOrganizacija_mera1.Caption := '1 mes.';
    izOrganizacija_mera1.Color := clNone;
    izOrganizacija_mera1.HeightPercent := 100.000000000000000000;
    izOrganizacija_mera1.WidthPercent := 100.000000000000000000;
    izOrganizacija_St2.SetParentComponent(ploscicaTabelaOrganizacijskihVprasanj);
    izOrganizacija_St2.Name := 'izOrganizacija_St2';
    izOrganizacija_St2.Left := 2;
    izOrganizacija_St2.Top := 44;
    izOrganizacija_St2.Width := 10;
    izOrganizacija_St2.Height := 16;
    izOrganizacija_St2.Anchors := [];
    izOrganizacija_St2.AutoSize := False;
    izOrganizacija_St2.Caption := '2.';
    izOrganizacija_St2.Color := clNone;
    izOrganizacija_St2.HeightPercent := 100.000000000000000000;
    izOrganizacija_St2.WidthPercent := 100.000000000000000000;
    izOrganizacija_mera2.SetParentComponent(ploscicaTabelaOrganizacijskihVprasanj);
    izOrganizacija_mera2.Name := 'izOrganizacija_mera2';
    izOrganizacija_mera2.Left := 659;
    izOrganizacija_mera2.Top := 44;
    izOrganizacija_mera2.Width := 60;
    izOrganizacija_mera2.Height := 18;
    izOrganizacija_mera2.Anchors := [];
    izOrganizacija_mera2.AutoSize := False;
    izOrganizacija_mera2.Caption := '1 mes.';
    izOrganizacija_mera2.Color := clNone;
    izOrganizacija_mera2.HeightPercent := 100.000000000000000000;
    izOrganizacija_mera2.WidthPercent := 100.000000000000000000;
    izOrganizacija_St3.SetParentComponent(ploscicaTabelaOrganizacijskihVprasanj);
    izOrganizacija_St3.Name := 'izOrganizacija_St3';
    izOrganizacija_St3.Left := 2;
    izOrganizacija_St3.Top := 86;
    izOrganizacija_St3.Width := 10;
    izOrganizacija_St3.Height := 16;
    izOrganizacija_St3.Anchors := [];
    izOrganizacija_St3.AutoSize := False;
    izOrganizacija_St3.Caption := '3.';
    izOrganizacija_St3.Color := clNone;
    izOrganizacija_St3.HeightPercent := 100.000000000000000000;
    izOrganizacija_St3.WidthPercent := 100.000000000000000000;
    izOrganizacija_mera3.SetParentComponent(ploscicaTabelaOrganizacijskihVprasanj);
    izOrganizacija_mera3.Name := 'izOrganizacija_mera3';
    izOrganizacija_mera3.Left := 659;
    izOrganizacija_mera3.Top := 86;
    izOrganizacija_mera3.Width := 60;
    izOrganizacija_mera3.Height := 18;
    izOrganizacija_mera3.Anchors := [];
    izOrganizacija_mera3.AutoSize := False;
    izOrganizacija_mera3.Color := clNone;
    izOrganizacija_mera3.HeightPercent := 100.000000000000000000;
    izOrganizacija_mera3.WidthPercent := 100.000000000000000000;
    izOrganizacija_St4.SetParentComponent(ploscicaTabelaOrganizacijskihVprasanj);
    izOrganizacija_St4.Name := 'izOrganizacija_St4';
    izOrganizacija_St4.Left := 2;
    izOrganizacija_St4.Top := 128;
    izOrganizacija_St4.Width := 10;
    izOrganizacija_St4.Height := 16;
    izOrganizacija_St4.Anchors := [];
    izOrganizacija_St4.AutoSize := False;
    izOrganizacija_St4.Caption := '4.';
    izOrganizacija_St4.Color := clNone;
    izOrganizacija_St4.HeightPercent := 100.000000000000000000;
    izOrganizacija_St4.WidthPercent := 100.000000000000000000;
    izOrganizacija_mera4.SetParentComponent(ploscicaTabelaOrganizacijskihVprasanj);
    izOrganizacija_mera4.Name := 'izOrganizacija_mera4';
    izOrganizacija_mera4.Left := 659;
    izOrganizacija_mera4.Top := 128;
    izOrganizacija_mera4.Width := 60;
    izOrganizacija_mera4.Height := 18;
    izOrganizacija_mera4.Anchors := [];
    izOrganizacija_mera4.AutoSize := False;
    izOrganizacija_mera4.Color := clNone;
    izOrganizacija_mera4.HeightPercent := 100.000000000000000000;
    izOrganizacija_mera4.WidthPercent := 100.000000000000000000;
    izOrganizacija_St5.SetParentComponent(ploscicaTabelaOrganizacijskihVprasanj);
    izOrganizacija_St5.Name := 'izOrganizacija_St5';
    izOrganizacija_St5.Left := 2;
    izOrganizacija_St5.Top := 170;
    izOrganizacija_St5.Width := 10;
    izOrganizacija_St5.Height := 16;
    izOrganizacija_St5.Anchors := [];
    izOrganizacija_St5.AutoSize := False;
    izOrganizacija_St5.Caption := '5.';
    izOrganizacija_St5.Color := clNone;
    izOrganizacija_St5.HeightPercent := 100.000000000000000000;
    izOrganizacija_St5.WidthPercent := 100.000000000000000000;
    izOrganizacija_mera5.SetParentComponent(ploscicaTabelaOrganizacijskihVprasanj);
    izOrganizacija_mera5.Name := 'izOrganizacija_mera5';
    izOrganizacija_mera5.Left := 659;
    izOrganizacija_mera5.Top := 170;
    izOrganizacija_mera5.Width := 60;
    izOrganizacija_mera5.Height := 18;
    izOrganizacija_mera5.Anchors := [];
    izOrganizacija_mera5.AutoSize := False;
    izOrganizacija_mera5.Color := clNone;
    izOrganizacija_mera5.HeightPercent := 100.000000000000000000;
    izOrganizacija_mera5.WidthPercent := 100.000000000000000000;
    izOrganizacija_St6.SetParentComponent(ploscicaTabelaOrganizacijskihVprasanj);
    izOrganizacija_St6.Name := 'izOrganizacija_St6';
    izOrganizacija_St6.Left := 2;
    izOrganizacija_St6.Top := 212;
    izOrganizacija_St6.Width := 10;
    izOrganizacija_St6.Height := 16;
    izOrganizacija_St6.Anchors := [];
    izOrganizacija_St6.AutoSize := False;
    izOrganizacija_St6.Caption := '6.';
    izOrganizacija_St6.Color := clNone;
    izOrganizacija_St6.HeightPercent := 100.000000000000000000;
    izOrganizacija_St6.WidthPercent := 100.000000000000000000;
    izOrganizacija_mera6.SetParentComponent(ploscicaTabelaOrganizacijskihVprasanj);
    izOrganizacija_mera6.Name := 'izOrganizacija_mera6';
    izOrganizacija_mera6.Left := 659;
    izOrganizacija_mera6.Top := 212;
    izOrganizacija_mera6.Width := 60;
    izOrganizacija_mera6.Height := 18;
    izOrganizacija_mera6.Anchors := [];
    izOrganizacija_mera6.AutoSize := False;
    izOrganizacija_mera6.Color := clNone;
    izOrganizacija_mera6.HeightPercent := 100.000000000000000000;
    izOrganizacija_mera6.WidthPercent := 100.000000000000000000;
    izOrganizacija_St7.SetParentComponent(ploscicaTabelaOrganizacijskihVprasanj);
    izOrganizacija_St7.Name := 'izOrganizacija_St7';
    izOrganizacija_St7.Left := 2;
    izOrganizacija_St7.Top := 254;
    izOrganizacija_St7.Width := 10;
    izOrganizacija_St7.Height := 16;
    izOrganizacija_St7.Anchors := [];
    izOrganizacija_St7.AutoSize := False;
    izOrganizacija_St7.Caption := '7.';
    izOrganizacija_St7.Color := clNone;
    izOrganizacija_St7.HeightPercent := 100.000000000000000000;
    izOrganizacija_St7.WidthPercent := 100.000000000000000000;
    izOrganizacija_mera7.SetParentComponent(ploscicaTabelaOrganizacijskihVprasanj);
    izOrganizacija_mera7.Name := 'izOrganizacija_mera7';
    izOrganizacija_mera7.Left := 659;
    izOrganizacija_mera7.Top := 254;
    izOrganizacija_mera7.Width := 60;
    izOrganizacija_mera7.Height := 18;
    izOrganizacija_mera7.Anchors := [];
    izOrganizacija_mera7.AutoSize := False;
    izOrganizacija_mera7.Color := clNone;
    izOrganizacija_mera7.HeightPercent := 100.000000000000000000;
    izOrganizacija_mera7.WidthPercent := 100.000000000000000000;
    izOrganizacija_St8.SetParentComponent(ploscicaTabelaOrganizacijskihVprasanj);
    izOrganizacija_St8.Name := 'izOrganizacija_St8';
    izOrganizacija_St8.Left := 2;
    izOrganizacija_St8.Top := 296;
    izOrganizacija_St8.Width := 10;
    izOrganizacija_St8.Height := 16;
    izOrganizacija_St8.Anchors := [];
    izOrganizacija_St8.AutoSize := False;
    izOrganizacija_St8.Caption := '8.';
    izOrganizacija_St8.Color := clNone;
    izOrganizacija_St8.HeightPercent := 100.000000000000000000;
    izOrganizacija_St8.WidthPercent := 100.000000000000000000;
    izOrganizacija_mera8.SetParentComponent(ploscicaTabelaOrganizacijskihVprasanj);
    izOrganizacija_mera8.Name := 'izOrganizacija_mera8';
    izOrganizacija_mera8.Left := 659;
    izOrganizacija_mera8.Top := 296;
    izOrganizacija_mera8.Width := 60;
    izOrganizacija_mera8.Height := 18;
    izOrganizacija_mera8.Anchors := [];
    izOrganizacija_mera8.AutoSize := False;
    izOrganizacija_mera8.Color := clNone;
    izOrganizacija_mera8.HeightPercent := 100.000000000000000000;
    izOrganizacija_mera8.WidthPercent := 100.000000000000000000;
    izOrganizacija_St9.SetParentComponent(ploscicaTabelaOrganizacijskihVprasanj);
    izOrganizacija_St9.Name := 'izOrganizacija_St9';
    izOrganizacija_St9.Left := 2;
    izOrganizacija_St9.Top := 338;
    izOrganizacija_St9.Width := 10;
    izOrganizacija_St9.Height := 16;
    izOrganizacija_St9.Anchors := [];
    izOrganizacija_St9.AutoSize := False;
    izOrganizacija_St9.Caption := '9.';
    izOrganizacija_St9.Color := clNone;
    izOrganizacija_St9.HeightPercent := 100.000000000000000000;
    izOrganizacija_St9.WidthPercent := 100.000000000000000000;
    izOrganizacija_mera9.SetParentComponent(ploscicaTabelaOrganizacijskihVprasanj);
    izOrganizacija_mera9.Name := 'izOrganizacija_mera9';
    izOrganizacija_mera9.Left := 659;
    izOrganizacija_mera9.Top := 338;
    izOrganizacija_mera9.Width := 60;
    izOrganizacija_mera9.Height := 18;
    izOrganizacija_mera9.Anchors := [];
    izOrganizacija_mera9.AutoSize := False;
    izOrganizacija_mera9.Caption := '1 izm.';
    izOrganizacija_mera9.Color := clNone;
    izOrganizacija_mera9.HeightPercent := 100.000000000000000000;
    izOrganizacija_mera9.WidthPercent := 100.000000000000000000;
    izOrganizacija_St10.SetParentComponent(ploscicaTabelaOrganizacijskihVprasanj);
    izOrganizacija_St10.Name := 'izOrganizacija_St10';
    izOrganizacija_St10.Left := 2;
    izOrganizacija_St10.Top := 380;
    izOrganizacija_St10.Width := 16;
    izOrganizacija_St10.Height := 16;
    izOrganizacija_St10.Anchors := [];
    izOrganizacija_St10.AutoSize := False;
    izOrganizacija_St10.Caption := '10.';
    izOrganizacija_St10.Color := clNone;
    izOrganizacija_St10.HeightPercent := 100.000000000000000000;
    izOrganizacija_St10.WidthPercent := 100.000000000000000000;
    izOrganizacija_mera10.SetParentComponent(ploscicaTabelaOrganizacijskihVprasanj);
    izOrganizacija_mera10.Name := 'izOrganizacija_mera10';
    izOrganizacija_mera10.Left := 659;
    izOrganizacija_mera10.Top := 380;
    izOrganizacija_mera10.Width := 60;
    izOrganizacija_mera10.Height := 18;
    izOrganizacija_mera10.Anchors := [];
    izOrganizacija_mera10.AutoSize := False;
    izOrganizacija_mera10.Caption := '100 %';
    izOrganizacija_mera10.Color := clNone;
    izOrganizacija_mera10.HeightPercent := 100.000000000000000000;
    izOrganizacija_mera10.WidthPercent := 100.000000000000000000;
    izOrganizacija_St11.SetParentComponent(ploscicaTabelaOrganizacijskihVprasanj);
    izOrganizacija_St11.Name := 'izOrganizacija_St11';
    izOrganizacija_St11.Left := 2;
    izOrganizacija_St11.Top := 422;
    izOrganizacija_St11.Width := 16;
    izOrganizacija_St11.Height := 16;
    izOrganizacija_St11.Anchors := [];
    izOrganizacija_St11.AutoSize := False;
    izOrganizacija_St11.Caption := '11.';
    izOrganizacija_St11.Color := clNone;
    izOrganizacija_St11.HeightPercent := 100.000000000000000000;
    izOrganizacija_St11.WidthPercent := 100.000000000000000000;
    izOrganizacija_OdgovorPrazen11.SetParentComponent(ploscicaTabelaOrganizacijskihVprasanj);
    izOrganizacija_OdgovorPrazen11.Name := 'izOrganizacija_OdgovorPrazen11';
    izOrganizacija_OdgovorPrazen11.Left := 564;
    izOrganizacija_OdgovorPrazen11.Top := 422;
    izOrganizacija_OdgovorPrazen11.Width := 3;
    izOrganizacija_OdgovorPrazen11.Height := 15;
    izOrganizacija_OdgovorPrazen11.Anchors := [];
    izOrganizacija_OdgovorPrazen11.Color := clNone;
    izOrganizacija_OdgovorPrazen11.HeightPercent := 100.000000000000000000;
    izOrganizacija_OdgovorPrazen11.WidthPercent := 100.000000000000000000;
    izOrganizacija_MeraPrazen11.SetParentComponent(ploscicaTabelaOrganizacijskihVprasanj);
    izOrganizacija_MeraPrazen11.Name := 'izOrganizacija_MeraPrazen11';
    izOrganizacija_MeraPrazen11.Left := 659;
    izOrganizacija_MeraPrazen11.Top := 422;
    izOrganizacija_MeraPrazen11.Width := 3;
    izOrganizacija_MeraPrazen11.Height := 15;
    izOrganizacija_MeraPrazen11.Anchors := [];
    izOrganizacija_MeraPrazen11.Color := clNone;
    izOrganizacija_MeraPrazen11.HeightPercent := 100.000000000000000000;
    izOrganizacija_MeraPrazen11.WidthPercent := 100.000000000000000000;
    izOrganizacija_StPrazen11_1.SetParentComponent(ploscicaTabelaOrganizacijskihVprasanj);
    izOrganizacija_StPrazen11_1.Name := 'izOrganizacija_StPrazen11_1';
    izOrganizacija_StPrazen11_1.Left := 2;
    izOrganizacija_StPrazen11_1.Top := 464;
    izOrganizacija_StPrazen11_1.Width := 3;
    izOrganizacija_StPrazen11_1.Height := 15;
    izOrganizacija_StPrazen11_1.Anchors := [];
    izOrganizacija_StPrazen11_1.Color := clNone;
    izOrganizacija_StPrazen11_1.HeightPercent := 100.000000000000000000;
    izOrganizacija_StPrazen11_1.WidthPercent := 100.000000000000000000;
    izOrganizacija_MeraPrazen11_1.SetParentComponent(ploscicaTabelaOrganizacijskihVprasanj);
    izOrganizacija_MeraPrazen11_1.Name := 'izOrganizacija_MeraPrazen11_1';
    izOrganizacija_MeraPrazen11_1.Left := 659;
    izOrganizacija_MeraPrazen11_1.Top := 464;
    izOrganizacija_MeraPrazen11_1.Width := 3;
    izOrganizacija_MeraPrazen11_1.Height := 15;
    izOrganizacija_MeraPrazen11_1.Anchors := [];
    izOrganizacija_MeraPrazen11_1.Color := clNone;
    izOrganizacija_MeraPrazen11_1.HeightPercent := 100.000000000000000000;
    izOrganizacija_MeraPrazen11_1.WidthPercent := 100.000000000000000000;
    izOrganizacija_StPrazen11_2.SetParentComponent(ploscicaTabelaOrganizacijskihVprasanj);
    izOrganizacija_StPrazen11_2.Name := 'izOrganizacija_StPrazen11_2';
    izOrganizacija_StPrazen11_2.Left := 2;
    izOrganizacija_StPrazen11_2.Top := 506;
    izOrganizacija_StPrazen11_2.Width := 3;
    izOrganizacija_StPrazen11_2.Height := 15;
    izOrganizacija_StPrazen11_2.Anchors := [];
    izOrganizacija_StPrazen11_2.Color := clNone;
    izOrganizacija_StPrazen11_2.HeightPercent := 100.000000000000000000;
    izOrganizacija_StPrazen11_2.WidthPercent := 100.000000000000000000;
    izOrganizacija_mera11_2.SetParentComponent(ploscicaTabelaOrganizacijskihVprasanj);
    izOrganizacija_mera11_2.Name := 'izOrganizacija_mera11_2';
    izOrganizacija_mera11_2.Left := 659;
    izOrganizacija_mera11_2.Top := 506;
    izOrganizacija_mera11_2.Width := 60;
    izOrganizacija_mera11_2.Height := 18;
    izOrganizacija_mera11_2.Anchors := [];
    izOrganizacija_mera11_2.AutoSize := False;
    izOrganizacija_mera11_2.Caption := '100 %';
    izOrganizacija_mera11_2.Color := clNone;
    izOrganizacija_mera11_2.HeightPercent := 100.000000000000000000;
    izOrganizacija_mera11_2.WidthPercent := 100.000000000000000000;
    izOrganizacija_StPrazen11_3.SetParentComponent(ploscicaTabelaOrganizacijskihVprasanj);
    izOrganizacija_StPrazen11_3.Name := 'izOrganizacija_StPrazen11_3';
    izOrganizacija_StPrazen11_3.Left := 2;
    izOrganizacija_StPrazen11_3.Top := 548;
    izOrganizacija_StPrazen11_3.Width := 3;
    izOrganizacija_StPrazen11_3.Height := 15;
    izOrganizacija_StPrazen11_3.Anchors := [];
    izOrganizacija_StPrazen11_3.Color := clNone;
    izOrganizacija_StPrazen11_3.HeightPercent := 100.000000000000000000;
    izOrganizacija_StPrazen11_3.WidthPercent := 100.000000000000000000;
    izOrganizacija_mera11_3.SetParentComponent(ploscicaTabelaOrganizacijskihVprasanj);
    izOrganizacija_mera11_3.Name := 'izOrganizacija_mera11_3';
    izOrganizacija_mera11_3.Left := 659;
    izOrganizacija_mera11_3.Top := 548;
    izOrganizacija_mera11_3.Width := 60;
    izOrganizacija_mera11_3.Height := 18;
    izOrganizacija_mera11_3.Anchors := [];
    izOrganizacija_mera11_3.AutoSize := False;
    izOrganizacija_mera11_3.Caption := '2 mes.';
    izOrganizacija_mera11_3.Color := clNone;
    izOrganizacija_mera11_3.HeightPercent := 100.000000000000000000;
    izOrganizacija_mera11_3.WidthPercent := 100.000000000000000000;
    izOrganizacija_StPrazen11_4.SetParentComponent(ploscicaTabelaOrganizacijskihVprasanj);
    izOrganizacija_StPrazen11_4.Name := 'izOrganizacija_StPrazen11_4';
    izOrganizacija_StPrazen11_4.Left := 2;
    izOrganizacija_StPrazen11_4.Top := 590;
    izOrganizacija_StPrazen11_4.Width := 3;
    izOrganizacija_StPrazen11_4.Height := 15;
    izOrganizacija_StPrazen11_4.Anchors := [];
    izOrganizacija_StPrazen11_4.Color := clNone;
    izOrganizacija_StPrazen11_4.HeightPercent := 100.000000000000000000;
    izOrganizacija_StPrazen11_4.WidthPercent := 100.000000000000000000;
    izOrganizacija_MeraPrazen11_4.SetParentComponent(ploscicaTabelaOrganizacijskihVprasanj);
    izOrganizacija_MeraPrazen11_4.Name := 'izOrganizacija_MeraPrazen11_4';
    izOrganizacija_MeraPrazen11_4.Left := 659;
    izOrganizacija_MeraPrazen11_4.Top := 590;
    izOrganizacija_MeraPrazen11_4.Width := 3;
    izOrganizacija_MeraPrazen11_4.Height := 15;
    izOrganizacija_MeraPrazen11_4.Anchors := [];
    izOrganizacija_MeraPrazen11_4.Color := clNone;
    izOrganizacija_MeraPrazen11_4.HeightPercent := 100.000000000000000000;
    izOrganizacija_MeraPrazen11_4.WidthPercent := 100.000000000000000000;
    izOrganizacija_StPrazen11_5.SetParentComponent(ploscicaTabelaOrganizacijskihVprasanj);
    izOrganizacija_StPrazen11_5.Name := 'izOrganizacija_StPrazen11_5';
    izOrganizacija_StPrazen11_5.Left := 2;
    izOrganizacija_StPrazen11_5.Top := 632;
    izOrganizacija_StPrazen11_5.Width := 3;
    izOrganizacija_StPrazen11_5.Height := 15;
    izOrganizacija_StPrazen11_5.Anchors := [];
    izOrganizacija_StPrazen11_5.Color := clNone;
    izOrganizacija_StPrazen11_5.HeightPercent := 100.000000000000000000;
    izOrganizacija_StPrazen11_5.WidthPercent := 100.000000000000000000;
    izOrganizacija_mera11_5.SetParentComponent(ploscicaTabelaOrganizacijskihVprasanj);
    izOrganizacija_mera11_5.Name := 'izOrganizacija_mera11_5';
    izOrganizacija_mera11_5.Left := 659;
    izOrganizacija_mera11_5.Top := 632;
    izOrganizacija_mera11_5.Width := 60;
    izOrganizacija_mera11_5.Height := 18;
    izOrganizacija_mera11_5.Anchors := [];
    izOrganizacija_mera11_5.AutoSize := False;
    izOrganizacija_mera11_5.Caption := '100 %';
    izOrganizacija_mera11_5.Color := clNone;
    izOrganizacija_mera11_5.HeightPercent := 100.000000000000000000;
    izOrganizacija_mera11_5.WidthPercent := 100.000000000000000000;
    izOrganizacija_StPrazen11_6.SetParentComponent(ploscicaTabelaOrganizacijskihVprasanj);
    izOrganizacija_StPrazen11_6.Name := 'izOrganizacija_StPrazen11_6';
    izOrganizacija_StPrazen11_6.Left := 2;
    izOrganizacija_StPrazen11_6.Top := 674;
    izOrganizacija_StPrazen11_6.Width := 3;
    izOrganizacija_StPrazen11_6.Height := 15;
    izOrganizacija_StPrazen11_6.Anchors := [];
    izOrganizacija_StPrazen11_6.Color := clNone;
    izOrganizacija_StPrazen11_6.HeightPercent := 100.000000000000000000;
    izOrganizacija_StPrazen11_6.WidthPercent := 100.000000000000000000;
    izOrganizacija_mera11_6.SetParentComponent(ploscicaTabelaOrganizacijskihVprasanj);
    izOrganizacija_mera11_6.Name := 'izOrganizacija_mera11_6';
    izOrganizacija_mera11_6.Left := 659;
    izOrganizacija_mera11_6.Top := 674;
    izOrganizacija_mera11_6.Width := 60;
    izOrganizacija_mera11_6.Height := 18;
    izOrganizacija_mera11_6.Anchors := [];
    izOrganizacija_mera11_6.AutoSize := False;
    izOrganizacija_mera11_6.Caption := '2 mes.';
    izOrganizacija_mera11_6.Color := clNone;
    izOrganizacija_mera11_6.HeightPercent := 100.000000000000000000;
    izOrganizacija_mera11_6.WidthPercent := 100.000000000000000000;
    izOrganizacija_St12.SetParentComponent(ploscicaTabelaOrganizacijskihVprasanj);
    izOrganizacija_St12.Name := 'izOrganizacija_St12';
    izOrganizacija_St12.Left := 2;
    izOrganizacija_St12.Top := 716;
    izOrganizacija_St12.Width := 16;
    izOrganizacija_St12.Height := 16;
    izOrganizacija_St12.Anchors := [];
    izOrganizacija_St12.AutoSize := False;
    izOrganizacija_St12.Caption := '12.';
    izOrganizacija_St12.Color := clNone;
    izOrganizacija_St12.HeightPercent := 100.000000000000000000;
    izOrganizacija_St12.WidthPercent := 100.000000000000000000;
    izOrganizacija_mera12.SetParentComponent(ploscicaTabelaOrganizacijskihVprasanj);
    izOrganizacija_mera12.Name := 'izOrganizacija_mera12';
    izOrganizacija_mera12.Left := 659;
    izOrganizacija_mera12.Top := 716;
    izOrganizacija_mera12.Width := 60;
    izOrganizacija_mera12.Height := 18;
    izOrganizacija_mera12.Anchors := [];
    izOrganizacija_mera12.AutoSize := False;
    izOrganizacija_mera12.Caption := '1 mes.';
    izOrganizacija_mera12.Color := clNone;
    izOrganizacija_mera12.HeightPercent := 100.000000000000000000;
    izOrganizacija_mera12.WidthPercent := 100.000000000000000000;
    izOrganizacija_St13.SetParentComponent(ploscicaTabelaOrganizacijskihVprasanj);
    izOrganizacija_St13.Name := 'izOrganizacija_St13';
    izOrganizacija_St13.Left := 2;
    izOrganizacija_St13.Top := 758;
    izOrganizacija_St13.Width := 16;
    izOrganizacija_St13.Height := 16;
    izOrganizacija_St13.Anchors := [];
    izOrganizacija_St13.AutoSize := False;
    izOrganizacija_St13.Caption := '13.';
    izOrganizacija_St13.Color := clNone;
    izOrganizacija_St13.HeightPercent := 100.000000000000000000;
    izOrganizacija_St13.WidthPercent := 100.000000000000000000;
    izOrganizacija_mera13.SetParentComponent(ploscicaTabelaOrganizacijskihVprasanj);
    izOrganizacija_mera13.Name := 'izOrganizacija_mera13';
    izOrganizacija_mera13.Left := 659;
    izOrganizacija_mera13.Top := 758;
    izOrganizacija_mera13.Width := 60;
    izOrganizacija_mera13.Height := 18;
    izOrganizacija_mera13.Anchors := [];
    izOrganizacija_mera13.AutoSize := False;
    izOrganizacija_mera13.Color := clNone;
    izOrganizacija_mera13.HeightPercent := 100.000000000000000000;
    izOrganizacija_mera13.WidthPercent := 100.000000000000000000;
    izOrganizacija_Vprasanje1.SetParentComponent(ploscicaTabelaOrganizacijskihVprasanj);
    izOrganizacija_Vprasanje1.Name := 'izOrganizacija_Vprasanje1';
    izOrganizacija_Vprasanje1.Left := 24;
    izOrganizacija_Vprasanje1.Top := 2;
    izOrganizacija_Vprasanje1.Width := 403;
    izOrganizacija_Vprasanje1.Height := 28;
    izOrganizacija_Vprasanje1.Anchors := [];
    izOrganizacija_Vprasanje1.AutoSize := False;
    izOrganizacija_Vprasanje1.BorderStyle := bsNone;
    izOrganizacija_Vprasanje1.HeightPercent := 100.000000000000000000;
    izOrganizacija_Vprasanje1.Lines.BeginUpdate;
    try
      izOrganizacija_Vprasanje1.Lines.Clear;
      izOrganizacija_Vprasanje1.Lines.Add('Kak'#353'ni so dobavni roki (brez monta'#382'e) za nakup vitalnih strojev oziroma linij, ');
      izOrganizacija_Vprasanje1.Lines.Add('ki se uporabljajo v podjetju');
    finally
      izOrganizacija_Vprasanje1.Lines.EndUpdate;
    end;
    izOrganizacija_Vprasanje1.ParentColor := True;
    izOrganizacija_Vprasanje1.ReadOnly := True;
    izOrganizacija_Vprasanje1.SelLength := 0;
    izOrganizacija_Vprasanje1.SelStart := 111;
    izOrganizacija_Vprasanje1.ShowFocus := False;
    izOrganizacija_Vprasanje1.SpellCheck := False;
    izOrganizacija_Vprasanje1.WidthPercent := 100.000000000000000000;
    izOrganizacija_Vprasanje1.WordWrap := False;
    vpOrganizacija_Odgovor1.SetParentComponent(ploscicaTabelaOrganizacijskihVprasanj);
    vpOrganizacija_Odgovor1.Name := 'vpOrganizacija_Odgovor1';
    vpOrganizacija_Odgovor1.Left := 491;
    vpOrganizacija_Odgovor1.Top := 2;
    vpOrganizacija_Odgovor1.Width := 150;
    vpOrganizacija_Odgovor1.Height := 20;
    vpOrganizacija_Odgovor1.HeightPercent := 100.000000000000000000;
    vpOrganizacija_Odgovor1.WidthPercent := 100.000000000000000000;
    vpOrganizacija_Odgovor1.Anchors := [];
    vpOrganizacija_Odgovor1.ChildOrder := 2;
    vpOrganizacija_Odgovor1.Max := 48;
    vpOrganizacija_Odgovor1.Min := 0;
    vpOrganizacija_Odgovor1.Position := 2;
    vpOrganizacija_Odgovor1.Role := '';
    SetEvent(vpOrganizacija_Odgovor1, Self, 'OnChange', 'vpOrganizacija_OdgovorMontazaChange');
    izOrganizacija_Vprasanje2.SetParentComponent(ploscicaTabelaOrganizacijskihVprasanj);
    izOrganizacija_Vprasanje2.Name := 'izOrganizacija_Vprasanje2';
    izOrganizacija_Vprasanje2.Left := 24;
    izOrganizacija_Vprasanje2.Top := 44;
    izOrganizacija_Vprasanje2.Width := 403;
    izOrganizacija_Vprasanje2.Height := 28;
    izOrganizacija_Vprasanje2.Anchors := [];
    izOrganizacija_Vprasanje2.AutoSize := False;
    izOrganizacija_Vprasanje2.BorderStyle := bsNone;
    izOrganizacija_Vprasanje2.HeightPercent := 100.000000000000000000;
    izOrganizacija_Vprasanje2.Lines.BeginUpdate;
    try
      izOrganizacija_Vprasanje2.Lines.Clear;
      izOrganizacija_Vprasanje2.Lines.Add('Koliko '#269'asa obi'#269'ajno traja monta'#382'a vitalnih strojev oziroma linij (brez dobave),');
      izOrganizacija_Vprasanje2.Lines.Add('ki se uporabljajo v podjetju');
    finally
      izOrganizacija_Vprasanje2.Lines.EndUpdate;
    end;
    izOrganizacija_Vprasanje2.ParentColor := True;
    izOrganizacija_Vprasanje2.ReadOnly := True;
    izOrganizacija_Vprasanje2.SelLength := 0;
    izOrganizacija_Vprasanje2.SelStart := 112;
    izOrganizacija_Vprasanje2.ShowFocus := False;
    izOrganizacija_Vprasanje2.SpellCheck := False;
    izOrganizacija_Vprasanje2.WidthPercent := 100.000000000000000000;
    izOrganizacija_Vprasanje2.WordWrap := False;
    vpOrganizacija_Odgovor2.SetParentComponent(ploscicaTabelaOrganizacijskihVprasanj);
    vpOrganizacija_Odgovor2.Name := 'vpOrganizacija_Odgovor2';
    vpOrganizacija_Odgovor2.Left := 491;
    vpOrganizacija_Odgovor2.Top := 44;
    vpOrganizacija_Odgovor2.Width := 150;
    vpOrganizacija_Odgovor2.Height := 20;
    vpOrganizacija_Odgovor2.HeightPercent := 100.000000000000000000;
    vpOrganizacija_Odgovor2.WidthPercent := 100.000000000000000000;
    vpOrganizacija_Odgovor2.Anchors := [];
    vpOrganizacija_Odgovor2.ChildOrder := 6;
    vpOrganizacija_Odgovor2.Max := 24;
    vpOrganizacija_Odgovor2.Min := 0;
    vpOrganizacija_Odgovor2.Position := 2;
    vpOrganizacija_Odgovor2.Role := '';
    SetEvent(vpOrganizacija_Odgovor2, Self, 'OnChange', 'vpOrganizacija_OdgovorMontazaChange');
    izOrganizacija_Vprasanje3.SetParentComponent(ploscicaTabelaOrganizacijskihVprasanj);
    izOrganizacija_Vprasanje3.Name := 'izOrganizacija_Vprasanje3';
    izOrganizacija_Vprasanje3.Left := 24;
    izOrganizacija_Vprasanje3.Top := 86;
    izOrganizacija_Vprasanje3.Width := 403;
    izOrganizacija_Vprasanje3.Height := 28;
    izOrganizacija_Vprasanje3.Anchors := [];
    izOrganizacija_Vprasanje3.AutoSize := False;
    izOrganizacija_Vprasanje3.BorderStyle := bsNone;
    izOrganizacija_Vprasanje3.HeightPercent := 100.000000000000000000;
    izOrganizacija_Vprasanje3.Lines.BeginUpdate;
    try
      izOrganizacija_Vprasanje3.Lines.Clear;
      izOrganizacija_Vprasanje3.Lines.Add('Ali je mo'#382'no v primeru uni'#269'enja vitalnih strojv oziroma linij te nadomestiti');
      izOrganizacija_Vprasanje3.Lines.Add('z rabljenimi nadomestnimi stroji (tj. ali obstaja delujo'#269'i trg rabljenih strojev)?');
    finally
      izOrganizacija_Vprasanje3.Lines.EndUpdate;
    end;
    izOrganizacija_Vprasanje3.ParentColor := True;
    izOrganizacija_Vprasanje3.ReadOnly := True;
    izOrganizacija_Vprasanje3.SelLength := 0;
    izOrganizacija_Vprasanje3.SelStart := 162;
    izOrganizacija_Vprasanje3.ShowFocus := False;
    izOrganizacija_Vprasanje3.SpellCheck := False;
    izOrganizacija_Vprasanje3.WidthPercent := 100.000000000000000000;
    izOrganizacija_Vprasanje3.WordWrap := False;
    ploscicaOrganizacija_Odgovor3.SetParentComponent(ploscicaTabelaOrganizacijskihVprasanj);
    ploscicaOrganizacija_Odgovor3.Name := 'ploscicaOrganizacija_Odgovor3';
    ploscicaOrganizacija_Odgovor3.Left := 501;
    ploscicaOrganizacija_Odgovor3.Top := 86;
    ploscicaOrganizacija_Odgovor3.Width := 130;
    ploscicaOrganizacija_Odgovor3.Height := 24;
    ploscicaOrganizacija_Odgovor3.HeightPercent := 100.000000000000000000;
    ploscicaOrganizacija_Odgovor3.WidthPercent := 100.000000000000000000;
    ploscicaOrganizacija_Odgovor3.Anchors := [];
    ploscicaOrganizacija_Odgovor3.BorderColor := clNone;
    ploscicaOrganizacija_Odgovor3.BorderStyle := bsNone;
    ploscicaOrganizacija_Odgovor3.ChildOrder := 10;
    ploscicaOrganizacija_Odgovor3.Color := clWindow;
    vpOrganizacija_OdgovorDa3.SetParentComponent(ploscicaOrganizacija_Odgovor3);
    vpOrganizacija_OdgovorDa3.Name := 'vpOrganizacija_OdgovorDa3';
    vpOrganizacija_OdgovorDa3.Left := 1;
    vpOrganizacija_OdgovorDa3.Top := 0;
    vpOrganizacija_OdgovorDa3.Width := 60;
    vpOrganizacija_OdgovorDa3.Height := 22;
    vpOrganizacija_OdgovorDa3.Caption := 'Da';
    vpOrganizacija_OdgovorDa3.Checked := False;
    vpOrganizacija_OdgovorDa3.Color := clNone;
    vpOrganizacija_OdgovorDa3.HeightPercent := 100.000000000000000000;
    vpOrganizacija_OdgovorDa3.WidthPercent := 100.000000000000000000;
    SetEvent(vpOrganizacija_OdgovorDa3, Self, 'OnClick', 'vpOrganizacija_Odgovor3Click');
    vpOrganizacija_OdgovorNe3.SetParentComponent(ploscicaOrganizacija_Odgovor3);
    vpOrganizacija_OdgovorNe3.Name := 'vpOrganizacija_OdgovorNe3';
    vpOrganizacija_OdgovorNe3.Left := 78;
    vpOrganizacija_OdgovorNe3.Top := 0;
    vpOrganizacija_OdgovorNe3.Width := 55;
    vpOrganizacija_OdgovorNe3.Height := 22;
    vpOrganizacija_OdgovorNe3.Caption := 'Ne';
    vpOrganizacija_OdgovorNe3.Checked := False;
    vpOrganizacija_OdgovorNe3.ChildOrder := 1;
    vpOrganizacija_OdgovorNe3.Color := clNone;
    vpOrganizacija_OdgovorNe3.HeightPercent := 100.000000000000000000;
    vpOrganizacija_OdgovorNe3.WidthPercent := 100.000000000000000000;
    SetEvent(vpOrganizacija_OdgovorNe3, Self, 'OnClick', 'vpOrganizacija_Odgovor3Click');
    izOrganizacija_Vprasanje4.SetParentComponent(ploscicaTabelaOrganizacijskihVprasanj);
    izOrganizacija_Vprasanje4.Name := 'izOrganizacija_Vprasanje4';
    izOrganizacija_Vprasanje4.Left := 24;
    izOrganizacija_Vprasanje4.Top := 128;
    izOrganizacija_Vprasanje4.Width := 403;
    izOrganizacija_Vprasanje4.Height := 28;
    izOrganizacija_Vprasanje4.Anchors := [];
    izOrganizacija_Vprasanje4.AutoSize := False;
    izOrganizacija_Vprasanje4.BorderStyle := bsNone;
    izOrganizacija_Vprasanje4.HeightPercent := 100.000000000000000000;
    izOrganizacija_Vprasanje4.Lines.BeginUpdate;
    try
      izOrganizacija_Vprasanje4.Lines.Clear;
      izOrganizacija_Vprasanje4.Lines.Add('Ali je mo'#382'no del proizvodnje preseliti na drugo (lastno) lokacijo?');
      izOrganizacija_Vprasanje4.Lines.Add('');
    finally
      izOrganizacija_Vprasanje4.Lines.EndUpdate;
    end;
    izOrganizacija_Vprasanje4.ParentColor := True;
    izOrganizacija_Vprasanje4.ReadOnly := True;
    izOrganizacija_Vprasanje4.SelLength := 0;
    izOrganizacija_Vprasanje4.SelStart := 70;
    izOrganizacija_Vprasanje4.ShowFocus := False;
    izOrganizacija_Vprasanje4.SpellCheck := False;
    izOrganizacija_Vprasanje4.WidthPercent := 100.000000000000000000;
    izOrganizacija_Vprasanje4.WordWrap := False;
    ploscicaOrganizacija_Odgovor4.SetParentComponent(ploscicaTabelaOrganizacijskihVprasanj);
    ploscicaOrganizacija_Odgovor4.Name := 'ploscicaOrganizacija_Odgovor4';
    ploscicaOrganizacija_Odgovor4.Left := 501;
    ploscicaOrganizacija_Odgovor4.Top := 128;
    ploscicaOrganizacija_Odgovor4.Width := 130;
    ploscicaOrganizacija_Odgovor4.Height := 24;
    ploscicaOrganizacija_Odgovor4.HeightPercent := 100.000000000000000000;
    ploscicaOrganizacija_Odgovor4.WidthPercent := 100.000000000000000000;
    ploscicaOrganizacija_Odgovor4.Anchors := [];
    ploscicaOrganizacija_Odgovor4.BorderColor := clNone;
    ploscicaOrganizacija_Odgovor4.BorderStyle := bsNone;
    ploscicaOrganizacija_Odgovor4.ChildOrder := 14;
    ploscicaOrganizacija_Odgovor4.Color := clWindow;
    vpOrganizacija_OdgovorDa4.SetParentComponent(ploscicaOrganizacija_Odgovor4);
    vpOrganizacija_OdgovorDa4.Name := 'vpOrganizacija_OdgovorDa4';
    vpOrganizacija_OdgovorDa4.Left := 1;
    vpOrganizacija_OdgovorDa4.Top := 0;
    vpOrganizacija_OdgovorDa4.Width := 60;
    vpOrganizacija_OdgovorDa4.Height := 22;
    vpOrganizacija_OdgovorDa4.Caption := 'Da';
    vpOrganizacija_OdgovorDa4.Checked := False;
    vpOrganizacija_OdgovorDa4.Color := clNone;
    vpOrganizacija_OdgovorDa4.HeightPercent := 100.000000000000000000;
    vpOrganizacija_OdgovorDa4.WidthPercent := 100.000000000000000000;
    SetEvent(vpOrganizacija_OdgovorDa4, Self, 'OnClick', 'vpOrganizacija_Odgovor3Click');
    vpOrganizacija_OdgovorNe4.SetParentComponent(ploscicaOrganizacija_Odgovor4);
    vpOrganizacija_OdgovorNe4.Name := 'vpOrganizacija_OdgovorNe4';
    vpOrganizacija_OdgovorNe4.Left := 78;
    vpOrganizacija_OdgovorNe4.Top := 0;
    vpOrganizacija_OdgovorNe4.Width := 55;
    vpOrganizacija_OdgovorNe4.Height := 22;
    vpOrganizacija_OdgovorNe4.Caption := 'Ne';
    vpOrganizacija_OdgovorNe4.Checked := False;
    vpOrganizacija_OdgovorNe4.ChildOrder := 1;
    vpOrganizacija_OdgovorNe4.Color := clNone;
    vpOrganizacija_OdgovorNe4.HeightPercent := 100.000000000000000000;
    vpOrganizacija_OdgovorNe4.WidthPercent := 100.000000000000000000;
    SetEvent(vpOrganizacija_OdgovorNe4, Self, 'OnClick', 'vpOrganizacija_Odgovor3Click');
    izOrganizacija_Vprasanje5.SetParentComponent(ploscicaTabelaOrganizacijskihVprasanj);
    izOrganizacija_Vprasanje5.Name := 'izOrganizacija_Vprasanje5';
    izOrganizacija_Vprasanje5.Left := 24;
    izOrganizacija_Vprasanje5.Top := 170;
    izOrganizacija_Vprasanje5.Width := 403;
    izOrganizacija_Vprasanje5.Height := 28;
    izOrganizacija_Vprasanje5.Anchors := [];
    izOrganizacija_Vprasanje5.AutoSize := False;
    izOrganizacija_Vprasanje5.BorderStyle := bsNone;
    izOrganizacija_Vprasanje5.HeightPercent := 100.000000000000000000;
    izOrganizacija_Vprasanje5.Lines.BeginUpdate;
    try
      izOrganizacija_Vprasanje5.Lines.Clear;
      izOrganizacija_Vprasanje5.Lines.Add('Ali je mo'#382'no del proizvodnje preseliti na drugo (najeto) lokacijo?');
      izOrganizacija_Vprasanje5.Lines.Add('');
    finally
      izOrganizacija_Vprasanje5.Lines.EndUpdate;
    end;
    izOrganizacija_Vprasanje5.ParentColor := True;
    izOrganizacija_Vprasanje5.ReadOnly := True;
    izOrganizacija_Vprasanje5.SelLength := 0;
    izOrganizacija_Vprasanje5.SelStart := 70;
    izOrganizacija_Vprasanje5.ShowFocus := False;
    izOrganizacija_Vprasanje5.SpellCheck := False;
    izOrganizacija_Vprasanje5.WidthPercent := 100.000000000000000000;
    izOrganizacija_Vprasanje5.WordWrap := False;
    ploscicaOrganizacija_Odgovor5.SetParentComponent(ploscicaTabelaOrganizacijskihVprasanj);
    ploscicaOrganizacija_Odgovor5.Name := 'ploscicaOrganizacija_Odgovor5';
    ploscicaOrganizacija_Odgovor5.Left := 501;
    ploscicaOrganizacija_Odgovor5.Top := 170;
    ploscicaOrganizacija_Odgovor5.Width := 130;
    ploscicaOrganizacija_Odgovor5.Height := 24;
    ploscicaOrganizacija_Odgovor5.HeightPercent := 100.000000000000000000;
    ploscicaOrganizacija_Odgovor5.WidthPercent := 100.000000000000000000;
    ploscicaOrganizacija_Odgovor5.Anchors := [];
    ploscicaOrganizacija_Odgovor5.BorderColor := clNone;
    ploscicaOrganizacija_Odgovor5.BorderStyle := bsNone;
    ploscicaOrganizacija_Odgovor5.ChildOrder := 18;
    ploscicaOrganizacija_Odgovor5.Color := clWindow;
    vpOrganizacija_OdgovorDa5.SetParentComponent(ploscicaOrganizacija_Odgovor5);
    vpOrganizacija_OdgovorDa5.Name := 'vpOrganizacija_OdgovorDa5';
    vpOrganizacija_OdgovorDa5.Left := -3;
    vpOrganizacija_OdgovorDa5.Top := 0;
    vpOrganizacija_OdgovorDa5.Width := 60;
    vpOrganizacija_OdgovorDa5.Height := 22;
    vpOrganizacija_OdgovorDa5.Caption := 'Da';
    vpOrganizacija_OdgovorDa5.Checked := False;
    vpOrganizacija_OdgovorDa5.Color := clNone;
    vpOrganizacija_OdgovorDa5.HeightPercent := 100.000000000000000000;
    vpOrganizacija_OdgovorDa5.WidthPercent := 100.000000000000000000;
    SetEvent(vpOrganizacija_OdgovorDa5, Self, 'OnClick', 'vpOrganizacija_Odgovor3Click');
    vpOrganizacija_OdgovorNe5.SetParentComponent(ploscicaOrganizacija_Odgovor5);
    vpOrganizacija_OdgovorNe5.Name := 'vpOrganizacija_OdgovorNe5';
    vpOrganizacija_OdgovorNe5.Left := 78;
    vpOrganizacija_OdgovorNe5.Top := 0;
    vpOrganizacija_OdgovorNe5.Width := 55;
    vpOrganizacija_OdgovorNe5.Height := 22;
    vpOrganizacija_OdgovorNe5.Caption := 'Ne';
    vpOrganizacija_OdgovorNe5.Checked := False;
    vpOrganizacija_OdgovorNe5.ChildOrder := 1;
    vpOrganizacija_OdgovorNe5.Color := clNone;
    vpOrganizacija_OdgovorNe5.HeightPercent := 100.000000000000000000;
    vpOrganizacija_OdgovorNe5.WidthPercent := 100.000000000000000000;
    SetEvent(vpOrganizacija_OdgovorNe5, Self, 'OnClick', 'vpOrganizacija_Odgovor3Click');
    izOrganizacija_Vprasanje6.SetParentComponent(ploscicaTabelaOrganizacijskihVprasanj);
    izOrganizacija_Vprasanje6.Name := 'izOrganizacija_Vprasanje6';
    izOrganizacija_Vprasanje6.Left := 24;
    izOrganizacija_Vprasanje6.Top := 212;
    izOrganizacija_Vprasanje6.Width := 403;
    izOrganizacija_Vprasanje6.Height := 28;
    izOrganizacija_Vprasanje6.Anchors := [];
    izOrganizacija_Vprasanje6.AutoSize := False;
    izOrganizacija_Vprasanje6.BorderStyle := bsNone;
    izOrganizacija_Vprasanje6.HeightPercent := 100.000000000000000000;
    izOrganizacija_Vprasanje6.Lines.BeginUpdate;
    try
      izOrganizacija_Vprasanje6.Lines.Clear;
      izOrganizacija_Vprasanje6.Lines.Add('Ali je za proizvodnjo potrebno vzpostaviti posebne delovne pogoje (npr.');
      izOrganizacija_Vprasanje6.Lines.Add('delovno okolje brez prahu ali s to'#269'no dolo'#269'eno temperaturo ipd.)?');
    finally
      izOrganizacija_Vprasanje6.Lines.EndUpdate;
    end;
    izOrganizacija_Vprasanje6.ParentColor := True;
    izOrganizacija_Vprasanje6.ReadOnly := True;
    izOrganizacija_Vprasanje6.SelLength := 0;
    izOrganizacija_Vprasanje6.SelStart := 140;
    izOrganizacija_Vprasanje6.ShowFocus := False;
    izOrganizacija_Vprasanje6.SpellCheck := False;
    izOrganizacija_Vprasanje6.WidthPercent := 100.000000000000000000;
    izOrganizacija_Vprasanje6.WordWrap := False;
    ploscicaOrganizacija_Odgovor6.SetParentComponent(ploscicaTabelaOrganizacijskihVprasanj);
    ploscicaOrganizacija_Odgovor6.Name := 'ploscicaOrganizacija_Odgovor6';
    ploscicaOrganizacija_Odgovor6.Left := 501;
    ploscicaOrganizacija_Odgovor6.Top := 212;
    ploscicaOrganizacija_Odgovor6.Width := 130;
    ploscicaOrganizacija_Odgovor6.Height := 24;
    ploscicaOrganizacija_Odgovor6.HeightPercent := 100.000000000000000000;
    ploscicaOrganizacija_Odgovor6.WidthPercent := 100.000000000000000000;
    ploscicaOrganizacija_Odgovor6.Anchors := [];
    ploscicaOrganizacija_Odgovor6.BorderColor := clNone;
    ploscicaOrganizacija_Odgovor6.BorderStyle := bsNone;
    ploscicaOrganizacija_Odgovor6.ChildOrder := 22;
    ploscicaOrganizacija_Odgovor6.Color := clWindow;
    vpOrganizacija_OdgovorDa6.SetParentComponent(ploscicaOrganizacija_Odgovor6);
    vpOrganizacija_OdgovorDa6.Name := 'vpOrganizacija_OdgovorDa6';
    vpOrganizacija_OdgovorDa6.Left := 0;
    vpOrganizacija_OdgovorDa6.Top := 0;
    vpOrganizacija_OdgovorDa6.Width := 60;
    vpOrganizacija_OdgovorDa6.Height := 22;
    vpOrganizacija_OdgovorDa6.Caption := 'Da';
    vpOrganizacija_OdgovorDa6.Checked := False;
    vpOrganizacija_OdgovorDa6.Color := clNone;
    vpOrganizacija_OdgovorDa6.HeightPercent := 100.000000000000000000;
    vpOrganizacija_OdgovorDa6.WidthPercent := 100.000000000000000000;
    SetEvent(vpOrganizacija_OdgovorDa6, Self, 'OnClick', 'vpOrganizacija_Odgovor3Click');
    vpOrganizacija_OdgovorNe6.SetParentComponent(ploscicaOrganizacija_Odgovor6);
    vpOrganizacija_OdgovorNe6.Name := 'vpOrganizacija_OdgovorNe6';
    vpOrganizacija_OdgovorNe6.Left := 78;
    vpOrganizacija_OdgovorNe6.Top := 0;
    vpOrganizacija_OdgovorNe6.Width := 55;
    vpOrganizacija_OdgovorNe6.Height := 22;
    vpOrganizacija_OdgovorNe6.Caption := 'Ne';
    vpOrganizacija_OdgovorNe6.Checked := False;
    vpOrganizacija_OdgovorNe6.ChildOrder := 1;
    vpOrganizacija_OdgovorNe6.Color := clNone;
    vpOrganizacija_OdgovorNe6.HeightPercent := 100.000000000000000000;
    vpOrganizacija_OdgovorNe6.WidthPercent := 100.000000000000000000;
    SetEvent(vpOrganizacija_OdgovorNe6, Self, 'OnClick', 'vpOrganizacija_Odgovor3Click');
    izOrganizacija_Vprasanje7.SetParentComponent(ploscicaTabelaOrganizacijskihVprasanj);
    izOrganizacija_Vprasanje7.Name := 'izOrganizacija_Vprasanje7';
    izOrganizacija_Vprasanje7.Left := 24;
    izOrganizacija_Vprasanje7.Top := 254;
    izOrganizacija_Vprasanje7.Width := 430;
    izOrganizacija_Vprasanje7.Height := 28;
    izOrganizacija_Vprasanje7.Anchors := [];
    izOrganizacija_Vprasanje7.AutoSize := False;
    izOrganizacija_Vprasanje7.BorderStyle := bsNone;
    izOrganizacija_Vprasanje7.HeightPercent := 100.000000000000000000;
    izOrganizacija_Vprasanje7.Lines.BeginUpdate;
    try
      izOrganizacija_Vprasanje7.Lines.Clear;
      izOrganizacija_Vprasanje7.Lines.Add('Ali se pri proizvodnem procesu uporabljajo snovi, ki lahko vplivajo na okolje');
      izOrganizacija_Vprasanje7.Lines.Add('(tudi '#269'e to velja zgolj za izredne razmere kot je na primer obsezen po'#382'ar ipd.)?');
    finally
      izOrganizacija_Vprasanje7.Lines.EndUpdate;
    end;
    izOrganizacija_Vprasanje7.ParentColor := True;
    izOrganizacija_Vprasanje7.ReadOnly := True;
    izOrganizacija_Vprasanje7.SelLength := 0;
    izOrganizacija_Vprasanje7.SelStart := 161;
    izOrganizacija_Vprasanje7.ShowFocus := False;
    izOrganizacija_Vprasanje7.SpellCheck := False;
    izOrganizacija_Vprasanje7.WidthPercent := 100.000000000000000000;
    izOrganizacija_Vprasanje7.WordWrap := False;
    ploscicaOrganizacija_Odgovor7.SetParentComponent(ploscicaTabelaOrganizacijskihVprasanj);
    ploscicaOrganizacija_Odgovor7.Name := 'ploscicaOrganizacija_Odgovor7';
    ploscicaOrganizacija_Odgovor7.Left := 501;
    ploscicaOrganizacija_Odgovor7.Top := 254;
    ploscicaOrganizacija_Odgovor7.Width := 130;
    ploscicaOrganizacija_Odgovor7.Height := 24;
    ploscicaOrganizacija_Odgovor7.HeightPercent := 100.000000000000000000;
    ploscicaOrganizacija_Odgovor7.WidthPercent := 100.000000000000000000;
    ploscicaOrganizacija_Odgovor7.Anchors := [];
    ploscicaOrganizacija_Odgovor7.BorderColor := clNone;
    ploscicaOrganizacija_Odgovor7.BorderStyle := bsNone;
    ploscicaOrganizacija_Odgovor7.ChildOrder := 26;
    ploscicaOrganizacija_Odgovor7.Color := clWindow;
    vpOrganizacija_OdgovorDa7.SetParentComponent(ploscicaOrganizacija_Odgovor7);
    vpOrganizacija_OdgovorDa7.Name := 'vpOrganizacija_OdgovorDa7';
    vpOrganizacija_OdgovorDa7.Left := 0;
    vpOrganizacija_OdgovorDa7.Top := 0;
    vpOrganizacija_OdgovorDa7.Width := 60;
    vpOrganizacija_OdgovorDa7.Height := 22;
    vpOrganizacija_OdgovorDa7.Caption := 'Da';
    vpOrganizacija_OdgovorDa7.Checked := False;
    vpOrganizacija_OdgovorDa7.Color := clNone;
    vpOrganizacija_OdgovorDa7.HeightPercent := 100.000000000000000000;
    vpOrganizacija_OdgovorDa7.WidthPercent := 100.000000000000000000;
    SetEvent(vpOrganizacija_OdgovorDa7, Self, 'OnClick', 'vpOrganizacija_Odgovor3Click');
    vpOrganizacija_OdgovorNe7.SetParentComponent(ploscicaOrganizacija_Odgovor7);
    vpOrganizacija_OdgovorNe7.Name := 'vpOrganizacija_OdgovorNe7';
    vpOrganizacija_OdgovorNe7.Left := 78;
    vpOrganizacija_OdgovorNe7.Top := 0;
    vpOrganizacija_OdgovorNe7.Width := 55;
    vpOrganizacija_OdgovorNe7.Height := 22;
    vpOrganizacija_OdgovorNe7.Caption := 'Ne';
    vpOrganizacija_OdgovorNe7.Checked := False;
    vpOrganizacija_OdgovorNe7.ChildOrder := 1;
    vpOrganizacija_OdgovorNe7.Color := clNone;
    vpOrganizacija_OdgovorNe7.HeightPercent := 100.000000000000000000;
    vpOrganizacija_OdgovorNe7.WidthPercent := 100.000000000000000000;
    SetEvent(vpOrganizacija_OdgovorNe7, Self, 'OnClick', 'vpOrganizacija_Odgovor3Click');
    izOrganizacija_Vprasanje8.SetParentComponent(ploscicaTabelaOrganizacijskihVprasanj);
    izOrganizacija_Vprasanje8.Name := 'izOrganizacija_Vprasanje8';
    izOrganizacija_Vprasanje8.Left := 24;
    izOrganizacija_Vprasanje8.Top := 296;
    izOrganizacija_Vprasanje8.Width := 403;
    izOrganizacija_Vprasanje8.Height := 28;
    izOrganizacija_Vprasanje8.Anchors := [];
    izOrganizacija_Vprasanje8.AutoSize := False;
    izOrganizacija_Vprasanje8.BorderStyle := bsNone;
    izOrganizacija_Vprasanje8.HeightPercent := 100.000000000000000000;
    izOrganizacija_Vprasanje8.Lines.BeginUpdate;
    try
      izOrganizacija_Vprasanje8.Lines.Clear;
      izOrganizacija_Vprasanje8.Lines.Add('Ali se v objektih / opremi vgrajene snovi, ki lahko  v izrednih razmerah (kot je');
      izOrganizacija_Vprasanje8.Lines.Add('na primer po'#382'ar) vplivajo na okolje?');
    finally
      izOrganizacija_Vprasanje8.Lines.EndUpdate;
    end;
    izOrganizacija_Vprasanje8.ParentColor := True;
    izOrganizacija_Vprasanje8.ReadOnly := True;
    izOrganizacija_Vprasanje8.SelLength := 0;
    izOrganizacija_Vprasanje8.SelStart := 120;
    izOrganizacija_Vprasanje8.ShowFocus := False;
    izOrganizacija_Vprasanje8.SpellCheck := False;
    izOrganizacija_Vprasanje8.WidthPercent := 100.000000000000000000;
    izOrganizacija_Vprasanje8.WordWrap := False;
    ploscicaOrganizacija_Odgovor8.SetParentComponent(ploscicaTabelaOrganizacijskihVprasanj);
    ploscicaOrganizacija_Odgovor8.Name := 'ploscicaOrganizacija_Odgovor8';
    ploscicaOrganizacija_Odgovor8.Left := 501;
    ploscicaOrganizacija_Odgovor8.Top := 296;
    ploscicaOrganizacija_Odgovor8.Width := 130;
    ploscicaOrganizacija_Odgovor8.Height := 24;
    ploscicaOrganizacija_Odgovor8.HeightPercent := 100.000000000000000000;
    ploscicaOrganizacija_Odgovor8.WidthPercent := 100.000000000000000000;
    ploscicaOrganizacija_Odgovor8.Anchors := [];
    ploscicaOrganizacija_Odgovor8.BorderColor := clNone;
    ploscicaOrganizacija_Odgovor8.BorderStyle := bsNone;
    ploscicaOrganizacija_Odgovor8.ChildOrder := 30;
    ploscicaOrganizacija_Odgovor8.Color := clWindow;
    vpOrganizacija_OdgovorDa8.SetParentComponent(ploscicaOrganizacija_Odgovor8);
    vpOrganizacija_OdgovorDa8.Name := 'vpOrganizacija_OdgovorDa8';
    vpOrganizacija_OdgovorDa8.Left := 0;
    vpOrganizacija_OdgovorDa8.Top := 0;
    vpOrganizacija_OdgovorDa8.Width := 60;
    vpOrganizacija_OdgovorDa8.Height := 24;
    vpOrganizacija_OdgovorDa8.Align := alLeft;
    vpOrganizacija_OdgovorDa8.Caption := 'Da';
    vpOrganizacija_OdgovorDa8.Checked := False;
    vpOrganizacija_OdgovorDa8.Color := clNone;
    vpOrganizacija_OdgovorDa8.HeightPercent := 100.000000000000000000;
    vpOrganizacija_OdgovorDa8.WidthPercent := 100.000000000000000000;
    SetEvent(vpOrganizacija_OdgovorDa8, Self, 'OnClick', 'vpOrganizacija_Odgovor3Click');
    vpOrganizacija_OdgovorNe8.SetParentComponent(ploscicaOrganizacija_Odgovor8);
    vpOrganizacija_OdgovorNe8.Name := 'vpOrganizacija_OdgovorNe8';
    vpOrganizacija_OdgovorNe8.Left := 75;
    vpOrganizacija_OdgovorNe8.Top := 0;
    vpOrganizacija_OdgovorNe8.Width := 55;
    vpOrganizacija_OdgovorNe8.Height := 24;
    vpOrganizacija_OdgovorNe8.Align := alRight;
    vpOrganizacija_OdgovorNe8.Caption := 'Ne';
    vpOrganizacija_OdgovorNe8.Checked := False;
    vpOrganizacija_OdgovorNe8.ChildOrder := 1;
    vpOrganizacija_OdgovorNe8.Color := clNone;
    vpOrganizacija_OdgovorNe8.HeightPercent := 100.000000000000000000;
    vpOrganizacija_OdgovorNe8.WidthPercent := 100.000000000000000000;
    SetEvent(vpOrganizacija_OdgovorNe8, Self, 'OnClick', 'vpOrganizacija_Odgovor3Click');
    izOrganizacija_Vprasanje9.SetParentComponent(ploscicaTabelaOrganizacijskihVprasanj);
    izOrganizacija_Vprasanje9.Name := 'izOrganizacija_Vprasanje9';
    izOrganizacija_Vprasanje9.Left := 24;
    izOrganizacija_Vprasanje9.Top := 338;
    izOrganizacija_Vprasanje9.Width := 403;
    izOrganizacija_Vprasanje9.Height := 28;
    izOrganizacija_Vprasanje9.Anchors := [];
    izOrganizacija_Vprasanje9.AutoSize := False;
    izOrganizacija_Vprasanje9.BorderStyle := bsNone;
    izOrganizacija_Vprasanje9.HeightPercent := 100.000000000000000000;
    izOrganizacija_Vprasanje9.Lines.BeginUpdate;
    try
      izOrganizacija_Vprasanje9.Lines.Clear;
      izOrganizacija_Vprasanje9.Lines.Add('V koliko izmenah poteka delo?');
      izOrganizacija_Vprasanje9.Lines.Add('');
    finally
      izOrganizacija_Vprasanje9.Lines.EndUpdate;
    end;
    izOrganizacija_Vprasanje9.ParentColor := True;
    izOrganizacija_Vprasanje9.ReadOnly := True;
    izOrganizacija_Vprasanje9.SelLength := 0;
    izOrganizacija_Vprasanje9.SelStart := 33;
    izOrganizacija_Vprasanje9.ShowFocus := False;
    izOrganizacija_Vprasanje9.SpellCheck := False;
    izOrganizacija_Vprasanje9.WidthPercent := 100.000000000000000000;
    izOrganizacija_Vprasanje9.WordWrap := False;
    vpOrganizacija_Odgovor9.SetParentComponent(ploscicaTabelaOrganizacijskihVprasanj);
    vpOrganizacija_Odgovor9.Name := 'vpOrganizacija_Odgovor9';
    vpOrganizacija_Odgovor9.Left := 491;
    vpOrganizacija_Odgovor9.Top := 338;
    vpOrganizacija_Odgovor9.Width := 150;
    vpOrganizacija_Odgovor9.Height := 20;
    vpOrganizacija_Odgovor9.HeightPercent := 100.000000000000000000;
    vpOrganizacija_Odgovor9.WidthPercent := 100.000000000000000000;
    vpOrganizacija_Odgovor9.Anchors := [];
    vpOrganizacija_Odgovor9.ChildOrder := 34;
    vpOrganizacija_Odgovor9.Max := 3;
    vpOrganizacija_Odgovor9.Min := 1;
    vpOrganizacija_Odgovor9.Position := 1;
    vpOrganizacija_Odgovor9.Role := '';
    SetEvent(vpOrganizacija_Odgovor9, Self, 'OnChange', 'vpOrganizacija_Odgovor9Change');
    izOrganizacija_Vprasanje10.SetParentComponent(ploscicaTabelaOrganizacijskihVprasanj);
    izOrganizacija_Vprasanje10.Name := 'izOrganizacija_Vprasanje10';
    izOrganizacija_Vprasanje10.Left := 24;
    izOrganizacija_Vprasanje10.Top := 380;
    izOrganizacija_Vprasanje10.Width := 403;
    izOrganizacija_Vprasanje10.Height := 28;
    izOrganizacija_Vprasanje10.Anchors := [];
    izOrganizacija_Vprasanje10.AutoSize := False;
    izOrganizacija_Vprasanje10.BorderStyle := bsNone;
    izOrganizacija_Vprasanje10.HeightPercent := 100.000000000000000000;
    izOrganizacija_Vprasanje10.Lines.BeginUpdate;
    try
      izOrganizacija_Vprasanje10.Lines.Clear;
      izOrganizacija_Vprasanje10.Lines.Add('Kak'#353'na je povpre'#269'na zasedenost proizvodnje (znotraj izmen, v katerih se dela)?');
      izOrganizacija_Vprasanje10.Lines.Add('');
    finally
      izOrganizacija_Vprasanje10.Lines.EndUpdate;
    end;
    izOrganizacija_Vprasanje10.ParentColor := True;
    izOrganizacija_Vprasanje10.ReadOnly := True;
    izOrganizacija_Vprasanje10.SelLength := 0;
    izOrganizacija_Vprasanje10.SelStart := 82;
    izOrganizacija_Vprasanje10.ShowFocus := False;
    izOrganizacija_Vprasanje10.SpellCheck := False;
    izOrganizacija_Vprasanje10.WidthPercent := 100.000000000000000000;
    izOrganizacija_Vprasanje10.WordWrap := False;
    vpOrganizacija_Odgovor10.SetParentComponent(ploscicaTabelaOrganizacijskihVprasanj);
    vpOrganizacija_Odgovor10.Name := 'vpOrganizacija_Odgovor10';
    vpOrganizacija_Odgovor10.Left := 491;
    vpOrganizacija_Odgovor10.Top := 380;
    vpOrganizacija_Odgovor10.Width := 150;
    vpOrganizacija_Odgovor10.Height := 20;
    vpOrganizacija_Odgovor10.HeightPercent := 100.000000000000000000;
    vpOrganizacija_Odgovor10.WidthPercent := 100.000000000000000000;
    vpOrganizacija_Odgovor10.Anchors := [];
    vpOrganizacija_Odgovor10.ChildOrder := 38;
    vpOrganizacija_Odgovor10.Max := 100;
    vpOrganizacija_Odgovor10.Min := 0;
    vpOrganizacija_Odgovor10.Position := 100;
    vpOrganizacija_Odgovor10.Role := '';
    SetEvent(vpOrganizacija_Odgovor10, Self, 'OnChange', 'vpOrganizacija_Odgovor10Change');
    izOrganizacija_Vprasanje11.SetParentComponent(ploscicaTabelaOrganizacijskihVprasanj);
    izOrganizacija_Vprasanje11.Name := 'izOrganizacija_Vprasanje11';
    izOrganizacija_Vprasanje11.Left := 24;
    izOrganizacija_Vprasanje11.Top := 422;
    izOrganizacija_Vprasanje11.Width := 403;
    izOrganizacija_Vprasanje11.Height := 28;
    izOrganizacija_Vprasanje11.Anchors := [];
    izOrganizacija_Vprasanje11.AutoSize := False;
    izOrganizacija_Vprasanje11.BorderStyle := bsNone;
    izOrganizacija_Vprasanje11.HeightPercent := 100.000000000000000000;
    izOrganizacija_Vprasanje11.Lines.BeginUpdate;
    try
      izOrganizacija_Vprasanje11.Lines.Clear;
      izOrganizacija_Vprasanje11.Lines.Add('Ali obstaja lo'#269'eno skladi'#353#269'e kon'#269'nih izdelkov (tj. skladi'#353#269'e, ki ni v objektu ali');
      izOrganizacija_Vprasanje11.Lines.Add('nepsoredni bli'#382'ini proizvodnega objekta?');
    finally
      izOrganizacija_Vprasanje11.Lines.EndUpdate;
    end;
    izOrganizacija_Vprasanje11.ParentColor := True;
    izOrganizacija_Vprasanje11.ReadOnly := True;
    izOrganizacija_Vprasanje11.SelLength := 0;
    izOrganizacija_Vprasanje11.SelStart := 125;
    izOrganizacija_Vprasanje11.ShowFocus := False;
    izOrganizacija_Vprasanje11.SpellCheck := False;
    izOrganizacija_Vprasanje11.WidthPercent := 100.000000000000000000;
    izOrganizacija_Vprasanje11.WordWrap := False;
    izOrganizacija_Vprasanje11_1.SetParentComponent(ploscicaTabelaOrganizacijskihVprasanj);
    izOrganizacija_Vprasanje11_1.Name := 'izOrganizacija_Vprasanje11_1';
    izOrganizacija_Vprasanje11_1.Left := 24;
    izOrganizacija_Vprasanje11_1.Top := 464;
    izOrganizacija_Vprasanje11_1.Width := 403;
    izOrganizacija_Vprasanje11_1.Height := 28;
    izOrganizacija_Vprasanje11_1.Anchors := [];
    izOrganizacija_Vprasanje11_1.AutoSize := False;
    izOrganizacija_Vprasanje11_1.BorderStyle := bsNone;
    izOrganizacija_Vprasanje11_1.HeightPercent := 100.000000000000000000;
    izOrganizacija_Vprasanje11_1.Lines.BeginUpdate;
    try
      izOrganizacija_Vprasanje11_1.Lines.Clear;
      izOrganizacija_Vprasanje11_1.Lines.Add('- lo'#269'eno skladi'#353#269'e oddaljeno vsaj 10 m od proizvodnih objektov');
    finally
      izOrganizacija_Vprasanje11_1.Lines.EndUpdate;
    end;
    izOrganizacija_Vprasanje11_1.ParentColor := True;
    izOrganizacija_Vprasanje11_1.ReadOnly := True;
    izOrganizacija_Vprasanje11_1.SelLength := 0;
    izOrganizacija_Vprasanje11_1.SelStart := 64;
    izOrganizacija_Vprasanje11_1.ShowFocus := False;
    izOrganizacija_Vprasanje11_1.SpellCheck := False;
    izOrganizacija_Vprasanje11_1.WidthPercent := 100.000000000000000000;
    izOrganizacija_Vprasanje11_1.WordWrap := False;
    ploscicaOrganizacija_Odgovor11_1.SetParentComponent(ploscicaTabelaOrganizacijskihVprasanj);
    ploscicaOrganizacija_Odgovor11_1.Name := 'ploscicaOrganizacija_Odgovor11_1';
    ploscicaOrganizacija_Odgovor11_1.Left := 501;
    ploscicaOrganizacija_Odgovor11_1.Top := 464;
    ploscicaOrganizacija_Odgovor11_1.Width := 130;
    ploscicaOrganizacija_Odgovor11_1.Height := 24;
    ploscicaOrganizacija_Odgovor11_1.HeightPercent := 100.000000000000000000;
    ploscicaOrganizacija_Odgovor11_1.WidthPercent := 100.000000000000000000;
    ploscicaOrganizacija_Odgovor11_1.Anchors := [];
    ploscicaOrganizacija_Odgovor11_1.BorderColor := clNone;
    ploscicaOrganizacija_Odgovor11_1.BorderStyle := bsNone;
    ploscicaOrganizacija_Odgovor11_1.ChildOrder := 46;
    ploscicaOrganizacija_Odgovor11_1.Color := clWindow;
    vpOrganizacija_OdgovorDa11_1.SetParentComponent(ploscicaOrganizacija_Odgovor11_1);
    vpOrganizacija_OdgovorDa11_1.Name := 'vpOrganizacija_OdgovorDa11_1';
    vpOrganizacija_OdgovorDa11_1.Left := 0;
    vpOrganizacija_OdgovorDa11_1.Top := 0;
    vpOrganizacija_OdgovorDa11_1.Width := 60;
    vpOrganizacija_OdgovorDa11_1.Height := 22;
    vpOrganizacija_OdgovorDa11_1.Caption := 'Da';
    vpOrganizacija_OdgovorDa11_1.Checked := False;
    vpOrganizacija_OdgovorDa11_1.Color := clNone;
    vpOrganizacija_OdgovorDa11_1.HeightPercent := 100.000000000000000000;
    vpOrganizacija_OdgovorDa11_1.WidthPercent := 100.000000000000000000;
    SetEvent(vpOrganizacija_OdgovorDa11_1, Self, 'OnClick', 'vpOrganizacija_Odgovor11IzborClick');
    vpOrganizacija_OdgovorNe11_1.SetParentComponent(ploscicaOrganizacija_Odgovor11_1);
    vpOrganizacija_OdgovorNe11_1.Name := 'vpOrganizacija_OdgovorNe11_1';
    vpOrganizacija_OdgovorNe11_1.Left := 78;
    vpOrganizacija_OdgovorNe11_1.Top := 0;
    vpOrganizacija_OdgovorNe11_1.Width := 55;
    vpOrganizacija_OdgovorNe11_1.Height := 22;
    vpOrganizacija_OdgovorNe11_1.Caption := 'Ne';
    vpOrganizacija_OdgovorNe11_1.Checked := False;
    vpOrganizacija_OdgovorNe11_1.ChildOrder := 1;
    vpOrganizacija_OdgovorNe11_1.Color := clNone;
    vpOrganizacija_OdgovorNe11_1.HeightPercent := 100.000000000000000000;
    vpOrganizacija_OdgovorNe11_1.WidthPercent := 100.000000000000000000;
    SetEvent(vpOrganizacija_OdgovorNe11_1, Self, 'OnClick', 'vpOrganizacija_Odgovor11IzborClick');
    izOrganizacija_Vprasanje11_2.SetParentComponent(ploscicaTabelaOrganizacijskihVprasanj);
    izOrganizacija_Vprasanje11_2.Name := 'izOrganizacija_Vprasanje11_2';
    izOrganizacija_Vprasanje11_2.Left := 24;
    izOrganizacija_Vprasanje11_2.Top := 506;
    izOrganizacija_Vprasanje11_2.Width := 403;
    izOrganizacija_Vprasanje11_2.Height := 28;
    izOrganizacija_Vprasanje11_2.Anchors := [];
    izOrganizacija_Vprasanje11_2.AutoSize := False;
    izOrganizacija_Vprasanje11_2.BorderStyle := bsNone;
    izOrganizacija_Vprasanje11_2.HeightPercent := 100.000000000000000000;
    izOrganizacija_Vprasanje11_2.Lines.BeginUpdate;
    try
      izOrganizacija_Vprasanje11_2.Lines.Clear;
      izOrganizacija_Vprasanje11_2.Lines.Add('........ dele'#382' prodajnega asortimaja, ki se nahaja v skladi'#353#269'u:');
    finally
      izOrganizacija_Vprasanje11_2.Lines.EndUpdate;
    end;
    izOrganizacija_Vprasanje11_2.ParentColor := True;
    izOrganizacija_Vprasanje11_2.ReadOnly := True;
    izOrganizacija_Vprasanje11_2.SelLength := 0;
    izOrganizacija_Vprasanje11_2.SelStart := 65;
    izOrganizacija_Vprasanje11_2.ShowFocus := False;
    izOrganizacija_Vprasanje11_2.SpellCheck := False;
    izOrganizacija_Vprasanje11_2.WidthPercent := 100.000000000000000000;
    izOrganizacija_Vprasanje11_2.WordWrap := False;
    vpOrganizacija_Odgovor11_2.SetParentComponent(ploscicaTabelaOrganizacijskihVprasanj);
    vpOrganizacija_Odgovor11_2.Name := 'vpOrganizacija_Odgovor11_2';
    vpOrganizacija_Odgovor11_2.Left := 491;
    vpOrganizacija_Odgovor11_2.Top := 506;
    vpOrganizacija_Odgovor11_2.Width := 150;
    vpOrganizacija_Odgovor11_2.Height := 20;
    vpOrganizacija_Odgovor11_2.HeightPercent := 100.000000000000000000;
    vpOrganizacija_Odgovor11_2.WidthPercent := 100.000000000000000000;
    vpOrganizacija_Odgovor11_2.Anchors := [];
    vpOrganizacija_Odgovor11_2.ChildOrder := 50;
    vpOrganizacija_Odgovor11_2.Max := 100;
    vpOrganizacija_Odgovor11_2.Min := 0;
    vpOrganizacija_Odgovor11_2.Position := 100;
    vpOrganizacija_Odgovor11_2.Role := '';
    SetEvent(vpOrganizacija_Odgovor11_2, Self, 'OnChange', 'vpOrganizacija_Odgovor11DelezZalogChange');
    izOrganizacija_Vprasanje11_3.SetParentComponent(ploscicaTabelaOrganizacijskihVprasanj);
    izOrganizacija_Vprasanje11_3.Name := 'izOrganizacija_Vprasanje11_3';
    izOrganizacija_Vprasanje11_3.Left := 24;
    izOrganizacija_Vprasanje11_3.Top := 548;
    izOrganizacija_Vprasanje11_3.Width := 403;
    izOrganizacija_Vprasanje11_3.Height := 28;
    izOrganizacija_Vprasanje11_3.Anchors := [];
    izOrganizacija_Vprasanje11_3.AutoSize := False;
    izOrganizacija_Vprasanje11_3.BorderStyle := bsNone;
    izOrganizacija_Vprasanje11_3.HeightPercent := 100.000000000000000000;
    izOrganizacija_Vprasanje11_3.Lines.BeginUpdate;
    try
      izOrganizacija_Vprasanje11_3.Lines.Clear;
      izOrganizacija_Vprasanje11_3.Lines.Add('........ zaloge v takem skladi'#353#269'u omogo'#269'ajo prodajo za obdobje:');
    finally
      izOrganizacija_Vprasanje11_3.Lines.EndUpdate;
    end;
    izOrganizacija_Vprasanje11_3.ParentColor := True;
    izOrganizacija_Vprasanje11_3.ReadOnly := True;
    izOrganizacija_Vprasanje11_3.SelLength := 0;
    izOrganizacija_Vprasanje11_3.SelStart := 65;
    izOrganizacija_Vprasanje11_3.ShowFocus := False;
    izOrganizacija_Vprasanje11_3.SpellCheck := False;
    izOrganizacija_Vprasanje11_3.WidthPercent := 100.000000000000000000;
    izOrganizacija_Vprasanje11_3.WordWrap := False;
    vpOrganizacija_Odgovor11_3.SetParentComponent(ploscicaTabelaOrganizacijskihVprasanj);
    vpOrganizacija_Odgovor11_3.Name := 'vpOrganizacija_Odgovor11_3';
    vpOrganizacija_Odgovor11_3.Left := 491;
    vpOrganizacija_Odgovor11_3.Top := 548;
    vpOrganizacija_Odgovor11_3.Width := 150;
    vpOrganizacija_Odgovor11_3.Height := 20;
    vpOrganizacija_Odgovor11_3.HeightPercent := 100.000000000000000000;
    vpOrganizacija_Odgovor11_3.WidthPercent := 100.000000000000000000;
    vpOrganizacija_Odgovor11_3.Anchors := [];
    vpOrganizacija_Odgovor11_3.ChildOrder := 54;
    vpOrganizacija_Odgovor11_3.Max := 24;
    vpOrganizacija_Odgovor11_3.Min := 0;
    vpOrganizacija_Odgovor11_3.Position := 4;
    vpOrganizacija_Odgovor11_3.Role := '';
    SetEvent(vpOrganizacija_Odgovor11_3, Self, 'OnChange', 'vpOrganizacija_Odgovor11KolicinaZalogChange');
    izOrganizacija_Vprasanje11_4.SetParentComponent(ploscicaTabelaOrganizacijskihVprasanj);
    izOrganizacija_Vprasanje11_4.Name := 'izOrganizacija_Vprasanje11_4';
    izOrganizacija_Vprasanje11_4.Left := 24;
    izOrganizacija_Vprasanje11_4.Top := 590;
    izOrganizacija_Vprasanje11_4.Width := 403;
    izOrganizacija_Vprasanje11_4.Height := 28;
    izOrganizacija_Vprasanje11_4.Anchors := [];
    izOrganizacija_Vprasanje11_4.AutoSize := False;
    izOrganizacija_Vprasanje11_4.BorderStyle := bsNone;
    izOrganizacija_Vprasanje11_4.HeightPercent := 100.000000000000000000;
    izOrganizacija_Vprasanje11_4.Lines.BeginUpdate;
    try
      izOrganizacija_Vprasanje11_4.Lines.Clear;
      izOrganizacija_Vprasanje11_4.Lines.Add('- lo'#269'eno skladi'#353#269'e oddaljeno manj kot 10 m od proizvodnih objektov');
    finally
      izOrganizacija_Vprasanje11_4.Lines.EndUpdate;
    end;
    izOrganizacija_Vprasanje11_4.ParentColor := True;
    izOrganizacija_Vprasanje11_4.ReadOnly := True;
    izOrganizacija_Vprasanje11_4.SelLength := 0;
    izOrganizacija_Vprasanje11_4.SelStart := 68;
    izOrganizacija_Vprasanje11_4.ShowFocus := False;
    izOrganizacija_Vprasanje11_4.SpellCheck := False;
    izOrganizacija_Vprasanje11_4.WidthPercent := 100.000000000000000000;
    izOrganizacija_Vprasanje11_4.WordWrap := False;
    ploscicaOrganizacija_Odgovor11_4.SetParentComponent(ploscicaTabelaOrganizacijskihVprasanj);
    ploscicaOrganizacija_Odgovor11_4.Name := 'ploscicaOrganizacija_Odgovor11_4';
    ploscicaOrganizacija_Odgovor11_4.Left := 501;
    ploscicaOrganizacija_Odgovor11_4.Top := 590;
    ploscicaOrganizacija_Odgovor11_4.Width := 130;
    ploscicaOrganizacija_Odgovor11_4.Height := 24;
    ploscicaOrganizacija_Odgovor11_4.HeightPercent := 100.000000000000000000;
    ploscicaOrganizacija_Odgovor11_4.WidthPercent := 100.000000000000000000;
    ploscicaOrganizacija_Odgovor11_4.Anchors := [];
    ploscicaOrganizacija_Odgovor11_4.BorderColor := clNone;
    ploscicaOrganizacija_Odgovor11_4.BorderStyle := bsNone;
    ploscicaOrganizacija_Odgovor11_4.ChildOrder := 58;
    ploscicaOrganizacija_Odgovor11_4.Color := clWindow;
    vpOrganizacija_OdgovorDa11_4.SetParentComponent(ploscicaOrganizacija_Odgovor11_4);
    vpOrganizacija_OdgovorDa11_4.Name := 'vpOrganizacija_OdgovorDa11_4';
    vpOrganizacija_OdgovorDa11_4.Left := 0;
    vpOrganizacija_OdgovorDa11_4.Top := 0;
    vpOrganizacija_OdgovorDa11_4.Width := 60;
    vpOrganizacija_OdgovorDa11_4.Height := 22;
    vpOrganizacija_OdgovorDa11_4.Caption := 'Da';
    vpOrganizacija_OdgovorDa11_4.Checked := False;
    vpOrganizacija_OdgovorDa11_4.Color := clNone;
    vpOrganizacija_OdgovorDa11_4.HeightPercent := 100.000000000000000000;
    vpOrganizacija_OdgovorDa11_4.WidthPercent := 100.000000000000000000;
    SetEvent(vpOrganizacija_OdgovorDa11_4, Self, 'OnClick', 'vpOrganizacija_Odgovor11IzborClick');
    vpOrganizacija_OdgovorNe11_4.SetParentComponent(ploscicaOrganizacija_Odgovor11_4);
    vpOrganizacija_OdgovorNe11_4.Name := 'vpOrganizacija_OdgovorNe11_4';
    vpOrganizacija_OdgovorNe11_4.Left := 78;
    vpOrganizacija_OdgovorNe11_4.Top := 0;
    vpOrganizacija_OdgovorNe11_4.Width := 55;
    vpOrganizacija_OdgovorNe11_4.Height := 22;
    vpOrganizacija_OdgovorNe11_4.Caption := 'Ne';
    vpOrganizacija_OdgovorNe11_4.Checked := False;
    vpOrganizacija_OdgovorNe11_4.ChildOrder := 1;
    vpOrganizacija_OdgovorNe11_4.Color := clNone;
    vpOrganizacija_OdgovorNe11_4.HeightPercent := 100.000000000000000000;
    vpOrganizacija_OdgovorNe11_4.WidthPercent := 100.000000000000000000;
    SetEvent(vpOrganizacija_OdgovorNe11_4, Self, 'OnClick', 'vpOrganizacija_Odgovor11IzborClick');
    izOrganizacija_Vprasanje11_5.SetParentComponent(ploscicaTabelaOrganizacijskihVprasanj);
    izOrganizacija_Vprasanje11_5.Name := 'izOrganizacija_Vprasanje11_5';
    izOrganizacija_Vprasanje11_5.Left := 24;
    izOrganizacija_Vprasanje11_5.Top := 632;
    izOrganizacija_Vprasanje11_5.Width := 403;
    izOrganizacija_Vprasanje11_5.Height := 28;
    izOrganizacija_Vprasanje11_5.Anchors := [];
    izOrganizacija_Vprasanje11_5.AutoSize := False;
    izOrganizacija_Vprasanje11_5.BorderStyle := bsNone;
    izOrganizacija_Vprasanje11_5.HeightPercent := 100.000000000000000000;
    izOrganizacija_Vprasanje11_5.Lines.BeginUpdate;
    try
      izOrganizacija_Vprasanje11_5.Lines.Clear;
      izOrganizacija_Vprasanje11_5.Lines.Add('........ dele'#382' prodajnega asortimaja, ki se nahaja v skladi'#353#269'u:');
    finally
      izOrganizacija_Vprasanje11_5.Lines.EndUpdate;
    end;
    izOrganizacija_Vprasanje11_5.ParentColor := True;
    izOrganizacija_Vprasanje11_5.ReadOnly := True;
    izOrganizacija_Vprasanje11_5.SelLength := 0;
    izOrganizacija_Vprasanje11_5.SelStart := 65;
    izOrganizacija_Vprasanje11_5.ShowFocus := False;
    izOrganizacija_Vprasanje11_5.SpellCheck := False;
    izOrganizacija_Vprasanje11_5.WidthPercent := 100.000000000000000000;
    izOrganizacija_Vprasanje11_5.WordWrap := False;
    vpOrganizacija_Odgovor11_5.SetParentComponent(ploscicaTabelaOrganizacijskihVprasanj);
    vpOrganizacija_Odgovor11_5.Name := 'vpOrganizacija_Odgovor11_5';
    vpOrganizacija_Odgovor11_5.Left := 491;
    vpOrganizacija_Odgovor11_5.Top := 632;
    vpOrganizacija_Odgovor11_5.Width := 150;
    vpOrganizacija_Odgovor11_5.Height := 20;
    vpOrganizacija_Odgovor11_5.HeightPercent := 100.000000000000000000;
    vpOrganizacija_Odgovor11_5.WidthPercent := 100.000000000000000000;
    vpOrganizacija_Odgovor11_5.Anchors := [];
    vpOrganizacija_Odgovor11_5.ChildOrder := 62;
    vpOrganizacija_Odgovor11_5.Max := 100;
    vpOrganizacija_Odgovor11_5.Min := 0;
    vpOrganizacija_Odgovor11_5.Position := 100;
    vpOrganizacija_Odgovor11_5.Role := '';
    SetEvent(vpOrganizacija_Odgovor11_5, Self, 'OnChange', 'vpOrganizacija_Odgovor11DelezZalogChange');
    izOrganizacija_Vprasanje11_6.SetParentComponent(ploscicaTabelaOrganizacijskihVprasanj);
    izOrganizacija_Vprasanje11_6.Name := 'izOrganizacija_Vprasanje11_6';
    izOrganizacija_Vprasanje11_6.Left := 24;
    izOrganizacija_Vprasanje11_6.Top := 674;
    izOrganizacija_Vprasanje11_6.Width := 403;
    izOrganizacija_Vprasanje11_6.Height := 28;
    izOrganizacija_Vprasanje11_6.Anchors := [];
    izOrganizacija_Vprasanje11_6.AutoSize := False;
    izOrganizacija_Vprasanje11_6.BorderStyle := bsNone;
    izOrganizacija_Vprasanje11_6.HeightPercent := 100.000000000000000000;
    izOrganizacija_Vprasanje11_6.Lines.BeginUpdate;
    try
      izOrganizacija_Vprasanje11_6.Lines.Clear;
      izOrganizacija_Vprasanje11_6.Lines.Add('........ zaloge v takem skladi'#353#269'u omogo'#269'ajo prodajo za obdobje:');
    finally
      izOrganizacija_Vprasanje11_6.Lines.EndUpdate;
    end;
    izOrganizacija_Vprasanje11_6.ParentColor := True;
    izOrganizacija_Vprasanje11_6.ReadOnly := True;
    izOrganizacija_Vprasanje11_6.SelLength := 0;
    izOrganizacija_Vprasanje11_6.SelStart := 65;
    izOrganizacija_Vprasanje11_6.ShowFocus := False;
    izOrganizacija_Vprasanje11_6.SpellCheck := False;
    izOrganizacija_Vprasanje11_6.WidthPercent := 100.000000000000000000;
    izOrganizacija_Vprasanje11_6.WordWrap := False;
    vpOrganizacija_Odgovor11_6.SetParentComponent(ploscicaTabelaOrganizacijskihVprasanj);
    vpOrganizacija_Odgovor11_6.Name := 'vpOrganizacija_Odgovor11_6';
    vpOrganizacija_Odgovor11_6.Left := 491;
    vpOrganizacija_Odgovor11_6.Top := 674;
    vpOrganizacija_Odgovor11_6.Width := 150;
    vpOrganizacija_Odgovor11_6.Height := 20;
    vpOrganizacija_Odgovor11_6.HeightPercent := 100.000000000000000000;
    vpOrganizacija_Odgovor11_6.WidthPercent := 100.000000000000000000;
    vpOrganizacija_Odgovor11_6.Anchors := [];
    vpOrganizacija_Odgovor11_6.ChildOrder := 66;
    vpOrganizacija_Odgovor11_6.Max := 24;
    vpOrganizacija_Odgovor11_6.Min := 0;
    vpOrganizacija_Odgovor11_6.Position := 4;
    vpOrganizacija_Odgovor11_6.Role := '';
    SetEvent(vpOrganizacija_Odgovor11_6, Self, 'OnChange', 'vpOrganizacija_Odgovor11KolicinaZalogChange');
    izOrganizacija_Vprasanje12.SetParentComponent(ploscicaTabelaOrganizacijskihVprasanj);
    izOrganizacija_Vprasanje12.Name := 'izOrganizacija_Vprasanje12';
    izOrganizacija_Vprasanje12.Left := 24;
    izOrganizacija_Vprasanje12.Top := 716;
    izOrganizacija_Vprasanje12.Width := 403;
    izOrganizacija_Vprasanje12.Height := 28;
    izOrganizacija_Vprasanje12.Anchors := [];
    izOrganizacija_Vprasanje12.AutoSize := False;
    izOrganizacija_Vprasanje12.BorderStyle := bsNone;
    izOrganizacija_Vprasanje12.HeightPercent := 100.000000000000000000;
    izOrganizacija_Vprasanje12.Lines.BeginUpdate;
    try
      izOrganizacija_Vprasanje12.Lines.Clear;
      izOrganizacija_Vprasanje12.Lines.Add('Kolik'#353'en je povpre'#269'en zamik pla'#269'il za prodane izdelke?');
      izOrganizacija_Vprasanje12.Lines.Add('');
    finally
      izOrganizacija_Vprasanje12.Lines.EndUpdate;
    end;
    izOrganizacija_Vprasanje12.ParentColor := True;
    izOrganizacija_Vprasanje12.ReadOnly := True;
    izOrganizacija_Vprasanje12.SelLength := 0;
    izOrganizacija_Vprasanje12.SelStart := 58;
    izOrganizacija_Vprasanje12.ShowFocus := False;
    izOrganizacija_Vprasanje12.SpellCheck := False;
    izOrganizacija_Vprasanje12.WidthPercent := 100.000000000000000000;
    izOrganizacija_Vprasanje12.WordWrap := False;
    vpOrganizacija_Odgovor12.SetParentComponent(ploscicaTabelaOrganizacijskihVprasanj);
    vpOrganizacija_Odgovor12.Name := 'vpOrganizacija_Odgovor12';
    vpOrganizacija_Odgovor12.Left := 491;
    vpOrganizacija_Odgovor12.Top := 716;
    vpOrganizacija_Odgovor12.Width := 150;
    vpOrganizacija_Odgovor12.Height := 20;
    vpOrganizacija_Odgovor12.HeightPercent := 100.000000000000000000;
    vpOrganizacija_Odgovor12.WidthPercent := 100.000000000000000000;
    vpOrganizacija_Odgovor12.Anchors := [];
    vpOrganizacija_Odgovor12.ChildOrder := 70;
    vpOrganizacija_Odgovor12.Max := 48;
    vpOrganizacija_Odgovor12.Min := 0;
    vpOrganizacija_Odgovor12.Position := 2;
    vpOrganizacija_Odgovor12.Role := '';
    SetEvent(vpOrganizacija_Odgovor12, Self, 'OnChange', 'vpOrganizacija_Odgovor12Change');
    izOrganizacija_Vprasanje13.SetParentComponent(ploscicaTabelaOrganizacijskihVprasanj);
    izOrganizacija_Vprasanje13.Name := 'izOrganizacija_Vprasanje13';
    izOrganizacija_Vprasanje13.Left := 24;
    izOrganizacija_Vprasanje13.Top := 758;
    izOrganizacija_Vprasanje13.Width := 403;
    izOrganizacija_Vprasanje13.Height := 28;
    izOrganizacija_Vprasanje13.Anchors := [];
    izOrganizacija_Vprasanje13.AutoSize := False;
    izOrganizacija_Vprasanje13.BorderStyle := bsNone;
    izOrganizacija_Vprasanje13.HeightPercent := 100.000000000000000000;
    izOrganizacija_Vprasanje13.Lines.BeginUpdate;
    try
      izOrganizacija_Vprasanje13.Lines.Clear;
      izOrganizacija_Vprasanje13.Lines.Add('Kako je najla'#382'je opisati tip proizvodnje?');
      izOrganizacija_Vprasanje13.Lines.Add('');
    finally
      izOrganizacija_Vprasanje13.Lines.EndUpdate;
    end;
    izOrganizacija_Vprasanje13.ParentColor := True;
    izOrganizacija_Vprasanje13.ReadOnly := True;
    izOrganizacija_Vprasanje13.SelLength := 0;
    izOrganizacija_Vprasanje13.SelStart := 45;
    izOrganizacija_Vprasanje13.ShowFocus := False;
    izOrganizacija_Vprasanje13.SpellCheck := False;
    izOrganizacija_Vprasanje13.WidthPercent := 100.000000000000000000;
    izOrganizacija_Vprasanje13.WordWrap := False;
    vpOrganizacija_Odgovor13.SetParentComponent(ploscicaTabelaOrganizacijskihVprasanj);
    vpOrganizacija_Odgovor13.Name := 'vpOrganizacija_Odgovor13';
    vpOrganizacija_Odgovor13.Left := 491;
    vpOrganizacija_Odgovor13.Top := 758;
    vpOrganizacija_Odgovor13.Width := 150;
    vpOrganizacija_Odgovor13.Height := 23;
    vpOrganizacija_Odgovor13.Anchors := [];
    vpOrganizacija_Odgovor13.HeightPercent := 100.000000000000000000;
    vpOrganizacija_Odgovor13.Text := 'vpOrganizacija_Odgovor13';
    vpOrganizacija_Odgovor13.WidthPercent := 100.000000000000000000;
    vpOrganizacija_Odgovor13.ItemIndex := -1;
    vpOrganizacija_Odgovor13.Items.BeginUpdate;
    try
      vpOrganizacija_Odgovor13.Items.Clear;
      vpOrganizacija_Odgovor13.Items.Add('1 - proizvodnja v enem obratu');
      vpOrganizacija_Odgovor13.Items.Add('2 - zaporedna proizvodnja v ve'#269' obratih');
      vpOrganizacija_Odgovor13.Items.Add('3 - vzporedna proizvodnja v ve'#269' lo'#269'enih obratih');
    finally
      vpOrganizacija_Odgovor13.Items.EndUpdate;
    end;
    DelovniList5.SetParentComponent(ploscicaDelovniListi);
    DelovniList5.Name := 'DelovniList5';
    DelovniList5.Left := 0;
    DelovniList5.Top := 20;
    DelovniList5.Width := 1157;
    DelovniList5.Height := 1090;
    DelovniList5.HeightPercent := 100.000000000000000000;
    DelovniList5.WidthPercent := 100.000000000000000000;
    DelovniList5.Caption := 'DelovniList5';
    DelovniList5.ChildOrder := 7;
    DelovniList5.TabVisible := False;
    WebScrollBox2.SetParentComponent(DelovniList5);
    WebScrollBox2.Name := 'WebScrollBox2';
    WebScrollBox2.Left := 0;
    WebScrollBox2.Top := 0;
    WebScrollBox2.Width := 1157;
    WebScrollBox2.Height := 1090;
    WebScrollBox2.HeightPercent := 100.000000000000000000;
    WebScrollBox2.WidthPercent := 100.000000000000000000;
    WebScrollBox2.Align := alClient;
    WebScrollBox2.AutoScroll := True;
    WebScrollBox2.BorderStyle := bsNone;
    WebScrollBox2.ChildOrder := 3;
    WebScrollBox2.Color := clWindow;
    izNaslovDL5.SetParentComponent(WebScrollBox2);
    izNaslovDL5.Name := 'izNaslovDL5';
    izNaslovDL5.Left := 12;
    izNaslovDL5.Top := 19;
    izNaslovDL5.Width := 431;
    izNaslovDL5.Height := 19;
    izNaslovDL5.Anchors := [];
    izNaslovDL5.Caption := 'Podatki o obstoje'#269'em zavarovalnem programu podjetja';
    izNaslovDL5.Color := clNone;
    izNaslovDL5.Font.Charset := DEFAULT_CHARSET;
    izNaslovDL5.Font.Color := clWindowText;
    izNaslovDL5.Font.Height := -17;
    izNaslovDL5.Font.Name := 'Arial';
    izNaslovDL5.Font.Style := [fsBold];
    izNaslovDL5.HeightPercent := 100.000000000000000000;
    izNaslovDL5.ParentFont := False;
    izNaslovDL5.WidthPercent := 100.000000000000000000;
    ploscicaPodatkiOZavarovanju.SetParentComponent(WebScrollBox2);
    ploscicaPodatkiOZavarovanju.Name := 'ploscicaPodatkiOZavarovanju';
    ploscicaPodatkiOZavarovanju.Left := 12;
    ploscicaPodatkiOZavarovanju.Top := 119;
    ploscicaPodatkiOZavarovanju.Width := 605;
    ploscicaPodatkiOZavarovanju.Height := 500;
    ploscicaPodatkiOZavarovanju.HeightPercent := 100.000000000000000000;
    ploscicaPodatkiOZavarovanju.WidthPercent := 100.000000000000000000;
    ploscicaPodatkiOZavarovanju.BorderColor := clNone;
    ploscicaPodatkiOZavarovanju.BorderStyle := bsNone;
    ploscicaPodatkiOZavarovanju.ChildOrder := 2;
    ploscicaPodatkiOZavarovanju.Color := clWindow;
    izTekstZVKosmatiDobicek.SetParentComponent(ploscicaPodatkiOZavarovanju);
    izTekstZVKosmatiDobicek.Name := 'izTekstZVKosmatiDobicek';
    izTekstZVKosmatiDobicek.Left := 32;
    izTekstZVKosmatiDobicek.Top := 38;
    izTekstZVKosmatiDobicek.Width := 154;
    izTekstZVKosmatiDobicek.Height := 15;
    izTekstZVKosmatiDobicek.Caption := '- in sicer z zavarovalno vsoto:';
    izTekstZVKosmatiDobicek.Color := clNone;
    izTekstZVKosmatiDobicek.HeightPercent := 100.000000000000000000;
    izTekstZVKosmatiDobicek.WidthPercent := 100.000000000000000000;
    izTekstStroski1.SetParentComponent(ploscicaPodatkiOZavarovanju);
    izTekstStroski1.Name := 'izTekstStroski1';
    izTekstStroski1.Left := 35;
    izTekstStroski1.Top := 120;
    izTekstStroski1.Width := 230;
    izTekstStroski1.Height := 15;
    izTekstStroski1.Caption := '- in sicer z zavarovanimi naslednjimi stro'#353'ki:';
    izTekstStroski1.Color := clNone;
    izTekstStroski1.HeightPercent := 100.000000000000000000;
    izTekstStroski1.WidthPercent := 100.000000000000000000;
    izTekstStroski2.SetParentComponent(ploscicaPodatkiOZavarovanju);
    izTekstStroski2.Name := 'izTekstStroski2';
    izTekstStroski2.Left := 27;
    izTekstStroski2.Top := 323;
    izTekstStroski2.Width := 161;
    izTekstStroski2.Height := 15;
    izTekstStroski2.Caption := '- in skupno zavarovalno vsoto:';
    izTekstStroski2.Color := clNone;
    izTekstStroski2.HeightPercent := 100.000000000000000000;
    izTekstStroski2.WidthPercent := 100.000000000000000000;
    izTekstMDJ.SetParentComponent(ploscicaPodatkiOZavarovanju);
    izTekstMDJ.Name := 'izTekstMDJ';
    izTekstMDJ.Left := 5;
    izTekstMDJ.Top := 375;
    izTekstMDJ.Width := 344;
    izTekstMDJ.Height := 15;
    izTekstMDJ.Caption := 'Zavarovanje je sklenjeno z naslednjo maksimalno dobo jam'#269'enja:';
    izTekstMDJ.Color := clNone;
    izTekstMDJ.HeightPercent := 100.000000000000000000;
    izTekstMDJ.WidthPercent := 100.000000000000000000;
    izTekstZacetekZavarovanja.SetParentComponent(ploscicaPodatkiOZavarovanju);
    izTekstZacetekZavarovanja.Name := 'izTekstZacetekZavarovanja';
    izTekstZacetekZavarovanja.Left := 5;
    izTekstZacetekZavarovanja.Top := 416;
    izTekstZacetekZavarovanja.Width := 217;
    izTekstZacetekZavarovanja.Height := 15;
    izTekstZacetekZavarovanja.Caption := 'Za'#269'etek zavarovanja (zavarovalnega leta):';
    izTekstZacetekZavarovanja.Color := clNone;
    izTekstZacetekZavarovanja.HeightPercent := 100.000000000000000000;
    izTekstZacetekZavarovanja.WidthPercent := 100.000000000000000000;
    izTekstPremija.SetParentComponent(ploscicaPodatkiOZavarovanju);
    izTekstPremija.Name := 'izTekstPremija';
    izTekstPremija.Left := 5;
    izTekstPremija.Top := 455;
    izTekstPremija.Width := 140;
    izTekstPremija.Height := 15;
    izTekstPremija.Caption := 'Letna zavarovalna premija:';
    izTekstPremija.Color := clNone;
    izTekstPremija.HeightPercent := 100.000000000000000000;
    izTekstPremija.WidthPercent := 100.000000000000000000;
    izMDJ.SetParentComponent(ploscicaPodatkiOZavarovanju);
    izMDJ.Name := 'izMDJ';
    izMDJ.Left := 504;
    izMDJ.Top := 375;
    izMDJ.Width := 65;
    izMDJ.Height := 15;
    izMDJ.Alignment := taRightJustify;
    izMDJ.Caption := 'mesec(e/ev)';
    izMDJ.Color := clNone;
    izMDJ.HeightPercent := 100.000000000000000000;
    izMDJ.WidthPercent := 100.000000000000000000;
    vpZavarovanjeDAKosmatiDobicek.SetParentComponent(ploscicaPodatkiOZavarovanju);
    vpZavarovanjeDAKosmatiDobicek.Name := 'vpZavarovanjeDAKosmatiDobicek';
    vpZavarovanjeDAKosmatiDobicek.Left := 3;
    vpZavarovanjeDAKosmatiDobicek.Top := 3;
    vpZavarovanjeDAKosmatiDobicek.Width := 400;
    vpZavarovanjeDAKosmatiDobicek.Height := 22;
    vpZavarovanjeDAKosmatiDobicek.Caption := 'Zavarovanje je sklenjeno po sistemu kosmatega dobi'#269'ka';
    vpZavarovanjeDAKosmatiDobicek.Color := clNone;
    vpZavarovanjeDAKosmatiDobicek.HeightPercent := 100.000000000000000000;
    vpZavarovanjeDAKosmatiDobicek.State := cbUnchecked;
    vpZavarovanjeDAKosmatiDobicek.WidthPercent := 100.000000000000000000;
    SetEvent(vpZavarovanjeDAKosmatiDobicek, Self, 'OnClick', 'vpZavarovanjeDAKosmatiDobicekClick');
    vpZVKosmatiDobicek.SetParentComponent(ploscicaPodatkiOZavarovanju);
    vpZVKosmatiDobicek.Name := 'vpZVKosmatiDobicek';
    vpZVKosmatiDobicek.Left := 456;
    vpZVKosmatiDobicek.Top := 36;
    vpZVKosmatiDobicek.Width := 117;
    vpZVKosmatiDobicek.Height := 22;
    vpZVKosmatiDobicek.Alignment := taRightJustify;
    vpZVKosmatiDobicek.ChildOrder := 2;
    vpZVKosmatiDobicek.HeightPercent := 100.000000000000000000;
    vpZVKosmatiDobicek.Text := '0.00';
    vpZVKosmatiDobicek.WidthPercent := 100.000000000000000000;
    SetEvent(vpZVKosmatiDobicek, Self, 'OnExit', 'vpZVKosmatiDobicekExit');
    SetEvent(vpZVKosmatiDobicek, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    vpZavarovanjeDANastetiStroski.SetParentComponent(ploscicaPodatkiOZavarovanju);
    vpZavarovanjeDANastetiStroski.Name := 'vpZavarovanjeDANastetiStroski';
    vpZavarovanjeDANastetiStroski.Left := 5;
    vpZavarovanjeDANastetiStroski.Top := 90;
    vpZavarovanjeDANastetiStroski.Width := 400;
    vpZavarovanjeDANastetiStroski.Height := 22;
    vpZavarovanjeDANastetiStroski.Caption := 'Zavarovanje je sklenjeno po sistemu na'#353'tetih stro'#353'kov';
    vpZavarovanjeDANastetiStroski.ChildOrder := 3;
    vpZavarovanjeDANastetiStroski.Color := clNone;
    vpZavarovanjeDANastetiStroski.HeightPercent := 100.000000000000000000;
    vpZavarovanjeDANastetiStroski.State := cbUnchecked;
    vpZavarovanjeDANastetiStroski.WidthPercent := 100.000000000000000000;
    SetEvent(vpZavarovanjeDANastetiStroski, Self, 'OnClick', 'vpZavarovanjeDANastetiStroskiClick');
    ploscicaNastetiStroski.SetParentComponent(ploscicaPodatkiOZavarovanju);
    ploscicaNastetiStroski.Name := 'ploscicaNastetiStroski';
    ploscicaNastetiStroski.Left := 79;
    ploscicaNastetiStroski.Top := 150;
    ploscicaNastetiStroski.Width := 280;
    ploscicaNastetiStroski.Height := 160;
    ploscicaNastetiStroski.HeightPercent := 100.000000000000000000;
    ploscicaNastetiStroski.WidthPercent := 100.000000000000000000;
    ploscicaNastetiStroski.ChildOrder := 5;
    ploscicaNastetiStroski.ColumnCollection.Clear;
    with ploscicaNastetiStroski.ColumnCollection.Add do
    begin
      Value := 50;
    end;
    with ploscicaNastetiStroski.ColumnCollection.Add do
    begin
      Alignment := taCenter;
      Value := 50;
    end;
    ploscicaNastetiStroski.ControlCollection.Clear;
    with ploscicaNastetiStroski.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izZavarovaniStroski1_1;
    end;
    with ploscicaNastetiStroski.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izZavarovaniStroski2_1;
    end;
    with ploscicaNastetiStroski.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izZavarovaniStroski1_2;
    end;
    with ploscicaNastetiStroski.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := vpZavarovaniStroski2_2;
    end;
    with ploscicaNastetiStroski.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izZavarovaniStroski1_3;
    end;
    with ploscicaNastetiStroski.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := vpZavarovaniStroski2_3;
    end;
    with ploscicaNastetiStroski.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izZavarovaniStroski1_4;
    end;
    with ploscicaNastetiStroski.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := vpZavarovaniStroski2_4;
    end;
    with ploscicaNastetiStroski.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izZavarovaniStroski1_5;
    end;
    with ploscicaNastetiStroski.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := vpZavarovaniStroski2_5;
    end;
    with ploscicaNastetiStroski.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izZavarovaniStroski1_6;
    end;
    with ploscicaNastetiStroski.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := vpZavarovaniStroski2_6;
    end;
    ploscicaNastetiStroski.Color := 16316664;
    ploscicaNastetiStroski.GridLineColor := clBlack;
    ploscicaNastetiStroski.RowCollection.Clear;
    with ploscicaNastetiStroski.RowCollection.Add do
    begin
      Value := 17;
    end;
    with ploscicaNastetiStroski.RowCollection.Add do
    begin
      Value := 17;
    end;
    with ploscicaNastetiStroski.RowCollection.Add do
    begin
      Value := 17;
    end;
    with ploscicaNastetiStroski.RowCollection.Add do
    begin
      Value := 17;
    end;
    with ploscicaNastetiStroski.RowCollection.Add do
    begin
      Value := 17;
    end;
    with ploscicaNastetiStroski.RowCollection.Add do
    begin
      Value := 17;
    end;
    izZavarovaniStroski1_1.SetParentComponent(ploscicaNastetiStroski);
    izZavarovaniStroski1_1.Name := 'izZavarovaniStroski1_1';
    izZavarovaniStroski1_1.Left := 2;
    izZavarovaniStroski1_1.Top := 2;
    izZavarovaniStroski1_1.Width := 93;
    izZavarovaniStroski1_1.Height := 15;
    izZavarovaniStroski1_1.Anchors := [];
    izZavarovaniStroski1_1.Caption := 'zavarovani stro'#353'ki';
    izZavarovaniStroski1_1.Color := clNone;
    izZavarovaniStroski1_1.HeightPercent := 100.000000000000000000;
    izZavarovaniStroski1_1.WidthPercent := 100.000000000000000000;
    izZavarovaniStroski2_1.SetParentComponent(ploscicaNastetiStroski);
    izZavarovaniStroski2_1.Name := 'izZavarovaniStroski2_1';
    izZavarovaniStroski2_1.Left := 195;
    izZavarovaniStroski2_1.Top := 2;
    izZavarovaniStroski2_1.Width := 30;
    izZavarovaniStroski2_1.Height := 15;
    izZavarovaniStroski2_1.Anchors := [];
    izZavarovaniStroski2_1.Caption := 'vi'#353'ina';
    izZavarovaniStroski2_1.Color := clNone;
    izZavarovaniStroski2_1.HeightPercent := 100.000000000000000000;
    izZavarovaniStroski2_1.WidthPercent := 100.000000000000000000;
    izZavarovaniStroski1_2.SetParentComponent(ploscicaNastetiStroski);
    izZavarovaniStroski1_2.Name := 'izZavarovaniStroski1_2';
    izZavarovaniStroski1_2.Left := 2;
    izZavarovaniStroski1_2.Top := 29;
    izZavarovaniStroski1_2.Width := 76;
    izZavarovaniStroski1_2.Height := 15;
    izZavarovaniStroski1_2.Anchors := [];
    izZavarovaniStroski1_2.Caption := 'stro'#353'ki storitev';
    izZavarovaniStroski1_2.Color := clNone;
    izZavarovaniStroski1_2.HeightPercent := 100.000000000000000000;
    izZavarovaniStroski1_2.WidthPercent := 100.000000000000000000;
    izZavarovaniStroski1_3.SetParentComponent(ploscicaNastetiStroski);
    izZavarovaniStroski1_3.Name := 'izZavarovaniStroski1_3';
    izZavarovaniStroski1_3.Left := 2;
    izZavarovaniStroski1_3.Top := 56;
    izZavarovaniStroski1_3.Width := 64;
    izZavarovaniStroski1_3.Height := 15;
    izZavarovaniStroski1_3.Anchors := [];
    izZavarovaniStroski1_3.Caption := 'amortizacija';
    izZavarovaniStroski1_3.Color := clNone;
    izZavarovaniStroski1_3.HeightPercent := 100.000000000000000000;
    izZavarovaniStroski1_3.WidthPercent := 100.000000000000000000;
    izZavarovaniStroski1_4.SetParentComponent(ploscicaNastetiStroski);
    izZavarovaniStroski1_4.Name := 'izZavarovaniStroski1_4';
    izZavarovaniStroski1_4.Left := 2;
    izZavarovaniStroski1_4.Top := 83;
    izZavarovaniStroski1_4.Width := 59;
    izZavarovaniStroski1_4.Height := 15;
    izZavarovaniStroski1_4.Anchors := [];
    izZavarovaniStroski1_4.Caption := 'stro'#353'ki dela';
    izZavarovaniStroski1_4.Color := clNone;
    izZavarovaniStroski1_4.HeightPercent := 100.000000000000000000;
    izZavarovaniStroski1_4.WidthPercent := 100.000000000000000000;
    izZavarovaniStroski1_5.SetParentComponent(ploscicaNastetiStroski);
    izZavarovaniStroski1_5.Name := 'izZavarovaniStroski1_5';
    izZavarovaniStroski1_5.Left := 2;
    izZavarovaniStroski1_5.Top := 110;
    izZavarovaniStroski1_5.Width := 65;
    izZavarovaniStroski1_5.Height := 15;
    izZavarovaniStroski1_5.Anchors := [];
    izZavarovaniStroski1_5.Caption := 'drugi stro'#353'ki';
    izZavarovaniStroski1_5.Color := clNone;
    izZavarovaniStroski1_5.HeightPercent := 100.000000000000000000;
    izZavarovaniStroski1_5.WidthPercent := 100.000000000000000000;
    izZavarovaniStroski1_6.SetParentComponent(ploscicaNastetiStroski);
    izZavarovaniStroski1_6.Name := 'izZavarovaniStroski1_6';
    izZavarovaniStroski1_6.Left := 2;
    izZavarovaniStroski1_6.Top := 137;
    izZavarovaniStroski1_6.Width := 113;
    izZavarovaniStroski1_6.Height := 15;
    izZavarovaniStroski1_6.Anchors := [];
    izZavarovaniStroski1_6.Caption := 'dobi'#269'ek iz poslovanja';
    izZavarovaniStroski1_6.Color := clNone;
    izZavarovaniStroski1_6.HeightPercent := 100.000000000000000000;
    izZavarovaniStroski1_6.WidthPercent := 100.000000000000000000;
    vpZavarovaniStroski2_2.SetParentComponent(ploscicaNastetiStroski);
    vpZavarovaniStroski2_2.Name := 'vpZavarovaniStroski2_2';
    vpZavarovaniStroski2_2.Left := 147;
    vpZavarovaniStroski2_2.Top := 29;
    vpZavarovaniStroski2_2.Width := 125;
    vpZavarovaniStroski2_2.Height := 22;
    vpZavarovaniStroski2_2.Alignment := taRightJustify;
    vpZavarovaniStroski2_2.Anchors := [];
    vpZavarovaniStroski2_2.ChildOrder := 3;
    vpZavarovaniStroski2_2.HeightPercent := 100.000000000000000000;
    vpZavarovaniStroski2_2.Text := '0.00';
    vpZavarovaniStroski2_2.WidthPercent := 100.000000000000000000;
    SetEvent(vpZavarovaniStroski2_2, Self, 'OnExit', 'vpZavarovaniStroskiSestevekExit');
    SetEvent(vpZavarovaniStroski2_2, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    vpZavarovaniStroski2_3.SetParentComponent(ploscicaNastetiStroski);
    vpZavarovaniStroski2_3.Name := 'vpZavarovaniStroski2_3';
    vpZavarovaniStroski2_3.Left := 147;
    vpZavarovaniStroski2_3.Top := 56;
    vpZavarovaniStroski2_3.Width := 125;
    vpZavarovaniStroski2_3.Height := 22;
    vpZavarovaniStroski2_3.Alignment := taRightJustify;
    vpZavarovaniStroski2_3.Anchors := [];
    vpZavarovaniStroski2_3.ChildOrder := 5;
    vpZavarovaniStroski2_3.HeightPercent := 100.000000000000000000;
    vpZavarovaniStroski2_3.Text := '0.00';
    vpZavarovaniStroski2_3.WidthPercent := 100.000000000000000000;
    SetEvent(vpZavarovaniStroski2_3, Self, 'OnExit', 'vpZavarovaniStroskiSestevekExit');
    SetEvent(vpZavarovaniStroski2_3, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    vpZavarovaniStroski2_4.SetParentComponent(ploscicaNastetiStroski);
    vpZavarovaniStroski2_4.Name := 'vpZavarovaniStroski2_4';
    vpZavarovaniStroski2_4.Left := 147;
    vpZavarovaniStroski2_4.Top := 83;
    vpZavarovaniStroski2_4.Width := 125;
    vpZavarovaniStroski2_4.Height := 22;
    vpZavarovaniStroski2_4.Alignment := taRightJustify;
    vpZavarovaniStroski2_4.Anchors := [];
    vpZavarovaniStroski2_4.ChildOrder := 7;
    vpZavarovaniStroski2_4.HeightPercent := 100.000000000000000000;
    vpZavarovaniStroski2_4.Text := '0.00';
    vpZavarovaniStroski2_4.WidthPercent := 100.000000000000000000;
    SetEvent(vpZavarovaniStroski2_4, Self, 'OnExit', 'vpZavarovaniStroskiSestevekExit');
    SetEvent(vpZavarovaniStroski2_4, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    vpZavarovaniStroski2_5.SetParentComponent(ploscicaNastetiStroski);
    vpZavarovaniStroski2_5.Name := 'vpZavarovaniStroski2_5';
    vpZavarovaniStroski2_5.Left := 147;
    vpZavarovaniStroski2_5.Top := 110;
    vpZavarovaniStroski2_5.Width := 125;
    vpZavarovaniStroski2_5.Height := 22;
    vpZavarovaniStroski2_5.Alignment := taRightJustify;
    vpZavarovaniStroski2_5.Anchors := [];
    vpZavarovaniStroski2_5.ChildOrder := 9;
    vpZavarovaniStroski2_5.HeightPercent := 100.000000000000000000;
    vpZavarovaniStroski2_5.Text := '0.00';
    vpZavarovaniStroski2_5.WidthPercent := 100.000000000000000000;
    SetEvent(vpZavarovaniStroski2_5, Self, 'OnExit', 'vpZavarovaniStroskiSestevekExit');
    SetEvent(vpZavarovaniStroski2_5, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    vpZavarovaniStroski2_6.SetParentComponent(ploscicaNastetiStroski);
    vpZavarovaniStroski2_6.Name := 'vpZavarovaniStroski2_6';
    vpZavarovaniStroski2_6.Left := 147;
    vpZavarovaniStroski2_6.Top := 137;
    vpZavarovaniStroski2_6.Width := 125;
    vpZavarovaniStroski2_6.Height := 22;
    vpZavarovaniStroski2_6.Alignment := taRightJustify;
    vpZavarovaniStroski2_6.Anchors := [];
    vpZavarovaniStroski2_6.ChildOrder := 11;
    vpZavarovaniStroski2_6.HeightPercent := 100.000000000000000000;
    vpZavarovaniStroski2_6.Text := '0.00';
    vpZavarovaniStroski2_6.WidthPercent := 100.000000000000000000;
    SetEvent(vpZavarovaniStroski2_6, Self, 'OnExit', 'vpZavarovaniStroskiSestevekExit');
    SetEvent(vpZavarovaniStroski2_6, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    vpZVNastetiStroski.SetParentComponent(ploscicaPodatkiOZavarovanju);
    vpZVNastetiStroski.Name := 'vpZVNastetiStroski';
    vpZVNastetiStroski.Left := 456;
    vpZVNastetiStroski.Top := 317;
    vpZVNastetiStroski.Width := 117;
    vpZVNastetiStroski.Height := 22;
    vpZVNastetiStroski.TabStop := False;
    vpZVNastetiStroski.Alignment := taRightJustify;
    vpZVNastetiStroski.ChildOrder := 7;
    vpZVNastetiStroski.Enabled := False;
    vpZVNastetiStroski.HeightPercent := 100.000000000000000000;
    vpZVNastetiStroski.Text := '0.00';
    vpZVNastetiStroski.WidthPercent := 100.000000000000000000;
    vpMDJ.SetParentComponent(ploscicaPodatkiOZavarovanju);
    vpMDJ.Name := 'vpMDJ';
    vpMDJ.Left := 456;
    vpMDJ.Top := 370;
    vpMDJ.Width := 41;
    vpMDJ.Height := 23;
    vpMDJ.HeightPercent := 100.000000000000000000;
    vpMDJ.Text := '12';
    vpMDJ.WidthPercent := 100.000000000000000000;
    vpMDJ.ItemIndex := -1;
    vpMDJ.Items.BeginUpdate;
    try
      vpMDJ.Items.Clear;
      vpMDJ.Items.Add('3');
      vpMDJ.Items.Add('4');
      vpMDJ.Items.Add('5');
      vpMDJ.Items.Add('6');
      vpMDJ.Items.Add('7');
      vpMDJ.Items.Add('8');
      vpMDJ.Items.Add('9');
      vpMDJ.Items.Add('10');
      vpMDJ.Items.Add('11');
      vpMDJ.Items.Add('12');
      vpMDJ.Items.Add('18');
      vpMDJ.Items.Add('24');
    finally
      vpMDJ.Items.EndUpdate;
    end;
    vpZacetekZavarovanja.SetParentComponent(ploscicaPodatkiOZavarovanju);
    vpZacetekZavarovanja.Name := 'vpZacetekZavarovanja';
    vpZacetekZavarovanja.Left := 416;
    vpZacetekZavarovanja.Top := 409;
    vpZacetekZavarovanja.Width := 157;
    vpZacetekZavarovanja.Height := 22;
    vpZacetekZavarovanja.HeightPercent := 100.000000000000000000;
    vpZacetekZavarovanja.WidthPercent := 100.000000000000000000;
    vpZacetekZavarovanja.BorderStyle := bsSingle;
    vpZacetekZavarovanja.ChildOrder := 11;
    vpZacetekZavarovanja.Color := clWhite;
    vpZacetekZavarovanja.Date := 44159.537542349500000000;
    vpZacetekZavarovanja.Font.Charset := DEFAULT_CHARSET;
    vpZacetekZavarovanja.Font.Color := clWindowText;
    vpZacetekZavarovanja.Font.Height := -11;
    vpZacetekZavarovanja.Font.Name := 'Tahoma';
    vpZacetekZavarovanja.Font.Style := [];
    vpZacetekZavarovanja.ParentFont := False;
    vpZacetekZavarovanja.Role := '';
    vpZacetekZavarovanja.Text := '';
    SetEvent(vpZacetekZavarovanja, Self, 'OnChange', 'vpZacetekZavarovanjaChange');
    vpPremija.SetParentComponent(ploscicaPodatkiOZavarovanju);
    vpPremija.Name := 'vpPremija';
    vpPremija.Left := 456;
    vpPremija.Top := 453;
    vpPremija.Width := 117;
    vpPremija.Height := 22;
    vpPremija.Alignment := taRightJustify;
    vpPremija.ChildOrder := 2;
    vpPremija.HeightPercent := 100.000000000000000000;
    vpPremija.Text := '0.00';
    vpPremija.WidthPercent := 100.000000000000000000;
    SetEvent(vpPremija, Self, 'OnExit', 'vpZVKosmatiDobicekExit');
    SetEvent(vpPremija, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    vpZavarovanjeDa.SetParentComponent(WebScrollBox2);
    vpZavarovanjeDa.Name := 'vpZavarovanjeDa';
    vpZavarovanjeDa.Left := 15;
    vpZavarovanjeDa.Top := 78;
    vpZavarovanjeDa.Width := 600;
    vpZavarovanjeDa.Height := 22;
    vpZavarovanjeDa.Caption := 'DA, podjetje ima sklenjeno zavarovalno polico za zavarovanje obratovalnega zastoja';
    vpZavarovanjeDa.ChildOrder := 1;
    vpZavarovanjeDa.Color := clNone;
    vpZavarovanjeDa.HeightPercent := 100.000000000000000000;
    vpZavarovanjeDa.State := cbUnchecked;
    vpZavarovanjeDa.WidthPercent := 100.000000000000000000;
    SetEvent(vpZavarovanjeDa, Self, 'OnClick', 'vpZavarovanjeDaClick');
    DelovniList6.SetParentComponent(ploscicaDelovniListi);
    DelovniList6.Name := 'DelovniList6';
    DelovniList6.Left := 0;
    DelovniList6.Top := 20;
    DelovniList6.Width := 1157;
    DelovniList6.Height := 1090;
    DelovniList6.HeightPercent := 100.000000000000000000;
    DelovniList6.WidthPercent := 100.000000000000000000;
    DelovniList6.Caption := 'DelovniList6';
    DelovniList6.ChildOrder := 8;
    DelovniList6.TabVisible := False;
    WebScrollBoxDL6.SetParentComponent(DelovniList6);
    WebScrollBoxDL6.Name := 'WebScrollBoxDL6';
    WebScrollBoxDL6.Left := 0;
    WebScrollBoxDL6.Top := 0;
    WebScrollBoxDL6.Width := 1157;
    WebScrollBoxDL6.Height := 1090;
    WebScrollBoxDL6.HeightPercent := 100.000000000000000000;
    WebScrollBoxDL6.WidthPercent := 100.000000000000000000;
    WebScrollBoxDL6.Align := alClient;
    WebScrollBoxDL6.AutoScroll := True;
    WebScrollBoxDL6.BorderStyle := bsNone;
    WebScrollBoxDL6.ChildOrder := 7;
    WebScrollBoxDL6.Color := clWindow;
    izNaslovDL6.SetParentComponent(WebScrollBoxDL6);
    izNaslovDL6.Name := 'izNaslovDL6';
    izNaslovDL6.Left := 12;
    izNaslovDL6.Top := 28;
    izNaslovDL6.Width := 285;
    izNaslovDL6.Height := 19;
    izNaslovDL6.Anchors := [];
    izNaslovDL6.Caption := 'Potrebna maksimalna doba jam'#269'enja';
    izNaslovDL6.Color := clNone;
    izNaslovDL6.Font.Charset := DEFAULT_CHARSET;
    izNaslovDL6.Font.Color := clWindowText;
    izNaslovDL6.Font.Height := -17;
    izNaslovDL6.Font.Name := 'Arial';
    izNaslovDL6.Font.Style := [fsBold];
    izNaslovDL6.HeightPercent := 100.000000000000000000;
    izNaslovDL6.ParentFont := False;
    izNaslovDL6.WidthPercent := 100.000000000000000000;
    izNastavljanjeOsnovnihParametrov.SetParentComponent(WebScrollBoxDL6);
    izNastavljanjeOsnovnihParametrov.Name := 'izNastavljanjeOsnovnihParametrov';
    izNastavljanjeOsnovnihParametrov.Left := 15;
    izNastavljanjeOsnovnihParametrov.Top := 200;
    izNastavljanjeOsnovnihParametrov.Width := 163;
    izNastavljanjeOsnovnihParametrov.Height := 14;
    izNastavljanjeOsnovnihParametrov.Caption := 'Nastavljanje osnovnih paramterov';
    izNastavljanjeOsnovnihParametrov.Color := clNone;
    izNastavljanjeOsnovnihParametrov.Font.Charset := DEFAULT_CHARSET;
    izNastavljanjeOsnovnihParametrov.Font.Color := clWindowText;
    izNastavljanjeOsnovnihParametrov.Font.Height := -11;
    izNastavljanjeOsnovnihParametrov.Font.Name := 'Arial';
    izNastavljanjeOsnovnihParametrov.Font.Style := [fsUnderline];
    izNastavljanjeOsnovnihParametrov.HeightPercent := 100.000000000000000000;
    izNastavljanjeOsnovnihParametrov.ParentFont := False;
    izNastavljanjeOsnovnihParametrov.WidthPercent := 100.000000000000000000;
    ploscicaKorekcijskiFaktorji.SetParentComponent(WebScrollBoxDL6);
    ploscicaKorekcijskiFaktorji.Name := 'ploscicaKorekcijskiFaktorji';
    ploscicaKorekcijskiFaktorji.Left := 60;
    ploscicaKorekcijskiFaktorji.Top := 1025;
    ploscicaKorekcijskiFaktorji.Width := 600;
    ploscicaKorekcijskiFaktorji.Height := 230;
    ploscicaKorekcijskiFaktorji.HeightPercent := 100.000000000000000000;
    ploscicaKorekcijskiFaktorji.WidthPercent := 100.000000000000000000;
    ploscicaKorekcijskiFaktorji.ChildOrder := 6;
    ploscicaKorekcijskiFaktorji.ColumnCollection.Clear;
    with ploscicaKorekcijskiFaktorji.ColumnCollection.Add do
    begin
      Value := 59;
    end;
    with ploscicaKorekcijskiFaktorji.ColumnCollection.Add do
    begin
      Alignment := taCenter;
      Value := 5;
    end;
    with ploscicaKorekcijskiFaktorji.ColumnCollection.Add do
    begin
      Alignment := taCenter;
      Value := 7;
    end;
    with ploscicaKorekcijskiFaktorji.ColumnCollection.Add do
    begin
      Alignment := taCenter;
      Value := 29;
    end;
    ploscicaKorekcijskiFaktorji.ControlCollection.Clear;
    with ploscicaKorekcijskiFaktorji.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izPloscicaKorekcijskiFaktorji_Naslov1;
    end;
    with ploscicaKorekcijskiFaktorji.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izPloscicaKorekcijskiFaktorji_Naslov2;
    end;
    with ploscicaKorekcijskiFaktorji.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izPloscicaKorekcijskiFaktorji_Naslov3;
    end;
    with ploscicaKorekcijskiFaktorji.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izPloscicaKorekcijskiFaktorji_Naslov4;
    end;
    with ploscicaKorekcijskiFaktorji.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izPloscicaKorekcijskiFaktorji_KF1_1;
    end;
    with ploscicaKorekcijskiFaktorji.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izPloscicaKorekcijskiFaktorji_KF1_2;
    end;
    with ploscicaKorekcijskiFaktorji.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izPloscicaKorekcijskiFaktorji_KF1_3;
    end;
    with ploscicaKorekcijskiFaktorji.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPloscicaKorekcijskiFaktor_1;
    end;
    with ploscicaKorekcijskiFaktorji.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izPloscicaKorekcijskiFaktorji_KF2_1;
    end;
    with ploscicaKorekcijskiFaktorji.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izPloscicaKorekcijskiFaktorji_KF2_2;
    end;
    with ploscicaKorekcijskiFaktorji.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izPloscicaKorekcijskiFaktorji_KF2_3;
    end;
    with ploscicaKorekcijskiFaktorji.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPloscicaKorekcijskiFaktor_2;
    end;
    with ploscicaKorekcijskiFaktorji.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izPloscicaKorekcijskiFaktorji_KF3_1;
    end;
    with ploscicaKorekcijskiFaktorji.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izPloscicaKorekcijskiFaktorji_KF3_2;
    end;
    with ploscicaKorekcijskiFaktorji.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izPloscicaKorekcijskiFaktorji_KF3_3;
    end;
    with ploscicaKorekcijskiFaktorji.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPloscicaKorekcijskiFaktor_3;
    end;
    with ploscicaKorekcijskiFaktorji.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izPloscicaKorekcijskiFaktorji_KF4_1;
    end;
    with ploscicaKorekcijskiFaktorji.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izPloscicaKorekcijskiFaktorji_KF4_2;
    end;
    with ploscicaKorekcijskiFaktorji.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izPloscicaKorekcijskiFaktorji_KF4_3;
    end;
    with ploscicaKorekcijskiFaktorji.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPloscicaKorekcijskiFaktor_4;
    end;
    with ploscicaKorekcijskiFaktorji.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izPloscicaKorekcijskiFaktorji_KF5_1;
    end;
    with ploscicaKorekcijskiFaktorji.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izPloscicaKorekcijskiFaktorji_KF5_2;
    end;
    with ploscicaKorekcijskiFaktorji.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izPloscicaKorekcijskiFaktorji_KF5_3;
    end;
    with ploscicaKorekcijskiFaktorji.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPloscicaKorekcijskiFaktor_5;
    end;
    ploscicaKorekcijskiFaktorji.Color := clWhite;
    ploscicaKorekcijskiFaktorji.GridLineColor := clBlack;
    ploscicaKorekcijskiFaktorji.RowCollection.Clear;
    with ploscicaKorekcijskiFaktorji.RowCollection.Add do
    begin
      Value := 17;
    end;
    with ploscicaKorekcijskiFaktorji.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 17;
    end;
    with ploscicaKorekcijskiFaktorji.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 17;
    end;
    with ploscicaKorekcijskiFaktorji.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 17;
    end;
    with ploscicaKorekcijskiFaktorji.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 17;
    end;
    with ploscicaKorekcijskiFaktorji.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 17;
    end;
    izPloscicaKorekcijskiFaktorji_Naslov1.SetParentComponent(ploscicaKorekcijskiFaktorji);
    izPloscicaKorekcijskiFaktorji_Naslov1.Name := 'izPloscicaKorekcijskiFaktorji_Naslov1';
    izPloscicaKorekcijskiFaktorji_Naslov1.Left := 2;
    izPloscicaKorekcijskiFaktorji_Naslov1.Top := 2;
    izPloscicaKorekcijskiFaktorji_Naslov1.Width := 3;
    izPloscicaKorekcijskiFaktorji_Naslov1.Height := 15;
    izPloscicaKorekcijskiFaktorji_Naslov1.Anchors := [];
    izPloscicaKorekcijskiFaktorji_Naslov1.Color := clNone;
    izPloscicaKorekcijskiFaktorji_Naslov1.HeightPercent := 100.000000000000000000;
    izPloscicaKorekcijskiFaktorji_Naslov1.WidthPercent := 100.000000000000000000;
    izPloscicaKorekcijskiFaktorji_Naslov2.SetParentComponent(ploscicaKorekcijskiFaktorji);
    izPloscicaKorekcijskiFaktorji_Naslov2.Name := 'izPloscicaKorekcijskiFaktorji_Naslov2';
    izPloscicaKorekcijskiFaktorji_Naslov2.Left := 367;
    izPloscicaKorekcijskiFaktorji_Naslov2.Top := 2;
    izPloscicaKorekcijskiFaktorji_Naslov2.Width := 3;
    izPloscicaKorekcijskiFaktorji_Naslov2.Height := 15;
    izPloscicaKorekcijskiFaktorji_Naslov2.Anchors := [];
    izPloscicaKorekcijskiFaktorji_Naslov2.Color := clNone;
    izPloscicaKorekcijskiFaktorji_Naslov2.HeightPercent := 100.000000000000000000;
    izPloscicaKorekcijskiFaktorji_Naslov2.WidthPercent := 100.000000000000000000;
    izPloscicaKorekcijskiFaktorji_Naslov3.SetParentComponent(ploscicaKorekcijskiFaktorji);
    izPloscicaKorekcijskiFaktorji_Naslov3.Name := 'izPloscicaKorekcijskiFaktorji_Naslov3';
    izPloscicaKorekcijskiFaktorji_Naslov3.Left := 403;
    izPloscicaKorekcijskiFaktorji_Naslov3.Top := 2;
    izPloscicaKorekcijskiFaktorji_Naslov3.Width := 3;
    izPloscicaKorekcijskiFaktorji_Naslov3.Height := 15;
    izPloscicaKorekcijskiFaktorji_Naslov3.Anchors := [];
    izPloscicaKorekcijskiFaktorji_Naslov3.Color := clNone;
    izPloscicaKorekcijskiFaktorji_Naslov3.HeightPercent := 100.000000000000000000;
    izPloscicaKorekcijskiFaktorji_Naslov3.WidthPercent := 100.000000000000000000;
    izPloscicaKorekcijskiFaktorji_Naslov4.SetParentComponent(ploscicaKorekcijskiFaktorji);
    izPloscicaKorekcijskiFaktorji_Naslov4.Name := 'izPloscicaKorekcijskiFaktorji_Naslov4';
    izPloscicaKorekcijskiFaktorji_Naslov4.Left := 453;
    izPloscicaKorekcijskiFaktorji_Naslov4.Top := 2;
    izPloscicaKorekcijskiFaktorji_Naslov4.Width := 119;
    izPloscicaKorekcijskiFaktorji_Naslov4.Height := 14;
    izPloscicaKorekcijskiFaktorji_Naslov4.Anchors := [];
    izPloscicaKorekcijskiFaktorji_Naslov4.Caption := 'korekcija (v mesecih)';
    izPloscicaKorekcijskiFaktorji_Naslov4.Color := clNone;
    izPloscicaKorekcijskiFaktorji_Naslov4.Font.Charset := DEFAULT_CHARSET;
    izPloscicaKorekcijskiFaktorji_Naslov4.Font.Color := clWindowText;
    izPloscicaKorekcijskiFaktorji_Naslov4.Font.Height := -11;
    izPloscicaKorekcijskiFaktorji_Naslov4.Font.Name := 'Arial';
    izPloscicaKorekcijskiFaktorji_Naslov4.Font.Style := [fsBold];
    izPloscicaKorekcijskiFaktorji_Naslov4.HeightPercent := 100.000000000000000000;
    izPloscicaKorekcijskiFaktorji_Naslov4.ParentFont := False;
    izPloscicaKorekcijskiFaktorji_Naslov4.WidthPercent := 100.000000000000000000;
    izPloscicaKorekcijskiFaktorji_KF1_1.SetParentComponent(ploscicaKorekcijskiFaktorji);
    izPloscicaKorekcijskiFaktorji_KF1_1.Name := 'izPloscicaKorekcijskiFaktorji_KF1_1';
    izPloscicaKorekcijskiFaktorji_KF1_1.Left := 2;
    izPloscicaKorekcijskiFaktorji_KF1_1.Top := 51;
    izPloscicaKorekcijskiFaktorji_KF1_1.Width := 257;
    izPloscicaKorekcijskiFaktorji_KF1_1.Height := 15;
    izPloscicaKorekcijskiFaktorji_KF1_1.Anchors := [];
    izPloscicaKorekcijskiFaktorji_KF1_1.Caption := '- vpliv na okolje (zaradi dela z nevarnimi snovmi)';
    izPloscicaKorekcijskiFaktorji_KF1_1.Color := clNone;
    izPloscicaKorekcijskiFaktorji_KF1_1.HeightPercent := 100.000000000000000000;
    izPloscicaKorekcijskiFaktorji_KF1_1.WidthPercent := 100.000000000000000000;
    izPloscicaKorekcijskiFaktorji_KF1_2.SetParentComponent(ploscicaKorekcijskiFaktorji);
    izPloscicaKorekcijskiFaktorji_KF1_2.Name := 'izPloscicaKorekcijskiFaktorji_KF1_2';
    izPloscicaKorekcijskiFaktorji_KF1_2.Left := 366;
    izPloscicaKorekcijskiFaktorji_KF1_2.Top := 51;
    izPloscicaKorekcijskiFaktorji_KF1_2.Width := 6;
    izPloscicaKorekcijskiFaktorji_KF1_2.Height := 14;
    izPloscicaKorekcijskiFaktorji_KF1_2.Anchors := [];
    izPloscicaKorekcijskiFaktorji_KF1_2.Caption := '+';
    izPloscicaKorekcijskiFaktorji_KF1_2.Color := clNone;
    izPloscicaKorekcijskiFaktorji_KF1_2.Font.Charset := DEFAULT_CHARSET;
    izPloscicaKorekcijskiFaktorji_KF1_2.Font.Color := clWindowText;
    izPloscicaKorekcijskiFaktorji_KF1_2.Font.Height := -11;
    izPloscicaKorekcijskiFaktorji_KF1_2.Font.Name := 'Arial';
    izPloscicaKorekcijskiFaktorji_KF1_2.Font.Style := [fsBold];
    izPloscicaKorekcijskiFaktorji_KF1_2.HeightPercent := 100.000000000000000000;
    izPloscicaKorekcijskiFaktorji_KF1_2.ParentFont := False;
    izPloscicaKorekcijskiFaktorji_KF1_2.WidthPercent := 100.000000000000000000;
    izPloscicaKorekcijskiFaktorji_KF1_3.SetParentComponent(ploscicaKorekcijskiFaktorji);
    izPloscicaKorekcijskiFaktorji_KF1_3.Name := 'izPloscicaKorekcijskiFaktorji_KF1_3';
    izPloscicaKorekcijskiFaktorji_KF1_3.Left := 397;
    izPloscicaKorekcijskiFaktorji_KF1_3.Top := 51;
    izPloscicaKorekcijskiFaktorji_KF1_3.Width := 15;
    izPloscicaKorekcijskiFaktorji_KF1_3.Height := 14;
    izPloscicaKorekcijskiFaktorji_KF1_3.Anchors := [];
    izPloscicaKorekcijskiFaktorji_KF1_3.Caption := 'DA';
    izPloscicaKorekcijskiFaktorji_KF1_3.Color := clNone;
    izPloscicaKorekcijskiFaktorji_KF1_3.Font.Charset := DEFAULT_CHARSET;
    izPloscicaKorekcijskiFaktorji_KF1_3.Font.Color := clWindowText;
    izPloscicaKorekcijskiFaktorji_KF1_3.Font.Height := -11;
    izPloscicaKorekcijskiFaktorji_KF1_3.Font.Name := 'Arial';
    izPloscicaKorekcijskiFaktorji_KF1_3.Font.Style := [fsBold];
    izPloscicaKorekcijskiFaktorji_KF1_3.HeightPercent := 100.000000000000000000;
    izPloscicaKorekcijskiFaktorji_KF1_3.ParentFont := False;
    izPloscicaKorekcijskiFaktorji_KF1_3.WidthPercent := 100.000000000000000000;
    izPloscicaKorekcijskiFaktorji_KF2_1.SetParentComponent(ploscicaKorekcijskiFaktorji);
    izPloscicaKorekcijskiFaktorji_KF2_1.Name := 'izPloscicaKorekcijskiFaktorji_KF2_1';
    izPloscicaKorekcijskiFaktorji_KF2_1.Left := 2;
    izPloscicaKorekcijskiFaktorji_KF2_1.Top := 90;
    izPloscicaKorekcijskiFaktorji_KF2_1.Width := 258;
    izPloscicaKorekcijskiFaktorji_KF2_1.Height := 15;
    izPloscicaKorekcijskiFaktorji_KF2_1.Anchors := [];
    izPloscicaKorekcijskiFaktorji_KF2_1.Caption := '- vpliv na okolje (zaradi vgrajenih nevarnih snovi)';
    izPloscicaKorekcijskiFaktorji_KF2_1.Color := clNone;
    izPloscicaKorekcijskiFaktorji_KF2_1.HeightPercent := 100.000000000000000000;
    izPloscicaKorekcijskiFaktorji_KF2_1.WidthPercent := 100.000000000000000000;
    izPloscicaKorekcijskiFaktorji_KF2_2.SetParentComponent(ploscicaKorekcijskiFaktorji);
    izPloscicaKorekcijskiFaktorji_KF2_2.Name := 'izPloscicaKorekcijskiFaktorji_KF2_2';
    izPloscicaKorekcijskiFaktorji_KF2_2.Left := 366;
    izPloscicaKorekcijskiFaktorji_KF2_2.Top := 90;
    izPloscicaKorekcijskiFaktorji_KF2_2.Width := 6;
    izPloscicaKorekcijskiFaktorji_KF2_2.Height := 14;
    izPloscicaKorekcijskiFaktorji_KF2_2.Anchors := [];
    izPloscicaKorekcijskiFaktorji_KF2_2.Caption := '+';
    izPloscicaKorekcijskiFaktorji_KF2_2.Color := clNone;
    izPloscicaKorekcijskiFaktorji_KF2_2.Font.Charset := DEFAULT_CHARSET;
    izPloscicaKorekcijskiFaktorji_KF2_2.Font.Color := clWindowText;
    izPloscicaKorekcijskiFaktorji_KF2_2.Font.Height := -11;
    izPloscicaKorekcijskiFaktorji_KF2_2.Font.Name := 'Arial';
    izPloscicaKorekcijskiFaktorji_KF2_2.Font.Style := [fsBold];
    izPloscicaKorekcijskiFaktorji_KF2_2.HeightPercent := 100.000000000000000000;
    izPloscicaKorekcijskiFaktorji_KF2_2.ParentFont := False;
    izPloscicaKorekcijskiFaktorji_KF2_2.WidthPercent := 100.000000000000000000;
    izPloscicaKorekcijskiFaktorji_KF2_3.SetParentComponent(ploscicaKorekcijskiFaktorji);
    izPloscicaKorekcijskiFaktorji_KF2_3.Name := 'izPloscicaKorekcijskiFaktorji_KF2_3';
    izPloscicaKorekcijskiFaktorji_KF2_3.Left := 397;
    izPloscicaKorekcijskiFaktorji_KF2_3.Top := 90;
    izPloscicaKorekcijskiFaktorji_KF2_3.Width := 15;
    izPloscicaKorekcijskiFaktorji_KF2_3.Height := 14;
    izPloscicaKorekcijskiFaktorji_KF2_3.Anchors := [];
    izPloscicaKorekcijskiFaktorji_KF2_3.Caption := 'DA';
    izPloscicaKorekcijskiFaktorji_KF2_3.Color := clNone;
    izPloscicaKorekcijskiFaktorji_KF2_3.Font.Charset := DEFAULT_CHARSET;
    izPloscicaKorekcijskiFaktorji_KF2_3.Font.Color := clWindowText;
    izPloscicaKorekcijskiFaktorji_KF2_3.Font.Height := -11;
    izPloscicaKorekcijskiFaktorji_KF2_3.Font.Name := 'Arial';
    izPloscicaKorekcijskiFaktorji_KF2_3.Font.Style := [fsBold];
    izPloscicaKorekcijskiFaktorji_KF2_3.HeightPercent := 100.000000000000000000;
    izPloscicaKorekcijskiFaktorji_KF2_3.ParentFont := False;
    izPloscicaKorekcijskiFaktorji_KF2_3.WidthPercent := 100.000000000000000000;
    izPloscicaKorekcijskiFaktorji_KF3_1.SetParentComponent(ploscicaKorekcijskiFaktorji);
    izPloscicaKorekcijskiFaktorji_KF3_1.Name := 'izPloscicaKorekcijskiFaktorji_KF3_1';
    izPloscicaKorekcijskiFaktorji_KF3_1.Left := 2;
    izPloscicaKorekcijskiFaktorji_KF3_1.Top := 129;
    izPloscicaKorekcijskiFaktorji_KF3_1.Width := 129;
    izPloscicaKorekcijskiFaktorji_KF3_1.Height := 15;
    izPloscicaKorekcijskiFaktorji_KF3_1.Anchors := [];
    izPloscicaKorekcijskiFaktorji_KF3_1.Caption := '- posebni delovni pogoji';
    izPloscicaKorekcijskiFaktorji_KF3_1.Color := clNone;
    izPloscicaKorekcijskiFaktorji_KF3_1.HeightPercent := 100.000000000000000000;
    izPloscicaKorekcijskiFaktorji_KF3_1.WidthPercent := 100.000000000000000000;
    izPloscicaKorekcijskiFaktorji_KF3_2.SetParentComponent(ploscicaKorekcijskiFaktorji);
    izPloscicaKorekcijskiFaktorji_KF3_2.Name := 'izPloscicaKorekcijskiFaktorji_KF3_2';
    izPloscicaKorekcijskiFaktorji_KF3_2.Left := 366;
    izPloscicaKorekcijskiFaktorji_KF3_2.Top := 129;
    izPloscicaKorekcijskiFaktorji_KF3_2.Width := 6;
    izPloscicaKorekcijskiFaktorji_KF3_2.Height := 14;
    izPloscicaKorekcijskiFaktorji_KF3_2.Anchors := [];
    izPloscicaKorekcijskiFaktorji_KF3_2.Caption := '+';
    izPloscicaKorekcijskiFaktorji_KF3_2.Color := clNone;
    izPloscicaKorekcijskiFaktorji_KF3_2.Font.Charset := DEFAULT_CHARSET;
    izPloscicaKorekcijskiFaktorji_KF3_2.Font.Color := clWindowText;
    izPloscicaKorekcijskiFaktorji_KF3_2.Font.Height := -11;
    izPloscicaKorekcijskiFaktorji_KF3_2.Font.Name := 'Arial';
    izPloscicaKorekcijskiFaktorji_KF3_2.Font.Style := [fsBold];
    izPloscicaKorekcijskiFaktorji_KF3_2.HeightPercent := 100.000000000000000000;
    izPloscicaKorekcijskiFaktorji_KF3_2.ParentFont := False;
    izPloscicaKorekcijskiFaktorji_KF3_2.WidthPercent := 100.000000000000000000;
    izPloscicaKorekcijskiFaktorji_KF3_3.SetParentComponent(ploscicaKorekcijskiFaktorji);
    izPloscicaKorekcijskiFaktorji_KF3_3.Name := 'izPloscicaKorekcijskiFaktorji_KF3_3';
    izPloscicaKorekcijskiFaktorji_KF3_3.Left := 397;
    izPloscicaKorekcijskiFaktorji_KF3_3.Top := 129;
    izPloscicaKorekcijskiFaktorji_KF3_3.Width := 15;
    izPloscicaKorekcijskiFaktorji_KF3_3.Height := 14;
    izPloscicaKorekcijskiFaktorji_KF3_3.Anchors := [];
    izPloscicaKorekcijskiFaktorji_KF3_3.Caption := 'DA';
    izPloscicaKorekcijskiFaktorji_KF3_3.Color := clNone;
    izPloscicaKorekcijskiFaktorji_KF3_3.Font.Charset := DEFAULT_CHARSET;
    izPloscicaKorekcijskiFaktorji_KF3_3.Font.Color := clWindowText;
    izPloscicaKorekcijskiFaktorji_KF3_3.Font.Height := -11;
    izPloscicaKorekcijskiFaktorji_KF3_3.Font.Name := 'Arial';
    izPloscicaKorekcijskiFaktorji_KF3_3.Font.Style := [fsBold];
    izPloscicaKorekcijskiFaktorji_KF3_3.HeightPercent := 100.000000000000000000;
    izPloscicaKorekcijskiFaktorji_KF3_3.ParentFont := False;
    izPloscicaKorekcijskiFaktorji_KF3_3.WidthPercent := 100.000000000000000000;
    izPloscicaKorekcijskiFaktorji_KF4_1.SetParentComponent(ploscicaKorekcijskiFaktorji);
    izPloscicaKorekcijskiFaktorji_KF4_1.Name := 'izPloscicaKorekcijskiFaktorji_KF4_1';
    izPloscicaKorekcijskiFaktorji_KF4_1.Left := 2;
    izPloscicaKorekcijskiFaktorji_KF4_1.Top := 168;
    izPloscicaKorekcijskiFaktorji_KF4_1.Width := 265;
    izPloscicaKorekcijskiFaktorji_KF4_1.Height := 15;
    izPloscicaKorekcijskiFaktorji_KF4_1.Anchors := [];
    izPloscicaKorekcijskiFaktorji_KF4_1.Caption := '- mo'#382'nost nakupa rabljenih vitalnih strojiev ali linij';
    izPloscicaKorekcijskiFaktorji_KF4_1.Color := clNone;
    izPloscicaKorekcijskiFaktorji_KF4_1.HeightPercent := 100.000000000000000000;
    izPloscicaKorekcijskiFaktorji_KF4_1.WidthPercent := 100.000000000000000000;
    izPloscicaKorekcijskiFaktorji_KF4_2.SetParentComponent(ploscicaKorekcijskiFaktorji);
    izPloscicaKorekcijskiFaktorji_KF4_2.Name := 'izPloscicaKorekcijskiFaktorji_KF4_2';
    izPloscicaKorekcijskiFaktorji_KF4_2.Left := 367;
    izPloscicaKorekcijskiFaktorji_KF4_2.Top := 168;
    izPloscicaKorekcijskiFaktorji_KF4_2.Width := 4;
    izPloscicaKorekcijskiFaktorji_KF4_2.Height := 14;
    izPloscicaKorekcijskiFaktorji_KF4_2.Anchors := [];
    izPloscicaKorekcijskiFaktorji_KF4_2.Caption := '-';
    izPloscicaKorekcijskiFaktorji_KF4_2.Color := clNone;
    izPloscicaKorekcijskiFaktorji_KF4_2.Font.Charset := DEFAULT_CHARSET;
    izPloscicaKorekcijskiFaktorji_KF4_2.Font.Color := clWindowText;
    izPloscicaKorekcijskiFaktorji_KF4_2.Font.Height := -11;
    izPloscicaKorekcijskiFaktorji_KF4_2.Font.Name := 'Arial';
    izPloscicaKorekcijskiFaktorji_KF4_2.Font.Style := [fsBold];
    izPloscicaKorekcijskiFaktorji_KF4_2.HeightPercent := 100.000000000000000000;
    izPloscicaKorekcijskiFaktorji_KF4_2.ParentFont := False;
    izPloscicaKorekcijskiFaktorji_KF4_2.WidthPercent := 100.000000000000000000;
    izPloscicaKorekcijskiFaktorji_KF4_3.SetParentComponent(ploscicaKorekcijskiFaktorji);
    izPloscicaKorekcijskiFaktorji_KF4_3.Name := 'izPloscicaKorekcijskiFaktorji_KF4_3';
    izPloscicaKorekcijskiFaktorji_KF4_3.Left := 397;
    izPloscicaKorekcijskiFaktorji_KF4_3.Top := 168;
    izPloscicaKorekcijskiFaktorji_KF4_3.Width := 15;
    izPloscicaKorekcijskiFaktorji_KF4_3.Height := 14;
    izPloscicaKorekcijskiFaktorji_KF4_3.Anchors := [];
    izPloscicaKorekcijskiFaktorji_KF4_3.Caption := 'DA';
    izPloscicaKorekcijskiFaktorji_KF4_3.Color := clNone;
    izPloscicaKorekcijskiFaktorji_KF4_3.Font.Charset := DEFAULT_CHARSET;
    izPloscicaKorekcijskiFaktorji_KF4_3.Font.Color := clWindowText;
    izPloscicaKorekcijskiFaktorji_KF4_3.Font.Height := -11;
    izPloscicaKorekcijskiFaktorji_KF4_3.Font.Name := 'Arial';
    izPloscicaKorekcijskiFaktorji_KF4_3.Font.Style := [fsBold];
    izPloscicaKorekcijskiFaktorji_KF4_3.HeightPercent := 100.000000000000000000;
    izPloscicaKorekcijskiFaktorji_KF4_3.ParentFont := False;
    izPloscicaKorekcijskiFaktorji_KF4_3.WidthPercent := 100.000000000000000000;
    izPloscicaKorekcijskiFaktorji_KF5_1.SetParentComponent(ploscicaKorekcijskiFaktorji);
    izPloscicaKorekcijskiFaktorji_KF5_1.Name := 'izPloscicaKorekcijskiFaktorji_KF5_1';
    izPloscicaKorekcijskiFaktorji_KF5_1.Left := 2;
    izPloscicaKorekcijskiFaktorji_KF5_1.Top := 207;
    izPloscicaKorekcijskiFaktorji_KF5_1.Width := 266;
    izPloscicaKorekcijskiFaktorji_KF5_1.Height := 15;
    izPloscicaKorekcijskiFaktorji_KF5_1.Anchors := [];
    izPloscicaKorekcijskiFaktorji_KF5_1.Caption := '- delo v izmenah in proste proizvodne zmogljivosti';
    izPloscicaKorekcijskiFaktorji_KF5_1.Color := clNone;
    izPloscicaKorekcijskiFaktorji_KF5_1.HeightPercent := 100.000000000000000000;
    izPloscicaKorekcijskiFaktorji_KF5_1.WidthPercent := 100.000000000000000000;
    izPloscicaKorekcijskiFaktorji_KF5_2.SetParentComponent(ploscicaKorekcijskiFaktorji);
    izPloscicaKorekcijskiFaktorji_KF5_2.Name := 'izPloscicaKorekcijskiFaktorji_KF5_2';
    izPloscicaKorekcijskiFaktorji_KF5_2.Left := 367;
    izPloscicaKorekcijskiFaktorji_KF5_2.Top := 207;
    izPloscicaKorekcijskiFaktorji_KF5_2.Width := 4;
    izPloscicaKorekcijskiFaktorji_KF5_2.Height := 14;
    izPloscicaKorekcijskiFaktorji_KF5_2.Anchors := [];
    izPloscicaKorekcijskiFaktorji_KF5_2.Caption := '-';
    izPloscicaKorekcijskiFaktorji_KF5_2.Color := clNone;
    izPloscicaKorekcijskiFaktorji_KF5_2.Font.Charset := DEFAULT_CHARSET;
    izPloscicaKorekcijskiFaktorji_KF5_2.Font.Color := clWindowText;
    izPloscicaKorekcijskiFaktorji_KF5_2.Font.Height := -11;
    izPloscicaKorekcijskiFaktorji_KF5_2.Font.Name := 'Arial';
    izPloscicaKorekcijskiFaktorji_KF5_2.Font.Style := [fsBold];
    izPloscicaKorekcijskiFaktorji_KF5_2.HeightPercent := 100.000000000000000000;
    izPloscicaKorekcijskiFaktorji_KF5_2.ParentFont := False;
    izPloscicaKorekcijskiFaktorji_KF5_2.WidthPercent := 100.000000000000000000;
    izPloscicaKorekcijskiFaktorji_KF5_3.SetParentComponent(ploscicaKorekcijskiFaktorji);
    izPloscicaKorekcijskiFaktorji_KF5_3.Name := 'izPloscicaKorekcijskiFaktorji_KF5_3';
    izPloscicaKorekcijskiFaktorji_KF5_3.Left := 397;
    izPloscicaKorekcijskiFaktorji_KF5_3.Top := 207;
    izPloscicaKorekcijskiFaktorji_KF5_3.Width := 15;
    izPloscicaKorekcijskiFaktorji_KF5_3.Height := 14;
    izPloscicaKorekcijskiFaktorji_KF5_3.Anchors := [];
    izPloscicaKorekcijskiFaktorji_KF5_3.Caption := 'DA';
    izPloscicaKorekcijskiFaktorji_KF5_3.Color := clNone;
    izPloscicaKorekcijskiFaktorji_KF5_3.Font.Charset := DEFAULT_CHARSET;
    izPloscicaKorekcijskiFaktorji_KF5_3.Font.Color := clWindowText;
    izPloscicaKorekcijskiFaktorji_KF5_3.Font.Height := -11;
    izPloscicaKorekcijskiFaktorji_KF5_3.Font.Name := 'Arial';
    izPloscicaKorekcijskiFaktorji_KF5_3.Font.Style := [fsBold];
    izPloscicaKorekcijskiFaktorji_KF5_3.HeightPercent := 100.000000000000000000;
    izPloscicaKorekcijskiFaktorji_KF5_3.ParentFont := False;
    izPloscicaKorekcijskiFaktorji_KF5_3.WidthPercent := 100.000000000000000000;
    ploscicaPloscicaKorekcijskiFaktor_1.SetParentComponent(ploscicaKorekcijskiFaktorji);
    ploscicaPloscicaKorekcijskiFaktor_1.Name := 'ploscicaPloscicaKorekcijskiFaktor_1';
    ploscicaPloscicaKorekcijskiFaktor_1.Left := 428;
    ploscicaPloscicaKorekcijskiFaktor_1.Top := 47;
    ploscicaPloscicaKorekcijskiFaktor_1.Width := 170;
    ploscicaPloscicaKorekcijskiFaktor_1.Height := 22;
    ploscicaPloscicaKorekcijskiFaktor_1.HeightPercent := 100.000000000000000000;
    ploscicaPloscicaKorekcijskiFaktor_1.WidthPercent := 100.000000000000000000;
    ploscicaPloscicaKorekcijskiFaktor_1.Anchors := [];
    ploscicaPloscicaKorekcijskiFaktor_1.BorderColor := clNone;
    ploscicaPloscicaKorekcijskiFaktor_1.BorderStyle := bsNone;
    ploscicaPloscicaKorekcijskiFaktor_1.ChildOrder := 7;
    ploscicaPloscicaKorekcijskiFaktor_1.Color := clWindow;
    vpPloscicaKorekcijskiFaktorji_KFIzpis1_4.SetParentComponent(ploscicaPloscicaKorekcijskiFaktor_1);
    vpPloscicaKorekcijskiFaktorji_KFIzpis1_4.Name := 'vpPloscicaKorekcijskiFaktorji_KFIzpis1_4';
    vpPloscicaKorekcijskiFaktorji_KFIzpis1_4.Left := 130;
    vpPloscicaKorekcijskiFaktorji_KFIzpis1_4.Top := 0;
    vpPloscicaKorekcijskiFaktorji_KFIzpis1_4.Width := 40;
    vpPloscicaKorekcijskiFaktorji_KFIzpis1_4.Height := 18;
    vpPloscicaKorekcijskiFaktorji_KFIzpis1_4.Alignment := taCenter;
    vpPloscicaKorekcijskiFaktorji_KFIzpis1_4.AutoSize := False;
    vpPloscicaKorekcijskiFaktorji_KFIzpis1_4.Caption := '0,00';
    vpPloscicaKorekcijskiFaktorji_KFIzpis1_4.Color := clNone;
    vpPloscicaKorekcijskiFaktorji_KFIzpis1_4.HeightPercent := 100.000000000000000000;
    vpPloscicaKorekcijskiFaktorji_KFIzpis1_4.WidthPercent := 100.000000000000000000;
    vpPloscicaKorekcijskiFaktorji_KFDrsnik1_4.SetParentComponent(ploscicaPloscicaKorekcijskiFaktor_1);
    vpPloscicaKorekcijskiFaktorji_KFDrsnik1_4.Name := 'vpPloscicaKorekcijskiFaktorji_KFDrsnik1_4';
    vpPloscicaKorekcijskiFaktorji_KFDrsnik1_4.Left := 7;
    vpPloscicaKorekcijskiFaktorji_KFDrsnik1_4.Top := -3;
    vpPloscicaKorekcijskiFaktorji_KFDrsnik1_4.Width := 125;
    vpPloscicaKorekcijskiFaktorji_KFDrsnik1_4.Height := 18;
    vpPloscicaKorekcijskiFaktorji_KFDrsnik1_4.HeightPercent := 100.000000000000000000;
    vpPloscicaKorekcijskiFaktorji_KFDrsnik1_4.WidthPercent := 100.000000000000000000;
    vpPloscicaKorekcijskiFaktorji_KFDrsnik1_4.Anchors := [];
    vpPloscicaKorekcijskiFaktorji_KFDrsnik1_4.Max := 24;
    vpPloscicaKorekcijskiFaktorji_KFDrsnik1_4.Min := 0;
    vpPloscicaKorekcijskiFaktorji_KFDrsnik1_4.Position := 0;
    vpPloscicaKorekcijskiFaktorji_KFDrsnik1_4.Role := '';
    SetEvent(vpPloscicaKorekcijskiFaktorji_KFDrsnik1_4, Self, 'OnChange', 'vpPloscicaKorekcijskiFaktorji_KFDrsnik');
    ploscicaPloscicaKorekcijskiFaktor_2.SetParentComponent(ploscicaKorekcijskiFaktorji);
    ploscicaPloscicaKorekcijskiFaktor_2.Name := 'ploscicaPloscicaKorekcijskiFaktor_2';
    ploscicaPloscicaKorekcijskiFaktor_2.Left := 428;
    ploscicaPloscicaKorekcijskiFaktor_2.Top := 86;
    ploscicaPloscicaKorekcijskiFaktor_2.Width := 170;
    ploscicaPloscicaKorekcijskiFaktor_2.Height := 22;
    ploscicaPloscicaKorekcijskiFaktor_2.HeightPercent := 100.000000000000000000;
    ploscicaPloscicaKorekcijskiFaktor_2.WidthPercent := 100.000000000000000000;
    ploscicaPloscicaKorekcijskiFaktor_2.Anchors := [];
    ploscicaPloscicaKorekcijskiFaktor_2.BorderColor := clNone;
    ploscicaPloscicaKorekcijskiFaktor_2.BorderStyle := bsNone;
    ploscicaPloscicaKorekcijskiFaktor_2.ChildOrder := 11;
    ploscicaPloscicaKorekcijskiFaktor_2.Color := clWindow;
    vpPloscicaKorekcijskiFaktorji_KFIzpis2_4.SetParentComponent(ploscicaPloscicaKorekcijskiFaktor_2);
    vpPloscicaKorekcijskiFaktorji_KFIzpis2_4.Name := 'vpPloscicaKorekcijskiFaktorji_KFIzpis2_4';
    vpPloscicaKorekcijskiFaktorji_KFIzpis2_4.Left := 130;
    vpPloscicaKorekcijskiFaktorji_KFIzpis2_4.Top := 7;
    vpPloscicaKorekcijskiFaktorji_KFIzpis2_4.Width := 40;
    vpPloscicaKorekcijskiFaktorji_KFIzpis2_4.Height := 18;
    vpPloscicaKorekcijskiFaktorji_KFIzpis2_4.Alignment := taCenter;
    vpPloscicaKorekcijskiFaktorji_KFIzpis2_4.AutoSize := False;
    vpPloscicaKorekcijskiFaktorji_KFIzpis2_4.Caption := '0,00';
    vpPloscicaKorekcijskiFaktorji_KFIzpis2_4.Color := clNone;
    vpPloscicaKorekcijskiFaktorji_KFIzpis2_4.HeightPercent := 100.000000000000000000;
    vpPloscicaKorekcijskiFaktorji_KFIzpis2_4.WidthPercent := 100.000000000000000000;
    vpPloscicaKorekcijskiFaktorji_KFDrsnik2_4.SetParentComponent(ploscicaPloscicaKorekcijskiFaktor_2);
    vpPloscicaKorekcijskiFaktorji_KFDrsnik2_4.Name := 'vpPloscicaKorekcijskiFaktorji_KFDrsnik2_4';
    vpPloscicaKorekcijskiFaktorji_KFDrsnik2_4.Left := 6;
    vpPloscicaKorekcijskiFaktorji_KFDrsnik2_4.Top := 2;
    vpPloscicaKorekcijskiFaktorji_KFDrsnik2_4.Width := 125;
    vpPloscicaKorekcijskiFaktorji_KFDrsnik2_4.Height := 18;
    vpPloscicaKorekcijskiFaktorji_KFDrsnik2_4.HeightPercent := 100.000000000000000000;
    vpPloscicaKorekcijskiFaktorji_KFDrsnik2_4.WidthPercent := 100.000000000000000000;
    vpPloscicaKorekcijskiFaktorji_KFDrsnik2_4.Anchors := [];
    vpPloscicaKorekcijskiFaktorji_KFDrsnik2_4.Max := 24;
    vpPloscicaKorekcijskiFaktorji_KFDrsnik2_4.Min := 0;
    vpPloscicaKorekcijskiFaktorji_KFDrsnik2_4.Position := 0;
    vpPloscicaKorekcijskiFaktorji_KFDrsnik2_4.Role := '';
    SetEvent(vpPloscicaKorekcijskiFaktorji_KFDrsnik2_4, Self, 'OnChange', 'vpPloscicaKorekcijskiFaktorji_KFDrsnik');
    ploscicaPloscicaKorekcijskiFaktor_3.SetParentComponent(ploscicaKorekcijskiFaktorji);
    ploscicaPloscicaKorekcijskiFaktor_3.Name := 'ploscicaPloscicaKorekcijskiFaktor_3';
    ploscicaPloscicaKorekcijskiFaktor_3.Left := 428;
    ploscicaPloscicaKorekcijskiFaktor_3.Top := 125;
    ploscicaPloscicaKorekcijskiFaktor_3.Width := 170;
    ploscicaPloscicaKorekcijskiFaktor_3.Height := 22;
    ploscicaPloscicaKorekcijskiFaktor_3.HeightPercent := 100.000000000000000000;
    ploscicaPloscicaKorekcijskiFaktor_3.WidthPercent := 100.000000000000000000;
    ploscicaPloscicaKorekcijskiFaktor_3.Anchors := [];
    ploscicaPloscicaKorekcijskiFaktor_3.BorderColor := clNone;
    ploscicaPloscicaKorekcijskiFaktor_3.BorderStyle := bsNone;
    ploscicaPloscicaKorekcijskiFaktor_3.ChildOrder := 15;
    ploscicaPloscicaKorekcijskiFaktor_3.Color := clWindow;
    vpPloscicaKorekcijskiFaktorji_KFIzpis3_4.SetParentComponent(ploscicaPloscicaKorekcijskiFaktor_3);
    vpPloscicaKorekcijskiFaktorji_KFIzpis3_4.Name := 'vpPloscicaKorekcijskiFaktorji_KFIzpis3_4';
    vpPloscicaKorekcijskiFaktorji_KFIzpis3_4.Left := 130;
    vpPloscicaKorekcijskiFaktorji_KFIzpis3_4.Top := 7;
    vpPloscicaKorekcijskiFaktorji_KFIzpis3_4.Width := 40;
    vpPloscicaKorekcijskiFaktorji_KFIzpis3_4.Height := 18;
    vpPloscicaKorekcijskiFaktorji_KFIzpis3_4.Alignment := taCenter;
    vpPloscicaKorekcijskiFaktorji_KFIzpis3_4.AutoSize := False;
    vpPloscicaKorekcijskiFaktorji_KFIzpis3_4.Caption := '0,00';
    vpPloscicaKorekcijskiFaktorji_KFIzpis3_4.Color := clNone;
    vpPloscicaKorekcijskiFaktorji_KFIzpis3_4.HeightPercent := 100.000000000000000000;
    vpPloscicaKorekcijskiFaktorji_KFIzpis3_4.WidthPercent := 100.000000000000000000;
    vpPloscicaKorekcijskiFaktorji_KFDrsnik3_4.SetParentComponent(ploscicaPloscicaKorekcijskiFaktor_3);
    vpPloscicaKorekcijskiFaktorji_KFDrsnik3_4.Name := 'vpPloscicaKorekcijskiFaktorji_KFDrsnik3_4';
    vpPloscicaKorekcijskiFaktorji_KFDrsnik3_4.Left := 6;
    vpPloscicaKorekcijskiFaktorji_KFDrsnik3_4.Top := 2;
    vpPloscicaKorekcijskiFaktorji_KFDrsnik3_4.Width := 125;
    vpPloscicaKorekcijskiFaktorji_KFDrsnik3_4.Height := 18;
    vpPloscicaKorekcijskiFaktorji_KFDrsnik3_4.HeightPercent := 100.000000000000000000;
    vpPloscicaKorekcijskiFaktorji_KFDrsnik3_4.WidthPercent := 100.000000000000000000;
    vpPloscicaKorekcijskiFaktorji_KFDrsnik3_4.Anchors := [];
    vpPloscicaKorekcijskiFaktorji_KFDrsnik3_4.Max := 24;
    vpPloscicaKorekcijskiFaktorji_KFDrsnik3_4.Min := 0;
    vpPloscicaKorekcijskiFaktorji_KFDrsnik3_4.Position := 0;
    vpPloscicaKorekcijskiFaktorji_KFDrsnik3_4.Role := '';
    SetEvent(vpPloscicaKorekcijskiFaktorji_KFDrsnik3_4, Self, 'OnChange', 'vpPloscicaKorekcijskiFaktorji_KFDrsnik');
    ploscicaPloscicaKorekcijskiFaktor_4.SetParentComponent(ploscicaKorekcijskiFaktorji);
    ploscicaPloscicaKorekcijskiFaktor_4.Name := 'ploscicaPloscicaKorekcijskiFaktor_4';
    ploscicaPloscicaKorekcijskiFaktor_4.Left := 428;
    ploscicaPloscicaKorekcijskiFaktor_4.Top := 164;
    ploscicaPloscicaKorekcijskiFaktor_4.Width := 170;
    ploscicaPloscicaKorekcijskiFaktor_4.Height := 22;
    ploscicaPloscicaKorekcijskiFaktor_4.HeightPercent := 100.000000000000000000;
    ploscicaPloscicaKorekcijskiFaktor_4.WidthPercent := 100.000000000000000000;
    ploscicaPloscicaKorekcijskiFaktor_4.Anchors := [];
    ploscicaPloscicaKorekcijskiFaktor_4.BorderColor := clNone;
    ploscicaPloscicaKorekcijskiFaktor_4.BorderStyle := bsNone;
    ploscicaPloscicaKorekcijskiFaktor_4.ChildOrder := 19;
    ploscicaPloscicaKorekcijskiFaktor_4.Color := clWindow;
    vpPloscicaKorekcijskiFaktorji_KFIzpis4_4.SetParentComponent(ploscicaPloscicaKorekcijskiFaktor_4);
    vpPloscicaKorekcijskiFaktorji_KFIzpis4_4.Name := 'vpPloscicaKorekcijskiFaktorji_KFIzpis4_4';
    vpPloscicaKorekcijskiFaktorji_KFIzpis4_4.Left := 130;
    vpPloscicaKorekcijskiFaktorji_KFIzpis4_4.Top := 7;
    vpPloscicaKorekcijskiFaktorji_KFIzpis4_4.Width := 40;
    vpPloscicaKorekcijskiFaktorji_KFIzpis4_4.Height := 18;
    vpPloscicaKorekcijskiFaktorji_KFIzpis4_4.Alignment := taCenter;
    vpPloscicaKorekcijskiFaktorji_KFIzpis4_4.AutoSize := False;
    vpPloscicaKorekcijskiFaktorji_KFIzpis4_4.Caption := '0,00';
    vpPloscicaKorekcijskiFaktorji_KFIzpis4_4.Color := clNone;
    vpPloscicaKorekcijskiFaktorji_KFIzpis4_4.HeightPercent := 100.000000000000000000;
    vpPloscicaKorekcijskiFaktorji_KFIzpis4_4.WidthPercent := 100.000000000000000000;
    vpPloscicaKorekcijskiFaktorji_KFDrsnik4_4.SetParentComponent(ploscicaPloscicaKorekcijskiFaktor_4);
    vpPloscicaKorekcijskiFaktorji_KFDrsnik4_4.Name := 'vpPloscicaKorekcijskiFaktorji_KFDrsnik4_4';
    vpPloscicaKorekcijskiFaktorji_KFDrsnik4_4.Left := 6;
    vpPloscicaKorekcijskiFaktorji_KFDrsnik4_4.Top := 2;
    vpPloscicaKorekcijskiFaktorji_KFDrsnik4_4.Width := 125;
    vpPloscicaKorekcijskiFaktorji_KFDrsnik4_4.Height := 18;
    vpPloscicaKorekcijskiFaktorji_KFDrsnik4_4.HeightPercent := 100.000000000000000000;
    vpPloscicaKorekcijskiFaktorji_KFDrsnik4_4.WidthPercent := 100.000000000000000000;
    vpPloscicaKorekcijskiFaktorji_KFDrsnik4_4.Anchors := [];
    vpPloscicaKorekcijskiFaktorji_KFDrsnik4_4.Max := 24;
    vpPloscicaKorekcijskiFaktorji_KFDrsnik4_4.Min := 0;
    vpPloscicaKorekcijskiFaktorji_KFDrsnik4_4.Position := 0;
    vpPloscicaKorekcijskiFaktorji_KFDrsnik4_4.Role := '';
    SetEvent(vpPloscicaKorekcijskiFaktorji_KFDrsnik4_4, Self, 'OnChange', 'vpPloscicaKorekcijskiFaktorji_KFDrsnik');
    ploscicaPloscicaKorekcijskiFaktor_5.SetParentComponent(ploscicaKorekcijskiFaktorji);
    ploscicaPloscicaKorekcijskiFaktor_5.Name := 'ploscicaPloscicaKorekcijskiFaktor_5';
    ploscicaPloscicaKorekcijskiFaktor_5.Left := 428;
    ploscicaPloscicaKorekcijskiFaktor_5.Top := 203;
    ploscicaPloscicaKorekcijskiFaktor_5.Width := 170;
    ploscicaPloscicaKorekcijskiFaktor_5.Height := 22;
    ploscicaPloscicaKorekcijskiFaktor_5.HeightPercent := 100.000000000000000000;
    ploscicaPloscicaKorekcijskiFaktor_5.WidthPercent := 100.000000000000000000;
    ploscicaPloscicaKorekcijskiFaktor_5.Anchors := [];
    ploscicaPloscicaKorekcijskiFaktor_5.BorderColor := clNone;
    ploscicaPloscicaKorekcijskiFaktor_5.BorderStyle := bsNone;
    ploscicaPloscicaKorekcijskiFaktor_5.ChildOrder := 23;
    ploscicaPloscicaKorekcijskiFaktor_5.Color := clWindow;
    vpPloscicaKorekcijskiFaktorji_KFIzpis5_4.SetParentComponent(ploscicaPloscicaKorekcijskiFaktor_5);
    vpPloscicaKorekcijskiFaktorji_KFIzpis5_4.Name := 'vpPloscicaKorekcijskiFaktorji_KFIzpis5_4';
    vpPloscicaKorekcijskiFaktorji_KFIzpis5_4.Left := 130;
    vpPloscicaKorekcijskiFaktorji_KFIzpis5_4.Top := 7;
    vpPloscicaKorekcijskiFaktorji_KFIzpis5_4.Width := 40;
    vpPloscicaKorekcijskiFaktorji_KFIzpis5_4.Height := 18;
    vpPloscicaKorekcijskiFaktorji_KFIzpis5_4.Alignment := taCenter;
    vpPloscicaKorekcijskiFaktorji_KFIzpis5_4.AutoSize := False;
    vpPloscicaKorekcijskiFaktorji_KFIzpis5_4.Caption := '0,00';
    vpPloscicaKorekcijskiFaktorji_KFIzpis5_4.Color := clNone;
    vpPloscicaKorekcijskiFaktorji_KFIzpis5_4.HeightPercent := 100.000000000000000000;
    vpPloscicaKorekcijskiFaktorji_KFIzpis5_4.WidthPercent := 100.000000000000000000;
    vpPloscicaKorekcijskiFaktorji_KFDrsnik5_4.SetParentComponent(ploscicaPloscicaKorekcijskiFaktor_5);
    vpPloscicaKorekcijskiFaktorji_KFDrsnik5_4.Name := 'vpPloscicaKorekcijskiFaktorji_KFDrsnik5_4';
    vpPloscicaKorekcijskiFaktorji_KFDrsnik5_4.Left := 6;
    vpPloscicaKorekcijskiFaktorji_KFDrsnik5_4.Top := 2;
    vpPloscicaKorekcijskiFaktorji_KFDrsnik5_4.Width := 125;
    vpPloscicaKorekcijskiFaktorji_KFDrsnik5_4.Height := 18;
    vpPloscicaKorekcijskiFaktorji_KFDrsnik5_4.HeightPercent := 100.000000000000000000;
    vpPloscicaKorekcijskiFaktorji_KFDrsnik5_4.WidthPercent := 100.000000000000000000;
    vpPloscicaKorekcijskiFaktorji_KFDrsnik5_4.Anchors := [];
    vpPloscicaKorekcijskiFaktorji_KFDrsnik5_4.Max := 24;
    vpPloscicaKorekcijskiFaktorji_KFDrsnik5_4.Min := 0;
    vpPloscicaKorekcijskiFaktorji_KFDrsnik5_4.Position := 0;
    vpPloscicaKorekcijskiFaktorji_KFDrsnik5_4.Role := '';
    SetEvent(vpPloscicaKorekcijskiFaktorji_KFDrsnik5_4, Self, 'OnChange', 'vpPloscicaKorekcijskiFaktorji_KFDrsnik');
    ploscicaMaksimalniPribitek.SetParentComponent(WebScrollBoxDL6);
    ploscicaMaksimalniPribitek.Name := 'ploscicaMaksimalniPribitek';
    ploscicaMaksimalniPribitek.Left := 382;
    ploscicaMaksimalniPribitek.Top := 990;
    ploscicaMaksimalniPribitek.Width := 175;
    ploscicaMaksimalniPribitek.Height := 22;
    ploscicaMaksimalniPribitek.HeightPercent := 100.000000000000000000;
    ploscicaMaksimalniPribitek.WidthPercent := 100.000000000000000000;
    ploscicaMaksimalniPribitek.Anchors := [];
    ploscicaMaksimalniPribitek.BorderColor := clNone;
    ploscicaMaksimalniPribitek.BorderStyle := bsNone;
    ploscicaMaksimalniPribitek.ChildOrder := 5;
    ploscicaMaksimalniPribitek.Color := clWindow;
    izMaksimalniPribitek.SetParentComponent(ploscicaMaksimalniPribitek);
    izMaksimalniPribitek.Name := 'izMaksimalniPribitek';
    izMaksimalniPribitek.Left := 125;
    izMaksimalniPribitek.Top := 7;
    izMaksimalniPribitek.Width := 50;
    izMaksimalniPribitek.Height := 18;
    izMaksimalniPribitek.Alignment := taCenter;
    izMaksimalniPribitek.AutoSize := False;
    izMaksimalniPribitek.Caption := '20 %';
    izMaksimalniPribitek.Color := clNone;
    izMaksimalniPribitek.HeightPercent := 100.000000000000000000;
    izMaksimalniPribitek.WidthPercent := 100.000000000000000000;
    vpMaksimalniPribitek.SetParentComponent(ploscicaMaksimalniPribitek);
    vpMaksimalniPribitek.Name := 'vpMaksimalniPribitek';
    vpMaksimalniPribitek.Left := 0;
    vpMaksimalniPribitek.Top := 0;
    vpMaksimalniPribitek.Width := 130;
    vpMaksimalniPribitek.Height := 22;
    vpMaksimalniPribitek.HeightPercent := 100.000000000000000000;
    vpMaksimalniPribitek.WidthPercent := 100.000000000000000000;
    vpMaksimalniPribitek.Align := alLeft;
    vpMaksimalniPribitek.Max := 100;
    vpMaksimalniPribitek.Min := 0;
    vpMaksimalniPribitek.Position := 20;
    vpMaksimalniPribitek.Role := '';
    SetEvent(vpMaksimalniPribitek, Self, 'OnChange', 'vpMaksimalniPribitekChange');
    ploscicaMDJRezultat.SetParentComponent(WebScrollBoxDL6);
    ploscicaMDJRezultat.Name := 'ploscicaMDJRezultat';
    ploscicaMDJRezultat.Left := 16;
    ploscicaMDJRezultat.Top := 80;
    ploscicaMDJRezultat.Width := 608;
    ploscicaMDJRezultat.Height := 60;
    ploscicaMDJRezultat.HeightPercent := 100.000000000000000000;
    ploscicaMDJRezultat.WidthPercent := 100.000000000000000000;
    ploscicaMDJRezultat.BorderColor := clSilver;
    ploscicaMDJRezultat.BorderStyle := bsSingle;
    ploscicaMDJRezultat.ChildOrder := 1;
    ploscicaMDJRezultat.Color := 13882323;
    izTekstPriporocenaMDJ.SetParentComponent(ploscicaMDJRezultat);
    izTekstPriporocenaMDJ.Name := 'izTekstPriporocenaMDJ';
    izTekstPriporocenaMDJ.Left := 15;
    izTekstPriporocenaMDJ.Top := 22;
    izTekstPriporocenaMDJ.Width := 377;
    izTekstPriporocenaMDJ.Height := 15;
    izTekstPriporocenaMDJ.Caption := 'Izra'#269'unana / priporo'#269'ena najni'#382'ja potrebna maksimalna doba jam'#269'enja:';
    izTekstPriporocenaMDJ.Color := clNone;
    izTekstPriporocenaMDJ.HeightPercent := 100.000000000000000000;
    izTekstPriporocenaMDJ.WidthPercent := 100.000000000000000000;
    izPriporocenaMDJ.SetParentComponent(ploscicaMDJRezultat);
    izPriporocenaMDJ.Name := 'izPriporocenaMDJ';
    izPriporocenaMDJ.Left := 444;
    izPriporocenaMDJ.Top := 22;
    izPriporocenaMDJ.Width := 113;
    izPriporocenaMDJ.Height := 14;
    izPriporocenaMDJ.Alignment := taRightJustify;
    izPriporocenaMDJ.AutoSize := False;
    izPriporocenaMDJ.Caption := 'xx,x mesece(v)';
    izPriporocenaMDJ.Color := clNone;
    izPriporocenaMDJ.Font.Charset := DEFAULT_CHARSET;
    izPriporocenaMDJ.Font.Color := clWindowText;
    izPriporocenaMDJ.Font.Height := -11;
    izPriporocenaMDJ.Font.Name := 'Arial';
    izPriporocenaMDJ.Font.Style := [fsBold];
    izPriporocenaMDJ.HeightPercent := 100.000000000000000000;
    izPriporocenaMDJ.ParentFont := False;
    izPriporocenaMDJ.WidthPercent := 100.000000000000000000;
    ploscicaParametriMDJ.SetParentComponent(WebScrollBoxDL6);
    ploscicaParametriMDJ.Name := 'ploscicaParametriMDJ';
    ploscicaParametriMDJ.Left := 16;
    ploscicaParametriMDJ.Top := 216;
    ploscicaParametriMDJ.Width := 720;
    ploscicaParametriMDJ.Height := 300;
    ploscicaParametriMDJ.HeightPercent := 100.000000000000000000;
    ploscicaParametriMDJ.WidthPercent := 100.000000000000000000;
    ploscicaParametriMDJ.ChildOrder := 2;
    ploscicaParametriMDJ.ColumnCollection.Clear;
    with ploscicaParametriMDJ.ColumnCollection.Add do
    begin
      Value := 50;
    end;
    with ploscicaParametriMDJ.ColumnCollection.Add do
    begin
      Alignment := taCenter;
      Value := 25;
    end;
    with ploscicaParametriMDJ.ColumnCollection.Add do
    begin
      Alignment := taCenter;
      Value := 25;
    end;
    ploscicaParametriMDJ.ControlCollection.Clear;
    with ploscicaParametriMDJ.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izParametriMDJ_Naslov_1;
    end;
    with ploscicaParametriMDJ.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izParametriMDJ_Naslov_2;
    end;
    with ploscicaParametriMDJ.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izParametriMDJ_Naslov_3;
    end;
    with ploscicaParametriMDJ.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izParametriMDJ_Parameter1;
    end;
    with ploscicaParametriMDJ.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaParameter1_1;
    end;
    with ploscicaParametriMDJ.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaParameter1_2;
    end;
    with ploscicaParametriMDJ.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izParametriMDJ_Parameter2;
    end;
    with ploscicaParametriMDJ.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaParameter2_1;
    end;
    with ploscicaParametriMDJ.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaParameter2_2;
    end;
    with ploscicaParametriMDJ.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izParametriMDJ_Parameter3;
    end;
    with ploscicaParametriMDJ.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaParameter3_1;
    end;
    with ploscicaParametriMDJ.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaParameter3_2;
    end;
    with ploscicaParametriMDJ.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izParametriMDJ_Parameter4;
    end;
    with ploscicaParametriMDJ.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaParameter4_1;
    end;
    with ploscicaParametriMDJ.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaParameter4_2;
    end;
    with ploscicaParametriMDJ.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izParametriMDJ_Parameter5;
    end;
    with ploscicaParametriMDJ.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaParameter5_1;
    end;
    with ploscicaParametriMDJ.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaParameter5_2;
    end;
    with ploscicaParametriMDJ.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izParametriMDJ_Parameter6;
    end;
    with ploscicaParametriMDJ.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaParameter6_1;
    end;
    with ploscicaParametriMDJ.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izParametriMDJ_PrazenParameter6_2;
    end;
    with ploscicaParametriMDJ.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izParametriMDJ_Parameter7;
    end;
    with ploscicaParametriMDJ.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaParameter7_1;
    end;
    with ploscicaParametriMDJ.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izParametriMDJ_PrazenParameter7_2;
    end;
    ploscicaParametriMDJ.Color := clNone;
    ploscicaParametriMDJ.GridLineColor := clBlack;
    ploscicaParametriMDJ.RowCollection.Clear;
    with ploscicaParametriMDJ.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 12;
    end;
    with ploscicaParametriMDJ.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 12;
    end;
    with ploscicaParametriMDJ.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 12;
    end;
    with ploscicaParametriMDJ.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 12;
    end;
    with ploscicaParametriMDJ.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 12;
    end;
    with ploscicaParametriMDJ.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 12;
    end;
    with ploscicaParametriMDJ.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 12;
    end;
    with ploscicaParametriMDJ.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 12;
    end;
    izParametriMDJ_Naslov_1.SetParentComponent(ploscicaParametriMDJ);
    izParametriMDJ_Naslov_1.Name := 'izParametriMDJ_Naslov_1';
    izParametriMDJ_Naslov_1.Left := 2;
    izParametriMDJ_Naslov_1.Top := 10;
    izParametriMDJ_Naslov_1.Width := 3;
    izParametriMDJ_Naslov_1.Height := 15;
    izParametriMDJ_Naslov_1.Anchors := [];
    izParametriMDJ_Naslov_1.Color := clNone;
    izParametriMDJ_Naslov_1.HeightPercent := 100.000000000000000000;
    izParametriMDJ_Naslov_1.WidthPercent := 100.000000000000000000;
    izParametriMDJ_Naslov_2.SetParentComponent(ploscicaParametriMDJ);
    izParametriMDJ_Naslov_2.Name := 'izParametriMDJ_Naslov_2';
    izParametriMDJ_Naslov_2.Left := 396;
    izParametriMDJ_Naslov_2.Top := 11;
    izParametriMDJ_Naslov_2.Width := 108;
    izParametriMDJ_Naslov_2.Height := 13;
    izParametriMDJ_Naslov_2.Anchors := [];
    izParametriMDJ_Naslov_2.Caption := 'trajanje (v mesecih)';
    izParametriMDJ_Naslov_2.Color := clNone;
    izParametriMDJ_Naslov_2.Font.Charset := DEFAULT_CHARSET;
    izParametriMDJ_Naslov_2.Font.Color := clWindowText;
    izParametriMDJ_Naslov_2.Font.Height := -11;
    izParametriMDJ_Naslov_2.Font.Name := 'Arial';
    izParametriMDJ_Naslov_2.Font.Style := [fsBold,fsItalic];
    izParametriMDJ_Naslov_2.HeightPercent := 100.000000000000000000;
    izParametriMDJ_Naslov_2.ParentFont := False;
    izParametriMDJ_Naslov_2.WidthPercent := 100.000000000000000000;
    izParametriMDJ_Naslov_3.SetParentComponent(ploscicaParametriMDJ);
    izParametriMDJ_Naslov_3.Name := 'izParametriMDJ_Naslov_3';
    izParametriMDJ_Naslov_3.Left := 558;
    izParametriMDJ_Naslov_3.Top := 11;
    izParametriMDJ_Naslov_3.Width := 144;
    izParametriMDJ_Naslov_3.Height := 13;
    izParametriMDJ_Naslov_3.Anchors := [];
    izParametriMDJ_Naslov_3.Caption := 'zamik za'#269'etka (v mesecih)';
    izParametriMDJ_Naslov_3.Color := clNone;
    izParametriMDJ_Naslov_3.Font.Charset := DEFAULT_CHARSET;
    izParametriMDJ_Naslov_3.Font.Color := clWindowText;
    izParametriMDJ_Naslov_3.Font.Height := -11;
    izParametriMDJ_Naslov_3.Font.Name := 'Arial';
    izParametriMDJ_Naslov_3.Font.Style := [fsBold,fsItalic];
    izParametriMDJ_Naslov_3.HeightPercent := 100.000000000000000000;
    izParametriMDJ_Naslov_3.ParentFont := False;
    izParametriMDJ_Naslov_3.WidthPercent := 100.000000000000000000;
    izParametriMDJ_Parameter1.SetParentComponent(ploscicaParametriMDJ);
    izParametriMDJ_Parameter1.Name := 'izParametriMDJ_Parameter1';
    izParametriMDJ_Parameter1.Left := 2;
    izParametriMDJ_Parameter1.Top := 46;
    izParametriMDJ_Parameter1.Width := 335;
    izParametriMDJ_Parameter1.Height := 15;
    izParametriMDJ_Parameter1.Anchors := [];
    izParametriMDJ_Parameter1.Caption := #268'i'#353#269'enje in ru'#353'enje (vklju'#269'no s pridobitvijo dokumentacije zanj):';
    izParametriMDJ_Parameter1.Color := clNone;
    izParametriMDJ_Parameter1.HeightPercent := 100.000000000000000000;
    izParametriMDJ_Parameter1.WidthPercent := 100.000000000000000000;
    izParametriMDJ_Parameter2.SetParentComponent(ploscicaParametriMDJ);
    izParametriMDJ_Parameter2.Name := 'izParametriMDJ_Parameter2';
    izParametriMDJ_Parameter2.Left := 2;
    izParametriMDJ_Parameter2.Top := 82;
    izParametriMDJ_Parameter2.Width := 305;
    izParametriMDJ_Parameter2.Height := 15;
    izParametriMDJ_Parameter2.Anchors := [];
    izParametriMDJ_Parameter2.Caption := 'Pridobivanje dokumentacije za ponovno gradnjjo objekta:';
    izParametriMDJ_Parameter2.Color := clNone;
    izParametriMDJ_Parameter2.HeightPercent := 100.000000000000000000;
    izParametriMDJ_Parameter2.WidthPercent := 100.000000000000000000;
    izParametriMDJ_Parameter3.SetParentComponent(ploscicaParametriMDJ);
    izParametriMDJ_Parameter3.Name := 'izParametriMDJ_Parameter3';
    izParametriMDJ_Parameter3.Left := 2;
    izParametriMDJ_Parameter3.Top := 118;
    izParametriMDJ_Parameter3.Width := 325;
    izParametriMDJ_Parameter3.Height := 15;
    izParametriMDJ_Parameter3.Anchors := [];
    izParametriMDJ_Parameter3.Caption := 'Gradnja objekta (vklju'#269'no z zaklju'#269'nimi in instalacijskimi deli):';
    izParametriMDJ_Parameter3.Color := clNone;
    izParametriMDJ_Parameter3.HeightPercent := 100.000000000000000000;
    izParametriMDJ_Parameter3.WidthPercent := 100.000000000000000000;
    izParametriMDJ_Parameter4.SetParentComponent(ploscicaParametriMDJ);
    izParametriMDJ_Parameter4.Name := 'izParametriMDJ_Parameter4';
    izParametriMDJ_Parameter4.Left := 2;
    izParametriMDJ_Parameter4.Top := 154;
    izParametriMDJ_Parameter4.Width := 200;
    izParametriMDJ_Parameter4.Height := 15;
    izParametriMDJ_Parameter4.Anchors := [];
    izParametriMDJ_Parameter4.Caption := 'Dobava ve'#269'jih (vitalnih) strojev in linij:';
    izParametriMDJ_Parameter4.Color := clNone;
    izParametriMDJ_Parameter4.HeightPercent := 100.000000000000000000;
    izParametriMDJ_Parameter4.WidthPercent := 100.000000000000000000;
    izParametriMDJ_Parameter5.SetParentComponent(ploscicaParametriMDJ);
    izParametriMDJ_Parameter5.Name := 'izParametriMDJ_Parameter5';
    izParametriMDJ_Parameter5.Left := 2;
    izParametriMDJ_Parameter5.Top := 190;
    izParametriMDJ_Parameter5.Width := 326;
    izParametriMDJ_Parameter5.Height := 15;
    izParametriMDJ_Parameter5.Anchors := [];
    izParametriMDJ_Parameter5.Caption := 'Monta'#382'a ve'#269'jih strojev in linij (skupaj s poizkusnim zagonom);';
    izParametriMDJ_Parameter5.Color := clNone;
    izParametriMDJ_Parameter5.HeightPercent := 100.000000000000000000;
    izParametriMDJ_Parameter5.WidthPercent := 100.000000000000000000;
    izParametriMDJ_Parameter6.SetParentComponent(ploscicaParametriMDJ);
    izParametriMDJ_Parameter6.Name := 'izParametriMDJ_Parameter6';
    izParametriMDJ_Parameter6.Left := 2;
    izParametriMDJ_Parameter6.Top := 226;
    izParametriMDJ_Parameter6.Width := 205;
    izParametriMDJ_Parameter6.Height := 15;
    izParametriMDJ_Parameter6.Anchors := [];
    izParametriMDJ_Parameter6.Caption := #268'as do polnih (prodajnih) zmogljivosti:';
    izParametriMDJ_Parameter6.Color := clNone;
    izParametriMDJ_Parameter6.HeightPercent := 100.000000000000000000;
    izParametriMDJ_Parameter6.WidthPercent := 100.000000000000000000;
    izParametriMDJ_PrazenParameter6_2.SetParentComponent(ploscicaParametriMDJ);
    izParametriMDJ_PrazenParameter6_2.Name := 'izParametriMDJ_PrazenParameter6_2';
    izParametriMDJ_PrazenParameter6_2.Left := 628;
    izParametriMDJ_PrazenParameter6_2.Top := 226;
    izParametriMDJ_PrazenParameter6_2.Width := 3;
    izParametriMDJ_PrazenParameter6_2.Height := 15;
    izParametriMDJ_PrazenParameter6_2.Anchors := [];
    izParametriMDJ_PrazenParameter6_2.Color := clNone;
    izParametriMDJ_PrazenParameter6_2.HeightPercent := 100.000000000000000000;
    izParametriMDJ_PrazenParameter6_2.WidthPercent := 100.000000000000000000;
    izParametriMDJ_Parameter7.SetParentComponent(ploscicaParametriMDJ);
    izParametriMDJ_Parameter7.Name := 'izParametriMDJ_Parameter7';
    izParametriMDJ_Parameter7.Left := 2;
    izParametriMDJ_Parameter7.Top := 262;
    izParametriMDJ_Parameter7.Width := 98;
    izParametriMDJ_Parameter7.Height := 15;
    izParametriMDJ_Parameter7.Anchors := [];
    izParametriMDJ_Parameter7.Caption := 'Varnostni dodatek:';
    izParametriMDJ_Parameter7.Color := clNone;
    izParametriMDJ_Parameter7.HeightPercent := 100.000000000000000000;
    izParametriMDJ_Parameter7.WidthPercent := 100.000000000000000000;
    izParametriMDJ_PrazenParameter7_2.SetParentComponent(ploscicaParametriMDJ);
    izParametriMDJ_PrazenParameter7_2.Name := 'izParametriMDJ_PrazenParameter7_2';
    izParametriMDJ_PrazenParameter7_2.Left := 628;
    izParametriMDJ_PrazenParameter7_2.Top := 262;
    izParametriMDJ_PrazenParameter7_2.Width := 3;
    izParametriMDJ_PrazenParameter7_2.Height := 15;
    izParametriMDJ_PrazenParameter7_2.Anchors := [];
    izParametriMDJ_PrazenParameter7_2.Color := clNone;
    izParametriMDJ_PrazenParameter7_2.HeightPercent := 100.000000000000000000;
    izParametriMDJ_PrazenParameter7_2.WidthPercent := 100.000000000000000000;
    ploscicaParameter1_1.SetParentComponent(ploscicaParametriMDJ);
    ploscicaParameter1_1.Name := 'ploscicaParameter1_1';
    ploscicaParameter1_1.Left := 365;
    ploscicaParameter1_1.Top := 43;
    ploscicaParameter1_1.Width := 170;
    ploscicaParameter1_1.Height := 22;
    ploscicaParameter1_1.HeightPercent := 100.000000000000000000;
    ploscicaParameter1_1.WidthPercent := 100.000000000000000000;
    ploscicaParameter1_1.Anchors := [];
    ploscicaParameter1_1.BorderColor := clNone;
    ploscicaParameter1_1.BorderStyle := bsNone;
    ploscicaParameter1_1.ChildOrder := 4;
    ploscicaParameter1_1.Color := clWindow;
    izParameter1_1.SetParentComponent(ploscicaParameter1_1);
    izParameter1_1.Name := 'izParameter1_1';
    izParameter1_1.Left := 130;
    izParameter1_1.Top := 7;
    izParameter1_1.Width := 40;
    izParameter1_1.Height := 22;
    izParameter1_1.Alignment := taCenter;
    izParameter1_1.AutoSize := False;
    izParameter1_1.Caption := '1,00';
    izParameter1_1.Color := clNone;
    izParameter1_1.HeightPercent := 100.000000000000000000;
    izParameter1_1.WidthPercent := 100.000000000000000000;
    vpParameter1_1.SetParentComponent(ploscicaParameter1_1);
    vpParameter1_1.Name := 'vpParameter1_1';
    vpParameter1_1.Left := 5;
    vpParameter1_1.Top := 3;
    vpParameter1_1.Width := 125;
    vpParameter1_1.Height := 18;
    vpParameter1_1.HeightPercent := 100.000000000000000000;
    vpParameter1_1.WidthPercent := 100.000000000000000000;
    vpParameter1_1.Anchors := [];
    vpParameter1_1.Max := 24;
    vpParameter1_1.Min := 0;
    vpParameter1_1.Position := 4;
    vpParameter1_1.Role := '';
    SetEvent(vpParameter1_1, Self, 'OnChange', 'vpParameter1_1Change');
    ploscicaParameter1_2.SetParentComponent(ploscicaParametriMDJ);
    ploscicaParameter1_2.Name := 'ploscicaParameter1_2';
    ploscicaParameter1_2.Left := 545;
    ploscicaParameter1_2.Top := 43;
    ploscicaParameter1_2.Width := 170;
    ploscicaParameter1_2.Height := 22;
    ploscicaParameter1_2.HeightPercent := 100.000000000000000000;
    ploscicaParameter1_2.WidthPercent := 100.000000000000000000;
    ploscicaParameter1_2.Anchors := [];
    ploscicaParameter1_2.BorderColor := clNone;
    ploscicaParameter1_2.BorderStyle := bsNone;
    ploscicaParameter1_2.ChildOrder := 5;
    ploscicaParameter1_2.Color := clWindow;
    izParameter1_2.SetParentComponent(ploscicaParameter1_2);
    izParameter1_2.Name := 'izParameter1_2';
    izParameter1_2.Left := 130;
    izParameter1_2.Top := 7;
    izParameter1_2.Width := 40;
    izParameter1_2.Height := 18;
    izParameter1_2.Alignment := taCenter;
    izParameter1_2.AutoSize := False;
    izParameter1_2.Caption := '0,50';
    izParameter1_2.Color := clNone;
    izParameter1_2.HeightPercent := 100.000000000000000000;
    izParameter1_2.WidthPercent := 100.000000000000000000;
    vpParameter1_2.SetParentComponent(ploscicaParameter1_2);
    vpParameter1_2.Name := 'vpParameter1_2';
    vpParameter1_2.Left := 6;
    vpParameter1_2.Top := 2;
    vpParameter1_2.Width := 125;
    vpParameter1_2.Height := 18;
    vpParameter1_2.HeightPercent := 100.000000000000000000;
    vpParameter1_2.WidthPercent := 100.000000000000000000;
    vpParameter1_2.Anchors := [];
    vpParameter1_2.Max := 24;
    vpParameter1_2.Min := 0;
    vpParameter1_2.Position := 2;
    vpParameter1_2.Role := '';
    SetEvent(vpParameter1_2, Self, 'OnChange', 'vpParameter1_1Change');
    ploscicaParameter2_1.SetParentComponent(ploscicaParametriMDJ);
    ploscicaParameter2_1.Name := 'ploscicaParameter2_1';
    ploscicaParameter2_1.Left := 365;
    ploscicaParameter2_1.Top := 79;
    ploscicaParameter2_1.Width := 170;
    ploscicaParameter2_1.Height := 22;
    ploscicaParameter2_1.HeightPercent := 100.000000000000000000;
    ploscicaParameter2_1.WidthPercent := 100.000000000000000000;
    ploscicaParameter2_1.Anchors := [];
    ploscicaParameter2_1.BorderColor := clNone;
    ploscicaParameter2_1.BorderStyle := bsNone;
    ploscicaParameter2_1.ChildOrder := 7;
    ploscicaParameter2_1.Color := clWindow;
    izParameter2_1.SetParentComponent(ploscicaParameter2_1);
    izParameter2_1.Name := 'izParameter2_1';
    izParameter2_1.Left := 130;
    izParameter2_1.Top := 7;
    izParameter2_1.Width := 40;
    izParameter2_1.Height := 18;
    izParameter2_1.Alignment := taCenter;
    izParameter2_1.AutoSize := False;
    izParameter2_1.Caption := '2,00';
    izParameter2_1.Color := clNone;
    izParameter2_1.HeightPercent := 100.000000000000000000;
    izParameter2_1.WidthPercent := 100.000000000000000000;
    vpParameter2_1.SetParentComponent(ploscicaParameter2_1);
    vpParameter2_1.Name := 'vpParameter2_1';
    vpParameter2_1.Left := 6;
    vpParameter2_1.Top := 2;
    vpParameter2_1.Width := 125;
    vpParameter2_1.Height := 18;
    vpParameter2_1.HeightPercent := 100.000000000000000000;
    vpParameter2_1.WidthPercent := 100.000000000000000000;
    vpParameter2_1.Anchors := [];
    vpParameter2_1.Max := 24;
    vpParameter2_1.Min := 0;
    vpParameter2_1.Position := 8;
    vpParameter2_1.Role := '';
    SetEvent(vpParameter2_1, Self, 'OnChange', 'vpParameter1_1Change');
    ploscicaParameter2_2.SetParentComponent(ploscicaParametriMDJ);
    ploscicaParameter2_2.Name := 'ploscicaParameter2_2';
    ploscicaParameter2_2.Left := 545;
    ploscicaParameter2_2.Top := 79;
    ploscicaParameter2_2.Width := 170;
    ploscicaParameter2_2.Height := 22;
    ploscicaParameter2_2.HeightPercent := 100.000000000000000000;
    ploscicaParameter2_2.WidthPercent := 100.000000000000000000;
    ploscicaParameter2_2.Anchors := [];
    ploscicaParameter2_2.BorderColor := clNone;
    ploscicaParameter2_2.BorderStyle := bsNone;
    ploscicaParameter2_2.ChildOrder := 8;
    ploscicaParameter2_2.Color := clWindow;
    izParameter2_2.SetParentComponent(ploscicaParameter2_2);
    izParameter2_2.Name := 'izParameter2_2';
    izParameter2_2.Left := 130;
    izParameter2_2.Top := 7;
    izParameter2_2.Width := 40;
    izParameter2_2.Height := 18;
    izParameter2_2.Alignment := taCenter;
    izParameter2_2.AutoSize := False;
    izParameter2_2.Caption := '0,50';
    izParameter2_2.Color := clNone;
    izParameter2_2.HeightPercent := 100.000000000000000000;
    izParameter2_2.WidthPercent := 100.000000000000000000;
    vpParameter2_2.SetParentComponent(ploscicaParameter2_2);
    vpParameter2_2.Name := 'vpParameter2_2';
    vpParameter2_2.Left := 3;
    vpParameter2_2.Top := 1;
    vpParameter2_2.Width := 125;
    vpParameter2_2.Height := 18;
    vpParameter2_2.HeightPercent := 100.000000000000000000;
    vpParameter2_2.WidthPercent := 100.000000000000000000;
    vpParameter2_2.Anchors := [];
    vpParameter2_2.Max := 12;
    vpParameter2_2.Min := 0;
    vpParameter2_2.Position := 2;
    vpParameter2_2.Role := '';
    SetEvent(vpParameter2_2, Self, 'OnChange', 'vpParameter1_1Change');
    ploscicaParameter3_1.SetParentComponent(ploscicaParametriMDJ);
    ploscicaParameter3_1.Name := 'ploscicaParameter3_1';
    ploscicaParameter3_1.Left := 365;
    ploscicaParameter3_1.Top := 115;
    ploscicaParameter3_1.Width := 170;
    ploscicaParameter3_1.Height := 22;
    ploscicaParameter3_1.HeightPercent := 100.000000000000000000;
    ploscicaParameter3_1.WidthPercent := 100.000000000000000000;
    ploscicaParameter3_1.Anchors := [];
    ploscicaParameter3_1.BorderColor := clNone;
    ploscicaParameter3_1.BorderStyle := bsNone;
    ploscicaParameter3_1.ChildOrder := 10;
    ploscicaParameter3_1.Color := clWindow;
    izParameter3_1.SetParentComponent(ploscicaParameter3_1);
    izParameter3_1.Name := 'izParameter3_1';
    izParameter3_1.Left := 130;
    izParameter3_1.Top := 7;
    izParameter3_1.Width := 40;
    izParameter3_1.Height := 18;
    izParameter3_1.Alignment := taCenter;
    izParameter3_1.AutoSize := False;
    izParameter3_1.Caption := '3,00';
    izParameter3_1.Color := clNone;
    izParameter3_1.HeightPercent := 100.000000000000000000;
    izParameter3_1.WidthPercent := 100.000000000000000000;
    vpParameter3_1.SetParentComponent(ploscicaParameter3_1);
    vpParameter3_1.Name := 'vpParameter3_1';
    vpParameter3_1.Left := 6;
    vpParameter3_1.Top := 2;
    vpParameter3_1.Width := 125;
    vpParameter3_1.Height := 18;
    vpParameter3_1.HeightPercent := 100.000000000000000000;
    vpParameter3_1.WidthPercent := 100.000000000000000000;
    vpParameter3_1.Anchors := [];
    vpParameter3_1.Max := 48;
    vpParameter3_1.Min := 0;
    vpParameter3_1.Position := 12;
    vpParameter3_1.Role := '';
    SetEvent(vpParameter3_1, Self, 'OnChange', 'vpParameter1_1Change');
    ploscicaParameter3_2.SetParentComponent(ploscicaParametriMDJ);
    ploscicaParameter3_2.Name := 'ploscicaParameter3_2';
    ploscicaParameter3_2.Left := 545;
    ploscicaParameter3_2.Top := 115;
    ploscicaParameter3_2.Width := 170;
    ploscicaParameter3_2.Height := 22;
    ploscicaParameter3_2.HeightPercent := 100.000000000000000000;
    ploscicaParameter3_2.WidthPercent := 100.000000000000000000;
    ploscicaParameter3_2.Anchors := [];
    ploscicaParameter3_2.BorderColor := clNone;
    ploscicaParameter3_2.BorderStyle := bsNone;
    ploscicaParameter3_2.ChildOrder := 11;
    ploscicaParameter3_2.Color := clWindow;
    izParameter3_2.SetParentComponent(ploscicaParameter3_2);
    izParameter3_2.Name := 'izParameter3_2';
    izParameter3_2.Left := 130;
    izParameter3_2.Top := 7;
    izParameter3_2.Width := 40;
    izParameter3_2.Height := 18;
    izParameter3_2.Alignment := taCenter;
    izParameter3_2.AutoSize := False;
    izParameter3_2.Caption := '0,00';
    izParameter3_2.Color := clNone;
    izParameter3_2.HeightPercent := 100.000000000000000000;
    izParameter3_2.WidthPercent := 100.000000000000000000;
    vpParameter3_2.SetParentComponent(ploscicaParameter3_2);
    vpParameter3_2.Name := 'vpParameter3_2';
    vpParameter3_2.Left := 6;
    vpParameter3_2.Top := 2;
    vpParameter3_2.Width := 125;
    vpParameter3_2.Height := 18;
    vpParameter3_2.HeightPercent := 100.000000000000000000;
    vpParameter3_2.WidthPercent := 100.000000000000000000;
    vpParameter3_2.Anchors := [];
    vpParameter3_2.Max := 24;
    vpParameter3_2.Min := 0;
    vpParameter3_2.Position := 0;
    vpParameter3_2.Role := '';
    SetEvent(vpParameter3_2, Self, 'OnChange', 'vpParameter1_1Change');
    ploscicaParameter4_1.SetParentComponent(ploscicaParametriMDJ);
    ploscicaParameter4_1.Name := 'ploscicaParameter4_1';
    ploscicaParameter4_1.Left := 365;
    ploscicaParameter4_1.Top := 151;
    ploscicaParameter4_1.Width := 170;
    ploscicaParameter4_1.Height := 22;
    ploscicaParameter4_1.HeightPercent := 100.000000000000000000;
    ploscicaParameter4_1.WidthPercent := 100.000000000000000000;
    ploscicaParameter4_1.Anchors := [];
    ploscicaParameter4_1.BorderColor := clNone;
    ploscicaParameter4_1.BorderStyle := bsNone;
    ploscicaParameter4_1.ChildOrder := 13;
    ploscicaParameter4_1.Color := clWindow;
    izParameter4_1.SetParentComponent(ploscicaParameter4_1);
    izParameter4_1.Name := 'izParameter4_1';
    izParameter4_1.Left := 130;
    izParameter4_1.Top := 7;
    izParameter4_1.Width := 40;
    izParameter4_1.Height := 18;
    izParameter4_1.Alignment := taCenter;
    izParameter4_1.AutoSize := False;
    izParameter4_1.Caption := '2,50';
    izParameter4_1.Color := clNone;
    izParameter4_1.HeightPercent := 100.000000000000000000;
    izParameter4_1.WidthPercent := 100.000000000000000000;
    vpParameter4_1.SetParentComponent(ploscicaParameter4_1);
    vpParameter4_1.Name := 'vpParameter4_1';
    vpParameter4_1.Left := 6;
    vpParameter4_1.Top := 2;
    vpParameter4_1.Width := 125;
    vpParameter4_1.Height := 18;
    vpParameter4_1.HeightPercent := 100.000000000000000000;
    vpParameter4_1.WidthPercent := 100.000000000000000000;
    vpParameter4_1.Anchors := [];
    vpParameter4_1.Max := 48;
    vpParameter4_1.Min := 0;
    vpParameter4_1.Position := 10;
    vpParameter4_1.Role := '';
    SetEvent(vpParameter4_1, Self, 'OnChange', 'vpParameter1_1Change');
    ploscicaParameter4_2.SetParentComponent(ploscicaParametriMDJ);
    ploscicaParameter4_2.Name := 'ploscicaParameter4_2';
    ploscicaParameter4_2.Left := 545;
    ploscicaParameter4_2.Top := 151;
    ploscicaParameter4_2.Width := 170;
    ploscicaParameter4_2.Height := 22;
    ploscicaParameter4_2.HeightPercent := 100.000000000000000000;
    ploscicaParameter4_2.WidthPercent := 100.000000000000000000;
    ploscicaParameter4_2.Anchors := [];
    ploscicaParameter4_2.BorderColor := clNone;
    ploscicaParameter4_2.BorderStyle := bsNone;
    ploscicaParameter4_2.ChildOrder := 14;
    ploscicaParameter4_2.Color := clWindow;
    izParameter4_2.SetParentComponent(ploscicaParameter4_2);
    izParameter4_2.Name := 'izParameter4_2';
    izParameter4_2.Left := 130;
    izParameter4_2.Top := 7;
    izParameter4_2.Width := 40;
    izParameter4_2.Height := 18;
    izParameter4_2.Alignment := taCenter;
    izParameter4_2.AutoSize := False;
    izParameter4_2.Caption := '0,00';
    izParameter4_2.Color := clNone;
    izParameter4_2.HeightPercent := 100.000000000000000000;
    izParameter4_2.WidthPercent := 100.000000000000000000;
    vpParameter4_2.SetParentComponent(ploscicaParameter4_2);
    vpParameter4_2.Name := 'vpParameter4_2';
    vpParameter4_2.Left := 6;
    vpParameter4_2.Top := 2;
    vpParameter4_2.Width := 125;
    vpParameter4_2.Height := 18;
    vpParameter4_2.HeightPercent := 100.000000000000000000;
    vpParameter4_2.WidthPercent := 100.000000000000000000;
    vpParameter4_2.Anchors := [];
    vpParameter4_2.Max := 24;
    vpParameter4_2.Min := 0;
    vpParameter4_2.Position := 0;
    vpParameter4_2.Role := '';
    SetEvent(vpParameter4_2, Self, 'OnChange', 'vpParameter1_1Change');
    ploscicaParameter5_1.SetParentComponent(ploscicaParametriMDJ);
    ploscicaParameter5_1.Name := 'ploscicaParameter5_1';
    ploscicaParameter5_1.Left := 365;
    ploscicaParameter5_1.Top := 187;
    ploscicaParameter5_1.Width := 170;
    ploscicaParameter5_1.Height := 22;
    ploscicaParameter5_1.HeightPercent := 100.000000000000000000;
    ploscicaParameter5_1.WidthPercent := 100.000000000000000000;
    ploscicaParameter5_1.Anchors := [];
    ploscicaParameter5_1.BorderColor := clNone;
    ploscicaParameter5_1.BorderStyle := bsNone;
    ploscicaParameter5_1.ChildOrder := 16;
    ploscicaParameter5_1.Color := clWindow;
    izParameter5_1.SetParentComponent(ploscicaParameter5_1);
    izParameter5_1.Name := 'izParameter5_1';
    izParameter5_1.Left := 130;
    izParameter5_1.Top := 6;
    izParameter5_1.Width := 40;
    izParameter5_1.Height := 18;
    izParameter5_1.Alignment := taCenter;
    izParameter5_1.AutoSize := False;
    izParameter5_1.Caption := '1,00';
    izParameter5_1.Color := clNone;
    izParameter5_1.HeightPercent := 100.000000000000000000;
    izParameter5_1.WidthPercent := 100.000000000000000000;
    vpParameter5_1.SetParentComponent(ploscicaParameter5_1);
    vpParameter5_1.Name := 'vpParameter5_1';
    vpParameter5_1.Left := 7;
    vpParameter5_1.Top := 2;
    vpParameter5_1.Width := 125;
    vpParameter5_1.Height := 18;
    vpParameter5_1.HeightPercent := 100.000000000000000000;
    vpParameter5_1.WidthPercent := 100.000000000000000000;
    vpParameter5_1.Anchors := [];
    vpParameter5_1.Max := 24;
    vpParameter5_1.Min := 0;
    vpParameter5_1.Position := 4;
    vpParameter5_1.Role := '';
    SetEvent(vpParameter5_1, Self, 'OnChange', 'vpParameter1_1Change');
    ploscicaParameter5_2.SetParentComponent(ploscicaParametriMDJ);
    ploscicaParameter5_2.Name := 'ploscicaParameter5_2';
    ploscicaParameter5_2.Left := 545;
    ploscicaParameter5_2.Top := 187;
    ploscicaParameter5_2.Width := 170;
    ploscicaParameter5_2.Height := 22;
    ploscicaParameter5_2.HeightPercent := 100.000000000000000000;
    ploscicaParameter5_2.WidthPercent := 100.000000000000000000;
    ploscicaParameter5_2.Anchors := [];
    ploscicaParameter5_2.BorderColor := clNone;
    ploscicaParameter5_2.BorderStyle := bsNone;
    ploscicaParameter5_2.ChildOrder := 17;
    ploscicaParameter5_2.Color := clWindow;
    izParameter5_2.SetParentComponent(ploscicaParameter5_2);
    izParameter5_2.Name := 'izParameter5_2';
    izParameter5_2.Left := 130;
    izParameter5_2.Top := 7;
    izParameter5_2.Width := 40;
    izParameter5_2.Height := 18;
    izParameter5_2.Alignment := taCenter;
    izParameter5_2.AutoSize := False;
    izParameter5_2.Caption := '0,00';
    izParameter5_2.Color := clNone;
    izParameter5_2.HeightPercent := 100.000000000000000000;
    izParameter5_2.WidthPercent := 100.000000000000000000;
    vpParameter5_2.SetParentComponent(ploscicaParameter5_2);
    vpParameter5_2.Name := 'vpParameter5_2';
    vpParameter5_2.Left := 6;
    vpParameter5_2.Top := 2;
    vpParameter5_2.Width := 125;
    vpParameter5_2.Height := 18;
    vpParameter5_2.HeightPercent := 100.000000000000000000;
    vpParameter5_2.WidthPercent := 100.000000000000000000;
    vpParameter5_2.Anchors := [];
    vpParameter5_2.Max := 12;
    vpParameter5_2.Min := 0;
    vpParameter5_2.Position := 0;
    vpParameter5_2.Role := '';
    SetEvent(vpParameter5_2, Self, 'OnChange', 'vpParameter1_1Change');
    ploscicaParameter6_1.SetParentComponent(ploscicaParametriMDJ);
    ploscicaParameter6_1.Name := 'ploscicaParameter6_1';
    ploscicaParameter6_1.Left := 365;
    ploscicaParameter6_1.Top := 223;
    ploscicaParameter6_1.Width := 170;
    ploscicaParameter6_1.Height := 22;
    ploscicaParameter6_1.HeightPercent := 100.000000000000000000;
    ploscicaParameter6_1.WidthPercent := 100.000000000000000000;
    ploscicaParameter6_1.Anchors := [];
    ploscicaParameter6_1.BorderColor := clNone;
    ploscicaParameter6_1.BorderStyle := bsNone;
    ploscicaParameter6_1.ChildOrder := 19;
    ploscicaParameter6_1.Color := clWindow;
    izParameter6_1.SetParentComponent(ploscicaParameter6_1);
    izParameter6_1.Name := 'izParameter6_1';
    izParameter6_1.Left := 130;
    izParameter6_1.Top := 7;
    izParameter6_1.Width := 40;
    izParameter6_1.Height := 18;
    izParameter6_1.Alignment := taCenter;
    izParameter6_1.AutoSize := False;
    izParameter6_1.Caption := '1,00';
    izParameter6_1.Color := clNone;
    izParameter6_1.HeightPercent := 100.000000000000000000;
    izParameter6_1.WidthPercent := 100.000000000000000000;
    vpParameter6_1.SetParentComponent(ploscicaParameter6_1);
    vpParameter6_1.Name := 'vpParameter6_1';
    vpParameter6_1.Left := 6;
    vpParameter6_1.Top := 2;
    vpParameter6_1.Width := 125;
    vpParameter6_1.Height := 18;
    vpParameter6_1.HeightPercent := 100.000000000000000000;
    vpParameter6_1.WidthPercent := 100.000000000000000000;
    vpParameter6_1.Anchors := [];
    vpParameter6_1.Max := 24;
    vpParameter6_1.Min := 0;
    vpParameter6_1.Position := 4;
    vpParameter6_1.Role := '';
    SetEvent(vpParameter6_1, Self, 'OnChange', 'vpParameter1_1Change');
    ploscicaParameter7_1.SetParentComponent(ploscicaParametriMDJ);
    ploscicaParameter7_1.Name := 'ploscicaParameter7_1';
    ploscicaParameter7_1.Left := 365;
    ploscicaParameter7_1.Top := 259;
    ploscicaParameter7_1.Width := 170;
    ploscicaParameter7_1.Height := 22;
    ploscicaParameter7_1.HeightPercent := 100.000000000000000000;
    ploscicaParameter7_1.WidthPercent := 100.000000000000000000;
    ploscicaParameter7_1.Anchors := [];
    ploscicaParameter7_1.BorderColor := clNone;
    ploscicaParameter7_1.BorderStyle := bsNone;
    ploscicaParameter7_1.ChildOrder := 22;
    ploscicaParameter7_1.Color := clWindow;
    izParameter7_1.SetParentComponent(ploscicaParameter7_1);
    izParameter7_1.Name := 'izParameter7_1';
    izParameter7_1.Left := 130;
    izParameter7_1.Top := 7;
    izParameter7_1.Width := 40;
    izParameter7_1.Height := 18;
    izParameter7_1.Alignment := taCenter;
    izParameter7_1.AutoSize := False;
    izParameter7_1.Caption := '0,50';
    izParameter7_1.Color := clNone;
    izParameter7_1.HeightPercent := 100.000000000000000000;
    izParameter7_1.WidthPercent := 100.000000000000000000;
    vpParameter7_1.SetParentComponent(ploscicaParameter7_1);
    vpParameter7_1.Name := 'vpParameter7_1';
    vpParameter7_1.Left := 6;
    vpParameter7_1.Top := 2;
    vpParameter7_1.Width := 125;
    vpParameter7_1.Height := 18;
    vpParameter7_1.HeightPercent := 100.000000000000000000;
    vpParameter7_1.WidthPercent := 100.000000000000000000;
    vpParameter7_1.Anchors := [];
    vpParameter7_1.Max := 12;
    vpParameter7_1.Min := 0;
    vpParameter7_1.Position := 2;
    vpParameter7_1.Role := '';
    SetEvent(vpParameter7_1, Self, 'OnChange', 'vpParameter1_1Change');
    vpKorekcijskiFaktorji.SetParentComponent(WebScrollBoxDL6);
    vpKorekcijskiFaktorji.Name := 'vpKorekcijskiFaktorji';
    vpKorekcijskiFaktorji.Left := 15;
    vpKorekcijskiFaktorji.Top := 993;
    vpKorekcijskiFaktorji.Width := 309;
    vpKorekcijskiFaktorji.Height := 22;
    vpKorekcijskiFaktorji.Anchors := [];
    vpKorekcijskiFaktorji.Caption := 'Upo'#353'tevanje korekcijskih faktorjev z maksimalnim pribitkom';
    vpKorekcijskiFaktorji.ChildOrder := 4;
    vpKorekcijskiFaktorji.Color := clNone;
    vpKorekcijskiFaktorji.HeightPercent := 100.000000000000000000;
    vpKorekcijskiFaktorji.State := cbUnchecked;
    vpKorekcijskiFaktorji.WidthPercent := 100.000000000000000000;
    SetEvent(vpKorekcijskiFaktorji, Self, 'OnClick', 'vpKorekcijskiFaktorjiClick');
    ploscicaGrafMDJ.SetParentComponent(WebScrollBoxDL6);
    ploscicaGrafMDJ.Name := 'ploscicaGrafMDJ';
    ploscicaGrafMDJ.Left := 13;
    ploscicaGrafMDJ.Top := 532;
    ploscicaGrafMDJ.Width := 710;
    ploscicaGrafMDJ.Height := 400;
    ploscicaGrafMDJ.HeightPercent := 100.000000000000000000;
    ploscicaGrafMDJ.WidthPercent := 100.000000000000000000;
    ploscicaGrafMDJ.BorderColor := clSilver;
    ploscicaGrafMDJ.BorderStyle := bsSingle;
    ploscicaGrafMDJ.ChildOrder := 7;
    GrafMDJ.SetParentComponent(ploscicaGrafMDJ);
    GrafMDJ.Name := 'GrafMDJ';
    GrafMDJ.Left := 7;
    GrafMDJ.Top := 7;
    GrafMDJ.Width := 697;
    GrafMDJ.Height := 382;
    GrafMDJ.HeightPercent := 100.000000000000000000;
    GrafMDJ.WidthPercent := 100.000000000000000000;
    GrafMDJ.Appearance.Animation.Startup := False;
    GrafMDJ.Series.Clear;
    with GrafMDJ.Series.Add do
    begin
      ChartType := gctLine;
      Color := clRed;
      Tag := -1;
    end;
    ploscicaGrafikaMDJ_osnovni.SetParentComponent(WebScrollBoxDL6);
    ploscicaGrafikaMDJ_osnovni.Name := 'ploscicaGrafikaMDJ_osnovni';
    ploscicaGrafikaMDJ_osnovni.Left := 623;
    ploscicaGrafikaMDJ_osnovni.Top := 80;
    ploscicaGrafikaMDJ_osnovni.Width := 102;
    ploscicaGrafikaMDJ_osnovni.Height := 60;
    ploscicaGrafikaMDJ_osnovni.HeightPercent := 100.000000000000000000;
    ploscicaGrafikaMDJ_osnovni.WidthPercent := 100.000000000000000000;
    ploscicaGrafikaMDJ_osnovni.BorderColor := clSilver;
    ploscicaGrafikaMDJ_osnovni.BorderStyle := bsSingle;
    ploscicaGrafikaMDJ_osnovni.ChildOrder := 10;
    izGraficniPrikazMDJ.SetParentComponent(ploscicaGrafikaMDJ_osnovni);
    izGraficniPrikazMDJ.Name := 'izGraficniPrikazMDJ';
    izGraficniPrikazMDJ.Left := 6;
    izGraficniPrikazMDJ.Top := 5;
    izGraficniPrikazMDJ.Width := 90;
    izGraficniPrikazMDJ.Height := 14;
    izGraficniPrikazMDJ.Alignment := taCenter;
    izGraficniPrikazMDJ.AutoSize := False;
    izGraficniPrikazMDJ.Caption := 'grafi'#269'ni prikaz';
    izGraficniPrikazMDJ.Color := clNone;
    izGraficniPrikazMDJ.Font.Charset := DEFAULT_CHARSET;
    izGraficniPrikazMDJ.Font.Color := clWindowText;
    izGraficniPrikazMDJ.Font.Height := -11;
    izGraficniPrikazMDJ.Font.Name := 'Arial';
    izGraficniPrikazMDJ.Font.Style := [];
    izGraficniPrikazMDJ.HeightPercent := 100.000000000000000000;
    izGraficniPrikazMDJ.ParentFont := False;
    izGraficniPrikazMDJ.WidthPercent := 100.000000000000000000;
    ploscicaGrafikaMDJ.SetParentComponent(ploscicaGrafikaMDJ_osnovni);
    ploscicaGrafikaMDJ.Name := 'ploscicaGrafikaMDJ';
    ploscicaGrafikaMDJ.Left := 5;
    ploscicaGrafikaMDJ.Top := 30;
    ploscicaGrafikaMDJ.Width := 93;
    ploscicaGrafikaMDJ.Height := 24;
    ploscicaGrafikaMDJ.HeightPercent := 100.000000000000000000;
    ploscicaGrafikaMDJ.WidthPercent := 100.000000000000000000;
    ploscicaGrafikaMDJ.Anchors := [];
    ploscicaGrafikaMDJ.BorderColor := clNone;
    ploscicaGrafikaMDJ.BorderStyle := bsNone;
    ploscicaGrafikaMDJ.ChildOrder := 1;
    ploscicaGrafikaMDJ.Color := clWindow;
    izGrafikaMDJ_DA.SetParentComponent(ploscicaGrafikaMDJ);
    izGrafikaMDJ_DA.Name := 'izGrafikaMDJ_DA';
    izGrafikaMDJ_DA.Left := 59;
    izGrafikaMDJ_DA.Top := 3;
    izGrafikaMDJ_DA.Width := 30;
    izGrafikaMDJ_DA.Height := 18;
    izGrafikaMDJ_DA.Alignment := taRightJustify;
    izGrafikaMDJ_DA.AutoSize := False;
    izGrafikaMDJ_DA.Caption := 'DA';
    izGrafikaMDJ_DA.Color := clNone;
    izGrafikaMDJ_DA.Font.Charset := DEFAULT_CHARSET;
    izGrafikaMDJ_DA.Font.Color := clWindowText;
    izGrafikaMDJ_DA.Font.Height := -11;
    izGrafikaMDJ_DA.Font.Name := 'Arial';
    izGrafikaMDJ_DA.Font.Style := [];
    izGrafikaMDJ_DA.HeightPercent := 100.000000000000000000;
    izGrafikaMDJ_DA.ParentFont := False;
    izGrafikaMDJ_DA.WidthPercent := 100.000000000000000000;
    izGrafikaMDJ_NE.SetParentComponent(ploscicaGrafikaMDJ);
    izGrafikaMDJ_NE.Name := 'izGrafikaMDJ_NE';
    izGrafikaMDJ_NE.Left := 2;
    izGrafikaMDJ_NE.Top := 3;
    izGrafikaMDJ_NE.Width := 35;
    izGrafikaMDJ_NE.Height := 18;
    izGrafikaMDJ_NE.AutoSize := False;
    izGrafikaMDJ_NE.Caption := 'NE';
    izGrafikaMDJ_NE.Color := clNone;
    izGrafikaMDJ_NE.Font.Charset := DEFAULT_CHARSET;
    izGrafikaMDJ_NE.Font.Color := clWindowText;
    izGrafikaMDJ_NE.Font.Height := -11;
    izGrafikaMDJ_NE.Font.Name := 'Arial';
    izGrafikaMDJ_NE.Font.Style := [];
    izGrafikaMDJ_NE.HeightPercent := 100.000000000000000000;
    izGrafikaMDJ_NE.ParentFont := False;
    izGrafikaMDJ_NE.WidthPercent := 100.000000000000000000;
    vpGrafikaMDJ.SetParentComponent(ploscicaGrafikaMDJ);
    vpGrafikaMDJ.Name := 'vpGrafikaMDJ';
    vpGrafikaMDJ.Left := 26;
    vpGrafikaMDJ.Top := -2;
    vpGrafikaMDJ.Width := 35;
    vpGrafikaMDJ.Height := 20;
    vpGrafikaMDJ.HeightPercent := 100.000000000000000000;
    vpGrafikaMDJ.WidthPercent := 100.000000000000000000;
    vpGrafikaMDJ.Anchors := [];
    vpGrafikaMDJ.ChildOrder := 2;
    vpGrafikaMDJ.Max := 1;
    vpGrafikaMDJ.Min := 0;
    vpGrafikaMDJ.Position := 0;
    vpGrafikaMDJ.Role := '';
    SetEvent(vpGrafikaMDJ, Self, 'OnChange', 'vpGrafikaMDJChange');
    DelovniList7.SetParentComponent(ploscicaDelovniListi);
    DelovniList7.Name := 'DelovniList7';
    DelovniList7.Left := 0;
    DelovniList7.Top := 20;
    DelovniList7.Width := 1157;
    DelovniList7.Height := 1090;
    DelovniList7.HeightPercent := 100.000000000000000000;
    DelovniList7.WidthPercent := 100.000000000000000000;
    DelovniList7.Caption := 'DelovniList7';
    DelovniList7.ChildOrder := 9;
    DelovniList7.TabVisible := False;
    WebScrollBoxDL7.SetParentComponent(DelovniList7);
    WebScrollBoxDL7.Name := 'WebScrollBoxDL7';
    WebScrollBoxDL7.Left := 0;
    WebScrollBoxDL7.Top := 0;
    WebScrollBoxDL7.Width := 1157;
    WebScrollBoxDL7.Height := 1090;
    WebScrollBoxDL7.HeightPercent := 100.000000000000000000;
    WebScrollBoxDL7.WidthPercent := 100.000000000000000000;
    WebScrollBoxDL7.Align := alClient;
    WebScrollBoxDL7.AutoScroll := True;
    WebScrollBoxDL7.BorderStyle := bsNone;
    WebScrollBoxDL7.ChildOrder := 3;
    WebScrollBoxDL7.Color := clWindow;
    izNaslovDL7.SetParentComponent(WebScrollBoxDL7);
    izNaslovDL7.Name := 'izNaslovDL7';
    izNaslovDL7.Left := 12;
    izNaslovDL7.Top := 20;
    izNaslovDL7.Width := 219;
    izNaslovDL7.Height := 19;
    izNaslovDL7.Anchors := [];
    izNaslovDL7.Caption := 'Potrebna zavarovalna vsota';
    izNaslovDL7.Color := clNone;
    izNaslovDL7.Font.Charset := DEFAULT_CHARSET;
    izNaslovDL7.Font.Color := clWindowText;
    izNaslovDL7.Font.Height := -17;
    izNaslovDL7.Font.Name := 'Arial';
    izNaslovDL7.Font.Style := [fsBold];
    izNaslovDL7.HeightPercent := 100.000000000000000000;
    izNaslovDL7.ParentFont := False;
    izNaslovDL7.WidthPercent := 100.000000000000000000;
    ploscicaPriporoceneZV.SetParentComponent(WebScrollBoxDL7);
    ploscicaPriporoceneZV.Name := 'ploscicaPriporoceneZV';
    ploscicaPriporoceneZV.Left := 15;
    ploscicaPriporoceneZV.Top := 215;
    ploscicaPriporoceneZV.Width := 525;
    ploscicaPriporoceneZV.Height := 225;
    ploscicaPriporoceneZV.HeightPercent := 100.000000000000000000;
    ploscicaPriporoceneZV.WidthPercent := 100.000000000000000000;
    ploscicaPriporoceneZV.BorderColor := clSilver;
    ploscicaPriporoceneZV.BorderStyle := bsSingle;
    ploscicaPriporoceneZV.Caption := 'ploscicaPriporoceneZV';
    ploscicaPriporoceneZV.ChildOrder := 2;
    ploscicaPriporoceneZV.Color := 13882323;
    izTekstLetoPriporoceneZV.SetParentComponent(ploscicaPriporoceneZV);
    izTekstLetoPriporoceneZV.Name := 'izTekstLetoPriporoceneZV';
    izTekstLetoPriporoceneZV.Left := 15;
    izTekstLetoPriporoceneZV.Top := 22;
    izTekstLetoPriporoceneZV.Width := 291;
    izTekstLetoPriporoceneZV.Height := 15;
    izTekstLetoPriporoceneZV.Caption := 'Izra'#269'un potrebne zavarovalne vsote za zavarovalno leto:';
    izTekstLetoPriporoceneZV.Color := clNone;
    izTekstLetoPriporoceneZV.HeightPercent := 100.000000000000000000;
    izTekstLetoPriporoceneZV.WidthPercent := 100.000000000000000000;
    ploscicaLetoPriporoceneZV.SetParentComponent(ploscicaPriporoceneZV);
    ploscicaLetoPriporoceneZV.Name := 'ploscicaLetoPriporoceneZV';
    ploscicaLetoPriporoceneZV.Left := 379;
    ploscicaLetoPriporoceneZV.Top := 19;
    ploscicaLetoPriporoceneZV.Width := 105;
    ploscicaLetoPriporoceneZV.Height := 24;
    ploscicaLetoPriporoceneZV.HeightPercent := 100.000000000000000000;
    ploscicaLetoPriporoceneZV.WidthPercent := 100.000000000000000000;
    ploscicaLetoPriporoceneZV.Anchors := [];
    ploscicaLetoPriporoceneZV.BorderColor := clNone;
    ploscicaLetoPriporoceneZV.BorderStyle := bsNone;
    ploscicaLetoPriporoceneZV.ChildOrder := 1;
    ploscicaLetoPriporoceneZV.Color := clWindow;
    izLetoPriporoceneZVmax.SetParentComponent(ploscicaLetoPriporoceneZV);
    izLetoPriporoceneZVmax.Name := 'izLetoPriporoceneZVmax';
    izLetoPriporoceneZVmax.Left := 70;
    izLetoPriporoceneZVmax.Top := 4;
    izLetoPriporoceneZVmax.Width := 35;
    izLetoPriporoceneZVmax.Height := 18;
    izLetoPriporoceneZVmax.Alignment := taRightJustify;
    izLetoPriporoceneZVmax.AutoSize := False;
    izLetoPriporoceneZVmax.Caption := 'xxxx';
    izLetoPriporoceneZVmax.Color := clNone;
    izLetoPriporoceneZVmax.Font.Charset := DEFAULT_CHARSET;
    izLetoPriporoceneZVmax.Font.Color := clWindowText;
    izLetoPriporoceneZVmax.Font.Height := -11;
    izLetoPriporoceneZVmax.Font.Name := 'Arial';
    izLetoPriporoceneZVmax.Font.Style := [];
    izLetoPriporoceneZVmax.HeightPercent := 100.000000000000000000;
    izLetoPriporoceneZVmax.ParentFont := False;
    izLetoPriporoceneZVmax.WidthPercent := 100.000000000000000000;
    izLetoPriporoceneZVmin.SetParentComponent(ploscicaLetoPriporoceneZV);
    izLetoPriporoceneZVmin.Name := 'izLetoPriporoceneZVmin';
    izLetoPriporoceneZVmin.Left := 0;
    izLetoPriporoceneZVmin.Top := 3;
    izLetoPriporoceneZVmin.Width := 35;
    izLetoPriporoceneZVmin.Height := 18;
    izLetoPriporoceneZVmin.AutoSize := False;
    izLetoPriporoceneZVmin.Caption := 'xxxx';
    izLetoPriporoceneZVmin.Color := clNone;
    izLetoPriporoceneZVmin.Font.Charset := DEFAULT_CHARSET;
    izLetoPriporoceneZVmin.Font.Color := clWindowText;
    izLetoPriporoceneZVmin.Font.Height := -11;
    izLetoPriporoceneZVmin.Font.Name := 'Arial';
    izLetoPriporoceneZVmin.Font.Style := [];
    izLetoPriporoceneZVmin.HeightPercent := 100.000000000000000000;
    izLetoPriporoceneZVmin.ParentFont := False;
    izLetoPriporoceneZVmin.WidthPercent := 100.000000000000000000;
    vpLetoPriporoceneZV.SetParentComponent(ploscicaLetoPriporoceneZV);
    vpLetoPriporoceneZV.Name := 'vpLetoPriporoceneZV';
    vpLetoPriporoceneZV.Left := 29;
    vpLetoPriporoceneZV.Top := -2;
    vpLetoPriporoceneZV.Width := 35;
    vpLetoPriporoceneZV.Height := 20;
    vpLetoPriporoceneZV.HeightPercent := 100.000000000000000000;
    vpLetoPriporoceneZV.WidthPercent := 100.000000000000000000;
    vpLetoPriporoceneZV.Anchors := [];
    vpLetoPriporoceneZV.Max := 1;
    vpLetoPriporoceneZV.Min := 0;
    vpLetoPriporoceneZV.Position := 0;
    vpLetoPriporoceneZV.Role := '';
    SetEvent(vpLetoPriporoceneZV, Self, 'OnChange', 'vpLetoPriporoceneZVChange');
    ploscicaTabelaPriporoceneZV.SetParentComponent(ploscicaPriporoceneZV);
    ploscicaTabelaPriporoceneZV.Name := 'ploscicaTabelaPriporoceneZV';
    ploscicaTabelaPriporoceneZV.Left := 15;
    ploscicaTabelaPriporoceneZV.Top := 75;
    ploscicaTabelaPriporoceneZV.Width := 400;
    ploscicaTabelaPriporoceneZV.Height := 120;
    ploscicaTabelaPriporoceneZV.HeightPercent := 100.000000000000000000;
    ploscicaTabelaPriporoceneZV.WidthPercent := 100.000000000000000000;
    ploscicaTabelaPriporoceneZV.ChildOrder := 2;
    ploscicaTabelaPriporoceneZV.ColumnCollection.Clear;
    with ploscicaTabelaPriporoceneZV.ColumnCollection.Add do
    begin
      Alignment := taCenter;
      Value := 50;
    end;
    with ploscicaTabelaPriporoceneZV.ColumnCollection.Add do
    begin
      Alignment := taCenter;
      Value := 50;
    end;
    ploscicaTabelaPriporoceneZV.ControlCollection.Clear;
    with ploscicaTabelaPriporoceneZV.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izPriporoceneZV_Naslov1;
    end;
    with ploscicaTabelaPriporoceneZV.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izPriporoceneZV_Naslov2;
    end;
    with ploscicaTabelaPriporoceneZV.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izPriporoceneZV_tekstZV12;
    end;
    with ploscicaTabelaPriporoceneZV.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izPriporoceneZV_ZV12;
    end;
    with ploscicaTabelaPriporoceneZV.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izPriporoceneZV_tekstZV18;
    end;
    with ploscicaTabelaPriporoceneZV.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izPriporoceneZV_ZV18;
    end;
    with ploscicaTabelaPriporoceneZV.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izPriporoceneZV_tekstZV24;
    end;
    with ploscicaTabelaPriporoceneZV.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izPriporoceneZV_ZV24;
    end;
    ploscicaTabelaPriporoceneZV.Color := clWhite;
    ploscicaTabelaPriporoceneZV.GridLineWidth := 1;
    ploscicaTabelaPriporoceneZV.GridLineColor := clGray;
    ploscicaTabelaPriporoceneZV.RowCollection.Clear;
    with ploscicaTabelaPriporoceneZV.RowCollection.Add do
    begin
      Value := 25;
    end;
    with ploscicaTabelaPriporoceneZV.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 25;
    end;
    with ploscicaTabelaPriporoceneZV.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 25;
    end;
    with ploscicaTabelaPriporoceneZV.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 25;
    end;
    izPriporoceneZV_Naslov1.SetParentComponent(ploscicaTabelaPriporoceneZV);
    izPriporoceneZV_Naslov1.Name := 'izPriporoceneZV_Naslov1';
    izPriporoceneZV_Naslov1.Left := 26;
    izPriporoceneZV_Naslov1.Top := 2;
    izPriporoceneZV_Naslov1.Width := 148;
    izPriporoceneZV_Naslov1.Height := 14;
    izPriporoceneZV_Naslov1.Anchors := [];
    izPriporoceneZV_Naslov1.Caption := 'Maksimalna doba jam'#269'enja';
    izPriporoceneZV_Naslov1.Color := clNone;
    izPriporoceneZV_Naslov1.Font.Charset := DEFAULT_CHARSET;
    izPriporoceneZV_Naslov1.Font.Color := clWindowText;
    izPriporoceneZV_Naslov1.Font.Height := -11;
    izPriporoceneZV_Naslov1.Font.Name := 'Arial';
    izPriporoceneZV_Naslov1.Font.Style := [fsBold];
    izPriporoceneZV_Naslov1.HeightPercent := 100.000000000000000000;
    izPriporoceneZV_Naslov1.ParentFont := False;
    izPriporoceneZV_Naslov1.WidthPercent := 100.000000000000000000;
    izPriporoceneZV_Naslov2.SetParentComponent(ploscicaTabelaPriporoceneZV);
    izPriporoceneZV_Naslov2.Name := 'izPriporoceneZV_Naslov2';
    izPriporoceneZV_Naslov2.Left := 216;
    izPriporoceneZV_Naslov2.Top := 2;
    izPriporoceneZV_Naslov2.Width := 167;
    izPriporoceneZV_Naslov2.Height := 14;
    izPriporoceneZV_Naslov2.Anchors := [];
    izPriporoceneZV_Naslov2.Caption := 'Priporo'#269'ena zavarovalna vsota';
    izPriporoceneZV_Naslov2.Color := clNone;
    izPriporoceneZV_Naslov2.Font.Charset := DEFAULT_CHARSET;
    izPriporoceneZV_Naslov2.Font.Color := clWindowText;
    izPriporoceneZV_Naslov2.Font.Height := -11;
    izPriporoceneZV_Naslov2.Font.Name := 'Arial';
    izPriporoceneZV_Naslov2.Font.Style := [fsBold];
    izPriporoceneZV_Naslov2.HeightPercent := 100.000000000000000000;
    izPriporoceneZV_Naslov2.ParentFont := False;
    izPriporoceneZV_Naslov2.WidthPercent := 100.000000000000000000;
    izPriporoceneZV_tekstZV12.SetParentComponent(ploscicaTabelaPriporoceneZV);
    izPriporoceneZV_tekstZV12.Name := 'izPriporoceneZV_tekstZV12';
    izPriporoceneZV_tekstZV12.Left := 61;
    izPriporoceneZV_tekstZV12.Top := 37;
    izPriporoceneZV_tekstZV12.Width := 78;
    izPriporoceneZV_tekstZV12.Height := 15;
    izPriporoceneZV_tekstZV12.Anchors := [];
    izPriporoceneZV_tekstZV12.Caption := 'do 12 mesecev';
    izPriporoceneZV_tekstZV12.Color := clNone;
    izPriporoceneZV_tekstZV12.HeightPercent := 100.000000000000000000;
    izPriporoceneZV_tekstZV12.WidthPercent := 100.000000000000000000;
    izPriporoceneZV_ZV12.SetParentComponent(ploscicaTabelaPriporoceneZV);
    izPriporoceneZV_ZV12.Name := 'izPriporoceneZV_ZV12';
    izPriporoceneZV_ZV12.Left := 273;
    izPriporoceneZV_ZV12.Top := 37;
    izPriporoceneZV_ZV12.Width := 54;
    izPriporoceneZV_ZV12.Height := 15;
    izPriporoceneZV_ZV12.Anchors := [];
    izPriporoceneZV_ZV12.Caption := '000,000.00';
    izPriporoceneZV_ZV12.Color := clNone;
    izPriporoceneZV_ZV12.HeightPercent := 100.000000000000000000;
    izPriporoceneZV_ZV12.WidthPercent := 100.000000000000000000;
    izPriporoceneZV_tekstZV18.SetParentComponent(ploscicaTabelaPriporoceneZV);
    izPriporoceneZV_tekstZV18.Name := 'izPriporoceneZV_tekstZV18';
    izPriporoceneZV_tekstZV18.Left := 69;
    izPriporoceneZV_tekstZV18.Top := 67;
    izPriporoceneZV_tekstZV18.Width := 61;
    izPriporoceneZV_tekstZV18.Height := 15;
    izPriporoceneZV_tekstZV18.Anchors := [];
    izPriporoceneZV_tekstZV18.Caption := '18 mesecev';
    izPriporoceneZV_tekstZV18.Color := clNone;
    izPriporoceneZV_tekstZV18.HeightPercent := 100.000000000000000000;
    izPriporoceneZV_tekstZV18.WidthPercent := 100.000000000000000000;
    izPriporoceneZV_ZV18.SetParentComponent(ploscicaTabelaPriporoceneZV);
    izPriporoceneZV_ZV18.Name := 'izPriporoceneZV_ZV18';
    izPriporoceneZV_ZV18.Left := 273;
    izPriporoceneZV_ZV18.Top := 67;
    izPriporoceneZV_ZV18.Width := 54;
    izPriporoceneZV_ZV18.Height := 15;
    izPriporoceneZV_ZV18.Anchors := [];
    izPriporoceneZV_ZV18.Caption := '000,000.00';
    izPriporoceneZV_ZV18.Color := clNone;
    izPriporoceneZV_ZV18.HeightPercent := 100.000000000000000000;
    izPriporoceneZV_ZV18.WidthPercent := 100.000000000000000000;
    izPriporoceneZV_tekstZV24.SetParentComponent(ploscicaTabelaPriporoceneZV);
    izPriporoceneZV_tekstZV24.Name := 'izPriporoceneZV_tekstZV24';
    izPriporoceneZV_tekstZV24.Left := 69;
    izPriporoceneZV_tekstZV24.Top := 97;
    izPriporoceneZV_tekstZV24.Width := 61;
    izPriporoceneZV_tekstZV24.Height := 15;
    izPriporoceneZV_tekstZV24.Anchors := [];
    izPriporoceneZV_tekstZV24.Caption := '24 mesecev';
    izPriporoceneZV_tekstZV24.Color := clNone;
    izPriporoceneZV_tekstZV24.HeightPercent := 100.000000000000000000;
    izPriporoceneZV_tekstZV24.WidthPercent := 100.000000000000000000;
    izPriporoceneZV_ZV24.SetParentComponent(ploscicaTabelaPriporoceneZV);
    izPriporoceneZV_ZV24.Name := 'izPriporoceneZV_ZV24';
    izPriporoceneZV_ZV24.Left := 273;
    izPriporoceneZV_ZV24.Top := 97;
    izPriporoceneZV_ZV24.Width := 54;
    izPriporoceneZV_ZV24.Height := 15;
    izPriporoceneZV_ZV24.Anchors := [];
    izPriporoceneZV_ZV24.Caption := '000,000.00';
    izPriporoceneZV_ZV24.Color := clNone;
    izPriporoceneZV_ZV24.HeightPercent := 100.000000000000000000;
    izPriporoceneZV_ZV24.WidthPercent := 100.000000000000000000;
    ploscicaZVRezultat.SetParentComponent(WebScrollBoxDL7);
    ploscicaZVRezultat.Name := 'ploscicaZVRezultat';
    ploscicaZVRezultat.Left := 16;
    ploscicaZVRezultat.Top := 80;
    ploscicaZVRezultat.Width := 525;
    ploscicaZVRezultat.Height := 90;
    ploscicaZVRezultat.HeightPercent := 100.000000000000000000;
    ploscicaZVRezultat.WidthPercent := 100.000000000000000000;
    ploscicaZVRezultat.BorderColor := clSilver;
    ploscicaZVRezultat.BorderStyle := bsSingle;
    ploscicaZVRezultat.ChildOrder := 1;
    ploscicaZVRezultat.Color := 16316664;
    izTekstPriporoceniKD.SetParentComponent(ploscicaZVRezultat);
    izTekstPriporoceniKD.Name := 'izTekstPriporoceniKD';
    izTekstPriporoceniKD.Left := 17;
    izTekstPriporoceniKD.Top := 21;
    izTekstPriporoceniKD.Width := 208;
    izTekstPriporoceniKD.Height := 15;
    izTekstPriporoceniKD.Caption := 'Izra'#269'unan kosmati dobi'#269'ek za leto xxxx:';
    izTekstPriporoceniKD.Color := clNone;
    izTekstPriporoceniKD.HeightPercent := 100.000000000000000000;
    izTekstPriporoceniKD.WidthPercent := 100.000000000000000000;
    izTekstPriporocenaSKD.SetParentComponent(ploscicaZVRezultat);
    izTekstPriporocenaSKD.Name := 'izTekstPriporocenaSKD';
    izTekstPriporocenaSKD.Left := 15;
    izTekstPriporocenaSKD.Top := 50;
    izTekstPriporocenaSKD.Width := 272;
    izTekstPriporocenaSKD.Height := 15;
    izTekstPriporocenaSKD.Caption := 'Izra'#269'unana stopnja kosmatega dobi'#269'ka za leto xxxx:';
    izTekstPriporocenaSKD.Color := clNone;
    izTekstPriporocenaSKD.HeightPercent := 100.000000000000000000;
    izTekstPriporocenaSKD.WidthPercent := 100.000000000000000000;
    izPriporoceniKD.SetParentComponent(ploscicaZVRezultat);
    izPriporoceniKD.Name := 'izPriporoceniKD';
    izPriporoceniKD.Left := 375;
    izPriporoceniKD.Top := 22;
    izPriporoceniKD.Width := 125;
    izPriporoceniKD.Height := 19;
    izPriporoceniKD.Alignment := taRightJustify;
    izPriporoceniKD.AutoSize := False;
    izPriporoceniKD.Caption := '1,000,000.00';
    izPriporoceniKD.Color := clNone;
    izPriporoceniKD.Font.Charset := DEFAULT_CHARSET;
    izPriporoceniKD.Font.Color := clWindowText;
    izPriporoceniKD.Font.Height := -11;
    izPriporoceniKD.Font.Name := 'Arial';
    izPriporoceniKD.Font.Style := [fsBold];
    izPriporoceniKD.HeightPercent := 100.000000000000000000;
    izPriporoceniKD.ParentFont := False;
    izPriporoceniKD.WidthPercent := 100.000000000000000000;
    izPriporocenaSKD.SetParentComponent(ploscicaZVRezultat);
    izPriporocenaSKD.Name := 'izPriporocenaSKD';
    izPriporocenaSKD.Left := 375;
    izPriporocenaSKD.Top := 50;
    izPriporocenaSKD.Width := 125;
    izPriporocenaSKD.Height := 19;
    izPriporocenaSKD.Alignment := taRightJustify;
    izPriporocenaSKD.AutoSize := False;
    izPriporocenaSKD.Caption := '00.00 %';
    izPriporocenaSKD.Color := clNone;
    izPriporocenaSKD.Font.Charset := DEFAULT_CHARSET;
    izPriporocenaSKD.Font.Color := clWindowText;
    izPriporocenaSKD.Font.Height := -11;
    izPriporocenaSKD.Font.Name := 'Arial';
    izPriporocenaSKD.Font.Style := [fsBold];
    izPriporocenaSKD.HeightPercent := 100.000000000000000000;
    izPriporocenaSKD.ParentFont := False;
    izPriporocenaSKD.WidthPercent := 100.000000000000000000;
    DelovniList8.SetParentComponent(ploscicaDelovniListi);
    DelovniList8.Name := 'DelovniList8';
    DelovniList8.Left := 0;
    DelovniList8.Top := 20;
    DelovniList8.Width := 1157;
    DelovniList8.Height := 1090;
    DelovniList8.HeightPercent := 100.000000000000000000;
    DelovniList8.WidthPercent := 100.000000000000000000;
    DelovniList8.Caption := 'DelovniList8';
    DelovniList8.ChildOrder := 10;
    DelovniList8.TabVisible := False;
    WebScrollBoxDL8.SetParentComponent(DelovniList8);
    WebScrollBoxDL8.Name := 'WebScrollBoxDL8';
    WebScrollBoxDL8.Left := 0;
    WebScrollBoxDL8.Top := 0;
    WebScrollBoxDL8.Width := 1157;
    WebScrollBoxDL8.Height := 1090;
    WebScrollBoxDL8.HeightPercent := 100.000000000000000000;
    WebScrollBoxDL8.WidthPercent := 100.000000000000000000;
    WebScrollBoxDL8.Align := alClient;
    WebScrollBoxDL8.AutoScroll := True;
    WebScrollBoxDL8.BorderStyle := bsNone;
    WebScrollBoxDL8.ChildOrder := 8;
    WebScrollBoxDL8.Color := clWindow;
    izNaslovDL8.SetParentComponent(WebScrollBoxDL8);
    izNaslovDL8.Name := 'izNaslovDL8';
    izNaslovDL8.Left := 14;
    izNaslovDL8.Top := 20;
    izNaslovDL8.Width := 201;
    izNaslovDL8.Height := 19;
    izNaslovDL8.Anchors := [];
    izNaslovDL8.Caption := 'Analiza prodajnih trendov';
    izNaslovDL8.Color := clNone;
    izNaslovDL8.Font.Charset := DEFAULT_CHARSET;
    izNaslovDL8.Font.Color := clWindowText;
    izNaslovDL8.Font.Height := -17;
    izNaslovDL8.Font.Name := 'Arial';
    izNaslovDL8.Font.Style := [fsBold];
    izNaslovDL8.HeightPercent := 100.000000000000000000;
    izNaslovDL8.ParentFont := False;
    izNaslovDL8.WidthPercent := 100.000000000000000000;
    ListiProdajnihTrendov.SetParentComponent(WebScrollBoxDL8);
    ListiProdajnihTrendov.Name := 'ListiProdajnihTrendov';
    ListiProdajnihTrendov.Left := 19;
    ListiProdajnihTrendov.Top := 60;
    ListiProdajnihTrendov.Width := 747;
    ListiProdajnihTrendov.Height := 436;
    ListiProdajnihTrendov.HeightPercent := 100.000000000000000000;
    ListiProdajnihTrendov.WidthPercent := 100.000000000000000000;
    ListiProdajnihTrendov.BorderStyle := bsSingle;
    ListiProdajnihTrendov.ChildOrder := 10;
    ListiProdajnihTrendov.TabIndex := 0;
    ListiProdajnihTrendov.TabOrder := 0;
    SetEvent(ListiProdajnihTrendov, Self, 'OnChange', 'ListiProdajnihTrendovChange');
    LPT1_Tabela.SetParentComponent(ListiProdajnihTrendov);
    LPT1_Tabela.Name := 'LPT1_Tabela';
    LPT1_Tabela.Left := 0;
    LPT1_Tabela.Top := 20;
    LPT1_Tabela.Width := 747;
    LPT1_Tabela.Height := 416;
    LPT1_Tabela.HeightPercent := 100.000000000000000000;
    LPT1_Tabela.WidthPercent := 100.000000000000000000;
    LPT1_Tabela.Caption := 'tabela';
    ploscicaAnalizeProdaje_Meseci.SetParentComponent(LPT1_Tabela);
    ploscicaAnalizeProdaje_Meseci.Name := 'ploscicaAnalizeProdaje_Meseci';
    ploscicaAnalizeProdaje_Meseci.Left := 23;
    ploscicaAnalizeProdaje_Meseci.Top := 40;
    ploscicaAnalizeProdaje_Meseci.Width := 100;
    ploscicaAnalizeProdaje_Meseci.Height := 330;
    ploscicaAnalizeProdaje_Meseci.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_Meseci.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_Meseci.ChildOrder := 1;
    ploscicaAnalizeProdaje_Meseci.ColumnCollection.Clear;
    with ploscicaAnalizeProdaje_Meseci.ColumnCollection.Add do
    begin
      MarginLeft := 5;
      Value := 50;
    end;
    ploscicaAnalizeProdaje_Meseci.ControlCollection.Clear;
    with ploscicaAnalizeProdaje_Meseci.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izMesec_Naslov;
    end;
    with ploscicaAnalizeProdaje_Meseci.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izMesec_1;
    end;
    with ploscicaAnalizeProdaje_Meseci.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izMesec_2;
    end;
    with ploscicaAnalizeProdaje_Meseci.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izMesec_3;
    end;
    with ploscicaAnalizeProdaje_Meseci.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izMesec_4;
    end;
    with ploscicaAnalizeProdaje_Meseci.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izMesec_5;
    end;
    with ploscicaAnalizeProdaje_Meseci.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izMesec_6;
    end;
    with ploscicaAnalizeProdaje_Meseci.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izMesec_7;
    end;
    with ploscicaAnalizeProdaje_Meseci.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izMesec_8;
    end;
    with ploscicaAnalizeProdaje_Meseci.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izMesec_9;
    end;
    with ploscicaAnalizeProdaje_Meseci.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izMesec_10;
    end;
    with ploscicaAnalizeProdaje_Meseci.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izMesec_11;
    end;
    with ploscicaAnalizeProdaje_Meseci.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izMesec_12;
    end;
    ploscicaAnalizeProdaje_Meseci.Color := clWhite;
    ploscicaAnalizeProdaje_Meseci.GridLineWidth := 1;
    ploscicaAnalizeProdaje_Meseci.GridLineColor := clBlack;
    ploscicaAnalizeProdaje_Meseci.RowCollection.Clear;
    with ploscicaAnalizeProdaje_Meseci.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_Meseci.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_Meseci.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_Meseci.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_Meseci.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_Meseci.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_Meseci.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_Meseci.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_Meseci.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_Meseci.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_Meseci.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_Meseci.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_Meseci.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    ploscicaAnalizeProdaje_izMesec_Naslov.SetParentComponent(ploscicaAnalizeProdaje_Meseci);
    ploscicaAnalizeProdaje_izMesec_Naslov.Name := 'ploscicaAnalizeProdaje_izMesec_Naslov';
    ploscicaAnalizeProdaje_izMesec_Naslov.Left := 7;
    ploscicaAnalizeProdaje_izMesec_Naslov.Top := 6;
    ploscicaAnalizeProdaje_izMesec_Naslov.Width := 68;
    ploscicaAnalizeProdaje_izMesec_Naslov.Height := 14;
    ploscicaAnalizeProdaje_izMesec_Naslov.Anchors := [];
    ploscicaAnalizeProdaje_izMesec_Naslov.Caption := 'mesec / leto';
    ploscicaAnalizeProdaje_izMesec_Naslov.Color := clNone;
    ploscicaAnalizeProdaje_izMesec_Naslov.Font.Charset := DEFAULT_CHARSET;
    ploscicaAnalizeProdaje_izMesec_Naslov.Font.Color := clWindowText;
    ploscicaAnalizeProdaje_izMesec_Naslov.Font.Height := -11;
    ploscicaAnalizeProdaje_izMesec_Naslov.Font.Name := 'Arial';
    ploscicaAnalizeProdaje_izMesec_Naslov.Font.Style := [fsBold];
    ploscicaAnalizeProdaje_izMesec_Naslov.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izMesec_Naslov.ParentFont := False;
    ploscicaAnalizeProdaje_izMesec_Naslov.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izMesec_1.SetParentComponent(ploscicaAnalizeProdaje_Meseci);
    ploscicaAnalizeProdaje_izMesec_1.Name := 'ploscicaAnalizeProdaje_izMesec_1';
    ploscicaAnalizeProdaje_izMesec_1.Left := 7;
    ploscicaAnalizeProdaje_izMesec_1.Top := 31;
    ploscicaAnalizeProdaje_izMesec_1.Width := 33;
    ploscicaAnalizeProdaje_izMesec_1.Height := 15;
    ploscicaAnalizeProdaje_izMesec_1.Anchors := [];
    ploscicaAnalizeProdaje_izMesec_1.Caption := 'januar';
    ploscicaAnalizeProdaje_izMesec_1.Color := clNone;
    ploscicaAnalizeProdaje_izMesec_1.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izMesec_1.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izMesec_2.SetParentComponent(ploscicaAnalizeProdaje_Meseci);
    ploscicaAnalizeProdaje_izMesec_2.Name := 'ploscicaAnalizeProdaje_izMesec_2';
    ploscicaAnalizeProdaje_izMesec_2.Left := 7;
    ploscicaAnalizeProdaje_izMesec_2.Top := 57;
    ploscicaAnalizeProdaje_izMesec_2.Width := 38;
    ploscicaAnalizeProdaje_izMesec_2.Height := 15;
    ploscicaAnalizeProdaje_izMesec_2.Anchors := [];
    ploscicaAnalizeProdaje_izMesec_2.Caption := 'februar';
    ploscicaAnalizeProdaje_izMesec_2.Color := clNone;
    ploscicaAnalizeProdaje_izMesec_2.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izMesec_2.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izMesec_3.SetParentComponent(ploscicaAnalizeProdaje_Meseci);
    ploscicaAnalizeProdaje_izMesec_3.Name := 'ploscicaAnalizeProdaje_izMesec_3';
    ploscicaAnalizeProdaje_izMesec_3.Left := 7;
    ploscicaAnalizeProdaje_izMesec_3.Top := 83;
    ploscicaAnalizeProdaje_izMesec_3.Width := 33;
    ploscicaAnalizeProdaje_izMesec_3.Height := 15;
    ploscicaAnalizeProdaje_izMesec_3.Anchors := [];
    ploscicaAnalizeProdaje_izMesec_3.Caption := 'marec';
    ploscicaAnalizeProdaje_izMesec_3.Color := clNone;
    ploscicaAnalizeProdaje_izMesec_3.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izMesec_3.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izMesec_4.SetParentComponent(ploscicaAnalizeProdaje_Meseci);
    ploscicaAnalizeProdaje_izMesec_4.Name := 'ploscicaAnalizeProdaje_izMesec_4';
    ploscicaAnalizeProdaje_izMesec_4.Left := 7;
    ploscicaAnalizeProdaje_izMesec_4.Top := 109;
    ploscicaAnalizeProdaje_izMesec_4.Width := 23;
    ploscicaAnalizeProdaje_izMesec_4.Height := 15;
    ploscicaAnalizeProdaje_izMesec_4.Anchors := [];
    ploscicaAnalizeProdaje_izMesec_4.Caption := 'april';
    ploscicaAnalizeProdaje_izMesec_4.Color := clNone;
    ploscicaAnalizeProdaje_izMesec_4.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izMesec_4.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izMesec_5.SetParentComponent(ploscicaAnalizeProdaje_Meseci);
    ploscicaAnalizeProdaje_izMesec_5.Name := 'ploscicaAnalizeProdaje_izMesec_5';
    ploscicaAnalizeProdaje_izMesec_5.Left := 7;
    ploscicaAnalizeProdaje_izMesec_5.Top := 135;
    ploscicaAnalizeProdaje_izMesec_5.Width := 20;
    ploscicaAnalizeProdaje_izMesec_5.Height := 15;
    ploscicaAnalizeProdaje_izMesec_5.Anchors := [];
    ploscicaAnalizeProdaje_izMesec_5.Caption := 'maj';
    ploscicaAnalizeProdaje_izMesec_5.Color := clNone;
    ploscicaAnalizeProdaje_izMesec_5.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izMesec_5.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izMesec_6.SetParentComponent(ploscicaAnalizeProdaje_Meseci);
    ploscicaAnalizeProdaje_izMesec_6.Name := 'ploscicaAnalizeProdaje_izMesec_6';
    ploscicaAnalizeProdaje_izMesec_6.Left := 7;
    ploscicaAnalizeProdaje_izMesec_6.Top := 161;
    ploscicaAnalizeProdaje_izMesec_6.Width := 23;
    ploscicaAnalizeProdaje_izMesec_6.Height := 15;
    ploscicaAnalizeProdaje_izMesec_6.Anchors := [];
    ploscicaAnalizeProdaje_izMesec_6.Caption := 'junij';
    ploscicaAnalizeProdaje_izMesec_6.Color := clNone;
    ploscicaAnalizeProdaje_izMesec_6.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izMesec_6.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izMesec_7.SetParentComponent(ploscicaAnalizeProdaje_Meseci);
    ploscicaAnalizeProdaje_izMesec_7.Name := 'ploscicaAnalizeProdaje_izMesec_7';
    ploscicaAnalizeProdaje_izMesec_7.Left := 7;
    ploscicaAnalizeProdaje_izMesec_7.Top := 187;
    ploscicaAnalizeProdaje_izMesec_7.Width := 19;
    ploscicaAnalizeProdaje_izMesec_7.Height := 15;
    ploscicaAnalizeProdaje_izMesec_7.Anchors := [];
    ploscicaAnalizeProdaje_izMesec_7.Caption := 'julij';
    ploscicaAnalizeProdaje_izMesec_7.Color := clNone;
    ploscicaAnalizeProdaje_izMesec_7.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izMesec_7.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izMesec_8.SetParentComponent(ploscicaAnalizeProdaje_Meseci);
    ploscicaAnalizeProdaje_izMesec_8.Name := 'ploscicaAnalizeProdaje_izMesec_8';
    ploscicaAnalizeProdaje_izMesec_8.Left := 7;
    ploscicaAnalizeProdaje_izMesec_8.Top := 213;
    ploscicaAnalizeProdaje_izMesec_8.Width := 35;
    ploscicaAnalizeProdaje_izMesec_8.Height := 15;
    ploscicaAnalizeProdaje_izMesec_8.Anchors := [];
    ploscicaAnalizeProdaje_izMesec_8.Caption := 'avgust';
    ploscicaAnalizeProdaje_izMesec_8.Color := clNone;
    ploscicaAnalizeProdaje_izMesec_8.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izMesec_8.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izMesec_9.SetParentComponent(ploscicaAnalizeProdaje_Meseci);
    ploscicaAnalizeProdaje_izMesec_9.Name := 'ploscicaAnalizeProdaje_izMesec_9';
    ploscicaAnalizeProdaje_izMesec_9.Left := 7;
    ploscicaAnalizeProdaje_izMesec_9.Top := 239;
    ploscicaAnalizeProdaje_izMesec_9.Width := 56;
    ploscicaAnalizeProdaje_izMesec_9.Height := 15;
    ploscicaAnalizeProdaje_izMesec_9.Anchors := [];
    ploscicaAnalizeProdaje_izMesec_9.Caption := 'september';
    ploscicaAnalizeProdaje_izMesec_9.Color := clNone;
    ploscicaAnalizeProdaje_izMesec_9.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izMesec_9.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izMesec_10.SetParentComponent(ploscicaAnalizeProdaje_Meseci);
    ploscicaAnalizeProdaje_izMesec_10.Name := 'ploscicaAnalizeProdaje_izMesec_10';
    ploscicaAnalizeProdaje_izMesec_10.Left := 7;
    ploscicaAnalizeProdaje_izMesec_10.Top := 265;
    ploscicaAnalizeProdaje_izMesec_10.Width := 41;
    ploscicaAnalizeProdaje_izMesec_10.Height := 15;
    ploscicaAnalizeProdaje_izMesec_10.Anchors := [];
    ploscicaAnalizeProdaje_izMesec_10.Caption := 'oktober';
    ploscicaAnalizeProdaje_izMesec_10.Color := clNone;
    ploscicaAnalizeProdaje_izMesec_10.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izMesec_10.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izMesec_11.SetParentComponent(ploscicaAnalizeProdaje_Meseci);
    ploscicaAnalizeProdaje_izMesec_11.Name := 'ploscicaAnalizeProdaje_izMesec_11';
    ploscicaAnalizeProdaje_izMesec_11.Left := 7;
    ploscicaAnalizeProdaje_izMesec_11.Top := 291;
    ploscicaAnalizeProdaje_izMesec_11.Width := 54;
    ploscicaAnalizeProdaje_izMesec_11.Height := 15;
    ploscicaAnalizeProdaje_izMesec_11.Anchors := [];
    ploscicaAnalizeProdaje_izMesec_11.Caption := 'november';
    ploscicaAnalizeProdaje_izMesec_11.Color := clNone;
    ploscicaAnalizeProdaje_izMesec_11.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izMesec_11.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izMesec_12.SetParentComponent(ploscicaAnalizeProdaje_Meseci);
    ploscicaAnalizeProdaje_izMesec_12.Name := 'ploscicaAnalizeProdaje_izMesec_12';
    ploscicaAnalizeProdaje_izMesec_12.Left := 7;
    ploscicaAnalizeProdaje_izMesec_12.Top := 317;
    ploscicaAnalizeProdaje_izMesec_12.Width := 53;
    ploscicaAnalizeProdaje_izMesec_12.Height := 15;
    ploscicaAnalizeProdaje_izMesec_12.Anchors := [];
    ploscicaAnalizeProdaje_izMesec_12.Caption := 'december';
    ploscicaAnalizeProdaje_izMesec_12.Color := clNone;
    ploscicaAnalizeProdaje_izMesec_12.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izMesec_12.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_PrometX.SetParentComponent(LPT1_Tabela);
    ploscicaAnalizeProdaje_PrometX.Name := 'ploscicaAnalizeProdaje_PrometX';
    ploscicaAnalizeProdaje_PrometX.Left := 221;
    ploscicaAnalizeProdaje_PrometX.Top := 40;
    ploscicaAnalizeProdaje_PrometX.Width := 100;
    ploscicaAnalizeProdaje_PrometX.Height := 330;
    ploscicaAnalizeProdaje_PrometX.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_PrometX.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_PrometX.ChildOrder := 3;
    ploscicaAnalizeProdaje_PrometX.ColumnCollection.Clear;
    with ploscicaAnalizeProdaje_PrometX.ColumnCollection.Add do
    begin
      Alignment := taRightJustify;
      MarginRight := 5;
      Value := 50;
    end;
    ploscicaAnalizeProdaje_PrometX.ControlCollection.Clear;
    with ploscicaAnalizeProdaje_PrometX.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izPromet_NaslovX;
    end;
    with ploscicaAnalizeProdaje_PrometX.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izPromet_2_1;
    end;
    with ploscicaAnalizeProdaje_PrometX.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izPromet_2_2;
    end;
    with ploscicaAnalizeProdaje_PrometX.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izPromet_2_3;
    end;
    with ploscicaAnalizeProdaje_PrometX.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izPromet_2_4;
    end;
    with ploscicaAnalizeProdaje_PrometX.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izPromet_2_5;
    end;
    with ploscicaAnalizeProdaje_PrometX.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izPromet_2_6;
    end;
    with ploscicaAnalizeProdaje_PrometX.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izPromet_2_7;
    end;
    with ploscicaAnalizeProdaje_PrometX.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izPromet_2_8;
    end;
    with ploscicaAnalizeProdaje_PrometX.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izPromet_2_9;
    end;
    with ploscicaAnalizeProdaje_PrometX.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izPromet_2_10;
    end;
    with ploscicaAnalizeProdaje_PrometX.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izPromet_2_11;
    end;
    with ploscicaAnalizeProdaje_PrometX.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izPromet_2_12;
    end;
    ploscicaAnalizeProdaje_PrometX.Color := clWhite;
    ploscicaAnalizeProdaje_PrometX.GridLineWidth := 1;
    ploscicaAnalizeProdaje_PrometX.GridLineColor := clBlack;
    ploscicaAnalizeProdaje_PrometX.RowCollection.Clear;
    with ploscicaAnalizeProdaje_PrometX.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_PrometX.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_PrometX.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_PrometX.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_PrometX.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_PrometX.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_PrometX.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_PrometX.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_PrometX.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_PrometX.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_PrometX.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_PrometX.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_PrometX.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    ploscicaAnalizeProdaje_izPromet_NaslovX.SetParentComponent(ploscicaAnalizeProdaje_PrometX);
    ploscicaAnalizeProdaje_izPromet_NaslovX.Name := 'ploscicaAnalizeProdaje_izPromet_NaslovX';
    ploscicaAnalizeProdaje_izPromet_NaslovX.Left := 12;
    ploscicaAnalizeProdaje_izPromet_NaslovX.Top := 6;
    ploscicaAnalizeProdaje_izPromet_NaslovX.Width := 31;
    ploscicaAnalizeProdaje_izPromet_NaslovX.Height := 14;
    ploscicaAnalizeProdaje_izPromet_NaslovX.Anchors := [];
    ploscicaAnalizeProdaje_izPromet_NaslovX.Caption := 'leto X';
    ploscicaAnalizeProdaje_izPromet_NaslovX.Color := clNone;
    ploscicaAnalizeProdaje_izPromet_NaslovX.Font.Charset := DEFAULT_CHARSET;
    ploscicaAnalizeProdaje_izPromet_NaslovX.Font.Color := clWindowText;
    ploscicaAnalizeProdaje_izPromet_NaslovX.Font.Height := -11;
    ploscicaAnalizeProdaje_izPromet_NaslovX.Font.Name := 'Arial';
    ploscicaAnalizeProdaje_izPromet_NaslovX.Font.Style := [fsBold];
    ploscicaAnalizeProdaje_izPromet_NaslovX.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_NaslovX.ParentFont := False;
    ploscicaAnalizeProdaje_izPromet_NaslovX.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_2_1.SetParentComponent(ploscicaAnalizeProdaje_PrometX);
    ploscicaAnalizeProdaje_izPromet_2_1.Name := 'ploscicaAnalizeProdaje_izPromet_2_1';
    ploscicaAnalizeProdaje_izPromet_2_1.Left := 22;
    ploscicaAnalizeProdaje_izPromet_2_1.Top := 31;
    ploscicaAnalizeProdaje_izPromet_2_1.Width := 21;
    ploscicaAnalizeProdaje_izPromet_2_1.Height := 15;
    ploscicaAnalizeProdaje_izPromet_2_1.Anchors := [];
    ploscicaAnalizeProdaje_izPromet_2_1.Caption := '0.00';
    ploscicaAnalizeProdaje_izPromet_2_1.Color := clNone;
    ploscicaAnalizeProdaje_izPromet_2_1.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_2_1.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_2_2.SetParentComponent(ploscicaAnalizeProdaje_PrometX);
    ploscicaAnalizeProdaje_izPromet_2_2.Name := 'ploscicaAnalizeProdaje_izPromet_2_2';
    ploscicaAnalizeProdaje_izPromet_2_2.Left := 22;
    ploscicaAnalizeProdaje_izPromet_2_2.Top := 57;
    ploscicaAnalizeProdaje_izPromet_2_2.Width := 21;
    ploscicaAnalizeProdaje_izPromet_2_2.Height := 15;
    ploscicaAnalizeProdaje_izPromet_2_2.Anchors := [];
    ploscicaAnalizeProdaje_izPromet_2_2.Caption := '0.00';
    ploscicaAnalizeProdaje_izPromet_2_2.Color := clNone;
    ploscicaAnalizeProdaje_izPromet_2_2.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_2_2.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_2_3.SetParentComponent(ploscicaAnalizeProdaje_PrometX);
    ploscicaAnalizeProdaje_izPromet_2_3.Name := 'ploscicaAnalizeProdaje_izPromet_2_3';
    ploscicaAnalizeProdaje_izPromet_2_3.Left := 22;
    ploscicaAnalizeProdaje_izPromet_2_3.Top := 83;
    ploscicaAnalizeProdaje_izPromet_2_3.Width := 21;
    ploscicaAnalizeProdaje_izPromet_2_3.Height := 15;
    ploscicaAnalizeProdaje_izPromet_2_3.Anchors := [];
    ploscicaAnalizeProdaje_izPromet_2_3.Caption := '0.00';
    ploscicaAnalizeProdaje_izPromet_2_3.Color := clNone;
    ploscicaAnalizeProdaje_izPromet_2_3.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_2_3.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_2_4.SetParentComponent(ploscicaAnalizeProdaje_PrometX);
    ploscicaAnalizeProdaje_izPromet_2_4.Name := 'ploscicaAnalizeProdaje_izPromet_2_4';
    ploscicaAnalizeProdaje_izPromet_2_4.Left := 22;
    ploscicaAnalizeProdaje_izPromet_2_4.Top := 109;
    ploscicaAnalizeProdaje_izPromet_2_4.Width := 21;
    ploscicaAnalizeProdaje_izPromet_2_4.Height := 15;
    ploscicaAnalizeProdaje_izPromet_2_4.Anchors := [];
    ploscicaAnalizeProdaje_izPromet_2_4.Caption := '0.00';
    ploscicaAnalizeProdaje_izPromet_2_4.Color := clNone;
    ploscicaAnalizeProdaje_izPromet_2_4.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_2_4.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_2_5.SetParentComponent(ploscicaAnalizeProdaje_PrometX);
    ploscicaAnalizeProdaje_izPromet_2_5.Name := 'ploscicaAnalizeProdaje_izPromet_2_5';
    ploscicaAnalizeProdaje_izPromet_2_5.Left := 22;
    ploscicaAnalizeProdaje_izPromet_2_5.Top := 135;
    ploscicaAnalizeProdaje_izPromet_2_5.Width := 21;
    ploscicaAnalizeProdaje_izPromet_2_5.Height := 15;
    ploscicaAnalizeProdaje_izPromet_2_5.Anchors := [];
    ploscicaAnalizeProdaje_izPromet_2_5.Caption := '0.00';
    ploscicaAnalizeProdaje_izPromet_2_5.Color := clNone;
    ploscicaAnalizeProdaje_izPromet_2_5.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_2_5.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_2_6.SetParentComponent(ploscicaAnalizeProdaje_PrometX);
    ploscicaAnalizeProdaje_izPromet_2_6.Name := 'ploscicaAnalizeProdaje_izPromet_2_6';
    ploscicaAnalizeProdaje_izPromet_2_6.Left := 22;
    ploscicaAnalizeProdaje_izPromet_2_6.Top := 161;
    ploscicaAnalizeProdaje_izPromet_2_6.Width := 21;
    ploscicaAnalizeProdaje_izPromet_2_6.Height := 15;
    ploscicaAnalizeProdaje_izPromet_2_6.Anchors := [];
    ploscicaAnalizeProdaje_izPromet_2_6.Caption := '0.00';
    ploscicaAnalizeProdaje_izPromet_2_6.Color := clNone;
    ploscicaAnalizeProdaje_izPromet_2_6.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_2_6.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_2_7.SetParentComponent(ploscicaAnalizeProdaje_PrometX);
    ploscicaAnalizeProdaje_izPromet_2_7.Name := 'ploscicaAnalizeProdaje_izPromet_2_7';
    ploscicaAnalizeProdaje_izPromet_2_7.Left := 22;
    ploscicaAnalizeProdaje_izPromet_2_7.Top := 187;
    ploscicaAnalizeProdaje_izPromet_2_7.Width := 21;
    ploscicaAnalizeProdaje_izPromet_2_7.Height := 15;
    ploscicaAnalizeProdaje_izPromet_2_7.Anchors := [];
    ploscicaAnalizeProdaje_izPromet_2_7.Caption := '0.00';
    ploscicaAnalizeProdaje_izPromet_2_7.Color := clNone;
    ploscicaAnalizeProdaje_izPromet_2_7.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_2_7.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_2_8.SetParentComponent(ploscicaAnalizeProdaje_PrometX);
    ploscicaAnalizeProdaje_izPromet_2_8.Name := 'ploscicaAnalizeProdaje_izPromet_2_8';
    ploscicaAnalizeProdaje_izPromet_2_8.Left := 22;
    ploscicaAnalizeProdaje_izPromet_2_8.Top := 213;
    ploscicaAnalizeProdaje_izPromet_2_8.Width := 21;
    ploscicaAnalizeProdaje_izPromet_2_8.Height := 15;
    ploscicaAnalizeProdaje_izPromet_2_8.Anchors := [];
    ploscicaAnalizeProdaje_izPromet_2_8.Caption := '0.00';
    ploscicaAnalizeProdaje_izPromet_2_8.Color := clNone;
    ploscicaAnalizeProdaje_izPromet_2_8.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_2_8.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_2_9.SetParentComponent(ploscicaAnalizeProdaje_PrometX);
    ploscicaAnalizeProdaje_izPromet_2_9.Name := 'ploscicaAnalizeProdaje_izPromet_2_9';
    ploscicaAnalizeProdaje_izPromet_2_9.Left := 22;
    ploscicaAnalizeProdaje_izPromet_2_9.Top := 239;
    ploscicaAnalizeProdaje_izPromet_2_9.Width := 21;
    ploscicaAnalizeProdaje_izPromet_2_9.Height := 15;
    ploscicaAnalizeProdaje_izPromet_2_9.Anchors := [];
    ploscicaAnalizeProdaje_izPromet_2_9.Caption := '0.00';
    ploscicaAnalizeProdaje_izPromet_2_9.Color := clNone;
    ploscicaAnalizeProdaje_izPromet_2_9.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_2_9.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_2_10.SetParentComponent(ploscicaAnalizeProdaje_PrometX);
    ploscicaAnalizeProdaje_izPromet_2_10.Name := 'ploscicaAnalizeProdaje_izPromet_2_10';
    ploscicaAnalizeProdaje_izPromet_2_10.Left := 22;
    ploscicaAnalizeProdaje_izPromet_2_10.Top := 265;
    ploscicaAnalizeProdaje_izPromet_2_10.Width := 21;
    ploscicaAnalizeProdaje_izPromet_2_10.Height := 15;
    ploscicaAnalizeProdaje_izPromet_2_10.Anchors := [];
    ploscicaAnalizeProdaje_izPromet_2_10.Caption := '0.00';
    ploscicaAnalizeProdaje_izPromet_2_10.Color := clNone;
    ploscicaAnalizeProdaje_izPromet_2_10.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_2_10.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_2_11.SetParentComponent(ploscicaAnalizeProdaje_PrometX);
    ploscicaAnalizeProdaje_izPromet_2_11.Name := 'ploscicaAnalizeProdaje_izPromet_2_11';
    ploscicaAnalizeProdaje_izPromet_2_11.Left := 22;
    ploscicaAnalizeProdaje_izPromet_2_11.Top := 291;
    ploscicaAnalizeProdaje_izPromet_2_11.Width := 21;
    ploscicaAnalizeProdaje_izPromet_2_11.Height := 15;
    ploscicaAnalizeProdaje_izPromet_2_11.Anchors := [];
    ploscicaAnalizeProdaje_izPromet_2_11.Caption := '0.00';
    ploscicaAnalizeProdaje_izPromet_2_11.Color := clNone;
    ploscicaAnalizeProdaje_izPromet_2_11.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_2_11.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_2_12.SetParentComponent(ploscicaAnalizeProdaje_PrometX);
    ploscicaAnalizeProdaje_izPromet_2_12.Name := 'ploscicaAnalizeProdaje_izPromet_2_12';
    ploscicaAnalizeProdaje_izPromet_2_12.Left := 22;
    ploscicaAnalizeProdaje_izPromet_2_12.Top := 317;
    ploscicaAnalizeProdaje_izPromet_2_12.Width := 21;
    ploscicaAnalizeProdaje_izPromet_2_12.Height := 15;
    ploscicaAnalizeProdaje_izPromet_2_12.Anchors := [];
    ploscicaAnalizeProdaje_izPromet_2_12.Caption := '0.00';
    ploscicaAnalizeProdaje_izPromet_2_12.Color := clNone;
    ploscicaAnalizeProdaje_izPromet_2_12.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_2_12.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_PrometXminus1.SetParentComponent(LPT1_Tabela);
    ploscicaAnalizeProdaje_PrometXminus1.Name := 'ploscicaAnalizeProdaje_PrometXminus1';
    ploscicaAnalizeProdaje_PrometXminus1.Left := 122;
    ploscicaAnalizeProdaje_PrometXminus1.Top := 40;
    ploscicaAnalizeProdaje_PrometXminus1.Width := 100;
    ploscicaAnalizeProdaje_PrometXminus1.Height := 330;
    ploscicaAnalizeProdaje_PrometXminus1.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_PrometXminus1.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_PrometXminus1.ChildOrder := 2;
    ploscicaAnalizeProdaje_PrometXminus1.ColumnCollection.Clear;
    with ploscicaAnalizeProdaje_PrometXminus1.ColumnCollection.Add do
    begin
      Alignment := taRightJustify;
      MarginRight := 5;
      Value := 50;
    end;
    ploscicaAnalizeProdaje_PrometXminus1.ControlCollection.Clear;
    with ploscicaAnalizeProdaje_PrometXminus1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izPromet_NaslovXminus1;
    end;
    with ploscicaAnalizeProdaje_PrometXminus1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izPromet_1_1;
    end;
    with ploscicaAnalizeProdaje_PrometXminus1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izPromet_1_2;
    end;
    with ploscicaAnalizeProdaje_PrometXminus1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izPromet_1_3;
    end;
    with ploscicaAnalizeProdaje_PrometXminus1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izPromet_1_4;
    end;
    with ploscicaAnalizeProdaje_PrometXminus1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izPromet_1_5;
    end;
    with ploscicaAnalizeProdaje_PrometXminus1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izPromet_1_6;
    end;
    with ploscicaAnalizeProdaje_PrometXminus1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izPromet_1_7;
    end;
    with ploscicaAnalizeProdaje_PrometXminus1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izPromet_1_8;
    end;
    with ploscicaAnalizeProdaje_PrometXminus1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izPromet_1_9;
    end;
    with ploscicaAnalizeProdaje_PrometXminus1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izPromet_1_10;
    end;
    with ploscicaAnalizeProdaje_PrometXminus1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izPromet_1_11;
    end;
    with ploscicaAnalizeProdaje_PrometXminus1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izPromet_1_12;
    end;
    ploscicaAnalizeProdaje_PrometXminus1.Color := clWhite;
    ploscicaAnalizeProdaje_PrometXminus1.GridLineWidth := 1;
    ploscicaAnalizeProdaje_PrometXminus1.GridLineColor := clBlack;
    ploscicaAnalizeProdaje_PrometXminus1.RowCollection.Clear;
    with ploscicaAnalizeProdaje_PrometXminus1.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_PrometXminus1.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_PrometXminus1.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_PrometXminus1.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_PrometXminus1.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_PrometXminus1.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_PrometXminus1.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_PrometXminus1.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_PrometXminus1.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_PrometXminus1.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_PrometXminus1.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_PrometXminus1.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_PrometXminus1.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    ploscicaAnalizeProdaje_izPromet_NaslovXminus1.SetParentComponent(ploscicaAnalizeProdaje_PrometXminus1);
    ploscicaAnalizeProdaje_izPromet_NaslovXminus1.Name := 'ploscicaAnalizeProdaje_izPromet_NaslovXminus1';
    ploscicaAnalizeProdaje_izPromet_NaslovXminus1.Left := 2;
    ploscicaAnalizeProdaje_izPromet_NaslovXminus1.Top := 6;
    ploscicaAnalizeProdaje_izPromet_NaslovXminus1.Width := 41;
    ploscicaAnalizeProdaje_izPromet_NaslovXminus1.Height := 14;
    ploscicaAnalizeProdaje_izPromet_NaslovXminus1.Anchors := [];
    ploscicaAnalizeProdaje_izPromet_NaslovXminus1.Caption := 'leto X-1';
    ploscicaAnalizeProdaje_izPromet_NaslovXminus1.Color := clNone;
    ploscicaAnalizeProdaje_izPromet_NaslovXminus1.Font.Charset := DEFAULT_CHARSET;
    ploscicaAnalizeProdaje_izPromet_NaslovXminus1.Font.Color := clWindowText;
    ploscicaAnalizeProdaje_izPromet_NaslovXminus1.Font.Height := -11;
    ploscicaAnalizeProdaje_izPromet_NaslovXminus1.Font.Name := 'Arial';
    ploscicaAnalizeProdaje_izPromet_NaslovXminus1.Font.Style := [fsBold];
    ploscicaAnalizeProdaje_izPromet_NaslovXminus1.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_NaslovXminus1.ParentFont := False;
    ploscicaAnalizeProdaje_izPromet_NaslovXminus1.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_1_1.SetParentComponent(ploscicaAnalizeProdaje_PrometXminus1);
    ploscicaAnalizeProdaje_izPromet_1_1.Name := 'ploscicaAnalizeProdaje_izPromet_1_1';
    ploscicaAnalizeProdaje_izPromet_1_1.Left := 22;
    ploscicaAnalizeProdaje_izPromet_1_1.Top := 31;
    ploscicaAnalizeProdaje_izPromet_1_1.Width := 21;
    ploscicaAnalizeProdaje_izPromet_1_1.Height := 15;
    ploscicaAnalizeProdaje_izPromet_1_1.Anchors := [];
    ploscicaAnalizeProdaje_izPromet_1_1.Caption := '0.00';
    ploscicaAnalizeProdaje_izPromet_1_1.Color := clNone;
    ploscicaAnalizeProdaje_izPromet_1_1.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_1_1.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_1_2.SetParentComponent(ploscicaAnalizeProdaje_PrometXminus1);
    ploscicaAnalizeProdaje_izPromet_1_2.Name := 'ploscicaAnalizeProdaje_izPromet_1_2';
    ploscicaAnalizeProdaje_izPromet_1_2.Left := 22;
    ploscicaAnalizeProdaje_izPromet_1_2.Top := 57;
    ploscicaAnalizeProdaje_izPromet_1_2.Width := 21;
    ploscicaAnalizeProdaje_izPromet_1_2.Height := 15;
    ploscicaAnalizeProdaje_izPromet_1_2.Anchors := [];
    ploscicaAnalizeProdaje_izPromet_1_2.Caption := '0.00';
    ploscicaAnalizeProdaje_izPromet_1_2.Color := clNone;
    ploscicaAnalizeProdaje_izPromet_1_2.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_1_2.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_1_3.SetParentComponent(ploscicaAnalizeProdaje_PrometXminus1);
    ploscicaAnalizeProdaje_izPromet_1_3.Name := 'ploscicaAnalizeProdaje_izPromet_1_3';
    ploscicaAnalizeProdaje_izPromet_1_3.Left := 22;
    ploscicaAnalizeProdaje_izPromet_1_3.Top := 83;
    ploscicaAnalizeProdaje_izPromet_1_3.Width := 21;
    ploscicaAnalizeProdaje_izPromet_1_3.Height := 15;
    ploscicaAnalizeProdaje_izPromet_1_3.Anchors := [];
    ploscicaAnalizeProdaje_izPromet_1_3.Caption := '0.00';
    ploscicaAnalizeProdaje_izPromet_1_3.Color := clNone;
    ploscicaAnalizeProdaje_izPromet_1_3.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_1_3.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_1_4.SetParentComponent(ploscicaAnalizeProdaje_PrometXminus1);
    ploscicaAnalizeProdaje_izPromet_1_4.Name := 'ploscicaAnalizeProdaje_izPromet_1_4';
    ploscicaAnalizeProdaje_izPromet_1_4.Left := 22;
    ploscicaAnalizeProdaje_izPromet_1_4.Top := 109;
    ploscicaAnalizeProdaje_izPromet_1_4.Width := 21;
    ploscicaAnalizeProdaje_izPromet_1_4.Height := 15;
    ploscicaAnalizeProdaje_izPromet_1_4.Anchors := [];
    ploscicaAnalizeProdaje_izPromet_1_4.Caption := '0.00';
    ploscicaAnalizeProdaje_izPromet_1_4.Color := clNone;
    ploscicaAnalizeProdaje_izPromet_1_4.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_1_4.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_1_5.SetParentComponent(ploscicaAnalizeProdaje_PrometXminus1);
    ploscicaAnalizeProdaje_izPromet_1_5.Name := 'ploscicaAnalizeProdaje_izPromet_1_5';
    ploscicaAnalizeProdaje_izPromet_1_5.Left := 22;
    ploscicaAnalizeProdaje_izPromet_1_5.Top := 135;
    ploscicaAnalizeProdaje_izPromet_1_5.Width := 21;
    ploscicaAnalizeProdaje_izPromet_1_5.Height := 15;
    ploscicaAnalizeProdaje_izPromet_1_5.Anchors := [];
    ploscicaAnalizeProdaje_izPromet_1_5.Caption := '0.00';
    ploscicaAnalizeProdaje_izPromet_1_5.Color := clNone;
    ploscicaAnalizeProdaje_izPromet_1_5.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_1_5.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_1_6.SetParentComponent(ploscicaAnalizeProdaje_PrometXminus1);
    ploscicaAnalizeProdaje_izPromet_1_6.Name := 'ploscicaAnalizeProdaje_izPromet_1_6';
    ploscicaAnalizeProdaje_izPromet_1_6.Left := 22;
    ploscicaAnalizeProdaje_izPromet_1_6.Top := 161;
    ploscicaAnalizeProdaje_izPromet_1_6.Width := 21;
    ploscicaAnalizeProdaje_izPromet_1_6.Height := 15;
    ploscicaAnalizeProdaje_izPromet_1_6.Anchors := [];
    ploscicaAnalizeProdaje_izPromet_1_6.Caption := '0.00';
    ploscicaAnalizeProdaje_izPromet_1_6.Color := clNone;
    ploscicaAnalizeProdaje_izPromet_1_6.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_1_6.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_1_7.SetParentComponent(ploscicaAnalizeProdaje_PrometXminus1);
    ploscicaAnalizeProdaje_izPromet_1_7.Name := 'ploscicaAnalizeProdaje_izPromet_1_7';
    ploscicaAnalizeProdaje_izPromet_1_7.Left := 22;
    ploscicaAnalizeProdaje_izPromet_1_7.Top := 187;
    ploscicaAnalizeProdaje_izPromet_1_7.Width := 21;
    ploscicaAnalizeProdaje_izPromet_1_7.Height := 15;
    ploscicaAnalizeProdaje_izPromet_1_7.Anchors := [];
    ploscicaAnalizeProdaje_izPromet_1_7.Caption := '0.00';
    ploscicaAnalizeProdaje_izPromet_1_7.Color := clNone;
    ploscicaAnalizeProdaje_izPromet_1_7.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_1_7.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_1_8.SetParentComponent(ploscicaAnalizeProdaje_PrometXminus1);
    ploscicaAnalizeProdaje_izPromet_1_8.Name := 'ploscicaAnalizeProdaje_izPromet_1_8';
    ploscicaAnalizeProdaje_izPromet_1_8.Left := 22;
    ploscicaAnalizeProdaje_izPromet_1_8.Top := 213;
    ploscicaAnalizeProdaje_izPromet_1_8.Width := 21;
    ploscicaAnalizeProdaje_izPromet_1_8.Height := 15;
    ploscicaAnalizeProdaje_izPromet_1_8.Anchors := [];
    ploscicaAnalizeProdaje_izPromet_1_8.Caption := '0.00';
    ploscicaAnalizeProdaje_izPromet_1_8.Color := clNone;
    ploscicaAnalizeProdaje_izPromet_1_8.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_1_8.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_1_9.SetParentComponent(ploscicaAnalizeProdaje_PrometXminus1);
    ploscicaAnalizeProdaje_izPromet_1_9.Name := 'ploscicaAnalizeProdaje_izPromet_1_9';
    ploscicaAnalizeProdaje_izPromet_1_9.Left := 22;
    ploscicaAnalizeProdaje_izPromet_1_9.Top := 239;
    ploscicaAnalizeProdaje_izPromet_1_9.Width := 21;
    ploscicaAnalizeProdaje_izPromet_1_9.Height := 15;
    ploscicaAnalizeProdaje_izPromet_1_9.Anchors := [];
    ploscicaAnalizeProdaje_izPromet_1_9.Caption := '0.00';
    ploscicaAnalizeProdaje_izPromet_1_9.Color := clNone;
    ploscicaAnalizeProdaje_izPromet_1_9.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_1_9.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_1_10.SetParentComponent(ploscicaAnalizeProdaje_PrometXminus1);
    ploscicaAnalizeProdaje_izPromet_1_10.Name := 'ploscicaAnalizeProdaje_izPromet_1_10';
    ploscicaAnalizeProdaje_izPromet_1_10.Left := 22;
    ploscicaAnalizeProdaje_izPromet_1_10.Top := 265;
    ploscicaAnalizeProdaje_izPromet_1_10.Width := 21;
    ploscicaAnalizeProdaje_izPromet_1_10.Height := 15;
    ploscicaAnalizeProdaje_izPromet_1_10.Anchors := [];
    ploscicaAnalizeProdaje_izPromet_1_10.Caption := '0.00';
    ploscicaAnalizeProdaje_izPromet_1_10.Color := clNone;
    ploscicaAnalizeProdaje_izPromet_1_10.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_1_10.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_1_11.SetParentComponent(ploscicaAnalizeProdaje_PrometXminus1);
    ploscicaAnalizeProdaje_izPromet_1_11.Name := 'ploscicaAnalizeProdaje_izPromet_1_11';
    ploscicaAnalizeProdaje_izPromet_1_11.Left := 22;
    ploscicaAnalizeProdaje_izPromet_1_11.Top := 291;
    ploscicaAnalizeProdaje_izPromet_1_11.Width := 21;
    ploscicaAnalizeProdaje_izPromet_1_11.Height := 15;
    ploscicaAnalizeProdaje_izPromet_1_11.Anchors := [];
    ploscicaAnalizeProdaje_izPromet_1_11.Caption := '0.00';
    ploscicaAnalizeProdaje_izPromet_1_11.Color := clNone;
    ploscicaAnalizeProdaje_izPromet_1_11.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_1_11.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_1_12.SetParentComponent(ploscicaAnalizeProdaje_PrometXminus1);
    ploscicaAnalizeProdaje_izPromet_1_12.Name := 'ploscicaAnalizeProdaje_izPromet_1_12';
    ploscicaAnalizeProdaje_izPromet_1_12.Left := 22;
    ploscicaAnalizeProdaje_izPromet_1_12.Top := 317;
    ploscicaAnalizeProdaje_izPromet_1_12.Width := 21;
    ploscicaAnalizeProdaje_izPromet_1_12.Height := 15;
    ploscicaAnalizeProdaje_izPromet_1_12.Anchors := [];
    ploscicaAnalizeProdaje_izPromet_1_12.Caption := '0.00';
    ploscicaAnalizeProdaje_izPromet_1_12.Color := clNone;
    ploscicaAnalizeProdaje_izPromet_1_12.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_1_12.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_PrometXplus1.SetParentComponent(LPT1_Tabela);
    ploscicaAnalizeProdaje_PrometXplus1.Name := 'ploscicaAnalizeProdaje_PrometXplus1';
    ploscicaAnalizeProdaje_PrometXplus1.Left := 320;
    ploscicaAnalizeProdaje_PrometXplus1.Top := 40;
    ploscicaAnalizeProdaje_PrometXplus1.Width := 100;
    ploscicaAnalizeProdaje_PrometXplus1.Height := 330;
    ploscicaAnalizeProdaje_PrometXplus1.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_PrometXplus1.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_PrometXplus1.ChildOrder := 4;
    ploscicaAnalizeProdaje_PrometXplus1.ColumnCollection.Clear;
    with ploscicaAnalizeProdaje_PrometXplus1.ColumnCollection.Add do
    begin
      Alignment := taRightJustify;
      MarginRight := 5;
      Value := 50;
    end;
    ploscicaAnalizeProdaje_PrometXplus1.ControlCollection.Clear;
    with ploscicaAnalizeProdaje_PrometXplus1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izPromet_NaslovXplus1;
    end;
    with ploscicaAnalizeProdaje_PrometXplus1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izPromet_3_1;
    end;
    with ploscicaAnalizeProdaje_PrometXplus1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izPromet_3_2;
    end;
    with ploscicaAnalizeProdaje_PrometXplus1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izPromet_3_3;
    end;
    with ploscicaAnalizeProdaje_PrometXplus1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izPromet_3_4;
    end;
    with ploscicaAnalizeProdaje_PrometXplus1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izPromet_3_5;
    end;
    with ploscicaAnalizeProdaje_PrometXplus1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izPromet_3_6;
    end;
    with ploscicaAnalizeProdaje_PrometXplus1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izPromet_3_7;
    end;
    with ploscicaAnalizeProdaje_PrometXplus1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izPromet_3_8;
    end;
    with ploscicaAnalizeProdaje_PrometXplus1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izPromet_3_9;
    end;
    with ploscicaAnalizeProdaje_PrometXplus1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izPromet_3_10;
    end;
    with ploscicaAnalizeProdaje_PrometXplus1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izPromet_3_11;
    end;
    with ploscicaAnalizeProdaje_PrometXplus1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izPromet_3_12;
    end;
    ploscicaAnalizeProdaje_PrometXplus1.Color := clWhite;
    ploscicaAnalizeProdaje_PrometXplus1.GridLineWidth := 1;
    ploscicaAnalizeProdaje_PrometXplus1.GridLineColor := clBlack;
    ploscicaAnalizeProdaje_PrometXplus1.RowCollection.Clear;
    with ploscicaAnalizeProdaje_PrometXplus1.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_PrometXplus1.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_PrometXplus1.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_PrometXplus1.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_PrometXplus1.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_PrometXplus1.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_PrometXplus1.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_PrometXplus1.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_PrometXplus1.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_PrometXplus1.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_PrometXplus1.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_PrometXplus1.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_PrometXplus1.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    ploscicaAnalizeProdaje_izPromet_NaslovXplus1.SetParentComponent(ploscicaAnalizeProdaje_PrometXplus1);
    ploscicaAnalizeProdaje_izPromet_NaslovXplus1.Name := 'ploscicaAnalizeProdaje_izPromet_NaslovXplus1';
    ploscicaAnalizeProdaje_izPromet_NaslovXplus1.Left := 0;
    ploscicaAnalizeProdaje_izPromet_NaslovXplus1.Top := 6;
    ploscicaAnalizeProdaje_izPromet_NaslovXplus1.Width := 43;
    ploscicaAnalizeProdaje_izPromet_NaslovXplus1.Height := 14;
    ploscicaAnalizeProdaje_izPromet_NaslovXplus1.Anchors := [];
    ploscicaAnalizeProdaje_izPromet_NaslovXplus1.Caption := 'leto X+1';
    ploscicaAnalizeProdaje_izPromet_NaslovXplus1.Color := clNone;
    ploscicaAnalizeProdaje_izPromet_NaslovXplus1.Font.Charset := DEFAULT_CHARSET;
    ploscicaAnalizeProdaje_izPromet_NaslovXplus1.Font.Color := clWindowText;
    ploscicaAnalizeProdaje_izPromet_NaslovXplus1.Font.Height := -11;
    ploscicaAnalizeProdaje_izPromet_NaslovXplus1.Font.Name := 'Arial';
    ploscicaAnalizeProdaje_izPromet_NaslovXplus1.Font.Style := [fsBold];
    ploscicaAnalizeProdaje_izPromet_NaslovXplus1.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_NaslovXplus1.ParentFont := False;
    ploscicaAnalizeProdaje_izPromet_NaslovXplus1.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_3_1.SetParentComponent(ploscicaAnalizeProdaje_PrometXplus1);
    ploscicaAnalizeProdaje_izPromet_3_1.Name := 'ploscicaAnalizeProdaje_izPromet_3_1';
    ploscicaAnalizeProdaje_izPromet_3_1.Left := 22;
    ploscicaAnalizeProdaje_izPromet_3_1.Top := 31;
    ploscicaAnalizeProdaje_izPromet_3_1.Width := 21;
    ploscicaAnalizeProdaje_izPromet_3_1.Height := 15;
    ploscicaAnalizeProdaje_izPromet_3_1.Anchors := [];
    ploscicaAnalizeProdaje_izPromet_3_1.Caption := '0.00';
    ploscicaAnalizeProdaje_izPromet_3_1.Color := clNone;
    ploscicaAnalizeProdaje_izPromet_3_1.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_3_1.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_3_2.SetParentComponent(ploscicaAnalizeProdaje_PrometXplus1);
    ploscicaAnalizeProdaje_izPromet_3_2.Name := 'ploscicaAnalizeProdaje_izPromet_3_2';
    ploscicaAnalizeProdaje_izPromet_3_2.Left := 22;
    ploscicaAnalizeProdaje_izPromet_3_2.Top := 57;
    ploscicaAnalizeProdaje_izPromet_3_2.Width := 21;
    ploscicaAnalizeProdaje_izPromet_3_2.Height := 15;
    ploscicaAnalizeProdaje_izPromet_3_2.Anchors := [];
    ploscicaAnalizeProdaje_izPromet_3_2.Caption := '0.00';
    ploscicaAnalizeProdaje_izPromet_3_2.Color := clNone;
    ploscicaAnalizeProdaje_izPromet_3_2.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_3_2.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_3_3.SetParentComponent(ploscicaAnalizeProdaje_PrometXplus1);
    ploscicaAnalizeProdaje_izPromet_3_3.Name := 'ploscicaAnalizeProdaje_izPromet_3_3';
    ploscicaAnalizeProdaje_izPromet_3_3.Left := 22;
    ploscicaAnalizeProdaje_izPromet_3_3.Top := 83;
    ploscicaAnalizeProdaje_izPromet_3_3.Width := 21;
    ploscicaAnalizeProdaje_izPromet_3_3.Height := 15;
    ploscicaAnalizeProdaje_izPromet_3_3.Anchors := [];
    ploscicaAnalizeProdaje_izPromet_3_3.Caption := '0.00';
    ploscicaAnalizeProdaje_izPromet_3_3.Color := clNone;
    ploscicaAnalizeProdaje_izPromet_3_3.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_3_3.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_3_4.SetParentComponent(ploscicaAnalizeProdaje_PrometXplus1);
    ploscicaAnalizeProdaje_izPromet_3_4.Name := 'ploscicaAnalizeProdaje_izPromet_3_4';
    ploscicaAnalizeProdaje_izPromet_3_4.Left := 22;
    ploscicaAnalizeProdaje_izPromet_3_4.Top := 109;
    ploscicaAnalizeProdaje_izPromet_3_4.Width := 21;
    ploscicaAnalizeProdaje_izPromet_3_4.Height := 15;
    ploscicaAnalizeProdaje_izPromet_3_4.Anchors := [];
    ploscicaAnalizeProdaje_izPromet_3_4.Caption := '0.00';
    ploscicaAnalizeProdaje_izPromet_3_4.Color := clNone;
    ploscicaAnalizeProdaje_izPromet_3_4.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_3_4.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_3_5.SetParentComponent(ploscicaAnalizeProdaje_PrometXplus1);
    ploscicaAnalizeProdaje_izPromet_3_5.Name := 'ploscicaAnalizeProdaje_izPromet_3_5';
    ploscicaAnalizeProdaje_izPromet_3_5.Left := 22;
    ploscicaAnalizeProdaje_izPromet_3_5.Top := 135;
    ploscicaAnalizeProdaje_izPromet_3_5.Width := 21;
    ploscicaAnalizeProdaje_izPromet_3_5.Height := 15;
    ploscicaAnalizeProdaje_izPromet_3_5.Anchors := [];
    ploscicaAnalizeProdaje_izPromet_3_5.Caption := '0.00';
    ploscicaAnalizeProdaje_izPromet_3_5.Color := clNone;
    ploscicaAnalizeProdaje_izPromet_3_5.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_3_5.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_3_6.SetParentComponent(ploscicaAnalizeProdaje_PrometXplus1);
    ploscicaAnalizeProdaje_izPromet_3_6.Name := 'ploscicaAnalizeProdaje_izPromet_3_6';
    ploscicaAnalizeProdaje_izPromet_3_6.Left := 22;
    ploscicaAnalizeProdaje_izPromet_3_6.Top := 161;
    ploscicaAnalizeProdaje_izPromet_3_6.Width := 21;
    ploscicaAnalizeProdaje_izPromet_3_6.Height := 15;
    ploscicaAnalizeProdaje_izPromet_3_6.Anchors := [];
    ploscicaAnalizeProdaje_izPromet_3_6.Caption := '0.00';
    ploscicaAnalizeProdaje_izPromet_3_6.Color := clNone;
    ploscicaAnalizeProdaje_izPromet_3_6.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_3_6.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_3_7.SetParentComponent(ploscicaAnalizeProdaje_PrometXplus1);
    ploscicaAnalizeProdaje_izPromet_3_7.Name := 'ploscicaAnalizeProdaje_izPromet_3_7';
    ploscicaAnalizeProdaje_izPromet_3_7.Left := 22;
    ploscicaAnalizeProdaje_izPromet_3_7.Top := 187;
    ploscicaAnalizeProdaje_izPromet_3_7.Width := 21;
    ploscicaAnalizeProdaje_izPromet_3_7.Height := 15;
    ploscicaAnalizeProdaje_izPromet_3_7.Anchors := [];
    ploscicaAnalizeProdaje_izPromet_3_7.Caption := '0.00';
    ploscicaAnalizeProdaje_izPromet_3_7.Color := clNone;
    ploscicaAnalizeProdaje_izPromet_3_7.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_3_7.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_3_8.SetParentComponent(ploscicaAnalizeProdaje_PrometXplus1);
    ploscicaAnalizeProdaje_izPromet_3_8.Name := 'ploscicaAnalizeProdaje_izPromet_3_8';
    ploscicaAnalizeProdaje_izPromet_3_8.Left := 22;
    ploscicaAnalizeProdaje_izPromet_3_8.Top := 213;
    ploscicaAnalizeProdaje_izPromet_3_8.Width := 21;
    ploscicaAnalizeProdaje_izPromet_3_8.Height := 15;
    ploscicaAnalizeProdaje_izPromet_3_8.Anchors := [];
    ploscicaAnalizeProdaje_izPromet_3_8.Caption := '0.00';
    ploscicaAnalizeProdaje_izPromet_3_8.Color := clNone;
    ploscicaAnalizeProdaje_izPromet_3_8.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_3_8.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_3_9.SetParentComponent(ploscicaAnalizeProdaje_PrometXplus1);
    ploscicaAnalizeProdaje_izPromet_3_9.Name := 'ploscicaAnalizeProdaje_izPromet_3_9';
    ploscicaAnalizeProdaje_izPromet_3_9.Left := 22;
    ploscicaAnalizeProdaje_izPromet_3_9.Top := 239;
    ploscicaAnalizeProdaje_izPromet_3_9.Width := 21;
    ploscicaAnalizeProdaje_izPromet_3_9.Height := 15;
    ploscicaAnalizeProdaje_izPromet_3_9.Anchors := [];
    ploscicaAnalizeProdaje_izPromet_3_9.Caption := '0.00';
    ploscicaAnalizeProdaje_izPromet_3_9.Color := clNone;
    ploscicaAnalizeProdaje_izPromet_3_9.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_3_9.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_3_10.SetParentComponent(ploscicaAnalizeProdaje_PrometXplus1);
    ploscicaAnalizeProdaje_izPromet_3_10.Name := 'ploscicaAnalizeProdaje_izPromet_3_10';
    ploscicaAnalizeProdaje_izPromet_3_10.Left := 22;
    ploscicaAnalizeProdaje_izPromet_3_10.Top := 265;
    ploscicaAnalizeProdaje_izPromet_3_10.Width := 21;
    ploscicaAnalizeProdaje_izPromet_3_10.Height := 15;
    ploscicaAnalizeProdaje_izPromet_3_10.Anchors := [];
    ploscicaAnalizeProdaje_izPromet_3_10.Caption := '0.00';
    ploscicaAnalizeProdaje_izPromet_3_10.Color := clNone;
    ploscicaAnalizeProdaje_izPromet_3_10.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_3_10.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_3_11.SetParentComponent(ploscicaAnalizeProdaje_PrometXplus1);
    ploscicaAnalizeProdaje_izPromet_3_11.Name := 'ploscicaAnalizeProdaje_izPromet_3_11';
    ploscicaAnalizeProdaje_izPromet_3_11.Left := 22;
    ploscicaAnalizeProdaje_izPromet_3_11.Top := 291;
    ploscicaAnalizeProdaje_izPromet_3_11.Width := 21;
    ploscicaAnalizeProdaje_izPromet_3_11.Height := 15;
    ploscicaAnalizeProdaje_izPromet_3_11.Anchors := [];
    ploscicaAnalizeProdaje_izPromet_3_11.Caption := '0.00';
    ploscicaAnalizeProdaje_izPromet_3_11.Color := clNone;
    ploscicaAnalizeProdaje_izPromet_3_11.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_3_11.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_3_12.SetParentComponent(ploscicaAnalizeProdaje_PrometXplus1);
    ploscicaAnalizeProdaje_izPromet_3_12.Name := 'ploscicaAnalizeProdaje_izPromet_3_12';
    ploscicaAnalizeProdaje_izPromet_3_12.Left := 22;
    ploscicaAnalizeProdaje_izPromet_3_12.Top := 317;
    ploscicaAnalizeProdaje_izPromet_3_12.Width := 21;
    ploscicaAnalizeProdaje_izPromet_3_12.Height := 15;
    ploscicaAnalizeProdaje_izPromet_3_12.Anchors := [];
    ploscicaAnalizeProdaje_izPromet_3_12.Caption := '0.00';
    ploscicaAnalizeProdaje_izPromet_3_12.Color := clNone;
    ploscicaAnalizeProdaje_izPromet_3_12.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_3_12.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_PrometXplus2.SetParentComponent(LPT1_Tabela);
    ploscicaAnalizeProdaje_PrometXplus2.Name := 'ploscicaAnalizeProdaje_PrometXplus2';
    ploscicaAnalizeProdaje_PrometXplus2.Left := 419;
    ploscicaAnalizeProdaje_PrometXplus2.Top := 40;
    ploscicaAnalizeProdaje_PrometXplus2.Width := 100;
    ploscicaAnalizeProdaje_PrometXplus2.Height := 330;
    ploscicaAnalizeProdaje_PrometXplus2.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_PrometXplus2.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_PrometXplus2.ChildOrder := 5;
    ploscicaAnalizeProdaje_PrometXplus2.ColumnCollection.Clear;
    with ploscicaAnalizeProdaje_PrometXplus2.ColumnCollection.Add do
    begin
      Alignment := taRightJustify;
      MarginRight := 5;
      Value := 50;
    end;
    ploscicaAnalizeProdaje_PrometXplus2.ControlCollection.Clear;
    with ploscicaAnalizeProdaje_PrometXplus2.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izPromet_NaslovXplus2;
    end;
    with ploscicaAnalizeProdaje_PrometXplus2.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izPromet_4_1;
    end;
    with ploscicaAnalizeProdaje_PrometXplus2.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izPromet_4_2;
    end;
    with ploscicaAnalizeProdaje_PrometXplus2.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izPromet_4_3;
    end;
    with ploscicaAnalizeProdaje_PrometXplus2.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izPromet_4_4;
    end;
    with ploscicaAnalizeProdaje_PrometXplus2.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izPromet_4_5;
    end;
    with ploscicaAnalizeProdaje_PrometXplus2.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izPromet_4_6;
    end;
    with ploscicaAnalizeProdaje_PrometXplus2.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izPromet_4_7;
    end;
    with ploscicaAnalizeProdaje_PrometXplus2.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izPromet_4_8;
    end;
    with ploscicaAnalizeProdaje_PrometXplus2.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izPromet_4_9;
    end;
    with ploscicaAnalizeProdaje_PrometXplus2.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izPromet_4_10;
    end;
    with ploscicaAnalizeProdaje_PrometXplus2.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izPromet_4_11;
    end;
    with ploscicaAnalizeProdaje_PrometXplus2.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izPromet_4_12;
    end;
    ploscicaAnalizeProdaje_PrometXplus2.Color := clWhite;
    ploscicaAnalizeProdaje_PrometXplus2.GridLineWidth := 1;
    ploscicaAnalizeProdaje_PrometXplus2.GridLineColor := clBlack;
    ploscicaAnalizeProdaje_PrometXplus2.RowCollection.Clear;
    with ploscicaAnalizeProdaje_PrometXplus2.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_PrometXplus2.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_PrometXplus2.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_PrometXplus2.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_PrometXplus2.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_PrometXplus2.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_PrometXplus2.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_PrometXplus2.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_PrometXplus2.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_PrometXplus2.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_PrometXplus2.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_PrometXplus2.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_PrometXplus2.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    ploscicaAnalizeProdaje_izPromet_NaslovXplus2.SetParentComponent(ploscicaAnalizeProdaje_PrometXplus2);
    ploscicaAnalizeProdaje_izPromet_NaslovXplus2.Name := 'ploscicaAnalizeProdaje_izPromet_NaslovXplus2';
    ploscicaAnalizeProdaje_izPromet_NaslovXplus2.Left := 0;
    ploscicaAnalizeProdaje_izPromet_NaslovXplus2.Top := 6;
    ploscicaAnalizeProdaje_izPromet_NaslovXplus2.Width := 43;
    ploscicaAnalizeProdaje_izPromet_NaslovXplus2.Height := 14;
    ploscicaAnalizeProdaje_izPromet_NaslovXplus2.Anchors := [];
    ploscicaAnalizeProdaje_izPromet_NaslovXplus2.Caption := 'leto X+2';
    ploscicaAnalizeProdaje_izPromet_NaslovXplus2.Color := clNone;
    ploscicaAnalizeProdaje_izPromet_NaslovXplus2.Font.Charset := DEFAULT_CHARSET;
    ploscicaAnalizeProdaje_izPromet_NaslovXplus2.Font.Color := clWindowText;
    ploscicaAnalizeProdaje_izPromet_NaslovXplus2.Font.Height := -11;
    ploscicaAnalizeProdaje_izPromet_NaslovXplus2.Font.Name := 'Arial';
    ploscicaAnalizeProdaje_izPromet_NaslovXplus2.Font.Style := [fsBold];
    ploscicaAnalizeProdaje_izPromet_NaslovXplus2.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_NaslovXplus2.ParentFont := False;
    ploscicaAnalizeProdaje_izPromet_NaslovXplus2.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_4_1.SetParentComponent(ploscicaAnalizeProdaje_PrometXplus2);
    ploscicaAnalizeProdaje_izPromet_4_1.Name := 'ploscicaAnalizeProdaje_izPromet_4_1';
    ploscicaAnalizeProdaje_izPromet_4_1.Left := 22;
    ploscicaAnalizeProdaje_izPromet_4_1.Top := 31;
    ploscicaAnalizeProdaje_izPromet_4_1.Width := 21;
    ploscicaAnalizeProdaje_izPromet_4_1.Height := 15;
    ploscicaAnalizeProdaje_izPromet_4_1.Anchors := [];
    ploscicaAnalizeProdaje_izPromet_4_1.Caption := '0.00';
    ploscicaAnalizeProdaje_izPromet_4_1.Color := clNone;
    ploscicaAnalizeProdaje_izPromet_4_1.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_4_1.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_4_2.SetParentComponent(ploscicaAnalizeProdaje_PrometXplus2);
    ploscicaAnalizeProdaje_izPromet_4_2.Name := 'ploscicaAnalizeProdaje_izPromet_4_2';
    ploscicaAnalizeProdaje_izPromet_4_2.Left := 22;
    ploscicaAnalizeProdaje_izPromet_4_2.Top := 57;
    ploscicaAnalizeProdaje_izPromet_4_2.Width := 21;
    ploscicaAnalizeProdaje_izPromet_4_2.Height := 15;
    ploscicaAnalizeProdaje_izPromet_4_2.Anchors := [];
    ploscicaAnalizeProdaje_izPromet_4_2.Caption := '0.00';
    ploscicaAnalizeProdaje_izPromet_4_2.Color := clNone;
    ploscicaAnalizeProdaje_izPromet_4_2.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_4_2.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_4_3.SetParentComponent(ploscicaAnalizeProdaje_PrometXplus2);
    ploscicaAnalizeProdaje_izPromet_4_3.Name := 'ploscicaAnalizeProdaje_izPromet_4_3';
    ploscicaAnalizeProdaje_izPromet_4_3.Left := 22;
    ploscicaAnalizeProdaje_izPromet_4_3.Top := 83;
    ploscicaAnalizeProdaje_izPromet_4_3.Width := 21;
    ploscicaAnalizeProdaje_izPromet_4_3.Height := 15;
    ploscicaAnalizeProdaje_izPromet_4_3.Anchors := [];
    ploscicaAnalizeProdaje_izPromet_4_3.Caption := '0.00';
    ploscicaAnalizeProdaje_izPromet_4_3.Color := clNone;
    ploscicaAnalizeProdaje_izPromet_4_3.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_4_3.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_4_4.SetParentComponent(ploscicaAnalizeProdaje_PrometXplus2);
    ploscicaAnalizeProdaje_izPromet_4_4.Name := 'ploscicaAnalizeProdaje_izPromet_4_4';
    ploscicaAnalizeProdaje_izPromet_4_4.Left := 22;
    ploscicaAnalizeProdaje_izPromet_4_4.Top := 109;
    ploscicaAnalizeProdaje_izPromet_4_4.Width := 21;
    ploscicaAnalizeProdaje_izPromet_4_4.Height := 15;
    ploscicaAnalizeProdaje_izPromet_4_4.Anchors := [];
    ploscicaAnalizeProdaje_izPromet_4_4.Caption := '0.00';
    ploscicaAnalizeProdaje_izPromet_4_4.Color := clNone;
    ploscicaAnalizeProdaje_izPromet_4_4.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_4_4.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_4_5.SetParentComponent(ploscicaAnalizeProdaje_PrometXplus2);
    ploscicaAnalizeProdaje_izPromet_4_5.Name := 'ploscicaAnalizeProdaje_izPromet_4_5';
    ploscicaAnalizeProdaje_izPromet_4_5.Left := 22;
    ploscicaAnalizeProdaje_izPromet_4_5.Top := 135;
    ploscicaAnalizeProdaje_izPromet_4_5.Width := 21;
    ploscicaAnalizeProdaje_izPromet_4_5.Height := 15;
    ploscicaAnalizeProdaje_izPromet_4_5.Anchors := [];
    ploscicaAnalizeProdaje_izPromet_4_5.Caption := '0.00';
    ploscicaAnalizeProdaje_izPromet_4_5.Color := clNone;
    ploscicaAnalizeProdaje_izPromet_4_5.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_4_5.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_4_6.SetParentComponent(ploscicaAnalizeProdaje_PrometXplus2);
    ploscicaAnalizeProdaje_izPromet_4_6.Name := 'ploscicaAnalizeProdaje_izPromet_4_6';
    ploscicaAnalizeProdaje_izPromet_4_6.Left := 22;
    ploscicaAnalizeProdaje_izPromet_4_6.Top := 161;
    ploscicaAnalizeProdaje_izPromet_4_6.Width := 21;
    ploscicaAnalizeProdaje_izPromet_4_6.Height := 15;
    ploscicaAnalizeProdaje_izPromet_4_6.Anchors := [];
    ploscicaAnalizeProdaje_izPromet_4_6.Caption := '0.00';
    ploscicaAnalizeProdaje_izPromet_4_6.Color := clNone;
    ploscicaAnalizeProdaje_izPromet_4_6.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_4_6.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_4_7.SetParentComponent(ploscicaAnalizeProdaje_PrometXplus2);
    ploscicaAnalizeProdaje_izPromet_4_7.Name := 'ploscicaAnalizeProdaje_izPromet_4_7';
    ploscicaAnalizeProdaje_izPromet_4_7.Left := 22;
    ploscicaAnalizeProdaje_izPromet_4_7.Top := 187;
    ploscicaAnalizeProdaje_izPromet_4_7.Width := 21;
    ploscicaAnalizeProdaje_izPromet_4_7.Height := 15;
    ploscicaAnalizeProdaje_izPromet_4_7.Anchors := [];
    ploscicaAnalizeProdaje_izPromet_4_7.Caption := '0.00';
    ploscicaAnalizeProdaje_izPromet_4_7.Color := clNone;
    ploscicaAnalizeProdaje_izPromet_4_7.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_4_7.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_4_8.SetParentComponent(ploscicaAnalizeProdaje_PrometXplus2);
    ploscicaAnalizeProdaje_izPromet_4_8.Name := 'ploscicaAnalizeProdaje_izPromet_4_8';
    ploscicaAnalizeProdaje_izPromet_4_8.Left := 22;
    ploscicaAnalizeProdaje_izPromet_4_8.Top := 213;
    ploscicaAnalizeProdaje_izPromet_4_8.Width := 21;
    ploscicaAnalizeProdaje_izPromet_4_8.Height := 15;
    ploscicaAnalizeProdaje_izPromet_4_8.Anchors := [];
    ploscicaAnalizeProdaje_izPromet_4_8.Caption := '0.00';
    ploscicaAnalizeProdaje_izPromet_4_8.Color := clNone;
    ploscicaAnalizeProdaje_izPromet_4_8.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_4_8.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_4_9.SetParentComponent(ploscicaAnalizeProdaje_PrometXplus2);
    ploscicaAnalizeProdaje_izPromet_4_9.Name := 'ploscicaAnalizeProdaje_izPromet_4_9';
    ploscicaAnalizeProdaje_izPromet_4_9.Left := 22;
    ploscicaAnalizeProdaje_izPromet_4_9.Top := 239;
    ploscicaAnalizeProdaje_izPromet_4_9.Width := 21;
    ploscicaAnalizeProdaje_izPromet_4_9.Height := 15;
    ploscicaAnalizeProdaje_izPromet_4_9.Anchors := [];
    ploscicaAnalizeProdaje_izPromet_4_9.Caption := '0.00';
    ploscicaAnalizeProdaje_izPromet_4_9.Color := clNone;
    ploscicaAnalizeProdaje_izPromet_4_9.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_4_9.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_4_10.SetParentComponent(ploscicaAnalizeProdaje_PrometXplus2);
    ploscicaAnalizeProdaje_izPromet_4_10.Name := 'ploscicaAnalizeProdaje_izPromet_4_10';
    ploscicaAnalizeProdaje_izPromet_4_10.Left := 22;
    ploscicaAnalizeProdaje_izPromet_4_10.Top := 265;
    ploscicaAnalizeProdaje_izPromet_4_10.Width := 21;
    ploscicaAnalizeProdaje_izPromet_4_10.Height := 15;
    ploscicaAnalizeProdaje_izPromet_4_10.Anchors := [];
    ploscicaAnalizeProdaje_izPromet_4_10.Caption := '0.00';
    ploscicaAnalizeProdaje_izPromet_4_10.Color := clNone;
    ploscicaAnalizeProdaje_izPromet_4_10.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_4_10.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_4_11.SetParentComponent(ploscicaAnalizeProdaje_PrometXplus2);
    ploscicaAnalizeProdaje_izPromet_4_11.Name := 'ploscicaAnalizeProdaje_izPromet_4_11';
    ploscicaAnalizeProdaje_izPromet_4_11.Left := 22;
    ploscicaAnalizeProdaje_izPromet_4_11.Top := 291;
    ploscicaAnalizeProdaje_izPromet_4_11.Width := 21;
    ploscicaAnalizeProdaje_izPromet_4_11.Height := 15;
    ploscicaAnalizeProdaje_izPromet_4_11.Anchors := [];
    ploscicaAnalizeProdaje_izPromet_4_11.Caption := '0.00';
    ploscicaAnalizeProdaje_izPromet_4_11.Color := clNone;
    ploscicaAnalizeProdaje_izPromet_4_11.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_4_11.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_4_12.SetParentComponent(ploscicaAnalizeProdaje_PrometXplus2);
    ploscicaAnalizeProdaje_izPromet_4_12.Name := 'ploscicaAnalizeProdaje_izPromet_4_12';
    ploscicaAnalizeProdaje_izPromet_4_12.Left := 22;
    ploscicaAnalizeProdaje_izPromet_4_12.Top := 317;
    ploscicaAnalizeProdaje_izPromet_4_12.Width := 21;
    ploscicaAnalizeProdaje_izPromet_4_12.Height := 15;
    ploscicaAnalizeProdaje_izPromet_4_12.Anchors := [];
    ploscicaAnalizeProdaje_izPromet_4_12.Caption := '0.00';
    ploscicaAnalizeProdaje_izPromet_4_12.Color := clNone;
    ploscicaAnalizeProdaje_izPromet_4_12.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_4_12.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_PrometXplus3.SetParentComponent(LPT1_Tabela);
    ploscicaAnalizeProdaje_PrometXplus3.Name := 'ploscicaAnalizeProdaje_PrometXplus3';
    ploscicaAnalizeProdaje_PrometXplus3.Left := 518;
    ploscicaAnalizeProdaje_PrometXplus3.Top := 40;
    ploscicaAnalizeProdaje_PrometXplus3.Width := 100;
    ploscicaAnalizeProdaje_PrometXplus3.Height := 330;
    ploscicaAnalizeProdaje_PrometXplus3.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_PrometXplus3.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_PrometXplus3.ChildOrder := 6;
    ploscicaAnalizeProdaje_PrometXplus3.ColumnCollection.Clear;
    with ploscicaAnalizeProdaje_PrometXplus3.ColumnCollection.Add do
    begin
      Alignment := taRightJustify;
      MarginRight := 5;
      Value := 50;
    end;
    ploscicaAnalizeProdaje_PrometXplus3.ControlCollection.Clear;
    with ploscicaAnalizeProdaje_PrometXplus3.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izPromet_NaslovXplus3;
    end;
    with ploscicaAnalizeProdaje_PrometXplus3.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izPromet_5_1;
    end;
    with ploscicaAnalizeProdaje_PrometXplus3.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izPromet_5_2;
    end;
    with ploscicaAnalizeProdaje_PrometXplus3.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izPromet_5_3;
    end;
    with ploscicaAnalizeProdaje_PrometXplus3.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izPromet_5_4;
    end;
    with ploscicaAnalizeProdaje_PrometXplus3.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izPromet_5_5;
    end;
    with ploscicaAnalizeProdaje_PrometXplus3.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izPromet_5_6;
    end;
    with ploscicaAnalizeProdaje_PrometXplus3.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izPromet_5_7;
    end;
    with ploscicaAnalizeProdaje_PrometXplus3.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izPromet_5_8;
    end;
    with ploscicaAnalizeProdaje_PrometXplus3.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izPromet_5_9;
    end;
    with ploscicaAnalizeProdaje_PrometXplus3.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izPromet_5_10;
    end;
    with ploscicaAnalizeProdaje_PrometXplus3.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izPromet_5_11;
    end;
    with ploscicaAnalizeProdaje_PrometXplus3.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izPromet_5_12;
    end;
    ploscicaAnalizeProdaje_PrometXplus3.Color := clWhite;
    ploscicaAnalizeProdaje_PrometXplus3.GridLineWidth := 1;
    ploscicaAnalizeProdaje_PrometXplus3.GridLineColor := clBlack;
    ploscicaAnalizeProdaje_PrometXplus3.RowCollection.Clear;
    with ploscicaAnalizeProdaje_PrometXplus3.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_PrometXplus3.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_PrometXplus3.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_PrometXplus3.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_PrometXplus3.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_PrometXplus3.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_PrometXplus3.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_PrometXplus3.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_PrometXplus3.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_PrometXplus3.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_PrometXplus3.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_PrometXplus3.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_PrometXplus3.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    ploscicaAnalizeProdaje_izPromet_NaslovXplus3.SetParentComponent(ploscicaAnalizeProdaje_PrometXplus3);
    ploscicaAnalizeProdaje_izPromet_NaslovXplus3.Name := 'ploscicaAnalizeProdaje_izPromet_NaslovXplus3';
    ploscicaAnalizeProdaje_izPromet_NaslovXplus3.Left := 0;
    ploscicaAnalizeProdaje_izPromet_NaslovXplus3.Top := 6;
    ploscicaAnalizeProdaje_izPromet_NaslovXplus3.Width := 43;
    ploscicaAnalizeProdaje_izPromet_NaslovXplus3.Height := 14;
    ploscicaAnalizeProdaje_izPromet_NaslovXplus3.Anchors := [];
    ploscicaAnalizeProdaje_izPromet_NaslovXplus3.Caption := 'leto X+2';
    ploscicaAnalizeProdaje_izPromet_NaslovXplus3.Color := clNone;
    ploscicaAnalizeProdaje_izPromet_NaslovXplus3.Font.Charset := DEFAULT_CHARSET;
    ploscicaAnalizeProdaje_izPromet_NaslovXplus3.Font.Color := clWindowText;
    ploscicaAnalizeProdaje_izPromet_NaslovXplus3.Font.Height := -11;
    ploscicaAnalizeProdaje_izPromet_NaslovXplus3.Font.Name := 'Arial';
    ploscicaAnalizeProdaje_izPromet_NaslovXplus3.Font.Style := [fsBold];
    ploscicaAnalizeProdaje_izPromet_NaslovXplus3.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_NaslovXplus3.ParentFont := False;
    ploscicaAnalizeProdaje_izPromet_NaslovXplus3.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_5_1.SetParentComponent(ploscicaAnalizeProdaje_PrometXplus3);
    ploscicaAnalizeProdaje_izPromet_5_1.Name := 'ploscicaAnalizeProdaje_izPromet_5_1';
    ploscicaAnalizeProdaje_izPromet_5_1.Left := 22;
    ploscicaAnalizeProdaje_izPromet_5_1.Top := 31;
    ploscicaAnalizeProdaje_izPromet_5_1.Width := 21;
    ploscicaAnalizeProdaje_izPromet_5_1.Height := 15;
    ploscicaAnalizeProdaje_izPromet_5_1.Anchors := [];
    ploscicaAnalizeProdaje_izPromet_5_1.Caption := '0.00';
    ploscicaAnalizeProdaje_izPromet_5_1.Color := clNone;
    ploscicaAnalizeProdaje_izPromet_5_1.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_5_1.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_5_2.SetParentComponent(ploscicaAnalizeProdaje_PrometXplus3);
    ploscicaAnalizeProdaje_izPromet_5_2.Name := 'ploscicaAnalizeProdaje_izPromet_5_2';
    ploscicaAnalizeProdaje_izPromet_5_2.Left := 22;
    ploscicaAnalizeProdaje_izPromet_5_2.Top := 57;
    ploscicaAnalizeProdaje_izPromet_5_2.Width := 21;
    ploscicaAnalizeProdaje_izPromet_5_2.Height := 15;
    ploscicaAnalizeProdaje_izPromet_5_2.Anchors := [];
    ploscicaAnalizeProdaje_izPromet_5_2.Caption := '0.00';
    ploscicaAnalizeProdaje_izPromet_5_2.Color := clNone;
    ploscicaAnalizeProdaje_izPromet_5_2.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_5_2.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_5_3.SetParentComponent(ploscicaAnalizeProdaje_PrometXplus3);
    ploscicaAnalizeProdaje_izPromet_5_3.Name := 'ploscicaAnalizeProdaje_izPromet_5_3';
    ploscicaAnalizeProdaje_izPromet_5_3.Left := 22;
    ploscicaAnalizeProdaje_izPromet_5_3.Top := 83;
    ploscicaAnalizeProdaje_izPromet_5_3.Width := 21;
    ploscicaAnalizeProdaje_izPromet_5_3.Height := 15;
    ploscicaAnalizeProdaje_izPromet_5_3.Anchors := [];
    ploscicaAnalizeProdaje_izPromet_5_3.Caption := '0.00';
    ploscicaAnalizeProdaje_izPromet_5_3.Color := clNone;
    ploscicaAnalizeProdaje_izPromet_5_3.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_5_3.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_5_4.SetParentComponent(ploscicaAnalizeProdaje_PrometXplus3);
    ploscicaAnalizeProdaje_izPromet_5_4.Name := 'ploscicaAnalizeProdaje_izPromet_5_4';
    ploscicaAnalizeProdaje_izPromet_5_4.Left := 22;
    ploscicaAnalizeProdaje_izPromet_5_4.Top := 109;
    ploscicaAnalizeProdaje_izPromet_5_4.Width := 21;
    ploscicaAnalizeProdaje_izPromet_5_4.Height := 15;
    ploscicaAnalizeProdaje_izPromet_5_4.Anchors := [];
    ploscicaAnalizeProdaje_izPromet_5_4.Caption := '0.00';
    ploscicaAnalizeProdaje_izPromet_5_4.Color := clNone;
    ploscicaAnalizeProdaje_izPromet_5_4.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_5_4.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_5_5.SetParentComponent(ploscicaAnalizeProdaje_PrometXplus3);
    ploscicaAnalizeProdaje_izPromet_5_5.Name := 'ploscicaAnalizeProdaje_izPromet_5_5';
    ploscicaAnalizeProdaje_izPromet_5_5.Left := 22;
    ploscicaAnalizeProdaje_izPromet_5_5.Top := 135;
    ploscicaAnalizeProdaje_izPromet_5_5.Width := 21;
    ploscicaAnalizeProdaje_izPromet_5_5.Height := 15;
    ploscicaAnalizeProdaje_izPromet_5_5.Anchors := [];
    ploscicaAnalizeProdaje_izPromet_5_5.Caption := '0.00';
    ploscicaAnalizeProdaje_izPromet_5_5.Color := clNone;
    ploscicaAnalizeProdaje_izPromet_5_5.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_5_5.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_5_6.SetParentComponent(ploscicaAnalizeProdaje_PrometXplus3);
    ploscicaAnalizeProdaje_izPromet_5_6.Name := 'ploscicaAnalizeProdaje_izPromet_5_6';
    ploscicaAnalizeProdaje_izPromet_5_6.Left := 22;
    ploscicaAnalizeProdaje_izPromet_5_6.Top := 161;
    ploscicaAnalizeProdaje_izPromet_5_6.Width := 21;
    ploscicaAnalizeProdaje_izPromet_5_6.Height := 15;
    ploscicaAnalizeProdaje_izPromet_5_6.Anchors := [];
    ploscicaAnalizeProdaje_izPromet_5_6.Caption := '0.00';
    ploscicaAnalizeProdaje_izPromet_5_6.Color := clNone;
    ploscicaAnalizeProdaje_izPromet_5_6.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_5_6.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_5_7.SetParentComponent(ploscicaAnalizeProdaje_PrometXplus3);
    ploscicaAnalizeProdaje_izPromet_5_7.Name := 'ploscicaAnalizeProdaje_izPromet_5_7';
    ploscicaAnalizeProdaje_izPromet_5_7.Left := 22;
    ploscicaAnalizeProdaje_izPromet_5_7.Top := 187;
    ploscicaAnalizeProdaje_izPromet_5_7.Width := 21;
    ploscicaAnalizeProdaje_izPromet_5_7.Height := 15;
    ploscicaAnalizeProdaje_izPromet_5_7.Anchors := [];
    ploscicaAnalizeProdaje_izPromet_5_7.Caption := '0.00';
    ploscicaAnalizeProdaje_izPromet_5_7.Color := clNone;
    ploscicaAnalizeProdaje_izPromet_5_7.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_5_7.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_5_8.SetParentComponent(ploscicaAnalizeProdaje_PrometXplus3);
    ploscicaAnalizeProdaje_izPromet_5_8.Name := 'ploscicaAnalizeProdaje_izPromet_5_8';
    ploscicaAnalizeProdaje_izPromet_5_8.Left := 22;
    ploscicaAnalizeProdaje_izPromet_5_8.Top := 213;
    ploscicaAnalizeProdaje_izPromet_5_8.Width := 21;
    ploscicaAnalizeProdaje_izPromet_5_8.Height := 15;
    ploscicaAnalizeProdaje_izPromet_5_8.Anchors := [];
    ploscicaAnalizeProdaje_izPromet_5_8.Caption := '0.00';
    ploscicaAnalizeProdaje_izPromet_5_8.Color := clNone;
    ploscicaAnalizeProdaje_izPromet_5_8.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_5_8.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_5_9.SetParentComponent(ploscicaAnalizeProdaje_PrometXplus3);
    ploscicaAnalizeProdaje_izPromet_5_9.Name := 'ploscicaAnalizeProdaje_izPromet_5_9';
    ploscicaAnalizeProdaje_izPromet_5_9.Left := 22;
    ploscicaAnalizeProdaje_izPromet_5_9.Top := 239;
    ploscicaAnalizeProdaje_izPromet_5_9.Width := 21;
    ploscicaAnalizeProdaje_izPromet_5_9.Height := 15;
    ploscicaAnalizeProdaje_izPromet_5_9.Anchors := [];
    ploscicaAnalizeProdaje_izPromet_5_9.Caption := '0.00';
    ploscicaAnalizeProdaje_izPromet_5_9.Color := clNone;
    ploscicaAnalizeProdaje_izPromet_5_9.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_5_9.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_5_10.SetParentComponent(ploscicaAnalizeProdaje_PrometXplus3);
    ploscicaAnalizeProdaje_izPromet_5_10.Name := 'ploscicaAnalizeProdaje_izPromet_5_10';
    ploscicaAnalizeProdaje_izPromet_5_10.Left := 22;
    ploscicaAnalizeProdaje_izPromet_5_10.Top := 265;
    ploscicaAnalizeProdaje_izPromet_5_10.Width := 21;
    ploscicaAnalizeProdaje_izPromet_5_10.Height := 15;
    ploscicaAnalizeProdaje_izPromet_5_10.Anchors := [];
    ploscicaAnalizeProdaje_izPromet_5_10.Caption := '0.00';
    ploscicaAnalizeProdaje_izPromet_5_10.Color := clNone;
    ploscicaAnalizeProdaje_izPromet_5_10.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_5_10.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_5_11.SetParentComponent(ploscicaAnalizeProdaje_PrometXplus3);
    ploscicaAnalizeProdaje_izPromet_5_11.Name := 'ploscicaAnalizeProdaje_izPromet_5_11';
    ploscicaAnalizeProdaje_izPromet_5_11.Left := 22;
    ploscicaAnalizeProdaje_izPromet_5_11.Top := 291;
    ploscicaAnalizeProdaje_izPromet_5_11.Width := 21;
    ploscicaAnalizeProdaje_izPromet_5_11.Height := 15;
    ploscicaAnalizeProdaje_izPromet_5_11.Anchors := [];
    ploscicaAnalizeProdaje_izPromet_5_11.Caption := '0.00';
    ploscicaAnalizeProdaje_izPromet_5_11.Color := clNone;
    ploscicaAnalizeProdaje_izPromet_5_11.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_5_11.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_5_12.SetParentComponent(ploscicaAnalizeProdaje_PrometXplus3);
    ploscicaAnalizeProdaje_izPromet_5_12.Name := 'ploscicaAnalizeProdaje_izPromet_5_12';
    ploscicaAnalizeProdaje_izPromet_5_12.Left := 22;
    ploscicaAnalizeProdaje_izPromet_5_12.Top := 317;
    ploscicaAnalizeProdaje_izPromet_5_12.Width := 21;
    ploscicaAnalizeProdaje_izPromet_5_12.Height := 15;
    ploscicaAnalizeProdaje_izPromet_5_12.Anchors := [];
    ploscicaAnalizeProdaje_izPromet_5_12.Caption := '0.00';
    ploscicaAnalizeProdaje_izPromet_5_12.Color := clNone;
    ploscicaAnalizeProdaje_izPromet_5_12.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_5_12.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_PrometXplus4.SetParentComponent(LPT1_Tabela);
    ploscicaAnalizeProdaje_PrometXplus4.Name := 'ploscicaAnalizeProdaje_PrometXplus4';
    ploscicaAnalizeProdaje_PrometXplus4.Left := 617;
    ploscicaAnalizeProdaje_PrometXplus4.Top := 40;
    ploscicaAnalizeProdaje_PrometXplus4.Width := 100;
    ploscicaAnalizeProdaje_PrometXplus4.Height := 330;
    ploscicaAnalizeProdaje_PrometXplus4.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_PrometXplus4.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_PrometXplus4.ChildOrder := 7;
    ploscicaAnalizeProdaje_PrometXplus4.ColumnCollection.Clear;
    with ploscicaAnalizeProdaje_PrometXplus4.ColumnCollection.Add do
    begin
      Alignment := taRightJustify;
      MarginRight := 5;
      Value := 50;
    end;
    ploscicaAnalizeProdaje_PrometXplus4.ControlCollection.Clear;
    with ploscicaAnalizeProdaje_PrometXplus4.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izPromet_NaslovXplus4;
    end;
    with ploscicaAnalizeProdaje_PrometXplus4.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izPromet_6_1;
    end;
    with ploscicaAnalizeProdaje_PrometXplus4.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izPromet_6_2;
    end;
    with ploscicaAnalizeProdaje_PrometXplus4.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izPromet_6_3;
    end;
    with ploscicaAnalizeProdaje_PrometXplus4.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izPromet_6_4;
    end;
    with ploscicaAnalizeProdaje_PrometXplus4.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izPromet_6_5;
    end;
    with ploscicaAnalizeProdaje_PrometXplus4.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izPromet_6_6;
    end;
    with ploscicaAnalizeProdaje_PrometXplus4.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izPromet_6_7;
    end;
    with ploscicaAnalizeProdaje_PrometXplus4.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izPromet_6_8;
    end;
    with ploscicaAnalizeProdaje_PrometXplus4.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izPromet_6_9;
    end;
    with ploscicaAnalizeProdaje_PrometXplus4.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izPromet_6_10;
    end;
    with ploscicaAnalizeProdaje_PrometXplus4.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izPromet_6_11;
    end;
    with ploscicaAnalizeProdaje_PrometXplus4.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaAnalizeProdaje_izPromet_6_12;
    end;
    ploscicaAnalizeProdaje_PrometXplus4.Color := clWhite;
    ploscicaAnalizeProdaje_PrometXplus4.GridLineWidth := 1;
    ploscicaAnalizeProdaje_PrometXplus4.GridLineColor := clBlack;
    ploscicaAnalizeProdaje_PrometXplus4.RowCollection.Clear;
    with ploscicaAnalizeProdaje_PrometXplus4.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_PrometXplus4.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_PrometXplus4.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_PrometXplus4.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_PrometXplus4.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_PrometXplus4.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_PrometXplus4.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_PrometXplus4.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_PrometXplus4.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_PrometXplus4.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_PrometXplus4.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_PrometXplus4.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaAnalizeProdaje_PrometXplus4.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    ploscicaAnalizeProdaje_izPromet_NaslovXplus4.SetParentComponent(ploscicaAnalizeProdaje_PrometXplus4);
    ploscicaAnalizeProdaje_izPromet_NaslovXplus4.Name := 'ploscicaAnalizeProdaje_izPromet_NaslovXplus4';
    ploscicaAnalizeProdaje_izPromet_NaslovXplus4.Left := 0;
    ploscicaAnalizeProdaje_izPromet_NaslovXplus4.Top := 6;
    ploscicaAnalizeProdaje_izPromet_NaslovXplus4.Width := 43;
    ploscicaAnalizeProdaje_izPromet_NaslovXplus4.Height := 14;
    ploscicaAnalizeProdaje_izPromet_NaslovXplus4.Anchors := [];
    ploscicaAnalizeProdaje_izPromet_NaslovXplus4.Caption := 'leto X+2';
    ploscicaAnalizeProdaje_izPromet_NaslovXplus4.Color := clNone;
    ploscicaAnalizeProdaje_izPromet_NaslovXplus4.Font.Charset := DEFAULT_CHARSET;
    ploscicaAnalizeProdaje_izPromet_NaslovXplus4.Font.Color := clWindowText;
    ploscicaAnalizeProdaje_izPromet_NaslovXplus4.Font.Height := -11;
    ploscicaAnalizeProdaje_izPromet_NaslovXplus4.Font.Name := 'Arial';
    ploscicaAnalizeProdaje_izPromet_NaslovXplus4.Font.Style := [fsBold];
    ploscicaAnalizeProdaje_izPromet_NaslovXplus4.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_NaslovXplus4.ParentFont := False;
    ploscicaAnalizeProdaje_izPromet_NaslovXplus4.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_6_1.SetParentComponent(ploscicaAnalizeProdaje_PrometXplus4);
    ploscicaAnalizeProdaje_izPromet_6_1.Name := 'ploscicaAnalizeProdaje_izPromet_6_1';
    ploscicaAnalizeProdaje_izPromet_6_1.Left := 22;
    ploscicaAnalizeProdaje_izPromet_6_1.Top := 31;
    ploscicaAnalizeProdaje_izPromet_6_1.Width := 21;
    ploscicaAnalizeProdaje_izPromet_6_1.Height := 15;
    ploscicaAnalizeProdaje_izPromet_6_1.Anchors := [];
    ploscicaAnalizeProdaje_izPromet_6_1.Caption := '0.00';
    ploscicaAnalizeProdaje_izPromet_6_1.Color := clNone;
    ploscicaAnalizeProdaje_izPromet_6_1.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_6_1.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_6_2.SetParentComponent(ploscicaAnalizeProdaje_PrometXplus4);
    ploscicaAnalizeProdaje_izPromet_6_2.Name := 'ploscicaAnalizeProdaje_izPromet_6_2';
    ploscicaAnalizeProdaje_izPromet_6_2.Left := 22;
    ploscicaAnalizeProdaje_izPromet_6_2.Top := 57;
    ploscicaAnalizeProdaje_izPromet_6_2.Width := 21;
    ploscicaAnalizeProdaje_izPromet_6_2.Height := 15;
    ploscicaAnalizeProdaje_izPromet_6_2.Anchors := [];
    ploscicaAnalizeProdaje_izPromet_6_2.Caption := '0.00';
    ploscicaAnalizeProdaje_izPromet_6_2.Color := clNone;
    ploscicaAnalizeProdaje_izPromet_6_2.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_6_2.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_6_3.SetParentComponent(ploscicaAnalizeProdaje_PrometXplus4);
    ploscicaAnalizeProdaje_izPromet_6_3.Name := 'ploscicaAnalizeProdaje_izPromet_6_3';
    ploscicaAnalizeProdaje_izPromet_6_3.Left := 22;
    ploscicaAnalizeProdaje_izPromet_6_3.Top := 83;
    ploscicaAnalizeProdaje_izPromet_6_3.Width := 21;
    ploscicaAnalizeProdaje_izPromet_6_3.Height := 15;
    ploscicaAnalizeProdaje_izPromet_6_3.Anchors := [];
    ploscicaAnalizeProdaje_izPromet_6_3.Caption := '0.00';
    ploscicaAnalizeProdaje_izPromet_6_3.Color := clNone;
    ploscicaAnalizeProdaje_izPromet_6_3.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_6_3.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_6_4.SetParentComponent(ploscicaAnalizeProdaje_PrometXplus4);
    ploscicaAnalizeProdaje_izPromet_6_4.Name := 'ploscicaAnalizeProdaje_izPromet_6_4';
    ploscicaAnalizeProdaje_izPromet_6_4.Left := 22;
    ploscicaAnalizeProdaje_izPromet_6_4.Top := 109;
    ploscicaAnalizeProdaje_izPromet_6_4.Width := 21;
    ploscicaAnalizeProdaje_izPromet_6_4.Height := 15;
    ploscicaAnalizeProdaje_izPromet_6_4.Anchors := [];
    ploscicaAnalizeProdaje_izPromet_6_4.Caption := '0.00';
    ploscicaAnalizeProdaje_izPromet_6_4.Color := clNone;
    ploscicaAnalizeProdaje_izPromet_6_4.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_6_4.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_6_5.SetParentComponent(ploscicaAnalizeProdaje_PrometXplus4);
    ploscicaAnalizeProdaje_izPromet_6_5.Name := 'ploscicaAnalizeProdaje_izPromet_6_5';
    ploscicaAnalizeProdaje_izPromet_6_5.Left := 22;
    ploscicaAnalizeProdaje_izPromet_6_5.Top := 135;
    ploscicaAnalizeProdaje_izPromet_6_5.Width := 21;
    ploscicaAnalizeProdaje_izPromet_6_5.Height := 15;
    ploscicaAnalizeProdaje_izPromet_6_5.Anchors := [];
    ploscicaAnalizeProdaje_izPromet_6_5.Caption := '0.00';
    ploscicaAnalizeProdaje_izPromet_6_5.Color := clNone;
    ploscicaAnalizeProdaje_izPromet_6_5.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_6_5.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_6_6.SetParentComponent(ploscicaAnalizeProdaje_PrometXplus4);
    ploscicaAnalizeProdaje_izPromet_6_6.Name := 'ploscicaAnalizeProdaje_izPromet_6_6';
    ploscicaAnalizeProdaje_izPromet_6_6.Left := 22;
    ploscicaAnalizeProdaje_izPromet_6_6.Top := 161;
    ploscicaAnalizeProdaje_izPromet_6_6.Width := 21;
    ploscicaAnalizeProdaje_izPromet_6_6.Height := 15;
    ploscicaAnalizeProdaje_izPromet_6_6.Anchors := [];
    ploscicaAnalizeProdaje_izPromet_6_6.Caption := '0.00';
    ploscicaAnalizeProdaje_izPromet_6_6.Color := clNone;
    ploscicaAnalizeProdaje_izPromet_6_6.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_6_6.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_6_7.SetParentComponent(ploscicaAnalizeProdaje_PrometXplus4);
    ploscicaAnalizeProdaje_izPromet_6_7.Name := 'ploscicaAnalizeProdaje_izPromet_6_7';
    ploscicaAnalizeProdaje_izPromet_6_7.Left := 22;
    ploscicaAnalizeProdaje_izPromet_6_7.Top := 187;
    ploscicaAnalizeProdaje_izPromet_6_7.Width := 21;
    ploscicaAnalizeProdaje_izPromet_6_7.Height := 15;
    ploscicaAnalizeProdaje_izPromet_6_7.Anchors := [];
    ploscicaAnalizeProdaje_izPromet_6_7.Caption := '0.00';
    ploscicaAnalizeProdaje_izPromet_6_7.Color := clNone;
    ploscicaAnalizeProdaje_izPromet_6_7.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_6_7.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_6_8.SetParentComponent(ploscicaAnalizeProdaje_PrometXplus4);
    ploscicaAnalizeProdaje_izPromet_6_8.Name := 'ploscicaAnalizeProdaje_izPromet_6_8';
    ploscicaAnalizeProdaje_izPromet_6_8.Left := 22;
    ploscicaAnalizeProdaje_izPromet_6_8.Top := 213;
    ploscicaAnalizeProdaje_izPromet_6_8.Width := 21;
    ploscicaAnalizeProdaje_izPromet_6_8.Height := 15;
    ploscicaAnalizeProdaje_izPromet_6_8.Anchors := [];
    ploscicaAnalizeProdaje_izPromet_6_8.Caption := '0.00';
    ploscicaAnalizeProdaje_izPromet_6_8.Color := clNone;
    ploscicaAnalizeProdaje_izPromet_6_8.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_6_8.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_6_9.SetParentComponent(ploscicaAnalizeProdaje_PrometXplus4);
    ploscicaAnalizeProdaje_izPromet_6_9.Name := 'ploscicaAnalizeProdaje_izPromet_6_9';
    ploscicaAnalizeProdaje_izPromet_6_9.Left := 22;
    ploscicaAnalizeProdaje_izPromet_6_9.Top := 239;
    ploscicaAnalizeProdaje_izPromet_6_9.Width := 21;
    ploscicaAnalizeProdaje_izPromet_6_9.Height := 15;
    ploscicaAnalizeProdaje_izPromet_6_9.Anchors := [];
    ploscicaAnalizeProdaje_izPromet_6_9.Caption := '0.00';
    ploscicaAnalizeProdaje_izPromet_6_9.Color := clNone;
    ploscicaAnalizeProdaje_izPromet_6_9.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_6_9.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_6_10.SetParentComponent(ploscicaAnalizeProdaje_PrometXplus4);
    ploscicaAnalizeProdaje_izPromet_6_10.Name := 'ploscicaAnalizeProdaje_izPromet_6_10';
    ploscicaAnalizeProdaje_izPromet_6_10.Left := 22;
    ploscicaAnalizeProdaje_izPromet_6_10.Top := 265;
    ploscicaAnalizeProdaje_izPromet_6_10.Width := 21;
    ploscicaAnalizeProdaje_izPromet_6_10.Height := 15;
    ploscicaAnalizeProdaje_izPromet_6_10.Anchors := [];
    ploscicaAnalizeProdaje_izPromet_6_10.Caption := '0.00';
    ploscicaAnalizeProdaje_izPromet_6_10.Color := clNone;
    ploscicaAnalizeProdaje_izPromet_6_10.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_6_10.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_6_11.SetParentComponent(ploscicaAnalizeProdaje_PrometXplus4);
    ploscicaAnalizeProdaje_izPromet_6_11.Name := 'ploscicaAnalizeProdaje_izPromet_6_11';
    ploscicaAnalizeProdaje_izPromet_6_11.Left := 22;
    ploscicaAnalizeProdaje_izPromet_6_11.Top := 291;
    ploscicaAnalizeProdaje_izPromet_6_11.Width := 21;
    ploscicaAnalizeProdaje_izPromet_6_11.Height := 15;
    ploscicaAnalizeProdaje_izPromet_6_11.Anchors := [];
    ploscicaAnalizeProdaje_izPromet_6_11.Caption := '0.00';
    ploscicaAnalizeProdaje_izPromet_6_11.Color := clNone;
    ploscicaAnalizeProdaje_izPromet_6_11.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_6_11.WidthPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_6_12.SetParentComponent(ploscicaAnalizeProdaje_PrometXplus4);
    ploscicaAnalizeProdaje_izPromet_6_12.Name := 'ploscicaAnalizeProdaje_izPromet_6_12';
    ploscicaAnalizeProdaje_izPromet_6_12.Left := 22;
    ploscicaAnalizeProdaje_izPromet_6_12.Top := 317;
    ploscicaAnalizeProdaje_izPromet_6_12.Width := 21;
    ploscicaAnalizeProdaje_izPromet_6_12.Height := 15;
    ploscicaAnalizeProdaje_izPromet_6_12.Anchors := [];
    ploscicaAnalizeProdaje_izPromet_6_12.Caption := '0.00';
    ploscicaAnalizeProdaje_izPromet_6_12.Color := clNone;
    ploscicaAnalizeProdaje_izPromet_6_12.HeightPercent := 100.000000000000000000;
    ploscicaAnalizeProdaje_izPromet_6_12.WidthPercent := 100.000000000000000000;
    LPT2_MesecniGraf.SetParentComponent(ListiProdajnihTrendov);
    LPT2_MesecniGraf.Name := 'LPT2_MesecniGraf';
    LPT2_MesecniGraf.Left := 0;
    LPT2_MesecniGraf.Top := 20;
    LPT2_MesecniGraf.Width := 747;
    LPT2_MesecniGraf.Height := 416;
    LPT2_MesecniGraf.HeightPercent := 100.000000000000000000;
    LPT2_MesecniGraf.WidthPercent := 100.000000000000000000;
    LPT2_MesecniGraf.Caption := 'mese'#269'ni graf';
    LPT2_MesecniGraf.ChildOrder := 1;
    GrafMesecniTrendi.SetParentComponent(LPT2_MesecniGraf);
    GrafMesecniTrendi.Name := 'GrafMesecniTrendi';
    GrafMesecniTrendi.Left := 3;
    GrafMesecniTrendi.Top := 9;
    GrafMesecniTrendi.Width := 741;
    GrafMesecniTrendi.Height := 399;
    GrafMesecniTrendi.HeightPercent := 100.000000000000000000;
    GrafMesecniTrendi.WidthPercent := 100.000000000000000000;
    GrafMesecniTrendi.Appearance.Animation.Startup := False;
    GrafMesecniTrendi.Series.Clear;
    with GrafMesecniTrendi.Series.Add do
    begin
      ChartType := gctLine;
      Color := clRed;
      Tag := -1;
    end;
    LPT3_LetniGraf.SetParentComponent(ListiProdajnihTrendov);
    LPT3_LetniGraf.Name := 'LPT3_LetniGraf';
    LPT3_LetniGraf.Left := 0;
    LPT3_LetniGraf.Top := 20;
    LPT3_LetniGraf.Width := 747;
    LPT3_LetniGraf.Height := 416;
    LPT3_LetniGraf.HeightPercent := 100.000000000000000000;
    LPT3_LetniGraf.WidthPercent := 100.000000000000000000;
    LPT3_LetniGraf.Caption := 'letni graf';
    LPT3_LetniGraf.ChildOrder := 2;
    GrafLetniTrendi.SetParentComponent(LPT3_LetniGraf);
    GrafLetniTrendi.Name := 'GrafLetniTrendi';
    GrafLetniTrendi.Left := 6;
    GrafLetniTrendi.Top := 17;
    GrafLetniTrendi.Width := 741;
    GrafLetniTrendi.Height := 399;
    GrafLetniTrendi.HeightPercent := 100.000000000000000000;
    GrafLetniTrendi.WidthPercent := 100.000000000000000000;
    GrafLetniTrendi.Appearance.Animation.Startup := False;
    GrafLetniTrendi.Series.Clear;
    with GrafLetniTrendi.Series.Add do
    begin
      ChartType := gctLine;
      Color := clRed;
      Tag := -1;
    end;
    DelovniList9.SetParentComponent(ploscicaDelovniListi);
    DelovniList9.Name := 'DelovniList9';
    DelovniList9.Left := 0;
    DelovniList9.Top := 20;
    DelovniList9.Width := 1157;
    DelovniList9.Height := 1090;
    DelovniList9.HeightPercent := 100.000000000000000000;
    DelovniList9.WidthPercent := 100.000000000000000000;
    DelovniList9.Caption := 'DelovniList9';
    DelovniList9.ChildOrder := 11;
    DelovniList9.TabVisible := False;
    WebScrollBoxDL9.SetParentComponent(DelovniList9);
    WebScrollBoxDL9.Name := 'WebScrollBoxDL9';
    WebScrollBoxDL9.Left := 0;
    WebScrollBoxDL9.Top := 0;
    WebScrollBoxDL9.Width := 1157;
    WebScrollBoxDL9.Height := 1090;
    WebScrollBoxDL9.HeightPercent := 100.000000000000000000;
    WebScrollBoxDL9.WidthPercent := 100.000000000000000000;
    WebScrollBoxDL9.Align := alClient;
    WebScrollBoxDL9.AutoScroll := True;
    WebScrollBoxDL9.BorderStyle := bsNone;
    WebScrollBoxDL9.ChildOrder := 2;
    WebScrollBoxDL9.Color := clWindow;
    izNaslovDL9.SetParentComponent(WebScrollBoxDL9);
    izNaslovDL9.Name := 'izNaslovDL9';
    izNaslovDL9.Left := 12;
    izNaslovDL9.Top := 28;
    izNaslovDL9.Width := 255;
    izNaslovDL9.Height := 19;
    izNaslovDL9.Anchors := [];
    izNaslovDL9.Caption := 'Analiza obstoje'#269'ega zavarovanja';
    izNaslovDL9.Color := clNone;
    izNaslovDL9.Font.Charset := DEFAULT_CHARSET;
    izNaslovDL9.Font.Color := clWindowText;
    izNaslovDL9.Font.Height := -17;
    izNaslovDL9.Font.Name := 'Arial';
    izNaslovDL9.Font.Style := [fsBold];
    izNaslovDL9.HeightPercent := 100.000000000000000000;
    izNaslovDL9.ParentFont := False;
    izNaslovDL9.WidthPercent := 100.000000000000000000;
    ploscicaObstojeceZavarovanje.SetParentComponent(WebScrollBoxDL9);
    ploscicaObstojeceZavarovanje.Name := 'ploscicaObstojeceZavarovanje';
    ploscicaObstojeceZavarovanje.Left := 15;
    ploscicaObstojeceZavarovanje.Top := 80;
    ploscicaObstojeceZavarovanje.Width := 400;
    ploscicaObstojeceZavarovanje.Height := 120;
    ploscicaObstojeceZavarovanje.HeightPercent := 100.000000000000000000;
    ploscicaObstojeceZavarovanje.WidthPercent := 100.000000000000000000;
    ploscicaObstojeceZavarovanje.ChildOrder := 1;
    ploscicaObstojeceZavarovanje.ColumnCollection.Clear;
    with ploscicaObstojeceZavarovanje.ColumnCollection.Add do
    begin
      Value := 60;
    end;
    with ploscicaObstojeceZavarovanje.ColumnCollection.Add do
    begin
      Value := 30;
    end;
    with ploscicaObstojeceZavarovanje.ColumnCollection.Add do
    begin
      Alignment := taCenter;
      Value := 5;
    end;
    with ploscicaObstojeceZavarovanje.ColumnCollection.Add do
    begin
      Value := 5;
    end;
    ploscicaObstojeceZavarovanje.ControlCollection.Clear;
    with ploscicaObstojeceZavarovanje.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izPloscicaObstojeceZavarovanje_Vprasanje1;
    end;
    with ploscicaObstojeceZavarovanje.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izPloscicaObstojeceZavarovanje_Odgovor1;
    end;
    with ploscicaObstojeceZavarovanje.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izPloscicaObstojeceZavarovanje_Semafor1;
    end;
    with ploscicaObstojeceZavarovanje.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izPloscicaObstojeceZavarovanje_prazna1;
    end;
    with ploscicaObstojeceZavarovanje.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izPloscicaObstojeceZavarovanje_Vprasanje2;
    end;
    with ploscicaObstojeceZavarovanje.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izPloscicaObstojeceZavarovanje_Odgovor2;
    end;
    with ploscicaObstojeceZavarovanje.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izPloscicaObstojeceZavarovanje_Semafor2;
    end;
    with ploscicaObstojeceZavarovanje.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izPloscicaObstojeceZavarovanje_prazna2;
    end;
    with ploscicaObstojeceZavarovanje.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izPloscicaObstojeceZavarovanje_Vprasanje3;
    end;
    with ploscicaObstojeceZavarovanje.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izPloscicaObstojeceZavarovanje_Odgovor3;
    end;
    with ploscicaObstojeceZavarovanje.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izPloscicaObstojeceZavarovanje_Semafor3;
    end;
    with ploscicaObstojeceZavarovanje.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izPloscicaObstojeceZavarovanje_Semafor3b;
    end;
    with ploscicaObstojeceZavarovanje.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izPloscicaObstojeceZavarovanje_Vprasanje4;
    end;
    with ploscicaObstojeceZavarovanje.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izPloscicaObstojeceZavarovanje_Odgovor4;
    end;
    with ploscicaObstojeceZavarovanje.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izPloscicaObstojeceZavarovanje_Semafor4;
    end;
    with ploscicaObstojeceZavarovanje.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izPloscicaObstojeceZavarovanje_prazna4;
    end;
    ploscicaObstojeceZavarovanje.Color := clWhite;
    ploscicaObstojeceZavarovanje.GridLineColor := clBlack;
    ploscicaObstojeceZavarovanje.RowCollection.Clear;
    with ploscicaObstojeceZavarovanje.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 25;
    end;
    with ploscicaObstojeceZavarovanje.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 25;
    end;
    with ploscicaObstojeceZavarovanje.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 25;
    end;
    with ploscicaObstojeceZavarovanje.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 25;
    end;
    izPloscicaObstojeceZavarovanje_Vprasanje1.SetParentComponent(ploscicaObstojeceZavarovanje);
    izPloscicaObstojeceZavarovanje_Vprasanje1.Name := 'izPloscicaObstojeceZavarovanje_Vprasanje1';
    izPloscicaObstojeceZavarovanje_Vprasanje1.Left := 2;
    izPloscicaObstojeceZavarovanje_Vprasanje1.Top := 7;
    izPloscicaObstojeceZavarovanje_Vprasanje1.Width := 218;
    izPloscicaObstojeceZavarovanje_Vprasanje1.Height := 15;
    izPloscicaObstojeceZavarovanje_Vprasanje1.Anchors := [];
    izPloscicaObstojeceZavarovanje_Vprasanje1.Caption := 'Ali ima podjetje '#382'e sklenjeno zavarovanje:';
    izPloscicaObstojeceZavarovanje_Vprasanje1.Color := clNone;
    izPloscicaObstojeceZavarovanje_Vprasanje1.HeightPercent := 100.000000000000000000;
    izPloscicaObstojeceZavarovanje_Vprasanje1.WidthPercent := 100.000000000000000000;
    izPloscicaObstojeceZavarovanje_Odgovor1.SetParentComponent(ploscicaObstojeceZavarovanje);
    izPloscicaObstojeceZavarovanje_Odgovor1.Name := 'izPloscicaObstojeceZavarovanje_Odgovor1';
    izPloscicaObstojeceZavarovanje_Odgovor1.Left := 242;
    izPloscicaObstojeceZavarovanje_Odgovor1.Top := 8;
    izPloscicaObstojeceZavarovanje_Odgovor1.Width := 15;
    izPloscicaObstojeceZavarovanje_Odgovor1.Height := 14;
    izPloscicaObstojeceZavarovanje_Odgovor1.Anchors := [];
    izPloscicaObstojeceZavarovanje_Odgovor1.Caption := 'DA';
    izPloscicaObstojeceZavarovanje_Odgovor1.Color := clNone;
    izPloscicaObstojeceZavarovanje_Odgovor1.Font.Charset := DEFAULT_CHARSET;
    izPloscicaObstojeceZavarovanje_Odgovor1.Font.Color := clWindowText;
    izPloscicaObstojeceZavarovanje_Odgovor1.Font.Height := -11;
    izPloscicaObstojeceZavarovanje_Odgovor1.Font.Name := 'Arial';
    izPloscicaObstojeceZavarovanje_Odgovor1.Font.Style := [fsBold];
    izPloscicaObstojeceZavarovanje_Odgovor1.HeightPercent := 100.000000000000000000;
    izPloscicaObstojeceZavarovanje_Odgovor1.ParentFont := False;
    izPloscicaObstojeceZavarovanje_Odgovor1.WidthPercent := 100.000000000000000000;
    izPloscicaObstojeceZavarovanje_Semafor1.SetParentComponent(ploscicaObstojeceZavarovanje);
    izPloscicaObstojeceZavarovanje_Semafor1.Name := 'izPloscicaObstojeceZavarovanje_Semafor1';
    izPloscicaObstojeceZavarovanje_Semafor1.Left := 362;
    izPloscicaObstojeceZavarovanje_Semafor1.Top := 7;
    izPloscicaObstojeceZavarovanje_Semafor1.Width := 15;
    izPloscicaObstojeceZavarovanje_Semafor1.Height := 15;
    izPloscicaObstojeceZavarovanje_Semafor1.HeightPercent := 100.000000000000000000;
    izPloscicaObstojeceZavarovanje_Semafor1.WidthPercent := 100.000000000000000000;
    izPloscicaObstojeceZavarovanje_Semafor1.Anchors := [];
    izPloscicaObstojeceZavarovanje_Semafor1.ChildOrder := 2;
    izPloscicaObstojeceZavarovanje_prazna1.SetParentComponent(ploscicaObstojeceZavarovanje);
    izPloscicaObstojeceZavarovanje_prazna1.Name := 'izPloscicaObstojeceZavarovanje_prazna1';
    izPloscicaObstojeceZavarovanje_prazna1.Left := 382;
    izPloscicaObstojeceZavarovanje_prazna1.Top := 7;
    izPloscicaObstojeceZavarovanje_prazna1.Width := 3;
    izPloscicaObstojeceZavarovanje_prazna1.Height := 15;
    izPloscicaObstojeceZavarovanje_prazna1.Anchors := [];
    izPloscicaObstojeceZavarovanje_prazna1.Color := clNone;
    izPloscicaObstojeceZavarovanje_prazna1.HeightPercent := 100.000000000000000000;
    izPloscicaObstojeceZavarovanje_prazna1.WidthPercent := 100.000000000000000000;
    izPloscicaObstojeceZavarovanje_Vprasanje2.SetParentComponent(ploscicaObstojeceZavarovanje);
    izPloscicaObstojeceZavarovanje_Vprasanje2.Name := 'izPloscicaObstojeceZavarovanje_Vprasanje2';
    izPloscicaObstojeceZavarovanje_Vprasanje2.Left := 2;
    izPloscicaObstojeceZavarovanje_Vprasanje2.Top := 37;
    izPloscicaObstojeceZavarovanje_Vprasanje2.Width := 234;
    izPloscicaObstojeceZavarovanje_Vprasanje2.Height := 15;
    izPloscicaObstojeceZavarovanje_Vprasanje2.Anchors := [];
    izPloscicaObstojeceZavarovanje_Vprasanje2.Caption := 'Sistem, po katerem je sklenjeno zavarovanje:';
    izPloscicaObstojeceZavarovanje_Vprasanje2.Color := clNone;
    izPloscicaObstojeceZavarovanje_Vprasanje2.HeightPercent := 100.000000000000000000;
    izPloscicaObstojeceZavarovanje_Vprasanje2.WidthPercent := 100.000000000000000000;
    izPloscicaObstojeceZavarovanje_Odgovor2.SetParentComponent(ploscicaObstojeceZavarovanje);
    izPloscicaObstojeceZavarovanje_Odgovor2.Name := 'izPloscicaObstojeceZavarovanje_Odgovor2';
    izPloscicaObstojeceZavarovanje_Odgovor2.Left := 242;
    izPloscicaObstojeceZavarovanje_Odgovor2.Top := 38;
    izPloscicaObstojeceZavarovanje_Odgovor2.Width := 92;
    izPloscicaObstojeceZavarovanje_Odgovor2.Height := 14;
    izPloscicaObstojeceZavarovanje_Odgovor2.Anchors := [];
    izPloscicaObstojeceZavarovanje_Odgovor2.Caption := 'kosmati dobi'#269'ek';
    izPloscicaObstojeceZavarovanje_Odgovor2.Color := clNone;
    izPloscicaObstojeceZavarovanje_Odgovor2.Font.Charset := DEFAULT_CHARSET;
    izPloscicaObstojeceZavarovanje_Odgovor2.Font.Color := clWindowText;
    izPloscicaObstojeceZavarovanje_Odgovor2.Font.Height := -11;
    izPloscicaObstojeceZavarovanje_Odgovor2.Font.Name := 'Arial';
    izPloscicaObstojeceZavarovanje_Odgovor2.Font.Style := [fsBold];
    izPloscicaObstojeceZavarovanje_Odgovor2.HeightPercent := 100.000000000000000000;
    izPloscicaObstojeceZavarovanje_Odgovor2.ParentFont := False;
    izPloscicaObstojeceZavarovanje_Odgovor2.WidthPercent := 100.000000000000000000;
    izPloscicaObstojeceZavarovanje_Semafor2.SetParentComponent(ploscicaObstojeceZavarovanje);
    izPloscicaObstojeceZavarovanje_Semafor2.Name := 'izPloscicaObstojeceZavarovanje_Semafor2';
    izPloscicaObstojeceZavarovanje_Semafor2.Left := 362;
    izPloscicaObstojeceZavarovanje_Semafor2.Top := 37;
    izPloscicaObstojeceZavarovanje_Semafor2.Width := 15;
    izPloscicaObstojeceZavarovanje_Semafor2.Height := 15;
    izPloscicaObstojeceZavarovanje_Semafor2.HeightPercent := 100.000000000000000000;
    izPloscicaObstojeceZavarovanje_Semafor2.WidthPercent := 100.000000000000000000;
    izPloscicaObstojeceZavarovanje_Semafor2.Anchors := [];
    izPloscicaObstojeceZavarovanje_Semafor2.ChildOrder := 6;
    izPloscicaObstojeceZavarovanje_prazna2.SetParentComponent(ploscicaObstojeceZavarovanje);
    izPloscicaObstojeceZavarovanje_prazna2.Name := 'izPloscicaObstojeceZavarovanje_prazna2';
    izPloscicaObstojeceZavarovanje_prazna2.Left := 382;
    izPloscicaObstojeceZavarovanje_prazna2.Top := 37;
    izPloscicaObstojeceZavarovanje_prazna2.Width := 3;
    izPloscicaObstojeceZavarovanje_prazna2.Height := 15;
    izPloscicaObstojeceZavarovanje_prazna2.Anchors := [];
    izPloscicaObstojeceZavarovanje_prazna2.Color := clNone;
    izPloscicaObstojeceZavarovanje_prazna2.HeightPercent := 100.000000000000000000;
    izPloscicaObstojeceZavarovanje_prazna2.WidthPercent := 100.000000000000000000;
    izPloscicaObstojeceZavarovanje_Vprasanje3.SetParentComponent(ploscicaObstojeceZavarovanje);
    izPloscicaObstojeceZavarovanje_Vprasanje3.Name := 'izPloscicaObstojeceZavarovanje_Vprasanje3';
    izPloscicaObstojeceZavarovanje_Vprasanje3.Left := 2;
    izPloscicaObstojeceZavarovanje_Vprasanje3.Top := 67;
    izPloscicaObstojeceZavarovanje_Vprasanje3.Width := 219;
    izPloscicaObstojeceZavarovanje_Vprasanje3.Height := 15;
    izPloscicaObstojeceZavarovanje_Vprasanje3.Anchors := [];
    izPloscicaObstojeceZavarovanje_Vprasanje3.Caption := 'Dogovorjena maksimalna doba jam'#269'enja:';
    izPloscicaObstojeceZavarovanje_Vprasanje3.Color := clNone;
    izPloscicaObstojeceZavarovanje_Vprasanje3.HeightPercent := 100.000000000000000000;
    izPloscicaObstojeceZavarovanje_Vprasanje3.WidthPercent := 100.000000000000000000;
    izPloscicaObstojeceZavarovanje_Odgovor3.SetParentComponent(ploscicaObstojeceZavarovanje);
    izPloscicaObstojeceZavarovanje_Odgovor3.Name := 'izPloscicaObstojeceZavarovanje_Odgovor3';
    izPloscicaObstojeceZavarovanje_Odgovor3.Left := 242;
    izPloscicaObstojeceZavarovanje_Odgovor3.Top := 68;
    izPloscicaObstojeceZavarovanje_Odgovor3.Width := 68;
    izPloscicaObstojeceZavarovanje_Odgovor3.Height := 14;
    izPloscicaObstojeceZavarovanje_Odgovor3.Anchors := [];
    izPloscicaObstojeceZavarovanje_Odgovor3.Caption := '3 mesece(v)';
    izPloscicaObstojeceZavarovanje_Odgovor3.Color := clNone;
    izPloscicaObstojeceZavarovanje_Odgovor3.Font.Charset := DEFAULT_CHARSET;
    izPloscicaObstojeceZavarovanje_Odgovor3.Font.Color := clWindowText;
    izPloscicaObstojeceZavarovanje_Odgovor3.Font.Height := -11;
    izPloscicaObstojeceZavarovanje_Odgovor3.Font.Name := 'Arial';
    izPloscicaObstojeceZavarovanje_Odgovor3.Font.Style := [fsBold];
    izPloscicaObstojeceZavarovanje_Odgovor3.HeightPercent := 100.000000000000000000;
    izPloscicaObstojeceZavarovanje_Odgovor3.ParentFont := False;
    izPloscicaObstojeceZavarovanje_Odgovor3.WidthPercent := 100.000000000000000000;
    izPloscicaObstojeceZavarovanje_Semafor3.SetParentComponent(ploscicaObstojeceZavarovanje);
    izPloscicaObstojeceZavarovanje_Semafor3.Name := 'izPloscicaObstojeceZavarovanje_Semafor3';
    izPloscicaObstojeceZavarovanje_Semafor3.Left := 362;
    izPloscicaObstojeceZavarovanje_Semafor3.Top := 67;
    izPloscicaObstojeceZavarovanje_Semafor3.Width := 15;
    izPloscicaObstojeceZavarovanje_Semafor3.Height := 15;
    izPloscicaObstojeceZavarovanje_Semafor3.HeightPercent := 100.000000000000000000;
    izPloscicaObstojeceZavarovanje_Semafor3.WidthPercent := 100.000000000000000000;
    izPloscicaObstojeceZavarovanje_Semafor3.Anchors := [];
    izPloscicaObstojeceZavarovanje_Semafor3.ChildOrder := 10;
    izPloscicaObstojeceZavarovanje_Semafor3b.SetParentComponent(ploscicaObstojeceZavarovanje);
    izPloscicaObstojeceZavarovanje_Semafor3b.Name := 'izPloscicaObstojeceZavarovanje_Semafor3b';
    izPloscicaObstojeceZavarovanje_Semafor3b.Left := 382;
    izPloscicaObstojeceZavarovanje_Semafor3b.Top := 67;
    izPloscicaObstojeceZavarovanje_Semafor3b.Width := 15;
    izPloscicaObstojeceZavarovanje_Semafor3b.Height := 15;
    izPloscicaObstojeceZavarovanje_Semafor3b.HeightPercent := 100.000000000000000000;
    izPloscicaObstojeceZavarovanje_Semafor3b.WidthPercent := 100.000000000000000000;
    izPloscicaObstojeceZavarovanje_Semafor3b.Anchors := [];
    izPloscicaObstojeceZavarovanje_Semafor3b.ChildOrder := 11;
    izPloscicaObstojeceZavarovanje_Vprasanje4.SetParentComponent(ploscicaObstojeceZavarovanje);
    izPloscicaObstojeceZavarovanje_Vprasanje4.Name := 'izPloscicaObstojeceZavarovanje_Vprasanje4';
    izPloscicaObstojeceZavarovanje_Vprasanje4.Left := 2;
    izPloscicaObstojeceZavarovanje_Vprasanje4.Top := 97;
    izPloscicaObstojeceZavarovanje_Vprasanje4.Width := 167;
    izPloscicaObstojeceZavarovanje_Vprasanje4.Height := 15;
    izPloscicaObstojeceZavarovanje_Vprasanje4.Anchors := [];
    izPloscicaObstojeceZavarovanje_Vprasanje4.Caption := 'Dogovorjena zavarovalna vsota:';
    izPloscicaObstojeceZavarovanje_Vprasanje4.Color := clNone;
    izPloscicaObstojeceZavarovanje_Vprasanje4.HeightPercent := 100.000000000000000000;
    izPloscicaObstojeceZavarovanje_Vprasanje4.WidthPercent := 100.000000000000000000;
    izPloscicaObstojeceZavarovanje_Odgovor4.SetParentComponent(ploscicaObstojeceZavarovanje);
    izPloscicaObstojeceZavarovanje_Odgovor4.Name := 'izPloscicaObstojeceZavarovanje_Odgovor4';
    izPloscicaObstojeceZavarovanje_Odgovor4.Left := 242;
    izPloscicaObstojeceZavarovanje_Odgovor4.Top := 98;
    izPloscicaObstojeceZavarovanje_Odgovor4.Width := 63;
    izPloscicaObstojeceZavarovanje_Odgovor4.Height := 14;
    izPloscicaObstojeceZavarovanje_Odgovor4.Anchors := [];
    izPloscicaObstojeceZavarovanje_Odgovor4.Caption := '3,500,000.00';
    izPloscicaObstojeceZavarovanje_Odgovor4.Color := clNone;
    izPloscicaObstojeceZavarovanje_Odgovor4.Font.Charset := DEFAULT_CHARSET;
    izPloscicaObstojeceZavarovanje_Odgovor4.Font.Color := clWindowText;
    izPloscicaObstojeceZavarovanje_Odgovor4.Font.Height := -11;
    izPloscicaObstojeceZavarovanje_Odgovor4.Font.Name := 'Arial';
    izPloscicaObstojeceZavarovanje_Odgovor4.Font.Style := [fsBold];
    izPloscicaObstojeceZavarovanje_Odgovor4.HeightPercent := 100.000000000000000000;
    izPloscicaObstojeceZavarovanje_Odgovor4.ParentFont := False;
    izPloscicaObstojeceZavarovanje_Odgovor4.WidthPercent := 100.000000000000000000;
    izPloscicaObstojeceZavarovanje_Semafor4.SetParentComponent(ploscicaObstojeceZavarovanje);
    izPloscicaObstojeceZavarovanje_Semafor4.Name := 'izPloscicaObstojeceZavarovanje_Semafor4';
    izPloscicaObstojeceZavarovanje_Semafor4.Left := 362;
    izPloscicaObstojeceZavarovanje_Semafor4.Top := 97;
    izPloscicaObstojeceZavarovanje_Semafor4.Width := 15;
    izPloscicaObstojeceZavarovanje_Semafor4.Height := 15;
    izPloscicaObstojeceZavarovanje_Semafor4.HeightPercent := 100.000000000000000000;
    izPloscicaObstojeceZavarovanje_Semafor4.WidthPercent := 100.000000000000000000;
    izPloscicaObstojeceZavarovanje_Semafor4.Anchors := [];
    izPloscicaObstojeceZavarovanje_Semafor4.ChildOrder := 14;
    izPloscicaObstojeceZavarovanje_prazna4.SetParentComponent(ploscicaObstojeceZavarovanje);
    izPloscicaObstojeceZavarovanje_prazna4.Name := 'izPloscicaObstojeceZavarovanje_prazna4';
    izPloscicaObstojeceZavarovanje_prazna4.Left := 382;
    izPloscicaObstojeceZavarovanje_prazna4.Top := 97;
    izPloscicaObstojeceZavarovanje_prazna4.Width := 3;
    izPloscicaObstojeceZavarovanje_prazna4.Height := 15;
    izPloscicaObstojeceZavarovanje_prazna4.Anchors := [];
    izPloscicaObstojeceZavarovanje_prazna4.Color := clNone;
    izPloscicaObstojeceZavarovanje_prazna4.HeightPercent := 100.000000000000000000;
    izPloscicaObstojeceZavarovanje_prazna4.WidthPercent := 100.000000000000000000;
    DelovniList10.SetParentComponent(ploscicaDelovniListi);
    DelovniList10.Name := 'DelovniList10';
    DelovniList10.Left := 0;
    DelovniList10.Top := 20;
    DelovniList10.Width := 1157;
    DelovniList10.Height := 1090;
    DelovniList10.HeightPercent := 100.000000000000000000;
    DelovniList10.WidthPercent := 100.000000000000000000;
    DelovniList10.Caption := 'DelovniList10';
    DelovniList10.ChildOrder := 12;
    DelovniList10.TabVisible := False;
    WebScrollBoxDL10.SetParentComponent(DelovniList10);
    WebScrollBoxDL10.Name := 'WebScrollBoxDL10';
    WebScrollBoxDL10.Left := 0;
    WebScrollBoxDL10.Top := 0;
    WebScrollBoxDL10.Width := 1157;
    WebScrollBoxDL10.Height := 1090;
    WebScrollBoxDL10.HeightPercent := 100.000000000000000000;
    WebScrollBoxDL10.WidthPercent := 100.000000000000000000;
    WebScrollBoxDL10.Align := alClient;
    WebScrollBoxDL10.AutoScroll := True;
    WebScrollBoxDL10.BorderStyle := bsNone;
    WebScrollBoxDL10.ChildOrder := 6;
    WebScrollBoxDL10.Color := clWindow;
    izDatumFizicneSkode.SetParentComponent(WebScrollBoxDL10);
    izDatumFizicneSkode.Name := 'izDatumFizicneSkode';
    izDatumFizicneSkode.Left := 18;
    izDatumFizicneSkode.Top := 278;
    izDatumFizicneSkode.Width := 174;
    izDatumFizicneSkode.Height := 14;
    izDatumFizicneSkode.Caption := 'Datum fizi'#269'ne '#353'kode na premo'#382'enju:';
    izDatumFizicneSkode.Color := clNone;
    izDatumFizicneSkode.Font.Charset := DEFAULT_CHARSET;
    izDatumFizicneSkode.Font.Color := clWindowText;
    izDatumFizicneSkode.Font.Height := -11;
    izDatumFizicneSkode.Font.Name := 'Arial';
    izDatumFizicneSkode.Font.Style := [];
    izDatumFizicneSkode.HeightPercent := 100.000000000000000000;
    izDatumFizicneSkode.ParentFont := False;
    izDatumFizicneSkode.WidthPercent := 100.000000000000000000;
    izNaslovDL10.SetParentComponent(WebScrollBoxDL10);
    izNaslovDL10.Name := 'izNaslovDL10';
    izNaslovDL10.Left := 12;
    izNaslovDL10.Top := 19;
    izNaslovDL10.Width := 325;
    izNaslovDL10.Height := 19;
    izNaslovDL10.Anchors := [];
    izNaslovDL10.Caption := 'Simulacija '#353'kode in izra'#269'un zavarovalnine';
    izNaslovDL10.Color := clNone;
    izNaslovDL10.Font.Charset := DEFAULT_CHARSET;
    izNaslovDL10.Font.Color := clWindowText;
    izNaslovDL10.Font.Height := -17;
    izNaslovDL10.Font.Name := 'Arial';
    izNaslovDL10.Font.Style := [fsBold];
    izNaslovDL10.HeightPercent := 100.000000000000000000;
    izNaslovDL10.ParentFont := False;
    izNaslovDL10.WidthPercent := 100.000000000000000000;
    gPreracunaj.SetParentComponent(WebScrollBoxDL10);
    gPreracunaj.Name := 'gPreracunaj';
    gPreracunaj.Left := 715;
    gPreracunaj.Top := 270;
    gPreracunaj.Width := 125;
    gPreracunaj.Height := 34;
    gPreracunaj.Caption := 'Prera'#269'unaj';
    gPreracunaj.Color := clNone;
    gPreracunaj.Font.Charset := DEFAULT_CHARSET;
    gPreracunaj.Font.Color := clWindowText;
    gPreracunaj.Font.Height := -13;
    gPreracunaj.Font.Name := 'Arial';
    gPreracunaj.Font.Style := [];
    gPreracunaj.Glyph.LoadFromFile('MBI_u01.WebScrollBoxDL10.gPreracunaj.Glyph.png');
    gPreracunaj.HeightPercent := 100.000000000000000000;
    gPreracunaj.ParentFont := False;
    gPreracunaj.WidthPercent := 100.000000000000000000;
    SetEvent(gPreracunaj, Self, 'OnClick', 'gPreracunajClick');
    ploscicaPodatkiZaSimulacijoSkode.SetParentComponent(WebScrollBoxDL10);
    ploscicaPodatkiZaSimulacijoSkode.Name := 'ploscicaPodatkiZaSimulacijoSkode';
    ploscicaPodatkiZaSimulacijoSkode.Left := 15;
    ploscicaPodatkiZaSimulacijoSkode.Top := 315;
    ploscicaPodatkiZaSimulacijoSkode.Width := 825;
    ploscicaPodatkiZaSimulacijoSkode.Height := 425;
    ploscicaPodatkiZaSimulacijoSkode.HeightPercent := 100.000000000000000000;
    ploscicaPodatkiZaSimulacijoSkode.WidthPercent := 100.000000000000000000;
    ploscicaPodatkiZaSimulacijoSkode.BorderColor := clSilver;
    ploscicaPodatkiZaSimulacijoSkode.BorderStyle := bsNone;
    ploscicaPodatkiZaSimulacijoSkode.ChildOrder := 4;
    ploscicaPodatkiZaSimulacijoSkode.Color := clWindow;
    DelovniListiPodatkiZaSimulacijoSkod.SetParentComponent(ploscicaPodatkiZaSimulacijoSkode);
    DelovniListiPodatkiZaSimulacijoSkod.Name := 'DelovniListiPodatkiZaSimulacijoSkod';
    DelovniListiPodatkiZaSimulacijoSkod.Left := 0;
    DelovniListiPodatkiZaSimulacijoSkod.Top := 0;
    DelovniListiPodatkiZaSimulacijoSkod.Width := 825;
    DelovniListiPodatkiZaSimulacijoSkod.Height := 425;
    DelovniListiPodatkiZaSimulacijoSkod.HeightPercent := 100.000000000000000000;
    DelovniListiPodatkiZaSimulacijoSkod.WidthPercent := 100.000000000000000000;
    DelovniListiPodatkiZaSimulacijoSkod.Align := alClient;
    DelovniListiPodatkiZaSimulacijoSkod.TabIndex := 0;
    DelovniListiPodatkiZaSimulacijoSkod.TabOrder := 0;
    SetEvent(DelovniListiPodatkiZaSimulacijoSkod, Self, 'OnChange', 'DelovniListiPodatkiZaSimulacijoSkodChange');
    WebTabSheet1.SetParentComponent(DelovniListiPodatkiZaSimulacijoSkod);
    WebTabSheet1.Name := 'WebTabSheet1';
    WebTabSheet1.Left := 0;
    WebTabSheet1.Top := 20;
    WebTabSheet1.Width := 825;
    WebTabSheet1.Height := 405;
    WebTabSheet1.HeightPercent := 100.000000000000000000;
    WebTabSheet1.WidthPercent := 100.000000000000000000;
    WebTabSheet1.Caption := 'Podatki o prometu';
    WebTabSheet1.ChildOrder := 3;
    izGraficniPrikazSimulacijaSkode.SetParentComponent(WebTabSheet1);
    izGraficniPrikazSimulacijaSkode.Name := 'izGraficniPrikazSimulacijaSkode';
    izGraficniPrikazSimulacijaSkode.Left := 714;
    izGraficniPrikazSimulacijaSkode.Top := 358;
    izGraficniPrikazSimulacijaSkode.Width := 90;
    izGraficniPrikazSimulacijaSkode.Height := 14;
    izGraficniPrikazSimulacijaSkode.Alignment := taCenter;
    izGraficniPrikazSimulacijaSkode.AutoSize := False;
    izGraficniPrikazSimulacijaSkode.Caption := 'grafi'#269'ni prikaz';
    izGraficniPrikazSimulacijaSkode.Color := clNone;
    izGraficniPrikazSimulacijaSkode.Font.Charset := DEFAULT_CHARSET;
    izGraficniPrikazSimulacijaSkode.Font.Color := clWindowText;
    izGraficniPrikazSimulacijaSkode.Font.Height := -11;
    izGraficniPrikazSimulacijaSkode.Font.Name := 'Arial';
    izGraficniPrikazSimulacijaSkode.Font.Style := [];
    izGraficniPrikazSimulacijaSkode.HeightPercent := 100.000000000000000000;
    izGraficniPrikazSimulacijaSkode.ParentFont := False;
    izGraficniPrikazSimulacijaSkode.WidthPercent := 100.000000000000000000;
    ploscicaTabelaPodatkovOPrometu.SetParentComponent(WebTabSheet1);
    ploscicaTabelaPodatkovOPrometu.Name := 'ploscicaTabelaPodatkovOPrometu';
    ploscicaTabelaPodatkovOPrometu.Left := 10;
    ploscicaTabelaPodatkovOPrometu.Top := 20;
    ploscicaTabelaPodatkovOPrometu.Width := 799;
    ploscicaTabelaPodatkovOPrometu.Height := 330;
    ploscicaTabelaPodatkovOPrometu.HeightPercent := 100.000000000000000000;
    ploscicaTabelaPodatkovOPrometu.WidthPercent := 100.000000000000000000;
    ploscicaTabelaPodatkovOPrometu.ColumnCollection.Clear;
    with ploscicaTabelaPodatkovOPrometu.ColumnCollection.Add do
    begin
      MarginRight := 5;
      Value := 16;
    end;
    with ploscicaTabelaPodatkovOPrometu.ColumnCollection.Add do
    begin
      Alignment := taCenter;
      Value := 12;
    end;
    with ploscicaTabelaPodatkovOPrometu.ColumnCollection.Add do
    begin
      Alignment := taCenter;
      Value := 12;
    end;
    with ploscicaTabelaPodatkovOPrometu.ColumnCollection.Add do
    begin
      Alignment := taCenter;
      Value := 12;
    end;
    with ploscicaTabelaPodatkovOPrometu.ColumnCollection.Add do
    begin
      Alignment := taCenter;
      Value := 12;
    end;
    with ploscicaTabelaPodatkovOPrometu.ColumnCollection.Add do
    begin
      Alignment := taCenter;
      Value := 12;
    end;
    with ploscicaTabelaPodatkovOPrometu.ColumnCollection.Add do
    begin
      Alignment := taCenter;
      Value := 12;
    end;
    with ploscicaTabelaPodatkovOPrometu.ColumnCollection.Add do
    begin
      Alignment := taCenter;
      Value := 12;
    end;
    ploscicaTabelaPodatkovOPrometu.ControlCollection.Clear;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Naslov1;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Naslov2;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Naslov3;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Naslov4;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Naslov5;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Naslov6;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Naslov7;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Naslov8;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Mesec1;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Promet_1_1;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Promet_2_1;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Promet_3_1;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Promet_4_1;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Indeks_1_1;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Indeks_2_1;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Indeks_3_1;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Mesec2;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Promet_1_2;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Promet_2_2;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Promet_3_2;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Promet_4_2;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Indeks_1_2;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Indeks_2_2;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Indeks_3_2;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Mesec3;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Promet_1_3;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Promet_2_3;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Promet_3_3;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Promet_4_3;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Indeks_1_3;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Indeks_2_3;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Indeks_3_3;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Mesec4;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Promet_1_4;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Promet_2_4;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Promet_3_4;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Promet_4_4;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Indeks_1_4;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Indeks_2_4;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Indeks_3_4;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Mesec5;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Promet_1_5;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Promet_2_5;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Promet_3_5;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Promet_4_5;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Indeks_1_5;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Indeks_2_5;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Indeks_3_5;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Mesec6;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Promet_1_6;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Promet_2_6;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Promet_3_6;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Promet_4_6;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Indeks_1_6;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Indeks_2_6;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Indeks_3_6;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Mesec7;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Promet_1_7;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Promet_2_7;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Promet_3_7;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Promet_4_7;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Indeks_1_7;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Indeks_2_7;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Indeks_3_7;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Mesec8;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Promet_1_8;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Promet_2_8;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Promet_3_8;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Promet_4_8;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Indeks_1_8;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Indeks_2_8;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Indeks_3_8;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Mesec9;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Promet_1_9;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Promet_2_9;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Promet_3_9;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Promet_4_9;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Indeks_1_9;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Indeks_2_9;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Indeks_3_9;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Mesec10;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Promet_1_10;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Promet_2_10;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Promet_3_10;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Promet_4_10;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Indeks_1_10;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Indeks_2_10;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Indeks_3_10;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Mesec11;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Promet_1_11;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Promet_2_11;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Promet_3_11;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Promet_4_11;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Indeks_1_11;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Indeks_2_11;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Indeks_3_11;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Mesec12;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Promet_1_12;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Promet_2_12;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Promet_3_12;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Promet_4_12;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Indeks_1_12;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Indeks_2_12;
    end;
    with ploscicaTabelaPodatkovOPrometu.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPromet_Indeks_3_12;
    end;
    ploscicaTabelaPodatkovOPrometu.Color := 15461355;
    ploscicaTabelaPodatkovOPrometu.GridLineWidth := 1;
    ploscicaTabelaPodatkovOPrometu.GridLineColor := clGray;
    ploscicaTabelaPodatkovOPrometu.RowCollection.Clear;
    with ploscicaTabelaPodatkovOPrometu.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaTabelaPodatkovOPrometu.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaTabelaPodatkovOPrometu.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaTabelaPodatkovOPrometu.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaTabelaPodatkovOPrometu.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaTabelaPodatkovOPrometu.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaTabelaPodatkovOPrometu.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaTabelaPodatkovOPrometu.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaTabelaPodatkovOPrometu.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaTabelaPodatkovOPrometu.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaTabelaPodatkovOPrometu.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaTabelaPodatkovOPrometu.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaTabelaPodatkovOPrometu.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    ploscicaPromet_Naslov1.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Naslov1.Name := 'ploscicaPromet_Naslov1';
    ploscicaPromet_Naslov1.Left := 2;
    ploscicaPromet_Naslov1.Top := 5;
    ploscicaPromet_Naslov1.Width := 65;
    ploscicaPromet_Naslov1.Height := 15;
    ploscicaPromet_Naslov1.Anchors := [];
    ploscicaPromet_Naslov1.Caption := 'mesec / leto';
    ploscicaPromet_Naslov1.Color := clNone;
    ploscicaPromet_Naslov1.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Naslov1.WidthPercent := 100.000000000000000000;
    ploscicaPromet_Naslov2.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Naslov2.Name := 'ploscicaPromet_Naslov2';
    ploscicaPromet_Naslov2.Left := 164;
    ploscicaPromet_Naslov2.Top := 5;
    ploscicaPromet_Naslov2.Width := 23;
    ploscicaPromet_Naslov2.Height := 15;
    ploscicaPromet_Naslov2.Anchors := [];
    ploscicaPromet_Naslov2.Caption := 'x - 1';
    ploscicaPromet_Naslov2.Color := clNone;
    ploscicaPromet_Naslov2.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Naslov2.WidthPercent := 100.000000000000000000;
    ploscicaPromet_Naslov3.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Naslov3.Name := 'ploscicaPromet_Naslov3';
    ploscicaPromet_Naslov3.Left := 269;
    ploscicaPromet_Naslov3.Top := 5;
    ploscicaPromet_Naslov3.Width := 6;
    ploscicaPromet_Naslov3.Height := 15;
    ploscicaPromet_Naslov3.Anchors := [];
    ploscicaPromet_Naslov3.Caption := 'x';
    ploscicaPromet_Naslov3.Color := clNone;
    ploscicaPromet_Naslov3.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Naslov3.WidthPercent := 100.000000000000000000;
    ploscicaPromet_Naslov4.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Naslov4.Name := 'ploscicaPromet_Naslov4';
    ploscicaPromet_Naslov4.Left := 355;
    ploscicaPromet_Naslov4.Top := 5;
    ploscicaPromet_Naslov4.Width := 26;
    ploscicaPromet_Naslov4.Height := 15;
    ploscicaPromet_Naslov4.Anchors := [];
    ploscicaPromet_Naslov4.Caption := 'x + 1';
    ploscicaPromet_Naslov4.Color := clNone;
    ploscicaPromet_Naslov4.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Naslov4.WidthPercent := 100.000000000000000000;
    ploscicaPromet_Naslov5.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Naslov5.Name := 'ploscicaPromet_Naslov5';
    ploscicaPromet_Naslov5.Left := 451;
    ploscicaPromet_Naslov5.Top := 5;
    ploscicaPromet_Naslov5.Width := 26;
    ploscicaPromet_Naslov5.Height := 15;
    ploscicaPromet_Naslov5.Anchors := [];
    ploscicaPromet_Naslov5.Caption := 'x + 2';
    ploscicaPromet_Naslov5.Color := clNone;
    ploscicaPromet_Naslov5.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Naslov5.WidthPercent := 100.000000000000000000;
    ploscicaPromet_Naslov6.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Naslov6.Name := 'ploscicaPromet_Naslov6';
    ploscicaPromet_Naslov6.Left := 555;
    ploscicaPromet_Naslov6.Top := 5;
    ploscicaPromet_Naslov6.Width := 9;
    ploscicaPromet_Naslov6.Height := 15;
    ploscicaPromet_Naslov6.Anchors := [];
    ploscicaPromet_Naslov6.Caption := 'I1';
    ploscicaPromet_Naslov6.Color := clNone;
    ploscicaPromet_Naslov6.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Naslov6.WidthPercent := 100.000000000000000000;
    ploscicaPromet_Naslov7.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Naslov7.Name := 'ploscicaPromet_Naslov7';
    ploscicaPromet_Naslov7.Left := 651;
    ploscicaPromet_Naslov7.Top := 5;
    ploscicaPromet_Naslov7.Width := 9;
    ploscicaPromet_Naslov7.Height := 15;
    ploscicaPromet_Naslov7.Anchors := [];
    ploscicaPromet_Naslov7.Caption := 'I2';
    ploscicaPromet_Naslov7.Color := clNone;
    ploscicaPromet_Naslov7.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Naslov7.WidthPercent := 100.000000000000000000;
    ploscicaPromet_Naslov8.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Naslov8.Name := 'ploscicaPromet_Naslov8';
    ploscicaPromet_Naslov8.Left := 747;
    ploscicaPromet_Naslov8.Top := 5;
    ploscicaPromet_Naslov8.Width := 9;
    ploscicaPromet_Naslov8.Height := 15;
    ploscicaPromet_Naslov8.Anchors := [];
    ploscicaPromet_Naslov8.Caption := 'I3';
    ploscicaPromet_Naslov8.Color := clNone;
    ploscicaPromet_Naslov8.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Naslov8.WidthPercent := 100.000000000000000000;
    ploscicaPromet_Mesec1.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Mesec1.Name := 'ploscicaPromet_Mesec1';
    ploscicaPromet_Mesec1.Left := 2;
    ploscicaPromet_Mesec1.Top := 30;
    ploscicaPromet_Mesec1.Width := 75;
    ploscicaPromet_Mesec1.Height := 18;
    ploscicaPromet_Mesec1.Anchors := [];
    ploscicaPromet_Mesec1.AutoSize := False;
    ploscicaPromet_Mesec1.Caption := 'januar';
    ploscicaPromet_Mesec1.Color := clNone;
    ploscicaPromet_Mesec1.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Mesec1.WidthPercent := 100.000000000000000000;
    ploscicaPromet_Mesec2.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Mesec2.Name := 'ploscicaPromet_Mesec2';
    ploscicaPromet_Mesec2.Left := 2;
    ploscicaPromet_Mesec2.Top := 56;
    ploscicaPromet_Mesec2.Width := 75;
    ploscicaPromet_Mesec2.Height := 18;
    ploscicaPromet_Mesec2.Anchors := [];
    ploscicaPromet_Mesec2.AutoSize := False;
    ploscicaPromet_Mesec2.Caption := 'februar';
    ploscicaPromet_Mesec2.Color := clNone;
    ploscicaPromet_Mesec2.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Mesec2.WidthPercent := 100.000000000000000000;
    ploscicaPromet_Mesec3.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Mesec3.Name := 'ploscicaPromet_Mesec3';
    ploscicaPromet_Mesec3.Left := 2;
    ploscicaPromet_Mesec3.Top := 82;
    ploscicaPromet_Mesec3.Width := 75;
    ploscicaPromet_Mesec3.Height := 18;
    ploscicaPromet_Mesec3.Anchors := [];
    ploscicaPromet_Mesec3.AutoSize := False;
    ploscicaPromet_Mesec3.Caption := 'marec';
    ploscicaPromet_Mesec3.Color := clNone;
    ploscicaPromet_Mesec3.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Mesec3.WidthPercent := 100.000000000000000000;
    ploscicaPromet_Mesec4.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Mesec4.Name := 'ploscicaPromet_Mesec4';
    ploscicaPromet_Mesec4.Left := 2;
    ploscicaPromet_Mesec4.Top := 108;
    ploscicaPromet_Mesec4.Width := 75;
    ploscicaPromet_Mesec4.Height := 18;
    ploscicaPromet_Mesec4.Anchors := [];
    ploscicaPromet_Mesec4.AutoSize := False;
    ploscicaPromet_Mesec4.Caption := 'april';
    ploscicaPromet_Mesec4.Color := clNone;
    ploscicaPromet_Mesec4.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Mesec4.WidthPercent := 100.000000000000000000;
    ploscicaPromet_Mesec5.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Mesec5.Name := 'ploscicaPromet_Mesec5';
    ploscicaPromet_Mesec5.Left := 2;
    ploscicaPromet_Mesec5.Top := 134;
    ploscicaPromet_Mesec5.Width := 75;
    ploscicaPromet_Mesec5.Height := 18;
    ploscicaPromet_Mesec5.Anchors := [];
    ploscicaPromet_Mesec5.AutoSize := False;
    ploscicaPromet_Mesec5.Caption := 'maj';
    ploscicaPromet_Mesec5.Color := clNone;
    ploscicaPromet_Mesec5.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Mesec5.WidthPercent := 100.000000000000000000;
    ploscicaPromet_Mesec6.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Mesec6.Name := 'ploscicaPromet_Mesec6';
    ploscicaPromet_Mesec6.Left := 2;
    ploscicaPromet_Mesec6.Top := 160;
    ploscicaPromet_Mesec6.Width := 75;
    ploscicaPromet_Mesec6.Height := 18;
    ploscicaPromet_Mesec6.Anchors := [];
    ploscicaPromet_Mesec6.AutoSize := False;
    ploscicaPromet_Mesec6.Caption := 'junij';
    ploscicaPromet_Mesec6.Color := clNone;
    ploscicaPromet_Mesec6.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Mesec6.WidthPercent := 100.000000000000000000;
    ploscicaPromet_Mesec7.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Mesec7.Name := 'ploscicaPromet_Mesec7';
    ploscicaPromet_Mesec7.Left := 2;
    ploscicaPromet_Mesec7.Top := 186;
    ploscicaPromet_Mesec7.Width := 75;
    ploscicaPromet_Mesec7.Height := 18;
    ploscicaPromet_Mesec7.Anchors := [];
    ploscicaPromet_Mesec7.AutoSize := False;
    ploscicaPromet_Mesec7.Caption := 'julij';
    ploscicaPromet_Mesec7.Color := clNone;
    ploscicaPromet_Mesec7.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Mesec7.WidthPercent := 100.000000000000000000;
    ploscicaPromet_Mesec8.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Mesec8.Name := 'ploscicaPromet_Mesec8';
    ploscicaPromet_Mesec8.Left := 2;
    ploscicaPromet_Mesec8.Top := 212;
    ploscicaPromet_Mesec8.Width := 75;
    ploscicaPromet_Mesec8.Height := 18;
    ploscicaPromet_Mesec8.Anchors := [];
    ploscicaPromet_Mesec8.AutoSize := False;
    ploscicaPromet_Mesec8.Caption := 'avgust';
    ploscicaPromet_Mesec8.Color := clNone;
    ploscicaPromet_Mesec8.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Mesec8.WidthPercent := 100.000000000000000000;
    ploscicaPromet_Mesec9.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Mesec9.Name := 'ploscicaPromet_Mesec9';
    ploscicaPromet_Mesec9.Left := 2;
    ploscicaPromet_Mesec9.Top := 238;
    ploscicaPromet_Mesec9.Width := 75;
    ploscicaPromet_Mesec9.Height := 18;
    ploscicaPromet_Mesec9.Anchors := [];
    ploscicaPromet_Mesec9.AutoSize := False;
    ploscicaPromet_Mesec9.Caption := 'september';
    ploscicaPromet_Mesec9.Color := clNone;
    ploscicaPromet_Mesec9.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Mesec9.WidthPercent := 100.000000000000000000;
    ploscicaPromet_Mesec10.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Mesec10.Name := 'ploscicaPromet_Mesec10';
    ploscicaPromet_Mesec10.Left := 2;
    ploscicaPromet_Mesec10.Top := 264;
    ploscicaPromet_Mesec10.Width := 75;
    ploscicaPromet_Mesec10.Height := 18;
    ploscicaPromet_Mesec10.Anchors := [];
    ploscicaPromet_Mesec10.AutoSize := False;
    ploscicaPromet_Mesec10.Caption := 'oktober';
    ploscicaPromet_Mesec10.Color := clNone;
    ploscicaPromet_Mesec10.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Mesec10.WidthPercent := 100.000000000000000000;
    ploscicaPromet_Mesec11.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Mesec11.Name := 'ploscicaPromet_Mesec11';
    ploscicaPromet_Mesec11.Left := 2;
    ploscicaPromet_Mesec11.Top := 290;
    ploscicaPromet_Mesec11.Width := 75;
    ploscicaPromet_Mesec11.Height := 18;
    ploscicaPromet_Mesec11.Anchors := [];
    ploscicaPromet_Mesec11.AutoSize := False;
    ploscicaPromet_Mesec11.Caption := 'november';
    ploscicaPromet_Mesec11.Color := clNone;
    ploscicaPromet_Mesec11.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Mesec11.WidthPercent := 100.000000000000000000;
    ploscicaPromet_Mesec12.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Mesec12.Name := 'ploscicaPromet_Mesec12';
    ploscicaPromet_Mesec12.Left := 2;
    ploscicaPromet_Mesec12.Top := 316;
    ploscicaPromet_Mesec12.Width := 75;
    ploscicaPromet_Mesec12.Height := 18;
    ploscicaPromet_Mesec12.Anchors := [];
    ploscicaPromet_Mesec12.AutoSize := False;
    ploscicaPromet_Mesec12.Caption := 'december';
    ploscicaPromet_Mesec12.Color := clNone;
    ploscicaPromet_Mesec12.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Mesec12.WidthPercent := 100.000000000000000000;
    ploscicaPromet_Promet_1_1.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Promet_1_1.Name := 'ploscicaPromet_Promet_1_1';
    ploscicaPromet_Promet_1_1.Left := 128;
    ploscicaPromet_Promet_1_1.Top := 28;
    ploscicaPromet_Promet_1_1.Width := 96;
    ploscicaPromet_Promet_1_1.Height := 22;
    ploscicaPromet_Promet_1_1.TabStop := False;
    ploscicaPromet_Promet_1_1.Alignment := taRightJustify;
    ploscicaPromet_Promet_1_1.Anchors := [];
    ploscicaPromet_Promet_1_1.AutoSelect := False;
    ploscicaPromet_Promet_1_1.BorderStyle := bsNone;
    ploscicaPromet_Promet_1_1.ChildOrder := 9;
    ploscicaPromet_Promet_1_1.Color := clWhite;
    ploscicaPromet_Promet_1_1.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Promet_1_1.ReadOnly := True;
    ploscicaPromet_Promet_1_1.ShowFocus := False;
    ploscicaPromet_Promet_1_1.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Promet_1_1, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Promet_1_1, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Promet_2_1.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Promet_2_1.Name := 'ploscicaPromet_Promet_2_1';
    ploscicaPromet_Promet_2_1.Left := 224;
    ploscicaPromet_Promet_2_1.Top := 28;
    ploscicaPromet_Promet_2_1.Width := 96;
    ploscicaPromet_Promet_2_1.Height := 22;
    ploscicaPromet_Promet_2_1.Alignment := taRightJustify;
    ploscicaPromet_Promet_2_1.Anchors := [];
    ploscicaPromet_Promet_2_1.BorderStyle := bsNone;
    ploscicaPromet_Promet_2_1.ChildOrder := 10;
    ploscicaPromet_Promet_2_1.Color := clWhite;
    ploscicaPromet_Promet_2_1.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Promet_2_1.TabOrder := 1;
    ploscicaPromet_Promet_2_1.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Promet_2_1, Self, 'OnDblClick', 'ploscicaPromet_Promet_OznacevanjeIzpadaDblClick');
    SetEvent(ploscicaPromet_Promet_2_1, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Promet_2_1, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Promet_3_1.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Promet_3_1.Name := 'ploscicaPromet_Promet_3_1';
    ploscicaPromet_Promet_3_1.Left := 320;
    ploscicaPromet_Promet_3_1.Top := 28;
    ploscicaPromet_Promet_3_1.Width := 96;
    ploscicaPromet_Promet_3_1.Height := 22;
    ploscicaPromet_Promet_3_1.Alignment := taRightJustify;
    ploscicaPromet_Promet_3_1.Anchors := [];
    ploscicaPromet_Promet_3_1.BorderStyle := bsNone;
    ploscicaPromet_Promet_3_1.ChildOrder := 11;
    ploscicaPromet_Promet_3_1.Color := clWhite;
    ploscicaPromet_Promet_3_1.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Promet_3_1.TabOrder := 13;
    ploscicaPromet_Promet_3_1.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Promet_3_1, Self, 'OnDblClick', 'ploscicaPromet_Promet_OznacevanjeIzpadaDblClick');
    SetEvent(ploscicaPromet_Promet_3_1, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Promet_3_1, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Promet_4_1.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Promet_4_1.Name := 'ploscicaPromet_Promet_4_1';
    ploscicaPromet_Promet_4_1.Left := 416;
    ploscicaPromet_Promet_4_1.Top := 28;
    ploscicaPromet_Promet_4_1.Width := 96;
    ploscicaPromet_Promet_4_1.Height := 22;
    ploscicaPromet_Promet_4_1.Alignment := taRightJustify;
    ploscicaPromet_Promet_4_1.Anchors := [];
    ploscicaPromet_Promet_4_1.BorderStyle := bsNone;
    ploscicaPromet_Promet_4_1.ChildOrder := 12;
    ploscicaPromet_Promet_4_1.Color := clWhite;
    ploscicaPromet_Promet_4_1.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Promet_4_1.TabOrder := 25;
    ploscicaPromet_Promet_4_1.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Promet_4_1, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Promet_4_1, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Indeks_1_1.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Indeks_1_1.Name := 'ploscicaPromet_Indeks_1_1';
    ploscicaPromet_Indeks_1_1.Left := 512;
    ploscicaPromet_Indeks_1_1.Top := 28;
    ploscicaPromet_Indeks_1_1.Width := 96;
    ploscicaPromet_Indeks_1_1.Height := 22;
    ploscicaPromet_Indeks_1_1.TabStop := False;
    ploscicaPromet_Indeks_1_1.Alignment := taRightJustify;
    ploscicaPromet_Indeks_1_1.Anchors := [];
    ploscicaPromet_Indeks_1_1.AutoSelect := False;
    ploscicaPromet_Indeks_1_1.BorderStyle := bsNone;
    ploscicaPromet_Indeks_1_1.ChildOrder := 13;
    ploscicaPromet_Indeks_1_1.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Indeks_1_1.ReadOnly := True;
    ploscicaPromet_Indeks_1_1.ShowFocus := False;
    ploscicaPromet_Indeks_1_1.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Indeks_1_1, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Indeks_1_1, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Indeks_2_1.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Indeks_2_1.Name := 'ploscicaPromet_Indeks_2_1';
    ploscicaPromet_Indeks_2_1.Left := 608;
    ploscicaPromet_Indeks_2_1.Top := 28;
    ploscicaPromet_Indeks_2_1.Width := 96;
    ploscicaPromet_Indeks_2_1.Height := 22;
    ploscicaPromet_Indeks_2_1.TabStop := False;
    ploscicaPromet_Indeks_2_1.Alignment := taRightJustify;
    ploscicaPromet_Indeks_2_1.Anchors := [];
    ploscicaPromet_Indeks_2_1.AutoSelect := False;
    ploscicaPromet_Indeks_2_1.BorderStyle := bsNone;
    ploscicaPromet_Indeks_2_1.ChildOrder := 14;
    ploscicaPromet_Indeks_2_1.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Indeks_2_1.ReadOnly := True;
    ploscicaPromet_Indeks_2_1.ShowFocus := False;
    ploscicaPromet_Indeks_2_1.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Indeks_2_1, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Indeks_2_1, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Indeks_3_1.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Indeks_3_1.Name := 'ploscicaPromet_Indeks_3_1';
    ploscicaPromet_Indeks_3_1.Left := 704;
    ploscicaPromet_Indeks_3_1.Top := 28;
    ploscicaPromet_Indeks_3_1.Width := 96;
    ploscicaPromet_Indeks_3_1.Height := 22;
    ploscicaPromet_Indeks_3_1.TabStop := False;
    ploscicaPromet_Indeks_3_1.Alignment := taRightJustify;
    ploscicaPromet_Indeks_3_1.Anchors := [];
    ploscicaPromet_Indeks_3_1.AutoSelect := False;
    ploscicaPromet_Indeks_3_1.BorderStyle := bsNone;
    ploscicaPromet_Indeks_3_1.ChildOrder := 15;
    ploscicaPromet_Indeks_3_1.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Indeks_3_1.ReadOnly := True;
    ploscicaPromet_Indeks_3_1.ShowFocus := False;
    ploscicaPromet_Indeks_3_1.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Indeks_3_1, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Indeks_3_1, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Promet_1_2.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Promet_1_2.Name := 'ploscicaPromet_Promet_1_2';
    ploscicaPromet_Promet_1_2.Left := 128;
    ploscicaPromet_Promet_1_2.Top := 54;
    ploscicaPromet_Promet_1_2.Width := 96;
    ploscicaPromet_Promet_1_2.Height := 22;
    ploscicaPromet_Promet_1_2.TabStop := False;
    ploscicaPromet_Promet_1_2.Alignment := taRightJustify;
    ploscicaPromet_Promet_1_2.Anchors := [];
    ploscicaPromet_Promet_1_2.AutoSelect := False;
    ploscicaPromet_Promet_1_2.BorderStyle := bsNone;
    ploscicaPromet_Promet_1_2.ChildOrder := 17;
    ploscicaPromet_Promet_1_2.Color := clWhite;
    ploscicaPromet_Promet_1_2.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Promet_1_2.ReadOnly := True;
    ploscicaPromet_Promet_1_2.ShowFocus := False;
    ploscicaPromet_Promet_1_2.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Promet_1_2, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Promet_1_2, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Promet_2_2.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Promet_2_2.Name := 'ploscicaPromet_Promet_2_2';
    ploscicaPromet_Promet_2_2.Left := 224;
    ploscicaPromet_Promet_2_2.Top := 54;
    ploscicaPromet_Promet_2_2.Width := 96;
    ploscicaPromet_Promet_2_2.Height := 22;
    ploscicaPromet_Promet_2_2.Alignment := taRightJustify;
    ploscicaPromet_Promet_2_2.Anchors := [];
    ploscicaPromet_Promet_2_2.BorderStyle := bsNone;
    ploscicaPromet_Promet_2_2.ChildOrder := 18;
    ploscicaPromet_Promet_2_2.Color := clWhite;
    ploscicaPromet_Promet_2_2.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Promet_2_2.TabOrder := 2;
    ploscicaPromet_Promet_2_2.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Promet_2_2, Self, 'OnDblClick', 'ploscicaPromet_Promet_OznacevanjeIzpadaDblClick');
    SetEvent(ploscicaPromet_Promet_2_2, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Promet_2_2, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Promet_3_2.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Promet_3_2.Name := 'ploscicaPromet_Promet_3_2';
    ploscicaPromet_Promet_3_2.Left := 320;
    ploscicaPromet_Promet_3_2.Top := 54;
    ploscicaPromet_Promet_3_2.Width := 96;
    ploscicaPromet_Promet_3_2.Height := 22;
    ploscicaPromet_Promet_3_2.Alignment := taRightJustify;
    ploscicaPromet_Promet_3_2.Anchors := [];
    ploscicaPromet_Promet_3_2.BorderStyle := bsNone;
    ploscicaPromet_Promet_3_2.ChildOrder := 19;
    ploscicaPromet_Promet_3_2.Color := clWhite;
    ploscicaPromet_Promet_3_2.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Promet_3_2.TabOrder := 14;
    ploscicaPromet_Promet_3_2.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Promet_3_2, Self, 'OnDblClick', 'ploscicaPromet_Promet_OznacevanjeIzpadaDblClick');
    SetEvent(ploscicaPromet_Promet_3_2, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Promet_3_2, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Promet_4_2.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Promet_4_2.Name := 'ploscicaPromet_Promet_4_2';
    ploscicaPromet_Promet_4_2.Left := 416;
    ploscicaPromet_Promet_4_2.Top := 54;
    ploscicaPromet_Promet_4_2.Width := 96;
    ploscicaPromet_Promet_4_2.Height := 22;
    ploscicaPromet_Promet_4_2.Alignment := taRightJustify;
    ploscicaPromet_Promet_4_2.Anchors := [];
    ploscicaPromet_Promet_4_2.BorderStyle := bsNone;
    ploscicaPromet_Promet_4_2.ChildOrder := 20;
    ploscicaPromet_Promet_4_2.Color := clWhite;
    ploscicaPromet_Promet_4_2.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Promet_4_2.TabOrder := 26;
    ploscicaPromet_Promet_4_2.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Promet_4_2, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Promet_4_2, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Indeks_1_2.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Indeks_1_2.Name := 'ploscicaPromet_Indeks_1_2';
    ploscicaPromet_Indeks_1_2.Left := 512;
    ploscicaPromet_Indeks_1_2.Top := 54;
    ploscicaPromet_Indeks_1_2.Width := 96;
    ploscicaPromet_Indeks_1_2.Height := 22;
    ploscicaPromet_Indeks_1_2.TabStop := False;
    ploscicaPromet_Indeks_1_2.Alignment := taRightJustify;
    ploscicaPromet_Indeks_1_2.Anchors := [];
    ploscicaPromet_Indeks_1_2.AutoSelect := False;
    ploscicaPromet_Indeks_1_2.BorderStyle := bsNone;
    ploscicaPromet_Indeks_1_2.ChildOrder := 21;
    ploscicaPromet_Indeks_1_2.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Indeks_1_2.ReadOnly := True;
    ploscicaPromet_Indeks_1_2.ShowFocus := False;
    ploscicaPromet_Indeks_1_2.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Indeks_1_2, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Indeks_1_2, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Indeks_2_2.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Indeks_2_2.Name := 'ploscicaPromet_Indeks_2_2';
    ploscicaPromet_Indeks_2_2.Left := 608;
    ploscicaPromet_Indeks_2_2.Top := 54;
    ploscicaPromet_Indeks_2_2.Width := 96;
    ploscicaPromet_Indeks_2_2.Height := 22;
    ploscicaPromet_Indeks_2_2.TabStop := False;
    ploscicaPromet_Indeks_2_2.Alignment := taRightJustify;
    ploscicaPromet_Indeks_2_2.Anchors := [];
    ploscicaPromet_Indeks_2_2.AutoSelect := False;
    ploscicaPromet_Indeks_2_2.BorderStyle := bsNone;
    ploscicaPromet_Indeks_2_2.ChildOrder := 22;
    ploscicaPromet_Indeks_2_2.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Indeks_2_2.ReadOnly := True;
    ploscicaPromet_Indeks_2_2.ShowFocus := False;
    ploscicaPromet_Indeks_2_2.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Indeks_2_2, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Indeks_2_2, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Indeks_3_2.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Indeks_3_2.Name := 'ploscicaPromet_Indeks_3_2';
    ploscicaPromet_Indeks_3_2.Left := 704;
    ploscicaPromet_Indeks_3_2.Top := 54;
    ploscicaPromet_Indeks_3_2.Width := 96;
    ploscicaPromet_Indeks_3_2.Height := 22;
    ploscicaPromet_Indeks_3_2.TabStop := False;
    ploscicaPromet_Indeks_3_2.Alignment := taRightJustify;
    ploscicaPromet_Indeks_3_2.Anchors := [];
    ploscicaPromet_Indeks_3_2.AutoSelect := False;
    ploscicaPromet_Indeks_3_2.BorderStyle := bsNone;
    ploscicaPromet_Indeks_3_2.ChildOrder := 23;
    ploscicaPromet_Indeks_3_2.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Indeks_3_2.ReadOnly := True;
    ploscicaPromet_Indeks_3_2.ShowFocus := False;
    ploscicaPromet_Indeks_3_2.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Indeks_3_2, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Indeks_3_2, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Promet_1_3.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Promet_1_3.Name := 'ploscicaPromet_Promet_1_3';
    ploscicaPromet_Promet_1_3.Left := 128;
    ploscicaPromet_Promet_1_3.Top := 80;
    ploscicaPromet_Promet_1_3.Width := 96;
    ploscicaPromet_Promet_1_3.Height := 22;
    ploscicaPromet_Promet_1_3.TabStop := False;
    ploscicaPromet_Promet_1_3.Alignment := taRightJustify;
    ploscicaPromet_Promet_1_3.Anchors := [];
    ploscicaPromet_Promet_1_3.AutoSelect := False;
    ploscicaPromet_Promet_1_3.BorderStyle := bsNone;
    ploscicaPromet_Promet_1_3.ChildOrder := 25;
    ploscicaPromet_Promet_1_3.Color := clWhite;
    ploscicaPromet_Promet_1_3.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Promet_1_3.ReadOnly := True;
    ploscicaPromet_Promet_1_3.ShowFocus := False;
    ploscicaPromet_Promet_1_3.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Promet_1_3, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Promet_1_3, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Promet_2_3.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Promet_2_3.Name := 'ploscicaPromet_Promet_2_3';
    ploscicaPromet_Promet_2_3.Left := 224;
    ploscicaPromet_Promet_2_3.Top := 80;
    ploscicaPromet_Promet_2_3.Width := 96;
    ploscicaPromet_Promet_2_3.Height := 22;
    ploscicaPromet_Promet_2_3.Alignment := taRightJustify;
    ploscicaPromet_Promet_2_3.Anchors := [];
    ploscicaPromet_Promet_2_3.BorderStyle := bsNone;
    ploscicaPromet_Promet_2_3.ChildOrder := 26;
    ploscicaPromet_Promet_2_3.Color := clWhite;
    ploscicaPromet_Promet_2_3.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Promet_2_3.TabOrder := 3;
    ploscicaPromet_Promet_2_3.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Promet_2_3, Self, 'OnDblClick', 'ploscicaPromet_Promet_OznacevanjeIzpadaDblClick');
    SetEvent(ploscicaPromet_Promet_2_3, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Promet_2_3, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Promet_3_3.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Promet_3_3.Name := 'ploscicaPromet_Promet_3_3';
    ploscicaPromet_Promet_3_3.Left := 320;
    ploscicaPromet_Promet_3_3.Top := 80;
    ploscicaPromet_Promet_3_3.Width := 96;
    ploscicaPromet_Promet_3_3.Height := 22;
    ploscicaPromet_Promet_3_3.Alignment := taRightJustify;
    ploscicaPromet_Promet_3_3.Anchors := [];
    ploscicaPromet_Promet_3_3.BorderStyle := bsNone;
    ploscicaPromet_Promet_3_3.ChildOrder := 27;
    ploscicaPromet_Promet_3_3.Color := clWhite;
    ploscicaPromet_Promet_3_3.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Promet_3_3.TabOrder := 15;
    ploscicaPromet_Promet_3_3.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Promet_3_3, Self, 'OnDblClick', 'ploscicaPromet_Promet_OznacevanjeIzpadaDblClick');
    SetEvent(ploscicaPromet_Promet_3_3, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Promet_3_3, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Promet_4_3.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Promet_4_3.Name := 'ploscicaPromet_Promet_4_3';
    ploscicaPromet_Promet_4_3.Left := 416;
    ploscicaPromet_Promet_4_3.Top := 80;
    ploscicaPromet_Promet_4_3.Width := 96;
    ploscicaPromet_Promet_4_3.Height := 22;
    ploscicaPromet_Promet_4_3.Alignment := taRightJustify;
    ploscicaPromet_Promet_4_3.Anchors := [];
    ploscicaPromet_Promet_4_3.BorderStyle := bsNone;
    ploscicaPromet_Promet_4_3.ChildOrder := 28;
    ploscicaPromet_Promet_4_3.Color := clWhite;
    ploscicaPromet_Promet_4_3.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Promet_4_3.TabOrder := 27;
    ploscicaPromet_Promet_4_3.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Promet_4_3, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Promet_4_3, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Indeks_1_3.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Indeks_1_3.Name := 'ploscicaPromet_Indeks_1_3';
    ploscicaPromet_Indeks_1_3.Left := 512;
    ploscicaPromet_Indeks_1_3.Top := 80;
    ploscicaPromet_Indeks_1_3.Width := 96;
    ploscicaPromet_Indeks_1_3.Height := 22;
    ploscicaPromet_Indeks_1_3.TabStop := False;
    ploscicaPromet_Indeks_1_3.Alignment := taRightJustify;
    ploscicaPromet_Indeks_1_3.Anchors := [];
    ploscicaPromet_Indeks_1_3.AutoSelect := False;
    ploscicaPromet_Indeks_1_3.BorderStyle := bsNone;
    ploscicaPromet_Indeks_1_3.ChildOrder := 29;
    ploscicaPromet_Indeks_1_3.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Indeks_1_3.ReadOnly := True;
    ploscicaPromet_Indeks_1_3.ShowFocus := False;
    ploscicaPromet_Indeks_1_3.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Indeks_1_3, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Indeks_1_3, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Indeks_2_3.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Indeks_2_3.Name := 'ploscicaPromet_Indeks_2_3';
    ploscicaPromet_Indeks_2_3.Left := 608;
    ploscicaPromet_Indeks_2_3.Top := 80;
    ploscicaPromet_Indeks_2_3.Width := 96;
    ploscicaPromet_Indeks_2_3.Height := 22;
    ploscicaPromet_Indeks_2_3.TabStop := False;
    ploscicaPromet_Indeks_2_3.Alignment := taRightJustify;
    ploscicaPromet_Indeks_2_3.Anchors := [];
    ploscicaPromet_Indeks_2_3.AutoSelect := False;
    ploscicaPromet_Indeks_2_3.BorderStyle := bsNone;
    ploscicaPromet_Indeks_2_3.ChildOrder := 30;
    ploscicaPromet_Indeks_2_3.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Indeks_2_3.ReadOnly := True;
    ploscicaPromet_Indeks_2_3.ShowFocus := False;
    ploscicaPromet_Indeks_2_3.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Indeks_2_3, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Indeks_2_3, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Indeks_3_3.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Indeks_3_3.Name := 'ploscicaPromet_Indeks_3_3';
    ploscicaPromet_Indeks_3_3.Left := 704;
    ploscicaPromet_Indeks_3_3.Top := 80;
    ploscicaPromet_Indeks_3_3.Width := 96;
    ploscicaPromet_Indeks_3_3.Height := 22;
    ploscicaPromet_Indeks_3_3.TabStop := False;
    ploscicaPromet_Indeks_3_3.Alignment := taRightJustify;
    ploscicaPromet_Indeks_3_3.Anchors := [];
    ploscicaPromet_Indeks_3_3.AutoSelect := False;
    ploscicaPromet_Indeks_3_3.BorderStyle := bsNone;
    ploscicaPromet_Indeks_3_3.ChildOrder := 31;
    ploscicaPromet_Indeks_3_3.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Indeks_3_3.ReadOnly := True;
    ploscicaPromet_Indeks_3_3.ShowFocus := False;
    ploscicaPromet_Indeks_3_3.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Indeks_3_3, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Indeks_3_3, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Promet_1_4.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Promet_1_4.Name := 'ploscicaPromet_Promet_1_4';
    ploscicaPromet_Promet_1_4.Left := 128;
    ploscicaPromet_Promet_1_4.Top := 106;
    ploscicaPromet_Promet_1_4.Width := 96;
    ploscicaPromet_Promet_1_4.Height := 22;
    ploscicaPromet_Promet_1_4.TabStop := False;
    ploscicaPromet_Promet_1_4.Alignment := taRightJustify;
    ploscicaPromet_Promet_1_4.Anchors := [];
    ploscicaPromet_Promet_1_4.AutoSelect := False;
    ploscicaPromet_Promet_1_4.BorderStyle := bsNone;
    ploscicaPromet_Promet_1_4.ChildOrder := 33;
    ploscicaPromet_Promet_1_4.Color := clWhite;
    ploscicaPromet_Promet_1_4.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Promet_1_4.ReadOnly := True;
    ploscicaPromet_Promet_1_4.ShowFocus := False;
    ploscicaPromet_Promet_1_4.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Promet_1_4, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Promet_1_4, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Promet_2_4.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Promet_2_4.Name := 'ploscicaPromet_Promet_2_4';
    ploscicaPromet_Promet_2_4.Left := 224;
    ploscicaPromet_Promet_2_4.Top := 106;
    ploscicaPromet_Promet_2_4.Width := 96;
    ploscicaPromet_Promet_2_4.Height := 22;
    ploscicaPromet_Promet_2_4.Alignment := taRightJustify;
    ploscicaPromet_Promet_2_4.Anchors := [];
    ploscicaPromet_Promet_2_4.BorderStyle := bsNone;
    ploscicaPromet_Promet_2_4.ChildOrder := 34;
    ploscicaPromet_Promet_2_4.Color := clWhite;
    ploscicaPromet_Promet_2_4.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Promet_2_4.TabOrder := 4;
    ploscicaPromet_Promet_2_4.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Promet_2_4, Self, 'OnDblClick', 'ploscicaPromet_Promet_OznacevanjeIzpadaDblClick');
    SetEvent(ploscicaPromet_Promet_2_4, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Promet_2_4, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Promet_3_4.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Promet_3_4.Name := 'ploscicaPromet_Promet_3_4';
    ploscicaPromet_Promet_3_4.Left := 320;
    ploscicaPromet_Promet_3_4.Top := 106;
    ploscicaPromet_Promet_3_4.Width := 96;
    ploscicaPromet_Promet_3_4.Height := 22;
    ploscicaPromet_Promet_3_4.Alignment := taRightJustify;
    ploscicaPromet_Promet_3_4.Anchors := [];
    ploscicaPromet_Promet_3_4.BorderStyle := bsNone;
    ploscicaPromet_Promet_3_4.ChildOrder := 35;
    ploscicaPromet_Promet_3_4.Color := clWhite;
    ploscicaPromet_Promet_3_4.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Promet_3_4.TabOrder := 16;
    ploscicaPromet_Promet_3_4.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Promet_3_4, Self, 'OnDblClick', 'ploscicaPromet_Promet_OznacevanjeIzpadaDblClick');
    SetEvent(ploscicaPromet_Promet_3_4, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Promet_3_4, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Promet_4_4.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Promet_4_4.Name := 'ploscicaPromet_Promet_4_4';
    ploscicaPromet_Promet_4_4.Left := 416;
    ploscicaPromet_Promet_4_4.Top := 106;
    ploscicaPromet_Promet_4_4.Width := 96;
    ploscicaPromet_Promet_4_4.Height := 22;
    ploscicaPromet_Promet_4_4.Alignment := taRightJustify;
    ploscicaPromet_Promet_4_4.Anchors := [];
    ploscicaPromet_Promet_4_4.BorderStyle := bsNone;
    ploscicaPromet_Promet_4_4.ChildOrder := 36;
    ploscicaPromet_Promet_4_4.Color := clWhite;
    ploscicaPromet_Promet_4_4.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Promet_4_4.TabOrder := 28;
    ploscicaPromet_Promet_4_4.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Promet_4_4, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Promet_4_4, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Indeks_1_4.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Indeks_1_4.Name := 'ploscicaPromet_Indeks_1_4';
    ploscicaPromet_Indeks_1_4.Left := 512;
    ploscicaPromet_Indeks_1_4.Top := 106;
    ploscicaPromet_Indeks_1_4.Width := 96;
    ploscicaPromet_Indeks_1_4.Height := 22;
    ploscicaPromet_Indeks_1_4.TabStop := False;
    ploscicaPromet_Indeks_1_4.Alignment := taRightJustify;
    ploscicaPromet_Indeks_1_4.Anchors := [];
    ploscicaPromet_Indeks_1_4.AutoSelect := False;
    ploscicaPromet_Indeks_1_4.BorderStyle := bsNone;
    ploscicaPromet_Indeks_1_4.ChildOrder := 37;
    ploscicaPromet_Indeks_1_4.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Indeks_1_4.ReadOnly := True;
    ploscicaPromet_Indeks_1_4.ShowFocus := False;
    ploscicaPromet_Indeks_1_4.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Indeks_1_4, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Indeks_1_4, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Indeks_2_4.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Indeks_2_4.Name := 'ploscicaPromet_Indeks_2_4';
    ploscicaPromet_Indeks_2_4.Left := 608;
    ploscicaPromet_Indeks_2_4.Top := 106;
    ploscicaPromet_Indeks_2_4.Width := 96;
    ploscicaPromet_Indeks_2_4.Height := 22;
    ploscicaPromet_Indeks_2_4.TabStop := False;
    ploscicaPromet_Indeks_2_4.Alignment := taRightJustify;
    ploscicaPromet_Indeks_2_4.Anchors := [];
    ploscicaPromet_Indeks_2_4.AutoSelect := False;
    ploscicaPromet_Indeks_2_4.BorderStyle := bsNone;
    ploscicaPromet_Indeks_2_4.ChildOrder := 38;
    ploscicaPromet_Indeks_2_4.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Indeks_2_4.ReadOnly := True;
    ploscicaPromet_Indeks_2_4.ShowFocus := False;
    ploscicaPromet_Indeks_2_4.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Indeks_2_4, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Indeks_2_4, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Indeks_3_4.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Indeks_3_4.Name := 'ploscicaPromet_Indeks_3_4';
    ploscicaPromet_Indeks_3_4.Left := 704;
    ploscicaPromet_Indeks_3_4.Top := 106;
    ploscicaPromet_Indeks_3_4.Width := 96;
    ploscicaPromet_Indeks_3_4.Height := 22;
    ploscicaPromet_Indeks_3_4.TabStop := False;
    ploscicaPromet_Indeks_3_4.Alignment := taRightJustify;
    ploscicaPromet_Indeks_3_4.Anchors := [];
    ploscicaPromet_Indeks_3_4.AutoSelect := False;
    ploscicaPromet_Indeks_3_4.BorderStyle := bsNone;
    ploscicaPromet_Indeks_3_4.ChildOrder := 39;
    ploscicaPromet_Indeks_3_4.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Indeks_3_4.ReadOnly := True;
    ploscicaPromet_Indeks_3_4.ShowFocus := False;
    ploscicaPromet_Indeks_3_4.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Indeks_3_4, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Indeks_3_4, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Promet_1_5.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Promet_1_5.Name := 'ploscicaPromet_Promet_1_5';
    ploscicaPromet_Promet_1_5.Left := 128;
    ploscicaPromet_Promet_1_5.Top := 132;
    ploscicaPromet_Promet_1_5.Width := 96;
    ploscicaPromet_Promet_1_5.Height := 22;
    ploscicaPromet_Promet_1_5.TabStop := False;
    ploscicaPromet_Promet_1_5.Alignment := taRightJustify;
    ploscicaPromet_Promet_1_5.Anchors := [];
    ploscicaPromet_Promet_1_5.AutoSelect := False;
    ploscicaPromet_Promet_1_5.BorderStyle := bsNone;
    ploscicaPromet_Promet_1_5.ChildOrder := 41;
    ploscicaPromet_Promet_1_5.Color := clWhite;
    ploscicaPromet_Promet_1_5.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Promet_1_5.ReadOnly := True;
    ploscicaPromet_Promet_1_5.ShowFocus := False;
    ploscicaPromet_Promet_1_5.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Promet_1_5, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Promet_1_5, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Promet_2_5.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Promet_2_5.Name := 'ploscicaPromet_Promet_2_5';
    ploscicaPromet_Promet_2_5.Left := 224;
    ploscicaPromet_Promet_2_5.Top := 132;
    ploscicaPromet_Promet_2_5.Width := 96;
    ploscicaPromet_Promet_2_5.Height := 22;
    ploscicaPromet_Promet_2_5.Alignment := taRightJustify;
    ploscicaPromet_Promet_2_5.Anchors := [];
    ploscicaPromet_Promet_2_5.BorderStyle := bsNone;
    ploscicaPromet_Promet_2_5.ChildOrder := 42;
    ploscicaPromet_Promet_2_5.Color := clWhite;
    ploscicaPromet_Promet_2_5.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Promet_2_5.TabOrder := 5;
    ploscicaPromet_Promet_2_5.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Promet_2_5, Self, 'OnDblClick', 'ploscicaPromet_Promet_OznacevanjeIzpadaDblClick');
    SetEvent(ploscicaPromet_Promet_2_5, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Promet_2_5, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Promet_3_5.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Promet_3_5.Name := 'ploscicaPromet_Promet_3_5';
    ploscicaPromet_Promet_3_5.Left := 320;
    ploscicaPromet_Promet_3_5.Top := 132;
    ploscicaPromet_Promet_3_5.Width := 96;
    ploscicaPromet_Promet_3_5.Height := 22;
    ploscicaPromet_Promet_3_5.Alignment := taRightJustify;
    ploscicaPromet_Promet_3_5.Anchors := [];
    ploscicaPromet_Promet_3_5.BorderStyle := bsNone;
    ploscicaPromet_Promet_3_5.ChildOrder := 43;
    ploscicaPromet_Promet_3_5.Color := clWhite;
    ploscicaPromet_Promet_3_5.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Promet_3_5.TabOrder := 17;
    ploscicaPromet_Promet_3_5.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Promet_3_5, Self, 'OnDblClick', 'ploscicaPromet_Promet_OznacevanjeIzpadaDblClick');
    SetEvent(ploscicaPromet_Promet_3_5, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Promet_3_5, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Promet_4_5.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Promet_4_5.Name := 'ploscicaPromet_Promet_4_5';
    ploscicaPromet_Promet_4_5.Left := 416;
    ploscicaPromet_Promet_4_5.Top := 132;
    ploscicaPromet_Promet_4_5.Width := 96;
    ploscicaPromet_Promet_4_5.Height := 22;
    ploscicaPromet_Promet_4_5.Alignment := taRightJustify;
    ploscicaPromet_Promet_4_5.Anchors := [];
    ploscicaPromet_Promet_4_5.BorderStyle := bsNone;
    ploscicaPromet_Promet_4_5.ChildOrder := 44;
    ploscicaPromet_Promet_4_5.Color := clWhite;
    ploscicaPromet_Promet_4_5.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Promet_4_5.TabOrder := 29;
    ploscicaPromet_Promet_4_5.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Promet_4_5, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Promet_4_5, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Indeks_1_5.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Indeks_1_5.Name := 'ploscicaPromet_Indeks_1_5';
    ploscicaPromet_Indeks_1_5.Left := 512;
    ploscicaPromet_Indeks_1_5.Top := 132;
    ploscicaPromet_Indeks_1_5.Width := 96;
    ploscicaPromet_Indeks_1_5.Height := 22;
    ploscicaPromet_Indeks_1_5.TabStop := False;
    ploscicaPromet_Indeks_1_5.Alignment := taRightJustify;
    ploscicaPromet_Indeks_1_5.Anchors := [];
    ploscicaPromet_Indeks_1_5.AutoSelect := False;
    ploscicaPromet_Indeks_1_5.BorderStyle := bsNone;
    ploscicaPromet_Indeks_1_5.ChildOrder := 45;
    ploscicaPromet_Indeks_1_5.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Indeks_1_5.ReadOnly := True;
    ploscicaPromet_Indeks_1_5.ShowFocus := False;
    ploscicaPromet_Indeks_1_5.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Indeks_1_5, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Indeks_1_5, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Indeks_2_5.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Indeks_2_5.Name := 'ploscicaPromet_Indeks_2_5';
    ploscicaPromet_Indeks_2_5.Left := 608;
    ploscicaPromet_Indeks_2_5.Top := 132;
    ploscicaPromet_Indeks_2_5.Width := 96;
    ploscicaPromet_Indeks_2_5.Height := 22;
    ploscicaPromet_Indeks_2_5.TabStop := False;
    ploscicaPromet_Indeks_2_5.Alignment := taRightJustify;
    ploscicaPromet_Indeks_2_5.Anchors := [];
    ploscicaPromet_Indeks_2_5.AutoSelect := False;
    ploscicaPromet_Indeks_2_5.BorderStyle := bsNone;
    ploscicaPromet_Indeks_2_5.ChildOrder := 46;
    ploscicaPromet_Indeks_2_5.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Indeks_2_5.ReadOnly := True;
    ploscicaPromet_Indeks_2_5.ShowFocus := False;
    ploscicaPromet_Indeks_2_5.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Indeks_2_5, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Indeks_2_5, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Indeks_3_5.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Indeks_3_5.Name := 'ploscicaPromet_Indeks_3_5';
    ploscicaPromet_Indeks_3_5.Left := 704;
    ploscicaPromet_Indeks_3_5.Top := 132;
    ploscicaPromet_Indeks_3_5.Width := 96;
    ploscicaPromet_Indeks_3_5.Height := 22;
    ploscicaPromet_Indeks_3_5.TabStop := False;
    ploscicaPromet_Indeks_3_5.Alignment := taRightJustify;
    ploscicaPromet_Indeks_3_5.Anchors := [];
    ploscicaPromet_Indeks_3_5.AutoSelect := False;
    ploscicaPromet_Indeks_3_5.BorderStyle := bsNone;
    ploscicaPromet_Indeks_3_5.ChildOrder := 47;
    ploscicaPromet_Indeks_3_5.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Indeks_3_5.ReadOnly := True;
    ploscicaPromet_Indeks_3_5.ShowFocus := False;
    ploscicaPromet_Indeks_3_5.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Indeks_3_5, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Indeks_3_5, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Promet_1_6.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Promet_1_6.Name := 'ploscicaPromet_Promet_1_6';
    ploscicaPromet_Promet_1_6.Left := 128;
    ploscicaPromet_Promet_1_6.Top := 158;
    ploscicaPromet_Promet_1_6.Width := 96;
    ploscicaPromet_Promet_1_6.Height := 22;
    ploscicaPromet_Promet_1_6.TabStop := False;
    ploscicaPromet_Promet_1_6.Alignment := taRightJustify;
    ploscicaPromet_Promet_1_6.Anchors := [];
    ploscicaPromet_Promet_1_6.AutoSelect := False;
    ploscicaPromet_Promet_1_6.BorderStyle := bsNone;
    ploscicaPromet_Promet_1_6.ChildOrder := 49;
    ploscicaPromet_Promet_1_6.Color := clWhite;
    ploscicaPromet_Promet_1_6.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Promet_1_6.ReadOnly := True;
    ploscicaPromet_Promet_1_6.ShowFocus := False;
    ploscicaPromet_Promet_1_6.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Promet_1_6, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Promet_1_6, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Promet_2_6.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Promet_2_6.Name := 'ploscicaPromet_Promet_2_6';
    ploscicaPromet_Promet_2_6.Left := 224;
    ploscicaPromet_Promet_2_6.Top := 158;
    ploscicaPromet_Promet_2_6.Width := 96;
    ploscicaPromet_Promet_2_6.Height := 22;
    ploscicaPromet_Promet_2_6.Alignment := taRightJustify;
    ploscicaPromet_Promet_2_6.Anchors := [];
    ploscicaPromet_Promet_2_6.BorderStyle := bsNone;
    ploscicaPromet_Promet_2_6.ChildOrder := 50;
    ploscicaPromet_Promet_2_6.Color := clWhite;
    ploscicaPromet_Promet_2_6.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Promet_2_6.TabOrder := 6;
    ploscicaPromet_Promet_2_6.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Promet_2_6, Self, 'OnDblClick', 'ploscicaPromet_Promet_OznacevanjeIzpadaDblClick');
    SetEvent(ploscicaPromet_Promet_2_6, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Promet_2_6, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Promet_3_6.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Promet_3_6.Name := 'ploscicaPromet_Promet_3_6';
    ploscicaPromet_Promet_3_6.Left := 320;
    ploscicaPromet_Promet_3_6.Top := 158;
    ploscicaPromet_Promet_3_6.Width := 96;
    ploscicaPromet_Promet_3_6.Height := 22;
    ploscicaPromet_Promet_3_6.Alignment := taRightJustify;
    ploscicaPromet_Promet_3_6.Anchors := [];
    ploscicaPromet_Promet_3_6.BorderStyle := bsNone;
    ploscicaPromet_Promet_3_6.ChildOrder := 51;
    ploscicaPromet_Promet_3_6.Color := clWhite;
    ploscicaPromet_Promet_3_6.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Promet_3_6.TabOrder := 18;
    ploscicaPromet_Promet_3_6.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Promet_3_6, Self, 'OnDblClick', 'ploscicaPromet_Promet_OznacevanjeIzpadaDblClick');
    SetEvent(ploscicaPromet_Promet_3_6, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Promet_3_6, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Promet_4_6.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Promet_4_6.Name := 'ploscicaPromet_Promet_4_6';
    ploscicaPromet_Promet_4_6.Left := 416;
    ploscicaPromet_Promet_4_6.Top := 158;
    ploscicaPromet_Promet_4_6.Width := 96;
    ploscicaPromet_Promet_4_6.Height := 22;
    ploscicaPromet_Promet_4_6.Alignment := taRightJustify;
    ploscicaPromet_Promet_4_6.Anchors := [];
    ploscicaPromet_Promet_4_6.BorderStyle := bsNone;
    ploscicaPromet_Promet_4_6.ChildOrder := 52;
    ploscicaPromet_Promet_4_6.Color := clWhite;
    ploscicaPromet_Promet_4_6.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Promet_4_6.TabOrder := 30;
    ploscicaPromet_Promet_4_6.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Promet_4_6, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Promet_4_6, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Indeks_1_6.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Indeks_1_6.Name := 'ploscicaPromet_Indeks_1_6';
    ploscicaPromet_Indeks_1_6.Left := 512;
    ploscicaPromet_Indeks_1_6.Top := 158;
    ploscicaPromet_Indeks_1_6.Width := 96;
    ploscicaPromet_Indeks_1_6.Height := 22;
    ploscicaPromet_Indeks_1_6.TabStop := False;
    ploscicaPromet_Indeks_1_6.Alignment := taRightJustify;
    ploscicaPromet_Indeks_1_6.Anchors := [];
    ploscicaPromet_Indeks_1_6.AutoSelect := False;
    ploscicaPromet_Indeks_1_6.BorderStyle := bsNone;
    ploscicaPromet_Indeks_1_6.ChildOrder := 53;
    ploscicaPromet_Indeks_1_6.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Indeks_1_6.ReadOnly := True;
    ploscicaPromet_Indeks_1_6.ShowFocus := False;
    ploscicaPromet_Indeks_1_6.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Indeks_1_6, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Indeks_1_6, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Indeks_2_6.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Indeks_2_6.Name := 'ploscicaPromet_Indeks_2_6';
    ploscicaPromet_Indeks_2_6.Left := 608;
    ploscicaPromet_Indeks_2_6.Top := 158;
    ploscicaPromet_Indeks_2_6.Width := 96;
    ploscicaPromet_Indeks_2_6.Height := 22;
    ploscicaPromet_Indeks_2_6.TabStop := False;
    ploscicaPromet_Indeks_2_6.Alignment := taRightJustify;
    ploscicaPromet_Indeks_2_6.Anchors := [];
    ploscicaPromet_Indeks_2_6.AutoSelect := False;
    ploscicaPromet_Indeks_2_6.BorderStyle := bsNone;
    ploscicaPromet_Indeks_2_6.ChildOrder := 54;
    ploscicaPromet_Indeks_2_6.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Indeks_2_6.ReadOnly := True;
    ploscicaPromet_Indeks_2_6.ShowFocus := False;
    ploscicaPromet_Indeks_2_6.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Indeks_2_6, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Indeks_2_6, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Indeks_3_6.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Indeks_3_6.Name := 'ploscicaPromet_Indeks_3_6';
    ploscicaPromet_Indeks_3_6.Left := 704;
    ploscicaPromet_Indeks_3_6.Top := 158;
    ploscicaPromet_Indeks_3_6.Width := 96;
    ploscicaPromet_Indeks_3_6.Height := 22;
    ploscicaPromet_Indeks_3_6.TabStop := False;
    ploscicaPromet_Indeks_3_6.Alignment := taRightJustify;
    ploscicaPromet_Indeks_3_6.Anchors := [];
    ploscicaPromet_Indeks_3_6.AutoSelect := False;
    ploscicaPromet_Indeks_3_6.BorderStyle := bsNone;
    ploscicaPromet_Indeks_3_6.ChildOrder := 55;
    ploscicaPromet_Indeks_3_6.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Indeks_3_6.ReadOnly := True;
    ploscicaPromet_Indeks_3_6.ShowFocus := False;
    ploscicaPromet_Indeks_3_6.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Indeks_3_6, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Indeks_3_6, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Promet_1_7.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Promet_1_7.Name := 'ploscicaPromet_Promet_1_7';
    ploscicaPromet_Promet_1_7.Left := 128;
    ploscicaPromet_Promet_1_7.Top := 184;
    ploscicaPromet_Promet_1_7.Width := 96;
    ploscicaPromet_Promet_1_7.Height := 22;
    ploscicaPromet_Promet_1_7.TabStop := False;
    ploscicaPromet_Promet_1_7.Alignment := taRightJustify;
    ploscicaPromet_Promet_1_7.Anchors := [];
    ploscicaPromet_Promet_1_7.AutoSelect := False;
    ploscicaPromet_Promet_1_7.BorderStyle := bsNone;
    ploscicaPromet_Promet_1_7.ChildOrder := 57;
    ploscicaPromet_Promet_1_7.Color := clWhite;
    ploscicaPromet_Promet_1_7.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Promet_1_7.ReadOnly := True;
    ploscicaPromet_Promet_1_7.ShowFocus := False;
    ploscicaPromet_Promet_1_7.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Promet_1_7, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Promet_1_7, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Promet_2_7.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Promet_2_7.Name := 'ploscicaPromet_Promet_2_7';
    ploscicaPromet_Promet_2_7.Left := 224;
    ploscicaPromet_Promet_2_7.Top := 184;
    ploscicaPromet_Promet_2_7.Width := 96;
    ploscicaPromet_Promet_2_7.Height := 22;
    ploscicaPromet_Promet_2_7.Alignment := taRightJustify;
    ploscicaPromet_Promet_2_7.Anchors := [];
    ploscicaPromet_Promet_2_7.BorderStyle := bsNone;
    ploscicaPromet_Promet_2_7.ChildOrder := 58;
    ploscicaPromet_Promet_2_7.Color := clWhite;
    ploscicaPromet_Promet_2_7.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Promet_2_7.TabOrder := 7;
    ploscicaPromet_Promet_2_7.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Promet_2_7, Self, 'OnDblClick', 'ploscicaPromet_Promet_OznacevanjeIzpadaDblClick');
    SetEvent(ploscicaPromet_Promet_2_7, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Promet_2_7, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Promet_3_7.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Promet_3_7.Name := 'ploscicaPromet_Promet_3_7';
    ploscicaPromet_Promet_3_7.Left := 320;
    ploscicaPromet_Promet_3_7.Top := 184;
    ploscicaPromet_Promet_3_7.Width := 96;
    ploscicaPromet_Promet_3_7.Height := 22;
    ploscicaPromet_Promet_3_7.Alignment := taRightJustify;
    ploscicaPromet_Promet_3_7.Anchors := [];
    ploscicaPromet_Promet_3_7.BorderStyle := bsNone;
    ploscicaPromet_Promet_3_7.ChildOrder := 59;
    ploscicaPromet_Promet_3_7.Color := clWhite;
    ploscicaPromet_Promet_3_7.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Promet_3_7.TabOrder := 19;
    ploscicaPromet_Promet_3_7.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Promet_3_7, Self, 'OnDblClick', 'ploscicaPromet_Promet_OznacevanjeIzpadaDblClick');
    SetEvent(ploscicaPromet_Promet_3_7, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Promet_3_7, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Promet_4_7.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Promet_4_7.Name := 'ploscicaPromet_Promet_4_7';
    ploscicaPromet_Promet_4_7.Left := 416;
    ploscicaPromet_Promet_4_7.Top := 184;
    ploscicaPromet_Promet_4_7.Width := 96;
    ploscicaPromet_Promet_4_7.Height := 22;
    ploscicaPromet_Promet_4_7.Alignment := taRightJustify;
    ploscicaPromet_Promet_4_7.Anchors := [];
    ploscicaPromet_Promet_4_7.BorderStyle := bsNone;
    ploscicaPromet_Promet_4_7.ChildOrder := 60;
    ploscicaPromet_Promet_4_7.Color := clWhite;
    ploscicaPromet_Promet_4_7.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Promet_4_7.TabOrder := 31;
    ploscicaPromet_Promet_4_7.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Promet_4_7, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Promet_4_7, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Indeks_1_7.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Indeks_1_7.Name := 'ploscicaPromet_Indeks_1_7';
    ploscicaPromet_Indeks_1_7.Left := 512;
    ploscicaPromet_Indeks_1_7.Top := 184;
    ploscicaPromet_Indeks_1_7.Width := 96;
    ploscicaPromet_Indeks_1_7.Height := 22;
    ploscicaPromet_Indeks_1_7.TabStop := False;
    ploscicaPromet_Indeks_1_7.Alignment := taRightJustify;
    ploscicaPromet_Indeks_1_7.Anchors := [];
    ploscicaPromet_Indeks_1_7.AutoSelect := False;
    ploscicaPromet_Indeks_1_7.BorderStyle := bsNone;
    ploscicaPromet_Indeks_1_7.ChildOrder := 61;
    ploscicaPromet_Indeks_1_7.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Indeks_1_7.ReadOnly := True;
    ploscicaPromet_Indeks_1_7.ShowFocus := False;
    ploscicaPromet_Indeks_1_7.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Indeks_1_7, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Indeks_1_7, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Indeks_2_7.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Indeks_2_7.Name := 'ploscicaPromet_Indeks_2_7';
    ploscicaPromet_Indeks_2_7.Left := 608;
    ploscicaPromet_Indeks_2_7.Top := 184;
    ploscicaPromet_Indeks_2_7.Width := 96;
    ploscicaPromet_Indeks_2_7.Height := 22;
    ploscicaPromet_Indeks_2_7.TabStop := False;
    ploscicaPromet_Indeks_2_7.Alignment := taRightJustify;
    ploscicaPromet_Indeks_2_7.Anchors := [];
    ploscicaPromet_Indeks_2_7.AutoSelect := False;
    ploscicaPromet_Indeks_2_7.BorderStyle := bsNone;
    ploscicaPromet_Indeks_2_7.ChildOrder := 62;
    ploscicaPromet_Indeks_2_7.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Indeks_2_7.ReadOnly := True;
    ploscicaPromet_Indeks_2_7.ShowFocus := False;
    ploscicaPromet_Indeks_2_7.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Indeks_2_7, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Indeks_2_7, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Indeks_3_7.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Indeks_3_7.Name := 'ploscicaPromet_Indeks_3_7';
    ploscicaPromet_Indeks_3_7.Left := 704;
    ploscicaPromet_Indeks_3_7.Top := 184;
    ploscicaPromet_Indeks_3_7.Width := 96;
    ploscicaPromet_Indeks_3_7.Height := 22;
    ploscicaPromet_Indeks_3_7.TabStop := False;
    ploscicaPromet_Indeks_3_7.Alignment := taRightJustify;
    ploscicaPromet_Indeks_3_7.Anchors := [];
    ploscicaPromet_Indeks_3_7.AutoSelect := False;
    ploscicaPromet_Indeks_3_7.BorderStyle := bsNone;
    ploscicaPromet_Indeks_3_7.ChildOrder := 63;
    ploscicaPromet_Indeks_3_7.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Indeks_3_7.ReadOnly := True;
    ploscicaPromet_Indeks_3_7.ShowFocus := False;
    ploscicaPromet_Indeks_3_7.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Indeks_3_7, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Indeks_3_7, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Promet_1_8.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Promet_1_8.Name := 'ploscicaPromet_Promet_1_8';
    ploscicaPromet_Promet_1_8.Left := 128;
    ploscicaPromet_Promet_1_8.Top := 210;
    ploscicaPromet_Promet_1_8.Width := 96;
    ploscicaPromet_Promet_1_8.Height := 22;
    ploscicaPromet_Promet_1_8.TabStop := False;
    ploscicaPromet_Promet_1_8.Alignment := taRightJustify;
    ploscicaPromet_Promet_1_8.Anchors := [];
    ploscicaPromet_Promet_1_8.AutoSelect := False;
    ploscicaPromet_Promet_1_8.BorderStyle := bsNone;
    ploscicaPromet_Promet_1_8.ChildOrder := 65;
    ploscicaPromet_Promet_1_8.Color := clWhite;
    ploscicaPromet_Promet_1_8.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Promet_1_8.ReadOnly := True;
    ploscicaPromet_Promet_1_8.ShowFocus := False;
    ploscicaPromet_Promet_1_8.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Promet_1_8, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Promet_1_8, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Promet_2_8.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Promet_2_8.Name := 'ploscicaPromet_Promet_2_8';
    ploscicaPromet_Promet_2_8.Left := 224;
    ploscicaPromet_Promet_2_8.Top := 210;
    ploscicaPromet_Promet_2_8.Width := 96;
    ploscicaPromet_Promet_2_8.Height := 22;
    ploscicaPromet_Promet_2_8.Alignment := taRightJustify;
    ploscicaPromet_Promet_2_8.Anchors := [];
    ploscicaPromet_Promet_2_8.BorderStyle := bsNone;
    ploscicaPromet_Promet_2_8.ChildOrder := 66;
    ploscicaPromet_Promet_2_8.Color := clWhite;
    ploscicaPromet_Promet_2_8.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Promet_2_8.TabOrder := 8;
    ploscicaPromet_Promet_2_8.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Promet_2_8, Self, 'OnDblClick', 'ploscicaPromet_Promet_OznacevanjeIzpadaDblClick');
    SetEvent(ploscicaPromet_Promet_2_8, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Promet_2_8, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Promet_3_8.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Promet_3_8.Name := 'ploscicaPromet_Promet_3_8';
    ploscicaPromet_Promet_3_8.Left := 320;
    ploscicaPromet_Promet_3_8.Top := 210;
    ploscicaPromet_Promet_3_8.Width := 96;
    ploscicaPromet_Promet_3_8.Height := 22;
    ploscicaPromet_Promet_3_8.Alignment := taRightJustify;
    ploscicaPromet_Promet_3_8.Anchors := [];
    ploscicaPromet_Promet_3_8.BorderStyle := bsNone;
    ploscicaPromet_Promet_3_8.ChildOrder := 67;
    ploscicaPromet_Promet_3_8.Color := clWhite;
    ploscicaPromet_Promet_3_8.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Promet_3_8.TabOrder := 20;
    ploscicaPromet_Promet_3_8.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Promet_3_8, Self, 'OnDblClick', 'ploscicaPromet_Promet_OznacevanjeIzpadaDblClick');
    SetEvent(ploscicaPromet_Promet_3_8, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Promet_3_8, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Promet_4_8.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Promet_4_8.Name := 'ploscicaPromet_Promet_4_8';
    ploscicaPromet_Promet_4_8.Left := 416;
    ploscicaPromet_Promet_4_8.Top := 210;
    ploscicaPromet_Promet_4_8.Width := 96;
    ploscicaPromet_Promet_4_8.Height := 22;
    ploscicaPromet_Promet_4_8.Alignment := taRightJustify;
    ploscicaPromet_Promet_4_8.Anchors := [];
    ploscicaPromet_Promet_4_8.BorderStyle := bsNone;
    ploscicaPromet_Promet_4_8.ChildOrder := 68;
    ploscicaPromet_Promet_4_8.Color := clWhite;
    ploscicaPromet_Promet_4_8.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Promet_4_8.TabOrder := 32;
    ploscicaPromet_Promet_4_8.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Promet_4_8, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Promet_4_8, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Indeks_1_8.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Indeks_1_8.Name := 'ploscicaPromet_Indeks_1_8';
    ploscicaPromet_Indeks_1_8.Left := 512;
    ploscicaPromet_Indeks_1_8.Top := 210;
    ploscicaPromet_Indeks_1_8.Width := 96;
    ploscicaPromet_Indeks_1_8.Height := 22;
    ploscicaPromet_Indeks_1_8.TabStop := False;
    ploscicaPromet_Indeks_1_8.Alignment := taRightJustify;
    ploscicaPromet_Indeks_1_8.Anchors := [];
    ploscicaPromet_Indeks_1_8.AutoSelect := False;
    ploscicaPromet_Indeks_1_8.BorderStyle := bsNone;
    ploscicaPromet_Indeks_1_8.ChildOrder := 69;
    ploscicaPromet_Indeks_1_8.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Indeks_1_8.ReadOnly := True;
    ploscicaPromet_Indeks_1_8.ShowFocus := False;
    ploscicaPromet_Indeks_1_8.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Indeks_1_8, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Indeks_1_8, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Indeks_2_8.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Indeks_2_8.Name := 'ploscicaPromet_Indeks_2_8';
    ploscicaPromet_Indeks_2_8.Left := 608;
    ploscicaPromet_Indeks_2_8.Top := 210;
    ploscicaPromet_Indeks_2_8.Width := 96;
    ploscicaPromet_Indeks_2_8.Height := 22;
    ploscicaPromet_Indeks_2_8.TabStop := False;
    ploscicaPromet_Indeks_2_8.Alignment := taRightJustify;
    ploscicaPromet_Indeks_2_8.Anchors := [];
    ploscicaPromet_Indeks_2_8.AutoSelect := False;
    ploscicaPromet_Indeks_2_8.BorderStyle := bsNone;
    ploscicaPromet_Indeks_2_8.ChildOrder := 70;
    ploscicaPromet_Indeks_2_8.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Indeks_2_8.ReadOnly := True;
    ploscicaPromet_Indeks_2_8.ShowFocus := False;
    ploscicaPromet_Indeks_2_8.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Indeks_2_8, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Indeks_2_8, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Indeks_3_8.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Indeks_3_8.Name := 'ploscicaPromet_Indeks_3_8';
    ploscicaPromet_Indeks_3_8.Left := 704;
    ploscicaPromet_Indeks_3_8.Top := 210;
    ploscicaPromet_Indeks_3_8.Width := 96;
    ploscicaPromet_Indeks_3_8.Height := 22;
    ploscicaPromet_Indeks_3_8.TabStop := False;
    ploscicaPromet_Indeks_3_8.Alignment := taRightJustify;
    ploscicaPromet_Indeks_3_8.Anchors := [];
    ploscicaPromet_Indeks_3_8.AutoSelect := False;
    ploscicaPromet_Indeks_3_8.BorderStyle := bsNone;
    ploscicaPromet_Indeks_3_8.ChildOrder := 71;
    ploscicaPromet_Indeks_3_8.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Indeks_3_8.ReadOnly := True;
    ploscicaPromet_Indeks_3_8.ShowFocus := False;
    ploscicaPromet_Indeks_3_8.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Indeks_3_8, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Indeks_3_8, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Promet_1_9.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Promet_1_9.Name := 'ploscicaPromet_Promet_1_9';
    ploscicaPromet_Promet_1_9.Left := 128;
    ploscicaPromet_Promet_1_9.Top := 236;
    ploscicaPromet_Promet_1_9.Width := 96;
    ploscicaPromet_Promet_1_9.Height := 22;
    ploscicaPromet_Promet_1_9.TabStop := False;
    ploscicaPromet_Promet_1_9.Alignment := taRightJustify;
    ploscicaPromet_Promet_1_9.Anchors := [];
    ploscicaPromet_Promet_1_9.AutoSelect := False;
    ploscicaPromet_Promet_1_9.BorderStyle := bsNone;
    ploscicaPromet_Promet_1_9.ChildOrder := 73;
    ploscicaPromet_Promet_1_9.Color := clWhite;
    ploscicaPromet_Promet_1_9.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Promet_1_9.ReadOnly := True;
    ploscicaPromet_Promet_1_9.ShowFocus := False;
    ploscicaPromet_Promet_1_9.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Promet_1_9, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Promet_1_9, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Promet_2_9.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Promet_2_9.Name := 'ploscicaPromet_Promet_2_9';
    ploscicaPromet_Promet_2_9.Left := 224;
    ploscicaPromet_Promet_2_9.Top := 236;
    ploscicaPromet_Promet_2_9.Width := 96;
    ploscicaPromet_Promet_2_9.Height := 22;
    ploscicaPromet_Promet_2_9.Alignment := taRightJustify;
    ploscicaPromet_Promet_2_9.Anchors := [];
    ploscicaPromet_Promet_2_9.BorderStyle := bsNone;
    ploscicaPromet_Promet_2_9.ChildOrder := 74;
    ploscicaPromet_Promet_2_9.Color := clWhite;
    ploscicaPromet_Promet_2_9.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Promet_2_9.TabOrder := 9;
    ploscicaPromet_Promet_2_9.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Promet_2_9, Self, 'OnDblClick', 'ploscicaPromet_Promet_OznacevanjeIzpadaDblClick');
    SetEvent(ploscicaPromet_Promet_2_9, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Promet_2_9, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Promet_3_9.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Promet_3_9.Name := 'ploscicaPromet_Promet_3_9';
    ploscicaPromet_Promet_3_9.Left := 320;
    ploscicaPromet_Promet_3_9.Top := 236;
    ploscicaPromet_Promet_3_9.Width := 96;
    ploscicaPromet_Promet_3_9.Height := 22;
    ploscicaPromet_Promet_3_9.Alignment := taRightJustify;
    ploscicaPromet_Promet_3_9.Anchors := [];
    ploscicaPromet_Promet_3_9.BorderStyle := bsNone;
    ploscicaPromet_Promet_3_9.ChildOrder := 75;
    ploscicaPromet_Promet_3_9.Color := clWhite;
    ploscicaPromet_Promet_3_9.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Promet_3_9.TabOrder := 21;
    ploscicaPromet_Promet_3_9.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Promet_3_9, Self, 'OnDblClick', 'ploscicaPromet_Promet_OznacevanjeIzpadaDblClick');
    SetEvent(ploscicaPromet_Promet_3_9, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Promet_3_9, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Promet_4_9.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Promet_4_9.Name := 'ploscicaPromet_Promet_4_9';
    ploscicaPromet_Promet_4_9.Left := 416;
    ploscicaPromet_Promet_4_9.Top := 236;
    ploscicaPromet_Promet_4_9.Width := 96;
    ploscicaPromet_Promet_4_9.Height := 22;
    ploscicaPromet_Promet_4_9.Alignment := taRightJustify;
    ploscicaPromet_Promet_4_9.Anchors := [];
    ploscicaPromet_Promet_4_9.BorderStyle := bsNone;
    ploscicaPromet_Promet_4_9.ChildOrder := 76;
    ploscicaPromet_Promet_4_9.Color := clWhite;
    ploscicaPromet_Promet_4_9.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Promet_4_9.TabOrder := 33;
    ploscicaPromet_Promet_4_9.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Promet_4_9, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Promet_4_9, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Indeks_1_9.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Indeks_1_9.Name := 'ploscicaPromet_Indeks_1_9';
    ploscicaPromet_Indeks_1_9.Left := 512;
    ploscicaPromet_Indeks_1_9.Top := 236;
    ploscicaPromet_Indeks_1_9.Width := 96;
    ploscicaPromet_Indeks_1_9.Height := 22;
    ploscicaPromet_Indeks_1_9.TabStop := False;
    ploscicaPromet_Indeks_1_9.Alignment := taRightJustify;
    ploscicaPromet_Indeks_1_9.Anchors := [];
    ploscicaPromet_Indeks_1_9.AutoSelect := False;
    ploscicaPromet_Indeks_1_9.BorderStyle := bsNone;
    ploscicaPromet_Indeks_1_9.ChildOrder := 77;
    ploscicaPromet_Indeks_1_9.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Indeks_1_9.ReadOnly := True;
    ploscicaPromet_Indeks_1_9.ShowFocus := False;
    ploscicaPromet_Indeks_1_9.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Indeks_1_9, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Indeks_1_9, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Indeks_2_9.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Indeks_2_9.Name := 'ploscicaPromet_Indeks_2_9';
    ploscicaPromet_Indeks_2_9.Left := 608;
    ploscicaPromet_Indeks_2_9.Top := 236;
    ploscicaPromet_Indeks_2_9.Width := 96;
    ploscicaPromet_Indeks_2_9.Height := 22;
    ploscicaPromet_Indeks_2_9.TabStop := False;
    ploscicaPromet_Indeks_2_9.Alignment := taRightJustify;
    ploscicaPromet_Indeks_2_9.Anchors := [];
    ploscicaPromet_Indeks_2_9.AutoSelect := False;
    ploscicaPromet_Indeks_2_9.BorderStyle := bsNone;
    ploscicaPromet_Indeks_2_9.ChildOrder := 78;
    ploscicaPromet_Indeks_2_9.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Indeks_2_9.ReadOnly := True;
    ploscicaPromet_Indeks_2_9.ShowFocus := False;
    ploscicaPromet_Indeks_2_9.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Indeks_2_9, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Indeks_2_9, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Indeks_3_9.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Indeks_3_9.Name := 'ploscicaPromet_Indeks_3_9';
    ploscicaPromet_Indeks_3_9.Left := 704;
    ploscicaPromet_Indeks_3_9.Top := 236;
    ploscicaPromet_Indeks_3_9.Width := 96;
    ploscicaPromet_Indeks_3_9.Height := 22;
    ploscicaPromet_Indeks_3_9.TabStop := False;
    ploscicaPromet_Indeks_3_9.Alignment := taRightJustify;
    ploscicaPromet_Indeks_3_9.Anchors := [];
    ploscicaPromet_Indeks_3_9.AutoSelect := False;
    ploscicaPromet_Indeks_3_9.BorderStyle := bsNone;
    ploscicaPromet_Indeks_3_9.ChildOrder := 79;
    ploscicaPromet_Indeks_3_9.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Indeks_3_9.ReadOnly := True;
    ploscicaPromet_Indeks_3_9.ShowFocus := False;
    ploscicaPromet_Indeks_3_9.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Indeks_3_9, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Indeks_3_9, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Promet_1_10.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Promet_1_10.Name := 'ploscicaPromet_Promet_1_10';
    ploscicaPromet_Promet_1_10.Left := 128;
    ploscicaPromet_Promet_1_10.Top := 262;
    ploscicaPromet_Promet_1_10.Width := 96;
    ploscicaPromet_Promet_1_10.Height := 22;
    ploscicaPromet_Promet_1_10.TabStop := False;
    ploscicaPromet_Promet_1_10.Alignment := taRightJustify;
    ploscicaPromet_Promet_1_10.Anchors := [];
    ploscicaPromet_Promet_1_10.AutoSelect := False;
    ploscicaPromet_Promet_1_10.BorderStyle := bsNone;
    ploscicaPromet_Promet_1_10.ChildOrder := 81;
    ploscicaPromet_Promet_1_10.Color := clWhite;
    ploscicaPromet_Promet_1_10.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Promet_1_10.ReadOnly := True;
    ploscicaPromet_Promet_1_10.ShowFocus := False;
    ploscicaPromet_Promet_1_10.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Promet_1_10, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Promet_1_10, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Promet_2_10.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Promet_2_10.Name := 'ploscicaPromet_Promet_2_10';
    ploscicaPromet_Promet_2_10.Left := 224;
    ploscicaPromet_Promet_2_10.Top := 262;
    ploscicaPromet_Promet_2_10.Width := 96;
    ploscicaPromet_Promet_2_10.Height := 22;
    ploscicaPromet_Promet_2_10.Alignment := taRightJustify;
    ploscicaPromet_Promet_2_10.Anchors := [];
    ploscicaPromet_Promet_2_10.BorderStyle := bsNone;
    ploscicaPromet_Promet_2_10.ChildOrder := 82;
    ploscicaPromet_Promet_2_10.Color := clWhite;
    ploscicaPromet_Promet_2_10.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Promet_2_10.TabOrder := 10;
    ploscicaPromet_Promet_2_10.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Promet_2_10, Self, 'OnDblClick', 'ploscicaPromet_Promet_OznacevanjeIzpadaDblClick');
    SetEvent(ploscicaPromet_Promet_2_10, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Promet_2_10, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Promet_3_10.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Promet_3_10.Name := 'ploscicaPromet_Promet_3_10';
    ploscicaPromet_Promet_3_10.Left := 320;
    ploscicaPromet_Promet_3_10.Top := 262;
    ploscicaPromet_Promet_3_10.Width := 96;
    ploscicaPromet_Promet_3_10.Height := 22;
    ploscicaPromet_Promet_3_10.Alignment := taRightJustify;
    ploscicaPromet_Promet_3_10.Anchors := [];
    ploscicaPromet_Promet_3_10.BorderStyle := bsNone;
    ploscicaPromet_Promet_3_10.ChildOrder := 83;
    ploscicaPromet_Promet_3_10.Color := clWhite;
    ploscicaPromet_Promet_3_10.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Promet_3_10.TabOrder := 22;
    ploscicaPromet_Promet_3_10.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Promet_3_10, Self, 'OnDblClick', 'ploscicaPromet_Promet_OznacevanjeIzpadaDblClick');
    SetEvent(ploscicaPromet_Promet_3_10, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Promet_3_10, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Promet_4_10.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Promet_4_10.Name := 'ploscicaPromet_Promet_4_10';
    ploscicaPromet_Promet_4_10.Left := 416;
    ploscicaPromet_Promet_4_10.Top := 262;
    ploscicaPromet_Promet_4_10.Width := 96;
    ploscicaPromet_Promet_4_10.Height := 22;
    ploscicaPromet_Promet_4_10.Alignment := taRightJustify;
    ploscicaPromet_Promet_4_10.Anchors := [];
    ploscicaPromet_Promet_4_10.BorderStyle := bsNone;
    ploscicaPromet_Promet_4_10.ChildOrder := 84;
    ploscicaPromet_Promet_4_10.Color := clWhite;
    ploscicaPromet_Promet_4_10.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Promet_4_10.TabOrder := 34;
    ploscicaPromet_Promet_4_10.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Promet_4_10, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Promet_4_10, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Indeks_1_10.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Indeks_1_10.Name := 'ploscicaPromet_Indeks_1_10';
    ploscicaPromet_Indeks_1_10.Left := 512;
    ploscicaPromet_Indeks_1_10.Top := 262;
    ploscicaPromet_Indeks_1_10.Width := 96;
    ploscicaPromet_Indeks_1_10.Height := 22;
    ploscicaPromet_Indeks_1_10.TabStop := False;
    ploscicaPromet_Indeks_1_10.Alignment := taRightJustify;
    ploscicaPromet_Indeks_1_10.Anchors := [];
    ploscicaPromet_Indeks_1_10.AutoSelect := False;
    ploscicaPromet_Indeks_1_10.BorderStyle := bsNone;
    ploscicaPromet_Indeks_1_10.ChildOrder := 85;
    ploscicaPromet_Indeks_1_10.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Indeks_1_10.ReadOnly := True;
    ploscicaPromet_Indeks_1_10.ShowFocus := False;
    ploscicaPromet_Indeks_1_10.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Indeks_1_10, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Indeks_1_10, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Indeks_2_10.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Indeks_2_10.Name := 'ploscicaPromet_Indeks_2_10';
    ploscicaPromet_Indeks_2_10.Left := 608;
    ploscicaPromet_Indeks_2_10.Top := 262;
    ploscicaPromet_Indeks_2_10.Width := 96;
    ploscicaPromet_Indeks_2_10.Height := 22;
    ploscicaPromet_Indeks_2_10.TabStop := False;
    ploscicaPromet_Indeks_2_10.Alignment := taRightJustify;
    ploscicaPromet_Indeks_2_10.Anchors := [];
    ploscicaPromet_Indeks_2_10.AutoSelect := False;
    ploscicaPromet_Indeks_2_10.BorderStyle := bsNone;
    ploscicaPromet_Indeks_2_10.ChildOrder := 86;
    ploscicaPromet_Indeks_2_10.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Indeks_2_10.ReadOnly := True;
    ploscicaPromet_Indeks_2_10.ShowFocus := False;
    ploscicaPromet_Indeks_2_10.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Indeks_2_10, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Indeks_2_10, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Indeks_3_10.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Indeks_3_10.Name := 'ploscicaPromet_Indeks_3_10';
    ploscicaPromet_Indeks_3_10.Left := 704;
    ploscicaPromet_Indeks_3_10.Top := 262;
    ploscicaPromet_Indeks_3_10.Width := 96;
    ploscicaPromet_Indeks_3_10.Height := 22;
    ploscicaPromet_Indeks_3_10.TabStop := False;
    ploscicaPromet_Indeks_3_10.Alignment := taRightJustify;
    ploscicaPromet_Indeks_3_10.Anchors := [];
    ploscicaPromet_Indeks_3_10.AutoSelect := False;
    ploscicaPromet_Indeks_3_10.BorderStyle := bsNone;
    ploscicaPromet_Indeks_3_10.ChildOrder := 87;
    ploscicaPromet_Indeks_3_10.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Indeks_3_10.ReadOnly := True;
    ploscicaPromet_Indeks_3_10.ShowFocus := False;
    ploscicaPromet_Indeks_3_10.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Indeks_3_10, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Indeks_3_10, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Promet_1_11.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Promet_1_11.Name := 'ploscicaPromet_Promet_1_11';
    ploscicaPromet_Promet_1_11.Left := 128;
    ploscicaPromet_Promet_1_11.Top := 288;
    ploscicaPromet_Promet_1_11.Width := 96;
    ploscicaPromet_Promet_1_11.Height := 22;
    ploscicaPromet_Promet_1_11.TabStop := False;
    ploscicaPromet_Promet_1_11.Alignment := taRightJustify;
    ploscicaPromet_Promet_1_11.Anchors := [];
    ploscicaPromet_Promet_1_11.AutoSelect := False;
    ploscicaPromet_Promet_1_11.BorderStyle := bsNone;
    ploscicaPromet_Promet_1_11.ChildOrder := 89;
    ploscicaPromet_Promet_1_11.Color := clWhite;
    ploscicaPromet_Promet_1_11.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Promet_1_11.ReadOnly := True;
    ploscicaPromet_Promet_1_11.ShowFocus := False;
    ploscicaPromet_Promet_1_11.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Promet_1_11, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Promet_1_11, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Promet_2_11.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Promet_2_11.Name := 'ploscicaPromet_Promet_2_11';
    ploscicaPromet_Promet_2_11.Left := 224;
    ploscicaPromet_Promet_2_11.Top := 288;
    ploscicaPromet_Promet_2_11.Width := 96;
    ploscicaPromet_Promet_2_11.Height := 22;
    ploscicaPromet_Promet_2_11.Alignment := taRightJustify;
    ploscicaPromet_Promet_2_11.Anchors := [];
    ploscicaPromet_Promet_2_11.BorderStyle := bsNone;
    ploscicaPromet_Promet_2_11.ChildOrder := 90;
    ploscicaPromet_Promet_2_11.Color := clWhite;
    ploscicaPromet_Promet_2_11.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Promet_2_11.TabOrder := 11;
    ploscicaPromet_Promet_2_11.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Promet_2_11, Self, 'OnDblClick', 'ploscicaPromet_Promet_OznacevanjeIzpadaDblClick');
    SetEvent(ploscicaPromet_Promet_2_11, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Promet_2_11, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Promet_3_11.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Promet_3_11.Name := 'ploscicaPromet_Promet_3_11';
    ploscicaPromet_Promet_3_11.Left := 320;
    ploscicaPromet_Promet_3_11.Top := 288;
    ploscicaPromet_Promet_3_11.Width := 96;
    ploscicaPromet_Promet_3_11.Height := 22;
    ploscicaPromet_Promet_3_11.Alignment := taRightJustify;
    ploscicaPromet_Promet_3_11.Anchors := [];
    ploscicaPromet_Promet_3_11.BorderStyle := bsNone;
    ploscicaPromet_Promet_3_11.ChildOrder := 91;
    ploscicaPromet_Promet_3_11.Color := clWhite;
    ploscicaPromet_Promet_3_11.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Promet_3_11.TabOrder := 23;
    ploscicaPromet_Promet_3_11.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Promet_3_11, Self, 'OnDblClick', 'ploscicaPromet_Promet_OznacevanjeIzpadaDblClick');
    SetEvent(ploscicaPromet_Promet_3_11, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Promet_3_11, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Promet_4_11.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Promet_4_11.Name := 'ploscicaPromet_Promet_4_11';
    ploscicaPromet_Promet_4_11.Left := 416;
    ploscicaPromet_Promet_4_11.Top := 288;
    ploscicaPromet_Promet_4_11.Width := 96;
    ploscicaPromet_Promet_4_11.Height := 22;
    ploscicaPromet_Promet_4_11.Alignment := taRightJustify;
    ploscicaPromet_Promet_4_11.Anchors := [];
    ploscicaPromet_Promet_4_11.BorderStyle := bsNone;
    ploscicaPromet_Promet_4_11.ChildOrder := 92;
    ploscicaPromet_Promet_4_11.Color := clWhite;
    ploscicaPromet_Promet_4_11.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Promet_4_11.TabOrder := 35;
    ploscicaPromet_Promet_4_11.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Promet_4_11, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Promet_4_11, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Indeks_1_11.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Indeks_1_11.Name := 'ploscicaPromet_Indeks_1_11';
    ploscicaPromet_Indeks_1_11.Left := 512;
    ploscicaPromet_Indeks_1_11.Top := 288;
    ploscicaPromet_Indeks_1_11.Width := 96;
    ploscicaPromet_Indeks_1_11.Height := 22;
    ploscicaPromet_Indeks_1_11.TabStop := False;
    ploscicaPromet_Indeks_1_11.Alignment := taRightJustify;
    ploscicaPromet_Indeks_1_11.Anchors := [];
    ploscicaPromet_Indeks_1_11.AutoSelect := False;
    ploscicaPromet_Indeks_1_11.BorderStyle := bsNone;
    ploscicaPromet_Indeks_1_11.ChildOrder := 93;
    ploscicaPromet_Indeks_1_11.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Indeks_1_11.ReadOnly := True;
    ploscicaPromet_Indeks_1_11.ShowFocus := False;
    ploscicaPromet_Indeks_1_11.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Indeks_1_11, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Indeks_1_11, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Indeks_2_11.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Indeks_2_11.Name := 'ploscicaPromet_Indeks_2_11';
    ploscicaPromet_Indeks_2_11.Left := 608;
    ploscicaPromet_Indeks_2_11.Top := 288;
    ploscicaPromet_Indeks_2_11.Width := 96;
    ploscicaPromet_Indeks_2_11.Height := 22;
    ploscicaPromet_Indeks_2_11.TabStop := False;
    ploscicaPromet_Indeks_2_11.Alignment := taRightJustify;
    ploscicaPromet_Indeks_2_11.Anchors := [];
    ploscicaPromet_Indeks_2_11.AutoSelect := False;
    ploscicaPromet_Indeks_2_11.BorderStyle := bsNone;
    ploscicaPromet_Indeks_2_11.ChildOrder := 94;
    ploscicaPromet_Indeks_2_11.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Indeks_2_11.ReadOnly := True;
    ploscicaPromet_Indeks_2_11.ShowFocus := False;
    ploscicaPromet_Indeks_2_11.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Indeks_2_11, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Indeks_2_11, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Indeks_3_11.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Indeks_3_11.Name := 'ploscicaPromet_Indeks_3_11';
    ploscicaPromet_Indeks_3_11.Left := 704;
    ploscicaPromet_Indeks_3_11.Top := 288;
    ploscicaPromet_Indeks_3_11.Width := 96;
    ploscicaPromet_Indeks_3_11.Height := 22;
    ploscicaPromet_Indeks_3_11.TabStop := False;
    ploscicaPromet_Indeks_3_11.Alignment := taRightJustify;
    ploscicaPromet_Indeks_3_11.Anchors := [];
    ploscicaPromet_Indeks_3_11.AutoSelect := False;
    ploscicaPromet_Indeks_3_11.BorderStyle := bsNone;
    ploscicaPromet_Indeks_3_11.ChildOrder := 95;
    ploscicaPromet_Indeks_3_11.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Indeks_3_11.ReadOnly := True;
    ploscicaPromet_Indeks_3_11.ShowFocus := False;
    ploscicaPromet_Indeks_3_11.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Indeks_3_11, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Indeks_3_11, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Promet_1_12.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Promet_1_12.Name := 'ploscicaPromet_Promet_1_12';
    ploscicaPromet_Promet_1_12.Left := 128;
    ploscicaPromet_Promet_1_12.Top := 314;
    ploscicaPromet_Promet_1_12.Width := 96;
    ploscicaPromet_Promet_1_12.Height := 22;
    ploscicaPromet_Promet_1_12.TabStop := False;
    ploscicaPromet_Promet_1_12.Alignment := taRightJustify;
    ploscicaPromet_Promet_1_12.Anchors := [];
    ploscicaPromet_Promet_1_12.AutoSelect := False;
    ploscicaPromet_Promet_1_12.BorderStyle := bsNone;
    ploscicaPromet_Promet_1_12.ChildOrder := 97;
    ploscicaPromet_Promet_1_12.Color := clWhite;
    ploscicaPromet_Promet_1_12.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Promet_1_12.ReadOnly := True;
    ploscicaPromet_Promet_1_12.ShowFocus := False;
    ploscicaPromet_Promet_1_12.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Promet_1_12, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Promet_1_12, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Promet_2_12.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Promet_2_12.Name := 'ploscicaPromet_Promet_2_12';
    ploscicaPromet_Promet_2_12.Left := 224;
    ploscicaPromet_Promet_2_12.Top := 314;
    ploscicaPromet_Promet_2_12.Width := 96;
    ploscicaPromet_Promet_2_12.Height := 22;
    ploscicaPromet_Promet_2_12.Alignment := taRightJustify;
    ploscicaPromet_Promet_2_12.Anchors := [];
    ploscicaPromet_Promet_2_12.BorderStyle := bsNone;
    ploscicaPromet_Promet_2_12.ChildOrder := 98;
    ploscicaPromet_Promet_2_12.Color := clWhite;
    ploscicaPromet_Promet_2_12.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Promet_2_12.TabOrder := 12;
    ploscicaPromet_Promet_2_12.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Promet_2_12, Self, 'OnDblClick', 'ploscicaPromet_Promet_OznacevanjeIzpadaDblClick');
    SetEvent(ploscicaPromet_Promet_2_12, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Promet_2_12, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Promet_3_12.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Promet_3_12.Name := 'ploscicaPromet_Promet_3_12';
    ploscicaPromet_Promet_3_12.Left := 320;
    ploscicaPromet_Promet_3_12.Top := 314;
    ploscicaPromet_Promet_3_12.Width := 96;
    ploscicaPromet_Promet_3_12.Height := 22;
    ploscicaPromet_Promet_3_12.Alignment := taRightJustify;
    ploscicaPromet_Promet_3_12.Anchors := [];
    ploscicaPromet_Promet_3_12.BorderStyle := bsNone;
    ploscicaPromet_Promet_3_12.ChildOrder := 99;
    ploscicaPromet_Promet_3_12.Color := clWhite;
    ploscicaPromet_Promet_3_12.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Promet_3_12.TabOrder := 24;
    ploscicaPromet_Promet_3_12.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Promet_3_12, Self, 'OnDblClick', 'ploscicaPromet_Promet_OznacevanjeIzpadaDblClick');
    SetEvent(ploscicaPromet_Promet_3_12, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Promet_3_12, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Promet_4_12.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Promet_4_12.Name := 'ploscicaPromet_Promet_4_12';
    ploscicaPromet_Promet_4_12.Left := 416;
    ploscicaPromet_Promet_4_12.Top := 314;
    ploscicaPromet_Promet_4_12.Width := 96;
    ploscicaPromet_Promet_4_12.Height := 22;
    ploscicaPromet_Promet_4_12.Alignment := taRightJustify;
    ploscicaPromet_Promet_4_12.Anchors := [];
    ploscicaPromet_Promet_4_12.BorderStyle := bsNone;
    ploscicaPromet_Promet_4_12.ChildOrder := 100;
    ploscicaPromet_Promet_4_12.Color := clWhite;
    ploscicaPromet_Promet_4_12.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Promet_4_12.TabOrder := 36;
    ploscicaPromet_Promet_4_12.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Promet_4_12, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Promet_4_12, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Indeks_1_12.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Indeks_1_12.Name := 'ploscicaPromet_Indeks_1_12';
    ploscicaPromet_Indeks_1_12.Left := 512;
    ploscicaPromet_Indeks_1_12.Top := 314;
    ploscicaPromet_Indeks_1_12.Width := 96;
    ploscicaPromet_Indeks_1_12.Height := 22;
    ploscicaPromet_Indeks_1_12.TabStop := False;
    ploscicaPromet_Indeks_1_12.Alignment := taRightJustify;
    ploscicaPromet_Indeks_1_12.Anchors := [];
    ploscicaPromet_Indeks_1_12.AutoSelect := False;
    ploscicaPromet_Indeks_1_12.BorderStyle := bsNone;
    ploscicaPromet_Indeks_1_12.ChildOrder := 101;
    ploscicaPromet_Indeks_1_12.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Indeks_1_12.ReadOnly := True;
    ploscicaPromet_Indeks_1_12.ShowFocus := False;
    ploscicaPromet_Indeks_1_12.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Indeks_1_12, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Indeks_1_12, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Indeks_2_12.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Indeks_2_12.Name := 'ploscicaPromet_Indeks_2_12';
    ploscicaPromet_Indeks_2_12.Left := 608;
    ploscicaPromet_Indeks_2_12.Top := 314;
    ploscicaPromet_Indeks_2_12.Width := 96;
    ploscicaPromet_Indeks_2_12.Height := 22;
    ploscicaPromet_Indeks_2_12.TabStop := False;
    ploscicaPromet_Indeks_2_12.Alignment := taRightJustify;
    ploscicaPromet_Indeks_2_12.Anchors := [];
    ploscicaPromet_Indeks_2_12.AutoSelect := False;
    ploscicaPromet_Indeks_2_12.BorderStyle := bsNone;
    ploscicaPromet_Indeks_2_12.ChildOrder := 102;
    ploscicaPromet_Indeks_2_12.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Indeks_2_12.ReadOnly := True;
    ploscicaPromet_Indeks_2_12.ShowFocus := False;
    ploscicaPromet_Indeks_2_12.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Indeks_2_12, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Indeks_2_12, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPromet_Indeks_3_12.SetParentComponent(ploscicaTabelaPodatkovOPrometu);
    ploscicaPromet_Indeks_3_12.Name := 'ploscicaPromet_Indeks_3_12';
    ploscicaPromet_Indeks_3_12.Left := 704;
    ploscicaPromet_Indeks_3_12.Top := 314;
    ploscicaPromet_Indeks_3_12.Width := 96;
    ploscicaPromet_Indeks_3_12.Height := 22;
    ploscicaPromet_Indeks_3_12.TabStop := False;
    ploscicaPromet_Indeks_3_12.Alignment := taRightJustify;
    ploscicaPromet_Indeks_3_12.Anchors := [];
    ploscicaPromet_Indeks_3_12.AutoSelect := False;
    ploscicaPromet_Indeks_3_12.BorderStyle := bsNone;
    ploscicaPromet_Indeks_3_12.ChildOrder := 103;
    ploscicaPromet_Indeks_3_12.HeightPercent := 100.000000000000000000;
    ploscicaPromet_Indeks_3_12.ReadOnly := True;
    ploscicaPromet_Indeks_3_12.ShowFocus := False;
    ploscicaPromet_Indeks_3_12.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPromet_Indeks_3_12, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(ploscicaPromet_Indeks_3_12, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    gVnosPopolnegaZastoja.SetParentComponent(WebTabSheet1);
    gVnosPopolnegaZastoja.Name := 'gVnosPopolnegaZastoja';
    gVnosPopolnegaZastoja.Left := 11;
    gVnosPopolnegaZastoja.Top := 365;
    gVnosPopolnegaZastoja.Width := 145;
    gVnosPopolnegaZastoja.Height := 24;
    gVnosPopolnegaZastoja.Caption := 'Vnesi popolni zastoj';
    gVnosPopolnegaZastoja.Color := clNone;
    gVnosPopolnegaZastoja.Flat := True;
    gVnosPopolnegaZastoja.Glyph.LoadFromFile('MBI_u01.WebTabSheet1.gVnosPopolnegaZastoja.Glyph.png');
    gVnosPopolnegaZastoja.HeightPercent := 100.000000000000000000;
    gVnosPopolnegaZastoja.WidthPercent := 100.000000000000000000;
    SetEvent(gVnosPopolnegaZastoja, Self, 'OnClick', 'gVnosPopolnegaZastojaClick');
    gAvtomaticnoPolnjenje.SetParentComponent(WebTabSheet1);
    gAvtomaticnoPolnjenje.Name := 'gAvtomaticnoPolnjenje';
    gAvtomaticnoPolnjenje.Left := 425;
    gAvtomaticnoPolnjenje.Top := 365;
    gAvtomaticnoPolnjenje.Width := 260;
    gAvtomaticnoPolnjenje.Height := 24;
    gAvtomaticnoPolnjenje.Caption := 'Avtomati'#269'no polnjenje s planiranimi vrednostmi';
    gAvtomaticnoPolnjenje.Color := clNone;
    gAvtomaticnoPolnjenje.Flat := True;
    gAvtomaticnoPolnjenje.Glyph.LoadFromFile('MBI_u01.WebTabSheet1.gAvtomaticnoPolnjenje.Glyph.png');
    gAvtomaticnoPolnjenje.HeightPercent := 100.000000000000000000;
    gAvtomaticnoPolnjenje.WidthPercent := 100.000000000000000000;
    SetEvent(gAvtomaticnoPolnjenje, Self, 'OnClick', 'gAvtomaticnoPolnjenjeClick');
    gVnesiKrivuljoZastoja.SetParentComponent(WebTabSheet1);
    gVnesiKrivuljoZastoja.Name := 'gVnesiKrivuljoZastoja';
    gVnesiKrivuljoZastoja.Left := 165;
    gVnesiKrivuljoZastoja.Top := 365;
    gVnesiKrivuljoZastoja.Width := 250;
    gVnesiKrivuljoZastoja.Height := 24;
    gVnesiKrivuljoZastoja.Caption := 'Vnesi 12-mese'#269'ni zastoj po krivulji';
    gVnesiKrivuljoZastoja.Color := clNone;
    gVnesiKrivuljoZastoja.Flat := True;
    gVnesiKrivuljoZastoja.Glyph.LoadFromFile('MBI_u01.WebTabSheet1.gVnesiKrivuljoZastoja.Glyph.png');
    gVnesiKrivuljoZastoja.HeightPercent := 100.000000000000000000;
    gVnesiKrivuljoZastoja.WidthPercent := 100.000000000000000000;
    SetEvent(gVnesiKrivuljoZastoja, Self, 'OnClick', 'gVnesiKrivuljoZastojaClick');
    ploscicaGrafikaSkode.SetParentComponent(WebTabSheet1);
    ploscicaGrafikaSkode.Name := 'ploscicaGrafikaSkode';
    ploscicaGrafikaSkode.Left := 714;
    ploscicaGrafikaSkode.Top := 376;
    ploscicaGrafikaSkode.Width := 93;
    ploscicaGrafikaSkode.Height := 24;
    ploscicaGrafikaSkode.HeightPercent := 100.000000000000000000;
    ploscicaGrafikaSkode.WidthPercent := 100.000000000000000000;
    ploscicaGrafikaSkode.Anchors := [];
    ploscicaGrafikaSkode.BorderColor := clNone;
    ploscicaGrafikaSkode.BorderStyle := bsNone;
    ploscicaGrafikaSkode.ChildOrder := 4;
    ploscicaGrafikaSkode.Color := clWindow;
    izGrafikaSkode_DA.SetParentComponent(ploscicaGrafikaSkode);
    izGrafikaSkode_DA.Name := 'izGrafikaSkode_DA';
    izGrafikaSkode_DA.Left := 59;
    izGrafikaSkode_DA.Top := 3;
    izGrafikaSkode_DA.Width := 30;
    izGrafikaSkode_DA.Height := 18;
    izGrafikaSkode_DA.Alignment := taRightJustify;
    izGrafikaSkode_DA.AutoSize := False;
    izGrafikaSkode_DA.Caption := 'DA';
    izGrafikaSkode_DA.Color := clNone;
    izGrafikaSkode_DA.Font.Charset := DEFAULT_CHARSET;
    izGrafikaSkode_DA.Font.Color := clWindowText;
    izGrafikaSkode_DA.Font.Height := -11;
    izGrafikaSkode_DA.Font.Name := 'Arial';
    izGrafikaSkode_DA.Font.Style := [];
    izGrafikaSkode_DA.HeightPercent := 100.000000000000000000;
    izGrafikaSkode_DA.ParentFont := False;
    izGrafikaSkode_DA.WidthPercent := 100.000000000000000000;
    izGrafikaSkode_NE.SetParentComponent(ploscicaGrafikaSkode);
    izGrafikaSkode_NE.Name := 'izGrafikaSkode_NE';
    izGrafikaSkode_NE.Left := 2;
    izGrafikaSkode_NE.Top := 3;
    izGrafikaSkode_NE.Width := 35;
    izGrafikaSkode_NE.Height := 18;
    izGrafikaSkode_NE.AutoSize := False;
    izGrafikaSkode_NE.Caption := 'NE';
    izGrafikaSkode_NE.Color := clNone;
    izGrafikaSkode_NE.Font.Charset := DEFAULT_CHARSET;
    izGrafikaSkode_NE.Font.Color := clWindowText;
    izGrafikaSkode_NE.Font.Height := -11;
    izGrafikaSkode_NE.Font.Name := 'Arial';
    izGrafikaSkode_NE.Font.Style := [];
    izGrafikaSkode_NE.HeightPercent := 100.000000000000000000;
    izGrafikaSkode_NE.ParentFont := False;
    izGrafikaSkode_NE.WidthPercent := 100.000000000000000000;
    vpGrafikaSkode.SetParentComponent(ploscicaGrafikaSkode);
    vpGrafikaSkode.Name := 'vpGrafikaSkode';
    vpGrafikaSkode.Left := 22;
    vpGrafikaSkode.Top := -2;
    vpGrafikaSkode.Width := 35;
    vpGrafikaSkode.Height := 20;
    vpGrafikaSkode.HeightPercent := 100.000000000000000000;
    vpGrafikaSkode.WidthPercent := 100.000000000000000000;
    vpGrafikaSkode.Anchors := [];
    vpGrafikaSkode.ChildOrder := 2;
    vpGrafikaSkode.Max := 1;
    vpGrafikaSkode.Min := 0;
    vpGrafikaSkode.Position := 0;
    vpGrafikaSkode.Role := '';
    SetEvent(vpGrafikaSkode, Self, 'OnChange', 'vpGrafikaSkodeChange');
    WebTabSheet2.SetParentComponent(DelovniListiPodatkiZaSimulacijoSkod);
    WebTabSheet2.Name := 'WebTabSheet2';
    WebTabSheet2.Left := 0;
    WebTabSheet2.Top := 20;
    WebTabSheet2.Width := 825;
    WebTabSheet2.Height := 405;
    WebTabSheet2.HeightPercent := 100.000000000000000000;
    WebTabSheet2.WidthPercent := 100.000000000000000000;
    WebTabSheet2.Caption := 'Podatki o povi'#353'anih stro'#353'kih';
    WebTabSheet2.ChildOrder := 4;
    ploscicaPovisaniStroski.SetParentComponent(WebTabSheet2);
    ploscicaPovisaniStroski.Name := 'ploscicaPovisaniStroski';
    ploscicaPovisaniStroski.Left := 10;
    ploscicaPovisaniStroski.Top := 50;
    ploscicaPovisaniStroski.Width := 560;
    ploscicaPovisaniStroski.Height := 90;
    ploscicaPovisaniStroski.HeightPercent := 100.000000000000000000;
    ploscicaPovisaniStroski.WidthPercent := 100.000000000000000000;
    ploscicaPovisaniStroski.ColumnCollection.Clear;
    with ploscicaPovisaniStroski.ColumnCollection.Add do
    begin
      Value := 75;
    end;
    with ploscicaPovisaniStroski.ColumnCollection.Add do
    begin
      Alignment := taRightJustify;
      Value := 50;
    end;
    ploscicaPovisaniStroski.ControlCollection.Clear;
    with ploscicaPovisaniStroski.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izPovisaniPoslovniStroskiOpis;
    end;
    with ploscicaPovisaniStroski.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := vpPovisaniPoslovniStroskiZnesek;
    end;
    with ploscicaPovisaniStroski.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izResenPrometOpis;
    end;
    with ploscicaPovisaniStroski.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := vpResenPrometZnesek;
    end;
    with ploscicaPovisaniStroski.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := izPovisaniPoslovniStroskiObdobjeOpis;
    end;
    with ploscicaPovisaniStroski.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := vpPovisaniPoslovniStroskiObdobje;
    end;
    ploscicaPovisaniStroski.Color := clWhite;
    ploscicaPovisaniStroski.GridLineColor := clBlack;
    ploscicaPovisaniStroski.RowCollection.Clear;
    with ploscicaPovisaniStroski.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 33;
    end;
    with ploscicaPovisaniStroski.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 33;
    end;
    with ploscicaPovisaniStroski.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 33;
    end;
    izPovisaniPoslovniStroskiOpis.SetParentComponent(ploscicaPovisaniStroski);
    izPovisaniPoslovniStroskiOpis.Name := 'izPovisaniPoslovniStroskiOpis';
    izPovisaniPoslovniStroskiOpis.Left := 2;
    izPovisaniPoslovniStroskiOpis.Top := 7;
    izPovisaniPoslovniStroskiOpis.Width := 379;
    izPovisaniPoslovniStroskiOpis.Height := 15;
    izPovisaniPoslovniStroskiOpis.Anchors := [];
    izPovisaniPoslovniStroskiOpis.Caption := 'Povi'#353'ani poslovni stro'#353'ki, povezani z re'#353'evanjem prometa v '#269'asu zastoja;';
    izPovisaniPoslovniStroskiOpis.Color := clNone;
    izPovisaniPoslovniStroskiOpis.HeightPercent := 100.000000000000000000;
    izPovisaniPoslovniStroskiOpis.WidthPercent := 100.000000000000000000;
    izResenPrometOpis.SetParentComponent(ploscicaPovisaniStroski);
    izResenPrometOpis.Name := 'izResenPrometOpis';
    izResenPrometOpis.Left := 2;
    izResenPrometOpis.Top := 37;
    izResenPrometOpis.Width := 237;
    izResenPrometOpis.Height := 15;
    izResenPrometOpis.Anchors := [];
    izResenPrometOpis.Caption := 'Re'#353'en promet s povi'#353'animi poslvnimi stro'#353'ki:';
    izResenPrometOpis.Color := clNone;
    izResenPrometOpis.HeightPercent := 100.000000000000000000;
    izResenPrometOpis.WidthPercent := 100.000000000000000000;
    izPovisaniPoslovniStroskiObdobjeOpis.SetParentComponent(ploscicaPovisaniStroski);
    izPovisaniPoslovniStroskiObdobjeOpis.Name := 'izPovisaniPoslovniStroskiObdobjeOpis';
    izPovisaniPoslovniStroskiObdobjeOpis.Left := 2;
    izPovisaniPoslovniStroskiObdobjeOpis.Top := 67;
    izPovisaniPoslovniStroskiObdobjeOpis.Width := 295;
    izPovisaniPoslovniStroskiObdobjeOpis.Height := 15;
    izPovisaniPoslovniStroskiObdobjeOpis.Anchors := [];
    izPovisaniPoslovniStroskiObdobjeOpis.Caption := 'Povi'#353'ani posovni stro'#353'ki in re'#353'en promet se nana'#353'ajo na:';
    izPovisaniPoslovniStroskiObdobjeOpis.Color := clNone;
    izPovisaniPoslovniStroskiObdobjeOpis.HeightPercent := 100.000000000000000000;
    izPovisaniPoslovniStroskiObdobjeOpis.WidthPercent := 100.000000000000000000;
    vpPovisaniPoslovniStroskiZnesek.SetParentComponent(ploscicaPovisaniStroski);
    vpPovisaniPoslovniStroskiZnesek.Name := 'vpPovisaniPoslovniStroskiZnesek';
    vpPovisaniPoslovniStroskiZnesek.Left := 598;
    vpPovisaniPoslovniStroskiZnesek.Top := 4;
    vpPovisaniPoslovniStroskiZnesek.Width := 100;
    vpPovisaniPoslovniStroskiZnesek.Height := 22;
    vpPovisaniPoslovniStroskiZnesek.Alignment := taRightJustify;
    vpPovisaniPoslovniStroskiZnesek.Anchors := [];
    vpPovisaniPoslovniStroskiZnesek.ChildOrder := 1;
    vpPovisaniPoslovniStroskiZnesek.HeightPercent := 100.000000000000000000;
    vpPovisaniPoslovniStroskiZnesek.Text := '0.00';
    vpPovisaniPoslovniStroskiZnesek.WidthPercent := 100.000000000000000000;
    SetEvent(vpPovisaniPoslovniStroskiZnesek, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(vpPovisaniPoslovniStroskiZnesek, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    vpResenPrometZnesek.SetParentComponent(ploscicaPovisaniStroski);
    vpResenPrometZnesek.Name := 'vpResenPrometZnesek';
    vpResenPrometZnesek.Left := 598;
    vpResenPrometZnesek.Top := 34;
    vpResenPrometZnesek.Width := 100;
    vpResenPrometZnesek.Height := 22;
    vpResenPrometZnesek.Alignment := taRightJustify;
    vpResenPrometZnesek.Anchors := [];
    vpResenPrometZnesek.ChildOrder := 3;
    vpResenPrometZnesek.HeightPercent := 100.000000000000000000;
    vpResenPrometZnesek.Text := '0.00';
    vpResenPrometZnesek.WidthPercent := 100.000000000000000000;
    SetEvent(vpResenPrometZnesek, Self, 'OnExit', 'OblikovanjeStevilkeExit');
    SetEvent(vpResenPrometZnesek, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    vpPovisaniPoslovniStroskiObdobje.SetParentComponent(ploscicaPovisaniStroski);
    vpPovisaniPoslovniStroskiObdobje.Name := 'vpPovisaniPoslovniStroskiObdobje';
    vpPovisaniPoslovniStroskiObdobje.Left := 498;
    vpPovisaniPoslovniStroskiObdobje.Top := 63;
    vpPovisaniPoslovniStroskiObdobje.Width := 200;
    vpPovisaniPoslovniStroskiObdobje.Height := 23;
    vpPovisaniPoslovniStroskiObdobje.Anchors := [];
    vpPovisaniPoslovniStroskiObdobje.HeightPercent := 100.000000000000000000;
    vpPovisaniPoslovniStroskiObdobje.Text := 'obdobje ugotovljenega zastoja';
    vpPovisaniPoslovniStroskiObdobje.WidthPercent := 100.000000000000000000;
    vpPovisaniPoslovniStroskiObdobje.ItemIndex := 0;
    vpPovisaniPoslovniStroskiObdobje.Items.BeginUpdate;
    try
      vpPovisaniPoslovniStroskiObdobje.Items.Clear;
      vpPovisaniPoslovniStroskiObdobje.Items.Add('obdobje ugotovljenega zastoja');
      vpPovisaniPoslovniStroskiObdobje.Items.Add('obdobje maksimalne dobe jam'#269'enja');
    finally
      vpPovisaniPoslovniStroskiObdobje.Items.EndUpdate;
    end;
    WebTabSheet3.SetParentComponent(DelovniListiPodatkiZaSimulacijoSkod);
    WebTabSheet3.Name := 'WebTabSheet3';
    WebTabSheet3.Left := 0;
    WebTabSheet3.Top := 20;
    WebTabSheet3.Width := 825;
    WebTabSheet3.Height := 405;
    WebTabSheet3.HeightPercent := 100.000000000000000000;
    WebTabSheet3.WidthPercent := 100.000000000000000000;
    WebTabSheet3.Caption := 'Podatki o prihrankih';
    WebTabSheet3.ChildOrder := 5;
    izPrihrankiObdobjeOpis.SetParentComponent(WebTabSheet3);
    izPrihrankiObdobjeOpis.Name := 'izPrihrankiObdobjeOpis';
    izPrihrankiObdobjeOpis.Left := 484;
    izPrihrankiObdobjeOpis.Top := 25;
    izPrihrankiObdobjeOpis.Width := 184;
    izPrihrankiObdobjeOpis.Height := 15;
    izPrihrankiObdobjeOpis.Anchors := [];
    izPrihrankiObdobjeOpis.Caption := 'Prijavljeni prihranki se nana'#353'ajo na:';
    izPrihrankiObdobjeOpis.Color := clNone;
    izPrihrankiObdobjeOpis.HeightPercent := 100.000000000000000000;
    izPrihrankiObdobjeOpis.WidthPercent := 100.000000000000000000;
    ploscicaPrihranki.SetParentComponent(WebTabSheet3);
    ploscicaPrihranki.Name := 'ploscicaPrihranki';
    ploscicaPrihranki.Left := 11;
    ploscicaPrihranki.Top := 18;
    ploscicaPrihranki.Width := 407;
    ploscicaPrihranki.Height := 350;
    ploscicaPrihranki.HeightPercent := 100.000000000000000000;
    ploscicaPrihranki.WidthPercent := 100.000000000000000000;
    ploscicaPrihranki.ColumnCollection.Clear;
    with ploscicaPrihranki.ColumnCollection.Add do
    begin
      Alignment := taCenter;
      Value := 76;
    end;
    with ploscicaPrihranki.ColumnCollection.Add do
    begin
      Alignment := taCenter;
      Value := 24;
    end;
    ploscicaPrihranki.ControlCollection.Clear;
    with ploscicaPrihranki.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPrihranki_Naslov1;
    end;
    with ploscicaPrihranki.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPrihranki_Naslov2;
    end;
    with ploscicaPrihranki.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPrihranki_Prihranek_1_1;
    end;
    with ploscicaPrihranki.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPrihranki_Prihranek_2_1;
    end;
    with ploscicaPrihranki.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPrihranki_Prihranek_1_2;
    end;
    with ploscicaPrihranki.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPrihranki_Prihranek_2_2;
    end;
    with ploscicaPrihranki.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPrihranki_Prihranek_1_3;
    end;
    with ploscicaPrihranki.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPrihranki_Prihranek_2_3;
    end;
    with ploscicaPrihranki.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPrihranki_Prihranek_1_4;
    end;
    with ploscicaPrihranki.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPrihranki_Prihranek_2_4;
    end;
    with ploscicaPrihranki.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPrihranki_Prihranek_1_5;
    end;
    with ploscicaPrihranki.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPrihranki_Prihranek_2_5;
    end;
    with ploscicaPrihranki.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPrihranki_Prihranek_1_6;
    end;
    with ploscicaPrihranki.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPrihranki_Prihranek_2_6;
    end;
    with ploscicaPrihranki.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPrihranki_Prihranek_1_7;
    end;
    with ploscicaPrihranki.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPrihranki_Prihranek_2_7;
    end;
    with ploscicaPrihranki.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPrihranki_Prihranek_1_8;
    end;
    with ploscicaPrihranki.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPrihranki_Prihranek_2_8;
    end;
    with ploscicaPrihranki.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPrihranki_Prihranek_1_9;
    end;
    with ploscicaPrihranki.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPrihranki_Prihranek_2_9;
    end;
    with ploscicaPrihranki.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPrihranki_Prihranek_1_10;
    end;
    with ploscicaPrihranki.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPrihranki_Prihranek_2_10;
    end;
    with ploscicaPrihranki.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPrihranki_PrihranekSkupajOpis;
    end;
    with ploscicaPrihranki.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := ploscicaPrihranki_PrihranekSkupajZnesek;
    end;
    ploscicaPrihranki.Color := 15461355;
    ploscicaPrihranki.GridLineWidth := 1;
    ploscicaPrihranki.GridLineColor := clGray;
    ploscicaPrihranki.RowCollection.Clear;
    with ploscicaPrihranki.RowCollection.Add do
    begin
      Value := 8;
    end;
    with ploscicaPrihranki.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaPrihranki.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaPrihranki.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaPrihranki.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaPrihranki.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaPrihranki.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaPrihranki.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaPrihranki.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaPrihranki.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaPrihranki.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    with ploscicaPrihranki.RowCollection.Add do
    begin
      Alignment := vaCenter;
      Value := 8;
    end;
    ploscicaPrihranki_Naslov1.SetParentComponent(ploscicaPrihranki);
    ploscicaPrihranki_Naslov1.Name := 'ploscicaPrihranki_Naslov1';
    ploscicaPrihranki_Naslov1.Left := 104;
    ploscicaPrihranki_Naslov1.Top := 2;
    ploscicaPrihranki_Naslov1.Width := 101;
    ploscicaPrihranki_Naslov1.Height := 15;
    ploscicaPrihranki_Naslov1.Anchors := [];
    ploscicaPrihranki_Naslov1.Caption := 'prijavljeni prihranki';
    ploscicaPrihranki_Naslov1.Color := clNone;
    ploscicaPrihranki_Naslov1.HeightPercent := 100.000000000000000000;
    ploscicaPrihranki_Naslov1.WidthPercent := 100.000000000000000000;
    ploscicaPrihranki_Naslov2.SetParentComponent(ploscicaPrihranki);
    ploscicaPrihranki_Naslov2.Name := 'ploscicaPrihranki_Naslov2';
    ploscicaPrihranki_Naslov2.Left := 320;
    ploscicaPrihranki_Naslov2.Top := 2;
    ploscicaPrihranki_Naslov2.Width := 76;
    ploscicaPrihranki_Naslov2.Height := 15;
    ploscicaPrihranki_Naslov2.Anchors := [];
    ploscicaPrihranki_Naslov2.Caption := 'vi'#353'ina (znesek)';
    ploscicaPrihranki_Naslov2.Color := clNone;
    ploscicaPrihranki_Naslov2.HeightPercent := 100.000000000000000000;
    ploscicaPrihranki_Naslov2.WidthPercent := 100.000000000000000000;
    ploscicaPrihranki_Prihranek_1_1.SetParentComponent(ploscicaPrihranki);
    ploscicaPrihranki_Prihranek_1_1.Name := 'ploscicaPrihranki_Prihranek_1_1';
    ploscicaPrihranki_Prihranek_1_1.Left := 5;
    ploscicaPrihranki_Prihranek_1_1.Top := 29;
    ploscicaPrihranki_Prihranek_1_1.Width := 298;
    ploscicaPrihranki_Prihranek_1_1.Height := 26;
    ploscicaPrihranki_Prihranek_1_1.Anchors := [];
    ploscicaPrihranki_Prihranek_1_1.BorderStyle := bsNone;
    ploscicaPrihranki_Prihranek_1_1.ChildOrder := 2;
    ploscicaPrihranki_Prihranek_1_1.HeightPercent := 100.000000000000000000;
    ploscicaPrihranki_Prihranek_1_1.WidthPercent := 100.000000000000000000;
    ploscicaPrihranki_Prihranek_2_1.SetParentComponent(ploscicaPrihranki);
    ploscicaPrihranki_Prihranek_2_1.Name := 'ploscicaPrihranki_Prihranek_2_1';
    ploscicaPrihranki_Prihranek_2_1.Left := 308;
    ploscicaPrihranki_Prihranek_2_1.Top := 29;
    ploscicaPrihranki_Prihranek_2_1.Width := 101;
    ploscicaPrihranki_Prihranek_2_1.Height := 26;
    ploscicaPrihranki_Prihranek_2_1.Alignment := taRightJustify;
    ploscicaPrihranki_Prihranek_2_1.Anchors := [];
    ploscicaPrihranki_Prihranek_2_1.BorderStyle := bsNone;
    ploscicaPrihranki_Prihranek_2_1.ChildOrder := 3;
    ploscicaPrihranki_Prihranek_2_1.HeightPercent := 100.000000000000000000;
    ploscicaPrihranki_Prihranek_2_1.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPrihranki_Prihranek_2_1, Self, 'OnExit', 'ploscicaPrihranki_PrihranekExit');
    SetEvent(ploscicaPrihranki_Prihranek_2_1, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPrihranki_Prihranek_1_2.SetParentComponent(ploscicaPrihranki);
    ploscicaPrihranki_Prihranek_1_2.Name := 'ploscicaPrihranki_Prihranek_1_2';
    ploscicaPrihranki_Prihranek_1_2.Left := 5;
    ploscicaPrihranki_Prihranek_1_2.Top := 57;
    ploscicaPrihranki_Prihranek_1_2.Width := 298;
    ploscicaPrihranki_Prihranek_1_2.Height := 26;
    ploscicaPrihranki_Prihranek_1_2.Anchors := [];
    ploscicaPrihranki_Prihranek_1_2.BorderStyle := bsNone;
    ploscicaPrihranki_Prihranek_1_2.ChildOrder := 4;
    ploscicaPrihranki_Prihranek_1_2.HeightPercent := 100.000000000000000000;
    ploscicaPrihranki_Prihranek_1_2.WidthPercent := 100.000000000000000000;
    ploscicaPrihranki_Prihranek_2_2.SetParentComponent(ploscicaPrihranki);
    ploscicaPrihranki_Prihranek_2_2.Name := 'ploscicaPrihranki_Prihranek_2_2';
    ploscicaPrihranki_Prihranek_2_2.Left := 308;
    ploscicaPrihranki_Prihranek_2_2.Top := 57;
    ploscicaPrihranki_Prihranek_2_2.Width := 101;
    ploscicaPrihranki_Prihranek_2_2.Height := 26;
    ploscicaPrihranki_Prihranek_2_2.Alignment := taRightJustify;
    ploscicaPrihranki_Prihranek_2_2.Anchors := [];
    ploscicaPrihranki_Prihranek_2_2.BorderStyle := bsNone;
    ploscicaPrihranki_Prihranek_2_2.ChildOrder := 5;
    ploscicaPrihranki_Prihranek_2_2.HeightPercent := 100.000000000000000000;
    ploscicaPrihranki_Prihranek_2_2.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPrihranki_Prihranek_2_2, Self, 'OnExit', 'ploscicaPrihranki_PrihranekExit');
    SetEvent(ploscicaPrihranki_Prihranek_2_2, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPrihranki_Prihranek_1_3.SetParentComponent(ploscicaPrihranki);
    ploscicaPrihranki_Prihranek_1_3.Name := 'ploscicaPrihranki_Prihranek_1_3';
    ploscicaPrihranki_Prihranek_1_3.Left := 5;
    ploscicaPrihranki_Prihranek_1_3.Top := 85;
    ploscicaPrihranki_Prihranek_1_3.Width := 298;
    ploscicaPrihranki_Prihranek_1_3.Height := 26;
    ploscicaPrihranki_Prihranek_1_3.Anchors := [];
    ploscicaPrihranki_Prihranek_1_3.BorderStyle := bsNone;
    ploscicaPrihranki_Prihranek_1_3.ChildOrder := 6;
    ploscicaPrihranki_Prihranek_1_3.HeightPercent := 100.000000000000000000;
    ploscicaPrihranki_Prihranek_1_3.WidthPercent := 100.000000000000000000;
    ploscicaPrihranki_Prihranek_2_3.SetParentComponent(ploscicaPrihranki);
    ploscicaPrihranki_Prihranek_2_3.Name := 'ploscicaPrihranki_Prihranek_2_3';
    ploscicaPrihranki_Prihranek_2_3.Left := 308;
    ploscicaPrihranki_Prihranek_2_3.Top := 85;
    ploscicaPrihranki_Prihranek_2_3.Width := 101;
    ploscicaPrihranki_Prihranek_2_3.Height := 26;
    ploscicaPrihranki_Prihranek_2_3.Alignment := taRightJustify;
    ploscicaPrihranki_Prihranek_2_3.Anchors := [];
    ploscicaPrihranki_Prihranek_2_3.BorderStyle := bsNone;
    ploscicaPrihranki_Prihranek_2_3.ChildOrder := 7;
    ploscicaPrihranki_Prihranek_2_3.HeightPercent := 100.000000000000000000;
    ploscicaPrihranki_Prihranek_2_3.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPrihranki_Prihranek_2_3, Self, 'OnExit', 'ploscicaPrihranki_PrihranekExit');
    SetEvent(ploscicaPrihranki_Prihranek_2_3, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPrihranki_Prihranek_1_4.SetParentComponent(ploscicaPrihranki);
    ploscicaPrihranki_Prihranek_1_4.Name := 'ploscicaPrihranki_Prihranek_1_4';
    ploscicaPrihranki_Prihranek_1_4.Left := 5;
    ploscicaPrihranki_Prihranek_1_4.Top := 113;
    ploscicaPrihranki_Prihranek_1_4.Width := 298;
    ploscicaPrihranki_Prihranek_1_4.Height := 26;
    ploscicaPrihranki_Prihranek_1_4.Anchors := [];
    ploscicaPrihranki_Prihranek_1_4.BorderStyle := bsNone;
    ploscicaPrihranki_Prihranek_1_4.ChildOrder := 8;
    ploscicaPrihranki_Prihranek_1_4.HeightPercent := 100.000000000000000000;
    ploscicaPrihranki_Prihranek_1_4.WidthPercent := 100.000000000000000000;
    ploscicaPrihranki_Prihranek_2_4.SetParentComponent(ploscicaPrihranki);
    ploscicaPrihranki_Prihranek_2_4.Name := 'ploscicaPrihranki_Prihranek_2_4';
    ploscicaPrihranki_Prihranek_2_4.Left := 308;
    ploscicaPrihranki_Prihranek_2_4.Top := 113;
    ploscicaPrihranki_Prihranek_2_4.Width := 101;
    ploscicaPrihranki_Prihranek_2_4.Height := 26;
    ploscicaPrihranki_Prihranek_2_4.Alignment := taRightJustify;
    ploscicaPrihranki_Prihranek_2_4.Anchors := [];
    ploscicaPrihranki_Prihranek_2_4.BorderStyle := bsNone;
    ploscicaPrihranki_Prihranek_2_4.ChildOrder := 9;
    ploscicaPrihranki_Prihranek_2_4.HeightPercent := 100.000000000000000000;
    ploscicaPrihranki_Prihranek_2_4.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPrihranki_Prihranek_2_4, Self, 'OnExit', 'ploscicaPrihranki_PrihranekExit');
    SetEvent(ploscicaPrihranki_Prihranek_2_4, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPrihranki_Prihranek_1_5.SetParentComponent(ploscicaPrihranki);
    ploscicaPrihranki_Prihranek_1_5.Name := 'ploscicaPrihranki_Prihranek_1_5';
    ploscicaPrihranki_Prihranek_1_5.Left := 5;
    ploscicaPrihranki_Prihranek_1_5.Top := 141;
    ploscicaPrihranki_Prihranek_1_5.Width := 298;
    ploscicaPrihranki_Prihranek_1_5.Height := 26;
    ploscicaPrihranki_Prihranek_1_5.Anchors := [];
    ploscicaPrihranki_Prihranek_1_5.BorderStyle := bsNone;
    ploscicaPrihranki_Prihranek_1_5.ChildOrder := 10;
    ploscicaPrihranki_Prihranek_1_5.HeightPercent := 100.000000000000000000;
    ploscicaPrihranki_Prihranek_1_5.WidthPercent := 100.000000000000000000;
    ploscicaPrihranki_Prihranek_2_5.SetParentComponent(ploscicaPrihranki);
    ploscicaPrihranki_Prihranek_2_5.Name := 'ploscicaPrihranki_Prihranek_2_5';
    ploscicaPrihranki_Prihranek_2_5.Left := 308;
    ploscicaPrihranki_Prihranek_2_5.Top := 141;
    ploscicaPrihranki_Prihranek_2_5.Width := 101;
    ploscicaPrihranki_Prihranek_2_5.Height := 26;
    ploscicaPrihranki_Prihranek_2_5.Alignment := taRightJustify;
    ploscicaPrihranki_Prihranek_2_5.Anchors := [];
    ploscicaPrihranki_Prihranek_2_5.BorderStyle := bsNone;
    ploscicaPrihranki_Prihranek_2_5.ChildOrder := 11;
    ploscicaPrihranki_Prihranek_2_5.HeightPercent := 100.000000000000000000;
    ploscicaPrihranki_Prihranek_2_5.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPrihranki_Prihranek_2_5, Self, 'OnExit', 'ploscicaPrihranki_PrihranekExit');
    SetEvent(ploscicaPrihranki_Prihranek_2_5, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPrihranki_Prihranek_1_6.SetParentComponent(ploscicaPrihranki);
    ploscicaPrihranki_Prihranek_1_6.Name := 'ploscicaPrihranki_Prihranek_1_6';
    ploscicaPrihranki_Prihranek_1_6.Left := 5;
    ploscicaPrihranki_Prihranek_1_6.Top := 169;
    ploscicaPrihranki_Prihranek_1_6.Width := 298;
    ploscicaPrihranki_Prihranek_1_6.Height := 26;
    ploscicaPrihranki_Prihranek_1_6.Anchors := [];
    ploscicaPrihranki_Prihranek_1_6.BorderStyle := bsNone;
    ploscicaPrihranki_Prihranek_1_6.ChildOrder := 12;
    ploscicaPrihranki_Prihranek_1_6.HeightPercent := 100.000000000000000000;
    ploscicaPrihranki_Prihranek_1_6.WidthPercent := 100.000000000000000000;
    ploscicaPrihranki_Prihranek_2_6.SetParentComponent(ploscicaPrihranki);
    ploscicaPrihranki_Prihranek_2_6.Name := 'ploscicaPrihranki_Prihranek_2_6';
    ploscicaPrihranki_Prihranek_2_6.Left := 308;
    ploscicaPrihranki_Prihranek_2_6.Top := 169;
    ploscicaPrihranki_Prihranek_2_6.Width := 101;
    ploscicaPrihranki_Prihranek_2_6.Height := 26;
    ploscicaPrihranki_Prihranek_2_6.Alignment := taRightJustify;
    ploscicaPrihranki_Prihranek_2_6.Anchors := [];
    ploscicaPrihranki_Prihranek_2_6.BorderStyle := bsNone;
    ploscicaPrihranki_Prihranek_2_6.ChildOrder := 13;
    ploscicaPrihranki_Prihranek_2_6.HeightPercent := 100.000000000000000000;
    ploscicaPrihranki_Prihranek_2_6.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPrihranki_Prihranek_2_6, Self, 'OnExit', 'ploscicaPrihranki_PrihranekExit');
    SetEvent(ploscicaPrihranki_Prihranek_2_6, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPrihranki_Prihranek_1_7.SetParentComponent(ploscicaPrihranki);
    ploscicaPrihranki_Prihranek_1_7.Name := 'ploscicaPrihranki_Prihranek_1_7';
    ploscicaPrihranki_Prihranek_1_7.Left := 5;
    ploscicaPrihranki_Prihranek_1_7.Top := 197;
    ploscicaPrihranki_Prihranek_1_7.Width := 298;
    ploscicaPrihranki_Prihranek_1_7.Height := 26;
    ploscicaPrihranki_Prihranek_1_7.Anchors := [];
    ploscicaPrihranki_Prihranek_1_7.BorderStyle := bsNone;
    ploscicaPrihranki_Prihranek_1_7.ChildOrder := 14;
    ploscicaPrihranki_Prihranek_1_7.HeightPercent := 100.000000000000000000;
    ploscicaPrihranki_Prihranek_1_7.WidthPercent := 100.000000000000000000;
    ploscicaPrihranki_Prihranek_2_7.SetParentComponent(ploscicaPrihranki);
    ploscicaPrihranki_Prihranek_2_7.Name := 'ploscicaPrihranki_Prihranek_2_7';
    ploscicaPrihranki_Prihranek_2_7.Left := 308;
    ploscicaPrihranki_Prihranek_2_7.Top := 197;
    ploscicaPrihranki_Prihranek_2_7.Width := 101;
    ploscicaPrihranki_Prihranek_2_7.Height := 26;
    ploscicaPrihranki_Prihranek_2_7.Alignment := taRightJustify;
    ploscicaPrihranki_Prihranek_2_7.Anchors := [];
    ploscicaPrihranki_Prihranek_2_7.BorderStyle := bsNone;
    ploscicaPrihranki_Prihranek_2_7.ChildOrder := 15;
    ploscicaPrihranki_Prihranek_2_7.HeightPercent := 100.000000000000000000;
    ploscicaPrihranki_Prihranek_2_7.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPrihranki_Prihranek_2_7, Self, 'OnExit', 'ploscicaPrihranki_PrihranekExit');
    SetEvent(ploscicaPrihranki_Prihranek_2_7, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPrihranki_Prihranek_1_8.SetParentComponent(ploscicaPrihranki);
    ploscicaPrihranki_Prihranek_1_8.Name := 'ploscicaPrihranki_Prihranek_1_8';
    ploscicaPrihranki_Prihranek_1_8.Left := 5;
    ploscicaPrihranki_Prihranek_1_8.Top := 225;
    ploscicaPrihranki_Prihranek_1_8.Width := 298;
    ploscicaPrihranki_Prihranek_1_8.Height := 26;
    ploscicaPrihranki_Prihranek_1_8.Anchors := [];
    ploscicaPrihranki_Prihranek_1_8.BorderStyle := bsNone;
    ploscicaPrihranki_Prihranek_1_8.ChildOrder := 16;
    ploscicaPrihranki_Prihranek_1_8.HeightPercent := 100.000000000000000000;
    ploscicaPrihranki_Prihranek_1_8.WidthPercent := 100.000000000000000000;
    ploscicaPrihranki_Prihranek_2_8.SetParentComponent(ploscicaPrihranki);
    ploscicaPrihranki_Prihranek_2_8.Name := 'ploscicaPrihranki_Prihranek_2_8';
    ploscicaPrihranki_Prihranek_2_8.Left := 308;
    ploscicaPrihranki_Prihranek_2_8.Top := 225;
    ploscicaPrihranki_Prihranek_2_8.Width := 101;
    ploscicaPrihranki_Prihranek_2_8.Height := 26;
    ploscicaPrihranki_Prihranek_2_8.Alignment := taRightJustify;
    ploscicaPrihranki_Prihranek_2_8.Anchors := [];
    ploscicaPrihranki_Prihranek_2_8.BorderStyle := bsNone;
    ploscicaPrihranki_Prihranek_2_8.ChildOrder := 17;
    ploscicaPrihranki_Prihranek_2_8.HeightPercent := 100.000000000000000000;
    ploscicaPrihranki_Prihranek_2_8.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPrihranki_Prihranek_2_8, Self, 'OnExit', 'ploscicaPrihranki_PrihranekExit');
    SetEvent(ploscicaPrihranki_Prihranek_2_8, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPrihranki_Prihranek_1_9.SetParentComponent(ploscicaPrihranki);
    ploscicaPrihranki_Prihranek_1_9.Name := 'ploscicaPrihranki_Prihranek_1_9';
    ploscicaPrihranki_Prihranek_1_9.Left := 5;
    ploscicaPrihranki_Prihranek_1_9.Top := 253;
    ploscicaPrihranki_Prihranek_1_9.Width := 298;
    ploscicaPrihranki_Prihranek_1_9.Height := 26;
    ploscicaPrihranki_Prihranek_1_9.Anchors := [];
    ploscicaPrihranki_Prihranek_1_9.BorderStyle := bsNone;
    ploscicaPrihranki_Prihranek_1_9.ChildOrder := 18;
    ploscicaPrihranki_Prihranek_1_9.HeightPercent := 100.000000000000000000;
    ploscicaPrihranki_Prihranek_1_9.WidthPercent := 100.000000000000000000;
    ploscicaPrihranki_Prihranek_2_9.SetParentComponent(ploscicaPrihranki);
    ploscicaPrihranki_Prihranek_2_9.Name := 'ploscicaPrihranki_Prihranek_2_9';
    ploscicaPrihranki_Prihranek_2_9.Left := 308;
    ploscicaPrihranki_Prihranek_2_9.Top := 253;
    ploscicaPrihranki_Prihranek_2_9.Width := 101;
    ploscicaPrihranki_Prihranek_2_9.Height := 26;
    ploscicaPrihranki_Prihranek_2_9.Alignment := taRightJustify;
    ploscicaPrihranki_Prihranek_2_9.Anchors := [];
    ploscicaPrihranki_Prihranek_2_9.BorderStyle := bsNone;
    ploscicaPrihranki_Prihranek_2_9.ChildOrder := 19;
    ploscicaPrihranki_Prihranek_2_9.HeightPercent := 100.000000000000000000;
    ploscicaPrihranki_Prihranek_2_9.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPrihranki_Prihranek_2_9, Self, 'OnExit', 'ploscicaPrihranki_PrihranekExit');
    SetEvent(ploscicaPrihranki_Prihranek_2_9, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPrihranki_Prihranek_1_10.SetParentComponent(ploscicaPrihranki);
    ploscicaPrihranki_Prihranek_1_10.Name := 'ploscicaPrihranki_Prihranek_1_10';
    ploscicaPrihranki_Prihranek_1_10.Left := 5;
    ploscicaPrihranki_Prihranek_1_10.Top := 281;
    ploscicaPrihranki_Prihranek_1_10.Width := 298;
    ploscicaPrihranki_Prihranek_1_10.Height := 26;
    ploscicaPrihranki_Prihranek_1_10.Anchors := [];
    ploscicaPrihranki_Prihranek_1_10.BorderStyle := bsNone;
    ploscicaPrihranki_Prihranek_1_10.ChildOrder := 20;
    ploscicaPrihranki_Prihranek_1_10.HeightPercent := 100.000000000000000000;
    ploscicaPrihranki_Prihranek_1_10.WidthPercent := 100.000000000000000000;
    ploscicaPrihranki_Prihranek_2_10.SetParentComponent(ploscicaPrihranki);
    ploscicaPrihranki_Prihranek_2_10.Name := 'ploscicaPrihranki_Prihranek_2_10';
    ploscicaPrihranki_Prihranek_2_10.Left := 308;
    ploscicaPrihranki_Prihranek_2_10.Top := 281;
    ploscicaPrihranki_Prihranek_2_10.Width := 101;
    ploscicaPrihranki_Prihranek_2_10.Height := 26;
    ploscicaPrihranki_Prihranek_2_10.Alignment := taRightJustify;
    ploscicaPrihranki_Prihranek_2_10.Anchors := [];
    ploscicaPrihranki_Prihranek_2_10.BorderStyle := bsNone;
    ploscicaPrihranki_Prihranek_2_10.ChildOrder := 21;
    ploscicaPrihranki_Prihranek_2_10.HeightPercent := 100.000000000000000000;
    ploscicaPrihranki_Prihranek_2_10.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPrihranki_Prihranek_2_10, Self, 'OnExit', 'ploscicaPrihranki_PrihranekExit');
    SetEvent(ploscicaPrihranki_Prihranek_2_10, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    ploscicaPrihranki_PrihranekSkupajOpis.SetParentComponent(ploscicaPrihranki);
    ploscicaPrihranki_PrihranekSkupajOpis.Name := 'ploscicaPrihranki_PrihranekSkupajOpis';
    ploscicaPrihranki_PrihranekSkupajOpis.Left := 5;
    ploscicaPrihranki_PrihranekSkupajOpis.Top := 309;
    ploscicaPrihranki_PrihranekSkupajOpis.Width := 298;
    ploscicaPrihranki_PrihranekSkupajOpis.Height := 26;
    ploscicaPrihranki_PrihranekSkupajOpis.TabStop := False;
    ploscicaPrihranki_PrihranekSkupajOpis.Anchors := [];
    ploscicaPrihranki_PrihranekSkupajOpis.AutoSelect := False;
    ploscicaPrihranki_PrihranekSkupajOpis.BorderStyle := bsNone;
    ploscicaPrihranki_PrihranekSkupajOpis.ChildOrder := 22;
    ploscicaPrihranki_PrihranekSkupajOpis.Font.Charset := DEFAULT_CHARSET;
    ploscicaPrihranki_PrihranekSkupajOpis.Font.Color := clWindowText;
    ploscicaPrihranki_PrihranekSkupajOpis.Font.Height := -11;
    ploscicaPrihranki_PrihranekSkupajOpis.Font.Name := 'Arial';
    ploscicaPrihranki_PrihranekSkupajOpis.Font.Style := [fsBold];
    ploscicaPrihranki_PrihranekSkupajOpis.HeightPercent := 100.000000000000000000;
    ploscicaPrihranki_PrihranekSkupajOpis.ParentFont := False;
    ploscicaPrihranki_PrihranekSkupajOpis.ReadOnly := True;
    ploscicaPrihranki_PrihranekSkupajOpis.ShowFocus := False;
    ploscicaPrihranki_PrihranekSkupajOpis.Text := 'Skupaj prijavljeni prihranki:';
    ploscicaPrihranki_PrihranekSkupajOpis.WidthPercent := 100.000000000000000000;
    ploscicaPrihranki_PrihranekSkupajZnesek.SetParentComponent(ploscicaPrihranki);
    ploscicaPrihranki_PrihranekSkupajZnesek.Name := 'ploscicaPrihranki_PrihranekSkupajZnesek';
    ploscicaPrihranki_PrihranekSkupajZnesek.Left := 308;
    ploscicaPrihranki_PrihranekSkupajZnesek.Top := 309;
    ploscicaPrihranki_PrihranekSkupajZnesek.Width := 101;
    ploscicaPrihranki_PrihranekSkupajZnesek.Height := 26;
    ploscicaPrihranki_PrihranekSkupajZnesek.TabStop := False;
    ploscicaPrihranki_PrihranekSkupajZnesek.Alignment := taRightJustify;
    ploscicaPrihranki_PrihranekSkupajZnesek.Anchors := [];
    ploscicaPrihranki_PrihranekSkupajZnesek.AutoSelect := False;
    ploscicaPrihranki_PrihranekSkupajZnesek.BorderStyle := bsNone;
    ploscicaPrihranki_PrihranekSkupajZnesek.ChildOrder := 23;
    ploscicaPrihranki_PrihranekSkupajZnesek.Font.Charset := DEFAULT_CHARSET;
    ploscicaPrihranki_PrihranekSkupajZnesek.Font.Color := clWindowText;
    ploscicaPrihranki_PrihranekSkupajZnesek.Font.Height := -11;
    ploscicaPrihranki_PrihranekSkupajZnesek.Font.Name := 'Arial';
    ploscicaPrihranki_PrihranekSkupajZnesek.Font.Style := [fsBold];
    ploscicaPrihranki_PrihranekSkupajZnesek.HeightPercent := 100.000000000000000000;
    ploscicaPrihranki_PrihranekSkupajZnesek.ParentFont := False;
    ploscicaPrihranki_PrihranekSkupajZnesek.ReadOnly := True;
    ploscicaPrihranki_PrihranekSkupajZnesek.ShowFocus := False;
    ploscicaPrihranki_PrihranekSkupajZnesek.WidthPercent := 100.000000000000000000;
    SetEvent(ploscicaPrihranki_PrihranekSkupajZnesek, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    vpPrihrankiObdobje.SetParentComponent(WebTabSheet3);
    vpPrihrankiObdobje.Name := 'vpPrihrankiObdobje';
    vpPrihrankiObdobje.Left := 484;
    vpPrihrankiObdobje.Top := 50;
    vpPrihrankiObdobje.Width := 243;
    vpPrihrankiObdobje.Height := 23;
    vpPrihrankiObdobje.Anchors := [];
    vpPrihrankiObdobje.HeightPercent := 100.000000000000000000;
    vpPrihrankiObdobje.Text := 'obdobje ugotovljenega zastoja';
    vpPrihrankiObdobje.WidthPercent := 100.000000000000000000;
    vpPrihrankiObdobje.ItemIndex := 0;
    vpPrihrankiObdobje.Items.BeginUpdate;
    try
      vpPrihrankiObdobje.Items.Clear;
      vpPrihrankiObdobje.Items.Add('obdobje ugotovljenega zastoja');
      vpPrihrankiObdobje.Items.Add('obdobje maksimalne dobe jam'#269'enja');
    finally
      vpPrihrankiObdobje.Items.EndUpdate;
    end;
    gShraniSkodo.SetParentComponent(ploscicaPodatkiZaSimulacijoSkode);
    gShraniSkodo.Name := 'gShraniSkodo';
    gShraniSkodo.Left := 732;
    gShraniSkodo.Top := 0;
    gShraniSkodo.Width := 93;
    gShraniSkodo.Height := 16;
    gShraniSkodo.Caption := 'Shrani '#353'kodo';
    gShraniSkodo.Color := clNone;
    gShraniSkodo.Flat := True;
    gShraniSkodo.Glyph.LoadFromFile('MBI_u01.ploscicaPodatkiZaSimulacijoSkode.gShraniSkodo.Glyph.png');
    gShraniSkodo.HeightPercent := 100.000000000000000000;
    gShraniSkodo.WidthPercent := 100.000000000000000000;
    SetEvent(gShraniSkodo, Self, 'OnClick', 'gShraniSkodoClick');
    gNaloziSkodo.SetParentComponent(ploscicaPodatkiZaSimulacijoSkode);
    gNaloziSkodo.Name := 'gNaloziSkodo';
    gNaloziSkodo.Left := 633;
    gNaloziSkodo.Top := 0;
    gNaloziSkodo.Width := 93;
    gNaloziSkodo.Height := 16;
    gNaloziSkodo.Caption := 'Nalo'#382'i '#353'kodo';
    gNaloziSkodo.Color := clNone;
    gNaloziSkodo.Flat := True;
    gNaloziSkodo.Glyph.LoadFromFile('MBI_u01.ploscicaPodatkiZaSimulacijoSkode.gNaloziSkodo.Glyph.png');
    gNaloziSkodo.HeightPercent := 100.000000000000000000;
    gNaloziSkodo.MaterialGlyphSize := 14;
    gNaloziSkodo.WidthPercent := 100.000000000000000000;
    SetEvent(gNaloziSkodo, Self, 'OnClick', 'gNaloziSkodoClick');
    ploscicaSimulacijaSkode_PodatkiOZavarovanju.SetParentComponent(WebScrollBoxDL10);
    ploscicaSimulacijaSkode_PodatkiOZavarovanju.Name := 'ploscicaSimulacijaSkode_PodatkiOZavarovanju';
    ploscicaSimulacijaSkode_PodatkiOZavarovanju.Left := 15;
    ploscicaSimulacijaSkode_PodatkiOZavarovanju.Top := 80;
    ploscicaSimulacijaSkode_PodatkiOZavarovanju.Width := 458;
    ploscicaSimulacijaSkode_PodatkiOZavarovanju.Height := 170;
    ploscicaSimulacijaSkode_PodatkiOZavarovanju.HeightPercent := 100.000000000000000000;
    ploscicaSimulacijaSkode_PodatkiOZavarovanju.WidthPercent := 100.000000000000000000;
    ploscicaSimulacijaSkode_PodatkiOZavarovanju.BorderColor := clSilver;
    ploscicaSimulacijaSkode_PodatkiOZavarovanju.BorderStyle := bsSingle;
    ploscicaSimulacijaSkode_PodatkiOZavarovanju.ChildOrder := 1;
    ploscicaSimulacijaSkode_PodatkiOZavarovanju.Color := clWindow;
    izPodatkiOZavarovanju_Naslov.SetParentComponent(ploscicaSimulacijaSkode_PodatkiOZavarovanju);
    izPodatkiOZavarovanju_Naslov.Name := 'izPodatkiOZavarovanju_Naslov';
    izPodatkiOZavarovanju_Naslov.Left := 10;
    izPodatkiOZavarovanju_Naslov.Top := 10;
    izPodatkiOZavarovanju_Naslov.Width := 119;
    izPodatkiOZavarovanju_Naslov.Height := 14;
    izPodatkiOZavarovanju_Naslov.Caption := 'Podatki o zavarovanju';
    izPodatkiOZavarovanju_Naslov.Color := clNone;
    izPodatkiOZavarovanju_Naslov.Font.Charset := DEFAULT_CHARSET;
    izPodatkiOZavarovanju_Naslov.Font.Color := clWindowText;
    izPodatkiOZavarovanju_Naslov.Font.Height := -11;
    izPodatkiOZavarovanju_Naslov.Font.Name := 'Arial';
    izPodatkiOZavarovanju_Naslov.Font.Style := [fsBold];
    izPodatkiOZavarovanju_Naslov.HeightPercent := 100.000000000000000000;
    izPodatkiOZavarovanju_Naslov.ParentFont := False;
    izPodatkiOZavarovanju_Naslov.WidthPercent := 100.000000000000000000;
    izPodatkioZavarovanju_Od.SetParentComponent(ploscicaSimulacijaSkode_PodatkiOZavarovanju);
    izPodatkioZavarovanju_Od.Name := 'izPodatkioZavarovanju_Od';
    izPodatkioZavarovanju_Od.Left := 10;
    izPodatkioZavarovanju_Od.Top := 49;
    izPodatkioZavarovanju_Od.Width := 116;
    izPodatkioZavarovanju_Od.Height := 15;
    izPodatkioZavarovanju_Od.Caption := 'Zavarovalno leto (od):';
    izPodatkioZavarovanju_Od.Color := clNone;
    izPodatkioZavarovanju_Od.HeightPercent := 100.000000000000000000;
    izPodatkioZavarovanju_Od.WidthPercent := 100.000000000000000000;
    izPodatkioZavarovanju_Do.SetParentComponent(ploscicaSimulacijaSkode_PodatkiOZavarovanju);
    izPodatkioZavarovanju_Do.Name := 'izPodatkioZavarovanju_Do';
    izPodatkioZavarovanju_Do.Left := 285;
    izPodatkioZavarovanju_Do.Top := 51;
    izPodatkioZavarovanju_Do.Width := 25;
    izPodatkioZavarovanju_Do.Height := 15;
    izPodatkioZavarovanju_Do.Caption := '(do):';
    izPodatkioZavarovanju_Do.Color := clNone;
    izPodatkioZavarovanju_Do.HeightPercent := 100.000000000000000000;
    izPodatkioZavarovanju_Do.WidthPercent := 100.000000000000000000;
    izPodatkioZavarovanju_ZavarovalnaVsota.SetParentComponent(ploscicaSimulacijaSkode_PodatkiOZavarovanju);
    izPodatkioZavarovanju_ZavarovalnaVsota.Name := 'izPodatkioZavarovanju_ZavarovalnaVsota';
    izPodatkioZavarovanju_ZavarovalnaVsota.Left := 10;
    izPodatkioZavarovanju_ZavarovalnaVsota.Top := 79;
    izPodatkioZavarovanju_ZavarovalnaVsota.Width := 98;
    izPodatkioZavarovanju_ZavarovalnaVsota.Height := 15;
    izPodatkioZavarovanju_ZavarovalnaVsota.Caption := 'Zavarovalna vsota:';
    izPodatkioZavarovanju_ZavarovalnaVsota.Color := clNone;
    izPodatkioZavarovanju_ZavarovalnaVsota.HeightPercent := 100.000000000000000000;
    izPodatkioZavarovanju_ZavarovalnaVsota.WidthPercent := 100.000000000000000000;
    izPodatkioZavarovanju_MaksimalnaDobaJamcenja.SetParentComponent(ploscicaSimulacijaSkode_PodatkiOZavarovanju);
    izPodatkioZavarovanju_MaksimalnaDobaJamcenja.Name := 'izPodatkioZavarovanju_MaksimalnaDobaJamcenja';
    izPodatkioZavarovanju_MaksimalnaDobaJamcenja.Left := 10;
    izPodatkioZavarovanju_MaksimalnaDobaJamcenja.Top := 108;
    izPodatkioZavarovanju_MaksimalnaDobaJamcenja.Width := 148;
    izPodatkioZavarovanju_MaksimalnaDobaJamcenja.Height := 15;
    izPodatkioZavarovanju_MaksimalnaDobaJamcenja.Caption := 'Maksimalna doba jam'#269'enja:';
    izPodatkioZavarovanju_MaksimalnaDobaJamcenja.Color := clNone;
    izPodatkioZavarovanju_MaksimalnaDobaJamcenja.HeightPercent := 100.000000000000000000;
    izPodatkioZavarovanju_MaksimalnaDobaJamcenja.WidthPercent := 100.000000000000000000;
    izPodatkioZavarovanju_Premija.SetParentComponent(ploscicaSimulacijaSkode_PodatkiOZavarovanju);
    izPodatkioZavarovanju_Premija.Name := 'izPodatkioZavarovanju_Premija';
    izPodatkioZavarovanju_Premija.Left := 10;
    izPodatkioZavarovanju_Premija.Top := 139;
    izPodatkioZavarovanju_Premija.Width := 140;
    izPodatkioZavarovanju_Premija.Height := 15;
    izPodatkioZavarovanju_Premija.Caption := 'Letna zavarovalna premija:';
    izPodatkioZavarovanju_Premija.Color := clNone;
    izPodatkioZavarovanju_Premija.HeightPercent := 100.000000000000000000;
    izPodatkioZavarovanju_Premija.WidthPercent := 100.000000000000000000;
    izMaksimalnaDobaJamcenjaTekst.SetParentComponent(ploscicaSimulacijaSkode_PodatkiOZavarovanju);
    izMaksimalnaDobaJamcenjaTekst.Name := 'izMaksimalnaDobaJamcenjaTekst';
    izMaksimalnaDobaJamcenjaTekst.Left := 210;
    izMaksimalnaDobaJamcenjaTekst.Top := 109;
    izMaksimalnaDobaJamcenjaTekst.Width := 65;
    izMaksimalnaDobaJamcenjaTekst.Height := 15;
    izMaksimalnaDobaJamcenjaTekst.Caption := 'mesec(e/ev)';
    izMaksimalnaDobaJamcenjaTekst.Color := clNone;
    izMaksimalnaDobaJamcenjaTekst.HeightPercent := 100.000000000000000000;
    izMaksimalnaDobaJamcenjaTekst.WidthPercent := 100.000000000000000000;
    vpZavarovanjeOd.SetParentComponent(ploscicaSimulacijaSkode_PodatkiOZavarovanju);
    vpZavarovanjeOd.Name := 'vpZavarovanjeOd';
    vpZavarovanjeOd.Left := 158;
    vpZavarovanjeOd.Top := 45;
    vpZavarovanjeOd.Width := 115;
    vpZavarovanjeOd.Height := 22;
    vpZavarovanjeOd.HeightPercent := 100.000000000000000000;
    vpZavarovanjeOd.WidthPercent := 100.000000000000000000;
    vpZavarovanjeOd.BorderStyle := bsNone;
    vpZavarovanjeOd.ChildOrder := 2;
    vpZavarovanjeOd.Color := clNone;
    vpZavarovanjeOd.Date := 44179.000000000000000000;
    vpZavarovanjeOd.ReadOnly := True;
    vpZavarovanjeOd.Role := '';
    vpZavarovanjeOd.Text := '';
    SetEvent(vpZavarovanjeOd, Self, 'OnChange', 'vpZavarovanjeOdChange');
    vpZavarovanjeDo.SetParentComponent(ploscicaSimulacijaSkode_PodatkiOZavarovanju);
    vpZavarovanjeDo.Name := 'vpZavarovanjeDo';
    vpZavarovanjeDo.Left := 327;
    vpZavarovanjeDo.Top := 45;
    vpZavarovanjeDo.Width := 115;
    vpZavarovanjeDo.Height := 22;
    vpZavarovanjeDo.HeightPercent := 100.000000000000000000;
    vpZavarovanjeDo.WidthPercent := 100.000000000000000000;
    vpZavarovanjeDo.BorderStyle := bsNone;
    vpZavarovanjeDo.ChildOrder := 4;
    vpZavarovanjeDo.Color := clNone;
    vpZavarovanjeDo.Date := 44179.651272974500000000;
    vpZavarovanjeDo.ReadOnly := True;
    vpZavarovanjeDo.Role := '';
    vpZavarovanjeDo.Text := '';
    izZavarovalnaVsota.SetParentComponent(ploscicaSimulacijaSkode_PodatkiOZavarovanju);
    izZavarovalnaVsota.Name := 'izZavarovalnaVsota';
    izZavarovalnaVsota.Left := 158;
    izZavarovalnaVsota.Top := 74;
    izZavarovalnaVsota.Width := 115;
    izZavarovalnaVsota.Height := 22;
    izZavarovalnaVsota.Alignment := taRightJustify;
    izZavarovalnaVsota.AutoSelect := False;
    izZavarovalnaVsota.ChildOrder := 8;
    izZavarovalnaVsota.Color := clNone;
    izZavarovalnaVsota.HeightPercent := 100.000000000000000000;
    izZavarovalnaVsota.Text := '0.00';
    izZavarovalnaVsota.WidthPercent := 100.000000000000000000;
    SetEvent(izZavarovalnaVsota, Self, 'OnExit', 'vpZVKosmatiDobicekExit');
    SetEvent(izZavarovalnaVsota, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    izMaksimalnaDobaJamcenja.SetParentComponent(ploscicaSimulacijaSkode_PodatkiOZavarovanju);
    izMaksimalnaDobaJamcenja.Name := 'izMaksimalnaDobaJamcenja';
    izMaksimalnaDobaJamcenja.Left := 158;
    izMaksimalnaDobaJamcenja.Top := 105;
    izMaksimalnaDobaJamcenja.Width := 45;
    izMaksimalnaDobaJamcenja.Height := 23;
    izMaksimalnaDobaJamcenja.HeightPercent := 100.000000000000000000;
    izMaksimalnaDobaJamcenja.Text := '3';
    izMaksimalnaDobaJamcenja.WidthPercent := 100.000000000000000000;
    izMaksimalnaDobaJamcenja.ItemIndex := -1;
    izMaksimalnaDobaJamcenja.Items.BeginUpdate;
    try
      izMaksimalnaDobaJamcenja.Items.Clear;
      izMaksimalnaDobaJamcenja.Items.Add('3');
      izMaksimalnaDobaJamcenja.Items.Add('4');
      izMaksimalnaDobaJamcenja.Items.Add('5');
      izMaksimalnaDobaJamcenja.Items.Add('6');
      izMaksimalnaDobaJamcenja.Items.Add('7');
      izMaksimalnaDobaJamcenja.Items.Add('8');
      izMaksimalnaDobaJamcenja.Items.Add('9');
      izMaksimalnaDobaJamcenja.Items.Add('10');
      izMaksimalnaDobaJamcenja.Items.Add('11');
      izMaksimalnaDobaJamcenja.Items.Add('12');
    finally
      izMaksimalnaDobaJamcenja.Items.EndUpdate;
    end;
    izPremija.SetParentComponent(ploscicaSimulacijaSkode_PodatkiOZavarovanju);
    izPremija.Name := 'izPremija';
    izPremija.Left := 158;
    izPremija.Top := 134;
    izPremija.Width := 115;
    izPremija.Height := 22;
    izPremija.Alignment := taRightJustify;
    izPremija.AutoSelect := False;
    izPremija.ChildOrder := 8;
    izPremija.Color := clNone;
    izPremija.HeightPercent := 100.000000000000000000;
    izPremija.Text := '0.00';
    izPremija.WidthPercent := 100.000000000000000000;
    SetEvent(izPremija, Self, 'OnExit', 'vpZVKosmatiDobicekExit');
    SetEvent(izPremija, Self, 'OnKeyPress', 'vpVnosFRPKeyPress');
    vpDatumFizicneSkode.SetParentComponent(WebScrollBoxDL10);
    vpDatumFizicneSkode.Name := 'vpDatumFizicneSkode';
    vpDatumFizicneSkode.Left := 198;
    vpDatumFizicneSkode.Top := 269;
    vpDatumFizicneSkode.Width := 125;
    vpDatumFizicneSkode.Height := 23;
    vpDatumFizicneSkode.HeightPercent := 100.000000000000000000;
    vpDatumFizicneSkode.Text := 'vpDatumFizicneSkode';
    vpDatumFizicneSkode.WidthPercent := 100.000000000000000000;
    SetEvent(vpDatumFizicneSkode, Self, 'OnChange', 'vpDatumFizicneSkodeChange');
    vpDatumFizicneSkode.ItemIndex := -1;
    ploscicaGrafSimulacijaSkode.SetParentComponent(WebScrollBoxDL10);
    ploscicaGrafSimulacijaSkode.Name := 'ploscicaGrafSimulacijaSkode';
    ploscicaGrafSimulacijaSkode.Left := 15;
    ploscicaGrafSimulacijaSkode.Top := 735;
    ploscicaGrafSimulacijaSkode.Width := 822;
    ploscicaGrafSimulacijaSkode.Height := 421;
    ploscicaGrafSimulacijaSkode.HeightPercent := 100.000000000000000000;
    ploscicaGrafSimulacijaSkode.WidthPercent := 100.000000000000000000;
    ploscicaGrafSimulacijaSkode.BorderColor := clSilver;
    ploscicaGrafSimulacijaSkode.BorderStyle := bsSingle;
    ploscicaGrafSimulacijaSkode.ChildOrder := 8;
    ploscicaGrafSimulacijaSkode.Color := clWhite;
    GrafSimulacijaSkode.SetParentComponent(ploscicaGrafSimulacijaSkode);
    GrafSimulacijaSkode.Name := 'GrafSimulacijaSkode';
    GrafSimulacijaSkode.Left := 10;
    GrafSimulacijaSkode.Top := 9;
    GrafSimulacijaSkode.Width := 826;
    GrafSimulacijaSkode.Height := 404;
    GrafSimulacijaSkode.HeightPercent := 100.000000000000000000;
    GrafSimulacijaSkode.WidthPercent := 100.000000000000000000;
    GrafSimulacijaSkode.ChildOrder := 6;
    GrafSimulacijaSkode.Appearance.Animation.Startup := False;
    GrafSimulacijaSkode.Series.Clear;
    with GrafSimulacijaSkode.Series.Add do
    begin
      ChartType := gctLine;
      Color := clRed;
      Tag := -1;
    end;
    DelovniList0B.SetParentComponent(ploscicaDelovniListi);
    DelovniList0B.Name := 'DelovniList0B';
    DelovniList0B.Left := 0;
    DelovniList0B.Top := 20;
    DelovniList0B.Width := 1157;
    DelovniList0B.Height := 1090;
    DelovniList0B.HeightPercent := 100.000000000000000000;
    DelovniList0B.WidthPercent := 100.000000000000000000;
    DelovniList0B.Caption := 'DelovniList0B';
    DelovniList0B.ChildOrder := 12;
    DelovniList0B.TabVisible := False;
    WebScrollBoxDL0B.SetParentComponent(DelovniList0B);
    WebScrollBoxDL0B.Name := 'WebScrollBoxDL0B';
    WebScrollBoxDL0B.Left := 0;
    WebScrollBoxDL0B.Top := 0;
    WebScrollBoxDL0B.Width := 1157;
    WebScrollBoxDL0B.Height := 1090;
    WebScrollBoxDL0B.HeightPercent := 100.000000000000000000;
    WebScrollBoxDL0B.WidthPercent := 100.000000000000000000;
    WebScrollBoxDL0B.Align := alClient;
    WebScrollBoxDL0B.AutoScroll := True;
    WebScrollBoxDL0B.BorderStyle := bsNone;
    WebScrollBoxDL0B.ChildOrder := 4;
    WebScrollBoxDL0B.Color := clWindow;
    gNaloziProjekt.SetParentComponent(WebScrollBoxDL0B);
    gNaloziProjekt.Name := 'gNaloziProjekt';
    gNaloziProjekt.Left := 231;
    gNaloziProjekt.Top := 272;
    gNaloziProjekt.Width := 150;
    gNaloziProjekt.Height := 74;
    gNaloziProjekt.Caption := 'Nalo'#382'i shranjen projekt';
    gNaloziProjekt.Color := clNone;
    gNaloziProjekt.Flat := True;
    gNaloziProjekt.Font.Charset := DEFAULT_CHARSET;
    gNaloziProjekt.Font.Color := clWindowText;
    gNaloziProjekt.Font.Height := -11;
    gNaloziProjekt.Font.Name := 'Tahoma';
    gNaloziProjekt.Font.Style := [fsBold];
    gNaloziProjekt.Glyph.LoadFromFile('MBI_u01.WebScrollBoxDL0B.gNaloziProjekt.Glyph.png');
    gNaloziProjekt.HeightPercent := 100.000000000000000000;
    gNaloziProjekt.Layout := blGlyphTop;
    gNaloziProjekt.ParentFont := False;
    gNaloziProjekt.WidthPercent := 100.000000000000000000;
    SetEvent(gNaloziProjekt, Self, 'OnClick', 'gNaloziProjektClick');
    gPrekiniDelo.SetParentComponent(WebScrollBoxDL0B);
    gPrekiniDelo.Name := 'gPrekiniDelo';
    gPrekiniDelo.Left := 411;
    gPrekiniDelo.Top := 274;
    gPrekiniDelo.Width := 150;
    gPrekiniDelo.Height := 74;
    gPrekiniDelo.Caption := 'Prekini delo';
    gPrekiniDelo.Color := clNone;
    gPrekiniDelo.Flat := True;
    gPrekiniDelo.Font.Charset := DEFAULT_CHARSET;
    gPrekiniDelo.Font.Color := clWindowText;
    gPrekiniDelo.Font.Height := -11;
    gPrekiniDelo.Font.Name := 'Tahoma';
    gPrekiniDelo.Font.Style := [fsBold];
    gPrekiniDelo.Glyph.LoadFromFile('MBI_u01.WebScrollBoxDL0B.gPrekiniDelo.Glyph.png');
    gPrekiniDelo.HeightPercent := 100.000000000000000000;
    gPrekiniDelo.Layout := blGlyphTop;
    gPrekiniDelo.ParentFont := False;
    gPrekiniDelo.WidthPercent := 100.000000000000000000;
    SetEvent(gPrekiniDelo, Self, 'OnClick', 'mnZakljuciDeloClick');
    gZacniNovProjekt.SetParentComponent(WebScrollBoxDL0B);
    gZacniNovProjekt.Name := 'gZacniNovProjekt';
    gZacniNovProjekt.Left := 59;
    gZacniNovProjekt.Top := 274;
    gZacniNovProjekt.Width := 150;
    gZacniNovProjekt.Height := 72;
    gZacniNovProjekt.Caption := 'Za'#269'ni nov projekt';
    gZacniNovProjekt.Color := clNone;
    gZacniNovProjekt.Flat := True;
    gZacniNovProjekt.Font.Charset := DEFAULT_CHARSET;
    gZacniNovProjekt.Font.Color := clWindowText;
    gZacniNovProjekt.Font.Height := -11;
    gZacniNovProjekt.Font.Name := 'Tahoma';
    gZacniNovProjekt.Font.Style := [fsBold];
    gZacniNovProjekt.Glyph.LoadFromFile('MBI_u01.WebScrollBoxDL0B.gZacniNovProjekt.Glyph.png');
    gZacniNovProjekt.HeightPercent := 100.000000000000000000;
    gZacniNovProjekt.Layout := blGlyphTop;
    gZacniNovProjekt.ParentFont := False;
    gZacniNovProjekt.WidthPercent := 100.000000000000000000;
    SetEvent(gZacniNovProjekt, Self, 'OnClick', 'gZacniNovProjektClick');
    ploscicaNaslovDL0B.SetParentComponent(WebScrollBoxDL0B);
    ploscicaNaslovDL0B.Name := 'ploscicaNaslovDL0B';
    ploscicaNaslovDL0B.Left := 33;
    ploscicaNaslovDL0B.Top := 46;
    ploscicaNaslovDL0B.Width := 619;
    ploscicaNaslovDL0B.Height := 182;
    ploscicaNaslovDL0B.HeightPercent := 100.000000000000000000;
    ploscicaNaslovDL0B.WidthPercent := 100.000000000000000000;
    ploscicaNaslovDL0B.BorderColor := clNone;
    ploscicaNaslovDL0B.BorderStyle := bsNone;
    ploscicaNaslovDL0B.ChildOrder := 3;
    ploscicaNaslovDL0B.Color := clWhite;
    izNaslov2_1_DL0B.SetParentComponent(ploscicaNaslovDL0B);
    izNaslov2_1_DL0B.Name := 'izNaslov2_1_DL0B';
    izNaslov2_1_DL0B.Left := 10;
    izNaslov2_1_DL0B.Top := 116;
    izNaslov2_1_DL0B.Width := 469;
    izNaslov2_1_DL0B.Height := 18;
    izNaslov2_1_DL0B.Caption := 'Model za ocenjevanje tveganj, povezanih z obratovalnimi zastoji v';
    izNaslov2_1_DL0B.Color := clNone;
    izNaslov2_1_DL0B.Font.Charset := DEFAULT_CHARSET;
    izNaslov2_1_DL0B.Font.Color := clWindowText;
    izNaslov2_1_DL0B.Font.Height := -15;
    izNaslov2_1_DL0B.Font.Name := 'Arial';
    izNaslov2_1_DL0B.Font.Style := [fsBold];
    izNaslov2_1_DL0B.HeightPercent := 100.000000000000000000;
    izNaslov2_1_DL0B.ParentFont := False;
    izNaslov2_1_DL0B.WidthPercent := 100.000000000000000000;
    izNaslov2_2_DL0B.SetParentComponent(ploscicaNaslovDL0B);
    izNaslov2_2_DL0B.Name := 'izNaslov2_2_DL0B';
    izNaslov2_2_DL0B.Left := 10;
    izNaslov2_2_DL0B.Top := 137;
    izNaslov2_2_DL0B.Width := 439;
    izNaslov2_2_DL0B.Height := 18;
    izNaslov2_2_DL0B.Caption := 'malih in srednje velikih podjetjih ter vrednotenje preventivnih';
    izNaslov2_2_DL0B.Color := clNone;
    izNaslov2_2_DL0B.Font.Charset := DEFAULT_CHARSET;
    izNaslov2_2_DL0B.Font.Color := clWindowText;
    izNaslov2_2_DL0B.Font.Height := -15;
    izNaslov2_2_DL0B.Font.Name := 'Arial';
    izNaslov2_2_DL0B.Font.Style := [fsBold];
    izNaslov2_2_DL0B.HeightPercent := 100.000000000000000000;
    izNaslov2_2_DL0B.ParentFont := False;
    izNaslov2_2_DL0B.WidthPercent := 100.000000000000000000;
    izNaslov2_3_DL0B.SetParentComponent(ploscicaNaslovDL0B);
    izNaslov2_3_DL0B.Name := 'izNaslov2_3_DL0B';
    izNaslov2_3_DL0B.Left := 10;
    izNaslov2_3_DL0B.Top := 158;
    izNaslov2_3_DL0B.Width := 496;
    izNaslov2_3_DL0B.Height := 18;
    izNaslov2_3_DL0B.Caption := 'ukrepov za zmanj'#353'anje tveganj oziroma odpravljanje tovrstnih tveganj';
    izNaslov2_3_DL0B.Color := clNone;
    izNaslov2_3_DL0B.Font.Charset := DEFAULT_CHARSET;
    izNaslov2_3_DL0B.Font.Color := clWindowText;
    izNaslov2_3_DL0B.Font.Height := -15;
    izNaslov2_3_DL0B.Font.Name := 'Arial';
    izNaslov2_3_DL0B.Font.Style := [fsBold];
    izNaslov2_3_DL0B.HeightPercent := 100.000000000000000000;
    izNaslov2_3_DL0B.ParentFont := False;
    izNaslov2_3_DL0B.WidthPercent := 100.000000000000000000;
    logo_ModelBI_DL0B.SetParentComponent(ploscicaNaslovDL0B);
    logo_ModelBI_DL0B.Name := 'logo_ModelBI_DL0B';
    logo_ModelBI_DL0B.Left := 5;
    logo_ModelBI_DL0B.Top := 0;
    logo_ModelBI_DL0B.Width := 181;
    logo_ModelBI_DL0B.Height := 100;
    logo_ModelBI_DL0B.HeightPercent := 100.000000000000000000;
    logo_ModelBI_DL0B.WidthPercent := 100.000000000000000000;
    logo_ModelBI_DL0B.AutoSize := True;
    logo_ModelBI_DL0B.ChildOrder := 4;
    logo_ModelBI_DL0B.Picture.LoadFromFile('MBI_u01.ploscicaNaslovDL0B.logo_ModelBI_DL0B.Picture.png');
    ploscicaGlava.SetParentComponent(Self);
    ploscicaGlava.Name := 'ploscicaGlava';
    ploscicaGlava.Left := 0;
    ploscicaGlava.Top := 0;
    ploscicaGlava.Width := 1384;
    ploscicaGlava.Height := 40;
    ploscicaGlava.HeightPercent := 100.000000000000000000;
    ploscicaGlava.WidthPercent := 100.000000000000000000;
    ploscicaGlava.Align := alTop;
    ploscicaGlava.BorderColor := clSilver;
    ploscicaGlava.BorderStyle := bsNone;
    ploscicaGlava.Color := clWindow;
    logoRC1.SetParentComponent(ploscicaGlava);
    logoRC1.Name := 'logoRC1';
    logoRC1.AlignWithMargins := True;
    logoRC1.Left := 15;
    logoRC1.Top := 8;
    logoRC1.Width := 97;
    logoRC1.Height := 24;
    logoRC1.Margins.Left := 15;
    logoRC1.Margins.Top := 8;
    logoRC1.Margins.Bottom := 8;
    logoRC1.HeightPercent := 100.000000000000000000;
    logoRC1.WidthPercent := 100.000000000000000000;
    logoRC1.Align := alLeft;
    logoRC1.AutoSize := True;
    logoRC1.Picture.LoadFromFile('MBI_u01.ploscicaGlava.logoRC1.Picture.png');
    izVerzija.SetParentComponent(ploscicaGlava);
    izVerzija.Name := 'izVerzija';
    izVerzija.Left := 1227;
    izVerzija.Top := 0;
    izVerzija.Width := 157;
    izVerzija.Height := 40;
    izVerzija.Align := alRight;
    izVerzija.Alignment := taRightJustify;
    izVerzija.Caption := 'ModelBI ver. 1.0.0 (06.03.2022)';
    izVerzija.Color := clNone;
    izVerzija.Font.Charset := DEFAULT_CHARSET;
    izVerzija.Font.Color := clGray;
    izVerzija.Font.Height := -11;
    izVerzija.Font.Name := 'Tahoma';
    izVerzija.Font.Style := [];
    izVerzija.HeightPercent := 100.000000000000000000;
    izVerzija.ParentFont := False;
    izVerzija.WidthPercent := 100.000000000000000000;
    ploscicaIzborPogledov.SetParentComponent(Self);
    ploscicaIzborPogledov.Name := 'ploscicaIzborPogledov';
    ploscicaIzborPogledov.AlignWithMargins := True;
    ploscicaIzborPogledov.Left := 3;
    ploscicaIzborPogledov.Top := 95;
    ploscicaIzborPogledov.Width := 215;
    ploscicaIzborPogledov.Height := 1110;
    ploscicaIzborPogledov.Margins.Top := 15;
    ploscicaIzborPogledov.HeightPercent := 100.000000000000000000;
    ploscicaIzborPogledov.WidthPercent := 100.000000000000000000;
    ploscicaIzborPogledov.Align := alLeft;
    ploscicaIzborPogledov.BorderColor := clSilver;
    ploscicaIzborPogledov.BorderStyle := bsNone;
    ploscicaIzborPogledov.ChildOrder := 2;
    izIzborVhodniPodatki.SetParentComponent(ploscicaIzborPogledov);
    izIzborVhodniPodatki.Name := 'izIzborVhodniPodatki';
    izIzborVhodniPodatki.AlignWithMargins := True;
    izIzborVhodniPodatki.Left := 15;
    izIzborVhodniPodatki.Top := 3;
    izIzborVhodniPodatki.Width := 197;
    izIzborVhodniPodatki.Height := 16;
    izIzborVhodniPodatki.Margins.Left := 15;
    izIzborVhodniPodatki.Align := alTop;
    izIzborVhodniPodatki.Caption := 'VHODNI PODATKI';
    izIzborVhodniPodatki.Font.Charset := DEFAULT_CHARSET;
    izIzborVhodniPodatki.Font.Color := clWindowText;
    izIzborVhodniPodatki.Font.Height := -13;
    izIzborVhodniPodatki.Font.Name := 'Tahoma';
    izIzborVhodniPodatki.Font.Style := [fsBold];
    izIzborVhodniPodatki.HeightPercent := 100.000000000000000000;
    izIzborVhodniPodatki.ParentFont := False;
    izIzborVhodniPodatki.WidthPercent := 100.000000000000000000;
    izIzborAnalize.SetParentComponent(ploscicaIzborPogledov);
    izIzborAnalize.Name := 'izIzborAnalize';
    izIzborAnalize.AlignWithMargins := True;
    izIzborAnalize.Left := 15;
    izIzborAnalize.Top := 136;
    izIzborAnalize.Width := 197;
    izIzborAnalize.Height := 16;
    izIzborAnalize.Margins.Left := 15;
    izIzborAnalize.Align := alTop;
    izIzborAnalize.Caption := 'ANALIZE';
    izIzborAnalize.Font.Charset := DEFAULT_CHARSET;
    izIzborAnalize.Font.Color := clWindowText;
    izIzborAnalize.Font.Height := -13;
    izIzborAnalize.Font.Name := 'Tahoma';
    izIzborAnalize.Font.Style := [fsBold];
    izIzborAnalize.HeightPercent := 100.000000000000000000;
    izIzborAnalize.ParentFont := False;
    izIzborAnalize.WidthPercent := 100.000000000000000000;
    izIzborSimulacijeSkod.SetParentComponent(ploscicaIzborPogledov);
    izIzborSimulacijeSkod.Name := 'izIzborSimulacijeSkod';
    izIzborSimulacijeSkod.AlignWithMargins := True;
    izIzborSimulacijeSkod.Left := 15;
    izIzborSimulacijeSkod.Top := 256;
    izIzborSimulacijeSkod.Width := 197;
    izIzborSimulacijeSkod.Height := 16;
    izIzborSimulacijeSkod.Margins.Left := 15;
    izIzborSimulacijeSkod.Align := alTop;
    izIzborSimulacijeSkod.Caption := 'SIMULACIJE '#352'KOD';
    izIzborSimulacijeSkod.Font.Charset := DEFAULT_CHARSET;
    izIzborSimulacijeSkod.Font.Color := clWindowText;
    izIzborSimulacijeSkod.Font.Height := -13;
    izIzborSimulacijeSkod.Font.Name := 'Tahoma';
    izIzborSimulacijeSkod.Font.Style := [fsBold];
    izIzborSimulacijeSkod.HeightPercent := 100.000000000000000000;
    izIzborSimulacijeSkod.ParentFont := False;
    izIzborSimulacijeSkod.WidthPercent := 100.000000000000000000;
    izborVhodniPodatki.SetParentComponent(ploscicaIzborPogledov);
    izborVhodniPodatki.Name := 'izborVhodniPodatki';
    izborVhodniPodatki.AlignWithMargins := True;
    izborVhodniPodatki.Left := 15;
    izborVhodniPodatki.Top := 27;
    izborVhodniPodatki.Width := 197;
    izborVhodniPodatki.Height := 103;
    izborVhodniPodatki.Margins.Left := 15;
    izborVhodniPodatki.Margins.Top := 5;
    izborVhodniPodatki.Align := alTop;
    izborVhodniPodatki.BorderStyle := bsNone;
    izborVhodniPodatki.Font.Charset := DEFAULT_CHARSET;
    izborVhodniPodatki.Font.Color := clWindowText;
    izborVhodniPodatki.Font.Height := -13;
    izborVhodniPodatki.Font.Name := 'Tahoma';
    izborVhodniPodatki.Font.Style := [];
    izborVhodniPodatki.HeightPercent := 100.000000000000000000;
    izborVhodniPodatki.ItemIndex := -1;
    izborVhodniPodatki.Items.BeginUpdate;
    try
      izborVhodniPodatki.Items.Clear;
      izborVhodniPodatki.Items.Add('O podjetju');
      izborVhodniPodatki.Items.Add('Finan'#269'ni podatki');
      izborVhodniPodatki.Items.Add('Podatki o prodaji');
      izborVhodniPodatki.Items.Add('Organizacija');
      izborVhodniPodatki.Items.Add('Obstoje'#269'e zavarovanje');
    finally
      izborVhodniPodatki.Items.EndUpdate;
    end;
    izborVhodniPodatki.ParentColor := True;
    izborVhodniPodatki.ParentFont := False;
    izborVhodniPodatki.ShowFocus := False;
    izborVhodniPodatki.WidthPercent := 100.000000000000000000;
    SetEvent(izborVhodniPodatki, Self, 'OnChange', 'izborVhodniPodatkiChange');
    izborAnalize.SetParentComponent(ploscicaIzborPogledov);
    izborAnalize.Name := 'izborAnalize';
    izborAnalize.AlignWithMargins := True;
    izborAnalize.Left := 15;
    izborAnalize.Top := 160;
    izborAnalize.Width := 197;
    izborAnalize.Height := 90;
    izborAnalize.Margins.Left := 15;
    izborAnalize.Margins.Top := 5;
    izborAnalize.Align := alTop;
    izborAnalize.BorderStyle := bsNone;
    izborAnalize.Font.Charset := DEFAULT_CHARSET;
    izborAnalize.Font.Color := clWindowText;
    izborAnalize.Font.Height := -13;
    izborAnalize.Font.Name := 'Tahoma';
    izborAnalize.Font.Style := [];
    izborAnalize.HeightPercent := 100.000000000000000000;
    izborAnalize.ItemIndex := -1;
    izborAnalize.Items.BeginUpdate;
    try
      izborAnalize.Items.Clear;
      izborAnalize.Items.Add('Maksimalna doba jam'#269'enja');
      izborAnalize.Items.Add('Zavarovalna vsota');
      izborAnalize.Items.Add('Prodajni trendi');
      izborAnalize.Items.Add('Obstoje'#269'e zavarovanje');
    finally
      izborAnalize.Items.EndUpdate;
    end;
    izborAnalize.ParentColor := True;
    izborAnalize.ParentFont := False;
    izborAnalize.ShowFocus := False;
    izborAnalize.WidthPercent := 100.000000000000000000;
    SetEvent(izborAnalize, Self, 'OnChange', 'izborAnalizeChange');
    izborSimulacijeSkod.SetParentComponent(ploscicaIzborPogledov);
    izborSimulacijeSkod.Name := 'izborSimulacijeSkod';
    izborSimulacijeSkod.AlignWithMargins := True;
    izborSimulacijeSkod.Left := 15;
    izborSimulacijeSkod.Top := 280;
    izborSimulacijeSkod.Width := 197;
    izborSimulacijeSkod.Height := 55;
    izborSimulacijeSkod.Margins.Left := 15;
    izborSimulacijeSkod.Margins.Top := 5;
    izborSimulacijeSkod.Align := alTop;
    izborSimulacijeSkod.BorderStyle := bsNone;
    izborSimulacijeSkod.Font.Charset := DEFAULT_CHARSET;
    izborSimulacijeSkod.Font.Color := clWindowText;
    izborSimulacijeSkod.Font.Height := -13;
    izborSimulacijeSkod.Font.Name := 'Tahoma';
    izborSimulacijeSkod.Font.Style := [];
    izborSimulacijeSkod.HeightPercent := 100.000000000000000000;
    izborSimulacijeSkod.ItemIndex := -1;
    izborSimulacijeSkod.Items.BeginUpdate;
    try
      izborSimulacijeSkod.Items.Clear;
      izborSimulacijeSkod.Items.Add('Obstoje'#269'e zavarovanje');
      izborSimulacijeSkod.Items.Add('Simulirano zavarovanje');
    finally
      izborSimulacijeSkod.Items.EndUpdate;
    end;
    izborSimulacijeSkod.ParentColor := True;
    izborSimulacijeSkod.ParentFont := False;
    izborSimulacijeSkod.ShowFocus := False;
    izborSimulacijeSkod.WidthPercent := 100.000000000000000000;
    SetEvent(izborSimulacijeSkod, Self, 'OnChange', 'izborSimulacijeSkodChange');
    ploscicaUporabnik.SetParentComponent(ploscicaIzborPogledov);
    ploscicaUporabnik.Name := 'ploscicaUporabnik';
    ploscicaUporabnik.Left := 0;
    ploscicaUporabnik.Top := 1050;
    ploscicaUporabnik.Width := 215;
    ploscicaUporabnik.Height := 60;
    ploscicaUporabnik.HeightPercent := 100.000000000000000000;
    ploscicaUporabnik.WidthPercent := 100.000000000000000000;
    ploscicaUporabnik.Align := alBottom;
    ploscicaUporabnik.BorderColor := clGray;
    ploscicaUporabnik.BorderStyle := bsNone;
    ploscicaUporabnik.ChildOrder := 6;
    ploscicaUporabnik.Color := clSilver;
    izUporabnikTekst.SetParentComponent(ploscicaUporabnik);
    izUporabnikTekst.Name := 'izUporabnikTekst';
    izUporabnikTekst.AlignWithMargins := True;
    izUporabnikTekst.Left := 5;
    izUporabnikTekst.Top := 2;
    izUporabnikTekst.Width := 60;
    izUporabnikTekst.Height := 12;
    izUporabnikTekst.Margins.Left := 5;
    izUporabnikTekst.Margins.Top := 5;
    izUporabnikTekst.AutoSize := False;
    izUporabnikTekst.Caption := 'Uporabnik:';
    izUporabnikTekst.Font.Charset := DEFAULT_CHARSET;
    izUporabnikTekst.Font.Color := clWindowText;
    izUporabnikTekst.Font.Height := -9;
    izUporabnikTekst.Font.Name := 'Tahoma';
    izUporabnikTekst.Font.Style := [];
    izUporabnikTekst.HeightPercent := 100.000000000000000000;
    izUporabnikTekst.ParentFont := False;
    izUporabnikTekst.WidthPercent := 100.000000000000000000;
    izPodjetjeTekst.SetParentComponent(ploscicaUporabnik);
    izPodjetjeTekst.Name := 'izPodjetjeTekst';
    izPodjetjeTekst.AlignWithMargins := True;
    izPodjetjeTekst.Left := 5;
    izPodjetjeTekst.Top := 22;
    izPodjetjeTekst.Width := 60;
    izPodjetjeTekst.Height := 12;
    izPodjetjeTekst.Margins.Left := 5;
    izPodjetjeTekst.Margins.Top := 5;
    izPodjetjeTekst.Margins.Bottom := 10;
    izPodjetjeTekst.AutoSize := False;
    izPodjetjeTekst.Caption := 'Podjetje:';
    izPodjetjeTekst.Font.Charset := DEFAULT_CHARSET;
    izPodjetjeTekst.Font.Color := clWindowText;
    izPodjetjeTekst.Font.Height := -9;
    izPodjetjeTekst.Font.Name := 'Tahoma';
    izPodjetjeTekst.Font.Style := [];
    izPodjetjeTekst.HeightPercent := 100.000000000000000000;
    izPodjetjeTekst.ParentFont := False;
    izPodjetjeTekst.WidthPercent := 100.000000000000000000;
    izUporabnik.SetParentComponent(ploscicaUporabnik);
    izUporabnik.Name := 'izUporabnik';
    izUporabnik.AlignWithMargins := True;
    izUporabnik.Left := 85;
    izUporabnik.Top := 5;
    izUporabnik.Width := 129;
    izUporabnik.Height := 12;
    izUporabnik.Margins.Left := 5;
    izUporabnik.Margins.Top := 5;
    izUporabnik.AutoSize := False;
    izUporabnik.Caption := 'xxx';
    izUporabnik.Font.Charset := DEFAULT_CHARSET;
    izUporabnik.Font.Color := clWindowText;
    izUporabnik.Font.Height := -9;
    izUporabnik.Font.Name := 'Tahoma';
    izUporabnik.Font.Style := [];
    izUporabnik.HeightPercent := 100.000000000000000000;
    izUporabnik.ParentFont := False;
    izUporabnik.WidthPercent := 100.000000000000000000;
    izPotekLicenceTekst.SetParentComponent(ploscicaUporabnik);
    izPotekLicenceTekst.Name := 'izPotekLicenceTekst';
    izPotekLicenceTekst.AlignWithMargins := True;
    izPotekLicenceTekst.Left := 5;
    izPotekLicenceTekst.Top := 40;
    izPotekLicenceTekst.Width := 57;
    izPotekLicenceTekst.Height := 11;
    izPotekLicenceTekst.Margins.Left := 5;
    izPotekLicenceTekst.Margins.Top := 5;
    izPotekLicenceTekst.Margins.Bottom := 10;
    izPotekLicenceTekst.Caption := 'Potek licence:';
    izPotekLicenceTekst.Font.Charset := DEFAULT_CHARSET;
    izPotekLicenceTekst.Font.Color := clWindowText;
    izPotekLicenceTekst.Font.Height := -9;
    izPotekLicenceTekst.Font.Name := 'Tahoma';
    izPotekLicenceTekst.Font.Style := [];
    izPotekLicenceTekst.HeightPercent := 100.000000000000000000;
    izPotekLicenceTekst.ParentFont := False;
    izPotekLicenceTekst.WidthPercent := 100.000000000000000000;
    izPodjetje.SetParentComponent(ploscicaUporabnik);
    izPodjetje.Name := 'izPodjetje';
    izPodjetje.AlignWithMargins := True;
    izPodjetje.Left := 85;
    izPodjetje.Top := 22;
    izPodjetje.Width := 129;
    izPodjetje.Height := 12;
    izPodjetje.Margins.Left := 5;
    izPodjetje.Margins.Top := 5;
    izPodjetje.AutoSize := False;
    izPodjetje.Caption := 'xxx';
    izPodjetje.Font.Charset := DEFAULT_CHARSET;
    izPodjetje.Font.Color := clWindowText;
    izPodjetje.Font.Height := -9;
    izPodjetje.Font.Name := 'Tahoma';
    izPodjetje.Font.Style := [];
    izPodjetje.HeightPercent := 100.000000000000000000;
    izPodjetje.ParentFont := False;
    izPodjetje.WidthPercent := 100.000000000000000000;
    izPotekLicence.SetParentComponent(ploscicaUporabnik);
    izPotekLicence.Name := 'izPotekLicence';
    izPotekLicence.Left := 85;
    izPotekLicence.Top := 35;
    izPotekLicence.Width := 120;
    izPotekLicence.Height := 22;
    izPotekLicence.HeightPercent := 100.000000000000000000;
    izPotekLicence.WidthPercent := 100.000000000000000000;
    izPotekLicence.BorderStyle := bsNone;
    izPotekLicence.ChildOrder := 5;
    izPotekLicence.Color := clSilver;
    izPotekLicence.Date := 44480.961461875000000000;
    izPotekLicence.Enabled := False;
    izPotekLicence.ReadOnly := True;
    izPotekLicence.Role := '';
    izPotekLicence.ShowFocus := False;
    izPotekLicence.ShowSeconds := False;
    izPotekLicence.Text := '';
    ploscicaGlavniMeni.SetParentComponent(Self);
    ploscicaGlavniMeni.Name := 'ploscicaGlavniMeni';
    ploscicaGlavniMeni.Left := 0;
    ploscicaGlavniMeni.Top := 40;
    ploscicaGlavniMeni.Width := 1384;
    ploscicaGlavniMeni.Height := 40;
    ploscicaGlavniMeni.HeightPercent := 100.000000000000000000;
    ploscicaGlavniMeni.WidthPercent := 100.000000000000000000;
    ploscicaGlavniMeni.Align := alTop;
    ploscicaGlavniMeni.BorderColor := clSilver;
    ploscicaGlavniMeni.BorderStyle := bsSingle;
    ploscicaGlavniMeni.ChildOrder := 5;
    ploscicaGlavniMeni.Color := clSilver;
    PovezavaServerGeslo.SetParentComponent(Self);
    PovezavaServerGeslo.Name := 'PovezavaServerGeslo';
    PovezavaServerGeslo.URL := 'http://ec2-35-158-89-121.eu-central-1.compute.amazonaws.com/tms/xdata';
    SetEvent(PovezavaServerGeslo, Self, 'OnConnect', 'PovezavaServerGesloConnect');
    SetEvent(PovezavaServerGeslo, Self, 'OnError', 'PovezavaServerGesloError');
    PovezavaServerGeslo.Left := 19;
    PovezavaServerGeslo.Top := 415;
    WebClientGeslo.SetParentComponent(Self);
    WebClientGeslo.Name := 'WebClientGeslo';
    WebClientGeslo.Connection := PovezavaServerGeslo;
    WebClientGeslo.Left := 115;
    WebClientGeslo.Top := 415;
    PovezavaServerPodatki.SetParentComponent(Self);
    PovezavaServerPodatki.Name := 'PovezavaServerPodatki';
    PovezavaServerPodatki.URL := 'http://ec2-35-158-89-121.eu-central-1.compute.amazonaws.com/tms/xdata';
    SetEvent(PovezavaServerPodatki, Self, 'OnConnect', 'PovezavaServerPodatkiConnect');
    SetEvent(PovezavaServerPodatki, Self, 'OnError', 'PovezavaServerPodatkiError');
    PovezavaServerPodatki.Left := 19;
    PovezavaServerPodatki.Top := 471;
    WebClientPodatki.SetParentComponent(Self);
    WebClientPodatki.Name := 'WebClientPodatki';
    WebClientPodatki.Connection := PovezavaServerPodatki;
    WebClientPodatki.Left := 115;
    WebClientPodatki.Top := 471;
    PovezavaWSPopolniPreracun.SetParentComponent(Self);
    PovezavaWSPopolniPreracun.Name := 'PovezavaWSPopolniPreracun';
    PovezavaWSPopolniPreracun.URL := 'http://ec2-35-158-89-121.eu-central-1.compute.amazonaws.com/tms/xdata';
    SetEvent(PovezavaWSPopolniPreracun, Self, 'OnConnect', 'PovezavaWSPopolniPreracunConnect');
    SetEvent(PovezavaWSPopolniPreracun, Self, 'OnError', 'PovezavaWSPopolniPreracunError');
    PovezavaWSPopolniPreracun.Left := 19;
    PovezavaWSPopolniPreracun.Top := 535;
    WebClientWSPopolniIzracun.SetParentComponent(Self);
    WebClientWSPopolniIzracun.Name := 'WebClientWSPopolniIzracun';
    WebClientWSPopolniIzracun.Connection := PovezavaWSPopolniPreracun;
    WebClientWSPopolniIzracun.Left := 115;
    WebClientWSPopolniIzracun.Top := 535;
    PovezavaWSDelniIzracuni.SetParentComponent(Self);
    PovezavaWSDelniIzracuni.Name := 'PovezavaWSDelniIzracuni';
    PovezavaWSDelniIzracuni.URL := 'http://ec2-35-158-89-121.eu-central-1.compute.amazonaws.com/tms/xdata';
    SetEvent(PovezavaWSDelniIzracuni, Self, 'OnConnect', 'PovezavaWSDelniIzracuniConnect');
    SetEvent(PovezavaWSDelniIzracuni, Self, 'OnError', 'PovezavaWSDelniIzracuniError');
    PovezavaWSDelniIzracuni.Left := 19;
    PovezavaWSDelniIzracuni.Top := 599;
    WebClientWSDelniIzracuni.SetParentComponent(Self);
    WebClientWSDelniIzracuni.Name := 'WebClientWSDelniIzracuni';
    WebClientWSDelniIzracuni.Connection := PovezavaWSDelniIzracuni;
    WebClientWSDelniIzracuni.Left := 123;
    WebClientWSDelniIzracuni.Top := 599;
    menuKrivuljaZastoja.SetParentComponent(Self);
    menuKrivuljaZastoja.Name := 'menuKrivuljaZastoja';
    menuKrivuljaZastoja.Appearance.HamburgerMenu.Caption := 'Menu';
    menuKrivuljaZastoja.Appearance.SubmenuIndicator := '&#9658;';
    menuKrivuljaZastoja.Left := 24;
    menuKrivuljaZastoja.Top := 879;
    mnKrivulja1.SetParentComponent(menuKrivuljaZastoja);
    mnKrivulja1.Name := 'mnKrivulja1';
    mnKrivulja1.Caption := '12-mese'#269'ni zastoj po kivluji 1';
    SetEvent(mnKrivulja1, Self, 'OnClick', 'mnKrivulja1Click');
    mnKrivulja2.SetParentComponent(menuKrivuljaZastoja);
    mnKrivulja2.Name := 'mnKrivulja2';
    mnKrivulja2.Caption := '12-mese'#269'ni zastoj po kivluji 2';
    SetEvent(mnKrivulja2, Self, 'OnClick', 'mnKrivulja1Click');
    mnKrivulja3.SetParentComponent(menuKrivuljaZastoja);
    mnKrivulja3.Name := 'mnKrivulja3';
    mnKrivulja3.Caption := '12-mese'#269'ni zastoj po kivluji 3';
    SetEvent(mnKrivulja3, Self, 'OnClick', 'mnKrivulja1Click');
    menuPopolniZastoj.SetParentComponent(Self);
    menuPopolniZastoj.Name := 'menuPopolniZastoj';
    menuPopolniZastoj.Appearance.HamburgerMenu.Caption := 'Menu';
    menuPopolniZastoj.Appearance.SubmenuIndicator := '&#9658;';
    menuPopolniZastoj.Left := 120;
    menuPopolniZastoj.Top := 879;
    mn3.SetParentComponent(menuPopolniZastoj);
    mn3.Name := 'mn3';
    mn3.Caption := 'popolni zastoj 3 mesece';
    SetEvent(mn3, Self, 'OnClick', 'mn3Click');
    mn4.SetParentComponent(menuPopolniZastoj);
    mn4.Name := 'mn4';
    mn4.Caption := 'popolni zastoj 4 mesece';
    SetEvent(mn4, Self, 'OnClick', 'mn3Click');
    mn5.SetParentComponent(menuPopolniZastoj);
    mn5.Name := 'mn5';
    mn5.Caption := 'popolni zastoj 5 mesecev';
    SetEvent(mn5, Self, 'OnClick', 'mn3Click');
    mn6.SetParentComponent(menuPopolniZastoj);
    mn6.Name := 'mn6';
    mn6.Caption := 'popolni zastoj 6 mesecev';
    SetEvent(mn6, Self, 'OnClick', 'mn3Click');
    mn7.SetParentComponent(menuPopolniZastoj);
    mn7.Name := 'mn7';
    mn7.Caption := 'popolni zastoj 7 mesecev';
    SetEvent(mn7, Self, 'OnClick', 'mn3Click');
    mn8.SetParentComponent(menuPopolniZastoj);
    mn8.Name := 'mn8';
    mn8.Caption := 'popolni zastoj 8 mesecev';
    SetEvent(mn8, Self, 'OnClick', 'mn3Click');
    mn9.SetParentComponent(menuPopolniZastoj);
    mn9.Name := 'mn9';
    mn9.Caption := 'popolni zastoj 9 mesecev';
    SetEvent(mn9, Self, 'OnClick', 'mn3Click');
    mn10.SetParentComponent(menuPopolniZastoj);
    mn10.Name := 'mn10';
    mn10.Caption := 'popolni zastoj 10 mesecev';
    SetEvent(mn10, Self, 'OnClick', 'mn3Click');
    mn11.SetParentComponent(menuPopolniZastoj);
    mn11.Name := 'mn11';
    mn11.Caption := 'popolni zastoj 11 mesecev';
    SetEvent(mn11, Self, 'OnClick', 'mn3Click');
    mn12.SetParentComponent(menuPopolniZastoj);
    mn12.Name := 'mn12';
    mn12.Caption := 'popolni zastoj 12 mesecev';
    SetEvent(mn12, Self, 'OnClick', 'mn3Click');
    PovezavaServerShranjevanje.SetParentComponent(Self);
    PovezavaServerShranjevanje.Name := 'PovezavaServerShranjevanje';
    PovezavaServerShranjevanje.URL := 'http://ec2-35-158-89-121.eu-central-1.compute.amazonaws.com/tms/xdata';
    SetEvent(PovezavaServerShranjevanje, Self, 'OnConnect', 'PovezavaServerShranjevanjeConnect');
    SetEvent(PovezavaServerShranjevanje, Self, 'OnError', 'PovezavaServerShranjevanjeError');
    PovezavaServerShranjevanje.Left := 19;
    PovezavaServerShranjevanje.Top := 663;
    WebClientShranjevanje.SetParentComponent(Self);
    WebClientShranjevanje.Name := 'WebClientShranjevanje';
    WebClientShranjevanje.Left := 123;
    WebClientShranjevanje.Top := 663;
    PovezavaServerJezik.SetParentComponent(Self);
    PovezavaServerJezik.Name := 'PovezavaServerJezik';
    PovezavaServerJezik.URL := 'http://ec2-35-158-89-121.eu-central-1.compute.amazonaws.com/tms/xdata';
    SetEvent(PovezavaServerJezik, Self, 'OnConnect', 'PovezavaServerJezikConnect');
    SetEvent(PovezavaServerJezik, Self, 'OnError', 'PovezavaServerJezikError');
    PovezavaServerJezik.Left := 19;
    PovezavaServerJezik.Top := 719;
    WebClientJezik.SetParentComponent(Self);
    WebClientJezik.Name := 'WebClientJezik';
    WebClientJezik.Left := 123;
    WebClientJezik.Top := 719;
    mnGlavniMeni.SetParentComponent(Self);
    mnGlavniMeni.Name := 'mnGlavniMeni';
    mnGlavniMeni.Appearance.BackgroundColor := clSilver;
    mnGlavniMeni.Appearance.HamburgerMenu.Caption := 'Menu';
    mnGlavniMeni.Appearance.ImageURLs.BeginUpdate;
    try
      mnGlavniMeni.Appearance.ImageURLs.Clear;
      mnGlavniMeni.Appearance.ImageURLs.Add('img_new.png');
      mnGlavniMeni.Appearance.ImageURLs.Add('img_open.png');
      mnGlavniMeni.Appearance.ImageURLs.Add('img_save.png');
      mnGlavniMeni.Appearance.ImageURLs.Add('img_refresh.png');
      mnGlavniMeni.Appearance.ImageURLs.Add('img_menuRest.png');
      mnGlavniMeni.Appearance.ImageURLs.Add('img_setup.png');
      mnGlavniMeni.Appearance.ImageURLs.Add('img_info.png');
      mnGlavniMeni.Appearance.ImageURLs.Add('img_help.png');
      mnGlavniMeni.Appearance.ImageURLs.Add('img_close.png');
    finally
      mnGlavniMeni.Appearance.ImageURLs.EndUpdate;
    end;
    mnGlavniMeni.Appearance.SubmenuIndicator := '&#9658;';
    mnGlavniMeni.Container := ploscicaGlavniMeni;
    mnGlavniMeni.Left := 27;
    mnGlavniMeni.Top := 935;
    mnNovProjekt.SetParentComponent(mnGlavniMeni);
    mnNovProjekt.Name := 'mnNovProjekt';
    mnNovProjekt.Caption := 'Nov projekt';
    mnNovProjekt.ImageIndex := 0;
    SetEvent(mnNovProjekt, Self, 'OnClick', 'mnNovProjektClick');
    mnOdpriProjekt.SetParentComponent(mnGlavniMeni);
    mnOdpriProjekt.Name := 'mnOdpriProjekt';
    mnOdpriProjekt.Caption := 'Odpri projekt';
    mnOdpriProjekt.ImageIndex := 1;
    SetEvent(mnOdpriProjekt, Self, 'OnClick', 'mnOdpriProjektClick');
    mnShraniProjekt.SetParentComponent(mnGlavniMeni);
    mnShraniProjekt.Name := 'mnShraniProjekt';
    mnShraniProjekt.Caption := 'Shrani projekt';
    mnShraniProjekt.ImageIndex := 2;
    SetEvent(mnShraniProjekt, Self, 'OnClick', 'mnShraniProjektClick');
    mnOsveziAnalize.SetParentComponent(mnGlavniMeni);
    mnOsveziAnalize.Name := 'mnOsveziAnalize';
    mnOsveziAnalize.Caption := 'Osve'#382'i analize';
    mnOsveziAnalize.ImageIndex := 3;
    mnOsveziPrivzeteVrednosti.SetParentComponent(mnOsveziAnalize);
    mnOsveziPrivzeteVrednosti.Name := 'mnOsveziPrivzeteVrednosti';
    mnOsveziPrivzeteVrednosti.Caption := 'Osve'#382'evanje s privzetimi vrednostmi';
    SetEvent(mnOsveziPrivzeteVrednosti, Self, 'OnClick', 'mnOsveziPrivzeteVrednostiClick');
    N6.SetParentComponent(mnOsveziAnalize);
    N6.Name := 'N6';
    N6.Caption := '-';
    mnOsveziDejanskeVrednosti.SetParentComponent(mnOsveziAnalize);
    mnOsveziDejanskeVrednosti.Name := 'mnOsveziDejanskeVrednosti';
    mnOsveziDejanskeVrednosti.Caption := 'Osve'#382'evanje z vnesenimi / dejanskimi vrednostmi';
    SetEvent(mnOsveziDejanskeVrednosti, Self, 'OnClick', 'mnOsveziPrivzeteVrednostiClick');
    mnOstalo.SetParentComponent(mnGlavniMeni);
    mnOstalo.Name := 'mnOstalo';
    mnOstalo.Caption := 'Ostalo';
    mnOstalo.ImageIndex := 4;
    mnNastavitve.SetParentComponent(mnOstalo);
    mnNastavitve.Name := 'mnNastavitve';
    mnNastavitve.Caption := 'Nastavitve';
    mnNastavitve.ImageIndex := 5;
    SetEvent(mnNastavitve, Self, 'OnClick', 'mnNastavitveClick');
    N4.SetParentComponent(mnOstalo);
    N4.Name := 'N4';
    N4.Caption := '-';
    mnOOrodju.SetParentComponent(mnOstalo);
    mnOOrodju.Name := 'mnOOrodju';
    mnOOrodju.Caption := 'O orodju';
    mnOOrodju.ImageIndex := 6;
    SetEvent(mnOOrodju, Self, 'OnClick', 'mnOOrodjuClick');
    N5.SetParentComponent(mnOstalo);
    N5.Name := 'N5';
    N5.Caption := '-';
    mnPomoc.SetParentComponent(mnOstalo);
    mnPomoc.Name := 'mnPomoc';
    mnPomoc.Caption := 'Pomo'#269;
    mnPomoc.ImageIndex := 7;
    SetEvent(mnPomoc, Self, 'OnClick', 'mnPomocClick');
    mnZakljuciDelo.SetParentComponent(mnGlavniMeni);
    mnZakljuciDelo.Name := 'mnZakljuciDelo';
    mnZakljuciDelo.Caption := 'Zaklju'#269'i delo';
    mnZakljuciDelo.ImageIndex := 8;
    SetEvent(mnZakljuciDelo, Self, 'OnClick', 'mnZakljuciDeloClick');
    PovezavaServerSkode.SetParentComponent(Self);
    PovezavaServerSkode.Name := 'PovezavaServerSkode';
    PovezavaServerSkode.URL := 'http://ec2-35-158-89-121.eu-central-1.compute.amazonaws.com/tms/xdata';
    SetEvent(PovezavaServerSkode, Self, 'OnConnect', 'PovezavaServerSkodeConnect');
    SetEvent(PovezavaServerSkode, Self, 'OnError', 'PovezavaServerSkodeError');
    PovezavaServerSkode.Left := 19;
    PovezavaServerSkode.Top := 783;
    WebClientSkode.SetParentComponent(Self);
    WebClientSkode.Name := 'WebClientSkode';
    WebClientSkode.Left := 123;
    WebClientSkode.Top := 783;
  finally
    obvestilo.AfterLoadDFMValues;
    ploscicaDelovniListi.AfterLoadDFMValues;
    DelovniList0A.AfterLoadDFMValues;
    WebScrollBoxDL0A.AfterLoadDFMValues;
    GoogleReCaptcha.AfterLoadDFMValues;
    ploscicaPrvaStran.AfterLoadDFMValues;
    slikaJezikANG.AfterLoadDFMValues;
    slikaJezikSLO.AfterLoadDFMValues;
    logo_ModelBI_DL0A.AfterLoadDFMValues;
    slikaBrowser1.AfterLoadDFMValues;
    slikaBrowser2.AfterLoadDFMValues;
    slikaBrowser3.AfterLoadDFMValues;
    slikaBrowser4.AfterLoadDFMValues;
    izBrskalniki.AfterLoadDFMValues;
    ploscicaPrijava.AfterLoadDFMValues;
    vpUporabniskoIme_Tekst.AfterLoadDFMValues;
    vpGeslo_Tekst.AfterLoadDFMValues;
    izPrijavaNaslov.AfterLoadDFMValues;
    izUporabniskoImeNapaka.AfterLoadDFMValues;
    izGesloNapaka.AfterLoadDFMValues;
    izVerzijaPrijava.AfterLoadDFMValues;
    vpUporabniskoIme.AfterLoadDFMValues;
    vpGeslo.AfterLoadDFMValues;
    gPrijava.AfterLoadDFMValues;
    ploscicaNaslovDL0A.AfterLoadDFMValues;
    izNaslov2_1_DL0A.AfterLoadDFMValues;
    izNaslov2_2_DL0A.AfterLoadDFMValues;
    izNaslov2_3_DL0A.AfterLoadDFMValues;
    izSpletnaStran.AfterLoadDFMValues;
    DelovniList1.AfterLoadDFMValues;
    WebScrollBoxDL1.AfterLoadDFMValues;
    izNaslovDL1.AfterLoadDFMValues;
    ploscicaVnosPodatkovOPodjetju.AfterLoadDFMValues;
    izImePodjetja.AfterLoadDFMValues;
    izDavcnaStevilkaPodjetja.AfterLoadDFMValues;
    izDejavnostPodjetja.AfterLoadDFMValues;
    izNaslovPodjetja.AfterLoadDFMValues;
    vpImePodjetja.AfterLoadDFMValues;
    vpDavcnaStevilkaPodjetja.AfterLoadDFMValues;
    vpNaslovPodjetja.AfterLoadDFMValues;
    vpDejavnostPodjetja.AfterLoadDFMValues;
    DelovniList2.AfterLoadDFMValues;
    WebScrollBoxDL2.AfterLoadDFMValues;
    izNaslovDL2.AfterLoadDFMValues;
    ploscicaVnosFinancnihPodatkovPodjetja.AfterLoadDFMValues;
    izVnosFRPLeto.AfterLoadDFMValues;
    izVnosFRPPromet.AfterLoadDFMValues;
    izVnosFRPZalogeZacetek.AfterLoadDFMValues;
    izVnosFRPZalogeKonec.AfterLoadDFMValues;
    izVnosFRPInternoProizvedeniDodatki.AfterLoadDFMValues;
    izVnosFRPStroskiSurovine.AfterLoadDFMValues;
    izVnosFRPStroskiMaterial.AfterLoadDFMValues;
    izVnosFRPStroskiEnergija.AfterLoadDFMValues;
    izVnosFRPStroskiTrgovskoBlago.AfterLoadDFMValues;
    izVnosFRPStroskiStoritve.AfterLoadDFMValues;
    izVnosFRPDrugiStroski.AfterLoadDFMValues;
    izVnosFRPPrejeteZavarovalnine.AfterLoadDFMValues;
    izVnosFRPKosmatiDobicek.AfterLoadDFMValues;
    vpVnosFRPKosmatiDobicek.AfterLoadDFMValues;
    izVnosFRPStopnjaKosmategaDobicka.AfterLoadDFMValues;
    vpVnosFRPStopnjaKosmategaDobicka.AfterLoadDFMValues;
    vpOpazovanoLeto.AfterLoadDFMValues;
    vpVnosFRPPromet.AfterLoadDFMValues;
    vpVnosFRPZalogeZacetek.AfterLoadDFMValues;
    vpVnosFRPZalogeKonec.AfterLoadDFMValues;
    vpVnosFRPInternoProizvedeniDodatki.AfterLoadDFMValues;
    vpVnosFRPStroskiSurovine.AfterLoadDFMValues;
    vpVnosFRPStroskiMaterial.AfterLoadDFMValues;
    vpVnosFRPStroskiEnergija.AfterLoadDFMValues;
    vpVnosFRPStroskiTrgovskoBlago.AfterLoadDFMValues;
    vpVnosFRPStroskiStoritve.AfterLoadDFMValues;
    vpVnosFRPDrugiStroski.AfterLoadDFMValues;
    vpVnosFRPPrejeteZavarovalnine.AfterLoadDFMValues;
    DelovniList3.AfterLoadDFMValues;
    WebScrollBoxDL3.AfterLoadDFMValues;
    izNaslovDL3.AfterLoadDFMValues;
    izPlanProdaje_LetoXplus1.AfterLoadDFMValues;
    izPlanProdaje_LetoXplus2.AfterLoadDFMValues;
    izPlanProdaje_LetoXplus3.AfterLoadDFMValues;
    izPlanProdaje_LetoXplus4.AfterLoadDFMValues;
    izPlanProdaje_Naslov.AfterLoadDFMValues;
    izPlanProdaje_RastXplus1.AfterLoadDFMValues;
    izPlanProdaje_RastXplus2.AfterLoadDFMValues;
    izPlanProdaje_RastXplus3.AfterLoadDFMValues;
    izPlanProdaje_RastXplus4.AfterLoadDFMValues;
    ploscicaTabelaPrometaX.AfterLoadDFMValues;
    vpTabelaPromet_LetoX.AfterLoadDFMValues;
    vpTabelaPromet_LetoXSkupaj.AfterLoadDFMValues;
    vpTabelaPromet_ProdajaX_1.AfterLoadDFMValues;
    vpTabelaPromet_ProdajaX_2.AfterLoadDFMValues;
    vpTabelaPromet_ProdajaX_3.AfterLoadDFMValues;
    vpTabelaPromet_ProdajaX_4.AfterLoadDFMValues;
    vpTabelaPromet_ProdajaX_5.AfterLoadDFMValues;
    vpTabelaPromet_ProdajaX_6.AfterLoadDFMValues;
    vpTabelaPromet_ProdajaX_7.AfterLoadDFMValues;
    vpTabelaPromet_ProdajaX_8.AfterLoadDFMValues;
    vpTabelaPromet_ProdajaX_9.AfterLoadDFMValues;
    vpTabelaPromet_ProdajaX_10.AfterLoadDFMValues;
    vpTabelaPromet_ProdajaX_11.AfterLoadDFMValues;
    vpTabelaPromet_ProdajaX_12.AfterLoadDFMValues;
    ploscicaTabelaPrometaXminus1.AfterLoadDFMValues;
    tabelaPromet_Mesec.AfterLoadDFMValues;
    vpTabelaPromet_LetoXminus1.AfterLoadDFMValues;
    tabelaPromet_Mesec1.AfterLoadDFMValues;
    tabelaPromet_Mesec2.AfterLoadDFMValues;
    tabelaPromet_Mesec3.AfterLoadDFMValues;
    tabelaPromet_Mesec4.AfterLoadDFMValues;
    tabelaPromet_Mesec5.AfterLoadDFMValues;
    tabelaPromet_Mesec6.AfterLoadDFMValues;
    tabelaPromet_Mesec7.AfterLoadDFMValues;
    tabelaPromet_Mesec8.AfterLoadDFMValues;
    tabelaPromet_Mesec9.AfterLoadDFMValues;
    tabelaPromet_Mesec10.AfterLoadDFMValues;
    tabelaPromet_Mesec11.AfterLoadDFMValues;
    tabelaPromet_Mesec12.AfterLoadDFMValues;
    tabelaPromet_Skupaj.AfterLoadDFMValues;
    vpTabelaPromet_LetoXminus1Skupaj.AfterLoadDFMValues;
    vpTabelaPromet_ProdajaXminus1_1.AfterLoadDFMValues;
    vpTabelaPromet_ProdajaXminus1_2.AfterLoadDFMValues;
    vpTabelaPromet_ProdajaXminus1_3.AfterLoadDFMValues;
    vpTabelaPromet_ProdajaXminus1_4.AfterLoadDFMValues;
    vpTabelaPromet_ProdajaXminus1_5.AfterLoadDFMValues;
    vpTabelaPromet_ProdajaXminus1_6.AfterLoadDFMValues;
    vpTabelaPromet_ProdajaXminus1_7.AfterLoadDFMValues;
    vpTabelaPromet_ProdajaXminus1_8.AfterLoadDFMValues;
    vpTabelaPromet_ProdajaXminus1_9.AfterLoadDFMValues;
    vpTabelaPromet_ProdajaXminus1_10.AfterLoadDFMValues;
    vpTabelaPromet_ProdajaXminus1_11.AfterLoadDFMValues;
    vpTabelaPromet_ProdajaXminus1_12.AfterLoadDFMValues;
    vpPlanProdaje_RastXplus1.AfterLoadDFMValues;
    vpPlanProdaje_RastXplus2.AfterLoadDFMValues;
    vpPlanProdaje_RastXplus3.AfterLoadDFMValues;
    vpPlanProdaje_RastXplus4.AfterLoadDFMValues;
    DelovniList4.AfterLoadDFMValues;
    WebScrollBoxDL4.AfterLoadDFMValues;
    izNaslovDL4.AfterLoadDFMValues;
    ploscicaTabelaOrganizacijskihVprasanj.AfterLoadDFMValues;
    izOrganizacija_St1.AfterLoadDFMValues;
    izOrganizacija_mera1.AfterLoadDFMValues;
    izOrganizacija_St2.AfterLoadDFMValues;
    izOrganizacija_mera2.AfterLoadDFMValues;
    izOrganizacija_St3.AfterLoadDFMValues;
    izOrganizacija_mera3.AfterLoadDFMValues;
    izOrganizacija_St4.AfterLoadDFMValues;
    izOrganizacija_mera4.AfterLoadDFMValues;
    izOrganizacija_St5.AfterLoadDFMValues;
    izOrganizacija_mera5.AfterLoadDFMValues;
    izOrganizacija_St6.AfterLoadDFMValues;
    izOrganizacija_mera6.AfterLoadDFMValues;
    izOrganizacija_St7.AfterLoadDFMValues;
    izOrganizacija_mera7.AfterLoadDFMValues;
    izOrganizacija_St8.AfterLoadDFMValues;
    izOrganizacija_mera8.AfterLoadDFMValues;
    izOrganizacija_St9.AfterLoadDFMValues;
    izOrganizacija_mera9.AfterLoadDFMValues;
    izOrganizacija_St10.AfterLoadDFMValues;
    izOrganizacija_mera10.AfterLoadDFMValues;
    izOrganizacija_St11.AfterLoadDFMValues;
    izOrganizacija_OdgovorPrazen11.AfterLoadDFMValues;
    izOrganizacija_MeraPrazen11.AfterLoadDFMValues;
    izOrganizacija_StPrazen11_1.AfterLoadDFMValues;
    izOrganizacija_MeraPrazen11_1.AfterLoadDFMValues;
    izOrganizacija_StPrazen11_2.AfterLoadDFMValues;
    izOrganizacija_mera11_2.AfterLoadDFMValues;
    izOrganizacija_StPrazen11_3.AfterLoadDFMValues;
    izOrganizacija_mera11_3.AfterLoadDFMValues;
    izOrganizacija_StPrazen11_4.AfterLoadDFMValues;
    izOrganizacija_MeraPrazen11_4.AfterLoadDFMValues;
    izOrganizacija_StPrazen11_5.AfterLoadDFMValues;
    izOrganizacija_mera11_5.AfterLoadDFMValues;
    izOrganizacija_StPrazen11_6.AfterLoadDFMValues;
    izOrganizacija_mera11_6.AfterLoadDFMValues;
    izOrganizacija_St12.AfterLoadDFMValues;
    izOrganizacija_mera12.AfterLoadDFMValues;
    izOrganizacija_St13.AfterLoadDFMValues;
    izOrganizacija_mera13.AfterLoadDFMValues;
    izOrganizacija_Vprasanje1.AfterLoadDFMValues;
    vpOrganizacija_Odgovor1.AfterLoadDFMValues;
    izOrganizacija_Vprasanje2.AfterLoadDFMValues;
    vpOrganizacija_Odgovor2.AfterLoadDFMValues;
    izOrganizacija_Vprasanje3.AfterLoadDFMValues;
    ploscicaOrganizacija_Odgovor3.AfterLoadDFMValues;
    vpOrganizacija_OdgovorDa3.AfterLoadDFMValues;
    vpOrganizacija_OdgovorNe3.AfterLoadDFMValues;
    izOrganizacija_Vprasanje4.AfterLoadDFMValues;
    ploscicaOrganizacija_Odgovor4.AfterLoadDFMValues;
    vpOrganizacija_OdgovorDa4.AfterLoadDFMValues;
    vpOrganizacija_OdgovorNe4.AfterLoadDFMValues;
    izOrganizacija_Vprasanje5.AfterLoadDFMValues;
    ploscicaOrganizacija_Odgovor5.AfterLoadDFMValues;
    vpOrganizacija_OdgovorDa5.AfterLoadDFMValues;
    vpOrganizacija_OdgovorNe5.AfterLoadDFMValues;
    izOrganizacija_Vprasanje6.AfterLoadDFMValues;
    ploscicaOrganizacija_Odgovor6.AfterLoadDFMValues;
    vpOrganizacija_OdgovorDa6.AfterLoadDFMValues;
    vpOrganizacija_OdgovorNe6.AfterLoadDFMValues;
    izOrganizacija_Vprasanje7.AfterLoadDFMValues;
    ploscicaOrganizacija_Odgovor7.AfterLoadDFMValues;
    vpOrganizacija_OdgovorDa7.AfterLoadDFMValues;
    vpOrganizacija_OdgovorNe7.AfterLoadDFMValues;
    izOrganizacija_Vprasanje8.AfterLoadDFMValues;
    ploscicaOrganizacija_Odgovor8.AfterLoadDFMValues;
    vpOrganizacija_OdgovorDa8.AfterLoadDFMValues;
    vpOrganizacija_OdgovorNe8.AfterLoadDFMValues;
    izOrganizacija_Vprasanje9.AfterLoadDFMValues;
    vpOrganizacija_Odgovor9.AfterLoadDFMValues;
    izOrganizacija_Vprasanje10.AfterLoadDFMValues;
    vpOrganizacija_Odgovor10.AfterLoadDFMValues;
    izOrganizacija_Vprasanje11.AfterLoadDFMValues;
    izOrganizacija_Vprasanje11_1.AfterLoadDFMValues;
    ploscicaOrganizacija_Odgovor11_1.AfterLoadDFMValues;
    vpOrganizacija_OdgovorDa11_1.AfterLoadDFMValues;
    vpOrganizacija_OdgovorNe11_1.AfterLoadDFMValues;
    izOrganizacija_Vprasanje11_2.AfterLoadDFMValues;
    vpOrganizacija_Odgovor11_2.AfterLoadDFMValues;
    izOrganizacija_Vprasanje11_3.AfterLoadDFMValues;
    vpOrganizacija_Odgovor11_3.AfterLoadDFMValues;
    izOrganizacija_Vprasanje11_4.AfterLoadDFMValues;
    ploscicaOrganizacija_Odgovor11_4.AfterLoadDFMValues;
    vpOrganizacija_OdgovorDa11_4.AfterLoadDFMValues;
    vpOrganizacija_OdgovorNe11_4.AfterLoadDFMValues;
    izOrganizacija_Vprasanje11_5.AfterLoadDFMValues;
    vpOrganizacija_Odgovor11_5.AfterLoadDFMValues;
    izOrganizacija_Vprasanje11_6.AfterLoadDFMValues;
    vpOrganizacija_Odgovor11_6.AfterLoadDFMValues;
    izOrganizacija_Vprasanje12.AfterLoadDFMValues;
    vpOrganizacija_Odgovor12.AfterLoadDFMValues;
    izOrganizacija_Vprasanje13.AfterLoadDFMValues;
    vpOrganizacija_Odgovor13.AfterLoadDFMValues;
    DelovniList5.AfterLoadDFMValues;
    WebScrollBox2.AfterLoadDFMValues;
    izNaslovDL5.AfterLoadDFMValues;
    ploscicaPodatkiOZavarovanju.AfterLoadDFMValues;
    izTekstZVKosmatiDobicek.AfterLoadDFMValues;
    izTekstStroski1.AfterLoadDFMValues;
    izTekstStroski2.AfterLoadDFMValues;
    izTekstMDJ.AfterLoadDFMValues;
    izTekstZacetekZavarovanja.AfterLoadDFMValues;
    izTekstPremija.AfterLoadDFMValues;
    izMDJ.AfterLoadDFMValues;
    vpZavarovanjeDAKosmatiDobicek.AfterLoadDFMValues;
    vpZVKosmatiDobicek.AfterLoadDFMValues;
    vpZavarovanjeDANastetiStroski.AfterLoadDFMValues;
    ploscicaNastetiStroski.AfterLoadDFMValues;
    izZavarovaniStroski1_1.AfterLoadDFMValues;
    izZavarovaniStroski2_1.AfterLoadDFMValues;
    izZavarovaniStroski1_2.AfterLoadDFMValues;
    izZavarovaniStroski1_3.AfterLoadDFMValues;
    izZavarovaniStroski1_4.AfterLoadDFMValues;
    izZavarovaniStroski1_5.AfterLoadDFMValues;
    izZavarovaniStroski1_6.AfterLoadDFMValues;
    vpZavarovaniStroski2_2.AfterLoadDFMValues;
    vpZavarovaniStroski2_3.AfterLoadDFMValues;
    vpZavarovaniStroski2_4.AfterLoadDFMValues;
    vpZavarovaniStroski2_5.AfterLoadDFMValues;
    vpZavarovaniStroski2_6.AfterLoadDFMValues;
    vpZVNastetiStroski.AfterLoadDFMValues;
    vpMDJ.AfterLoadDFMValues;
    vpZacetekZavarovanja.AfterLoadDFMValues;
    vpPremija.AfterLoadDFMValues;
    vpZavarovanjeDa.AfterLoadDFMValues;
    DelovniList6.AfterLoadDFMValues;
    WebScrollBoxDL6.AfterLoadDFMValues;
    izNaslovDL6.AfterLoadDFMValues;
    izNastavljanjeOsnovnihParametrov.AfterLoadDFMValues;
    ploscicaKorekcijskiFaktorji.AfterLoadDFMValues;
    izPloscicaKorekcijskiFaktorji_Naslov1.AfterLoadDFMValues;
    izPloscicaKorekcijskiFaktorji_Naslov2.AfterLoadDFMValues;
    izPloscicaKorekcijskiFaktorji_Naslov3.AfterLoadDFMValues;
    izPloscicaKorekcijskiFaktorji_Naslov4.AfterLoadDFMValues;
    izPloscicaKorekcijskiFaktorji_KF1_1.AfterLoadDFMValues;
    izPloscicaKorekcijskiFaktorji_KF1_2.AfterLoadDFMValues;
    izPloscicaKorekcijskiFaktorji_KF1_3.AfterLoadDFMValues;
    izPloscicaKorekcijskiFaktorji_KF2_1.AfterLoadDFMValues;
    izPloscicaKorekcijskiFaktorji_KF2_2.AfterLoadDFMValues;
    izPloscicaKorekcijskiFaktorji_KF2_3.AfterLoadDFMValues;
    izPloscicaKorekcijskiFaktorji_KF3_1.AfterLoadDFMValues;
    izPloscicaKorekcijskiFaktorji_KF3_2.AfterLoadDFMValues;
    izPloscicaKorekcijskiFaktorji_KF3_3.AfterLoadDFMValues;
    izPloscicaKorekcijskiFaktorji_KF4_1.AfterLoadDFMValues;
    izPloscicaKorekcijskiFaktorji_KF4_2.AfterLoadDFMValues;
    izPloscicaKorekcijskiFaktorji_KF4_3.AfterLoadDFMValues;
    izPloscicaKorekcijskiFaktorji_KF5_1.AfterLoadDFMValues;
    izPloscicaKorekcijskiFaktorji_KF5_2.AfterLoadDFMValues;
    izPloscicaKorekcijskiFaktorji_KF5_3.AfterLoadDFMValues;
    ploscicaPloscicaKorekcijskiFaktor_1.AfterLoadDFMValues;
    vpPloscicaKorekcijskiFaktorji_KFIzpis1_4.AfterLoadDFMValues;
    vpPloscicaKorekcijskiFaktorji_KFDrsnik1_4.AfterLoadDFMValues;
    ploscicaPloscicaKorekcijskiFaktor_2.AfterLoadDFMValues;
    vpPloscicaKorekcijskiFaktorji_KFIzpis2_4.AfterLoadDFMValues;
    vpPloscicaKorekcijskiFaktorji_KFDrsnik2_4.AfterLoadDFMValues;
    ploscicaPloscicaKorekcijskiFaktor_3.AfterLoadDFMValues;
    vpPloscicaKorekcijskiFaktorji_KFIzpis3_4.AfterLoadDFMValues;
    vpPloscicaKorekcijskiFaktorji_KFDrsnik3_4.AfterLoadDFMValues;
    ploscicaPloscicaKorekcijskiFaktor_4.AfterLoadDFMValues;
    vpPloscicaKorekcijskiFaktorji_KFIzpis4_4.AfterLoadDFMValues;
    vpPloscicaKorekcijskiFaktorji_KFDrsnik4_4.AfterLoadDFMValues;
    ploscicaPloscicaKorekcijskiFaktor_5.AfterLoadDFMValues;
    vpPloscicaKorekcijskiFaktorji_KFIzpis5_4.AfterLoadDFMValues;
    vpPloscicaKorekcijskiFaktorji_KFDrsnik5_4.AfterLoadDFMValues;
    ploscicaMaksimalniPribitek.AfterLoadDFMValues;
    izMaksimalniPribitek.AfterLoadDFMValues;
    vpMaksimalniPribitek.AfterLoadDFMValues;
    ploscicaMDJRezultat.AfterLoadDFMValues;
    izTekstPriporocenaMDJ.AfterLoadDFMValues;
    izPriporocenaMDJ.AfterLoadDFMValues;
    ploscicaParametriMDJ.AfterLoadDFMValues;
    izParametriMDJ_Naslov_1.AfterLoadDFMValues;
    izParametriMDJ_Naslov_2.AfterLoadDFMValues;
    izParametriMDJ_Naslov_3.AfterLoadDFMValues;
    izParametriMDJ_Parameter1.AfterLoadDFMValues;
    izParametriMDJ_Parameter2.AfterLoadDFMValues;
    izParametriMDJ_Parameter3.AfterLoadDFMValues;
    izParametriMDJ_Parameter4.AfterLoadDFMValues;
    izParametriMDJ_Parameter5.AfterLoadDFMValues;
    izParametriMDJ_Parameter6.AfterLoadDFMValues;
    izParametriMDJ_PrazenParameter6_2.AfterLoadDFMValues;
    izParametriMDJ_Parameter7.AfterLoadDFMValues;
    izParametriMDJ_PrazenParameter7_2.AfterLoadDFMValues;
    ploscicaParameter1_1.AfterLoadDFMValues;
    izParameter1_1.AfterLoadDFMValues;
    vpParameter1_1.AfterLoadDFMValues;
    ploscicaParameter1_2.AfterLoadDFMValues;
    izParameter1_2.AfterLoadDFMValues;
    vpParameter1_2.AfterLoadDFMValues;
    ploscicaParameter2_1.AfterLoadDFMValues;
    izParameter2_1.AfterLoadDFMValues;
    vpParameter2_1.AfterLoadDFMValues;
    ploscicaParameter2_2.AfterLoadDFMValues;
    izParameter2_2.AfterLoadDFMValues;
    vpParameter2_2.AfterLoadDFMValues;
    ploscicaParameter3_1.AfterLoadDFMValues;
    izParameter3_1.AfterLoadDFMValues;
    vpParameter3_1.AfterLoadDFMValues;
    ploscicaParameter3_2.AfterLoadDFMValues;
    izParameter3_2.AfterLoadDFMValues;
    vpParameter3_2.AfterLoadDFMValues;
    ploscicaParameter4_1.AfterLoadDFMValues;
    izParameter4_1.AfterLoadDFMValues;
    vpParameter4_1.AfterLoadDFMValues;
    ploscicaParameter4_2.AfterLoadDFMValues;
    izParameter4_2.AfterLoadDFMValues;
    vpParameter4_2.AfterLoadDFMValues;
    ploscicaParameter5_1.AfterLoadDFMValues;
    izParameter5_1.AfterLoadDFMValues;
    vpParameter5_1.AfterLoadDFMValues;
    ploscicaParameter5_2.AfterLoadDFMValues;
    izParameter5_2.AfterLoadDFMValues;
    vpParameter5_2.AfterLoadDFMValues;
    ploscicaParameter6_1.AfterLoadDFMValues;
    izParameter6_1.AfterLoadDFMValues;
    vpParameter6_1.AfterLoadDFMValues;
    ploscicaParameter7_1.AfterLoadDFMValues;
    izParameter7_1.AfterLoadDFMValues;
    vpParameter7_1.AfterLoadDFMValues;
    vpKorekcijskiFaktorji.AfterLoadDFMValues;
    ploscicaGrafMDJ.AfterLoadDFMValues;
    GrafMDJ.AfterLoadDFMValues;
    ploscicaGrafikaMDJ_osnovni.AfterLoadDFMValues;
    izGraficniPrikazMDJ.AfterLoadDFMValues;
    ploscicaGrafikaMDJ.AfterLoadDFMValues;
    izGrafikaMDJ_DA.AfterLoadDFMValues;
    izGrafikaMDJ_NE.AfterLoadDFMValues;
    vpGrafikaMDJ.AfterLoadDFMValues;
    DelovniList7.AfterLoadDFMValues;
    WebScrollBoxDL7.AfterLoadDFMValues;
    izNaslovDL7.AfterLoadDFMValues;
    ploscicaPriporoceneZV.AfterLoadDFMValues;
    izTekstLetoPriporoceneZV.AfterLoadDFMValues;
    ploscicaLetoPriporoceneZV.AfterLoadDFMValues;
    izLetoPriporoceneZVmax.AfterLoadDFMValues;
    izLetoPriporoceneZVmin.AfterLoadDFMValues;
    vpLetoPriporoceneZV.AfterLoadDFMValues;
    ploscicaTabelaPriporoceneZV.AfterLoadDFMValues;
    izPriporoceneZV_Naslov1.AfterLoadDFMValues;
    izPriporoceneZV_Naslov2.AfterLoadDFMValues;
    izPriporoceneZV_tekstZV12.AfterLoadDFMValues;
    izPriporoceneZV_ZV12.AfterLoadDFMValues;
    izPriporoceneZV_tekstZV18.AfterLoadDFMValues;
    izPriporoceneZV_ZV18.AfterLoadDFMValues;
    izPriporoceneZV_tekstZV24.AfterLoadDFMValues;
    izPriporoceneZV_ZV24.AfterLoadDFMValues;
    ploscicaZVRezultat.AfterLoadDFMValues;
    izTekstPriporoceniKD.AfterLoadDFMValues;
    izTekstPriporocenaSKD.AfterLoadDFMValues;
    izPriporoceniKD.AfterLoadDFMValues;
    izPriporocenaSKD.AfterLoadDFMValues;
    DelovniList8.AfterLoadDFMValues;
    WebScrollBoxDL8.AfterLoadDFMValues;
    izNaslovDL8.AfterLoadDFMValues;
    ListiProdajnihTrendov.AfterLoadDFMValues;
    LPT1_Tabela.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_Meseci.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izMesec_Naslov.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izMesec_1.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izMesec_2.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izMesec_3.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izMesec_4.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izMesec_5.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izMesec_6.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izMesec_7.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izMesec_8.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izMesec_9.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izMesec_10.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izMesec_11.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izMesec_12.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_PrometX.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izPromet_NaslovX.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izPromet_2_1.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izPromet_2_2.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izPromet_2_3.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izPromet_2_4.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izPromet_2_5.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izPromet_2_6.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izPromet_2_7.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izPromet_2_8.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izPromet_2_9.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izPromet_2_10.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izPromet_2_11.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izPromet_2_12.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_PrometXminus1.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izPromet_NaslovXminus1.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izPromet_1_1.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izPromet_1_2.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izPromet_1_3.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izPromet_1_4.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izPromet_1_5.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izPromet_1_6.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izPromet_1_7.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izPromet_1_8.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izPromet_1_9.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izPromet_1_10.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izPromet_1_11.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izPromet_1_12.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_PrometXplus1.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izPromet_NaslovXplus1.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izPromet_3_1.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izPromet_3_2.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izPromet_3_3.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izPromet_3_4.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izPromet_3_5.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izPromet_3_6.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izPromet_3_7.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izPromet_3_8.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izPromet_3_9.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izPromet_3_10.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izPromet_3_11.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izPromet_3_12.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_PrometXplus2.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izPromet_NaslovXplus2.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izPromet_4_1.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izPromet_4_2.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izPromet_4_3.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izPromet_4_4.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izPromet_4_5.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izPromet_4_6.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izPromet_4_7.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izPromet_4_8.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izPromet_4_9.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izPromet_4_10.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izPromet_4_11.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izPromet_4_12.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_PrometXplus3.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izPromet_NaslovXplus3.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izPromet_5_1.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izPromet_5_2.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izPromet_5_3.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izPromet_5_4.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izPromet_5_5.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izPromet_5_6.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izPromet_5_7.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izPromet_5_8.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izPromet_5_9.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izPromet_5_10.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izPromet_5_11.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izPromet_5_12.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_PrometXplus4.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izPromet_NaslovXplus4.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izPromet_6_1.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izPromet_6_2.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izPromet_6_3.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izPromet_6_4.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izPromet_6_5.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izPromet_6_6.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izPromet_6_7.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izPromet_6_8.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izPromet_6_9.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izPromet_6_10.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izPromet_6_11.AfterLoadDFMValues;
    ploscicaAnalizeProdaje_izPromet_6_12.AfterLoadDFMValues;
    LPT2_MesecniGraf.AfterLoadDFMValues;
    GrafMesecniTrendi.AfterLoadDFMValues;
    LPT3_LetniGraf.AfterLoadDFMValues;
    GrafLetniTrendi.AfterLoadDFMValues;
    DelovniList9.AfterLoadDFMValues;
    WebScrollBoxDL9.AfterLoadDFMValues;
    izNaslovDL9.AfterLoadDFMValues;
    ploscicaObstojeceZavarovanje.AfterLoadDFMValues;
    izPloscicaObstojeceZavarovanje_Vprasanje1.AfterLoadDFMValues;
    izPloscicaObstojeceZavarovanje_Odgovor1.AfterLoadDFMValues;
    izPloscicaObstojeceZavarovanje_Semafor1.AfterLoadDFMValues;
    izPloscicaObstojeceZavarovanje_prazna1.AfterLoadDFMValues;
    izPloscicaObstojeceZavarovanje_Vprasanje2.AfterLoadDFMValues;
    izPloscicaObstojeceZavarovanje_Odgovor2.AfterLoadDFMValues;
    izPloscicaObstojeceZavarovanje_Semafor2.AfterLoadDFMValues;
    izPloscicaObstojeceZavarovanje_prazna2.AfterLoadDFMValues;
    izPloscicaObstojeceZavarovanje_Vprasanje3.AfterLoadDFMValues;
    izPloscicaObstojeceZavarovanje_Odgovor3.AfterLoadDFMValues;
    izPloscicaObstojeceZavarovanje_Semafor3.AfterLoadDFMValues;
    izPloscicaObstojeceZavarovanje_Semafor3b.AfterLoadDFMValues;
    izPloscicaObstojeceZavarovanje_Vprasanje4.AfterLoadDFMValues;
    izPloscicaObstojeceZavarovanje_Odgovor4.AfterLoadDFMValues;
    izPloscicaObstojeceZavarovanje_Semafor4.AfterLoadDFMValues;
    izPloscicaObstojeceZavarovanje_prazna4.AfterLoadDFMValues;
    DelovniList10.AfterLoadDFMValues;
    WebScrollBoxDL10.AfterLoadDFMValues;
    izDatumFizicneSkode.AfterLoadDFMValues;
    izNaslovDL10.AfterLoadDFMValues;
    gPreracunaj.AfterLoadDFMValues;
    ploscicaPodatkiZaSimulacijoSkode.AfterLoadDFMValues;
    DelovniListiPodatkiZaSimulacijoSkod.AfterLoadDFMValues;
    WebTabSheet1.AfterLoadDFMValues;
    izGraficniPrikazSimulacijaSkode.AfterLoadDFMValues;
    ploscicaTabelaPodatkovOPrometu.AfterLoadDFMValues;
    ploscicaPromet_Naslov1.AfterLoadDFMValues;
    ploscicaPromet_Naslov2.AfterLoadDFMValues;
    ploscicaPromet_Naslov3.AfterLoadDFMValues;
    ploscicaPromet_Naslov4.AfterLoadDFMValues;
    ploscicaPromet_Naslov5.AfterLoadDFMValues;
    ploscicaPromet_Naslov6.AfterLoadDFMValues;
    ploscicaPromet_Naslov7.AfterLoadDFMValues;
    ploscicaPromet_Naslov8.AfterLoadDFMValues;
    ploscicaPromet_Mesec1.AfterLoadDFMValues;
    ploscicaPromet_Mesec2.AfterLoadDFMValues;
    ploscicaPromet_Mesec3.AfterLoadDFMValues;
    ploscicaPromet_Mesec4.AfterLoadDFMValues;
    ploscicaPromet_Mesec5.AfterLoadDFMValues;
    ploscicaPromet_Mesec6.AfterLoadDFMValues;
    ploscicaPromet_Mesec7.AfterLoadDFMValues;
    ploscicaPromet_Mesec8.AfterLoadDFMValues;
    ploscicaPromet_Mesec9.AfterLoadDFMValues;
    ploscicaPromet_Mesec10.AfterLoadDFMValues;
    ploscicaPromet_Mesec11.AfterLoadDFMValues;
    ploscicaPromet_Mesec12.AfterLoadDFMValues;
    ploscicaPromet_Promet_1_1.AfterLoadDFMValues;
    ploscicaPromet_Promet_2_1.AfterLoadDFMValues;
    ploscicaPromet_Promet_3_1.AfterLoadDFMValues;
    ploscicaPromet_Promet_4_1.AfterLoadDFMValues;
    ploscicaPromet_Indeks_1_1.AfterLoadDFMValues;
    ploscicaPromet_Indeks_2_1.AfterLoadDFMValues;
    ploscicaPromet_Indeks_3_1.AfterLoadDFMValues;
    ploscicaPromet_Promet_1_2.AfterLoadDFMValues;
    ploscicaPromet_Promet_2_2.AfterLoadDFMValues;
    ploscicaPromet_Promet_3_2.AfterLoadDFMValues;
    ploscicaPromet_Promet_4_2.AfterLoadDFMValues;
    ploscicaPromet_Indeks_1_2.AfterLoadDFMValues;
    ploscicaPromet_Indeks_2_2.AfterLoadDFMValues;
    ploscicaPromet_Indeks_3_2.AfterLoadDFMValues;
    ploscicaPromet_Promet_1_3.AfterLoadDFMValues;
    ploscicaPromet_Promet_2_3.AfterLoadDFMValues;
    ploscicaPromet_Promet_3_3.AfterLoadDFMValues;
    ploscicaPromet_Promet_4_3.AfterLoadDFMValues;
    ploscicaPromet_Indeks_1_3.AfterLoadDFMValues;
    ploscicaPromet_Indeks_2_3.AfterLoadDFMValues;
    ploscicaPromet_Indeks_3_3.AfterLoadDFMValues;
    ploscicaPromet_Promet_1_4.AfterLoadDFMValues;
    ploscicaPromet_Promet_2_4.AfterLoadDFMValues;
    ploscicaPromet_Promet_3_4.AfterLoadDFMValues;
    ploscicaPromet_Promet_4_4.AfterLoadDFMValues;
    ploscicaPromet_Indeks_1_4.AfterLoadDFMValues;
    ploscicaPromet_Indeks_2_4.AfterLoadDFMValues;
    ploscicaPromet_Indeks_3_4.AfterLoadDFMValues;
    ploscicaPromet_Promet_1_5.AfterLoadDFMValues;
    ploscicaPromet_Promet_2_5.AfterLoadDFMValues;
    ploscicaPromet_Promet_3_5.AfterLoadDFMValues;
    ploscicaPromet_Promet_4_5.AfterLoadDFMValues;
    ploscicaPromet_Indeks_1_5.AfterLoadDFMValues;
    ploscicaPromet_Indeks_2_5.AfterLoadDFMValues;
    ploscicaPromet_Indeks_3_5.AfterLoadDFMValues;
    ploscicaPromet_Promet_1_6.AfterLoadDFMValues;
    ploscicaPromet_Promet_2_6.AfterLoadDFMValues;
    ploscicaPromet_Promet_3_6.AfterLoadDFMValues;
    ploscicaPromet_Promet_4_6.AfterLoadDFMValues;
    ploscicaPromet_Indeks_1_6.AfterLoadDFMValues;
    ploscicaPromet_Indeks_2_6.AfterLoadDFMValues;
    ploscicaPromet_Indeks_3_6.AfterLoadDFMValues;
    ploscicaPromet_Promet_1_7.AfterLoadDFMValues;
    ploscicaPromet_Promet_2_7.AfterLoadDFMValues;
    ploscicaPromet_Promet_3_7.AfterLoadDFMValues;
    ploscicaPromet_Promet_4_7.AfterLoadDFMValues;
    ploscicaPromet_Indeks_1_7.AfterLoadDFMValues;
    ploscicaPromet_Indeks_2_7.AfterLoadDFMValues;
    ploscicaPromet_Indeks_3_7.AfterLoadDFMValues;
    ploscicaPromet_Promet_1_8.AfterLoadDFMValues;
    ploscicaPromet_Promet_2_8.AfterLoadDFMValues;
    ploscicaPromet_Promet_3_8.AfterLoadDFMValues;
    ploscicaPromet_Promet_4_8.AfterLoadDFMValues;
    ploscicaPromet_Indeks_1_8.AfterLoadDFMValues;
    ploscicaPromet_Indeks_2_8.AfterLoadDFMValues;
    ploscicaPromet_Indeks_3_8.AfterLoadDFMValues;
    ploscicaPromet_Promet_1_9.AfterLoadDFMValues;
    ploscicaPromet_Promet_2_9.AfterLoadDFMValues;
    ploscicaPromet_Promet_3_9.AfterLoadDFMValues;
    ploscicaPromet_Promet_4_9.AfterLoadDFMValues;
    ploscicaPromet_Indeks_1_9.AfterLoadDFMValues;
    ploscicaPromet_Indeks_2_9.AfterLoadDFMValues;
    ploscicaPromet_Indeks_3_9.AfterLoadDFMValues;
    ploscicaPromet_Promet_1_10.AfterLoadDFMValues;
    ploscicaPromet_Promet_2_10.AfterLoadDFMValues;
    ploscicaPromet_Promet_3_10.AfterLoadDFMValues;
    ploscicaPromet_Promet_4_10.AfterLoadDFMValues;
    ploscicaPromet_Indeks_1_10.AfterLoadDFMValues;
    ploscicaPromet_Indeks_2_10.AfterLoadDFMValues;
    ploscicaPromet_Indeks_3_10.AfterLoadDFMValues;
    ploscicaPromet_Promet_1_11.AfterLoadDFMValues;
    ploscicaPromet_Promet_2_11.AfterLoadDFMValues;
    ploscicaPromet_Promet_3_11.AfterLoadDFMValues;
    ploscicaPromet_Promet_4_11.AfterLoadDFMValues;
    ploscicaPromet_Indeks_1_11.AfterLoadDFMValues;
    ploscicaPromet_Indeks_2_11.AfterLoadDFMValues;
    ploscicaPromet_Indeks_3_11.AfterLoadDFMValues;
    ploscicaPromet_Promet_1_12.AfterLoadDFMValues;
    ploscicaPromet_Promet_2_12.AfterLoadDFMValues;
    ploscicaPromet_Promet_3_12.AfterLoadDFMValues;
    ploscicaPromet_Promet_4_12.AfterLoadDFMValues;
    ploscicaPromet_Indeks_1_12.AfterLoadDFMValues;
    ploscicaPromet_Indeks_2_12.AfterLoadDFMValues;
    ploscicaPromet_Indeks_3_12.AfterLoadDFMValues;
    gVnosPopolnegaZastoja.AfterLoadDFMValues;
    gAvtomaticnoPolnjenje.AfterLoadDFMValues;
    gVnesiKrivuljoZastoja.AfterLoadDFMValues;
    ploscicaGrafikaSkode.AfterLoadDFMValues;
    izGrafikaSkode_DA.AfterLoadDFMValues;
    izGrafikaSkode_NE.AfterLoadDFMValues;
    vpGrafikaSkode.AfterLoadDFMValues;
    WebTabSheet2.AfterLoadDFMValues;
    ploscicaPovisaniStroski.AfterLoadDFMValues;
    izPovisaniPoslovniStroskiOpis.AfterLoadDFMValues;
    izResenPrometOpis.AfterLoadDFMValues;
    izPovisaniPoslovniStroskiObdobjeOpis.AfterLoadDFMValues;
    vpPovisaniPoslovniStroskiZnesek.AfterLoadDFMValues;
    vpResenPrometZnesek.AfterLoadDFMValues;
    vpPovisaniPoslovniStroskiObdobje.AfterLoadDFMValues;
    WebTabSheet3.AfterLoadDFMValues;
    izPrihrankiObdobjeOpis.AfterLoadDFMValues;
    ploscicaPrihranki.AfterLoadDFMValues;
    ploscicaPrihranki_Naslov1.AfterLoadDFMValues;
    ploscicaPrihranki_Naslov2.AfterLoadDFMValues;
    ploscicaPrihranki_Prihranek_1_1.AfterLoadDFMValues;
    ploscicaPrihranki_Prihranek_2_1.AfterLoadDFMValues;
    ploscicaPrihranki_Prihranek_1_2.AfterLoadDFMValues;
    ploscicaPrihranki_Prihranek_2_2.AfterLoadDFMValues;
    ploscicaPrihranki_Prihranek_1_3.AfterLoadDFMValues;
    ploscicaPrihranki_Prihranek_2_3.AfterLoadDFMValues;
    ploscicaPrihranki_Prihranek_1_4.AfterLoadDFMValues;
    ploscicaPrihranki_Prihranek_2_4.AfterLoadDFMValues;
    ploscicaPrihranki_Prihranek_1_5.AfterLoadDFMValues;
    ploscicaPrihranki_Prihranek_2_5.AfterLoadDFMValues;
    ploscicaPrihranki_Prihranek_1_6.AfterLoadDFMValues;
    ploscicaPrihranki_Prihranek_2_6.AfterLoadDFMValues;
    ploscicaPrihranki_Prihranek_1_7.AfterLoadDFMValues;
    ploscicaPrihranki_Prihranek_2_7.AfterLoadDFMValues;
    ploscicaPrihranki_Prihranek_1_8.AfterLoadDFMValues;
    ploscicaPrihranki_Prihranek_2_8.AfterLoadDFMValues;
    ploscicaPrihranki_Prihranek_1_9.AfterLoadDFMValues;
    ploscicaPrihranki_Prihranek_2_9.AfterLoadDFMValues;
    ploscicaPrihranki_Prihranek_1_10.AfterLoadDFMValues;
    ploscicaPrihranki_Prihranek_2_10.AfterLoadDFMValues;
    ploscicaPrihranki_PrihranekSkupajOpis.AfterLoadDFMValues;
    ploscicaPrihranki_PrihranekSkupajZnesek.AfterLoadDFMValues;
    vpPrihrankiObdobje.AfterLoadDFMValues;
    gShraniSkodo.AfterLoadDFMValues;
    gNaloziSkodo.AfterLoadDFMValues;
    ploscicaSimulacijaSkode_PodatkiOZavarovanju.AfterLoadDFMValues;
    izPodatkiOZavarovanju_Naslov.AfterLoadDFMValues;
    izPodatkioZavarovanju_Od.AfterLoadDFMValues;
    izPodatkioZavarovanju_Do.AfterLoadDFMValues;
    izPodatkioZavarovanju_ZavarovalnaVsota.AfterLoadDFMValues;
    izPodatkioZavarovanju_MaksimalnaDobaJamcenja.AfterLoadDFMValues;
    izPodatkioZavarovanju_Premija.AfterLoadDFMValues;
    izMaksimalnaDobaJamcenjaTekst.AfterLoadDFMValues;
    vpZavarovanjeOd.AfterLoadDFMValues;
    vpZavarovanjeDo.AfterLoadDFMValues;
    izZavarovalnaVsota.AfterLoadDFMValues;
    izMaksimalnaDobaJamcenja.AfterLoadDFMValues;
    izPremija.AfterLoadDFMValues;
    vpDatumFizicneSkode.AfterLoadDFMValues;
    ploscicaGrafSimulacijaSkode.AfterLoadDFMValues;
    GrafSimulacijaSkode.AfterLoadDFMValues;
    DelovniList0B.AfterLoadDFMValues;
    WebScrollBoxDL0B.AfterLoadDFMValues;
    gNaloziProjekt.AfterLoadDFMValues;
    gPrekiniDelo.AfterLoadDFMValues;
    gZacniNovProjekt.AfterLoadDFMValues;
    ploscicaNaslovDL0B.AfterLoadDFMValues;
    izNaslov2_1_DL0B.AfterLoadDFMValues;
    izNaslov2_2_DL0B.AfterLoadDFMValues;
    izNaslov2_3_DL0B.AfterLoadDFMValues;
    logo_ModelBI_DL0B.AfterLoadDFMValues;
    ploscicaGlava.AfterLoadDFMValues;
    logoRC1.AfterLoadDFMValues;
    izVerzija.AfterLoadDFMValues;
    ploscicaIzborPogledov.AfterLoadDFMValues;
    izIzborVhodniPodatki.AfterLoadDFMValues;
    izIzborAnalize.AfterLoadDFMValues;
    izIzborSimulacijeSkod.AfterLoadDFMValues;
    izborVhodniPodatki.AfterLoadDFMValues;
    izborAnalize.AfterLoadDFMValues;
    izborSimulacijeSkod.AfterLoadDFMValues;
    ploscicaUporabnik.AfterLoadDFMValues;
    izUporabnikTekst.AfterLoadDFMValues;
    izPodjetjeTekst.AfterLoadDFMValues;
    izUporabnik.AfterLoadDFMValues;
    izPotekLicenceTekst.AfterLoadDFMValues;
    izPodjetje.AfterLoadDFMValues;
    izPotekLicence.AfterLoadDFMValues;
    ploscicaGlavniMeni.AfterLoadDFMValues;
    PovezavaServerGeslo.AfterLoadDFMValues;
    WebClientGeslo.AfterLoadDFMValues;
    PovezavaServerPodatki.AfterLoadDFMValues;
    WebClientPodatki.AfterLoadDFMValues;
    PovezavaWSPopolniPreracun.AfterLoadDFMValues;
    WebClientWSPopolniIzracun.AfterLoadDFMValues;
    PovezavaWSDelniIzracuni.AfterLoadDFMValues;
    WebClientWSDelniIzracuni.AfterLoadDFMValues;
    menuKrivuljaZastoja.AfterLoadDFMValues;
    mnKrivulja1.AfterLoadDFMValues;
    mnKrivulja2.AfterLoadDFMValues;
    mnKrivulja3.AfterLoadDFMValues;
    menuPopolniZastoj.AfterLoadDFMValues;
    mn3.AfterLoadDFMValues;
    mn4.AfterLoadDFMValues;
    mn5.AfterLoadDFMValues;
    mn6.AfterLoadDFMValues;
    mn7.AfterLoadDFMValues;
    mn8.AfterLoadDFMValues;
    mn9.AfterLoadDFMValues;
    mn10.AfterLoadDFMValues;
    mn11.AfterLoadDFMValues;
    mn12.AfterLoadDFMValues;
    PovezavaServerShranjevanje.AfterLoadDFMValues;
    WebClientShranjevanje.AfterLoadDFMValues;
    PovezavaServerJezik.AfterLoadDFMValues;
    WebClientJezik.AfterLoadDFMValues;
    mnGlavniMeni.AfterLoadDFMValues;
    mnNovProjekt.AfterLoadDFMValues;
    mnOdpriProjekt.AfterLoadDFMValues;
    mnShraniProjekt.AfterLoadDFMValues;
    mnOsveziAnalize.AfterLoadDFMValues;
    mnOsveziPrivzeteVrednosti.AfterLoadDFMValues;
    N6.AfterLoadDFMValues;
    mnOsveziDejanskeVrednosti.AfterLoadDFMValues;
    mnOstalo.AfterLoadDFMValues;
    mnNastavitve.AfterLoadDFMValues;
    N4.AfterLoadDFMValues;
    mnOOrodju.AfterLoadDFMValues;
    N5.AfterLoadDFMValues;
    mnPomoc.AfterLoadDFMValues;
    mnZakljuciDelo.AfterLoadDFMValues;
    PovezavaServerSkode.AfterLoadDFMValues;
    WebClientSkode.AfterLoadDFMValues;
  end;
end;

end.
