unit MBI_u03;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Imaging.pngimage, WEBLib.Buttons,
  Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls, WEBLib.ExtCtrls,
  XData.Web.Client, XData.Web.Connection;

type
  TfmNastavitveUporabnika = class(TForm)
    ploscicaGlava: TPanel;
    izUporabnikTekst: TLabel;
    izPodjetjeTekst: TLabel;
    izLicencaTekst: TLabel;
    gZapriNastavitve: TSpeedButton;
    izUporabnik: TLabel;
    izPodjetje: TLabel;
    izStaroGeslo: TLabel;
    vpStaroGeslo: TEdit;
    izNovoGeslo1Tekst: TLabel;
    vpNovoGeslo1: TEdit;
    izNovoGeslo2Tekst: TLabel;
    vpNovoGeslo2: TEdit;
    ploscicaSpremembaGesla: TPanel;
    izSpremeniGeslo: TLabel;
    gSpremeniGeslo: TSpeedButton;
    izSpremembaGeslaOpozorilo: TLabel;
    izVeljavnostLicence: TDateTimePicker;
    povezavaServerSpremembaGesla: TXDataWebConnection;
    WebClientSpremembaGesla: TXDataWebClient;
    procedure gZapriNastavitveClick(Sender: TObject);
    procedure gSpremeniGesloClick(Sender: TObject);
    procedure povezavaServerSpremembaGeslaError(
      Error: TXDataWebConnectionError);
    procedure povezavaServerSpremembaGeslaConnect(Sender: TObject);
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  fmNastavitveUporabnika: TfmNastavitveUporabnika;

implementation


uses MBI_u01;

{$R *.dfm}

procedure TfmNastavitveUporabnika.gSpremeniGesloClick(Sender: TObject);
begin
  if vpNovoGeslo1.Text <> vpNovoGeslo2.Text then begin
    vpStaroGeslo.Text := '';
    vpNovoGeslo1.Text := '';
    vpNovoGeslo2.Text := '';
    izSpremembaGeslaOpozorilo.Caption := LjezikUporabnik_Opozorilo1;
    vpStaroGeslo.SetFocus;
  end
  else begin
    WebClientSpremembaGesla.Connection := PovezavaServerSpremembaGesla;
    fmOsnovniKontroler.Cursor := crHourGlass;
    if PovezavaServerSpremembaGesla.Connected = true then
       PovezavaServerSpremembaGesla.Connected := false;
    try
      PovezavaServerSpremembaGesla.Connected := true;  // Zažene povezavo s serjeverjem. Ko je ta vzpostavljena se zažene procedura OnConnect gradnika PovezavaServer
    except
      izSpremembaGeslaOpozorilo.Visible := false;
      izSpremembaGeslaOpozorilo.Caption := '';
      vpStaroGeslo.Text := '';
      vpNovoGeslo1.Text := '';
      vpNovoGeslo2.Text := '';
      vpStaroGeslo.SetFocus;
      showMessage(LjezikObvestilo_ServerNiPovezave_02);
    end;
  end;
end;

procedure TfmNastavitveUporabnika.gZapriNastavitveClick(Sender: TObject);
begin
  Close;
end;

procedure TfmNastavitveUporabnika.povezavaServerSpremembaGeslaConnect(
  Sender: TObject);

 procedure OnResult( Response: TXDataClientResponse );
  var
    LArray : TJSArray;
    LItem : TJSObject;
    // spremenljivke v katere se prepišejo vrednosti/rezultati iz poizvedbe / web servisa
    LUporabnik : String;
    LGeslo : String;
    LPodjetje : String;
    // spremenljivka, ki pobere vse vrdnosti iz baze
    podatkiObj: TJSObject;

  begin

    try
      LArray := TJSArray( TJSObject( Response.Result )['value']);
      LItem := TJSObject( LArray[0]);

      if LItem <> null then begin
          LUporabnik := string(LItem['Uporabnik']);
          LPodjetje := string(LItem['Podjetje']);
          LGeslo := string(LItem['Geslo']);

          if LUporabnik = izUporabnik.Caption then begin

             if LGeslo = vpStaroGeslo.Text then begin
                 izSpremembaGeslaOpozorilo.Visible := false;
                 try
                   podatkiObj := TJSObject.New;
                   podatkiObj['Uporabnik'] := LUporabnik;
                   podatkiObj['Podjetje'] := Lpodjetje;
                   podatkiObj['Geslo'] := vpNovoGeslo1.Text;
                   podatkiObj['PotekLicence'] := izVeljavnostLicence.Text;
                   WebClientSpremembaGesla.Put('Uporabniki', podatkiObj);
                   izSpremembaGeslaOpozorilo.Visible := false;
                   vpStaroGeslo.Text := '';
                   vpNovoGeslo1.Text := '';
                   vpNovoGeslo2.Text := '';
                   vpStaroGeslo.SetFocus;
                   except
                     izSpremembaGeslaOpozorilo.Visible := true;
                     izSpremembaGeslaOpozorilo.Caption := LjezikUporabnik_Opozorilo2;
                     vpStaroGeslo.Text := '';
                     vpNovoGeslo1.Text := '';
                     vpNovoGeslo2.Text := '';
                     vpStaroGeslo.SetFocus;
                  end;

             end
             else begin
                 izSpremembaGeslaOpozorilo.Visible := true;
                 izSpremembaGeslaOpozorilo.Caption := LjezikUporabnik_Opozorilo3;
                 vpStaroGeslo.Text := '';
                 vpNovoGeslo1.Text := '';
                 vpNovoGeslo2.Text := '';
                 vpStaroGeslo.SetFocus;
             end;
          end
          else begin
                 izSpremembaGeslaOpozorilo.Visible := true;
                 izSpremembaGeslaOpozorilo.Caption := LjezikUporabnik_Opozorilo4;
                 vpStaroGeslo.Text := '';
                 vpNovoGeslo1.Text := '';
                 vpNovoGeslo2.Text := '';
                 vpStaroGeslo.SetFocus;
          end;
      end
      else begin
         izSpremembaGeslaOpozorilo.Visible := true;
         izSpremembaGeslaOpozorilo.Caption := LjezikUporabnik_Opozorilo4;
         vpStaroGeslo.Text := '';
         vpNovoGeslo1.Text := '';
         vpNovoGeslo2.Text := '';
         vpStaroGeslo.SetFocus;
      end;
    finally
       PovezavaServerSpremembaGesla.Connected := false;
       fmOsnovniKontroler.Cursor := crDefault;
    end;
  end;


begin
  try
    WebClientSpremembaGesla.RawInvoke('IUporabnikiService.GetUporabnik', [izUporabnik.Caption], @OnResult );
  except
    izSpremembaGeslaOpozorilo.Visible := false;
    izSpremembaGeslaOpozorilo.Caption := '';
    vpStaroGeslo.Text := '';
    vpNovoGeslo1.Text := '';
    vpNovoGeslo2.Text := '';
    vpStaroGeslo.SetFocus;
    ShowMessage(LjezikObvestilo_ServerPoizvedba);
  end;
end;

procedure TfmNastavitveUporabnika.povezavaServerSpremembaGeslaError(
  Error: TXDataWebConnectionError);
begin
  izSpremembaGeslaOpozorilo.Visible := false;
  izSpremembaGeslaOpozorilo.Caption := '';
  vpStaroGeslo.Text := '';
  vpNovoGeslo1.Text := '';
  vpNovoGeslo2.Text := '';
  vpStaroGeslo.SetFocus;
  showMessage(LjezikObvestilo_ServerNiPOvezave_01);
end;

procedure TfmNastavitveUporabnika.LoadDFMValues;
begin
  inherited LoadDFMValues;

  izUporabnikTekst := TLabel.Create(Self);
  izPodjetjeTekst := TLabel.Create(Self);
  izLicencaTekst := TLabel.Create(Self);
  izUporabnik := TLabel.Create(Self);
  izPodjetje := TLabel.Create(Self);
  ploscicaGlava := TPanel.Create(Self);
  gZapriNastavitve := TSpeedButton.Create(Self);
  ploscicaSpremembaGesla := TPanel.Create(Self);
  izNovoGeslo1Tekst := TLabel.Create(Self);
  izNovoGeslo2Tekst := TLabel.Create(Self);
  izStaroGeslo := TLabel.Create(Self);
  izSpremeniGeslo := TLabel.Create(Self);
  izSpremembaGeslaOpozorilo := TLabel.Create(Self);
  vpNovoGeslo1 := TEdit.Create(Self);
  vpNovoGeslo2 := TEdit.Create(Self);
  vpStaroGeslo := TEdit.Create(Self);
  gSpremeniGeslo := TSpeedButton.Create(Self);
  izVeljavnostLicence := TDateTimePicker.Create(Self);
  povezavaServerSpremembaGesla := TXDataWebConnection.Create(Self);
  WebClientSpremembaGesla := TXDataWebClient.Create(Self);

  izUporabnikTekst.BeforeLoadDFMValues;
  izPodjetjeTekst.BeforeLoadDFMValues;
  izLicencaTekst.BeforeLoadDFMValues;
  izUporabnik.BeforeLoadDFMValues;
  izPodjetje.BeforeLoadDFMValues;
  ploscicaGlava.BeforeLoadDFMValues;
  gZapriNastavitve.BeforeLoadDFMValues;
  ploscicaSpremembaGesla.BeforeLoadDFMValues;
  izNovoGeslo1Tekst.BeforeLoadDFMValues;
  izNovoGeslo2Tekst.BeforeLoadDFMValues;
  izStaroGeslo.BeforeLoadDFMValues;
  izSpremeniGeslo.BeforeLoadDFMValues;
  izSpremembaGeslaOpozorilo.BeforeLoadDFMValues;
  vpNovoGeslo1.BeforeLoadDFMValues;
  vpNovoGeslo2.BeforeLoadDFMValues;
  vpStaroGeslo.BeforeLoadDFMValues;
  gSpremeniGeslo.BeforeLoadDFMValues;
  izVeljavnostLicence.BeforeLoadDFMValues;
  povezavaServerSpremembaGesla.BeforeLoadDFMValues;
  WebClientSpremembaGesla.BeforeLoadDFMValues;
  try
    Name := 'fmNastavitveUporabnika';
    Width := 450;
    Height := 392;
    izUporabnikTekst.SetParentComponent(Self);
    izUporabnikTekst.Name := 'izUporabnikTekst';
    izUporabnikTekst.Left := 24;
    izUporabnikTekst.Top := 49;
    izUporabnikTekst.Width := 58;
    izUporabnikTekst.Height := 15;
    izUporabnikTekst.Caption := 'Uporabnik:';
    izUporabnikTekst.HeightPercent := 100.000000000000000000;
    izUporabnikTekst.WidthPercent := 100.000000000000000000;
    izPodjetjeTekst.SetParentComponent(Self);
    izPodjetjeTekst.Name := 'izPodjetjeTekst';
    izPodjetjeTekst.Left := 24;
    izPodjetjeTekst.Top := 81;
    izPodjetjeTekst.Width := 46;
    izPodjetjeTekst.Height := 15;
    izPodjetjeTekst.Caption := 'Podjetje:';
    izPodjetjeTekst.HeightPercent := 100.000000000000000000;
    izPodjetjeTekst.WidthPercent := 100.000000000000000000;
    izLicencaTekst.SetParentComponent(Self);
    izLicencaTekst.Name := 'izLicencaTekst';
    izLicencaTekst.Left := 24;
    izLicencaTekst.Top := 100;
    izLicencaTekst.Width := 113;
    izLicencaTekst.Height := 15;
    izLicencaTekst.Caption := 'Veljavnost licence do:';
    izLicencaTekst.HeightPercent := 100.000000000000000000;
    izLicencaTekst.WidthPercent := 100.000000000000000000;
    izUporabnik.SetParentComponent(Self);
    izUporabnik.Name := 'izUporabnik';
    izUporabnik.Left := 144;
    izUporabnik.Top := 49;
    izUporabnik.Width := 27;
    izUporabnik.Height := 13;
    izUporabnik.Caption := '.........';
    izUporabnik.Font.Charset := DEFAULT_CHARSET;
    izUporabnik.Font.Color := clWindowText;
    izUporabnik.Font.Height := -11;
    izUporabnik.Font.Name := 'Tahoma';
    izUporabnik.Font.Style := [fsBold];
    izUporabnik.HeightPercent := 100.000000000000000000;
    izUporabnik.ParentFont := False;
    izUporabnik.WidthPercent := 100.000000000000000000;
    izPodjetje.SetParentComponent(Self);
    izPodjetje.Name := 'izPodjetje';
    izPodjetje.Left := 144;
    izPodjetje.Top := 81;
    izPodjetje.Width := 30;
    izPodjetje.Height := 15;
    izPodjetje.Caption := '..........';
    izPodjetje.HeightPercent := 100.000000000000000000;
    izPodjetje.WidthPercent := 100.000000000000000000;
    ploscicaGlava.SetParentComponent(Self);
    ploscicaGlava.Name := 'ploscicaGlava';
    ploscicaGlava.Left := 0;
    ploscicaGlava.Top := 0;
    ploscicaGlava.Width := 450;
    ploscicaGlava.Height := 28;
    ploscicaGlava.HeightPercent := 100.000000000000000000;
    ploscicaGlava.WidthPercent := 100.000000000000000000;
    ploscicaGlava.Align := alTop;
    ploscicaGlava.BorderColor := clSilver;
    ploscicaGlava.BorderStyle := bsSingle;
    ploscicaGlava.Caption := 'Podatki o uporabniku';
    ploscicaGlava.Font.Charset := DEFAULT_CHARSET;
    ploscicaGlava.Font.Color := clWindowText;
    ploscicaGlava.Font.Height := -13;
    ploscicaGlava.Font.Name := 'Tahoma';
    ploscicaGlava.Font.Style := [fsBold];
    ploscicaGlava.ParentFont := False;
    gZapriNastavitve.SetParentComponent(ploscicaGlava);
    gZapriNastavitve.Name := 'gZapriNastavitve';
    gZapriNastavitve.Left := 426;
    gZapriNastavitve.Top := 0;
    gZapriNastavitve.Width := 24;
    gZapriNastavitve.Height := 28;
    gZapriNastavitve.Align := alRight;
    gZapriNastavitve.Caption := 'gZapriNastavitve';
    gZapriNastavitve.Color := clNone;
    gZapriNastavitve.Flat := True;
    gZapriNastavitve.Glyph.LoadFromFile('MBI_u03.ploscicaGlava.gZapriNastavitve.Glyph.png');
    gZapriNastavitve.HeightPercent := 100.000000000000000000;
    gZapriNastavitve.WidthPercent := 100.000000000000000000;
    SetEvent(gZapriNastavitve, Self, 'OnClick', 'gZapriNastavitveClick');
    ploscicaSpremembaGesla.SetParentComponent(Self);
    ploscicaSpremembaGesla.Name := 'ploscicaSpremembaGesla';
    ploscicaSpremembaGesla.Left := 24;
    ploscicaSpremembaGesla.Top := 160;
    ploscicaSpremembaGesla.Width := 402;
    ploscicaSpremembaGesla.Height := 209;
    ploscicaSpremembaGesla.HeightPercent := 100.000000000000000000;
    ploscicaSpremembaGesla.WidthPercent := 100.000000000000000000;
    ploscicaSpremembaGesla.BorderColor := clSilver;
    ploscicaSpremembaGesla.BorderStyle := bsSingle;
    ploscicaSpremembaGesla.ChildOrder := 13;
    ploscicaSpremembaGesla.Color := clWhite;
    izNovoGeslo1Tekst.SetParentComponent(ploscicaSpremembaGesla);
    izNovoGeslo1Tekst.Name := 'izNovoGeslo1Tekst';
    izNovoGeslo1Tekst.Left := 17;
    izNovoGeslo1Tekst.Top := 98;
    izNovoGeslo1Tekst.Width := 102;
    izNovoGeslo1Tekst.Height := 15;
    izNovoGeslo1Tekst.Caption := 'Vnesite novo geslo:';
    izNovoGeslo1Tekst.HeightPercent := 100.000000000000000000;
    izNovoGeslo1Tekst.WidthPercent := 100.000000000000000000;
    izNovoGeslo2Tekst.SetParentComponent(ploscicaSpremembaGesla);
    izNovoGeslo2Tekst.Name := 'izNovoGeslo2Tekst';
    izNovoGeslo2Tekst.Left := 209;
    izNovoGeslo2Tekst.Top := 98;
    izNovoGeslo2Tekst.Width := 111;
    izNovoGeslo2Tekst.Height := 15;
    izNovoGeslo2Tekst.Caption := 'Ponovite novo geslo:';
    izNovoGeslo2Tekst.HeightPercent := 100.000000000000000000;
    izNovoGeslo2Tekst.WidthPercent := 100.000000000000000000;
    izStaroGeslo.SetParentComponent(ploscicaSpremembaGesla);
    izStaroGeslo.Name := 'izStaroGeslo';
    izStaroGeslo.Left := 16;
    izStaroGeslo.Top := 42;
    izStaroGeslo.Width := 101;
    izStaroGeslo.Height := 15;
    izStaroGeslo.Caption := 'Vnesite staro geslo:';
    izStaroGeslo.HeightPercent := 100.000000000000000000;
    izStaroGeslo.WidthPercent := 100.000000000000000000;
    izSpremeniGeslo.SetParentComponent(ploscicaSpremembaGesla);
    izSpremeniGeslo.Name := 'izSpremeniGeslo';
    izSpremeniGeslo.Left := 16;
    izSpremeniGeslo.Top := 3;
    izSpremeniGeslo.Width := 107;
    izSpremeniGeslo.Height := 13;
    izSpremeniGeslo.Caption := 'SPREMEMBA GESLA';
    izSpremeniGeslo.Font.Charset := DEFAULT_CHARSET;
    izSpremeniGeslo.Font.Color := clWindowText;
    izSpremeniGeslo.Font.Height := -11;
    izSpremeniGeslo.Font.Name := 'Tahoma';
    izSpremeniGeslo.Font.Style := [fsBold];
    izSpremeniGeslo.HeightPercent := 100.000000000000000000;
    izSpremeniGeslo.ParentFont := False;
    izSpremeniGeslo.WidthPercent := 100.000000000000000000;
    izSpremembaGeslaOpozorilo.SetParentComponent(ploscicaSpremembaGesla);
    izSpremembaGeslaOpozorilo.Name := 'izSpremembaGeslaOpozorilo';
    izSpremembaGeslaOpozorilo.Left := 16;
    izSpremembaGeslaOpozorilo.Top := 145;
    izSpremembaGeslaOpozorilo.Width := 369;
    izSpremembaGeslaOpozorilo.Height := 13;
    izSpremembaGeslaOpozorilo.Alignment := taCenter;
    izSpremembaGeslaOpozorilo.AutoSize := False;
    izSpremembaGeslaOpozorilo.Caption := '.....';
    izSpremembaGeslaOpozorilo.Font.Charset := DEFAULT_CHARSET;
    izSpremembaGeslaOpozorilo.Font.Color := clMaroon;
    izSpremembaGeslaOpozorilo.Font.Height := -11;
    izSpremembaGeslaOpozorilo.Font.Name := 'Tahoma';
    izSpremembaGeslaOpozorilo.Font.Style := [];
    izSpremembaGeslaOpozorilo.HeightPercent := 100.000000000000000000;
    izSpremembaGeslaOpozorilo.ParentFont := False;
    izSpremembaGeslaOpozorilo.WidthPercent := 100.000000000000000000;
    vpNovoGeslo1.SetParentComponent(ploscicaSpremembaGesla);
    vpNovoGeslo1.Name := 'vpNovoGeslo1';
    vpNovoGeslo1.Left := 17;
    vpNovoGeslo1.Top := 117;
    vpNovoGeslo1.Width := 176;
    vpNovoGeslo1.Height := 22;
    vpNovoGeslo1.AutoCompletion := acCurrentPassword;
    vpNovoGeslo1.ChildOrder := 3;
    vpNovoGeslo1.HeightPercent := 100.000000000000000000;
    vpNovoGeslo1.PasswordChar := '*';
    vpNovoGeslo1.WidthPercent := 100.000000000000000000;
    vpNovoGeslo2.SetParentComponent(ploscicaSpremembaGesla);
    vpNovoGeslo2.Name := 'vpNovoGeslo2';
    vpNovoGeslo2.Left := 209;
    vpNovoGeslo2.Top := 117;
    vpNovoGeslo2.Width := 176;
    vpNovoGeslo2.Height := 22;
    vpNovoGeslo2.AutoCompletion := acCurrentPassword;
    vpNovoGeslo2.ChildOrder := 4;
    vpNovoGeslo2.HeightPercent := 100.000000000000000000;
    vpNovoGeslo2.PasswordChar := '*';
    vpNovoGeslo2.WidthPercent := 100.000000000000000000;
    vpStaroGeslo.SetParentComponent(ploscicaSpremembaGesla);
    vpStaroGeslo.Name := 'vpStaroGeslo';
    vpStaroGeslo.Left := 16;
    vpStaroGeslo.Top := 61;
    vpStaroGeslo.Width := 176;
    vpStaroGeslo.Height := 22;
    vpStaroGeslo.AutoCompletion := acCurrentPassword;
    vpStaroGeslo.ChildOrder := 5;
    vpStaroGeslo.HeightPercent := 100.000000000000000000;
    vpStaroGeslo.PasswordChar := '*';
    vpStaroGeslo.WidthPercent := 100.000000000000000000;
    gSpremeniGeslo.SetParentComponent(ploscicaSpremembaGesla);
    gSpremeniGeslo.Name := 'gSpremeniGeslo';
    gSpremeniGeslo.Left := 120;
    gSpremeniGeslo.Top := 164;
    gSpremeniGeslo.Width := 125;
    gSpremeniGeslo.Height := 24;
    gSpremeniGeslo.Caption := 'Spremeni geslo';
    gSpremeniGeslo.Color := clSilver;
    gSpremeniGeslo.Flat := True;
    gSpremeniGeslo.Glyph.LoadFromFile('MBI_u03.ploscicaSpremembaGesla.gSpremeniGeslo.Glyph.png');
    gSpremeniGeslo.HeightPercent := 100.000000000000000000;
    gSpremeniGeslo.WidthPercent := 100.000000000000000000;
    SetEvent(gSpremeniGeslo, Self, 'OnClick', 'gSpremeniGesloClick');
    izVeljavnostLicence.SetParentComponent(Self);
    izVeljavnostLicence.Name := 'izVeljavnostLicence';
    izVeljavnostLicence.Left := 140;
    izVeljavnostLicence.Top := 96;
    izVeljavnostLicence.Width := 170;
    izVeljavnostLicence.Height := 22;
    izVeljavnostLicence.HeightPercent := 100.000000000000000000;
    izVeljavnostLicence.WidthPercent := 100.000000000000000000;
    izVeljavnostLicence.BorderStyle := bsNone;
    izVeljavnostLicence.ChildOrder := 7;
    izVeljavnostLicence.Color := clWhite;
    izVeljavnostLicence.Date := 44479.859004317130000000;
    izVeljavnostLicence.ReadOnly := True;
    izVeljavnostLicence.Role := '';
    izVeljavnostLicence.ShowFocus := False;
    izVeljavnostLicence.Text := '';
    povezavaServerSpremembaGesla.SetParentComponent(Self);
    povezavaServerSpremembaGesla.Name := 'povezavaServerSpremembaGesla';
    povezavaServerSpremembaGesla.URL := 'http://ec2-35-158-89-121.eu-central-1.compute.amazonaws.com/tms/xdata';
    SetEvent(povezavaServerSpremembaGesla, Self, 'OnConnect', 'povezavaServerSpremembaGeslaConnect');
    SetEvent(povezavaServerSpremembaGesla, Self, 'OnError', 'povezavaServerSpremembaGeslaError');
    povezavaServerSpremembaGesla.Left := 360;
    povezavaServerSpremembaGesla.Top := 48;
    WebClientSpremembaGesla.SetParentComponent(Self);
    WebClientSpremembaGesla.Name := 'WebClientSpremembaGesla';
    WebClientSpremembaGesla.Left := 360;
    WebClientSpremembaGesla.Top := 104;
  finally
    izUporabnikTekst.AfterLoadDFMValues;
    izPodjetjeTekst.AfterLoadDFMValues;
    izLicencaTekst.AfterLoadDFMValues;
    izUporabnik.AfterLoadDFMValues;
    izPodjetje.AfterLoadDFMValues;
    ploscicaGlava.AfterLoadDFMValues;
    gZapriNastavitve.AfterLoadDFMValues;
    ploscicaSpremembaGesla.AfterLoadDFMValues;
    izNovoGeslo1Tekst.AfterLoadDFMValues;
    izNovoGeslo2Tekst.AfterLoadDFMValues;
    izStaroGeslo.AfterLoadDFMValues;
    izSpremeniGeslo.AfterLoadDFMValues;
    izSpremembaGeslaOpozorilo.AfterLoadDFMValues;
    vpNovoGeslo1.AfterLoadDFMValues;
    vpNovoGeslo2.AfterLoadDFMValues;
    vpStaroGeslo.AfterLoadDFMValues;
    gSpremeniGeslo.AfterLoadDFMValues;
    izVeljavnostLicence.AfterLoadDFMValues;
    povezavaServerSpremembaGesla.AfterLoadDFMValues;
    WebClientSpremembaGesla.AfterLoadDFMValues;
  end;
end;

end.