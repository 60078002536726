unit MBI_u06;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Imaging.pngimage, WEBLib.Buttons,
  Vcl.Controls, WEBLib.ExtCtrls, Vcl.Grids, WEBLib.Grids, Data.DB, WEBLib.DB,
  XData.Web.JsonDataset, XData.Web.Dataset, XData.Web.Connection, WEBLib.DBCtrls,
  XData.Web.Client;

type
  TfmOdpriKalkulacijo = class(TForm)
    ploscicaGumbi: TPanel;
    gIzberi: TSpeedButton;
    gPreklici: TSpeedButton;
    PovezavaServerKalkulacije: TXDataWebConnection;
    DataSetKalkulacije: TXDataWebDataSet;
    DataSourceKalkulacije: TDataSource;
    TabelaKalkulacije: TDBGrid;
    ploscicaNaslov: TPanel;
    DataSetKalkulacijeStevilka: TIntegerField;
    DataSetKalkulacijeDavcnaID: TStringField;
    DataSetKalkulacijeLetoPodatkov: TStringField;
    DataSetKalkulacijeDatumVnosa: TStringField;
    DataSetKalkulacijeUporabnik: TStringField;
    DataSetKalkulacijeImePodjetja: TStringField;
    DataSetKalkulacijeNaslovPodjetja: TStringField;
    DataSetKalkulacijeDejavnostPodjetja: TStringField;
    gIzbrisi: TSpeedButton;
    obvestiloBrisanje: TMessageDlg;
    PovezavaServerBrisanje: TXDataWebConnection;
    WebClientBrisanje: TXDataWebClient;
    procedure gPrekliciClick(Sender: TObject);
    procedure PovezavaServerKalkulacijeConnect(Sender: TObject);
    procedure gIzberiClick(Sender: TObject);
    procedure gIzbrisiClick(Sender: TObject);
    procedure obvestiloBrisanjeButtonClick(Sender: TObject);
    procedure PovezavaServerBrisanjeConnect(Sender: TObject);
    procedure PovezavaServerBrisanjeError(Error: TXDataWebConnectionError);
    procedure PovezavaServerKalkulacijeError(Error: TXDataWebConnectionError);
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  fmOdpriKalkulacijo: TfmOdpriKalkulacijo;
  izborKalkulacijeZaBrisanje : Integer;

implementation

uses MBI_u01;

{$R *.dfm}

procedure TfmOdpriKalkulacijo.gIzberiClick(Sender: TObject);
begin
  ModalResult := 1;
  Close;
  FreeAndNil(fmOdpriKalkulacijo);
end;

procedure TfmOdpriKalkulacijo.gPrekliciClick(Sender: TObject);
begin
  ModalResult := 0;
  Close;
  FreeAndNil(fmOdpriKalkulacijo);
end;

procedure TfmOdpriKalkulacijo.obvestiloBrisanjeButtonClick(Sender: TObject);
begin
  if obvestiloBrisanje.DialogResult = 6 then begin

    izborKalkulacijeZaBrisanje := DataSourceKalkulacije.DataSet.FieldByName('Stevilka').AsInteger;

    WebClientBrisanje.Connection := PovezavaServerBrisanje;
    fmOsnovniKontroler.Cursor := crHourGlass;
    if PovezavaServerBrisanje.Connected = true then
       PovezavaServerBrisanje.Connected := false;

    try
      With DataSetKalkulacije do begin
        Edit;
        Delete;
        ApplyUpdates;
      end;
      try
        PovezavaServerBrisanje.Connected := true;  // Zažene povezavo s serjeverjem. Ko je ta vzpostavljena se zažene procedura OnConnect gradnika PovezavaServer
      except
        showMessage(LjezikObvestilo_ServerNiPovezave_02);
      end;
    except
       showMessage(LjezikObvestilo_ServerBrisanje_01);
    end;
  end;
end;

procedure TfmOdpriKalkulacijo.PovezavaServerBrisanjeConnect(Sender: TObject);
var podatkiObj1, podatkiObj2 : TJSObject;

    procedure OnResult(Response: TXDataClientResponse);
    var LArray : TJSArray;
        LItem : TJSObject;
    begin
      LArray := TJSArray( TJSObject( Response.Result )['value']);
      LItem := TJSObject( LArray[0]);
      try
        if LItem <> null then begin
          try
             WebClientBrisanje.Delete('Skode', podatkiObj2);
           except
              ShowMessage(LjezikObvestilo_ServerBrisanje_02);
           end;
        end;
      finally
        fmOsnovniKontroler.Cursor := crDefault;
      end;
    end;

begin
   try
     try
       podatkiObj1 := TJSObject.New;
       podatkiObj1['Stevilka'] := izborKalkulacijeZaBrisanje;
       WebClientBrisanje.Delete('Podatki', podatkiObj1);

       podatkiObj2 := TJSObject.New;
       podatkiObj2['Stevilka'] := izborKalkulacijeZaBrisanje;
       WebClientBrisanje.RawInvoke('ISkodeService.GetSkode', [izborKalkulacijeZaBrisanje], @OnResult);

     except
        ShowMessage(LjezikObvestilo_ServerBrisanje_02);
     end;
  finally
     fmOsnovniKontroler.Cursor := crDefault;
  end;

end;



procedure TfmOdpriKalkulacijo.PovezavaServerBrisanjeError(
  Error: TXDataWebConnectionError);
begin
  showMessage(LjezikObvestilo_ServerNiPovezave_01);
end;

procedure TfmOdpriKalkulacijo.PovezavaServerKalkulacijeConnect(Sender: TObject);
begin
  DataSetKalkulacije.Load;
end;

procedure TfmOdpriKalkulacijo.PovezavaServerKalkulacijeError(
  Error: TXDataWebConnectionError);
begin
  showMessage(LjezikObvestilo_ServerNiPovezave_01);
end;

procedure TfmOdpriKalkulacijo.gIzbrisiClick(Sender: TObject);
begin
  obvestiloBrisanje.ShowDialog();
end;

procedure TfmOdpriKalkulacijo.LoadDFMValues;
begin
  inherited LoadDFMValues;

  ploscicaGumbi := TPanel.Create(Self);
  gIzberi := TSpeedButton.Create(Self);
  gPreklici := TSpeedButton.Create(Self);
  gIzbrisi := TSpeedButton.Create(Self);
  TabelaKalkulacije := TDBGrid.Create(Self);
  ploscicaNaslov := TPanel.Create(Self);
  obvestiloBrisanje := TMessageDlg.Create(Self);
  PovezavaServerKalkulacije := TXDataWebConnection.Create(Self);
  DataSetKalkulacije := TXDataWebDataSet.Create(Self);
  DataSetKalkulacijeStevilka := TIntegerField.Create(Self);
  DataSetKalkulacijeDavcnaID := TStringField.Create(Self);
  DataSetKalkulacijeLetoPodatkov := TStringField.Create(Self);
  DataSetKalkulacijeDatumVnosa := TStringField.Create(Self);
  DataSetKalkulacijeUporabnik := TStringField.Create(Self);
  DataSetKalkulacijeImePodjetja := TStringField.Create(Self);
  DataSetKalkulacijeNaslovPodjetja := TStringField.Create(Self);
  DataSetKalkulacijeDejavnostPodjetja := TStringField.Create(Self);
  DataSourceKalkulacije := TDataSource.Create(Self);
  PovezavaServerBrisanje := TXDataWebConnection.Create(Self);
  WebClientBrisanje := TXDataWebClient.Create(Self);

  ploscicaGumbi.BeforeLoadDFMValues;
  gIzberi.BeforeLoadDFMValues;
  gPreklici.BeforeLoadDFMValues;
  gIzbrisi.BeforeLoadDFMValues;
  TabelaKalkulacije.BeforeLoadDFMValues;
  ploscicaNaslov.BeforeLoadDFMValues;
  obvestiloBrisanje.BeforeLoadDFMValues;
  PovezavaServerKalkulacije.BeforeLoadDFMValues;
  DataSetKalkulacije.BeforeLoadDFMValues;
  DataSetKalkulacijeStevilka.BeforeLoadDFMValues;
  DataSetKalkulacijeDavcnaID.BeforeLoadDFMValues;
  DataSetKalkulacijeLetoPodatkov.BeforeLoadDFMValues;
  DataSetKalkulacijeDatumVnosa.BeforeLoadDFMValues;
  DataSetKalkulacijeUporabnik.BeforeLoadDFMValues;
  DataSetKalkulacijeImePodjetja.BeforeLoadDFMValues;
  DataSetKalkulacijeNaslovPodjetja.BeforeLoadDFMValues;
  DataSetKalkulacijeDejavnostPodjetja.BeforeLoadDFMValues;
  DataSourceKalkulacije.BeforeLoadDFMValues;
  PovezavaServerBrisanje.BeforeLoadDFMValues;
  WebClientBrisanje.BeforeLoadDFMValues;
  try
    Name := 'fmOdpriKalkulacijo';
    Width := 375;
    Height := 268;
    Caption := 'SHRANJENE KALKULACIJE';
    ploscicaGumbi.SetParentComponent(Self);
    ploscicaGumbi.Name := 'ploscicaGumbi';
    ploscicaGumbi.Left := 0;
    ploscicaGumbi.Top := 240;
    ploscicaGumbi.Width := 375;
    ploscicaGumbi.Height := 28;
    ploscicaGumbi.HeightPercent := 100.000000000000000000;
    ploscicaGumbi.WidthPercent := 100.000000000000000000;
    ploscicaGumbi.Align := alBottom;
    ploscicaGumbi.BorderColor := clSilver;
    ploscicaGumbi.BorderStyle := bsNone;
    ploscicaGumbi.Color := clSilver;
    gIzberi.SetParentComponent(ploscicaGumbi);
    gIzberi.Name := 'gIzberi';
    gIzberi.AlignWithMargins := True;
    gIzberi.Left := 3;
    gIzberi.Top := 5;
    gIzberi.Width := 119;
    gIzberi.Height := 20;
    gIzberi.Margins.Top := 5;
    gIzberi.Align := alLeft;
    gIzberi.Caption := 'Izberi';
    gIzberi.Color := clNone;
    gIzberi.Flat := True;
    gIzberi.Glyph.LoadFromFile('MBI_u06.ploscicaGumbi.gIzberi.Glyph.png');
    gIzberi.HeightPercent := 100.000000000000000000;
    gIzberi.WidthPercent := 100.000000000000000000;
    SetEvent(gIzberi, Self, 'OnClick', 'gIzberiClick');
    gPreklici.SetParentComponent(ploscicaGumbi);
    gPreklici.Name := 'gPreklici';
    gPreklici.AlignWithMargins := True;
    gPreklici.Left := 253;
    gPreklici.Top := 5;
    gPreklici.Width := 119;
    gPreklici.Height := 20;
    gPreklici.Margins.Top := 5;
    gPreklici.Align := alRight;
    gPreklici.Caption := 'Prekli'#269'i';
    gPreklici.Color := clNone;
    gPreklici.Flat := True;
    gPreklici.Glyph.LoadFromFile('MBI_u06.ploscicaGumbi.gPreklici.Glyph.png');
    gPreklici.HeightPercent := 100.000000000000000000;
    gPreklici.WidthPercent := 100.000000000000000000;
    SetEvent(gPreklici, Self, 'OnClick', 'gPrekliciClick');
    gIzbrisi.SetParentComponent(ploscicaGumbi);
    gIzbrisi.Name := 'gIzbrisi';
    gIzbrisi.AlignWithMargins := True;
    gIzbrisi.Left := 128;
    gIzbrisi.Top := 5;
    gIzbrisi.Width := 119;
    gIzbrisi.Height := 20;
    gIzbrisi.Margins.Top := 5;
    gIzbrisi.Align := alClient;
    gIzbrisi.Caption := 'Izbri'#353'i';
    gIzbrisi.Color := clNone;
    gIzbrisi.Flat := True;
    gIzbrisi.Glyph.LoadFromFile('MBI_u06.ploscicaGumbi.gIzbrisi.Glyph.png');
    gIzbrisi.HeightPercent := 100.000000000000000000;
    gIzbrisi.WidthPercent := 100.000000000000000000;
    SetEvent(gIzbrisi, Self, 'OnClick', 'gIzbrisiClick');
    TabelaKalkulacije.SetParentComponent(Self);
    TabelaKalkulacije.Name := 'TabelaKalkulacije';
    TabelaKalkulacije.AlignWithMargins := True;
    TabelaKalkulacije.Left := 5;
    TabelaKalkulacije.Top := 29;
    TabelaKalkulacije.Width := 365;
    TabelaKalkulacije.Height := 206;
    TabelaKalkulacije.Margins.Left := 5;
    TabelaKalkulacije.Margins.Top := 5;
    TabelaKalkulacije.Margins.Right := 5;
    TabelaKalkulacije.Margins.Bottom := 5;
    TabelaKalkulacije.Align := alClient;
    TabelaKalkulacije.BorderStyle := bsNone;
    TabelaKalkulacije.Color := clWhite;
    TabelaKalkulacije.Columns.Clear;
    with TabelaKalkulacije.Columns.Add do
    begin
      DataField := 'DavcnaID';
      Title := 'Dav'#269'na ID';
      Width := 60;
    end;
    with TabelaKalkulacije.Columns.Add do
    begin
      DataField := 'ImePodjetja';
      Title := 'Podjetje';
      Width := 192;
    end;
    with TabelaKalkulacije.Columns.Add do
    begin
      DataField := 'LetoPodatkov';
      Title := 'Leto';
      Width := 48;
    end;
    with TabelaKalkulacije.Columns.Add do
    begin
      DataField := 'DatumVnosa';
      Title := 'Datum';
      Width := 60;
    end;
    TabelaKalkulacije.DataSource := DataSourceKalkulacije;
    TabelaKalkulacije.FixedColor := clSilver;
    TabelaKalkulacije.FixedCols := 0;
    TabelaKalkulacije.Options := [goRowSelect];
    TabelaKalkulacije.TabOrder := 1;
    TabelaKalkulacije.HeightPercent := 100.000000000000000000;
    TabelaKalkulacije.WidthPercent := 100.000000000000000000;
    SetEvent(TabelaKalkulacije, Self, 'OnDblClick', 'gIzberiClick');
    TabelaKalkulacije.ColWidths[0] := 60;
    TabelaKalkulacije.ColWidths[1] := 192;
    TabelaKalkulacije.ColWidths[2] := 48;
    TabelaKalkulacije.ColWidths[3] := 60;
    ploscicaNaslov.SetParentComponent(Self);
    ploscicaNaslov.Name := 'ploscicaNaslov';
    ploscicaNaslov.Left := 0;
    ploscicaNaslov.Top := 0;
    ploscicaNaslov.Width := 375;
    ploscicaNaslov.Height := 24;
    ploscicaNaslov.HeightPercent := 100.000000000000000000;
    ploscicaNaslov.WidthPercent := 100.000000000000000000;
    ploscicaNaslov.Align := alTop;
    ploscicaNaslov.BorderColor := clGray;
    ploscicaNaslov.BorderStyle := bsNone;
    ploscicaNaslov.Caption := 'Shranjene kalkulacije';
    ploscicaNaslov.ChildOrder := 2;
    ploscicaNaslov.Color := clGray;
    obvestiloBrisanje.SetParentComponent(Self);
    obvestiloBrisanje.Name := 'obvestiloBrisanje';
    obvestiloBrisanje.Left := 24;
    obvestiloBrisanje.Top := 152;
    obvestiloBrisanje.Width := 24;
    obvestiloBrisanje.Height := 24;
    obvestiloBrisanje.HeightPercent := 100.000000000000000000;
    obvestiloBrisanje.WidthPercent := 100.000000000000000000;
    obvestiloBrisanje.Buttons := [mbYes,mbNo];
    obvestiloBrisanje.DialogText.BeginUpdate;
    try
      obvestiloBrisanje.DialogText.Clear;
      obvestiloBrisanje.DialogText.Add('Warning');
      obvestiloBrisanje.DialogText.Add('Error');
      obvestiloBrisanje.DialogText.Add('Information');
      obvestiloBrisanje.DialogText.Add('Confirm');
      obvestiloBrisanje.DialogText.Add('Custom');
      obvestiloBrisanje.DialogText.Add('OK');
      obvestiloBrisanje.DialogText.Add('Cancel');
      obvestiloBrisanje.DialogText.Add('Yes');
      obvestiloBrisanje.DialogText.Add('No');
      obvestiloBrisanje.DialogText.Add('Abort');
      obvestiloBrisanje.DialogText.Add('Retry');
      obvestiloBrisanje.DialogText.Add('Ignore');
      obvestiloBrisanje.DialogText.Add('All');
      obvestiloBrisanje.DialogText.Add('Yes to All');
      obvestiloBrisanje.DialogText.Add('No to All');
      obvestiloBrisanje.DialogText.Add('Help');
      obvestiloBrisanje.DialogText.Add('Close');
    finally
      obvestiloBrisanje.DialogText.EndUpdate;
    end;
    obvestiloBrisanje.DialogType := mtWarning;
    obvestiloBrisanje.Message := #268'e nadaljujete, boste nepovratno izbrisali kalkulacijo. <br> <br> Ali '#382'elite nadaljevati?';
    obvestiloBrisanje.Opacity := 0.200000000000000000;
    SetEvent(obvestiloBrisanje, Self, 'OnButtonClick', 'obvestiloBrisanjeButtonClick');
    PovezavaServerKalkulacije.SetParentComponent(Self);
    PovezavaServerKalkulacije.Name := 'PovezavaServerKalkulacije';
    PovezavaServerKalkulacije.URL := 'http://ec2-35-158-89-121.eu-central-1.compute.amazonaws.com/tms/xdata';
    SetEvent(PovezavaServerKalkulacije, Self, 'OnConnect', 'PovezavaServerKalkulacijeConnect');
    SetEvent(PovezavaServerKalkulacije, Self, 'OnError', 'PovezavaServerKalkulacijeError');
    PovezavaServerKalkulacije.Left := 24;
    PovezavaServerKalkulacije.Top := 88;
    DataSetKalkulacije.SetParentComponent(Self);
    DataSetKalkulacije.Name := 'DataSetKalkulacije';
    DataSetKalkulacije.EntitySetName := 'Kalkulacije';
    DataSetKalkulacije.Connection := PovezavaServerKalkulacije;
    DataSetKalkulacije.Left := 112;
    DataSetKalkulacije.Top := 88;
    DataSetKalkulacijeStevilka.SetParentComponent(DataSetKalkulacije);
    DataSetKalkulacijeStevilka.Name := 'DataSetKalkulacijeStevilka';
    DataSetKalkulacijeStevilka.FieldName := 'Stevilka';
    DataSetKalkulacijeStevilka.Required := True;
    DataSetKalkulacijeDavcnaID.SetParentComponent(DataSetKalkulacije);
    DataSetKalkulacijeDavcnaID.Name := 'DataSetKalkulacijeDavcnaID';
    DataSetKalkulacijeDavcnaID.FieldName := 'DavcnaID';
    DataSetKalkulacijeDavcnaID.Required := True;
    DataSetKalkulacijeDavcnaID.Size := 0;
    DataSetKalkulacijeLetoPodatkov.SetParentComponent(DataSetKalkulacije);
    DataSetKalkulacijeLetoPodatkov.Name := 'DataSetKalkulacijeLetoPodatkov';
    DataSetKalkulacijeLetoPodatkov.FieldName := 'LetoPodatkov';
    DataSetKalkulacijeLetoPodatkov.Required := True;
    DataSetKalkulacijeLetoPodatkov.Size := 0;
    DataSetKalkulacijeDatumVnosa.SetParentComponent(DataSetKalkulacije);
    DataSetKalkulacijeDatumVnosa.Name := 'DataSetKalkulacijeDatumVnosa';
    DataSetKalkulacijeDatumVnosa.FieldName := 'DatumVnosa';
    DataSetKalkulacijeDatumVnosa.Size := 0;
    DataSetKalkulacijeUporabnik.SetParentComponent(DataSetKalkulacije);
    DataSetKalkulacijeUporabnik.Name := 'DataSetKalkulacijeUporabnik';
    DataSetKalkulacijeUporabnik.FieldName := 'Uporabnik';
    DataSetKalkulacijeUporabnik.Required := True;
    DataSetKalkulacijeUporabnik.Size := 0;
    DataSetKalkulacijeImePodjetja.SetParentComponent(DataSetKalkulacije);
    DataSetKalkulacijeImePodjetja.Name := 'DataSetKalkulacijeImePodjetja';
    DataSetKalkulacijeImePodjetja.FieldName := 'ImePodjetja';
    DataSetKalkulacijeImePodjetja.Required := True;
    DataSetKalkulacijeImePodjetja.Size := 0;
    DataSetKalkulacijeNaslovPodjetja.SetParentComponent(DataSetKalkulacije);
    DataSetKalkulacijeNaslovPodjetja.Name := 'DataSetKalkulacijeNaslovPodjetja';
    DataSetKalkulacijeNaslovPodjetja.FieldName := 'NaslovPodjetja';
    DataSetKalkulacijeNaslovPodjetja.Size := 0;
    DataSetKalkulacijeDejavnostPodjetja.SetParentComponent(DataSetKalkulacije);
    DataSetKalkulacijeDejavnostPodjetja.Name := 'DataSetKalkulacijeDejavnostPodjetja';
    DataSetKalkulacijeDejavnostPodjetja.FieldName := 'DejavnostPodjetja';
    DataSetKalkulacijeDejavnostPodjetja.Size := 0;
    DataSourceKalkulacije.SetParentComponent(Self);
    DataSourceKalkulacije.Name := 'DataSourceKalkulacije';
    DataSourceKalkulacije.DataSet := DataSetKalkulacije;
    DataSourceKalkulacije.Left := 200;
    DataSourceKalkulacije.Top := 88;
    PovezavaServerBrisanje.SetParentComponent(Self);
    PovezavaServerBrisanje.Name := 'PovezavaServerBrisanje';
    PovezavaServerBrisanje.URL := 'http://ec2-35-158-89-121.eu-central-1.compute.amazonaws.com/tms/xdata';
    SetEvent(PovezavaServerBrisanje, Self, 'OnConnect', 'PovezavaServerBrisanjeConnect');
    SetEvent(PovezavaServerBrisanje, Self, 'OnError', 'PovezavaServerBrisanjeError');
    PovezavaServerBrisanje.Left := 16;
    PovezavaServerBrisanje.Top := 192;
    WebClientBrisanje.SetParentComponent(Self);
    WebClientBrisanje.Name := 'WebClientBrisanje';
    WebClientBrisanje.Left := 128;
    WebClientBrisanje.Top := 192;
  finally
    ploscicaGumbi.AfterLoadDFMValues;
    gIzberi.AfterLoadDFMValues;
    gPreklici.AfterLoadDFMValues;
    gIzbrisi.AfterLoadDFMValues;
    TabelaKalkulacije.AfterLoadDFMValues;
    ploscicaNaslov.AfterLoadDFMValues;
    obvestiloBrisanje.AfterLoadDFMValues;
    PovezavaServerKalkulacije.AfterLoadDFMValues;
    DataSetKalkulacije.AfterLoadDFMValues;
    DataSetKalkulacijeStevilka.AfterLoadDFMValues;
    DataSetKalkulacijeDavcnaID.AfterLoadDFMValues;
    DataSetKalkulacijeLetoPodatkov.AfterLoadDFMValues;
    DataSetKalkulacijeDatumVnosa.AfterLoadDFMValues;
    DataSetKalkulacijeUporabnik.AfterLoadDFMValues;
    DataSetKalkulacijeImePodjetja.AfterLoadDFMValues;
    DataSetKalkulacijeNaslovPodjetja.AfterLoadDFMValues;
    DataSetKalkulacijeDejavnostPodjetja.AfterLoadDFMValues;
    DataSourceKalkulacije.AfterLoadDFMValues;
    PovezavaServerBrisanje.AfterLoadDFMValues;
    WebClientBrisanje.AfterLoadDFMValues;
  end;
end;

end.
