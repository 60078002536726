program ModelBI_project;

uses
  Vcl.Forms,
  WEBLib.Forms,
  MBI_u01 in 'MBI_u01.pas'{*.html},
  MBI_u04 in 'MBI_u04.pas'{*.html},
  MBI_u05 in 'MBI_u05.pas'{*.html},
  MBI_u06 in 'MBI_u06.pas'{*.html},
  MBI_u07 in 'MBI_u07.pas'{*.html},
  MBI_u02 in 'MBI_u02.pas'{*.html},
  MBI_u03 in 'MBI_u03.pas'{*.html};

{$R *.res}

begin
  Application.Initialize;
  Application.MainFormOnTaskbar := True;
  Application.CreateForm(TfmOsnovniKontroler, fmOsnovniKontroler);
  Application.CreateForm(TfmSimulacijaSkode, fmSimulacijaSkode);
  Application.CreateForm(TfmSimulacije, fmSimulacije);
  Application.CreateForm(TfmOdpriKalkulacijo, fmOdpriKalkulacijo);
  Application.CreateForm(TfmLetoPodatkov, fmLetoPodatkov);
  Application.CreateForm(TfmVizitka, fmVizitka);
  Application.CreateForm(TfmNastavitveUporabnika, fmNastavitveUporabnika);
  Application.Run;
end.
