{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2018                                      }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.GoogleReCaptcha;

{$DEFINE NOPP}

interface

uses
  Classes, Web, JS, WEBLib.Controls, WEBLib.Graphics;

const
  MAJ_VER = 1; // Major version nr.
  MIN_VER = 0; // Minor version nr.
  REL_VER = 0; // Release nr.
  BLD_VER = 0; // Build nr.

  // version history
  // 1.0.0.0 : First release

type
  TVerifyEventArgs = class(TPersistent)
  private
    FScore: Double;
    FAction: string;
    FDateTime: string;
    FHostName: string;
  public
    property Action: string read FAction write FAction;
    property Score: Double read FScore write FScore;
    property TimeStamp: string read FDateTime write FDateTime;
    property HostName: string read FHostName write FHostName;
  end;

  TVerifyEvent = procedure(Sender: TObject; Args: TVerifyEventArgs) of object;

  TGoogleReCaptcha = class(TCustomControl)
  private
    FReq: TJSXMLHttpRequest;
    FAPIKey: string;
    FOnVerified: TVerifyEvent;
    FAPIUrl: string;
    procedure SetAPIKey(const Value: string);
    procedure SetAPIUrl(const Value: string);
  protected
    function CreateElement: TJSElement; override;
    procedure UpdateElement; override;
    function HandleResponse(Event: TEventListenerEvent): boolean;
    function HandleAbort(Event: TEventListenerEvent): boolean;
    function HandleToken(Token: string): Boolean;
    procedure VerifyToken(Token: string);
  public
    constructor Create(AOwner: TComponent); override;
    procedure Verify(Action: string);
  published
    property Enabled;
    property ElementClassName;
    property ElementID;
    property APIKey: string read FAPIKey write SetAPIKey;
    property APIUrl: string read FAPIUrl write SetAPIUrl;
    property OnVerified: TVerifyEvent read FOnVerified write FOnVerified;
  end;

  TWebGoogleReCaptcha = class(TGoogleReCaptcha);

implementation

uses
  SysUtils, WebLib.WebTools;

{ TGoogleReCaptcha }

constructor TGoogleReCaptcha.Create(AOwner: TComponent);
begin
  inherited Create(AOwner);
  FAPIKey := '';
end;

function TGoogleReCaptcha.CreateElement: TJSElement;
begin
  if (csDesigning in ComponentState) then
    Result := nil
  else
    Result := document.createElement('DIV');
end;

procedure TGoogleReCaptcha.SetAPIKey(const Value: string);
begin
  if FAPIKey <> Value then
  begin
    FAPIKey := Value;
    UpdateElement;
  end;
end;

procedure TGoogleReCaptcha.SetAPIUrl(const Value: string);
begin
  FAPIUrl := Value;
end;

procedure TGoogleReCaptcha.UpdateElement;
begin
  inherited;

  if IsUpdating then
    Exit;

  if APIKey = '' then
    Exit;

  AddControlScriptLink('https://www.google.com/recaptcha/api.js?render=' + APIKey);
end;

function TGoogleReCaptcha.HandleAbort(Event: TEventListenerEvent): boolean;
begin
  Result := False;
end;

function TGoogleReCaptcha.HandleResponse(Event: TEventListenerEvent): boolean;
var
  fnd: Boolean;
  score: Double;
  action: string;
  timestamp: string;
  hostname: string;
  Args: TVerifyEventArgs;
begin
  Result := true;
  fnd := false;

  asm
    var s = Event.target.responseText;
    var js = JSON.parse(s);
    if (js.success == true) {
      fnd = true;
      score = js.score;
      action = js.action;
      timestamp = js.challenge_ts;
      hostname = js.hostname;
    }
  end;

  if fnd and Assigned(OnVerified) then
  begin
    Args := TVerifyEventArgs.Create;
    Args.Action := action;
    Args.Score := score;
    Args.TimeStamp := timestamp;
    Args.HostName := hostname;
    OnVerified(Self, Args);
    Args.Free;
  end;
end;

function TGoogleReCaptcha.HandleToken(Token: string): Boolean;
begin
  Result := True;
  VerifyToken(Token);
end;

procedure TGoogleReCaptcha.VerifyToken(Token: string);
var
  FURL: string;
begin
  FReq := TJSXMLHttpRequest.new;
  FReq.addEventListener('load', @HandleResponse);
  FReq.addEventListener('abort', @HandleAbort);

  FURL := APIUrl + Token;

  FReq.open('GET', FURL);
  FReq.send;
end;

procedure TGoogleReCaptcha.Verify(Action: string);
var
  api: string;
begin
  api := APIKey;
  if api <> '' then
  begin
    asm
      var cl = this;
      grecaptcha.execute(api, {action: Action})
      .then
      (
        function(token)
        {
          cl.HandleToken(token);
        }
      );
    end;
  end;
end;

end.


